import React from 'react'

import { Button } from 'antd'

import { FlexBoxX } from 'common/components/boxes'

import { OrderDeliveryCostsTotal, OrderDeliveryCostsTotalProps } from './order_delivery_costs_total'

type OrderDeliveryCostsProps = {
  addEmptyOrderMaterials?: () => void
  hideTotal?: boolean
  roundingPrecision?: number
} & OrderDeliveryCostsTotalProps

export const OrderDeliveryCosts = ({
  disabled,
  costsDisabled,
  isPoLocked,
  index,
  addEmptyOrderMaterials,
  hideTotal,
  onChange,
  value,
  deliveryTotalCost,
  hideAddTotal,
  roundingPrecision,
  inputsProps,
  orderMaterialsTotalCost,
  deliveryChargesUnits,
  onChangeChargesUnit,
  deliveryName,
}: OrderDeliveryCostsProps) => {
  return (
    <FlexBoxX
      alignItems="center"
      justifyContent={disabled || isPoLocked ? 'flex-end' : 'space-between'}
      width="100%"
      mt="12px"
    >
      {!disabled && !isPoLocked && (
        <Button data-cy={`add-more-materials-${index}`} onClick={() => addEmptyOrderMaterials()} type="link">
          Add More Materials
        </Button>
      )}
      {!hideTotal && (
        <OrderDeliveryCostsTotal
          index={index}
          onChange={onChange}
          value={value}
          orderMaterialsTotalCost={orderMaterialsTotalCost}
          deliveryTotalCost={deliveryTotalCost}
          hideAddTotal={hideAddTotal}
          isPoLocked={isPoLocked}
          disabled={disabled}
          costsDisabled={costsDisabled}
          roundingPrecision={roundingPrecision}
          inputsProps={inputsProps}
          deliveryChargesUnits={deliveryChargesUnits}
          onChangeChargesUnit={onChangeChargesUnit}
          deliveryName={deliveryName}
        />
      )}
    </FlexBoxX>
  )
}
