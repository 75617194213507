import React from 'react'

import { TableInstance } from 'react-table'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Button, Spin } from 'antd'

import { Box, FlexBoxY } from 'common/components/boxes'
import { PsqlTableHeader } from 'common/components/PsqlTable/header'
import { usePsqlTable } from 'common/components/PsqlTable/psql_table_provider'

export interface PsqlTableProps<Data = unknown> extends TableInstance {
  isLoading?: boolean
  hasMore?: boolean
  onLoadMore?: () => void
  onClickRow?: (params: { row: Data; rowIndex: number }) => void
}

const TableWrapper = styled(FlexBoxY)`
  align-items: flex-start;
  justify-content: flex-start;
  background: white;
  width: min-content;
  min-width: 100%;

  table {
    width: 100%;
  }

  .show-on-hover-row {
    visibility: hidden;
  }

  table tbody tr:hover .show-on-hover-row {
    visibility: visible;
  }
`

const Tr = styled('tr')<{ withHover: boolean }>`
  border-bottom: 1px solid;
  height: 40px;
  border-color: ${({ theme }) => theme.colors['gray-3']};

  ${(props) =>
    props.withHover &&
    css`
      &:hover {
        background: ${props.theme.colors['gray-2']};
        cursor: pointer;
      }
    `}

  td {
    padding: 16px 8px;
  }
`

export function PsqlTable(props: PsqlTableProps) {
  const { isLoading, hasMore, onLoadMore, onClickRow } = props

  const [loadingMore, setLoadingMore] = React.useState(false)

  const { getTableProps, headerGroups, getTableBodyProps, sort, prepareRow, rows, data, localStorageKey } =
    usePsqlTable()

  React.useEffect(() => {
    setLoadingMore(false)
  }, [data.length])

  if (isLoading) {
    return (
      <Spin
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      />
    )
  }

  return (
    <Box width="100%" height="100%" overflow="auto" position="relative">
      <TableWrapper>
        <table style={{ tableLayout: 'auto' }} {...getTableProps()}>
          <thead>
            <tr>
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {headerGroups[0]?.headers.map((column: any, index) => (
                <PsqlTableHeader
                  key={index}
                  {...column.getHeaderProps()}
                  sort={
                    sort && {
                      enabled: !!column.sortBy,
                      active: sort.field === column.sortBy,
                      onChange: (direction) => sort.onChange({ field: column.sortBy, direction: direction }),
                      default: {
                        direction: sort.direction,
                        field: sort.field,
                      },
                    }
                  }
                  style={{
                    width: column.width ?? 'auto',
                    maxWidth: column.width ?? 'auto',
                    minWidth: column.width ?? 'auto',
                  }}
                >
                  {column.render('Header', { localStorageKey })}
                </PsqlTableHeader>
              ))}
            </tr>
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)

              return (
                <Tr
                  key={row.id}
                  withHover={!!onClickRow}
                  onClick={() => onClickRow?.({ row: row.original, rowIndex: row.index })}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, cellIndex) => {
                    const ellipsisStyle = cell.column.ellipsis ? { overflow: 'hidden', textOverflow: 'ellipsis' } : {}

                    return (
                      <td key={cellIndex} {...cell.getCellProps()} style={{ ...ellipsisStyle }}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </Tr>
              )
            })}
          </tbody>
        </table>
      </TableWrapper>
      {hasMore && onLoadMore && (
        <Box display="flex" justifyContent="center" flex={0} py={16} bg="white" position="sticky" left={0} right={0}>
          <Button
            type="link"
            loading={loadingMore}
            onClick={() => {
              setLoadingMore(true)
              onLoadMore()
            }}
          >
            Load More
          </Button>
        </Box>
      )}
    </Box>
  )
}
