import React from 'react'

import { flatten } from 'lodash'

import styled from '@emotion/styled'

import { Form } from 'antd'

import { observer } from 'mobx-react-lite'

import { TableStyled } from 'common/components/EditableTable'
import { currencyFormatter } from 'common/helpers/formatters'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'

const getAllDeliveriesExtraCosts = (deliveries) => {
  const extraCosts = deliveries.reduce(
    (acc, delivery) => {
      acc.discountValue += Number(delivery.discount_value)
      acc.shippingValue += Number(delivery.shipping_value)
      acc.otherValue += Number(delivery.other_value)
      acc.taxValue += Number(delivery.tax_value)
      return acc
    },
    {
      discountValue: 0,
      shippingValue: 0,
      otherValue: 0,
      taxValue: 0,
    },
  )

  return extraCosts
}

const getDeliveriesCostByOrder = (orderId, deliveries, field) => {
  const orderDeliveries = deliveries.filter((delivery) => delivery.order.id === orderId)
  const value = orderDeliveries.reduce((acc, delivery) => acc + Number(delivery[field]), 0)

  if (!value) {
    return null
  }

  const orderNumber = orderDeliveries[0]?.order?.order_number

  return {
    orderId,
    orderNumber,
    orderedCost: value,
  }
}

const Foo = styled(TableStyled)`
  .ant-table-cell {
    background: #fdfdfd;
  }
`

export const ExtraCosts = observer(() => {
  const { invoiceStore, companySettingStore, userStore } = useStores()

  const { form, taxLineItemsEnabled } = useInvoice()

  const taxAmountField = Form.useWatch('taxAmount', form) || 0
  const shippingCostField = Form.useWatch('shippingCost', form) || 0
  const discountAmountField = Form.useWatch('discountAmount', form) || 0
  const otherCostsField = Form.useWatch('otherCosts', form) || 0

  const allDeliveries = React.useMemo(
    () =>
      flatten(
        invoiceStore.selectedOrders?.map(({ order }) => order.deliveries.map((delivery) => ({ ...delivery, order }))),
      ),
    [invoiceStore.selectedOrders?.length],
  )

  const { discountValue, shippingValue, otherValue, taxValue } = React.useMemo(
    () => getAllDeliveriesExtraCosts(allDeliveries),
    [allDeliveries.length],
  )

  const ordersIds = invoiceStore.selectedOrders?.map(({ order }) => order.id)

  const dataSource = [
    ...(Number(taxAmountField + taxValue) !== 0 && !taxLineItemsEnabled
      ? [
          {
            description: 'Tax',
            invoicedCost: taxAmountField,
            orderedCost: taxValue,
            orders: ordersIds.map((orderId) => getDeliveriesCostByOrder(orderId, allDeliveries, 'tax_value')),
          },
        ]
      : []),
    ...(Number(shippingCostField + shippingValue) !== 0
      ? [
          {
            description: 'Shipping',
            invoicedCost: shippingCostField,
            orderedCost: shippingValue,
            orders: ordersIds.map((orderId) => getDeliveriesCostByOrder(orderId, allDeliveries, 'shipping_value')),
          },
        ]
      : []),
    ...(Number(discountAmountField + discountValue) !== 0
      ? [
          {
            description: 'Discount',
            invoicedCost: discountAmountField,
            orderedCost: discountValue,
            orders: ordersIds.map((orderId) => getDeliveriesCostByOrder(orderId, allDeliveries, 'discount_value')),
          },
        ]
      : []),
    ...(Number(otherCostsField + otherValue) !== 0
      ? [
          {
            description: 'Other',
            invoicedCost: otherCostsField,
            orderedCost: otherValue,
            orders: ordersIds.map((orderId) => getDeliveriesCostByOrder(orderId, allDeliveries, 'other_value')),
          },
        ]
      : []),
  ]

  if (!dataSource.length) {
    return null
  }

  const { company_attributes = [] } = companySettingStore.companyMaterialConfiguration
  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const canUseCostCode = userStore.canUseCostCode && company_attributes.includes('cost_code_id')
  const retainageAmountEnabled = useFlag('retainage_v1')
  const independentPhaseCodeEnabled = userStore.canUseCostCode && costCodeSettings?.independent_phase_codes_enabled

  return (
    <Foo
      rowKey="description"
      columns={[
        {
          dataIndex: 'description',
        },
        {
          dataIndex: 'invoicedCost',
          width: 100,
          align: 'right',
          render: (invoicedCost = 0) => currencyFormatter(invoicedCost, 2),
        },
        {
          dataIndex: 'ext_cost',
          width: 100,
        },
        ...(taxLineItemsEnabled
          ? [
              {
                dataIndex: 'tax',
                width: 100,
              },
            ]
          : []),
        ...(canUseCostCode
          ? [
              {
                dataIndex: 'cost_code',
                width: 200,
              },
            ]
          : []),
        ...(independentPhaseCodeEnabled
          ? [
              {
                dataIndex: 'phase_code',
                width: 200,
              },
            ]
          : []),
        ...(retainageAmountEnabled
          ? [
              {
                dataIndex: 'retainage',
                width: 190,
              },
            ]
          : []),
      ]}
      dataSource={dataSource}
      showHeader={false}
      pagination={false}
      size="small"
    />
  )
})
