import React from 'react'

import styled from '@emotion/styled'

import PaperClipOutlined from '@ant-design/icons/PaperClipOutlined'
import { Typography } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { getDataTestId } from 'common/test-utils'

import { ShowOrderResponse } from 'contractor/server/orders'

import { useLeveling } from './context'
import { Td } from './table_footer'

export type AttachmentsProps = {
  orders: ShowOrderResponse[]
}

type FileItemProps = {
  name: string
  url: string
  size: number
}

const FileItem = ({ name, url }: FileItemProps) => {
  return (
    <FlexBoxX justifyContent="flex-start">
      <PaperClipOutlined />
      <Typography.Link ellipsis style={{ margin: '0 8px' }} href={url}>
        {name}
      </Typography.Link>
    </FlexBoxX>
  )
}

const Divider = styled.td`
  padding-top: 32px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: none !important;
`

const tdStyle = { textAlign: 'right', verticalAlign: 'baseline' }

export const Attachments = ({ orders }: AttachmentsProps) => {
  const { isSplitting } = useLeveling()

  // Calc all the columns table to make a divider row
  const dividerColSpan = 3 + 3 * orders.length

  const size = orders.length

  // Calc cell with to not break when attachment has a large name
  const width = (innerWidth - 402) / size

  return (
    <>
      <tr className="hide-on-print">
        <Divider colSpan={isSplitting ? dividerColSpan + size : dividerColSpan} className="divider" />
      </tr>

      <tr className="hide-on-print">
        <Td colSpan={3} style={tdStyle}>
          <Typography.Text strong>Quote Attachments</Typography.Text>
        </Td>
        {orders.map((order) => {
          const { quote } = order
          if (!quote) {
            return (
              <Td
                colSpan={isSplitting ? 4 : 3}
                key={`quote-${order.id}`}
                {...getDataTestId('empty-quote')}
                style={{ width, maxWidth: width }}
              />
            )
          }

          return (
            <Td
              key={`quote-${order.id}`}
              colSpan={isSplitting ? 4 : 3}
              style={{ ...tdStyle, width, maxWidth: width }}
              {...getDataTestId('quote')}
            >
              <FileItem name={quote?.filename} url={quote.url} size={size} />
            </Td>
          )
        })}
      </tr>

      <tr className="hide-on-print">
        <Td colSpan={3} style={tdStyle}>
          <Typography.Text strong>Vendor Attachments</Typography.Text>
        </Td>
        {orders.map((order) => {
          const { vendor_files } = order
          if (!vendor_files.length) {
            return (
              <Td
                colSpan={isSplitting ? 4 : 3}
                key={`vendor-files-${order.id}`}
                {...getDataTestId('empty-vendor-files')}
                style={{ width, maxWidth: width }}
              />
            )
          }

          return (
            <Td
              key={`vendor-files-${order.id}`}
              colSpan={isSplitting ? 4 : 3}
              style={{ ...tdStyle, width, maxWidth: width }}
              {...getDataTestId('vendor-files')}
            >
              {order.vendor_files.map((vendorFile) => (
                <FileItem key={vendorFile.key} name={vendorFile?.filename} url={vendorFile.url} size={size} />
              ))}
            </Td>
          )
        })}
      </tr>
    </>
  )
}
