import styled from '@emotion/styled/dist/emotion-styled.cjs'

import { Dropdown } from 'antd'

export const DropdownStyled = styled(Dropdown.Button)`
  .ant-btn-compact-item.ant-btn.ant-btn-compact-first-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-top: 2px;
    padding: 0;
  }
  .ant-btn-compact-item.ant-btn.ant-btn-compact-last-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-item-rtl) {
    margin-top: 1px;
  }
  .ant-btn-icon-only.ant-btn-sm {
    width: 21px;
    height: 21px;
    padding: 0px 0;
    font-size: 14px;
    border-radius: 2px;
  }
`
