import { createConsumer } from '@rails/actioncable'

const NOTIFICATION_CHANNEL = 'ApplicationCable::NotificationChannel'

export const TYPE_VENDOR = 'VENDOR'
export const TYPE_CONTRACTOR = 'CONTRACTOR'

const getActionCableUrl = () => document.querySelector('meta[name="action-cable-url"]')['content']

let consumer = null

export const subscribeNotificationChannel = (callback, type) => {
  if (!consumer) {
    consumer = createConsumer(`${getActionCableUrl()}?type=${type}`)
  }

  return consumer.subscriptions.create(NOTIFICATION_CHANNEL, {
    received(notification) {
      callback(notification)
    },
    connected() {
      console.log('connected')
    },
    disconnected() {
      console.log('disconnected')
    },
  })
}
