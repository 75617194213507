import _ from 'lodash'

import { USE_LOCK_PO_SESSION_ACTIONS_DEBOUNCE_TIME, UseLockPoType } from 'contractor/hooks/use-lock-po'
import { useStores } from 'contractor/hooks/use-stores'

type UseDirtyOrderActionProps = UseLockPoType['orderSession']

export type UseDirtyOrderActionType = {
  handleDirtyOrder: () => void
}

export const useDirtyOrderAction = (session: UseDirtyOrderActionProps): UseDirtyOrderActionType => {
  const { orderStore } = useStores()

  const handleRenewDebounced = _.debounce(() => {
    session?.onRenew()
  }, USE_LOCK_PO_SESSION_ACTIONS_DEBOUNCE_TIME)

  const handleDirtyOrder = () => {
    orderStore.setOrderDirty()
    handleRenewDebounced()
  }

  return { handleDirtyOrder }
}
