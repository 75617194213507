import React from 'react'

import * as XLSX from 'xlsx'

import { message, Space, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { BoxProps } from 'common/components/boxes'
import { noticeError } from 'common/helpers/new_relic'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'
import { IntegrationsSourceSystem } from 'common/server/server_types'

import { IntegrationIcons } from 'contractor/components/IntegrationIcons'
import { useStores } from 'contractor/hooks/use-stores'

import { makeFoundationDetailFileItems, makeFoundationHeaderFile } from './helpers'

type TCMSyncStatusDetailedProps = {
  isSynced: boolean
  onClick?: () => Promise<void>
} & BoxProps

const style = { fontSize: 14, fontWeight: 400 }

/*
  The task with the configuration params settings:
  https://www.notion.so/subbase/TCM-CSV-Export-Full-Ticket-fcac111f67a2435daf157b6834a31aed
*/
export const FoundationSyncStatusDetailed = observer<TCMSyncStatusDetailedProps>(({ isSynced, onClick }) => {
  const { orderStore, userStore } = useStores()

  const currentOrder = orderStore.selectedOrder

  const downloadData = () => {
    try {
      const companyVendorName =
        currentOrder?.company_vendor?.external_vendor_id ||
        currentOrder?.company_vendor?.vendor?.name ||
        currentOrder?.company_vendor?.vendor_name

      const downloadHeaders = [
        makeFoundationHeaderFile({
          order: {
            ...currentOrder,
            company_vendor: {
              ...currentOrder?.company_vendor,
              vendor: { ...currentOrder?.company_vendor?.vendor, name: companyVendorName },
            },
          },
        }),
      ]
      const downloadDetails = makeFoundationDetailFileItems({ order: currentOrder })

      /* generate worksheet from state */
      const wsHeaders = XLSX.utils.json_to_sheet(downloadHeaders)
      const wsDetails = XLSX.utils.json_to_sheet([...downloadDetails].filter(Boolean))

      /* create workbook and append worksheet */
      const wbHeaders = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wbHeaders, wsHeaders, 'Data')
      const wbDetails = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wbDetails, wsDetails, 'Data')

      XLSX.writeFile(wbHeaders, 'FoundationPOHeaderImp.csv')
      XLSX.writeFile(wbDetails, 'FoundationPODetailImp.csv')

      onClick()
    } catch (error) {
      noticeError(error, { entry: 'foundation-order-detail-download' })
      message.error(`Unable to download file. Please contact ${SUPPORT_EMAIL_ADDRESS} for further assistance.`)
    }
  }

  if (!userStore.canSyncWithErp) {
    return null
  }

  return (
    <Tooltip
      title={isSynced ? 'CSV downloaded, user must manually upload to Foundation' : 'Click to download Foundation CSV'}
      placement="bottom"
    >
      <Space
        onClick={() => {
          downloadData()
        }}
        style={{ cursor: 'pointer' }}
      >
        <IntegrationIcons type={IntegrationsSourceSystem.FOUNDATION_HOSTED} style={{ width: 32, height: 32 }} />
        {isSynced ? (
          <Typography.Text type="secondary" style={style}>
            Synced
          </Typography.Text>
        ) : (
          <Typography.Text type="danger" style={style}>
            Not Synced
          </Typography.Text>
        )}
      </Space>
    </Tooltip>
  )
})
