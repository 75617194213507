import React from 'react'

import { DataViewerProps } from 'react-spreadsheet'

import { FlexBoxX } from 'common/components/boxes'
import { CostCode } from 'common/server/cost_codes/cost_codes'

type CostCodeViewerCellProps = {
  costCode?: CostCode
  costCodeSettings: {
    required: boolean
    project_filtering_enabled: boolean
    class_enabled: boolean
    phase_code_enabled: boolean
    independent_phase_codes_enabled: boolean
  }
} & DataViewerProps

export const CostCodeViewerCell = ({ costCodeSettings, cell }: CostCodeViewerCellProps) => {
  const costCode = cell.value

  const showPhaseCode =
    costCodeSettings?.phase_code_enabled && !costCodeSettings.independent_phase_codes_enabled && !!costCode
  const canShowClass = costCodeSettings?.class_enabled && !!costCode

  return (
    <FlexBoxX alignItems="center" justifyContent="space-between" ml={4}>
      {[
        showPhaseCode ? costCode?.phase_code || 'N/A' : '',
        costCode?.code,
        canShowClass ? costCode?.clazz || 'N/A' : '',
      ]
        .filter((item) => !!item)
        .join(' / ')}
    </FlexBoxX>
  )
}
