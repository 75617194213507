import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { usePsqlTable } from 'common/components/PsqlTable/psql_table_provider'

import { ExportButton } from 'contractor/components/ExportButton/export_button'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

import { DownloadFoundation } from './download_foundation'
import { DownloadTCM } from './download_tcm'

export const OrderDownload = () => {
  const {
    orderStore: { listStore },
  } = useStores()
  const tcmCsvV2DownloadEnabled = useFlag('tcm_csv_v2_download')
  const foundationCsvDownloadEnabled = useFlag('foundation_csv_download')
  const { state } = usePsqlTable()
  return (
    <Box display="flex" flexDirection="column">
      <ExportButton hiddenColumns={state?.hiddenColumns} onExport={listStore.exportRecords} title="Download Orders" />

      <Box p={12}>
        {tcmCsvV2DownloadEnabled && (
          <Box mt="4" display="flex" alignItems="center" justifyContent="space-between">
            <Typography.Title level={5}>Download TCM</Typography.Title>

            <DownloadTCM />
          </Box>
        )}

        {foundationCsvDownloadEnabled && (
          <Box mt="16" display="flex" alignItems="center" justifyContent="space-between">
            <Typography.Title level={5}>Download Foundation</Typography.Title>

            <DownloadFoundation />
          </Box>
        )}
      </Box>
    </Box>
  )
}
