import React, { useCallback, useEffect } from 'react'

import { ThunderboltFilled } from '@ant-design/icons'
import { Button, Skeleton } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { InfiniteScrollDebounceSelect, InfiniteScrollDebounceSelectProps } from 'common/components/DebounceSelect'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { CompanyVendorContact } from 'contractor/server/company_vendor_contacts'

import { makeContactOption } from './helpers'

type Option = {
  label: string
  value: string
  contact: CompanyVendorContact
}

type SelectCompanyVendorContactProps = {
  onClickDiscover?: () => void
  hideDiscoverVendors?: boolean
  onChange?: (options: Nullable<Option[]>) => void
  defaultVendorId?: string // Used to filter contacts by company vendor
} & Partial<Omit<InfiniteScrollDebounceSelectProps, 'onChange'>>

export const SelectCompanyVendorContact = observer<SelectCompanyVendorContactProps>(
  ({ onClickDiscover, hideDiscoverVendors, onChange, defaultVendorId, ...props }) => {
    const { companyVendorStore } = useStores()

    const { companyVendorContactListStore } = companyVendorStore

    const applyVendorFilter = useCallback((companyVendorId: Nullable<string>) => {
      companyVendorContactListStore.searchState.per_page = 100
      companyVendorContactListStore.setFilter('company_vendor_id', companyVendorId, true, true)
    }, [])

    const { isLoading, data: initialOptions = [] } = useQuery(async () => {
      applyVendorFilter(defaultVendorId || null)
      companyVendorContactListStore.searchState.page = 1

      const data = await companyVendorContactListStore.fetchRecords()

      return toJS(data).map(makeContactOption)
    }, [defaultVendorId, applyVendorFilter])

    const fetchOptions = useCallback(
      async (search: string, page: number) => {
        companyVendorContactListStore.searchState.page = page
        applyVendorFilter(defaultVendorId || null)

        const data = await companyVendorContactListStore.setSearch(search)
        const initialOptions = toJS(data).map(makeContactOption)

        return { data: initialOptions, hasMore: companyVendorContactListStore.hasMore }
      },
      [defaultVendorId, applyVendorFilter],
    )

    // Clear filter when unmounting
    useEffect(() => {
      return () => {
        companyVendorContactListStore.resetState()
      }
    }, [])

    if (isLoading) {
      return <Skeleton.Input block active />
    }

    return (
      <InfiniteScrollDebounceSelect
        initialPage={2} // Start fetching on page 2, cause we do a preload on page 1
        threshold={200}
        labelInValue
        loading={isLoading}
        notFoundContent="No remaining vendors"
        initialOptions={initialOptions}
        fetchOptions={fetchOptions}
        dropdownRender={(menu) => (
          <>
            {menu}
            {!hideDiscoverVendors && (
              <Button
                style={{ margin: 8, width: 'calc(100% - 16px)' }}
                onClick={onClickDiscover}
                icon={<ThunderboltFilled style={{ color: '#0192CF' }} />}
              >
                Discover more vendors
              </Button>
            )}
          </>
        )}
        tagRender={() => null}
        autoClearSearchValue={false}
        {...props}
        onSelect={(_, option: Option) => {
          const currentValue = props?.value || []
          onChange?.([...currentValue, option])
        }}
        onDeselect={(_, option: Option) => {
          const currentValue = props?.value || []
          onChange?.(currentValue.filter((item) => item?.value !== option?.value))
        }}
        style={{ width: '100%', ...props?.style }}
      />
    )
  },
)
