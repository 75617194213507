import React from 'react'

import { DatePicker, Drawer, Form, Select, Space } from 'antd'
import { FormInstance } from 'antd/lib/form'

import { observer } from 'mobx-react-lite'

import { InputCurrency } from 'common/components/InputCurrency'

type PriceIncreaseDrawerProps = {
  visible: boolean
  onClose: () => void
  title: string
  form: FormInstance
}

export const PriceIncreaseDrawer = observer<PriceIncreaseDrawerProps>((props: PriceIncreaseDrawerProps) => {
  const { onClose, visible, title, form } = props

  return (
    <Drawer
      style={{ padding: 0, paddingLeft: 16, paddingRight: 16 }}
      title={title}
      placement="bottom"
      closable={true}
      onClose={onClose}
      open={visible}
      height={150}
    >
      <Form form={form}>
        <Space>
          <Space style={{ marginRight: 8 }}>
            <Form.Item name="priceIncrease" style={{ marginBottom: 0, minWidth: 180 }}>
              <InputCurrency placeholder="Value" />
            </Form.Item>
          </Space>

          <Space style={{ marginRight: 8 }}>
            <Form.Item name="dateRange" style={{ marginBottom: 0, minWidth: 180 }}>
              <DatePicker placeholder="Date Range" style={{ width: '100%' }} />
            </Form.Item>
          </Space>

          <Space style={{ marginRight: 8 }}>
            <Form.Item name="timePeriod" style={{ marginBottom: 0, minWidth: 180 }}>
              <Select placeholder="Time period" style={{ width: '100%' }}>
                <Select.Option value="1">1</Select.Option>
                <Select.Option value="2">2</Select.Option>
                <Select.Option value="3">3</Select.Option>
                <Select.Option value="4">4</Select.Option>
              </Select>
            </Form.Item>
          </Space>
        </Space>
      </Form>
    </Drawer>
  )
})
