import React from 'react'

import styled from '@emotion/styled'

import { LockOutlined, ProjectOutlined, SearchOutlined, ShopOutlined } from '@ant-design/icons'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import { AutoComplete, AutoCompleteProps, Input, Spin, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import OrderStateTag from 'common/components/statuses/order_state'
import { useDebounce } from 'common/hooks/use-debounce'
import theme from 'common/styles/theme'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import { ConsolidatedOrders } from 'contractor/server/orders/consolidated_order'

const StdInput = styled(Input)`
  color: ${({ theme }) => theme.colors['gray-9']};

  & > input {
    margin-left: 0.25rem;
  }
`

function DefaultNotFound() {
  return <Box p={4}>No orders found</Box>
}

function renderItem(item: ConsolidatedOrders.Order, orderBlockingEnabled?: boolean) {
  return {
    value: item.order_id,
    label: (
      <Box display="flex" flexDirection="column" gridGap={4} width="100%" py={8}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography.Text style={{ fontWeight: 'semi-bold' }} ellipsis>
            {item.order_number}: {item.name}
          </Typography.Text>
        </Box>

        {item.commitment_name && (
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography.Text style={{ fontWeight: 'semi-bold' }} ellipsis>
              Commitment: {item.commitment_name}
            </Typography.Text>
          </Box>
        )}

        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center">
            <ProjectOutlined style={{ color: theme.colors['gray-7'] }} />
            <Typography.Text type="secondary" style={{ margin: '0 4px' }} ellipsis={{ tooltip: item?.project_name }}>
              {item.project_number && `${item.project_number}: `}
              {item?.project_name}
            </Typography.Text>
          </Box>
          {item.vendor_name && (
            <Box display="flex" alignItems="center" ml="8">
              <ShopOutlined style={{ color: theme.colors['gray-7'] }} />
              <Typography.Text type="secondary" style={{ margin: '0 4px' }}>
                {item.vendor_name}
              </Typography.Text>
            </Box>
          )}
        </Box>

        <OrderStateTag state={item.state} sub_state={item.sub_state} theme={theme} fontSize={12} />

        {item.is_blocked_by && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '0.5rem',
              gap: '0.5rem',
            }}
          >
            {orderBlockingEnabled && <LockOutlined />}
            <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
              This order is being edited by {item.is_blocked_by}
            </Typography.Paragraph>
          </div>
        )}
      </Box>
    ),
    disabled: orderBlockingEnabled && !!item.is_blocked_by,
  }
}

export type OrdersPsqlAutocompleteProps = {
  onSelect: (item: ConsolidatedOrders.Order) => void
  size?: 'default' | 'large'
  footerComponent: React.ReactNode
  filters?: ConsolidatedOrders.StandardIndexRequest
} & AutoCompleteProps

function Autocomplete(props: OrdersPsqlAutocompleteProps) {
  const { onSelect, size = 'default', footerComponent, filters, ...rest } = props

  const {
    orderStore: { listStore },
  } = useStores()
  const orderBlockingEnabled = useFlag('order_blocking')

  const [keyword, setKeyword] = React.useState<string>('')
  const [isFetching, setIsFetching] = React.useState<boolean>(false)
  const debouncedValue = useDebounce<string>(keyword, 500)
  const [records, setRecords] = React.useState<ConsolidatedOrders.Order[]>([])
  const [searchFilters, setSearchFilters] = React.useState<ConsolidatedOrders.StandardIndexRequest>({
    sort: '',
    sort_direction: undefined,
    filters: {},
    search: '',
    page: 1,
    per_page: 10,
    enable_session_info: false,
    ...filters,
  })

  React.useEffect(() => {
    listStore.setEnableSessionInfo(true)
    const fetchData = async () => {
      setIsFetching(true)
      const response = (await listStore.index(searchFilters)).data.records
      setRecords(response)
      setIsFetching(false)
    }

    fetchData()

    return () => {
      listStore.setEnableSessionInfo(false)
    }
  }, [filters, debouncedValue])

  React.useEffect(() => {
    setSearchFilters({ ...searchFilters, search: keyword })
  }, [keyword])

  const options = React.useMemo(() => {
    const options: Array<{ label: React.ReactNode; value: string; disabled?: boolean }> = records.map((item) =>
      renderItem(item, orderBlockingEnabled),
    )

    if (footerComponent && options.length > 0) {
      options.push({
        label: (
          <Box
            bg={theme.colors['gray-3']}
            textAlign="center"
            borderRadius={theme.radii.md}
            style={{ cursor: 'default' }}
          >
            {footerComponent}
          </Box>
        ),
        value: '',
        disabled: true,
      })
    }

    return options
  }, [records, keyword, debouncedValue, records.length, isFetching])

  function handleSelect(value: string) {
    if (!value) return

    const item = records.find((item) => item.order_id === value)

    if (!item) return

    item.id = item.order_id

    onSelect(item)
    setKeyword('')
  }

  return (
    <AutoComplete
      options={options}
      onSelect={handleSelect}
      searchValue={keyword}
      data-cy="orders-autocomplete"
      onSearch={setKeyword}
      dropdownMatchSelectWidth={size === 'default' && 350}
      style={{ width: '100%' }}
      allowClear
      notFoundContent={footerComponent || DefaultNotFound}
      value={keyword}
      onChange={setKeyword}
      {...rest}
    >
      <StdInput
        aria-autocomplete="none"
        placeholder="Order"
        prefix={
          isFetching ? <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} /> : <SearchOutlined />
        }
      />
    </AutoComplete>
  )
}

export const OrdersPsqlAutocomplete = observer(Autocomplete)
