import React from 'react'

import { BrowserRouter } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'

import { ThemeProvider } from '@emotion/react'

import { withErrorBoundary } from 'common/components/ErrorBoundary/error_boundary'
import { GlobalStyle } from 'common/components/GlobalStyle'
import theme from 'common/styles/theme'

/* Load axios to set headers configuration */
import 'common/data-access/axios'

type ProvidersProps = {
  children: React.ReactNode
}

const Providers = ({ children }: ProvidersProps) => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <LastLocationProvider>
          <GlobalStyle />
          {children}
        </LastLocationProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export const AllProviders = withErrorBoundary(Providers)
