import { RefObject, useEffect } from 'react'

const classesToIgnore = [
  'rc-virtual-list',
  'ant-picker-header',
  'ant-picker-header-next-btn',
  'ant-picker-header-super-next-btn',
  'ant-picker-header-prev-btn',
  'ant-picker-header-super-prev-btn',
  'ant-select-dropdown',
  'ant-select-arrow',
  'ant-select-selector',
  'ant-input-number-handler-wrap',
  'ant-input-number-input-wrap',
  'ant-input-number-group-wrapper',
]

export const useClickOutside = <T extends HTMLElement = HTMLElement>(
  refs: RefObject<T>[] = [],
  handler: (event) => void,
) => {
  useEffect(() => {
    const listener = (event) => {
      let isInsideSelect = false
      let target = event.target

      // Prevent clicking in Antd selects/datepickers and dispatch the handler
      while (target) {
        if (classesToIgnore.some((className) => target.classList.contains(className))) {
          isInsideSelect = true
          break
        }

        target = target.parentElement
      }

      if (refs.some((ref) => ref?.current?.contains?.(event.target)) || isInsideSelect) {
        return
      }
      handler(event)
    }

    document.addEventListener('click', listener)
    return () => {
      document.removeEventListener('click', listener)
    }
  }, [...refs, handler])
}
