import React from 'react'

import { InputNumber } from 'antd'

import { Box } from 'common/components/boxes'

import { CommitmentMaterial } from 'contractor/server/commitments'

export type QuantityColumnProps = {
  commitmentMaterial: CommitmentMaterial
  index: number
  disabled?: boolean
  onChange: (value: CommitmentMaterial, changes: CommitmentMaterial) => void
}

export const QuantityColumn = (props: QuantityColumnProps) => {
  const { commitmentMaterial, disabled, onChange, index } = props
  const handleQuantityChange = (value: string) => {
    onChange(commitmentMaterial, { ...commitmentMaterial, quantity: value })
  }

  return (
    <Box width="100%">
      <InputNumber
        data-cy={`quantity-${index}`}
        value={commitmentMaterial.quantity || 0}
        disabled={disabled}
        onChange={handleQuantityChange}
        style={{ width: '100%', minWidth: 70 }}
        inputMode="numeric"
        className="input-quantity"
      />
    </Box>
  )
}
