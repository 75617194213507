export * from './deliveries'
export * from './utils'

import { search_key, index, show, update } from './deliveries'

export const deliveryRequests = {
  search_key,
  index,
  show,
  update,
}
