import React from 'react'

import qs from 'qs'

import { Button, Form, Popconfirm } from 'antd'

import { OrderStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

import { ActionsProps } from './actions'

type SaveDraftButtonProps = {
  isDraft: boolean
  isApproved: boolean
  disabled?: boolean
  updateDisabled?: boolean
} & Pick<ActionsProps, 'isLoading' | 'isRequest' | 'orderState' | 'onSaveDraft' | 'onSaveDraftAndApprove'>

export const SaveDraftButton = (props: SaveDraftButtonProps) => {
  const {
    isLoading,
    isRequest,
    isDraft,
    isApproved,
    onSaveDraft,
    onSaveDraftAndApprove,
    orderState,
    disabled,
    updateDisabled,
  } = props
  const { userStore, companySettingStore } = useStores()
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

  const showApproveButton =
    userStore.canApproveOrderRequest &&
    isRequest &&
    companySettingStore?.otherSettings?.approval_state_enabled &&
    isDraft

  if (isRequest && 'duplicate' in queryParams) {
    return (
      <Button
        data-cy="update-request"
        type="primary"
        loading={isLoading}
        onClick={() => onSaveDraft({})}
        disabled={disabled}
      >
        Submit
      </Button>
    )
  }

  if (showApproveButton) {
    return (
      <>
        <Form.Item style={{ margin: 0, marginRight: 8 }}>
          {isDraft && userStore.canApproveOrderRequest ? (
            <Popconfirm
              placement="bottomRight"
              title="You are about to update this request. Do you want to approve it as well?"
              onCancel={() => onSaveDraft({})}
              onConfirm={onSaveDraftAndApprove}
              okText="Update and approve"
              cancelText="Only update"
            >
              <Button data-cy="update-request" type="default" loading={isLoading} disabled={updateDisabled}>
                Update Request
              </Button>
            </Popconfirm>
          ) : (
            <Button
              data-cy="update-request"
              type="default"
              loading={isLoading}
              onClick={() => onSaveDraft({})}
              disabled={updateDisabled}
            >
              Update Request
            </Button>
          )}
        </Form.Item>

        {userStore.canApproveOrderRequest && (
          <Form.Item style={{ margin: 0 }}>
            <Button
              data-cy="approve-action"
              type="default"
              loading={isLoading}
              onClick={onSaveDraftAndApprove}
              disabled={!userStore.canApproveOrderRequest && disabled}
            >
              Approve
            </Button>
          </Form.Item>
        )}
      </>
    )
  }

  if (!userStore.canCreateDrafts) {
    return null
  }

  if (userStore.cannotEditCostCode && userStore.cannotSendAndUpdateOrders && orderState == OrderStates.APPROVED) {
    return null
  }

  return (
    <Form.Item style={{ margin: 0 }}>
      <Button
        data-cy="save-draft"
        type="default"
        loading={isLoading}
        disabled={updateDisabled}
        onClick={() => onSaveDraft({})}
      >
        {isRequest || isApproved ? 'Update Request' : 'Save Draft'}
      </Button>
    </Form.Item>
  )
}
