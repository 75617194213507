import React from 'react'

import moment from 'moment'

import { Timeline, Avatar, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { useQuery } from 'common/hooks/use-query'
import { InvoiceInboxIssueActionType } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

import { ErrorState } from './error_state'
import { Filename } from './filename'
import { LoadingState } from './loading_state'
import { ReportIssueHistoryProps } from './report_issue_history'
import { SubBaseAction } from './subbase_action'
import { SubBaseReviewAction } from './subbase_review_action'

type ContentProps = Pick<ReportIssueHistoryProps, 'invoiceInboxId' | 'filters'>

export const Content = ({ invoiceInboxId, filters }: ContentProps) => {
  const { invoiceInboxStore } = useStores()

  const { isLoading, isError, data } = useQuery(
    () => invoiceInboxStore.getIssueHistory(invoiceInboxId, filters),
    [invoiceInboxId, filters],
  )

  if (isLoading) {
    return <LoadingState />
  }

  if (isError) {
    return <ErrorState />
  }

  return (
    <Timeline style={{ paddingLeft: 11, paddingTop: 10, width: '100%' }}>
      {data.map((history) => {
        const authorName = history?.author?.name || 'User'
        const createdDate = moment(new Date(history.created_at)).format('MMM D, h:mm A')
        const reviewDate = moment(new Date(history.created_at)).add(1, 'minutes').format('MMM D, h:mm A')

        if (history.action_type === InvoiceInboxIssueActionType.REPORTED && !!history?.author) {
          return (
            <React.Fragment key={history.created_at}>
              <Timeline.Item dot={<Avatar>{authorName[0]}</Avatar>}>
                <Box display="flex" flexDirection="column" pl="4px">
                  <Typography.Text>
                    <strong>{authorName}</strong> reported an issue on {createdDate}.
                  </Typography.Text>
                  <Typography.Paragraph style={{ margin: 0 }}>
                    <pre>{history.issue_message}</pre>
                  </Typography.Paragraph>

                  {history.invoice_inbox_attachments.map((attachment) => (
                    <Filename key={attachment.id} attachment={attachment} />
                  ))}
                </Box>
              </Timeline.Item>

              {/* Always shows a next history line to warn the user that SubBase is reviewing */}
              <SubBaseReviewAction reviewDate={reviewDate} />
            </React.Fragment>
          )
        }

        if (history.action_type === InvoiceInboxIssueActionType.REPORTED && !history?.author) {
          return (
            <React.Fragment key={history.created_at}>
              <SubBaseAction attachments={history.invoice_inbox_attachments} issueMessage={history.issue_message}>
                <strong>SubBase</strong> caught an issue on {createdDate}.
              </SubBaseAction>

              {/* Always shows a next history line to warn the user that SubBase is reviewing */}
              <SubBaseReviewAction reviewDate={reviewDate} />
            </React.Fragment>
          )
        }

        if (history.action_type === InvoiceInboxIssueActionType.RESOLVED) {
          return (
            <SubBaseAction attachments={history.invoice_inbox_attachments} key={history.created_at}>
              <strong>SubBase</strong> resolved the issue on the file below on {createdDate}.
            </SubBaseAction>
          )
        }

        if (history.action_type === InvoiceInboxIssueActionType.FAILED_DUE_TO_UPDATE) {
          return (
            <SubBaseAction attachments={history.invoice_inbox_attachments} key={history.created_at}>
              <strong>SubBase</strong> wasn&apos;t able to resolve the issue on the file below because an user edited
              the invoice or the file on {createdDate}.
            </SubBaseAction>
          )
        }

        if (history.action_type === InvoiceInboxIssueActionType.UNRESOLVED) {
          return (
            <SubBaseAction attachments={history.invoice_inbox_attachments} key={history.created_at}>
              <strong>SubBase</strong> wasn&apos;t able to resolve the issue on the file below on {createdDate}. Please
              enter in contact with our support for further assistance.
            </SubBaseAction>
          )
        }
      })}
    </Timeline>
  )
}
