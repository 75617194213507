import React, { useState } from 'react'

import moment from 'moment'

import styled from '@emotion/styled'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Form, Select, Input, Button, Divider, message, Checkbox, Typography, Tooltip, Badge } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import DateTimePicker from 'common/components/date_time_picker'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { InputCurrency } from 'common/components/InputCurrency'
import { useLocalStorage } from 'common/hooks/use-local-storage'
import { useQuery } from 'common/hooks/use-query'
import { OrderMaterial } from 'common/server/orders'

import { SelectProject } from 'contractor/components/SelectProject'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import { OrderCreateResponse } from 'contractor/server/orders'

import { useInvoice } from '../../context'

export type CreateQuickPOProps = {
  onCancel: () => void
  onCreated: (order: OrderCreateResponse) => void
  initialValues?: { grandTotal?: number; orderNumber?: string; companyVendorId?: string }
}

const TotalFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    width: 100%;
  }
`

export const CreateQuickPO = observer<CreateQuickPOProps, DrawerRef>(
  ({ onCancel, onCreated, initialValues }, ref) => {
    const { orderStore, companyVendorStore, projectStore, invoiceStore } = useStores()

    const { isLoading: isLoadingProjects } = useQuery(projectStore.maybeIndexProjects)
    const { isLoading: isLoadingCompanyVendors } = useQuery(companyVendorStore.maybeGetAllCompanyVendors)

    const [localStorage, setLocalStorage] = useLocalStorage<boolean>(
      '@subbase/invoice/create-materials-using-invoice',
      false,
    )

    const { calculatedGrandTotal } = useInvoice()

    const [form] = Form.useForm()

    const [isSubmitting, setSubmitting] = useState(false)
    const [createMaterialsUsingInvoice, toggleCreateMaterialsUsingInvoice] = useState(() => {
      if (localStorage) {
        form.setFieldValue('grandTotal', calculatedGrandTotal)
      }
      return localStorage
    })

    const extractedInvoiceDataEnabled = useFlag('extracted_invoice_data')

    const handleFinish = async (formValues) => {
      setSubmitting(true)
      try {
        const orderMaterials = invoiceStore.invoice?.invoice_materials?.map((invoiceMaterial) => {
          return {
            quantity: invoiceMaterial?.quantity_shipped,
            unit_cost: invoiceMaterial?.unit_price,
            cost_code_id: invoiceMaterial?.cost_code?.id,
            cost_code_phase_id: invoiceMaterial?.cost_code_phase_id,
            company_material: {
              description: invoiceMaterial?.description,
              cost_code_id: invoiceMaterial?.cost_code?.id,
              unit_id: invoiceMaterial?.unit?.id,
              unit_name: invoiceMaterial?.unit?.id ? undefined : invoiceMaterial?.uom,
            },
          }
        }) as unknown as OrderMaterial[]

        const common = createMaterialsUsingInvoice
          ? {
              order_materials: orderMaterials,
              other_value:
                Number(invoiceStore.invoice?.other_costs) > 0 ? invoiceStore.invoice?.other_costs : undefined,
              shipping_value:
                Number(invoiceStore.invoice?.shipping_cost) > 0 ? invoiceStore.invoice?.shipping_cost : undefined,
              tax_value: Number(invoiceStore.invoice?.tax_amount) > 0 ? invoiceStore.invoice?.tax_amount : undefined,
              discount_value:
                Number(invoiceStore.invoice?.discount_amount) > 0 ? invoiceStore.invoice?.discount_amount : undefined,
            }
          : { grand_total: formValues?.grandTotal }

        const [order] = await orderStore.createQuickPO({
          project_id: formValues?.projectId,
          company_vendor_id: formValues?.companyVendorId,
          order_package_name: formValues?.orderPackageName,
          order_number: formValues?.orderNumber,
          order_date: formValues?.orderDate,
          comment: formValues?.comment,
          ...common,
        })
        onCreated(order)
        message.success(
          <>
            Successfully Saved PO:{' '}
            <Typography.Link href={`/order/${order?.id}`} target="_blank">
              {order?.order_number}
            </Typography.Link>
          </>,
        )
      } catch (error) {
        if (error?.response?.data?.error) {
          message.error(error.response.data.error)
        } else {
          message.error('Unable to create the Quick PO')
        }
      } finally {
        setSubmitting(false)
      }
    }

    const handleToggleCreateMaterialsUsingInvoice = (event) => {
      toggleCreateMaterialsUsingInvoice(event.target.checked)
      setLocalStorage(event.target.checked)
      if (event.target.checked) {
        form.setFieldValue('grandTotal', calculatedGrandTotal)
      } else {
        form.setFieldValue('grandTotal', null)
      }
    }

    return (
      <Drawer title="Quick Create PO" ref={ref} bgGray width={378}>
        <Box width="100%" overflowY="auto" p={16}>
          <Form
            form={form}
            initialValues={{
              comment: 'PO Quick Created during Invoice Approval',
              orderDate: moment().startOf('day').add(12, 'hours').toISOString(),
              ...initialValues,
            }}
            style={{ width: '100%' }}
            onFinish={handleFinish}
            layout="vertical"
          >
            <Form.Item
              label="Project"
              name="projectId"
              rules={[{ required: true, message: 'Project is required!' }]}
              style={{ width: '100%' }}
            >
              <SelectProject loading={isLoadingProjects} />
            </Form.Item>

            <Form.Item
              label="Vendor"
              name="companyVendorId"
              rules={[{ required: true, message: 'Vendor is required!' }]}
              style={{ width: '100%' }}
              extra="Your vendor will not be notified about this order."
            >
              <Select
                placeholder="Select Vendor"
                loading={isLoadingCompanyVendors}
                options={companyVendorStore.companyVendors?.map((companyVendor) => ({
                  value: companyVendor.id,
                  label: companyVendor?.vendor?.name || companyVendor.vendor_name,
                }))}
                showSearch
                filterOption={(input, option) => {
                  const inputValue = input?.toLowerCase()
                  return option?.label?.toLowerCase()?.includes(inputValue)
                }}
                aria-autocomplete="none"
              />
            </Form.Item>

            <Form.Item
              label="Order Name"
              name="orderPackageName"
              rules={[{ required: true, message: 'Please add a name to the order' }]}
              style={{ width: '100%' }}
            >
              <Input
                style={{ width: '100%' }}
                placeholder="Order Name e.g. Fixtures, Tile, etc."
                aria-autocomplete="none"
              />
            </Form.Item>

            <Form.Item label="PO Number" name="orderNumber">
              <Input style={{ width: '100%' }} placeholder="PO # (optional)" aria-autocomplete="none" />
            </Form.Item>

            {extractedInvoiceDataEnabled && (
              <Box display="flex" alignItems="center" width="100%" mb={24}>
                <Checkbox onChange={handleToggleCreateMaterialsUsingInvoice} checked={createMaterialsUsingInvoice}>
                  Create Materials using Invoice
                </Checkbox>
                <Badge
                  count={
                    <Tooltip
                      placement="top"
                      title="Materials will be created in your database but marked as inactive so that they are not selectable for future orders. This will bypass any field requirements you have for your orders (e.g. unit cost or cost codes required)."
                    >
                      <QuestionCircleOutlined style={{ top: '-5px' }} />
                    </Tooltip>
                  }
                />
              </Box>
            )}

            <TotalFormItem label="Total" name="grandTotal">
              <InputCurrency style={{ width: '100%' }} disabled={createMaterialsUsingInvoice} />
            </TotalFormItem>

            <Form.Item
              label="Order Date"
              name="orderDate"
              tooltip="This will be used as both the order date and delivery date."
            >
              <DateTimePicker wrapperProps={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="Comments" name="comment">
              <Input.TextArea style={{ width: '100%', resize: 'none' }} rows={3} aria-autocomplete="none" />
            </Form.Item>

            <Divider />

            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Button onClick={onCancel} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button htmlType="submit" type="primary" loading={isSubmitting}>
                Create
              </Button>
            </Box>
          </Form>
        </Box>
      </Drawer>
    )
  },
  {
    forwardRef: true,
  },
)
