import React from 'react'

import { Spin } from 'antd'
import { SpinProps } from 'antd/lib/spin'

import { FlexBoxY, BoxProps } from 'common/components/boxes'

type LoadingProps = {
  size?: SpinProps['size']
  boxProps?: BoxProps
  spinProps?: SpinProps
}

export const Loading = ({ size, boxProps, spinProps }: LoadingProps) => (
  <FlexBoxY width="100%" height="100%" {...boxProps}>
    <Spin size={size || 'large'} {...spinProps} />
  </FlexBoxY>
)
