import React, { useRef } from 'react'

import { BrowserRouter } from 'react-router-dom'

import { ThemeProvider } from '@emotion/react'

import { Button } from 'antd'

import { DrawerRef } from 'common/components/Drawer'
import theme from 'common/styles/theme'

import { CreateMaterial } from 'contractor/pages/CompanyMaterials/Materials/create_material'

import { FooterProps } from './footer'

type CreateDetailedMaterialProps = FooterProps

export const CreateDetailedMaterial = (props: CreateDetailedMaterialProps) => {
  const { materialDescription = '', onSelect, publicOrderFormUrlExtension, companyAttributes, units, setIsOpen } = props

  const drawerCreateRef = useRef<DrawerRef>()

  return (
    <>
      <Button
        onClick={(e) => {
          e.preventDefault()
          drawerCreateRef.current.show()
          setIsOpen?.(false)
        }}
        data-cy="create-with-detail-material-button"
      >
        Create with detail
      </Button>

      {/* Need to add provider because autocomplete rendering outside of app  */}
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CreateMaterial
            onCancel={() => drawerCreateRef.current.close()}
            onSubmit={(response) => {
              drawerCreateRef.current.close()
              onSelect(response)
            }}
            initialValues={{ description: materialDescription }}
            hideNotification
            publicOrderFormUrlExtension={publicOrderFormUrlExtension}
            companyAttributes={companyAttributes}
            units={units}
            ref={drawerCreateRef}
          />
        </ThemeProvider>
      </BrowserRouter>
    </>
  )
}
