import React, { useRef, useState } from 'react'

import { observer } from 'mobx-react-lite'

import { Drawer, DrawerRef } from 'common/components/Drawer'
import { ErrorBoundary } from 'common/components/ErrorBoundary'

import { useStores } from 'contractor/hooks/use-stores'
import { CreateCompanyVendorContact } from 'contractor/pages/CompanyVendorContacts/MyVendors/Create'
import { CompanyVendorContact } from 'contractor/server/company_vendor_contacts'
import { IndexCompanyVendor } from 'contractor/server/company_vendors'

import { VendorProfileContent } from './vendor_profile_content'

export type VendorProfileProps = {
  vendorId: string
  companyVendor?: IndexCompanyVendor
  hideCreate?: boolean
  onSubmitAndAdd?: (newCompanyVendorContact: CompanyVendorContact) => void
  orderType?: OrderType
}

export const VendorProfile = observer<VendorProfileProps, DrawerRef>(
  (props, ref) => {
    const { vendorId, hideCreate, onSubmitAndAdd, companyVendor: companyVendorProp, orderType } = props
    const { vendorStore } = useStores()

    const vendor = vendorStore.selectedVendor
    const companyVendor = companyVendorProp || vendorStore.selectedVendor?.company_vendor

    const drawerCreateRef = useRef<DrawerRef>()

    const [activeKey, setActiveKey] = useState<'sales-representatives' | 'my-contacts'>('sales-representatives')

    const handleCreateCompanyVendorContact = () => {
      setActiveKey('my-contacts')
      vendorStore.getVendorById(vendorId)
    }

    return (
      <Drawer ref={ref} bgGray destroyOnClose closable={false}>
        <ErrorBoundary isFull={false}>
          <VendorProfileContent
            vendorId={vendorId}
            hideCreate={hideCreate}
            onSubmitAndAdd={onSubmitAndAdd}
            companyVendor={companyVendor}
            orderType={orderType}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            onAddNewVendorContact={() => drawerCreateRef.current.toggle()}
          />

          <CreateCompanyVendorContact
            hideNotification
            hideShowSaveAndAddAnotherButton
            selectedCompanyVendor={companyVendor}
            selectedVendor={vendor}
            onCancel={() => drawerCreateRef.current.close()}
            onFinish={handleCreateCompanyVendorContact}
            ref={drawerCreateRef}
          />
        </ErrorBoundary>
      </Drawer>
    )
  },
  { forwardRef: true },
)
