import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { getUnit } from 'common/components/OrderMaterialsV2'
import { Option, SelectUnit } from 'common/components/SelectUnit'
import { Unit } from 'common/server/units'

import { CommitmentMaterial } from 'contractor/server/commitments'

export type UnitColumnProps = {
  commitmentMaterial: CommitmentMaterial
  disabled?: boolean
  units: Unit[]
  onChange: (value: CommitmentMaterial, changes: CommitmentMaterial) => void
}

export const UnitColumn = (props: UnitColumnProps) => {
  const { commitmentMaterial, disabled, units, onChange } = props

  const unit = commitmentMaterial?.unit
  const option = unit ? unit : getUnit(commitmentMaterial.company_material)
  const increments_label = option?.original?.increments_label

  const handleUnitChange = (_, unitObject: Option) => {
    onChange(commitmentMaterial, {
      ...commitmentMaterial,
      unit: unitObject,
    })
  }

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <SelectUnit
        value={option}
        onChange={handleUnitChange}
        disabled={disabled}
        units={units}
        optionLabelProp="selectedLabel"
      />
      {increments_label && (
        <Typography.Text type="secondary" style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
          {increments_label?.replace(/[()]/g, '')}
        </Typography.Text>
      )}
    </Box>
  )
}
