import React from 'react'

import { Table } from 'antd'

import { observer } from 'mobx-react-lite'

import { makeOption, ValueType } from 'common/components/DebounceSelect'
import { MappingDirections } from 'common/server/server_types'

import { SelectAgaveVendors } from 'contractor/components/SelectAgaveVendors'
import {
  hasSuggestions,
  maybeAddApproveRejectColumn,
} from 'contractor/components/SelectExternalMappings/select_external_mappings'
import { SelectIntegrationEntity } from 'contractor/components/SelectIntegrationEntity/select_integration_entity'
import { useStores } from 'contractor/hooks/use-stores'
import { ProcoreMultiSelect } from 'contractor/pages/Integrations/Mappings/ProcoreMultiSelect'
import RelationshipsTable from 'contractor/pages/Integrations/Mappings/Relationships/relationships_table'
import { SuggestionAlert } from 'contractor/pages/Integrations/Mappings/suggestion_alert'
import { useUpdateMappingsHook } from 'contractor/pages/Integrations/Mappings/use_update_mappings_hook'
import { CompanyVendor, ListParams } from 'contractor/server/integrations'

interface CompanyVendorsTableProps {
  filter?: ListParams
  setFilter?: React.Dispatch<React.SetStateAction<ListParams>>
  isLoading: boolean
}

const CompanyVendorsTable = observer<CompanyVendorsTableProps>(({ isLoading, filter, setFilter }) => {
  const { integrationStore } = useStores()

  const reloadCallback = (fetchRelationships = true) => {
    if (fetchRelationships) integrationStore.getCompanyVendorsRelationships(filter)
    integrationStore.getCompanyVendors(filter)
    integrationStore.getCompanyVendorsCount({ search: filter?.search })
  }

  const { handleUpdate, handleDirectUpdate, handleSuggestionsApproval, handleSuggestionsRejection, updating } =
    useUpdateMappingsHook({
      update: integrationStore.updateCompanyVendor,
      reloadCallback,
    })

  if (integrationStore.mappingDirection === MappingDirections.INSIDE_OUT) {
    const columns = [
      {
        title: 'SubBase Name',
        dataIndex: 'vendor_name',
        width: '30%',
        render: (_, row) => row?.vendor_name || row?.vendor?.name,
      },
      {
        title: `${integrationStore.title()} Vendors`,
        width: '70%',
        dataIndex: 'external_relationships',
        render: (_, row) => {
          const selected = []
          const suggestions = hasSuggestions(row)
          const externalItems = []
          if (suggestions) {
            externalItems.push(...row.mapping_suggestions)
          } else {
            externalItems.push(...row.external_relationships)
          }
          selected.push(...externalItems.map((item) => makeOption(item)))

          if (integrationStore.isProcore()) {
            return (
              <ProcoreMultiSelect
                hasSuggestions={suggestions}
                selected={selected}
                values={externalItems}
                row={row}
                handleUpdate={handleUpdate}
              >
                <SelectAgaveVendors
                  selected={selected}
                  loading={updating}
                  showArrow={true}
                  onChange={(value) => handleUpdate({ option: value, entity: row, fetchRelationships: false })}
                  status={suggestions ? 'warning' : undefined}
                  tagRender={() => <></>}
                />
              </ProcoreMultiSelect>
            )
          }
          return (
            <SelectAgaveVendors
              selected={selected}
              loading={updating}
              showArrow={true}
              onChange={(value) => {
                handleUpdate({ option: value, entity: row })
              }}
              status={suggestions ? 'warning' : undefined}
            />
          )
        },
      },
    ]
    maybeAddApproveRejectColumn(
      'companyVendors',
      integrationStore.companyVendors?.data,
      integrationStore.showCompanyVendorsSuggestions,
      columns,
      handleSuggestionsApproval,
      handleSuggestionsRejection,
    )
    return (
      <>
        <SuggestionAlert showSuggestions={integrationStore.showCompanyVendorsSuggestions()} />
        <Table<CompanyVendor>
          pagination={{
            total: integrationStore.companyVendors?.totalCount,
            current: integrationStore.companyVendors?.currentPage,
            pageSize: integrationStore.companyVendors?.pageItems,
            onChange: (page) => {
              setFilter((prev) => ({ ...prev, page }))
            },
            showSizeChanger: false,
          }}
          loading={isLoading}
          rowKey="id"
          style={{ width: '100%' }}
          columns={columns}
          dataSource={integrationStore.companyVendors?.data}
        />
      </>
    )
  }

  const disabledOption = (item: CompanyVendor) =>
    integrationStore.enabledVendorsMultiMapping() ? false : item.external_relationships.length > 0

  const makeInternalOption = (item: CompanyVendor) =>
    ({
      value: item.id,
      label: item.vendor_name || item.vendor?.name,
      disabled: disabledOption(item),
    } as ValueType)

  return (
    <RelationshipsTable
      showSuggestions={integrationStore.showCompanyVendorsSuggestions()}
      isLoading={isLoading}
      handleSuggestionsApproval={handleSuggestionsApproval}
      handleSuggestionsRejection={handleSuggestionsRejection}
      filter={filter}
      setFilter={setFilter}
      relationships={integrationStore.companyVendorsRelationships}
      renderRowTitle="Vendors"
      renderRow={(row) => (
        <SelectIntegrationEntity
          loading={updating}
          row={row}
          optionApi={integrationStore.getCompanyVendors}
          optionData={integrationStore.companyVendors}
          makeInternalOption={makeInternalOption}
          handleUpdate={handleDirectUpdate}
          status={row.mapping_suggestions && row.mapping_suggestions.length > 0 ? 'warning' : undefined}
        />
      )}
    />
  )
})

export default CompanyVendorsTable
