import React from 'react'

import { render, screen } from 'common/test-utils/index'

import { TableBody, TableBodyProps } from '../table_body'

const makeVendorItem = (props = {}) => ({
  vendor_note: null,
  unit_cost: 100,
  delivery_id: 'delivery_id_1',
  isLowestPrice: false,
  tax_value: 10,
  shipping_value: 10,
  other_value: 10,
  discount_value: 10,
  extended_cost: 10,
  orderId: '123',
  ...props,
})

const makeCompanyMaterial = (props = {}) => ({
  id: 'company_material_1',
  description: 'Material N',
  unit: 'm',
  ...props,
})

export const makeTableData = (props = {}) => ({
  company_material: makeCompanyMaterial(),
  quantity: 10,
  vendor_items: [makeVendorItem()],
  ...props,
})

describe.skip('CompareOrders > table_body', () => {
  it('should render all rows', () => {
    render(
      <table>
        <TableBody tableData={[makeTableData(), makeTableData()] as TableBodyProps['tableData']} />
      </table>,
    )

    expect(screen.getAllByTestId('row').length).toBe(2)
  })

  it('should render empty cell if not have material', () => {
    render(
      <table>
        <TableBody
          tableData={
            [
              makeTableData({
                vendor_items: [makeVendorItem(), {}],
              }),
            ] as TableBodyProps['tableData']
          }
        />
      </table>,
    )

    expect(screen.getAllByTestId('empty-cell').length).toBe(1)
    expect(screen.getByTestId('empty-cell').style.backgroundColor).toBe('rgb(247, 247, 247)')
  })

  it('should render highlighted cell for lowest price', () => {
    render(
      <table>
        <TableBody
          tableData={
            [
              makeTableData({
                vendor_items: [makeVendorItem({ isLowestPrice: true }), makeVendorItem({ isLowestPrice: false })],
              }),
            ] as TableBodyProps['tableData']
          }
        />
      </table>,
    )

    expect(screen.getAllByTestId('lowest-price').length).toBe(1)
    expect(screen.getByTestId('lowest-price').style.backgroundColor).toBe('rgb(246, 255, 236)')
  })

  it('should not render vendor notes if not present', () => {
    render(
      <table>
        <TableBody tableData={[makeTableData()] as TableBodyProps['tableData']} />
      </table>,
    )

    expect(screen.queryByTestId('vendor-note-cell')).toBeNull()
  })

  it('should render vendor notes if present', () => {
    render(
      <table>
        <TableBody
          tableData={
            [
              makeTableData({
                vendor_items: [makeVendorItem({ vendor_note: 'note' })],
              }),
            ] as TableBodyProps['tableData']
          }
        />
      </table>,
    )

    expect(screen.getByTestId('vendor-note-cell')).toBeTruthy()
  })
})
