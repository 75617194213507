import React from 'react'

import { Box, BoxProps } from 'common/components/boxes'

type MobileWrapperProps = { children: React.ReactNode[] | React.ReactNode } & BoxProps

const Wrapper = (props: MobileWrapperProps) => {
  return (
    <Box
      position="fixed"
      left={0}
      right={0}
      top={40}
      bottom={0}
      zIndex={1000}
      bg="white"
      display="flex"
      flexDirection="column"
      {...props}
    />
  )
}

const Header = (props: MobileWrapperProps) => {
  return (
    <Box
      width="100%"
      style={{ gap: 8 }}
      display="inline-flex"
      p={12}
      borderBottom="1px"
      borderBottomStyle="solid"
      borderBottomColor="gray-4"
      {...props}
    />
  )
}

const List = (props: MobileWrapperProps) => {
  return <Box flexGrow={1} p={12} overflowY="auto" as="ul" {...props} />
}

const ListItem = (props: MobileWrapperProps) => {
  return (
    <Box
      as="li"
      py={6}
      borderBottom="1px"
      borderBottomStyle="solid"
      borderBottomColor="gray-2"
      style={{ cursor: 'pointer' }}
      {...props}
    />
  )
}

const Footer = (props: MobileWrapperProps) => {
  return <Box borderTop="1px" borderTopStyle="solid" borderTopColor="gray-3" {...props} />
}

export const Mobile = {
  Wrapper,
  Header,
  Footer,
  List,
  ListItem,
}
