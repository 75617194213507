import React from 'react'

import { Global, css } from '@emotion/core'

import { ErrorBoundary } from 'common/components/ErrorBoundary'
import { Page } from 'common/components/Page'

import { LevelingProvider } from './context'
import { Header } from './Header'
import { Table } from './table'

const CompareOrders = () => {
  return (
    <ErrorBoundary>
      <LevelingProvider>
        <Page>
          <Global
            styles={css`
              .material .ant-popover-inner {
                margin-top: 30px;
              }

              .material .ant-popover-arrow {
                left: 20px !important;
                margin-top: 15px;
                transform: rotate(135deg);
              }
            `}
          />
          <Page.Header>
            <Header />
          </Page.Header>

          <Page.Content>
            <Table />
          </Page.Content>
        </Page>
      </LevelingProvider>
    </ErrorBoundary>
  )
}

export default CompareOrders
