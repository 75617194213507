import React from 'react'

import { Tag, Empty, Typography } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { PsqlTable } from 'common/components/PsqlTable'
import { PsqlTableProvider } from 'common/components/PsqlTable/psql_table_provider'
import { ResponsiveTable } from 'common/components/ResponsiveTable'
import CheckBoxCell from 'common/components/table/cells/checkbox'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

type TablePhaseCodesProps = {
  onClickRow: (phaseCodeId: string) => void
}

const Content = observer<TablePhaseCodesProps>(({ onClickRow }) => {
  const { costCodeStore } = useStores()

  const { costCodePhaseListStore } = costCodeStore

  if (costCodePhaseListStore.isFetching && costCodePhaseListStore.searchState.page === 1) {
    return <Loading />
  }

  if (costCodePhaseListStore.records.length === 0) {
    return (
      <Box
        p={16}
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              Sorry! No Items were found with these search criteria.
            </Typography.Title>
          }
        />
      </Box>
    )
  }

  return (
    <ResponsiveTable
      Table={PsqlTable}
      data={toJS(costCodePhaseListStore.records) ?? []}
      hasMore={costCodePhaseListStore.hasMore}
      loadMore={costCodePhaseListStore.fetchNextPage}
      onLoadMore={costCodePhaseListStore.fetchNextPage}
      onClickRow={({ row }) => onClickRow(row.id)}
    />
  )
})

export const TablePhaseCodes = observer<TablePhaseCodesProps>(({ onClickRow }) => {
  const { costCodeStore, companySettingStore, projectStore, integrationStore } = useStores()
  const persistentFilters = usePersistentFilters()

  const { costCodePhaseListStore } = costCodeStore

  const procore = integrationStore.isProcore()

  useQuery(costCodePhaseListStore.fetchFacets)
  useQuery(projectStore.maybeIndexProjects)
  useQuery(() => {
    persistentFilters.handleChangeFilters({ active: true }, false)
    return persistentFilters.init()
  }, [])

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  return (
    <PsqlTableProvider
      options={{
        data: toJS(costCodePhaseListStore.records),
        sort: {
          sortFromPersistentFilter: true,
          field: costCodePhaseListStore.searchState.sort,
          direction: costCodePhaseListStore.searchState.sort_direction,
        },
        columns: [
          {
            Header: 'Code',
            accessor: 'code',
          },
          ...(procore
            ? [
                {
                  Header: 'Source',
                  accessor: 'data_source',
                  Cell: ({ value }) => {
                    const tag = {
                      color: value === 'INTERNAL' ? 'blue' : 'gold',
                      text: value === 'INTERNAL' ? 'SubBase' : 'Procore',
                    }
                    return (
                      <Tag color={tag.color} style={{ marginTop: 5, minWidth: 67, textAlign: 'center' }}>
                        {tag.text}
                      </Tag>
                    )
                  },
                },
              ]
            : []),
          {
            Header: 'Description',
            accessor: 'description',
          },
          ...(costCodeSettings?.project_specific_phase_codes_enabled
            ? [
                {
                  Header: 'Projects',
                  accessor: 'projects',
                  Cell: ({ value = [] }) => value.map((project) => <Tag key={project?.id}>{project?.name}</Tag>),
                },
              ]
            : []),
          {
            Header: 'Active',
            accessor: 'discarded_at',
            width: 50,
            Cell: ({ value }) => <CheckBoxCell value={!value} />,
          },
        ],
      }}
      tableName="AllPhaseCodes"
    >
      <Content onClickRow={onClickRow} />
    </PsqlTableProvider>
  )
})
