import React from 'react'

import { Link } from 'react-router-dom'
import { Row } from 'react-table'

import { RequestQuote } from '@styled-icons/material'
import { get } from 'lodash'

import { Tooltip } from 'antd'

import { OrderStates } from 'common/server/server_types'
import theme from 'common/styles/theme'

interface CellLevelingProps {
  row: Row
}

export const CellLeveling = ({ row }: CellLevelingProps) => {
  const state = get(row, 'original.state')
  const orderPackageId = get(row, 'original.order_package_id')
  const orderPackageCount = get(row, 'original.order_package_count', 1)

  if (orderPackageCount <= 1 || [OrderStates.DRAFT, OrderStates.REQUESTED].includes(state)) {
    return null
  }

  return (
    <Tooltip title="Open RFQ Leveling" placement="topRight">
      <Link to={`/order_package/${orderPackageId}`} className="show-on-hover-row" onClick={(e) => e.stopPropagation()}>
        <RequestQuote style={{ width: 20, color: theme.colors.primary }} />
      </Link>
    </Tooltip>
  )
}
