import { useState } from 'react'

export const useHistoryCollapse = () => {
  const [expanded, setExpand] = useState<Record<string, boolean>>({ 0: true })

  const hasExpanded = Object.values(expanded).length > 1

  const handleToggleItem = (index: number) => {
    const value = Object.prototype.hasOwnProperty.call(expanded, index) ? !expanded[index] : true
    setExpand((prev) => ({
      ...prev,
      [index]: value,
    }))
  }

  const handleToggleAll = (data = []) => {
    if (hasExpanded) {
      setExpand({})
    } else {
      const expandeds = {}
      data.forEach((_, index) => {
        expandeds[index] = true
      })
      setExpand(expandeds)
    }
  }

  return { hasExpanded, handleToggleItem, handleToggleAll, expanded }
}
