import { useEffect, useState } from 'react'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'

interface UserReloadIdleUser {
  timeToSetIdle?: number
}

export const useReloadIdleUser = (params: UserReloadIdleUser = {}) => {
  const { timeToSetIdle = 3600000 } = params

  const [isIdle, setIsIdle] = useState(false)

  useEffect(() => {
    let timeoutId

    const resetTimeout = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setIsIdle(true)
      }, timeToSetIdle)
    }

    const handleUserActivity = () => {
      setIsIdle(false)
      resetTimeout()
    }

    document.addEventListener('mousemove', handleUserActivity)
    document.addEventListener('keydown', handleUserActivity)

    resetTimeout()

    return () => {
      document.removeEventListener('mousemove', handleUserActivity)
      document.removeEventListener('keydown', handleUserActivity)
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    if (isIdle) {
      trackEvent(Events.RELOAD_IDLE_USER, location.pathname)
      window.location.reload()
    }
  }, [isIdle])
}
