import { BindAll } from 'lodash-decorators'

import ListBaseStore from 'common/stores/ListBaseStore'

import {
  index,
  facets,
  PriceSheet,
  create,
  destroy,
  show,
  update,
  expire,
  price_sheet_materials,
  PriceSheetCreate,
  PriceSheetUpdate,
  PriceSheetMaterial,
} from 'contractor/server/price_sheet'

class PriceSheetStoreListStore extends ListBaseStore<PriceSheet> {
  index = index
  getFacets = facets
}

class PriceSheetMaterialStoreListStore extends ListBaseStore<PriceSheetMaterial> {
  index = price_sheet_materials
}

@BindAll()
export default class PriceSheetStore {
  listStore: PriceSheetStoreListStore
  priceSheetMaterialListStore: PriceSheetMaterialStoreListStore

  constructor() {
    this.listStore = new PriceSheetStoreListStore()
    this.priceSheetMaterialListStore = new PriceSheetMaterialStoreListStore()
  }

  async createPriceSheet(payload: PriceSheetCreate) {
    const { data } = await create(payload)
    return data
  }

  updatePriceSheet(priceSheet: PriceSheetUpdate, applyPreferredPrice?: boolean) {
    return update(priceSheet, applyPreferredPrice)
  }

  destroyPriceSheet(id: string) {
    return destroy(id)
  }

  expirePriceSheet(id: string) {
    return expire(id)
  }

  async getPriceSheetById(id: string) {
    const { data } = await show(id)
    return data
  }
}
