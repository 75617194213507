import React from 'react'

import { Tooltip, Typography } from 'antd'

import { roundValue } from 'common/helpers/order'
import theme from 'common/styles/theme'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'
import { MappedStatus } from './mapped_status'

const getRemainingPriceColor = (remaining) => {
  if (remaining === 0) {
    return theme.colors['gray-7']
  }

  if (remaining > 0) {
    return theme.colors.link
  }

  return theme.colors.error
}

export const getTableColumns = () => {
  const { userStore, companySettingStore, invoiceStore } = useStores()

  const { currencyFormatter, taxLineItemsEnabled, calcExtCost } = useInvoice()
  const canUseRetainage = useFlag('retainage_v1')

  const { invoice } = invoiceStore
  const { company_attributes = [] } = companySettingStore.companyMaterialConfiguration
  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const fixedFirstColumns = [
    {
      dataIndex: 'action',
      render: (_, row) => {
        const rowId = row?.id?.split('-')[0] || ''
        if (['total', 'tax', 'shipping', 'other', 'discount'].includes(rowId)) {
          return null
        }
        return <MappedStatus orderMaterial={row} />
      },
      width: 36,
    },
    {
      title: 'Order Description',
      dataIndex: ['company_material', 'description'],
      render: (description) => {
        return (
          <Typography.Paragraph ellipsis={{ rows: 2 }} style={{ margin: 0, minWidth: 72 }}>
            {description}
          </Typography.Paragraph>
        )
      },
    },
    {
      title: 'Number',
      dataIndex: 'order_number',
      width: 150,
      render: (orderNumber, row) => {
        if (row.is_remaining) return '-'

        return (
          <Tooltip title="Order Number">
            <Typography.Link href={`/order/${row.order_id}`} target="_blank">
              #{orderNumber}
            </Typography.Link>
          </Tooltip>
        )
      },
    },
  ]

  const columnsToHide = [
    {
      title: 'Qty',
      dataIndex: 'quantity',
      width: 70,
      align: 'right',
      render: (_, row) => {
        if (row.is_remaining) return '-'

        return row?.quantity_delivered ?? row?.quantity
      },
    },
    {
      title: 'UOM',
      dataIndex: 'unit',
      width: 100,
      render: (_, row) => {
        if (row.is_remaining) return '-'

        return row?.company_material?.unit?.name || row?.company_material?.unit_name
      },
    },
    {
      title: 'Unit Cost',
      dataIndex: 'unit_cost',
      width: 100,
      align: 'right',
      render: (value, row) => {
        if (row.is_remaining) return '-'

        return value ? currencyFormatter(value) : null
      },
    },
    ...(taxLineItemsEnabled
      ? [
          {
            title: 'Tax',
            dataIndex: 'tax_split',
            width: 100,
            align: 'right',
            render: (value, row) => {
              // Used for total row
              if (value) {
                return <Typography.Text>{currencyFormatter(value)}</Typography.Text>
              }

              return <Typography.Text>{currencyFormatter(row?.tax_value || 0)}</Typography.Text>
            },
          },
          {
            title: 'Ext. Cost + Tax',
            dataIndex: 'ext_cost_with_tax',
            width: 120,
            align: 'right',
            render: (extCostWithTax, row) => {
              // Used for total row
              if (extCostWithTax) {
                return <Typography.Text>{currencyFormatter(extCostWithTax)}</Typography.Text>
              }

              const extCost = calcExtCost({
                unitCost: row?.unit_cost,
                quantity: row?.quantity,
                multiplier: row?.unit?.multiplier,
                qtyIncrement: row?.unit?.qty_increment,
              })

              const taxValue = row?.tax_value || 0

              return <Typography.Text>{currencyFormatter(extCost + taxValue)}</Typography.Text>
            },
          },
        ]
      : []),
    {
      title: 'Ordered',
      dataIndex: 'ordered',
      width: 100,
      align: 'right',
      render: (ordered, row) => {
        if (row.is_remaining) return '-'

        if (ordered) {
          return currencyFormatter(ordered)
        }

        const extendedCost = calcExtCost({
          unitCost: Number(row?.unit_cost),
          quantity: row?.quantity,
          multiplier: row?.company_material?.unit?.multiplier,
          qtyIncrement: row?.company_material?.unit?.qty_increment,
        })
        return currencyFormatter(extendedCost)
      },
    },
    {
      title: 'Invoiced',
      dataIndex: 'invoiced',
      align: 'right',
      width: 100,
      render: (invoiced, row) => {
        if (row.is_remaining) {
          const extCost = calcExtCost({
            unitCost: row?.unit_cost,
            quantity: row?.quantity,
            multiplier: row?.company_material?.unit?.multiplier,
            qtyIncrement: row?.company_material?.unit?.qty_increment,
          })
          return currencyFormatter(extCost)
        }

        if (invoiced) {
          return currencyFormatter(invoiced)
        }

        const allExtendedCosts = row?.invoice_materials?.reduce((acc, invoiceMaterial) => {
          const extCost = calcExtCost({
            unitCost: invoiceMaterial?.unit_price,
            quantity: invoiceMaterial?.quantity_shipped,
            multiplier: invoiceMaterial?.unit?.multiplier,
            qtyIncrement: invoiceMaterial?.unit?.qty_increment,
          })
          return acc + extCost
        }, 0)

        return currencyFormatter(allExtendedCosts)
      },
    },
    {
      title: 'Remaining',
      dataIndex: 'remaining',
      align: 'right',
      width: 100,
      render: (remaining, row) => {
        if (row.is_remaining) return '-'

        if (remaining) {
          const color = getRemainingPriceColor(remaining)
          return <Typography.Text style={{ color }}>{currencyFormatter(remaining)}</Typography.Text>
        }

        const invoiceMaterialsExtendedCost =
          row?.invoice_materials?.reduce((acc, invoiceMaterial) => {
            const extCost = calcExtCost({
              unitCost: invoiceMaterial?.unit_price,
              quantity: invoiceMaterial?.quantity_shipped,
              multiplier: invoiceMaterial?.unit?.multiplier,
              qtyIncrement: invoiceMaterial?.unit?.qty_increment,
            })
            return acc + extCost
          }, 0) || 0

        const orderExtendedCost =
          calcExtCost({
            unitCost: Number(row?.unit_cost),
            quantity: row?.quantity,
            multiplier: row?.company_material?.unit?.multiplier,
            qtyIncrement: row?.company_material?.unit?.qty_increment,
          }) || 0

        const calculatedRemaining =
          roundValue(orderExtendedCost, invoice?.decimal_precision) -
          roundValue(invoiceMaterialsExtendedCost, invoice?.decimal_precision)
        const color = getRemainingPriceColor(calculatedRemaining)

        return <Typography.Text style={{ color }}>{currencyFormatter(calculatedRemaining)}</Typography.Text>
      },
    },
  ]

  if (userStore.canUseCostCode && company_attributes.includes('cost_code_id')) {
    const canShowPhase = costCodeSettings?.phase_code_enabled && !costCodeSettings.independent_phase_codes_enabled
    const canShowClass = costCodeSettings?.class_enabled

    columnsToHide.push({
      title: 'Cost Code',
      dataIndex: 'cost_code',
      width: 200,
      render: (costCode) => {
        if (!costCode) return null

        return (
          <Tooltip title={costCode?.description}>
            <Typography.Text style={{ padding: '0 12px' }}>
              {[
                canShowPhase ? costCode?.phase_code || 'N/A' : '',
                costCode?.code,
                canShowClass ? costCode?.clazz || 'N/A' : '',
              ]
                .filter((item) => !!item)
                .join('.')}
            </Typography.Text>
          </Tooltip>
        )
      },
    })

    if (costCodeSettings?.independent_phase_codes_enabled) {
      columnsToHide.push({
        title: 'Phase Code',
        dataIndex: 'cost_code_phase',
        width: 130,
        render: (_, row) => {
          return (
            <Tooltip title={row?.cost_code_phase?.description}>
              <Typography.Text style={{ padding: '0 12px' }}>{row?.cost_code_phase?.code}</Typography.Text>
            </Tooltip>
          )
        },
      })
    }
  }

  if (canUseRetainage) {
    columnsToHide.push({
      title: 'Retainage amount',
      dataIndex: 'retainage',
      align: 'right',
      width: 190,
      render: () => '',
    })
  }

  return { fixedFirstColumns, columnsToHide }
}
