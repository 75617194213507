import React from 'react'

import { message } from 'antd'

import { observer } from 'mobx-react-lite'

import { Comments } from 'common/components/@v2/Comments'

import { useStores } from 'contractor/hooks/use-stores'

export const TabVendorChat = observer(({ opened = true }: { opened?: boolean }) => {
  const { orderStore, userStore } = useStores()

  const unableToUpdate = !userStore.canSendAndUpdateOrders || !userStore.canSendAndUpdateRfqs

  const handleSubmitComment = (comment: string) => {
    const newComment = {
      id: orderStore.selectedOrder?.id,
      comment,
    }

    try {
      orderStore.sendNewComment(newComment)
    } catch (error) {
      message.error('Unable to sent comment')
    }
  }

  const { comments } = orderStore.selectedOrder

  if (!opened) return null

  return (
    <Comments
      comments={comments}
      onChangeNewComment={orderStore.updateNewComment.bind(orderStore)}
      newComment={orderStore.newComment}
      unableToUpdate={unableToUpdate}
      onSubmit={handleSubmitComment}
      placeholder="Add a message for the vendor"
    />
  )
})
