import React, { ReactElement } from 'react'

import { BrowserRouter } from 'react-router-dom'

import { render, RenderOptions } from '@testing-library/react'

import { ThemeProvider } from '@emotion/react'

import theme from 'common/styles/theme'

const AllTheProviders: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>{children}</BrowserRouter>
    </ThemeProvider>
  )
}

const customRender = (ui: ReactElement, options?: Omit<RenderOptions, 'wrapper'>) =>
  render(ui, { wrapper: AllTheProviders, ...options })

const getDataTestId = (dataTestId) => ({
  'data-testid': dataTestId,
})

export * from '@testing-library/react'
export { customRender as render, getDataTestId }
