import React from 'react'

import moment from 'moment'

import { Button, Checkbox, Col, Input, Row, Space, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { CarrierSelect } from 'common/components/carrier_select'
import DateTimePicker from 'common/components/date_time_picker'
import { formatDateString } from 'common/helpers/formatters'
import { OrderDelivery } from 'common/server/deliveries'

import { useStores } from 'contractor/hooks/use-stores'

import ItemTitle from './item_title'

type ShippingDetailsProps = {
  delivery: OrderDelivery
  onChange: (item) => void
  disableEditing?: boolean
}

export const ShippingDetail = observer<ShippingDetailsProps>(({ delivery, onChange, disableEditing }) => {
  const { deliveryStore } = useStores()

  const [editShippingDetails, setEditShippingDetails] = React.useState(false)
  const [autoToggledMarkAsShipped, setAutoToggleMarkAsShipped] = React.useState(false)

  React.useEffect(() => {
    if (editShippingDetails) {
      deliveryStore.maybeGetShippingCarriers()
    }
  }, [editShippingDetails])

  const { marked_shipped_at, estimated_delivered_at, tracking_number, carrier } = delivery

  const maybeToggleMarkAsShipped = (otherParams) => {
    // If we already toggled it once we don't want to be annoying to the user
    if (autoToggledMarkAsShipped || marked_shipped_at) {
      onChange(otherParams)
      return
    }
    setAutoToggleMarkAsShipped(true)
    onChange({ ...otherParams, marked_shipped_at: marked_shipped_at ? null : moment().toISOString() })
  }

  return (
    <>
      <FlexBoxX justifyContent="space-between" flexWrap="wrap" mb={12}>
        <Typography.Text strong>Vendor Shipping Details</Typography.Text>

        {!editShippingDetails && !disableEditing && (
          <Tooltip title="These are usually provided by the vendor">
            <Button data-cy="edit_shipping_details" type="link" onClick={() => setEditShippingDetails(true)}>
              Edit Shipping Details
            </Button>
          </Tooltip>
        )}
      </FlexBoxX>

      <Row gutter={[20, 20]}>
        <Col xs={24} sm={12}>
          <FlexBoxY justifyContent="flex-start" alignItems="flex-start">
            <ItemTitle text="Estimated Delivery Date" />
            {editShippingDetails ? (
              <DateTimePicker
                data-cy="estimated-delivered-at"
                value={estimated_delivered_at}
                onChange={(estimated_delivered_at) => onChange({ estimated_delivered_at })}
                wrapperProps={{ width: '100%' }}
                disabled={disableEditing}
              />
            ) : (
              formatDateString(estimated_delivered_at)
            )}
          </FlexBoxY>
        </Col>

        <Col xs={24} sm={12}>
          <Box display="flex" alignItems="center" height="100%">
            <Space>
              <Checkbox
                data-cy="mark-as-shipped"
                checked={marked_shipped_at !== null}
                onChange={() => onChange({ marked_shipped_at: marked_shipped_at ? null : moment().toISOString() })}
                disabled={!editShippingDetails || disableEditing}
              />
              {marked_shipped_at ? (
                <ItemTitle text={`Marked as shipped on ${formatDateString(marked_shipped_at)}`} />
              ) : (
                <ItemTitle text="Mark as Shipped" />
              )}
            </Space>
          </Box>
        </Col>

        <Col xs={24} sm={12}>
          <FlexBoxY justifyContent="flex-start" alignItems="flex-start">
            <ItemTitle text="Carrier" />
            {editShippingDetails ? (
              <CarrierSelect
                onChange={(carrier_info) => maybeToggleMarkAsShipped(carrier_info)}
                delivery={delivery}
                shippingCarriers={deliveryStore.shippingCarriers}
                wrapperProps={{ width: '100%', minWidth: 'auto' }}
                disabled={disableEditing}
              />
            ) : (
              carrier
            )}
          </FlexBoxY>
        </Col>

        <Col xs={24} sm={12}>
          <FlexBoxY justifyContent="flex-start" alignItems="flex-start">
            <ItemTitle text="Tracking Number or Bill of Lading" />
            {editShippingDetails ? (
              <Input
                data-cy="tracking-number"
                value={tracking_number}
                onChange={(event) => maybeToggleMarkAsShipped({ tracking_number: event.target.value })}
                disabled={disableEditing}
              />
            ) : (
              tracking_number
            )}
          </FlexBoxY>
        </Col>
      </Row>
    </>
  )
})

export default ShippingDetail
