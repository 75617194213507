import React, { useState, useEffect } from 'react'

import { Form, Input, Col, Alert } from 'antd'

import { Image } from 'common/components/Image'

export const FieldImageUrl = ({ imageUrlField, ...props }) => {
  const [hasImageError, setImageError] = useState(false)

  useEffect(() => {
    setImageError(false)
  }, [imageUrlField])

  return (
    <>
      <Col span={24}>
        <Form.Item name="image_url" label="Image URL">
          <Input {...props} />
        </Form.Item>
      </Col>

      {imageUrlField && (
        <Col xs={24}>
          <Image src={imageUrlField} onError={() => setImageError(true)} style={{ maxWidth: '100%' }} />
          {hasImageError && (
            <Alert
              type="error"
              style={{ marginTop: 12 }}
              message="We couldn't recognize the image URL, please make sure it is a valid URL."
            />
          )}
        </Col>
      )}
    </>
  )
}
