import React, { useMemo } from 'react'

import { flatten } from 'lodash'

import { observer } from 'mobx-react-lite'

import { OrderDeliveryCosts } from 'common/components/@v2/OrderDeliveryCosts'
import { OrderMaterialSpreadsheet } from 'common/components/@v2/OrderMaterialsSpreadsheet'
import { getFilteredColumns } from 'common/components/OrderMaterialsSpreadsheet'
import { calcOrderTaxSplitBulk, roundValue } from 'common/helpers/order'
import { CostCode } from 'common/server/cost_codes/cost_codes'
import { OrderMaterial } from 'common/server/orders'
import { OrderStates } from 'common/server/server_types'

import {
  CustomCostCodeEditorCell,
  CustomCostCodeViewerCell,
  CustomDescriptionEditorCell,
  CustomDescriptionViewerCell,
  CustomPhaseCodeEditorCell,
  CustomPhaseCodeViewerCell,
  CustomUnitsEditorCell,
} from 'contractor/components/OrderMaterials'
import { usePopulateSameCostCode, UsePopulateSameCostCodePath } from 'contractor/hooks/use-populate-same-cost-code'
import { useStores } from 'contractor/hooks/use-stores'

import { useOrderDetail } from '../../context'

interface HandleMaybeApplyToAllProps {
  index: number
  value: CostCode | string
  path: string
}

export const MaterialAndDeliveriesTable: React.FC<{
  delivery_id: string
  costCodeDisabled?: boolean
  isPoLocked?: boolean
  deliveryName: string
}> = observer(({ delivery_id, costCodeDisabled, isPoLocked, deliveryName }) => {
  const { companySettingStore, orderStore, userStore } = useStores()
  const { actions } = useOrderDetail()

  const requiredOrderFields = companySettingStore.otherSettings?.required_order_fields
  const requiredQuoteFields = companySettingStore.otherSettings?.required_quote_fields
  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const projectId = orderStore.selectedOrder?.project_id
  const commitmentId = orderStore.selectedOrder?.commitment_id
  const populateSameCostCode = usePopulateSameCostCode<OrderMaterial>({
    itemIdentifierKey: 'company_material.id',
  })

  function handleApplyCostCodeToAll(args: HandleMaybeApplyToAllProps) {
    const { index, value, path } = args

    const newMaterials = populateSameCostCode.handleBulkChange({
      triggerIndex: index,
      path: path as UsePopulateSameCostCodePath,
      value,
      datasource: orderStore.getOrderMaterialsByDeliveryId(delivery_id),
    })

    orderStore.updateOrderMaterialsByDeliveryId(delivery_id, newMaterials)
  }

  // Get all the columns filtered/ordered by the company attributes
  const columns = useMemo(() => {
    const columns = getFilteredColumns(companySettingStore.companyAttributes).map((column) => {
      switch (column.id) {
        case 'unit':
          return {
            ...column,
            readOnly: !userStore.canCreateNewMaterial,
            DataEditor: CustomUnitsEditorCell,
          }
        case 'description':
          return {
            ...column,
            DataEditor: (props) => (
              <CustomDescriptionEditorCell
                {...props}
                canCreateNewMaterial={userStore.canCreateNewMaterial}
                projectId={projectId}
              />
            ),
            DataViewer: CustomDescriptionViewerCell,
          }
        case 'cost_code_id':
          return {
            ...column,
            readOnly: costCodeDisabled,
            DataEditor: (props) => {
              return (
                <CustomCostCodeEditorCell
                  {...props}
                  projectId={projectId}
                  onChange={(cell, shouldPropagateValue) => {
                    props.onChange({
                      ...cell,
                      shouldPropagateValue,
                    })
                  }}
                  defaultApplyToAllChecked={props.cell.materialRow.default_cost_code_to_be_applied}
                  onChangeApplyToAll={(args) => {
                    handleApplyCostCodeToAll({
                      ...args,
                      index: props.row,
                    })
                  }}
                />
              )
            },
            DataViewer: CustomCostCodeViewerCell,
          }
        case 'cost_code_phase_id':
          return {
            ...column,
            readOnly: costCodeDisabled,
            DataEditor: (props) => (
              <CustomPhaseCodeEditorCell
                {...props}
                projectId={projectId}
                onChange={(cell, shouldPropagateValue) =>
                  props.onChange({
                    ...cell,
                    shouldPropagateValue,
                  })
                }
                defaultApplyToAllChecked={props.cell.materialRow.default_cost_code_to_be_applied}
                onChangeApplyToAll={(args) => {
                  handleApplyCostCodeToAll({
                    ...args,
                    index: props.row,
                  })
                }}
              />
            ),
            DataViewer: CustomPhaseCodeViewerCell,
          }
        default: {
          // Disable the company materials column to edit when user doesn't have permission
          if (companySettingStore.companyAttributes.includes(column.id)) {
            return {
              ...column,
              readOnly: !userStore.canCreateNewMaterial,
            }
          }
          return column
        }
      }
    })

    return columns
  }, [
    companySettingStore.companyAttributes,
    orderStore.selectedOrder?.project_id,
    costCodeSettings?.independent_phase_codes_enabled,
    userStore.canCreateNewMaterial,
    handleApplyCostCodeToAll,
  ])

  const hiddenColumns = useMemo(() => {
    const arr = ['approve', 'group', 'sub_group']

    if (!costCodeSettings?.independent_phase_codes_enabled) {
      arr.push('cost_code_phase_id')
    }
    return arr
  }, [costCodeSettings?.independent_phase_codes_enabled])

  const readOnlyColumns = useMemo(() => {
    const arr = ['vendor_notes', 'extended_cost', 'vendor_response']

    if (commitmentId) {
      arr.push(
        'cost_code_id',
        'cost_code_phase_id',
        'unit',
        'unit_cost',
        'product_identifier',
        'size',
        'material',
        'connection_type',
        'manufacturer',
      )
    }
    return arr
  }, [commitmentId])

  const deliveryTotalCost = orderStore.deliveryTotalCost({
    deliveryId: delivery_id,
    precision: companySettingStore.otherSettings?.rounding_precision_settings?.order_precision,
  })

  const delivery = orderStore.selectedOrder?.deliveries.find((delivery) => delivery.id === delivery_id)

  const orderMaterials = orderStore.getOrderMaterialsByDeliveryId(delivery_id) || []

  if (companySettingStore.companyAttributes.length === 0) {
    return null
  }

  const { company_vendor, state } = orderStore.selectedOrder
  const orderType = state === OrderStates.QUOTED ? 'RFQ' : 'Order'

  return (
    <>
      {!!orderMaterials.length && (
        <OrderMaterialSpreadsheet
          canCreateNewMaterial={userStore.canCreateNewMaterial}
          costCodeSettings={costCodeSettings}
          companyVendorId={company_vendor?.id}
          orderType={orderType}
          columns={flatten(columns)}
          requiredFields={orderType === 'Order' ? requiredOrderFields : requiredQuoteFields}
          readOnlyColumns={readOnlyColumns}
          hiddenColumns={hiddenColumns}
          orderMaterials={orderMaterials}
          onChange={(newOrderMaterials, valuesToPropagate) => {
            if (valuesToPropagate?.length > 0) {
              valuesToPropagate.forEach((valueToPropagate) => {
                newOrderMaterials = populateSameCostCode.handleBulkChange({
                  triggerIndex: valueToPropagate.index,
                  path: valueToPropagate.path as UsePopulateSameCostCodePath,
                  value: valueToPropagate.value,
                  datasource: newOrderMaterials,
                })
              })
            }

            orderStore.updateOrderMaterialsByDeliveryId(delivery.id, newOrderMaterials)

            if (
              newOrderMaterials.some((material) => !!material?.tax_value) &&
              orderStore.selectedOrder?.project?.tax_line_items?.enabled
            ) {
              const totalTaxSumFromOrderMaterials = newOrderMaterials.reduce(
                (acc, material) => acc + (Number(material?.tax_value) || 0),
                0,
              )
              delivery.tax_value = roundValue(totalTaxSumFromOrderMaterials)
            }

            actions.handleDirtyOrder()
          }}
          disabled={!userStore.canManageOrders && !userStore.canSendAndUpdateOrders}
          projectId={orderStore.selectedOrder?.project_id}
          defaultCostCodeByApplyAll={populateSameCostCode.defaultValues.cost_code as CostCode}
          onApplyDefaultCostCodeFromApplyAll={populateSameCostCode.addToAppliedByAll}
        />
      )}
      <OrderDeliveryCosts
        index={1}
        hideAddTotal={userStore.cannotSendAndUpdateOrders}
        deliveryChargesUnits={delivery.delivery_charges_units}
        onChangeChargesUnit={(value) => (delivery.delivery_charges_units = value)}
        disabled={!userStore.canManageOrders && !userStore.canSendAndUpdateOrders}
        deliveryTotalCost={deliveryTotalCost}
        addEmptyOrderMaterials={() => orderStore.addEmptyOrderMaterials(delivery_id, 5)}
        isPoLocked={isPoLocked}
        onChange={(value) => {
          delivery.discount_value = value.discountValue
          delivery.shipping_value = value.shippingValue
          delivery.other_value = value.otherValue
          delivery.tax_value = value.taxValue

          if (orderStore.selectedOrder?.project?.tax_line_items?.enabled) {
            const newOrderMaterials = calcOrderTaxSplitBulk({
              orderMaterials,
              taxAmount: value.taxValue,
              taxLineItemsEnabled: true,
              precision: companySettingStore.otherSettings?.rounding_precision_settings?.order_precision,
            })
            orderStore.updateOrderMaterialsByDeliveryId(delivery.id, newOrderMaterials)
          }

          actions.handleDirtyOrder()
        }}
        roundingPrecision={companySettingStore.otherSettings?.rounding_precision_settings?.order_precision}
        value={{
          discountValue: delivery?.discount_value,
          shippingValue: delivery?.shipping_value,
          otherValue: delivery?.other_value,
          taxValue: delivery?.tax_value,
        }}
        deliveryName={deliveryName}
      />
    </>
  )
})
