import axios from 'axios'

export interface Environment {
  name: string
  value: string
}

interface EnvironmentsResponse {
  environments: Environment[]
}

export function environments() {
  return axios.get<EnvironmentsResponse>('/environments')
}
