import React, { useRef, useState } from 'react'

import { RefSelectProps } from 'antd/lib/select'

import { observer } from 'mobx-react-lite'

import AddressToDrawer from 'common/components/AddressTo/address_to_drawer'
import AddressToSelector, {
  AddressSelectorProps as CommonAddressSelectorProps,
} from 'common/components/AddressTo/selector'
import { FlexBoxX } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { AddressTo } from 'common/server/addressesTo'

import { useStores } from 'contractor/hooks/use-stores'

type AddressToSelectorProps = { canCreateAttnTo?: boolean } & CommonAddressSelectorProps

export const SelectAddressTo = observer<AddressToSelectorProps>(
  ({ canCreateAttnTo = true, ...props }: AddressToSelectorProps) => {
    const { addressToStore } = useStores()

    const [selectedAddressTo, setSelectedAddressTo] = useState(null)
    const [open, setOpen] = useState(false)

    const drawerCreateAddressToRef = useRef<DrawerRef>()
    const selectRef = useRef<RefSelectProps>()

    const handleSubmitAddressTo = async (addressTo: AddressTo) => {
      const createOrUpdate = addressTo?.id ? addressToStore.updateAddressTo : addressToStore.createAddressTo
      const newAddress = await createOrUpdate(addressTo)
      addressToStore.indexAddressesTo()
      props?.onChange(newAddress?.id, null)
      drawerCreateAddressToRef.current?.close()
    }

    const handleEditAddressTo = (addressTo: AddressTo) => {
      setSelectedAddressTo(addressTo)
      drawerCreateAddressToRef.current?.show()
    }

    const handleCreateAddressTo = () => {
      setOpen(true)
      setSelectedAddressTo(null)
      drawerCreateAddressToRef.current?.show()
    }

    return (
      <FlexBoxX width="100%">
        <AddressToSelector
          onShowAddressToCreate={canCreateAttnTo ? handleCreateAddressTo : undefined}
          onEditAddressTo={canCreateAttnTo ? handleEditAddressTo : undefined}
          wrapperProps={{
            maxWidth: '100%',
            minWidth: '100%',
            width: '100%',
          }}
          open={open}
          onDropdownVisibleChange={setOpen}
          ref={selectRef}
          {...props}
        />
        <AddressToDrawer
          ref={drawerCreateAddressToRef}
          onClose={() => {
            setSelectedAddressTo(null)
            selectRef.current?.focus()
          }}
          onSubmit={handleSubmitAddressTo}
          initialValues={selectedAddressTo}
        />
      </FlexBoxX>
    )
  },
)
