import moment from 'moment'

import { Modal } from 'antd'

import { useNotifyOrder } from 'common/hooks/use-notify-order'

import { useStores } from 'contractor/hooks/use-stores'

import { UseOrderActionsType, UseOrderInternalActions } from './useOrderActions'
import { UseOrderStatesType } from './useOrderStates'

type UseCancelOrderActionProps = Pick<
  UseOrderInternalActions,
  'getRequiredOrderFields' | 'handleValidateProject' | 'handleLogError' | 'handleSaveOrder'
> &
  Pick<UseOrderActionsType, 'handleGoBack'>

export type UseCancelOrderActionType = {
  handleCancelOrder: () => Promise<void>
}

export const useCancelOrderAction = (
  states: UseOrderStatesType,
  actions: UseCancelOrderActionProps,
): UseCancelOrderActionType => {
  const { orderStore, uploaderStore } = useStores()
  const { notifyOrder } = useNotifyOrder({ orderId: orderStore.selectedOrder?.id, operation: 'update' })

  const handleCancelOrder = async () => {
    if (orderStore.anyDelivered) {
      Modal.error({ title: 'You cannot cancel an order that has already been delivered.' })
      return
    }
    if (!uploaderStore.checkIfAllUploadsCompleted()) {
      Modal.error({ title: 'Uploads have not completed yet, please try again.' })
      return
    }
    const orderMaterials = orderStore.filteredOrderedMaterials()
    const errors = orderStore.validateRequiredFields(orderMaterials, actions.getRequiredOrderFields())
    if (errors.length) {
      Modal.error({ title: `Please fill in the following required fields: ${errors.join(', ')}.` })
      return
    }

    const projectErrors = actions.handleValidateProject()
    if (projectErrors.length) {
      Modal.error({ title: projectErrors[0] })
      return
    }

    states.setCountActionClicked(1)

    try {
      const orderType = states.isRFQ ? 'RFQ' : 'Order'
      await actions.handleSaveOrder({ cancelled_at: moment().toISOString() }, false)
      actions.handleGoBack()
      notifyOrder({ message: `Cancelled ${orderType}` })
    } catch (error) {
      actions.handleLogError(error, 'Unable to cancel the order.')
    } finally {
      states.setCountActionClicked(-1)
    }
  }

  return { handleCancelOrder }
}
