import React, { useEffect, useMemo, useState } from 'react'

import { useTheme } from '@emotion/react'

import { Alert, Button, Form, message, Progress, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { PdfViewer } from 'common/components/PdfViewer'
import { InvoiceInboxPdfFilesResponse, InvoiceInboxResponse } from 'common/server/invoice_inbox'
import { OrderHit } from 'common/server/orders'

import { useStores } from 'contractor/hooks/use-stores'
import { CommonInvoiceForm } from 'contractor/pages/Invoices/Add/common_invoice_form'
import { FailedInboxPdfs } from 'contractor/pages/Invoices/Inbox/Action/invoice_pdf_selector'
import { CreateInvoicePayload } from 'contractor/server/invoices/invoice'

type CreateFailedInvoiceDrawerProps = {
  failedInboxPdfs: FailedInboxPdfs
  setSubmitting?: React.Dispatch<React.SetStateAction<boolean>>
  onClose: () => void
}

export const CreateFailedInvoiceDrawer = observer<CreateFailedInvoiceDrawerProps, DrawerRef>(
  ({ setSubmitting, failedInboxPdfs, onClose }, ref) => {
    const { invoiceStore, invoiceInboxStore } = useStores()
    const theme = useTheme()

    const [alertMessage, setAlertMessage] = useState('')
    const [selectedOrders, setSelectedOrders] = useState<OrderHit[]>([])
    const [submittingForm, setSubmittingForm] = useState(false)
    const [openPdfViewer, setOpenPdfViewer] = useState(false)
    const [uploadUrl, setUploadUrl] = useState('')
    const [executeStep, setExecuteStep] = useState(1)
    const [failedPdfFile, setFailedPdfFile] = useState<Nullable<InvoiceInboxPdfFilesResponse>>(null)
    const [invoiceInbox, setInvoiceInbox] = useState<Nullable<InvoiceInboxResponse>>(null)
    const [total, setTotal] = useState(1)

    const [form] = Form.useForm()

    const clearForm = () => {
      form.resetFields()
      setSelectedOrders([])
      setAlertMessage('')
    }

    const createInvoice = async (formValues) => {
      setSubmitting?.(true)
      setSubmittingForm(true)

      const { number, assigned_to_id, company_vendor_id, due_date, document_date, total_amount, order_ids } = formValues
      try {
        const payload: CreateInvoicePayload = {
          order_ids,
          number,
          assigned_to_id,
          company_vendor_id,
          due_date,
          document_date,
          total_amount,
          invoice_inbox_id: failedInboxPdfs.inbox_id,
          file_signed_id: failedPdfFile.id,
        }

        await invoiceStore.create(payload)
        message.success('Invoice added')
        clearForm()
        setOpenPdfViewer(false)
        if (executeStep === total) {
          setInvoiceInbox(null)
          setExecuteStep(1)
          onClose()
        } else {
          setExecuteStep(executeStep + 1)
        }
      } catch (err) {
        console.error(`Unable create invoice: `, err?.response?.data)
        setAlertMessage(`Unable to create invoice: ${number}`)
      } finally {
        setSubmitting?.(false)
        setSubmittingForm(false)
      }
    }

    const drawerWidth = useMemo(() => {
      return openPdfViewer ? 1024 : 378
    }, [openPdfViewer])

    useEffect(() => {
      if (failedInboxPdfs?.inbox_id) {
        invoiceInboxStore.getInvoiceInbox(failedInboxPdfs.inbox_id).then((inbox) => {
          setInvoiceInbox(inbox)
        })
      }
    }, [failedInboxPdfs])

    useEffect(() => {
      if (!invoiceInbox) return

      const step = failedInboxPdfs.steps.find((s) => s.step === executeStep)
      const foundPdfFile = invoiceInbox.pdf_files.find((file) => file.id === step.file_id)
      setFailedPdfFile(foundPdfFile)
      setUploadUrl(foundPdfFile?.url)
      setOpenPdfViewer(true)
      setTotal(failedInboxPdfs.total)
    }, [failedInboxPdfs, executeStep, invoiceInbox])

    return (
      <Drawer
        closable
        onClose={onClose}
        ref={ref}
        width={drawerWidth}
        title={
          <Box display="flex" width="100%" height="100%" alignItems="flex-start" justifyContent="space-between">
            <Box display="inline-flex" alignItems="flex-start">
              <Typography.Text style={{ marginTop: 5 }}>Manually create Invoices</Typography.Text>
            </Box>
            <Box display="inline-flex" alignItems="flex-end">
              <Progress
                type="line"
                percent={(executeStep / total) * 100}
                steps={total}
                strokeWidth={20}
                style={{ marginRight: 60, padding: 6 }}
                format={() => `PDF ${executeStep} of ${total}`}
                strokeColor={theme.colors['green-6']}
              />
              <Button style={{ width: '100%' }} type="default" onClick={clearForm}>
                Reset
              </Button>
            </Box>
          </Box>
        }
        forceRender
      >
        <Box display="flex" width="100%">
          <Box
            display={openPdfViewer ? 'inline-flex' : 'none'}
            justifyContent="flex-start"
            width={646}
            style={{
              borderRadius: 3,
              overflowY: 'auto',
              overflowX: 'hidden',
              height: '100%',
            }}
            p="20px 0 20px 20px"
          >
            <PdfViewer url={uploadUrl} key={uploadUrl} />
          </Box>
          <Box width={378} overflowY="auto" p={20}>
            <Form
              id="create-failed-invoice-form"
              style={{ width: '100%' }}
              form={form}
              onFinish={createInvoice}
              layout="vertical"
            >
              {alertMessage && <Alert type="error" message={alertMessage} style={{ marginBottom: '20px' }} />}

              <CommonInvoiceForm form={form} selectedOrders={selectedOrders} setSelectedOrders={setSelectedOrders} />
              <Button
                style={{ width: '100%' }}
                type="primary"
                loading={submittingForm}
                form="create-failed-invoice-form"
                key="submit"
                htmlType="submit"
              >
                {`Create Invoice ${executeStep} of ${total}`}
              </Button>
            </Form>
          </Box>
        </Box>
      </Drawer>
    )
  },
  { forwardRef: true },
)
