import React from 'react'

import { useStores } from 'contractor/hooks/use-stores'
import { ManagedUser } from 'contractor/server/company_settings/manage_users'
import { ProjectGroupsShow } from 'contractor/server/project_groups'
import { IndexProject } from 'contractor/server/projects'

import { ManageGroupDrawerForm } from './manage_group_drawer'

interface UseManageGroupDrawer {
  projectGroup: ProjectGroupsShow
  projects: Array<IndexProject>
  users: Array<ManagedUser>
  defaultValuesFactory: (groupId: string) => Promise<ManageGroupDrawerForm & { id: string }>
  dataLoaders: {
    projects: () => void
    users: () => void
  }
}

export function makeProjectName(project: IndexProject) {
  if (!project.active) {
    return {
      ...project,
      name: `[Inactive] - ${project.name}`,
    }
  }

  return project
}

export const useManageGroupDrawer = (): UseManageGroupDrawer => {
  const { projectStore, projectGroupsStore, companySettingStore } = useStores()

  const [projectGroup, setProjectGroup] = React.useState<ProjectGroupsShow>(null)

  const loadProjects = () => Promise.all([projectStore.indexProjects(), projectStore.indexAllProjects()])

  const loadUsers = () => companySettingStore.indexUsers()

  const defaultValuesFactory = async (groupId: string) => {
    const group = await projectGroupsStore.showProjectGroups(groupId)
    setProjectGroup(group)

    return {
      id: groupId,
      name: group.name,
      selectedProjectsIds: group.project_ids,
      selectedCompanyUsersIds: group.company_user_ids,
    }
  }

  return {
    projectGroup,
    projects: [...projectStore.allProjects, ...projectStore.allInactiveProjects].map(makeProjectName),
    users: companySettingStore.users,
    defaultValuesFactory,
    dataLoaders: {
      projects: loadProjects,
      users: loadUsers,
    },
  }
}
