import { filter } from 'lodash'
import { BindAll } from 'lodash-decorators'

import { observable } from 'mobx'

import { AllAddresses, index } from 'contractor/server/addresses'

@BindAll()
export default class AddressStore {
  @observable addresses: AllAddresses

  async indexAddresses(): Promise<void> {
    const response = await index()
    this.addresses = response.data
  }

  maybeIndexAddresses() {
    if (!this.addresses) {
      return this.indexAddresses()
    }
  }

  // Filter addresses to only how relevant projects
  filteredAddresses(projectIds: string[]) {
    if (!this.addresses) {
      return { company: [], project: [], companyVendor: [] }
    }

    const company = this.addresses.company || []
    const companyVendor = this.addresses.company_vendor || []
    const project = filter(this.addresses.project, (address) => projectIds.includes(address.project_id)) || []

    return {
      company,
      project,
      companyVendor,
    }
  }
}
