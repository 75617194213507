import axios, { AxiosResponse } from 'axios'

export type ExpenseAccounts = {
  data: {
    id: string
    name: string
  }[]
}

export type LedgerAccounts = {
  data: {
    id: string
    external_id: string
    external_name: string
  }[]
}

export type QboClasses = {
  data: {
    id: string
    external_id: string
    external_name: string
  }[]
}

export function resync_wbs_procore() {
  return axios.patch('/integrations/accounting/extras/procore/wbs-resync')
}

export function auto_create_and_map_phase_codes() {
  return axios.patch('/integrations/accounting/extras/acumatica/auto_create_and_map_phase_codes')
}

export function expense_accounts(): Promise<AxiosResponse<ExpenseAccounts>> {
  return axios.get('/integrations/accounting/extras/qbd/expense_accounts')
}

export function ledger_accounts(): Promise<AxiosResponse<LedgerAccounts>> {
  return axios.get('/integrations/accounting/extras/ledger_accounts')
}

export function qbo_classes(): Promise<AxiosResponse<QboClasses>> {
  return axios.get('/integrations/accounting/extras/qbo/classes')
}
