import { BindAll } from 'lodash-decorators'

import { observable } from 'mobx'

import { AddressTo } from 'common/server/addressesTo'

import { index, create, update } from 'contractor/server/addresses_to'

@BindAll()
export default class AddressToStore {
  addressesTo = observable.array<AddressTo>([])

  async indexAddressesTo(params: { active: boolean } = { active: true }): Promise<void> {
    const response = await index(params)
    this.addressesTo.replace(response.data)
  }

  async createAddressTo(payload: AddressTo) {
    const response = await create(payload)
    this.addressesTo.push(response.data)
    return response.data
  }

  async updateAddressTo(payload: AddressTo) {
    const { data } = await update(payload)
    this.addressesTo.replace(this.addressesTo.map((addressesTo) => (addressesTo.id === data.id ? data : addressesTo)))
    return data
  }
}
