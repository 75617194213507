import React, { useState } from 'react'

import { UploadOutlined } from '@ant-design/icons'
import { Button, Modal, Tooltip, Typography } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { UploadProps } from 'antd/lib/upload'

import { Box, FlexBoxX } from 'common/components/boxes'
import Uploader, { FileControl } from 'common/components/uploader'
import theme from 'common/styles/theme'

type UploaderProps = FileControl &
  Pick<UploadProps, 'onPreview'> & {
    accept?: string
    disabled?: boolean
    multiple: boolean
    onChange?: () => void
    title?: string
    extra?: React.ReactNode
    uploadButtonText?: string
    type?: 'icon' | 'text'
    size?: SizeType
    modalTitle?: string
  }

export const UploaderTitle = ({
  accept,
  disabled,
  multiple,
  fileList,
  onChange,
  title = '',
  uploadKey,
  onUpdateUpload,
  onRemoveUpload,
  onResetUploads,
  onAddNewUpload,
  onSetUploadError,
  onPreview,
  uploadButtonText = '',
  type = 'icon',
  size = 'small',
  modalTitle = '',
  ...props
}: UploaderProps) => {
  const typeIsText = type === 'text'

  const [modalVisible, setModalVisibility] = useState(false)

  const handlePreview = (file) => {
    window.open(file?.url)
    onPreview?.(file)
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" width="100%">
        <FlexBoxX display="flex" alignItems="start" width="100%" justifyContent="space-between">
          {!!title && <Typography.Text color={theme.colors['gray-8']}>{title}</Typography.Text>}
          <Box display="flex">
            {!disabled && (
              <Tooltip title="Add attachment">
                <Button
                  data-cy={`upload-${uploadButtonText}-button`}
                  size={size}
                  onClick={() => setModalVisibility(true)}
                  icon={<UploadOutlined />}
                  type={typeIsText ? 'default' : 'text'}
                >
                  {typeIsText && uploadButtonText}
                </Button>
              </Tooltip>
            )}
          </Box>
        </FlexBoxX>
      </Box>

      <Modal
        title={`Attach ${modalTitle}`}
        open={!disabled && modalVisible}
        onCancel={() => setModalVisibility(false)}
        onOk={() => setModalVisibility(false)}
        destroyOnClose
      >
        <Uploader
          disabled={disabled}
          multiple={multiple}
          onChange={onChange}
          uploadKey={uploadKey}
          noResetUploads
          fileList={fileList}
          accept={accept}
          onUpdateUpload={onUpdateUpload}
          onResetUploads={onResetUploads}
          onAddNewUpload={onAddNewUpload}
          onSetUploadError={onSetUploadError}
          onRemoveUpload={onRemoveUpload}
          onPreview={handlePreview}
          {...props}
        />
      </Modal>
    </Box>
  )
}
