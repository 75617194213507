import React from 'react'

import { Layout as AntdLayout } from 'antd'

import { isStaging } from 'common/helpers/check_environment'
import { useTheme } from 'common/hooks/use-theme'

type LayoutProps = {
  children: React.ReactNode
  header?: React.ReactNode
}

const headerHeight = '40px'

export const Layout = ({ children, header }: LayoutProps) => {
  const theme = useTheme()

  const headerStyle = {
    position: 'fixed' as const,
    width: '100%',
    height: headerHeight,
    lineHeight: headerHeight,
    zIndex: 20,
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    color: theme.colors.white,
  }

  return (
    <AntdLayout style={{ backgroundColor: '#ffff', height: '100%' }} className={isStaging() && 'staging'}>
      <AntdLayout.Header style={headerStyle}>{header}</AntdLayout.Header>
      <AntdLayout.Content style={{ marginTop: headerHeight }}>{children}</AntdLayout.Content>
    </AntdLayout>
  )
}
