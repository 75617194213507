import React, { forwardRef, useState } from 'react'

import { flatten } from 'lodash'

import { Typography, Alert } from 'antd'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import OrderState from 'common/components/statuses/order_state'
import { InvoicesStates } from 'common/server/server_types'

import { ShowOrderResponse } from 'contractor/server/orders'

import { Footer } from './footer'
import { TableCosts } from './table_costs'
import { TableItems } from './table_items'

type InvoiceOrderMaterialProps = {
  order: ShowOrderResponse
}

export const InvoiceOrderMaterial = forwardRef<DrawerRef, InvoiceOrderMaterialProps>((props, ref) => {
  const { order: orderProp } = props

  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([])

  const rejectedInvoicesRelated = orderProp?.invoices?.filter(({ state }) => state?.state === InvoicesStates.REJECTED)
  const rejectedInvoicesRelatedIds = rejectedInvoicesRelated?.map((invoice) => invoice.id)

  // Remove rejected invoices from the current order to don't mess up the calculation and presentations
  const order = {
    ...orderProp,
    invoices: orderProp?.invoices?.filter((invoice) => !rejectedInvoicesRelatedIds.includes(invoice.id)),
    order_materials: orderProp?.order_materials?.map((orderMaterial) => ({
      ...orderMaterial,
      invoice_materials: orderMaterial.invoice_materials.filter(
        (invoiceMaterial) => !rejectedInvoicesRelatedIds.includes(invoiceMaterial.invoice_id),
      ),
    })),
  }

  const handleToggleExpandRowKeys = () => {
    if (expandedRowKeys?.length) {
      setExpandedRowKeys([])
    } else {
      const columnsKeys = order?.order_materials?.map((orderMaterial) => orderMaterial.id)
      setExpandedRowKeys([...columnsKeys, 'Tax', 'Shipping', 'Discount', 'Other'])
    }
  }

  const unrelatedInvoiceMaterials = flatten(order?.invoices?.map((invoice) => invoice.invoice_materials))?.filter(
    (invoiceMaterial) => !invoiceMaterial?.order_materials_count,
  )

  return (
    <Drawer
      title={
        <Typography.Text>
          Order{' '}
          <Typography.Link
            href={`/order/${order?.id}`}
            target="blank"
            style={{ textDecoration: 'underline' }}
          >{`${order?.order_number}:${order?.order_package_name}`}</Typography.Link>
        </Typography.Text>
      }
      extra={
        <OrderState
          state={order?.state}
          sub_state={order?.sub_state}
          showIssue={order?.deliveries?.some((delivery) => delivery.has_open_issue)}
          deliveryIssues={order?.deliveries?.map((delivery) => delivery.delivery_issue_type)}
          size="medium"
        />
      }
      ref={ref}
      closable={false}
      width={980}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <Box width="100%" overflow="auto" flexGrow={1} p={16}>
          <TableItems
            order={order}
            expandedRowKeys={expandedRowKeys}
            setExpandedRowKeys={setExpandedRowKeys}
            onToggleExpandRowKeys={handleToggleExpandRowKeys}
          />

          <TableCosts
            order={order}
            expandedRowKeys={expandedRowKeys}
            setExpandedRowKeys={setExpandedRowKeys}
            onToggleExpandRowKeys={handleToggleExpandRowKeys}
          />
        </Box>

        {!!unrelatedInvoiceMaterials?.length && (
          <Alert
            style={{ padding: 16, margin: '0px 16px 16px 16px' }}
            type="warning"
            message={`This order includes ${unrelatedInvoiceMaterials?.length} unmatched invoice materials, which are not represented in the table.`}
          />
        )}

        {!!rejectedInvoicesRelated?.length && (
          <Alert
            style={{ padding: 16, margin: '0px 16px 16px 16px' }}
            type="error"
            message={
              <span>
                This order is attached to rejected invoices (
                {rejectedInvoicesRelated.map((invoice, index) => (
                  <Typography.Link key={`rejected-${invoice.id}`} href={`/invoice/${invoice.id}`} target="_blank">
                    {(index ? ', ' : '') + `#${invoice.number}`}
                  </Typography.Link>
                ))}
                ) which are not represented in these calculations.
              </span>
            }
          />
        )}

        <Footer order={order} />
      </Box>
    </Drawer>
  )
})
