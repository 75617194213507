import React from 'react'

import { Typography } from 'antd'

import { noticeError } from 'common/helpers/new_relic'
import { InvoiceInboxFilesStates } from 'common/server/server_types'

import { InvoiceInboxFileHit } from 'contractor/server/invoices/inbox'

const parseFailMessage = (failMessage: string) => {
  try {
    return JSON.parse(failMessage)
  } catch (error) {
    noticeError('Failed to parse fail message in the invoice inbox list.', { failMessage })
    return []
  }
}

// @todo: change this type (InvoiceInboxFileHit): FailedPdfDetails
export function FailedPdfDetails({ files }: { files: InvoiceInboxFileHit[] }) {
  return (
    <Typography.Paragraph style={{ paddingTop: 10 }}>
      {files
        .filter((file) => file.state === InvoiceInboxFilesStates.FAILED)
        .map((file) => {
          // This is a temporary fix, should be removed when the backend is fixed
          const railsHashJson = file.fail_message ? file.fail_message.replace(/=>/g, ':').replace(/nil/g, 'null') : '[]'
          const messages = parseFailMessage(railsHashJson)
          return (
            <Typography.Paragraph key={`${file.filename}-key`}>
              <a href={file.url} target="_blank" rel="noreferrer">
                {file.filename}
              </a>
              &nbsp;has failed
              {messages && messages.length > 0 && (
                <ul>
                  {messages.map(({ reason, page }, idx) => (
                    <li key={`fail-message-${idx}`}>
                      {page > 0 && <b>{`Page ${page}: `}</b>}
                      {reason}
                    </li>
                  ))}
                </ul>
              )}
            </Typography.Paragraph>
          )
        })}
    </Typography.Paragraph>
  )
}
