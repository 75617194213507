import React, { useRef } from 'react'

import moment from 'moment'

import { CalendarOutlined, DollarOutlined } from '@ant-design/icons'
import NumberIcon from '@ant-design/icons/NumberOutlined'
import { DatePicker, Input, Space, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { CustomEditableContent } from 'common/components/CustomEditableContent'
import { InputCurrency } from 'common/components/InputCurrency'
import { Visibility } from 'common/components/Visibility'
import { currencyFormatter, formatDateStringShort } from 'common/helpers/formatters'

import { AgaveSyncStatusDetailed } from 'contractor/components/AgaveSyncStatusDetailed'
import { SelectUser, makeCompanyUserOption } from 'contractor/components/SelectUser'
import { useStores } from 'contractor/hooks/use-stores'

type InvoiceInfoProps = {
  onSelectAssign: (id: string) => void
  assignTo: string
  disabled?: boolean
  disabledAssignee?: boolean
}

export const InvoiceInfo = observer<InvoiceInfoProps>(({ onSelectAssign, assignTo, disabled, disabledAssignee }) => {
  const { invoiceStore, integrationStore, userStore, invoiceSettingsStore } = useStores()

  const accountingDateEnabled = invoiceSettingsStore?.settings?.accounting_date_enabled

  const datePickerRef = useRef()

  const invoice = invoiceStore.invoice

  const canShowIntegration =
    userStore.canUseIntegrations && integrationStore.connected && integrationStore.invoiceSyncEnabled

  return (
    <Box display="flex" alignItems="baseline" justifyContent="space-between" width="100%">
      <Box display="flex" alignItems="center" flexDirection={{ _: 'column', lg: 'row' }} style={{ gap: 20 }}>
        <Tooltip title="Invoice Number" placement="top">
          <Box display="flex" flexDirection={{ _: 'column', lg: 'row' }}>
            <Visibility.Show breakpoint="lg">
              <Typography.Text type="secondary">INVOICE NUMBER</Typography.Text>
            </Visibility.Show>
            <CustomEditableContent
              boxProps={{ mt: 0 }}
              disabled={disabled}
              input={
                <Input
                  value={invoice?.number}
                  disabled={disabled}
                  onChange={(e) => invoiceStore.updateSelectedInvoice('number', e.target.value)}
                  style={{ width: 180 }}
                />
              }
            >
              <Space>
                <NumberIcon />
                <Typography.Text>{invoice.number || 'Invoice Number'}</Typography.Text>
              </Space>
            </CustomEditableContent>
          </Box>
        </Tooltip>

        <Tooltip title="Invoice Date" placement="top">
          <Box display="flex" flexDirection={{ _: 'column', lg: 'row' }}>
            <Visibility.Show breakpoint="lg">
              <Typography.Text type="secondary">INVOICE DATE</Typography.Text>
            </Visibility.Show>

            <CustomEditableContent
              boxProps={{ ml: 4 }}
              disabled={disabled}
              refs={[datePickerRef]}
              input={
                <DatePicker
                  disabled={disabled}
                  panelRender={(originPanel) => {
                    return <div ref={datePickerRef}>{originPanel}</div>
                  }}
                  format="MM/DD/YYYY"
                  value={invoice.document_date ? moment(invoice.document_date) : undefined}
                  onChange={(value) =>
                    invoiceStore.updateSelectedInvoice('document_date', value?.toISOString() || null)
                  }
                  autoFocus
                  open
                />
              }
            >
              <Space>
                <CalendarOutlined />
                <Typography.Text>{formatDateStringShort(invoice.document_date, 'Unknown')}</Typography.Text>
              </Space>
            </CustomEditableContent>
          </Box>
        </Tooltip>

        {accountingDateEnabled && (
          <Tooltip title="Accounting Date" placement="top">
            <Box display="flex" flexDirection={{ _: 'column', lg: 'row' }}>
              <Visibility.Show breakpoint="lg">
                <Typography.Text type="secondary">ACCOUNTING DATE</Typography.Text>
              </Visibility.Show>
              <CustomEditableContent
                boxProps={{ ml: 4 }}
                disabled={disabled}
                refs={[datePickerRef]}
                input={
                  <DatePicker
                    panelRender={(originPanel) => {
                      return <div ref={datePickerRef}>{originPanel}</div>
                    }}
                    format="MM/DD/YYYY"
                    value={invoice.accounting_date ? moment(invoice.accounting_date) : undefined}
                    onChange={(value) =>
                      invoiceStore.updateSelectedInvoice('accounting_date', value?.toISOString() || null)
                    }
                    autoFocus
                    open
                  />
                }
              >
                <Space>
                  <CalendarOutlined />
                  <Typography.Text>{formatDateStringShort(invoice.accounting_date, 'Unknown')}</Typography.Text>
                </Space>
              </CustomEditableContent>
            </Box>
          </Tooltip>
        )}

        <Tooltip title="Total Amount" placement="top">
          <Box display="flex" flexDirection={{ _: 'column', lg: 'row' }}>
            <Visibility.Show breakpoint="lg">
              <Typography.Text type="secondary">GRAND TOTAL</Typography.Text>
            </Visibility.Show>
            <CustomEditableContent
              boxProps={{ ml: 4 }}
              disabled={disabled}
              input={
                <InputCurrency
                  value={invoice.total_amount}
                  disabled={disabled}
                  onChange={(value = '') => invoiceStore.updateSelectedInvoice('total_amount', value)}
                  autoFocus
                />
              }
            >
              <Space>
                <DollarOutlined />
                <Typography.Text>
                  {invoice.total_amount ? currencyFormatter(invoice.total_amount) : 'Unknown'}
                </Typography.Text>
              </Space>
            </CustomEditableContent>
          </Box>
        </Tooltip>

        {canShowIntegration && (
          <AgaveSyncStatusDetailed
            integration={invoiceStore.invoice?.integration}
            integrationName={integrationStore.getIntegrationName(invoiceStore.invoice?.integration?.source)}
            webUrl={invoiceStore.invoice?.integration?.web_url}
          />
        )}
      </Box>

      <Visibility.Hidden breakpoint="lg">
        <Box display="inline-flex" alignItems="baseline">
          <Typography.Text>Assignee</Typography.Text>
          <SelectUser
            disabled={disabledAssignee}
            onlyActives
            onChange={onSelectAssign}
            value={assignTo}
            makeOption={makeCompanyUserOption}
            placeholder="Search for user"
            boxProps={{ minWidth: '180px', width: 'auto', ml: 8 }}
            showAssignToMe={false}
            showAssignToMeOnDropdown
          />
        </Box>
      </Visibility.Hidden>
    </Box>
  )
})
