import axios from 'axios'

import { noticeError } from 'common/helpers/new_relic'

// Need to also set this here (in addition to use-stores.ts b/c stores aren't used on landing page)
const csrfToken = (document.querySelector('[name=csrf-token]') as HTMLMetaElement).content
axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
axios.defaults.headers.common['responseType'] = 'json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json'

const notify = (error) => {
  const extra =
    typeof error !== 'object'
      ? { error }
      : {
          headers: error?.config?.headers,
          data: error?.config?.data,
          method: error?.config?.method,
          url: error?.config?.url,
          error: JSON.stringify(error),
        }

  if (error.response) {
    noticeError(error, {
      entry: 'axios-response',
      payload: error.response,
      extra,
    })
  } else if (error.request) {
    noticeError(error, {
      entry: 'axios-request',
      payload: {
        response: error.request?.response,
        'response-type': error.request?.responseType,
        'response-text': error.request?.responseText,
        'response-url': error.request?.responseUrl,
        'response-headers': error.request?.getAllResponseHeaders?.(),
        status: error.request?.status,
        'status-text': error.request?.statusText,
      },
    })
  } else {
    noticeError(error, { entry: 'axios', payload: error.toJSON() })
  }
}

// Add interceptor to handle response errors
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    /*
      If the backend returns 401 or 403 error and the user is already logged in it's will be
      redirected to login page, if is a vendor will send to /vendor else send to /
    */
    if ([401].includes(error?.response?.status) && !error?.config?.url.includes('/users/sign_in')) {
      window.location.href = window.location.pathname.startsWith('/vendor') ? '/vendor' : '/'
    } else {
      const { config, message } = error

      // retry while Network timeout or Network Error
      if (!(message.includes('timeout') || message.includes('Network Error'))) {
        notify(error)
        return Promise.reject(error)
      }

      if (Number(config?.retry) === 0) {
        notify(error)
        return Promise.reject(error)
      }

      if (!config.retry) {
        config.retry = 3
      }
      if (!config.retryDelay) {
        config.retryDelay = 3000
      }
      config.retry = config.retry - 1
      const delayRetryRequest = new Promise((resolve) => {
        setTimeout(() => {
          console.log('Retrying the request', config.url)
          resolve()
        }, config.retryDelay)
      })
      return delayRetryRequest.then(() => axios(config))
    }
  },
)
