import { formatDateStringShort } from 'common/helpers/formatters'
import { calcExtCost } from 'common/helpers/order'

import { ConsolidatedOrders, ShowOrderResponse } from 'contractor/server/orders'

/*
  The task with the configuration params settings:
  https://www.notion.so/subbase/TCM-Export-Updates-5eb2b2ea66b846b896c17c63ad45015e
*/

const getExpenseCode = (costCodeClass: string) => {
  if (costCodeClass === 'M') {
    return 730000
  }

  if (costCodeClass === 'E') {
    return 765000
  }
}

const makeShipVia = (shipVia) => ({
  Quantity: '',
  'Item Description': '',
  'Tax Code': '',
  'Tax Amount': '',
  UOM: '',
  'Unit Cost': '',
  'Extended Amt': '',
  Property: '',
  'Job D': '',
  Phase: '',
  Cost: '',
  'Cost Type': '',
  'Expense Code': '',
  'Acct Date': '',
  'Description D': '',
  Amount: '',
  'Buyout Amount': '',
  Building: '',
  Unit: '',
  Notes: '',
  'Ship Address Line 1': '',
  'Ship Address Line 2': '',
  'Ship Address Line 3': '',
  City: '',
  State: '',
  Zip: '',
  Country: '',
  Instructions: '',
  Buyer: '',
  'Confirming PO': '',
  'Ack Required?': '',
  'Ack Date': '',
  'Confirming to': '',
  'F.O.B.': '',
  Freight: '',
  'Ship Via': shipVia,
  Terms: '',
  SeqNo: '',
  Warehouse: '',
  Item: '',
})

export const makeTCMFileConsolidated = (consolidatedOrder: ConsolidatedOrders.Order) => {
  const firstMaterial = consolidatedOrder?.order_materials?.[0]
  const costCodeNumber = firstMaterial?.cost_code?.code ? `${firstMaterial?.cost_code?.code}-` : ''
  const costCodeClass = firstMaterial?.cost_code?.clazz ? firstMaterial?.cost_code?.clazz : ''
  const costCode = costCodeNumber + costCodeClass

  const shipVia = consolidatedOrder.deliveries[0]?.is_pick_up ? 'Pick Up' : 'Delivery'

  const header = {
    'Line Type': 'H',
    'PO Number': consolidatedOrder.order_number,
    Vendor: consolidatedOrder.external_vendor_id || consolidatedOrder.vendor_name,
    Status: 'O',
    Date: formatDateStringShort(consolidatedOrder.ordered_at),
    'Description H': `${consolidatedOrder.order_number}${costCode ? `-${costCode}` : ''}`,
    'Date Required': '',
    Entity: '25',
    'Job H': consolidatedOrder.project_number,
    Taxable: 'N',
    'Submittal Required': 'N',
    'Received Date': '',
    'One to One': 'Y',
    ...makeShipVia(shipVia),
  }

  const materials = consolidatedOrder?.order_materials?.map((orderMaterial) => {
    const extCost = calcExtCost({
      unitCost: orderMaterial?.unit_cost,
      quantity: orderMaterial?.quantity,
      multiplier: orderMaterial?.company_material?.unit?.multiplier,
      qtyIncrement: orderMaterial?.company_material?.unit?.qty_increment,
    })

    return {
      'Line Type': 'D',
      Quantity: orderMaterial.quantity,
      'Item Description': orderMaterial.company_material?.description,
      'Tax Code': '',
      'Tax Amount': '',
      UOM: orderMaterial.company_material?.unit?.name || orderMaterial.company_material?.unit_name,
      'Unit Cost': orderMaterial?.unit_cost,
      'Extended Amt': extCost,
      Property: '25',
      'Job D': consolidatedOrder.project_number,
      Phase: '0',
      Cost: orderMaterial?.cost_code?.code,
      'Cost Type': orderMaterial?.cost_code?.clazz,
      'Expense Code': getExpenseCode(orderMaterial?.cost_code?.clazz),
      'Acct Date': formatDateStringShort(consolidatedOrder.ordered_at),
      'Description D': orderMaterial.company_material?.description,
      Amount: extCost,
      'Buyout Amount': '',
      Building: '',
      Unit: '',
      Notes: '',
      'Ship Address Line 1': '',
      'Ship Address Line 2': '',
      'Ship Address Line 3': '',
      City: '',
      State: '',
      Zip: '',
      Country: '',
      Instructions: '',
      Buyer: '',
      'Confirming PO': '',
      'Ack Required?': '',
      'Ack Date': '',
      'Confirming to': '',
      'F.O.B.': '',
      Freight: '',
      'Ship Via': '',
      Terms: '',
      SeqNo: '',
      Warehouse: '',
      Item: '',
    }
  })

  const totalTaxes = consolidatedOrder?.deliveries?.reduce((acc, delivery) => acc + Number(delivery?.tax_value), 0)
  const taxItem = {
    'Line Type': 'D',
    Quantity: 1,
    'Item Description': 'Sales Taxes',
    'Tax Code': '',
    'Tax Amount': '',
    UOM: '',
    'Unit Cost': '',
    'Extended Amt': totalTaxes,
    Property: '25',
    'Job D': consolidatedOrder.project_number,
    Phase: '0',
    Cost: '004000',
    'Cost Type': 'M',
    'Expense Code': '730000',
    'Acct Date': formatDateStringShort(consolidatedOrder.ordered_at),
    'Description D': 'Sales Taxes',
    Amount: totalTaxes,
    'Buyout Amount': '',
    Building: '',
    Unit: '',
    Notes: '',
    'Ship Address Line 1': '',
    'Ship Address Line 2': '',
    'Ship Address Line 3': '',
    City: '',
    State: '',
    Zip: '',
    Country: '',
    Instructions: '',
    Buyer: '',
    'Confirming PO': '',
    'Ack Required?': '',
    'Ack Date': '',
    'Confirming to': '',
    'F.O.B.': '',
    Freight: '',
    'Ship Via': '',
    Terms: '',
    SeqNo: '',
    Warehouse: '',
    Item: '',
  }

  return [header, ...materials, taxItem]
}

export const makeTCMFileOrderDetail = (orderDetail: ShowOrderResponse) => {
  const firstMaterial = orderDetail?.order_materials?.[0]
  const costCodeNumber = firstMaterial?.cost_code?.code ? `${firstMaterial?.cost_code?.code}-` : ''
  const costCodeClass = firstMaterial?.cost_code?.clazz ? firstMaterial?.cost_code?.clazz : ''
  const costCode = costCodeNumber + costCodeClass

  const shipVia = orderDetail.deliveries[0]?.is_pick_up ? 'Pick Up' : 'Delivery'

  const header = {
    'Line Type': 'H',
    'PO Number': orderDetail.order_number,
    Vendor:
      orderDetail.company_vendor?.external_vendor_id ||
      orderDetail.company_vendor?.vendor?.name ||
      orderDetail.company_vendor?.vendor_name,
    Status: 'O',
    Date: formatDateStringShort(orderDetail.ordered_at),
    'Description H': `${orderDetail.order_number}${costCode ? `-${costCode}` : ''}`,
    'Date Required': '',
    Entity: '25',
    'Job H': orderDetail.project?.project_id,
    Taxable: 'N',
    'Submittal Required': 'N',
    'Received Date': '',
    'One to One': 'Y',
    ...makeShipVia(shipVia),
  }

  const materials = orderDetail?.order_materials?.map((orderMaterial) => {
    const extCost = calcExtCost({
      unitCost: Number(orderMaterial?.unit_cost),
      quantity: orderMaterial?.quantity,
      multiplier: orderMaterial?.company_material?.unit?.multiplier,
      qtyIncrement: orderMaterial?.company_material?.unit?.qty_increment,
    })

    return {
      'Line Type': 'D',
      Quantity: orderMaterial.quantity,
      'Item Description': orderMaterial.company_material?.description,
      'Tax Code': '',
      'Tax Amount': '',
      UOM: orderMaterial.company_material?.unit?.name || orderMaterial.company_material?.unit_name,
      'Unit Cost': orderMaterial?.unit_cost,
      'Extended Amt': extCost,
      Property: '25',
      'Job D': orderDetail.project?.project_id,
      Phase: '0',
      Cost: orderMaterial?.cost_code?.code,
      'Cost Type': orderMaterial?.cost_code?.clazz,
      'Expense Code': getExpenseCode(orderMaterial?.cost_code?.clazz),
      'Acct Date': formatDateStringShort(orderDetail.ordered_at),
      'Description D': orderMaterial.company_material?.description,
      Amount: extCost,
      'Buyout Amount': '',
      Building: '',
      Unit: '',
      Notes: '',
      'Ship Address Line 1': '',
      'Ship Address Line 2': '',
      'Ship Address Line 3': '',
      City: '',
      State: '',
      Zip: '',
      Country: '',
      Instructions: '',
      Buyer: '',
      'Confirming PO': '',
      'Ack Required?': '',
      'Ack Date': '',
      'Confirming to': '',
      'F.O.B.': '',
      Freight: '',
      'Ship Via': '',
      Terms: '',
      SeqNo: '',
      Warehouse: '',
      Item: '',
    }
  })

  const totalTaxes = orderDetail?.deliveries?.reduce((acc, delivery) => acc + Number(delivery?.tax_value), 0)
  const taxItem = {
    'Line Type': 'D',
    Quantity: 1,
    'Item Description': 'Sales Taxes',
    'Tax Code': '',
    'Tax Amount': '',
    UOM: '',
    'Unit Cost': '',
    'Extended Amt': totalTaxes,
    Property: '25',
    'Job D': orderDetail.project?.project_id,
    Phase: '0',
    Cost: '004000',
    'Cost Type': 'M',
    'Expense Code': '730000',
    'Acct Date': formatDateStringShort(orderDetail.ordered_at),
    'Description D': 'Sales Taxes',
    Amount: totalTaxes,
    'Buyout Amount': '',
    Building: '',
    Unit: '',
    Notes: '',
    'Ship Address Line 1': '',
    'Ship Address Line 2': '',
    'Ship Address Line 3': '',
    City: '',
    State: '',
    Zip: '',
    Country: '',
    Instructions: '',
    Buyer: '',
    'Confirming PO': '',
    'Ack Required?': '',
    'Ack Date': '',
    'Confirming to': '',
    'F.O.B.': '',
    Freight: '',
    'Ship Via': '',
    Terms: '',
    SeqNo: '',
    Warehouse: '',
    Item: '',
  }

  return [header, ...materials, taxItem]
}
