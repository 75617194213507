import React, { useRef } from 'react'

import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'
import { Page } from 'common/components/Page'

import { useStores } from 'contractor/hooks/use-stores'

import { CostCodeProvider } from './context'
import { Header } from './Header'
import { TableCostCodes } from './table'
import { UpsertCostCode } from './Upsert'

export const CostCodes = observer(() => {
  const { costCodeStore, userStore } = useStores()

  const upsertCostCodeRef = useRef<DrawerRef>()

  const handleClickRow = (costCodeId: string) => {
    if (!userStore.canEditMaterialDatabase) {
      return
    }
    costCodeStore.selectFromList(costCodeId)
    upsertCostCodeRef.current?.show()
  }

  return (
    <CostCodeProvider>
      <Page.Header>
        <Header onAdd={() => upsertCostCodeRef.current?.show()} />
      </Page.Header>

      <Page.Content>
        <TableCostCodes onClickRow={handleClickRow} />
      </Page.Content>

      <UpsertCostCode ref={upsertCostCodeRef} onCancel={() => upsertCostCodeRef.current?.close()} />
    </CostCodeProvider>
  )
})
