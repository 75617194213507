import React, { useState, useEffect } from 'react'

import { message, Modal, Select, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'

export const SwitchCompany = observer(() => {
  const { companyStore, userStore } = useStores()

  const { currentUser } = userStore

  const [open, toggleOpen] = useState(false)
  const [companyId, onChangeCompanyId] = useState(null)

  const handleSwitchCompany = async () => {
    try {
      await companyStore.switchCompany(companyId)
      window.location.reload()
    } catch (error) {
      const errorMessage = 'Unable to switch the company'
      message.error(error?.response?.data?.message || errorMessage)
    }
  }

  useEffect(() => {
    if (open) {
      companyStore.getCompanies()
    }
  }, [open])

  return (
    <>
      <div onClick={() => toggleOpen(true)}>Companies</div>
      <Modal
        title="Switch Company"
        open={open}
        onCancel={() => toggleOpen(false)}
        onOk={handleSwitchCompany}
        okButtonProps={{ disabled: !companyId }}
      >
        <Box display="flex" flexDirection="column">
          <Typography.Paragraph>
            Select a company to switch to. The current company:{' '}
            <Typography.Text strong>{currentUser?.company_name}</Typography.Text>
          </Typography.Paragraph>
          <Select
            loading={!companyStore.companies.length}
            value={companyId}
            onChange={onChangeCompanyId}
            options={companyStore.companies.map((company) => {
              const isCurrentCompany = company.id === currentUser?.company_id
              return {
                label: isCurrentCompany ? `${company.name} - Current` : company.name,
                value: company.id,
                disabled: isCurrentCompany,
              }
            })}
          />
        </Box>
      </Modal>
    </>
  )
})
