import axios, { AxiosResponse } from 'axios'

import { Address } from 'common/server/addresses'

export interface IndexProjectsResponse {
  projects: IndexProject[]
  inactive_projects: IndexProject[]
}

export interface IndexProject {
  id: string
  name: string
  inventory: boolean
  active: boolean
  default_watcher_user_ids: string[]
  cost_code_id: string
  project_group_id: string
  project_id: string
  tax_line_items: { enabled: boolean }
}

export interface CreateProjectRequest {
  name: string
  default_watcher_user_ids: string[]
  project_group_id?: string
  project_id?: string
  project_invoice_assignees?: { id: string; user_id: string }[]
}

export interface CreateProjectResponse {
  id: string
}

export interface ShowProjectResponse {
  id: string
  name: string
  active: boolean
  addresses: Address[]
  inventory: boolean
  default_watcher_user_ids: string[]
  project_group_id: string
  project_id: string
  project_assignees?: {
    id: string
    company_user_id: string
    project_invoice_assignee: {
      id: string
      name: string
    }
  }[]
  tax_line_items?: { enabled: boolean }
}

export interface UpdateProjectRequest {
  id: string
  name?: string
  active?: boolean
  project_id?: string
  project_group_id?: string
  default_watcher_user_ids: string[]
  project_invoice_assignees?: { id: string; user_id: string }[]
  tax_line_items?: { enabled: boolean }
}

export async function index(showAllProjects: boolean): Promise<AxiosResponse<IndexProjectsResponse>> {
  return await axios.get('/projects', { params: { show_all_projects: showAllProjects } })
}

export async function create(project: CreateProjectRequest): Promise<AxiosResponse<CreateProjectResponse>> {
  return await axios.post('/project', project)
}

export async function show(projectId: string): Promise<AxiosResponse<ShowProjectResponse>> {
  return await axios.get(`/project/${projectId}`)
}

export async function update(payload: UpdateProjectRequest): Promise<AxiosResponse<ShowProjectResponse>> {
  return await axios.patch('/project', payload)
}

export const unknownProject: ShowProjectResponse = {
  id: 'unknown',
  name: 'Unknown Project',
  active: true,
  addresses: [],
  inventory: false,
  default_watcher_user_ids: [],
  project_id: 'unknown',
  project_group_id: '',
}
