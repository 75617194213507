import React from 'react'

import { uniq } from 'lodash'

import { DeleteOutlined } from '@ant-design/icons'
import { Select, SelectProps, Typography, Button, Space, Popconfirm, List } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { OptionDescriptor } from 'common/components/Select/Option'

import { useStores } from 'contractor/hooks/use-stores'

export type SelectProjectsProps = SelectProps

export const SelectProjects = observer<SelectProjectsProps>((props) => {
  const { value: projectIds = [], onChange, ...rest } = props
  const { projectStore } = useStores()

  const handleRemove = (removedProjectId) => {
    onChange(
      projectIds?.filter((projectId) => projectId !== removedProjectId),
      null,
    )
  }

  const handleChange = (value, option) => {
    onChange(uniq([...projectIds, ...value]), option)
  }

  const selectedProjects = projectStore?.projects?.filter((project) => projectIds?.includes(project.id))
  const availableOptions = projectStore?.projects?.filter((project) => !projectIds?.includes(project.id))

  return (
    <Box width="100%">
      <Select
        aria-label="project-select"
        style={{ width: '100%', marginBottom: 8 }}
        filterOption={(input, option) => {
          const inputValue = input?.toLowerCase()
          return option?.searchable?.toLowerCase()?.includes(inputValue)
        }}
        showSearch
        mode="multiple"
        {...rest}
        value={[]}
        onChange={handleChange}
      >
        {availableOptions.map((project) => (
          <Select.Option
            key={project.id}
            value={project.id}
            label={project.name}
            searchable={`${project.name}:${project.project_id ? project.project_id : ''}`}
          >
            <OptionDescriptor value={project.id} label={project.name} descriptor={project.project_id} />
          </Select.Option>
        ))}
      </Select>

      {!!selectedProjects.length && (
        <List
          style={{ marginTop: '16px' }}
          dataSource={selectedProjects}
          renderItem={(project) => (
            <List.Item
              actions={[
                <Popconfirm
                  key="as"
                  title={`Are you sure to delete the project ${project.name}?`}
                  placement="topLeft"
                  onConfirm={() => handleRemove(project.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} size="small" type="dashed" />
                </Popconfirm>,
              ]}
            >
              <Space>
                <Typography.Text>{project.name}</Typography.Text>
                {project.project_id && <Typography.Text type="secondary">{project.project_id}</Typography.Text>}
              </Space>
            </List.Item>
          )}
        />
      )}
    </Box>
  )
})
