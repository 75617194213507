import React, { useEffect, useState } from 'react'

import { CheckOutlined } from '@ant-design/icons'
import { Typography, Form, Input, Popconfirm, Button, Tooltip, Space, Row, Col, Switch, Checkbox } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import Uploader from 'common/components/uploader'

import { useStores } from 'contractor/hooks/use-stores'

import { useOtherSettings } from './context'

export const FILE_KEY = 'terms_and_conditions'

export const TermsAndConditions = observer(() => {
  const { companySettingStore, uploaderStore } = useStores()
  const { form } = useOtherSettings()

  const [onlyActives, setOnlyActives] = useState(true)

  const { otherSettings } = companySettingStore

  useEffect(() => {
    if (otherSettings?.terms_and_conditions?.length) {
      const termsAndConditions = otherSettings.terms_and_conditions?.map((termsAndCondition, index) => {
        uploaderStore.resetUploads(`${FILE_KEY}:${index}`)
        uploaderStore.addExistingFiles(`${FILE_KEY}:${index}`, termsAndCondition?.file)
        return {
          id: termsAndCondition?.id,
          name: termsAndCondition?.name,
          discardedAt: termsAndCondition?.discarded_at,
          file: termsAndCondition?.file?.signed_id,
        }
      })
      form.setFieldsValue({ termsAndConditions })
    }
  }, [otherSettings?.terms_and_conditions?.length])

  const getFileList = React.useCallback(
    (index) => {
      return Object.values(uploaderStore.uploads[`${FILE_KEY}:${index}`] || {}).map(uploaderStore.mapFileList)
    },
    [uploaderStore.uploads],
  )

  return (
    <Form layout="vertical" form={form} scrollToFirstError>
      <Form.List name="termsAndConditions">
        {(fields, { add, remove }) => (
          <Box alignItems="flex-start" mt={20}>
            <Space style={{ marginBottom: 10 }}>
              <Typography.Title level={4} style={{ margin: 0 }}>
                Terms and Conditions
              </Typography.Title>
              <Tooltip title="Add Terms and Conditions">
                <Button type="primary" onClick={() => add({ name: undefined })} size="small">
                  Add
                </Button>
              </Tooltip>
            </Space>

            <Typography.Paragraph>
              Here you can attach terms and conditions your organization uses for different types of purchase orders.
              They will be attached to order PDF.{' '}
              <Typography.Text strong underline>
                Show inactives{' '}
                <Switch onChange={setOnlyActives} checked={onlyActives} checkedChildren={<CheckOutlined />} />
              </Typography.Text>
            </Typography.Paragraph>

            <Row gutter={20}>
              {fields.map(({ key, name }, index) => {
                const discardedAt = form.getFieldValue(['termsAndConditions', name, 'discardedAt'])

                return (
                  <Col
                    key={key}
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    xl={5}
                    xxl={4}
                    style={{
                      display: (onlyActives && discardedAt) || (!onlyActives && !discardedAt) ? 'none' : 'block',
                    }}
                  >
                    <Form.Item name={[name, 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={[name, 'discardedAt']} hidden>
                      <Input />
                    </Form.Item>

                    <Box display="inline-flex" alignItems="baseline" width="100%" style={{ gap: 8 }}>
                      <Form.Item
                        name={[name, 'name']}
                        rules={[{ required: true, message: 'Input some value or remove the field.' }]}
                        style={{ width: '100%' }}
                      >
                        <Input placeholder={`e.g. Terms and Conditions ${index + 1}`} />
                      </Form.Item>
                      <Popconfirm
                        placement="topRight"
                        title={`Are you sure you want to ${
                          discardedAt ? 'activate' : 'deactivate'
                        } this term and condition?`}
                        onConfirm={() => {
                          const termsAndConditionId = form.getFieldValue(['termsAndConditions', name, 'id'])

                          if (termsAndConditionId) {
                            const termsAndConditions = form.getFieldValue(['termsAndConditions'])
                            termsAndConditions[index].discardedAt = discardedAt ? null : new Date().toISOString()
                            form.setFieldsValue({ termsAndConditions })
                          } else {
                            remove(name)
                            const file = Object.values(uploaderStore.uploads[`${FILE_KEY}:${index}`] || {})?.[0]
                            if (file) {
                              uploaderStore.removeUpload(`${FILE_KEY}:${index}`, file?.uid)
                            }
                          }
                        }}
                      >
                        <Tooltip title={discardedAt ? 'Activate' : 'Deactivate'}>
                          <Checkbox checked={!discardedAt} />
                        </Tooltip>
                      </Popconfirm>
                    </Box>

                    <Form.Item
                      name={[name, 'file']}
                      rules={[{ required: true, message: 'You must select a PDF file.' }]}
                      style={{ width: '100%' }}
                    >
                      <Uploader
                        accept=".pdf,application/pdf"
                        component="Upload"
                        multiple={false}
                        showRemove={false}
                        uploadKey={`${FILE_KEY}:${index}`}
                        noResetUploads
                        listType="text"
                        fileList={getFileList(index)}
                        onRemoveUpload={uploaderStore.removeUpload}
                        onAddNewUpload={uploaderStore.addNewUpload}
                        onResetUploads={uploaderStore.resetUploads}
                        onSetUploadError={uploaderStore.setUploadError}
                        onUpdateUpload={(uploadKey: string, uid: string, percent: number, signed_id: string) => {
                          uploaderStore.updateUpload(uploadKey, uid, percent, signed_id)
                          form.setFieldValue([name, 'file'], signed_id)
                        }}
                        textContent={getFileList(index).length ? 'Replace attachment' : 'Upload'}
                      />
                    </Form.Item>
                  </Col>
                )
              })}
            </Row>
          </Box>
        )}
      </Form.List>
    </Form>
  )
})
