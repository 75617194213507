import React from 'react'

import { Select, Typography, Divider } from 'antd'
import { SelectProps, DefaultOptionType } from 'antd/lib/select'

import { observer } from 'mobx-react-lite'

import { Box, BoxProps } from 'common/components/boxes'
import { formatName } from 'common/helpers/formatters'

import { useStores } from 'contractor/hooks/use-stores'
import { ManagedUser } from 'contractor/server/company_settings/manage_users'

type SelectUserProps = {
  onlyActives?: boolean
  showAssignToMe?: boolean
  showAssignToMeOnDropdown?: boolean
  boxProps?: BoxProps
  concatOptions?: (options: DefaultOptionType[]) => DefaultOptionType[]
  makeOption?: (user: ManagedUser) => DefaultOptionType
} & SelectProps

const makeUserOption = (user) => {
  const name = formatName(user?.first_name, user?.last_name)
  return { value: user.id, label: name || user?.email }
}

export const makeCompanyUserOption = (user) => {
  const name = formatName(user?.first_name, user?.last_name)
  return { value: user.company_user_id, label: name || user?.email }
}

const AssignToMe = (props) => {
  return <Typography.Link {...props}>Assign to me</Typography.Link>
}

export const SelectUser = observer<SelectUserProps>(
  ({
    onlyActives = false,
    showAssignToMe = true,
    boxProps,
    concatOptions,
    showAssignToMeOnDropdown,
    makeOption = makeUserOption,
    ...props
  }) => {
    const { companySettingStore, userStore } = useStores()

    const currentUser = userStore.currentUser

    const users = onlyActives
      ? companySettingStore.possibleUsers.filter((user) => user.is_active)
      : companySettingStore.possibleUsers

    const options = users.map((user) => makeOption(user))

    const handleAssignToMe = () => {
      const option = makeOption(currentUser as unknown as ManagedUser)
      props?.onChange?.(option?.value, {
        value: option?.value,
        label: option?.label,
      })
    }

    return (
      <Box display="flex" flexDirection="column" width="100%" {...boxProps}>
        <Select
          style={{ width: '100%' }}
          filterOption={(input, option) => {
            const inputValue = input.toLowerCase()
            const label = option?.label as string
            return label?.toLowerCase().includes(inputValue)
          }}
          aria-autocomplete="none"
          showSearch
          allowClear
          options={concatOptions ? concatOptions(options) : options}
          dropdownRender={(menu) => {
            if (showAssignToMeOnDropdown && currentUser.id !== props?.value) {
              return (
                <>
                  <AssignToMe onClick={handleAssignToMe} style={{ padding: '0 12px' }} />
                  <Divider style={{ margin: '8px 0' }} />
                  {menu}
                </>
              )
            }

            return menu
          }}
          {...props}
        />

        {showAssignToMe && currentUser.id !== props?.value && <AssignToMe onClick={handleAssignToMe} />}
      </Box>
    )
  },
)
