import React, { useRef } from 'react'

import pluralize from 'pluralize'

import { Typography, Badge, Space } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, BoxProps } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { useTheme } from 'common/hooks/use-theme'

import { InvoiceOrderMaterial } from 'contractor/components/InvoiceOrderMaterial'
import { DrawerInvoices } from 'contractor/components/Invoices/invoices_drawer'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

type InvoicesProps = BoxProps

export const Invoices = observer<InvoicesProps>((props) => {
  const { orderStore, userStore } = useStores()
  const drawerRef = useRef<DrawerRef>()

  const theme = useTheme()

  const extractedInvoiceDataEnabled = useFlag('extracted_invoice_data')

  if (!userStore.canUseInvoices) {
    return null
  }

  const order = orderStore.selectedOrder
  const { invoices = [] } = orderStore.selectedOrder

  const getBadgeStyle = () => {
    if (invoices.length) {
      return {
        color: theme.colors['blue-6'],
        background: theme.colors['blue-1'],
        boxShadow: `0 0 0 1px ${theme.colors['blue-3']}`,
      }
    }
    return {
      color: theme.colors['gray-13'],
      background: theme.colors['gray-6'],
      boxShadow: `0 0 0 1px ${theme.colors['gray-3']}`,
    }
  }

  return (
    <>
      <Box
        onClick={() => {
          if (invoices.length) {
            drawerRef.current?.show()
          }
        }}
        display="inline-flex"
        alignItems="center"
        border="1px solid"
        borderColor="gray-4"
        borderRadius="md"
        px={8}
        py={2}
        height="30px"
        justifyContent="center"
        style={invoices.length ? { cursor: 'pointer' } : {}}
        {...props}
      >
        <Space>
          <Badge count={invoices.length} showZero style={getBadgeStyle()} />
          <Typography.Text>{pluralize('Invoice', invoices.length)}</Typography.Text>
        </Space>
      </Box>

      {extractedInvoiceDataEnabled ? (
        <InvoiceOrderMaterial order={order} ref={drawerRef} />
      ) : (
        <DrawerInvoices
          orderNumber={order?.order_number}
          invoices={invoices}
          ref={drawerRef}
          grandTotal={order?.grand_total}
          totalInvoicedAmount={order?.total_invoiced_amount}
          remaining={Number(order?.grand_total) - Number(order?.total_invoiced_amount)}
        />
      )}
    </>
  )
})
