import React from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'
import { Card } from 'common/components/Card'
import { ButtonToggleSpreadsheet, OrderMaterials } from 'common/components/OrderMaterialsV2'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { MaterialRequestTypes } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

import { usePrivateOrderForm } from './context'

type MaterialsV2Props = {
  isSpreadsheetMode: boolean
  toggleSpreadsheetMode: React.Dispatch<React.SetStateAction<boolean>>
}

export const MaterialsV2 = observer<MaterialsV2Props>(({ toggleSpreadsheetMode, isSpreadsheetMode }) => {
  const { orderStore, companySettingStore, costCodeStore, unitsStore, userStore } = useStores()

  const { projectId } = usePrivateOrderForm()

  const { company_attributes = [] } = companySettingStore.companyMaterialConfiguration
  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings
  const requiredRequestFields = companySettingStore.otherSettings?.required_request_fields
  const { costCodePhaseListStore } = costCodeStore

  const isMobileScreen = useMediaQuery('md')

  const deliveryId = orderStore.newDeliveries[0].id

  const handleAdd = () => {
    const button = document.getElementsByClassName('aa-DetachedSearchButton')[0] as HTMLElement
    const input = document.getElementsByClassName('aa-Input')[0] as HTMLElement
    button?.click()
    input?.focus()
  }

  const orderType =
    companySettingStore.otherSettings?.material_requisitions_default_order_type === MaterialRequestTypes.RFQ
      ? 'RFQ'
      : 'Order'

  return (
    <Card title="Materials" extra={<Button icon={<PlusOutlined />} onClick={handleAdd} size="small" />} mt={16}>
      <FlexBoxY justifyContent="flex-start" width="100%" alignItems="flex-start">
        <OrderMaterials
          orderType={orderType}
          dataSource={orderStore
            .getOrderMaterialsByDeliveryId(deliveryId)
            .filter((data) => !!data.company_material?.description)}
          onChange={(newOrderMaterials) => {
            orderStore.updateOrderMaterialsByDeliveryId(deliveryId, newOrderMaterials)
          }}
          hideCostCode={!company_attributes?.includes('cost_code_id')}
          hideVendorResponse
          hideUnitCost
          hideTax
          showDeleteItem
          isRequest
          projectId={projectId}
          deliveryId={deliveryId}
          costCodeInput={{
            costCodes: toJS(costCodeStore.costCodeListStore.records),
            costCodeSettings,
            projectId,
          }}
          units={unitsStore.units}
          unitInput={{ units: unitsStore.units, disabled: !userStore.canCreateNewMaterial }}
          phaseCodeInput={{
            projectSpecificPhaseCodesEnabled: costCodeSettings?.project_specific_phase_codes_enabled,
            initialCostCodePhases: toJS(costCodePhaseListStore.records),
            projectId,
          }}
          requiredFields={{
            request: requiredRequestFields,
          }}
          costCodeSettings={costCodeSettings}
          canEditMaterialDatabase={userStore.canEditMaterialDatabase}
          canViewHistoricalPricing={userStore.canViewHistoricalPricing}
          canCreateNewMaterial={userStore.canCreateNewMaterial}
          userId={userStore.currentUser?.id}
        />

        {!isMobileScreen && (
          <ButtonToggleSpreadsheet
            onClick={() => toggleSpreadsheetMode((prev) => !prev)}
            data-cy={isSpreadsheetMode ? `is-spread-sheet-0` : `spread-sheet-0`}
            isSpreadsheetMode={isSpreadsheetMode}
          />
        )}
      </FlexBoxY>
    </Card>
  )
})
