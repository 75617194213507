import React from 'react'

import _ from 'lodash'

import styled from '@emotion/styled'

import { LockOutlined, ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Switch, Space, Button, Modal, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { observer } from 'mobx-react-lite'

import { formatEnumValue } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'

import { useStores } from 'contractor/hooks/use-stores'

const TableStyled = styled(Table)`
  width: 100%;

  tr.disabled-table-row:hover > td,
  .locked-table-row {
    background-color: ${({ theme }) => theme.colors['gray-3']};
    cursor: not-allowed;
  }
`

const ColumnsTable = observer(() => {
  const { companySettingStore, userStore } = useStores()

  const theme = useTheme()

  const { all_attributes, company_attributes, required_attributes } = companySettingStore.companyMaterialConfiguration

  // Add company_attributes first THEN all_attributes so active attributes come in order
  const orderedAttributes = _.union(company_attributes, all_attributes)

  const data = orderedAttributes.map((column = '') => {
    const common = {
      column_name: column,
      enabled: company_attributes.includes(column),
      required: required_attributes.includes(column),
    }

    switch (column) {
      case 'cost_code_id':
        return {
          ...common,
          column_label: 'Cost Code',
          locked: !userStore.canUseCostCode,
        }
      case 'project_ids':
        return {
          ...common,
          column_label: 'Project Specific Material',
        }
      default: {
        /*
          Remove Id from column name, like:
          cost_code_id => cost_code
        */
        const columnName = column.replace(/_id$/, '')
        return {
          ...common,
          column_label: columnName,
        }
      }
    }
  })

  const columns: ColumnsType<{
    column_label: string
    column_name: string
    enabled: boolean
    locked?: boolean
    required?: boolean
  }> = [
    {
      title: 'Column Name',
      dataIndex: 'column_label',
      key: 'column_label',
      width: 200,
      render: (columnLabel) => formatEnumValue(columnLabel),
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      key: 'enabled',
      width: 150,
      render: (enabled, record) => (
        <Switch
          checked={enabled}
          disabled={record.required || record.locked}
          onChange={() => {
            const newAttributes = [...company_attributes]
            const { column_name } = record

            // If we are enabled then remove by index, otherwise append the value
            if (enabled) {
              _.remove(newAttributes, (c) => c == column_name)
            } else {
              newAttributes.push(column_name)
            }
            companySettingStore.updateCompanyMaterialAttributes(newAttributes)
          }}
        />
      ),
    },
    {
      title: 'Position',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (_, record, index) => {
        if (record.locked) {
          return <LockOutlined style={{ fontSize: 20, color: theme.colors['gray-6'] }} />
        }

        if (!record.enabled) {
          return null
        }

        return (
          <Space>
            <Button
              disabled={record.required}
              icon={<ArrowUpOutlined />}
              onClick={() => companySettingStore.shiftCompanyMaterialAttributes(index, 'UP')}
            />
            <Button
              disabled={record.required}
              icon={<ArrowDownOutlined />}
              onClick={() => companySettingStore.shiftCompanyMaterialAttributes(index, 'DOWN')}
            />
          </Space>
        )
      },
    },
  ]

  return (
    <TableStyled
      rowClassName={(record) => {
        if (record['locked']) {
          return 'locked-table-row'
        }
      }}
      onRow={(record) => ({
        onClick: () => {
          if (record['locked'] && record['column_name'] === 'cost_code_id') {
            Modal.info({
              content: (
                <Typography.Text>
                  Users on the <Typography.Text strong>Basic</Typography.Text> plan are unable to leverage{' '}
                  <Typography.Text strong>Cost Codes</Typography.Text>. Let us know if you&apos;d like to upgrade!
                </Typography.Text>
              ),
            })
          }
        },
      })}
      rowKey="id"
      dataSource={data}
      columns={columns}
      pagination={false}
    />
  )
})

export default ColumnsTable
