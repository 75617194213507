/** @jsx jsx */
import React from 'react'

import { jsx } from '@emotion/core'

import UserOutlined from '@ant-design/icons/UserOutlined'
import { Alert, Button, Form, Input } from 'antd'

import { FlexBoxY } from 'common/components/boxes'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'
import { SignInTypes, LandingMethods } from 'common/pages/Landing'

interface ResendConfirmationProps {
  setSignInType: (string) => void
  resendConfirmationMessage: string
  resendConfirmation: LandingMethods['resendConfirmation']
}

// See user.rb for explanation about confirmation functionality being disabled

const ResendConfirmation = ({
  setSignInType,
  resendConfirmationMessage,
  resendConfirmation,
}: ResendConfirmationProps) => {
  const [resetMessage, setResetMessage] = React.useState([])
  const [form] = Form.useForm()

  const onValuesChange = (changedValues) => {
    if (changedValues?.email) form.setFieldsValue({ email: changedValues.email.toLowerCase().trim() })
  }

  React.useEffect(() => {
    setResetMessage(resendConfirmationMessage ? ['success', resendConfirmationMessage] : [])
  }, [resendConfirmationMessage])

  const onFinish = async ({ email }): Promise<void> => {
    try {
      await resendConfirmation(email)
      setResetMessage([
        'success',
        'Please check your email for a link to confirm your email address.\
        If you do not see it in your inbox, check your junk mail./',
      ])
    } catch (err) {
      setResetMessage([
        'error',
        `Unable to send confirmation to this email address.\
        Please try re-registering or contact ${SUPPORT_EMAIL_ADDRESS} for further asssistance.`,
      ])
    }
  }

  return (
    <Form
      style={{ width: '300px' }}
      form={form}
      name="normal_login"
      initialValues={{ remember_me: true }}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        name="email"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: 'Please input your Email!' },
          { type: 'email', message: 'The input is not valid E-mail!' },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
      </Form.Item>
      <Form.Item>
        <FlexBoxY alignItems="flex-start">
          <Button style={{ width: '100%' }} type="primary" htmlType="submit" className="login-form-button">
            Resend Confirmation
          </Button>
          <span>
            Or&nbsp;
            <a
              type="link"
              onClick={() => {
                setSignInType(SignInTypes.SIGN_IN)
                setResetMessage([])
              }}
            >
              sign in!
            </a>
          </span>
        </FlexBoxY>
      </Form.Item>
      {resetMessage.length > 0 && (
        <Alert style={{ marginBottom: '20px' }} type={resetMessage[0] as any} message={resetMessage[1]} /> // eslint-disable-line
      )}
    </Form>
  )
}

export default ResendConfirmation
