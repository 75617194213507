import React, { useEffect } from 'react'

import { Input, Modal, Form } from 'antd'

type ModalSplittingOrderPackageNameProps = {
  visible: boolean
  onConfirm: (name: string) => void
  onCancel: () => void
  initialValue?: string
}

export const ModalSplittingOrderPackageName = ({
  visible,
  onCancel,
  onConfirm,
  initialValue = '',
}: ModalSplittingOrderPackageNameProps) => {
  const [form] = Form.useForm()

  const handleOk = () => {
    form.submit()
  }

  const handleFinish = ({ orderPackageName }) => {
    onConfirm(orderPackageName)
    onCancel()
  }

  useEffect(() => {
    form.setFieldsValue({ orderPackageName: initialValue })
  }, [initialValue, visible])

  return (
    <Modal open={visible} okText="Confirm" title="Splitting Order" onCancel={onCancel} onOk={handleOk}>
      <Form onFinish={handleFinish} form={form}>
        <Form.Item
          label="Order Name"
          name="orderPackageName"
          rules={[{ required: true, message: 'Please input Order Name!' }]}
        >
          <Input data-cy="splitting-order-name" placeholder="Order Name e.g. Fixtures, Tile, etc." autoFocus />
        </Form.Item>
      </Form>
    </Modal>
  )
}
