import React from 'react'

import { UnitsEditorCell } from 'common/components/OrderMaterialsSpreadsheet'

import { useStores } from 'contractor/hooks/use-stores'

export const CustomUnitsEditorCell = (props) => {
  const { unitsStore } = useStores()

  return <UnitsEditorCell {...props} units={unitsStore.units} />
}
