import { DrawerTableSortOptionsType } from 'common/components/@v2/DrawerTableSort/DrawerTableSort'

const constants = {
  sortOptions: [
    { id: 'order_state', description: 'Status' },
    { id: 'order_number', description: 'Order' },
  ] as DrawerTableSortOptionsType<'order_state' | 'order_number'>[],
}

export default constants
