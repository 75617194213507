import React from 'react'

import { useSpring, animated } from 'react-spring'
import useMeasure from 'react-use-measure'

import styled from '@emotion/styled'

const CollapsibleContentStyled = styled(animated.div)`
  width: 100%;
`

export const CollapsibleContent = ({ children, isOpen }) => {
  const [measureRef, { height }] = useMeasure()
  const animatedStyle = useSpring({
    maxHeight: isOpen ? height : 0,
    opacity: isOpen ? 1 : 0,
  })

  return (
    <CollapsibleContentStyled style={{ ...animatedStyle, overflow: 'hidden' }}>
      <div ref={measureRef} style={{ width: 'inherit' }}>
        {children}
      </div>
    </CollapsibleContentStyled>
  )
}
