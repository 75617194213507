import React from 'react'

import { flatten } from 'lodash'

import { Table } from 'antd'

import { observer } from 'mobx-react-lite'

import { ValueType } from 'common/components/DebounceSelect'

import { useStores } from 'contractor/hooks/use-stores'
import { ApproveRejectButton } from 'contractor/pages/Integrations/Mappings/approve_reject_button'
import { SuggestionAlert } from 'contractor/pages/Integrations/Mappings/suggestion_alert'
import { ListParams, MappingSuggestion, Relationship } from 'contractor/server/integrations'
import { Paginated } from 'contractor/stores/IntegrationStore'

export const rowValue = (row: Relationship): ValueType | undefined => {
  if (row.mapping_suggestions && row.mapping_suggestions.length > 0) {
    const suggestion = row.mapping_suggestions[0]
    return { value: suggestion.internal_id, label: suggestion.internal_name, disabled: false }
  }
  return row.internal_id ? { value: row.internal_id, label: row.internal_name, disabled: false } : undefined
}

interface RelationshipsTableProps {
  showSuggestions: boolean
  handleSuggestionsApproval: (suggestions: MappingSuggestion[]) => void
  handleSuggestionsRejection: (suggestions: MappingSuggestion[]) => void
  filter?: ListParams
  setFilter?: React.Dispatch<React.SetStateAction<ListParams>>
  isLoading: boolean
  relationships: Nullable<Paginated<Relationship>>
  renderRowTitle: string
  renderRow: (row: Relationship) => React.ReactNode
}

const RelationshipsTable = observer<RelationshipsTableProps>(
  ({
    showSuggestions,
    handleSuggestionsApproval,
    handleSuggestionsRejection,
    renderRow,
    renderRowTitle,
    relationships,
    isLoading,
    setFilter,
  }) => {
    const { integrationStore, userStore } = useStores()
    const updating = relationships === null ? true : isLoading

    const pageMappings = flatten(relationships?.data?.map((item) => item.mapping_suggestions) || [])

    const handleApproveAll = () => {
      handleSuggestionsApproval(pageMappings)
    }

    const handleRejectAll = () => {
      handleSuggestionsRejection(pageMappings)
    }

    const handleApprove = (row: Relationship) => {
      handleSuggestionsApproval(row.mapping_suggestions)
    }

    const handleReject = (row: Relationship) => {
      handleSuggestionsRejection(row.mapping_suggestions)
    }

    const columns = [
      {
        title: `${integrationStore.title()} Name`,
        dataIndex: 'external_name',
        width: '20%',
      },
      {
        title: `SubBase ${renderRowTitle}`,
        width: showSuggestions ? '53%' : '60%',
        dataIndex: 'internal_id',
        render: (_, row) => renderRow(row),
      },
    ]
    if (showSuggestions) {
      columns.push({
        width: '7%',
        dataIndex: 'mapping_suggestions',
        // typescript is complaining about title being a React node but Antd docs say it's ok
        // @ts-ignore
        title: (
          <ApproveRejectButton
            isTitle
            handleApprove={handleApproveAll}
            handleReject={handleRejectAll}
            rowId="relationships"
            disabled={pageMappings.length === 0 || !userStore.canSyncWithErp}
          />
        ),
        render: (_, row) =>
          row.mapping_suggestions && row.mapping_suggestions.length ? (
            <ApproveRejectButton
              handleApprove={() => handleApprove(row)}
              handleReject={() => handleReject(row)}
              disabled={!userStore.canSyncWithErp}
              rowId="relationships"
            />
          ) : (
            <></>
          ),
      })
    }
    return (
      <>
        <SuggestionAlert showSuggestions={showSuggestions} />
        <Table<Relationship>
          pagination={{
            total: relationships?.totalCount,
            current: relationships?.currentPage,
            pageSize: relationships?.pageItems,
            onChange: (page) => {
              setFilter((prev) => ({ ...prev, page }))
            },
            showSizeChanger: false,
          }}
          loading={updating}
          rowKey="id"
          style={{ width: '100%' }}
          columns={columns}
          dataSource={relationships?.data}
        />
      </>
    )
  },
)

export default RelationshipsTable
