import React, { createContext, useContext, useRef } from 'react'

import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'

const DashboardContext = createContext<DashboardContextProps>({} as DashboardContextProps)

export const useDashboard = () => useContext(DashboardContext)

type DashboardContextProps = {
  deliveryDetailRef: React.MutableRefObject<DrawerRef>
}

export const DashboardProvider = observer(({ children }) => {
  const deliveryDetailRef = useRef<DrawerRef>()

  return <DashboardContext.Provider value={{ deliveryDetailRef }}>{children}</DashboardContext.Provider>
})

export const withDashboardProvider = (Component) => (props) => {
  return (
    <DashboardProvider>
      <Component {...props} />
    </DashboardProvider>
  )
}
