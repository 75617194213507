import React from 'react'

import shouldForwardProp from '@styled-system/should-forward-prop'

import styled from '@emotion/styled'

import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { Typography, Tooltip, Card, Avatar, Button } from 'antd'

import { FlexBoxY, Box } from 'common/components/boxes'

type VendorUserCardProps = {
  name: string
  headline?: string
  email: string
  phone?: string
  isConnected: boolean
  onConnect?: () => void
  onConnectAndAdd?: () => void
  isAddToOrder?: boolean
  orderType?: OrderType
  isLoading?: boolean
}

const CardStyled = styled(Card, { shouldForwardProp })<Pick<VendorUserCardProps, 'isConnected'>>`
  width: 100%;
  height: 120px;
  border-radius: 4px;

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    height: auto;
  }

  & .ant-card-body {
    padding: 16px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
      flex-direction: column;
    }
  }

  ${(props) => {
    if (props?.isConnected) {
      return 'background-color: #F5FAFF'
    }
  }}
`

export const VendorUserCard = ({
  name,
  headline,
  email,
  phone,
  isConnected,
  orderType = 'Order',
  onConnect,
  onConnectAndAdd,
  isLoading = false,
}: VendorUserCardProps) => {
  return (
    <CardStyled isConnected={isConnected}>
      <Box display="flex" alignItems="center" flexGrow={1} minWidth={0} maxWidth="100%">
        <Avatar size={55} style={{ backgroundColor: '#505F8D', fontSize: 20, minWidth: 55 }}>
          {name[0]}
        </Avatar>

        <FlexBoxY mx={16} alignItems="flex-start" flexGrow={1} minWidth="0">
          <Typography.Title level={4} style={{ margin: 0 }}>
            {name}
          </Typography.Title>

          <Typography.Text style={{ color: '#676767' }}>
            <MailOutlined style={{ marginRight: 4 }} /> {email}
          </Typography.Text>
          {!!phone && (
            <Typography.Text style={{ color: '#676767' }}>
              <PhoneOutlined style={{ marginRight: 4 }} /> {phone}
            </Typography.Text>
          )}

          <Tooltip title={headline}>
            <Typography.Paragraph type="secondary" ellipsis style={{ margin: 0, width: '100%', color: '#9E9E9E' }}>
              {headline}
            </Typography.Paragraph>
          </Tooltip>
        </FlexBoxY>
      </Box>

      <FlexBoxY flexGrow={0}>
        {onConnectAndAdd && (
          <Button onClick={onConnectAndAdd} type="primary" loading={isLoading} style={{ width: 120 }}>
            Add to {orderType}
          </Button>
        )}
        {!isConnected && (
          <Button onClick={onConnect} type="primary" ghost loading={isLoading} style={{ width: 120, marginTop: 12 }}>
            Connect
          </Button>
        )}
      </FlexBoxY>
    </CardStyled>
  )
}
