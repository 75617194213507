import React, { useState, useEffect } from 'react'

import { CSVLink } from 'react-csv'

import { get, set } from 'lodash'

import { observer } from 'mobx-react-lite'

import { formatForCSVExport } from 'common/helpers/formatters'

import { useStores } from 'contractor/hooks/use-stores'

import { makeDeliveryCosts } from './download_csv'

const headers = [
  { label: 'Cost Code', key: 'cost_code' },
  { label: 'Cost Type', key: 'cost_type' },
  { label: 'Description', key: 'description' },
  { label: 'Quantity', key: 'quantity' },
  { label: 'UOM', key: 'uom' },
  { label: 'Unit Price', key: 'unit_price' },
  { label: 'Subtotal Override', key: 'subtotal_override' },
]

export const DownloadCsvProcore = observer(() => {
  const { orderStore } = useStores()

  const orderNumber = orderStore.selectedOrder?.order_number

  const [data, setData] = useState([])

  useEffect(() => {
    const { order_materials = [], deliveries = [] } = orderStore.selectedOrder

    const downloadContent =
      order_materials.map((material) => {
        const uom =
          material.company_material?.unit?.unit_name_with_increment_label?.toLowerCase() ||
          material.company_material?.unit_name
        return {
          cost_code: material?.cost_code?.code,
          cost_type: 'O',
          description: material.company_material.description,
          quantity: material.quantity,
          uom,
          unit_price: material.unit_cost,
        }
      }) || []

    downloadContent.forEach((hit) => {
      headers.forEach(({ key }) => {
        const currentValue = get(hit, key)
        const newValue = formatForCSVExport(currentValue)
        set(hit, key, newValue)
      })
    })

    setData([...downloadContent, ...makeDeliveryCosts(deliveries)])
  }, [orderStore.selectedOrder])

  if (!data.length) {
    return <>Processing CSV...</>
  }

  return (
    <CSVLink data={data} filename={`${orderNumber}.csv`} headers={headers} target="_blank">
      Procore CSV Download
    </CSVLink>
  )
})
