import React from 'react'

import { Divider, List } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { FlexBoxY, Box } from 'common/components/boxes'
import { SearchInput } from 'common/components/SearchInput'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { CompanyVendorContact } from 'contractor/server/company_vendor_contacts'

import { VendorUserCard } from './vendor_user_card'

export type MyContactsProps = {
  companyVendorId: string
  orderType: OrderType
  onAdd?: (newCompanyVendorContact: CompanyVendorContact) => void
}

export const MyContacts = observer<MyContactsProps>(({ companyVendorId, orderType, onAdd }) => {
  const { companyVendorStore } = useStores()

  const { companyVendorContactListStore } = companyVendorStore

  const { isLoading } = useQuery(() => {
    if (companyVendorId) {
      companyVendorContactListStore.setFilter('company_vendor_id', companyVendorId, true, true)
      return companyVendorContactListStore.fetchRecords()
    }
  }, [companyVendorId])

  return (
    <>
      <Box mx={{ _: 12, xs: 24 }}>
        <SearchInput
          onSearch={(searchTerm: string) => {
            if (companyVendorId) {
              companyVendorContactListStore.setSearch(searchTerm)
            }
          }}
          placeholder="Search for my contacts..."
          style={{ width: '100%' }}
        />
      </Box>

      <Divider style={{ margin: '16px 0' }} />

      <FlexBoxY width="100%" justifyContent="flex-start" overflowY="auto" px={{ _: 12, xs: 24 }}>
        <List
          grid={{
            gutter: 20,
            column: 1,
          }}
          loading={isLoading}
          style={{ width: '100%', height: '100%' }}
          dataSource={toJS(companyVendorContactListStore.records) || []}
          renderItem={(companyVendorContact) => {
            const contact = companyVendorContact.contact
            return (
              <List.Item>
                <VendorUserCard
                  name={contact.vendor_user ? contact.vendor_user.full_name : contact.full_name}
                  email={contact.vendor_user ? contact.vendor_user.email : contact.email}
                  headline={contact?.vendor_user?.headline}
                  onConnectAndAdd={onAdd && (() => onAdd(companyVendorContact))}
                  orderType={orderType}
                  isConnected
                />
              </List.Item>
            )
          }}
        />
      </FlexBoxY>
    </>
  )
})
