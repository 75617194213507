import shouldForwardProp from '@styled-system/should-forward-prop'
import {
  border,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  position,
  PositionProps,
  grid,
  GridProps,
  BorderProps,
  shadow,
  ShadowProps,
  compose,
} from 'styled-system'

import styled from '@emotion/styled'

export type BoxProps =
  | BorderProps
  | Omit<ColorProps, 'color'>
  | FlexboxProps
  | LayoutProps
  | SpaceProps
  | TypographyProps
  | PositionProps
  | GridProps
  | BorderProps
  | ShadowProps

export const Box = styled('div', { shouldForwardProp })<BoxProps>(
  compose(border, color, flexbox, layout, space, typography, position, grid, shadow),
)

export const FlexBoxY = styled('div', { shouldForwardProp })<BoxProps>(
  {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  compose(border, color, flexbox, layout, space, typography, position, grid, shadow),
)

export const FlexBoxX = styled('div', { shouldForwardProp })<BoxProps>(
  {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  compose(border, color, flexbox, layout, space, typography, position, grid, shadow),
)
