import React from 'react'

import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined'
import { Typography } from 'antd'

import { AgaveSyncStatus } from 'common/components/AgaveSyncStatus'
import { FlexBoxX } from 'common/components/boxes'
import { InvoiceHistoryResponse } from 'common/server/invoice_history'

type SyncStatusProps = {
  invoice: InvoiceHistoryResponse['invoice']
  snapshot: InvoiceHistoryResponse['snapshot']
  isCreate?: boolean
}

export const SyncStatus = ({ invoice, snapshot }: SyncStatusProps) => {
  const statusPrev = invoice?.['Sync status'] ? invoice?.['Sync status'][0] : snapshot['Sync status']
  const statusNext = invoice?.['Sync status'] ? invoice?.['Sync status'][1] : snapshot['Sync status']

  return (
    <FlexBoxX alignItems="center" justifyContent="flex-start" mb="8px">
      <Typography.Text style={{ marginRight: 8, whiteSpace: 'nowrap' }}>Sync status:</Typography.Text>
      {!!statusPrev && (
        <>
          <AgaveSyncStatus status={statusPrev} />
          <ArrowRightOutlined style={{ margin: '0 8px' }} />
        </>
      )}
      <AgaveSyncStatus status={statusNext} />
    </FlexBoxX>
  )
}
