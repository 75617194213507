export const getErrorMessages = (error) => {
  const errorMessage = error?.response?.data?.error

  if (errorMessage?.includes('PG::UniqueViolation')) {
    if (errorMessage?.includes('unique_material_vendor_combination')) {
      return "It's not allowed to have duplicated preferred vendor."
    } else {
      return 'Already exists a material exactly like this. Please make sure all materials are unique.'
    }
  }

  if (errorMessage?.includes('Company material vendor prices company material ::')) {
    return errorMessage.split('::')[1]
  }

  return errorMessage || 'Unable to add material'
}
