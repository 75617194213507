import React from 'react'

import { useMediaQuery, Breakpoints } from 'common/hooks/use-media-query'

type VisibilityProps = {
  breakpoint?: Breakpoints
  children: React.ReactNode
}

const Hidden = ({ children, breakpoint = 'xs' }: VisibilityProps) => {
  const isMatch = useMediaQuery(breakpoint)

  if (isMatch) {
    return null
  }

  return <>{children}</>
}

const Show = ({ children, breakpoint = 'xs' }: VisibilityProps) => {
  const isMatch = useMediaQuery(breakpoint)

  if (isMatch) {
    return <>{children}</>
  }

  return null
}

export const Visibility = { Hidden, Show }
