import React from 'react'

import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps as ReactErrorBoundaryProps,
} from 'react-error-boundary'

import MehOutlined from '@ant-design/icons/MehOutlined'
import { Button, Typography } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { FlexBoxY, Box } from 'common/components/boxes'
import { noticeError } from 'common/helpers/new_relic'

const ErrorFallback = ({ resetErrorBoundary, isFull = true }) => {
  return (
    <FlexBoxY justifyContent="center" alignContent="center" height="100%">
      {isFull && (
        <Box mb={24}>
          <MehOutlined style={{ fontSize: '15rem' }} />
        </Box>
      )}
      <Typography.Title level={1} style={{ fontSize: isFull ? 38 : 20 }}>
        Oops! Something went wrong.
      </Typography.Title>
      <Typography.Title level={4} style={{ textAlign: 'center', fontSize: isFull ? 20 : 14 }}>
        If you are seeing this screen repeatedly, please contact:
        <Typography.Link href="mailto: help@subbase.io"> help@subbase.io</Typography.Link>
      </Typography.Title>
      <Typography.Title level={4} style={{ textAlign: 'center', margin: 0, fontSize: isFull ? 20 : 14 }}>
        Please refresh your browser.
      </Typography.Title>
      <Box mt={24}>
        <Button onClick={resetErrorBoundary} type="primary" size="large">
          Click to Refresh
        </Button>
      </Box>
    </FlexBoxY>
  )
}

type ErrorBoundaryProps = { children: React.ReactNode; isFull?: boolean } & Partial<ReactErrorBoundaryProps>

export const ErrorBoundary = ({ children, isFull }: ErrorBoundaryProps) => (
  <ReactErrorBoundary
    FallbackComponent={(props) => <ErrorFallback {...props} isFull={isFull} />}
    onReset={() => {
      window.location.reload()
    }}
    onError={(error, info) => {
      trackEvent(Events.ERROR_BOUNDARY, window.location.href, {
        error_stack: error?.stack,
        error_message: error?.message,
      })
      noticeError(error, { ...info, entry: 'error-boundary' })
    }}
  >
    {children}
  </ReactErrorBoundary>
)

export const withErrorBoundary = (Component) => (props) =>
  (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  )
