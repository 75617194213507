import React, { useState } from 'react'

import { SwapOutlined } from '@ant-design/icons'
import { Button, Input, Modal, notification, Tooltip } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'

type DrawerTittleProps = {
  title: string
  changeSide: () => void
}

export const DrawerTitle = (props: DrawerTittleProps) => {
  const { title, changeSide } = props
  const { quoteStore } = useStores()
  const [openModal, setOpenModal] = useState(false)
  const [issueMessage, setIssueMessage] = useState('')

  const handleSendIssue = async () => {
    try {
      await quoteStore.reportQuoteIssue(quoteStore.quote.id, issueMessage, quoteStore.quote.order_id)
      notification.success({
        message: 'Thanks for your issue report!\n Our team will take care of it ASAP.',
        placement: 'bottomLeft',
      })
      setIssueMessage('')
    } catch {
      notification.error({
        message: 'Error while sending the issue, try again later',
        placement: 'bottomLeft',
      })
    } finally {
      setOpenModal(false)
    }
  }

  return (
    <FlexBoxX width="100%" justifyContent="space-between">
      <Box>{title}</Box>
      <Box>
        <Tooltip title="Report extraction issue">
          <Button style={{ marginRight: 16, minHeight: 16 }} onClick={() => setOpenModal(true)}>
            Something went wrong?
          </Button>
        </Tooltip>
        <Tooltip title="Swap sides">
          <Button onClick={() => changeSide()}>
            <SwapOutlined />
          </Button>
        </Tooltip>
      </Box>

      <Modal
        title="Describe the issue here and hit OK"
        open={openModal}
        onCancel={() => setOpenModal(false)}
        okButtonProps={{ onClick: () => handleSendIssue(), disabled: issueMessage.trim().length === 0 }}
      >
        <Input.TextArea
          value={issueMessage}
          onChange={(e) => setIssueMessage(e.target.value)}
          showCount
          maxLength={255}
          style={{ height: 120, resize: 'none' }}
        />
      </Modal>
    </FlexBoxX>
  )
}
