import React from 'react'

import { CalendarOutlined } from '@ant-design/icons'
import { Form, Space, Tooltip, Typography } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'
import { CustomEditableContent } from 'common/components/CustomEditableContent'
import DateTimePicker from 'common/components/date_time_picker'
import { formatDateStringShort } from 'common/helpers/formatters'

import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'

interface DocumentDateProps {
  disabled?: boolean
}

export const ExpirationDate = (props: DocumentDateProps) => {
  const { disabled } = props
  const ref = React.useRef()
  const { expirationDate } = useCommitment()
  const source = window.location.pathname

  if (disabled) {
    return (
      <Tooltip title="Expiration Date" placement="bottom">
        <Space>
          <Form.Item style={{ marginBottom: 0 }} name="expirationDate">
            <CalendarOutlined />
            <Typography.Text type="secondary">
              {formatDateStringShort(expirationDate) || 'Expiration Date'}
            </Typography.Text>
          </Form.Item>
        </Space>
      </Tooltip>
    )
  }

  return (
    <CustomEditableContent
      boxProps={{ mt: 0 }}
      refs={[ref]}
      input={
        <Form.Item style={{ marginBottom: 0 }} name="expirationDate">
          <DateTimePicker pickerRef={ref} data-cy="expiration_date" disabled={disabled} wrapperProps={{ width: 150 }} />
        </Form.Item>
      }
    >
      <Tooltip title="Expiration Date" placement="bottom">
        <Box onClick={() => trackEvent(Events.CLICK_EXPIRATION_DATE, source)}>
          <Space>
            <CalendarOutlined />
            <Typography.Text type="secondary">
              {formatDateStringShort(expirationDate) || 'Expiration Date'}
            </Typography.Text>
          </Space>
        </Box>
      </Tooltip>
    </CustomEditableContent>
  )
}
