/** @jsx jsx */

import * as React from 'react'

import { Column, Row } from 'react-table'

import moment from 'moment'

import { jsx } from '@emotion/core'

import { Typography } from 'antd'

import { observer } from 'mobx-react-lite'

interface FormattedTextProps {
  value: string
  row: Row
  column: Column & {
    format: 'text' | 'date'
    ellipsis?: boolean
  }
}

const FormattedText: React.FC<FormattedTextProps> = observer(({ value, column }) => {
  if (column.format === 'date') {
    return <span>{value && moment(value).format('ddd. MMM Do, YYYY')}</span>
  } else {
    return <Typography.Text ellipsis={column.ellipsis}>{value}</Typography.Text>
  }
})

export default FormattedText
