import React from 'react'

import { Link } from 'react-router-dom'

interface Props {
  name: string
  number: string
  href: string
}

export function CellCommitmentNumber(props: Props) {
  const { name, number, href } = props

  return (
    <Link to={href} onClick={(e) => e.stopPropagation()}>
      {number}: {name}
    </Link>
  )
}
