import React, { useState, useEffect } from 'react'

import { useLocation, useHistory } from 'react-router-dom'

import { ThunderboltFilled } from '@ant-design/icons'
import { Typography } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import { useTheme } from 'common/hooks/use-theme'

import { MyVendors } from './MyVendors/my_vendors'
import { SubBaseNetwork } from './SubBaseNetwork'

const CompanyVendorContacts = () => {
  const history = useHistory()
  const location = useLocation()

  const theme = useTheme()

  const [activeKey, setActiveKey] = useState(() => new URLSearchParams(location.search).get('tab') || 'my-vendors')

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const tab = params.get('tab')
    if (tab) {
      setActiveKey(params.get('tab'))
    }
  }, [location.search])

  const handleChange = (key) => {
    setActiveKey(key)
    history.push(`?tab=${key}`)
  }

  const tabItems = [
    {
      label: 'My Vendors',
      key: 'my-vendors',
      children: <MyVendors goToNetWork={() => setActiveKey('subbase-network')} />,
    },
    {
      label: (
        <FlexBoxX alignItems="center">
          <ThunderboltFilled style={{ color: theme.colors.highlightBlue, fontSize: 16, marginRight: 4 }} />
          <Typography.Text>SubBase Network</Typography.Text>
        </FlexBoxX>
      ),
      key: 'subbase-network',
      children: <SubBaseNetwork />,
    },
  ]

  return (
    <Page>
      <Page.Tabs activeKey={activeKey} onChange={handleChange} destroyInactiveTabPane items={tabItems} />
    </Page>
  )
}

export default CompanyVendorContacts
