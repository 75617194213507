import { formatName } from 'common/helpers/formatters'

import { CompanyVendorContact } from 'contractor/server/company_vendor_contacts'

export const makeContactOption = (vendorContact: CompanyVendorContact) => {
  const { contact, company_vendor } = vendorContact
  const { vendor_user } = contact

  const safeGlobalVendorName = company_vendor?.vendor?.name || company_vendor?.vendor_name
  const safeGlobalVendorContactName = vendor_user
    ? formatName(vendor_user?.first_name, vendor_user?.last_name)
    : formatName(contact?.first_name, contact?.last_name)

  return {
    label: `${safeGlobalVendorName}: ${safeGlobalVendorContactName}${
      contact?.nickname ? ` (${contact?.nickname})` : ''
    }`,
    value: contact.id,
    contact: vendorContact,
    companyVendor: company_vendor,
    companyVendorContact: contact,
  }
}
