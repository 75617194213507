import React, { useState } from 'react'

import moment from 'moment/moment'

import { Form, Select, Space, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import DeliveryState from 'common/components/statuses/delivery_state'
import Uploader from 'common/components/uploader'
import { CustomUploadItem } from 'common/components/uploader/custom_upload_item'
import { formatDateString } from 'common/helpers/formatters'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { LogDeliveryResponse } from 'common/server/deliveries'
import { OrderStates } from 'common/server/server_types'

import { DeliveryTag } from 'contractor/components/LogDelivery/SelectOrderStep/delivery_tag'
import { ExpectedDeliveries } from 'contractor/components/LogDelivery/SelectOrderStep/expected_deliveries'
import { OrdersAutocomplete } from 'contractor/components/OrdersAutocomplete'
import { useStores } from 'contractor/hooks/use-stores'

type SelectOrderProps = {
  form: FormInstance
  recentDeliveries: LogDeliveryResponse[]
  filesKey: string
}

const filterByDate = (deliveries: LogDeliveryResponse[], period: 'today' | 'yesterday' | 'tomorrow') => {
  let targetDate: moment.Moment

  switch (period) {
    case 'today':
      targetDate = moment()
      break
    case 'yesterday':
      targetDate = moment().subtract(1, 'day')
      break
    case 'tomorrow':
      targetDate = moment().add(1, 'day')
      break
    default:
      return []
  }

  return deliveries.filter((delivery) => moment(delivery?.delivery?.requested_delivered_at).isSame(targetDate, 'day'))
}

export const SelectOrderStep = observer((props: SelectOrderProps) => {
  const { form, filesKey, recentDeliveries } = props
  const [deliveries, setDeliveries] = useState<LogDeliveryResponse[]>([])
  const { uploaderStore, deliveryStore } = useStores()
  const isMobile = useMediaQuery('md')
  const todayDeliveries = filterByDate(recentDeliveries, 'today')
  const yesterdayDeliveries = filterByDate(recentDeliveries, 'yesterday')
  const tomorrowDeliveries = filterByDate(recentDeliveries, 'tomorrow')

  const handleSelectDelivery = (deliveryId: string, deliveries?: LogDeliveryResponse[]) => {
    deliveryStore.selectDelivery(deliveryId)
    form.setFieldsValue({ deliveryId })
    if (deliveries) {
      setDeliveries(deliveries)
    }
  }

  return (
    <Box display="flex" flexDirection="column" maxWidth={isMobile ? '100%' : 600} pt="8" height="100%">
      <Typography.Text strong style={{ paddingLeft: 16, paddingBottom: 8 }}>
        Files
      </Typography.Text>
      <Form.Item style={{ paddingLeft: 16, paddingRight: 16 }} name="files">
        <Uploader
          component="Dragger"
          showPreviewModal
          multiple
          itemRender={(_originNode, file, _fileList, actions) => (
            <CustomUploadItem
              file={file}
              onRemove={() => actions.remove(file)}
              onDownload={() => actions.download(file)}
            />
          )}
          uploadKey={filesKey}
          listType="picture"
          showDownload
          noResetUploads
          hasFileNameTimeStamp
          fileList={uploaderStore.fileList(filesKey)}
          onRemoveUpload={uploaderStore.removeUpload}
          onAddNewUpload={uploaderStore.addNewUpload}
          onResetUploads={uploaderStore.resetUploads}
          onSetUploadError={uploaderStore.setUploadError}
          onUpdateUpload={uploaderStore.updateUpload}
        />
      </Form.Item>

      <Typography.Text strong style={{ paddingLeft: 16, paddingBottom: 8 }}>
        Order
      </Typography.Text>

      <Box style={{ width: '100%', paddingLeft: 16, paddingRight: 16 }}>
        <OrdersAutocomplete
          aria-label="order-autocomplete-log-delivery"
          portal={false}
          detachedMediaQuery="none"
          size="small"
          onSelect={(selectedOrder) => {
            form.setFieldsValue({ orderId: selectedOrder.id })

            if (selectedOrder?.deliveries.length === 1) {
              handleSelectDelivery(selectedOrder.deliveries[0].id, selectedOrder.deliveries)
            } else {
              setDeliveries(selectedOrder.deliveries)
            }
          }}
          filters={{
            state: [OrderStates.DELIVERED, OrderStates.ORDERED, OrderStates.SHIPPED, OrderStates.PARTIALLY_SHIPPED],
            filter_orders_delivered: true,
          }}
        />
      </Box>

      {deliveries?.length > 1 && (
        <>
          <Typography.Text strong style={{ paddingLeft: 16, paddingBottom: 8 }}>
            Delivery
          </Typography.Text>
          <Form.Item name="deliveryId" rules={[{ required: true, message: 'Delivery is required!' }]}>
            <Select
              style={{ paddingLeft: 16, paddingRight: 16 }}
              options={deliveries.map((delivery, idx) => ({
                value: delivery.id,
                label: (
                  <Space>
                    <DeliveryTag deliveryTag={`Delivery ${idx + 1}`} />
                    <Typography.Text type="secondary">
                      {formatDateString(
                        delivery.requested_delivered_at ||
                          delivery.estimated_delivered_at ||
                          delivery.best_guess_delivered_at,
                      )}
                    </Typography.Text>
                    <DeliveryState state={delivery.state} style={{ margin: 0 }} />
                  </Space>
                ),
              }))}
              onChange={(deliveryId) => {
                handleSelectDelivery(deliveryId)
              }}
            />
          </Form.Item>
        </>
      )}
      <Box flexGrow={1} overflow="auto" style={{ paddingLeft: 8, paddingRight: 8 }}>
        <ExpectedDeliveries
          title="Expected Yesterday"
          recentDeliveries={todayDeliveries}
          handleSelectDelivery={handleSelectDelivery}
        />
        <ExpectedDeliveries
          title="Expected Today"
          recentDeliveries={yesterdayDeliveries}
          handleSelectDelivery={handleSelectDelivery}
        />
        <ExpectedDeliveries
          title="Expected Tomorrow"
          recentDeliveries={tomorrowDeliveries}
          handleSelectDelivery={handleSelectDelivery}
        />
      </Box>
    </Box>
  )
})
