import styled from '@emotion/styled'

export const Wrapper = styled.div`
  width: 100%;
  .ant-table-row.update {
    background-color: #fefbe6;
  }
  .ant-table-row.create {
    background-color: #f6ffed;
  }
  .ant-table-row.delete {
    background-color: #fff1f0;
    text-decoration: line-through;
  }

  .ant-table-row:hover td {
    background: none !important;
  }
`
