import React from 'react'

import { useHistory, useLocation } from 'react-router'

import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'
import { InvoiceSourceType } from 'common/server/server_types'

import { InvoiceInboxDetailsDrawer } from 'contractor/components/InvoiceInboxDetailsDrawer'

const Content = () => {
  const detailDrawerRef = React.useRef<DrawerRef>()
  const history = useHistory()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const invoicesInboxSourceId: string = queryParams.get('invoices_inbox_source_id')
  const invoiceInboxType: InvoiceSourceType = queryParams.get('invoices_inbox_source_type') as InvoiceSourceType

  React.useEffect(() => {
    if (invoicesInboxSourceId) {
      detailDrawerRef.current?.show()
    }
  }, [invoicesInboxSourceId])

  const handleClose = () => {
    detailDrawerRef.current?.close()
    const params = new URLSearchParams(location.search)
    params.delete('invoices_inbox_source_id')
    params.delete('invoices_inbox_source_type')
    history.replace({
      search: params.toString(),
    })
  }

  return (
    <InvoiceInboxDetailsDrawer
      ref={detailDrawerRef}
      invoiceInboxType={invoiceInboxType}
      invoicesInboxSourceId={invoicesInboxSourceId}
      onClose={handleClose}
    />
  )
}

export const InvoiceInboxesDetails = observer(Content)
