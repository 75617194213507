import React from 'react'

import { useHistory } from 'react-router-dom'

import SettingOutlined from '@ant-design/icons/SettingOutlined'
import { Button, Tooltip, Typography } from 'antd'

import { Box } from 'common/components/boxes'

export type SelectOptionDescriptorProps = {
  value: string
  label: string
  descriptor?: string
  showSettings?: boolean
}

// HACK: Changed this to a ReactElement function because antd Select component was not allowing the custom renderer for Option
// I'll check antd docs later to see how can this be done without warnings/errors
export const OptionDescriptor = ({ value, label, descriptor, showSettings }: SelectOptionDescriptorProps) => {
  const history = useHistory()

  const hasDescriptor = descriptor !== null && descriptor !== ''

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box display="flex" minWidth={0} flexGrow={1}>
        <Typography.Text ellipsis={{ tooltip: label }}>{label}</Typography.Text>
        {hasDescriptor && (
          <Typography.Text type="secondary" style={{ marginLeft: 4 }} id="project-id">
            {descriptor}
          </Typography.Text>
        )}
      </Box>

      {showSettings && (
        <Tooltip title="Edit project settings">
          <Button
            type="text"
            style={{ marginLeft: 4 }}
            icon={<SettingOutlined />}
            size="small"
            shape="circle"
            onClick={(event) => {
              event.stopPropagation()
              history.push(`/project/${value}`)
            }}
          />
        </Tooltip>
      )}
    </Box>
  )
}
