import axios from 'axios'

export interface InvoiceSuggestions {
  vendor: Nullable<{ external_id: string; external_name: string }>
  customer: Nullable<{ external_id: string; external_name: string }>
  ledger_account: Nullable<{ external_id: string; external_name: string }>
  class?: Nullable<{ external_id: string; external_name: string }>
}

export interface CreateInvoice {
  id: string
  vendor_id?: string
  customer_id?: string
  ledger_account_id?: string
  class_id?: string
}

export function create_invoice(params: CreateInvoice) {
  return axios.post(`/integrations/accounting/invoices/${params?.id}`, params)
}

export function invoice_suggestions(invoice_id: string) {
  return axios.get<InvoiceSuggestions>(`/integrations/accounting/invoices/${invoice_id}/suggestions`)
}

export function mark_as_synced(invoice_id: string) {
  return axios.patch(`/integrations/accounting/invoices/${invoice_id}/mark_as_synced`)
}
