import { BindAll } from 'lodash-decorators'

import ListBaseStore from 'common/stores/ListBaseStore'

import { index, ConsolidatedManufacturerMaterials } from 'contractor/server/manufacturer_materials'

class ManufacturerMaterialListStore extends ListBaseStore<ConsolidatedManufacturerMaterials.ManufacturerMaterials> {
  index = index
}

@BindAll()
export default class ManufacturerMaterialStore {
  listStore: ManufacturerMaterialListStore

  constructor() {
    this.listStore = new ManufacturerMaterialListStore()
  }
}
