import React, { useCallback, useEffect, useState } from 'react'

import styled from '@emotion/styled'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Badge, Switch, Table, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

const TableStyled = styled(Table)`
  width: 100%;
  justify-content: space-between;
`

type RequiredFieldsSettingsProps = {
  onChange: (configs: {}) => void
}

function getRequiredFieldsMap(requiredFields) {
  const requiredFieldsMap = {}
  requiredFields.forEach((setting) => {
    requiredFieldsMap[setting.key + '_order'] = setting.order
    requiredFieldsMap[setting.key + '_quote'] = setting.quote
    requiredFieldsMap[setting.key + '_request'] = setting.request
  })
  return requiredFieldsMap
}

export const RequiredFieldsSettings = observer<RequiredFieldsSettingsProps>(({ onChange }) => {
  const { companySettingStore, userStore, projectStore } = useStores()

  useQuery(() => {
    // Need to set allProjects and allInactiveProjects to empty arrays because it was not triggering the useCallback
    projectStore.allProjects.replace([])
    projectStore.allInactiveProjects.replace([])
    return projectStore.indexAllProjects()
  })

  const makeData = useCallback(() => {
    const requiredOrderFieldsValue = companySettingStore.otherSettings?.required_order_fields
    const requiredQuoteFieldsValue = companySettingStore.otherSettings?.required_quote_fields
    const requiredRequestFieldsValue = companySettingStore.otherSettings?.required_request_fields
    const taxLineItemsValue = companySettingStore.otherSettings?.tax_line_items
    const costCodeSettingsValue = companySettingStore.otherSettings?.cost_code_settings

    const allProjects = [...projectStore.allProjects, ...projectStore.allInactiveProjects]

    return [
      {
        label: 'Description',
        order: true,
        quote: true,
        key: 'description',
        disabledOrder: true,
        disabledQuote: true,
        request: true,
        disabledRequest: true,
      },
      {
        label: 'Unit',
        order: requiredOrderFieldsValue?.unit,
        quote: requiredQuoteFieldsValue?.unit,
        request: requiredRequestFieldsValue?.unit,
        key: 'unit',
      },
      {
        label: 'Quantity',
        order: requiredOrderFieldsValue?.quantity,
        quote: requiredQuoteFieldsValue?.quantity,
        request: requiredRequestFieldsValue?.quantity,
        key: 'quantity',
      },
      {
        label: 'Unit Cost',
        order: requiredOrderFieldsValue?.unit_cost,
        quote: false,
        request: false,
        key: 'unit_cost',
        disabledQuote: true,
        disabledRequest: true,
      },
      {
        label: 'Tax',
        description:
          'This is only editable if Distribute Tax to Line Items is enabled for the company or some project.',
        order: requiredOrderFieldsValue?.tax_value,
        quote: false,
        request: false,
        key: 'tax_value',
        disabledOrder: !taxLineItemsValue?.enabled && allProjects.every((project) => !project.tax_line_items?.enabled),
        disabledQuote: true,
        disabledRequest: true,
      },
      ...(userStore.canUseCostCode
        ? [
            {
              label: 'Cost Code',
              order: requiredOrderFieldsValue?.cost_code,
              quote: requiredQuoteFieldsValue?.cost_code,
              request: requiredRequestFieldsValue?.cost_code,
              key: 'cost_code',
            },
            {
              label: 'Phase Code',
              description: 'This is only editable if phase codes are independent from cost codes.',
              order: requiredOrderFieldsValue?.cost_code_phase,
              quote: requiredQuoteFieldsValue?.cost_code_phase,
              request: requiredRequestFieldsValue?.cost_code_phase,
              key: 'cost_code_phase',
              disabledOrder: !costCodeSettingsValue?.independent_phase_codes_enabled,
              disabledQuote: !costCodeSettingsValue?.independent_phase_codes_enabled,
              disabledRequest: !costCodeSettingsValue?.independent_phase_codes_enabled,
            },
          ]
        : []),
    ]
  }, [
    companySettingStore.otherSettings?.required_order_fields,
    companySettingStore.otherSettings?.required_quote_fields,
    companySettingStore.otherSettings?.required_request_fields,
    companySettingStore.otherSettings?.tax_line_items,
    companySettingStore.otherSettings?.cost_code_settings,
    userStore.canUseCostCode,
    projectStore.allProjects,
    projectStore.allInactiveProjects,
  ])

  const [requiredFields, setRequiredFields] = useState([])

  useEffect(() => {
    const data = makeData()
    setRequiredFields(data)
  }, [makeData])

  const handleToggleField = (item, field) => {
    const newState = requiredFields.map((state) => {
      if (state.key === item.key) {
        return { ...state, [field]: !item[field] }
      }
      return state
    })
    const requiredFieldsMap = getRequiredFieldsMap(requiredFields)
    newState.forEach((setting) => {
      requiredFieldsMap[setting.key + '_' + field] = setting[field]
    })
    setRequiredFields(newState)
    onChange(requiredFieldsMap)
  }

  const columns: ColumnsType<{
    label: string
    description?: string
    disabledOrder?: boolean
    disabledQuote?: boolean
    disabledRequest?: boolean
    key: string
    order: boolean
    quote: boolean
    request: boolean
  }> = [
    {
      title: 'Field',
      dataIndex: 'label',
      width: 195,
      render: (label, record) => (
        <>
          <Typography.Text>{label}</Typography.Text>{' '}
          {record?.description && (
            <Badge
              count={
                <Tooltip placement="topLeft" title={record?.description} arrowPointAtCenter>
                  <QuestionCircleOutlined style={{ top: '-5px', fontSize: 12 }} />
                </Tooltip>
              }
            />
          )}
        </>
      ),
    },
    {
      title: 'Order',
      dataIndex: 'order',
      width: 80,
      render: (order, record) => (
        <Switch
          checked={order}
          disabled={record.disabledOrder}
          onChange={() => {
            handleToggleField(record, 'order')
          }}
        />
      ),
    },
    {
      title: 'Quote',
      dataIndex: 'quote',
      width: 80,
      render: (quote, record) => (
        <Switch
          style={{ paddingRight: '8' }}
          checked={quote}
          disabled={record.disabledQuote}
          onChange={() => {
            handleToggleField(record, 'quote')
          }}
        />
      ),
    },
    {
      title: 'Request',
      dataIndex: 'request',
      width: 80,
      render: (request, record) => (
        <Switch
          style={{ paddingRight: '8' }}
          checked={request}
          disabled={record.disabledRequest}
          onChange={() => {
            handleToggleField(record, 'request')
          }}
        />
      ),
    },
  ]

  return (
    <Box mt={32}>
      <Typography.Title level={5}>Custom Required Fields</Typography.Title>
      <TableStyled dataSource={requiredFields} columns={columns} pagination={false} />
    </Box>
  )
})
