import React, { forwardRef } from 'react'

import { ExternalLink } from '@styled-icons/evaicons-solid'

import styled from '@emotion/styled'

import { CalendarOutlined, DollarOutlined } from '@ant-design/icons'
import { Typography, Space } from 'antd'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { currencyFormatter, formatDateString } from 'common/helpers/formatters'

import { InvoiceStatusTag } from 'contractor/components/Invoices/invoice_status_tag'
import { ShowOrderResponse } from 'contractor/server/orders'

import { InvoiceTotalValues } from './invoice_total_values'

const Item = styled(Box)`
  height: fit-content;
  width: 100%;
  cursor: pointer;
  padding: 16px;
  transition: background 0.3s;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors['gray-4']};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors['gray-2']};
  }
`

type DrawerInvoiceProps = {
  invoices: ShowOrderResponse['invoices']
  grandTotal?: string
  totalInvoicedAmount?: number
  remaining?: number
  orderNumber: string
  showRemaining?: boolean
  showTotalInvoicedAmount?: boolean
}

export const DrawerInvoices = forwardRef<DrawerRef, DrawerInvoiceProps>((props, ref) => {
  const { invoices, grandTotal, totalInvoicedAmount, remaining, orderNumber, showRemaining, showTotalInvoicedAmount } =
    props

  return (
    <Drawer title={`Invoices for PO #${orderNumber}`} ref={ref} closable={false} width={378}>
      <Box width="100%" overflowY="auto" marginRight="0">
        {invoices?.map((invoice) => (
          <Item key={invoice.id} onClick={() => window.open(`/invoice/${invoice.id}`, '_blank')} borderRadius="4px">
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" mb={8}>
              <Box>
                <InvoiceStatusTag state={invoice.state} rejectionReason={invoice?.rejection_reason} />
              </Box>
              <Typography.Link>
                <Box display="inline-flex">
                  {invoice.number}
                  <ExternalLink size="13" />
                </Box>
              </Typography.Link>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Space>
                <CalendarOutlined />
                <Typography.Text>
                  {invoice.document_date ? formatDateString(invoice.document_date) : 'Unknown'}
                </Typography.Text>
              </Space>
              <Space>
                <DollarOutlined />
                <Typography.Text>
                  {invoice.total_amount ? currencyFormatter(invoice.total_amount, 2) : 'Unknown'}
                </Typography.Text>
              </Space>
            </Box>
          </Item>
        ))}

        <InvoiceTotalValues
          grandTotal={grandTotal}
          totalInvoicedAmount={totalInvoicedAmount}
          remaining={remaining}
          invoicesCount={invoices?.length}
          alignRight
          showRemaining={showRemaining}
          showTotalInvoicedAmount={showTotalInvoicedAmount}
        />
      </Box>
    </Drawer>
  )
})
