import React, { useState, useEffect } from 'react'

import styled from '@emotion/styled'

import { Button } from 'antd'

import { Box } from 'common/components/boxes'
import Table from 'common/components/table'
import { useMediaQuery } from 'common/hooks/use-media-query'

const Row = styled(Box)`
  cursor: pointer;
  transition: background 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors['gray-2']};
  }
`

export const ResponsiveTable = (props) => {
  const { hasMore, loadMore, data, renderMobileCard, onClickRow } = props

  const isMobileScreen = useMediaQuery('md')

  const [loadingMore, setLoadingMore] = useState(false)

  useEffect(() => {
    setLoadingMore(false)
  }, [data.length])

  const TableComponent = props.Table ?? Table

  if (isMobileScreen && !!renderMobileCard) {
    return (
      <Box width="100%">
        {data.map((row, rowIndex) => {
          return (
            <Row
              key={row.id}
              width="100%"
              px={24}
              py={12}
              bg="white"
              borderBottom="1px solid"
              borderColor="gray-4"
              onClick={() => onClickRow({ row, rowIndex })}
            >
              {renderMobileCard(row)}
            </Row>
          )
        })}
        {hasMore && (
          <Box mt={16} width="100%" display="flex" justifyContent="center">
            <Button
              type="link"
              onClick={() => {
                setLoadingMore(true)
                loadMore()
              }}
              loading={loadingMore}
            >
              Load More
            </Button>
          </Box>
        )}
      </Box>
    )
  }

  return <TableComponent {...props} />
}
