import { BindAll } from 'lodash-decorators'

import { observable, action, computed } from 'mobx'

import {
  index,
  getVendorsUsers,
  getVendorById,
  VendorList,
  VendorUser,
  Vendor as IVendor,
} from 'contractor/server/vendors'

@BindAll()
export default class Vendor {
  @observable selectedVendor: Nullable<IVendor> = null
  vendors = observable.array<VendorList>([], { deep: true })
  vendorUsers = observable.array<VendorUser>([], { deep: true })

  @computed
  get vendorsWithContacts(): VendorList[] {
    return this.vendors.filter((vendor) => !!vendor.vendor_users_count)
  }

  @action
  async getAllVendors() {
    const { data } = await index()
    this.vendors.replace(data.vendors)
  }

  @action
  async getAllVendorsUsers() {
    const { data } = await getVendorsUsers()
    this.vendorUsers.replace(data.vendor_users)
  }

  @action
  async getVendorById(id: string) {
    const { data } = await getVendorById(id)
    this.selectedVendor = data.vendor
  }
}
