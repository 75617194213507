import React from 'react'

import { Form, Input } from 'antd'

import { Box } from 'common/components/boxes'
import DateTimePicker from 'common/components/date_time_picker'

import { SelectCompanyVendor } from 'contractor/components/SelectCompanyVendor'
import { SelectProject } from 'contractor/components/SelectProject'

export const InfoForm = () => {
  return (
    <>
      <Form.Item rules={[{ required: true, message: 'Please input the price sheet name!' }]} name="name">
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item rules={[{ required: true, message: 'Please input the price vendor name!' }]} name="companyVendor">
        <SelectCompanyVendor placeholder="Vendor" />
      </Form.Item>
      <Form.Item name="projectId">
        <SelectProject />
      </Form.Item>
      <Form.Item name="number">
        <Input placeholder="Price sheet number" />
      </Form.Item>
      <Box width="100%" gridGap={16} display="flex">
        <Form.Item name="startAt" style={{ width: '100%' }}>
          <DateTimePicker placeholder="Start date" wrapperProps={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="endAt"
          style={{ width: '100%' }}
          dependencies={['startAt']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                const startAt = getFieldValue('startAt')
                if (!value || !startAt || value >= startAt) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('End date must be greater than start date!'))
              },
            }),
          ]}
        >
          <DateTimePicker placeholder="End date" wrapperProps={{ width: '100%' }} />
        </Form.Item>
      </Box>
    </>
  )
}
