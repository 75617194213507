import axios from 'axios'

import {
  BasicRelationship,
  ListParams,
  MappingSuggestion,
  Relationship,
  SearchParams,
  UpdateMapping,
} from './integration'

export type UpdateCompanyVendor = UpdateMapping

export interface CompanyVendor {
  id: string
  domain: string
  vendor_name: string
  vendor?: { id: string; name: string }
  external_relationships: BasicRelationship[]
  mapping_suggestions?: MappingSuggestion[]
}

export function company_vendors_count(mappingDirection: string, params?: SearchParams) {
  const queryParams = params
    ? { ...params, mapping_direction: mappingDirection }
    : { mapping_direction: mappingDirection }
  return axios.get<{ [key: string]: number }>(`/integrations/accounting/company_vendors/count`, { params: queryParams })
}
export function company_vendors_relationships(params?: ListParams) {
  return axios.get<Relationship[]>(`/integrations/accounting/company_vendors/relationships`, { params })
}
export function company_vendors(params: ListParams) {
  return axios.get<CompanyVendor[]>(`/integrations/accounting/company_vendors`, { params })
}
export function update_company_vendor(company_vendor: UpdateCompanyVendor) {
  return axios.patch(`/integrations/accounting/company_vendors/${company_vendor?.id}`, company_vendor)
}
export function resync_company_vendors() {
  return axios.patch('/integrations/accounting/company_vendors/resync')
}
