import React from 'react'

import { Link } from 'react-router-dom'

import { uniq } from 'lodash'

import { DeleteOutlined } from '@ant-design/icons'
import { Button, Form, Input, Tabs, Typography, List, Tooltip, Select } from 'antd'

import { FlexBoxY } from 'common/components/boxes'
import { Drawer, DrawerRef, DrawerBody, DrawerFooter } from 'common/components/Drawer'
import { formatName } from 'common/helpers/formatters'

import { ManagedUser } from 'contractor/server/company_settings/manage_users'
import { IndexProject } from 'contractor/server/projects'

export interface ManageGroupDrawerForm {
  id?: string
  name: string
  selectedProjectsIds: Array<string>
  selectedCompanyUsersIds: Array<string>
}

interface Props {
  onClose?: () => void
  onSubmit: (values: ManageGroupDrawerForm) => void | Promise<void>
  projects: Array<IndexProject>
  users: Array<ManagedUser>
  projectGroupId?: string
}

export type ManageGroupDrawerRef = DrawerRef & {
  show: (defaultValues?: Partial<ManageGroupDrawerForm>) => void
}

function Element(props: Props, ref: React.RefObject<ManageGroupDrawerRef>) {
  const { onClose, onSubmit, users, projectGroupId } = props

  const [projects, setProjects] = React.useState<Array<IndexProject>>([])

  const [form] = Form.useForm()

  const [isSubmitting, setSubmitting] = React.useState(false)

  const [selectedProjectsIds, setSelectedProjectsIds] = React.useState([])
  const [selectedCompanyUsersIds, setSelectedCompanyUsersIds] = React.useState([])

  function handleAddProject(id: string) {
    setSelectedProjectsIds((prevs) => uniq([...prevs, id]))
  }

  function handleRemoveProject(id: string) {
    setSelectedProjectsIds((prevProjectsIds) => prevProjectsIds.filter((projectId) => projectId !== id))
  }

  function handleAddUser(id: string) {
    setSelectedCompanyUsersIds((prevs) => uniq([...prevs, id]))
  }

  function handleRemoveUser(id: string) {
    setSelectedCompanyUsersIds((prevUsersIds) => prevUsersIds.filter((userId) => userId !== id))
  }

  function cleanUpForm() {
    setSelectedCompanyUsersIds([])
    setSelectedProjectsIds([])
    form.resetFields()
  }

  function handleClose() {
    cleanUpForm()

    if (onClose) {
      onClose()
    }
  }

  async function handleSubmit(formValues: ManageGroupDrawerForm) {
    const { name } = formValues

    try {
      setSubmitting(true)

      await onSubmit({
        id: projectGroupId,
        name,
        selectedProjectsIds,
        selectedCompanyUsersIds,
      })

      cleanUpForm()
    } finally {
      setSubmitting(false)
    }
  }

  const drawerRef = React.useRef<DrawerRef>(null)
  React.useImperativeHandle(ref, () => ({
    close: drawerRef.current?.close,
    toggle: drawerRef.current?.toggle,
    show: (defaultValues?: Partial<ManageGroupDrawerForm & { id: string }>) => {
      if (defaultValues) {
        form.setFieldsValue({ name: defaultValues?.name })

        setSelectedProjectsIds(defaultValues.selectedProjectsIds || [])
        setSelectedCompanyUsersIds(defaultValues.selectedCompanyUsersIds || [])

        setProjects(
          props.projects.filter(
            (project) => !project?.project_group_id || project?.project_group_id === defaultValues.id,
          ),
        )
      } else {
        setProjects(props.projects.filter((project) => !project?.project_group_id))
      }

      drawerRef.current?.show()
    },
  }))

  const selectedProjects = React.useMemo(() => {
    return projects.filter((project) => selectedProjectsIds.includes(project.id))
  }, [projects, selectedProjectsIds])

  const selectedUsers = React.useMemo(() => {
    return users.filter((user) => selectedCompanyUsersIds.includes(user.company_user_id))
  }, [users, selectedCompanyUsersIds])

  return (
    <Drawer bgGray ref={drawerRef} title="Manage group" onClose={handleClose}>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <DrawerBody>
          <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input the group name!' }]}>
            <Input placeholder="Miami" />
          </Form.Item>

          <Tabs
            centered
            items={[
              {
                label: 'Projects',
                key: 'projects',
                children: (
                  <>
                    <Select
                      placeholder="Select a project"
                      style={{ width: '100%' }}
                      onChange={([id]) => {
                        handleAddProject(id)
                      }}
                      mode="multiple"
                      value={[]}
                      showSearch
                      filterOption={(input, option) => {
                        const inputValue = input.toLowerCase()
                        return option?.searchable?.toLowerCase().includes(inputValue)
                      }}
                    >
                      {projects.map(({ id, name, project_id }) => (
                        <Select.Option
                          key={id}
                          value={id}
                          searchable={`${name}:${project_id}`}
                          className={
                            selectedProjects.map((project) => project.id).includes(id)
                              ? 'ant-select-item-option-selected'
                              : ''
                          }
                        >
                          {name}
                        </Select.Option>
                      ))}
                    </Select>

                    <List
                      dataSource={selectedProjects}
                      renderItem={(item) => (
                        <List.Item
                          actions={[
                            <Tooltip key="remove-project" title="Remove project">
                              <Button danger icon={<DeleteOutlined />} onClick={() => handleRemoveProject(item.id)} />
                            </Tooltip>,
                          ]}
                        >
                          <Tooltip title="Click to go to project's orders">
                            <Link to={`/orders?project_id=${item.id}`}>{item.name}</Link>
                          </Tooltip>
                        </List.Item>
                      )}
                    />
                    <Typography.Text strong>{`# ${selectedProjects.length} Projects`}</Typography.Text>
                  </>
                ),
              },
              {
                label: 'Users',
                key: 'users',
                children: (
                  <>
                    <Select
                      placeholder="Select a user"
                      style={{ width: '100%' }}
                      onChange={([id]) => {
                        handleAddUser(id)
                      }}
                      mode="multiple"
                      value={[]}
                      showSearch
                      filterOption={(input, option) => {
                        const inputValue = input.toLowerCase()
                        const label = option?.label as string
                        return label?.toLowerCase().includes(inputValue)
                      }}
                    >
                      {users.map((user) => {
                        const label =
                          user.first_name && user.last_name ? formatName(user.first_name, user.last_name) : user?.email
                        return (
                          <Select.Option
                            key={user.company_user_id}
                            value={user.company_user_id}
                            label={label}
                            className={
                              selectedUsers.map((user) => user.id).includes(user.id)
                                ? 'ant-select-item-option-selected'
                                : ''
                            }
                          >
                            {label}
                          </Select.Option>
                        )
                      })}
                    </Select>

                    <List
                      dataSource={selectedUsers}
                      renderItem={(item) => (
                        <List.Item
                          actions={[
                            <Tooltip key="remove-user" title="Remove user">
                              <Button
                                danger
                                icon={<DeleteOutlined onClick={() => handleRemoveUser(item.company_user_id)} />}
                              />
                            </Tooltip>,
                          ]}
                        >
                          <FlexBoxY alignItems="flex-start">
                            <Typography.Text>{formatName(item.first_name, item.last_name)}</Typography.Text>
                            <Typography.Text type="secondary">{item.email}</Typography.Text>
                          </FlexBoxY>
                        </List.Item>
                      )}
                    />
                    <Typography.Text strong>{`# ${selectedUsers.length} Users`}</Typography.Text>
                  </>
                ),
              },
            ]}
            style={{ margin: 0 }}
          />
        </DrawerBody>

        <DrawerFooter>
          <Button onClick={onClose} disabled={isSubmitting} style={{ width: 100 }}>
            Cancel
          </Button>

          <Button type="primary" htmlType="submit" loading={isSubmitting} style={{ width: 100 }}>
            Save
          </Button>
        </DrawerFooter>
      </Form>
    </Drawer>
  )
}

export const ManageGroupDrawer = React.forwardRef<ManageGroupDrawerRef, Props>(Element)
