import React from 'react'

import { UpSquareOutlined } from '@ant-design/icons'
import { Typography, Button } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { FlexBoxX } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'

type PriceLineItemProps = {
  value?: number
  title?: string
  companyVendorId?: string
  option?: string
  onChange: (value: number) => void
}

export const PriceLineItem = ({ value, title, companyVendorId, option, onChange }: PriceLineItemProps) => (
  <FlexBoxX justifyContent="space-between">
    <Typography.Text ellipsis>{title}</Typography.Text>
    <FlexBoxX justifyContent="end">
      <Typography.Text style={{ marginLeft: 16, whiteSpace: 'nowrap' }}>
        {value ? currencyFormatter(value) : 'N/A'}
      </Typography.Text>
      <Button
        type="text"
        size="small"
        style={{ marginLeft: 8, width: 20, height: 20 }}
        onClick={() => {
          onChange(value)
          trackEvent(Events.SELECT_PRICE_FROM_PRICING_HISTORY, window.location.pathname, {
            vendor_id: companyVendorId,
            option: option,
          })
        }}
        icon={<UpSquareOutlined />}
      />
    </FlexBoxX>
  </FlexBoxX>
)
