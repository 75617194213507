import React from 'react'

import { CellProps } from 'react-table'

import { Typography, Avatar } from 'antd'

import { Box, BoxProps } from 'common/components/boxes'
import { ContractorIcon } from 'common/components/ContractorIcon'
import { SubBaseWhiteIcon } from 'common/components/SubBaseIcon'
import { VendorIcon } from 'common/components/VendorIcon'
import { formatDateDiff } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'
import { OrderHit } from 'common/server/orders'

const isVendorAuthor = (authorType) => ['VendorUser', 'CompanyVendorContact'].includes(authorType)

const LastActionIcon = ({ authorType = '' }) => {
  if (authorType === 'User') {
    return <ContractorIcon />
  }

  if (isVendorAuthor(authorType)) {
    return <VendorIcon />
  }

  return <SubBaseWhiteIcon />
}

type CellLastActionProps = {
  currentType: 'User' | 'VendorUser' | 'CompanyVendorContact' | 'SubBase'
  boxProps?: BoxProps
} & CellProps<OrderHit>

export const CellLastAction = ({ value: lastAction, currentType, boxProps }: CellLastActionProps) => {
  const theme = useTheme()

  if (!lastAction) {
    return <Typography.Text type="secondary">Unknown</Typography.Text>
  }

  const backgroundColor =
    (lastAction?.author_type === currentType || currentType.includes(lastAction?.author_type)) && theme.colors.primary

  return (
    <Box display="inline-flex" alignItems="center" {...boxProps}>
      <Avatar
        style={{
          backgroundColor,
          minWidth: 32,
        }}
        icon={<LastActionIcon authorType={lastAction?.author_type} />}
      />
      <Box display="flex" flexDirection="column" ml={8}>
        <Typography.Text>{lastAction?.author_name}</Typography.Text>
        <Typography.Text type="secondary">{formatDateDiff(lastAction?.created_at)}</Typography.Text>
      </Box>
    </Box>
  )
}
