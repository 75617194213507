import React, { createContext, useContext, useRef } from 'react'

import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'

const CalendarContext = createContext<CalendarContextProps>({} as CalendarContextProps)

export const useCalendar = () => useContext(CalendarContext)

type CalendarContextProps = {
  deliveryDetailRef: React.MutableRefObject<DrawerRef>
}

export const CalendarProvider = observer(({ children }) => {
  const deliveryDetailRef = useRef<DrawerRef>()

  return (
    <CalendarContext.Provider
      value={{
        deliveryDetailRef,
      }}
    >
      {children}
    </CalendarContext.Provider>
  )
})

export const withCalendarProvider = (Component) => (props) => {
  return (
    <CalendarProvider>
      <Component {...props} />
    </CalendarProvider>
  )
}
