import React, { useState } from 'react'

import { Avatar, Timeline, Typography, Button, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { ContractorIcon } from 'common/components/ContractorIcon'
import { CollapsibleContent, Loading, makeTitle } from 'common/components/History'
import { SubBaseWhiteIcon } from 'common/components/SubBaseIcon'
import { useTheme } from 'common/hooks/use-theme'
import { InvoiceHistoryResponse } from 'common/server/invoice_history'

import { Invoice, ALLOW_INVOICE_FIELDS } from './invoice'
import { Orders } from './orders'

const filterAllowHistoryEvents = (history: InvoiceHistoryResponse) => {
  const hasChange = Object.keys(history.invoice).some((key) => ALLOW_INVOICE_FIELDS.includes(key))

  return hasChange || history?.orders?.length
}

type InvoiceHistoryProps = {
  isLoading?: boolean
  data: InvoiceHistoryResponse[]
}

const Icon = ({ author }) => {
  if ((author?.company_id && author?.email.includes('subbase.io')) || !author) {
    return <SubBaseWhiteIcon />
  }

  return <ContractorIcon />
}

export const InvoiceHistory = observer((props: InvoiceHistoryProps) => {
  const { isLoading, data } = props
  const [expanded, setExpand] = useState<Record<string, boolean>>({ 0: true })

  const theme = useTheme()

  const hasExpanded = Object.values(expanded).length > 1

  const handleToggleItem = (index: number) => {
    const value = Object.prototype.hasOwnProperty.call(expanded, index) ? !expanded[index] : true
    setExpand((prev) => ({
      ...prev,
      [index]: value,
    }))
  }

  const handleToggleAll = () => {
    if (hasExpanded) {
      setExpand({})
    } else {
      const expandeds = {}
      data.forEach((_, index) => {
        expandeds[index] = true
      })
      setExpand(expandeds)
    }
  }

  const getStyle = (author) => {
    if ((author?.company_id && author?.email.includes('subbase.io')) || !author) {
      return {
        backgroundColor: theme.colors.primary,
      }
    }

    return {}
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end">
      <Button type="primary" size="small" onClick={handleToggleAll}>
        {hasExpanded ? 'Collapse All' : 'Expand All'}
      </Button>
      <Timeline style={{ paddingLeft: 11, paddingTop: 2, width: '100%', marginTop: 16 }}>
        {data.filter(filterAllowHistoryEvents).map((history, index) => {
          const isCreate = history.event === 'create'

          return (
            <Timeline.Item
              key={history.created_at}
              dot={
                <Tooltip title={expanded[index] ? 'Collapse' : 'Expand'}>
                  <span onClick={() => handleToggleItem(index)} style={{ cursor: 'pointer' }}>
                    <Avatar style={getStyle(history?.author)} icon={<Icon author={history?.author} />} />
                  </span>
                </Tooltip>
              }
            >
              <Box pl="4px" onClick={() => handleToggleItem(index)} style={{ cursor: 'pointer' }}>
                <Typography.Text style={{ marginBottom: 12 }}>
                  <span dangerouslySetInnerHTML={{ __html: makeTitle(history?.title, history?.author?.name) }} />
                </Typography.Text>

                <CollapsibleContent isOpen={expanded[index]}>
                  <Invoice invoice={history.invoice} snapshot={history.snapshot} isCreate={isCreate} />

                  {history?.orders?.length && <Orders orders={history?.orders} />}
                </CollapsibleContent>
              </Box>
            </Timeline.Item>
          )
        })}
      </Timeline>
    </Box>
  )
})
