import React, { useState } from 'react'

import { DataViewerProps } from 'react-spreadsheet'

import HistoryOutlined from '@ant-design/icons/HistoryOutlined'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import { Button, Popover, Typography } from 'antd'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import { useQuery } from 'common/hooks/use-query'

type PopoverContentProps = {
  onClose: () => void
  companyMaterialId: string
} & Pick<DescriptionViewerCellProps, 'onLoadMaterial' | 'onSelectMaterial'>

const PopoverContent = ({ onClose, onLoadMaterial, companyMaterialId, onSelectMaterial }: PopoverContentProps) => {
  const { isLoading, data } = useQuery(() => onLoadMaterial(companyMaterialId))

  if (isLoading) {
    return (
      <FlexBoxX>
        <LoadingOutlined />
      </FlexBoxX>
    )
  }

  const handleClick = () => {
    onSelectMaterial(data)
    onClose()
  }

  return (
    <FlexBoxY>
      <FlexBoxX alignItems="flex-start" marginBottom="5px">
        <Typography.Text style={{ marginRight: '3px' }} strong>
          Last Order Price:
        </Typography.Text>
        {data?.cached_last_price ? currencyFormatter(data?.cached_last_price) : 'N/A'}
      </FlexBoxX>
      <FlexBoxX alignItems="flex-start" marginBottom="5px">
        <Typography.Text style={{ marginRight: '3px' }} strong>
          Average Order Price:
        </Typography.Text>
        {data?.cached_average_price ? currencyFormatter(data?.cached_average_price, 2) : 'N/A'}
      </FlexBoxX>
      <FlexBoxX alignItems="flex-start" marginBottom="15px">
        <Typography.Text style={{ marginRight: '3px' }} strong>
          Lowest Order Price:
        </Typography.Text>
        {data?.cached_lowest_price ? currencyFormatter(data?.cached_lowest_price) : 'N/A'}
      </FlexBoxX>
      <FlexBoxX>
        <Button type="primary" onClick={handleClick}>
          More Info
        </Button>
      </FlexBoxX>
    </FlexBoxY>
  )
}

interface CompanyMaterial {
  cached_last_price: string
  cached_average_price: string
  cached_lowest_price: string
}

type DescriptionViewerCellProps = {
  onLoadMaterial: (companyMaterialId: string) => Promise<CompanyMaterial>
  showMaterialDetails?: boolean
  onSelectMaterial: (CompanyMaterial: CompanyMaterial) => void
} & DataViewerProps

export const DescriptionViewerCell = ({
  cell,
  showMaterialDetails,
  onLoadMaterial,
  onSelectMaterial,
}: DescriptionViewerCellProps) => {
  const [visible, setVisible] = useState(false)

  const value = cell.value
  const companyMaterialId = cell?.['materialRow']?.['company_material']?.['id']

  return (
    <FlexBoxX alignItems="center" justifyContent="space-between" ml={5}>
      {value}

      {/* Only show the popover if this is not the vendor portal */}
      {companyMaterialId && value && showMaterialDetails && (
        <Popover
          placement="right"
          title={value}
          content={
            <PopoverContent
              onClose={() => setVisible(false)}
              onLoadMaterial={onLoadMaterial}
              companyMaterialId={companyMaterialId}
              onSelectMaterial={onSelectMaterial}
            />
          }
          open={visible}
          onOpenChange={setVisible}
        >
          <HistoryOutlined />
        </Popover>
      )}
    </FlexBoxX>
  )
}
