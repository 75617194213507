import React from 'react'

import { Mentions, MentionProps as AntdMentionProps } from 'antd'

import { formatName } from 'common/helpers/formatters'

import { ManagedUser } from 'contractor/server/company_settings/manage_users'

type MentionProps = {
  users: ManagedUser[]
} & AntdMentionProps

export const makeMentionTag = (user) => `${user.first_name}${user.last_name}`.trim()

const makeMentionOption = (user) => ({
  value: makeMentionTag(user),
  label: formatName(user.first_name, user.last_name),
})

export const Mention = ({ users, ...props }: MentionProps) => {
  return (
    <Mentions
      style={{ resize: 'none', width: '100%' }}
      options={users?.filter((user) => !!user?.first_name && !!user.last_name)?.map(makeMentionOption)}
      autoFocus
      rows={4}
      placeholder="Type your message here and use the '@' symbol to tag someone."
      {...props}
    />
  )
}
