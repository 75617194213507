import React, { useEffect, useState } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'

import moment from 'moment'

import { LoadingOutlined } from '@ant-design/icons'
import { Button, Divider, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { useTheme } from 'common/hooks/use-theme'

import { useStores } from 'contractor/hooks/use-stores'

export const ClientLogs = ({ showUpdateButton, lastUpdate }) => {
  const theme = useTheme()
  const { integrationStore } = useStores()
  const [lastActions, setLastActions] = useState([])
  const [relativeTime, setRelativeTime] = useState(true)

  useEffect(() => {
    integrationStore.client_last_actions_list(true).then((res) => {
      setLastActions(Object.entries(res))
    })
  }, [integrationStore.syncingProgress?.init_data_progress, lastUpdate])

  const updateLogs = () => {
    integrationStore.client_last_actions_list(true).then((res) => {
      setLastActions(Object.entries(res))
    })
  }

  const onLoadMore = () => {
    integrationStore.client_last_actions_list(false).then((res) => {
      setLastActions([...lastActions, ...Object.entries(res)])
    })
  }

  const getIntegrationLogColor = (message) => {
    if (/\bresponse: 4\d{2}\b|\bresponse: 5\d{2}\b|\bstatus 4\d{2}\b|\bstatus 5\d{2}\b|\bError\b/i.test(message)) {
      return theme.colors['red-7']
    } else if (/INFO/.test(message)) {
      return theme.colors['blue-7']
    } else if (/SCHEDULED/.test(message)) {
      return theme.colors['gold-7']
    } else return theme.colors['green-7']
  }

  const getIsResponse = (message) => {
    return /response:|completed:/.test(message)
  }

  const getIsInfo = (message) => {
    return /INFO/.test(message)
  }

  const getIsScheduled = (message) => {
    return /SCHEDULED/.test(message)
  }

  const RequestArrow = () => {
    return (
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
        <path d="M4 12L20 12" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14 6L20 12L14 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }

  const ResponseArrow = () => {
    return (
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
        <path d="M20 12L4 12" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 6L4 12L10 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }

  const LetterI = () => {
    return (
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
        <rect x="11" y="8" width="2" height="12" fill="black" />
        <circle cx="12" cy="5" r="1.5" fill="black" />
      </svg>
    )
  }

  const Clock = () => {
    return (
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
        <circle cx="12" cy="12" r="10" stroke="black" strokeWidth="2" fill="none" />
        <circle cx="12" cy="12" r="1" fill="black" />
        <rect x="11.5" y="6" width="1" height="6" fill="black" transform="rotate(90 12 12)" />
        <rect x="11.5" y="2" width="1" height="8" fill="black" />
      </svg>
    )
  }

  return (
    <>
      {lastActions.length > 0 && (
        <Box height="43vh">
          <Divider />

          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Button onClick={() => setRelativeTime(!relativeTime)} size="small">
              {relativeTime ? 'Absolute time' : 'Relative time'}
            </Button>

            <Typography.Title level={5} style={{ margin: 10 }}>
              Last integration activities
            </Typography.Title>
            {showUpdateButton && (
              <Button onClick={updateLogs} size="small">
                Update
              </Button>
            )}
          </Box>

          <Box id="scrollableInbox" width="100%" display="flex" flexDirection="column" overflowY="auto" height="43vh">
            <InfiniteScroll
              scrollThreshold={0.9}
              scrollableTarget="scrollableInbox"
              dataLength={lastActions.length}
              next={onLoadMore}
              hasMore={true}
              loader={<LoadingOutlined />}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>You have seen it all</b>
                </p>
              }
            >
              {lastActions.map((entry, index) => {
                const dateTimeText = relativeTime ? moment.utc(entry[0]).fromNow() : entry[0]
                return (
                  <Box
                    key={index}
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ borderTop: '1px solid #E8E8E8' }}
                  >
                    <Typography.Text style={{ color: theme.colors['gray-7'], marginRight: 10, whiteSpace: 'nowrap' }}>
                      {dateTimeText}
                    </Typography.Text>

                    {getIsInfo(entry[1]) ? (
                      <LetterI />
                    ) : getIsResponse(entry[1]) ? (
                      <ResponseArrow />
                    ) : getIsScheduled(entry[1]) ? (
                      <Clock />
                    ) : (
                      <RequestArrow />
                    )}

                    <Typography.Text
                      style={{
                        color: getIntegrationLogColor(entry[1]),
                        marginLeft: 10,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                      }}
                    >
                      {entry[1]}
                    </Typography.Text>
                  </Box>
                )
              })}
            </InfiniteScroll>
          </Box>
        </Box>
      )}
    </>
  )
}
