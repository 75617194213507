import React, { useEffect, useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { Loading } from 'common/components/Loading'
import { useQuery } from 'common/hooks/use-query'
import { InvoicesStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { StateResults } from 'contractor/pages/@v2/Invoices/tabs/ToReview/state_results'

import { InvoiceTab } from '../List/invoice_tab'

export const ToReview = observer(({ tabKey }: { tabKey?: string }) => {
  const { invoiceStore, userStore, invoiceStateStore } = useStores()
  const listStore = invoiceStore.listStore

  const { isLoading } = useQuery(invoiceStateStore.getAllInvoiceStates)
  const finalState = useMemo(
    () => invoiceStateStore.invoiceStates?.find((state) => state.state === InvoicesStates.POSTED),
    [invoiceStateStore.invoiceStates.length],
  )

  // Ensure we have the right filters in place
  useEffect(() => {
    if (userStore.currentUser) {
      listStore.setPageFilters('assigned_to_id', userStore.currentUser?.id)
    }
  }, [userStore.currentUser?.id])

  // When we unmount, clean up our page filters
  React.useEffect(() => {
    return () => {
      listStore.resetState()
    }
  }, [])

  // Do this to avoid any data showing up until we've applied the right filters in place
  if (isLoading || !finalState?.label || !userStore.currentUser?.id) {
    return <Loading />
  }

  return (
    <InvoiceTab
      EmptyState={StateResults}
      ribbonFilterExcludedStates={InvoicesStates.POSTED}
      excludedColumns={['Assigned To', 'Posted At']}
      finalState={finalState?.label}
      tabKey={tabKey}
    />
  )
})
