import React from 'react'

import { SelectUnit } from 'common/components/SelectUnit'

export const UnitsEditorCell = ({ cell, onChange, units = [] }) => {
  return (
    <SelectUnit
      units={units}
      value={cell.value}
      onChange={(_, value) => {
        onChange({ ...cell, value })
      }}
      autoFocus
    />
  )
}
