import React, { useMemo, useRef } from 'react'

import { useLocation } from 'react-router'

import { ArrowSort, ArrowSortDown, ArrowSortUp } from '@styled-icons/fluentui-system-filled'

import styled from '@emotion/styled'

import { DeleteOutlined } from '@ant-design/icons'
import { Button, Typography } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import theme from 'common/styles/theme'

export type DrawerTableSortOptionsType<T extends string = string, P extends string = string> = {
  id: T
  description: P
}
type DirectionType = 'asc' | 'desc'

const ItemStyled = styled(FlexBoxX)`
  justify-content: flex-start;
  cursor: pointer;
  height: 48px;
  border: 1px solid ${({ theme }) => theme.colors['gray-4']};

  &:hover {
    background-color: ${({ theme }) => theme.colors['gray-2']};
  }
`

const ItemStyledActived = styled(ItemStyled)`
  background-color: ${({ theme }) => theme.colors['blue-1']};
  border-color: ${({ theme }) => theme.colors['primary']};
  border-right-color: ${({ theme }) => theme.colors['blue-1']};
`

export const DrawerTableSort = ({ options }: { options: DrawerTableSortOptionsType[] }) => {
  const drawerSortRef = useRef<DrawerRef>()
  const location = useLocation()
  const persistentFilters = usePersistentFilters()

  const option = useMemo<{ field: string; direction: DirectionType }>(() => {
    const searchParams = new URLSearchParams(location.search)

    return {
      field: searchParams.get('sort'),
      direction: searchParams.get('sort_direction') as DirectionType,
    }
  }, [location.search])

  const handleClear = () => {
    persistentFilters.handleChangeSort()
  }

  const handleClick = (field: DrawerTableSortOptionsType['id']) => {
    const searchParams = new URLSearchParams(location.search)
    const direction = searchParams.get('sort_direction') === 'asc' ? 'desc' : 'asc'

    persistentFilters.handleChangeSort({ direction, field })
  }

  return (
    <>
      <Button data-cy="sort" type="ghost" style={{ height: 32 }} onClick={() => drawerSortRef.current.show()}>
        <ArrowSort style={{ color: theme.colors.black, width: 16 }} />
      </Button>

      <Drawer ref={drawerSortRef} width={378} title="Sort options" forceRender closable={false}>
        <Box width="100%" overflow="auto" pt={16}>
          {options.map((item) => {
            if (item.id === option.field) {
              const IconDirection = option.direction === 'asc' ? ArrowSortUp : ArrowSortDown
              return (
                <ItemStyledActived key={item.id} onClick={() => handleClick(item.id)}>
                  <FlexBoxX justifyContent="flex-start">
                    <Box width={8} height={48} backgroundColor={theme.colors.primary} />

                    <Typography.Text style={{ marginLeft: 8 }}>{item.description}</Typography.Text>
                  </FlexBoxX>

                  <Box px={16}>
                    <IconDirection style={{ color: theme.colors.black, width: 16 }} />
                  </Box>
                </ItemStyledActived>
              )
            }

            return (
              <ItemStyled key={item.id} onClick={() => handleClick(item.id)}>
                <Typography.Text style={{ marginLeft: 16 }}>{item.description}</Typography.Text>
              </ItemStyled>
            )
          })}

          <Box display="flex" justifyContent="center" mt={24}>
            <Button type="ghost" danger icon={<DeleteOutlined style={{ fontSize: 16 }} />} onClick={handleClear}>
              Clear sort
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  )
}
