import React, { useState } from 'react'

import { Button, message, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { useQuery } from 'common/hooks/use-query'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import CommitmentsTable from 'contractor/pages/CompanySettings/Permissions/commitments_table'
import InvoicesTable from 'contractor/pages/CompanySettings/Permissions/invoices_table'
import OrdersTable from 'contractor/pages/CompanySettings/Permissions/orders_table'
import {
  CommitmentPermissions,
  InvoicePermissions,
  OrderPermissions,
  Permissions,
} from 'contractor/server/company_settings/permissions'

const CompanyPermissions = observer(() => {
  const { companySettingStore, userStore } = useStores()

  const { isLoading } = useQuery(companySettingStore.indexPermissions)
  const canUseCommitments = useFlag('commitments')

  const invoicePermissions = companySettingStore?.permissions?.invoices_permissions
  const orderPermissions = companySettingStore?.permissions?.order_permissions
  const commitmentsPermissions = companySettingStore?.permissions?.commitment_permissions

  const [newPermissions, setNewPermissions] = useState<Permissions>({
    invoices_permissions: {
      ADMIN: {
        can_view_all_invoices: invoicePermissions?.ADMIN?.can_view_all_invoices,
        can_view_invoice_inbox: invoicePermissions?.ADMIN?.can_view_invoice_inbox,
        can_create_quick_po: invoicePermissions?.ADMIN?.can_create_quick_po,
        can_edit_and_delete_invoices: invoicePermissions?.ADMIN?.can_edit_and_delete_invoices,
        can_view_all_my_project_invoices: invoicePermissions?.ADMIN?.can_view_all_my_project_invoices,
        can_post_invoices: invoicePermissions?.ADMIN?.can_post_invoices,
      },
      PURCHASER: {
        can_view_all_invoices: invoicePermissions?.PURCHASER?.can_view_all_invoices,
        can_view_invoice_inbox: invoicePermissions?.PURCHASER?.can_view_invoice_inbox,
        can_create_quick_po: invoicePermissions?.PURCHASER?.can_create_quick_po,
        can_edit_and_delete_invoices: invoicePermissions?.PURCHASER?.can_edit_and_delete_invoices,
        can_view_all_my_project_invoices: invoicePermissions?.PURCHASER?.can_view_all_my_project_invoices,
        can_post_invoices: invoicePermissions?.PURCHASER?.can_post_invoices,
      },
      USER: {
        can_view_all_invoices: invoicePermissions?.USER?.can_view_all_invoices,
        can_view_invoice_inbox: invoicePermissions?.USER?.can_view_invoice_inbox,
        can_create_quick_po: invoicePermissions?.USER?.can_create_quick_po,
        can_edit_and_delete_invoices: invoicePermissions?.USER?.can_edit_and_delete_invoices,
        can_view_all_my_project_invoices: invoicePermissions?.USER?.can_view_all_my_project_invoices,
        can_post_invoices: invoicePermissions?.USER?.can_post_invoices,
      },
      MATERIAL_REQUESTER: {
        can_view_all_invoices: invoicePermissions?.MATERIAL_REQUESTER?.can_view_all_invoices,
        can_view_invoice_inbox: invoicePermissions?.MATERIAL_REQUESTER?.can_view_invoice_inbox,
        can_create_quick_po: invoicePermissions?.MATERIAL_REQUESTER?.can_create_quick_po,
        can_edit_and_delete_invoices: invoicePermissions?.MATERIAL_REQUESTER?.can_edit_and_delete_invoices,
        can_view_all_my_project_invoices: invoicePermissions?.MATERIAL_REQUESTER?.can_view_all_my_project_invoices,
        can_post_invoices: invoicePermissions?.MATERIAL_REQUESTER?.can_post_invoices,
      },
    },
    order_permissions: {
      ADMIN: {
        can_see_orders_from_all_groups: orderPermissions?.ADMIN?.can_see_orders_from_all_groups,
        can_view_historical_pricing: orderPermissions?.ADMIN?.can_view_historical_pricing,
        can_edit_vendor_database: orderPermissions?.ADMIN?.can_edit_vendor_database,
        can_edit_material_database: orderPermissions?.ADMIN?.can_edit_material_database,
        can_create_new_material: orderPermissions?.ADMIN?.can_create_new_material,
        can_sync_with_erp: orderPermissions?.ADMIN?.can_sync_with_erp,
        can_send_and_update_orders: orderPermissions?.ADMIN?.can_send_and_update_orders,
        can_edit_delivery_information: orderPermissions?.ADMIN?.can_edit_delivery_information,
        can_send_and_update_rfqs: orderPermissions?.ADMIN?.can_send_and_update_rfqs,
        can_create_drafts: orderPermissions?.ADMIN?.can_create_drafts,
        can_input_vendor_data: orderPermissions?.ADMIN?.can_input_vendor_data,
      },
      PURCHASER: {
        can_see_orders_from_all_groups: orderPermissions?.PURCHASER?.can_see_orders_from_all_groups,
        can_view_historical_pricing: orderPermissions?.PURCHASER?.can_view_historical_pricing,
        can_edit_vendor_database: orderPermissions?.PURCHASER?.can_edit_vendor_database,
        can_edit_material_database: orderPermissions?.PURCHASER?.can_edit_material_database,
        can_create_new_material: orderPermissions?.ADMIN?.can_create_new_material,
        can_sync_with_erp: orderPermissions?.PURCHASER?.can_sync_with_erp,
        can_send_and_update_orders: orderPermissions?.PURCHASER?.can_send_and_update_orders,
        can_edit_delivery_information: orderPermissions?.PURCHASER?.can_edit_delivery_information,
        can_send_and_update_rfqs: orderPermissions?.PURCHASER?.can_send_and_update_rfqs,
        can_create_drafts: orderPermissions?.PURCHASER?.can_create_drafts,
        can_input_vendor_data: orderPermissions?.PURCHASER?.can_input_vendor_data,
      },
      USER: {
        can_see_orders_from_all_groups: orderPermissions?.USER?.can_see_orders_from_all_groups,
        can_view_historical_pricing: orderPermissions?.USER?.can_view_historical_pricing,
        can_edit_vendor_database: orderPermissions?.USER?.can_edit_vendor_database,
        can_edit_material_database: orderPermissions?.USER?.can_edit_material_database,
        can_create_new_material: orderPermissions?.ADMIN?.can_create_new_material,
        can_sync_with_erp: orderPermissions?.USER?.can_sync_with_erp,
        can_send_and_update_orders: orderPermissions?.USER?.can_send_and_update_orders,
        can_edit_delivery_information: orderPermissions?.USER?.can_edit_delivery_information,
        can_send_and_update_rfqs: orderPermissions?.USER?.can_send_and_update_rfqs,
        can_create_drafts: orderPermissions?.USER?.can_create_drafts,
        can_input_vendor_data: orderPermissions?.USER?.can_input_vendor_data,
      },
      MATERIAL_REQUESTER: {
        can_see_orders_from_all_groups: orderPermissions?.MATERIAL_REQUESTER?.can_see_orders_from_all_groups,
        can_view_historical_pricing: orderPermissions?.MATERIAL_REQUESTER?.can_view_historical_pricing,
        can_edit_vendor_database: orderPermissions?.MATERIAL_REQUESTER?.can_edit_vendor_database,
        can_edit_material_database: orderPermissions?.MATERIAL_REQUESTER?.can_edit_material_database,
        can_create_new_material: orderPermissions?.ADMIN?.can_create_new_material,
        can_sync_with_erp: orderPermissions?.MATERIAL_REQUESTER?.can_sync_with_erp,
        can_send_and_update_orders: orderPermissions?.MATERIAL_REQUESTER?.can_send_and_update_orders,
        can_edit_delivery_information: orderPermissions?.MATERIAL_REQUESTER?.can_edit_delivery_information,
        can_send_and_update_rfqs: orderPermissions?.MATERIAL_REQUESTER?.can_send_and_update_rfqs,
        can_create_drafts: orderPermissions?.MATERIAL_REQUESTER?.can_create_drafts,
        can_input_vendor_data: orderPermissions?.MATERIAL_REQUESTER?.can_input_vendor_data,
      },
    },
    commitment_permissions: {
      ADMIN: {
        can_see_and_create_commitments: commitmentsPermissions?.ADMIN?.can_see_and_create_commitments,
        can_edit_and_delete_commitments: commitmentsPermissions?.ADMIN?.can_edit_and_delete_commitments,
      },
      PURCHASER: {
        can_see_and_create_commitments: commitmentsPermissions?.PURCHASER?.can_see_and_create_commitments,
        can_edit_and_delete_commitments: commitmentsPermissions?.PURCHASER?.can_edit_and_delete_commitments,
      },
      USER: {
        can_see_and_create_commitments: commitmentsPermissions?.USER?.can_see_and_create_commitments,
        can_edit_and_delete_commitments: commitmentsPermissions?.USER?.can_edit_and_delete_commitments,
      },
      MATERIAL_REQUESTER: {
        can_see_and_create_commitments: commitmentsPermissions?.MATERIAL_REQUESTER?.can_see_and_create_commitments,
        can_edit_and_delete_commitments: commitmentsPermissions?.MATERIAL_REQUESTER?.can_edit_and_delete_commitments,
      },
    },
  })

  const handleChangeOrderPermissions = (data: OrderPermissions) => {
    setNewPermissions((prev) => ({
      ...prev,
      order_permissions: data,
    }))
  }

  const handleChangeInvoicePermissions = (data: InvoicePermissions) => {
    setNewPermissions((prev) => ({
      ...prev,
      invoices_permissions: data,
    }))
  }

  const handleChangeCommitmentPermissions = (data: CommitmentPermissions) => {
    setNewPermissions((prev) => ({
      ...prev,
      commitment_permissions: data,
    }))
  }

  const handleSave = async () => {
    try {
      await companySettingStore.updatePermissions(newPermissions)
      // to index the new permissions right away
      await userStore.showUser()
      message.success('Saved Changes')
    } catch (error) {
      message.error('Error saving changes')
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <Page.Header>
        <FlexBoxX justifyContent="space-between" width="100%">
          <Box>
            <Typography.Title level={4} style={{ margin: 0 }}>
              Permissions
            </Typography.Title>
            <Typography.Paragraph style={{ margin: 0 }}>
              Adjust permissions per role to best suit your organization
            </Typography.Paragraph>
          </Box>
          <Button type="primary" onClick={() => handleSave()}>
            Save Changes
          </Button>
        </FlexBoxX>
      </Page.Header>

      <Page.Content>
        <OrdersTable setOrderPermissions={handleChangeOrderPermissions} ordersPermissions={orderPermissions} />
        {userStore.canUseInvoices && (
          <InvoicesTable
            setInvoicePermissions={handleChangeInvoicePermissions}
            invoicesPermissions={invoicePermissions}
          />
        )}

        {canUseCommitments && (
          <CommitmentsTable
            setCommitmentPermissions={handleChangeCommitmentPermissions}
            commitmentsPermissions={commitmentsPermissions}
          />
        )}
      </Page.Content>
    </>
  )
})

export default CompanyPermissions
