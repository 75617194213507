import React, { useMemo } from 'react'

import { flatten } from 'lodash'

import { Button } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { Card } from 'common/components/Card'
import {
  OrderMaterialSpreadsheet,
  getFilteredColumns,
  UnitsEditorCell,
  CostCodeEditorCell,
  CostCodeViewerCell,
  PhaseCodeEditorCell,
  PhaseCodeViewerCell,
} from 'common/components/OrderMaterialsSpreadsheet'
import { ButtonToggleSpreadsheet } from 'common/components/OrderMaterialsV2'

import { CustomDescriptionEditorCell, CustomDescriptionViewerCell } from 'contractor/components/OrderMaterials'
import { useStores } from 'contractor/hooks/use-stores'

import { usePublicOrderForm } from './context'

type MaterialProps = {
  isSpreadsheetMode: boolean
  toggleSpreadsheetMode: React.Dispatch<React.SetStateAction<boolean>>
}

const Material = observer<MaterialProps>(({ toggleSpreadsheetMode, isSpreadsheetMode }) => {
  const { publicOrderStore } = useStores()
  const {
    deliveries,
    addEmptyOrderMaterials,
    formParams,
    getOrderMaterialsByDeliveryId,
    updateOrderMaterialsByDeliveryId,
  } = publicOrderStore

  const { project } = usePublicOrderForm()

  const requiredQuoteFields = formParams?.required_quote_fields
  const costCodeSettings = formParams.cost_code_settings

  // Get all the columns filtered/ordered by the company attributes
  const columns = getFilteredColumns(publicOrderStore.companyAttributes).map((column) => {
    switch (column.id) {
      case 'unit':
        return {
          ...column,
          readOnly: !formParams?.order_permissions?.MATERIAL_REQUESTER?.can_create_new_material,
          DataEditor: (props) => <UnitsEditorCell {...props} units={formParams?.units} />,
        }
      case 'description':
        return {
          ...column,
          DataEditor: (props) => (
            <CustomDescriptionEditorCell
              {...props}
              canCreateNewMaterial={formParams?.order_permissions?.MATERIAL_REQUESTER?.can_create_new_material}
              projectId={project?.id}
            />
          ),
          DataViewer: CustomDescriptionViewerCell,
        }
      case 'cost_code_id':
        return {
          ...column,
          DataEditor: (props) => (
            <CostCodeEditorCell
              {...props}
              costCodes={formParams?.cost_codes}
              costCodeSettings={formParams?.cost_code_settings}
              projectId={project?.id}
            />
          ),
          DataViewer: (props) => (
            <CostCodeViewerCell
              {...props}
              costCode={formParams?.cost_codes.find((costCode) => costCode.id === props?.cell.value)}
              costCodeSettings={formParams?.cost_code_settings}
            />
          ),
        }
      case 'cost_code_phase_id':
        return {
          ...column,
          DataEditor: (props) => (
            <PhaseCodeEditorCell
              {...props}
              initialCostCodePhases={formParams?.cost_code_phases}
              projectId={project?.id}
            />
          ),
          DataViewer: PhaseCodeViewerCell,
        }
      default: {
        // Disable the company materials column to edit when user doesn't have permission
        if (publicOrderStore.companyAttributes.includes(column.id)) {
          return {
            ...column,
            readOnly: !formParams?.order_permissions?.MATERIAL_REQUESTER?.can_create_new_material,
          }
        }
        return column
      }
    }
  })

  const hiddenColumns = useMemo(() => {
    const arr = [
      'approve',
      'group',
      'sub_group',
      'vendor_response',
      'vendor_notes',
      'unit_cost',
      'extended_cost',
      'tax_value',
      'ext_cost_with_tax',
    ]

    if (!costCodeSettings?.independent_phase_codes_enabled) {
      arr.push('cost_code_phase_id')
    }
    return arr
  }, [costCodeSettings?.independent_phase_codes_enabled])

  return (
    <Card title="Materials" mt={16}>
      <FlexBoxY justifyContent="flex-start" width="100%" alignItems="flex-start">
        <FlexBoxX width="100%" justifyContent="flex-start">
          <OrderMaterialSpreadsheet
            canCreateNewMaterial={formParams?.order_permissions?.MATERIAL_REQUESTER?.can_create_new_material}
            costCodeSettings={formParams?.cost_code_settings}
            orderType="RFQ"
            columns={flatten(columns)}
            requiredFields={requiredQuoteFields}
            hiddenColumns={hiddenColumns}
            orderMaterials={getOrderMaterialsByDeliveryId(deliveries[0].id)}
            onChange={(newOrderMaterials) => {
              updateOrderMaterialsByDeliveryId(deliveries[0].id, newOrderMaterials)
            }}
          />
        </FlexBoxX>

        <FlexBoxX justifyContent="flex-start" width="100%" alignItems="flex-start">
          <Button data-cy="add-more-materials" onClick={() => addEmptyOrderMaterials(5, deliveries[0].id)} type="link">
            Add More Materials
          </Button>
        </FlexBoxX>

        <ButtonToggleSpreadsheet
          onClick={() => toggleSpreadsheetMode((prev) => !prev)}
          data-cy={isSpreadsheetMode ? `is-spread-sheet-0` : `spread-sheet-0`}
          isSpreadsheetMode={isSpreadsheetMode}
        />
      </FlexBoxY>
    </Card>
  )
})

export default Material
