import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { Select, Typography } from 'antd'

import { Box } from 'common/components/boxes'

import { useActiveView } from 'contractor/hooks/use-active-view'
import { useStores } from 'contractor/hooks/use-stores'

const { Text } = Typography

export function OrderView() {
  const {
    orderStore: { listStore },
  } = useStores()
  const history = useHistory()

  const [activeKey, setActiveKey] = useState(useActiveView())

  const tabItems = [
    { label: 'All Orders', value: 'all_orders' },
    { label: 'My Orders', value: 'my_orders' },
    { label: 'Last Month', value: 'last_month' },
  ]

  useEffect(() => {
    localStorage.setItem('@subbase/orders/active-view', activeKey)

    const searchParams = new URLSearchParams(history.location.search)
    searchParams.set('view', activeKey)
    history.push({ search: searchParams.toString() })
  }, [activeKey, history])

  const handleChangeActiveKey = (option: string) => {
    setActiveKey(option)
    listStore.setFilter('view', option, false, false)
  }

  return (
    <Box display="flex" flexDirection="column" p="12" gridGap={16}>
      <Box width="100%" display="flex" flexDirection="column">
        <Text type="secondary">Order View</Text>

        <Select
          value={activeKey}
          style={{ minWidth: 200 }}
          onSelect={(option) => handleChangeActiveKey(option)}
          options={tabItems}
        />
      </Box>
    </Box>
  )
}
