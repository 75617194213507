import moment from 'moment'

import { useNotifyOrder } from 'common/hooks/use-notify-order'

import { useStores } from 'contractor/hooks/use-stores'

import { UseOrderActionsType, UseOrderInternalActions } from './useOrderActions'
import { UseOrderStatesType } from './useOrderStates'

type UseRequestCancellationActionProps = Pick<UseOrderInternalActions, 'handleLogError' | 'handleSaveOrder'> &
  Pick<UseOrderActionsType, 'handleGoBack'>

export type UseRequestCancellationActionType = {
  handleRequestCancellation: (cancellationReason: string) => Promise<void>
  handleRollbackRequestCancellation: () => Promise<void>
}

export const useRequestCancellationAction = (
  states: UseOrderStatesType,
  actions: UseRequestCancellationActionProps,
): UseRequestCancellationActionType => {
  const { orderStore } = useStores()
  const { notifyOrder } = useNotifyOrder({ orderId: orderStore.selectedOrder?.id, operation: 'update' })

  const handleRequestCancellation = async (cancellationReason: string) => {
    states.setCountActionClicked(1)

    try {
      await actions.handleSaveOrder(
        {
          cancellation_requested_at: moment().toISOString(),
          cancellation_requested_reason: cancellationReason,
          cancellation_accepted_at: null,
          cancellation_rejected_at: null,
        },
        false,
      )

      actions.handleGoBack()

      notifyOrder({ message: 'Cancellation requested' })
    } catch (error) {
      actions.handleLogError(error, 'Unable to request cancelation of the order.')
    } finally {
      states.setCountActionClicked(-1)
    }
  }

  const handleRollbackRequestCancellation = async () => {
    states.setCountActionClicked(1)

    try {
      await actions.handleSaveOrder(
        {
          cancellation_requested_at: null,
          cancellation_requested_reason: null,
          cancellation_accepted_at: null,
          cancellation_rejected_at: null,
        },
        false,
      )

      actions.handleGoBack()

      notifyOrder({ message: 'Cancellation Withdrawn' })
    } catch (error) {
      actions.handleLogError(error, 'Unable to request cancelation of the order.')
    } finally {
      states.setCountActionClicked(-1)
    }
  }

  return { handleRequestCancellation, handleRollbackRequestCancellation }
}
