import React from 'react'

import styled from '@emotion/styled'

import { Tooltip } from 'antd'

export enum DraggableType {
  ORDER_MATERIAL,
  INVOICE_MATERIAL,
}

type ExpandButtonProps = {
  hasExpandedRowKeys: boolean
  onToggleExpandRowKeys: () => void
}

// This style is the same as the expandable button inside the table
const StyledButton = styled.button<{ hasExpandedRowKeys: boolean }>`
  display: inline-flex;
  float: none;
  vertical-align: sub;
  outline: none;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  transform: scale(0.94117647);
  transition: all 0.3s;
  user-select: none;

  &:hover {
    border-color: currentcolor;
    color: #2362ba;
  }

  &:before,
  &:after {
    position: absolute;
    background: currentcolor;
    transition: transform 0.3s ease-out;
    content: '';
  }

  &::after {
    top: 3px;
    bottom: 3px;
    left: 7px;
    width: 1px;
    ${({ hasExpandedRowKeys }) => (hasExpandedRowKeys ? 'transform: rotate(90deg);' : 'transform: rotate(0deg);')}
  }

  &::before {
    top: 7px;
    right: 3px;
    left: 3px;
    height: 1px;
    ${({ hasExpandedRowKeys }) => !hasExpandedRowKeys && 'transform: rotate(-180deg);'}
  }
`

export const ExpandButton = ({ hasExpandedRowKeys, onToggleExpandRowKeys }: ExpandButtonProps) => {
  return (
    <Tooltip title={hasExpandedRowKeys ? 'Collapse All' : 'Expand All'} placement="top">
      <StyledButton onClick={onToggleExpandRowKeys} hasExpandedRowKeys={hasExpandedRowKeys} />
    </Tooltip>
  )
}
