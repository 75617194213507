import React from 'react'

import { observer } from 'mobx-react-lite'

import { Page } from 'common/components/Page'
import { PersistentFiltersProvider } from 'common/contexts/persistent_filters'

import { useStores } from 'contractor/hooks/use-stores'

import { PriceSheetHeader } from './Header'
import { PriceSheetList } from './List'

export const PriceSheet = observer(() => {
  const {
    priceSheetStore: { listStore },
  } = useStores()

  return (
    <PersistentFiltersProvider ignoreQueryParams={['tab']} ignoreOnFilterCounter={['state']} listStore={listStore}>
      <Page.Header display="grid" gridGap={16}>
        <PriceSheetHeader />
      </Page.Header>

      <Page.Content>
        <PriceSheetList />
      </Page.Content>
    </PersistentFiltersProvider>
  )
})
