import React, { useMemo, useRef, useState } from 'react'

import debounce from 'lodash/debounce'

import { Select, Spin } from 'antd'
import type { SelectProps } from 'antd/es/select'

import { Box } from 'common/components/boxes'
import { noticeError } from 'common/helpers/new_relic'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface InfiniteScrollDebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string, page: number) => Promise<{ data: ValueType[]; hasMore: boolean }>
  debounceTimeout?: number
  initialOptions?: ValueType[]
  initialPage?: number
  threshold?: number
}

export const InfiniteScrollDebounceSelect = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
>({
  fetchOptions,
  debounceTimeout = 800,
  initialOptions = [],
  initialPage = 1,
  threshold = 100,
  dropdownRender: customDropdownRender,
  ...props
}: InfiniteScrollDebounceSelectProps<ValueType>) => {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<ValueType[]>(initialOptions)
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(initialPage)
  const [searchQuery, setSearchQuery] = useState('')

  const fetchRef = useRef(0)
  const selectRef = useRef(null)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      setSearchQuery(value)
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchOptions(value, 1).then(({ data, hasMore }) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(data)
        setHasMore(hasMore)
        setPage(2)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  const fetchOptionsWithErrorHandling = async (search, page) => {
    try {
      return await fetchOptions(search, page)
    } catch (error) {
      noticeError(error, { entry: 'infinite-scroll-debounce-select' })
      setFetching(false)
      return { data: [], hasMore: false }
    }
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = event.target as HTMLDivElement
    if (!fetching && hasMore && target.scrollTop + target.offsetHeight >= target.scrollHeight - threshold) {
      setFetching(true)
      fetchOptionsWithErrorHandling(searchQuery, page).then(({ data, hasMore }) => {
        setOptions((prev) => [...prev, ...data])
        setHasMore(hasMore)
        setPage((prev) => prev + 1)
        setFetching(false)
      })
    }
  }

  const handleDropdownVisibleChange = (open) => {
    if (!open) {
      setOptions(initialOptions) // Reset to initial options when closing dropdown
      setSearchQuery('')
      setPage(initialPage)
      setHasMore(true)

      // Await some time to allow the dropdown to close before scrolling to top
      setTimeout(() => selectRef.current?.scrollTo?.({ top: 0 }), 200)
    }
  }

  const composedDropdownRender = (menu: React.ReactNode) => {
    const spinner = fetching && (
      <Box textAlign="center" pt="4px">
        <Spin size="small" />
      </Box>
    )

    const defaultDropdownRender = (
      <>
        {menu}
        {spinner}
      </>
    )

    // Use the consumer-provided dropdownRender if available
    if (customDropdownRender) {
      return customDropdownRender(defaultDropdownRender)
    }

    return defaultDropdownRender
  }

  return (
    <Select
      ref={selectRef}
      filterOption={false}
      loading={fetching}
      showSearch
      onSearch={debounceFetcher}
      onPopupScroll={handleScroll}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      notFoundContent={fetching ? 'Loading...' : props?.notFoundContent || 'Not found'}
      dropdownRender={composedDropdownRender}
      {...props}
      aria-autocomplete="none"
      options={options}
      style={{ width: '100%', ...props.style }}
    />
  )
}
