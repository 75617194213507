import axios from 'axios'

import { Option } from 'common/components/SelectUnit'
import { Comment } from 'common/server/comment'
import { CompanyMaterial } from 'common/server/company_materials'
import { CostCodePhase } from 'common/server/cost_codes/cost_code_phases'
import { CostCode } from 'common/server/cost_codes/cost_codes'
import { CommitmentStatuses, File } from 'common/server/server_types'

import { CommonMaterial } from 'contractor/components/MaterialsAutocomplete'
import { IndexInvoiceRecord } from 'contractor/server/invoices/invoice'
import { ConsolidatedOrdersMaterials } from 'contractor/server/order_materials'
import { ConsolidatedOrders } from 'contractor/server/orders'

export type CommitmentMaterials = CommitmentMaterial | ConcreteCommitmentMaterial

export interface CommitmentMaterial {
  id: string
  unique_id?: string
  company_material: CompanyMaterial | CommonMaterial
  quantity: string | number
  unit: Option
  unit_price: string | number
  quantity_ordered?: string
  cost_code?: CostCode
  cost_code_id?: string
  cost_code_phase_id?: string
  cost_code_phase?: CostCodePhase
  locked?: boolean
  low_stock?: boolean
}

export interface ConcreteCommitmentMaterial {
  id: string
  unique_id?: string
  company_material_id: string
  company_material: CompanyMaterial
  phase: string
  schedule: string
  mix_design: string
  mix_code: string
  cost_code?: CostCode
  cost_code_id?: string
  cost_code_phase_id?: string
  unit_price: string | number
  cost_code_phase?: CostCodePhase
  estimated_cy: string | number
}

type Watcher = {
  id: string
  full_name: string
}

export type CommitmentTypes = 'MaterialCommitment' | 'ConcreteCommitment' | 'BudgetCommitment'

export interface Commitment {
  id: string
  commitment_id: string
  vendor_name: string
  commitment_name: string
  commitment_number: string
  project_id: string
  project_name: string
  project_number: string
  created_at: string
  updated_at: string
  status: CommitmentStatuses
  drafted_by: string
  discarded_at: string
  commitment_drafted_at: string
  commitment_submitted_at: string
  company_vendor_id: string
  company_vendor: {
    id: string
    vendor_name: string
    vendor?: {
      id: string
      name: string
    }
  }
  company_vendor_contact_id: string
  submitted_at: string
  submitted_by: string
  last_action: LastAction
  total_invoiced: string
  total_ordered: string
  total_uninvoiced: string
  expiration_date: string
  tags: string[]
  commitment_materials: CommitmentMaterials[]
  internal_comments: Comment[]
  watchers: Watcher[]
  watcher_ids: string[]
  order_count: number
  invoice_count: number
  attachments: File[]
  resume: File
  isExpired: boolean
  attachments_files_signed_ids: string[]
  attachments_files_delete_ids: string[]
  orders_to_import: string[]
  commitment_type: CommitmentTypes
  fuel_charge: number
  environmental_charge: number
  price_increase_value: number
  price_increase_percent: number
  price_increase_type: 'percent' | 'value'
  date_range_start: string
  date_range_end: string
  time_period: string
}

interface LastAction {
  author_type: 'User' | 'VendorUser' | 'CompanyVendorContact' | 'SubBase'
  author_name: string
  created_at: string
}

export function show(commitment_id: string) {
  return axios.get<Commitment>(`/commitments/${commitment_id}`)
}

export function destroy(commitment_id: string) {
  return axios.delete(`/commitments/${commitment_id}`)
}

export function loadOrders(id: string, ordersToImport: string[] = []) {
  return axios.post<ConsolidatedOrders.Order[]>(`/commitments/${id}/load_orders`, { order_ids: ordersToImport })
}

export function loadInvoices(id: string) {
  return axios.post<IndexInvoiceRecord[]>(`/commitments/${id}/load_invoices`)
}

export function loadCommitmentMaterials(params: { id: string; material_id: string }) {
  return axios.post<ConsolidatedOrdersMaterials.OrderMaterial[]>(
    `/commitments/${params.id}/load_commitment_materials`,
    params,
  )
}

export function loadOrderMaterials(params: { id: string; order_id: string }) {
  return axios.post<ConsolidatedOrdersMaterials.OrderMaterial[]>(
    `/commitments/${params.id}/load_order_materials`,
    params,
  )
}

export function newInternalComment(payload: { text: string; commitment_id: string; company_user_ids: string[] }) {
  return axios.post<Comment>(`/commitments/${payload.commitment_id}/internal_comments`, payload)
}

// Material Commitment

export function updateMaterialCommitment(payload: Commitment) {
  return axios.patch<Commitment>(`/material_commitments/${payload['id']}`, payload)
}

export function createMaterialCommitment(payload: Commitment) {
  return axios.post<Commitment>('/material_commitments/create', payload)
}
