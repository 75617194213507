import React from 'react'

import { CellBase, DataEditorProps } from 'react-spreadsheet'

import { toJS } from 'mobx'

import { PhaseCodeEditorCell } from 'common/components/OrderMaterialsSpreadsheet'
import { SelectPhaseCodeProps } from 'common/components/SelectPhaseCode'

import { useStores } from 'contractor/hooks/use-stores'

export type CustomPhaseCodeEditorCellProps = SelectPhaseCodeProps &
  DataEditorProps & {
    onChange: (cell: CellBase, shouldPropagateValue?: boolean) => void
  }

export const CustomPhaseCodeEditorCell = (props: CustomPhaseCodeEditorCellProps) => {
  const { costCodeStore, companySettingStore } = useStores()

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const { costCodePhaseListStore } = costCodeStore
  return (
    <PhaseCodeEditorCell
      {...props}
      projectSpecificPhaseCodesEnabled={costCodeSettings?.independent_phase_codes_enabled}
      initialCostCodePhases={toJS(costCodePhaseListStore.records)}
    />
  )
}
