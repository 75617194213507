import React from 'react'

import { Typography } from 'antd'

import { FlexBoxX } from '../../boxes'

type RequiredMarkProps = {
  title: string
  isRequired: boolean
  isMobile?: boolean
}

export const RequiredMark = ({ title, isRequired, isMobile = false }: RequiredMarkProps) => {
  // Return the default title for desktop
  if (!isRequired && !isMobile) {
    return <Typography.Text>{title}</Typography.Text>
  }

  // Return the default title for mobile
  if (!isRequired && isMobile) {
    return <Typography.Text style={{ textAlign: 'end' }}>{title}</Typography.Text>
  }

  // Return the required marked title for mobile
  if (isRequired && isMobile) {
    return (
      <FlexBoxX style={{ placeContent: 'end' }}>
        <Typography.Text type="danger" style={{ fontSize: 12, paddingRight: 8 }}>
          *
        </Typography.Text>
        <Typography.Text style={{ textAlign: 'end' }}>{title}</Typography.Text>
      </FlexBoxX>
    )
  }

  // Return the required marked title for desktop
  return (
    <FlexBoxX style={{ placeContent: 'start' }}>
      <Typography.Text type="danger" style={{ fontSize: 12, paddingRight: 8 }}>
        *
      </Typography.Text>
      <Typography.Text style={{ textAlign: 'start' }}>{title}</Typography.Text>
    </FlexBoxX>
  )
}
