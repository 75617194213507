import axios from 'axios'

import { Address } from 'common/server/addresses'

export interface AllAddresses {
  company: Address[]
  project: ({ project_id: string; project_name: string } & Address)[]
  company_vendor: ({ vendor_name: string } & Address)[]
}

export function index() {
  return axios.get<AllAddresses>('/address')
}

export function create(payload: {
  company_id?: string
  project_id?: string
  company_vendor_id?: string
  address: Address
}) {
  return axios.post<Address>('/address', payload)
}

export function update(payload: { id: string; address: Address }) {
  return axios.patch<Address>(`/address/${payload['id']}`, payload)
}
