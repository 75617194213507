import React, { useMemo } from 'react'

import { Avatar, Timeline, Typography, Button, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { CollapsibleContent, Loading, makeTitle } from 'common/components/History'
import { InvoiceHistoryResponse } from 'common/server/invoice_history'

import { filterAllowHistoryEvents, getAvatarStyle } from './helpers'
import { useHistoryCollapse } from './hooks'
import { Icon } from './icon'
import { Invoice, ALLOW_INVOICE_FIELDS_FULL } from './Invoice'
import { InvoiceMaterials } from './InvoiceMaterials'
import { Orders } from './orders'

type InvoiceHistoryProps = {
  isLoading?: boolean
  data: InvoiceHistoryResponse[]
  optionalColumns: string[]
}

export const InvoiceHistory = observer((props: InvoiceHistoryProps) => {
  const { isLoading, data, optionalColumns } = props

  const { hasExpanded, handleToggleItem, handleToggleAll, expanded } = useHistoryCollapse()

  const dataSource = useMemo(() => data.filter(filterAllowHistoryEvents), [data?.length])

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end">
      <Button type="primary" size="small" onClick={() => handleToggleAll(data)}>
        {hasExpanded ? 'Collapse All' : 'Expand All'}
      </Button>

      <Timeline style={{ paddingLeft: 11, paddingTop: 2, width: '100%', marginTop: 16 }}>
        {dataSource.map((history, index) => {
          const isCreate = history.event === 'create'

          return (
            <Timeline.Item
              key={history.created_at}
              dot={
                <Tooltip title={expanded[index] ? 'Collapse' : 'Expand'}>
                  <span onClick={() => handleToggleItem(index)} style={{ cursor: 'pointer' }}>
                    <Avatar style={getAvatarStyle(history?.author)} icon={<Icon author={history?.author} />} />
                  </span>
                </Tooltip>
              }
            >
              <Box pl="4px" onClick={() => handleToggleItem(index)} style={{ cursor: 'pointer' }}>
                <Typography.Text style={{ marginBottom: 12 }}>
                  <span dangerouslySetInnerHTML={{ __html: makeTitle(history?.title, history?.author?.name) }} />
                </Typography.Text>

                <CollapsibleContent isOpen={expanded[index]}>
                  <Invoice
                    invoice={history.invoice}
                    snapshot={history.snapshot}
                    isCreate={isCreate}
                    allowInvoiceFields={ALLOW_INVOICE_FIELDS_FULL}
                  />

                  {history?.orders?.length && <Orders orders={history?.orders} />}

                  {history?.invoice_materials && (
                    <InvoiceMaterials invoiceMaterials={history?.invoice_materials} optionalColumns={optionalColumns} />
                  )}
                </CollapsibleContent>
              </Box>
            </Timeline.Item>
          )
        })}
      </Timeline>
    </Box>
  )
})
