import React from 'react'

import { observer } from 'mobx-react-lite'

import { useStores } from 'contractor/hooks/use-stores'

import { InvoiceMaterials } from './invoice_materials'
import { OrderMaterials } from './order_materials'

type ReviewStepProps = {
  visible: boolean
}

export const ReviewStep = observer<ReviewStepProps>(({ visible }) => {
  const { invoiceStore } = useStores()

  const { invoice } = invoiceStore

  /*
    When an invoice is marked as reconciled without a PO attached,
    the review page will change to "invoice details" and show the invoice line items without a dropdown
  */
  if (!invoiceStore.selectedOrders.length && !!invoice?.marked_reconciled_at) {
    return <InvoiceMaterials visible={visible} />
  }

  return <OrderMaterials visible={visible} />
})
