import React from 'react'

import ShopOutlined from '@ant-design/icons/ShopOutlined'
import { Typography, Divider } from 'antd'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { useTheme } from 'common/hooks/use-theme'

const Description = (props) => (
  <Typography.Text type="secondary" style={{ textTransform: 'uppercase', fontSize: 10, marginTop: 4 }} {...props} />
)

const Title = (props) => <Typography.Text style={{ fontSize: 16, fontWeight: 500 }} {...props} />

const Logo = ({ logo }) => {
  const theme = useTheme()

  if (logo) {
    return <img src={logo} width="64px" style={{ objectFit: 'cover', marginLeft: 16 }} />
  }

  return <ShopOutlined style={{ color: theme.colors.primary, marginLeft: 16, fontSize: 40 }} />
}

export const GlobalizedVendorCard = ({
  vendorName,
  vendorDomain,
  vendorDescription = '',
  vendorLogo = '',
  contactName,
  contactEmail,
  contactPhone = '',
  contactHeadline = '',
}) => {
  return (
    <FlexBoxY borderRadius="4px" border="1px solid" borderColor="gray-2" flexGrow={0}>
      <FlexBoxY width="100%" alignItems="flex-start">
        <FlexBoxX justifyContent="space-between" width="100%" p="24px" bg="#FAFAFA">
          <FlexBoxY alignItems="flex-start">
            <Title>{vendorName}</Title>
            {vendorDomain && <Typography.Text type="secondary">{vendorDomain}</Typography.Text>}
            {vendorDescription && <Description>{vendorDescription}</Description>}
          </FlexBoxY>

          <Logo logo={vendorLogo} />
        </FlexBoxX>

        <Divider style={{ margin: 0 }} />

        <FlexBoxY width="100%" alignItems="flex-start" p="16px 24px" bg="#FFFFFF">
          <Title>{contactName}</Title>
          <Typography.Text type="secondary">{contactEmail}</Typography.Text>
          {contactPhone && <Typography.Text type="secondary">{contactPhone}</Typography.Text>}
          {contactHeadline && <Description>{contactHeadline}</Description>}
        </FlexBoxY>
      </FlexBoxY>
    </FlexBoxY>
  )
}
