import React from 'react'

import { uniq } from 'lodash'

import { CheckCircleOutlined, WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Popover, Table, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { InvoiceMaterial } from 'common/server/invoice'

type MappedStatusProps = {
  invoiceMaterial: InvoiceMaterial
}

const insertIf = (condition, ...elements) => (condition ? elements : [])

export const MappedStatus = ({ invoiceMaterial }: MappedStatusProps) => {
  const getAttributes = () => {
    if (!invoiceMaterial?.order_materials?.length) {
      return {
        color: 'error',
        title: 'Discrepancies',
        icon: <ExclamationCircleOutlined />,
        dataSource: [],
      }
    }
    const orderMaterialsQuantities = uniq(
      invoiceMaterial?.order_materials?.map(
        (orderMaterial) => (orderMaterial.quantity_delivered ?? orderMaterial?.quantity) || 0,
      ),
    )
    const orderMaterialsUnits = uniq(
      invoiceMaterial?.order_materials?.map(
        (orderMaterial) =>
          orderMaterial?.company_material?.unit?.name?.trim() ||
          orderMaterial?.company_material?.unit_name?.trim() ||
          '',
      ),
    )

    const invoiceUnit = invoiceMaterial?.uom?.trim() || ''
    const extractedQuantity = Number(invoiceMaterial?.quantity_shipped || 0)

    const quantityMatch = orderMaterialsQuantities?.length <= 1 && extractedQuantity === orderMaterialsQuantities[0]
    const unitMatch = orderMaterialsUnits?.length <= 1 && invoiceUnit === orderMaterialsUnits[0]

    const dataSource = [
      ...insertIf(!unitMatch, { field: 'UOM', invoice: invoiceMaterial?.uom, order: orderMaterialsUnits.join(', ') }),
      ...insertIf(!quantityMatch, {
        field: 'Qty',
        invoice: invoiceMaterial?.quantity_shipped,
        order: orderMaterialsQuantities.join(', '),
      }),
    ]

    if (unitMatch && quantityMatch) {
      return { color: 'success', title: 'Perfect match!', icon: <CheckCircleOutlined />, dataSource }
    }

    return {
      color: 'warning',
      title: 'Discrepancies',
      icon: <WarningOutlined />,
      dataSource,
    }
  }

  const attributes = getAttributes()

  return (
    <Box color={attributes.color} m="0 auto" display="flex" alignItems="center" justifyContent="center">
      <Popover
        title={attributes.title}
        placement="topRight"
        arrowPointAtCenter
        content={
          attributes.dataSource?.length ? (
            <Table
              pagination={false}
              size="small"
              columns={[
                { dataIndex: 'field' },
                { title: 'Invoice', dataIndex: 'invoice' },
                { title: 'Order', dataIndex: 'order' },
              ]}
              dataSource={attributes.dataSource}
              style={{ minWidth: 200 }}
              locale={{
                emptyText: 'No matching line item in order.',
              }}
            />
          ) : (
            <Typography.Text>No matching line items</Typography.Text>
          )
        }
      >
        {attributes.icon}
      </Popover>
    </Box>
  )
}
