import styled from '@emotion/styled'

import { Tag, Badge } from 'antd'

import { Box } from 'common/components/boxes'

export const StyledTag = styled(Tag.CheckableTag)`
  display: flex;
  padding: 5px 7px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors['gray-5']};
  transition: background 0.3s;

  &.ant-tag-checkable:not(.ant-tag-checkable-checked),
  &:hover:not(.ant-tag-checkable-checked) {
    background-color: ${({ theme }) => theme.colors['gray-2']};
  }

  &.ant-tag-checkable-checked {
    background-color: ${({ theme }) => theme.colors.highlightBlue};
  }
`

export const Scrollable = styled(Box)`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const BadgeStyled = styled(Badge)`
  & .ant-badge-count {
    background: ${({ theme }) => theme.colors['blue-1']};
    color: ${({ theme }) => theme.colors.highlightBlue};
    margin-left: 10px;
    border: none;
    box-shadow: none;
  }
`

export const LeftButton = styled(Box)`
  position: absolute;
  left: 0;
  top: -15%;
  display: flex;
  justify-content: center;
  height: 130%;
  z-index: 1000;

  &::after {
    background: linear-gradient(to right, #fafafa 0%, rgba(255, 255, 255, 0) 100%);
    height: 100%;
    width: 20px;
    content: '';
    pointer-events: none;
  }
`

export const RightButton = styled(Box)`
  position: absolute;
  right: 0;
  top: -15%;
  display: flex;
  justify-content: center;
  height: 130%;
  z-index: 1000;

  &::before {
    background: linear-gradient(to left, #fafafa 0%, rgba(255, 255, 255, 0) 100%);
    height: 100%;
    width: 20px;
    content: '';
    pointer-events: none;
  }
`
