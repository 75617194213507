import React, { useState } from 'react'

import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined'
import { Alert, Button, Form, Input, Typography } from 'antd'

import { FlexBoxY, FlexBoxX, Box } from 'common/components/boxes'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'
import { passwordConfirmationRules, passwordRules } from 'common/pages/Landing/password_rules'
import theme from 'common/styles/theme'

import { SignInTypes, LandingMethods } from '.'
import { Logo } from './logo'

interface RegisterMaterialRequesterPageProps {
  setSignInType: (type: SignInTypes) => void
  register?: LandingMethods['registerMaterialRequester']
}

const Success = ({ email = '' }) => (
  <FlexBoxY width="100%" flexGrow={0}>
    <FlexBoxX mb={24}>
      <CheckCircleOutlined style={{ marginRight: 12, fontSize: 25, color: theme.colors['green-4'] }} />
      <Typography.Title level={2} style={{ margin: 0 }}>
        Congratulations!
      </Typography.Title>
    </FlexBoxX>
    <Typography.Title level={4} type="secondary">
      We just sent a confirmation email to
    </Typography.Title>
    <Typography.Title level={4} style={{ marginTop: 0 }}>
      {email}
    </Typography.Title>
    <Typography.Title level={4} type="secondary" style={{ margin: 0 }}>
      Please confirm your registration to login!
    </Typography.Title>
  </FlexBoxY>
)

export const RegisterMaterialRequesterPage = ({ setSignInType, register }: RegisterMaterialRequesterPageProps) => {
  const [form] = Form.useForm()
  const [error, setError] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)

  const [isUserWasCreated, setUserCreated] = useState(false)
  const [userEmail, setUserEmail] = useState('')

  const params = new URLSearchParams(location.search)

  const onValuesChange = (changedValues) => {
    if (changedValues?.email) form.setFieldsValue({ email: changedValues.email.toLowerCase().trim() })
  }

  const onFinish = async (values): Promise<void> => {
    const { email } = values

    if (error) {
      setError('')
    }

    setSubmitting(true)
    const params = new URLSearchParams(location.search)

    try {
      await register({ ...values, public_order_form_url_extension: params.get('public_order_form_url_extension') })
      setUserCreated(true)
      setUserEmail(email)
    } catch (err) {
      setUserCreated(false)
      setError(err.response.data['error'] || `Unknown registration error, please contact ${SUPPORT_EMAIL_ADDRESS}`)
    } finally {
      setSubmitting(false)
    }
  }

  if (isUserWasCreated) {
    return <Success email={userEmail} />
  }

  return (
    <Box display="flex" flexDirection="column" width="100%" justifyContent="center" alignItems="flex-start">
      <Logo onClick={() => setSignInType(SignInTypes.SIGN_IN)} />

      <Form
        layout="vertical"
        form={form}
        style={{ width: '100%' }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          first_name: params.get('first_name'),
          last_name: params.get('last_name'),
          email: params.get('email'),
        }}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[{ required: true, message: 'Please your first name!' }]}
        >
          <Input placeholder="First name" />
        </Form.Item>

        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[{ required: true, message: 'Please input your last name!' }]}
        >
          <Input placeholder="Last name" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please input your Email!' },
            { type: 'email', message: 'The input is not valid E-mail!' },
          ]}
          hasFeedback
        >
          <Input placeholder="Email" type="email" />
        </Form.Item>

        <Form.Item label="Password" name="password" rules={passwordRules}>
          <Input type="password" placeholder="Password" />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm password"
          dependencies={['password']}
          hasFeedback
          rules={passwordConfirmationRules}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        {error && <Alert style={{ marginBottom: '20px' }} message={error} type="error" />}

        <Form.Item>
          <Box display="flex" flexDirection="column">
            <Button loading={isSubmitting} block type="primary" htmlType="submit">
              Register
            </Button>

            <Box display="flex" justifyContent="center" width="100%" mt={8}>
              <Typography.Text>
                Already have an account?{' '}
                <Typography.Link onClick={() => setSignInType(SignInTypes.SIGN_IN)}>Login</Typography.Link>
              </Typography.Text>
            </Box>
          </Box>
        </Form.Item>
      </Form>
    </Box>
  )
}
