import React, { useEffect } from 'react'

import styled from '@emotion/styled'

import { Typography } from 'antd'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { InputCurrency } from 'common/components/InputCurrency'
import { currencyFormatter } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'
import { CompanyMaterialVendorPrice } from 'common/server/company_materials'

import { AvgPrices } from './avg_prices'
import { ExtendedCost } from './extended_cost'
import { PreferredVendors } from './preferred_vendors'

const InputCurrencyStyled = styled(InputCurrency)`
  width: 100%;
  min-width: 80px;
  input {
    text-align: end;
    padding-right: 30px;
  }
`

export type UnitCostColumnProps = {
  companyMaterialDescription?: string
  companyMaterialId?: string
  companyVendorId?: string
  unitCost?: number
  extCost?: number
  disabled?: boolean
  splitting?: boolean
  isCommitment?: boolean
  hideDetails?: boolean
  defaultVendors?: CompanyMaterialVendorPrice[]
  showMaterialPriceDetails?: boolean
  showDefaultVendorAlert?: boolean
  roundingPrecision?: number
  onChange?: (
    value: string | number,
    params?: {
      index?: number
      defaultVendor?: CompanyMaterialVendorPrice
    },
  ) => void
  defaultVendorSelected?: CompanyMaterialVendorPrice
  onChangeDefaultVendorSelected?: (companyMaterialVendorPrice?: CompanyMaterialVendorPrice) => void
  edit?: boolean
}

export const UnitCostColumn = ({
  onChange = null,
  companyMaterialDescription = '',
  companyVendorId = '',
  companyMaterialId = '',
  unitCost = null,
  extCost = 0,
  disabled = false,
  splitting = false,
  hideDetails = false,
  defaultVendors = [],
  showMaterialPriceDetails = false,
  showDefaultVendorAlert,
  isCommitment,
  roundingPrecision = 3,
  defaultVendorSelected,
  onChangeDefaultVendorSelected,
  edit = true,
}: UnitCostColumnProps) => {
  const theme = useTheme()

  // Used only to set the vendor label according to the price in the existent order
  useEffect(() => {
    const foundDefaultVendor = defaultVendors?.find((vendor) => Number(vendor.price) === Number(unitCost))
    if (!defaultVendorSelected && foundDefaultVendor) {
      onChangeDefaultVendorSelected(foundDefaultVendor)
    }
  }, [unitCost, defaultVendors, defaultVendorSelected])

  const showAlert = showDefaultVendorAlert && !!defaultVendors?.length
  const showMaterialPriceDetailsPopover = !hideDetails && showMaterialPriceDetails

  if (!edit) {
    return (
      <FlexBoxY alignItems="flex-end" minWidth="124px">
        <Typography.Text style={{ width: '100%', textAlign: 'end' }}>
          {currencyFormatter(unitCost, roundingPrecision)}
        </Typography.Text>
        <ExtendedCost extCost={extCost} roundingPrecision={roundingPrecision} style={{ margin: 0 }} />
      </FlexBoxY>
    )
  }

  return (
    <FlexBoxY alignItems="flex-end" minWidth="124px">
      <InputCurrencyStyled
        value={unitCost}
        onChange={(value) => {
          onChange(value)
          onChangeDefaultVendorSelected(null)
        }}
        disabled={disabled || splitting || isCommitment}
        defaultValue={null}
        data-cy="unit-cost-input"
        style={showAlert ? { borderColor: theme.colors['gold-6'] } : {}}
      />

      {!disabled && !!defaultVendors?.length && (
        <FlexBoxY width="100%" mt="2px">
          <FlexBoxX width="100%">
            <ExtendedCost extCost={extCost} roundingPrecision={roundingPrecision} hideDetails={hideDetails} />

            {showMaterialPriceDetailsPopover && (
              <AvgPrices
                onChange={onChange}
                companyMaterialDescription={companyMaterialDescription}
                companyVendorId={companyVendorId}
                companyMaterialId={companyMaterialId}
                unitCost={unitCost}
              />
            )}
          </FlexBoxX>

          <PreferredVendors
            onChange={onChange}
            companyVendorId={companyVendorId}
            defaultVendors={defaultVendors}
            hideDetails={hideDetails}
            defaultVendorSelected={defaultVendorSelected}
            onChangeDefaultVendorSelected={onChangeDefaultVendorSelected}
          />
        </FlexBoxY>
      )}

      {!disabled && !defaultVendors?.length && (
        <FlexBoxX width="100%" mt="2px">
          <ExtendedCost extCost={extCost} roundingPrecision={roundingPrecision} hideDetails={hideDetails} />

          {showMaterialPriceDetailsPopover && (
            <AvgPrices
              onChange={onChange}
              companyMaterialDescription={companyMaterialDescription}
              companyVendorId={companyVendorId}
              companyMaterialId={companyMaterialId}
              unitCost={unitCost}
            />
          )}
        </FlexBoxX>
      )}
    </FlexBoxY>
  )
}
