import { ENVIRONMENT } from 'common/helpers/check_environment'

export interface AmplitudeUser {
  id: string
  email: string
  role: string
  type: 'CONTRACTOR' | 'VENDOR'
  account: {
    id: string
    name: string
    type?: string
  }
}

// Ignore initialization when is development
// The event tracking is raising errors while e2e is executing
let amplitudeInitialized = ENVIRONMENT === 'development'

export const initializeAmplitude = (user: AmplitudeUser, featureFlagEnabled = false) => {
  if (!amplitudeInitialized && window?.amplitude && featureFlagEnabled) {
    amplitudeInitialized = true

    const amplitude = window?.amplitude
    amplitude.add(window?.sessionReplay.plugin({ sampleRate: 1 }))
    amplitude.init('c69684cfa189e829c83cc2e6f349971c', {
      autocapture: { elementInteractions: true },
    })
    amplitude.setUserId(user.email)
    const identifyEvent = new amplitude.Identify()
    identifyEvent.set('role', user.role)
    identifyEvent.set('type', user.type)
    identifyEvent.set('company_id', user.account.id)
    identifyEvent.set('company_name', user.account.name)
    identifyEvent.set('company_type', user.account?.type)
    amplitude.identify(identifyEvent)
  }
}
