import React, { useState } from 'react'

import { observer } from 'mobx-react-lite'

import { Loading } from 'common/components/Loading'
import { RibbonFilter } from 'common/components/RibbonFilter'

import { ListParams } from 'contractor/server/integrations'

interface RibbonMappingsProps {
  counts: { [key: string]: number }
  setFilter: React.Dispatch<React.SetStateAction<ListParams>>
  isLoading: boolean
  overflowCount?: number
}

export const RibbonMappings = observer<RibbonMappingsProps>(({ counts, overflowCount, setFilter, isLoading }) => {
  const [ribbonFilter, setRibbonFilter] = useState(['all'])

  const handleFilter = (filter) => {
    setRibbonFilter(filter)
    if (filter[0] === 'all') {
      setFilter((prev) => ({ ...prev, mapped: undefined, unmapped: undefined }))
    } else if (filter[0] === 'mapped') {
      setFilter((prev) => ({ ...prev, mapped: true, unmapped: undefined }))
    } else if (filter[0] === 'unmapped') {
      setFilter((prev) => ({ ...prev, mapped: undefined, unmapped: true }))
    }
  }
  if (isLoading) {
    return <Loading size="small" />
  }

  return (
    <RibbonFilter
      boxProps={{
        mt: { _: 8, lg: 0 },
      }}
      value={ribbonFilter}
      counts={counts}
      onChange={(value) => handleFilter(value)}
      overflowCount={overflowCount}
      options={[
        { label: 'All', filter: 'all' },
        { label: 'Mapped', filter: 'mapped' },
        { label: 'Unmapped', filter: 'unmapped' },
      ]}
    />
  )
})
