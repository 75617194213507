import React from 'react'

import classNames from 'classnames'
import { flatten, minBy } from 'lodash'

import styled from '@emotion/styled'

import { currencyFormatter } from 'common/helpers/formatters'
import { OrderStates } from 'common/server/server_types'

import { AddMaterials } from './add_materials'
import { Attachments } from './attachments'
import { useLeveling } from './context'

export const Td = (props) => <td className="ant-table-cell" {...props} />

const TFoot = styled.tfoot`
  *:not(.divider) {
    border: none !important;
  }
`

const FooterRow = ({ label = '', acessor = '', items = [] }) => {
  const { isSplitting } = useLeveling()

  const isEmptyRow = items.filter((item) => {
    return item[acessor] == null
  })

  if (isEmptyRow.length === items.length) {
    return null
  }

  return (
    <tr>
      <Td colSpan={3} style={{ textAlign: 'right', padding: 4 }}>
        {label}
      </Td>
      {items.map((item, index) => {
        const value = Number(item[acessor] || 0)
        const className = classNames({
          'order-cancelled': item?.state === OrderStates.CANCELLED,
          'zero-quantity': item?.grandTotal === 0,
        })

        return (
          <React.Fragment key={`${index}-${acessor}`}>
            <Td
              colSpan={isSplitting ? 4 : 3}
              style={{ textAlign: 'right', paddingRight: 47, paddingTop: 4, paddingBottom: 4 }}
              className={className}
            >
              {currencyFormatter(value, 3)}
            </Td>
          </React.Fragment>
        )
      })}
    </tr>
  )
}

export const TableFooter = () => {
  const { isSplitting, orders, tableData } = useLeveling()

  const firstTableRow = tableData[0]?.vendor_items || []
  const vendorItems = flatten(tableData.map((tableRow) => tableRow.vendor_items))

  const findCellWithData = (rowIndex, columnIndex) => {
    if (rowIndex >= tableData.length) {
      return tableData[rowIndex]?.vendor_items[columnIndex]
    }

    if (!tableData[rowIndex]?.vendor_items[columnIndex]?.orderId) {
      return findCellWithData(rowIndex + 1, columnIndex)
    }

    return tableData[rowIndex]?.vendor_items[columnIndex]
  }

  const totals = firstTableRow.map((data, columnIndex) => {
    if (!data?.orderId) {
      data = findCellWithData(1, columnIndex)
    }

    // Edge case: Happens when the user removes materials from some RFQ
    if (!data?.orderId) {
      return {
        ...data,
        grandTotal: 0,
        isAllPrincesFilled: false,
      }
    }

    const orderItems = vendorItems.filter((vendorItem) => vendorItem.orderId === data.orderId)
    const subtotal = orderItems.reduce((acc, orderItem) => {
      // Don't sum up when the material is deleted
      if (orderItem?.discarded_at) {
        return acc
      }
      return acc + orderItem.extended_cost
    }, 0)
    const unitCosts = orderItems.filter((vendorItem) => vendorItem.unit_cost > 0)

    const isAllPrincesFilled = unitCosts.length === orderItems.length

    if (data.state === OrderStates.CANCELLED || !subtotal) {
      return {
        ...data,
        grandTotal: 0,
        isAllPrincesFilled,
      }
    }

    const extraValue = data?.taxValue + data?.shippingValue + data?.otherValue
    const grandTotal = subtotal + extraValue - data?.discountValue

    return {
      ...data,
      grandTotal,
      isAllPrincesFilled,
    }
  })

  const min = minBy(totals, (total) => {
    if (total.grandTotal > 0 && total.isAllPrincesFilled) {
      return total.grandTotal
    }
  })

  return (
    <TFoot>
      <tr className="hide-on-print">
        <Td colSpan={999}>
          <AddMaterials />
        </Td>
      </tr>

      <FooterRow label="Discount" acessor="discountValue" items={totals} />
      <FooterRow label="Shipping" acessor="shippingValue" items={totals} />
      <FooterRow label="Other" acessor="otherValue" items={totals} />
      <FooterRow label="Tax" acessor="taxValue" items={totals} />

      <tr style={{ fontWeight: 'bold' }}>
        <Td colSpan={3} style={{ textAlign: 'right' }}>
          Grand Total
        </Td>
        {totals.map((total, index) => {
          const className = classNames({
            'lowest-price-total': min?.orderId === total.orderId && total?.state === OrderStates.QUOTED,
            'order-cancelled': total?.state === OrderStates.CANCELLED,
          })

          return (
            <React.Fragment key={`total-${index}`}>
              <Td colSpan={isSplitting ? 4 : 3} style={{ textAlign: 'right', paddingRight: 47 }} className={className}>
                {currencyFormatter(total.grandTotal, 3)}
              </Td>
            </React.Fragment>
          )
        })}
      </tr>

      <Attachments orders={orders} />
    </TFoot>
  )
}
