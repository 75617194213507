import React from 'react'

import { Card, Col, Row, Typography } from 'antd'

import { Box, FlexBoxY } from 'common/components/boxes'
import { currencyFormatter, formatDateOrdinal } from 'common/helpers/formatters'
import theme from 'common/styles/theme'

import { ShowOrderResponse } from 'contractor/server/orders'

import { InvoiceStatusTag } from './invoice_status_tag'

type InvoiceTableItemsMobileProps = {
  invoices: ShowOrderResponse['invoices']
}

export const InvoiceTableItemsMobile = (props: InvoiceTableItemsMobileProps) => {
  const { invoices = [] } = props

  return (
    <>
      {invoices.map((invoice) => (
        <Card key={invoice.id}>
          <Box display="flex" flexDirection="column" style={{ gap: 12 }}>
            <Row key={invoice.id}>
              <Col span={12}>
                <FlexBoxY alignItems="flex-start">
                  <Typography.Text style={{ fontSize: 12 }}>Invoice number</Typography.Text>
                  <Typography.Link href={`/invoice/${invoice.id}`}>#{invoice.number}</Typography.Link>
                </FlexBoxY>
              </Col>
              <Col span={12}>
                <InvoiceStatusTag state={invoice.state} />
              </Col>
            </Row>

            <Row key={invoice.id}>
              <Col span={12}>
                <FlexBoxY alignItems="flex-start">
                  <Typography.Text style={{ fontSize: 12 }}>Grand total</Typography.Text>
                  <Typography.Text color={theme.colors['gray-12']}>
                    {currencyFormatter(invoice.invoice_grand_total, 2)}{' '}
                  </Typography.Text>
                </FlexBoxY>
              </Col>
              <Col span={12}>
                <FlexBoxY alignItems="flex-start">
                  <Typography.Text style={{ fontSize: 12 }}>Assigned to</Typography.Text>
                  <Typography.Text color={theme.colors['gray-12']}>
                    {!invoice?.assigned_to ? 'Not assigned' : invoice.assigned_to?.name || invoice.assigned_to?.email}
                  </Typography.Text>
                </FlexBoxY>
              </Col>
            </Row>

            <Row key={invoice.id}>
              <Col span={12}>
                <FlexBoxY alignItems="flex-start">
                  <Typography.Text style={{ fontSize: 12 }}>Created at</Typography.Text>
                  <Typography.Text color={theme.colors['gray-12']}>
                    {formatDateOrdinal(invoice.created_at)}
                  </Typography.Text>
                </FlexBoxY>
              </Col>
              <Col span={12}>
                <FlexBoxY alignItems="flex-start">
                  <Typography.Text style={{ fontSize: 12 }}>Invoice date</Typography.Text>
                  <Typography.Text color={theme.colors['gray-12']}>
                    {formatDateOrdinal(invoice.document_date)}
                  </Typography.Text>
                </FlexBoxY>
              </Col>
            </Row>
          </Box>
        </Card>
      ))}
    </>
  )
}
