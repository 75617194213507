import React, { useEffect, useState } from 'react'

import styled from '@emotion/styled'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Badge, Popover, Switch, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { InvoiceOtherSettings } from 'contractor/server/invoices/invoice_settings'

const TableStyled = styled(Table)`
  width: 100%;

  tr.disabled-table-row:hover > td,
  .locked-table-row {
    background-color: ${({ theme }) => theme.colors['gray-3']};
    cursor: not-allowed;
  }
`

type InvoiceOtherSettingsTableProps = {
  onChange: (value: InvoiceOtherSettings) => void
  otherSettings: InvoiceOtherSettings
}

export const OtherSettingsTable = ({ onChange, otherSettings }: InvoiceOtherSettingsTableProps) => {
  const [data, setData] = useState(null)

  useEffect(() => {
    const newData = [
      {
        id: 'accounting_date_enabled',
        setting: 'Accounting Date',
        tooltip:
          "Subbase's Invoice Date will be populated with the date on the actual Invoice or date\n" +
          'the invoice was sent to us. Accounting Date will default to the Invoice Date but can be overridden\n' +
          'with',
        enabled: otherSettings?.accounting_date_enabled,
      },
    ]

    setData(newData)
  }, [otherSettings])

  const handleSwitchChange = (id: string) => {
    const newData = [...data]
    newData.map((item) => {
      if (item.id === id) {
        item.enabled = !item.enabled
      }
      return item
    })

    const updatedInvoiceOtherSettings = newData.reduce((accumulator, item) => {
      accumulator[item.id] = item.enabled
      return accumulator
    }, {})

    setData(newData)
    onChange(updatedInvoiceOtherSettings)
  }

  const columns: ColumnsType<{
    id: string
    setting: string
    tooltip: string
    enabled: boolean
  }> = [
    {
      title: 'Feature',
      dataIndex: 'setting',
      width: 200,
      render: (setting, record) => (
        <>
          <Typography.Text>{setting}</Typography.Text>
          {record?.tooltip && (
            <Badge
              count={
                <Popover placement="bottomLeft" content={record?.tooltip}>
                  <QuestionCircleOutlined style={{ top: '-5px', right: '-5px', fontSize: 12, maxWidth: 100 }} />
                </Popover>
              }
            />
          )}
        </>
      ),
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      key: 'other_settings_enabled',
      width: 150,
      render: (enabled, record) => <Switch checked={enabled} onChange={() => handleSwitchChange(record.id)} />,
    },
  ]

  if (!otherSettings) {
    return null
  }

  return <TableStyled rowKey="id" dataSource={data} columns={columns} pagination={false} />
}
