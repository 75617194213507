import React from 'react'

import { Select, Typography, SelectProps } from 'antd'

import { Box, BoxProps } from 'common/components/boxes'

export type TermsAndConditionsProps = {
  type: 'PO' | 'RFQ'
  boxProps?: BoxProps
} & SelectProps

export const mapOptions = (termsAndCondition) => ({ value: termsAndCondition.id, label: termsAndCondition.name })

export const TermsAndConditions = (props: TermsAndConditionsProps) => {
  const { type = 'PO', boxProps, ...rest } = props

  return (
    <Box display="flex" flexDirection="column" {...boxProps}>
      <Typography.Text type="secondary">Terms and conditions (optional)</Typography.Text>
      <Select placeholder="Select template" allowClear {...rest} />
      <Typography.Text
        type="secondary"
        italic
        style={{ fontSize: 12 }}
      >{`It'll automatically be added to the PDF of ${type}`}</Typography.Text>
    </Box>
  )
}
