/** @jsx jsx */

import * as React from 'react'

import { jsx } from '@emotion/core'

import { Checkbox } from 'antd'

const CheckBoxCell: React.FC<{ value: boolean }> = ({ value }) => (
  <div style={{ textAlign: 'center', width: '100%' }}>
    <Checkbox checked={value} />
  </div>
)

export default CheckBoxCell
