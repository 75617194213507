import React, { useRef } from 'react'

import { connectStateResults } from 'react-instantsearch-dom'

import { Empty, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { InvoiceUpload } from 'contractor/pages/Invoices/Upload/invoice_upload'

type CustomStateResultsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchState: Record<string, any>
  searchResults: Record<string, unknown>
  allSearchResults: Record<string, unknown>
  error: Record<string, unknown>
  searching: boolean
  searchingForFacetValues: boolean
  isSearchStalled: boolean
  children: React.ReactNode
}

const commonBoxProps = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}

const CustomStateResults = observer<CustomStateResultsProps>(({ children, searchResults }) => {
  const { companySettingStore } = useStores()

  const { data: invoicingMail } = useQuery(companySettingStore.invoicingMail)

  const drawerRef = useRef<DrawerRef>()

  if (searchResults?.nbHits === 0) {
    return (
      <Box {...commonBoxProps}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <span>
              Share your custom email address with your vendors to start receiving invoices:
              <br />
              <Typography.Link copyable strong>
                {invoicingMail}
              </Typography.Link>
              <br />
              <br />
              You can also manually upload invoices
            </span>
          }
        >
          <Box display="flex" justifyContent="center">
            <InvoiceUpload ref={drawerRef} onClose={() => drawerRef.current?.close()} />
          </Box>
        </Empty>
      </Box>
    )
  }

  return (
    <Box position="relative" height="inherit" width="inherit">
      {children}
    </Box>
  )
})

export const StateResults = connectStateResults(CustomStateResults)
