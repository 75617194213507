import { CellDeliveryInfo } from 'common/components/OrdersList/Cells/cell_delivery_info'
import { CellExpirationQuote } from 'common/components/OrdersList/Cells/cell_expiration_quote'
import { CellFormattedText } from 'common/components/OrdersList/Cells/cell_formatted_text'
import { CellLastAction } from 'common/components/OrdersList/Cells/cell_last_action'
import { CellNoContent } from 'common/components/OrdersList/Cells/cell_no_content'
import { CellOrderedBy } from 'common/components/OrdersList/Cells/cell_ordered_by'
import { CellStatus } from 'common/components/OrdersList/Cells/cell_status'
import { CellTags } from 'common/components/OrdersList/Cells/cell_tags'
import { MobileCard } from 'common/components/OrdersList/mobile_card'

export const OrdersList = {
  Cell: {
    Status: CellStatus,
    FormattedText: CellFormattedText,
    ExpirationQuote: CellExpirationQuote,
    LastAction: CellLastAction,
    OrderedBy: CellOrderedBy,
    NoContent: CellNoContent,
    Tags: CellTags,
    DeliveryInfo: CellDeliveryInfo,
  },
  MobileCard: MobileCard,
}
