import { useState, useEffect } from 'react'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../../context'

const getInitialVisibleColumns = ({
  taxLineItemsEnabled,
  canUseCostCode,
  companyAttributes,
  independentPhaseCodesEnabled,
  canUseRetainage,
}) => {
  const columns = [
    { id: 1, Header: 'Qty', dataIndex: 'quantity', isVisible: true },
    { id: 2, Header: 'UOM', dataIndex: 'unit', isVisible: true },
    { id: 3, Header: 'Unit Cost', dataIndex: 'unit_cost', isVisible: true },
    ...(taxLineItemsEnabled
      ? [
          { id: 4, Header: 'Tax', dataIndex: 'tax_split', isVisible: true },
          { id: 5, Header: 'Ext. Cost + Tax', dataIndex: 'ext_cost_with_tax', isVisible: true },
        ]
      : []),
    { id: 6, Header: 'Ordered', dataIndex: 'ordered', isVisible: true },
    { id: 7, Header: 'Invoiced', dataIndex: 'invoiced', isVisible: true },
    { id: 8, Header: 'Remaining', dataIndex: 'remaining', isVisible: true },
  ]

  if (canUseCostCode && companyAttributes.includes('cost_code_id')) {
    columns.push({ id: 9, Header: 'Cost Code', dataIndex: 'cost_code', isVisible: true })

    if (independentPhaseCodesEnabled) {
      columns.push({ id: 10, Header: 'Phase Code', dataIndex: 'cost_code_phase', isVisible: true })
    }
  }

  if (canUseRetainage) {
    columns.push({ id: 11, Header: 'Retainage amount', dataIndex: 'retainage', isVisible: true })
  }

  return columns
}

const reviewStepTableColumnLocalStorageKey = '@subbase/review-step/table-columns'

const setLocalStorage = (value) => localStorage.setItem(reviewStepTableColumnLocalStorageKey, JSON.stringify(value))

export const useVisibleColumns = () => {
  const canUseRetainage = useFlag('retainage_v1')

  const { userStore, companySettingStore } = useStores()
  const { taxLineItemsEnabled } = useInvoice()

  const [visibleColumns, setVisibleColumns] = useState(() =>
    getInitialVisibleColumns({
      taxLineItemsEnabled,
      canUseCostCode: userStore.canUseCostCode,
      companyAttributes: companySettingStore.companyMaterialConfiguration?.company_attributes,
      independentPhaseCodesEnabled:
        companySettingStore.otherSettings?.cost_code_settings?.independent_phase_codes_enabled,
      canUseRetainage,
    }),
  )

  useEffect(() => {
    const savedColumns = JSON.parse(window.localStorage.getItem(reviewStepTableColumnLocalStorageKey))

    const defaultColumns = getInitialVisibleColumns({
      taxLineItemsEnabled,
      canUseCostCode: userStore.canUseCostCode,
      companyAttributes: companySettingStore.companyMaterialConfiguration?.company_attributes,
      independentPhaseCodesEnabled:
        companySettingStore.otherSettings?.cost_code_settings?.independent_phase_codes_enabled,
      canUseRetainage,
    })

    if (savedColumns) {
      const mergedColumns = defaultColumns.map((defaultCol) => {
        const savedCol = savedColumns.find((savedCol) => savedCol.id === defaultCol.id)
        return savedCol ? { ...defaultCol, isVisible: savedCol.isVisible } : defaultCol
      })
      setLocalStorage(mergedColumns)
      setVisibleColumns(mergedColumns)
    } else {
      setLocalStorage(defaultColumns)
      setVisibleColumns(defaultColumns)
    }
  }, [
    taxLineItemsEnabled,
    userStore.canUseCostCode,
    companySettingStore.companyMaterialConfiguration?.company_attributes,
    companySettingStore.otherSettings?.cost_code_settings?.independent_phase_codes_enabled,
  ])

  const filterVisibleColumn = (column) => {
    return visibleColumns.some(
      (visibleColumn) => visibleColumn.dataIndex === column.dataIndex && visibleColumn.isVisible,
    )
  }

  const handleChangeVisibleColumns = (changedVisibleColumns) => {
    setLocalStorage(changedVisibleColumns)
    setVisibleColumns(changedVisibleColumns)
  }

  return { visibleColumns, filterVisibleColumn, handleChangeVisibleColumns }
}
