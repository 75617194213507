import React from 'react'

import { Typography } from 'antd'

import { formatDateStringShort } from 'common/helpers/formatters'

export const DeliveryInfo = ({ delivery }) => {
  if (!delivery.address?.address_line_1) return <></>

  return (
    <table width="100%" style={{ marginBottom: 20 }}>
      <thead>
        <tr style={{ marginBottom: '0', fontSize: 10, fontWeight: 'bold' }}>
          <td>
            <Typography.Paragraph>Address</Typography.Paragraph>
          </td>
          <td>
            <Typography.Paragraph>Requested Date</Typography.Paragraph>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Typography.Text>{delivery.address.address_line_1}</Typography.Text>
          </td>
          <td>{formatDateStringShort(delivery.requested_delivered_at, 'N/A')}</td>
        </tr>
      </tbody>
    </table>
  )
}
