import React, { useCallback } from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { Form, Input, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'

import { Box } from 'common/components/boxes'
import DateTimePicker from 'common/components/date_time_picker'
import { InputCurrency } from 'common/components/InputCurrency'
import { useTheme } from 'common/hooks/use-theme'
import { OrderHit } from 'common/server/orders'

import { OrdersAutocomplete } from 'contractor/components/OrdersAutocomplete'
import { SelectCompanyVendor } from 'contractor/components/SelectCompanyVendor'
import { SelectUser, makeCompanyUserOption } from 'contractor/components/SelectUser'

export type OrderSelected = {
  id: string
  name: string
}

export type CommonInvoiceFormProps = {
  form: FormInstance
  selectedOrders: OrderHit[]
  setSelectedOrders: React.Dispatch<React.SetStateAction<OrderHit[]>>
}

export const CommonInvoiceForm = ({ form, selectedOrders, setSelectedOrders }: CommonInvoiceFormProps) => {
  const theme = useTheme()

  const selectedOrdersViewer = useCallback((): OrderSelected[] => {
    return selectedOrders.map((selected) => {
      return { id: selected.id, name: `${selected.order_number}: ${selected.name}` }
    })
  }, [selectedOrders])

  return (
    <>
      <Form.Item name="name" label="Invoice Name" style={{ marginBottom: 12 }}>
        <Input style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        name="number"
        label="Invoice Number"
        rules={[{ required: true, message: 'Invoice number is required!' }]}
        style={{ marginBottom: 12 }}
      >
        <Input style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item name="document_date" label="Invoice Date" style={{ marginBottom: 12 }}>
        <DateTimePicker
          data-cy="document-date"
          wrapperProps={{
            width: '100%',
          }}
        />
      </Form.Item>
      <Form.Item name="due_date" label="Due Date" style={{ marginBottom: 12 }}>
        <DateTimePicker
          data-cy="due-date"
          wrapperProps={{
            width: '100%',
          }}
        />
      </Form.Item>
      <Form.Item name="total_amount" label="Total Amount" style={{ marginBottom: 12 }}>
        <InputCurrency style={{ width: '100%' }} />
      </Form.Item>

      {/* We had some problems with the zIndex and X/Y with this algolia component and the drawer */}
      <Form.Item name="order_ids" label="Orders" style={{ marginBottom: 12 }}>
        <OrdersAutocomplete
          portal={false}
          size="small"
          offset={[0, 38]}
          onSelect={(selected) => {
            setSelectedOrders((prevState) => {
              if (prevState?.some((o) => o.id === selected.id)) {
                form.setFieldsValue({ order_ids: prevState.map((o) => o.id) })
                return prevState
              }
              const orders = [...prevState, selected]
              form.setFieldsValue({ order_ids: orders.map((o) => o.id) })
              return orders
            })
          }}
        />
      </Form.Item>

      <Box
        minHeight={54}
        style={{
          border: '1px solid #d9d9d9',
          borderRadius: 3,
          marginBottom: 15,
          paddingBottom: 8,
        }}
      >
        {selectedOrdersViewer().map((order) => (
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            width="100%"
            key={`${order.id}-selected`}
            style={{ paddingLeft: 8, paddingRight: 8, maxHeight: 22, paddingTop: 4 }}
          >
            <Box display="inline-flex" alignItems="flex-start">
              <Typography.Paragraph strong style={{ width: '100%' }}>
                {order.name}
              </Typography.Paragraph>
            </Box>
            <Box display="inline-flex" alignItems="flex-end">
              <DeleteOutlined
                style={{ paddingTop: 5, color: theme.colors['gray-7'] }}
                onClick={() => {
                  setSelectedOrders((prevState) => {
                    const orders = [...prevState.filter((o) => o.id !== order.id)]
                    form.setFieldsValue({ order_ids: orders.map((o) => o.id) })
                    return orders
                  })
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>

      <Form.Item name="company_vendor" label="Vendor" style={{ marginBottom: 0 }}>
        <SelectCompanyVendor placeholder="Select vendor" isCreate />
      </Form.Item>
      <Form.Item name="assigned_to_company_user_id" label="Assignee" style={{ marginBottom: 12 }}>
        <SelectUser
          allowClear
          showSearch
          placeholder="Select user"
          makeOption={makeCompanyUserOption}
          boxProps={{ width: '100%' }}
        />
      </Form.Item>
    </>
  )
}
