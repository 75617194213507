import React from 'react'

import styled from '@emotion/styled'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Typography, Popover } from 'antd'

import { Box } from 'common/components/boxes'
import { InputCurrency, InputCurrencyProps } from 'common/components/InputCurrency'
import { currencyFormatter } from 'common/helpers/formatters'
import { roundValue } from 'common/helpers/order'

const InputCurrencyStyled = styled(InputCurrency)`
  width: 100%;
  min-width: 80px;
  input {
    text-align: end;
    padding-right: 30px;
  }
`

export type TaxColumnProps = { extCost?: number } & InputCurrencyProps

export const TaxTitle = ({ isRequired = false }) => (
  <Popover
    content={
      <Box display="flex" flexDirection="column" style={{ gap: 8 }} maxWidth={320} fontSize={12}>
        <Typography.Text>The tax column and the tax field below will always display the same value.</Typography.Text>
        <Typography.Text>
          If you fill out the tax directly in the column, the field below will show the sum of all line item taxes.
        </Typography.Text>
        <Typography.Text>
          If you add the tax using the <Typography.Text strong>“Add Cost”</Typography.Text> field, the tax will be
          distributed to the columns using the following formula:
        </Typography.Text>
        <Typography.Text code>Total Tax * (Material Ext. Cost / Total Material Cost)</Typography.Text>
      </Box>
    }
    arrowPointAtCenter={false}
    placement="topRight"
  >
    {isRequired && (
      <Typography.Text type="danger" style={{ fontSize: 12, paddingRight: 8 }}>
        *
      </Typography.Text>
    )}
    Tax
    <QuestionCircleOutlined style={{ marginLeft: 8 }} />
  </Popover>
)

export const TaxColumn = ({ extCost: extCostProp, ...props }: TaxColumnProps) => {
  const taxValue = Number(props?.value) || 0
  const extCost = Number(extCostProp) || 0
  const extCostWithTax = roundValue(extCost + taxValue)

  return (
    <Box width="100%">
      <InputCurrencyStyled {...props} />
      <Box display="flex" alignItems="center" width="100%" mt={2}>
        <Typography.Text type="secondary" style={{ width: '100%', textAlign: 'end', marginRight: 16 }}>
          {currencyFormatter(extCostWithTax)}
        </Typography.Text>
      </Box>
    </Box>
  )
}
