import React from 'react'

import { Tag } from 'antd'

import { Box } from 'common/components/boxes'

type CellTags = {
  tags: string[]
}

export const CellTags = (props: CellTags) => {
  const { tags } = props

  return (
    <Box style={{ gap: 4 }} display="flex" flexWrap="wrap">
      {tags?.map((tag) => (
        <Tag key={`company-material-tag-${tag}`} style={{ margin: 0 }}>
          {tag}
        </Tag>
      ))}
    </Box>
  )
}
