import constants from '../constants'

import { OrderSubStates } from 'common/server/server_types'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

export type UseOrderPermissionsType = {
  canEditPackageName: boolean
  canInputVendorData: boolean
  canShowFoundationDownload: boolean
  canShowIntegration: boolean
  canShowOrderConfirmOverride: boolean
  canShowTCMV2Download: boolean
  canSyncWithErp: boolean
  cannotSendAndUpdateOrders: boolean
  foundationCsvDownloadEnabled: boolean
  rfqExtractionEnabled: boolean
}

export const useOrderPermissions = () => {
  const { integrationStore, orderStore, userStore } = useStores()

  const foundationCsvDownloadEnabled = useFlag('foundation_csv_download')
  const tcmCsvV2DownloadEnabled = useFlag('tcm_csv_v2_download')
  const rfqExtractionEnabled = useFlag('rfq_extraction')

  const canEditPackageName = userStore.canManageOrders && userStore.canSendAndUpdateOrders
  const canInputVendorData = userStore.canInputVendorData
  const canShowFoundationDownload =
    foundationCsvDownloadEnabled && constants.statesToShowIntegration.includes(orderStore.selectedOrder?.state)
  const canShowIntegration =
    userStore.canUseIntegrations &&
    constants.statesToShowIntegration.includes(orderStore.selectedOrder?.state) &&
    integrationStore.connected &&
    integrationStore.purchaseOrderSyncEnabled
  const canShowTCMV2Download =
    tcmCsvV2DownloadEnabled && constants.statesToShowIntegration.includes(orderStore.selectedOrder?.state)
  const canShowOrderConfirmOverride =
    userStore.canInputVendorData && orderStore.selectedOrder?.sub_state === OrderSubStates.ORDERED_REQUESTED
  const canSyncWithErp = userStore.canSyncWithErp

  const cannotSendAndUpdateOrders = userStore.cannotSendAndUpdateOrders

  return {
    canEditPackageName,
    canInputVendorData,
    canShowFoundationDownload,
    canShowIntegration,
    canShowOrderConfirmOverride,
    canShowTCMV2Download,
    canSyncWithErp,
    cannotSendAndUpdateOrders,
    foundationCsvDownloadEnabled,
    rfqExtractionEnabled,
  }
}
