import React from 'react'

import { Typography, Input } from 'antd'
import { InputProps } from 'antd/lib/input'

export type VendorNoteColumnProps = { edit?: boolean } & InputProps

export const VendorNoteColumn = ({ edit = true, ...props }) => {
  if (edit) {
    return <Input style={{ width: '100%' }} {...props} />
  }

  return (
    <Typography.Paragraph
      title={props?.value}
      ellipsis={{ rows: 2, expandable: true }}
      style={{ fontSize: 13, margin: 0 }}
    >
      {props?.value}
    </Typography.Paragraph>
  )
}
