import axios from 'axios'

export interface InvoiceOtherSettings {
  accounting_date_enabled: boolean
}

export function index() {
  return axios.get('/invoice_settings')
}

export function update(invoice_settings: InvoiceOtherSettings) {
  return axios.post('/invoice_settings', { invoice_settings: invoice_settings })
}
