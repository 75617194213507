import React from 'react'

import moment from 'moment/moment'

import styled from '@emotion/styled'

import { Table as AntdTable, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'

import { InvoiceStatusTag } from 'contractor/components/Invoices/invoice_status_tag'
import { Cell } from 'contractor/pages/@v2/Commitments/common/components/ListCells'
import { Wrapper } from 'contractor/pages/@v2/Commitments/Material/components/Table/Table/wrapper'
import { IndexInvoiceRecord } from 'contractor/server/invoices/invoice'

const StyledTable = styled(AntdTable)`
  .ant-table.cell {
    background-color: white !important;
  }

  .ant-table.table {
    background-color: white !important;
  }
`

type TableProps = {
  dataSource: IndexInvoiceRecord[]
}

export const Table = observer<TableProps>((props) => {
  const { dataSource } = props

  return (
    <FlexBoxY bg="white" mt="16" width="100%">
      <Wrapper alignItems="flex-start" justifyContent="flex-start" width="100%" overflowX="auto">
        <StyledTable
          rowKey={(record) => record?.invoice_id}
          pagination={false}
          size="small"
          style={{ width: '100%', backgroundColor: 'white' }}
          data-cy="material-table"
          aria-label="order-materials-table"
          dataSource={dataSource}
          columns={[
            {
              title: 'Invoice Number',
              width: '20%',
              minWidth: 120,
              render: (_, record: IndexInvoiceRecord) => {
                return (
                  <Typography.Link href={`/invoice/${record.invoice_id}`} target="_blank">
                    {record.invoice_number}
                  </Typography.Link>
                )
              },
            },
            {
              title: 'Status',
              dataIndex: 'state',
              width: '8%',
              minWidth: 120,
              render: (row, record: IndexInvoiceRecord) => {
                return <InvoiceStatusTag state={record.state} />
              },
            },
            {
              width: '10%',
              title: 'Grand total',
              minWidth: 120,
              render: (_, record: IndexInvoiceRecord) => {
                return currencyFormatter(record.total_amount, 2)
              },
            },
            {
              width: '10%',
              title: 'Assigned To',
              minWidth: 120,
              render: (_, record: IndexInvoiceRecord) => {
                return <Cell.FormattedText value={record?.assigned_to_label} column={record} />
              },
            },
            {
              width: '10%',
              title: 'Created at',
              minWidth: 120,
              render: (_, record: IndexInvoiceRecord) => {
                return (
                  (record.invoice_created_at && moment(record.invoice_created_at).format('ddd. MMM Do, YYYY')) ?? '-'
                )
              },
            },
            {
              width: '10%',
              title: 'Invoice date',
              minWidth: 120,
              render: (_, record: IndexInvoiceRecord) => {
                return (record.invoice_date && moment(record.invoice_date).format('ddd. MMM Do, YYYY')) ?? '-'
              },
            },
          ]}
        />
      </Wrapper>
    </FlexBoxY>
  )
})
