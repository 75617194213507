import React from 'react'

import { Button, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DrawerTableFilters } from 'common/components/DrawerTableFilters'
import { SelectFilter } from 'common/components/table/postgres_filters'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { ExportButton } from 'contractor/components/ExportButton/export_button'
import { useStores } from 'contractor/hooks/use-stores'

export const Filters = observer(() => {
  const { costCodeStore } = useStores()
  const persistentFilters = usePersistentFilters()

  const { costCodePhaseListStore } = costCodeStore

  return (
    <DrawerTableFilters title="Phase Code Options" useAlgolia={false}>
      <Box mb={16} display="flex" justifyContent="space-between" alignItems="center">
        <Typography.Title level={5} style={{ margin: 0 }}>
          Filters
        </Typography.Title>
        <Button
          type="link"
          onClick={() => persistentFilters.handleClearFilters(['active'])}
          disabled={persistentFilters.appliedFiltersCount === 0}
        >
          Clear All
        </Button>
      </Box>

      <Box width="100%" display="flex" flexDirection="column">
        <Typography.Paragraph type="secondary">Project</Typography.Paragraph>
        <SelectFilter
          items={
            costCodePhaseListStore.facets['projects']?.map((project) => ({
              value: project.label,
              label: project.label,
            })) || []
          }
          selected={costCodePhaseListStore.searchState.filters['projects']}
          onChange={(projects) => persistentFilters.handleChangeFilters({ projects })}
          attribute="projects"
          placeholder="Select Projects"
          isSearchable
        />
      </Box>

      <ExportButton onExport={costCodePhaseListStore?.exportRecords} title="Download Phase Codes" />
    </DrawerTableFilters>
  )
})
