import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { NotificationIntegrationIcon } from 'common/components/Notifications/notification_integration_icon'
import { NotificationProjectIcon } from 'common/components/Notifications/notification_project_icon'
import { NotificationTag } from 'common/components/Notifications/notification_tag'
import { formatDateDiff } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'
import { ServerNotificationGroup } from 'common/server/user'

type NotificationRowProps = { group: ServerNotificationGroup }

export const NotificationHeader = ({ group }: NotificationRowProps) => {
  const { project_name, subject_name, target_number, object_type, integration_source, model_state } = group
  const theme = useTheme()

  return (
    <Box borderRadius="md" py="4" width="100%">
      {!!subject_name && (
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <NotificationTag type={object_type} state={model_state} />
          <Typography.Text strong style={{ alignSelf: 'flex-start' }}>
            {target_number ? `${target_number}: ${subject_name}` : subject_name}
          </Typography.Text>
        </Box>
      )}

      <Box display="flex" justifyContent="space-between">
        {integration_source ? (
          <NotificationIntegrationIcon integrationSource={integration_source} />
        ) : (
          <NotificationProjectIcon projectName={project_name} />
        )}
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Typography.Text style={{ fontSize: 12, color: theme.colors['gray-7'], alignSelf: 'flex-end' }}>
            {formatDateDiff(group.updated_at)}
          </Typography.Text>
        </Box>
      </Box>
    </Box>
  )
}
