/** @jsx jsx */

import * as React from 'react'

import { jsx } from '@emotion/core'

import { Checkbox, Modal } from 'antd'

import { observer } from 'mobx-react-lite'

import { useStores } from 'contractor/hooks/use-stores'

// Had some customers that registered before we implemented terms and conditions so this
// guarantees that they have approved them.
// TODO: Also needed for users accepting an invitation (but that should be moved)
const TermsAndConditions: React.FC<{}> = observer(() => {
  const { userStore } = useStores()
  const [approved, setApproved] = React.useState(false)

  if (!userStore.currentUser) {
    return null
  }

  const { terms_and_conditions } = userStore.currentUser

  return (
    <Modal
      title="Terms and Conditions"
      open={!terms_and_conditions.terms_and_conditions_approved_on}
      cancelButtonProps={{ style: { visibility: 'hidden' } }}
      okButtonProps={{ disabled: !approved }}
      onOk={() => userStore.update()}
      closable={false}
    >
      <Checkbox checked={approved} onChange={() => setApproved(!approved)} style={{ marginRight: '10px' }}>
        Approve <a href={terms_and_conditions.url}>Terms and Conditions</a>
      </Checkbox>
    </Modal>
  )
})

export default TermsAndConditions
