import React from 'react'

import { Typography, Avatar } from 'antd'

import { Box } from 'common/components/boxes'
import { ContractorIcon } from 'common/components/ContractorIcon'
import { SubBaseWhiteIcon } from 'common/components/SubBaseIcon'
import { VendorIcon } from 'common/components/VendorIcon'
import { formatDateDiff } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'

const isVendorAuthor = (authorType) => ['VendorUser', 'CompanyVendorContact'].includes(authorType)

const LastActionIcon = ({ authorType = '' }) => {
  if (authorType === 'User') {
    return <ContractorIcon />
  }

  if (isVendorAuthor(authorType)) {
    return <VendorIcon />
  }

  return <SubBaseWhiteIcon />
}

type CurrentType = 'User' | 'VendorUser' | 'CompanyVendorContact' | 'SubBase'

type Props = {
  currentType: Array<CurrentType>
  lastAction: {
    author_type: CurrentType
    author_name: string
    created_at: string
  }
}

export function CellLastAction(props: Props) {
  const { lastAction, currentType } = props

  const theme = useTheme()

  if (!lastAction) {
    return <Typography.Text type="secondary">Unknown</Typography.Text>
  }

  const backgroundColor = currentType.includes(lastAction?.author_type) && theme.colors.primary

  return (
    <Box display="inline-flex" alignItems="center">
      <Avatar
        style={{
          backgroundColor,
          minWidth: 32,
        }}
        icon={<LastActionIcon authorType={lastAction?.author_type} />}
      />
      <Box display="flex" flexDirection="column" ml={8}>
        <Typography.Text>{lastAction?.author_name}</Typography.Text>
        <Typography.Text type="secondary">{formatDateDiff(lastAction?.created_at)}</Typography.Text>
      </Box>
    </Box>
  )
}
