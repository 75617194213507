import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import styled from '@emotion/styled'

import { Badge, Tabs } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'

import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'
import { AdditionalCostsDrawer } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/additional_costs_drawer'
import { PriceIncreaseDrawer } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/price_increase_drawer'
import { MixListExtraContent } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/mix_list_extra_content'
import { MixList } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/MixList/mix_list'

const insertIf = (condition, ...elements) => (condition ? elements : [])

type AttachmentsProps = {
  disabled?: boolean
}

const StyledCard = styled(Box)`
  padding: 8px;
  margin: 0;
  border-width: 0 1px 1px 1px !important;
  border-style: none solid solid solid !important;
  border-color: transparent #f0f0f0 #f0f0f0 #f0f0f0 !important;
  background-color: white;
  border-radius: 4px;
`

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0 !important;
  }
`

export const ConcreteCommitmentMaterials = (props: AttachmentsProps) => {
  const { disabled } = props
  const history = useHistory()
  const location = useLocation()
  const { commitment, form } = useCommitment()
  const [isAdditionalCostDrawerVisible, setIsAdditionalCostDrawerVisible] = React.useState(false)
  const [isPriceIncreaseDrawerVisible, setIsPriceIncreaseDrawerVisible] = React.useState(false)
  const isCreated = commitment?.created_at
  const source = window.location.pathname

  const handleAdditionCosts = () => {
    setIsAdditionalCostDrawerVisible(!isAdditionalCostDrawerVisible)
  }

  const handlePriceIncrease = () => {
    setIsPriceIncreaseDrawerVisible(!isPriceIncreaseDrawerVisible)
  }

  const activeKey = new URLSearchParams(location.search).get('commitment_tab') || 'mix_list'
  const tabItems = [
    {
      label: (
        <Badge style={{ zIndex: 900 }} count={commitment?.commitment_materials?.length} offset={[4, 0]} color="blue">
          <Box onClick={() => trackEvent(Events.CLICK_COMMITMENTS_TAB, source)} paddingX="8">
            Mix List
          </Box>
        </Badge>
      ),
      key: 'mix_list',
      children: <MixList disabled={disabled} />,
    },
    ...insertIf(isCreated, {
      label: (
        <Badge style={{ zIndex: 900 }} count={commitment?.order_count} offset={[4, 0]} color="blue">
          <Box onClick={() => trackEvent(Events.CLICK_ORDERS_TAB, source)} paddingX="8">
            Look ahead
          </Box>
        </Badge>
      ),
      key: 'look_ahead',
      children: <StyledCard> Look Ahead</StyledCard>,
    }),
    ...insertIf(isCreated, {
      label: (
        <Badge style={{ zIndex: 900 }} count={commitment?.invoice_count} offset={[4, 0]} color="blue">
          <Box onClick={() => trackEvent(Events.CLICK_INVOICES_TAB, source)} paddingX="8">
            Log
          </Box>
        </Badge>
      ),
      key: 'log',
      children: <StyledCard> Log</StyledCard>,
    }),
    ...insertIf(isCreated, {
      label: (
        <Badge style={{ zIndex: 900 }} count={commitment?.invoice_count} offset={[4, 0]} color="blue">
          Invoice
        </Badge>
      ),
      key: 'invoices',
      children: <StyledCard> Invoices</StyledCard>,
    }),
  ]

  return (
    <>
      <StyledTabs
        tabBarExtraContent={
          <MixListExtraContent
            disabled={disabled}
            onAdditionCosts={handleAdditionCosts}
            onPriceIncrease={handlePriceIncrease}
          />
        }
        activeKey={activeKey}
        onChange={(key) => {
          const searchParams = new URLSearchParams(history.location.search)
          searchParams.set('commitment_tab', key)
          history.push({ search: searchParams.toString() })
        }}
        items={tabItems}
      />
      <AdditionalCostsDrawer
        visible={isAdditionalCostDrawerVisible}
        onClose={() => setIsAdditionalCostDrawerVisible(false)}
        title="Additional costs"
        form={form}
      />
      <PriceIncreaseDrawer
        visible={isPriceIncreaseDrawerVisible}
        onClose={() => setIsPriceIncreaseDrawerVisible(false)}
        title="Price increase"
        form={form}
      />
    </>
  )
}
