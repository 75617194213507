import React from 'react'

import { LocalShipping } from '@styled-icons/material-outlined'

import { PaperClipOutlined, ProjectOutlined, ShopFilled, ShopOutlined } from '@ant-design/icons'
import { Space, Typography } from 'antd'

import { Box, FlexBoxY } from 'common/components/boxes'
import { ContractorIcon } from 'common/components/ContractorIcon'
import OrderState from 'common/components/statuses/order_state'
import { formatDateString } from 'common/helpers/formatters'
import { OrderDelivery } from 'common/server/deliveries'
import { OrderHitDelivery } from 'common/server/orders'
import { DeliveryStates, OrderStates, OrderSubStates } from 'common/server/server_types'
import theme from 'common/styles/theme'

interface Item {
  order_number: string
  name: string
  project_name?: string
  company_name?: string
  is_draft: boolean
  draft_vendor_names: string[]
  vendor_name: string
  deliveries: OrderDelivery[]
  state: OrderStates
  sub_state: OrderSubStates
  has_open_delivery_issue?: boolean
}

export interface Props {
  notificationCount?: number
  item: Item
  onSelectDelivery?: () => void
  canSelectDelivery?: boolean
  showVendor?: boolean
  showContractor?: boolean
}

export function MobileCard(props: Props) {
  const { item, notificationCount, showVendor, showContractor } = props

  return (
    <Box display="grid" gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr' }} gridGap={16} alignItems="center">
      <Typography.Link ellipsis style={{ width: '100%' }}>
        {item.order_number}: {item.name}
      </Typography.Link>

      <Box display="flex" gridGap={4} alignItems="center" justifySelf={{ _: 'start', sm: 'end' }}>
        <OrderState
          state={item.state}
          sub_state={item.sub_state}
          notificationCount={notificationCount}
          deliveryIssues={item.deliveries?.map((d) => d.delivery_issue_type)}
          showIssue={item.has_open_delivery_issue}
          style={{ margin: 0, marginTop: 2 }}
        />
      </Box>

      {item.project_name && (
        <Box gridColumn={{ _: 1, sm: 'span 2' }} justifySelf="start">
          <Space>
            <ProjectOutlined />
            <Typography.Text>{item?.project_name}</Typography.Text>
          </Space>
        </Box>
      )}

      {showContractor && (
        <Space>
          <ContractorIcon />
          <Typography.Text>{item.company_name}</Typography.Text>
        </Space>
      )}

      {showVendor && (
        <Box gridColumn={{ _: 1, sm: 'span 2' }} justifySelf="start">
          {item.is_draft ? (
            item.draft_vendor_names?.map((vendor_name) => (
              <Space key={vendor_name}>
                <ShopOutlined />
                <Typography.Text>{vendor_name}</Typography.Text>
              </Space>
            ))
          ) : (
            <Space>
              <ShopOutlined />
              <Typography.Text>{item.vendor_name}</Typography.Text>
            </Space>
          )}
        </Box>
      )}

      <Box gridColumn={{ _: 1, sm: 'span 2' }} justifySelf="start">
        <FlexBoxY alignItems="flex-start">
          {item.deliveries?.map((delivery, index) => {
            const {
              state,
              requested_delivered_at,
              best_guess_delivered_at,
              address,
              estimated_delivered_at,
              carrier,
              delivery_files = [],
            } = delivery as OrderHitDelivery

            const Component = props.canSelectDelivery ? Typography.Link : Typography.Text

            const addressText = address ? address.nickname || address.address_line_1 : ''

            const formattedRequestedAt = formatDateString(requested_delivered_at)
            const formattedETA = formatDateString(estimated_delivered_at)
            const formattedDeliveryDate = formatDateString(best_guess_delivered_at)

            const style = {}
            let text = ''
            switch (state) {
              case DeliveryStates.UNSCHEDULED:
                style['color'] = theme.colors['gray-7']
                text = 'Unscheduled'
                break
              case DeliveryStates.REQUESTED:
                text = `Requested - ${formattedRequestedAt} to ${addressText}`
                break
              case DeliveryStates.SCHEDULED:
                text = `Scheduled - ${formattedRequestedAt} to ${addressText}`
                break
              case DeliveryStates.SHIPPED:
                if (estimated_delivered_at && carrier) {
                  text = `${carrier}, ETA: ${formattedETA}`
                } else if (estimated_delivered_at && !carrier) {
                  text = `ETA: ${formattedETA}`
                } else if (!estimated_delivered_at && carrier) {
                  text = `${carrier}, ETA: Unknown`
                } else {
                  text = 'ETA: Unknown'
                }
                text = 'Shipped - ' + text
                break
              case DeliveryStates.DELIVERED:
                text = `Delivered - ${formattedDeliveryDate}`
                break
            }

            return (
              // @todo: handleClick(id, e)
              <div onClick={console.log} key={index}>
                <Space align="center">
                  {delivery.is_pick_up ? (
                    <ShopFilled style={{ color: theme.colors['gray-7'] }} />
                  ) : (
                    <LocalShipping size="15" color={theme.colors['gray-7']} />
                  )}
                  <Component ellipsis style={style}>
                    {text}
                  </Component>
                  {!!delivery_files.length && <PaperClipOutlined />}
                </Space>
              </div>
            )
          })}
        </FlexBoxY>
      </Box>
    </Box>
  )
}
