import React from 'react'

import { useHistory } from 'react-router-dom'

import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import { Button, Typography, Space } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'

export const InvoicePagination = observer(() => {
  const { invoiceStore } = useStores()

  const history = useHistory()

  const { listStore } = invoiceStore

  const listItems = invoiceStore.hits.length ? [...invoiceStore.hits] : listStore.records
  const hitsSize = listItems?.length

  if (!hitsSize) {
    return null
  }

  const currentInvoiceIndex = listItems?.findIndex(
    (listItem) => listItem.invoice_id === invoiceStore.invoice.id || listItem.id === invoiceStore.invoice.id,
  )

  return (
    <Box
      bg="white"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      width="fit-content"
      mt={20}
      pl={16}
      border="1px solid"
      borderColor="gray-4"
    >
      <Typography.Text strong style={{ marginRight: 12 }}>
        {`${currentInvoiceIndex + 1} of ${hitsSize}`}
      </Typography.Text>
      <Space.Compact>
        <Button
          icon={<LeftOutlined />}
          type="text"
          disabled={currentInvoiceIndex === 0}
          onClick={() => {
            const prevInvoice = listItems[currentInvoiceIndex - 1]
            history.push(`./${prevInvoice?.['invoice_id'] || prevInvoice?.id}`)
          }}
        />
        <Button
          icon={<RightOutlined />}
          type="text"
          disabled={currentInvoiceIndex === hitsSize - 1}
          onClick={() => {
            const nextInvoice = listItems[currentInvoiceIndex + 1]
            history.push(`./${nextInvoice?.['invoice_id'] || nextInvoice?.id}`)
          }}
        />
      </Space.Compact>
    </Box>
  )
})
