import { BindAll } from 'lodash-decorators'

import { observable, action } from 'mobx'

import { Unit } from 'common/server/units'

@BindAll()
export default class UnitStore {
  // In the best scenario, we need to create an interface to the HTTP client and receive a generic store type,
  // but first, we need to standardize our payloads. For now just inject this specific list method.
  constructor(private list) {}

  units = observable.array<Unit>([])

  @action
  async getUnits() {
    const { data } = await this.list()
    this.units.replace(data.units)

    return this.units
  }

  convertUnitsToString(units: Unit[]) {
    return units.map((item) => (item.multiplier === '1' ? item.name : `${item.multiplier} ${item.name}`))
  }

  // Only index if we don't have any unit loaded yet
  maybeUnits() {
    if (!this.units.length) {
      return this.getUnits()
    }
  }
}
