import React from 'react'

import { flatten, uniq } from 'lodash'

import styled from '@emotion/styled'

import { Alert } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'
import { RfqsBlockedModals } from 'common/components/RfqsBlockedModals'
import { formatEnumValue } from 'common/helpers/formatters'

import { useLeveling } from './context'
import { TableBody } from './table_body'
import { TableFooter } from './table_footer'
import { TableHeader } from './table_header'

const TableStyled = styled.table`
  .order-cancelled,
  .material-deleted,
  .zero-quantity {
    text-decoration: line-through;
  }
  .lowest-price-total {
    color: ${({ theme }) => theme.colors.success};
  }
  .lowest-price {
    background-color: ${({ theme }) => theme.colors['green-1']};
  }
  .ordered-material {
    background-color: ${({ theme }) => theme.colors['blue-1']};
  }

  .border-left {
    border-left: 1px solid rgba(0, 0, 0, 0.06);
  }
  .border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.06);
  }
  .border-top {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }

  @media print {
    @page {
      margin: 9mm;
    }
    .hide-on-print {
      display: none;
    }
    .ant-table-cell {
      padding: 8px;
    }
  }
`

export const Table = () => {
  const { tableData, orders, printAreaRef, ordersSession, goBack } = useLeveling()

  // Every vendor item should have a delivery_id, if not, it means that the vendor item is not in the order
  const materialsDoesntMatch = tableData.some((item) =>
    item.vendor_items.some((vendorItem) => !vendorItem?.delivery_id),
  )

  const deliveryCosts = ['tax_value', 'shipping_value', 'other_value', 'discount_value']

  const hasNonMaterialCosts = orders.some((order) => {
    return order.deliveries.some((delivery) => {
      return deliveryCosts.some((cost) => delivery[cost])
    })
  })

  const deliveries = flatten(orders.map((order) => order.deliveries.map((delivery) => delivery)))
  const currentDeliveriesCosts = flatten(
    deliveries.map((delivery) => Object.keys(delivery).filter((key) => deliveryCosts.includes(key) && delivery[key])),
  )
  const nonMaterialList = uniq(currentDeliveriesCosts)
    .map((cost) => formatEnumValue(cost.replace('_value', '')))
    .join(', ')

  return (
    <Box width="100%">
      {materialsDoesntMatch && (
        <Alert
          message="The quotes you are comparing appear to have some differences in quantity, unit, or other attributes."
          type="warning"
          style={{ marginBottom: 16 }}
        />
      )}

      {hasNonMaterialCosts && (
        <Alert
          message={`Some of your orders have non-material costs (${nonMaterialList}) that may be affected by modifying your request.`}
          type="warning"
          style={{ marginBottom: 16 }}
        />
      )}

      <FlexBoxX width="100%" alignItems="base-line" overflowX="auto">
        <Box className="ant-table ant-table-small " width="100%">
          <Box className="ant-table-container">
            <Box className="ant-table-content">
              <TableStyled id="RFQComparisonTable" ref={printAreaRef}>
                <TableHeader />

                <TableBody />

                <TableFooter />
              </TableStyled>
            </Box>
          </Box>
        </Box>
      </FlexBoxX>

      <RfqsBlockedModals
        onCancelModalOrderExpired={() => goBack()}
        onCancelModalOrderReleased={() => goBack()}
        {...ordersSession}
      />
    </Box>
  )
}
