/** @jsx jsx */

import * as React from 'react'

import { Column, Row } from 'react-table'

import { jsx } from '@emotion/core'

import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import { Button, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

interface RemoveCellProps {
  value: boolean
  row: Row
  column: Column & {
    onRemove: (index: number) => void
  }
}

const RemoveCell: React.FC<RemoveCellProps> = observer(({ row, column }) => {
  const onRemove = () => {
    column['onRemove'](row.index)
  }

  return (
    <Tooltip title="Remove">
      <Button shape="circle" size="small" style={{ margin: '0 5px' }} onClick={onRemove} icon={<DeleteOutlined />} />
    </Tooltip>
  )
})

export default RemoveCell
