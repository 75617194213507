import React from 'react'

import { Link } from 'react-router-dom'

export type NavItemProps = {
  label: string
  href: string
}

export const NavItem = ({ label, href }: NavItemProps) => {
  return (
    <Link data-cy={`menu-${label.toLowerCase()}`} to={href}>
      {label}
    </Link>
  )
}
