import React from 'react'

import { FlexBoxY } from 'common/components/boxes'
import { ChangedItem, ChangeType } from 'common/components/History'
import { EventChange } from 'common/server/order_history'

const FILE_FIELDS_CONFIG = [
  {
    key: 'Blob',
    type: 'file',
  },
  {
    key: 'Discarded at',
    type: 'file',
  },
]
export const ALLOW_FILE_FIELDS = FILE_FIELDS_CONFIG.map((item) => item.key)

type PurchaserFileProps = {
  purchaserFile: EventChange
}

export const PurchaserFile = ({ purchaserFile }: PurchaserFileProps) => {
  const keys = Object.keys(purchaserFile.changes).filter((key) => ALLOW_FILE_FIELDS.includes(key))

  if (!keys.length) {
    return null
  }

  return (
    <FlexBoxY alignItems="flex-start" width="100%" key={purchaserFile.item_id}>
      {keys.map((key, index) => {
        let changes = purchaserFile.changes[key]

        if (key === 'Discarded at') {
          changes = [purchaserFile?.snapshot?.FileName, null]
        }

        return (
          <FlexBoxY alignItems="flex-start" width="100%" key={`${key}-${index}`}>
            <ChangedItem
              href={purchaserFile?.snapshot?.FilePath}
              label="Purchaser File"
              changes={changes}
              isCreate={purchaserFile.event === 'create'}
              type={FILE_FIELDS_CONFIG.find((item) => item.key === key)?.type as ChangeType}
            />
          </FlexBoxY>
        )
      })}
    </FlexBoxY>
  )
}
