import React, { useEffect } from 'react'

import styled from '@emotion/styled'

import { CloseOutlined } from '@ant-design/icons'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import { Alert, Button, Drawer, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX } from 'common/components/boxes'
import { getUnit } from 'common/components/OrderMaterialsV2'
import { CostCode } from 'common/server/cost_codes/cost_codes'
import { OrderMaterial } from 'common/server/orders'

import { OrdersAutocomplete } from 'contractor/components/OrdersAutocomplete'
import { useStores } from 'contractor/hooks/use-stores'
import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'
import {
  getNonExistingMaterials,
  getOverBudgetMaterials,
  getRemainingQuantity,
  Table,
} from 'contractor/pages/@v2/Commitments/Material/components/OrderDrawer/table'
import { CommitmentMaterial } from 'contractor/server/commitments'
import { ConsolidatedOrders, ShowOrderResponse } from 'contractor/server/orders'

type CommitmentOrderDrawerProps = {
  visible: boolean
  onClose: () => void
  title: string
  phaseCodeEnabled?: boolean
  costCodeEnabled?: boolean
}

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0 16px 16px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 56px);
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  border-top: 1px solid #f0f0f0;
  background: #fff;
`

export const CommitmentOrderDrawer = observer<CommitmentOrderDrawerProps>((props: CommitmentOrderDrawerProps) => {
  const { onClose, visible, title, phaseCodeEnabled = false, costCodeEnabled } = props
  const { commitment, ordersToImport, setOrdersToImport } = useCommitment()
  const { commitmentStore } = useStores()
  const [isLoading, setIsLoading] = React.useState(false)
  const [order, setOrder] = React.useState<ShowOrderResponse | null>(null)

  const loadOrder = async (id: string) => {
    setIsLoading(true)
    commitmentStore?.loadOrder(id).then((order) => {
      setOrder(order)
    })
    setIsLoading(false)
  }

  const clearOrder = () => {
    setOrder(null)
  }

  useEffect(() => {
    clearOrder()
    return () => {
      clearOrder()
    }
  }, [])

  const handleCancel = () => {
    onClose()
    clearOrder()
  }

  const handleImportMaterialsIntoCommitment = (nonExistingMaterials: OrderMaterial[]) => {
    nonExistingMaterials.forEach((material) => {
      const newMaterial = {
        id: null,
        unit_price: material.unit_cost,
        unit: getUnit(material.company_material),
        cost_code_phase_id: material?.['cost_code_phase_id'],
        cost_code: material['cost_code'] as CostCode,
        cost_code_id: material['cost_code']?.['id'],
        company_material: {
          ...material.company_material,
        },
        quantity: material.quantity,
      } as CommitmentMaterial

      commitmentStore.addMaterial(newMaterial)
    })
  }

  const handleIncreaseBudget = (overBudgetMaterials: OrderMaterial[]) => {
    overBudgetMaterials.forEach((material) => {
      const commitmentMaterial = commitment.commitment_materials.find((cm) => cm.unique_id === material.unique_id)
      const overBudgetQuantity = Math.abs(getRemainingQuantity(commitmentMaterial, material.quantity))

      commitmentStore.updateMaterial(commitmentMaterial, {
        ...commitmentMaterial,
        quantity_ordered: Number(commitmentMaterial.quantity_ordered) + Number(material.quantity),
        quantity: Number(commitmentMaterial.quantity) + Number(overBudgetQuantity),
      })
    })
  }

  const handleUpdateExistingMaterialsBudget = (materialsToUpdate: OrderMaterial[]) => {
    materialsToUpdate.forEach((material) => {
      const commitmentMaterial = commitment.commitment_materials.find((cm) => cm.unique_id === material.unique_id)

      commitmentStore.updateMaterial(commitmentMaterial, {
        ...commitmentMaterial,
        quantity_ordered: Number(commitmentMaterial.quantity_ordered) + Number(material.quantity),
      })
    })
  }

  const handleAddOrder = () => {
    setIsLoading(true)
    const nonExistingMaterials = getNonExistingMaterials(order?.order_materials, commitment.commitment_materials)
    const overBudgetMaterials = getOverBudgetMaterials(order?.order_materials, commitment.commitment_materials)
    const materialsToUpdate = order?.order_materials?.filter(
      (om) =>
        !nonExistingMaterials.some((nm) => nm.unique_id === om.unique_id) &&
        !overBudgetMaterials.some((obm) => obm.unique_id === om.unique_id),
    )

    handleImportMaterialsIntoCommitment(nonExistingMaterials)
    handleIncreaseBudget(overBudgetMaterials)
    handleUpdateExistingMaterialsBudget(materialsToUpdate)

    setOrdersToImport([...ordersToImport, order.id])
    commitmentStore.updateSelectedCommitment('order_count', commitment.order_count + 1)

    setIsLoading(false)
    onClose()
    clearOrder()
  }

  return (
    <StyledDrawer title={title} placement="right" closable={true} onClose={onClose} open={visible} width="65%">
      <Box paddingTop={16}>
        <Typography.Text style={{ marginBottom: 16 }}>
          Select the order you want to import into the commitment
        </Typography.Text>
        <FlexBoxX>
          <OrdersAutocomplete
            onSelect={(order: ConsolidatedOrders.Order) => loadOrder(order.order_id)}
            filters={{ commitment_id: commitment.id }}
            footerComponent={null}
          />
          <Button onClick={clearOrder} style={{ marginLeft: 8 }}>
            {isLoading ? <LoadingOutlined /> : <CloseOutlined />}
          </Button>
        </FlexBoxX>
        {!!order && (
          <Typography.Text style={{ marginTop: 8 }}>
            #{order.order_number} - {order.order_package_name}
          </Typography.Text>
        )}
        {!!order && (
          <Table
            phaseCodeEnabled={phaseCodeEnabled}
            costCodeEnabled={costCodeEnabled}
            commitmentMaterials={commitment.commitment_materials}
            orderMaterials={order?.order_materials}
          />
        )}
      </Box>
      <Box>
        <Alert
          style={{ width: '100%' }}
          message="Please note that the order will be imported when you update the commitment."
          type="info"
          showIcon={true}
        />
        <Footer>
          <Button onClick={handleCancel} loading={isLoading} style={{ width: 100 }}>
            Cancel
          </Button>
          <Button
            onClick={handleAddOrder}
            disabled={!order}
            type="primary"
            loading={isLoading}
            htmlType="submit"
            style={{ width: 100 }}
          >
            Confirm
          </Button>
        </Footer>
      </Box>
    </StyledDrawer>
  )
})
