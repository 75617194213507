import React from 'react'

import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'

import { formatEnumValue } from 'common/helpers/formatters'
import { DeliveryStatuses } from 'common/server/server_types'

type SelectDeliveryIssuesProps = SelectProps

export const SelectDeliveryIssues = (props: SelectDeliveryIssuesProps) => {
  return (
    <Select data-cy="delivery-issue-type" placeholder="Complete" style={{ width: '100%' }} allowClear {...props}>
      {Object.values(DeliveryStatuses).map((issue) => (
        <Select.Option data-cy={issue} key={issue} value={issue}>
          {formatEnumValue(issue)}
        </Select.Option>
      ))}
    </Select>
  )
}
