import { BindAll } from 'lodash-decorators'

import { observable, action } from 'mobx'

import {
  index,
  ProjectGroupsList,
  update,
  ProjectGroupsUpdate,
  create,
  ProjectGroupsCreate,
  show,
  ProjectGroupsShow,
} from 'contractor/server/project_groups'

@BindAll()
export default class ProjectGroupsStore {
  projectGroups = observable.array<ProjectGroupsList>([])
  @observable selectedProjectGroup: Nullable<ProjectGroupsShow>

  @action
  async indexProjectGroups() {
    const { data } = await index()
    this.projectGroups.replace(data)
  }

  @action
  async showProjectGroups(id?: string): Promise<ProjectGroupsShow | null> {
    if (!id) {
      this.selectedProjectGroup = null
      return null
    }

    const { data } = await show(id)
    this.selectedProjectGroup = data

    return data
  }

  createProjectGroups(params: ProjectGroupsCreate) {
    return create(params)
  }

  updateProjectGroups(id: string, params: ProjectGroupsUpdate) {
    return update(id, params)
  }
}
