import React, { useEffect, useRef } from 'react'

import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'
import { Page } from 'common/components/Page'
import { PersistentFiltersProvider } from 'common/contexts/persistent_filters'
import { noticeError } from 'common/helpers/new_relic'

import { useStores } from 'contractor/hooks/use-stores'
import { UpsertCostCodePhase } from 'contractor/pages/CompanyMaterials/CostCodes/Upsert/upsert_cost_code_phase'

import { Header } from './Header'
import { TablePhaseCodes } from './table'

export const PhaseCodes = observer(() => {
  const { costCodeStore, userStore, integrationStore } = useStores()

  const upsertCostCodeRef = useRef<DrawerRef>()

  const { costCodePhaseListStore } = costCodeStore

  const handleClickRow = (phaseCodeId: string) => {
    if (!userStore.canEditMaterialDatabase) {
      return
    }
    costCodeStore.selectPhaseFromList(phaseCodeId)
    upsertCostCodeRef.current?.show()
  }

  const handleAfterCreatePhaseCode = async () => {
    try {
      await costCodePhaseListStore.fetchRecords()
    } catch (error) {
      noticeError(error, { entry: 'refetch-phase-codes-after-create' })
    }
  }

  useEffect(() => {
    integrationStore.accountingInfo()
  }, [])

  return (
    <PersistentFiltersProvider ignoreQueryParams={['tab']} listStore={costCodePhaseListStore}>
      <Page.Header>
        <Header onAdd={() => upsertCostCodeRef.current?.show()} />
      </Page.Header>

      <Page.Content>
        <TablePhaseCodes onClickRow={handleClickRow} />
      </Page.Content>

      <UpsertCostCodePhase
        ref={upsertCostCodeRef}
        onCancel={() => {
          upsertCostCodeRef.current?.close()
          costCodeStore.selectPhaseFromList(null)
        }}
        onSubmit={handleAfterCreatePhaseCode}
        initialValues={{
          code: costCodeStore.selectedPhaseCode?.code,
          dataSource: costCodeStore.selectedPhaseCode?.data_source,
          description: costCodeStore.selectedPhaseCode?.description,
          id: costCodeStore.selectedPhaseCode?.id,
          projectIdsRelation: costCodeStore.selectedPhaseCode?.project_ids,
          active: !costCodeStore.selectedPhaseCode?.discarded_at,
        }}
      />
    </PersistentFiltersProvider>
  )
})
