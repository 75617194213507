import React from 'react'

import { connectSortBy } from 'react-instantsearch-dom'
import { useLocation } from 'react-router-dom'

import classNames from 'classnames'

import styled from '@emotion/styled'

import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined'
import CaretUpOutlined from '@ant-design/icons/CaretUpOutlined'
import { Tooltip } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { FlexBoxY } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'

const SortAscButton = ({ onClick, isActive }) => (
  <CaretUpOutlined onClick={() => onClick('asc')} className={classNames({ active: isActive })} />
)

const SortDescButton = ({ onClick, isActive }) => (
  <CaretDownOutlined onClick={() => onClick('desc')} className={classNames({ active: isActive })} />
)

const Wrapper = styled(FlexBoxY)`
  .anticon {
    font-size: 10px;
    &:not(.active) {
      color: #bfbfbf;
    }
    &.active {
      color: inherit;
    }
  }
`

const SortIcon = ({ defaultRefinement, refine, sort_replica, currentRefinement }) => {
  const location = useLocation()
  const source = location.pathname.substr(1)
  const { orderStore } = useStores()

  // If we no sort_replica exists always return null
  if (!sort_replica || !defaultRefinement) {
    return null
  }

  const [currName, currDirection] = [currentRefinement?.split('__')[1], currentRefinement?.split('__')[2]]
  const isSorted = currName === sort_replica

  const constructReplica = (dir) => `${defaultRefinement}__${sort_replica}__${dir}`

  const handleSort = (direction) => {
    const replica = constructReplica(direction)
    orderStore.isSorted = true
    refine(replica)
    trackEvent(Events.TABLE_SORTING, source, {
      field: sort_replica,
      direction: direction,
    })
  }

  return (
    <Tooltip title="Sort">
      <Wrapper flexGrow={0} ml="4px">
        <SortAscButton onClick={handleSort} isActive={currDirection === 'asc' && isSorted} />
        <SortDescButton onClick={handleSort} isActive={currDirection === 'desc' && isSorted} />
      </Wrapper>
    </Tooltip>
  )
}

export default connectSortBy(SortIcon)
