import React from 'react'

import styled from '@emotion/styled'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Typography, Popover, PopoverProps } from 'antd'

import { Box } from 'common/components/boxes'

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 58px;
  white-space: nowrap;

  .anticon {
    margin-left: 4px;
    color: ${({ theme }) => theme.colors.error};
  }
`

type CalculatedLabelProps = {
  label: string
  value: string
  showError: boolean
  errorMessage: React.ReactNode
} & Pick<PopoverProps, 'placement'>

export const CalculatedLabel = ({ label, value, showError, errorMessage, placement }: CalculatedLabelProps) => {
  return (
    <Wrapper>
      <Typography.Text style={{ marginBottom: 8 }}>
        {label}
        {showError && (
          <Popover content={errorMessage} placement={placement} arrowPointAtCenter>
            <ExclamationCircleOutlined />
          </Popover>
        )}
      </Typography.Text>
      <Typography.Text underline strong>
        {value}
      </Typography.Text>
    </Wrapper>
  )
}
