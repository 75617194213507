import React from 'react'

import { Button, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { DateRangePicker } from 'common/components/DateRangePicker'
import { SelectFilter } from 'common/components/table/postgres_filters'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { useStores } from 'contractor/hooks/use-stores'

export const InvoiceFilters = () => {
  const { invoiceStore } = useStores()
  const { handleChangeFilters, handleClearFilters } = usePersistentFilters()

  const { listStore } = invoiceStore

  const companyVendorNameOptions = (listStore.facets['company_vendor_names'] || []).map((x) => ({
    label: x,
    value: x,
  }))
  const discardedByCompanyUserNamesOptions = (listStore.facets['discarded_by_company_user_names'] || []).map((x) => ({
    label: x,
    value: x,
  }))
  const discardReasonsOptions = (listStore.facets['discard_reasons'] || []).map((x) => ({ label: x, value: x }))

  const appliedFiltersAndCounters = Object.keys(listStore.searchState.filters).filter(
    (filter) => !['state_name', 'project_id'].includes(filter),
  ).length

  return (
    <Box display="flex" flexDirection="column" p="12" gridGap={16}>
      <Box width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Vendor</Typography.Text>

        <SelectFilter
          items={companyVendorNameOptions}
          selected={listStore.searchState.filters['company_vendor_name']}
          onChange={(value) => handleChangeFilters({ company_vendor_name: value })}
          attribute="company_vendor_name"
          placeholder="Select by vendor"
          isSearchable
        />
      </Box>

      <Box width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Deleted By</Typography.Text>

        <SelectFilter
          items={discardedByCompanyUserNamesOptions}
          selected={listStore.searchState.filters['discarded_by_company_user_name']}
          onChange={(value) => handleChangeFilters({ discarded_by_company_user_name: value })}
          attribute="discarded_by_company_user_name"
          placeholder="Select by user"
          isSearchable
        />
      </Box>

      <Box width="100%" display="flex" flexDirection="column">
        <Box mb={16} width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Delete Reasons</Typography.Text>

          <SelectFilter
            items={discardReasonsOptions}
            selected={listStore.searchState.filters['discard_reason']}
            onChange={(value) => handleChangeFilters({ discard_reason: value })}
            attribute="discard_reason"
            placeholder="Select by delete reason"
            isSearchable
          />
        </Box>

        <Box width="100%" mb={16}>
          <Typography.Text type="secondary">Deleted At</Typography.Text>

          <DateRangePicker
            placeholderStart="Start date"
            placeholderEnd="End date"
            value={{
              startDate: listStore.searchState.filters['discarded_at_start'],
              endDate: listStore.searchState.filters['discarded_at_end'],
            }}
            onChange={({ startDate, endDate }) => {
              handleChangeFilters({
                discarded_at_start: startDate,
                discarded_at_end: endDate,
              })
            }}
          />
        </Box>

        <Box width="100%" mb={16}>
          <Typography.Text type="secondary">Created At</Typography.Text>

          <DateRangePicker
            placeholderStart="Start date"
            placeholderEnd="End date"
            value={{
              startDate: listStore.searchState.filters['created_at_start'],
              endDate: listStore.searchState.filters['created_at_end'],
            }}
            onChange={({ startDate, endDate }) => {
              handleChangeFilters({
                created_at_start: startDate,
                created_at_end: endDate,
              })
            }}
          />
        </Box>

        <Button
          style={{ marginTop: 16, width: '100%' }}
          type="primary"
          data-cy="clear-filters"
          onClick={() => handleClearFilters()}
          disabled={appliedFiltersAndCounters === 0}
        >
          Clear All
        </Button>
      </Box>
    </Box>
  )
}
