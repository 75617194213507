import React from 'react'

import moment from 'moment/moment'

import { Typography } from 'antd'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'

type TitleProps = {
  orderType: OrderType
  isRequest: boolean
  requestedBy: string
  requestedAt: string
  isApproved?: boolean
  approvedBy?: string
  approvedAt?: string
  isDeleted?: boolean
  deletedAt?: string
}

export const HeaderTitle = (props: TitleProps) => {
  const { orderType, isRequest, requestedBy, requestedAt, isDeleted, deletedAt, approvedAt, isApproved, approvedBy } =
    props
  const type = orderType === 'RFQ' ? 'RFQ' : 'Order'
  if (requestedBy && approvedBy) {
    return (
      <FlexBoxX width="100%" justifyContent="flex-start">
        <Typography.Title level={4}>Material Request</Typography.Title>
        <FlexBoxY>
          <Typography.Title level={5} style={{ marginTop: 0, marginLeft: 8, whiteSpace: 'nowrap' }} type="secondary">
            Requested by: {requestedBy} and Approved by: {approvedBy} on {moment(approvedAt).format('llll')}
          </Typography.Title>
        </FlexBoxY>
      </FlexBoxX>
    )
  }

  if (isDeleted) {
    return (
      <FlexBoxX justifyContent="flex-start">
        <Typography.Title level={4}>Deleted {requestedAt ? 'Request' : 'Draft'} </Typography.Title>
        <Typography.Title level={5} style={{ marginTop: 0, marginLeft: 8 }} type="secondary">
          Deleted at {moment(deletedAt).format('llll')}
        </Typography.Title>
      </FlexBoxX>
    )
  }

  if (isRequest) {
    return (
      <FlexBoxX justifyContent="flex-start">
        <Typography.Title level={4}>Material Request</Typography.Title>
        <Typography.Title level={5} style={{ marginTop: 0, marginLeft: 8 }} type="secondary">
          Requested by: {requestedBy} on {moment(requestedAt).format('llll')}
        </Typography.Title>
      </FlexBoxX>
    )
  }

  if (isApproved) {
    return (
      <FlexBoxX justifyContent="flex-start">
        <Typography.Title level={4}>{requestedAt ? 'Material Request' : `Draft ${type}`}</Typography.Title>
        <Typography.Title level={5} style={{ marginTop: 0, marginLeft: 8 }} type="secondary">
          Approved by: {approvedBy} on {moment(approvedAt).format('llll')}
        </Typography.Title>
      </FlexBoxX>
    )
  }

  return <Typography.Title level={4}>New {type}</Typography.Title>
}
