import React, { useRef } from 'react'

import { EditOutlined } from '@ant-design/icons'
import ProjectIcon from '@ant-design/icons/ProjectOutlined'
import { Typography, Tooltip, Alert } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import theme from 'common/styles/theme'

import { SelectProject } from 'contractor/components/SelectProject'
import { useStores } from 'contractor/hooks/use-stores'

type ProjectsDrawerProps = {
  disabled?: boolean
}

export const ProjectsDrawer = observer((props: ProjectsDrawerProps) => {
  const { disabled } = props
  const { orderStore, projectStore, companySettingStore } = useStores()

  const drawerRef = useRef<DrawerRef>()

  const { project_id, project } = orderStore.selectedOrder

  const handleChangeProject = (projectId) => {
    const foundProject = projectStore?.projects?.find((project) => project.id === projectId)
    orderStore.updateSelectedOrder('project_id', projectId)
    orderStore.updateSelectedOrder('project', foundProject)
  }

  const isProjectFilteringEnabled = companySettingStore.otherSettings?.cost_code_settings?.project_filtering_enabled
  const isProjectSpecificMaterialEnabled =
    companySettingStore.companyMaterialConfiguration?.company_attributes.includes('project_ids')

  return (
    <>
      <Tooltip title="Project" placement="bottom">
        <Box
          display="inline-flex"
          alignItems="center"
          onClick={() => {
            if (disabled) return
            drawerRef.current?.show()
          }}
          py={2}
          width="100%"
          height="30px"
          justifyContent="flex-start"
          style={{ cursor: 'pointer' }}
        >
          <ProjectIcon />
          <Typography.Text
            style={{ margin: '0 4px', fontSize: 12, color: theme.colors['gray-8'] }}
            ellipsis={{ tooltip: project?.name }}
          >
            {project?.project_id && `${project?.project_id}: `}
            {project?.name}
          </Typography.Text>
          {!disabled && <EditOutlined style={{ color: theme.colors.link }} />}
        </Box>
      </Tooltip>

      <Drawer title="Edit Project" ref={drawerRef} width={378}>
        <Box width="100%" p={16}>
          <SelectProject disabled={orderStore.isSplitting} onChange={handleChangeProject} value={project_id} />

          {(isProjectFilteringEnabled || isProjectSpecificMaterialEnabled) && (
            <Alert
              message="Changing the project may conflict with materials and/or cost codes that restrict access to specific projects."
              type="warning"
              style={{ marginTop: 20 }}
            />
          )}
        </Box>
      </Drawer>
    </>
  )
})
