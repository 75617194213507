import React, { useCallback, useEffect, useState } from 'react'

import { Skeleton } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { DebounceSelect, DebounceSelectProps } from 'common/components/DebounceSelect'
import { CostCodePhase } from 'common/server/cost_codes/cost_code_phases'

import { useStores } from 'contractor/hooks/use-stores'

import { ApplyToAllEmptyPhaseCodes } from './apply_to_all_empty_phase_codes'
import { makeCostCodePhaseOptionAdvanced } from './helpers'
import { useQueryCostCodePhases } from './hooks'

interface OnChangeApplyToAllProps {
  path: string
  value: string
  index?: number
}

type Option = { value: string; label: React.ReactNode; originalObject?: CostCodePhase }

export type SelectCostCodePhaseAdvancedProps = Partial<Omit<DebounceSelectProps, 'onChange' | 'onBlur'>> & {
  projectId?: string
  projectSpecificPhaseCodesEnabled?: boolean
  onChange?: (option: Nullable<Option>, shouldPropagateValues?: boolean) => void
  onBlur?: (event: React.FocusEvent, applyToAllChecked: boolean) => void
  onChangeApplyToAll?: (props: OnChangeApplyToAllProps) => void
  defaultApplyToAllChecked?: boolean
  initialCostCodePhases?: CostCodePhase[]
}

/*
 Use this component in a list of materials with cost code phase, like: order materials, invoice materials, commitment materials, etc.
*/
export const SelectCostCodePhaseAdvanced = observer<SelectCostCodePhaseAdvancedProps>((props) => {
  const {
    projectId,
    projectSpecificPhaseCodesEnabled,
    defaultApplyToAllChecked,
    onChangeApplyToAll,
    onBlur,
    onChange,
    initialCostCodePhases = [],
    ...rest
  } = props

  const { costCodeStore } = useStores()
  const { isLoading, data = [] } = useQueryCostCodePhases({ projectId, enabled: !initialCostCodePhases.length })

  const [applyToAllChecked, setApplyToAllChecked] = useState(defaultApplyToAllChecked)

  const { costCodePhaseListStore } = costCodeStore

  const fetchOptions = useCallback(
    (search: string) => {
      if (projectId) {
        costCodePhaseListStore.setFilter('project_id', projectId, true, true)
      }
      costCodePhaseListStore.setFilter('active', true, true, true)
      return costCodePhaseListStore.setSearch(search).then((data) => toJS(data).map(makeCostCodePhaseOptionAdvanced))
    },
    [projectId],
  )

  const handleBlur = useCallback(
    (event: React.FocusEvent) => onBlur?.(event, applyToAllChecked),
    [onBlur, applyToAllChecked],
  )

  const handleChangeApplyForAllEmptyPhaseCodes = (e: CheckboxChangeEvent) => {
    const shouldApplyForAllEmptyCostCodes = e.target.checked

    // it should apply only when is in the first step and the value is not empty
    // otherwhise, it'll trigger a re-render
    // when we don't apply here, it'll be applied when the customer finishes the selection
    if (!!rest?.value && shouldApplyForAllEmptyCostCodes) {
      onChangeApplyToAll({
        path: 'cost_code_phase',
        value: rest?.value?.originalObject,
      })
    }

    setApplyToAllChecked(shouldApplyForAllEmptyCostCodes)
  }

  useEffect(() => {
    if (applyToAllChecked !== defaultApplyToAllChecked) {
      setApplyToAllChecked(defaultApplyToAllChecked)
    }

    return () => setApplyToAllChecked(false)
  }, [defaultApplyToAllChecked])

  if (isLoading) {
    return <Skeleton.Input block active />
  }

  const projectRequired = projectSpecificPhaseCodesEnabled && !projectId

  return (
    <DebounceSelect
      allowClear
      labelInValue
      placeholder="Select Phase"
      loading={isLoading}
      // @ts-ignore
      initialOptions={toJS(data || initialCostCodePhases)?.map(makeCostCodePhaseOptionAdvanced)}
      fetchOptions={fetchOptions}
      dropdownMatchSelectWidth={250}
      {...rest}
      onBlur={handleBlur}
      onSelect={(_, option: Option) => {
        onChange(option, applyToAllChecked)
        costCodePhaseListStore.searchState.search = ''
      }}
      onClear={() => {
        onChange(null, applyToAllChecked)
        costCodePhaseListStore.searchState.search = ''
      }}
      style={{ width: '100%', ...rest?.style }}
      disabled={projectRequired || rest?.disabled}
      dropdownRender={(menu) => (
        <>
          {menu}
          {!!onChangeApplyToAll && (
            <ApplyToAllEmptyPhaseCodes
              onChangeApplyForAllEmptyPhaseCodes={handleChangeApplyForAllEmptyPhaseCodes}
              disabled={rest?.disabled}
              applyToAllChecked={applyToAllChecked}
            />
          )}
        </>
      )}
    />
  )
})
