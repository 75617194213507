import React, { useCallback, useRef } from 'react'

import { Form } from 'antd'
import { RefSelectProps } from 'antd/lib/select'

import { SelectCostCode, SelectCostCodeProps } from 'common/components/SelectCostCode'

import { useForm } from './row_context'

type EditableCellSelectCostCodeProps = {
  inputName: string
  onSave: (value: string, shouldPropagateValue?: boolean) => void
} & SelectCostCodeProps

export const EditableCellSelectCostCode = ({ inputName, onSave, ...props }: EditableCellSelectCostCodeProps) => {
  const selectRef = useRef<RefSelectProps>(null)
  const form = useForm()

  const save = useCallback(
    async (_, shouldPropagateValue) => {
      try {
        const values = await form.validateFields()

        onSave(values[inputName], shouldPropagateValue)
      } catch (errInfo) {
        console.log('Save failed:', errInfo)
      }
    },
    [form, onSave],
  )

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      selectRef.current?.focus()
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [selectRef.current])

  return (
    <Form.Item style={{ margin: 0 }} name={inputName}>
      <SelectCostCode
        ref={selectRef}
        onClick={(e) => e.stopPropagation()}
        onBlur={save}
        onChange={save}
        style={{ width: '100%' }}
        autoFocus
        {...props}
      />
    </Form.Item>
  )
}
