import _ from 'lodash'
import { BindAll } from 'lodash-decorators'

import { observable, action } from 'mobx'

import { Address } from 'common/server/addresses'

import { create as createAddress, update as updateAddress } from 'contractor/server/addresses'
import {
  create,
  index,
  IndexProject,
  show,
  ShowProjectResponse,
  update,
  CreateProjectRequest,
  unknownProject,
  UpdateProjectRequest,
} from 'contractor/server/projects'

@BindAll()
export default class ProjectStore {
  allProjects = observable.array<IndexProject>([])
  allInactiveProjects = observable.array<IndexProject>([])

  projects = observable.array<IndexProject>([])
  inactiveProjects = observable.array<IndexProject>([])

  @observable selectedProject: Nullable<ShowProjectResponse> = null

  async createProject(params: CreateProjectRequest): Promise<string> {
    const projectId = (await create(params)).data.id
    // Need to wait until we've selected the project, otherwise we might not be able to add the address afterwards
    await this.showProject(projectId)
    return projectId
  }

  // HACK: Kind of weird that you can get to the selected order via the URL or from session storage
  maybeRestoreSelectedProject(projectId?: string) {
    const id = projectId || sessionStorage['selectedProjectId']

    if (this.projects.some((project) => project.id === id)) {
      return this.showProject(id)
    }

    return this.showProject()
  }

  async find(id: string): Promise<ShowProjectResponse> {
    const response = await show(id)
    return response.data
  }

  @action
  async showProject(id?: Nullable<string>) {
    if (!id || id.length === 0) {
      this.selectedProject = null
      sessionStorage.setItem('selectedProjectId', '')
      return id
    }

    // Adding this comment afterwards because I'm not sure why we set the ID before receiving the response but probably convenience
    // Makes it hard though to check if the selectedProject changed...
    this.selectedProject = { id } as ShowProjectResponse
    // This causes problems if we delete a project, but working okay for now
    const response = id === unknownProject.id ? { data: unknownProject } : await show(id)
    this.selectedProject = this.mapProject(response.data)
    if (id !== unknownProject.id) sessionStorage.setItem('selectedProjectId', id)

    return id
  }

  @action
  async updateProject(project: UpdateProjectRequest): Promise<void> {
    const response = await update(project)
    this.selectedProject = response.data
  }

  async updateWatchers(id: string, default_watcher_user_ids: string[]): Promise<ShowProjectResponse> {
    const response = await update({ id, default_watcher_user_ids })
    return response.data
  }

  @action
  async addAddress(project_id: string, address: Address): Promise<void> {
    const newAddress = (await createAddress({ project_id, address })).data
    this.selectedProject.addresses.push(newAddress)
  }

  @action
  async updateAddress(address: Address): Promise<void> {
    const newAddress = (await updateAddress({ id: address.id, address })).data
    const index = _.findIndex(this.selectedProject.addresses, { id: address.id })
    this.selectedProject.addresses[index] = newAddress
  }

  @action
  async indexProjects(showAllProjects = false): Promise<void> {
    const response = await index(showAllProjects)
    this.projects.replace(response.data.projects?.map(this.mapProject) || [])
    this.inactiveProjects.replace(response.data.inactive_projects?.map(this.mapProject) || [])
  }

  @action
  async indexAllProjects() {
    const response = await index(true)
    this.allProjects.replace(response.data.projects?.map(this.mapProject) || [])
    this.allInactiveProjects.replace(response.data.inactive_projects?.map(this.mapProject) || [])
  }

  mapProject(project) {
    return {
      ...project,
      default_watcher_user_ids: project.default_watcher_user_ids || [],
    }
  }

  // Only index if we don't have any projects loaded yet
  async maybeIndexProjects(): Promise<void> {
    if (this.projects.length === 0) {
      await this.indexProjects()
    }
  }
}
