import React, { memo } from 'react'

import { ExclamationCircleOutlined, CheckCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { Input, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import theme from 'common/styles/theme'

const config = {
  match: {
    icon: <CheckCircleOutlined style={{ color: theme.colors['success'] }} />,
    label: 'Match',
  },
  partialMatch: {
    icon: <WarningOutlined style={{ color: theme.colors['warning'] }} />,
    label: 'Partial match',
  },
  noMatch: {
    icon: <ExclamationCircleOutlined style={{ color: theme.colors['error'] }} />,
    label: 'No match',
  },
} as const

export type ConfigKeys = keyof typeof config

type MatchConfidenceProps = {
  type: ConfigKeys
}

export const MatchConfidence = memo<MatchConfidenceProps>(({ type }) => {
  const { icon, label } = config[type] || config.noMatch

  return (
    <Box display="flex" flexDirection="column" width="100%" mb={24}>
      <Typography.Text style={{ paddingBottom: 4 }}>Match confidence</Typography.Text>
      <Input prefix={icon} disabled value={label} />
    </Box>
  )
})
