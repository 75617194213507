import React from 'react'

import { FlexBoxY } from 'common/components/boxes'
import { ChangedItem, ChangeType } from 'common/components/History'
import { EventChange } from 'common/server/order_history'

const FILE_FIELDS_CONFIG = [
  {
    key: 'Blob',
    type: 'file',
  },
  {
    key: 'Discarded at',
    type: 'file',
  },
]
const ALLOW_FILE_FIELDS = FILE_FIELDS_CONFIG.map((item) => item.key)

type VendorFileProps = {
  vendorFile: EventChange
}

export const VendorFile = ({ vendorFile }: VendorFileProps) => {
  const keys = Object.keys(vendorFile.changes).filter((key) => ALLOW_FILE_FIELDS.includes(key))

  if (!keys.length) {
    return null
  }

  return (
    <FlexBoxY alignItems="flex-start" width="100%">
      {keys.map((key, index) => {
        let changes = vendorFile.changes[key]

        if (key === 'Discarded at') {
          changes = [vendorFile?.snapshot?.FileName, null]
        }

        return (
          <FlexBoxY alignItems="flex-start" width="100%" key={`${key}-${index}`}>
            <ChangedItem
              href={vendorFile?.snapshot?.FilePath}
              label="Vendor File"
              changes={changes}
              isCreate={vendorFile.event === 'create'}
              type={FILE_FIELDS_CONFIG.find((item) => item.key === key)?.type as ChangeType}
            />
          </FlexBoxY>
        )
      })}
    </FlexBoxY>
  )
}
