import React from 'react'

import { FlexBoxX } from 'common/components/boxes'
import { formatDateStringShort } from 'common/helpers/formatters'

export const DateViewerCell = ({ cell }) => {
  return (
    <FlexBoxX alignItems="flex-start" justifyContent="space-between">
      {formatDateStringShort(cell.value)}
    </FlexBoxX>
  )
}
