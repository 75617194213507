import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'

import { RequestQuote } from '@styled-icons/material'
import { orderBy } from 'lodash'

import { Dropdown, Typography, Badge } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import OrderState from 'common/components/statuses/order_state'

import { useStores } from 'contractor/hooks/use-stores'

export const LevelingPopover = observer(() => {
  const { orderStore, orderPackageStore } = useStores()

  const orderPackageId = orderStore.selectedOrder?.order_package_id

  useEffect(() => {
    orderPackageStore.orderPackage = null
    orderPackageStore.getOrderPackage(orderPackageId)
  }, [orderPackageId])

  const orders = orderPackageStore.orderPackage?.orders || []

  const orderedOrders = orderBy(orders, 'vendor.vendor_name')

  if (orders.length <= 1) {
    return null
  }

  const ordersItems = orderedOrders.map((order) => {
    const isCurrentOrder = orderStore.selectedOrder?.id === order.id
    const safeGlobalVendorName = order.company_vendor?.vendor?.name || order.company_vendor?.vendor_name

    return {
      label: (
        <Link to={`/order/${order.id}`} target="_blank">
          <Box display="flex" justifyContent="space-between">
            {isCurrentOrder ? <Badge status="success" text={safeGlobalVendorName} /> : safeGlobalVendorName}
            <Box ml={12}>
              <OrderState state={order.state} sub_state={order.sub_state} />
            </Box>
          </Box>
        </Link>
      ),
      key: order.id,
    }
  })

  return (
    <Dropdown
      placement="bottom"
      menu={{
        items: [
          ...ordersItems,
          { type: 'divider' },
          {
            label: (
              <Link to={`/order_package/${orderPackageId}`} target="_blank" style={{ textAlign: 'center' }}>
                Open RFQ Leveling
              </Link>
            ),
            key: 'leveling',
          },
        ],
      }}
      overlayStyle={{ maxWidth: 300 }}
    >
      <Box
        display="inline-flex"
        alignItems="center"
        border="1px solid"
        borderColor="gray-4"
        borderRadius="md"
        px={8}
        py={2}
        width="100%"
        height="30px"
        justifyContent="center"
        style={{ cursor: 'pointer' }}
      >
        <RequestQuote style={{ width: 20, marginRight: 5 }} />
        <Typography.Text style={{ whiteSpace: 'nowrap' }}>{`Leveling (${orders.length})`}</Typography.Text>
      </Box>
    </Dropdown>
  )
})
