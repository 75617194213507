import React from 'react'

import styled from '@emotion/styled'

import { LoadingOutlined } from '@ant-design/icons'
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'
import { Form, Tooltip, Badge } from 'antd'
import { FormItemProps } from 'antd/lib/form'

import { observer } from 'mobx-react-lite'

import { FlexBoxX } from 'common/components/boxes'
import { useQuery } from 'common/hooks/use-query'

import { SelectProject, SelectProjectProps } from 'contractor/components/SelectProject'
import { useStores } from 'contractor/hooks/use-stores'

const FormItemWithIcon = styled(Form.Item)`
  .ant-form-item-label {
    overflow: unset;
  }
`

type FieldProjectEspecificProps = {
  formItemProps?: FormItemProps
  inputProps?: SelectProjectProps
}

export const FieldProjectEspecific = observer<FieldProjectEspecificProps>(({ formItemProps, inputProps }) => {
  const { projectStore } = useStores()
  const { isLoading } = useQuery(projectStore.indexProjects)

  if (isLoading) {
    return (
      <FlexBoxX>
        <LoadingOutlined />
      </FlexBoxX>
    )
  }

  return (
    <FormItemWithIcon
      label={
        <Badge
          count={
            <Tooltip
              placement="right"
              title="Only orders made specifically for these projects will be able to select this material"
            >
              <QuestionCircleOutlined style={{ right: -5 }} />
            </Tooltip>
          }
        >
          <span>Projects</span>
        </Badge>
      }
      name="project_ids"
      style={{ width: '100%' }}
      {...formItemProps}
    >
      <SelectProject {...inputProps} mode={'multiple'} />
    </FormItemWithIcon>
  )
})
