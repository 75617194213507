import axios from 'axios'

import { OrderMaterial } from 'common/server/orders'
import { QuoteExtractionStates } from 'common/server/server_types'

export interface Quote {
  id?: string
  total: number
  sub_total: number
  tax: number
  additional_charges: number
  shipping_cost: number
  status: QuoteExtractionStates
  order_id: string
  failure_reason?: string
  num_line_items_reconcilied?: number
  num_line_items_extracted?: number
  num_order_materials?: number
  unquoted_materials?: string[]
  unmatched_materials?: UnmatchedMaterial[]
  automatch_options: AutoMatchOptions[]
}

export interface AutoMatchOptions {
  order_material: OrderMaterial
  suggested_unit_cost: number
  quantity: number
  reconcile_status?: string
  unit_name: string
  ext_cost?: number
}

export interface UnmatchedMaterial {
  id: string
  description: string
  unit_name: string
  quantity: number
  unit_price: number
}

export interface Automatch {
  order_id: string
  quote_id: string
  materials: AutoMatchOptions[]
}

export interface QuoteAdditionalCharge {
  subTotal?: number
  tax?: number
  shipping?: number
  discount?: number
}

export function getQuote(orderId: string, quoteId: string) {
  let url = '/quotes'

  url += `?order_id=${orderId}`

  url += `&quote_id=${quoteId}`

  return axios.get<Quote>(url)
}

export function reportQuoteIssue(quoteId: string, issue: string, orderId: string) {
  return axios.post(`/quotes/report_issue`, { issue: issue, quote_id: quoteId, order_id: orderId })
}
