import React from 'react'

import { RobotOutlined } from '@ant-design/icons'
import { Typography, Button, Space } from 'antd'

import { FlexBoxY } from 'common/components/boxes'

export const EmptyVendors = ({ onCreate, onFind, ...props }) => {
  return (
    <FlexBoxY flexGrow={0} mx="auto" {...props}>
      <RobotOutlined style={{ fontSize: 60 }} />
      <Typography.Text type="secondary" strong style={{ margin: '16px 0', fontSize: 16 }}>
        You have no vendors, create or find one on the SubBase Network
      </Typography.Text>
      <Space>
        <Button type="primary" onClick={onCreate}>
          Create Vendor
        </Button>
        <Button type="primary" onClick={onFind}>
          SubBase Network
        </Button>
      </Space>
    </FlexBoxY>
  )
}
