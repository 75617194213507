import { OrderStates } from 'common/server/server_types'

const constants = {
  statesToShowIntegration: [
    OrderStates.ORDERED,
    OrderStates.PARTIALLY_SHIPPED,
    OrderStates.SHIPPED,
    OrderStates.DELIVERED,
  ],
}

export default constants
