import React from 'react'

import { Button, Space, Divider } from 'antd'

import { Box } from 'common/components/boxes'
import { Visibility } from 'common/components/Visibility'

export const SubmitActions = ({ onCancel, onSubmit, isSubmitting, hideShowSaveAndAddAnotherButton }) => {
  return (
    <Box>
      <Divider />

      <Box display="flex" justifyContent="space-between" flexGrow={0}>
        <Button onClick={onCancel} loading={isSubmitting} style={{ width: 100 }}>
          Cancel
        </Button>
        <Space size="middle">
          <Visibility.Hidden>
            {!hideShowSaveAndAddAnotherButton && (
              <Button type="primary" ghost onClick={() => onSubmit(true)} loading={isSubmitting}>
                Save and Add Another
              </Button>
            )}
          </Visibility.Hidden>

          <Button type="primary" onClick={() => onSubmit()} loading={isSubmitting} style={{ width: 100 }}>
            Save
          </Button>
        </Space>
      </Box>
    </Box>
  )
}
