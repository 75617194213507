import React from 'react'

import styled from '@emotion/styled'

import { Divider, Typography } from 'antd'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { MaterialItemTags } from 'common/components/MaterialItemTags'

import { DataSource } from '../order_materials'

const WrapperAttributes = styled(FlexBoxX)`
  & > div:last-child .ant-divider {
    display: none;
  }
`
const Note = styled(Typography.Text)`
  width: 100%;
  textarea.ant-input {
    resize: none;
    margin-top: 4px;
    min-height: 28px !important;
    height: 28px !important;
    line-height: 18px;
  }

  &.ant-typography-edit-content {
    margin: 0;
    left: 0;
  }
`

const ItemValue = ({ label = '', value }) => {
  if (!value) {
    return null
  }

  return (
    <FlexBoxX justifyContent="flex-start" flexGrow={0}>
      {label && (
        <Typography.Text type="secondary" style={{ marginRight: 4, fontSize: 12 }}>
          {label}:
        </Typography.Text>
      )}

      <Typography.Text style={{ fontSize: 12, color: '#000000' }}>{value}</Typography.Text>

      <Divider type="vertical" />
    </FlexBoxX>
  )
}

type MaterialColumnProps = {
  description: string
  disabled?: boolean
  item: DataSource['company_material']
  onChangCompanyNote?: (value: string) => void
  companyNote?: string
  deliveryIssue?: boolean
}

const label = 'Add note'

export const MaterialColumn = ({
  description,
  item,
  onChangCompanyNote,
  companyNote,
  disabled,
  deliveryIssue,
}: MaterialColumnProps) => {
  const handleChangeNote = (value = '') => {
    const note = value.trim()
    if (note !== companyNote) {
      onChangCompanyNote?.(note)
    }
  }

  return (
    <FlexBoxY alignItems="flex-start" minWidth="265px">
      <Typography.Text style={{ color: deliveryIssue ? 'red' : '#000000' }}>{description}</Typography.Text>
      <WrapperAttributes justifyContent="flex-start" flexWrap="wrap">
        <ItemValue value={item?.['manufacturer'] || item?.['manufacturer_name']} />
        <ItemValue label="ID" value={item?.['product_identifier']} />
        <ItemValue label="SIZE" value={item?.['size']} />
        <ItemValue label="MATERIAL" value={item?.['material']} />
      </WrapperAttributes>

      <MaterialItemTags label="TAGS" value={item?.['tags']} />

      {(!!onChangCompanyNote || !!companyNote) && (
        <Note
          type="secondary"
          disabled={disabled}
          editable={!disabled && { onChange: handleChangeNote, text: companyNote || '' }}
        >
          {companyNote || label}
        </Note>
      )}
    </FlexBoxY>
  )
}
