import React, { useEffect } from 'react'

import { Collapse, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX } from 'common/components/boxes'
import Uploader from 'common/components/uploader'
import { OrderDelivery } from 'common/server/deliveries'
import { OrderMaterial as IOrderMaterial } from 'common/server/orders'

import { useStores } from 'contractor/hooks/use-stores'

import { DeliveryHeader } from './delivery_header'
import { DeliveryInfo } from './delivery_info'
import { OrderMaterial } from './order_material'

type DeliveryListProps = {
  deliveries: OrderDelivery[]
  orderMaterials: { [deliveryId: string]: IOrderMaterial[] }
  projectIds: string[]
}

export const DeliveryList = observer<DeliveryListProps>((props) => {
  const { deliveries, orderMaterials, projectIds } = props
  const { uploaderStore, orderStore, companySettingStore } = useStores()

  useEffect(() => {
    deliveries.forEach((delivery) => {
      uploaderStore.addExistingFiles(`delivery_files:${delivery.id}`, delivery.delivery_files)
    })

    return () => {
      deliveries.forEach((delivery) => {
        uploaderStore.resetUploads(`delivery_files:${delivery.id}`)
      })
    }
  }, [deliveries])

  return (
    <FlexBoxX width="100%">
      <Collapse defaultActiveKey={deliveries.map((d) => `${d.id}-key`)} style={{ width: '100%' }}>
        {deliveries.map((delivery, index) => (
          <Collapse.Panel
            header={<DeliveryHeader delivery={delivery} index={index + 1} projectIds={projectIds} />}
            key={`${delivery.id}-key`}
          >
            <DeliveryInfo delivery={delivery} />

            <OrderMaterial
              orderMaterials={orderMaterials[delivery.id]}
              discountValue={delivery.discount_value}
              shippingValue={delivery.shipping_value}
              otherValue={delivery.other_value}
              taxValue={delivery.tax_value}
              hasOpenIssue={delivery.has_open_issue}
              deliveryTotalCost={orderStore.deliveryTotalCost({
                deliveryId: delivery.id,
                deliveries,
                materials: orderMaterials[delivery.id],
                precision: companySettingStore.otherSettings?.rounding_precision_settings?.order_precision,
              })}
            />

            {!!uploaderStore.fileList(`delivery_files:${delivery.id}`).length && (
              <>
                <Typography.Paragraph style={{ marginTop: '1em' }} strong>
                  Delivery files
                </Typography.Paragraph>
                <Uploader
                  disabled
                  hideUploadButton
                  component="Upload"
                  multiple
                  uploadKey={`delivery_files:${delivery.id}`}
                  listType="picture-card"
                  noResetUploads
                  showPreviewModal
                  showDownload
                  fileList={uploaderStore.fileList(`delivery_files:${delivery.id}`)}
                  onRemoveUpload={uploaderStore.removeUpload}
                  onAddNewUpload={uploaderStore.addNewUpload}
                  onResetUploads={uploaderStore.resetUploads}
                  onSetUploadError={uploaderStore.setUploadError}
                  onUpdateUpload={uploaderStore.updateUpload}
                />
              </>
            )}
          </Collapse.Panel>
        ))}
      </Collapse>
    </FlexBoxX>
  )
})
