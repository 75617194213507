import React, { useEffect, useState } from 'react'

import { v4 as uuidV4 } from 'uuid'

import { DeleteOutlined } from '@ant-design/icons'
import { Button, Form, Popconfirm, Typography, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { InputCurrency } from 'common/components/InputCurrency'
import { getSpreadValueByDot, currencyFormatter as currencyFormatterDefault } from 'common/helpers/formatters'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'
import { isDifferenceGreaterThanThreshold } from '../helpers'
import { getInvoiceMaterialsColumns, InvoiceMaterials } from '../InvoiceMaterials'
import { CalculatedLabel } from './calculated_label'
import { DeleteHeader } from './delete_header'
import { TaxInput } from './tax_input'

export const ExtractedData = observer(() => {
  const { invoiceStore } = useStores()

  const useInvoiceExtractedValuesFlag = useFlag('use_invoice_extracted_values')

  const { form, setSelectedInvoiceDirty, calculatedGrandTotal, calculatedSubtotal, currencyFormatter, lockInvoice } =
    useInvoice()

  const [deletingInBulk, toggleDeletingInBulk] = useState(false)
  const [invoiceMaterialIdsToDelete, setInvoiceMaterialIdsToDelete] = useState([])

  const invoice = invoiceStore.invoice

  const handleAddItem = () => {
    invoiceStore.updateSelectedInvoice('invoice_materials', [
      ...invoice?.invoice_materials,
      {
        id: `new-item-${uuidV4()}`,
        description: '',
        quantity_shipped: 0,
        uom: '',
        unit_price: 0,
        extended_price: 0,
        key: uuidV4(),
        invoice_id: invoice?.id,
        accepts_tax_split: true,
      },
    ])
    setSelectedInvoiceDirty(true)
  }

  const handleRemoveItem = (tableIndex) => {
    invoiceStore.updateSelectedInvoice(
      'invoice_materials',
      invoice?.invoice_materials?.filter((_, index) => index !== tableIndex),
    )
    setSelectedInvoiceDirty(true)
  }

  const handleRemoveItems = () => {
    invoiceStore.updateSelectedInvoice(
      'invoice_materials',
      invoice?.invoice_materials?.filter((invoiceMaterial) => !invoiceMaterialIdsToDelete.includes(invoiceMaterial.id)),
    )
    toggleDeletingInBulk(false)
    setInvoiceMaterialIdsToDelete([])
    setSelectedInvoiceDirty(true)
  }

  const handleSelectAll = (checked) => {
    if (checked) {
      setInvoiceMaterialIdsToDelete(invoice?.invoice_materials?.map((material) => material.id) || [])
    } else {
      setInvoiceMaterialIdsToDelete([])
    }
  }

  const columns = [
    ...getInvoiceMaterialsColumns().filter(
      (column) =>
        !['action', 'cost_code', 'tax', 'ext_cost_with_tax', 'cost_code_phase', 'retainage'].includes(column.dataIndex),
    ),
    {
      title: invoice?.invoice_materials?.length ? (
        <Tooltip title="Select materials to delete.">
          <Button icon={<DeleteOutlined />} size="small" type="dashed" onClick={() => toggleDeletingInBulk(true)} />
        </Tooltip>
      ) : undefined,
      dataIndex: 'remove',
      render: (_value, _row, index) => {
        if (deletingInBulk) {
          return null
        }
        return (
          <Popconfirm title="Are you sure to remove this item?" onConfirm={() => handleRemoveItem(index)}>
            <Button icon={<DeleteOutlined />} size="small" type="dashed" />
          </Popconfirm>
        )
      },
      width: 36,
    },
  ]

  useEffect(() => {
    form.setFieldsValue({
      taxAmount: invoice?.tax_amount,
      discountAmount: invoice?.discount_amount,
      shippingCost: invoice?.shipping_cost,
      otherCosts: invoice?.other_costs,
    })
  }, [invoice?.tax_amount, invoice?.discount_amount, invoice?.shipping_cost, invoice?.other_costs])

  const extractedSubtotal = Number(invoice?.extracted_subtotal)
  const extractedGrandTotal = Number(invoice?.extracted_invoice_amount)

  const extractedSubtotalFormatted = currencyFormatter(extractedSubtotal)
  const extractedGrandTotalFormatted = currencyFormatter(extractedGrandTotal)

  const { fractional: fractionalSubtotal } = getSpreadValueByDot(extractedSubtotal?.toString())
  const { fractional: fractionalGrandTotal } = getSpreadValueByDot(extractedGrandTotal?.toString())

  // When the flag is enabled use the extracted values to get the decimal places to format the calculated values
  const calculatedSubtotalFormatted = useInvoiceExtractedValuesFlag
    ? currencyFormatterDefault(calculatedSubtotal, fractionalSubtotal?.length, fractionalSubtotal?.length)
    : currencyFormatter(calculatedSubtotal)

  const calculatedGrandTotalFormatted = useInvoiceExtractedValuesFlag
    ? currencyFormatterDefault(calculatedGrandTotal, fractionalGrandTotal?.length, fractionalGrandTotal?.length)
    : currencyFormatter(calculatedGrandTotal)

  const deletingInBulkTableHeader = {
    header: {
      row: () => (
        <DeleteHeader
          qtyTotalItems={invoice?.invoice_materials?.length}
          colSpan={columns.length}
          onCancelDelete={() => {
            setInvoiceMaterialIdsToDelete([])
            toggleDeletingInBulk(false)
          }}
          qtyItemsToDelete={invoiceMaterialIdsToDelete.length}
          onConfirmDelete={handleRemoveItems}
          onSelectAll={handleSelectAll}
        />
      ),
      cell: () => null,
    },
  }

  const deletingInBulkTableRowSelection = {
    onChange: (selectedRowKeys) => setInvoiceMaterialIdsToDelete(selectedRowKeys),
    selectedRowKeys: invoiceMaterialIdsToDelete,
  }

  return (
    <Form
      key={invoice?.id}
      disabled={!lockInvoice.canEditInvoice}
      form={form}
      onChange={() => setSelectedInvoiceDirty(true)}
      layout="vertical"
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1, width: '100%' }}
    >
      <Box flexGrow={1} display="flex" flexDirection="column">
        <Box overflow="auto" height={0} flexGrow={1}>
          <InvoiceMaterials
            columns={columns}
            rowSelection={deletingInBulk ? deletingInBulkTableRowSelection : undefined}
            components={deletingInBulk ? deletingInBulkTableHeader : undefined}
          />
          <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end" mt={12}>
            <Button disabled={!lockInvoice.canEditInvoice} onClick={() => handleAddItem()} type="link">
              Add Item
            </Button>
          </Box>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between" style={{ gap: 16 }} mt={12}>
        <CalculatedLabel
          label="Subtotal"
          value={calculatedSubtotalFormatted}
          errorMessage={
            <Typography.Text style={{ width: 250, display: 'block' }}>
              When we attempted to extract the subtotal directly from the invoice we found{' '}
              <Typography.Text strong underline>
                {extractedSubtotalFormatted}
              </Typography.Text>{' '}
              which does not match the calculated value. Please double check that everything is correct.
            </Typography.Text>
          }
          showError={
            useInvoiceExtractedValuesFlag
              ? isDifferenceGreaterThanThreshold(calculatedSubtotal, extractedSubtotal)
              : calculatedSubtotalFormatted !== extractedSubtotalFormatted
          }
          placement="topLeft"
        />

        <Form.Item label="Tax" name="taxAmount" style={{ flexGrow: 1, margin: 0 }}>
          <TaxInput tabIndex={51} disabled={!lockInvoice.canEditInvoice} />
        </Form.Item>
        <Form.Item label="Shipping" name="shippingCost" style={{ flexGrow: 1, margin: 0 }}>
          <InputCurrency tabIndex={52} disabled={!lockInvoice.canEditInvoice} />
        </Form.Item>
        <Form.Item label="Other costs" name="otherCosts" style={{ flexGrow: 1, margin: 0 }}>
          <InputCurrency tabIndex={53} disabled={!lockInvoice.canEditInvoice} />
        </Form.Item>
        <Form.Item label="Discount" name="discountAmount" style={{ flexGrow: 1, margin: 0 }}>
          <InputCurrency tabIndex={54} disabled={!lockInvoice.canEditInvoice} />
        </Form.Item>

        <CalculatedLabel
          label="Grand Total"
          value={calculatedGrandTotalFormatted}
          errorMessage={
            <Typography.Text style={{ width: 250, display: 'block' }}>
              When we attempted to extract the grand total directly from the invoice we found{' '}
              <Typography.Text strong underline>
                {extractedGrandTotalFormatted}
              </Typography.Text>{' '}
              which does not match the calculated value. Please double check that everything is correct.
            </Typography.Text>
          }
          showError={
            useInvoiceExtractedValuesFlag
              ? isDifferenceGreaterThanThreshold(calculatedGrandTotal, extractedGrandTotal)
              : calculatedGrandTotalFormatted !== extractedGrandTotalFormatted
          }
          placement="topRight"
        />
      </Box>
    </Form>
  )
})
