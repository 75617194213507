import React from 'react'

import { useHistory } from 'react-router-dom'

import { Dropdown } from 'antd'
import { MenuDividerType } from 'antd/lib/menu/hooks/useItems'

import { observer } from 'mobx-react-lite'

import { OrderSubStates, OrderStates } from 'common/server/server_types'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import { DownloadCsv } from 'contractor/pages/OrderDetail/Actions/download_csv'
import { DownloadCsvProcore } from 'contractor/pages/OrderDetail/Actions/download_csv_procore'
import {
  RequestCancellation,
  RequestCancellationProps,
} from 'contractor/pages/OrderDetail/Actions/request_cancellation'

export type ExtraActionsProps = RequestCancellationProps & {
  isSpreadsheetMode?: boolean
  toggleSpreadsheetMode?: () => void
}

export const ExtraActions = observer<ExtraActionsProps>(
  ({ onRequestCancellation, isSubmitting, isSpreadsheetMode, toggleSpreadsheetMode }) => {
    const { userStore, orderStore } = useStores()
    const history = useHistory()

    const isProcoreCsvDownloadEnabled = useFlag('procore_csv_download')

    const order = orderStore.selectedOrder

    const handleDuplicate = () => {
      orderStore.updateSelectedOrder('internal_comments', [])
      orderStore.updateSelectedOrder('order_number', null)
      orderStore.setDuplicateOrder()
      history.push(`/orders/new_order?duplicate`)
    }

    return (
      <Dropdown.Button
        menu={{
          items: [
            {
              label: <div onClick={toggleSpreadsheetMode}>{isSpreadsheetMode && 'Exit '}Spreadsheet Mode</div>,
              key: 'spreadsheet-mode',
            },
            { label: <DownloadCsv />, key: 'download-csv' },
            ...(isProcoreCsvDownloadEnabled ? [{ label: <DownloadCsvProcore />, key: 'procore-download-csv' }] : []),
            ...(userStore.canSendAndUpdateOrders
              ? [
                  {
                    label: <div onClick={handleDuplicate}>Duplicate Order</div>,
                    key: 'duplicate',
                  },
                ]
              : []),
            ...(order?.state === OrderStates.ORDERED && order?.sub_state === OrderSubStates.ORDERED_CONFIRMED
              ? [
                  {
                    type: 'divider' as MenuDividerType['type'],
                  },
                  {
                    label: (
                      <RequestCancellation onRequestCancellation={onRequestCancellation} isSubmitting={isSubmitting} />
                    ),
                    key: 'request-cancellation',
                  },
                ]
              : []),
          ],
        }}
        trigger={['click']}
        buttonsRender={([_, rightButton]) => [null, React.cloneElement(rightButton as React.ReactElement)]}
        data-cy="extra-options"
      />
    )
  },
)
