import axios, { AxiosResponse } from 'axios'

export interface CompanyMaterialConfiguration {
  all_attributes: string[]
  company_attributes: string[]
  required_attributes: string[]
}

export async function index(): Promise<AxiosResponse<CompanyMaterialConfiguration>> {
  return await axios.get('/company_settings/company_material_configuration')
}

export async function update(company_material_columns: string[]): Promise<AxiosResponse<CompanyMaterialConfiguration>> {
  return await axios.patch('/company_settings/company_material_configuration', { company_material_columns })
}
