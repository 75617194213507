import { useState } from 'react'

import { AxiosResponse } from 'axios'

import { message } from 'antd'

import { ValueType } from 'common/components/DebounceSelect'

import { useStores } from 'contractor/hooks/use-stores'
import { BasicRelationship, MappingSuggestion, SuggestionAction, UpdateMapping } from 'contractor/server/integrations'

type useUpdateHookProps = {
  update: (mapping: UpdateMapping) => Promise<AxiosResponse>
  reloadCallback?: (fetchRelationships: boolean) => void
}

type UpdateParams = {
  option: ValueType | ValueType[]
  entity: { id: string; external_relationships: BasicRelationship[]; mapping_suggestions?: MappingSuggestion[] }
  awaitReload?: boolean
  fetchRelationships?: boolean
  multiToMono?: boolean
}

export const useUpdateMappingsHook = ({ update, reloadCallback }: useUpdateHookProps) => {
  const [updating, setUpdating] = useState(false)
  const { integrationStore } = useStores()

  const reload = async ({ awaitReload, fetchRelationships }: { awaitReload: boolean; fetchRelationships: boolean }) => {
    if (awaitReload) {
      await reloadCallback?.(fetchRelationships)
    } else {
      reloadCallback?.(fetchRelationships)
    }
  }

  const raiseError = (error) => {
    if (error?.response?.data?.message) {
      message.error(error?.response?.data?.message)
    } else {
      message.error('Unable to save changes')
    }
  }

  const handleSuggestionsApproval = async (suggestions: MappingSuggestion[]) => {
    try {
      setUpdating(true)
      await integrationStore.approveRejectSuggestions(SuggestionAction.APPROVED, suggestions)
      message.success('Successfully approved suggestions')
      await reload({ awaitReload: false, fetchRelationships: true })
    } catch (error) {
      raiseError(error)
    } finally {
      setUpdating(false)
    }
  }

  const handleSuggestionsRejection = async (suggestions: MappingSuggestion[]) => {
    try {
      setUpdating(true)
      await integrationStore.approveRejectSuggestions(SuggestionAction.REJECTED, suggestions)
      message.success('Successfully rejected suggestions')
      await reload({ awaitReload: false, fetchRelationships: true })
    } catch (error) {
      raiseError(error)
    } finally {
      setUpdating(false)
    }
  }

  const handleUpdate = async (params: UpdateParams) => {
    const { option, entity, awaitReload, fetchRelationships, multiToMono } = params
    const currentExternalIds = entity.external_relationships.map((item) => item.external_id)
    const hasSuggestions = entity.mapping_suggestions?.length > 0
    try {
      setUpdating(true)
      if (Array.isArray(option)) {
        const values = option.map((item) => item.value)
        if (hasSuggestions) {
          const suggestions = entity.mapping_suggestions.map((item) => item.external_id)
          const valuesSize = values.length
          const suggestionsSize = suggestions.length
          if (valuesSize > suggestionsSize) {
            const addedExternalId = values.filter((id) => !suggestions.includes(id))[0]
            await update({
              id: entity.id,
              external_id: addedExternalId,
            })
          }
        } else {
          const values = option.map((item) => item.value)
          const addedExternalId = values.filter((id) => !currentExternalIds.includes(id))[0]
          const removedExternalId = currentExternalIds.filter((id) => !values.includes(id))[0]
          await update({
            id: entity.id,
            external_id: addedExternalId || removedExternalId,
          })
        }
      } else if (multiToMono) {
        const value = option?.value
        const external_id =
          entity.external_relationships.length > 0 && !value ? entity.external_relationships[0].external_id : value
        await update({
          id: entity.id,
          external_id,
        })
      } else {
        const value = option?.value
        if (currentExternalIds.length > 0 && currentExternalIds[0] !== value) {
          await update({
            id: entity.id,
            external_id: currentExternalIds[0],
          })
        }
        if (value) {
          await update({
            id: entity.id,
            external_id: value,
          })
        }
      }
      // Commenting this for now, I don't think we need it
      // if (hasSuggestions) {
      //   await integrationStore.approveRejectSuggestions(SuggestionAction.REJECTED, entity.mapping_suggestions)
      // }
      await reload({ awaitReload, fetchRelationships })
      message.success('Successfully saved changes')
    } catch (error) {
      raiseError(error)
    } finally {
      setUpdating(false)
    }
  }

  const handleDirectUpdate = async (
    internalId,
    externalId,
    suggestions,
    awaitReload = false,
    fetchRelationships = true,
  ) => {
    try {
      setUpdating(true)
      if (!internalId) {
        await integrationStore.approveRejectSuggestions(SuggestionAction.REJECTED, suggestions)
      } else {
        await update({
          id: internalId,
          external_id: externalId,
        })
        message.success('Successfully saved changes')
      }
      await reload({ awaitReload, fetchRelationships })
    } catch (error) {
      raiseError(error)
    } finally {
      setUpdating(false)
    }
  }

  return { handleUpdate, handleDirectUpdate, handleSuggestionsRejection, handleSuggestionsApproval, updating }
}
