import React from 'react'

import { useHistory } from 'react-router-dom'

import { Dropdown, message, Popconfirm } from 'antd'

import { useStores } from 'contractor/hooks/use-stores'

interface DropdownOptionsProps {
  orderPackageId: string
}

export const DropdownOptions = ({ orderPackageId }: DropdownOptionsProps) => {
  const history = useHistory()
  const { orderPackageStore } = useStores()
  const handleDelete = async () => {
    try {
      await orderPackageStore.deleteOrderPackage(orderPackageId)
      history.push('/orders')
    } catch (error) {
      console.error('Error deleting order package', error)
      message.error('Error while deleting, please try again later.')
    }
  }

  const items = [
    {
      label: (
        <Popconfirm
          title="Are you sure you want to delete the draft?"
          onConfirm={() => handleDelete()}
          okText="Yes"
          cancelText="No"
          placement="left"
        >
          <span data-cy="delete-draft">Delete Draft</span>
        </Popconfirm>
      ),
      key: '1',
    },
  ]

  return (
    <Dropdown.Button
      menu={{ items }}
      trigger={['click', 'hover']}
      buttonsRender={([_, rightButton]) => [null, React.cloneElement(rightButton as React.ReactElement)]}
      data-cy="extra-options"
    />
  )
}
