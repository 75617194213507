import React from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { Button, Input, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'

import { CompanyVendor } from '../context'

type SelectedVendorProps = {
  onRemove: (id: string) => void
  onChangeMessage: (id: string, message: string) => void
  sameMessageAllVendors: boolean
  companyVendor: CompanyVendor
  placeholder: string
  index: number
  disabled?: boolean
}

export const SelectedVendor = observer<SelectedVendorProps>(
  ({ onRemove, onChangeMessage, sameMessageAllVendors, companyVendor, placeholder, index, disabled }) => {
    const { userStore, orderStore } = useStores()

    const selectIsDisabled =
      orderStore.isSplitting || (userStore.isMaterialRequester && !userStore.canSendAndUpdateOrders) || disabled

    return (
      <FlexBoxY alignItems="flex-start" width="100%">
        <FlexBoxX justifyContent="space-between" mb={12} width="100%">
          {/* Need to set the max width otherwise if this runs over will move shift the vendor card position  */}
          <span style={{ maxWidth: '310px' }}>
            <Typography.Text style={{ marginRight: '10px' }}>
              {companyVendor?.vendor?.name || companyVendor?.vendor_name}:
            </Typography.Text>

            {companyVendor?.contacts
              .map((contact) => {
                const fullName = contact?.vendor_user?.full_name || contact?.full_name
                return `${fullName}${contact?.nickname ? ` (${contact?.nickname})` : ''}`
              })
              .join(', ')}
          </span>

          <Tooltip title="Remove this vendor from the quote" placement="topRight">
            <Button
              style={{ marginLeft: '10px' }}
              size="small"
              type="dashed"
              icon={<DeleteOutlined />}
              onClick={() => onRemove(companyVendor?.id)}
              disabled={selectIsDisabled}
            />
          </Tooltip>
        </FlexBoxX>

        {!sameMessageAllVendors && (
          <Input.TextArea
            data-cy={`vendor-message-${index}`}
            style={{ width: '100%', resize: 'none', marginBottom: '20px' }}
            rows={3}
            placeholder={placeholder}
            value={companyVendor?.comment}
            onChange={(e) => onChangeMessage(companyVendor?.id, e.target.value)}
            disabled={selectIsDisabled}
          />
        )}
      </FlexBoxY>
    )
  },
)
