import React from 'react'

import { Link } from 'react-router-dom'
import { Column } from 'react-table'

import _ from 'lodash'

import { Popover, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { currencyFormatter, formatDateString, formatEnumValue } from 'common/helpers/formatters'

export const UnknownText = () => (
  <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
    Unknown
  </Typography.Paragraph>
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getInvoiceColumns(excludeColumns: Array<string> = []): Array<Column<any>> {
  const columns = [
    {
      Header: 'Vendor',
      id: 'company_vendor_name',
      accessor: ({ company_vendor_name }) => company_vendor_name || <UnknownText />,
      sortBy: 'company_vendor_name',
      width: 300,
    },
    {
      Header: 'Invoice',
      id: 'invoice_number',
      sortBy: 'invoice_number',
      width: 240,
      Cell: ({ row }) => {
        return (
          <Link onClick={(e) => e.stopPropagation()} to={`/invoice/${row?.original?.invoice_id}`}>
            {row.original.name ? `${row.original.name}: ` : ''}
            {row.original.invoice_number}
          </Link>
        )
      },
    },
    {
      Header: 'Projects',
      id: 'project_names',
      accessor: 'project_names',
      sortBy: 'project_names',
      width: 300,
      Cell: ({ value }) => {
        if (_.isEqual(value, ['unknown'])) return <UnknownText />
        return (
          <Box display="flex" gridGap={2}>
            {value.map((name, index) => (
              <Typography.Paragraph key={`${name}-${index}-p-key`} style={{ marginBottom: 0 }}>
                {name}
                {index + 1 < value?.length ? ', ' : ''}
              </Typography.Paragraph>
            ))}
          </Box>
        )
      },
    },
    {
      Header: 'Deleted At',
      id: 'discarded_at',
      sortBy: 'discarded_at',
      accessor: ({ discarded_at }) => (discarded_at ? formatDateString(discarded_at) : <UnknownText />),
      width: 160,
    },
    {
      Header: 'Deleted By',
      id: 'discarded_by_company_user_name',
      accessor: ({ discarded_by_company_user_name }) => {
        return discarded_by_company_user_name === 'Not assigned' ? <UnknownText /> : discarded_by_company_user_name
      },
      width: 200,
      sortBy: 'discarded_by_company_user_name',
    },
    {
      Header: 'Delete Reason',
      id: 'discard_reason',
      sortBy: 'discard_reason',
      accessor: ({ discard_reason }) => (discard_reason ? formatEnumValue(discard_reason) : <UnknownText />),
      width: 160,
    },
    {
      Header: 'Delete Explained',
      id: 'discard_explained',
      sortBy: 'discard_explained',
      accessor: ({ discard_explained }) =>
        discard_explained ? (
          <Popover content={<Box maxWidth={300}>{discard_explained}</Box>} placement="bottom">
            <Typography.Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2, expandable: false }}>
              {discard_explained}
            </Typography.Paragraph>
          </Popover>
        ) : (
          <UnknownText />
        ),
      width: 160,
    },
    {
      Header: 'Created At',
      id: 'created_at',
      accessor: ({ created_at }) => (created_at ? formatDateString(created_at) : <UnknownText />),
      width: 160,
      sortBy: 'created_at',
    },
    {
      Header: 'Total Amount',
      id: 'total_amount',
      sortBy: 'total_amount',
      accessor: ({ total_amount }) => (total_amount ? currencyFormatter(total_amount, 2) : <UnknownText />),
      width: 150,
    },
  ]

  return columns.filter((column) => !excludeColumns.includes(column.Header))
}
