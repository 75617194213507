import React from 'react'

import styled from '@emotion/styled'

import { Table, TableProps } from 'antd'

import { EditableCell, EditableCellProps } from './editable_cell'
import { EditableRow } from './editable_row'

export type EditableTableProps<Item> = Omit<TableProps<Item>, 'columns'> & {
  columns: (TableProps<Item>['columns'][0] &
    Pick<EditableCellProps<Item>, 'renderEditable' | 'editable' | 'dataIndex' | 'tabIndex'>)[]
  onMove?: (origin: Item, target: Item) => void
  index?: number
}

type ColumnTypes<Item> = Exclude<EditableTableProps<Item>['columns'], undefined>

export const TableStyled = styled(Table)`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
    min-height: 32px;
    border: 1px solid transparent;
    border-radius: 2px;
    transition: border-color 0.3s ease;
  }

  .editable-row:hover .editable-cell-value-wrap {
    border-color: ${({ theme }) => theme.colors['gray-5']};
  }

  .ant-table-row-expand-icon-cell {
    text-align: center;
  }

  .ant-table-row.is-over td {
    background-color: ${({ theme }) => theme.colors['gray-2']};
  }

  .ant-table-expanded-row {
    .ant-table.ant-table-small {
      margin: -8px -8px -8px 28px !important;
    }

    .ant-table-row:hover,
    .ant-table-cell {
      background: ${({ theme }) => theme.colors['gray-2']} !important;
      color: #00000082;
    }
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: ${({ theme }) => theme.colors['blue-1']};
  }
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EditableTable = <Item extends Record<string, any>>({
  columns,
  dataSource,
  onMove,
  index = 0,
  ...props
}: EditableTableProps<Item>) => {
  const mappedColumns = columns.map((col) => {
    if (!col.renderEditable) {
      return col
    }

    return {
      ...col,
      onCell: (record, rowIndex) => ({
        record,
        rowIndex,
        dataIndex: col.dataIndex,
        editable: col?.editable,
        renderEditable: col?.renderEditable,
        tabIndex: col?.tabIndex,
        tableIndex: index,
      }),
    }
  })

  return (
    <TableStyled
      onRow={(item, index) => {
        return {
          index,
          item,
          moveRow: onMove,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as React.HTMLAttributes<any>
      }}
      rowClassName="editable-row"
      columns={mappedColumns as ColumnTypes<Item>}
      dataSource={dataSource}
      {...props}
      components={{
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
        ...props?.components,
      }}
    />
  )
}
