import React from 'react'

import moment from 'moment'

import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import { Button, Col, DatePicker, Input, Row, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { makeCostCodeValue, Option, SelectCostCode } from 'common/components/SelectCostCode'
import { CostCode } from 'common/server/cost_codes/cost_codes'
import { Unit } from 'common/server/units'

import { ConcreteCommitmentMaterial } from 'contractor/server/commitments'
import { CostCodeSettings } from 'contractor/server/company_settings/other_settings'

const Label = (props) => <Typography.Text type="secondary" style={{ fontSize: 10 }} {...props} />

type ConcreteMaterialRowProps = {
  disabled?: boolean
  hideCostCode?: boolean
  projectId: string
  costCodeSettings: CostCodeSettings
  dataSource: ConcreteCommitmentMaterial
  costCodes: CostCode[]
  units: Unit[]
  onChange: (value: ConcreteCommitmentMaterial, changes: ConcreteCommitmentMaterial) => void
  onRemove?: (material: ConcreteCommitmentMaterial) => void
}

export const ConcreteMaterialRow = (props: ConcreteMaterialRowProps) => {
  const { disabled, projectId, costCodeSettings, dataSource, costCodes, onChange, onRemove } = props
  return (
    <Box
      bg="white"
      borderRadius={4}
      py={12}
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Row gutter={[16, 16]} align="middle" style={{ width: '100%' }}>
        <Col xs={6} sm={4} md={3} lg={2} xl={1} xxl={1}>
          <Box display="flex" flexDirection="column">
            <Label>Phase</Label>
            <Input
              value={dataSource.phase}
              onChange={(value) =>
                onChange(dataSource, {
                  ...dataSource,
                  phase: value.target.value,
                })
              }
            />
          </Box>
        </Col>

        <Col xs={18} sm={6} md={6} lg={6} xl={3} xxl={2}>
          <Box display="flex" flexDirection="column">
            <Label>Date</Label>
            <DatePicker
              value={dataSource.schedule ? moment(dataSource.schedule) : null}
              onChange={(value) =>
                onChange(dataSource, {
                  ...dataSource,
                  schedule: value?.toISOString(),
                })
              }
            />
          </Box>
        </Col>

        <Col xs={24} sm={14} md={15} lg={8} xl={4} xxl={4}>
          <Box display="flex" flexDirection="column">
            <Label>Mix design</Label>
            <Input
              value={dataSource?.company_material?.mix_design}
              onChange={(value) =>
                onChange(dataSource, {
                  ...dataSource,
                  company_material: {
                    ...dataSource.company_material,
                    mix_design: value.target.value,
                  },
                  company_material_id: null,
                })
              }
            />
          </Box>
        </Col>

        <Col xs={8} sm={12} md={12} lg={5} xl={2} xxl={2}>
          <Box display="flex" flexDirection="column">
            <Label>Mix code</Label>
            <Input
              value={dataSource?.company_material?.mix_code}
              onChange={(value) =>
                onChange(dataSource, {
                  ...dataSource,
                  company_material: {
                    ...dataSource.company_material,
                    mix_code: value.target.value,
                  },
                  company_material_id: null,
                })
              }
            />
          </Box>
        </Col>

        <Col xs={8} sm={6} md={6} lg={3} xl={2} xxl={2}>
          <Box display="flex" flexDirection="column">
            <Label>Cost per CYD</Label>
            <Input
              value={dataSource.unit_price}
              onChange={(value) =>
                onChange(dataSource, {
                  ...dataSource,
                  unit_price: value.target.value,
                })
              }
            />
          </Box>
        </Col>

        <Col xs={8} sm={6} md={6} lg={4} xl={2} xxl={2}>
          <Box display="flex" flexDirection="column">
            <Label>Estimated CY</Label>
            <Input
              value={dataSource.estimated_cy}
              onChange={(value) =>
                onChange(dataSource, {
                  ...dataSource,
                  estimated_cy: value.target.value,
                })
              }
            />
          </Box>
        </Col>

        <Col xs={24} sm={13} md={13} lg={11} xl={6} xxl={7}>
          <Box display="flex" flexDirection="column">
            <Label>Location/Description</Label>
            <Input
              value={dataSource?.company_material?.description}
              onChange={(value) =>
                onChange(dataSource, {
                  ...dataSource,
                  company_material: {
                    ...dataSource.company_material,
                    description: value.target.value,
                  },
                  company_material_id: null,
                })
              }
            />
          </Box>
        </Col>

        <Col xs={20} sm={9} md={9} lg={7} xl={3} xxl={3}>
          <Box display="flex" flexDirection="column">
            <Label>Cost Code</Label>
            <SelectCostCode
              style={{ width: '100%' }}
              costCodes={costCodes}
              costCodeSettings={costCodeSettings}
              projectId={projectId}
              disabled={disabled}
              value={makeCostCodeValue(dataSource.cost_code, costCodeSettings)}
              onChange={(option: Option) =>
                onChange(dataSource, {
                  ...dataSource,
                  cost_code: option?.costCode,
                  cost_code_id: option?.costCode?.id,
                })
              }
            />
          </Box>
        </Col>

        <Col
          xs={4}
          sm={2}
          md={2}
          lg={2}
          xl={1}
          xxl={1}
          style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
        >
          <Button style={{ marginTop: 16 }} onClick={() => onRemove(dataSource)}>
            <DeleteOutlined style={{ color: 'red' }} />
          </Button>
        </Col>
      </Row>
    </Box>
  )
}
