import React from 'react'

import styled from '@emotion/styled'

import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'
import { Tooltip, Badge, Form } from 'antd'
import { FormItemProps } from 'antd/lib/form'

import { observer } from 'mobx-react-lite'

import { SelectCostCode, SelectCostCodeProps } from 'contractor/components/SelectCostCode'
import { useStores } from 'contractor/hooks/use-stores'

const FormItemWithIcon = styled(Form.Item)`
  .ant-form-item-label {
    overflow: unset;
  }
`

type FieldCostCodeProps = {
  inputProps?: Partial<SelectCostCodeProps>
  formItemProps?: FormItemProps
}

export const FieldCostCode = observer<FieldCostCodeProps>(({ inputProps, formItemProps }) => {
  const { companySettingStore } = useStores()

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  return (
    <FormItemWithIcon
      name="cost_code"
      label={
        <Badge
          count={
            <Tooltip
              placement="right"
              title="When this material is ordered we will automatically assign it to this cost code.
            If this material can be assigned to multiple cost codes then don't worry about specifying a default."
            >
              <QuestionCircleOutlined style={{ right: -5 }} />
            </Tooltip>
          }
        >
          <span>Default Cost Code</span>
        </Badge>
      }
      {...formItemProps}
    >
      <SelectCostCode
        isCreate
        {...inputProps}
        costCodeSettings={{
          ...costCodeSettings,
          project_filtering_enabled: false,
        }}
      />
    </FormItemWithIcon>
  )
})
