import React from 'react'

import { ShopOutlined } from '@ant-design/icons'
import { Space, Tooltip, Typography } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { CustomEditableContent } from 'common/components/CustomEditableContent'

import { makeCompanyVendorOption, SelectCompanyVendor } from 'contractor/components/SelectCompanyVendor'
import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'

interface CompanyVendorSelectProps {
  disabled?: boolean
}

export const CompanyVendorSelect = (props: CompanyVendorSelectProps) => {
  const { setDirty, setSelectedCompanyVendor, selectedCompanyVendor, commitment } = useCommitment()
  const { disabled } = props
  const source = window.location.pathname

  const handleFindCompanyVendor = (selectedOption) => {
    if (!selectedOption) return setSelectedCompanyVendor(null)

    setSelectedCompanyVendor(selectedOption?.original)
  }

  const companyVendorName = selectedCompanyVendor?.vendor?.name || selectedCompanyVendor?.vendor_name

  if (disabled) {
    return (
      <Tooltip title="Vendor" placement="bottom">
        <Space>
          <ShopOutlined />
          <Typography.Text type="secondary">{companyVendorName}</Typography.Text>
        </Space>
      </Tooltip>
    )
  }

  return (
    <CustomEditableContent
      boxProps={{ mt: 0, mb: 0 }}
      showRequired={!commitment?.id}
      input={
        <SelectCompanyVendor
          disabled={disabled}
          onChange={(option) => {
            trackEvent(Events.CLICK_VENDOR, source)
            handleFindCompanyVendor(option)
            setDirty(true)
          }}
          value={makeCompanyVendorOption(selectedCompanyVendor)}
          style={{ minWidth: 180 }}
        />
      }
    >
      <Tooltip title="Vendor" placement="bottom">
        <Space>
          <ShopOutlined />
          <Typography.Text type="secondary">{companyVendorName || 'Select Vendor'}</Typography.Text>
        </Space>
      </Tooltip>
    </CustomEditableContent>
  )
}
