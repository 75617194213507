import React from 'react'

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Switch, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { usePsqlTable } from 'common/components/PsqlTable/psql_table_provider'

import { ListItem } from 'contractor/pages/@v2/Orders/components/Filters/list_item'

export const CommitmentColumns = () => {
  const { columns } = usePsqlTable()
  return (
    <Box p="12" width="100%">
      {columns
        .filter((column) => !column.disabledForToggleView)
        .map((column) => {
          return (
            <ListItem
              key={column.id}
              mb={4}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => column.toggleHidden(column.isVisible)}
              py={4}
              px={6}
              mx={-6}
              borderRadius="md"
              backgroundColor={column.isVisible ? 'transparent' : 'gray-4'}
            >
              <Typography.Text>{column.Header}</Typography.Text>
              <Switch
                size="small"
                checked={column.isVisible}
                checkedChildren={<EyeOutlined />}
                unCheckedChildren={<EyeInvisibleOutlined />}
              />
            </ListItem>
          )
        })}

      <Button
        style={{ marginTop: 16, width: '100%' }}
        type="primary"
        onClick={() => columns.forEach((column) => column.toggleHidden(false))}
        disabled={columns.every((column) => column.isVisible)}
      >
        Show All
      </Button>
    </Box>
  )
}
