import React from 'react'

import { Skeleton } from 'antd'

import { Box } from 'common/components/boxes'

export const Loading = () => (
  <Box width="100%" pt={16}>
    <Skeleton avatar={{ size: 'default' }} paragraph={{ rows: 4 }} />
    <Skeleton avatar={{ size: 'default' }} paragraph={{ rows: 1 }} />
  </Box>
)
