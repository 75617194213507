import React from 'react'

import { Typography, Switch } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'

type FilterUserProps = {
  modalRef: React.RefObject<DrawerRef>
  options: {
    label: string
    checked: boolean
    onCheck: (checked: boolean) => void
  }[]
  onCancel: () => void
}

export const FilterUser = ({ modalRef, onCancel, options }: FilterUserProps) => {
  return (
    <Drawer bgGray title="Filters" ref={modalRef} onClose={onCancel}>
      <Box p={16} width="100%" overflowY="auto">
        {options.map((option, index) => (
          <FlexBoxX
            key={index}
            border="1px solid"
            borderColor="gray-2"
            borderRadius="md"
            px={12}
            py="6px"
            justifyContent="space-between"
            style={{ whiteSpace: 'nowrap' }}
          >
            <Typography.Text strong={true}>{option.label}</Typography.Text>
            <Switch checked={option.checked} onChange={option.onCheck} style={{ marginLeft: 8 }} />
          </FlexBoxX>
        ))}
      </Box>
    </Drawer>
  )
}
