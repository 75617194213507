import React, { useCallback } from 'react'

import Spreadsheet from 'react-spreadsheet'

import classNames from 'classnames'
import _ from 'lodash'

import { Modal, Tooltip } from 'antd'

import { allColumns, Column, ColumnHeader } from 'common/components/OrderMaterialsSpreadsheet/columns'
import { CustomCell } from 'common/components/OrderMaterialsSpreadsheet/custom_cell'
import { RequiredMark } from 'common/components/OrderMaterialsSpreadsheet/required_mark'
import { Wrapper } from 'common/components/OrderMaterialsSpreadsheet/wrapper'
import { makeOption as makeUnitOption, Option as UnitOption } from 'common/components/SelectUnit'
import { calcExtCost, roundValue } from 'common/helpers/order'
import { CostCode } from 'common/server/cost_codes/cost_codes'
import { OrderMaterial } from 'common/server/orders'
import { OrderStates } from 'common/server/server_types'

import { CostCodeSettings } from 'contractor/server/company_settings/other_settings'

type RequiredOrderFields = {
  unit: boolean
  quantity: boolean
  unit_cost?: boolean
  cost_code: boolean
}

type OrderMaterialSpreadsheetProps = {
  columns: { id: string }[]
  orderMaterials: OrderMaterial[]
  onChange?: (
    orderMaterials: OrderMaterial[],
    valuesToPropagate?: Array<{ index: number; value: unknown; path: string }>,
  ) => void
  maxHeight?: string
  hiddenColumns?: string[]
  readOnlyColumns?: string[]
  disabled?: boolean
  isSelecting?: boolean
  projectId?: string
  canCreateNewMaterial?: boolean

  requiredFields?: RequiredOrderFields
  orderType: OrderType
  companyVendorId?: string
  costCodeSettings?: CostCodeSettings
  orderState?: OrderStates

  defaultCostCodeByApplyAll?: CostCode
  onApplyDefaultCostCodeFromApplyAll?: (path: string, key: string) => void
}

const getUnit = (companyMaterial): UnitOption => {
  if (companyMaterial?.unit_id) {
    return makeUnitOption(companyMaterial?.unit)
  }

  if (companyMaterial?.unit_name) {
    return {
      value: companyMaterial?.unit_name,
      label: companyMaterial?.unit_name,
      selectedLabel: companyMaterial?.unit_name,
      original: null,
    }
  }
}

export const OrderMaterialSpreadsheet = ({
  requiredFields,
  hiddenColumns = [],
  readOnlyColumns = [],
  columns: columnsProp,
  onChange,
  orderMaterials: orderMaterialsProp = [],
  disabled,
  isSelecting,
  projectId,
  orderType,
  companyVendorId,
  costCodeSettings,
  orderState,
  canCreateNewMaterial,
  defaultCostCodeByApplyAll,
  onApplyDefaultCostCodeFromApplyAll,
}: OrderMaterialSpreadsheetProps) => {
  // Can't edit the mobX observable directly, incompatible with being "frozen", so convert to the plain array.
  const orderMaterials = JSON.parse(JSON.stringify(orderMaterialsProp))
  // Add  checkbox column when isSelecting is true
  const columns = isSelecting ? [{ id: 'select' }, ...columnsProp] : columnsProp

  const rootRef = React.useRef(null)

  // Merge column info (if it exists), otherwise just pass it straight through
  const filteredColumns = columns.map((c) => {
    const correspondingColumn = _.find(allColumns, { id: c.id })
    return { ...correspondingColumn, ...c }
  }) as Column[]

  // Remove any hidden columns
  _.remove(filteredColumns, (c) => (hiddenColumns || []).includes(c.id))

  const onSelectMaterial = useCallback(
    (index, newCompanyMaterial) => {
      if (onChange) {
        const newOrderMaterials = _.cloneDeep(orderMaterials)

        newOrderMaterials[index]['company_material'] = newCompanyMaterial

        // Validates if the cost code is valid for the project, the material can have a default
        // cost code and it could not belong to the project it is being used in
        const costCodeProjects = newCompanyMaterial?.cost_code?.project_ids
        if (costCodeSettings?.project_filtering_enabled && !!costCodeProjects?.length) {
          newOrderMaterials[index]['cost_code'] = costCodeProjects?.includes(projectId)
            ? newCompanyMaterial.cost_code
            : null
        } else if (!newOrderMaterials[index]['cost_code'] && defaultCostCodeByApplyAll) {
          newOrderMaterials[index]['cost_code'] = defaultCostCodeByApplyAll
          onApplyDefaultCostCodeFromApplyAll('cost_code', newCompanyMaterial.id)
        } else {
          newOrderMaterials[index]['cost_code'] = newCompanyMaterial?.cost_code
        }

        // Validates if the cost code phase is valid for the project, the material can have a default
        // cost code phase and it could not belong to the project it is being used in
        const costCodePhaseProjects = newCompanyMaterial?.cost_code_phase?.project_ids
        if (costCodeSettings?.project_specific_phase_codes_enabled && !!costCodePhaseProjects?.length) {
          newOrderMaterials[index]['cost_code_phase_id'] = costCodePhaseProjects?.includes(projectId)
            ? newCompanyMaterial.cost_code_phase?.id
            : null
        } else if (!newOrderMaterials[index]['cost_code_phase_id'] && defaultCostCodeByApplyAll) {
          newOrderMaterials[index]['cost_code_phase_id'] = defaultCostCodeByApplyAll
          onApplyDefaultCostCodeFromApplyAll('cost_code_phase_id', newCompanyMaterial.id)
        } else {
          newOrderMaterials[index]['cost_code_phase_id'] = newCompanyMaterial?.cost_code_phase?.id
        }

        const preferredVendorPrices =
          newCompanyMaterial['preferred_vendor_prices'] || newCompanyMaterial['company_material_vendor_prices']
        const hasPreferredVendors = preferredVendorPrices?.length
        if (
          orderType === 'Order' &&
          !!companyVendorId &&
          hasPreferredVendors &&
          !newOrderMaterials[index]['unit_cost']
        ) {
          const preferredVendorPrice = preferredVendorPrices.find(
            (preferredVendorPrice) => preferredVendorPrice?.company_vendor?.id === companyVendorId,
          )
          newOrderMaterials[index]['unit_cost'] = preferredVendorPrice?.price
        } else if (
          orderType === 'Order' &&
          !companyVendorId &&
          hasPreferredVendors &&
          !newOrderMaterials[index]['unit_cost']
        ) {
          const itemWithLowestPrice = preferredVendorPrices.reduce((minItem, currentItem) => {
            return Number(currentItem.price) < Number(minItem.price) ? currentItem : minItem
          }, preferredVendorPrices[0])

          newOrderMaterials[index]['unit_cost'] = itemWithLowestPrice?.price
        }

        newOrderMaterials[index]['unit'] = getUnit(newCompanyMaterial)

        onChange(newOrderMaterials)
      }
    },
    [onChange, orderMaterials],
  )

  const mapToSpreadsheet = (orderMaterial: OrderMaterial) => {
    // Reset selected rows when isSelecting is false
    if (!isSelecting) {
      orderMaterial['select'] = false
    }

    return filteredColumns.map(({ accessor, width, DataEditor, DataViewer, readOnly, ...props }) => {
      const cellMap = {
        value: _.get(orderMaterial, accessor, ''),
        accessor,
        width,
      }

      if (disabled) {
        if (DataViewer) {
          cellMap['DataViewer'] = DataViewer
        }
        cellMap['readOnly'] = disabled
      } else {
        cellMap['onSelectMaterial'] = onSelectMaterial

        if (DataEditor) {
          cellMap['DataEditor'] = DataEditor
        }
        if (DataViewer) {
          cellMap['DataViewer'] = DataViewer
        }

        cellMap['readOnly'] = readOnlyColumns.includes(props['id']) || readOnly
      }

      const extCost = calcExtCost({
        unitCost: Number(orderMaterial?.unit_cost),
        quantity: orderMaterial?.quantity,
        multiplier: orderMaterial?.['unit']?.original?.multiplier,
        qtyIncrement: orderMaterial?.['unit']?.original?.qty_increment,
      })

      if (accessor === 'extended_cost') {
        cellMap['value'] = extCost
      }

      if (accessor === 'ext_cost_with_tax' && extCost) {
        cellMap['value'] = roundValue(extCost + Number(orderMaterial?.tax_value) || 0)
      }

      const unit = orderMaterial['unit'] || getUnit(orderMaterial?.company_material)

      if (accessor === 'unit') {
        cellMap['value'] = unit
      }

      cellMap['materialRow'] = { ...orderMaterial, unit }
      return cellMap
    })
  }

  // When the user pastes more lines that have on the table these new rows become undefined, so we need to populate row data
  const transformTableRow = (row = [], _, data = []) => {
    if (!row?.[0]?.['materialRow']) {
      const rowWithMaterialRow = data.find((item = []) => item?.find((cell) => !!cell?.['materialRow'])) || []

      if (rowWithMaterialRow.length) {
        return rowWithMaterialRow.map((cell, index) => {
          return {
            ...cell,
            value: row[index]?.value,
          }
        })
      }
    }

    return row
  }

  // When the user does not have permission to create the material we block the user from pasting items on the table
  const maybeBlockPaste = (row = []) => {
    const descriptionColumn = row?.[0]
    if (!canCreateNewMaterial && !descriptionColumn?.['materialRow']?.company_material?.id) {
      return row.map((column) => {
        if (column?.accessor === 'company_material.description') {
          return { ...column, value: '' }
        }
        return column
      })
    }

    return row
  }

  const mapToOrderMaterial = (data) => {
    const orderMaterials = []
    // data is the matrix from the table
    data
      .map(transformTableRow)
      .map(maybeBlockPaste)
      .forEach((row) => {
        const orderMaterial = _.cloneDeep(row?.[0]?.['materialRow'])
        orderMaterials.push(orderMaterial)

        /* Row is array with each column for table, like:
        [{
          DataEditor: ƒ (_a)
          DataViewer: ƒ (_a)
          accessor: "company_material.description"
          materialRow: orderMaterial
          onSelectMaterial: ƒ (index, newCompanyMaterial)
          readOnly: false
          showTimeline: true
          value: ""
          width: "300px"
        }]
      */
        row.forEach((item) => {
          const value = item.value || ''

          // Remove company_material.id and company_material.manufacturer_material_id if the user changes any property in the company material
          const prevValue = _.get(orderMaterial, item.accessor, '') || ''

          const hasChangesOnCompanyMaterial =
            item.accessor.includes('company_material') && value?.trim() !== prevValue?.trim()
          const hasUnitChange = item.accessor === 'unit' && value?.value !== prevValue?.value
          if (hasChangesOnCompanyMaterial || hasUnitChange) {
            _.set(orderMaterial, 'company_material.id', null)
            _.set(orderMaterial, 'company_material.manufacturer_material_id', null)

            // If the user changes something in the spreadsheet with pending approval material, we accept it as approved
            _.set(orderMaterial, 'company_material.requested_at', null)
            _.set(orderMaterial, 'company_material.requested_by_id', null)
          }

          _.set(orderMaterial, item.accessor, value)
        })

        const unitCost = row.find((item) => item.accessor === 'unit_cost')
        const quantity = row.find((item) => item.accessor === 'quantity')

        const extCost = calcExtCost({
          unitCost,
          quantity,
          multiplier: orderMaterial?.['unit']?.original?.multiplier,
          qtyIncrement: orderMaterial?.['unit']?.original?.qty_increment,
        })

        _.set(orderMaterial, 'extended_cost', extCost)
      })

    return orderMaterials
  }

  const handlePaste = React.useCallback(
    (e) => {
      if (!canCreateNewMaterial && rootRef.current?.matches(':focus-within')) {
        e.preventDefault()
        Modal.warning({ title: 'Cannot paste all, each material needs to be added individually' })
      }
    },
    [canCreateNewMaterial],
  )

  React.useEffect(() => {
    document.addEventListener('paste', handlePaste)

    return () => {
      document.removeEventListener('paste', handlePaste)
    }
  }, [handlePaste])

  const dataSource = orderMaterials.map(mapToSpreadsheet)

  return (
    <Wrapper overflowX="auto" width="100%" ref={rootRef} maxHeight="calc(100% - 36px)" minHeight={{ _: 200, lg: 175 }}>
      <div id="external-library">
        <Spreadsheet
          data={dataSource}
          onChange={(data) => {
            const valuesToPropagate = _.flatten(
              data.map((row, index) =>
                row
                  .filter((cell) => cell && cell.shouldPropagateValue)
                  .map((cell) => ({ index, path: cell.accessor, value: cell.value })),
              ),
            )

            if (onChange) {
              const orderMaterials = mapToOrderMaterial(data)
              onChange(orderMaterials, valuesToPropagate)
            }
          }}
          hideRowIndicators
          columnLabels={
            filteredColumns.map((column, index) => {
              return (
                <Tooltip title={column?.description} key={index}>
                  <RequiredMark title={column?.header} orderType={orderType} requiredFields={requiredFields} />
                </Tooltip>
              ) // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }) as any
          }
          ColumnIndicator={({ column, label }) => (
            <ColumnHeader columnIndex={column} label={label} filteredColumns={filteredColumns} />
          )}
          Row={({ children, row: rowIndex }) => {
            const row = dataSource[rowIndex]
            const companyMaterial = row?.[0]?.materialRow?.company_material
            return (
              <tr className={classNames({ requested_material: companyMaterial?.requested_by_id && !!orderState })}>
                {children}
              </tr>
            )
          }}
          Cell={CustomCell}
        />
      </div>
    </Wrapper>
  )
}
