import React from 'react'

import { Button, Space } from 'antd'

type MixListExtraContentProps = {
  disabled?: boolean
  onAdditionCosts: () => void
  onPriceIncrease: () => void
}

export const MixListExtraContent = (props: MixListExtraContentProps) => {
  const { disabled, onAdditionCosts, onPriceIncrease } = props

  return (
    <Space>
      <Button disabled={disabled} onClick={onAdditionCosts}>
        Additional costs
      </Button>
      <Button disabled={disabled} onClick={onPriceIncrease}>
        Price increase
      </Button>
    </Space>
  )
}
