import React from 'react'

import { Checkbox, Typography } from 'antd'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import theme from 'common/styles/theme'

type Option = {
  id: string
  description?: string
  group_name?: string
  group_total?: number
  quantity_shipped: number
  uom?: string
  unit_price: number
  unit_multiplier?: number
  unit_qty_increment?: number
  checked?: boolean
  calcExtCost: (...rest) => number
}

type Column = {
  id: string
  label?: string
  render: (value, option: Option) => React.ReactNode
  width?: number | string
}

const COLUMNS: Column[] = [
  {
    id: 'description',
    label: 'Invoice Description',
    render: (value) => <Typography.Text>{value}</Typography.Text>,
    width: '100%',
  },
  {
    id: 'quantity_shipped',
    label: 'Qty',
    render: (value) => <Typography.Text>{value || '-'}</Typography.Text>,
    width: 100,
  },
  {
    id: 'uom',
    label: 'UOM',
    render: (value) => <Typography.Text>{value || '-'}</Typography.Text>,
    width: 150,
  },
  {
    id: 'unit_price',
    label: 'Unit cost',
    render: (value) => <Typography.Text>{currencyFormatter(value)}</Typography.Text>,
    width: 150,
  },
  {
    id: 'ext_price',
    label: 'Ext. cost',
    render: (_, option) => (
      <Typography.Text>
        {currencyFormatter(
          option.calcExtCost({
            unitCost: option?.unit_price,
            quantity: option?.quantity_shipped,
            multiplier: Number(option?.unit_multiplier),
            qtyIncrement: Number(option?.unit_qty_increment),
          }),
        )}
      </Typography.Text>
    ),
    width: 200,
  },
]

type InvoiceItemProps = {
  item: Option
  onCheck: (itemId: string) => void
}

const InvoiceItem = ({ item, onCheck }: InvoiceItemProps) => {
  const renderColumn = React.useCallback(
    (column: Column, option: Option) => (
      <FlexBoxY key={`${option.description}-${column.id}`} alignItems="baseline" width={column.width}>
        <Typography.Text color={theme.colors['gray-8']} style={{ fontSize: 10 }}>
          {column.label}
        </Typography.Text>
        <Typography.Text color={theme.colors['gray-12']}>{column.render(option[column.id], option)}</Typography.Text>
      </FlexBoxY>
    ),
    [],
  )

  return (
    <FlexBoxX alignItems="center" style={{ gap: 16 }}>
      <Checkbox onChange={() => onCheck(item.id)} checked={item.checked} className="mt-4" />
      {COLUMNS.map((column) => renderColumn(column, item))}
    </FlexBoxX>
  )
}

export { InvoiceItem }
