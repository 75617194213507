import React from 'react'

import LockOutlined from '@ant-design/icons/LockOutlined'
import { Tooltip } from 'antd'

import { formatEnumValue } from 'common/helpers/formatters'
import { LockOrderOptionType } from 'common/server/server_types'

interface LockPoProps {
  role: string
  rule: string
  cannotUpdateOrders: boolean
}

const rules = {
  [LockOrderOptionType.ORDER_CONFIRMATION]:
    'Your company settings lock orders once they have been confirmed by a vendor',
  [LockOrderOptionType.DELIVERY_COMPLETION]: 'Your company settings lock orders once they have been fully delivered',
  [LockOrderOptionType.ORDER_INVOICED]: 'Your company settings lock orders once they have been fully invoiced',
}

export const LockPo = ({ role, rule, cannotUpdateOrders }: LockPoProps) => {
  const title = cannotUpdateOrders
    ? `Your current role as a ${formatEnumValue(role)}, does not grant you permissions to edit orders`
    : rules[rule]

  return (
    <>
      <Tooltip placement={'bottom'} title={title}>
        <LockOutlined data-cy="lock-po-icon" />
      </Tooltip>
    </>
  )
}
