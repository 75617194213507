import React from 'react'

import { IndexOrderRecord } from 'javascript/vendor/server/orders'
import moment from 'moment/moment'

import styled from '@emotion/styled'

import FlagTwoTone from '@ant-design/icons/FlagTwoTone'
import WarningTwoTone from '@ant-design/icons/WarningTwoTone'
import { Tooltip } from 'antd'

import { Box } from 'common/components/boxes'
import OrderState from 'common/components/statuses/order_state'
import { OrderDelivery } from 'common/server/deliveries'
import { OrderStates, OrderSubStates } from 'common/server/server_types'

import { ConsolidatedOrders } from 'contractor/server/orders'

interface Props {
  // @todo: remove order and receive only specific params.
  order?: ConsolidatedOrders.Order

  notificationsCount: number
  showDeliveryIssue?: boolean
  state?: OrderStates
  subState?: OrderSubStates
  deliveries?: Array<OrderDelivery>
  hasOpenDeliveryIssue?: boolean
  leadTimeCutoffDateTimestamp?: number
}

const StdContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  * .ant-badge {
    width: 100%;
  }
`

interface OrderWarningsProps {
  order?: ConsolidatedOrders.Order
  leadTimeCutoffDateTimestamp?: number
}

const OrderWarnings = (props: OrderWarningsProps) => {
  const { order, leadTimeCutoffDateTimestamp: leadTimeCutoffDateTimestampProp } = props

  const today = moment().unix()
  const weekFromToday = moment().add(7, 'd').unix()

  const leadTimeCutoffDateTimestamp = leadTimeCutoffDateTimestampProp || order?.lead_time_cutoff_date_timestamp

  const warnings: string[] = []
  const errors: string[] = []

  // Order By Date
  if (leadTimeCutoffDateTimestamp && leadTimeCutoffDateTimestamp < today) {
    errors.push('Based on your lead times and requested delivery date this order will not arrive in time')
  } else if (leadTimeCutoffDateTimestamp && leadTimeCutoffDateTimestamp < weekFromToday) {
    warnings.push(
      'Based on your lead times and requested delivery date you must place this order now for it to arrive on time',
    )
  }

  const messages = [...errors, ...warnings].join(', ')
  if (messages.length == 0) {
    return null
  }

  return (
    <Tooltip title={messages}>
      {errors.length > 0 ? <FlagTwoTone twoToneColor="#eb2f96" /> : <WarningTwoTone twoToneColor="#F7CE3E" />}
    </Tooltip>
  )
}

export function CellStatus(props: Props) {
  const {
    notificationsCount,
    order = {} as ConsolidatedOrders.Order | IndexOrderRecord,
    showDeliveryIssue = true,
    state: stateProp,
    subState: subStateProp,
    deliveries: deliveriesProp,
    hasOpenDeliveryIssue: hasOpenDeliveryIssueProp,
    leadTimeCutoffDateTimestamp: leadTimeCutoffDateTimestampProps,
  } = props

  const {
    state: stateOrder,
    sub_state: subStateOrder,
    deliveries: deliveriesOrder = [],
    has_open_delivery_issue: hasOpenDeliveryIssueOrder,
  } = order

  const state = stateProp || stateOrder
  const sub_state = subStateProp || subStateOrder
  const deliveries = deliveriesProp || deliveriesOrder
  const hasOpenDeliveryIssue = hasOpenDeliveryIssueProp || hasOpenDeliveryIssueOrder
  const leadTimeCutoffDateTimestamp = leadTimeCutoffDateTimestampProps || order.lead_time_cutoff_date_timestamp

  return (
    <StdContainer>
      <Box style={{ flex: 1 }}>
        <OrderState
          state={state}
          sub_state={sub_state}
          notificationCount={notificationsCount}
          deliveryIssues={deliveries?.map((d) => d.delivery_issue_type)}
          showIssue={showDeliveryIssue && hasOpenDeliveryIssue}
        />
      </Box>

      <OrderWarnings leadTimeCutoffDateTimestamp={leadTimeCutoffDateTimestamp} />
    </StdContainer>
  )
}
