import React, { useCallback } from 'react'

import { Form } from 'antd'

import { SelectUnit, SelectUnitProps } from 'common/components/SelectUnit'
import { Unit } from 'common/server/units'

import { useForm } from './row_context'

type EditableCellSelectUnitProps = {
  inputName: string
  onSave: (value: string) => void
  units: Unit[]
} & SelectUnitProps

export const EditableCellSelectUnit = ({ inputName, onSave, units, ...props }: EditableCellSelectUnitProps) => {
  const selectUnitRef = React.useRef<HTMLInputElement>(null)

  const form = useForm()

  const save = useCallback(async () => {
    try {
      const values = await form.validateFields()

      onSave(values[inputName])
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }, [form, onSave])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      selectUnitRef.current?.focus()
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [selectUnitRef.current])

  return (
    <Form.Item style={{ margin: 0 }} name={inputName}>
      <SelectUnit
        ref={selectUnitRef}
        units={units}
        onChange={save}
        style={{ width: '100%' }}
        autoFocus
        labelInValue
        {...props}
      />
    </Form.Item>
  )
}
