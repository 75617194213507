import React from 'react'

import { Col, Form, Input, ColProps } from 'antd'

import { SelectUser, makeCompanyUserOption } from 'contractor/components/SelectUser'
import { ProjectInvoiceAssignee } from 'contractor/server/project_invoice_assignees'

type InvoiceAssigneesProps = {
  colProps?: ColProps
}

export const InvoiceAssignees = ({ colProps }: InvoiceAssigneesProps) => {
  const form = Form.useFormInstance()
  const projectInvoiceAssignees: ProjectInvoiceAssignee[] = form.getFieldValue('projectInvoiceAssignees') || []

  return (
    <>
      {projectInvoiceAssignees.map((projectInvoiceAssignee, index) => (
        <Col xs={24} sm={12} lg={8} xl={6} xxl={4} {...colProps} key={projectInvoiceAssignee.id}>
          <Form.Item
            name={['projectInvoiceAssignees', index, 'companyUserId']}
            label={`Invoice assignee - ${projectInvoiceAssignee.name}`}
          >
            <SelectUser placeholder="Search for user" makeOption={makeCompanyUserOption} />
          </Form.Item>
          <Form.Item name={['projectInvoiceAssignees', index, 'id']} hidden>
            <Input />
          </Form.Item>
        </Col>
      ))}
    </>
  )
}
