import React from 'react'

import _ from 'lodash'

import { Box, BoxProps } from 'common/components/boxes'
import { Select as ReactSelect } from 'common/components/Select'
import { OrderDelivery } from 'common/server/deliveries'

export interface ShippingCarrier {
  id: string
  name: string
}

interface CarrierSelectProps {
  delivery: OrderDelivery
  onChange: (params: { [key: string]: string }) => void
  shippingCarriers?: ShippingCarrier[]
  wrapperProps?: BoxProps
  disabled?: boolean
}

export const CarrierSelect = ({
  delivery,
  onChange,
  shippingCarriers = [],
  wrapperProps,
  disabled,
}: CarrierSelectProps) => {
  const options = [
    { label: 'Delivered by vendor', value: 'Delivered by vendor' },
    { label: 'Pickup', value: 'Pickup' },
    ...shippingCarriers?.map(({ id, name }) => ({
      label: name,
      value: id,
      id,
    })),
  ]

  return (
    <Box minWidth="200px" {...wrapperProps}>
      <ReactSelect
        creatable
        data-cy="carrier-select"
        aria-label="carrier-selector"
        options={options}
        // HACK: Using name as the unique value to search off here which could be dangerous
        value={
          delivery.carrier &&
          (_.find(options, { label: delivery.carrier }) || { label: delivery.carrier, value: delivery.carrier })
        }
        onChange={(selected) => {
          onChange({ carrier: selected['label'], shipping_carrier_id: selected['id'] })
        }}
        styles={{ container: (s) => ({ ...s, width: '100%' }) }}
        disabled={disabled}
      />
    </Box>
  )
}

export default CarrierSelect
