import React, { useRef } from 'react'

import { Card, Col, message, Row } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Page } from 'common/components/Page'

import { DeliveryDetail } from 'contractor/components/DeliveryDetail'
import ProjectSelector from 'contractor/components/ProjectSelector'
import { useStores } from 'contractor/hooks/use-stores'

import { useDashboard, withDashboardProvider } from './context'
import DeliveryIssues, { DeliveryIssuesRef } from './delivery_issues'
import OrderReminders from './order_reminders'
import OrderStats from './order_stats'
import UnconfirmedOrders from './unconfirmed_orders'
import UpcomingDeliveries, { UpcomingDeliveriesRef } from './upcoming_deliveries'

const cardBodyStyle = {
  height: '250px',
  overflowY: 'auto',
  padding: '10px 20px',
} as React.CSSProperties

const Dashboard = observer(() => {
  const { deliveryStore } = useStores()

  const { deliveryDetailRef } = useDashboard()

  const deliveryIssuesRef = useRef<DeliveryIssuesRef>()
  const upcomingDeliveriesRef = useRef<UpcomingDeliveriesRef>()

  return (
    <Page>
      <Page.Header>
        <Box display="flex" alignItems="start" justifyContent="space-between" width="100%">
          <ProjectSelector showSettings showAddProject />
        </Box>
      </Page.Header>

      <DeliveryDetail
        ref={deliveryDetailRef}
        onClose={() => {
          deliveryDetailRef.current?.close()
          deliveryStore.selectDelivery(null)
        }}
        orderDelivery={deliveryStore.selectedDelivery?.delivery}
        onUpdate={async (delivery) => {
          await deliveryStore.updateDelivery(delivery)
          deliveryDetailRef.current?.close()
          deliveryStore.selectDelivery(null)
          message.success('Delivery updated')
          deliveryIssuesRef.current?.fetchDeliveries()
          upcomingDeliveriesRef.current?.fetchDeliveries()
        }}
        project_ids={[deliveryStore.selectedDelivery?.order.project_id]}
        orderInfo={deliveryStore.selectedDelivery?.order}
      />

      <Page.Content>
        <Box width="100%" mb={16}>
          <OrderStats />
        </Box>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12}>
            <Card title="Upcoming deliveries" bodyStyle={cardBodyStyle}>
              <UpcomingDeliveries ref={upcomingDeliveriesRef} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Card title="Delivery issues" bodyStyle={cardBodyStyle}>
              <DeliveryIssues ref={deliveryIssuesRef} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Card title="Unconfirmed orders" bodyStyle={cardBodyStyle}>
              <UnconfirmedOrders />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Card title="Need to order" bodyStyle={cardBodyStyle}>
              <OrderReminders />
            </Card>
          </Col>
        </Row>
      </Page.Content>
    </Page>
  )
})

export default withDashboardProvider(Dashboard)
