import React from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table/Table'

import { Deliveries } from 'common/server/deliveries'

export interface Props {
  deliveries: Deliveries.Index[]
  showProjectName?: boolean
  onSelectDelivery: (id: string) => void
  columns: ColumnsType<Deliveries.Index>
}

export function DeliveriesTable(props: Props) {
  const { deliveries, showProjectName, onSelectDelivery, columns: columnsProp } = props

  const columns = React.useMemo(() => {
    if (!showProjectName) {
      return columnsProp
    }

    const columnsWithProject = [...columnsProp]

    columnsWithProject.splice(1, 0, {
      title: 'Project',
      dataIndex: 'project_name',
      key: 'project_name',
    })

    return columnsWithProject
  }, [columnsProp, showProjectName])

  return (
    <Table
      style={{ width: '100%' }}
      rowKey={'id'}
      dataSource={deliveries}
      columns={columns}
      pagination={false}
      onRow={(record) => ({
        onClick: () => {
          onSelectDelivery(record.id)
        },
      })}
      size="small"
    />
  )
}
