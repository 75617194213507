import React from 'react'

import { ExternalLink } from '@styled-icons/evaicons-solid'

import { ShopOutlined } from '@ant-design/icons'
import NumberIcon from '@ant-design/icons/NumberOutlined'
import ProjectIcon from '@ant-design/icons/ProjectOutlined'
import { Tooltip, Typography, Space } from 'antd'

import { Box } from 'common/components/boxes'
import OrderState from 'common/components/statuses/order_state'

import { ShowOrderResponse } from 'contractor/server/orders'

type OrderInfoProps = {
  order: ShowOrderResponse
}

export const OrderInfo = ({ order }: OrderInfoProps) => {
  return (
    <>
      <Typography.Title type="secondary" style={{ margin: 0 }} level={5}>
        {order.order_package_name}
      </Typography.Title>

      <Box width="100%" display="flex" alignItems="flex-start" justifyContent="space-between" py={10}>
        <Tooltip title="Project" placement="bottom">
          <Space size="small">
            <ProjectIcon />
            <Typography.Text ellipsis={{ tooltip: order?.project?.name }}>{order?.project?.name}</Typography.Text>
          </Space>
        </Tooltip>

        <Tooltip title="Order number" placement="bottom">
          <Typography.Link target="_blank" href={`/order/${order.id}`}>
            <Space size="small">
              <NumberIcon />
              <Box display="inline-flex">
                {order.order_number}
                <ExternalLink size="13" />
              </Box>
            </Space>
          </Typography.Link>
        </Tooltip>

        <Tooltip title="Vendor" placement="bottom">
          <Space size="small">
            <ShopOutlined />
            <Typography.Text style={{ whiteSpace: 'nowrap' }}>
              {order.company_vendor?.vendor?.name || order.company_vendor?.vendor_name}
            </Typography.Text>
          </Space>
        </Tooltip>

        <Box display="inline-flex" alignItems="center">
          <OrderState state={order.state} sub_state={order.sub_state} size="medium" style={{ margin: 0 }} />
        </Box>
      </Box>
    </>
  )
}
