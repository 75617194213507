import React from 'react'

import { flatten } from 'lodash'
import * as XLSX from 'xlsx'

import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
import { Button, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'
import { OrderStates, OrderSubStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { foundation } from 'contractor/pages/@v2/Orders/helpers/foundation'
import { ConsolidatedOrders } from 'contractor/server/orders'

const allowedOrderStatesToDownload = [OrderSubStates.ORDERED_CONFIRMED, OrderStates.SHIPPED, OrderStates.DELIVERED]

export const DownloadFoundation = observer(() => {
  const { orderStore } = useStores()

  const [loading, setLoading] = React.useState(false)

  const downloadData = async () => {
    try {
      setLoading(true)
      const orders = await orderStore.listStore.fetchAllRecords()

      if (!orders.length) {
        return
      }

      const ordersToDownload = orders.filter((orderHit) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        allowedOrderStatesToDownload.includes(orderHit.contractor_orders_filter as any),
      )

      const downloadOrders = ordersToDownload.map((order) => {
        const companyVendorName = order?.company_vendor_name || order?.vendor_name

        const header = [foundation.makeFoundationHeaderFile({ ...order, company_vendor_name: companyVendorName })]
        const detail = foundation.makeFoundationDetailFileItems(order)

        return {
          header,
          detail: [...detail].filter(Boolean),
        }
      })

      const headersFileData = flatten(downloadOrders.map((data) => data.header))
      const detailsFileData = flatten(downloadOrders.map((data) => data.detail))

      const wsHeaders = XLSX.utils.json_to_sheet(headersFileData)
      const wsDetails = XLSX.utils.json_to_sheet(detailsFileData)

      const wbHeaders = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wbHeaders, wsHeaders, 'Data')
      const wbDetails = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wbDetails, wsDetails, 'Data')

      XLSX.writeFile(wbHeaders, 'TCMPOHeaderImp.csv')
      XLSX.writeFile(wbDetails, 'TCMPODetailImp.csv')

      await orderStore.manual_export(ordersToDownload.map((order) => ({ id: order.order_id })))

      // HACK: If we update directly the records, the list will not be updated
      // becuase it's an async operation in our API.
      const ordersToUpdate = orderStore.listStore.records.map<ConsolidatedOrders.Order>((order) => ({
        ...order,
        integration_sync_status: 'SYNCED',
      }))

      orderStore.listStore.optimisticUpdateRecords(ordersToUpdate)
    } catch {
      message.error(`Unable to download files. Please contact ${SUPPORT_EMAIL_ADDRESS} for further assistance.`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button loading={loading} onClick={downloadData}>
      <DownloadOutlined />
    </Button>
  )
})
