import React from 'react'

import { Typography } from 'antd'

import { Box, FlexBoxY } from 'common/components/boxes'

import { DeliveryOptions } from 'contractor/components/LogDelivery/SelectOrderStep/delivery_options'

export const ExpectedDeliveries = ({ title, recentDeliveries, handleSelectDelivery }) => {
  if (recentDeliveries?.length) {
    return (
      <FlexBoxY width="100%" flexGrow={0} alignItems="flex-start">
        <Box
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            width: '100%',
            zIndex: 1,
            padding: '8px 16px',
          }}
        >
          <Typography.Text type="secondary">{title}</Typography.Text>
        </Box>
        <DeliveryOptions dataSource={recentDeliveries} onSelect={handleSelectDelivery} />
      </FlexBoxY>
    )
  }

  return null
}
