import React from 'react'

import styled from '@emotion/styled'

import { Tag } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { PsqlTableCells } from 'common/components/PsqlTable'

interface CellCostCodeProps {
  code?: string
  clazz?: string
  phaseCode?: string
  phaseEnabled?: boolean
  classEnabled?: boolean
}

const StyledTag = styled(Tag)`
  margin: 0;
`

export const CellCostCode = (props: CellCostCodeProps) => {
  const { code, clazz, phaseCode, classEnabled, phaseEnabled } = props

  if (!phaseEnabled && !classEnabled && !code) return <PsqlTableCells.NoContent />

  return (
    <FlexBoxX gridGap={4} alignItems="flex-start" justifyContent="flex-start" flexWrap="wrap">
      {phaseEnabled && <StyledTag>{phaseCode || 'N/A'}</StyledTag>}
      {code && <StyledTag>{code}</StyledTag>}
      {classEnabled && <StyledTag>{clazz || 'N/A'}</StyledTag>}
    </FlexBoxX>
  )
}
