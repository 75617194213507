import React, { useState } from 'react'

import { DataEditorProps } from 'react-spreadsheet'

import styled from '@emotion/styled'

import { Popover, Typography, Divider } from 'antd'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { formatDateString } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'
import { CompanyMaterialHit } from 'common/server/company_materials'

import { Autosuggest } from '../Autosuggest'

type OptionRowProps = {
  value: CompanyMaterialHit
}

const WrapperAttributes = styled(FlexBoxX)`
  & > div:last-child .ant-divider {
    display: none;
  }
`

const ItemValue = ({ label = '', value }) => {
  if (!value) {
    return null
  }

  return (
    <FlexBoxX justifyContent="flex-start" flexGrow={0}>
      {label && (
        <Typography.Text type="secondary" style={{ marginRight: 4, fontSize: 12 }}>
          {label}:
        </Typography.Text>
      )}

      <Typography.Text style={{ fontSize: 12, color: '#000000' }}>{value}</Typography.Text>

      <Divider type="vertical" />
    </FlexBoxX>
  )
}
const Image = (props) => (
  <img
    {...props}
    style={{ objectFit: 'contain', border: '1px solid #f0f0f0', borderRadius: 4, backgroundColor: '#FFFF', padding: 2 }}
  />
)

const OptionRow = ({ value }: OptionRowProps) => {
  const theme = useTheme()

  const imageSrc = value?.['image_url']

  return (
    <FlexBoxX justifyContent="space-between">
      <FlexBoxY alignItems="flex-start" mr="12px">
        <FlexBoxX alignItems="flex-start" style={{ color: '#000000' }}>
          {value.description}
        </FlexBoxX>
        <WrapperAttributes justifyContent="flex-start" color={theme.colors['gray-7']} flexWrap="wrap">
          <ItemValue value={value?.manufacturer || value?.['manufacturer_name']} />
          <ItemValue label="ID" value={value?.product_identifier} />
          <ItemValue label="SIZE" value={value?.size} />
          <ItemValue label="UNIT" value={value?.unit_name} />
          <ItemValue label="UNIT" value={value?.unit?.unit_name_with_increment_label} />
          <ItemValue label="MATERIAL" value={value?.material} />
        </WrapperAttributes>

        {value?.last_ordered && (
          <Typography.Text type="secondary" style={{ fontSize: 12, fontStyle: 'italic' }}>
            Last Ordered: {formatDateString(value?.last_ordered)}
          </Typography.Text>
        )}
      </FlexBoxY>

      {imageSrc && (
        <Popover content={<Image src={imageSrc} height={220} width={220} />} overlayStyle={{ zIndex: 9999 }}>
          <Image src={imageSrc} height={64} width={64} />
        </Popover>
      )}
    </FlexBoxX>
  )
}

type DescriptionEditorCellProps = {
  onSearchText: (searchText: string, options: {}) => Promise<CompanyMaterialHit[]>
  onSelect: (hit: CompanyMaterialHit, position: number) => void
  canCreateNewMaterial: boolean
} & DataEditorProps

type Option = {
  value: string
  hit: CompanyMaterialHit
}

// NOTE: This cannot be used by the vendor, it's custom for the contractor's database
export const DescriptionEditorCell = ({
  cell,
  onChange,
  row,
  onSearchText,
  onSelect,
  canCreateNewMaterial,
}: DescriptionEditorCellProps) => {
  const [options, setOptions] = useState<Option[]>([])
  const split_key = '!______________'

  const [value, setValue] = useState(cell.value)
  const timerId = React.useRef(null)

  React.useEffect(() => {
    setValue(cell.value)
  }, [cell.value])

  const handleSuggestionsFetchRequested = ({ value = '' }) => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }

    timerId.current = setTimeout(() => {
      onSearchText(value, { filters: 'active:true' }).then((hits) => {
        const newOptions = hits.map((hit) => ({
          value: `${hit.id}${split_key}${hit.description}`,
          hit,
        }))
        setOptions(newOptions)
      })
    }, 500)
  }

  const handleChange = (newValue) => {
    // HACK: Value needs to be a string and that's all that gets passed, descriptions aren't unique so can't use those
    const splitValue = newValue.split(split_key)

    // Need skip onChange if is the value from select because onChange start a recursive loop.
    if (!newValue.includes(split_key)) {
      const description = splitValue[splitValue.length - 1]
      // If the user does not have permission to create material, we oblige him to select one item and not allow to write a free text
      if (canCreateNewMaterial || (!canCreateNewMaterial && !description)) {
        onChange({ ...cell, value: description })
      }
      setValue(description)
    }
  }

  const handleSuggestionSelected = (_, { suggestion, suggestionIndex }) => {
    const newCompanyMaterial = {
      ...cell?.['materialRow']?.['company_material'],
      ...suggestion.hit,
    }

    cell['onSelectMaterial'](row, newCompanyMaterial)
    onSelect?.(suggestion.hit, suggestionIndex + 1)
  }

  const handleSuggestionsClearRequested = () => setOptions([])

  return (
    <Autosuggest<Option, unknown>
      suggestions={options}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      getSuggestionValue={(suggestion) => suggestion.value}
      renderSuggestion={(suggestion) => <OptionRow value={suggestion.hit} />}
      onSuggestionSelected={handleSuggestionSelected}
      inputProps={{
        value,
        onChange: (_, { newValue }) => handleChange(newValue),
        autoFocus: true,
      }}
      dropdownMinWidth={400}
    />
  )
}
