import React from 'react'

import Landing, { LandingProps } from 'common/pages/Landing'

import { AllProviders } from 'contractor/providers'
import {
  accept_invite,
  forgot_password,
  resend_confirmation,
  reset_password,
  sign_in,
  register,
  register_material_requester,
} from 'contractor/server/user'

const ContractorLanding = (props: LandingProps) => (
  <AllProviders>
    <Landing
      {...props}
      redirectPath={props['redirectPath'] || '/projects'}
      acceptInvite={accept_invite}
      forgotPassword={forgot_password}
      register={register}
      registerMaterialRequester={register_material_requester}
      resendConfirmation={resend_confirmation}
      resetPassword={reset_password}
      signIn={sign_in}
      withVendorLink
    />
  </AllProviders>
)

export default ContractorLanding
