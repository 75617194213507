import moment from 'moment/moment'

import { message, Modal } from 'antd'

import { useNotifyCommitment } from 'common/hooks/use-notify-commitment'

import { useStores } from 'contractor/hooks/use-stores'
import { Commitment } from 'contractor/server/commitments'

type UseMaterialCommitmentProps = {
  createCommitmentPayload: (options?: {}) => Commitment
  goBack: () => void
  setSubmitting: (submitting: boolean) => void
}

export const useMaterialCommitment = (props: UseMaterialCommitmentProps) => {
  const { commitmentStore, uploaderStore } = useStores()
  const { notifyCommitment } = useNotifyCommitment()
  const { createCommitmentPayload, goBack, setSubmitting } = props
  const payload = createCommitmentPayload({ submitted_at: moment().toISOString() })

  const handleLogError = (error, defaultMessage = '') => {
    if (error?.response?.data?.error) {
      message.error({
        content: error.response.data.error,
        duration: 8,
      })
    } else {
      message.error(defaultMessage || `Unable to save the commitment`)
    }
  }
  const validateCommitmentProject = () => {
    return {
      message: 'Must select a project.',
      invalid: !payload.project_id,
    }
  }

  const validateCommitmentName = () => {
    return {
      message: 'Must enter a name for the commitment.',
      invalid: !payload.commitment_name,
    }
  }

  const validateCommitmentVendor = () => {
    return {
      message: 'Must specify a vendor.',
      invalid: !payload.company_vendor_id,
    }
  }

  const validateUploads = () => {
    return {
      message: 'Uploads have not completed yet, please try again',
      invalid: !uploaderStore.checkIfAllUploadsCompleted(),
    }
  }

  const validateCommitmentMaterial = () => {
    return {
      message: 'Must specify materials or add an attachment',
      invalid: uploaderStore.signedIds('purchaser_files').length === 0 && commitmentStore.totalMaterialsCount === 0,
    }
  }

  const validateCommitmentQuantities = (isSubmitting: boolean) => {
    if (!isSubmitting) return { message: '', invalid: false }

    return {
      message: 'Must specify quantities for all materials',
      invalid: commitmentStore.selectedCommitment.commitment_materials.some((material) => !material.quantity),
    }
  }

  const validateCommitment = (isSubmitting?: boolean) => {
    const validations = [
      validateCommitmentMaterial(),
      validateUploads(),
      validateCommitmentProject(),
      validateCommitmentVendor(),
      validateCommitmentName(),
      validateCommitmentQuantities(isSubmitting),
    ]
    if (validations.some((validation) => validation.invalid)) {
      const validation = validations.find((validation) => validation.invalid)
      const title = validation?.message
      Modal.error({ title })
      return false
    }
    return true
  }

  const handleUpdateCommitment = async () => {
    if (!validateCommitment()) return

    try {
      setSubmitting(true)
      await commitmentStore.updateCommitment(payload)

      notifyCommitment({
        message: 'Commitment Updated',
        commitmentId: payload.id,
        commitmentType: payload.commitment_type,
      })

      goBack()
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleSaveDraftCommitment = async () => {
    if (!validateCommitment()) return

    try {
      setSubmitting(true)
      let commitmentId = ''
      const commitment = await commitmentStore.createCommitment(payload)
      commitmentId = commitment.id

      notifyCommitment({
        message: 'Commitment saved as draft',
        commitmentId: commitmentId,
        commitmentType: payload.commitment_type,
      })

      goBack()
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleCreateSubmittedCommitment = async () => {
    if (!validateCommitment(true)) return

    try {
      setSubmitting(true)
      let commitmentId = 'new'
      await commitmentStore.createCommitment(payload).then((commitment) => {
        commitmentId = commitment.id
      })

      notifyCommitment({
        message: 'Commitment submitted.',
        commitmentId: commitmentId,
        commitmentType: payload.commitment_type,
      })

      goBack()
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleSubmitCommitment = async () => {
    if (!validateCommitment(true)) return

    try {
      setSubmitting(true)
      await commitmentStore.updateCommitment(payload)

      notifyCommitment({
        message: 'Commitment submitted',
        commitmentId: payload.id,
        commitmentType: payload.commitment_type,
      })

      goBack()
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  return {
    validateCommitment,
    handleUpdateCommitment,
    handleSubmitCommitment,
    handleCreateSubmittedCommitment,
    handleSaveDraftCommitment,
  }
}
