import React from 'react'

import { Button, Checkbox, Form, message, Select } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { useQuery } from 'common/hooks/use-query'
import { NotificationFrequency, HomePageOptions } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

interface UserSettingsProps {
  default_home_page: HomePageOptions
  notification_frequency: NotificationFrequency
  daily_reminder_email: boolean
}

export const Settings = observer(() => {
  const { userStore, notificationStore, userSettingStore } = useStores()

  const [form] = Form.useForm()

  const { isLoading } = useQuery(() =>
    userSettingStore.indexSettings().then((data) => form.setFieldsValue(data?.default_home_page)),
  )

  React.useEffect(() => {
    form.setFieldsValue(userStore.currentUser)
  }, [userStore.currentUser])

  const onSubmit = async (values: UserSettingsProps): Promise<void> => {
    const userOtherSettings = {
      default_home_page: values.default_home_page,
    }
    const notificationSettings = {
      notification_frequency: values.notification_frequency,
      daily_reminder_email: values.daily_reminder_email,
    }

    try {
      await Promise.all([
        notificationStore.updateSettings(notificationSettings),
        userSettingStore.updateSettings(userOtherSettings),
      ])

      userStore.showUser()
      message.success('Settings updated successfully')
    } catch (err) {
      console.error('Unable to update', err)
      message.error('Unable to update your settings')
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <Page.Header p={8} />

      <Page.Content>
        <FlexBoxY alignItems="flex-start">
          <Form layout="vertical" form={form} name="edit-user-notification-setting" onFinish={onSubmit}>
            <Form.Item name="default_home_page" label="Default Home Page">
              <Select style={{ width: '300px', marginRight: '20px' }}>
                {Object.keys(HomePageOptions).map((option) => (
                  <Select.Option key={option} value={option}>
                    {HomePageOptions[option]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="notification_frequency" label="Frequency you will receive notification emails">
              <Select style={{ width: '300px', marginRight: '20px' }}>
                {Object.keys(NotificationFrequency).map((role) => (
                  <Select.Option key={role} value={role}>
                    {NotificationFrequency[role]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item valuePropName="checked" name="daily_reminder_email">
              <Checkbox>Daily reminder email </Checkbox>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        </FlexBoxY>
      </Page.Content>
    </>
  )
})
