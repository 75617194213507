import { BindAll } from 'lodash-decorators'

import { action, observable } from 'mobx'

import {
  CostCodeClass,
  create_cost_code_class,
  CreateCostCodeClass,
  get_all_cost_code_classes,
  get_cost_code_class,
  update_cost_code_class,
  UpdateCostCodeClass,
} from 'common/server/cost_codes/cost_code_classes'
import {
  CostCodeNumber,
  create_cost_code_number,
  CreateCostCodeNumber,
  get_all_cost_code_numbers,
  get_cost_code_number,
  update_cost_code_number,
  UpdateCostCodeNumber,
} from 'common/server/cost_codes/cost_code_numbers'
import {
  CostCodePhase,
  create_cost_code_phase,
  CreateCostCodePhase,
  export_records as export_phase_records,
  facets as phase_code_facets,
  get_all_cost_code_phases,
  get_cost_code_phase,
  update_cost_code_phase,
  UpdateCostCodePhase,
} from 'common/server/cost_codes/cost_code_phases'
import {
  CostCode,
  export_records,
  facets as cost_code_facets,
  index,
  tags,
  upsert,
  UpsertCostCodeParam,
} from 'common/server/cost_codes/cost_codes'
import ListBaseStore from 'common/stores/ListBaseStore'

class CostCodePhaseListStore extends ListBaseStore<CostCodePhase> {
  index = get_all_cost_code_phases
  getFacets = phase_code_facets
  export = export_phase_records
}

class CostCodeNumberListStore extends ListBaseStore<CostCodeNumber> {
  index = get_all_cost_code_numbers
}

class CostCodeClassListStore extends ListBaseStore<CostCodeClass> {
  index = get_all_cost_code_classes
}

class CostCodeListStore extends ListBaseStore<CostCode> {
  index = index
  getFacets = cost_code_facets
  export = export_records
}

@BindAll()
export default class CostCodeStore {
  @observable selectedCostCode: Nullable<CostCode> = null
  @observable selectedPhaseCode: Nullable<CostCodePhase> = null

  costCodeTags = observable.array<string>([])

  costCodePhaseListStore: CostCodePhaseListStore
  costCodeNumberListStore: CostCodeNumberListStore
  costCodeClassListStore: CostCodeClassListStore
  costCodeListStore: CostCodeListStore
  constructor() {
    this.costCodePhaseListStore = new CostCodePhaseListStore()
    this.costCodeNumberListStore = new CostCodeNumberListStore()
    this.costCodeClassListStore = new CostCodeClassListStore()
    this.costCodeListStore = new CostCodeListStore()
  }

  @action
  async getCostCodeTags() {
    const { data } = await tags()
    this.costCodeTags.replace(data?.cost_code_tags || [])
  }

  fetchAllActiveCostCodes() {
    this.costCodeListStore.setFilter('active', true, true, true)
    return this.costCodeListStore.fetchAllRecords()
  }

  fetchAllActivePhaseCodes() {
    this.costCodePhaseListStore.setFilter('active', true, true, true)
    return this.costCodePhaseListStore.fetchAllRecords()
  }

  @action
  selectFromList(id: Nullable<string>) {
    if (!id) {
      this.selectedCostCode = null
    } else {
      this.selectedCostCode = this.costCodeListStore.records.find((costCode) => costCode.id === id)
    }

    return this.selectedCostCode
  }

  @action
  selectPhaseFromList(id: Nullable<string>) {
    if (!id) {
      this.selectedPhaseCode = null
    } else {
      this.selectedPhaseCode = this.costCodePhaseListStore.records.find((phaseCode) => phaseCode.id === id)
    }

    return this.selectedPhaseCode
  }

  async upsert(costCode: UpsertCostCodeParam) {
    const { data } = await upsert([costCode])
    return data?.cost_codes
  }

  // Cost Code
  async getCostCodeNumber(id: string) {
    const { data } = await get_cost_code_number(id)
    return data
  }
  async createCostCodeNumber(costCodeNumber: CreateCostCodeNumber) {
    const { data } = await create_cost_code_number(costCodeNumber)
    return data
  }
  async updateCostCodeNumber(costCodeNumber: UpdateCostCodeNumber) {
    const { data } = await update_cost_code_number(costCodeNumber)
    return data
  }

  // Phase Code
  async getCostCodePhase(id: string) {
    const { data } = await get_cost_code_phase(id)
    return data
  }
  async createCostCodePhase(costCodePhase: CreateCostCodePhase) {
    const { data } = await create_cost_code_phase(costCodePhase)
    return data
  }
  async updateCostCodePhase(costCodePhase: UpdateCostCodePhase) {
    const { data } = await update_cost_code_phase(costCodePhase)
    return data
  }

  // Class Code
  async getCostCodeClass(id: string) {
    const { data } = await get_cost_code_class(id)
    return data
  }
  async createCostCodeClass(costCodeClass: CreateCostCodeClass) {
    const { data } = await create_cost_code_class(costCodeClass)
    return data
  }
  async updateCostCodeClass(costCodeClass: UpdateCostCodeClass) {
    const { data } = await update_cost_code_class(costCodeClass)
    return data
  }
}
