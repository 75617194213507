import React from 'react'

import { Drawer, DrawerBody, DrawerProps } from 'common/components/Drawer'

import { Content } from './content'

export type ReportIssueHistoryProps = {
  invoiceInboxId?: string
  filters?: { invoice_inbox_attachment_id: string }
} & Pick<DrawerProps, 'onClose' | 'open'>

export const ReportIssueHistory = (props: ReportIssueHistoryProps) => {
  const { invoiceInboxId, filters, ...rest } = props

  return (
    <Drawer title="Issue History" width={500} bodyStyle={{ flexDirection: 'column' }} {...rest}>
      <DrawerBody>
        <Content invoiceInboxId={invoiceInboxId} filters={filters} />
      </DrawerBody>
    </Drawer>
  )
}
