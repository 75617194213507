import React, { useState, useMemo } from 'react'

import ReactAutosuggest, { AutosuggestProps as ReactAutosuggestProps } from 'react-autosuggest'
import { usePopper } from 'react-popper'
import { Portal } from 'react-portal'

import { v4 as uuid_v4 } from 'uuid'

import styled from '@emotion/styled'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .react-autosuggest__container {
    height: inherit;
    width: inherit;
  }
`

const InputContainer = styled.span`
  height: inherit;
  width: inherit;

  .react-autosuggest__input {
    height: inherit;
    width: inherit;
    box-sizing: border-box;
    margin: 0;
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    border: none;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }
`

const SuggestionsContainer = styled.span`
  z-index: 1050;

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    background-color: #fff;
    border-radius: 2px;
    width: 100%;
    height: 100%;
    max-height: 256px;
    overflow-y: auto;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    display: block;
    min-height: 32px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #f5f5f5;
  }
`

type AutosuggestProps<TSuggestion, TSection> = ReactAutosuggestProps<TSuggestion, TSection> & {
  dropdownMinWidth?: number
}

const idPortal = uuid_v4()

/*
  This component is created specifically to use in the spreadsheet, we did a problem using
  with antd autocomplete (User needs double click or double key press to start a write). 
  We styled this component based antd autocomplete, so both have the same appearance.
*/
export const Autosuggest = <TSuggestion, TSection>({
  dropdownMinWidth,
  ...props
}: AutosuggestProps<TSuggestion, TSection>) => {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  })

  const containerMinWidth = useMemo(() => {
    if (!dropdownMinWidth) {
      return referenceElement?.offsetWidth
    }

    return Math.max(dropdownMinWidth, referenceElement?.offsetWidth)
  }, [dropdownMinWidth, referenceElement?.offsetWidth])

  const renderSuggestionsContainer = ({ children, containerProps }) => {
    return (
      <Portal node={document.getElementById(idPortal)}>
        <SuggestionsContainer
          ref={setPopperElement}
          style={{ ...styles.popper, minWidth: containerMinWidth }}
          {...attributes.popper}
        >
          <div {...containerProps}>{children}</div>
        </SuggestionsContainer>
      </Portal>
    )
  }

  const renderInputComponent = (inputProps) => {
    return (
      <InputContainer ref={setReferenceElement}>
        <input {...inputProps} />
      </InputContainer>
    )
  }

  return (
    <Wrapper>
      <ReactAutosuggest<TSuggestion, TSection>
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderInputComponent={renderInputComponent}
        {...props}
      />
      <div id={idPortal} />
    </Wrapper>
  )
}
