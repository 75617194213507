import axios from 'axios'

import { StandardIndexRequest, StandardIndexResponse } from 'common/server/server_types'

export interface CostCodePhase {
  id: string
  code: string
  data_source: 'INTERNAL' | 'EXTERNAL'
  description?: string
  discarded_at?: string
  project_ids?: string[]
  projects?: {
    id: string
    name: string
    project_id: string
  }[]
}

export interface CreateCostCodePhase {
  code: string
  description?: string
  active: boolean
  project_ids_relation?: string[]
  project_ids?: string
}

export interface UpdateCostCodePhase {
  id: string
  code?: string
  description?: string
  active?: boolean
  project_ids_relation?: string[]
  project_ids?: string
}

export function create_cost_code_phase(cost_code_phase: CreateCostCodePhase) {
  return axios.post<CostCodePhase>('/cost_code_phases', { cost_code_phase })
}

export function update_cost_code_phase(cost_code_phase: UpdateCostCodePhase) {
  return axios.patch<CostCodePhase>(`/cost_code_phases/${cost_code_phase.id}`, { cost_code_phase })
}

export function get_all_cost_code_phases(params: StandardIndexRequest) {
  return axios.get<StandardIndexResponse<CostCodePhase[]>>('/cost_code_phases', { params })
}

export function get_cost_code_phase(id: string) {
  return axios.get<CostCodePhase>(`/cost_code_phases/${id}`)
}

export function facets() {
  return axios.get('/cost_code_phases/facets')
}

export function export_records(params: { filters: { [key: string]: string } }) {
  const url = `/cost_code_phases/export`

  return axios.post(url, params)
}
