import { createContext } from 'react'

import { list } from 'common/server/units'
import UnitStore from 'common/stores/UnitStore'
import UploaderStore from 'common/stores/UploaderStore'

import AddressStore from 'contractor/stores/AddressStore'
import AddressToStore from 'contractor/stores/AddressToStore'
import CommitmentStore from 'contractor/stores/CommitmentStore'
import CompanyMaterialStore from 'contractor/stores/CompanyMaterialStore'
import CompanySettingStore from 'contractor/stores/CompanySettingStore'
import CompanyStore from 'contractor/stores/CompanyStore'
import CompanyVendorStore from 'contractor/stores/CompanyVendorStore'
import CostCodeStore from 'contractor/stores/CostCodeStore'
import DashboardStore from 'contractor/stores/DashboardStore'
import DeliveryStore from 'contractor/stores/DeliveryStore'
import EnvironmentsStore from 'contractor/stores/EnvironmentsStore'
import FlatfileStore from 'contractor/stores/FlatfileStore'
import IntegrationStore from 'contractor/stores/IntegrationStore'
import InvoiceInboxStore from 'contractor/stores/InvoiceInboxStore'
import InvoiceSettingsStore from 'contractor/stores/InvoiceSettingsStore'
import InvoiceStateStore from 'contractor/stores/InvoiceStateStore'
import InvoiceStore from 'contractor/stores/InvoiceStore'
import ManufacturerMaterialStore from 'contractor/stores/ManufacturerMaterialStore'
import NotificationStore from 'contractor/stores/NotificationStore'
import OrderMaterialStore from 'contractor/stores/OrderMaterialStore'
import OrderPackageStore from 'contractor/stores/OrderPackageStore'
import OrderStore from 'contractor/stores/OrderStore'
import PriceSheetStore from 'contractor/stores/PriceSheetStore'
import ProjectGroupsStore from 'contractor/stores/ProjectGroupsStore'
import ProjectInvoiceAssigneeStore from 'contractor/stores/ProjectInvoiceAssigneeStore'
import ProjectStore from 'contractor/stores/ProjectStore'
import PublicOrderStore from 'contractor/stores/PublicOrderStore'
import QuoteStore from 'contractor/stores/QuoteStore'
import UserSettingStore from 'contractor/stores/UserSettingStore'
import UserStore from 'contractor/stores/UserStore'
import VendorStore from 'contractor/stores/VendorStore'

export interface StoresMapping {
  addressStore: AddressStore
  dashboardStore: DashboardStore
  companyMaterialStore: CompanyMaterialStore
  companySettingStore: CompanySettingStore
  companyVendorStore: CompanyVendorStore
  deliveryStore: DeliveryStore
  orderStore: OrderStore
  commitmentStore: CommitmentStore
  orderMaterialStore: OrderMaterialStore
  projectStore: ProjectStore
  companyStore: CompanyStore
  publicOrderStore: PublicOrderStore
  uploaderStore: UploaderStore
  userStore: UserStore
  projectGroupsStore: ProjectGroupsStore
  environmentsStore: EnvironmentsStore
  orderPackageStore: OrderPackageStore
  unitsStore: UnitStore
  vendorStore: VendorStore
  costCodeStore: CostCodeStore
  invoiceInboxStore: InvoiceInboxStore
  invoiceStore: InvoiceStore
  integrationStore: IntegrationStore
  invoiceStateStore: InvoiceStateStore
  notificationStore: NotificationStore
  userSettingStore: UserSettingStore
  addressToStore: AddressToStore
  quoteStore: QuoteStore
  projectInvoiceAssigneeStore: ProjectInvoiceAssigneeStore
  flatfileStore: FlatfileStore
  invoiceSettingsStore: InvoiceSettingsStore
  manufacturerMaterialStore: ManufacturerMaterialStore
  priceSheetStore: PriceSheetStore
}

export const storeInstances = {
  addressStore: new AddressStore(),
  dashboardStore: new DashboardStore(),
  companyMaterialStore: new CompanyMaterialStore(),
  companySettingStore: new CompanySettingStore(),
  companyVendorStore: new CompanyVendorStore(),
  deliveryStore: new DeliveryStore(),
  orderStore: new OrderStore(),
  companyStore: new CompanyStore(),
  orderMaterialStore: new OrderMaterialStore(),
  projectStore: new ProjectStore(),
  publicOrderStore: new PublicOrderStore(),
  uploaderStore: new UploaderStore(),
  userStore: new UserStore(),
  projectGroupsStore: new ProjectGroupsStore(),
  environmentsStore: new EnvironmentsStore(),
  orderPackageStore: new OrderPackageStore(),
  unitsStore: new UnitStore(list),
  vendorStore: new VendorStore(),
  costCodeStore: new CostCodeStore(),
  invoiceInboxStore: new InvoiceInboxStore(),
  invoiceStore: new InvoiceStore(),
  integrationStore: new IntegrationStore(),
  invoiceStateStore: new InvoiceStateStore(),
  notificationStore: new NotificationStore(),
  userSettingStore: new UserSettingStore(),
  addressToStore: new AddressToStore(),
  quoteStore: new QuoteStore(),
  projectInvoiceAssigneeStore: new ProjectInvoiceAssigneeStore(),
  flatfileStore: new FlatfileStore(),
  invoiceSettingsStore: new InvoiceSettingsStore(),
  manufacturerMaterialStore: new ManufacturerMaterialStore(),
  commitmentStore: new CommitmentStore(),
  priceSheetStore: new PriceSheetStore(),
}

export const storesContext = createContext(storeInstances)
