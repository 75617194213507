import React from 'react'

import { Tag } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { OrdersList } from 'common/components/OrdersList'

interface Props {
  tags: Array<string>
}

export function CellTags(props: Props) {
  const { tags = [] } = props

  if (tags.length === 0) {
    return <OrdersList.Cell.NoContent />
  }

  return (
    <FlexBoxX alignItems="flex-start" justifyContent="flex-start" flexWrap="wrap" gridRowGap={8}>
      {tags.map((tag, index) => (
        <Tag key={index}>{tag}</Tag>
      ))}
    </FlexBoxX>
  )
}
