import React, { useState } from 'react'

import styled from '@emotion/styled'

import { DownOutlined, FilePdfFilled, UpOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Tabs as AntdTabs } from 'antd'

import { Box } from 'common/components/boxes'
import theme from 'common/styles/theme'

import { TabAttachments } from './tabs_attachments'
import { TabVendorChat } from './tabs_vendor_chat'

const TabsStyled = styled(AntdTabs)`
  flex: 1;
  .ant-tabs-nav {
    margin: 0 !important;
  }
`

type TabsProps = {
  canInputVendorData?: boolean
  isPoLocked?: boolean
}

export const Tabs = ({ canInputVendorData, isPoLocked }: TabsProps) => {
  const [activeTab, setActiveTab] = useState('chat')
  const [isCollapsed, setIsCollapsed] = useState(true)

  const Icon = isCollapsed ? DownOutlined : UpOutlined

  const handleTabClick = (key: string) => {
    setActiveTab(key)
  }

  const handleCollapseClick = (key: string) => {
    if (activeTab === key) {
      setIsCollapsed(!isCollapsed)
      return
    }
    setIsCollapsed(false)
  }

  const items = [
    {
      label: (
        <Box onClick={() => handleCollapseClick('chat')} paddingX="8">
          <UserOutlined />
          Vendor chat
        </Box>
      ),
      key: 'chat',
      children: <TabVendorChat opened={!isCollapsed} />,
    },
    {
      label: (
        <Box onClick={() => handleCollapseClick('attachments')} paddingX="8" data-cy="tab-attachments">
          <FilePdfFilled />
          Attachments
        </Box>
      ),
      key: 'attachments',
      children: (
        <TabAttachments opened={!isCollapsed} isPoLocked={isPoLocked} canInputVendorData={canInputVendorData} />
      ),
    },
  ]

  return (
    <Box display="flex" backgroundColor={theme.colors.white}>
      <TabsStyled
        activeKey={activeTab}
        onChange={handleTabClick}
        size="small"
        tabBarExtraContent={{
          right: (
            <Button
              type="text"
              size="small"
              style={{ marginRight: 12 }}
              icon={
                <Icon
                  style={{ fontSize: 10, color: theme.colors['primary-6'] }}
                  onClick={() => setIsCollapsed(!isCollapsed)}
                />
              }
            />
          ),
        }}
        items={items}
      />
    </Box>
  )
}
