import React, { useState, useEffect } from 'react'

import { Alert, Form, Input, Divider, Button } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { CostCodeClass } from 'common/server/cost_codes/cost_code_classes'

import { useStores } from 'contractor/hooks/use-stores'

type UpsertCostCodeClassProps = {
  onCancel: () => void
  onSubmit: (data: CostCodeClass) => void
  initialValues?: {
    id: string
    code: string
    description: string
  }
}

export const UpsertCostCodeClass = observer<UpsertCostCodeClassProps, DrawerRef>(
  ({ onCancel, onSubmit, initialValues }, ref) => {
    const { costCodeStore } = useStores()

    const [alertMessage, setAlertMessage] = useState('')
    const [isSubmitting, setSubmitting] = useState(false)

    const [form] = Form.useForm()

    const handleCancel = () => {
      form.resetFields()
      setAlertMessage('')
      onCancel()
    }

    const handleSubmit = async (formValues) => {
      setSubmitting(true)

      try {
        let costCodeClass = null
        if (initialValues?.id) {
          costCodeClass = await costCodeStore.updateCostCodeClass({
            id: initialValues?.id,
            code: formValues?.code,
            description: formValues?.description,
          })
        } else {
          costCodeClass = await costCodeStore.createCostCodeClass({
            code: formValues?.code,
            description: formValues?.description,
            active: true,
          })
        }
        onSubmit(costCodeClass)
        handleCancel()
      } catch (error) {
        if (error.response.data.error.includes('duplicate key value violates')) {
          setAlertMessage('Unable to save due to duplicate cost code.')
        } else {
          setAlertMessage('Unable to add cost code')
        }
      } finally {
        setSubmitting(false)
      }
    }

    useEffect(() => {
      form.setFieldsValue(initialValues)
    }, [initialValues])

    return (
      <Drawer
        bgGray
        ref={ref}
        title={initialValues?.id ? 'Update Class' : 'Add New Class'}
        onClose={handleCancel}
        width={378}
      >
        <Box width="100%" height="100%" p={16} overflow="auto">
          {alertMessage && <Alert type="error" message={alertMessage} style={{ marginBottom: '20px' }} />}

          <Form layout="vertical" form={form} onFinish={handleSubmit} style={{ width: '100%' }}>
            <Form.Item name="code" label="Code" rules={[{ required: true, message: 'Code is required!' }]}>
              <Input style={{ width: '100%' }} required />
            </Form.Item>

            <Form.Item name="description" label="Description">
              <Input style={{ width: '100%' }} />
            </Form.Item>

            <Divider />

            <Box display="flex" justifyContent="space-between">
              <Button onClick={onCancel} loading={isSubmitting} style={{ width: 100 }}>
                Cancel
              </Button>

              <Button type="primary" htmlType="submit" loading={isSubmitting} style={{ width: 100 }}>
                {initialValues?.id ? 'Update' : 'Create'}
              </Button>
            </Box>
          </Form>
        </Box>
      </Drawer>
    )
  },
  { forwardRef: true },
)
