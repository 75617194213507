import React from 'react'

import Icon from '@ant-design/icons'

const SubBaseSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M87.5727 14.1011C99.2981 17.1769 108.192 25.7277 111.362 36.9852C112.931 42.5524 113.178 50.6726 111.854 53.6562C110.469 56.8243 107.792 58.7313 104.407 58.9774C100.775 59.2234 97.7593 57.624 95.9128 54.4867C95.1434 53.1948 95.0819 52.7334 94.8664 48.3042C94.7741 45.659 94.4971 42.8908 94.2817 42.1218C93.2661 38.4001 90.2809 34.5553 87.0188 32.8328C79.9097 29.0188 71.3235 31.4795 67.0765 38.5231C65.1684 41.722 65.0453 42.9523 65.0453 57.4702C65.0453 65.7134 65.1684 70.9731 65.3838 72.0189C66.184 75.8636 68.6152 79.4008 71.9389 81.5539C74.2163 83.0303 76.0936 83.584 79.7558 83.9531C82.0024 84.1684 82.9565 84.4144 84.2182 85.1219C88.4037 87.4595 89.9424 92.5039 87.7882 96.81C85.6031 101.178 81.8485 102.5 74.9241 101.362C67.7535 100.163 61.8447 97.1176 56.8591 92.1348C52.7045 87.9824 49.7808 82.8765 48.2113 77.0325L47.3804 73.9566L47.3496 57.9623C47.3496 40.5839 47.3804 39.9995 49.1038 35.0474C52.9815 23.9129 62.4294 15.9158 74.401 13.6397C77.54 13.0553 84.4952 13.2706 87.5727 14.1011Z"
      fill="white"
    />
    <path
      d="M86.434 59.0079C98.7441 61.5609 108.161 70.5731 111.577 83.0302C112.285 85.6138 112.285 85.8291 112.285 102.562V119.479L111.423 122.555C108.192 134.243 99.5135 142.67 87.5112 145.839C86.0032 146.238 83.7566 146.515 81.0484 146.607C76.2782 146.792 72.339 146.146 68.1228 144.516C59.2288 141.102 51.9043 133.32 48.9499 124.215C47.7189 120.401 47.3804 117.91 47.3496 112.712C47.3496 107.237 47.6266 106.16 49.627 103.853C52.7968 100.255 58.9826 100.101 62.3679 103.577C64.399 105.637 64.8299 107.145 65.0761 112.712C65.2915 118.371 65.7531 119.971 67.9382 122.954C71.2619 127.445 76.8938 129.783 82.2794 128.86C88.3113 127.845 93.4816 122.831 94.5895 116.895C94.9895 114.742 94.9895 90.3814 94.5895 88.2283C94.1278 85.7369 92.3121 82.292 90.4656 80.4772C87.7881 77.7705 83.5412 75.9557 80.002 75.9557C76.8322 75.9557 73.8778 74.2333 72.2159 71.4343C71.1388 69.558 70.8618 66.1439 71.662 64.0523C72.4006 62.1453 74.3702 60.023 76.2782 59.1617C78.2479 58.239 82.4025 58.1775 86.434 59.0079Z"
      fill="white"
    />
    <rect x="0.5" y="0.5" width="159" height="159" stroke="#7B61FF" strokeLinejoin="round" strokeDasharray="2 2" />
  </svg>
)

export const SubBaseWhiteIcon = () => <Icon component={SubBaseSvg} />
