export const passwordRules = [
  { required: true, message: 'Please input your Password!' },
  { min: 8, message: 'Password must be at least 8 characters long' },
  {
    pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W])/,
    message: 'Password must include one uppercase letter, one lowercase letter, one digit, and one special character.',
  },
]

// Pulled this rule straight from Antd Form documentation
export const passwordConfirmationRules = [
  {
    required: true,
    message: 'Please confirm your password!',
  },
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ({ getFieldValue }) => ({
    validator(_rule, value): Promise<void> {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve()
      }
      return Promise.reject('The two passwords that you entered do not match!')
    },
  }),
]
