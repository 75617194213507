import React from 'react'

import _ from 'lodash'

import styled from '@emotion/styled'

import { ArrowLeftOutlined } from '@ant-design/icons'
import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import { Button, Table, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'

import { UnmatchedMaterial } from 'contractor/server/quote'

type LineItemsTableProps = {
  unmatchedMaterials: UnmatchedMaterial[]
  onChange: (value: UnmatchedMaterial[]) => void
  handleImportQuoteLineItem: (payload: UnmatchedMaterial) => void
}

const TableStyled = styled(Table)`
  width: 100%;
  overflow: auto;

  tr.disabled-table-row:hover > td,
  .locked-table-row {
    background-color: ${({ theme }) => theme.colors['gray-3']};
    cursor: not-allowed;
    padding: 0;
    margin: 0;
    magin-left: 16px;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    position: relative;
    padding: 12px 8px;
    overflow-wrap: break-word;
  }
`

export const UnmatchedMaterialsTable = observer<LineItemsTableProps>((props: LineItemsTableProps) => {
  const { unmatchedMaterials, onChange, handleImportQuoteLineItem } = props

  if (!unmatchedMaterials) return null

  const handleRemove = (id: string) => {
    const newData = _.filter(unmatchedMaterials, (item) => item.id !== id)
    onChange(newData)
  }

  const columns = [
    {
      title: 'Unmatched Materials',
      key: 'description',
      minWidth: 250,
      render: (record: UnmatchedMaterial) => {
        return record?.description
      },
    },
    {
      title: 'Quantity',
      key: 'quantity',
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      render: (record: UnmatchedMaterial) => {
        return record?.quantity || 0
      },
    },
    {
      title: 'Unit',
      key: 'unit',
      width: 125,
      minWidth: 125,
      render: (record: UnmatchedMaterial) => {
        return record.unit_name
      },
    },
    {
      title: 'Unit cost',
      key: 'unit_cost',
      width: 110,
      minWidth: 110,
      render: (record: UnmatchedMaterial) => {
        return record.unit_price
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 75,
      minWidth: 75,
      render: (record: UnmatchedMaterial) => {
        return (
          <Box width="100%" display="flex" alignItems="center">
            <Tooltip title="Import extracted material from the vendor quote into your order.">
              <Button
                shape="circle"
                onClick={() => {
                  handleImportQuoteLineItem(record)
                  handleRemove(record.id)
                }}
              >
                <ArrowLeftOutlined style={{ paddingTop: 4 }} />
              </Button>
            </Tooltip>
            <Tooltip title="Remove extracted suggestio.">
              <Button style={{ marginLeft: 4 }} shape="circle" onClick={() => handleRemove(record.id)}>
                <DeleteOutlined style={{ paddingTop: 4 }} />
              </Button>
            </Tooltip>
          </Box>
        )
      },
    },
  ]

  return <TableStyled rowKey="id" dataSource={unmatchedMaterials} columns={columns} pagination={false} />
})
