import React from 'react'

import { Space, Typography, Checkbox } from 'antd'

import { Box } from 'common/components/boxes'

interface Props {
  companyAttributes: string[]
  description: string
  costCode: string
  productIdentifier: string
  unit: string
  material: string
  active: boolean
}

const style = { fontSize: 12, whiteSpace: 'nowrap' } as React.CSSProperties

export function CompanyMaterialMobileItem(props: Props) {
  const { companyAttributes = [], description, costCode, productIdentifier, unit, material, active } = props

  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Typography.Text>{description}</Typography.Text>

      <Box display="flex" alignItems="center" flexWrap="wrap">
        {companyAttributes.includes('cost_code_id') && (
          <Typography.Text type="secondary" style={style}>
            {`COST CODE: ${costCode || 'N/A'}`}
          </Typography.Text>
        )}

        {companyAttributes.includes('product_identifier') && (
          <Typography.Text type="secondary" style={style}>
            &nbsp;{`| PROD. ID: ${productIdentifier || 'N/A'}`}
          </Typography.Text>
        )}

        {companyAttributes.includes('unit') && (
          <Typography.Text type="secondary" style={style}>
            &nbsp;{`| UNIT: ${unit || 'N/A'}`}
          </Typography.Text>
        )}

        {companyAttributes.includes('material') && (
          <Typography.Text type="secondary" style={style}>
            &nbsp;{`| MATERIAL: ${material || 'N/A'}`}
          </Typography.Text>
        )}
      </Box>

      <Checkbox checked={active}>Active</Checkbox>
    </Space>
  )
}
