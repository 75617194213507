import React from 'react'

import { Checkbox } from 'antd'

import { PsqlTableCells } from 'common/components/PsqlTable'
import { PsqlColumn } from 'common/components/PsqlTable/psql_table_provider'
import { formatEnumValue } from 'common/helpers/formatters'

import { ConsolidatedCompanyMaterials } from 'contractor/server/company_materials'

import { CompanyMaterialCells } from '../components/cells'

interface Props {
  canUseHistoricalCost: boolean
  companyAttributes: Array<string>
  costCodeColumn: {
    phaseEnabled: boolean
    classEnabled: boolean
    independentPhaseCodesEnabled: boolean
  }
}

export function columnsFactory(props: Props): Array<PsqlColumn<ConsolidatedCompanyMaterials.CompanyMaterial>> {
  const { canUseHistoricalCost, companyAttributes = [], costCodeColumn } = props

  const baseColumns: Array<PsqlColumn<ConsolidatedCompanyMaterials.CompanyMaterial>> = []

  companyAttributes.forEach((columnName: string) => {
    switch (columnName) {
      case 'cost_code_id':
        baseColumns.push({
          Header: 'Cost Code',
          accessor: 'cost_code',
          width: 180,
          Cell: ({ row: { original } }) => (
            <CompanyMaterialCells.CostCode
              code={original?.cost_code?.code}
              codeDescription={original?.cost_code?.description}
              phaseCode={original?.cost_code?.phase_code}
              phaseCodeDescription={original?.cost_code?.phase_code_description}
              clazz={original?.cost_code?.clazz}
              clazzDescription={original?.cost_code?.clazz_description}
              classEnabled={costCodeColumn.classEnabled}
              phaseEnabled={costCodeColumn.phaseEnabled}
            />
          ),
        })

        if (costCodeColumn.independentPhaseCodesEnabled) {
          baseColumns.push({
            Header: 'Phase Code',
            accessor: 'cost_code_phase',
            width: 180,
            Cell: ({ value }) => value?.code || 'N/A',
          })
        }
        break
      case 'preferred_vendor_prices':
        baseColumns.push({
          Header: 'Preferred Vendors',
          accessor: 'preferred_vendor_prices',
          width: 220,
          Cell: ({ value = [] }) => <CompanyMaterialCells.PreferredVendors preferredVendors={value} />,
        })
        break
      case 'product_identifier':
        baseColumns.push({
          Header: 'Product ID',
          accessor: 'product_identifier',
          width: 180,
        })
        break
      case 'unit':
        baseColumns.push({
          Header: 'Unit',
          accessor: 'unit',
          width: 60,
          Cell: ({ row: { original } }) => {
            if (original?.unit_id) {
              return original?.unit?.unit_name_with_increment_label
            }

            if (original?.unit_name) {
              return original?.unit_name
            }

            return null
          },
        })
        break
      case 'description':
        baseColumns.push({
          Header: 'Description',
          accessor: 'description',
          width: 300,
        })
        break
      case 'connection_type':
        baseColumns.push({
          Header: 'Conn. Type',
          accessor: 'connection_type',
          width: 180,
        })
        break
      case 'sub_group':
        baseColumns.push({
          Header: 'Sub Group',
          accessor: 'sub_group',
          width: 140,
        })
        break
      case 'project_ids':
        baseColumns.push({
          Header: 'Projects',
          accessor: 'project_names',
          width: 250,
        })
        break
      default:
        baseColumns.push({
          Header: formatEnumValue(columnName),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: columnName as any,
          width: '10%',
        })
    }
  })

  baseColumns.push({
    Header: 'Tags',
    accessor: 'tags',
    minWidth: 150,
    Cell: ({ value = [] }) => <CompanyMaterialCells.Tags tags={value} />,
  })

  if (canUseHistoricalCost) {
    baseColumns.push(
      {
        Header: 'Last Price',
        accessor: 'cached_last_price',
        width: 120,
        format: 'currency',
        Cell: PsqlTableCells.FormattedText,
      },
      {
        Header: 'Avg. Price',
        accessor: 'cached_average_price',
        width: 120,
        format: 'currency',
        Cell: PsqlTableCells.FormattedText,
      },
      {
        Header: 'Lwst. Price',
        accessor: 'cached_lowest_price',
        width: 120,
        format: 'currency',
        Cell: PsqlTableCells.FormattedText,
      },
    )
  }

  baseColumns.push({
    Header: 'Active',
    accessor: 'active',
    width: 50,
    Cell: ({ value }) => <Checkbox checked={value} />,
  })

  return baseColumns
}
