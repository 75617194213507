import React from 'react'

import { Theme } from '@emotion/react'

import { Tag, Tooltip, Typography } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { useTheme } from 'common/hooks/use-theme'
import { InvoiceInboxFilesStates, InvoiceInboxSubStates } from 'common/server/server_types'

import { InvoiceInboxFileFailMessage, InvoiceInboxFileHit } from 'contractor/server/invoices/inbox'

export type FilesTagProps = {
  inbox_id: string
  files: InvoiceInboxFileHit[]
  inbox_fail_reason?: string
  inbox_auto_ignored_reason?: string
  upper_state: InvoiceInboxSubStates
}

type FileTagProps = {
  filename: string
  style: object
  url: string
  theme: Theme
  title: string
  color: string
  state: InvoiceInboxFilesStates
  upper_state: InvoiceInboxSubStates
}

const FileText = ({ filename }: { filename: string }) => {
  const sep = filename.split('.')
  const file = sep.length > 1 ? sep[sep.length - 1]?.toUpperCase() : 'N/A'
  return (
    <Typography.Text type="secondary" style={{ marginRight: 7 }}>
      {file || 'N/A'}
    </Typography.Text>
  )
}

const tooltipTitleColor = (
  filename: string,
  upper_state: InvoiceInboxSubStates,
  state: InvoiceInboxFilesStates,
  theme: Theme,
  fail_message: InvoiceInboxFileFailMessage[],
  auto_ignored_message?: string,
  inbox_fail_reason?: string,
  inbox_auto_ignored_reason?: string,
) => {
  let title: string
  let color: string
  // Be aware that the validation order is important here!
  if (!filename.toLowerCase().endsWith('.pdf')) {
    title = 'Ignored'
    color = theme.colors['gray-7']
  } else if (upper_state === InvoiceInboxSubStates.IGNORED) {
    title = 'Marked as ignored'
    color = theme.colors['gray-7']
  } else if (upper_state === InvoiceInboxSubStates.AUTO_IGNORED) {
    title = inbox_auto_ignored_reason || 'Auto ignored'
    color = theme.colors['gold-6']
  } else {
    switch (state) {
      case InvoiceInboxFilesStates.RESOLVED:
        title = 'Manually resolved'
        color = theme.colors['green-8']
        break
      case InvoiceInboxFilesStates.SUCCESS:
        title = 'Successfully extracted'
        color = theme.colors['green-6']
        break
      case InvoiceInboxFilesStates.AUTO_IGNORED:
        title = auto_ignored_message || 'Auto ignored'
        color = theme.colors['gold-6']
        break
      case InvoiceInboxFilesStates.FAILED:
        color = theme.colors['red-6']
        if (upper_state === InvoiceInboxSubStates.RESOLVED) {
          title = 'Manually resolved'
          color = theme.colors['green-8']
        } else if (fail_message && fail_message[0]?.reason) {
          title = fail_message[0]?.reason
        } else if (inbox_fail_reason) {
          title = inbox_fail_reason
        } else {
          title = 'Oops, something failed'
        }
        break
      case InvoiceInboxFilesStates.EXTRACTING:
        title = 'Extracting'
        color = theme.colors['gray-7']
        break
    }
  }
  return {
    title,
    color,
  }
}

const FileTag = ({ filename, style, upper_state, state, theme, url, title, color }: FileTagProps) => (
  <Tag style={style}>
    <a href={url} target="_blank" rel="noreferrer">
      <Typography.Text
        style={{
          color:
            state === InvoiceInboxFilesStates.FAILED && upper_state === InvoiceInboxSubStates.FAILED
              ? theme.colors['red-6']
              : theme.colors['gray-8'],
        }}
      >
        <Tooltip title={title} color={color} style={{ minWidth: 200 }}>
          <FileText filename={filename} />
          {filename}
        </Tooltip>
      </Typography.Text>
    </a>
  </Tag>
)

export const FileTags = ({
  inbox_id,
  files,
  inbox_fail_reason,
  inbox_auto_ignored_reason,
  upper_state,
}: FilesTagProps) => {
  const theme = useTheme()

  return (
    <FlexBoxX
      mt={5}
      flexWrap="wrap"
      justifyContent="flex-start"
      style={{ gap: 4 }}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {files.map(({ filename, url, state, fail_message, auto_ignored_message }) => {
        const defaultStyle = { margin: 0 }
        const style =
          state === InvoiceInboxFilesStates.FAILED && upper_state === InvoiceInboxSubStates.FAILED
            ? {
                ...defaultStyle,
                color: theme.colors['red-6'],
                background: theme.colors['red-1'],
                borderColor: theme.colors['red-6'],
              }
            : defaultStyle

        // This is a temporary fix, should be removed when the backend is fixed
        const railsHashJson = fail_message ? fail_message.replace(/=>/g, ':') : '[]'
        const messages = JSON.parse(railsHashJson)

        const tooltip = tooltipTitleColor(
          filename,
          upper_state,
          state,
          theme,
          messages,
          auto_ignored_message,
          inbox_fail_reason,
          inbox_auto_ignored_reason,
        )

        return (
          <FileTag
            key={`${inbox_id}-${filename}-key`}
            filename={filename}
            style={style}
            url={url}
            theme={theme}
            state={state}
            upper_state={upper_state}
            title={tooltip.title}
            color={tooltip.color}
          />
        )
      })}
    </FlexBoxX>
  )
}
