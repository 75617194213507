import React from 'react'

import { Tag } from 'antd'

import { Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'

import { ConsolidatedCompanyMaterials } from 'contractor/server/company_materials'

type CellPreferredVendorsProps = {
  preferredVendors: ConsolidatedCompanyMaterials.CompanyMaterial['preferred_vendor_prices']
}

export const CellPreferredVendors = (props: CellPreferredVendorsProps) => {
  const { preferredVendors = [] } = props

  if (!preferredVendors.length) {
    return null
  }

  return (
    <Box style={{ gap: 4 }} display="flex" flexWrap="wrap">
      {preferredVendors?.map(({ id, company_vendor, price }) => (
        <Tag key={id} style={{ margin: 0 }}>{`${company_vendor?.safe_globalized_vendor_name}: ${currencyFormatter(
          price,
        )}`}</Tag>
      ))}
    </Box>
  )
}
