import React from 'react'

import { SelectProps } from 'antd'

import { observer } from 'mobx-react-lite'

import { DebounceSelect } from 'common/components/DebounceSelect'

import { ValueType } from 'contractor/components/SelectExternalMappings/select_external_mappings'
import { rowValue } from 'contractor/pages/Integrations/Mappings/Relationships/relationships_table'
import { ListParams, MappingSuggestion, Relationship } from 'contractor/server/integrations'
import { Paginated } from 'contractor/stores/IntegrationStore'

type SelectIntegrationEntityProps = {
  optionApi: (params?: ListParams, forceParams?: boolean) => Promise<Paginated<unknown>>
  optionData: Nullable<Paginated<unknown>>
  makeInternalOption: (item: unknown) => ValueType
  row: Relationship
  handleUpdate: (
    internalId: unknown,
    externalId: string,
    suggestions: MappingSuggestion[],
    awaitReload?: boolean,
  ) => Promise<void>
} & SelectProps

export const SelectIntegrationEntity = observer<SelectIntegrationEntityProps>(
  ({ optionApi, optionData, makeInternalOption, row, handleUpdate, ...props }) => {
    const fetchOptions = async (search: string) => {
      const { data } = await optionApi({ search }, true)
      return data.map((item) => makeInternalOption(item))
    }

    const initialOptions = optionData?.data?.map((item) => makeInternalOption(item))

    return (
      // @ts-ignore
      <DebounceSelect<ValueType>
        initialOptions={initialOptions}
        fetchOptions={fetchOptions}
        allowClear
        showArrow
        value={rowValue(row)}
        onClear={() => handleUpdate(row.internal_id, row.external_id, row.mapping_suggestions)}
        onSelect={(value) => handleUpdate(value, row.external_id, row.mapping_suggestions)}
        {...props}
      />
    )
  },
)
