import React, { useCallback } from 'react'

import { useAgaveLink } from '@agave-api/react-agave-link'

import { Button, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX } from 'common/components/boxes'
import { isProduction } from 'common/helpers/check_environment'
import { IntegrationsSourceSystem } from 'common/server/server_types'

import { IntegrationIcons } from 'contractor/components/IntegrationIcons'
import { useStores } from 'contractor/hooks/use-stores'

const System = ({ type, title, disabled = false }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      borderRadius={4}
      border="1px solid"
      borderColor="gray-5"
      width={210}
      m={5}
      p={8}
      style={disabled ? { filter: 'grayscale(1)' } : {}}
    >
      <IntegrationIcons type={type} />
      <Typography.Text strong>{title}</Typography.Text>
    </Box>
  )
}

const SUBBASE_COMPANY_IDS_PRODUCTION = [
  '93366dcb-111e-420d-b472-63a5fc58ea0b',
  'f89d21be-0f30-4a9f-9a5b-2936a46e8480',
  'bfcb8c1a-c40c-46ea-9992-3b5a2cf41db9',
]

interface UnauthenticatedProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const Unauthenticated = observer<UnauthenticatedProps>(({ setLoading }) => {
  const { integrationStore, userStore } = useStores()

  const linkToken = integrationStore.linkToken

  const onSuccess = useCallback(
    async (publicToken) => {
      try {
        setLoading(true)
        await integrationStore.connect(publicToken)
      } finally {
        setLoading(false)
      }
    },
    [userStore.companyId],
  )

  // SubBase always signs on the sandbox environment
  const isSandboxEnv = !isProduction() || SUBBASE_COMPANY_IDS_PRODUCTION.includes(userStore.companyId)

  const { openLink, isReady } = useAgaveLink({
    referenceId: userStore.currentUser?.company_id,
    linkToken,
    sourceSystem: [
      'procore',
      'quick-books-desktop',
      'quick-books-online',
      'foundation-hosted',
      'cmic',
      'spectrum',
      'acumatica',
      'sage300',
    ],
    sourceSystemEnvironment: isSandboxEnv ? 'sandbox' : 'prod',
    showSandboxSourceSystems: isSandboxEnv,
    onSuccess,
  })

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="100%" mt={-30}>
      <Box style={{ textAlign: 'center' }}>
        <Typography.Title level={5} style={{ margin: 0 }}>
          Welcome to the Integrations page!
        </Typography.Title>
      </Box>
      <Box display="flex" style={{ gap: 12 }} mt={30} mb={30} width={800} flexDirection="column">
        <FlexBoxX justifyContent="center">
          <System type={IntegrationsSourceSystem.QBO} title="QuickBooks Online" />
          <System type={IntegrationsSourceSystem.QBD} title="QuickBooks Desktop" />
          <System type={IntegrationsSourceSystem.PROCORE} title="Procore" />
          <System type={IntegrationsSourceSystem.SAGE_300} title="Sage 300" />
        </FlexBoxX>
        <FlexBoxX justifyContent="center">
          <System type={IntegrationsSourceSystem.FOUNDATION_HOSTED} title="Foundation" />
          <System type={IntegrationsSourceSystem.CMIC} title="CMiC" />
          <System type={IntegrationsSourceSystem.SPECTRUM} title="Spectrum" />
          <System type={IntegrationsSourceSystem.ACUMATICA} title="Acumatica" />
        </FlexBoxX>
      </Box>
      <Box style={{ textAlign: 'center' }} mb={20}>
        <Typography.Paragraph style={{ margin: 0 }}>
          Enabling the integrations will allow you to create purchase orders and invoices in SubBase and sync these with
          the software of your choice.
        </Typography.Paragraph>
        <Typography.Paragraph style={{ margin: 0 }}>
          For the time being, you can integrate with: <b>QuickBooks Online</b>, <b>QuickBooks Desktop</b>,{' '}
          <b>Procore</b>, <b>Sage 300</b>, <b>Foundation</b>, <b>CMiC</b>, <b>Spectrum</b> and <b>Acumatica</b>.
        </Typography.Paragraph>
        <Typography.Paragraph style={{ margin: 0 }}>
          Ready to get started? Click on the button bellow to connect your account.
        </Typography.Paragraph>
      </Box>

      <Button disabled={!userStore.canSyncWithErp || !isReady} onClick={openLink} type="primary">
        {isReady ? 'Click here to Login' : 'Loading...'}
      </Button>
    </Box>
  )
})
