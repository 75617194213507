import React from 'react'

import { Skeleton } from 'antd'

import { Box } from 'common/components/boxes'

const skeletonRows = new Array(5).fill(0)

export const LoadingState = () => {
  return (
    <Box width="100%" display="flex" flexDirection="column" gridGap={16} p={{ _: 12, lg: 16 }}>
      {skeletonRows.map((_, index) => (
        <Skeleton.Input key={index} style={{ height: 78, width: '100%' }} />
      ))}
    </Box>
  )
}
