import React from 'react'

export interface EditableContextProviderProps {
  children: React.ReactNode
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EditableTableContextProps {
  currentEditingCell: Nullable<string>
  navigationByTabs: boolean
  setNavigationByTabs: (value: boolean) => void
  shouldFocusOnCell: (tableId: string | number, rowIndex: number, tabIndex: number) => boolean
  resetCurrentEditingCell: () => void
  handleChangeCurrentEditingCell: (
    tableId: string | number,
    rowIndex: number,
    tabIndex: number,
    navigatingByTabs?: boolean,
  ) => void
}

export const EditableTableContext = React.createContext<EditableTableContextProps>(null)

export const EditableTableContextProvider = (props: EditableContextProviderProps) => {
  const { children } = props

  const [currentEditingCell, setCurrentEditingCell] = React.useState<Nullable<string>>(null)
  const [navigationByTabs, setNavigationByTabs] = React.useState(false)

  function shouldFocusOnCell(tableId: string | number, rowIndex: number, tabIndex: number) {
    return currentEditingCell === `${tableId}_${rowIndex}_${tabIndex}`
  }

  function handleChangeCurrentEditingCell(
    tableId: string | number,
    rowIndex: number,
    tabIndex: number,
    navigatinByTabs?: boolean,
  ) {
    if (navigatinByTabs) {
      setNavigationByTabs(true)
    }

    setCurrentEditingCell(`${tableId}_${rowIndex}_${tabIndex}`)
  }

  function resetCurrentEditingCell() {
    setCurrentEditingCell(null)
    setNavigationByTabs(false)
  }

  return (
    <EditableTableContext.Provider
      value={{
        currentEditingCell,
        navigationByTabs,
        setNavigationByTabs,
        shouldFocusOnCell,
        handleChangeCurrentEditingCell,
        resetCurrentEditingCell,
      }}
    >
      {children}
    </EditableTableContext.Provider>
  )
}

export const useEditableTableContext = () => React.useContext(EditableTableContext)
