import React from 'react'

import { Input, Form } from 'antd'

export const FieldDescription = (props) => (
  <Form.Item
    style={{ width: '100%' }}
    name="description"
    label="Description"
    rules={[{ required: true, message: 'Description is required!' }]}
  >
    <Input {...props} aria-autocomplete="none" />
  </Form.Item>
)
