import React, { useEffect } from 'react'

import { useParams, useLocation } from 'react-router-dom'

import { Button, Form } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { makeOptionFromObject } from 'common/components/SelectUnit'
import { PersistentFiltersProvider } from 'common/contexts/persistent_filters'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

import { ErrorState } from '../components'
import { useEditPriceSheet } from '../context'
import { LoadingState } from './loading_state'
import { MaterialItem } from './MaterialItem'
import { Tabs } from './tabs'

const Component = observer(() => {
  const {
    priceSheetStore: { priceSheetMaterialListStore },
  } = useStores()

  const { id } = useParams<{ id: string }>()
  const location = useLocation()
  const { form } = useEditPriceSheet()

  const activeKey = new URLSearchParams(location.search).get('state') || 'all'

  const persistentFilters = usePersistentFilters()

  const { isLoading, isError } = useQuery(() => {
    if (!id) return
    priceSheetMaterialListStore.searchState.per_page = 30
    priceSheetMaterialListStore.setFilter('price_sheet_id', id, false, true)
    return persistentFilters.init()
  }, [id])

  useEffect(() => {
    const existingFormData = form.getFieldsValue()?.priceSheetMaterials || []
    const existingRecordsMap = new Map<string, typeof existingFormData[0]>(
      existingFormData.map((item) => [item.id, item]),
    )

    const mergedRecords = priceSheetMaterialListStore.records.map((record) => {
      const existingRecord = existingRecordsMap.get(record.id) || {}

      return {
        ...record,
        ...existingRecord,
        unit: existingRecord?.unit || makeOptionFromObject(record),
        company_material:
          record.company_material || existingRecord?.company_material
            ? {
                ...record?.company_material,
                ...existingRecord?.company_material,
                unit: existingRecord?.company_material?.unit || makeOptionFromObject(record?.company_material),
              }
            : undefined,
      }
    })

    form.setFieldsValue({ priceSheetMaterials: mergedRecords })
  }, [priceSheetMaterialListStore.records.length, form])

  if (isLoading || priceSheetMaterialListStore.isFetching) {
    return (
      <>
        <Box px={{ _: 12, lg: 16 }} pt={{ _: 12, lg: 16 }}>
          <Tabs activeKey={activeKey} priceSheetMaterials={priceSheetMaterialListStore.records} disabled />
        </Box>
        <LoadingState />
      </>
    )
  }

  if (isError) {
    return <ErrorState />
  }

  return (
    <>
      <Box px={{ _: 12, lg: 16 }} pt={{ _: 12, lg: 16 }}>
        <Tabs activeKey={activeKey} priceSheetMaterials={priceSheetMaterialListStore.records} />
      </Box>

      <Box
        px={{ _: 12, lg: 16 }}
        pb={{ _: 80, lg: 16 }}
        flexGrow={1}
        overflowY="auto"
        display="flex"
        flexDirection="column"
        gridGap={16}
        mt={16}
      >
        <Form.List name="priceSheetMaterials">
          {(fields, { remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <MaterialItem key={key} remove={remove} name={name} />
              ))}
            </>
          )}
        </Form.List>

        {priceSheetMaterialListStore.hasMore && (
          <Box m="0 auto">
            <Button
              type="primary"
              ghost
              loading={priceSheetMaterialListStore.isFetching}
              onClick={priceSheetMaterialListStore.fetchNextPage}
            >
              Load More
            </Button>
          </Box>
        )}
      </Box>
    </>
  )
})

export const Materials = observer(() => {
  const {
    priceSheetStore: { priceSheetMaterialListStore },
  } = useStores()

  return (
    <PersistentFiltersProvider listStore={priceSheetMaterialListStore}>
      <Component />
    </PersistentFiltersProvider>
  )
})
