import React from 'react'

import { DownOutlined } from '@ant-design/icons'
import LockOutlined from '@ant-design/icons/LockOutlined'
import { Button, Dropdown, Modal } from 'antd'
import { DropdownButtonProps } from 'antd/lib/dropdown'

import { observer } from 'mobx-react-lite'

import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'

type UpdateButtonProps = {
  saveAndNextDefault: boolean
  isCorrelating: boolean
  isSubmitting: boolean
  onSaveInvoice: () => void
  onSaveInvoiceAndNext: () => void
}

export const UpdateButton = observer<UpdateButtonProps>((props) => {
  const { invoiceStore } = useStores()

  const { saveAndNextDefault, isCorrelating, isSubmitting, onSaveInvoice, onSaveInvoiceAndNext } = props

  const { selectedInvoiceDirty, lockInvoice } = useInvoice()

  if (isCorrelating) {
    return (
      <Button
        type="primary"
        icon={<LockOutlined />}
        onClick={() =>
          Modal.info({
            content:
              'SubBase AI is working to auto-match the line items from the Invoice with your Order. Please wait.',
          })
        }
      >
        {saveAndNextDefault ? 'Update & Next' : 'Update'}
      </Button>
    )
  }

  const { listStore } = invoiceStore

  const disabledUpdate =
    isCorrelating || !selectedInvoiceDirty || (!lockInvoice.canChangeStatusAndAssignee && !lockInvoice.canEditInvoice)

  const commonDropdownProps: DropdownButtonProps = {
    placement: 'bottomRight',
    trigger: ['click'],
    loading: isSubmitting,
    type: 'primary',
    disabled: disabledUpdate,
    icon: <DownOutlined />,
    style: { width: 'auto' },
  }

  if (listStore.records.length > 1 && saveAndNextDefault) {
    return (
      <Dropdown.Button
        {...commonDropdownProps}
        menu={{
          items: [
            {
              label: 'Update',
              key: 'update',
              onClick: () => onSaveInvoice(),
            },
          ],
        }}
        onClick={() => onSaveInvoiceAndNext()}
      >
        Update & Next
      </Dropdown.Button>
    )
  } else if (listStore.records.length > 1 && !saveAndNextDefault) {
    return (
      <Dropdown.Button
        {...commonDropdownProps}
        menu={{
          items: [
            {
              label: 'Update & Next',
              key: 'update-next',
              onClick: () => onSaveInvoiceAndNext(),
            },
          ],
        }}
        onClick={() => onSaveInvoice()}
      >
        Update
      </Dropdown.Button>
    )
  }

  return (
    <Button
      loading={isSubmitting}
      type="primary"
      onClick={onSaveInvoice}
      disabled={disabledUpdate}
      data-cy="save-invoice-button"
    >
      Update
    </Button>
  )
})
