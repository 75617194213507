import { OrderStates } from 'common/server/server_types'

const refNumberStates = [
  OrderStates.DRAFT,
  OrderStates.DRAFTED_REQUEST,
  OrderStates.REQUESTED,
  OrderStates.APPROVED,
  OrderStates.QUOTED,
]

export const isRefNumber = (state?: OrderStates) => !state || refNumberStates.includes(state)

export const getPONumberTitle = (state?: OrderStates) => {
  if (isRefNumber(state)) {
    return 'Ref Number'
  }

  return 'PO Number'
}
