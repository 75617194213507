import React, { useState, useRef } from 'react'

import { message } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'
import { Loading } from 'common/components/Loading'
import { PsqlTableProvider } from 'common/components/PsqlTable/psql_table_provider'
import { PersistentFiltersProvider } from 'common/contexts/persistent_filters'
import { useQuery } from 'common/hooks/use-query'

import { makeCompanyVendorOption } from 'contractor/components/SelectCompanyVendor'
import { useStores } from 'contractor/hooks/use-stores'
import { CreateMaterial } from 'contractor/pages/CompanyMaterials/Materials/create_material'
import { UpdateMaterial } from 'contractor/pages/CompanyMaterials/Materials/UpdateMaterial'
import { ShowCompanyMaterialResponse } from 'contractor/server/company_materials'

import { CompanyMaterialsContent } from './company_materials_content'
import { columnsFactory } from './factories/columns_factory'

function Content() {
  const { companyMaterialStore, userStore, costCodeStore, companySettingStore, unitsStore } = useStores()

  const { listStore } = companyMaterialStore
  const { costCodeClassListStore, costCodeNumberListStore } = costCodeStore

  const { company_attributes } = companySettingStore.companyMaterialConfiguration
  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const [duplicateMaterial, setDuplicateMaterial] = useState(null)

  const drawerUpdateRef = useRef<DrawerRef>()
  const drawerCreateRef = useRef<DrawerRef>()

  const { isLoading } = useQuery(companySettingStore.indexCompanyMaterialConfiguration)
  useQuery(() => {
    return Promise.all([
      costCodeStore.fetchAllActiveCostCodes(),
      costCodeClassListStore.fetchAllRecords(),
      costCodeNumberListStore.fetchAllRecords(),
      unitsStore.maybeUnits(),
      listStore.fetchFacets(),
      companyMaterialStore.getCompanyMaterialTags(),
    ])
  })

  const handleDuplicateMaterial = (material: ShowCompanyMaterialResponse) => {
    const duplicateMaterial = { tags: material?.tags }
    company_attributes.forEach((columnName) => {
      switch (columnName) {
        case 'cost_code_id': {
          duplicateMaterial['cost_code'] = material?.cost_code
          break
        }
        case 'preferred_vendor_prices': {
          duplicateMaterial['company_material_vendor_prices_attributes'] =
            material?.company_material_vendor_prices?.map((companyMaterialVendorPrice) => ({
              price: companyMaterialVendorPrice.price,
              company_vendor: makeCompanyVendorOption({
                id: companyMaterialVendorPrice.company_vendor?.id,
                vendor_name: companyMaterialVendorPrice.company_vendor?.safe_globalized_vendor_name,
              }),
            }))
          break
        }
        case 'unit': {
          duplicateMaterial['unit_id'] = material?.unit_id
          duplicateMaterial['unit_name'] = material?.unit_name
          break
        }
        default: {
          duplicateMaterial[columnName] = material[columnName]
          break
        }
      }
    })
    setDuplicateMaterial(duplicateMaterial)
    companyMaterialStore.selectMaterial(null)
    drawerUpdateRef.current.close()
    drawerCreateRef.current.show()
  }

  const handleClickRow = async ({ row }) => {
    if (!userStore.canEditMaterialDatabase) return

    try {
      await companyMaterialStore.selectMaterial(row.company_material_id)
      drawerUpdateRef.current.show()
    } catch {
      message.error('Failed to load material')
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <PersistentFiltersProvider
      ignoreOnFilterCounter={['tab', 'filter_tab']}
      ignoreQueryParams={['tab', 'filter_tab']}
      listStore={listStore}
    >
      <PsqlTableProvider
        options={{
          data: toJS(listStore.records),
          sort: {
            sortFromPersistentFilter: true,
            field: listStore.searchState.sort,
            direction: listStore.searchState.sort_direction,
          },
          columns: columnsFactory({
            canUseHistoricalCost: userStore.canUseHistoricalCost,
            companyAttributes: company_attributes,
            costCodeColumn: {
              phaseEnabled: costCodeSettings?.phase_code_enabled && !costCodeSettings?.independent_phase_codes_enabled,
              classEnabled: costCodeSettings?.class_enabled,
              independentPhaseCodesEnabled: costCodeSettings?.independent_phase_codes_enabled,
            },
          }),
        }}
        tableName="AllCompanyMaterials"
      >
        <CompanyMaterialsContent onClickRow={handleClickRow} onAdd={() => drawerCreateRef.current.show()} />

        <UpdateMaterial
          editable={userStore.canEditMaterialDatabase}
          onCancel={() => {
            drawerUpdateRef.current.close()
            companyMaterialStore.selectMaterial(null)
          }}
          onSubmit={() => {
            listStore.fetchRecords()
            listStore.fetchFacets()
            drawerUpdateRef.current.close()
          }}
          onDuplicate={handleDuplicateMaterial}
          ref={drawerUpdateRef}
        />

        <CreateMaterial
          onCancel={() => {
            drawerCreateRef.current.close()
            setDuplicateMaterial(null)
          }}
          onSubmit={() => {
            listStore.fetchRecords()
            listStore.fetchFacets()
            setDuplicateMaterial(null)
            drawerCreateRef.current.close()
          }}
          costCodeSettings={costCodeSettings}
          initialValues={duplicateMaterial}
          ref={drawerCreateRef}
          units={unitsStore.units}
        />
      </PsqlTableProvider>
    </PersistentFiltersProvider>
  )
}

export const CompanyMaterialsPage = observer(Content)
