import React, { useState } from 'react'

import { Col, Form, Input, Row } from 'antd'

import { observer } from 'mobx-react-lite'

import SelectAddress from 'common/components/address/selector'
import { Box } from 'common/components/boxes'
import { Card } from 'common/components/Card'
import DateTimePicker from 'common/components/date_time_picker'

import { DeliveryInstructions } from 'contractor/components/DeliveryInstructions'
import { SelectAddressTo } from 'contractor/components/OrderDeliveries/address_to_selector'
import { SelectProject } from 'contractor/components/SelectProject'
import { useStores } from 'contractor/hooks/use-stores'

import { usePublicOrderForm } from './context'

const colProps = { xs: 24, sm: 12 }

const mapProjectOption = ({ id, name }) => ({ value: id, label: name })

export const OrderDetails = observer(() => {
  const { publicOrderStore } = useStores()
  const { user, setProject, project } = usePublicOrderForm()
  const { projects = [] } = publicOrderStore.formParams
  const { order_fields_settings } = publicOrderStore.formParams
  const { addresses_to } = publicOrderStore.formParams
  const { deliveries } = publicOrderStore
  const [selectedAddress, setSelectedAddress] = useState(null)

  const { company_addresses } = publicOrderStore.formParams

  const filterProjectOptions = ({ project_group_id }) => {
    // Show all projects for new users
    if (user === undefined || user === null) return true

    //  Show the user's projects for existing users
    return (
      !project_group_id || user?.project_group_ids?.length === 0 || user?.project_group_ids?.includes(project_group_id)
    )
  }
  const [projectOptions, setProjectOptions] = useState(projects.filter(filterProjectOptions).map(mapProjectOption))

  const handleProjectChange = (selected) => {
    if (!selected) {
      setProject(undefined)
      setProjectOptions(projects.filter(filterProjectOptions).map(mapProjectOption))
    }
  }

  const delivery = deliveries[0]

  const addressOptions = {
    company: company_addresses,
    project: (project?.addresses || []).map((address) => ({
      project_id: project?.id,
      project_name: project?.name,
      ...address,
    })),
  }

  return (
    <Card id="order-details" mt={{ _: 12, sm: 16 }}>
      <Row gutter={16}>
        <Col {...colProps}>
          <Form.Item label="Project" name="projectId" rules={[{ required: true, message: 'Project is required' }]}>
            <SelectProject options={projectOptions} onChange={handleProjectChange} />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            label="Order Title"
            name="orderPackageName"
            rules={[{ required: true, message: 'Order Title is required' }]}
          >
            <Input style={{ width: '100%' }} data-cy="order-title" placeholder="My Order" aria-autocomplete="none" />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item label="Address" name="address">
            <SelectAddress
              placeholder={'Select address'}
              addressOptions={addressOptions}
              aria-label="address-select"
              onChange={(address) => {
                setSelectedAddress({ ...address, address_to: null })
              }}
              selectedAddress={selectedAddress}
              delivery={deliveries[0]}
              setAddress={(address) => {
                setSelectedAddress(address)
              }}
              addNewAddressOptionVisible={false}
              disabled={!project}
              wrapperProps={{
                maxWidth: '100%',
                minWidth: '100%',
                width: '100%',
              }}
            />
          </Form.Item>
        </Col>

        {order_fields_settings?.address_to?.enabled && (
          <Col {...colProps}>
            <Form.Item label="Attention To" name="addressToId">
              <SelectAddressTo
                addressToOptions={addresses_to}
                placeholder="Optional"
                wrapperProps={{
                  maxWidth: '100%',
                  minWidth: '100%',
                  width: '100%',
                }}
                canCreateAttnTo={false}
              />
            </Form.Item>
          </Col>
        )}

        <Col {...colProps} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Form.Item label="Deliver On" name="requestedDeliveredAt" style={{ flexGrow: 1 }}>
            <DateTimePicker
              data-cy="delivered-at"
              wrapperProps={{
                width: '100%',
              }}
              disabled={!project}
            />
          </Form.Item>

          <Box mt={6}>
            <DeliveryInstructions
              value={delivery?.instructions}
              onChange={(value) => {
                if (delivery) {
                  delivery.instructions = value
                }
              }}
            />
          </Box>
        </Col>
      </Row>
    </Card>
  )
})
