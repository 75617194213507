import React from 'react'

import { Link } from 'react-router-dom'

import { DollarOutlined, ShopOutlined } from '@ant-design/icons'
import { Space, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { currencyFormatter, formatDateString } from 'common/helpers/formatters'

import { UnknownText } from 'contractor/pages/@v2/Invoices/tabs/List/invoice_table_columns'
import { IndexInvoiceRecord } from 'contractor/server/invoices/invoice'

const InvoiceMobileCard = (row: IndexInvoiceRecord) => {
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Link onClick={(e) => e.stopPropagation()} to={`/invoice/${row.id}`}>
          {row.invoice_number}
        </Link>
      </Box>

      <Space>
        <ShopOutlined />
        {row.company_vendor_name ? <Typography.Text>{row.company_vendor_name}</Typography.Text> : <UnknownText />}
      </Space>

      <Space>
        <DollarOutlined />
        {row.total_amount ? (
          <Typography.Text>{currencyFormatter(row.total_amount, 2)}</Typography.Text>
        ) : (
          <UnknownText />
        )}
      </Space>

      <Space>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          DELETED DATE
        </Typography.Text>
        {row.discarded_at ? <Typography.Text>{formatDateString(row.discarded_at)}</Typography.Text> : <UnknownText />}
      </Space>
    </Space>
  )
}

export default InvoiceMobileCard
