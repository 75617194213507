import React from 'react'

import styled from '@emotion/styled'

import { ProjectOutlined, RightOutlined, ShopOutlined } from '@ant-design/icons'
import { Typography } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'
import { LogDeliveryResponse } from 'common/server/deliveries'

import { DeliveryTag } from 'contractor/components/LogDelivery/SelectOrderStep/delivery_tag'

export const OrderTextContainer = styled(Typography.Text)`
  margin-left: 4px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
`

interface DeliveryCardProps {
  orderDelivery: LogDeliveryResponse
  onSelect: (deliveryId: string) => void
}

export const DeliveryCard: React.FC<DeliveryCardProps> = (props: DeliveryCardProps) => {
  const { orderDelivery, onSelect } = props
  const getDeliveryIndex = () => {
    return orderDelivery.order.deliveries.findIndex((d) => d.id === orderDelivery.delivery.id) + 1
  }
  return (
    <Box
      style={{
        position: 'relative',
        cursor: 'pointer',
        padding: '8px 16px',
        width: '100%',
      }}
      onClick={() => onSelect(orderDelivery.delivery.id)}
    >
      <Box width="100%">
        <FlexBoxX width="100%" display="flex" alignItems="center" justifyContent="flex-start">
          {orderDelivery.order.deliveries.length > 1 && <DeliveryTag deliveryTag={`Delivery ${getDeliveryIndex()}`} />}
          <Typography.Text style={{ maxWidth: '70%' }} strong>
            {orderDelivery.order.order_number}: {orderDelivery.order.order_package_name}
          </Typography.Text>
          <DeliveryTag state={orderDelivery.order.state} sub_state={orderDelivery.order.sub_state} />
        </FlexBoxX>
        <Box display="flex">
          <Box style={{ marginTop: 6 }} display="flex" alignItems="center">
            <ProjectOutlined style={{ fontSize: 12, color: '#121C4E' }} />
            <OrderTextContainer>{orderDelivery.order.project_name}</OrderTextContainer>
          </Box>
          <Box style={{ marginTop: 6, marginLeft: 8 }} display="flex" alignItems="center">
            <ShopOutlined style={{ fontSize: 12, color: '#121C4E' }} />
            <OrderTextContainer>{orderDelivery.order.vendor_name}</OrderTextContainer>
          </Box>
        </Box>
      </Box>
      <RightOutlined style={{ position: 'absolute', right: 16, top: 16, fontSize: 12 }} />
    </Box>
  )
}
