import axios from 'axios'

export interface Unit {
  id?: string
  name: string
  override_label?: string
  multiplier: string
  qty_increment?: string
  synonyms?: string[]

  // Fields generated by Back-End
  increments_label?: string
  unit_name_label?: string
  unit_name_with_increment_label?: string
}

export interface Units {
  units: Unit[]
}

export function list() {
  return axios.get<Units>(`/common/units`)
}
