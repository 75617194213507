import React from 'react'

import { StyledTag } from 'common/components/AlgoliaTagsFilter/styles'
import { FlexBoxX } from 'common/components/boxes'
import { formatEnumValue } from 'common/helpers/formatters'
import { Role } from 'common/server/server_types'

type UserRibbonsFiltersProps = {
  onChange: (filter: string) => void
  filter: string
  roles: Role[]
}

export const UsersRibbonFilter = ({ onChange, filter, roles = [] }: UserRibbonsFiltersProps) => {
  const rolesFilter = [
    { label: 'All', filter: '' },
    ...roles.map((role) => ({ label: formatEnumValue(role.name), filter: role.id })),
  ]

  return (
    <FlexBoxX justifyContent="start" maxWidth="100%">
      {rolesFilter?.map((roleFilter) => {
        const isChecked = roleFilter.filter === filter

        return (
          <StyledTag
            data-cy={`${roleFilter.label}-filter`}
            key={roleFilter.label}
            checked={isChecked}
            onChange={() => {
              onChange(roleFilter.filter)
            }}
          >
            {roleFilter.label}
          </StyledTag>
        )
      })}
    </FlexBoxX>
  )
}
