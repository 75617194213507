import React from 'react'

import { Box, FlexBoxY } from 'common/components/boxes'
import { PdfViewer } from 'common/components/PdfViewer'

import { useStores } from 'contractor/hooks/use-stores'
import { UnmatchedMaterialsTable } from 'contractor/pages/OrderDetail/QuoteReconciliation/QuoteLineItemsViewer/unmatched_materials_table'
import { UnmatchedMaterial } from 'contractor/server/quote'

interface ViewProps {
  unmatchedMaterials: UnmatchedMaterial[]
  handleChangeUnquotedMaterials: (payload: UnmatchedMaterial[]) => void
  handleImportQuoteLineItem: (payload: UnmatchedMaterial) => void
  pdfUrl: string
}

export const QuoteLineItemsViewer = (props: ViewProps) => {
  const { unmatchedMaterials, handleChangeUnquotedMaterials, handleImportQuoteLineItem, pdfUrl } = props
  const { userStore } = useStores()
  const showUnmatchedMaterials = unmatchedMaterials?.length > 0 && userStore.canCreateNewMaterial
  return (
    <FlexBoxY verticalAlign="top" height="100%" width="100%">
      <Box maxHeight={showUnmatchedMaterials ? '80%' : '100%'} width="100%">
        <PdfViewer style={{ height: '100%', borderRadius: 4 }} url={pdfUrl} />
      </Box>
      {showUnmatchedMaterials && (
        <Box marginTop="16" height="20%" width="100%">
          <UnmatchedMaterialsTable
            unmatchedMaterials={unmatchedMaterials}
            onChange={handleChangeUnquotedMaterials}
            handleImportQuoteLineItem={handleImportQuoteLineItem}
          />
        </Box>
      )}
    </FlexBoxY>
  )
}
