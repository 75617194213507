import React from 'react'

import { Popover, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'

type PopoverInfoProps = {
  inputValue: number
  roundingPrecision: number
}

export const PopoverInfo = (props: PopoverInfoProps) => {
  const { inputValue, roundingPrecision } = props
  return (
    <Popover
      content={
        <Box display="flex" flexDirection="column" style={{ gap: 8 }} fontSize={12}>
          <Typography.Text style={{ whiteSpace: 'nowrap' }} code>
            Total material cost * percentage
          </Typography.Text>
          <Typography.Text strong>Total: {currencyFormatter(inputValue || 0, roundingPrecision || 3)}</Typography.Text>
        </Box>
      }
    >
      $
    </Popover>
  )
}
