import React from 'react'

import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'
import { Badge, Tooltip, Typography } from 'antd'

import { Box } from 'common/components/boxes'

export const QuantityHeader = (isMissingMaterials) => {
  return (
    <Box>
      <Typography.Text>Qty</Typography.Text>
      {isMissingMaterials && (
        <Badge
          count={
            <Tooltip
              placement="right"
              title="Some materials are missing from this order. Please check the order details."
            >
              <QuestionCircleOutlined style={{ right: -5 }} />
            </Tooltip>
          }
        />
      )}
    </Box>
  )
}
