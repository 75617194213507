import axios from 'axios'

import { AddressTo } from 'common/server/addressesTo'

export function index(params: { active: boolean }) {
  return axios.get<AddressTo[]>('/address_to', { params })
}

export function create(payload: AddressTo) {
  return axios.post<AddressTo>('/address_to', payload)
}

export function update(payload: AddressTo) {
  return axios.patch<AddressTo>(`/address_to/${payload.id}`, payload)
}
