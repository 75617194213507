import { useMemo } from 'react'

import { flatten } from 'lodash'

import { toJS } from 'mobx'

import { getSpreadValueByDot } from 'common/helpers/formatters'
import { roundValue } from 'common/helpers/order'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../../context'
import {
  calculateInvoiceSubtotal,
  calculateOrderedDiscount,
  calculateOrderedOther,
  calculateOrderedShipping,
  calculateOrderedTax,
  calculateTotalInvoiced,
  getInvoicesRelatedToOrder,
  makeInvoiceDiscountRows,
  makeInvoiceOtherRows,
  makeInvoiceShippingRows,
  makeInvoiceTaxRows,
  makeInvoiceTotalRows,
  makeInvoiceUnreconciledItemsRows,
  makeOrderDiscountRow,
  makeOrderOtherRow,
  makeOrderShippingRow,
  makeOrderTaxRow,
  makeOrderTotalRow,
} from './utils'

export const useOrderMaterialsData = () => {
  const useInvoiceExtractedValuesFlag = useFlag('use_invoice_extracted_values')

  const { invoiceStore } = useStores()
  const {
    taxAmountField,
    shippingCostField,
    discountAmountField,
    otherCostsField,
    calculatedGrandTotal,
    calculatedEligibleTaxSplitTotal,
    allInvoicesExceptTheCurrent,
    calcTaxSplitBulk,
    taxLineItemsEnabled,
  } = useInvoice()

  const { invoice } = invoiceStore

  const extractedGrandTotal = Number(invoice?.extracted_invoice_amount)
  const { fractional: fractionalExtractedGrandTotal } = getSpreadValueByDot(extractedGrandTotal?.toString())

  /*
    Combine all invoices except the current with the current invoice,
    to keep the current invoice values updated based on the user changes
  */
  const allInvoices = [
    {
      ...invoice,
      invoice_grand_total: useInvoiceExtractedValuesFlag
        ? roundValue(calculatedGrandTotal, fractionalExtractedGrandTotal?.length || 0)
        : calculatedGrandTotal,
      eligible_tax_split_total: calculatedEligibleTaxSplitTotal,
      tax_amount: taxAmountField,
      shipping_cost: shippingCostField,
      other_costs: otherCostsField,
      discount_amount: discountAmountField,
    },
    ...allInvoicesExceptTheCurrent,
  ]

  /*
    Get all invoice materials from all invoices present in the orders related to the current invoice.
    Except for the current invoice which is loaded in invoiceStore.invoice.
  */
  const allInvoicesMaterialsExceptTheCurrent = useMemo(() => {
    const allInvoicesExceptTheCurrentMatrix = allInvoicesExceptTheCurrent.map((currentInvoice) =>
      currentInvoice.invoice_materials.map((invoiceMaterial) => ({
        ...invoiceMaterial,
        number: currentInvoice.number,
      })),
    )

    return flatten(allInvoicesExceptTheCurrentMatrix)
  }, [allInvoicesExceptTheCurrent.length])

  const currentInvoiceMaterials = calcTaxSplitBulk({
    taxAmount: taxAmountField,
    taxLineItemsEnabled,
    invoiceMaterials: invoice?.invoice_materials?.map((invoiceMaterial) => ({
      ...invoiceMaterial,
      number: invoice.number,
    })),
  })

  // Merge the current invoice materials with all invoices materials from the all orders related to the current invoice
  const allInvoicesMaterials = [...allInvoicesMaterialsExceptTheCurrent, ...currentInvoiceMaterials]

  const mapCurrentInvoiceMaterial = (invoiceMaterial) => {
    const invoice = allInvoices.find((inv) => inv.id === invoiceMaterial.invoice_id)

    const taxAmount = invoice?.tax_amount || 0
    const eligibleTaxSplitTotal = invoice?.eligible_tax_split_total || 0

    const common = {
      ...invoiceMaterial,
      tax_amount: taxAmount,
      eligible_tax_split_total: eligibleTaxSplitTotal,
    }

    if (!invoiceMaterial.accepts_tax_split) {
      return common
    }

    const extendedCost = Number(invoiceMaterial?.extended_price) || 0
    const extCostWithTax = extendedCost + invoiceMaterial.tax_split

    return { ...common, ext_cost_with_tax: extCostWithTax }
  }

  // I didn't use useMemo here because it has a lot of dependencies hard to track,
  // and since we don't have tests maybe it can break something in the production
  const dataSource = flatten(
    invoiceStore.selectedOrders?.map(({ order }) => {
      // Add to the current order materials all invoice materials related
      const orderMaterials = order.order_materials.map((orderMaterial) => {
        // Get the invoice materials related to the current order material
        const invoiceMaterials = allInvoicesMaterials
          ?.filter((invoiceMaterial) => invoiceMaterial?.order_materials?.some((om) => om.id === orderMaterial.id))
          ?.map(mapCurrentInvoiceMaterial)

        const common = {
          ...orderMaterial,
          order_id: order.id,
          order_number: order.order_number,
          sub_total: order.sub_total,
        }

        return invoiceMaterials.length ? { ...common, invoice_materials: invoiceMaterials } : common
      })

      const invoiceNotReconciledItems = currentInvoiceMaterials
        .filter((invoiceMaterial) => toJS(invoiceMaterial.order_materials || [])?.length == 0)
        .map(mapCurrentInvoiceMaterial)
      const transformedInvoicedMaterials = invoice?.marked_reconciled_at
        ? makeInvoiceUnreconciledItemsRows(invoiceNotReconciledItems, invoice)
        : []

      const orderedTax = calculateOrderedTax(order?.deliveries)
      const orderedShipping = calculateOrderedShipping(order?.deliveries)
      const orderedOther = calculateOrderedOther(order?.deliveries)
      const orderedDiscount = calculateOrderedDiscount(order?.deliveries)

      const invoicesRelatedToCurrentOrder = getInvoicesRelatedToOrder(order, allInvoices)

      // Create the expandable row for the invoice taxes.
      const expandableInvoicesTaxRows = makeInvoiceTaxRows(invoicesRelatedToCurrentOrder)
      const expandableInvoicesShippingRows = makeInvoiceShippingRows(invoicesRelatedToCurrentOrder)
      const expandableInvoicesOtherRows = makeInvoiceOtherRows(invoicesRelatedToCurrentOrder)
      const expandableInvoicesDiscountRows = makeInvoiceDiscountRows(invoicesRelatedToCurrentOrder)

      const invoicedTax = calculateTotalInvoiced(expandableInvoicesTaxRows)
      const invoicedShipping = calculateTotalInvoiced(expandableInvoicesShippingRows)
      const invoicedOther = calculateTotalInvoiced(expandableInvoicesOtherRows)
      const invoicedDiscount = calculateTotalInvoiced(expandableInvoicesDiscountRows)

      const taxRow = makeOrderTaxRow({ order, orderedTax, invoicedTax, expandableInvoicesTaxRows })
      const shippingRow = makeOrderShippingRow({
        order,
        orderedShipping,
        invoicedShipping,
        expandableInvoicesShippingRows,
      })
      const otherRow = makeOrderOtherRow({ order, orderedOther, invoicedOther, expandableInvoicesOtherRows })
      const discountRow = makeOrderDiscountRow({
        order,
        orderedDiscount,
        invoicedDiscount,
        expandableInvoicesDiscountRows,
      })

      const invoicedSubtotal = calculateInvoiceSubtotal(invoicesRelatedToCurrentOrder)

      const { fractional: fractionalOrderGrandTotal } = getSpreadValueByDot(order.grand_total?.toString() || '')

      const invoicedTotal = useInvoiceExtractedValuesFlag
        ? roundValue(
            invoicedSubtotal + invoicedTax + invoicedShipping + invoicedOther - invoicedDiscount,
            fractionalExtractedGrandTotal?.length || 0,
          )
        : invoicedSubtotal + invoicedTax + invoicedShipping + invoicedOther - invoicedDiscount

      // Create the expandable row for the invoice total.
      const invoicesTotalRows = makeInvoiceTotalRows(invoicesRelatedToCurrentOrder, [
        ...orderMaterials,
        ...transformedInvoicedMaterials,
      ])

      const maxRemainingFractionalLength = Math.max(
        fractionalOrderGrandTotal?.length || 0,
        fractionalExtractedGrandTotal?.length || 0,
      )

      // Create the row row for the order total.
      const totalRow = makeOrderTotalRow({
        order,
        tax: orderedTax,
        invoicedTotal,
        invoicesTotalRows,
        remaining: roundValue(Number(order.grand_total) - invoicedTotal, maxRemainingFractionalLength),
      })

      const taxesRows = [
        { ordered: orderedTax, invoiced: invoicedTax, row: taxRow },
        { ordered: orderedShipping, invoiced: invoicedShipping, row: shippingRow },
        { ordered: orderedOther, invoiced: invoicedOther, row: otherRow },
        { ordered: orderedDiscount, invoiced: invoicedDiscount, row: discountRow },
      ]

      // Only add the taxes rows if there is any value in invoices or order
      const taxesRowsToShow = taxesRows
        .filter(({ ordered, invoiced }) => ordered !== 0 || invoiced !== 0)
        .map(({ row }) => row)

      // Only show the tax row if the tax to line items is disabled
      return [
        ...orderMaterials,
        ...transformedInvoicedMaterials,
        ...(taxLineItemsEnabled ? [] : taxesRowsToShow),
        totalRow,
      ]
    }),
  )

  return { dataSource }
}
