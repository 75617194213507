import React, { useEffect, useCallback } from 'react'

import axios from 'axios'
import { uniqBy } from 'lodash'

import { DescriptionEditorCell } from 'common/components/OrderMaterialsSpreadsheet'
import { noticeError } from 'common/helpers/new_relic'

import { useStores } from 'contractor/hooks/use-stores'

export const CustomDescriptionEditorCell = (props) => {
  const {
    companyMaterialStore: { listStore: companyMateriaListStore },
    manufacturerMaterialStore: { listStore: manufacturerMateriallistStore },
    companySettingStore,
    publicOrderStore,
  } = useStores()

  useEffect(() => {
    if (
      companySettingStore.companyMaterialConfiguration?.company_attributes.includes('project_ids') &&
      props?.projectId
    ) {
      companyMateriaListStore.setFilter('projects_with_empty', [props?.projectId], true, true)
    }
    companyMateriaListStore.setFilter('active', true, true, true)
  }, [companySettingStore.companyMaterialConfiguration?.company_attributes, props?.projectId])

  useEffect(() => {
    if (publicOrderStore.formParams?.public_token) {
      axios.defaults.headers.common['Public-Token'] = publicOrderStore.formParams?.public_token
    }
  }, [publicOrderStore.formParams?.public_token])

  const handleSearchText = useCallback(
    async (query: string) => {
      try {
        await Promise.all([companyMateriaListStore.setSearch(query), manufacturerMateriallistStore.setSearch(query)])

        const mergedMaterials = uniqBy(
          [...companyMateriaListStore.records, ...manufacturerMateriallistStore.records],
          (material) => {
            if (material['manufacturer_material_id']) {
              return material['manufacturer_material_id']
            } else {
              return material['id']
            }
          },
        )

        return mergedMaterials
      } catch (error) {
        noticeError(error, { entry: 'search-materials-spreadsheet', query })
        return []
      }
    },
    [companyMateriaListStore.records, manufacturerMateriallistStore.records],
  )

  return <DescriptionEditorCell {...props} onSearchText={handleSearchText} />
}
