import React, { useState } from 'react'

import { Button, Form, Input, Switch, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { SearchInput } from 'common/components/SearchInput'
import { useMediaQuery } from 'common/hooks/use-media-query'

import { IncompleteItems } from 'contractor/components/DeliveryDetail/IncompleteItems/incomplete_items'
import { DeliveryDetailsUploader } from 'contractor/components/LogDelivery/DeliveryDetails/delivery_details_uploader'
import { Header } from 'contractor/components/LogDelivery/DeliveryDetails/header'

type DeliveryDetailsProps = {
  onBack: () => void
  form: any
  filesKey: string
  loading: boolean
}

export const DeliveryDetails = observer((props: DeliveryDetailsProps) => {
  const { onBack, form, filesKey, loading } = props
  const [filter, setFilter] = useState('')
  const isMobile = useMediaQuery('md')
  const deliveryIssueResolvedAt = Form.useWatch('deliveryIssueResolvedAt', form)
  const dataSource = Form.useWatch('incompleteItems', form)?.filter((item) =>
    item?.company_material?.description?.toLowerCase()?.includes(filter?.toLowerCase() || ''),
  )
  const resolved = Form.useWatch('resolved', form)
  const orderDelivery = Form.useWatch('orderDelivery', form)
  const orderNumber = Form.useWatch('orderNumber', form)
  const orderName = Form.useWatch('orderName', form)
  const showResolve = dataSource?.some(
    (item) => item.has_open_issue || orderDelivery?.delivery.delivery_issues?.length > 0,
  )
  const whoMarkedResolved = orderDelivery?.delivery?.delivery_issue_resolved_by?.first_name
    ? `${orderDelivery?.delivery?.delivery_issue_resolved_by.first_name} ${orderDelivery?.delivery?.delivery_issue_resolved_by?.last_name}`
    : 'you'

  return (
    <Box height="100vh" display="flex" maxWidth={isMobile ? '100%' : 600} flexDirection="column">
      <Box maxWidth="100vw" marginBottom={64} flexGrow={1} overflowY="auto">
        <Header onBack={onBack} orderName={orderName} orderNumber={orderNumber} />

        <DeliveryDetailsUploader filesKey={filesKey} />

        <SearchInput
          onChange={(search) => setFilter(search?.target?.value)}
          style={{ paddingLeft: 16, paddingRight: 16 }}
        />

        <Form.Item name="incompleteItems">
          <IncompleteItems orderMaterials={dataSource} />
        </Form.Item>

        {showResolve && (
          <Form.Item
            style={{ paddingLeft: 16, paddingRight: 16 }}
            name="deliveryIssues"
            data-cy="delivery-issues"
            label="Provide details on the problem for your vendor"
          >
            <Input.TextArea placeholder="Describe issues with delivery here" style={{ resize: 'none' }} />
          </Form.Item>
        )}

        {showResolve && (
          <Box ml="16" display="flex">
            <Form.Item name="resolved" valuePropName="checked" noStyle>
              <Switch />
            </Form.Item>
            <Typography.Text style={{ marginLeft: 8, marginRight: 8 }}>
              {resolved
                ? `Issue resolved on ${
                    deliveryIssueResolvedAt ? deliveryIssueResolvedAt.format('YYYY-MM-DD HH:mm') : 'N/A'
                  } by ${whoMarkedResolved}`
                : 'Mark as Resolved'}
            </Typography.Text>
          </Box>
        )}
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        style={{
          padding: '16px',
          gap: '16px',
          backgroundColor: 'white',
          borderTopStyle: 'solid',
          borderTopWidth: '1px',
          borderTopColor: '#f0f0f0',
          position: 'sticky',
          bottom: 0,
          zIndex: 1,
        }}
      >
        <Button disabled={loading} onClick={onBack} style={{ flexGrow: 1 }}>
          Back
        </Button>

        <Button loading={loading} data-cy="save_delivery" type="primary" htmlType="submit" style={{ flexGrow: 1 }}>
          Save
        </Button>
      </Box>
    </Box>
  )
})
