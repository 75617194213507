import React from 'react'

import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'

import { observer } from 'mobx-react-lite'

import { useStores } from 'contractor/hooks/use-stores'

type MaterialRequestStateActionsProps = ButtonProps

export const MaterialRequestStateActions = observer<MaterialRequestStateActionsProps>((props) => {
  const { userStore } = useStores()

  if (userStore.cannotSendAndUpdateOrders && userStore.cannotEditCostCode) {
    return null
  }

  return (
    <Button data-cy="update-order" type="primary" {...props}>
      Save
    </Button>
  )
})
