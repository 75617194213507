import React, { useState, useEffect, useCallback } from 'react'

import { Typography, Switch, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'

interface OrderFieldsItem {
  label: string
  active: boolean
  required: boolean
  key: string
}

type OrderFieldsProps = {
  onChange: (configs: {}) => void
}

export const OrderFieldsSettings = observer<OrderFieldsProps>(({ onChange }) => {
  const { companySettingStore } = useStores()

  const orderSettingsValue = companySettingStore.otherSettings?.order_fields_settings

  const makeData = useCallback(() => {
    return [
      {
        label: 'Attention To',
        active: orderSettingsValue?.address_to?.enabled,
        required: orderSettingsValue?.address_to?.required,
        key: 'address_to',
      },
    ]
  }, [orderSettingsValue])

  const [orderSettings, setOrderSettings] = useState<OrderFieldsItem[]>(makeData())

  useEffect(() => {
    const data = makeData()
    setOrderSettings(data)
  }, [makeData])

  const handleToggle = (item: OrderFieldsItem, required) => {
    const newState = orderSettings.map((state) => {
      if (state.key === item.key) {
        if (required) {
          return { ...state, required: !item.required }
        } else {
          return { ...state, active: !item.active }
        }
      }
      return state
    })

    const orderSettingsMap = {}
    newState.forEach((setting) => {
      orderSettingsMap[setting.key] = {
        enabled: setting.active,
        required: setting.required,
      }
    })

    setOrderSettings(newState)
    onChange(orderSettingsMap)
  }

  const columns: ColumnsType<OrderFieldsItem> = [
    {
      title: 'Feature',
      dataIndex: 'label',
      width: 100,
    },
    {
      title: 'Enabled',
      dataIndex: 'active',
      width: 125,
      render: (active, record) => <Switch checked={active} onChange={() => handleToggle(record, false)} />,
    },
    // Commented out because we will better implement this later
    // {
    //   title: 'Required',
    //   dataIndex: 'required',
    //   width: 100,
    //   render: (active, record) => <Switch checked={active} onChange={() => handleToggle(record, true)} />,
    // },
  ]

  return (
    <Box mb={32}>
      <Typography.Title level={5}>Order Fields Settings</Typography.Title>
      <Table dataSource={orderSettings} columns={columns} pagination={false} />
    </Box>
  )
})
