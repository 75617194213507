import React, { useState } from 'react'

import { message } from 'antd'

import { observer } from 'mobx-react-lite'

import { InternalComments as InternalCommentsComponent } from 'contractor/components/InternalComments'
import { useStores } from 'contractor/hooks/use-stores'

export const InternalComments = observer(() => {
  const { invoiceStore, userStore } = useStores()

  const [isSubmitting, setSubmitting] = useState(false)

  const handleSendCommnent = async ({ text, companyUserIds = [] }) => {
    setSubmitting(true)
    try {
      await invoiceStore.sendInternalComment({
        text,
        companyUserIds: [...companyUserIds, userStore.currentUser?.company_user_id],
      })
    } catch (error) {
      message.error('Unable to send comment')
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <InternalCommentsComponent
      comments={invoiceStore.invoice?.comments}
      onSubmit={handleSendCommnent}
      loading={isSubmitting}
      invoiceState={invoiceStore.invoice?.state}
    />
  )
})
