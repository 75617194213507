import { createConsumer } from '@rails/actioncable'

import { WebSocketMessage } from '../server/server_types'

const CLIENT_LOG_CHANNEL = 'ApplicationCable::ClientLogChannel'

type OrderChannelType = 'VENDOR' | 'CONTRACTOR'

export type ClientLogMessage = {
  updated?: string
}

const getActionCableUrl = () => document.querySelector('meta[name="action-cable-url"]')['content']

let consumer = null

export const subscribeClientLogChannel = (
  callback: (message: WebSocketMessage) => void,
  type: OrderChannelType,
  message_id: string,
) => {
  if (!consumer) {
    consumer = createConsumer(`${getActionCableUrl()}?type=${type}`)
  }

  const subscription = consumer.subscriptions.create(
    {
      channel: CLIENT_LOG_CHANNEL,
      entity_id: message_id,
    },
    {
      received(message) {
        callback(message)
      },
      connected() {
        console.log('connected on client log with params:', message_id)
      },
      disconnected() {
        console.log('disconnected on client log with params:', message_id)
      },
    },
  )

  return {
    unsubscribe: () => {
      consumer.subscriptions.remove(subscription)
    },
  }
}
