import React from 'react'

import { useDrop } from 'react-dnd'

import { Form } from 'antd'

import { EditableContext } from './row_context'

type EditableRowProps<Item> = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTableRowElement>,
  HTMLTableRowElement
> & {
  index: number
  moveRow?: (origin: Item, target: Item) => void
  item: Item
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EditableRow = <Item extends Record<string, any>>({
  index,
  item: itemProp,
  moveRow,
  className,
  ...restProps
}: EditableRowProps<Item>) => {
  const [form] = Form.useForm()

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'DraggableBodyRow',
      collect: (monitor) => {
        const item = monitor.getItem() || {}
        if (item['index'] === index) {
          return {}
        }
        return { isOver: monitor.isOver() }
      },
      drop: (item: Item) => {
        moveRow?.(item, itemProp)
      },
    }),
    [itemProp, index],
  )

  React.useEffect(() => {
    form.setFieldsValue({ ...itemProp })
  }, [itemProp])

  return (
    <Form form={form} component={false} initialValues={{ ...itemProp }}>
      <EditableContext.Provider value={form}>
        <tr ref={drop} className={`${className} ${isOver ? 'is-over' : ''}`} {...restProps} />
      </EditableContext.Provider>
    </Form>
  )
}
