import { BindAll } from 'lodash-decorators'

import { observable } from 'mobx'

import { index, update, OtherSettings } from 'contractor/server/user_settings/user_settings'

@BindAll()
export default class UserSettingStore {
  @observable otherSettings: OtherSettings

  async indexSettings() {
    const response = await index()
    this.otherSettings = response?.data
    return this.otherSettings
  }

  async updateSettings(settings: OtherSettings) {
    this.otherSettings = (await update(settings))?.data
  }
}
