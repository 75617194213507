// https://ant.design/components/grid/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const breakpoints: any = ['576px', '576px', '768px', '992px', '1200px', '1600px']

breakpoints.xs = breakpoints[0]
breakpoints.sm = breakpoints[1]
breakpoints.md = breakpoints[2]
breakpoints.lg = breakpoints[3]
breakpoints.xl = breakpoints[4]
breakpoints.xxl = breakpoints[5]

export default breakpoints
