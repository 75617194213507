import React from 'react'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Loading } from 'common/components/Loading'
import { PsqlTableProvider } from 'common/components/PsqlTable/psql_table_provider'
import { PersistentFiltersProvider } from 'common/contexts/persistent_filters'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { columnsFactory } from 'contractor/pages/@v2/OrderMaterials/factories/columns_factory'
import { OrdersMaterialsContent } from 'contractor/pages/@v2/OrderMaterials/order_materials_content'

function Content() {
  const { orderMaterialStore, companySettingStore, notificationStore, projectStore } = useStores()

  const { isLoading } = useQuery(() => {
    return Promise.all([projectStore.maybeIndexProjects(), companySettingStore.indexCompanyMaterialConfiguration()])
  })

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const columns =
    companySettingStore.companyAttributes.length === 0
      ? []
      : columnsFactory({
          ignoreProjectColumns: !!projectStore.selectedProject,
          companyAttributes: companySettingStore.companyAttributes,
          statusColumn: {
            getNotificationsCount: (id) => notificationStore.notificationCountByObject(id),
          },
          costCodeColumn: {
            phaseEnabled: costCodeSettings?.phase_code_enabled && !costCodeSettings?.independent_phase_codes_enabled,
            classEnabled: costCodeSettings?.class_enabled,
          },
        })

  return (
    <PersistentFiltersProvider
      ignoreOnFilterCounter={['order_state', 'filter_tab', 'project_id']}
      ignoreQueryParams={['refresh']}
      listStore={orderMaterialStore.listStore}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <PsqlTableProvider
          options={{
            data: toJS(orderMaterialStore.listStore.records),
            sort: {
              sortFromPersistentFilter: true,
              field: orderMaterialStore.listStore.searchState.sort,
              direction: orderMaterialStore.listStore.searchState.sort_direction,
            },
            columns: columns,
          }}
          tableName="AllOrdersMaterials"
        >
          <OrdersMaterialsContent />
        </PsqlTableProvider>
      )}
    </PersistentFiltersProvider>
  )
}

export const OrdersMaterialPageV2 = observer(Content)
