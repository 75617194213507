import React from 'react'

import { Alert } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'

import { Quote } from 'contractor/server/quote'

type FailureReasonProps = {
  quote?: Quote
}

export const FailureReason = ({ quote }: FailureReasonProps) => {
  const reason = quote.failure_reason
  let message = null
  if (!reason) {
    return null
  } else if (reason.includes('subtotal')) {
    message = `We extracted a subtotal of ${currencyFormatter(
      quote.sub_total,
      2,
    )} from the PDF but when we tried to add up all the line items from the quote, we didn't get that total. You should review each suggestion to make sure we didn't miss anything.`
  }

  if (!message) return null

  return (
    <FlexBoxX width="100%" justifyContent="start" marginBottom="16">
      <Alert message={message} type={'warning'} />
    </FlexBoxX>
  )
}
