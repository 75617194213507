import React from 'react'

import { Box } from 'common/components/boxes'
import { usePsqlTable } from 'common/components/PsqlTable/psql_table_provider'

import { ExportButton } from 'contractor/components/ExportButton/export_button'
import { useStores } from 'contractor/hooks/use-stores'

export const InvoiceDownload = () => {
  const {
    invoiceStore: { listStore },
  } = useStores()
  const { state } = usePsqlTable()

  return (
    <Box width="100%">
      <ExportButton
        onExport={listStore?.exportRecords}
        extras={{ export_type: 'export_invoices_files', only_discarded: true }}
        hiddenColumns={state?.hiddenColumns}
        title="Download Invoice Files"
      />

      <ExportButton
        onExport={listStore?.exportRecords}
        hiddenColumns={state?.hiddenColumns}
        title="Download CSV"
        extras={{
          only_discarded: true,
        }}
      />
    </Box>
  )
}
