import React, { useState } from 'react'

import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { Avatar, Button, Popover, Tooltip, Typography } from 'antd'

import { Box, FlexBoxY } from '../boxes'

interface RfqsBlockedHeaderProps {
  externalUsers?: Array<{ name: string }>
  isBlocked?: boolean
  onOpenModalOrderUnlocked?: () => void
  unlockEnabled?: boolean
}

export const RfqsBlockedHeader = ({
  externalUsers,
  isBlocked,
  onOpenModalOrderUnlocked,
  unlockEnabled,
}: RfqsBlockedHeaderProps) => {
  if (!externalUsers.length) return null

  const lockContent = () => {
    if (!isBlocked) return null

    if (unlockEnabled) {
      return <RfqsBlockedHeaderUnlockButton onOpenModalOrderUnlocked={onOpenModalOrderUnlocked} />
    }

    return <LockOutlined />
  }

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop="0.5rem" style={{ gap: '0.5rem' }}>
      {lockContent()}

      <Popover
        placement="bottomRight"
        content={
          <FlexBoxY minWidth={200} maxWidth={300} alignItems="flex-start" style={{ gap: 8 }}>
            {externalUsers
              .filter((user, index, self) => index === self.findIndex((u) => u.name === user.name))
              .map((user, index) => (
                <Box key={index} display="flex" alignItems="center" style={{ gap: 8 }}>
                  <Avatar size={20} style={{ backgroundColor: '#505F8D', fontSize: 14, minWidth: 20 }}>
                    {user.name.charAt(0)}
                  </Avatar>
                  <Typography.Text strong>{user.name}</Typography.Text>
                </Box>
              ))}
          </FlexBoxY>
        }
      >
        <Typography.Paragraph style={{ textAlign: 'right', marginBottom: 0, cursor: 'default' }}>
          This RFQs is being edited by other users.
        </Typography.Paragraph>
      </Popover>
    </Box>
  )
}

const RfqsBlockedHeaderUnlockButton = (props: RfqsBlockedHeaderProps) => {
  const [hover, setHover] = useState(false)

  return (
    <Tooltip title="Unlock order">
      <Box onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <Button
          icon={hover ? <UnlockOutlined /> : <LockOutlined />}
          type="ghost"
          shape="circle"
          onClick={props.onOpenModalOrderUnlocked}
          size="small"
        />
      </Box>
    </Tooltip>
  )
}
