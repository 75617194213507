import React, { useRef } from 'react'

import FilterOutlined from '@ant-design/icons/FilterOutlined'
import { Button, Typography, Space } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxY } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { Visibility } from 'common/components/Visibility'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { ManagedUser } from 'contractor/server/company_settings/manage_users'

import { CreateUser, CreateUserRef } from './create_user'
import { FilterUser } from './filter_user'
import { UsersRibbonFilter } from './ribbon_filter'
import { UsersTable } from './table'
import { useManageUsers } from './use_manage_users'

const ManageUsers = observer(() => {
  const { companySettingStore } = useStores()
  const { filterOptions, isLoading, showAddUsersButton } = useManageUsers()

  const drawerCreateUserRef = useRef<CreateUserRef>()
  const drawerFilterUserRef = useRef<DrawerRef>()

  const [filter, setFilter] = React.useState<string>('')

  const { isLoading: isLoadingRoles } = useQuery(companySettingStore.maybeGetRoles)

  function handleOpenCreateUserDrawerForReplace(user: ManagedUser) {
    drawerCreateUserRef.current.openForReplace(user)
  }

  if (isLoading || isLoadingRoles) {
    return <Loading />
  }

  return (
    <>
      <Page.Header>
        <FlexBoxY alignItems="flex-start">
          <Box display="flex" justifyContent="space-between" width="100%" flexWrap="wrap">
            <Typography.Title level={4}>Users</Typography.Title>

            <Space>
              <Button onClick={() => drawerFilterUserRef.current?.show()}>
                <FilterOutlined style={{ fontSize: 16 }} />
              </Button>

              {showAddUsersButton && (
                <Button type="primary" onClick={() => drawerCreateUserRef.current?.show()}>
                  Add users
                </Button>
              )}
            </Space>
          </Box>

          <Visibility.Hidden>
            <Typography.Paragraph style={{ margin: 0 }}>
              Invite users to join your company account and have access to your projects.
            </Typography.Paragraph>
          </Visibility.Hidden>
        </FlexBoxY>
      </Page.Header>

      <Page.Content>
        <UsersRibbonFilter onChange={setFilter} filter={filter} roles={companySettingStore.roles} />
        <UsersTable onReplace={handleOpenCreateUserDrawerForReplace} filter={filter} />
      </Page.Content>

      <CreateUser ref={drawerCreateUserRef} onCancel={drawerCreateUserRef.current?.close} />
      <FilterUser
        modalRef={drawerFilterUserRef}
        onCancel={drawerFilterUserRef.current?.close}
        options={filterOptions}
      />
    </>
  )
})

export default ManageUsers
