import axios from 'axios'

import { OrderDelivery } from 'common/server/deliveries'
import { OrderStates, OrderSubStates, StandardIndexRequest, StandardIndexResponse } from 'common/server/server_types'

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ConsolidatedOrders {
  interface CompanyMaterial {
    size: null | string

    // @todo: verify type for CompanyMaterial.unit
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    unit: any
    group: null | string
    unit_id: null | string
    material: null | string
    sub_group: null | string
    unit_name: string
    description: string
    project_ids: string[]
    cost_code_id: null | string
    manufacturer: string
    connection_type: null | string
    product_identifier: string
  }

  export interface StandardIndexRequest {
    sort?: string
    filters: { [filter_name: string]: string | string[] | boolean }
    page?: number
    per_page?: number
    search?: string
  }

  export interface OrderMaterial {
    id: string
    quantity: number

    // @todo: verify type for OrderMaterial.cost_code
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cost_code: any
    unit_cost: number
    company_material: CompanyMaterial
  }

  interface LastAction {
    author_type: 'User' | 'VendorUser' | 'CompanyVendorContact' | 'SubBase'
    author_name: string
    created_at: string
  }

  export interface Order {
    id: string
    name: string
    delivery_issue_types: string
    delivery_issues: string
    has_open_delivery_issue: boolean
    draft_vendor_names: Array<string>
    drafted_by_id?: string
    drafted_by_name?: string
    invoice_ids: Array<string>
    invoice_numbers: string[]
    invoiced: string
    integration_sync_status: 'SYNCED' | 'NOT_SYNCED'
    order_created_at: string
    order_materials: Array<OrderMaterial>
    order_number: string
    order_updated_at: string
    ordered_at: string
    ordered_by_name: string
    order_package_id: string
    package_name: string
    project_group_name: string
    project_name: string
    project_number: string
    project_name_with_company: string
    commitment_name: string
    quote_number: string
    requested_by_name: string
    state: OrderStates
    subtotal_before_tax: number
    sub_state: OrderSubStates
    tags: string[]
    user_email: string
    user_first_name: string
    user_last_name: string
    vendor_name: string
    vendor_user: string
    created_at: string
    updated_at: string
    company_id: string
    company_vendor_id: string
    company_vendor_name: string
    external_vendor_id: string
    order_id: string
    ordered_by_id: string
    project_group_id: string
    project_id: string
    requested_by_id: string
    user_id: string
    deliveries: Array<OrderDelivery>
    is_draft: boolean
    contractor_orders_filter: OrderStates | OrderSubStates
    last_action: LastAction
    grand_total: number
    quote_scheduled_to_expire_at: string
    invoices: Array<{
      id: string
      number: string
    }>
    invoice_amount_remaining: string
    invoices_count: number
    is_quick_created: boolean
    lead_time_cutoff_date_timestamp: number
    lead_time_cutoff_date: string
    is_blocked_by?: string
  }

  export interface Facets {
    [key: string]: string | Array<string>
  }

  export type StateCounts = {
    [stateName: string]: number
  }

  export type Response = StandardIndexResponse<Array<Order>> & {
    state_counts: StateCounts
  }
}

export async function index(request: StandardIndexRequest) {
  const { filters, ...restRequest } = request
  const url = `/consolidated_orders`

  return axios.get<ConsolidatedOrders.Response>(url, {
    params: {
      ...filters,
      ...restRequest,
    },
  })
}

export function facets() {
  const url = `/consolidated_orders/facets`

  return axios.get<ConsolidatedOrders.Facets>(url)
}

export const consolidatedOrdersRequests = {
  index,
  facets,
}
