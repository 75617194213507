import { css } from '@emotion/react'
import styled from '@emotion/styled'

const responsiveStyle = ({ theme }) =>
  css`
    @media (min-width: ${theme.breakpoints[0]}) {
      & > span {
        display: flex;
      }

      & .ant-upload {
        margin-right: 12px;

        &.ant-upload-drag {
          width: auto;
          height: auto;
        }

        & .ant-upload-btn {
          padding: 16px;
        }
      }

      & .ant-upload-list {
        flex-grow: 1;
        width: 0;

        & .ant-upload-list-text-container:first-of-type .ant-upload-list-item {
          margin-top: 0;
        }
      }
    }
  `

export const Wrapper = styled.div<{ isResponsive: boolean }>`
  width: 100%;

  .ant-upload.ant-upload-drag {
    height: auto;
  }

  ${(props) => {
    return props?.isResponsive && responsiveStyle(props)
  }}
`
