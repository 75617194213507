import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { SelectUnit, SelectUnitProps } from 'common/components/SelectUnit'

export type UnitColumnProps = {
  edit?: boolean
  prevValue?: string
} & SelectUnitProps

const style = { fontSize: 12, whiteSpace: 'nowrap' } as React.CSSProperties

const commonProps = { display: 'flex', flexDirection: 'column', width: '100%' }

export const UnitColumn = ({ edit = true, prevValue, ...props }: UnitColumnProps) => {
  const canShowIncrements = !!props?.value?.original?.increments_label && !props?.value?.original?.override_label

  if (edit) {
    return (
      <Box {...commonProps}>
        <SelectUnit {...props} optionLabelProp="selectedLabel" />
        {canShowIncrements && (
          <Typography.Text type="secondary" style={style}>
            {props?.value?.original?.increments_label?.replace(/[()]/g, '')}
          </Typography.Text>
        )}
        {!!prevValue && <Typography.Text type="secondary" style={style}>{`Prev: ${prevValue}`}</Typography.Text>}
      </Box>
    )
  }

  return (
    <Box {...commonProps}>
      <Typography.Text>{props?.value?.selectedLabel}</Typography.Text>
      {canShowIncrements && (
        <Typography.Text type="secondary" style={style}>
          {props?.value?.original?.increments_label?.replace(/[()]/g, '')}
        </Typography.Text>
      )}
    </Box>
  )
}
