import React, { useState } from 'react'

import { Card, Tabs, Row, Col } from 'antd'

import { observer } from 'mobx-react-lite'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { FlexBoxY } from 'common/components/boxes'
import { Comments } from 'common/components/comments'
import { ErrorBoundary } from 'common/components/ErrorBoundary/error_boundary'
import { OrderHistory } from 'common/components/OrderHistory/history'
import { useTheme } from 'common/hooks/use-theme'

import { useStores } from 'contractor/hooks/use-stores'
import { MaterialsAndDeliveries } from 'contractor/pages/OrderDetail/materials_and_deliveries'

import { Attachments } from './attachments'

type OrderOverviewProps = {
  onFinish: (
    state_changes: { ordered_at?: string; cancelled_at?: string },
    action: number,
    silent_update?: boolean,
  ) => Promise<string>
}

const OrderOverview = observer<OrderOverviewProps>(({ onFinish }) => {
  const theme = useTheme()
  const { orderStore, companySettingStore } = useStores()
  const source = location.pathname.substr(1)

  const [loading, setLoading] = useState(false)
  const [activeKey, setActiveKey] = useState('messages')

  const { comments } = orderStore.selectedOrder
  const cardStyle = theme.cardStyles.normal

  return (
    <FlexBoxY width="100%" alignItems="flex-start" mt={16}>
      <MaterialsAndDeliveries />

      <Row gutter={[16, 16]} style={{ width: 'calc(100% + 20px)', marginTop: 16 }}>
        <Col xs={24} md={24} lg={12}>
          <Card style={cardStyle}>
            <Attachments canInputVendorData={false} onFinish={onFinish} />
          </Card>
        </Col>

        <Col xs={24} md={24} lg={12}>
          <Card style={cardStyle}>
            <Tabs
              activeKey={activeKey}
              onChange={async (key) => {
                setActiveKey(key)
                trackEvent(Events.ORDER_HISTORY, source, { clickView: true })
                try {
                  setLoading(true)
                  await orderStore.getOrderHistory(orderStore.selectedOrder?.id)
                } finally {
                  setLoading(false)
                }
              }}
              items={[
                {
                  label: 'Messages',
                  key: 'messages',
                  children: (
                    <Comments
                      comments={comments}
                      onChangeNewComment={orderStore.updateNewComment.bind(orderStore)}
                      newComment={orderStore.newComment}
                      css={{ width: '100%' }}
                    />
                  ),
                },
                {
                  label: 'History',
                  key: 'history',
                  children: (
                    <ErrorBoundary isFull={false}>
                      <OrderHistory
                        isLoading={loading}
                        data={orderStore.orderHistory}
                        companyAttributes={companySettingStore.companyMaterialConfiguration.company_attributes}
                      />
                    </ErrorBoundary>
                  ),
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </FlexBoxY>
  )
})

export default OrderOverview
