import axios, { AxiosResponse } from 'axios'

export interface ProjectOrderStatsResponse {
  project_state_counts: { [key: string]: { [key: string]: number } }
}
export interface OrderStatsResponse {
  state_counts: { [key: string]: number }
}

export async function order_stats(project_id?: string): Promise<AxiosResponse<OrderStatsResponse>> {
  let url = '/dashboard/order_stats'
  if (project_id) {
    url += `?project_id=${project_id}`
  }
  return await axios.get(url)
}

export async function order_stats_by_project(): Promise<AxiosResponse<ProjectOrderStatsResponse>> {
  return await axios.get('/dashboard/order_stats_by_project')
}
