/** @jsx jsx */

import React from 'react'

import { Column, useTable } from 'react-table'

import _ from 'lodash'

import { jsx } from '@emotion/react'
import styled from '@emotion/styled'

import { Button, Typography, Spin } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { useTheme } from 'common/hooks/use-theme'

import SortIcon from './sort_icon'

const Wrapper = styled(FlexBoxY)`
  .show-on-hover-row {
    visibility: hidden;
  }

  table tbody tr:hover .show-on-hover-row {
    visibility: visible;
  }
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface SubBaseTableProps<T = any> {
  data: T[]
  onClickRow?: (data: { rowIndex: number; row: T }) => void
  columns: Column[]
  tableName?: string
  tableStyle?: {}
  className?: string
  groupByPath?: string
  groupByHeader?: boolean
  hasMore?: boolean
  loadMore?: () => void
  indexName?: string
  searchClient?: string
  isSorted?: boolean
  replicas?: string[]
  setTableColumns?: (columns: Column[]) => void
  disabled?: boolean
  isLoading?: boolean
}

const SubBaseTable = observer<SubBaseTableProps>((props) => {
  const { className, data, groupByPath, groupByHeader, onClickRow, replicas, isLoading } = props
  const { tableStyle, tableName } = props
  const { hasMore, loadMore } = props
  const theme = useTheme()
  const [loadingMore, setLoadingMore] = React.useState(false)

  const memoizedColumns = React.useMemo(() => props.columns, [props.columns])

  const localStorageKey = `hidden${tableName}Columns`
  const { getTableProps, getTableBodyProps, state, headerGroups, prepareRow, rows, columns } = useTable({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns: memoizedColumns as Column<any>[],
    data: toJS(data),
    initialState: {
      hiddenColumns: JSON.parse(localStorage[localStorageKey] || '[]'),
    },
  })

  React.useEffect(() => {
    setLoadingMore(false)
  }, [data.length])

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(state.hiddenColumns))
  }, [state.hiddenColumns.length])

  React.useEffect(() => {
    if (props.setTableColumns) {
      props.setTableColumns(columns)
    }
  }, [state.hiddenColumns.length])

  // Tried using styled components but was having issues passing ...getTableProps
  // TODO: Re-evaluate scrolling, might not work on all browsers
  const fullTableStyle = {
    width: '100%',
    ...(tableStyle || {}),
  }

  return (
    <Wrapper
      width="100%"
      height="100%"
      overflowX="auto"
      alignItems="flex-start"
      justifyContent="flex-start"
      bg="white"
      className={className}
    >
      {isLoading ? (
        <Spin
          style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        />
      ) : (
        <React.Fragment>
          <table {...getTableProps()} css={fullTableStyle}>
            <thead>
              <tr>
                {/* Need to use header groups in order to get hiddenColumns functionality */}
                {headerGroups[0]?.headers.map((column, i) => (
                  <th
                    key={i}
                    {...column.getHeaderProps()}
                    css={_.omitBy(
                      {
                        width: column?.width,
                        minWidth: column?.minWidth,
                        maxWidth: column?.maxWidth,
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        backgroundColor: '#FAFAFA',
                        padding: '5px',
                        height: '50px',
                        textAlign: 'left',
                      },
                      _.isUndefined,
                    )}
                  >
                    <FlexBoxX alignItems="center" justifyContent="space-between">
                      {column.render('Header', { localStorageKey })}
                      {props.indexName && (
                        <SortIcon
                          sort_replica={column['sort_replica_name']}
                          defaultRefinement={props.indexName}
                          replicas={replicas}
                          items={[]}
                        />
                      )}
                    </FlexBoxX>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row)

                const hasBorder = true
                let rowStyle: {} = {
                  borderBottom: '1px solid',
                  height: '40px',
                  borderColor: theme.colors['gray-2'],
                }
                let showGroupTitle = ''
                if (groupByPath) {
                  const lastRowGroupBy = _.get(rows, `[${rowIndex - 1}].${groupByPath}`)
                  const currentRowGroupBy = _.get(rows, `[${rowIndex}].${groupByPath}`)
                  const nextRowGroupBy = _.get(rows, `[${rowIndex + 1}].${groupByPath}`)
                  // Only show the border and header when the next row is different
                  if (currentRowGroupBy == nextRowGroupBy) {
                    rowStyle = {} // Clears the border
                  }
                  // Only show title if different than the previous one
                  if (groupByHeader && lastRowGroupBy != currentRowGroupBy) {
                    showGroupTitle = currentRowGroupBy
                  }
                }
                if (onClickRow) {
                  rowStyle['&:hover'] = {
                    backgroundColor: theme.colors['gray-2'],
                  }
                }

                // TODO: Maybe add styling for disabled rows?
                return (
                  <React.Fragment key={rowIndex}>
                    {/* If do showGroupTitle && ... will get dev error */}
                    {showGroupTitle ? (
                      <tr key={showGroupTitle}>
                        <td css={{ paddingTop: '20px' }}>
                          <Typography.Text type="secondary">{showGroupTitle}</Typography.Text>
                        </td>
                      </tr>
                    ) : null}
                    <tr
                      key={rowIndex}
                      {...row.getRowProps()}
                      css={hasBorder ? rowStyle : undefined}
                      onClick={() => onClickRow && onClickRow({ rowIndex, row: row.original })}
                    >
                      {row.cells.map((cell, cellIndex) => {
                        return (
                          <td key={cellIndex} {...cell.getCellProps()} css={{ padding: '12px 5px' }}>
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>

          {hasMore && (
            <FlexBoxX mt={16} width="100%">
              <Button
                type="link"
                onClick={() => {
                  setLoadingMore(true)
                  loadMore()
                }}
                loading={loadingMore}
              >
                Load More
              </Button>
            </FlexBoxX>
          )}
        </React.Fragment>
      )}
    </Wrapper>
  )
})

export default SubBaseTable
