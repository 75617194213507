import { BindAll } from 'lodash-decorators'

import { observable, action } from 'mobx'

import { subscribeCompanyChannel, TYPE_CONTRACTOR } from 'common/data-access/company_websocket'

import { switch_company, get_companies } from 'contractor/server/companies'

@BindAll()
export default class CompanyStore {
  companies = observable.array([])
  @observable subscriptions = []

  @action
  async getCompanies() {
    const { data } = await get_companies()
    this.companies.replace(data?.companies || [])
  }

  switchCompany(companyId: string) {
    return switch_company(companyId)
  }

  companySubscribe(id: string, handleWebSocketMessage: (message: {}) => void) {
    if (this.subscriptions.includes(id)) {
      // Do not subscribe twice to the same entity
      return
    }
    this.subscriptions.push(id)
    return subscribeCompanyChannel(handleWebSocketMessage, TYPE_CONTRACTOR, id, id)
  }
}
