import { useMemo } from 'react'

import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

export const useManageUsers = () => {
  const { companySettingStore, projectGroupsStore, userStore } = useStores()

  const { indexUserFilter } = companySettingStore

  const { isLoading } = useQuery(() => {
    return Promise.all([companySettingStore.indexUsers(), projectGroupsStore.indexProjectGroups()])
  })

  const showAddUsersButton = userStore.canManageUsers

  const filterOptions = useMemo(
    () => [
      {
        label: 'Only show activated',
        checked: indexUserFilter.activated,
        onCheck: () => {
          indexUserFilter.activated = !indexUserFilter.activated
          indexUserFilter.deactivated = false
        },
      },
      {
        label: 'Only show deactivated',
        checked: indexUserFilter.deactivated,
        onCheck: () => {
          indexUserFilter.deactivated = !indexUserFilter.deactivated
          indexUserFilter.activated = false
        },
      },
      {
        label: 'Only show ungrouped',
        checked: indexUserFilter.ungrouped,
        onCheck: () => {
          indexUserFilter.ungrouped = !indexUserFilter.ungrouped
        },
      },
    ],
    [indexUserFilter.activated, indexUserFilter.deactivated, indexUserFilter.ungrouped],
  )

  return {
    filterOptions,
    isLoading,
    showAddUsersButton,
  }
}
