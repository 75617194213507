import React from 'react'

import { Empty } from 'antd'

import { Box } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'

interface PdfViewerProps {
  url: string
  style?: React.CSSProperties
  loading?: boolean
}

export function PdfViewer(props: PdfViewerProps) {
  const { url, style, loading } = props

  if (!url && !loading) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No files extracted" style={{ padding: 24 }} />
  }

  if (loading || url.length === 0) {
    return (
      <Box height={250} display="flex" alignItems="center" justifyContent="center">
        <Loading />
      </Box>
    )
  }

  // Detect Chrome
  const chromeAgent = navigator.userAgent.indexOf('Chrome') > -1

  // Detect Safari
  const safariAgent = navigator.userAgent.indexOf('Safari') > -1

  // Detect Firefox
  const firefoxAgent = navigator.userAgent.indexOf('Firefox') > -1

  // HACK: Chrome thinks it is Safari too, so we have to filter out Chrome
  // Returns true if it is Chrome, firefox or chromium based, not Safari
  const isChromium = (chromeAgent && safariAgent) || firefoxAgent

  if (!isChromium) {
    return (
      <Box style={{ flexGrow: 1, minHeight: 800, overflow: 'auto', ...style }}>
        <img src={url} width="100%" />
      </Box>
    )
  }

  return <object data={url} type="application/pdf" width="100%" style={{ flexGrow: 1, minHeight: 800, ...style }} />
}
