import React from 'react'

import { ProjectOutlined } from '@ant-design/icons'
import { Space, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import OrderState from 'common/components/statuses/order_state'
import { CellOrderNumber } from 'common/components/table/cells'
import { OrderStates, OrderSubStates } from 'common/server/server_types'

interface Props {
  notificationCount?: number
  companyMaterialDescription?: string
  companyMaterialQuantity?: string
  companyMaterialProductIdentifier?: string
  companyMaterialSize?: string
  orderName?: string
  orderNumber?: string
  orderId?: string
  orderPackageId?: string
  orderState?: OrderStates
  orderSubState?: OrderSubStates
  projectName?: string
  deliveryIssueType?: string
  deliveryHasOpenIssue?: boolean
}

export function OrderMaterialMobileItem(props: Props) {
  const {
    notificationCount,
    companyMaterialDescription,
    companyMaterialQuantity,
    companyMaterialProductIdentifier,
    companyMaterialSize,
    orderName,
    orderNumber,
    orderId,
    orderPackageId,
    orderState,
    orderSubState,
    projectName,
    deliveryIssueType,
    deliveryHasOpenIssue,
  } = props

  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Typography.Text>{companyMaterialDescription}</Typography.Text>
      <Box display="flex" alignItems="center">
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>{`QTY: ${
          companyMaterialQuantity || 'N/A'
        }`}</Typography.Text>
        {!!companyMaterialProductIdentifier && (
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            &nbsp;{`| ID: ${companyMaterialProductIdentifier}`}
          </Typography.Text>
        )}
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          &nbsp;{`| SIZE: ${companyMaterialSize || 'N/A'}`}
        </Typography.Text>
      </Box>

      <CellOrderNumber
        orderName={orderName}
        orderNumber={orderNumber}
        orderId={orderId}
        orderPackageId={orderPackageId}
        state={orderState}
        subState={orderSubState}
      />

      <OrderState
        state={orderState}
        sub_state={orderSubState}
        deliveryIssues={[deliveryIssueType]}
        notificationCount={notificationCount}
        showIssue={deliveryHasOpenIssue}
      />

      <Space>
        <ProjectOutlined />
        <Typography.Text>{projectName}</Typography.Text>
      </Space>
    </Space>
  )
}
