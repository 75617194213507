import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { Page } from 'common/components/Page'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { ManageGroups } from 'contractor/pages/CompanySettings/ManageGroups/manage_groups'

import Addresses from './addresses'
import Configuration from './Configuration/index'
import { CostCodes } from './CostCodes'
import { Invoices } from './Invoices'
import ManageUsers from './ManageUsers/manage_users'
import { OtherSettings } from './OtherSettings'
import CompanyPermissions from './Permissions/index'

const ProjectSettings = observer(() => {
  const { companySettingStore, userStore } = useStores()

  const history = useHistory()
  const location = useLocation()

  // Do this here so by the time we open the tab it's loaded
  useQuery(companySettingStore.indexCompanyMaterialConfiguration)

  const activeKey = new URLSearchParams(location.search).get('tab')

  const tabItems = [
    { label: 'Users', key: 'users', children: <ManageUsers /> },
    { label: 'Groups', key: 'groups', children: <ManageGroups /> },
    ...(userStore.canManageUsers
      ? [{ label: 'Permissions', key: 'permissions', children: <CompanyPermissions /> }]
      : []),
    { label: 'Addresses', key: 'addresses', children: <Addresses /> },
    ...(userStore.canUseCostCode ? [{ label: 'Cost Codes', key: 'cost-codes', children: <CostCodes /> }] : []),
    { label: 'Orders', key: 'orders', children: <Configuration /> },
    ...(userStore.canUseInvoices ? [{ label: 'Invoices', key: 'invoices', children: <Invoices /> }] : []),
    { label: 'Other settings', key: 'other-settings', children: <OtherSettings /> },
  ]

  return (
    <Page>
      <Page.Tabs
        activeKey={activeKey || 'users'}
        onChange={(key) => {
          history.push({
            search: new URLSearchParams({ tab: key }).toString(),
          })
        }}
        items={tabItems}
        destroyInactiveTabPane
      />
    </Page>
  )
})

export default ProjectSettings
