import React from 'react'

import { Alert } from 'antd'

import { FlexBoxY } from 'common/components/boxes'

export const InvoiceCorrelatingOverlay = () => {
  return (
    <>
      <Alert
        message="Auto match in progress. Please wait until it is done."
        type="warning"
        showIcon
        style={{ width: '100%' }}
      />
      <FlexBoxY
        style={{
          position: 'fixed',
          top: 190,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 800,
          backgroundColor: 'rgba(255, 255, 255, 0.6)',
        }}
      />
    </>
  )
}
