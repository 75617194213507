import styled from '@emotion/styled'

import { FlexBoxY } from 'common/components/boxes'

export const Wrapper = styled(FlexBoxY)`
  & .ant-table-header {
    position: sticky;
    top: 0;
    z-index: ${({ theme }) => theme.zIndices['docked']};
    white-space: nowrap;
  }

  & .ant-table-body {
    overflow-x: auto !important;
    overflow-y: auto !important;
  }

  & .ant-table-tbody > tr.ant-table-row-selected > td,
  & .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: #f8fbff;
  }

  & .editable-cell-value-wrap {
    padding: 3px 11px;
    min-height: 32px;
    width: 100%;
  }

  & .ant-table-row:hover .editable-cell-value-wrap {
    outline: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  & .ant-table-placeholder {
    display: none;
  }

  & .ant-table-cell {
    vertical-align: top;
  }

  .ant-table-summary .ant-table-cell:last-child {
    padding-right: 24px;
  }

  // Always show arrows on the input number
  & .ant-input-number .ant-input-number-handler-wrap {
    opacity: 1;
  }

  // Highlight the row when the materials need approval
  & .requested_material {
    &:hover,
    & .ant-table-cell {
      background-color: ${({ theme }) => theme.colors['gold-1']} !important;
    }

    & * {
      border-color: ${({ theme }) => theme.colors['gold-3']} !important;
    }
  }
`
