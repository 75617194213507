import React, { useState, useEffect } from 'react'

import { Alert, Form, Input, Divider, Button, Checkbox, Tag, Typography, Modal } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { CostCodePhase } from 'common/server/cost_codes/cost_code_phases'

import { SelectProject } from 'contractor/components/SelectProject'
import { useStores } from 'contractor/hooks/use-stores'

type UpsertCostCodePhaseProps = {
  onCancel: () => void
  onSubmit: (data: CostCodePhase) => void
  initialValues?: Partial<{
    id: string
    code: string
    dataSource: 'INTERNAL' | 'EXTERNAL'
    description: string
    projectIdsRelation?: string[]
    active: boolean
  }>
}

export const UpsertCostCodePhase = observer<UpsertCostCodePhaseProps, DrawerRef>(
  ({ onCancel, onSubmit, initialValues }, ref) => {
    const { costCodeStore, companySettingStore, integrationStore } = useStores()

    const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

    const procore = integrationStore.isProcore()
    const integrationName = integrationStore.getIntegrationName()

    const [alertMessage, setAlertMessage] = useState('')
    const [isSubmitting, setSubmitting] = useState(false)

    const [form] = Form.useForm()

    const handleCancel = () => {
      form.resetFields()
      setAlertMessage('')
      onCancel()
    }

    const handleConfirm = async (formValues) => {
      setSubmitting(true)

      try {
        const { id } = initialValues || {}
        const { code, description, projectIdsRelation, active = true } = formValues || {}

        const payload = { code, description, project_ids_relation: projectIdsRelation, active }
        const costCodePhase = id
          ? await costCodeStore.updateCostCodePhase({ id, ...payload })
          : await costCodeStore.createCostCodePhase(payload)

        onSubmit(costCodePhase)
        handleCancel()
      } catch (error) {
        if (error?.response?.data?.error?.includes('duplicate key value violates')) {
          setAlertMessage('Unable to save due to duplicate phase code.')
        } else {
          setAlertMessage(error?.response?.data?.error || 'Unable to add phase code')
        }
      } finally {
        setSubmitting(false)
      }
    }

    const handleSubmit = async (formValues) => {
      if (initialValues?.dataSource === 'EXTERNAL') {
        Modal.confirm({
          title: 'Are you sure you want to save?',
          content: (
            <p>
              You are updating a data that was imported from {integrationName}. This action may affect PO and Invoice
              sync. <br />
              Are you sure?
            </p>
          ),
          onOk: () => handleConfirm(formValues),
        })
      } else {
        await handleConfirm(formValues)
      }
    }

    useEffect(() => {
      form.setFieldsValue(initialValues)
    }, [initialValues])

    const dataSource = (value) => {
      const tag = {
        color: value === 'INTERNAL' ? 'blue' : 'gold',
        text: value === 'INTERNAL' ? 'SubBase' : 'Procore',
      }
      return (
        <Tag color={tag.color} style={{ marginTop: 5, minWidth: 67, textAlign: 'center' }}>
          {tag.text}
        </Tag>
      )
    }

    return (
      <Drawer
        bgGray
        ref={ref}
        title={
          <Box display="flex" width="100%" height="100%" alignItems="flex-start" justifyContent="space-between">
            <Box display="inline-flex" alignItems="flex-start">
              <Typography.Text style={{ marginTop: 5 }}>
                {initialValues?.id ? 'Update Phase' : 'Add New Phase'}
              </Typography.Text>
            </Box>
            {procore && (
              <Box display="inline-flex" alignItems="flex-end">
                {initialValues?.id ? dataSource(initialValues?.dataSource) : dataSource('INTERNAL')}
              </Box>
            )}
          </Box>
        }
        onClose={handleCancel}
        width={378}
      >
        <Box width="100%" height="100%" p={16} overflow="auto">
          {alertMessage && <Alert type="error" message={alertMessage} style={{ marginBottom: '20px' }} />}

          <Form layout="vertical" form={form} onFinish={handleSubmit} style={{ width: '100%' }}>
            <Form.Item name="code" label="Code" rules={[{ required: true, message: 'Code is required!' }]}>
              <Input style={{ width: '100%' }} required />
            </Form.Item>

            <Form.Item name="description" label="Description">
              <Input style={{ width: '100%' }} />
            </Form.Item>

            {costCodeSettings?.project_specific_phase_codes_enabled && (
              <Form.Item name="projectIdsRelation" label="Projects">
                <SelectProject mode="multiple" allowClear />
              </Form.Item>
            )}

            {costCodeSettings?.independent_phase_codes_enabled && (
              <Form.Item name="active" label="Active" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            )}

            <Divider />

            <Box display="flex" justifyContent="space-between">
              <Button onClick={onCancel} disabled={isSubmitting} style={{ width: 100 }}>
                Cancel
              </Button>

              <Button type="primary" htmlType="submit" loading={isSubmitting} style={{ width: 100 }}>
                {initialValues?.id ? 'Update' : 'Create'}
              </Button>
            </Box>
          </Form>
        </Box>
      </Drawer>
    )
  },
  { forwardRef: true },
)
