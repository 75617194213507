import { waitFor } from '@testing-library/react'
import { mockedCompanySettingsStore } from 'testHelpers/mocks/companySettingsStore'
import { mockedUseFlag } from 'testHelpers/mocks/featureFlag'
import { mockedOrderStore, mockedOrderStoreUnlockOrderSession } from 'testHelpers/mocks/orderStore'
import { mockedUseQuery } from 'testHelpers/mocks/query'
import { mockedUserStore, mockedUserStoreCurrentUser } from 'testHelpers/mocks/userStore'
import { renderHooksProvider } from 'testHelpers/providers'

import { mockedAntd, mockedAntdNotification } from 'testHelpers/mocks/antd'

import { useLockPo } from 'contractor/hooks/use-lock-po'
import { ShowOrderResponse } from 'contractor/server/orders'

jest.mock('antd', () => mockedAntd)

jest.mock('contractor/hooks/use-flag', () => ({
  useFlag: mockedUseFlag,
}))

jest.mock('contractor/hooks/use-stores', () => ({
  useStores: jest.fn().mockReturnValue({
    orderStore: mockedOrderStore,
    userStore: mockedUserStore,
    companySettingStore: mockedCompanySettingsStore,
  }),
}))

jest.mock('common/hooks/use-query', () => ({
  useQuery: jest.fn().mockReturnValue(mockedUseQuery),
}))

const mockedOrder = {
  id: 123,
} as unknown as ShowOrderResponse

describe('[hooks: useLockPO]', () => {
  it('should initialize with default values ​​when there is no order', () => {
    mockedOrderStore.selectedOrder = null
    const { result } = renderHooksProvider(() => useLockPo())

    expect(result.current.orderLocked).toBe(false)
    expect(result.current.orderSession.isBlocked).toBe(false)
    expect(result.current.orderSession.isReleased).toBe(false)
    expect(result.current.orderSession.externalUser).toBeNull()
  })

  it('should block the order when there is an external user', () => {
    const externalUser = {
      id: '789',
      name: 'External User',
    }
    mockedOrderStore.orderSessionOwner = externalUser
    mockedOrderStore.selectedOrder = mockedOrder

    const { result } = renderHooksProvider(() => useLockPo())

    expect(result.current.orderSession.isBlocked).toBe(true)
    expect(result.current.orderSession.externalUser).toEqual(externalUser)
  })

  it('should clear orderSessionOwner on unmount', () => {
    mockedOrderStore.selectedOrder = mockedOrder

    const { unmount } = renderHooksProvider(() => useLockPo())

    unmount()

    expect(mockedOrderStore.orderSessionOwner).toBeNull()
  })

  it('should call unlockOrderSession when executing onUnlock', async () => {
    mockedOrderStore.selectedOrder = mockedOrder

    const { result } = renderHooksProvider(() => useLockPo())

    await waitFor(() => {
      result.current.orderSession.onUnlock()

      expect(mockedOrderStore.unlockOrderSession).toHaveBeenCalledWith(
        { order_ids: [mockedOrder.id] },
        {
          id: mockedUserStoreCurrentUser.id,
          name: mockedUserStoreCurrentUser.first_name,
        },
      )
    })
  })

  it('should refresh session when executing onRenew', async () => {
    mockedOrderStore.selectedOrder = mockedOrder
    mockedOrderStore.selectedOrderDirty = true

    const { result } = renderHooksProvider(() => useLockPo())

    await waitFor(() => {
      result.current.orderSession.onRenew()

      expect(mockedOrderStore.updateOrderSession).toHaveBeenCalledWith(
        { order_ids: [mockedOrder.id], order_changed: true },
        {
          id: mockedUserStoreCurrentUser.id,
          name: mockedUserStoreCurrentUser.first_name,
        },
      )
    })
  })

  it('should show error notification when failed to unlock', async () => {
    mockedOrderStore.selectedOrder = mockedOrder

    const { result } = renderHooksProvider(() => useLockPo())
    mockedOrderStoreUnlockOrderSession.mockImplementation(Promise.reject)

    await waitFor(() => {
      result.current.orderSession.onUnlock()

      expect(mockedAntdNotification.error).toHaveBeenCalledWith({
        message: 'Error while unlock order, try again later',
        placement: 'bottomLeft',
      })
    })
  })
})
