import React from 'react'

import { Button, notification } from 'antd'

import { useStores } from 'contractor/hooks/use-stores'

type Operation = 'create' | 'update'

type UseNotifyCompanyVendor = {
  operation?: Operation
}

type NotifyOrderParams = {
  companyVendorContactId: string
}

export const useNotifyCompanyVendor = ({ operation = 'create' }: UseNotifyCompanyVendor = {}) => {
  const { companyVendorStore } = useStores()

  const handleClick = ({ companyVendorContactId }) => {
    notification.destroy()
    companyVendorStore.selectedVendorContactId = companyVendorContactId
  }

  const notifyCompanyVendor = ({ companyVendorContactId }: NotifyOrderParams) => {
    const message = operation === 'create' ? 'Created new vendor contact' : 'Updated vendor contact'
    const description = 'Please allow a few seconds for your changes to appear'

    notification.success({
      message,
      description,
      duration: 10,
      placement: 'bottomLeft',
      btn: (
        <Button type="primary" size="small" onClick={() => handleClick({ companyVendorContactId })}>
          View vendor
        </Button>
      ),
    })
  }

  return { notifyCompanyVendor }
}
