import React from 'react'

import { InteractionOutlined } from '@ant-design/icons'
import { Typography } from 'antd'

import { useTheme } from 'common/hooks/use-theme'

import { Box } from '../boxes'

type NotificationIconProps = {
  integrationSource: string
}

export const NotificationIntegrationIcon = (props: NotificationIconProps) => {
  const { integrationSource } = props
  const theme = useTheme()

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start">
      <InteractionOutlined style={{ color: theme.colors['gray-7'], marginRight: 8 }} />
      <Typography.Text style={{ fontSize: 12, color: theme.colors['gray-7'], alignSelf: 'flex-start' }}>
        {integrationSource}
      </Typography.Text>
    </Box>
  )
}
