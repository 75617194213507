import React from 'react'

import { ShopOutlined } from '@ant-design/icons'
import { Typography, Card, Tag, Tooltip } from 'antd'

import { FlexBoxY, FlexBoxX } from 'common/components/boxes'
import { useTheme } from 'common/hooks/use-theme'

export const Logo = ({ logo }) => {
  const theme = useTheme()

  if (logo) {
    return <img src={logo} style={{ objectFit: 'cover', marginLeft: 16, maxHeight: 60, maxWidth: 120 }} />
  }

  return <ShopOutlined style={{ color: theme.colors.primary, marginLeft: 16, fontSize: 60 }} />
}

export const VendorCard = ({ name, domain, logoUrl, description }) => {
  return (
    <Card style={{ width: '100%' }}>
      <FlexBoxY width="100%" alignItems="flex-start">
        <FlexBoxX justifyContent="space-between" width="100%" mb="12px" flexGrow={0}>
          <FlexBoxY alignItems="flex-start">
            <Typography.Title level={4}>{name}</Typography.Title>
            <Tooltip title="Click to go to the website">
              <Typography.Link href={`http://${domain}`} target="_blank" rel="noreferrer">
                {domain}
              </Typography.Link>
            </Tooltip>
            <FlexBoxX>
              {[].map((tag) => (
                <Tag key={tag} color="green">
                  {tag}
                </Tag>
              ))}
            </FlexBoxX>
          </FlexBoxY>

          <Logo logo={logoUrl} />
        </FlexBoxX>

        {description && (
          <Typography.Paragraph type="secondary" style={{ margin: 0 }}>
            {description}
          </Typography.Paragraph>
        )}
      </FlexBoxY>
    </Card>
  )
}
