import React from 'react'

import { Button, Modal, Tooltip, Typography } from 'antd'

import { OrderBlockedModalExpired } from './order_blocked_modal_expired'
import { OrderBlockedModalUnlocked } from './order_blocked_modal_unlocked'

interface OrderBlockedModalsProps {
  externalUser?: { name: string }
  modalOrderExpiredOpened?: boolean
  modalOrderLockedOpened?: boolean
  modalOrderReleasedOpened?: boolean
  modalOrderUnlockedOpened?: boolean
  onCancelModalOrderExpired?: () => void
  onCancelModalOrderReleased?: () => void
  onCloseModalOrderLocked?: () => void
  onCloseModalOrderUnlocked?: () => void
  onRenew?: () => void
  onUnlock?: () => void
  onUpdate?: () => void
  isRFQ?: boolean
  unlockEnabled?: boolean
}

export const OrderBlockedModals = ({
  externalUser = { name: 'a user' },
  modalOrderExpiredOpened,
  modalOrderLockedOpened,
  modalOrderReleasedOpened,
  modalOrderUnlockedOpened,
  onCancelModalOrderExpired,
  onCancelModalOrderReleased,
  onCloseModalOrderLocked,
  onCloseModalOrderUnlocked,
  onRenew,
  onUnlock,
  onUpdate,
  isRFQ,
  unlockEnabled,
}: OrderBlockedModalsProps) => {
  const label = isRFQ ? 'RFQ' : 'Order'

  return (
    <>
      <Modal
        title={`${label} Blocked`}
        open={modalOrderLockedOpened}
        onOk={onCloseModalOrderLocked}
        onCancel={onCloseModalOrderLocked}
        footer={[
          unlockEnabled && (
            <Tooltip title="This will make the person currently editing lose all unsaved changes and get locked from the order instead">
              <Button key="unlock" onClick={onUnlock}>
                Unlock
              </Button>
            </Tooltip>
          ),
          <Button key="ok" type="primary" onClick={onCloseModalOrderLocked}>
            OK
          </Button>,
        ]}
        width={500}
      >
        <Typography.Paragraph>
          This {label} is being edited by <Typography.Text strong>{externalUser?.name}</Typography.Text>. For urgent
          access, please contact the user directly.
        </Typography.Paragraph>
      </Modal>

      <Modal
        title={`${label} Released`}
        open={modalOrderReleasedOpened}
        onOk={onUpdate}
        onCancel={onCancelModalOrderReleased}
        cancelText="Leave"
        width={500}
      >
        <Typography.Paragraph>The {label} is now released for editing, do you want to access it?</Typography.Paragraph>
      </Modal>

      <OrderBlockedModalExpired
        label={label}
        onCancelModalOrderExpired={onCancelModalOrderExpired}
        modalOrderExpiredOpened={modalOrderExpiredOpened}
        onRenew={onRenew}
      />

      <OrderBlockedModalUnlocked
        label={label}
        modalOrderUnlockedOpened={modalOrderUnlockedOpened}
        onCloseModalOrderUnlocked={onCloseModalOrderUnlocked}
        onUnlock={onUnlock}
      />
    </>
  )
}
