import React from 'react'

import styled from '@emotion/styled'

import { CloudUploadOutlined } from '@ant-design/icons'
import { InputNumber, Select, Space, Table as AntdTable, Tooltip, Typography } from 'antd'
import { TableProps as AntdTableProps } from 'antd/lib/table'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { InputCurrency } from 'common/components/InputCurrency'
import { makeCostCodeValue, Option, SelectCostCode } from 'common/components/SelectCostCode'
import { CostCode } from 'common/server/cost_codes/cost_codes'
import { CommitmentStatuses } from 'common/server/server_types'
import { Unit } from 'common/server/units'

import { Flatfile } from 'contractor/components/Flatfile'
import { MaterialsAutocomplete, MaterialsAutocompleteProps } from 'contractor/components/MaterialsAutocomplete'
import {
  makeCostCodePhaseOptionAdvanced,
  SelectCostCodePhaseAdvanced,
} from 'contractor/components/SelectCostCodePhaseAdvanced'
import { useStores } from 'contractor/hooks/use-stores'
import { Commitment, CommitmentMaterial } from 'contractor/server/commitments'
import { CostCodeSettings } from 'contractor/server/company_settings/other_settings'

import { ActionsColumn, QuantityColumn, UnitColumn, UnitCostColumn } from '../Columns'
import { Wrapper } from './wrapper'

const StyledTable = styled(AntdTable)`
  .ant-table.cell {
    background-color: white !important;
  }

  .ant-table.table {
    background-color: white !important;
  }
`

type TableProps = {
  disabled?: boolean
  hideCostCode?: boolean
  commitmentHaveOrders: boolean
  projectId: string
  commitment: Commitment
  costCodeSettings: CostCodeSettings
  materialsAutocompleteProps: MaterialsAutocompleteProps
  dataSource: CommitmentMaterial[]
  costCodes: CostCode[]
  units: Unit[]
  onChange: (value: CommitmentMaterial, changes: CommitmentMaterial) => void
  onRemove?: (material: CommitmentMaterial) => void
  handleLoadOrderMaterials: (value: string) => void
  precision: number
}

export const Table = observer<TableProps>((props) => {
  const {
    disabled,
    hideCostCode,
    costCodeSettings,
    materialsAutocompleteProps,
    dataSource,
    costCodes,
    units,
    onChange,
    onRemove,
    handleLoadOrderMaterials,
    projectId,
    commitment,
    commitmentHaveOrders,
    precision,
  } = props

  const { flatfileStore, costCodeStore } = useStores()
  const source = window.location.pathname

  return (
    <FlexBoxY bg="white" width="100%">
      <Wrapper alignItems="flex-start" justifyContent="flex-start" width="100%" overflowX="auto">
        <StyledTable
          rowKey={(record: CommitmentMaterial) => record?.id}
          pagination={false}
          tableLayout="auto"
          size="small"
          scroll={{ y: 'max-content' }}
          style={{ width: '100%', backgroundColor: 'white' }}
          data-cy="material-table"
          aria-label="order-materials-table"
          dataSource={dataSource}
          columns={[
            {
              width: 24,
              render: (_, record: CommitmentMaterial) => {
                return (
                  <Box data-cy="commitment-material-actions">
                    <ActionsColumn
                      onRemove={onRemove}
                      onChange={onChange}
                      commitmentSubmitted={commitment?.status !== CommitmentStatuses.DRAFT}
                      commitmentMaterial={record}
                      disabled={disabled}
                    />
                  </Box>
                )
              },
            },
            {
              title: 'Item',
              render: (record: CommitmentMaterial) => {
                return (
                  <FlexBoxY onClick={() => handleLoadOrderMaterials(record?.id)} alignItems="flex-start" width="100%">
                    <Typography.Text type={record.low_stock ? 'danger' : null}>
                      {record?.company_material?.description}
                    </Typography.Text>
                  </FlexBoxY>
                )
              },
            },
            {
              dataIndex: 'quantity',
              title: 'Quantity',
              width: 130,
              render: (_, record: CommitmentMaterial, index) => {
                return (
                  <FlexBoxY>
                    <QuantityColumn
                      onChange={onChange}
                      index={index}
                      commitmentMaterial={record}
                      disabled={disabled || record?.locked}
                    />
                    {record?.quantity_ordered && (
                      <>
                        <Typography.Text
                          type={record.low_stock ? 'danger' : 'secondary'}
                          style={{ fontSize: 12, width: '100%', paddingTop: 4 }}
                        >
                          Remaining: {Number(record.quantity) - Number(record.quantity_ordered)}
                        </Typography.Text>
                      </>
                    )}
                  </FlexBoxY>
                )
              },
            },
            {
              dataIndex: 'unit',
              title: 'Unit',
              width: 96,
              render: (_, record: CommitmentMaterial) => (
                <Box minWidth="80px">
                  <UnitColumn
                    onChange={onChange}
                    units={units}
                    commitmentMaterial={record}
                    disabled={commitmentHaveOrders || disabled || record?.locked}
                  />
                </Box>
              ),
            },
            {
              dataIndex: 'unit_cost',
              width: 150,
              title: 'Unit cost',
              render: (_, record: CommitmentMaterial) => {
                return (
                  <UnitCostColumn
                    onChange={onChange}
                    commitmentMaterial={record}
                    disabled={disabled || record?.locked}
                    roundingPrecision={precision}
                  />
                )
              },
            },
            ...(hideCostCode
              ? []
              : ([
                  {
                    title: 'Cost Code',
                    dataIndex: 'cost_code_id',
                    width: 216,
                    render: (_, record: CommitmentMaterial) => (
                      <Space style={{ width: '100%' }} direction="vertical">
                        <SelectCostCode
                          style={{ width: '100%' }}
                          costCodes={costCodes}
                          costCodeSettings={costCodeSettings}
                          projectId={projectId}
                          disabled={disabled || record?.locked}
                          value={makeCostCodeValue(record.cost_code, costCodeSettings)}
                          onChange={(option: Option) =>
                            onChange(record, {
                              ...record,
                              cost_code: option?.costCode,
                              cost_code_id: option?.costCode?.id,
                            })
                          }
                        />
                        {costCodeSettings?.independent_phase_codes_enabled && (
                          <SelectCostCodePhaseAdvanced
                            style={{ minWidth: 200 }}
                            value={makeCostCodePhaseOptionAdvanced(record['cost_code_phase'])}
                            onChange={(option = null) => {
                              onChange(record, {
                                ...record,
                                cost_code_phase: option?.originalObject,
                                cost_code_phase_id: option?.originalObject?.id,
                              })
                            }}
                            initialCostCodePhases={toJS(costCodeStore.costCodePhaseListStore.records)}
                            projectSpecificPhaseCodesEnabled={costCodeSettings?.project_specific_phase_codes_enabled}
                            projectId={projectId}
                            disabled={disabled || record?.locked}
                          />
                        )}
                      </Space>
                    ),
                  },
                ] as AntdTableProps<CommitmentMaterial>['columns'])),
          ]}
          summary={() => {
            return (
              <AntdTable.Summary fixed="top">
                <AntdTable.Summary.Row>
                  <AntdTable.Summary.Cell colSpan={2} index={0}>
                    <FlexBoxX onClick={() => trackEvent(Events.CLICK_COMMITMENT_UPLOAD, source)} minWidth="372px">
                      <Flatfile
                        icon={
                          <Tooltip title={'Upload materials'}>
                            <CloudUploadOutlined />
                          </Tooltip>
                        }
                        openUpload={flatfileStore.openUploadCommitment}
                        buttonProps={{ type: 'default', style: { marginRight: 8 } }}
                      />
                      <MaterialsAutocomplete {...materialsAutocompleteProps} />
                    </FlexBoxX>
                  </AntdTable.Summary.Cell>
                  <AntdTable.Summary.Cell index={2}>
                    <InputNumber
                      data-cy="quantity-input"
                      style={{ width: '100%', minWidth: 70 }}
                      inputMode="numeric"
                      className="input-quantity"
                      disabled
                    />
                  </AntdTable.Summary.Cell>
                  <AntdTable.Summary.Cell index={1}>
                    <Box minWidth="80px">
                      <Select disabled style={{ width: '100%' }} />
                    </Box>
                  </AntdTable.Summary.Cell>

                  <AntdTable.Summary.Cell index={3}>
                    <InputCurrency disabled />
                  </AntdTable.Summary.Cell>
                  {!hideCostCode && (
                    <AntdTable.Summary.Cell index={4}>
                      <SelectCostCode
                        costCodes={costCodes}
                        costCodeSettings={costCodeSettings}
                        disabled
                        placeholder=""
                        style={{ minWidth: 200 }}
                      />
                    </AntdTable.Summary.Cell>
                  )}
                </AntdTable.Summary.Row>
              </AntdTable.Summary>
            )
          }}
        />
      </Wrapper>
    </FlexBoxY>
  )
})
