import React from 'react'

import styled from '@emotion/styled'

import { EditOutlined } from '@ant-design/icons'
import { Button, Tooltip, Typography } from 'antd'

import { Box } from 'common/components/boxes'

type SelectorOptionProps = {
  disabled: boolean
  title: string
  label: string
  onEditAddressTo?: () => void
}
const StyledOptionContent = styled(Box)`
  .ant-select-item {
    min-height: s34px;
  }
  .ant-btn {
    visibility: hidden;
  }
  &:hover .ant-btn {
    visibility: visible;
  }
`

export const SelectorOption = (props: SelectorOptionProps) => {
  const { onEditAddressTo, disabled, title, label } = props

  return (
    <Tooltip title={title} placement="right">
      <StyledOptionContent display="flex" justifyContent="space-between" alignItems="center">
        <Typography.Text>{label}</Typography.Text>
        {onEditAddressTo && !disabled && (
          <Button
            size="small"
            type="text"
            icon={<EditOutlined />}
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              onEditAddressTo()
            }}
          />
        )}
      </StyledOptionContent>
    </Tooltip>
  )
}
