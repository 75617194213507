import React from 'react'

import { Alert, Button } from 'antd'

import { Box } from 'common/components/boxes'
import { InvoiceInboxFilesStates, InvoiceInboxSubStates } from 'common/server/server_types'

import { ConsolidatedInvoiceInboxes } from 'contractor/server/invoices/inbox/consolidated_invoice_inboxes'

import { FailedPdfDetails } from './failed_pdf_details'

interface Props {
  invoiceInbox: ConsolidatedInvoiceInboxes.InvoiceInbox
  onIgnore: () => Promise<void>
  onCreateInvoices: () => Promise<void>
}

export function InvoiceInboxDetailsDrawerErrorAlert(props: Props) {
  const { invoiceInbox, onIgnore, onCreateInvoices } = props

  const [loadingIgnore, setLoadingIgnore] = React.useState(false)
  const message = invoiceInbox?.fail_reason || 'Oops, something went wrong'

  if (
    invoiceInbox?.sub_state !== InvoiceInboxSubStates.FAILED ||
    !invoiceInbox?.files?.some((file) => file.state === InvoiceInboxFilesStates.FAILED)
  ) {
    return null
  }

  async function handleIgnore() {
    setLoadingIgnore(true)
    await onIgnore()
    setLoadingIgnore(false)
  }

  return (
    <Alert
      showIcon
      message={message}
      type="error"
      description={
        <Box>
          <Box mb={24}>
            <FailedPdfDetails files={invoiceInbox.files || []} />
          </Box>

          <Box display="flex" justifyContent="flex-end" gridGap={8}>
            <Button loading={loadingIgnore} onClick={handleIgnore}>
              Mark as ignored
            </Button>
            <Button onClick={onCreateInvoices}>Create invoices</Button>
          </Box>
        </Box>
      }
    />
  )
}
