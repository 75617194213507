import { formatDateStringShort } from 'common/helpers/formatters'
import { calcExtCost } from 'common/helpers/order'

import { ConsolidatedOrders } from 'contractor/server/orders'

export const foundation = {
  makeFoundationHeaderFile: (order: ConsolidatedOrders.Order) => {
    return {
      'PO#': order?.order_number,
      Vendor: order?.company_vendor_name,
      Date: formatDateStringShort(order?.ordered_at),
      'Description of PO': order?.name,
      Job: order?.project_name,
      GL: '5420',
      'Amount Pre Tax': order?.subtotal_before_tax,
      'Sales Tax': 'C',
    }
  },
  makeFoundationDetailFileItems: (order: ConsolidatedOrders.Order) => {
    return order?.order_materials?.map((orderMaterial) => {
      const extCost = calcExtCost({
        unitCost: orderMaterial?.unit_cost,
        quantity: orderMaterial?.quantity,
        multiplier: orderMaterial?.company_material?.unit?.multiplier,
        qtyIncrement: orderMaterial?.company_material?.unit?.qty_increment,
      })

      return {
        'PO#': order?.order_number,
        Job: order?.project_name,
        GL: '5420',
        Division: '1',
        'Cost Code': orderMaterial?.cost_code?.code,
        'Cost Class': orderMaterial?.cost_code?.clazz,
        'Amount Pre Tax': extCost,
      }
    })
  },
}
