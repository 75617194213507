import React from 'react'

import { Tag, Tooltip, Typography } from 'antd'

import { useTheme } from 'common/hooks/use-theme'
import { InvoiceInboxFilesStates, InvoiceInboxSubStates } from 'common/server/server_types'

type FileTagProps = {
  filename: string
  url: string
  title: string
  color: string
  state: InvoiceInboxFilesStates
  upper_state: InvoiceInboxSubStates
}

const FileType = ({ filename }: { filename: string }) => {
  const sep = filename.split('.')
  const file = sep.length > 1 ? sep[sep.length - 1]?.toUpperCase() : 'N/A'
  return (
    <Typography.Text type="secondary" style={{ marginRight: 7 }}>
      {file || 'N/A'}
    </Typography.Text>
  )
}

const defaultStyle = { margin: 0 }

export const FileTag = ({ filename, upper_state, state, url, title, color }: FileTagProps) => {
  const theme = useTheme()

  const stylesByState = {
    [`${InvoiceInboxFilesStates.FAILED}-${InvoiceInboxSubStates.FAILED}`]: {
      color: theme.colors['red-6'],
      background: theme.colors['red-1'],
      borderColor: theme.colors['red-6'],
    },
    [InvoiceInboxFilesStates.IN_REVIEW]: {
      color: theme.colors['gold-7'],
      background: theme.colors['gold-1'],
      borderColor: theme.colors['gold-3'],
    },
  }

  const combinedStateKey = `${state}-${upper_state}`
  const style = stylesByState[combinedStateKey] || stylesByState[state] || defaultStyle

  return (
    <Tag style={{ ...defaultStyle, ...style }}>
      <a href={url} target="_blank" rel="noreferrer">
        <Typography.Text
          style={
            stylesByState
              ? {
                  color: style?.color,
                }
              : undefined
          }
        >
          <Tooltip title={title} color={color} style={{ minWidth: 200 }}>
            <FileType filename={filename} />
            {filename}
          </Tooltip>
        </Typography.Text>
      </a>
    </Tag>
  )
}
