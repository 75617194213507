import React from 'react'

import { InputNumber, Typography } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'

import { Box, FlexBoxY } from 'common/components/boxes'

export type QuantityColumnProps = {
  edit?: boolean
  prevValue?: number
  required?: boolean
  remainingQuantity?: number
} & InputNumberProps

export const QuantityColumn = ({
  edit = true,
  prevValue,
  required,
  remainingQuantity,
  ...props
}: QuantityColumnProps) => {
  if (edit) {
    return (
      <Box width="100%">
        <InputNumber
          data-cy="quantity-input"
          min={required ? 1 : 0}
          max={remainingQuantity}
          style={{ width: '100%', minWidth: 70 }}
          inputMode="numeric"
          className="input-quantity"
          {...props}
        />
        {!!prevValue && <Typography.Text type="secondary">{`Prev: ${prevValue}`}</Typography.Text>}
      </Box>
    )
  }

  return (
    <FlexBoxY justifyContent="flex-start" width="100%" height="32px">
      <Typography.Text>{props?.value}</Typography.Text>
    </FlexBoxY>
  )
}
