import React, { createContext, useContext } from 'react'

import { observer } from 'mobx-react-lite'

import { PersistentFiltersProvider } from 'common/contexts/persistent_filters'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

const CostCodeContext = createContext<CostCodeContextProps>({} as CostCodeContextProps)

type CostCodeContextProps = {}

type CostCodeProviderProps = {
  children: React.ReactNode
}

export const useCostCode = () => useContext(CostCodeContext)

export const CostCodeProvider = observer(({ children }: CostCodeProviderProps) => {
  const { costCodeStore, projectStore, companySettingStore } = useStores()

  const { costCodeListStore } = costCodeStore

  useQuery(companySettingStore.indexCompanyMaterialConfiguration)
  useQuery(projectStore.maybeIndexProjects)
  useQuery(costCodeStore.getCostCodeTags)

  return (
    <CostCodeContext.Provider value={{}}>
      <PersistentFiltersProvider ignoreQueryParams={['tab']} listStore={costCodeListStore}>
        {children}
      </PersistentFiltersProvider>
    </CostCodeContext.Provider>
  )
})
