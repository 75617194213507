import React from 'react'

import { TableHeaderProps } from 'react-table'

import classNames from 'classnames'

import styled from '@emotion/styled'

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

import { FlexBoxY, Box } from 'common/components/boxes'

export type Direction = 'asc' | 'desc'

interface PsqlHeaderProps extends TableHeaderProps {
  sort?: {
    enabled?: boolean
    active?: boolean // when this column is current used for sorting
    onChange: (direction: Direction) => void
    default?: {
      direction?: Direction
      field?: string
    }
  }
}

const Th = styled('th')`
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fafafa;
  padding: 16px 8px;
  height: 50px;
  text-align: left;
`

const Wrapper = styled(FlexBoxY)<{ enabled: boolean }>`
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  cursor: ${(props) => (props.enabled ? 'pointer' : 'default')};

  &:hover {
    color: #3d3d3d;
  }

  .anticon {
    font-size: 12px;

    &:not(.active) {
      color: #bfbfbf;
    }

    &.active {
      color: inherit;
    }

    &:hover {
      color: #777777;
    }
  }
`

const defaultCalledTimes = new Map([
  ['asc', 1],
  ['desc', 2],
])

export function PsqlTableHeader(props: React.PropsWithChildren<PsqlHeaderProps>) {
  const { children, sort } = props

  const [direction, setDirection] = React.useState<Direction>(sort?.default?.direction ?? 'asc')
  const [sortedTimes, setSortedTimes] = React.useState<number>(defaultCalledTimes.get(sort?.default.direction) ?? 0)

  function handleSort(withDirection?: Direction) {
    if (!sort?.enabled) {
      return
    }

    const newDirection = withDirection ?? (direction === 'asc' ? 'desc' : 'asc')

    if (withDirection) {
      setSortedTimes(0)
    }

    if (sortedTimes === 2 && !withDirection) {
      setDirection(null)
      setSortedTimes(0)
      sort.onChange(null)

      return
    }

    setSortedTimes((current) => current + 1)
    setDirection(newDirection)
    sort.onChange(newDirection)
  }

  function handleSortIconClick(event: React.MouseEvent<HTMLElement>, direction: Direction) {
    event.stopPropagation()
    handleSort(direction)
  }

  return (
    <Tooltip align={{ offset: [0, 18, 0] }} showArrow placement="top" title={sort?.enabled && 'Sort'}>
      <Th {...props}>
        <Wrapper enabled={sort?.enabled} onClick={() => handleSort()}>
          {children}

          {sort?.enabled && (
            <Box display="flex" flexDirection="column">
              <CaretUpOutlined
                onClick={(event) => handleSortIconClick(event, 'asc')}
                className={classNames({ active: sort.active && direction === 'asc' })}
              />
              <CaretDownOutlined
                onClick={(event) => handleSortIconClick(event, 'desc')}
                className={classNames({ active: sort.active && direction === 'desc' })}
              />
            </Box>
          )}
        </Wrapper>
      </Th>
    </Tooltip>
  )
}
