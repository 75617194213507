import React from 'react'

import { Button, Popconfirm, Tooltip } from 'antd'
import { ButtonProps } from 'antd/lib/button'

import { observer } from 'mobx-react-lite'

import { Tool, trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'
import { FloatActionsWrapper } from 'common/components/FloatActionsWrapper'
import { OrderStates, OrderSubStates } from 'common/server/server_types'

import { ModalConfirmPurchase } from 'contractor/components/ModalConfirmPurchase'
import { useStores } from 'contractor/hooks/use-stores'

import { ExtraActions, ExtraActionsProps } from './extra_actions'
import { UpdateAndSaveButton } from './update_button'

type MaterialRequestPlaceOrderProps = ButtonProps

const MaterialRequestPlaceOrder = observer<MaterialRequestPlaceOrderProps>((props) => {
  const { userStore } = useStores()

  if (userStore.cannotSendAndUpdateOrders && userStore.cannotEditCostCode) {
    return
  }

  return (
    <Button data-cy="update-order" type="primary" {...props}>
      Save
    </Button>
  )
})

type OrderActionsProps = {
  actionClicked: number
  onFinish: (params: {
    stateChanges?: { cancelled_at?: Nullable<string> }
    action?: number
    silentUpdate?: boolean
    isBack?: boolean
  }) => Promise<void>
  onPlaceOrder: () => Promise<void>
  onCancelOrder: () => Promise<void>
  isFloat?: boolean
  purchaseOrderModalVisible: boolean
  togglePurchaseOrderModalVisible: (visiable: boolean) => void
  syncOrderWithIntegration: boolean
  toggleSyncOrderWithIntegration: (visiable: boolean) => void
  cancelOtherPackageOrders: boolean
  toggleCancelOtherPackageOrders: (visible: boolean) => void
  isPoLocked?: boolean
} & ExtraActionsProps

export const OrderActions = observer<OrderActionsProps>(
  ({
    onFinish,
    onPlaceOrder,
    onCancelOrder,
    syncOrderWithIntegration,
    toggleSyncOrderWithIntegration,
    purchaseOrderModalVisible,
    togglePurchaseOrderModalVisible,
    cancelOtherPackageOrders,
    toggleCancelOtherPackageOrders,
    actionClicked,
    isFloat,
    onRequestCancellation,
    isPoLocked,
  }) => {
    const { orderStore, userStore } = useStores()

    const { state, sub_state } = orderStore.selectedOrder

    if (userStore.cannotSendAndUpdateOrders && userStore.cannotEditCostCode) {
      return null
    }

    if (userStore.isMaterialRequester) {
      return isFloat ? (
        <FloatActionsWrapper>
          <MaterialRequestPlaceOrder
            onClick={() => onFinish({ action: 2 })}
            loading={actionClicked === 3}
            disabled={isPoLocked || !orderStore.selectedOrderDirty}
          />
        </FloatActionsWrapper>
      ) : (
        <MaterialRequestPlaceOrder
          onClick={() => onFinish({ action: 3 })}
          loading={actionClicked === 3}
          disabled={isPoLocked || !orderStore.selectedOrderDirty}
        />
      )
    }

    if (state == OrderStates.QUOTED) {
      const content = (
        <>
          {userStore.canSendAndUpdateRfqs && (
            <Box mr={{ _: 4, xs: 0 }}>
              <Tooltip placement="left" title="Notify the vendor you would like to cancel the RFQ.">
                <Popconfirm
                  placement="bottom"
                  title="Are you sure to cancel this RFQ?"
                  onConfirm={onCancelOrder}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    data-cy="cancel-rfq"
                    danger
                    loading={actionClicked == 1}
                    disabled={isPoLocked || actionClicked > 0}
                  >
                    Cancel
                  </Button>
                </Popconfirm>
              </Tooltip>
            </Box>
          )}

          {userStore.canSendAndUpdateOrders && (
            <Tooltip title="Notify the vendor you would like to place the order.">
              <Button
                data-cy="place-order"
                type="primary"
                onClick={() => {
                  trackEvent(Events.CLICK_PLACE_ORDER, window.location.pathname, {}, Tool.AMPLITUDE)
                  togglePurchaseOrderModalVisible(true)
                }}
                loading={actionClicked == 2}
                disabled={isPoLocked || actionClicked > 0}
              >
                Place Order
              </Button>
            </Tooltip>
          )}

          <UpdateAndSaveButton
            orderState={state}
            onUpdateWithNotification={() => onFinish({ action: 3 })}
            onUpdateWithoutNotification={() => onFinish({ action: 3, silentUpdate: true })}
            loading={actionClicked == 3}
            disabled={
              isPoLocked || !orderStore.selectedOrderDirty || actionClicked > 0 || !userStore.canSendAndUpdateRfqs
            }
          />

          <ExtraActions onRequestCancellation={onRequestCancellation} isSubmitting={actionClicked > 0} />

          {userStore.canSendAndUpdateOrders && (
            <ModalConfirmPurchase
              open={purchaseOrderModalVisible}
              onFinish={onPlaceOrder}
              onClose={() => togglePurchaseOrderModalVisible(false)}
              onChangeSyncOrderWithIntegration={toggleSyncOrderWithIntegration}
              syncOrderWithIntegration={syncOrderWithIntegration}
              cancelOtherPackageOrders={cancelOtherPackageOrders}
              onChangeCancelOtherPackageOrders={toggleCancelOtherPackageOrders}
              isLoading={actionClicked > 0}
            />
          )}
        </>
      )

      return isFloat ? (
        <FloatActionsWrapper>{content}</FloatActionsWrapper>
      ) : (
        <Box display="flex" alignItems="center" style={{ gap: 8 }}>
          {content}
        </Box>
      )
    }

    const content = (
      <>
        {userStore.canCancelOrder(orderStore.selectedOrder) && (
          <Box mr={{ _: 4, xs: 0 }}>
            <Tooltip placement="left" title="Notify the vendor you would like to cancel the order.">
              <Popconfirm
                placement="bottom"
                title="Are you sure to cancel this order?"
                onConfirm={onCancelOrder}
                okText="Yes"
                cancelText="No"
                disabled={isPoLocked || actionClicked > 0}
              >
                <Button
                  data-cy="cancel-order"
                  danger
                  loading={actionClicked == 1}
                  disabled={isPoLocked || actionClicked > 0}
                >
                  Cancel
                </Button>
              </Popconfirm>
            </Tooltip>
          </Box>
        )}

        <UpdateAndSaveButton
          orderState={state}
          onUpdateWithNotification={() => onFinish({ action: 2 })}
          onUpdateWithoutNotification={() => onFinish({ action: 2, silentUpdate: true })}
          onReopen={() => onFinish({ stateChanges: { cancelled_at: null }, action: 1 })}
          loading={actionClicked == 2}
          showReopenButton={
            state == OrderStates.CANCELLED &&
            sub_state == OrderSubStates.CANCELLED_QUOTE_BY_BUYER &&
            userStore.canSendAndUpdateRfqs
          }
          reopenButtonDisabled={actionClicked > 0}
          disabled={
            isPoLocked || !orderStore.selectedOrderDirty || actionClicked > 0 || !userStore.canSendAndUpdateOrders
          }
        />

        <ExtraActions onRequestCancellation={onRequestCancellation} isSubmitting={actionClicked > 0} />
      </>
    )

    return isFloat ? (
      <FloatActionsWrapper>{content}</FloatActionsWrapper>
    ) : (
      <Box display="flex" alignItems="center" style={{ gap: 8 }}>
        {content}
      </Box>
    )
  },
)
