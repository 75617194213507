import React from 'react'

import { CheckCircleOutlined, ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons'

import { Box } from 'common/components/boxes'
import { RibbonFilter } from 'common/components/RibbonFilter'
import { useTheme } from 'common/hooks/use-theme'

import { AutoMatchOptions, Quote, QuoteAdditionalCharge } from 'contractor/server/quote'

import { GrandTotalFooter } from './grand_total_footer'
import { LineItemsTable } from './line_items_table'

interface ViewProps {
  quote: Quote
  quoteLineItemsFilter: string[]
  setQuoteLineItemsFilter: (filters: string[]) => void
  automatchOptions: AutoMatchOptions[]
  handleChangeOrderMaterials: (payload: AutoMatchOptions[]) => void
  removeUnquotedSuggestion: (idToRemove: string) => void
  subTotal: number
  discount: number
  handleChangeAdditionalCharges: (payload: QuoteAdditionalCharge) => void
}

export const OrderMaterialsTable = (props: ViewProps) => {
  const {
    quote,
    quoteLineItemsFilter,
    setQuoteLineItemsFilter,
    automatchOptions,
    handleChangeOrderMaterials,
    removeUnquotedSuggestion,
    subTotal,
    discount,
    handleChangeAdditionalCharges,
  } = props
  const theme = useTheme()
  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%" width="100%" mr="16">
      <Box overflow="auto" flexGrow={1}>
        <RibbonFilter
          boxProps={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            padding: '8px 0',
            backgroundColor: theme.colors['gray-2'],
          }}
          value={quoteLineItemsFilter}
          onChange={setQuoteLineItemsFilter}
          options={[
            { label: 'All', filter: 'all' },
            {
              label: 'Matches',
              filter: 'matches',
              rightIcon: <CheckCircleOutlined style={{ color: theme.colors.success }} />,
            },
            {
              label: 'Partial Matches',
              filter: 'partial-matches',
              rightIcon: <WarningOutlined style={{ color: theme.colors.warning }} />,
            },
            {
              label: 'No Matches',
              filter: 'no-matches',
              rightIcon: <ExclamationCircleOutlined style={{ color: theme.colors.error }} />,
            },
          ]}
        />
        <LineItemsTable
          autoMatchOptions={automatchOptions}
          onChange={handleChangeOrderMaterials}
          removeUnquotedSuggestion={removeUnquotedSuggestion}
        />
      </Box>
      <Box width="100%" marginTop="16">
        <GrandTotalFooter
          onChange={handleChangeAdditionalCharges}
          subTotal={subTotal}
          discount={Number(discount)}
          quote={quote}
        />
      </Box>
    </Box>
  )
}
