import axios from 'axios'

import { IndexNotificationResponse, NotificationData, ServerNotificationGroup } from 'common/server/user'

export function dismiss_notification_group(notification_id: string) {
  return axios.delete(`/user/notification_group/${notification_id}`)
}

export function dismiss_all() {
  return axios.post('/user/notification_group/dismiss_all')
}

export function indexNotificationMessages(groupId: string) {
  return axios.post<NotificationData[]>(`/user/notification_messages`, { group_id: groupId })
}

export function loadNotifications(page: number, unread: boolean) {
  return axios.post<ServerNotificationGroup[]>(`/user/load_notifications`, { page, unread })
}

export function indexNotifications() {
  return axios.get<IndexNotificationResponse>(`/user/notifications`)
}

export function dismiss_notification(notification_id: string) {
  return axios.delete(`/user/notification/${notification_id}`)
}
