import React from 'react'

import { ContractorIcon } from 'common/components/ContractorIcon'
import { SubBaseWhiteIcon } from 'common/components/SubBaseIcon'

export const Icon = ({ author }) => {
  if (author?.email?.includes('subbase.io') || !author) {
    return <SubBaseWhiteIcon />
  }

  return <ContractorIcon />
}
