import React from 'react'

import { Box, BoxProps } from 'common/components/boxes'

type DrawerFooter = BoxProps

export const DrawerFooter = (props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={16}
      borderTopWidth="1px"
      borderTopStyle="solid"
      borderTopColor="gray-4"
      {...props}
    />
  )
}
