import { BindAll } from 'lodash-decorators'

import { observable } from 'mobx'

import { environments, Environment } from 'contractor/server/environments'

@BindAll()
export default class EnvironmentsStore {
  environments = observable.array<Environment>([], { deep: true })

  async getEnvironments() {
    const { data } = await environments()

    this.environments.replace(data?.environments || [])
  }
}
