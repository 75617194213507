import { renderHook } from '@testing-library/react-hooks'

export type WrapperProps = {
  children: React.ReactElement
}

const wrapper = ({ children }: WrapperProps) => children

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderHooksProvider = <T = any>(children: () => T) => renderHook(children, { wrapper })
