import React, { useRef } from 'react'

import { DrawerRef } from 'common/components/Drawer'
import { DescriptionViewerCell } from 'common/components/OrderMaterialsSpreadsheet'

import { useStores } from 'contractor/hooks/use-stores'
import { UpdateMaterial } from 'contractor/pages/CompanyMaterials/Materials/UpdateMaterial'

export const CustomDescriptionViewerCell = (props) => {
  const { companyMaterialStore, userStore } = useStores()

  const drawerUpdateRef = useRef<DrawerRef>()

  const handleLoadMaterial = (companyMaterialId: string) => {
    return companyMaterialStore.showMaterialDetail(companyMaterialId)
  }

  const handleSelectMaterial = (companyMaterial) => {
    companyMaterialStore.selectedCompanyMaterial = companyMaterial
    drawerUpdateRef.current.show()
  }

  return (
    <>
      <DescriptionViewerCell
        {...props}
        showMaterialDetails={userStore.canUseHistoricalCost}
        onLoadMaterial={handleLoadMaterial}
        onSelectMaterial={handleSelectMaterial}
      />
      <UpdateMaterial editable={false} onCancel={() => drawerUpdateRef.current.close()} ref={drawerUpdateRef} />
    </>
  )
}
