import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import ListBaseStore from 'common/stores/ListBaseStore'

interface PersistentFiltersContextProps {
  handleChangeSort: (props?: HandleChangeSortProps) => void
  handleChangeFilters: (filters: { [key: string]: string | Array<unknown> | boolean }, shouldFetch?: boolean) => void
  handleClearFilters: (filtersToKeep?: string[]) => void
  init: (urlSearchParams?: URLSearchParams) => Promise<unknown[]>
  appliedFiltersCount: number
}

interface PersistentFiltersProviderProps {
  children: React.ReactNode
  ignoreQueryParams?: Array<string>
  ignoreOnFilterCounter?: Array<string>

  // @todo: replace listStore props for individual methods and values (e.g. "filters")
  // it's necessary because Mobx sometimes doesn't replace the object reference, so the
  // component doesn't re-render properly.
  listStore: ListBaseStore<unknown>
  filters?: { [key: string]: string | Array<unknown> | boolean }
}

interface HandleChangeSortProps {
  field: string
  direction: 'asc' | 'desc'
}

export const PersistentFiltersContext = React.createContext<PersistentFiltersContextProps | null>(null)

function mergeSearchParams(params1, params2) {
  const mergedParams = new URLSearchParams(params1)

  for (const [key, value] of params2) {
    // If the key already exists, append the new value
    if (mergedParams.has(key)) {
      const existingValue = mergedParams.get(key)
      mergedParams.set(key, [existingValue, value].join(','))
    } else {
      // If the key doesn't exist, add it
      mergedParams.append(key, value)
    }
  }

  return mergedParams
}

export function PersistentFiltersProvider(props: PersistentFiltersProviderProps) {
  const { children, listStore, ignoreQueryParams = [], ignoreOnFilterCounter = [], filters } = props

  const location = useLocation()
  const history = useHistory()

  const init = (urlSearchParams?: URLSearchParams) => {
    const searchParams = new URLSearchParams(location.search)
    const mergedParams = urlSearchParams ? mergeSearchParams(searchParams, urlSearchParams) : searchParams

    const sortParams: { sort?: string; sort_direction?: string } = {}
    let search = null

    for (const key of mergedParams.keys()) {
      if (ignoreQueryParams.includes(key)) {
        continue
      }

      const entry = mergedParams.get(key)

      if (entry === '[]' || entry.length === 0) {
        continue
      }

      if (['sort', 'sort_direction'].includes(key)) {
        sortParams[key] = entry

        continue
      }

      if (key === 'search') {
        search = entry
        continue
      }

      if (entry === 'true' || entry === 'false') {
        listStore.setFilter(key, entry === 'true', true, true)
        continue
      }

      let parsedValue

      // Try to parse JSON if it's a valid JSON array or object
      try {
        parsedValue = JSON.parse(entry)
      } catch {
        parsedValue = entry // Fallback to raw string
      }

      listStore.setFilter(key, parsedValue, true, true)
    }

    listStore.searchState.search = search
    listStore.searchState.sort = sortParams.sort
    listStore.searchState.sort_direction = sortParams.sort_direction as 'asc' | 'desc'

    return listStore.fetchRecords()
  }

  const appliedFiltersCount = React.useMemo<number>(() => {
    const filtersObj = filters || listStore.searchState.filters

    return Object.keys(filtersObj).filter((filter) => !ignoreOnFilterCounter.includes(filter)).length
  }, [listStore.searchState.filters, ignoreOnFilterCounter, filters])

  function handleChangeFilters(filters = {}, shouldFetch = true) {
    const searchParams = new URLSearchParams(location.search)

    Object.keys(filters).forEach((key) => {
      const value = filters[key]

      if ((!!value && value.length > 0 && value[0] !== '') || typeof value === 'boolean') {
        if (Array.isArray(value) || typeof value === 'object') {
          searchParams.set(key, JSON.stringify(value))
        } else {
          searchParams.set(key, value) // Directly set primitive values
        }
      } else {
        searchParams.delete(key)
      }

      if (key === 'search') {
        listStore.setSearch(value)
        return
      }

      listStore.setFilter(key, value, true, true)
    })

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    })

    if (shouldFetch) {
      listStore.fetchRecords()
    }
  }

  function handleChangeSort(props?: HandleChangeSortProps) {
    const { field, direction } = props || { field: '', direction: '' }

    const searchParams = new URLSearchParams(location.search)

    if (!!direction && !!field) {
      searchParams.set('sort', field)
      searchParams.set('sort_direction', direction)
    } else {
      searchParams.delete('sort')
      searchParams.delete('sort_direction')
    }

    if (!field || field.length === 0 || !direction || direction.length === 0) {
      listStore.searchState.sort = undefined
      listStore.searchState.sort_direction = undefined
    } else {
      listStore.searchState.sort = field
      listStore.searchState.sort_direction = direction
    }

    // It'll improve UX avoiding to re-fetch everything again
    listStore.searchState.page = 1
    listStore.searchState.per_page = listStore.records.length

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    })

    listStore.fetchRecords()
  }

  function handleClearFilters(filtersToKeep = []) {
    const searchParams = new URLSearchParams(location.search)
    const currentTab = searchParams.get('tab')
    const currentProjectId = searchParams.get('project_id')
    const currentView = searchParams.get('view')

    listStore.clearFilters(true)

    const search = new URLSearchParams()

    if (!!currentTab && currentTab !== 'null') {
      search.set('tab', currentTab)
    }

    if (!!currentView && currentView !== 'null') {
      search.set('view', currentView)
    }

    if (!!currentProjectId && currentProjectId !== 'null') {
      listStore.setFilter('project_id', currentProjectId, true, true)
      search.set('project_id', currentProjectId)
    }

    filtersToKeep
      ?.map((filterKey) => ({ filterKey, paramValue: searchParams.get(filterKey) }))
      .forEach(({ filterKey, paramValue }) => {
        listStore.setFilter(filterKey, paramValue, true, true)
        search.set(filterKey, paramValue)
      })

    history.replace({
      pathname: location.pathname,
      search: search.toString(),
    })

    listStore.fetchRecords()
  }

  return (
    <PersistentFiltersContext.Provider
      value={{ handleChangeSort, init, handleChangeFilters, handleClearFilters, appliedFiltersCount }}
    >
      {children}
    </PersistentFiltersContext.Provider>
  )
}

export function usePersistentFilters(): PersistentFiltersContextProps {
  return React.useContext(PersistentFiltersContext)
}
