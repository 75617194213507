/** @jsx jsx */

import * as React from 'react'

import { Column, Row } from 'react-table'

import _ from 'lodash'

import { jsx } from '@emotion/core'

import { InputNumber } from 'antd'

import { observer } from 'mobx-react-lite'

import { useTheme } from 'common/hooks/use-theme'

interface EditableCellProps {
  value: number
  row: Row
  column: Column & {
    onBlurChange: (index: number, id: string, value: number) => void
    border?: boolean
  }
  rowDisabled?: boolean
}

const EditableCell: React.FC<EditableCellProps> = observer(({ value, row, column, rowDisabled }) => {
  const theme = useTheme()

  const [newValue, setNewValue] = React.useState<number>(value)
  // Update our new value if the value prop gets updated
  React.useEffect(() => {
    setNewValue(value)
  }, [value])

  if (rowDisabled) {
    return <span css={{ marginLeft: '15px' }}>{value}</span>
  }

  const onChangeValue = (value): void => {
    setNewValue(value)
  }
  const onBlur = () => {
    if (newValue !== value) {
      column.onBlurChange && column.onBlurChange(row.index, column.id, newValue)
    }
  }

  // Only show the placeholder if the row is null
  const placeholder = row.original['id'] === null ? column['placeholder'] : undefined

  const inputStyle = {
    width: '90%',
    marginLeft: '3px',
  }
  if (column.border !== true) {
    inputStyle['border'] = 'none'
    inputStyle['boxShadow'] = 'none'
    inputStyle['outline'] = 'none'
    inputStyle['&:hover'] = {
      outline: 'none',
      boxShadow: `0 0 0 2px ${theme.colors['blue-7']}`,
    }
    inputStyle['&:focus-within'] = {
      boxShadow: `0 0 0 2px ${theme.colors['gray-7']}`,
    }
  }
  if (newValue && !_.isNumber(newValue)) {
    // TODO: Fix weirdness in red remaining while the invalid characters go away
    inputStyle['boxShadow'] = `0 0 0 2px ${theme.colors.error}`
  }

  return (
    <InputNumber
      // style={inputStyle}
      css={inputStyle}
      value={newValue && (_.isNumber(newValue) ? newValue : parseFloat(newValue))}
      placeholder={placeholder}
      onChange={onChangeValue}
      onBlur={onBlur}
      formatter={column['formatter']}
      parser={column['parser']}
      step={column['step']}
    />
  )
})

export default EditableCell
