import React from 'react'

import { Table } from 'antd'

import { observer } from 'mobx-react-lite'

import { makeOption, ValueType } from 'common/components/DebounceSelect'
import { MappingDirections } from 'common/server/server_types'

import { SelectAgaveMaterials } from 'contractor/components/SelectAgaveMaterials'
import {
  hasSuggestions,
  maybeAddApproveRejectColumn,
} from 'contractor/components/SelectExternalMappings/select_external_mappings'
import { SelectIntegrationEntity } from 'contractor/components/SelectIntegrationEntity/select_integration_entity'
import { useStores } from 'contractor/hooks/use-stores'
import RelationshipsTable from 'contractor/pages/Integrations/Mappings/Relationships/relationships_table'
import { SuggestionAlert } from 'contractor/pages/Integrations/Mappings/suggestion_alert'
import { useUpdateMappingsHook } from 'contractor/pages/Integrations/Mappings/use_update_mappings_hook'
import { CompanyMaterial, ListParams } from 'contractor/server/integrations'

interface CompanyMaterialsTableProps {
  filter?: ListParams
  setFilter?: React.Dispatch<React.SetStateAction<ListParams>>
  isLoading: boolean
}

const CompanyMaterialsTable = observer<CompanyMaterialsTableProps>(({ isLoading, filter, setFilter }) => {
  const { integrationStore, userStore } = useStores()

  const reloadCallback = (fetchRelationships = true) => {
    if (fetchRelationships) integrationStore.getCompanyMaterialsRelationships(filter)
    integrationStore.getCompanyMaterials(filter)
    integrationStore.getCompanyMaterialsCount({ search: filter?.search })
  }

  const { handleUpdate, handleDirectUpdate, handleSuggestionsRejection, handleSuggestionsApproval, updating } =
    useUpdateMappingsHook({
      update: integrationStore.updateCompanyMaterial,
      reloadCallback,
    })

  if (integrationStore.mappingDirection === MappingDirections.INSIDE_OUT) {
    const columns = [
      {
        title: 'SubBase Description',
        dataIndex: 'description',
        width: '23%',
      },
      {
        title: 'SubBase Manufacturer',
        dataIndex: 'manufacturer',
        width: '10%',
      },
      {
        title: 'SubBase Material',
        dataIndex: 'material',
        width: '10%',
      },
      {
        title: 'SubBase Prod. ID.',
        dataIndex: 'product_identifier',
        width: '10%',
      },
      {
        title: 'SubBase Group',
        dataIndex: 'group',
        width: '10%',
      },
      {
        title: `${integrationStore.title()} Materials`,
        width: '40%',
        dataIndex: 'external_relationships',
        render: (_, row) => {
          const selected = []
          const suggestions = hasSuggestions(row)
          if (suggestions) {
            selected.push(...row.mapping_suggestions.map((item) => makeOption(item)))
          } else {
            selected.push(...row.external_relationships.map((item) => makeOption(item)))
          }
          return (
            <SelectAgaveMaterials
              selected={selected}
              loading={updating}
              showArrow={true}
              onChange={(value) => handleUpdate({ option: value, entity: row })}
              status={suggestions ? 'warning' : undefined}
            />
          )
        },
      },
    ]
    maybeAddApproveRejectColumn(
      'costCodeNumbers',
      integrationStore.companyMaterials?.data,
      integrationStore.showCompanyMaterialsSuggestions,
      columns,
      handleSuggestionsApproval,
      handleSuggestionsRejection,
    )
    return (
      <>
        <SuggestionAlert showSuggestions={integrationStore.showCompanyVendorsSuggestions()} />
        <Table<CompanyMaterial>
          pagination={{
            total: integrationStore.companyMaterials?.totalCount,
            current: integrationStore.companyMaterials?.currentPage,
            pageSize: integrationStore.companyMaterials?.pageItems,
            onChange: (page) => {
              setFilter((prev) => ({ ...prev, page }))
            },
            showSizeChanger: false,
          }}
          loading={isLoading}
          rowKey="id"
          style={{ width: '100%' }}
          columns={columns}
          dataSource={integrationStore.companyMaterials?.data}
        />
      </>
    )
  }

  const disabledOption = (item: CompanyMaterial) =>
    integrationStore.enabledMaterialsMultiMapping() ? false : item.external_relationships.length > 0

  const makeInternalOption = (item: CompanyMaterial) =>
    ({
      value: item.id,
      label: item.description,
      disabled: disabledOption(item),
    } as ValueType)

  return (
    <RelationshipsTable
      isLoading={isLoading}
      relationships={integrationStore.companyMaterialsRelationships}
      showSuggestions={integrationStore.showCompanyMaterialsSuggestions()}
      handleSuggestionsApproval={handleSuggestionsApproval}
      handleSuggestionsRejection={handleSuggestionsRejection}
      filter={filter}
      setFilter={setFilter}
      renderRowTitle="Materials"
      renderRow={(row) => (
        <SelectIntegrationEntity
          loading={updating}
          row={row}
          optionApi={integrationStore.getCompanyMaterials}
          optionData={integrationStore.companyMaterials}
          makeInternalOption={makeInternalOption}
          disabled={!userStore.canSyncWithErp}
          handleUpdate={handleDirectUpdate}
          status={row.mapping_suggestions && row.mapping_suggestions.length > 0 ? 'warning' : undefined}
        />
      )}
    />
  )
})

export default CompanyMaterialsTable
