import { LockOrderOptionType } from 'common/server/server_types'

export const mockedCompanySettingsStoreOtherSettings = {
  lock_order_settings: {
    lock_order_rule: LockOrderOptionType.NEVER,
  },
}

export const mockedCompanySettingsStoreCanUnlockOrder = true

export const mockedCompanySettingsStore = {
  otherSettings: mockedCompanySettingsStoreOtherSettings,
}
