import React, { useState, useEffect, useCallback } from 'react'

import styled from '@emotion/styled'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Typography, Switch, Table, Badge, Tooltip, Button, message } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Page } from 'common/components/Page'

import { useStores } from 'contractor/hooks/use-stores'

const TableStyled = styled(Table)`
  width: 100%;
  justify-content: space-between;
`

interface CostCodeItem {
  label: string
  description?: string
  disabled?: boolean
  disabledMessage?: string
  active: boolean
  key: string
}

export const CostCodes = observer(() => {
  const { companySettingStore } = useStores()

  const costCodeSettingsValue = companySettingStore.otherSettings?.cost_code_settings

  const requiredOrderFieldsValue = companySettingStore.otherSettings?.required_order_fields
  const requiredQuoteFieldsValue = companySettingStore.otherSettings?.required_quote_fields

  const makeData = useCallback(() => {
    return [
      { label: 'Class Enabled', active: costCodeSettingsValue?.class_enabled, key: 'class_enabled' },
      {
        label: 'Project Specific Cost Codes',
        active: costCodeSettingsValue?.project_filtering_enabled,
        key: 'project_filtering_enabled',
      },
      { label: 'Phase Code Enabled', active: costCodeSettingsValue?.phase_code_enabled, key: 'phase_code_enabled' },
      {
        label: 'Independent Phase Codes',
        description:
          "Allows users to select a phase independently of a cost code and class. For example, if you create a cost code in your system '101-01' with class 'A,' a user would be able to assign this cost code to any of your phase codes.",
        active: costCodeSettingsValue?.independent_phase_codes_enabled,
        disabled: requiredOrderFieldsValue?.cost_code_phase || requiredQuoteFieldsValue?.cost_code_phase,
        disabledMessage: 'To disable you need to disable the required phase code first.',
        key: 'independent_phase_codes_enabled',
      },
      {
        label: 'Project Specific Phase Codes',
        active: costCodeSettingsValue?.project_specific_phase_codes_enabled,
        key: 'project_specific_phase_codes_enabled',
        description: 'It is only applied when Independent Phase Codes are active.',
      },
    ]
  }, [costCodeSettingsValue, requiredOrderFieldsValue?.cost_code_phase, requiredQuoteFieldsValue?.cost_code_phase])

  const [costCodeSettings, setCostCodeSettings] = useState<CostCodeItem[]>(makeData())
  const [isSubmitting, setSubmitting] = useState(false)

  useEffect(() => {
    const data = makeData()
    setCostCodeSettings(data)
  }, [makeData])

  const handleToggle = (item: CostCodeItem) => {
    const newState = costCodeSettings.map((state) => {
      if (state.key === item.key) {
        return { ...state, active: !item.active }
      }
      return state
    })

    setCostCodeSettings(newState)
  }

  const handleSave = async () => {
    setSubmitting(true)
    try {
      const costCodeSettingMap = {}
      costCodeSettings.forEach((setting) => {
        costCodeSettingMap[setting.key] = setting.active
      })

      await companySettingStore.updateOtherSettings({
        ...companySettingStore.otherSettings,
        cost_code_settings: {
          required: costCodeSettingMap['required'],
          project_filtering_enabled: costCodeSettingMap['project_filtering_enabled'],
          class_enabled: costCodeSettingMap['class_enabled'],
          phase_code_enabled: costCodeSettingMap['phase_code_enabled'],
          independent_phase_codes_enabled: costCodeSettingMap['independent_phase_codes_enabled'],
          project_specific_phase_codes_enabled: costCodeSettingMap['project_specific_phase_codes_enabled'],
        },
      })
      message.success('Successfully changed cost code settings')
    } catch (error) {
      message.error(error?.response?.data?.error || 'Unable to save changes')
    } finally {
      setSubmitting(false)
      localStorage.removeItem('@subbase/review-step/table-columns')
    }
  }

  const columns: ColumnsType<CostCodeItem> = [
    {
      title: 'Feature',
      dataIndex: 'label',
      render: (label, record) => (
        <>
          <Typography.Text>{label}</Typography.Text>{' '}
          {record?.description && (
            <Badge
              count={
                <Tooltip placement="top" title={record?.description}>
                  <QuestionCircleOutlined style={{ top: '-5px', right: '-5px', fontSize: 12 }} />
                </Tooltip>
              }
            />
          )}
        </>
      ),
    },
    {
      title: 'Enabled',
      dataIndex: 'active',
      width: 83,
      render: (active, record) => (
        <>
          <Switch checked={active} disabled={record?.disabled} onChange={() => handleToggle(record)} />
          {record?.disabled && record?.disabledMessage && (
            <Badge
              count={
                <Tooltip placement="top" title={record?.disabledMessage}>
                  <QuestionCircleOutlined style={{ top: '-5px', fontSize: 12 }} />
                </Tooltip>
              }
            />
          )}
        </>
      ),
    },
  ]

  return (
    <>
      <Page.Header>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Cost Codes
          </Typography.Title>

          <Button type="primary" onClick={() => handleSave()} loading={isSubmitting}>
            Save Changes
          </Button>
        </Box>
      </Page.Header>

      <Page.Content>
        <TableStyled dataSource={costCodeSettings} columns={columns} pagination={false} />
      </Page.Content>
    </>
  )
})
