import React from 'react'

import moment from 'moment/moment'
import pluralize from 'pluralize'

import { WarningOutlined } from '@ant-design/icons'
import { Popover, Typography } from 'antd'

import { formatEnumValue } from 'common/helpers/formatters'
import { OrderMaterial } from 'common/server/orders'

import { FlexBoxX, FlexBoxY } from '../../boxes'

type DeliveryIssuesProps = {
  orderMaterial: OrderMaterial
}

export const DeliveryIssues = ({ orderMaterial }: DeliveryIssuesProps) => {
  const resolvedAt = orderMaterial.resolved_at
  return (
    <FlexBoxX style={{ minHeight: 32, marginLeft: 8 }}>
      <Popover
        title="Delivery issue"
        content={
          <FlexBoxY alignItems="start">
            <Typography.Text type="secondary">
              This material {resolvedAt ? 'had' : 'has'} the following{' '}
              {pluralize('issue', orderMaterial.delivery_issues?.length, true)}:{' '}
              {orderMaterial.delivery_issues?.map(formatEnumValue).join(', ')}.
            </Typography.Text>
            {resolvedAt && (
              <Typography.Text type="secondary">
                and was marked resolved at {moment(resolvedAt).format('YYYY-MM-DD HH:mm')}
              </Typography.Text>
            )}
          </FlexBoxY>
        }
      >
        <WarningOutlined style={{ color: 'red' }} />
      </Popover>
    </FlexBoxX>
  )
}
