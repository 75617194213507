import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { CompanyMaterials } from 'contractor/pages/Integrations/Mappings/CompanyMaterials'
import { CompanyVendors } from 'contractor/pages/Integrations/Mappings/CompanyVendors'
import { CostCodeClasses } from 'contractor/pages/Integrations/Mappings/CostCodeClasses'
import { CostCodeNumbers } from 'contractor/pages/Integrations/Mappings/CostCodeNumbers'
import { CostCodePhases } from 'contractor/pages/Integrations/Mappings/CostCodePhases'
import { Projects } from 'contractor/pages/Integrations/Mappings/Projects'
import { Settings } from 'contractor/pages/Integrations/Settings'

const insertIf = (condition, ...elements) => (condition ? elements : [])

const Integrations = observer(() => {
  const { companySettingStore, integrationStore } = useStores()

  const history = useHistory()
  const location = useLocation()

  // Do this here so by the time we open the tab it's loaded
  useQuery(companySettingStore.indexCompanyMaterialConfiguration)

  const { isLoading } = useQuery(integrationStore.accounting)

  if (isLoading) {
    return <Loading />
  }

  const activeKey = new URLSearchParams(location.search).get('tab')

  const tabItems = [
    { label: 'Integrations', key: 'settings', children: <Settings /> },
    ...insertIf(integrationStore.showProjectsMapping(), {
      label: 'Projects',
      key: 'projects',
      children: <Projects />,
    }),
    ...insertIf(integrationStore.showVendorsMapping(), {
      label: 'Vendors',
      key: 'vendors',
      children: <CompanyVendors />,
    }),
    ...insertIf(integrationStore.showMaterialsMapping(), {
      label: 'Materials',
      key: 'materials',
      children: <CompanyMaterials />,
    }),
    ...insertIf(integrationStore.showCostPhasesMapping(), {
      label: 'Cost Phases',
      key: 'cost_phases',
      children: <CostCodePhases />,
    }),
    ...insertIf(integrationStore.showCostCodesMapping(), {
      label: 'Cost Codes',
      key: 'cost_codes',
      children: <CostCodeNumbers />,
    }),
    ...insertIf(integrationStore.showCostClassesMapping(), {
      label: 'Cost Classes',
      key: 'cost_classes',
      children: <CostCodeClasses />,
    }),
  ]

  return (
    <Page>
      <Page.Tabs
        activeKey={activeKey || 'settings'}
        onChange={(key) => {
          history.push({
            search: new URLSearchParams({ tab: key }).toString(),
          })
        }}
        items={tabItems}
      />
    </Page>
  )
})

export default Integrations
