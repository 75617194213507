import React, { useMemo } from 'react'

import styled from '@emotion/styled'

import { DeleteOutlined, SwapOutlined } from '@ant-design/icons'
import { Button, Input, Typography, Checkbox, Row, Col, Popconfirm, Form } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { InputCurrency } from 'common/components/InputCurrency'
import { SelectUnit } from 'common/components/SelectUnit'
import { makeOptionFromObject } from 'common/components/SelectUnit'

import { MaterialsAutocomplete } from 'contractor/components/MaterialsAutocomplete'
import { useStores } from 'contractor/hooks/use-stores'

import { useEditPriceSheet } from '../../context'
import { getMatchConfidenceOnSelect, getMatchConfidenceOnUpdate } from './helpers'
import { MatchConfidence } from './match_confidence'

type MaterialItemProps = {
  remove: (index: number | number[]) => void
  name: number
}

const SwapOutlinedStyled = styled(SwapOutlined)`
  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    transform: rotate(90deg);
  }
`

export const MaterialItem = observer<MaterialItemProps>(({ remove, name }) => {
  const { unitsStore } = useStores()

  const { selectedMaterials, setSelectedMaterials, form } = useEditPriceSheet()

  const priceSheetMaterial = Form.useWatch(['priceSheetMaterials', name], form)

  const autocompletePlaceholder = priceSheetMaterial?.company_material
    ? priceSheetMaterial?.company_material?.description
    : 'Search material'

  const matchConfidence = useMemo(() => {
    return getMatchConfidenceOnUpdate(priceSheetMaterial, priceSheetMaterial?.company_material)
  }, [priceSheetMaterial?.id])

  const handleCreateNewMaterial = () => {
    if (!priceSheetMaterial) return

    const updatedMaterial = {
      ...priceSheetMaterial,
      match_confidence: 'match',
      company_material: {
        description: priceSheetMaterial.description,
        size: priceSheetMaterial.size,
        unit: priceSheetMaterial.unit,
      },
    }

    const currentList = form.getFieldValue('priceSheetMaterials') || []

    const updatedList = [...currentList]
    updatedList[name] = updatedMaterial

    form.setFieldsValue({ priceSheetMaterials: updatedList })
  }

  const handleSelectMaterial = (databaseMaterial) => {
    if (!priceSheetMaterial) return

    const updatedMaterial = {
      ...priceSheetMaterial,
      match_confidence: getMatchConfidenceOnSelect(priceSheetMaterial, databaseMaterial),
      company_material: {
        ...databaseMaterial,
        unit: makeOptionFromObject(databaseMaterial),
      },
    }

    const currentList = form.getFieldValue('priceSheetMaterials') || []

    const updatedList = [...currentList]
    updatedList[name] = updatedMaterial

    form.setFieldsValue({ priceSheetMaterials: updatedList })
  }

  if (!priceSheetMaterial) return null

  return (
    <Box bg="white" borderRadius={4} px={16} py={12}>
      <Row gutter={[10, 10]} align="middle">
        <Col xs={24} sm={12} md={12} lg={4} xl={3} xxl={3}>
          <Box display="flex" alignItems="center" gridGap={10} width="100%">
            <Checkbox
              style={{ minWidth: 16 }}
              checked={selectedMaterials?.includes(priceSheetMaterial?.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedMaterials((prevSelectedMaterials) => [...prevSelectedMaterials, priceSheetMaterial?.id])
                } else {
                  setSelectedMaterials((prevSelectedMaterials) =>
                    prevSelectedMaterials.filter((id) => id !== priceSheetMaterial?.id),
                  )
                }
              }}
            />
            <Box display="flex" flexDirection="column" width="100%">
              <Form.Item name={[name, 'upc']} label="UPC">
                <Input />
              </Form.Item>
            </Box>
          </Box>
        </Col>

        <Col xs={24} sm={12} md={12} lg={11} xl={4} xxl={5}>
          <Box display="flex" flexDirection="column">
            <Form.Item
              name={[name, 'description']}
              label="Price sheet material"
              rules={[{ required: true, message: 'Please input the material!' }]}
            >
              <Input />
            </Form.Item>
          </Box>
        </Col>

        <Col xs={24} sm={8} md={8} lg={3} xl={2} xxl={2}>
          <Box display="flex" flexDirection="column">
            <Form.Item name={[name, 'unit']} label="Unit">
              <SelectUnit
                allowClear
                labelInValue
                units={toJS(unitsStore.units)}
                status={matchConfidence === 'partialMatch' && 'error'}
              />
            </Form.Item>
          </Box>
        </Col>

        <Col xs={12} sm={8} md={8} lg={3} xl={2} xxl={2}>
          <Box display="flex" flexDirection="column">
            <Form.Item name={[name, 'size']} label="Size">
              <Input />
            </Form.Item>
          </Box>
        </Col>

        <Col xs={12} sm={8} md={8} lg={3} xl={2} xxl={2}>
          <Box display="flex" flexDirection="column">
            <Form.Item
              name={[name, 'price']}
              label="Price"
              rules={[{ required: true, message: 'Please input the price!' }]}
            >
              <InputCurrency />
            </Form.Item>
          </Box>
        </Col>

        <Col xs={24} xl={1}>
          <Box display="flex" justifyContent="center">
            <SwapOutlinedStyled />
          </Box>
        </Col>

        <Col xs={24} sm={11} md={12} lg={11} xl={4} xxl={4}>
          <Box display="flex" flexDirection="column" width="100%" mb={24}>
            <Typography.Text style={{ paddingBottom: 4 }}>Database material</Typography.Text>
            <MaterialsAutocomplete onSelect={handleSelectMaterial} placeholder={autocompletePlaceholder} />
          </Box>
        </Col>

        <Col xs={24} sm={5} md={5} lg={3} xl={2} xxl={2}>
          {priceSheetMaterial?.company_material ? (
            <Box display="flex" flexDirection="column">
              <Form.Item name={[name, 'company_material', 'unit']} label="Database unit">
                <SelectUnit units={toJS(unitsStore.units)} disabled />
              </Form.Item>
            </Box>
          ) : (
            <Button onClick={handleCreateNewMaterial} type="primary" style={{ width: '100%', padding: 0 }}>
              Create new
            </Button>
          )}
        </Col>

        <Col xs={24} sm={8} md={7} lg={10} xl={4} xxl={3}>
          <Box display="flex" alignItems="center" gridGap={10} width="100%">
            <MatchConfidence type={matchConfidence} />
            <Popconfirm
              placement="topRight"
              title="Are you sure to delete this item?"
              onConfirm={() => remove(name)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                size="small"
                danger
                type="text"
                shape="circle"
                style={{ minWidth: 24 }}
              />
            </Popconfirm>
          </Box>
        </Col>
      </Row>
    </Box>
  )
})
