import React, { useRef } from 'react'

import { LocalShipping, Store } from '@styled-icons/material'

import { Button, Space, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { OrderDelivery } from 'common/server/deliveries'

import VendorDeliveryInfo from 'contractor/components/Deliveries/delivery_info'
import { DeliveryDetail } from 'contractor/components/DeliveryDetail'
import { getDeliveryPickUpTitle } from 'contractor/helpers/get-delivery-pick-up-title'
import { useStores } from 'contractor/hooks/use-stores'

type DeliveryHeaderProps = {
  delivery: OrderDelivery
  index: number
  projectIds: string[]
}

export const DeliveryHeader = observer<DeliveryHeaderProps>(({ delivery, index, projectIds }) => {
  const { deliveryStore } = useStores()
  const deliveryDetailRef = useRef<DrawerRef>()

  const title = getDeliveryPickUpTitle({
    requestedDeliveredAt: delivery.requested_delivered_at,
    isPickUp: delivery.is_pick_up,
    pickUpCount: index,
    deliveryCount: index,
  })

  return (
    <>
      <Box display="inline-flex" alignItems="center" width="100%">
        <Space>
          {delivery.is_pick_up ? <Store size="20" /> : <LocalShipping size="20" />}

          <Typography.Text style={{ whiteSpace: 'nowrap' }}>{title}</Typography.Text>
        </Space>

        {!!delivery && (
          <Box
            ml={20}
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
            width="100%"
          >
            <Box display="inline-flex" alignItems="center" minWidth="130px">
              <VendorDeliveryInfo
                delivery={{
                  state: delivery.state,
                  estimated_delivered_at: delivery.estimated_delivered_at,
                  carrier: delivery.carrier,
                  actual_delivered_at: delivery.actual_delivered_at,
                  marked_delivered_at: delivery.marked_delivered_at,
                  delivery_issue_type: delivery.delivery_issue_type,
                  delivery_issues: delivery.delivery_issues,
                }}
              />
            </Box>

            <Box display="inline-flex" alignItems="flex-end">
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  deliveryDetailRef.current?.show()
                }}
              >
                {delivery.is_pick_up ? 'Pick Up Details' : 'Delivery Details'}
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      <DeliveryDetail
        ref={deliveryDetailRef}
        onClose={(e) => {
          e.stopPropagation()
          deliveryDetailRef.current?.close()
          deliveryStore.selectDelivery(null)
        }}
        orderDelivery={delivery}
        project_ids={projectIds}
        disableEditing
      />
    </>
  )
})
