import React, { createContext, useContext } from 'react'

import { Form, FormInstance } from 'antd'

import { observer } from 'mobx-react-lite'

const InvoiceSettingsContext = createContext<InvoiceSettingsContentProps>({} as InvoiceSettingsContentProps)

export const useInvoiceSettings = () => useContext(InvoiceSettingsContext)

type InvoiceSettingsContentProps = {
  form: FormInstance
}

export const InvoiceSettingsProvider = observer(({ children }) => {
  const [form] = Form.useForm()

  return <InvoiceSettingsContext.Provider value={{ form }}>{children}</InvoiceSettingsContext.Provider>
})

export const withInvoiceSettingsProvider = (Component) => (props) => {
  return (
    <InvoiceSettingsProvider>
      <Component {...props} />
    </InvoiceSettingsProvider>
  )
}
