import React from 'react'

import styled from '@emotion/styled'

import { MessageOutlined } from '@ant-design/icons'

import { Box, FlexBoxX } from 'common/components/boxes'
import { NotificationMessage } from 'common/components/Notifications/NotificationMessages/notification_card'

const Card = styled(Box)`
  position: relative;
  margin: 8px 0;
  width: 90%;
  padding: 8px 0 8px 8px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  transition: box-shadow 0.3s;
  cursor: pointer;
`

type CommentProps = {
  comment: string
  style?: React.CSSProperties
}

export const Comment = (props: CommentProps) => {
  const { comment, style } = props

  if (!comment) return null

  return (
    <Card {...style}>
      <FlexBoxX alignItems="center" justifyContent="flex-start">
        <MessageOutlined style={{ fontSize: 12, color: '#121C4E' }} />
        <NotificationMessage>{comment}</NotificationMessage>
      </FlexBoxX>
    </Card>
  )
}
