import React from 'react'

import { Preview } from './preview'

export const Dropdown = ({
  menu,
  showPreview,
  costCodeSettings,
  handleRemove,
  costCode,
  isPhaseCodeSelected,
  isCostCodeSelected,
  isClazzCodeSelected,
}) => {
  if (showPreview) {
    return (
      <div
        onMouseDown={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <Preview
          {...{
            handleRemove,
            costCodeSettings,
            isPhaseCodeSelected,
            isCostCodeSelected,
            isClazzCodeSelected,
            costCode,
          }}
        />
        {menu}
      </div>
    )
  }
  return menu
}
