import React from 'react'

import { animated, useSpring } from 'react-spring'

type StepperProps = {
  currentStep: number
  children: React.ReactNode[]
  style?: React.CSSProperties
}

export const Stepper: React.FC<StepperProps> = ({ currentStep, children, style }) => {
  const springProps = useSpring({
    transform: currentStep === 1 ? 'translateX(0%)' : `translateX(-${100 * (currentStep - 1)}%)`,
    config: { tension: 200, friction: 40 },
  })

  if (!children) return null

  return (
    <animated.div style={{ ...springProps, display: 'flex', width: '200%', ...style }}>
      {children.map((child, index) => (
        <div key={index} style={{ flexShrink: 0, width: '100%' }}>
          {child}
        </div>
      ))}
    </animated.div>
  )
}
