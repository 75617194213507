import React, { useEffect, useState, useCallback } from 'react'

import _ from 'lodash'

import { observer } from 'mobx-react-lite'

import { FlexBoxX, Box } from 'common/components/boxes'
import { Drawer } from 'common/components/Drawer'
import { QuoteExtractionStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { DrawerTitle } from 'contractor/pages/OrderDetail/QuoteReconciliation/drawer_title'
import { OrderMaterialsTable } from 'contractor/pages/OrderDetail/QuoteReconciliation/OrderMaterialsTable'
import { QuoteLineItemsViewer } from 'contractor/pages/OrderDetail/QuoteReconciliation/QuoteLineItemsViewer'
import { AutoMatchOptions, QuoteAdditionalCharge, UnmatchedMaterial } from 'contractor/server/quote'

type QuoteReconciliationDrawerProps = {
  state: QuoteExtractionStates
  closeDrawer: () => void
  showDrawer: boolean
  pdfUrl: string
}

type TableSide = 'left' | 'right'

export const QuoteReconciliationDrawer = observer((props: QuoteReconciliationDrawerProps) => {
  const { quoteStore, orderStore, unitsStore } = useStores()

  const quote = quoteStore.quote
  const quoteExtraction = orderStore?.selectedOrder?.quote_extraction

  const { state, showDrawer, closeDrawer, pdfUrl } = props
  const [automatchOptions, setAutomatchOptions] = useState(quote?.automatch_options)
  const [tableSide, setTableSide] = useState<TableSide>('left')
  const [quoteLineItemsFilter, setQuoteLineItemsFilter] = useState(['all'])

  const discount = orderStore.selectedOrder.deliveries[0].discount_value
  const subTotal = automatchOptions?.reduce((acc, item) => {
    return acc + item.ext_cost
  }, 0)

  const prepareOption = useCallback((item) => {
    let reconcileStatus = ''

    // There is a quote line item and both quantities match
    if (item.quantity == item.order_material.quantity) {
      reconcileStatus = 'matches'
      // There is a quote line item and the quantities do not match
    } else if (item.quantity) {
      reconcileStatus = 'partial-matches'
    } else {
      // There is no quote line item matched.
      reconcileStatus = 'no-matches'
    }

    const unquoted = quoteStore.quote.unquoted_materials?.includes(item?.order_material?.id)

    const unit = item.order_material?.company_material?.unit
    const unitCost = item.order_material.unit_cost
    const multiplier = (unitCost * (unit?.qty_increment || 1)) / (unit?.multiplier || 1)
    const ext_cost = item.order_material.quantity * multiplier

    return { ...item, ext_cost: ext_cost, reconcile_status: reconcileStatus, unquoted: unquoted }
  }, [])

  const removeUnquotedSuggestion = (idToRemove: string) => {
    const unquotedMaterials = quote.unquoted_materials
    _.remove(unquotedMaterials, (id) => id === idToRemove)

    quoteStore.updateUnquotedMaterials(unquotedMaterials)
    setAutomatchOptions(quote.automatch_options.map(prepareOption))
  }

  useEffect(() => {
    if (quote?.automatch_options) {
      if (quoteLineItemsFilter.includes('all')) {
        setAutomatchOptions(quote.automatch_options.map(prepareOption))
      } else {
        const allowedOptions = quote.automatch_options
          .filter((item) => quoteLineItemsFilter.includes(item.reconcile_status))
          .map(prepareOption)

        setAutomatchOptions(allowedOptions)
      }
    }
  }, [
    quote?.automatch_options,
    prepareOption,
    quoteLineItemsFilter,
    quoteStore?.quote?.unquoted_materials,
    orderStore?.selectedOrder,
  ])

  useEffect(() => {
    if (quoteExtraction?.id) {
      quoteStore.getQuote(orderStore.selectedOrder.id, quoteExtraction?.id)
    }
  }, [orderStore?.selectedOrder?.id, quoteExtraction?.id, quoteExtraction?.quote_state])

  const handleChangeSide = () => {
    if (tableSide === 'left') {
      setTableSide('right')
    } else {
      setTableSide('left')
    }
  }

  const handleChangeOrderMaterials = (payload: AutoMatchOptions[]) => {
    const newAutoMatchOptions = payload.map(prepareOption)

    const newOrderMaterials = newAutoMatchOptions.map((item) => {
      return item.order_material
    })

    orderStore.updateOrderMaterials(newOrderMaterials)
    quoteStore.updateQuoteLineItems(newAutoMatchOptions)
    setAutomatchOptions(newAutoMatchOptions)
  }

  const handleChangeAdditionalCharges = (payload: QuoteAdditionalCharge) => {
    const newPayload = {
      tax: payload.tax == 0 ? null : payload.tax,
      shipping: payload.shipping == 0 ? null : payload.shipping,
      discount: payload.discount == 0 ? null : payload.discount,
    }
    orderStore.updateFirstDeliveryAdditionalCharges(newPayload)
  }

  const handleChangeUnquotedMaterials = (payload: UnmatchedMaterial[]) => {
    quoteStore.updateUnmatchedMaterials(payload)
  }

  const handleImportQuoteLineItem = (payload: UnmatchedMaterial) => {
    const unit = unitsStore?.units?.find((unit) => unit?.name === payload?.unit_name)
    // @ts-ignore
    const multiplier = (payload.unit_price * (unit?.qty_increment || 1)) / (unit?.multiplier || 1)
    const ext_cost = payload.quantity * multiplier

    const newOrderMaterial = {
      quantity: payload.quantity,
      unit_cost: payload.unit_price,
      delivery_id: orderStore?.selectedOrder?.deliveries?.[0]?.id,
      company_material: {
        description: payload.description,
        unit_name: unit ? null : payload.unit_name,
        unit_id: unit ? unit.id : null,
        active: false,
      },
    }

    const newSuggestion = {
      order_material: newOrderMaterial,
      suggested_unit_cost: payload.unit_price,
      quantity: payload.quantity,
      unit_name: payload.unit_name,
      ext_cost: ext_cost,
    }

    const newOrderMaterials = [...orderStore.getPlainOrderMaterials(), newOrderMaterial]
    orderStore.updateOrderMaterials(newOrderMaterials)
    quoteStore.updateQuoteLineItems([...automatchOptions, newSuggestion])
  }

  if (!quote || !state) return null

  return (
    <Drawer
      title={<DrawerTitle title="RFQ Data Entry Helper" changeSide={handleChangeSide} />}
      placement="right"
      bgGray
      overflow="auto"
      onClose={closeDrawer}
      width="90%"
      open={showDrawer}
    >
      <FlexBoxX height="100%" width="100%" padding={16} style={{ gap: 16 }}>
        {tableSide === 'left' ? (
          <>
            <Box height="100%" width="55%">
              <OrderMaterialsTable
                quote={quote}
                quoteLineItemsFilter={quoteLineItemsFilter}
                setQuoteLineItemsFilter={setQuoteLineItemsFilter}
                automatchOptions={automatchOptions}
                handleChangeOrderMaterials={handleChangeOrderMaterials}
                removeUnquotedSuggestion={removeUnquotedSuggestion}
                subTotal={subTotal}
                discount={discount}
                handleChangeAdditionalCharges={handleChangeAdditionalCharges}
              />
            </Box>
            <Box height="100%" width="45%">
              <QuoteLineItemsViewer
                unmatchedMaterials={quote.unmatched_materials}
                handleChangeUnquotedMaterials={handleChangeUnquotedMaterials}
                handleImportQuoteLineItem={handleImportQuoteLineItem}
                pdfUrl={pdfUrl}
              />
            </Box>
          </>
        ) : (
          <>
            <Box height="100%" width="45%">
              <QuoteLineItemsViewer
                unmatchedMaterials={quote.unmatched_materials}
                handleChangeUnquotedMaterials={handleChangeUnquotedMaterials}
                handleImportQuoteLineItem={handleImportQuoteLineItem}
                pdfUrl={pdfUrl}
              />
            </Box>
            <Box height="100%" width="55%">
              <OrderMaterialsTable
                quote={quote}
                quoteLineItemsFilter={quoteLineItemsFilter}
                setQuoteLineItemsFilter={setQuoteLineItemsFilter}
                automatchOptions={automatchOptions}
                handleChangeOrderMaterials={handleChangeOrderMaterials}
                removeUnquotedSuggestion={removeUnquotedSuggestion}
                subTotal={subTotal}
                discount={discount}
                handleChangeAdditionalCharges={handleChangeAdditionalCharges}
              />
            </Box>
          </>
        )}
      </FlexBoxX>
    </Drawer>
  )
})
