import React from 'react'

import * as XLSX from 'xlsx'

import { Typography, Tooltip, Space, message } from 'antd'

import { BoxProps } from 'common/components/boxes'
import { noticeError } from 'common/helpers/new_relic'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'
import { IntegrationsSourceSystem } from 'common/server/server_types'

import { IntegrationIcons } from 'contractor/components/IntegrationIcons'
import { makeTCMFileOrderDetail } from 'contractor/pages/@v2/Orders/helpers/tcm'
import { ShowOrderResponse } from 'contractor/server/orders'

type TCMSyncStatusDetailedProps = {
  isSynced: boolean
  onClick?: () => Promise<void>
  canSyncWithErp: boolean
  order: ShowOrderResponse
} & BoxProps

const style = { fontSize: 14, fontWeight: 400 }

export const TCMSyncStatusDetailed = ({ isSynced, onClick, order, canSyncWithErp }: TCMSyncStatusDetailedProps) => {
  const downloadData = () => {
    try {
      const downloadItems = makeTCMFileOrderDetail(order)

      const wsFile = XLSX.utils.json_to_sheet(downloadItems)
      const wbFile = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wbFile, wsFile, 'Data')
      XLSX.writeFile(wbFile, 'TCMPOImp.csv')

      onClick()
    } catch (error) {
      noticeError(error, { entry: 'tcm-order-detail-download' })
      message.error(`Unable to download file. Please contact ${SUPPORT_EMAIL_ADDRESS} for further assistance.`)
    }
  }

  if (!canSyncWithErp) {
    return null
  }

  return (
    <Tooltip
      title={isSynced ? 'CSV downloaded, user must manually upload to TCM' : 'Click to download TCM CSV'}
      placement="bottom"
    >
      <Space
        onClick={() => {
          downloadData()
        }}
        style={{ cursor: 'pointer' }}
      >
        <IntegrationIcons type={IntegrationsSourceSystem.QBO} style={{ width: 32, height: 32 }} />
        {isSynced ? (
          <Typography.Text type="secondary" style={style}>
            Synced
          </Typography.Text>
        ) : (
          <Typography.Text type="danger" style={style}>
            Not Synced
          </Typography.Text>
        )}
      </Space>
    </Tooltip>
  )
}
