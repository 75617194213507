import React, { useCallback } from 'react'

import { Form } from 'antd'

import {
  SelectCostCodePhaseAdvanced,
  SelectCostCodePhaseAdvancedProps,
} from 'contractor/components/SelectCostCodePhaseAdvanced'

import { useForm } from './row_context'

type EditableCellSelectPhaseCodeProps = {
  inputName: string
  onSave: (value: string, shouldPropagateValue?: boolean) => void
} & SelectCostCodePhaseAdvancedProps

export const EditableCellSelectPhaseCode = ({ inputName, onSave, ...props }: EditableCellSelectPhaseCodeProps) => {
  const form = useForm()

  const save = useCallback(
    async (_, shouldPropagateValue) => {
      try {
        const values = await form.validateFields()

        onSave(values[inputName], shouldPropagateValue)
      } catch (errInfo) {
        console.log('Save failed:', errInfo)
      }
    },
    [form, onSave],
  )

  return (
    <Form.Item style={{ margin: 0 }} name={inputName}>
      <SelectCostCodePhaseAdvanced
        onClick={(e) => e.stopPropagation()}
        onBlur={save}
        onChange={save}
        style={{ width: '100%', ...props?.style }}
        autoFocus
        {...props}
      />
    </Form.Item>
  )
}
