import React from 'react'

import { Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'

import { useStores } from 'contractor/hooks/use-stores'

type MaterialHistoryItemProps = {
  lastPrice?: number | string
  averagePrice?: number | string
  lowestPrice?: number | string
  type: 'quote' | 'order'
}

export const MaterialHistoryItem = observer<MaterialHistoryItemProps>(
  ({ lastPrice = 0, averagePrice = 0, lowestPrice = 0, type }) => {
    const { userStore } = useStores()

    if (!userStore.canUseHistoricalCost) {
      return null
    }

    const title = type === 'quote' ? 'Quote' : 'Order'

    return (
      <Box
        px={16}
        py={8}
        display="flex"
        bg="white"
        justifyContent={{ _: 'start', md: 'space-between' }}
        alignItems={{ _: 'start', md: 'center' }}
        width="100%"
        flexDirection={{ _: 'column', md: 'row' }}
      >
        <Box>
          <Typography.Text>Last {title} Price: </Typography.Text>
          {currencyFormatter(lastPrice)}
        </Box>
        <Box>
          <Typography.Text>Average {title} Price: </Typography.Text>
          {currencyFormatter(averagePrice, 2)}
        </Box>
        <Box>
          <Typography.Text>Lowest {title} Price: </Typography.Text>
          {currencyFormatter(lowestPrice)}
        </Box>
      </Box>
    )
  },
)
