import React from 'react'

import moment from 'moment'

import styled from '@emotion/styled'

import { BellOutlined, CalendarOutlined } from '@ant-design/icons'
import { Typography } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'
import { Actions } from 'common/components/Notifications/NotificationMessages/actions'
import { Actor } from 'common/components/Notifications/NotificationMessages/actor'
import { Comment } from 'common/components/Notifications/NotificationMessages/comment'
import { NotificationData } from 'common/server/user'

const Card = styled(Box)`
  position: relative;
  margin: 8px 0;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  transition: box-shadow 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  }
`

export const NotificationMessage = styled(Typography.Text)`
  margin-left: 4px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
`

interface NotificationCardProps {
  notification: NotificationData
  onDismiss: (id: string) => void
  onOpen: () => void
}

export const NotificationCard: React.FC<NotificationCardProps> = ({ notification, onDismiss, onOpen }) => {
  const notificationMessage = notification.metadata['message'] || notification.metadata['content']
  const userName = notification.metadata['user_name']?.replace('[', '')?.replace(']', '')
  const entity = notification.metadata['entity']
  const vendorName = notification.metadata['vendor_name']
  const createdAt = moment(notification.created_at).format('lll')
  const comment = notification.metadata['internal_comment'] || notification.metadata['comment']

  return (
    <Card
      style={{
        padding: '8px 16px',
        backgroundColor: notification.read ? '#FAFAFA' : '#E6F7FF',
      }}
      onClick={onOpen}
    >
      <Box>
        <FlexBoxX alignItems="center" justifyContent="flex-start">
          <BellOutlined style={{ fontSize: 12, color: '#121C4E' }} />
          <NotificationMessage>{notificationMessage}</NotificationMessage>
        </FlexBoxX>
        <Comment comment={comment} />
        <Box style={{ marginTop: 6 }} display="flex" alignItems="center">
          <CalendarOutlined style={{ fontSize: 10 }} />
          <Typography.Text style={{ marginLeft: 4, fontSize: 10 }}>{createdAt}</Typography.Text>
          <Actor vendor={vendorName} userName={userName} entity={entity} />
        </Box>
      </Box>
      <Actions notificationId={notification.id} read={notification.read} onDismiss={onDismiss} />
    </Card>
  )
}
