import axios from 'axios'

export interface ProjectInvoiceAssignee {
  id: string
  name: string
}

export interface UpsertProjectInvoiceAssignee {
  id?: string
  name: string
}

export function index() {
  return axios.get<{ project_invoice_assignees: ProjectInvoiceAssignee[] }>('/project_invoice_assignees')
}

export function upsert(project_invoice_assignees: UpsertProjectInvoiceAssignee[]) {
  return axios.post('/project_invoice_assignees/bulk_upsert', { project_invoice_assignees })
}
