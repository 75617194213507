import React from 'react'

import styled from '@emotion/styled'

import { CloseOutlined, DownOutlined } from '@ant-design/icons'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import { Badge, message, Tooltip } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { ServerNotificationGroup } from 'common/server/user'

interface NoficationExtraActionsProps {
  notification: ServerNotificationGroup
  activeKeys: string | string[]
  onDismissNotificationGroup: (id: string) => Promise<void>
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 16px;
  transform: translateY(-10%);
`

export const NoficationExtraActions = (props: NoficationExtraActionsProps) => {
  const { notification, onDismissNotificationGroup, activeKeys } = props
  const [isSubmitting, setSubmitting] = React.useState(false)
  const isBadgeHighlighted = notification.unread && !activeKeys.includes(notification.id)
  const count = notification.unread ? notification.unread_count : notification.notification_ids?.length

  const handleDismiss = async (id: string) => {
    setSubmitting(true)
    try {
      await onDismissNotificationGroup(id)
    } catch {
      message.error('Unable to dismiss the notification')
    } finally {
      setSubmitting(false)
    }
  }

  if (isSubmitting) {
    return <LoadingOutlined style={{ fontSize: 16 }} spin />
  }

  return (
    <Wrapper>
      <FlexBoxX pt="12">
        <Tooltip title="Expand all unread notifications" placement="bottomRight">
          <DownOutlined
            rotate={activeKeys.includes(notification.id) ? 180 : 0}
            style={{ fontSize: 10, paddingTop: 2 }}
          />
        </Tooltip>

        <Badge
          count={count.toString().padStart(2, '0')}
          style={{
            backgroundColor: isBadgeHighlighted ? '#E6F7FF' : '#F0F0F0',
            marginLeft: 6,
            marginRight: 6,
            fontSize: 10,
            color: isBadgeHighlighted ? '#1890ff' : 'gray',
          }}
        />

        {notification.unread && (
          <Tooltip title="Dismiss all grouped notifications" placement="right">
            <div
              onClick={(event) => {
                event.stopPropagation()
                handleDismiss(notification.id)
              }}
            >
              <CloseOutlined style={{ fontSize: 10, marginRight: 8 }} />
            </div>
          </Tooltip>
        )}
      </FlexBoxX>
    </Wrapper>
  )
}
