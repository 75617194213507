import React, { useCallback, useMemo, useRef, useState } from 'react'

import debounce from 'lodash/debounce'

import { Select, Spin } from 'antd'
import type { SelectProps } from 'antd/es/select'

import { Paginated } from 'contractor/stores/IntegrationStore'

export type ValueType = {
  value: string
  label: string
  disabled: boolean
  suggestion_id?: string
}

export const makeOption = (relation, canDisableSelected = true): ValueType => {
  const hasItem = relation && relation.internal_id !== null && relation.internal_id !== undefined
  const disabled = canDisableSelected ? hasItem : false
  return {
    value: relation.external_id,
    label: relation?.external_name || 'unknown',
    disabled: disabled,
    suggestion_id: relation?.suggestion_id,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ReFetchDebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  selected?: ValueType[]
  initialOptions?: ValueType[]
  fetchOptions: (search?: string, page?: number, per_page?: number) => Promise<Paginated<unknown>>
  debounceTimeout?: number
}

export const ReFetchDebounceSelect = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
>({
  selected,
  fetchOptions,
  debounceTimeout = 800,
  notFoundContent,
  ...props
}: ReFetchDebounceSelectProps<ValueType>) => {
  const [fetching, setFetching] = useState(false)
  const [hasContentLoading, setHasContentLoading] = useState(false)
  const [searchValue, setSearchValue] = useState<string>(undefined)
  const [options, setOptions] = useState<ValueType[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)
  const fetchRef = useRef(0)

  const loadOptions = useCallback(
    async (value?: string, page = 1) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      if (page > 1) {
        setHasContentLoading(true)
      }
      if (page === 1) {
        setOptions([])
      }
      setFetching(true)
      await fetchOptions(value, page).then((result) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }
        setTotal(result?.totalCount || 0)
        const newOptions = result?.data.map((item) => makeOption(item))
        // @ts-ignore (typescript is complaining about the ValueType but dont know why)
        setOptions((prev) => [...prev, ...newOptions])
        setCurrentPage(page)
        setSearchValue(value)
        setFetching(false)
        setHasContentLoading(false)
      })
    },
    [fetchOptions],
  )

  const debounceFetcher = useMemo(() => {
    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  const isOptionDisabled = (option) => {
    if (selected.some((item) => item.value === option.value)) {
      return false
    }
    return !!option.disabled
  }

  const infiniteScroll = (e) => {
    const { target } = e
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((target as any).scrollTop + (target as any).offsetHeight === (target as any).scrollHeight) {
      if (options.length < total && !hasContentLoading) {
        loadOptions(searchValue, currentPage + 1)
      }
    }
  }

  const initializeOptions = () => {
    loadOptions(undefined, 1)
  }

  if (['multiple', 'tags'].includes(props.mode)) {
    return (
      <Select
        filterOption={false}
        showSearch
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        onFocus={initializeOptions}
        onPopupScroll={infiniteScroll}
        dropdownRender={(menu) => (
          <>
            {menu}
            {hasContentLoading ? <Spin size="small" style={{ padding: '0 12px' }} /> : null}
          </>
        )}
        value={selected}
        {...props}
        style={{ width: '100%' }}
        // I had to disable virtual scroll (unfortunately) because of this bug
        // https://github.com/ant-design/ant-design/issues/27152
        // the scroll was only showing if you clicked on the dropdown and scrolled with the mouse wheel
        // but not showing already on click. This is not good for the user experience
        // seems this was already fixed on version 5.2+ of antd
        virtual={false}
      >
        {options.map((option) => (
          <Select.Option key={option.value} value={option.value} disabled={isOptionDisabled(option)}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    )
  }

  return (
    <Select
      filterOption={false}
      showSearch
      onSearch={debounceFetcher}
      notFoundContent={fetching ? 'Loading...' : notFoundContent}
      onFocus={initializeOptions}
      onPopupScroll={infiniteScroll}
      {...props}
      options={options}
      style={{ width: '100%' }}
    />
  )
}
