import { useEffect } from 'react'

import { useStores } from 'contractor/hooks/use-stores'

import { UseOrderActionsType } from './useOrderActions'

export const useOrderEffects = (actions: UseOrderActionsType) => {
  const { orderStore } = useStores()

  // Hack to redirect to the draft page if the user access the order directly
  useEffect(() => {
    if (orderStore.selectedOrder) {
      actions.handleRedirectToDraftPage()
    }
  }, [orderStore.selectedOrder])

  useEffect(() => {
    return () => {
      orderStore.clearOrder()
    }
  }, [])
}
