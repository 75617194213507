import React from 'react'

import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import { LockOutlined } from '@ant-design/icons'
import { Button, Modal, Space, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { BoxProps, FlexBoxX } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'

export const ButtonToOrderForm = styled(Link)<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    `
      color: #00000040;
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
  `}
`

const rfqDemoLink = 'https://www.loom.com/share/59c1d94f673e4510994eca49a0d2cd5f'

const MessageContent = () => {
  const { userStore } = useStores()

  if (!userStore.canUseCreateRFQ) {
    return (
      <Typography.Text>
        Sending RFQs is part of our <Typography.Text strong>Professional Package</Typography.Text>. You can see how
        sending and leveling RFQs works in{' '}
        <Typography.Link href={rfqDemoLink} target="_blank">
          this demo
        </Typography.Link>
        . If you are interested in upgrading please reach out to your account executive.
      </Typography.Text>
    )
  }

  return <Typography.Text>You are currently not allowed by your administrator to use this feature.</Typography.Text>
}

const CreateRFQBlocked = () => {
  return (
    <Button
      type="primary"
      icon={<LockOutlined />}
      onClick={() =>
        Modal.info({
          content: <MessageContent />,
        })
      }
    >
      Create RFQ
    </Button>
  )
}

export const MaterialRequesterCreateOrder = observer<BoxProps>((props) => {
  const { companySettingStore, userStore } = useStores()

  const { otherSettings } = companySettingStore

  return (
    <FlexBoxX justifyContent="flex-end" {...props}>
      <Space>
        <Tooltip
          title={
            !otherSettings?.public_order_form_enabled
              ? 'Your company does not enabled this option, contact an admin of your company'
              : ''
          }
        >
          <span>
            <ButtonToOrderForm
              data-cy="create-order"
              className="ant-btn ant-btn-primary"
              to={`/new_order/${otherSettings?.public_order_form_url_extension}`}
              disabled={!otherSettings?.public_order_form_enabled}
            >
              Request Material
            </ButtonToOrderForm>
          </span>
        </Tooltip>
        {userStore.canSendAndUpdateRfqs && (
          <Link
            data-cy="create-rfq"
            className="ant-btn ant-btn-primary"
            role="button"
            to={'/orders/new_quote'}
            style={{ marginLeft: 16 }}
          >
            Create RFQ
          </Link>
        )}

        {userStore.canSendAndUpdateOrders && (
          <Link
            data-cy="create-order"
            className="ant-btn ant-btn-primary"
            role="button"
            to={'/orders/new_order'}
            style={{ marginLeft: 16 }}
          >
            Create Order
          </Link>
        )}
      </Space>
    </FlexBoxX>
  )
})

export const CreateOrderActions = observer<BoxProps>((props) => {
  const { projectStore, userStore } = useStores()
  const projectId = projectStore.selectedProject?.id

  if (userStore.isMaterialRequester || userStore.cannotSendAndUpdateOrders) {
    return <MaterialRequesterCreateOrder {...props} />
  }

  if (!userStore.canCreateDrafts && !userStore.canManageOrders) return null

  return (
    <Space>
      {userStore.canUseCreateRFQ ? (
        <Link
          data-cy="create-rfq"
          className="ant-btn ant-btn-primary"
          role="button"
          to={projectId ? `/orders/new_quote?project_id=${projectId}` : '/orders/new_quote'}
        >
          Create RFQ
        </Link>
      ) : (
        <CreateRFQBlocked />
      )}

      <Link
        data-cy="create-order"
        className="ant-btn ant-btn-primary"
        role="button"
        to={projectId ? `/orders/new_order?project_id=${projectId}` : '/orders/new_order'}
        style={{ marginLeft: 16 }}
      >
        Create Order
      </Link>
    </Space>
  )
})
