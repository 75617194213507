import React from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { Typography, Divider } from 'antd'

import { Box } from 'common/components/boxes'
import { CostCode } from 'common/server/cost_codes/cost_codes'

import { SelectCostCodeProps } from './select_cost_code'

type PreviewProps = {
  costCode: CostCode
  handleRemove: (phase: string) => void
  isPhaseCodeSelected: boolean
  isCostCodeSelected: boolean
  isClazzCodeSelected: boolean
} & Pick<SelectCostCodeProps, 'costCodeSettings'>

export const Preview = ({
  costCodeSettings,
  handleRemove,
  costCode,
  isPhaseCodeSelected,
  isCostCodeSelected,
  isClazzCodeSelected,
}: PreviewProps) => {
  const canShowPhaseCode =
    isPhaseCodeSelected && costCodeSettings?.phase_code_enabled && !costCodeSettings.independent_phase_codes_enabled

  const canShowClassCode = isClazzCodeSelected && costCodeSettings?.class_enabled

  return (
    <>
      <Box display="flex" flexDirection="column" px={8}>
        {canShowPhaseCode && (
          <Box display="flex" alignItems="baseline">
            <Box minWidth="32px" mr={4} textAlign="end">
              <Typography.Text type="secondary" style={{ fontSize: 10 }}>
                PHASE:
              </Typography.Text>
            </Box>
            <Box flexGrow={1} minWidth={0}>
              <Typography.Text strong ellipsis style={{ fontSize: 10, display: 'contents' }}>
                {costCode?.phase_code}
              </Typography.Text>
            </Box>
            <CloseOutlined onClick={() => handleRemove('phaseCodes')} style={{ fontSize: 10 }} />
          </Box>
        )}
        {isCostCodeSelected && (
          <Box display="flex" alignItems="baseline">
            <Box minWidth="32px" mr={4} textAlign="end">
              <Typography.Text type="secondary" style={{ fontSize: 10 }}>
                COST:
              </Typography.Text>
            </Box>
            <Box flexGrow={1} minWidth={0}>
              <Typography.Text strong ellipsis style={{ fontSize: 10, display: 'contents' }}>
                {costCode?.code}
              </Typography.Text>
            </Box>
            <CloseOutlined onClick={() => handleRemove('costCodes')} style={{ fontSize: 10 }} />
          </Box>
        )}
        {canShowClassCode && (
          <Box display="flex" alignItems="baseline">
            <Box minWidth="32px" mr={4} textAlign="end">
              <Typography.Text strong ellipsis style={{ fontSize: 10, display: 'contents' }}>
                CLASS:
              </Typography.Text>
            </Box>
            <Box flexGrow={1} minWidth={0}>
              <Typography.Text strong style={{ fontSize: 10 }}>
                {costCode?.clazz}
              </Typography.Text>
            </Box>
          </Box>
        )}
      </Box>
      <Divider style={{ margin: '8px 0' }} />
    </>
  )
}
