import styled from '@emotion/styled'

import { Box } from 'common/components/boxes'

export const Wrapper = styled(Box)`
  .Spreadsheet__active-cell--edit .ant-select {
    width: 100%;
    height: 100%;
  }
  .Spreadsheet__active-cell--edit .ant-select .ant-select-selector {
    height: 100%;
    border: none;
    margin: 0;
  }
  .Spreadsheet__active-cell--edit .ant-select .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
  }

  .Spreadsheet {
    width: 100%;
  }
  .Spreadsheet__table {
    width: 100%;
    table-layout: auto;
  }
  .Spreadsheet__header {
    top: -1px;
    color: black;
    position: sticky;
  }
  .Spreadsheet__cell {
    height: 32px;
    word-break: break-word;
    white-space: normal;
    min-width: auto;
  }
  .Spreadsheet__active-cell--edit {
    padding: 0;
  }
  .Spreadsheet__cell--readonly {
    background: #c5c1c0;
    color: black;
  }
  .Spreadsheet__active-cell.Spreadsheet__active-cell--edit {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // Highlight the row when the materials need approval
  & .requested_material {
    background-color: ${({ theme }) => theme.colors['gold-1']} !important;

    & * {
      border-color: ${({ theme }) => theme.colors['gold-3']} !important;
    }
  }
`
