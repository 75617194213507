import React from 'react'

import pluralize from 'pluralize'
import { v4 as uuidV4 } from 'uuid'

import styled from '@emotion/styled'

import { Typography, Button, Table } from 'antd'

import { Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'

type InvoiceTotalValuesProps = {
  grandTotal?: string
  totalInvoicedAmount?: number
  remaining?: number
  invoicesCount?: number
  onClickInvoice?: () => void
  alignRight?: boolean
  showRemaining?: boolean
  showTotalInvoicedAmount?: boolean
}

const TableStyled = styled(Table)`
  width: 100%;
  tbody {
    display: none;
  }
  thead {
    visibility: hidden;
  }

  .ant-table-summary tr:last-child td {
    border-bottom: 0;
  }
`

export const InvoiceTotalValues = ({
  grandTotal,
  totalInvoicedAmount,
  remaining,
  invoicesCount,
  onClickInvoice,
  alignRight,
  showRemaining = true,
  showTotalInvoicedAmount = true,
}: InvoiceTotalValuesProps) => {
  const theme = useTheme()

  const getRemainingPriceColor = () => {
    if (remaining === 0) {
      return theme.colors['gray-7']
    }

    if (remaining > 0) {
      return theme.colors.link
    }

    return theme.colors.error
  }

  return (
    <TableStyled
      pagination={false}
      size="small"
      // Columns and dataSource is fake, just to align with the materials table
      columns={[
        { title: '', dataIndex: 'name' },
        { width: 100, dataIndex: 'name' },
        { width: 100, dataIndex: 'name' },
        { width: 100, dataIndex: 'name' },
        { width: 150, dataIndex: 'name' },
      ]}
      dataSource={[{ name: 'dataIndex', key: uuidV4() }]}
      summary={() => {
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={3}>
                <Typography.Text strong>PO Grand Total</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={alignRight ? 2 : 1} align="right">
                <Typography.Text style={{ textAlign: 'left' }}>{currencyFormatter(grandTotal, 2)}</Typography.Text>
              </Table.Summary.Cell>
              {!alignRight && <Table.Summary.Cell index={2} />}
            </Table.Summary.Row>

            {showTotalInvoicedAmount && (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={3}>
                  <Typography.Text strong style={{ whiteSpace: 'pre' }}>
                    Total Invoiced
                    {onClickInvoice ? (
                      <Button type="link" onClick={onClickInvoice}>
                        ({invoicesCount} {pluralize('Invoice', invoicesCount)} )
                      </Button>
                    ) : (
                      <>
                        {' '}
                        ({invoicesCount} {pluralize('Invoice', invoicesCount)})
                      </>
                    )}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={alignRight ? 2 : 1} align="right">
                  <Typography.Text>{currencyFormatter(totalInvoicedAmount, 2)}</Typography.Text>
                </Table.Summary.Cell>
                {!alignRight && <Table.Summary.Cell index={2} />}
              </Table.Summary.Row>
            )}

            {showRemaining && (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography.Text strong style={{ whiteSpace: 'pre' }}>
                      Remaining
                    </Typography.Text>
                    {remaining > 0 && (
                      <Typography.Text strong style={{ fontSize: 12, color: theme.colors.link, whiteSpace: 'pre' }}>
                        *Outstanding balance to be invoiced
                      </Typography.Text>
                    )}
                    {remaining < 0 && (
                      <Typography.Text strong style={{ fontSize: 12, color: theme.colors.error, whiteSpace: 'pre' }}>
                        *Total invoiced exceeds PO amount
                      </Typography.Text>
                    )}
                  </Box>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={alignRight ? 2 : 1} align="right">
                  <Typography.Text style={{ color: getRemainingPriceColor() }}>
                    {currencyFormatter(remaining, 2)}
                  </Typography.Text>
                </Table.Summary.Cell>
                {!alignRight && <Table.Summary.Cell index={2} />}
              </Table.Summary.Row>
            )}
          </>
        )
      }}
    />
  )
}
