import React from 'react'

import { useHistory } from 'react-router-dom'

import hotkeys from 'hotkeys-js'

const KEYS = 'ctrl,command'

export const useMaybeOpenIntoNewTab = () => {
  const history = useHistory()

  React.useEffect(() => {
    hotkeys(KEYS, function () {
      console.log('Pressed', KEYS)
    })
    return () => {
      hotkeys.unbind(KEYS)
    }
  }, [])

  const maybeOpenIntoNewTab = (path) => {
    if (KEYS.split(',').some((key) => hotkeys.isPressed(key))) {
      window.open(path, '_blank')
    } else {
      history.push(path)
    }
  }

  return { maybeOpenIntoNewTab }
}
