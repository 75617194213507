import React, { useMemo, useRef } from 'react'

import pluralize from 'pluralize'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Space, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import {
  calculateInvoiceAmounts,
  calculateOrderTotalAmount,
  extractInvoiceOrderDiscrepancy,
} from 'common/helpers/invoice'
import { roundValue } from 'common/helpers/order'
import { useTheme } from 'common/hooks/use-theme'

import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'
import { DrawerInvoices, DrawerOrders } from './Drawers'

type TotalsProps = {
  activeStep: number
}

export const Totals = observer<TotalsProps>(({ activeStep }) => {
  const theme = useTheme()
  const { invoiceStore } = useStores()
  const {
    calculatedGrandTotal,
    allInvoicesExceptTheCurrent,
    currencyFormatter,
    calculatedSubtotal,
    shippingCostField,
    otherCostsField,
    discountAmountField,
    taxAmountField,
  } = useInvoice()
  const { invoice } = invoiceStore

  const drawerInvoicesRef = useRef<DrawerRef>()
  const drawerOrdersRef = useRef<DrawerRef>()

  const allInvoices = useMemo(
    () => [
      {
        ...invoice,
        invoice_grand_total: calculatedGrandTotal,
        shipping_cost: shippingCostField,
        other_costs: otherCostsField,
        discount_amount: discountAmountField,
        tax_amount: taxAmountField,
      },
      ...allInvoicesExceptTheCurrent,
    ],
    [invoice?.id, calculatedGrandTotal, allInvoicesExceptTheCurrent],
  )

  const totalInvoicedAmount = useMemo(() => calculateInvoiceAmounts(allInvoices, 'invoice_grand_total'), [allInvoices])

  const ordersGrandTotal = useMemo(
    () =>
      calculateOrderTotalAmount(
        invoiceStore.selectedOrders?.map((o) => o.order),
        'grand_total',
      ),
    [invoiceStore?.selectedOrders?.map((o) => o.order?.id)],
  )

  const ordersSubtotalTotal = useMemo(
    () =>
      calculateOrderTotalAmount(
        invoiceStore.selectedOrders?.map((o) => o.order),
        'sub_total',
      ),
    [invoiceStore?.selectedOrders?.map((o) => o.order?.id)],
  )

  const formattedOrdersGrandTotal = useMemo(
    () => roundValue(ordersGrandTotal, invoice?.decimal_precision),
    [ordersGrandTotal, invoice?.decimal_precision],
  )

  const formattedTotalInvoicedAmount = useMemo(
    () => roundValue(totalInvoicedAmount, invoice?.decimal_precision),
    [totalInvoicedAmount, invoice?.decimal_precision],
  )

  const remaining = formattedOrdersGrandTotal - formattedTotalInvoicedAmount

  const getRemainingProperties = () => {
    if (remaining === 0) {
      return {
        color: theme.colors['gray-7'],
        text: '',
        icon: null,
      }
    }

    if (remaining > 0) {
      return {
        color: theme.colors.link,
        text: 'Outstanding balance to be invoiced',
        icon: <ExclamationCircleOutlined style={{ color: theme.colors.link, marginLeft: 4 }} />,
      }
    }

    if (remaining < 0 && ordersSubtotalTotal == calculatedSubtotal) {
      const message = extractInvoiceOrderDiscrepancy(allInvoices, invoiceStore.selectedOrders)

      return {
        color: theme.colors.warning,
        text: message,
        icon: <ExclamationCircleOutlined style={{ color: theme.colors.warning, marginLeft: 4 }} />,
      }
    }

    return {
      color: theme.colors.error,
      text: 'Total invoiced exceeds PO amount',
      icon: <ExclamationCircleOutlined style={{ color: theme.colors.error, marginLeft: 4 }} />,
    }
  }

  const remainingProperties = useMemo(
    () => getRemainingProperties(),
    [
      remaining,
      theme.colors,
      invoiceStore?.selectedOrders?.length,
      allInvoices,
      formattedTotalInvoicedAmount,
      formattedOrdersGrandTotal,
    ],
  )

  if (activeStep !== 2) {
    return null
  }

  return (
    <Space size="large">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography.Text type="secondary">
          PO Total{' '}
          <Typography.Link onClick={() => drawerOrdersRef.current?.show()}>
            ({pluralize('Order', invoiceStore.selectedOrders?.length || 0, true)})
          </Typography.Link>
        </Typography.Text>
        <Typography.Title level={5} style={{ margin: 0 }}>
          {currencyFormatter(ordersGrandTotal)}
        </Typography.Title>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography.Text type="secondary">
          Total Invoiced{' '}
          <Typography.Link onClick={() => drawerInvoicesRef.current?.show()}>
            ({pluralize('Invoice', allInvoices?.length, true)})
          </Typography.Link>
        </Typography.Text>
        <Typography.Title level={5} style={{ margin: 0 }}>
          {currencyFormatter(totalInvoicedAmount)}
        </Typography.Title>
      </Box>

      <DrawerInvoices
        ref={drawerInvoicesRef}
        ordersGrandTotal={formattedOrdersGrandTotal}
        totalInvoicedAmount={formattedTotalInvoicedAmount}
        invoices={allInvoices}
        ordersCount={invoiceStore.selectedOrders?.length}
      />

      <DrawerOrders
        ref={drawerOrdersRef}
        ordersGrandTotal={formattedOrdersGrandTotal}
        totalInvoicedAmount={formattedTotalInvoicedAmount}
        orders={invoiceStore.selectedOrders?.map(({ order }) => order)}
        invoicesCount={allInvoices.length}
      />

      <Tooltip title={remainingProperties.text} placement="topRight">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography.Text type="secondary">Remaining {remainingProperties.icon}</Typography.Text>

          <Typography.Title level={5} style={{ margin: 0, color: remainingProperties.color }}>
            {currencyFormatter(remaining)}
          </Typography.Title>
        </Box>
      </Tooltip>
    </Space>
  )
})
