import React from 'react'

import { Button, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DateRangePicker } from 'common/components/DateRangePicker'
import { SelectFilter } from 'common/components/table/postgres_filters'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

export const Filters = observer(() => {
  const {
    priceSheetStore: { listStore },
  } = useStores()

  const persistentFilters = usePersistentFilters()

  useQuery(listStore.fetchFacets)

  const values = listStore.searchState.filters
  const facets = listStore.facets

  const optionsFactory = React.useCallback(
    (key: string) => {
      return (facets[key] || []).map((x: { [key: string]: string }) => ({
        label: x.label,
        value: x.value,
      }))
    },
    [facets],
  )

  return (
    <Box display="flex" flexDirection="column" p="12" gridGap={16} width="100%">
      <Box width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Vendor</Typography.Text>

        <SelectFilter
          items={optionsFactory('company_vendors')}
          selected={values['company_vendors']}
          onChange={(value) => persistentFilters.handleChangeFilters({ company_vendors: value })}
          attribute="company_vendors"
          placeholder="Select vendor"
          isSearchable
        />
      </Box>

      <Box width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Project</Typography.Text>

        <SelectFilter
          items={optionsFactory('projects')}
          selected={values['projects']}
          onChange={(value) => persistentFilters.handleChangeFilters({ projects: value })}
          attribute="projects"
          placeholder="Select project"
          isSearchable
        />
      </Box>

      <Box width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Created at</Typography.Text>

        <DateRangePicker
          placeholderStart="Start date"
          placeholderEnd="End date"
          attribute="created_at"
          value={{
            startDate: values['created_at_start'],
            endDate: values['created_at_end'],
          }}
          onChange={({ startDate, endDate }) => {
            persistentFilters.handleChangeFilters({
              created_at_start: startDate,
              created_at_end: endDate,
            })
          }}
        />
      </Box>

      <Box width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Start at</Typography.Text>

        <DateRangePicker
          placeholderStart="Start date"
          placeholderEnd="End date"
          attribute="start_at"
          value={{
            startDate: values['start_at_start'],
            endDate: values['start_at_end'],
          }}
          onChange={({ startDate, endDate }) => {
            persistentFilters.handleChangeFilters({
              start_at_start: startDate,
              start_at_end: endDate,
            })
          }}
        />
      </Box>

      <Box width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">End at</Typography.Text>

        <DateRangePicker
          placeholderStart="Start date"
          placeholderEnd="End date"
          attribute="end_at"
          value={{
            startDate: values['end_at_start'],
            endDate: values['end_at_end'],
          }}
          onChange={({ startDate, endDate }) => {
            persistentFilters.handleChangeFilters({
              end_at_start: startDate,
              end_at_end: endDate,
            })
          }}
        />
      </Box>

      <Button
        style={{ marginTop: 16, width: '100%' }}
        type="primary"
        data-cy="clear-filters"
        onClick={() => persistentFilters.handleClearFilters()}
        disabled={persistentFilters.appliedFiltersCount === 0}
      >
        Clear All
      </Button>
    </Box>
  )
})
