import React from 'react'

import { Typography } from 'antd'
import { LinkProps } from 'antd/lib/typography/Link'

import { OrdersList } from 'common/components/OrdersList'
import { currencyFormatter } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'

type InvoiceAmountRemainingProps = { value: string } & LinkProps

export function CellInvoiceAmountRemaining(props: InvoiceAmountRemainingProps) {
  const { value, ...rest } = props

  const theme = useTheme()

  if ([undefined, null].includes(value)) {
    return <OrdersList.Cell.NoContent />
  }

  const getRemainingStyle = (remaining) => {
    if (remaining >= 0) {
      return theme.colors['gray-7']
    }

    return theme.colors.error
  }

  return (
    <Typography.Link
      style={{ color: getRemainingStyle(value), cursor: 'pointer' }}
      onClick={(e) => e.stopPropagation()}
      {...rest}
    >
      {currencyFormatter(value, 2)}
    </Typography.Link>
  )
}
