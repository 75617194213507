export enum Events {
  CLOSE_VENDOR_BASIC_ALERT = 'close_vendor_basic_alert',
  TABLE_SORTING = 'table_sorting',
  TABLE_FILTERING = 'table_filtering',
  ADD_VENDORS = 'add_vendors',
  ADD_ADDRESS = 'add_address',
  ADD_MATERIAL = 'add_material',
  QUICK_COMMENT = 'quick_comment',
  ORDER_HISTORY = 'order_history',
  ERROR_BOUNDARY = 'error_boundary',
  ORDER_DETAIL_HOVER = 'order_detail_hover',
  DELIVERY_INFO = 'delivery_info',
  TOGGLE_SPREADSHEET = 'toggle_spreadsheet',
  DOWNLOAD_PO = 'download_po',
  HOVER_PRICING_HISTORY = 'hover_pricing_history',
  SELECT_PRICE_FROM_PRICING_HISTORY = 'select_price_from_pricing_history',
  ORDER_NOTIFICATION_CLICKED_VIEW_ORDER = 'order_notification_clicked_view_order',
  ORDER_NOTIFICATION_CLICKED_DOWNLOAD_PO = 'order_notification_clicked_download_po',

  // Commitments
  CLICK_COMMITMENTS_TAB = 'click_commitments_tab',
  CLICK_ORDERS_TAB = 'click_orders_tab',
  CLICK_INVOICES_TAB = 'click_invoices_tab',
  CLICK_COMMITMENTS_FILTER = 'click_commitments_filter',
  CLICK_COMMITMENTS_FILTER_CLEAR = 'click_commitments_filter_clear',
  CLICK_PLACE_COMMITMENT_ORDER = 'click_place_commitment_order',
  CLICK_SAVE_DRAFT = 'click_save_draft',
  CLICK_SUBMIT_COMMITMENT = 'click_submit_commitment',
  CLICK_UPDATE_COMMITMENT = 'click_update_commitment',
  CLICK_CANCEL_COMMITMENT = 'click_cancel_commitment',
  CLICK_OPEN_ATTACHMENT = 'click_open_attachment',
  CLICK_EXPIRATION_DATE = 'click_expiration_date',
  CLICK_VENDOR = 'click_vendor',
  CLICK_PROJECT = 'click_project',
  CLICK_COMMITMENT_NUMBER = 'click_commitment_number',
  CLICK_COMMITMENT_NAME = 'click_commitment_name',
  CLICK_COMMITMENT_UPLOAD = 'click_commitment_upload',
  CLICK_COMMITMENT_MATERIAL_UNLOCK = 'click_commitment_material_unlock',
  CLICK_COMMITMENT_MATERIAL_DELETE = 'click_commitment_material_delete',

  VENDOR_SAVE_OLD_ORDER_VERSION = 'vendor_save_old_order_version',

  // Order Detail
  CHANGE_ORDER_DETAIL_TAB = 'change_order_detail_tab',

  // Landing
  SELECT_ACCESS_TYPE = 'select_access_type',
  CLICK_LOGIN = 'click_login',
  CLICK_CONTACT_US = 'click_contact_us',
  CLICK_LOGIN_AS = 'click_login_as',
  CLICK_REGISTER = 'click_register',
  CLICK_LOGIN_CONTRACTOR_REGISTER = 'click_vendor_register',

  // Invoice
  CLICK_INVOICE_INBOX = 'click_invoice_inbox',
  CHANGE_INVOICE_VISUALIZATION = 'change_invoice_visualization',

  RELOAD_IDLE_USER = 'reload_idle_user',

  // New events
  CLICK_LOG_DELIVERY_HEADER = 'click_log_delivery_header',
  CLICK_DELIVERY_ORDER_DETAILS = 'click_delivery_order_details',
  CLICK_DELIVERY_CALENDAR = 'click_delivery_calendar',
  CLICK_CREATE_ORDER_HEADER = 'click_create_order_header',
  CLICK_CREATE_ORDER_ORDER_TABLE = 'click_create_order_order_table',
  CLICK_CREATE_RFQ_ORDER_TABLE = 'click_create_rfq_order_table',
  CLICK_PLACE_ORDER = 'click_place_order',
  CLICK_CONFIRM_PURCHASE_PLACE_ORDER_MODAL = 'click_confirm_purchase_place_order_modal',
  CLICK_CREATE_INVOICE_INVOICE_TABLE = 'click_create_invoice_invoice_table',
  CLICK_UPLOAD_INVOICE_INVOICE_TABLE = 'click_upload_invoice_invoice_table',
  CLICK_NOTIFICATIONS_HEADER = 'click_notifications_header',
  CLICK_CREATE_RFQ_HEADER = 'click_create_rfq_header',
  CLICK_SWITCH_TO_RFQ = 'click_switch_to_rfq',
  CLICK_SWITCH_TO_ORDER = 'click_switch_to_order',
  CLICK_UNLOCK_ORDER_SESSION = 'click_unlock_order_session',
  VIEW_ORDER_TABLE = 'view_order_table',
  VIEW_ORDER_DETAILS = 'view_order_details',
  VIEW_CALENDAR = 'view_calendar',
  VIEW_DASHBOARD = 'view_dashboard',
  VIEW_PROJECTS = 'view_projects',
  VIEW_MATERIALS = 'view_materials',
  VIEW_COMMITMENTS = 'view_commitments',
  VIEW_INVOICES = 'view_invoices',
}
