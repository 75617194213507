import React from 'react'

import styled from '@emotion/styled'

import { Table, Typography } from 'antd'

import { currencyFormatter, formatDateOrdinal } from 'common/helpers/formatters'

import { ShowOrderResponse } from 'contractor/server/orders'

import { InvoiceStatusTag } from './invoice_status_tag'

type InvoiceTableItemsProps = {
  invoices: ShowOrderResponse['invoices']
}

const TableStyled = styled(Table)`
  .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .ant-table-expanded-row {
    .ant-table.ant-table-small {
      margin: -8px -8px -8px 28px !important;
    }

    .ant-table-row:hover,
    .ant-table-cell {
      background: ${({ theme }) => theme.colors['gray-2']} !important;
      color: #00000082;
    }
  }

  .ant-table-row-expand-icon-cell {
    text-align: center;
  }
`

export const InvoiceTableItems = (props: InvoiceTableItemsProps) => {
  const { invoices: dataSource } = props

  return (
    <TableStyled
      rowKey="id"
      columns={[
        {
          title: 'Invoice Number',
          dataIndex: 'number',
          render: (number, invoice: InvoiceTableItemsProps['invoices'][0]) => {
            return <Typography.Link href={`/invoice/${invoice.id}`}>#{number}</Typography.Link>
          },
        },
        {
          title: 'Status',
          dataIndex: 'state',
          render: (state) => {
            return <InvoiceStatusTag state={state} />
          },
        },
        {
          title: 'Grand total',
          dataIndex: 'invoice_grand_total',
          render: (invoice_grand_total) => {
            return currencyFormatter(invoice_grand_total, 2)
          },
        },
        {
          title: 'Assign to',
          dataIndex: 'assigned_to',
          render: (assigned_to) => {
            return !assigned_to ? 'Not assigned' : assigned_to?.name || assigned_to?.email
          },
        },
        {
          title: 'Created at',
          dataIndex: 'created_at',
          render: (created_at) => {
            return formatDateOrdinal(created_at)
          },
        },
        {
          title: 'Invoice date',
          dataIndex: 'document_date',
          render: (document_date) => {
            return formatDateOrdinal(document_date)
          },
        },
      ]}
      dataSource={dataSource}
      pagination={false}
      size="small"
      style={{ minWidth: 850 }}
    />
  )
}
