import styled from '@emotion/styled'

import { FlexBoxY } from 'common/components/boxes'

export const Wrapper = styled(FlexBoxY)`
  border-bottom: 1px solid;
  margin-bottom: 24px;

  // Highlight the row when the materials need approval
  & .requested_material {
    background-color: ${({ theme }) => theme.colors['gold-1']} !important;

    & * {
      border-color: ${({ theme }) => theme.colors['gold-3']} !important;
    }
  }
`
