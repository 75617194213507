import React, { useEffect } from 'react'

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, Input, Row, Col, Button, Typography, Popconfirm, Tooltip, Space, Alert } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

import { useInvoiceSettings } from './context'

type ProjectAssigneesProps = {
  projectAssigneeDirty: boolean
}

export const ProjectAssignees = observer<ProjectAssigneesProps>(({ projectAssigneeDirty }) => {
  const { projectInvoiceAssigneeStore } = useStores()

  const { isLoading } = useQuery(projectInvoiceAssigneeStore.getAllProjectInvoiceAssignees)

  const { form } = useInvoiceSettings()

  useEffect(() => {
    const projectAssignees = projectInvoiceAssigneeStore.projectInvoiceAssignees?.map((projectInvoiceAssignee) => ({
      name: projectInvoiceAssignee?.name,
      id: projectInvoiceAssignee?.id,
    }))

    form.setFieldsValue({ projectAssignees })
  }, [projectInvoiceAssigneeStore.projectInvoiceAssignees?.length])

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box mb={32}>
      <Form.List name="projectAssignees">
        {(fields, { add, remove }) => (
          <>
            <Typography.Title level={5}>
              Project Assignees
              <Tooltip title="Add Project Assignee">
                <Button
                  type="primary"
                  onClick={() => add({ name: undefined, id: undefined })}
                  icon={<PlusOutlined />}
                  style={{ marginLeft: 16 }}
                  size="small"
                />
              </Tooltip>
            </Typography.Title>
            <Typography.Paragraph>
              For each project, you can specify different types of assignees (e.g. Project Executive, Project
              Accountant, etc.)
            </Typography.Paragraph>

            {projectAssigneeDirty && (
              <Alert
                type="warning"
                message="Save your changes to Project Assignees to use them below in your workflow."
                style={{ marginBottom: 16 }}
              />
            )}

            <Row gutter={20}>
              {fields.map(({ key, name }, index) => (
                <Col key={key} xs={24} sm={12} md={8} lg={6} xxl={4}>
                  <Space.Compact block>
                    <Form.Item
                      style={{ width: '100%' }}
                      name={[name, 'name']}
                      rules={[{ required: true, message: 'Input some value or remove the field.' }]}
                    >
                      <Input placeholder={`e.g. Project Assignee ${index + 1}`} />
                    </Form.Item>
                    <Form.Item name={[name, 'id']} hidden>
                      <Input />
                    </Form.Item>
                    {fields.length > 1 && (
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to delete this project assignee?"
                        onConfirm={() => remove(name)}
                      >
                        <Button danger icon={<DeleteOutlined />} style={{ minWidth: 32 }} />
                      </Popconfirm>
                    )}
                  </Space.Compact>
                </Col>
              ))}
            </Row>
          </>
        )}
      </Form.List>
    </Box>
  )
})
