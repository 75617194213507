import React from 'react'

import { DrawerTableFilters } from 'common/components/DrawerTableFilters'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { Filters } from './filters'

export const Options = () => {
  const persistentFilters = usePersistentFilters()

  return (
    <DrawerTableFilters
      title="Table Filters"
      useAlgolia={false}
      appliedFiltersCounter={persistentFilters.appliedFiltersCount}
      padding={0}
      drawerProps={{ bgGray: true }}
    >
      <Filters />
    </DrawerTableFilters>
  )
}
