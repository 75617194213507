import React from 'react'

import { SubBaseAction } from './subbase_action'

export const SubBaseReviewAction = ({ reviewDate }) => {
  return (
    <SubBaseAction>
      <strong>SubBase</strong> is reviewing the issue on {reviewDate}.
    </SubBaseAction>
  )
}
