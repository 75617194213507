import React from 'react'

import { Typography } from 'antd'

import { currencyFormatter } from 'common/helpers/formatters'

import { UnitCostColumnProps } from './unit_cost'

type ExtendedCostProps = Pick<UnitCostColumnProps, 'extCost' | 'hideDetails' | 'roundingPrecision'>

export const ExtendedCost = ({ extCost = 0, hideDetails = false, roundingPrecision = 3 }: ExtendedCostProps) => {
  return (
    <Typography.Text type="secondary" style={{ width: '100%', textAlign: 'end', marginRight: hideDetails ? 30 : 16 }}>
      {currencyFormatter(extCost, roundingPrecision)}
    </Typography.Text>
  )
}
