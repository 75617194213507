import React from 'react'

import { Tag } from 'antd'

import { useTheme } from 'common/hooks/use-theme'

const defaultStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 0,
  width: '100%',
}

export const PriceSheetStateTag = ({ stateName }) => {
  const theme = useTheme()

  const statesConfigs = {
    active: {
      label: 'Active',
      backgroundColor: theme.colors['geekblue-1'],
      borderColor: theme.colors['geekblue-3'],
    },
    expired: {
      label: 'Expired',
      backgroundColor: theme.colors['red-1'],
      borderColor: theme.colors['red-3'],
    },
    not_started: {
      label: 'Not started',
      backgroundColor: theme.colors['purple-1'],
      borderColor: theme.colors['purple-3'],
    },
    draft: {
      label: 'Draft',
      backgroundColor: theme.colors['orange-1'],
      borderColor: theme.colors['orange-3'],
    },
  }

  const stateConfig = statesConfigs[stateName]

  return (
    <Tag
      style={{
        ...defaultStyle,
        backgroundColor: stateConfig?.backgroundColor,
        borderColor: stateConfig?.borderColor,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {stateConfig?.label}
    </Tag>
  )
}
