import React, { useCallback, useEffect, useState } from 'react'

import { Select, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'
import { LockOrderOptionType } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

type LockSettingsProps = {
  onChange: (configs: {}) => void
}

interface Option {
  value: string
  label: string
  enabled: boolean
  setting: string
}

export const LockSettings = observer<LockSettingsProps>(({ onChange }) => {
  const { companySettingStore } = useStores()

  const LockOrderSettingsValue = companySettingStore.otherSettings?.lock_order_settings

  const makeData = useCallback(() => {
    return [
      {
        value: LockOrderOptionType.NEVER,
        label: 'Never',
        enabled: LockOrderSettingsValue?.lock_order_rule == LockOrderOptionType.NEVER,
        setting: 'lock_order_rule',
      },
      {
        value: LockOrderOptionType.ORDER_CONFIRMATION,
        label: 'Upon Order Confirmation',
        enabled: LockOrderSettingsValue?.lock_order_rule == LockOrderOptionType.ORDER_CONFIRMATION,
        setting: 'lock_order_rule',
      },
      {
        value: LockOrderOptionType.DELIVERY_COMPLETION,
        label: 'Upon Delivery Completion',
        enabled: LockOrderSettingsValue?.lock_order_rule == LockOrderOptionType.DELIVERY_COMPLETION,
        setting: 'lock_order_rule',
      },
      {
        value: LockOrderOptionType.ORDER_INVOICED,
        label: 'Upon Order Invoiced',
        enabled: LockOrderSettingsValue?.lock_order_rule == LockOrderOptionType.ORDER_INVOICED,
        setting: 'lock_order_rule',
      },
    ]
  }, [LockOrderSettingsValue])

  const [lockOrderSettings, setLockOrderSettings] = useState<Option[]>(makeData())

  useEffect(() => {
    const data = makeData()
    setLockOrderSettings(data)
  }, [makeData])

  const handleSelect = (value: string) => {
    const newState = lockOrderSettings.map((setting) => {
      if (setting.value === value) {
        return { ...setting, enabled: !setting.enabled }
      }
      return { ...setting, enabled: false }
    })

    const emailSettingMap = {}
    newState.forEach((setting) => {
      if (setting.enabled) {
        return (emailSettingMap[setting.setting] = setting.value)
      }
    })

    setLockOrderSettings(newState)
    onChange(emailSettingMap)
  }

  return (
    <FlexBoxY alignItems="flex-start" mt={32}>
      <Typography.Title level={5}>Lock Order</Typography.Title>
      <Typography.Text>
        To prevent retroactive changes, you can lock the order once it reaches a specific state. This will stop users
        from editing materials, pricing, etc. but does not affect the ability to comment or edit delivery information.
      </Typography.Text>
      <Select
        options={lockOrderSettings}
        defaultValue={LockOrderSettingsValue?.lock_order_rule}
        onSelect={(item) => handleSelect(item)}
        style={{ marginTop: 16, minWidth: 350 }}
      />
    </FlexBoxY>
  )
})
