import { Column, Row } from 'react-table'

export { default as CellDatePicker } from './date_picker'
export { default as CellEditableTags } from './editable_tags'
export { default as CellFormattedText } from './formatted_text'
export { default as CellInput } from './input'
export { default as CellInputValue } from './input_value'
export { default as CellRemove } from './remove'
export { default as CellTag } from './tag'
export { default as CellTextPopover } from './text_popover'
export * from './cell_order_number'
export * from './cell_order_state'
export * from './cell_delivery_info'
export * from './cell_watchers'
export * from './cell_last_action'
export * from './cell_expiration_quote'

export interface CellProps<T> {
  value: T
  row: Row
  column: Column
}
