import React, { useMemo } from 'react'

import { flatten } from 'lodash'

import { observer } from 'mobx-react-lite'

import {
  getFilteredColumns,
  OrderMaterialSpreadsheet,
  OrderMaterialSpreadsheetFooter,
} from 'common/components/OrderMaterialsSpreadsheet'
import { calcOrderTaxSplitBulk, roundValue } from 'common/helpers/order'
import { CostCode } from 'common/server/cost_codes/cost_codes'
import { OrderMaterial } from 'common/server/orders'

import {
  CustomApproveCell,
  CustomCostCodeEditorCell,
  CustomCostCodeViewerCell,
  CustomDescriptionEditorCell,
  CustomDescriptionViewerCell,
  CustomPhaseCodeEditorCell,
  CustomPhaseCodeViewerCell,
  CustomUnitsEditorCell,
} from 'contractor/components/OrderMaterials'
import { usePopulateSameCostCode, UsePopulateSameCostCodePath } from 'contractor/hooks/use-populate-same-cost-code'
import { useStores } from 'contractor/hooks/use-stores'

import { useNewQuote } from '../context'

interface MaterialsSpreadsheetProps {
  deliveryId: string
  orderType: OrderType
  index: number
  isPoLocked?: boolean
}

interface HandleMaybeApplyToAllProps {
  index: number
  value: CostCode | string
  path: string
}

export const MaterialsSpreadsheet = observer<MaterialsSpreadsheetProps>(
  ({ deliveryId, orderType, isPoLocked, index }) => {
    const { companySettingStore, orderStore, userStore, projectStore } = useStores()

    const { projectId, companyVendors, commitment, handleOrderDirty } = useNewQuote()

    const delivery = orderStore.newDeliveries.find((delivery) => delivery.id === deliveryId)
    const orderMaterials = orderStore.getOrderMaterialsByDeliveryId(deliveryId) || []

    const populateSameCostCode = usePopulateSameCostCode<OrderMaterial>({
      itemIdentifierKey: 'company_material.id',
    })

    const requiredOrderFields = companySettingStore.otherSettings?.required_order_fields
    const requiredQuoteFields = companySettingStore.otherSettings?.required_quote_fields
    const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

    const currentProjectId = projectId || projectStore?.selectedProject?.id
    const project = projectStore.projects.find((project) => project.id === currentProjectId)

    function handleApplyCostCodeToAll(args: HandleMaybeApplyToAllProps) {
      const { index, value, path } = args

      const newMaterials = populateSameCostCode.handleBulkChange({
        triggerIndex: index,
        path: path as UsePopulateSameCostCodePath,
        value,
        datasource: orderStore.getOrderMaterialsByDeliveryId(deliveryId),
      })

      orderStore.updateOrderMaterialsByDeliveryId(deliveryId, newMaterials)
    }

    // Get all permited columns
    const columns = useMemo(() => {
      return getFilteredColumns(companySettingStore.companyAttributes).map((column) => {
        switch (column.id) {
          case 'approve':
            return {
              ...column,
              DataViewer: CustomApproveCell,
            }
          case 'unit':
            return {
              ...column,
              readOnly: !userStore.canCreateNewMaterial,
              DataEditor: CustomUnitsEditorCell,
            }
          case 'description':
            return {
              ...column,
              DataEditor: (props) => (
                <CustomDescriptionEditorCell
                  {...props}
                  canCreateNewMaterial={userStore.canCreateNewMaterial}
                  projectId={currentProjectId}
                />
              ),
              DataViewer: CustomDescriptionViewerCell,
            }
          case 'cost_code_id':
            return {
              ...column,
              DataEditor: (props) => {
                return (
                  <CustomCostCodeEditorCell
                    {...props}
                    onChange={(cell, shouldPropagateValue) => {
                      props.onChange({
                        ...cell,
                        shouldPropagateValue,
                      })
                    }}
                    projectId={currentProjectId}
                    defaultApplyToAllChecked={props.cell.materialRow.default_cost_code_to_be_applied}
                    onChangeApplyToAll={(args) => {
                      handleApplyCostCodeToAll({
                        ...args,
                        index: props.row,
                      })
                    }}
                  />
                )
              },
              DataViewer: CustomCostCodeViewerCell,
            }
          case 'cost_code_phase_id':
            return {
              ...column,
              DataEditor: (props) => (
                <CustomPhaseCodeEditorCell
                  {...props}
                  onChange={(cell, shouldPropagateValue) =>
                    props.onChange({
                      ...cell,
                      shouldPropagateValue,
                    })
                  }
                  defaultApplyToAllChecked={props.cell.materialRow.default_cost_code_to_be_applied}
                  onChangeApplyToAll={(args) => {
                    handleApplyCostCodeToAll({
                      ...args,
                      index: props.row,
                    })
                  }}
                  projectId={currentProjectId}
                />
              ),
              DataViewer: CustomPhaseCodeViewerCell,
            }
          default: {
            // Disable the company materials column to edit when user doesn't have permission
            if (companySettingStore.companyAttributes.includes(column.id)) {
              return {
                ...column,
                readOnly: !userStore.canCreateNewMaterial,
              }
            }
            return column
          }
        }
      })
    }, [
      companySettingStore.companyAttributes,
      currentProjectId,
      costCodeSettings?.independent_phase_codes_enabled,
      userStore.canCreateNewMaterial,
      handleApplyCostCodeToAll,
    ])

    const readOnlyColumns = useMemo(() => {
      const arr = ['default_vendor', 'ext_cost_with_tax']

      if (commitment?.id) {
        arr.push(
          'cost_code_id',
          'cost_code_phase_id',
          'unit',
          'unit_cost',
          'product_identifier',
          'size',
          'material',
          'connection_type',
          'manufacturer',
        )
      }
      return arr
    }, [commitment?.id])

    // Remove some other undesired columns, e.g. we don't want lead time or vendor notes at quote time
    const hiddenColumns = useMemo(() => {
      const arr = ['vendor_notes', 'extended_cost', 'group', 'sub_group', 'vendor_response']
      if (orderType === 'RFQ') {
        arr.push('unit_cost')
      }
      if (!costCodeSettings?.independent_phase_codes_enabled) {
        arr.push('cost_code_phase_id')
      }
      if (!orderStore.anyRequestedMaterial || !userStore.canCreateNewMaterial) {
        arr.push('approve')
      }
      if (!project?.tax_line_items?.enabled) {
        arr.push('tax_value')
        arr.push('ext_cost_with_tax')
      }

      return arr
    }, [
      orderType,
      costCodeSettings?.independent_phase_codes_enabled,
      orderStore.anyRequestedMaterial,
      userStore.canCreateNewMaterial,
      project?.id,
    ])

    const deliveryTotalCost = orderStore.deliveryTotalCost({
      deliveryId,
      deliveries: orderStore.newDeliveries,
    })

    if (companySettingStore.companyAttributes.length === 0) {
      return null
    }

    return (
      <>
        <OrderMaterialSpreadsheet
          companyVendorId={companyVendors[0]?.id}
          canCreateNewMaterial={userStore.canCreateNewMaterial}
          costCodeSettings={costCodeSettings}
          orderType={orderType}
          columns={flatten(columns)}
          requiredFields={orderType === 'Order' ? requiredOrderFields : requiredQuoteFields}
          hiddenColumns={hiddenColumns}
          readOnlyColumns={readOnlyColumns}
          orderMaterials={orderMaterials}
          isSelecting={orderStore.isSplitting}
          projectId={currentProjectId}
          onChange={(newOrderMaterials, valuesToPropagate) => {
            if (valuesToPropagate?.length > 0) {
              valuesToPropagate.forEach((valueToPropagate) => {
                newOrderMaterials = populateSameCostCode.handleBulkChange({
                  triggerIndex: valueToPropagate.index,
                  path: valueToPropagate.path as UsePopulateSameCostCodePath,
                  value: valueToPropagate.value,
                  datasource: newOrderMaterials,
                })
              })
            }

            if (newOrderMaterials.some((material) => !!material?.tax_value) && project?.tax_line_items?.enabled) {
              const totalTaxSumFromOrderMaterials = newOrderMaterials.reduce(
                (acc, material) => acc + (Number(material?.tax_value) || 0),
                0,
              )
              delivery.tax_value = roundValue(totalTaxSumFromOrderMaterials)
            }

            orderStore.updateOrderMaterialsByDeliveryId(delivery.id, newOrderMaterials)
            handleOrderDirty()
          }}
          orderState={orderStore?.selectedOrder?.state}
          defaultCostCodeByApplyAll={populateSameCostCode.defaultValues.cost_code as CostCode}
          onApplyDefaultCostCodeFromApplyAll={populateSameCostCode.addToAppliedByAll}
        />

        <OrderMaterialSpreadsheetFooter
          index={index}
          hideAddTotal={userStore.isMaterialRequester}
          disabled={!userStore.canCreateDrafts || orderStore.isSplitting}
          addEmptyOrderMaterials={() => orderStore.addEmptyOrderMaterials(deliveryId, 5)}
          deliveryTotalCost={deliveryTotalCost}
          isPoLocked={isPoLocked}
          hideTotal={orderType === 'RFQ' || orderStore.isSplitting}
          deliveryChargesUnits={delivery.delivery_charges_units}
          onChangeChargesUnit={(value) => (delivery.delivery_charges_units = value)}
          roundingPrecision={companySettingStore.otherSettings?.rounding_precision_settings?.order_precision}
          orderMaterialsTotalCost={orderStore.materialsTotalCost(orderStore.getOrderMaterialsByDeliveryId(delivery.id))}
          onChange={(value) => {
            delivery.discount_value = value.discountValue
            delivery.shipping_value = value.shippingValue
            delivery.other_value = value.otherValue
            delivery.tax_value = value.taxValue

            if (project?.tax_line_items?.enabled) {
              const newOrderMaterials = calcOrderTaxSplitBulk({
                orderMaterials,
                taxAmount: value.taxValue,
                taxLineItemsEnabled: true,
                precision: companySettingStore.otherSettings?.rounding_precision_settings?.order_precision,
              })
              orderStore.updateOrderMaterialsByDeliveryId(delivery.id, newOrderMaterials)
            }
            handleOrderDirty()
          }}
          value={{
            discountValue: delivery?.discount_value,
            shippingValue: delivery?.shipping_value,
            otherValue: delivery?.other_value,
            taxValue: delivery?.tax_value,
          }}
        />
      </>
    )
  },
)
