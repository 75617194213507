import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import _ from 'lodash'

import { Box } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import { PersistentFiltersProvider } from 'common/contexts/persistent_filters'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import { CreateInvoiceAction } from 'contractor/pages/Invoices/create_invoice_actions'

import { InvoiceDeletedList } from './tabs/DeletedList/invoice_deleted_list_tab'
import { InvoiceInboxes } from './tabs/Inboxes/invoice_inboxes_page'
import { InvoiceTab } from './tabs/List/invoice_tab'
import { ToReview } from './tabs/ToReview'

export function InvoicesPageV2() {
  const { userStore, invoiceStore, invoiceInboxStore } = useStores()
  const history = useHistory()
  const location = useLocation()
  const invoiceTabDeletedEnabled = useFlag('invoice_tab_deleted')

  const tabItems = React.useMemo(() => {
    const items = []
    const toRewiewTab = {
      label: 'To Review',
      key: 'to_review',
      children: <ToReview tabKey="to_review" />,
    }

    if (userStore.canViewAllInvoices || userStore.canViewAllMyProjectInvoices) {
      items.push(toRewiewTab)
      items.push({
        label: 'All Invoices',
        key: 'invoices',
        children: <InvoiceTab tabKey="invoices" />,
      })
    } else if (userStore.canEditInvoices) {
      items.push(toRewiewTab)
    }

    if (userStore.canViewInvoiceInbox) {
      items.push({
        label: 'Inbox',
        key: 'invoice_inboxes',
        children: <InvoiceInboxes />,
      })
    }

    if (invoiceTabDeletedEnabled && userStore.canDeleteInvoices) {
      items.push({
        label: 'Deleted',
        key: 'invoices_deleted',
        children: <InvoiceDeletedList />,
      })
    }

    return items
  }, [userStore.canViewAllInvoices, userStore.canViewInvoiceInbox, userStore.canViewAllMyProjectInvoices])

  const convertFilterToURLParam = (filter) => {
    // if filter is array, convert to comma separated string
    if (Array.isArray(filter)) {
      return filter.join(',')
    }
    return filter
  }

  const loadInvoiceSessionFilters = (filters: {}) => {
    const invoiceFilters = sessionStorage.getItem('invoiceRibbonFilter')
    // invoiceFilters is not null or empty
    if (_.isEmpty(invoiceFilters)) {
      return filters
    }
    const sessionStates = invoiceFilters.split(',')
    const currentStates = filters['state_name'] || []
    filters['state_name'] = Array.from(new Set([...sessionStates, ...currentStates]))
    sessionStorage.removeItem('invoiceRibbonFilter')
    return filters
  }

  const removeDiscardedFilters = (filters: {}) => {
    const keys = Object.keys(filters)
    const dropFilters: string[] = []
    if (keys.includes('discard_reason')) {
      dropFilters.push('discard_reason')
    }
    if (keys.includes('discarded_by_company_user_name')) {
      dropFilters.push('discarded_by_company_user_name')
    }
    return dropFilters
  }

  const setParamsForTab = (tab: string) => {
    const urlParams = new URLSearchParams(location.search)
    urlParams.set('tab', tab)

    let filters: {}
    let dropFilters: string[] = []
    switch (tab) {
      case 'to_review':
      case 'invoices':
        filters = loadInvoiceSessionFilters(_.cloneDeep(invoiceStore.listStore.searchState.filters))
        dropFilters = removeDiscardedFilters(filters)
        break
      case 'invoice_inboxes':
        filters = invoiceInboxStore.listStore.searchState.filters
        dropFilters = removeDiscardedFilters(filters)
        break
      case 'invoices_deleted':
      default:
        filters = {}
    }

    for (const key in filters) {
      let value = invoiceStore.listStore.searchState.filters[key]
      // if value is empty or null don't add to url
      if (!value || (Array.isArray(value) && value.length === 0)) {
        continue
      }
      if (Array.isArray(value)) {
        value = value.filter((v) => v !== '')
      }
      const params = convertFilterToURLParam(value)
      // if params empty or blank don't add to url
      if (!params || params === '') {
        continue
      }
      urlParams.set(key, params)
    }
    for (const key of dropFilters) {
      urlParams.delete(key)
    }
    history.replace({ search: urlParams.toString() })
  }

  const handleChangeTab = (key: string) => {
    const searchParams = new URLSearchParams(location.search)

    const currentTab = searchParams.get('tab')
    if (currentTab !== key) {
      setParamsForTab(key)
      return
    }

    searchParams.set('tab', key)
    history.push({
      search: searchParams.toString(),
    })
  }

  const getDefaultTab = () => {
    if (userStore.canViewAllInvoices || userStore.canViewAllMyProjectInvoices || userStore.canEditInvoices) {
      setParamsForTab('to_review')
      return 'to_review'
    }

    return 'invoice_inboxes'
  }

  const activeKey = new URLSearchParams(location.search).get('tab')

  return (
    <PersistentFiltersProvider
      ignoreOnFilterCounter={['tab']}
      ignoreQueryParams={['tab']}
      listStore={invoiceStore.listStore}
    >
      <Page>
        <Page.Tabs
          destroyInactiveTabPane
          activeKey={activeKey || getDefaultTab()}
          items={tabItems}
          onChange={handleChangeTab}
          tabBarExtraContent={
            <Box mr={4}>
              <CreateInvoiceAction />
            </Box>
          }
        />
      </Page>
    </PersistentFiltersProvider>
  )
}
