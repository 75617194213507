import React from 'react'

import { observer } from 'mobx-react-lite'

import { Page } from 'common/components/Page'
import EditUserPassword from 'common/pages/UserSettings/edit_user_password'

import { useStores } from 'contractor/hooks/use-stores'

import { PersonalInformation } from './personal_information'
import { Settings } from './settings'

const ContractorUserSettings = observer(() => {
  const { userStore } = useStores()

  const params = new URLSearchParams(location.search)

  const tabItems = [
    { label: 'Personal information', key: 'personal-information', children: <PersonalInformation /> },
    {
      label: 'Security',
      key: 'security',
      children: <EditUserPassword onSubmitPasswordChange={(values) => userStore.updatePassword(values)} />,
    },
    { label: 'Settings', key: 'settings', children: <Settings /> },
  ]

  return (
    <Page>
      <Page.Tabs defaultActiveKey={params.get('tab') || 'personal-information'} items={tabItems} />
    </Page>
  )
})

export default ContractorUserSettings
