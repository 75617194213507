import React, { useEffect } from 'react'

import { Select, SelectProps } from 'antd'

import { observer } from 'mobx-react-lite'

import { useStores } from 'contractor/hooks/use-stores'
import { Commitment } from 'contractor/server/commitments'

export type SelectProjectProps = {
  projectId: string
} & SelectProps

export const SelectCommitment = observer<SelectProjectProps>((props) => {
  const { commitmentStore } = useStores()
  const { projectId, options, ...rest } = props
  const { listStore } = commitmentStore
  const [commitments, setCommitments] = React.useState<Commitment[]>([])

  useEffect(() => {
    const newCommitments = listStore.records?.filter((commitment) => commitment.project_id === projectId)
    setCommitments(newCommitments)
  }, [projectId])

  if (props.options) {
    return (
      <Select
        aria-label="project-commitment"
        data-cy="project-commitment"
        style={{ width: '100%' }}
        placeholder="Select Commitment"
        filterOption={(input, option) => {
          const inputValue = input?.toLowerCase()
          const searchable = option?.label as Nullable<string>
          return searchable?.toLowerCase()?.includes(inputValue)
        }}
        showSearch
        aria-autocomplete="none"
        {...rest}
      >
        {options.map(({ label, value }) => (
          <Select.Option key={value} name={label} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>
    )
  }

  return (
    <Select
      aria-label="project-commitment"
      data-cy="project-commitment"
      style={{ width: '100%' }}
      placeholder="Select Commitment"
      allowClear
      filterOption={(input, option) => {
        const inputValue = input?.toLowerCase()
        return option?.searchable?.toLowerCase()?.includes(inputValue)
      }}
      showSearch
      {...rest}
    >
      {commitments?.map((commitment) => (
        <Select.Option
          key={commitment.id}
          value={commitment.commitment_id}
          label={commitment.commitment_name}
          searchable={commitment?.commitment_name}
        >
          {commitment.commitment_name}
        </Select.Option>
      ))}
    </Select>
  )
})
