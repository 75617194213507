import React, { createContext, useContext } from 'react'

import _ from 'lodash'

import { observer } from 'mobx-react-lite'

import { USE_LOCK_PO_SESSION_ACTIONS_DEBOUNCE_TIME, useLockPo, UseLockPoType } from 'contractor/hooks/use-lock-po'
import { useStores } from 'contractor/hooks/use-stores'

const OrderDetailContext = createContext<OrderDetailContextProps>({} as OrderDetailContextProps)

export const useOrderDetails = () => useContext(OrderDetailContext)

type OrderDetailContextProps = {
  handleOrderDirty: () => void
  orderSession: UseLockPoType['orderSession']
  orderLocked: UseLockPoType['orderLocked']
}

export const OrderDetailProvider = observer(({ children }) => {
  const { orderStore } = useStores()
  const { orderSession, orderLocked } = useLockPo()

  const handleRenewDebounced = _.debounce(() => {
    orderSession?.onRenew()
  }, USE_LOCK_PO_SESSION_ACTIONS_DEBOUNCE_TIME)

  const handleOrderDirty = () => {
    orderStore.setOrderDirty()
    handleRenewDebounced()
  }

  return (
    <OrderDetailContext.Provider
      value={{
        handleOrderDirty,
        orderSession,
        orderLocked,
      }}
    >
      {children}
    </OrderDetailContext.Provider>
  )
})

export const withOrderDetailProvider = (Component) => (props) => {
  return (
    <OrderDetailProvider>
      <Component {...props} />
    </OrderDetailProvider>
  )
}
