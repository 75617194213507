import React, { useRef } from 'react'

import { useLocation } from 'react-router-dom'

import styled from '@emotion/styled'

import { MenuOutlined, UserOutlined } from '@ant-design/icons'
import { Divider, Menu, Space, Typography } from 'antd'
import { MenuProps } from 'antd/lib/menu'
import { ItemType, MenuItemType } from 'antd/lib/menu/hooks/useItems'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { Visibility } from 'common/components/Visibility'
import { Breakpoints } from 'common/hooks/use-media-query'

import { NavItem } from './nav_item'

type NavItem = { href: string; match: RegExp } & MenuItemType

type NavBarProps = {
  navItems: NavItem[]
  extraItems?: MenuProps['items']
  userExtraItems?: MenuProps['items']
  adminExtraItems?: MenuProps['items']
  notification?: React.ReactNode
  userName?: string
  resourceName?: string
  basePath?: string
  breakpoint?: Breakpoints
  extraActionMenuItem?: ItemType
  extraActionComponent?: React.ReactNode
}

const NavStyled = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .only-icon {
    & .ant-menu-title-content {
      display: none;
    }
    & .ant-menu-submenu-title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
`

export const NavBar = ({
  navItems = [],
  extraItems = [],
  userExtraItems = [],
  adminExtraItems = [],
  notification,
  userName,
  resourceName,
  basePath = '/',
  breakpoint = 'lg',
  extraActionComponent,
  extraActionMenuItem,
}: NavBarProps) => {
  const { pathname } = useLocation()

  const drawerRef = useRef<DrawerRef>()

  const activeKey = navItems.find((navItem) => navItem.match.test(pathname))?.key

  const selectedKeys = [activeKey] as string[]

  const commonMenuProps: MenuProps = {
    mode: 'horizontal',
    theme: 'dark',
    selectedKeys,
  }

  const commonDrawerMenuProps: MenuProps = {
    mode: 'inline',
    selectedKeys,
  }

  const extraItemsChildren = [
    ...extraItems,
    ...((userExtraItems.length ? [{ type: 'divider' }, ...userExtraItems] : []) as MenuProps['items']),
    ...((adminExtraItems.length ? [{ type: 'divider' }, ...adminExtraItems] : []) as MenuProps['items']),
  ]

  return (
    <NavStyled>
      <img
        onClick={() => (location.href = basePath)}
        src="/subbase_logo_short.png"
        style={{ width: '40px', height: '40px', marginRight: '20px', cursor: 'pointer' }}
      />

      {/* Desktop */}
      <Visibility.Hidden breakpoint={breakpoint}>
        <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow={1}>
          <Menu
            items={navItems.map((navItem) => ({
              label: <NavItem label={navItem.label as string} href={navItem.href} />,
              key: navItem.key,
              onClick: () => drawerRef.current?.toggle(),
            }))}
            style={{ flexGrow: 1 }}
            {...commonMenuProps}
          />

          <Menu
            forceSubMenuRender
            items={[
              extraActionMenuItem,
              ...(notification ? [{ label: notification, key: 'notification' }] : []),
              {
                key: 'user',
                label: resourceName ? (
                  <Box display="flex" alignItems="center" lineHeight="15px" my={5}>
                    <UserOutlined style={{ fontSize: 18, marginRight: 10 }} />
                    <Box display="flex" flexDirection="column" maxWidth={130}>
                      <Typography.Text ellipsis style={{ color: 'white' }}>
                        {userName}
                      </Typography.Text>
                      {resourceName && (
                        <Typography.Text ellipsis style={{ fontSize: 12, color: 'white' }}>
                          {resourceName}
                        </Typography.Text>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center">
                    <UserOutlined style={{ fontSize: 18, marginRight: 10 }} />
                    <Typography.Text ellipsis style={{ color: 'white', maxWidth: 130 }}>
                      {userName}
                    </Typography.Text>
                  </Box>
                ),
                onClick: () => drawerRef.current?.toggle(),
                children: extraItemsChildren.length ? extraItemsChildren : null,
                theme: 'light',
              },
            ]}
            style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}
            {...commonMenuProps}
          />
        </Box>
      </Visibility.Hidden>

      {/* Mobile */}
      <Visibility.Show breakpoint={breakpoint}>
        <Space size="large">
          {extraActionComponent}
          {!!notification && React.cloneElement(notification as React.ReactElement, { withIcon: true })}
          <MenuOutlined onClick={() => drawerRef.current?.toggle()} style={{ fontSize: 20 }} />
        </Space>

        <Drawer ref={drawerRef} closable={false} width={378} forceRender>
          <Box width="100%" overflow="auto" py={16}>
            <Menu
              items={navItems.map((navItem) => ({
                label: <NavItem label={navItem.label as string} href={navItem.href} />,
                key: navItem.key,
                icon: navItem.icon,
                style: { fontWeight: 'bold' },
                onClick: () => drawerRef.current?.toggle(),
              }))}
              {...commonDrawerMenuProps}
            />

            {!!extraItems.length && (
              <>
                <Divider orientation="left">
                  <Typography.Text type="secondary">Company</Typography.Text>
                </Divider>
                <Menu
                  items={extraItems.map((item) => ({ ...item, onClick: () => drawerRef.current?.toggle() }))}
                  {...commonDrawerMenuProps}
                />
              </>
            )}

            {!!userExtraItems.length && (
              <>
                <Divider orientation="left">
                  <Typography.Text type="secondary">User</Typography.Text>
                </Divider>
                <Menu
                  items={userExtraItems.map((item) => ({ ...item, onClick: () => drawerRef.current?.toggle() }))}
                  {...commonDrawerMenuProps}
                />
              </>
            )}

            {!!adminExtraItems.length && (
              <>
                <Divider orientation="left">
                  <Typography.Text type="secondary">SubBase</Typography.Text>
                </Divider>
                <Menu
                  items={adminExtraItems.map((item) => ({ ...item, onClick: () => drawerRef.current?.toggle() }))}
                  {...commonDrawerMenuProps}
                />
              </>
            )}
          </Box>
        </Drawer>
      </Visibility.Show>
    </NavStyled>
  )
}
