import React, { useEffect, useState } from 'react'

import { ProjectOutlined } from '@ant-design/icons'
import { Tooltip, Typography } from 'antd'
import { SelectProps } from 'antd/lib/select'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { CustomEditableContent } from 'common/components/CustomEditableContent'
import { useQuery } from 'common/hooks/use-query'
import { InvoiceProject as InvoiceProjectResponse } from 'common/server/invoice'

import { SelectProject } from 'contractor/components/SelectProject'
import { useStores } from 'contractor/hooks/use-stores'
import { SimpleProject } from 'contractor/server/orders'
import { IndexProject } from 'contractor/server/projects'

import { useInvoice } from '../context'

const makeProjectOption = (project: IndexProject | InvoiceProjectResponse | SimpleProject | null) => {
  return project ? { label: project.name, value: project.id } : { label: 'Unknown', value: null }
}

export const InvoiceProject = observer<SelectProps>((props) => {
  const { invoiceStore, projectStore } = useStores()

  const { isLoading } = useQuery(projectStore.maybeIndexProjects)

  const { invoice } = invoiceStore

  const { setSelectedInvoiceDirty } = useInvoice()

  const firstInvoiceOrder = [...invoiceStore.selectedOrders]?.[0]?.order

  const [project, setProject] = useState(invoice.project)
  const [selectedProject, setSelectedProject] = useState(makeProjectOption(project))

  const onProjectChange = (projectId: string | null) => {
    setProject(projectStore.projects.find((project) => project.id === projectId))
  }

  const onProjectClear = () => {
    setProject(null)
  }

  useEffect(() => {
    setSelectedProject(makeProjectOption(project))
    invoiceStore.updateSelectedInvoice('project', project)
    setSelectedInvoiceDirty?.(true)
  }, [project])

  if (isLoading) {
    return null
  }

  if (firstInvoiceOrder?.project) {
    // Do not allow user to edit project when Order is selected
    // Assume the project from the first Order is the project for the Invoice
    invoiceStore.updateSelectedInvoice('project', firstInvoiceOrder.project)
    return (
      <Tooltip title="Project" placement="bottom">
        <Box style={{ gap: 8 }} display="inline-flex" alignItems="center">
          <ProjectOutlined />
          <Typography.Text type="secondary">{firstInvoiceOrder.project.name}</Typography.Text>
        </Box>
      </Tooltip>
    )
  }

  return (
    <CustomEditableContent
      boxProps={{ mt: 0 }}
      disabled={props?.disabled}
      input={
        <Tooltip title="Project" placement="bottom">
          <Box style={{ gap: 8 }} display="inline-flex" alignItems="center">
            <SelectProject
              disabled={props?.disabled}
              value={selectedProject}
              onSelect={onProjectChange}
              allowClear={selectedProject.value !== null}
              onClear={onProjectClear}
              style={{ width: '100%', minWidth: 200 }}
              {...props}
            />
          </Box>
        </Tooltip>
      }
    >
      <Tooltip title="Project" placement="bottom">
        <Box style={{ gap: 8 }} display="inline-flex" alignItems="center">
          <ProjectOutlined />
          <Typography.Text type="secondary">{project?.name || 'Unknown'}</Typography.Text>
        </Box>
      </Tooltip>
    </CustomEditableContent>
  )
})
