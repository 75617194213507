import React from 'react'

import { SendOutlined } from '@ant-design/icons'
import { Button, Comment, Input } from 'antd'

import { Box } from 'common/components/boxes'

import { CommentsProps } from './comments'

type NewCommentProps = {
  onChange: (comment: string) => void
  newComment: string
  disabled?: boolean
} & Pick<CommentsProps, 'onSubmit' | 'currentView' | 'placeholder' | 'loading'>

export const NewComment = ({
  onChange,
  newComment,
  onSubmit,
  placeholder = 'Add a comment',
  loading = false,
  disabled = false,
}: NewCommentProps) => {
  const handlePressEnter = (event) => {
    if (event.shiftKey || !newComment.trim().length) return

    onSubmit?.(newComment)
    onChange('')
    setTimeout(() => onChange(''), 100)
  }

  return (
    <Comment
      style={{ width: '100%' }}
      content={
        <Box display="flex" alignItems="flex-end" style={{ gap: 16 }}>
          <Input
            data-cy="add-comment"
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
            onPressEnter={handlePressEnter}
            value={newComment}
            style={{ resize: 'none', width: '100%' }}
            disabled={disabled}
          />

          <Button
            onClick={() => {
              onSubmit(newComment)
              onChange('')
            }}
            loading={loading}
            type="primary"
            disabled={!newComment}
            icon={<SendOutlined />}
          />
        </Box>
      }
    />
  )
}
