import { orderBy, uniqBy } from 'lodash'
import { BindAll } from 'lodash-decorators'

import { action, observable } from 'mobx'

import { subscribeNotificationChannel, TYPE_CONTRACTOR } from 'common/data-access/notification_websocket'
import { NotificationData, ServerNotificationGroup } from 'common/server/user'

import {
  dismiss_all,
  dismiss_notification,
  dismiss_notification_group,
  indexNotificationMessages,
  indexNotifications,
  loadNotifications,
} from 'contractor/server/notification'
import { update, UserNotificationFrequency } from 'contractor/server/user'

@BindAll()
export default class NotificationStore {
  notificationGroups = observable.array<ServerNotificationGroup>([], { deep: true })
  messages = observable.array<NotificationData>([], { deep: true })
  subscriptions = []
  page = 1
  showLoadMore = false
  unreadNotificationsCount = 0
  totalUnreadNotificationsCount = 0

  updateSettings(params: UserNotificationFrequency) {
    return update(params)
  }

  subscribe(userId: string) {
    if (!userId) return
    if (this.subscriptions.includes(userId)) {
      return
    }
    this.subscriptions.push(userId)
    return subscribeNotificationChannel(this.handleNotification, TYPE_CONTRACTOR)
  }

  @action
  async getIndexNotifications() {
    indexNotifications().then((response) => {
      this.totalUnreadNotificationsCount = response.data.unread_count
      this.handleNotification(response.data.notifications)
    })
  }

  @action
  handleNotification(message: ServerNotificationGroup | ServerNotificationGroup[]) {
    if (!message) return null

    if (Array.isArray(message) && this.notificationGroups.length === 0) {
      this.notificationGroups.replace(message)
    } else if (Array.isArray(message) && this.notificationGroups.length !== 0) {
      const uniqueMessages = uniqBy([...message, ...this.notificationGroups], 'id')
      const newGroups = orderBy(uniqueMessages, ['updated_at'], ['desc'])

      this.notificationGroups.replace(newGroups)
    } else if (!Array.isArray(message) && this.notificationGroups.some((group) => group.id === message.id)) {
      const index = this.notificationGroups.findIndex((group) => group.id === message.id)
      this.notificationGroups[index] = message

      const newGroups = orderBy(this.notificationGroups, ['updated_at'], ['desc'])

      this.notificationGroups.replace(newGroups)
    } else if (!Array.isArray(message) && message?.notification_ids?.length > 0) {
      this.notificationGroups.unshift(message)
    } else {
      this.notificationGroups.replace([...this.notificationGroups, ...message])
    }

    this.updateCounters()
  }

  @action
  async loadMoreNotifications(unread: boolean) {
    this.page++
    const notifications = await loadNotifications(this.page, unread)
    this.notificationGroups.replace([...this.notificationGroups, ...notifications.data])
    this.updateCounters()
  }

  @action
  async dismissGroup(groupId: string) {
    await dismiss_notification_group(groupId)
    const notifications = this.notificationGroups?.map((group) => {
      if (group.id === groupId) {
        return {
          ...group,
          unread: false,
          unread_count: 0,
        }
      }
      return group
    })
    const decreaseBy = this.notificationGroups.find((group) => group.id === groupId)?.unread_count
    this.updateCounters(decreaseBy)
    this.notificationGroups.replace(notifications)
  }

  @action
  async dismissAll() {
    await dismiss_all()
    const notifications = this.notificationGroups?.map((notification) => {
      return { ...notification, unread: false, unread_count: 0 }
    })

    this.updateCounters(this.unreadNotificationsCount)
    this.notificationGroups.replace(notifications)
  }

  @action
  async loadMessages(notificationId: string) {
    return (await indexNotificationMessages(notificationId)).data
  }

  @action
  updateCounters(decreaseBy?: number) {
    if (decreaseBy) {
      this.unreadNotificationsCount = this.unreadNotificationsCount - decreaseBy
      return
    }

    this.unreadNotificationsCount = this.notificationGroups
      ?.filter((group) => group.unread)
      ?.reduce((acc, group) => acc + group.unread_count, 0)

    if (!this.notificationGroups.length) {
      this.showLoadMore = false
      this.unreadNotificationsCount = 0
      return
    }
    this.showLoadMore = this.unreadNotificationsCount < this.totalUnreadNotificationsCount
  }

  notificationCountByObject(orderId: string) {
    const notifications = this.notificationGroups.filter((group) => group.object_id === orderId)
    return notifications?.reduce((acc, group) => acc + group.unread_count, 0)
  }

  async dismiss(notificationId: string) {
    await dismiss_notification(notificationId)
    const newNotificationsGroup = this?.notificationGroups.map((group) => {
      if (group.notification_ids.includes(notificationId)) {
        return {
          ...group,
          notification_ids: group.notification_ids.filter((id) => id !== notificationId),
          unread_count: group.unread_count - 1,
          unread: group.unread_count > 0,
        }
      }
      return group
    })
    this.notificationGroups.replace(newNotificationsGroup)
  }
}
