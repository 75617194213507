import React from 'react'

import { LeftOutlined } from '@ant-design/icons'
import { DatePicker, Divider, Form, Typography } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'

type HeaderProps = {
  orderName: string
  orderNumber: string
  onBack: () => void
}

export const Header = (props: HeaderProps) => {
  const { orderName, orderNumber, onBack } = props

  return (
    <Box>
      <Box
        style={{
          position: 'relative',
          cursor: 'pointer',
          padding: '8px 16px',
        }}
      >
        <FlexBoxX
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{ position: 'relative' }}
        >
          <LeftOutlined
            style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', fontSize: 12 }}
            onClick={onBack}
          />

          <Typography.Text ellipsis strong style={{ marginLeft: 32, maxWidth: '40vw' }}>
            {orderName}: {orderNumber}
          </Typography.Text>

          <Form.Item style={{ padding: 0, margin: 0 }} name="actualDeliveredAt">
            <DatePicker />
          </Form.Item>
        </FlexBoxX>
      </Box>

      <Divider type="horizontal" style={{ width: '100%', margin: 0, padding: 0, paddingTop: 4 }} />
    </Box>
  )
}
