import React, { useEffect } from 'react'

import { get } from 'lodash'

import { Button, Input, message, Select, Switch, Typography, Modal } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import Uploader from 'common/components/uploader'
import { noticeError } from 'common/helpers/new_relic'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'
import { useQuery } from 'common/hooks/use-query'
import { PONumberGenerationMethods } from 'common/server/server_types'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

import { withOtherSettingsProvider, useOtherSettings } from './context'
import { TermsAndConditions, FILE_KEY } from './terms_and_conditions'

const generationMethods = [
  { id: PONumberGenerationMethods.DEFAULT, label: 'Default', value: PONumberGenerationMethods.DEFAULT },
  {
    id: PONumberGenerationMethods.ORDER_NUMBER__PROJECT_ID,
    label: 'By Project',
    value: PONumberGenerationMethods.ORDER_NUMBER__PROJECT_ID,
  },
  {
    id: PONumberGenerationMethods.PREFIX_WITH_PROJECT_ID,
    label: 'By Project (With Prefix)',
    value: PONumberGenerationMethods.PREFIX_WITH_PROJECT_ID,
  },
  {
    id: PONumberGenerationMethods.ORDER_NUMBER__COMPANY_ID,
    label: 'By Company',
    value: PONumberGenerationMethods.ORDER_NUMBER__COMPANY_ID,
  },
  {
    id: PONumberGenerationMethods.ORDER_NUMBER__PROJECT_ID__TCM,
    label: 'Other (Custom)',
    value: PONumberGenerationMethods.ORDER_NUMBER__PROJECT_ID__TCM,
  },
]

const OtherSettingsComponent = observer(() => {
  const { companySettingStore, userStore, uploaderStore } = useStores()
  const { form } = useOtherSettings()

  const { otherSettings } = companySettingStore

  const featureFlagOrderBlockingEnabled = useFlag('order_blocking')

  useEffect(() => {
    uploaderStore.resetUploads('logo')
    uploaderStore.addExistingFiles('logo', otherSettings?.logo)
  }, [otherSettings?.logo?.key])

  useQuery(companySettingStore.indexOtherSettings)

  const handleSave = async () => {
    if (!uploaderStore.checkIfAllUploadsCompleted()) {
      Modal.error({ title: 'Uploads have not completed yet, please try again' })
      return
    }

    const formValues = await form.validateFields()

    try {
      const termsAndConditions = formValues?.termsAndConditions
        ?.map((termsAndCondition, index) => ({
          id: termsAndCondition?.id,
          name: termsAndCondition?.name,
          discarded_at: termsAndCondition?.discardedAt,
          file_signed_id: get(uploaderStore.signedIds(`${FILE_KEY}:${index}`), '[0]'),
        }))
        ?.filter((termsAndCondition) => termsAndCondition.file_signed_id)

      const logoSignedId = get(uploaderStore.signedIds('logo'), '[0]')
      const logoSignedDeleteId = get(uploaderStore.deleteAttachmentIds['logo'], '[0]')

      await companySettingStore.updateOtherSettings({
        ...otherSettings,
        logo_signed_id: logoSignedId,
        logo_signed_delete_id: logoSignedDeleteId,
        terms_and_conditions: termsAndConditions,
      })
      message.success('Saved Changes')
    } catch (error) {
      noticeError(error, { entry: 'save-other-settings' })
      const apiErrorMessage = error?.response?.data?.error
      message.error(
        apiErrorMessage || `Unable to save changes. Please contact ${SUPPORT_EMAIL_ADDRESS} for further assistance.`,
      )
    }
  }

  const orderFormURL = `${window.location.origin}/order_form/${otherSettings.public_order_form_url_extension}`

  return (
    <>
      <Page.Header>
        <FlexBoxX justifyContent="space-between" width="100%">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Other Settings
          </Typography.Title>

          {userStore.canManageCompanySettings && (
            <Button type="primary" onClick={() => handleSave()}>
              Save Changes
            </Button>
          )}
        </FlexBoxX>
      </Page.Header>

      <Page.Content>
        <FlexBoxY alignItems="flex-start">
          <Typography.Title level={4}>Material requisition</Typography.Title>

          <FlexBoxX my={8}>
            <Typography.Text strong>Public Order Form Enabled: </Typography.Text>
            <Switch
              checked={otherSettings.public_order_form_enabled}
              onChange={() => {
                otherSettings.public_order_form_enabled = !otherSettings.public_order_form_enabled
              }}
              disabled={!userStore.canManageCompanySettings}
              style={{ marginLeft: '16px' }}
            />
          </FlexBoxX>

          <Typography.Paragraph>
            This will enable anyone to submit draft orders WITHOUT formally setting up an account. The project&apos;s
            default watchers will be notified of all new material requests. Users will be automatically setup as
            Material Requesters.
          </Typography.Paragraph>

          <Typography.Link disabled={!otherSettings.public_order_form_enabled} href={orderFormURL}>
            {orderFormURL}
          </Typography.Link>
        </FlexBoxY>

        <FlexBoxY alignItems="flex-start" mt={20}>
          <FlexBoxX my={8}>
            <Typography.Text strong>Add Approval Step: </Typography.Text>
            <Switch
              checked={otherSettings.approval_state_enabled}
              onChange={() => {
                otherSettings.approval_state_enabled = !otherSettings.approval_state_enabled
              }}
              disabled={!userStore.canManageCompanySettings}
              style={{ marginLeft: '16px' }}
            />
          </FlexBoxX>

          <Typography.Paragraph>
            This will add an “Approval” step to your ordering workflow. You can configure in your permissions which
            roles are able to approve a request and/or place an order.
          </Typography.Paragraph>
        </FlexBoxY>

        <FlexBoxY alignItems="flex-start" mt={20}>
          <FlexBoxX my={8}>
            <Typography.Text strong>Distribute Tax to Line Items: </Typography.Text>
            <Switch
              checked={otherSettings.tax_line_items.enabled}
              onChange={(value) => {
                otherSettings.tax_line_items.enabled = value
              }}
              disabled={!userStore.canManageCompanySettings}
              style={{ marginLeft: '16px' }}
            />
          </FlexBoxX>

          <Typography.Paragraph>
            Tax will be proportionally included in each line items extended cost.
          </Typography.Paragraph>
        </FlexBoxY>

        <FlexBoxY alignItems="flex-start" mt={20}>
          <FlexBoxX my={8}>
            <Typography.Text strong>Vendor pricing lock: </Typography.Text>
            <Switch
              checked={otherSettings.vendor_price_lock.enabled}
              onChange={(value) => {
                otherSettings.vendor_price_lock.enabled = value
              }}
              disabled={!userStore.canManageCompanySettings}
              style={{ marginLeft: '16px' }}
            />
          </FlexBoxX>

          <Typography.Paragraph>
            Vendors won&apos;t be able to change the pricing in placed and confirmed orders. They will have to send the
            price change as a request.
          </Typography.Paragraph>
        </FlexBoxY>

        {featureFlagOrderBlockingEnabled && (
          <FlexBoxY alignItems="flex-start" mt={4}>
            <FlexBoxX my={8}>
              <Typography.Text strong>Lock vendor when editing: </Typography.Text>
              <Switch
                checked={otherSettings.order_session_vendor_enabled}
                onChange={() => {
                  otherSettings.order_session_vendor_enabled = !otherSettings.order_session_vendor_enabled
                }}
                disabled={!userStore.canManageCompanySettings}
                style={{ marginLeft: '16px' }}
              />
            </FlexBoxX>

            <Typography.Paragraph>
              This will activate the order blocking flow for the vendors. If this option is enabled, vendors will also
              be locked from doing any action on an order while somebody from your company is currently editing it.
            </Typography.Paragraph>
          </FlexBoxY>
        )}

        <FlexBoxY alignItems="flex-start" mt={20}>
          <Typography.Title level={4}>Order Number (Auto Generation)</Typography.Title>
          <Typography.Paragraph>
            This will allow you to set how the order number should be auto generated.
            <br />
            <b>Generation methods:</b>
            <ul>
              <li>
                <b>Default</b> - Provides a global order number
              </li>
              <li>
                <b>By Project</b> - Provides a sequential number per project and uses the project&apos;s ID as prefix.
                If the project ID is not present, it will use the prefix assigned here.
              </li>
              <li>
                <b>By Project (With Prefix)</b> - Provides a sequential number per project and uses the selected prefix
                plus the project&apos;s ID as Prefix.
              </li>
              <li>
                <b>By Company</b> - Provides a sequential number shared among all projects
              </li>
              <li>
                <b>Other (Custom)</b> - This should be used if you want to use a custom order number format. Please be
                in touch with SubBase team to use this configuration.
              </li>
            </ul>
            <b>Prefix:</b> This will be added to the order number. <i>Example:</i> if set as &quot;<b>SB</b>&quot;, the
            Order Number will be &quot;<b>SB</b>-000001&quot;.
            <br />
            <b>Leading Zeros:</b> This will add leading zeros to the order number. <i>Example:</i> if set as <b>6</b>,
            the Order Number will be &quot;<b>00000</b>1&quot;.
          </Typography.Paragraph>

          <FlexBoxX>
            <Typography.Text strong style={{ minWidth: 150, textAlign: 'right', paddingRight: 10 }}>
              Generation Method:
            </Typography.Text>
            <Select
              options={generationMethods}
              value={otherSettings.po_number.generation_method}
              onChange={(value) => {
                otherSettings.po_number.generation_method = value
              }}
              disabled={!userStore.canManageCompanySettings}
              style={{ width: '100%', minWidth: 178 }}
            />
          </FlexBoxX>

          <FlexBoxX mt={5}>
            <Typography.Text strong style={{ minWidth: 150, textAlign: 'right', paddingRight: 10 }}>
              Prefix:
            </Typography.Text>
            <Input
              value={otherSettings.po_number.prefix}
              onChange={(e) => {
                otherSettings.po_number.prefix = e.target.value
              }}
              disabled={!userStore.canManageCompanySettings}
              style={{ width: '100%' }}
            />
          </FlexBoxX>

          <FlexBoxX mt={5}>
            <Typography.Text strong style={{ minWidth: 150, textAlign: 'right', paddingRight: 10 }}>
              Leading Zeros:
            </Typography.Text>
            <Input
              type="number"
              value={otherSettings.po_number.lead_zeros}
              onChange={(e) => {
                otherSettings.po_number.lead_zeros = Number(e.target.value)
              }}
              disabled={!userStore.canManageCompanySettings}
              style={{ width: '100%' }}
            />
          </FlexBoxX>
        </FlexBoxY>

        <FlexBoxY alignItems="flex-start" mt={20}>
          <Typography.Title level={4}>Company Information</Typography.Title>
          <Typography.Text strong>Logo: </Typography.Text>
          <Uploader
            modalTitle="Edit logo"
            accept="image/png, image/jpg, image/jpeg"
            component="Upload"
            multiple={false}
            uploadKey="logo"
            noResetUploads
            listType="picture-card"
            fileList={uploaderStore.fileList('logo')}
            onRemoveUpload={uploaderStore.removeUpload}
            onAddNewUpload={uploaderStore.addNewUpload}
            onResetUploads={uploaderStore.resetUploads}
            onSetUploadError={uploaderStore.setUploadError}
            onUpdateUpload={uploaderStore.updateUpload}
          />
        </FlexBoxY>

        <TermsAndConditions />
      </Page.Content>
    </>
  )
})

export const OtherSettings = withOtherSettingsProvider(OtherSettingsComponent)
