import React from 'react'

import { Tooltip, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { CostCodePhase } from 'common/server/cost_codes/cost_code_phases'

export const makeCostCodePhaseOptionAdvanced = (costCodePhase: CostCodePhase) => {
  if (!costCodePhase) {
    return undefined
  }

  return {
    originalObject: costCodePhase,
    value: costCodePhase.id,
    label: (
      <Tooltip title={costCodePhase?.description}>
        <Box display="flex" flexDirection="column" style={{ whiteSpace: 'normal' }}>
          <Typography.Text>{costCodePhase.code}</Typography.Text>
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            {costCodePhase?.description}
          </Typography.Text>
        </Box>
      </Tooltip>
    ),
  }
}
