import React from 'react'

import { Button, Typography, Space, Switch } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX, FlexBoxY, Box } from 'common/components/boxes'
import { SearchInput } from 'common/components/SearchInput'
import { Visibility } from 'common/components/Visibility'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { Flatfile } from 'contractor/components/Flatfile'
import { useStores } from 'contractor/hooks/use-stores'

import { CostCodeOptions } from './Options'

type HeaderProps = {
  onAdd: () => void
}

type ExtraActionspProps = HeaderProps

const ExtraActions = observer<ExtraActionspProps>(({ onAdd }) => {
  const { costCodeStore, flatfileStore } = useStores()

  const { costCodeListStore } = costCodeStore

  const handleSuccessUpload = () => {
    costCodeListStore.fetchRecords()
    costCodeListStore.fetchFacets()
    costCodeStore.getCostCodeTags()
  }

  return (
    <Space size="middle">
      <Visibility.Hidden breakpoint="md">
        <Flatfile onSuccess={handleSuccessUpload} openUpload={flatfileStore.openUploadCostCodes} />
      </Visibility.Hidden>

      <Button type="primary" onClick={onAdd}>
        Add
      </Button>
    </Space>
  )
})

export const Header = observer<HeaderProps>(({ onAdd }) => {
  const { costCodeStore } = useStores()
  const persistentFilters = usePersistentFilters()

  const { costCodeListStore } = costCodeStore

  return (
    <FlexBoxY>
      <FlexBoxX justifyContent="space-between" width="100%" mb={{ _: 12, xs: 24 }} alignItems="flex-start">
        <Typography.Title level={3}>Cost Codes</Typography.Title>

        <FlexBoxX flexGrow={0}>
          <ExtraActions onAdd={onAdd} />
        </FlexBoxX>
      </FlexBoxX>

      <FlexBoxX width="100%" flexDirection={{ _: 'column', xs: 'row' }}>
        <Box mr={{ _: 0, xs: 16 }} mb={{ _: 12, xs: 0 }} width="100%">
          <SearchInput
            value={costCodeListStore.searchState.search}
            onSearch={(search: string) => persistentFilters.handleChangeFilters({ search })}
            style={{ width: '100%' }}
          />
        </Box>

        <Space>
          <FlexBoxX
            width={{ _: '100%', xs: 'auto' }}
            border="1px solid"
            borderColor="gray-2"
            borderRadius="md"
            py="6px"
            px={12}
            flexGrow={0}
          >
            <Typography.Text style={{ whiteSpace: 'nowrap', marginRight: 12 }} strong>
              Actives only
            </Typography.Text>
            <Switch
              checked={costCodeListStore.searchState.filters['active']}
              onChange={(active) => persistentFilters.handleChangeFilters({ active })}
            />
          </FlexBoxX>

          <CostCodeOptions />
        </Space>
      </FlexBoxX>
    </FlexBoxY>
  )
})
