import React, { useState } from 'react'

import { LocalShipping } from '@styled-icons/material'

import styled from '@emotion/styled'

import { ExclamationCircleOutlined, PlusOutlined, ShopFilled } from '@ant-design/icons'
import { Tabs, Modal, Dropdown } from 'antd'
import { TabsProps } from 'antd/lib/tabs'

import { Box } from 'common/components/boxes'

import VendorDeliveryInfo from 'contractor/components/Deliveries/delivery_info'

type OrderMaterialTabsProps = {
  onAdd?: () => void
  onRemove?: (targetKey: string) => void
  onDuplicate?: (targetKey: string) => void
  deliveriesCount?: number
  disabled?: boolean
} & TabsProps

const TabsStyled = styled(Tabs)`
  width: 100%;

  &#order-materials-tabs .ant-tabs-nav {
    margin: 0;
  }
  &#order-materials-tabs .ant-tabs-content-holder {
    background: ${({ theme }) => theme.colors.white};
    padding: ${({ theme }) => theme.sizes[12]};
    border-top-right-radius: ${({ theme }) => theme.radii.sm};
    border-bottom-left-radius: ${({ theme }) => theme.radii.sm};
    border-bottom-right-radius: ${({ theme }) => theme.radii.sm};
  }
  &#order-materials-tabs > .ant-tabs-nav::before {
    border: none;
  }
`

export const OrderMaterialTabs = ({
  onAdd,
  onRemove,
  onDuplicate,
  deliveriesCount,
  disabled,
  hideAdd,
  ...props
}: OrderMaterialTabsProps) => {
  const [activeKey, setActiveKey] = useState('0')

  const handleEdit = (targetKey: string, action: 'add' | 'remove') => {
    if (disabled) return null

    if (action === 'add') {
      onAdd?.()
      setActiveKey(String(deliveriesCount))
    } else {
      Modal.confirm({
        title: 'Are you sure you want to remove this delivery?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          onRemove?.(targetKey)
          if (activeKey === targetKey && activeKey !== '0') {
            const newKey = parseInt(activeKey, 10) - 1
            setActiveKey(String(newKey))
          }
        },
      })
    }
  }

  const handleDuplicate = (targetKey: string) => {
    onDuplicate?.(targetKey)
    setActiveKey(String(deliveriesCount))
  }

  const items = [
    {
      label: 'New blank delivery',
      key: 'new-blank-delivery',
      onClick: () => handleEdit(String(deliveriesCount), 'add'),
    },
    ...props?.items?.map((item) => ({
      label: `Duplicate ${item['title']}`,
      key: item.key,
      onClick: () => handleDuplicate(item.key),
    })),
  ]

  const tabBarExtraContent = hideAdd ? undefined : (
    <Dropdown menu={{ items }} trigger={['click']} disabled={disabled}>
      <button className="ant-tabs-nav-add" style={{ height: 48 }} disabled={disabled}>
        <PlusOutlined />
      </button>
    </Dropdown>
  )

  return (
    <TabsStyled
      id="order-materials-tabs"
      type="editable-card"
      onEdit={handleEdit}
      onChange={setActiveKey}
      activeKey={activeKey}
      tabBarExtraContent={{ left: tabBarExtraContent }}
      hideAdd
      {...props}
    />
  )
}

OrderMaterialTabs.TabPaneTitle = ({ isPickUp = false, title, delivery = null }) => (
  <Box display="inline-flex" alignItems="center">
    {isPickUp ? (
      <ShopFilled style={{ marginRight: '8px', fontSize: 20 }} />
    ) : (
      <LocalShipping size="20" style={{ marginRight: '8px' }} />
    )}
    {title}

    {!!delivery && (
      <Box ml={20}>
        <VendorDeliveryInfo delivery={delivery} />
      </Box>
    )}
  </Box>
)
