import React, { useCallback } from 'react'

import { useHistory } from 'react-router-dom'

import { Modal, Button, Space } from 'antd'

import { Box } from 'common/components/boxes'

interface UnloadWarningProps {
  showWarning: () => boolean
  onSaveAndLeave?: () => Promise<void>
  isSubmitting?: boolean
  hideLeaveAndSave?: boolean
}

const Footer = ({ onSaveAndLeave, onCancel, onLeave, isSubmitting }) => {
  if (onSaveAndLeave) {
    return (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Button onClick={onCancel} disabled={isSubmitting}>
          Cancel
        </Button>
        <Space>
          <Button onClick={onLeave} disabled={isSubmitting}>
            Leave
          </Button>
          {onSaveAndLeave && (
            <Button onClick={onSaveAndLeave} loading={isSubmitting} type="primary">
              Save and Leave
            </Button>
          )}
        </Space>
      </Box>
    )
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onLeave} type="primary">
        Leave Page
      </Button>
    </Box>
  )
}

// Warn user if they are leaving the page
const UnloadWarning = ({ showWarning, onSaveAndLeave, isSubmitting }: UnloadWarningProps) => {
  const history = useHistory()

  const [nextHref, setNextHref] = React.useState(null)
  const [followingLink, setFollowingLink] = React.useState(false)

  const maybeWarnUserLeavingPage = useCallback(
    (e) => {
      if (!showWarning() || followingLink) {
        return undefined
      }
      const confirmationMessage =
        'It looks like you have been editing something. ' + 'If you leave before saving, your changes will be lost.'
      ;(e || window.event).returnValue = confirmationMessage //Gecko + IE
      return confirmationMessage //Gecko + Webkit, Safari, Chrome etc.
    },
    [followingLink, showWarning],
  )

  const maybeWarnUserClickedLink = useCallback(
    (e) => {
      if (
        showWarning() &&
        e?.target?.['nodeName'] == 'A' &&
        e.target?.['target'] !== '_blank' &&
        !e.target?.['download'] &&
        !!e?.target?.['href'] &&
        !e?.ctrlKey &&
        !e?.metaKey
      ) {
        e.preventDefault()
        setNextHref(e.target['href'])
      }
    },
    [showWarning],
  )

  React.useEffect(() => {
    // Set an even listener that will warn the user with default message if exit window entirely
    window.addEventListener('beforeunload', maybeWarnUserLeavingPage)
    // Warn the user if exiting the page via link click
    document.body.addEventListener('click', maybeWarnUserClickedLink)

    return () => {
      window.removeEventListener('beforeunload', maybeWarnUserLeavingPage)
      document.body.removeEventListener('click', maybeWarnUserClickedLink)
    }
  }, [followingLink, maybeWarnUserLeavingPage, maybeWarnUserClickedLink])

  // Need to make sure we know we are following the link otherwise the second window level event listener will trigger
  React.useEffect(() => {
    if (nextHref !== null && followingLink) {
      const url = new URL(nextHref)

      if (url.host.includes('localhost') || url.host.includes('subbase')) {
        history.push({ pathname: url.pathname, search: url.search })
      } else {
        window.location.href = nextHref
      }
    }
  }, [followingLink, nextHref])

  return (
    <Modal
      title="Warning!"
      open={nextHref !== null}
      onCancel={() => setNextHref(null)}
      footer={
        <Footer
          isSubmitting={isSubmitting}
          onCancel={() => setNextHref(null)}
          onLeave={() => setFollowingLink(true)}
          onSaveAndLeave={onSaveAndLeave ? () => onSaveAndLeave().then(() => setFollowingLink(true)) : undefined}
        />
      }
    >
      You may have unsaved changes, are you sure you want to leave?
    </Modal>
  )
}

export default UnloadWarning
