import React from 'react'

import { LeftCircleFilled, EditOutlined } from '@ant-design/icons'
import { Select, Typography, Tooltip } from 'antd'

import { Box } from 'common/components/boxes'
import { formatName } from 'common/helpers/formatters'
import { useQuery } from 'common/hooks/use-query'
import { useTheme } from 'common/hooks/use-theme'
import { InvoicesStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

export type ActionStatusProps = {
  disabled: boolean
  stateId: string
  changeStatus: (stateId: string) => void
  toggleRejectionReasonModal: React.Dispatch<React.SetStateAction<boolean>>
  canPostInvoices: boolean
}

export const ActionStatus = ({
  disabled,
  stateId,
  changeStatus,
  toggleRejectionReasonModal,
  canPostInvoices,
}: ActionStatusProps) => {
  const { companySettingStore, invoiceStateStore, invoiceStore } = useStores()

  const theme = useTheme()

  const invoice = invoiceStore.invoice

  useQuery(companySettingStore.indexUsers)
  const { isLoading } = useQuery(invoiceStateStore.getAllInvoiceStates)

  const invoiceStates = invoiceStateStore.invoiceStates?.map((state) => {
    if (state.assigned_to_company_user_id) {
      return {
        ...state,
        assignee: companySettingStore.users.find((user) => user.company_user_id === state.assigned_to_company_user_id),
      }
    }

    return {
      ...state,
      assignee: null,
    }
  })

  const rejectedState = invoiceStateStore.invoiceStates?.find(({ state }) => state === InvoicesStates.REJECTED)

  const currentState = invoice?.state

  return (
    <Box width={200} display="flex" flexDirection="column">
      <Select
        aria-label="invoice-status-select"
        style={{ width: '100%' }}
        placeholder="Status"
        optionFilterProp="children"
        disabled={disabled}
        onSelect={changeStatus}
        value={stateId}
        loading={isLoading}
      >
        {invoiceStates.map((state) => {
          const assignee = state.assignee?.first_name
            ? formatName(state.assignee?.first_name, state.assignee?.last_name)
            : state.assignee?.email

          const cannotPostInvoices = !canPostInvoices && state.state === InvoicesStates.POSTED
          const disabled =
            ![...currentState?.allowed_next_state_ids, invoice?.state?.id].includes(state?.id) || cannotPostInvoices
          const color = disabled ? theme.colors['gray-3'] : theme.colors[`${state?.color || 'gray'}-6`]

          return (
            <Select.Option key={state?.label} value={state?.id} disabled={disabled}>
              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Typography.Text ellipsis type={disabled ? 'secondary' : undefined}>
                  {state?.label}
                </Typography.Text>
                <LeftCircleFilled
                  style={{
                    color,
                    borderColor: theme.colors['gray-6'],
                    border: '1px solid',
                    borderRadius: 8,
                    marginLeft: 7,
                  }}
                />
              </Box>

              {state.assignee && invoice?.state?.id !== state.id && (
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  {`assigns to ${assignee}`}
                </Typography.Text>
              )}
            </Select.Option>
          )
        })}
      </Select>

      {rejectedState?.id === stateId && !!invoice?.rejection_reason && (
        <Tooltip title="Edit Reject Reason" arrowPointAtCenter placement="bottom">
          <Box
            onClick={() => toggleRejectionReasonModal(true)}
            style={{ cursor: 'pointer', gap: 8 }}
            alignItems="center"
            display="flex"
          >
            <Typography.Text ellipsis type="secondary">
              {invoice?.rejection_reason}
            </Typography.Text>
            <EditOutlined style={{ color: theme.colors.highlightBlue }} />
          </Box>
        </Tooltip>
      )}
    </Box>
  )
}
