import React, { useState } from 'react'

import EditOutlinedIcon from '@ant-design/icons/EditOutlined'
import { Button } from 'antd'

interface SelectProjectGroupOptionProps {
  children: string
  handleEdit: () => void
}

export const SelectProjectGroupOption = ({ children, handleEdit }: SelectProjectGroupOptionProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    handleEdit()
  }

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: 24 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div title={children}>{children}</div>
      {isHovered && (
        <Button type="ghost" size="small" onClick={handleClick}>
          <EditOutlinedIcon />
        </Button>
      )}
    </div>
  )
}
