import { createConsumer } from '@rails/actioncable'

import { WebSocketMessage } from '../server/server_types'

const ORDER_CHANNEL = 'ApplicationCable::OrderChannel'

type OrderChannelType = 'VENDOR' | 'CONTRACTOR'

export type OrderChannelMessage = {
  order_released?: boolean
  order_blocked?: boolean
  order_blocked_manually?: boolean
  order_will_expire?: boolean
  user: { id: string; name: string }
}

const getActionCableUrl = () => document.querySelector('meta[name="action-cable-url"]')['content']

let consumer = null

export const subscribeOrderChannel = (
  callback: (message: WebSocketMessage) => void,
  type: OrderChannelType,
  order_id: string,
) => {
  if (!consumer) {
    consumer = createConsumer(`${getActionCableUrl()}?type=${type}`)
  }

  const subscription = consumer.subscriptions.create(
    {
      channel: ORDER_CHANNEL,
      entity_id: order_id,
    },
    {
      received(message) {
        callback(message)
      },
      connected() {
        console.log('connected on order with params:', order_id)
      },
      disconnected() {
        console.log('disconnected on order with params:', order_id)
      },
    },
  )

  return {
    isConnected: () => consumer?.connection?.isOpen() ?? false,
    keepalive: () => {
      if (subscription && consumer?.connection?.isOpen()) {
        subscription.perform('keepalive')
      }
    },
    unsubscribe: () => {
      consumer.subscriptions.remove(subscription)
    },
  }
}
