import React from 'react'

import { Alert, Button } from 'antd'

type WithdrawCancelationProps = {
  cancellationRequestedReason?: string
  cancellationRejectedAt?: string
  cancellationAcceptedAt?: string
  cancellationRequestedAt?: string
  onClick: React.MouseEventHandler<HTMLElement>
  isSubmitting?: boolean
}

export const WithdrawCancelation = ({
  cancellationRequestedReason,
  cancellationRequestedAt,
  cancellationRejectedAt,
  cancellationAcceptedAt,
  onClick,
  isSubmitting,
}: WithdrawCancelationProps) => {
  if (!cancellationRequestedAt || !!cancellationRejectedAt || !!cancellationAcceptedAt) {
    return null
  }

  return (
    <Alert
      style={{ marginBottom: 16 }}
      message="Request for cancellation"
      description={cancellationRequestedReason || 'Unknown reason'}
      type="error"
      action={
        <Button danger onClick={onClick} loading={isSubmitting}>
          Withdraw Request
        </Button>
      }
    />
  )
}
