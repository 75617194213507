export const mockedUserStoreCurrentUser = {
  id: '123',
  first_name: 'John',
  last_name: 'Doe',
  impersonating: false,
}

export const mockedUserStoreCanUnlockOrder = true

export const mockedUserStore = {
  currentUser: mockedUserStoreCurrentUser,
  canUnlockOrder: mockedUserStoreCanUnlockOrder,
}
