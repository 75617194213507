import React, { useRef } from 'react'

import { Button, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { Page } from 'common/components/Page'
import { useQuery } from 'common/hooks/use-query'
import { Address } from 'common/server/addresses'

import { AddressDrawer, AddressTable } from 'contractor/components/Addresses'
import { useStores } from 'contractor/hooks/use-stores'

const Addresses = observer(() => {
  const { companySettingStore, userStore, addressStore } = useStores()

  const drawerCreateAddressRef = useRef<DrawerRef>()

  useQuery(companySettingStore.indexAddresses)

  const handleAddressSaveAsNew = async (address: Omit<Address, 'id'>, currentAddress: Address) => {
    await Promise.all([
      companySettingStore.updateAddress({ ...currentAddress, main_address: false, active: false }),
      companySettingStore.addAddress(userStore.currentUser.company_id, address),
    ])

    addressStore.indexAddresses()
  }

  const handleCreateAddress = async (address: Address) => {
    await companySettingStore.addAddress(userStore.currentUser.company_id, address)
    addressStore.indexAddresses()
  }

  return (
    <>
      <Page.Header>
        <FlexBoxX width="100%" justifyContent="space-between">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Addresses
          </Typography.Title>

          <Button type="primary" onClick={() => drawerCreateAddressRef.current?.show()}>
            Add Company Address
          </Button>
        </FlexBoxX>
      </Page.Header>
      <Page.Content>
        <AddressTable
          addresses={companySettingStore.addresses}
          onSubmit={companySettingStore.updateAddress}
          onSaveAsNew={handleAddressSaveAsNew}
          hideSelect
          showMainOfficeLabel
        />
      </Page.Content>
      <AddressDrawer
        ref={drawerCreateAddressRef}
        onClose={() => drawerCreateAddressRef.current?.close()}
        onSubmit={handleCreateAddress}
        hideSelect
        showMainOfficeLabel
      />
    </>
  )
})

export default Addresses
