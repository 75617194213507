import React, { forwardRef } from 'react'

import { InputNumber } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'

import { inputCurrencyFormatter, inputCurrencyParser } from 'common/helpers/formatters'

export type InputCurrencyProps = InputNumberProps

export const InputCurrency = forwardRef<HTMLInputElement, InputNumberProps>((props, ref) => {
  return (
    <InputNumber
      formatter={inputCurrencyFormatter}
      parser={inputCurrencyParser}
      inputMode="decimal"
      ref={ref}
      aria-autocomplete="none"
      onFocus={() => {
        // Clear the input when the user touches it and the value is zero.
        // It solves a problem when the user tries to input a new value when the input has a current value equal to zero.
        if (!props?.value) {
          props?.onChange(null)
        }
      }}
      {...props}
      style={{ width: '100%', ...props?.style }}
    />
  )
})
