import React from 'react'

import { Tag, Tooltip } from 'antd'

import { Box } from 'common/components/boxes'
import { CostCode } from 'common/server/cost_codes/cost_codes'

import { SelectCostCodeProps } from './select_cost_code'

type LabelProps = {
  costCode: CostCode
} & Pick<SelectCostCodeProps, 'costCodeSettings'>

const style = { margin: 0, padding: '0 4px' }

export const Label = ({ costCode, costCodeSettings }: LabelProps) => {
  if (!costCodeSettings?.class_enabled && !costCodeSettings?.phase_code_enabled) {
    return <Tag style={{ margin: 0 }}>{costCode.code}</Tag>
  }

  const canShowPhaseCode = costCodeSettings?.phase_code_enabled && !costCodeSettings.independent_phase_codes_enabled

  const canShowClassCode = costCodeSettings?.class_enabled

  return (
    <Tooltip title={costCode.description}>
      <Box>
        {canShowPhaseCode && (
          <>
            <Tag style={style}>{costCode.phase_code || 'N/A'}</Tag>
            {' / '}
          </>
        )}
        <>
          <Tag style={style}>{costCode.code}</Tag>
        </>
        {canShowClassCode && (
          <>
            {' / '}
            <Tag style={style}>{costCode.clazz || 'N/A'}</Tag>
          </>
        )}
      </Box>
    </Tooltip>
  )
}
