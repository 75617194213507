import { v4 as uuidV4 } from 'uuid'

import { InvoiceMaterialChange } from 'common/server/invoice_history'

export const ALLOW_INVOICE_MATERIALS_FIELDS = [
  'Description',
  'Quantity shipped',
  'Uom',
  'Unit cost',
  'Extended price',
  'Cost code',
  'Cost code phase',
  'Deleted at',
  'Retainage type',
  'Retainage amount',
  'Retainage percentage',
  'Discarded at',
]

export const makeTableRow = (invoiceMaterial: InvoiceMaterialChange) => ({
  descriptionUnchanged: invoiceMaterial?.snapshot['Description'],
  description: invoiceMaterial?.changes?.['Description'],

  costCodeUnchanged: invoiceMaterial?.snapshot['Cost code'],
  costCode: invoiceMaterial?.changes?.['Cost code'],
  costCodePhaseUnchanged: invoiceMaterial?.snapshot['Cost code phase'],
  costCodePhase: invoiceMaterial?.changes?.['Cost code phase'],

  unitUnchanged: invoiceMaterial?.snapshot['Unit'] || invoiceMaterial?.snapshot['Uom'],
  unit: invoiceMaterial?.changes?.['Unit'] || invoiceMaterial?.changes?.['Uom'],

  quantityShippedUnchanged: invoiceMaterial?.snapshot['Quantity shipped'],
  quantityShipped: invoiceMaterial?.changes?.['Quantity shipped'],

  unitCostUnchanged: invoiceMaterial?.snapshot['Unit price'],
  unitCost: invoiceMaterial?.changes?.['Unit price'],

  extendedPriceUnchanged: invoiceMaterial?.snapshot['Extended price'],
  extendedPrice: invoiceMaterial?.changes?.['Extended price'],

  retainageTypeUnchanged: invoiceMaterial?.snapshot['Retainage type'],
  retainageType: invoiceMaterial?.changes?.['Retainage type'],
  retainageAmountUnchanged: invoiceMaterial?.snapshot['Retainage amount'],
  retainageAmount: invoiceMaterial?.changes?.['Retainage amount'],
  retainagePercentageUnchanged: invoiceMaterial?.snapshot['Retainage percentage'],
  retainagePercentage: invoiceMaterial?.changes?.['Retainage percentage'],

  invoiceOrderMaterials: invoiceMaterial?.invoice_order_materials,

  discardedAt: invoiceMaterial?.changes?.['Discarded at'],
  event: invoiceMaterial?.event,

  key: uuidV4(),
})

export const filterAllowedChanges = (invoiceMaterial: InvoiceMaterialChange) => {
  return (
    Object.keys(invoiceMaterial?.changes).some((key) => ALLOW_INVOICE_MATERIALS_FIELDS.includes(key)) ||
    !!invoiceMaterial?.invoice_order_materials?.length
  )
}
