import React from 'react'

import { ForwardOutlined } from '@ant-design/icons'
import { Typography, Tooltip } from 'antd'

import { OrdersList } from 'common/components/OrdersList'
import { OrderStates } from 'common/server/server_types'

type Props = {
  orderedBy?: string
  quickCreated?: boolean
  state: OrderStates
}

const OPACITY_STATES = [OrderStates.CANCELLED, OrderStates.DRAFT, OrderStates.REQUESTED, OrderStates.QUOTED]

export function CellOrderedBy(props: Props) {
  const { orderedBy, quickCreated, state } = props

  if (!orderedBy) {
    return <OrdersList.Cell.NoContent />
  }

  const opacity = OPACITY_STATES.includes(state) ? { opacity: 0.3 } : {}

  return (
    <Typography.Text
      style={{
        display: 'flex',
        alignItems: 'center',
        ...opacity,
      }}
    >
      {quickCreated && (
        <Tooltip title="This PO was Quick Created during Invoice Approval">
          <ForwardOutlined style={{ fontSize: 20, marginRight: 4 }} />
        </Tooltip>
      )}
      <span>{orderedBy}</span>
    </Typography.Text>
  )
}
