import React from 'react'

import { Button, Modal, Space } from 'antd'

import { observer } from 'mobx-react-lite'

import { Visibility } from 'common/components/Visibility'
import { noticeError } from 'common/helpers/new_relic'

import { Flatfile } from 'contractor/components/Flatfile'
import { useStores } from 'contractor/hooks/use-stores'

import { HeaderProps } from './header'

type ActionspProps = HeaderProps

export const Actions = observer<ActionspProps>(({ onAdd }) => {
  const { costCodeStore, flatfileStore, integrationStore } = useStores()

  const { costCodePhaseListStore } = costCodeStore

  const procore = integrationStore.isProcore()
  const disabledImport = procore && integrationStore.accountingIntegration?.importing_sub_jobs

  // Show a modal warning the user that importing from Procore can take some time
  const handleImportFromProcore = () => {
    integrationStore
      .importSubJobs()
      .then(() => {
        integrationStore.accountingInfo()
        Modal.info({
          title: 'Importing from Procore',
          content: (
            <span>
              Please hold while we import the data from Procore. <br />
              This process can take a few minutes depending on how many Sub Jobs you have on Procore.
            </span>
          ),
        })
      })
      .catch((error) => {
        noticeError(error, { entry: 'import-sub-jobs' })
      })
  }

  return (
    <Space size="middle">
      {procore && (
        <Button type="primary" onClick={handleImportFromProcore} disabled={disabledImport}>
          Import from Procore
        </Button>
      )}

      <Visibility.Hidden breakpoint="md">
        <Flatfile onSuccess={costCodePhaseListStore.fetchRecords} openUpload={flatfileStore.openUploadPhaseCodes} />
      </Visibility.Hidden>

      <Button type="primary" onClick={onAdd}>
        Add
      </Button>
    </Space>
  )
})
