import React from 'react'

import { FloatActionsWrapper } from 'common/components/FloatActionsWrapper'

import { DropdownActions } from './dropdown_actions'
import { HeaderProps } from './header'
import { UpdateButton } from './update_button'

type FloatActionsProps = Pick<
  HeaderProps,
  'isCorrelating' | 'isSubmitting' | 'saveAndNextDefault' | 'onSaveInvoice' | 'onSaveInvoiceAndNext'
>

export const FloatActions = (props: FloatActionsProps) => {
  const { isCorrelating, isSubmitting, saveAndNextDefault, onSaveInvoice, onSaveInvoiceAndNext } = props

  return (
    <FloatActionsWrapper>
      <UpdateButton
        isCorrelating={isCorrelating}
        onSaveInvoice={onSaveInvoice}
        onSaveInvoiceAndNext={onSaveInvoiceAndNext}
        isSubmitting={isSubmitting}
        saveAndNextDefault={saveAndNextDefault}
      />
      <DropdownActions />
    </FloatActionsWrapper>
  )
}
