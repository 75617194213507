import React from 'react'

import styled from '@emotion/styled'

import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { Avatar, Card, Switch, Tooltip, Typography } from 'antd'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'

const CardStyled = styled(Card)`
  width: 100%;
  height: 120px;
  border-radius: 4px;

  & .ant-card-body {
    padding: 16px;
    height: 100%;
    display: flex;
  }
`

type CompanyVendorContactCardProps = {
  name: string
  nickname?: string
  headline?: string
  email: string
  phone?: string
  isActive: boolean
  id: string
  onChange?: (checked: boolean, id: string) => void
  onClick?: (id: string) => void
}

export const CompanyVendorContactCard = ({
  name,
  nickname,
  headline,
  email,
  phone,
  isActive,
  id,
  onChange,
  onClick,
}: CompanyVendorContactCardProps) => {
  return (
    <CardStyled hoverable onClick={() => onClick?.(id)}>
      {!!name && (
        <Box width="55px">
          <Avatar size={55} style={{ backgroundColor: '#505F8D', fontSize: 20, lineHeight: 50 }}>
            {name[0]}
          </Avatar>
        </Box>
      )}

      <FlexBoxY ml="12px" alignItems="flex-start" minWidth="0">
        <FlexBoxX mb="8px" width="100%" justifyContent="space-between" flexGrow={0}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {`${name} ${nickname ? `(${nickname})` : ''}`}
          </Typography.Title>
          {onChange && (
            <Switch
              checked={isActive}
              checkedChildren="ACTIVE"
              unCheckedChildren="INACTIVE"
              onChange={(checked) => onChange(checked, id)}
              onClick={(_, event) => event.stopPropagation()}
            />
          )}
        </FlexBoxX>

        <FlexBoxX mb="8px" width="100%" justifyContent="space-between" flexGrow={0}>
          <Typography.Text>
            <MailOutlined style={{ marginRight: 4 }} /> {email}
          </Typography.Text>
          {!!phone && (
            <Typography.Text>
              <PhoneOutlined style={{ marginRight: 4 }} /> {phone}
            </Typography.Text>
          )}
        </FlexBoxX>

        <Tooltip title={headline}>
          <Typography.Paragraph type="secondary" ellipsis style={{ margin: 0, maxWidth: '100%' }}>
            {headline}
          </Typography.Paragraph>
        </Tooltip>
      </FlexBoxY>
    </CardStyled>
  )
}
