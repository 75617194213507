import { Comment } from 'common/server/comment'
import { CompanyMaterial } from 'common/server/company_materials'
import { CostCodeClass } from 'common/server/cost_codes/cost_code_classes'
import { CostCodeNumber } from 'common/server/cost_codes/cost_code_numbers'
import { CostCodePhase } from 'common/server/cost_codes/cost_code_phases'
import { CostCode } from 'common/server/cost_codes/cost_codes'
import { OrderDelivery } from 'common/server/deliveries'
import { InvoiceMaterial } from 'common/server/invoice'
import {
  File,
  OrderAuditLogStates,
  OrderStates,
  OrderStatesFilter,
  OrderSubStates,
  RelationshipStockStatus,
  VendorResponseType,
} from 'common/server/server_types'
import { Unit } from 'common/server/units'

import { BasicRelationship } from 'contractor/server/integrations'

export interface ShowOrderResponse {
  id: string
  order_number: string
  order_package_name: string
  company_material_columns: string[]
  vendor_contact_id: string
  project: { name: string }
  state: OrderStates
  sub_state: OrderSubStates
  confirmed_at: string
  user: {
    id: string
    full_name: string
    company_name: string
  }
  requested_by: {
    id: string
    full_name: string
    company_name: string
  }
  company_vendor: {
    id: string
    vendor_name: string
    vendor?: {
      id: string
      name: string
    }
  }
  vendor_contacts: {
    id: string
    first_name: string
    last_name: string
    email: string
    vendor_user?: {
      id: string
      first_name: string
      last_name: string
      email: string
    }
  }[]
  order_materials: OrderMaterial[]
  deliveries: OrderDelivery[]
  purchaser_files: File[]
  vendor_files: File[]
  purchase_order?: File
  quote?: File[]
  comments: Comment[]
}

interface AgaveExternalId {
  external_relationships: Nullable<BasicRelationship[]>
}

export interface CostCodePhaseWithAgaveExternalId extends CostCodePhase, AgaveExternalId {}

export interface CostCodeClassWithAgaveExternalId extends CostCodeClass, AgaveExternalId {}

export interface CostCodeNumbersWithAgaveExternalId extends CostCodeNumber, AgaveExternalId {}

export interface CostCodeWithAgaveExternalId
  extends Omit<CostCode, 'cost_code_phase' | 'cost_code_class' | 'cost_code_number'>,
    AgaveExternalId {
  cost_code_phase?: CostCodePhaseWithAgaveExternalId
  cost_code_class?: CostCodeClassWithAgaveExternalId
  cost_code_number?: CostCodeNumbersWithAgaveExternalId
}

interface InvoiceOrderMaterial extends Omit<InvoiceMaterial, 'order_materials'> {
  invoice_id: string
  invoice_number: string
}

export enum DeliveryIssuesType {
  MISSING = 'MISSING',
  BROKEN = 'BROKEN',
  INCORRECT = 'INCORRECT',
  BACKORDERED = 'BACKORDERED',
}

export interface OrderMaterial {
  resolved_at?: string
  resolved?: boolean
  id?: string
  created_at: string
  has_open_issue: boolean
  expected_back_ordered_date: string
  delivery_issues: DeliveryIssuesType[]
  remaining_quantity?: number
  quantity_delivered?: number
  quantity: Nullable<number>
  unit_cost: Nullable<string> | number
  delivery_id: string
  order_id: string
  company_material: CompanyMaterial
  company_material_id?: string
  vendor_note: string
  company_note?: string
  discarded_at?: string
  cost_code_id?: string
  cost_code?: CostCodeWithAgaveExternalId
  cost_code_phase_id?: string
  cost_code_phase?: CostCodePhaseWithAgaveExternalId
  vendor_response?: VendorResponseType[]
  tax_value?: number
  unique_id?: string
  ext_cost?: number

  // Used to filter order materials
  searchable_description?: string

  // Used to split order, if select is true this material will be added to the new order
  select?: boolean

  // Used to link order material with invoice material
  invoice_materials: InvoiceOrderMaterial[]

  // invoice remaining control
  remaining_quantity_to_invoice?: number
  is_invoiced?: boolean
}

export interface OrderHit {
  objectID: string
  id: string
  created_at: string
  updated_at: string
  is_quick_created: boolean
  order_number: string
  quote_number?: string
  state: OrderStates
  contractor_orders_filter: OrderStatesFilter
  sub_state: OrderSubStates
  subtotal_before_tax: string
  confirmed_at: string
  order_package_id: string
  user_id: string
  name: string
  last_user_action_at: string | null
  last_vendor_action_at: string | null
  user: { id: string; first_name: string; last_name: string; email: string }
  project_id: string
  project_identifier?: string
  project_name: string
  project_group_name?: string
  company_vendor: { id: string; safe_globalized_vendor_name: string; external_vendor_id?: string }
  company_vendor_name?: string
  vendor: { id: string; name: string }
  vendor_response: VendorResponseType[]
  company_id: string
  company: { id: string; name: string }
  ordered_at: string
  quoted_at?: string
  cancelled_at: string
  requested_at: string
  lead_time_days: number
  must_receive_by: string
  must_receive_by_timestamp: number
  lead_time_cutoff_date: string
  lead_time_cutoff_date_timestamp: number
  deliveries: OrderHitDelivery[]
  order_materials: OrderHitMaterial[]
  has_open_delivery_issue: boolean
  tags: string[]
  order_audit_log: OrderAuditLogStates
  materials_count: number
  grand_total: string
  is_draft: boolean
  is_fully_invoiced?: boolean
  draft_vendors_names: string[]
  is_requested?: boolean
  requested_by?: string
  draft_vendor_names?: string[]
  last_action: {
    created_at: string
    author_type: 'User' | 'VendorUser' | 'CompanyVendorContact' | 'SubBase'
    author_name: string
  }
  ordered_by_column: string
  agave_sync_status: RelationshipStockStatus
}

export interface OrderHitDelivery {
  id: string
  state: string
  requested_delivered_at: string
  shipped_at: string
  actual_delivered_at: string
  carrier: string
  tracking_number: string
  estimated_delivered_at: string
  best_guess_delivered_at: string
  marked_delivered_at: string
  delivery_issue_type: string
  address: { id: string; nickname: string; address_line_1: string }
  has_open_issue: boolean
  delivery_files: File[]
  is_pick_up: boolean
  tax_value?: number
}

export interface OrderHitMaterial {
  id: string
  quantity: number
  unit_cost: string
  cost_code?: {
    id: string
    code: string
    clazz: string
    phase_code: string
    project_ids: string[]
  }
  company_material: {
    id: string
    description: string
    unit?: Unit
    unit_name?: string
    unit_id?: string
  }
}
