import React, { useState } from 'react'

import UserOutlined from '@ant-design/icons/UserOutlined'
import { Alert, Button, Form, Input, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'
import { SignInTypes, LandingMethods } from 'common/pages/Landing'

import { Logo } from './logo'

type ForgotPasswordProps = {
  setSignInType: (string) => void
  forgotPassword: LandingMethods['forgotPassword']
}

const ForgotPassword = ({ setSignInType, forgotPassword }: ForgotPasswordProps) => {
  const [resetMessage, setResetMessage] = useState([])
  const [form] = Form.useForm()

  const onValuesChange = (changedValues) => {
    if (changedValues?.email) form.setFieldsValue({ email: changedValues.email.toLowerCase().trim() })
  }

  const onFinish = async ({ email }): Promise<void> => {
    try {
      await forgotPassword(email)
      setResetMessage(['success', 'Please check your email for a link to reset your password'])
    } catch (err) {
      if (err?.response?.status === 404) {
        setResetMessage(['error', `No account found with this email address.`])
      } else {
        setResetMessage([
          'error',
          `Unable to reset password for this email address. Please contact ${SUPPORT_EMAIL_ADDRESS} for further asssistance`,
        ])
      }
    }
  }

  return (
    <Box display="flex" flexDirection="column" width="100%" justifyContent="center" alignItems="flex-start">
      <Logo onClick={() => setSignInType(SignInTypes.SIGN_IN)} />

      <Form style={{ width: '100%' }} form={form} onFinish={onFinish} onValuesChange={onValuesChange} layout="vertical">
        <Form.Item
          name="email"
          label="Email"
          validateTrigger="onBlur"
          rules={[
            { required: true, message: 'Please input your Email!' },
            { type: 'email', message: 'The input is not valid E-mail!' },
          ]}
        >
          <Input data-cy="email" prefix={<UserOutlined />} placeholder="Email" />
        </Form.Item>

        <Form.Item>
          <Box display="flex" flexDirection="column">
            <Button data-cy="reset-password" block type="primary" htmlType="submit">
              Reset Password
            </Button>

            <Box display="flex" justifyContent="center" width="100%" mt={8}>
              <Typography.Text>
                Already have an account?{' '}
                <Typography.Link onClick={() => setSignInType(SignInTypes.SIGN_IN)}>Login</Typography.Link>
              </Typography.Text>
            </Box>
          </Box>
        </Form.Item>

        {resetMessage.length > 0 && (
          <Alert style={{ marginBottom: '20px' }} type={resetMessage[0]} message={resetMessage[1]} />
        )}
      </Form>
    </Box>
  )
}

export default ForgotPassword
