import React, { useEffect, useState } from 'react'

import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import { Divider, message, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { NotificationData } from 'common/server/user'

import { NotificationCard } from './notification_card'

interface NotificationMessagesProps {
  groupId: string
  groupUnread: boolean
  link: string
  closeDrawer: () => void
  notificationIds: string[]
  loadMessages: (groupId: string) => Promise<NotificationData[]>
  dismissMessage: (id: string) => Promise<void>
  dismissGroup: (id: string) => Promise<void>
}

export const Notifications: React.FC<NotificationMessagesProps> = ({
  groupId,
  notificationIds,
  loadMessages,
  dismissMessage,
  link,
  closeDrawer,
  groupUnread,
  dismissGroup,
}) => {
  const [messages, setMessages] = useState<NotificationData[]>([])
  const showLoadMore = messages?.some((msg) => msg.read) && messages?.some((msg) => !msg.read)
  const [onlyUnread, setOnlyUnread] = useState<boolean>(false)
  const filteredMessages = messages.filter((msg) => (onlyUnread ? !msg.read : true))

  useEffect(() => {
    loadMessages(groupId)
      .then(setMessages)
      .catch(() => message.error('Unable to get the notification updates'))
  }, [notificationIds, groupId, loadMessages])

  useEffect(() => {
    setOnlyUnread(groupUnread)
  }, [groupUnread])

  const handleDismiss = async (id: string) => {
    try {
      await dismissMessage(id)
      setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== id))
    } catch {
      message.error('Unable to dismiss the notification')
    }
  }

  const handleOpenNotification = () => {
    closeDrawer?.()
    window.open(link, '_blank')
    dismissGroup(groupId)
  }

  if (!messages.length) {
    return <LoadingOutlined style={{ fontSize: 16 }} spin />
  }

  return (
    <>
      <Divider type="horizontal" style={{ width: '100%', margin: 0, padding: 0, paddingTop: 4 }} />
      {filteredMessages.map((notification) => (
        <NotificationCard
          key={notification.id}
          notification={notification}
          onDismiss={handleDismiss}
          onOpen={handleOpenNotification}
        />
      ))}
      {showLoadMore && (
        <Box onClick={() => setOnlyUnread(!onlyUnread)}>
          <Typography.Text style={{ fontSize: 12, color: 'gray' }}>
            {onlyUnread ? 'Show read notifications' : 'Hide read notifications'}
          </Typography.Text>
        </Box>
      )}
    </>
  )
}
