import React, { useState } from 'react'

import { useHistory } from 'react-router'

import styled from '@emotion/styled'

import { Button, Form, Steps, Modal } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerBody, DrawerFooter, DrawerProps } from 'common/components/Drawer'
import { useQuery } from 'common/hooks/use-query'

import { Flatfile } from 'contractor/components/Flatfile'
import { useStores } from 'contractor/hooks/use-stores'

import { usePriceSheet } from './hooks'
import { InfoForm } from './info_form'

type PriceSheetCreateProps = {
  drawerProps?: Partial<DrawerProps>
}

const StepsStyled = styled(Steps)`
  & .ant-steps-item:first-of-type {
    cursor: pointer;
  }
`

export const PriceSheetCreate = observer<PriceSheetCreateProps>(({ drawerProps }) => {
  const { priceSheetStore, projectStore, flatfileStore } = useStores()
  const { isSubmitting, createdPriceSheet, createPriceSheet, destroyPriceSheet, clearCreatedPriceSheet } =
    usePriceSheet(priceSheetStore)

  useQuery(projectStore.maybeIndexProjects)

  const [currentStep, setCurrentStep] = useState(0)

  const [form] = Form.useForm()
  const history = useHistory()

  const nextStep = () => setCurrentStep((prevStep) => prevStep + 1)

  const maybeDestroyPriceSheet = () => {
    if (createdPriceSheet) {
      destroyPriceSheet(createdPriceSheet.id)
    }
  }

  const handleCancel = (event) => {
    form.resetFields()
    setCurrentStep(0)
    drawerProps?.onClose(event)
    maybeDestroyPriceSheet()
  }

  const handleCancelConfirmation = (event) => {
    Modal.confirm({
      title: 'You may have unsaved changes, are you sure you want to leave?',
      content: 'If you leave before saving, your changes will be lost.',
      onOk() {
        handleCancel(event)
      },
    })
  }

  const maybeCancelWithConfirmation = (event) => {
    if (currentStep === 1) {
      handleCancelConfirmation(event)
    } else {
      handleCancel(event)
    }
  }

  const handleFinish = () => {
    const createdPriceSheetId = createdPriceSheet?.id
    clearCreatedPriceSheet()
    priceSheetStore.listStore.fetchRecords()
    priceSheetStore.listStore.fetchFacets()
    form.resetFields()
    setCurrentStep(0)
    history.push(`/price_sheets/${createdPriceSheetId}`)
  }

  return (
    <Drawer title="Upload Price Sheet" width={460} {...drawerProps} onClose={maybeCancelWithConfirmation}>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={(formValues) =>
          createPriceSheet(
            {
              name: formValues.name,
              company_vendor_id: formValues.companyVendor?.value,
              project_id: formValues.projectId,
              number: formValues.number,
              start_at: formValues.startAt,
              end_at: formValues.endAt,
            },
            nextStep,
          )
        }
        style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <DrawerBody>
          <Box px={70} mt={13} mb={24}>
            <StepsStyled
              current={currentStep}
              items={[
                {
                  title: 'Info',
                  onClick: () => setCurrentStep(0),
                },
                {
                  title: 'Upload',
                },
              ]}
              style={{ marginBottom: 16 }}
            />
          </Box>

          {currentStep === 0 ? (
            <InfoForm />
          ) : (
            <Flatfile
              openUpload={() => {
                if (!createdPriceSheet) return
                return flatfileStore.openUploadPriceSheet(createdPriceSheet.id)
              }}
              icon="Click to upload a file."
              onSuccess={handleFinish}
            />
          )}
        </DrawerBody>

        <DrawerFooter>
          <Button onClick={maybeCancelWithConfirmation} style={{ width: '100px' }} disabled={isSubmitting}>
            Cancel
          </Button>

          {currentStep === 0 && (
            <Button htmlType="submit" type="primary" style={{ width: '100px' }} loading={isSubmitting}>
              Next
            </Button>
          )}
        </DrawerFooter>
      </Form>
    </Drawer>
  )
})
