// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`CompareOrders > table should render correct 1`] = `
<DocumentFragment>
  <div
    class="css-j66bv4"
  >
    <div
      class="ant-table ant-table-small  css-8atqhb"
    >
      <div
        class="ant-table-container css-0"
      >
        <div
          class="ant-table-content css-0"
        >
          <table
            id="RFQComparisonTable"
          >
            <thead
              class="ant-table-thead"
            >
              <tr>
                <th
                  class="ant-table-cell"
                  colspan="3"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <th
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="orders"
                  style="background-color: transparent; border-top: 1px solid rgba(0,0,0,.06); border-right: 1px solid rgba(0,0,0,.06);"
                >
                  <div
                    class="css-i80i99"
                  >
                    <div
                      class="css-p7o96u"
                    >
                      <a
                        data-testid="BLX-000347"
                        href="/order/49f76b57-4fa3-4ca3-9595-335a13630507"
                        target="_blank"
                      >
                        <span
                          aria-label="number"
                          class="anticon anticon-number"
                          role="img"
                        >
                          <svg
                            aria-hidden="true"
                            class=""
                            data-icon="number"
                            fill="currentColor"
                            focusable="false"
                            height="1em"
                            viewBox="64 64 896 896"
                            width="1em"
                          >
                            <path
                              d="M872 394c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8H708V152c0-4.4-3.6-8-8-8h-64c-4.4 0-8 3.6-8 8v166H400V152c0-4.4-3.6-8-8-8h-64c-4.4 0-8 3.6-8 8v166H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h168v236H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h168v166c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V706h228v166c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V706h164c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8H708V394h164zM628 630H400V394h228v236z"
                            />
                          </svg>
                        </span>
                        BLX-000347: 
                      </a>
                      Biz
                    </div>
                    <div
                      class="css-p7o96u"
                    >
                      <span
                        class="ant-badge"
                      >
                        <span
                          _css="[object Object]"
                          class="ant-tag ant-tag-gold order_state___StyledTag-m8e6se-0 eHggoA"
                        >
                          <div
                            class="css-p7o96u"
                          >
                            Quote Requested
                          </div>
                        </span>
                      </span>
                      <button
                        class="ant-btn ant-btn-sm ant-btn-icon-only"
                        style="background-color: transparent;"
                        type="button"
                      >
                        <span
                          aria-label="comment"
                          class="anticon anticon-comment"
                          role="img"
                        >
                          <svg
                            aria-hidden="true"
                            class=""
                            data-icon="comment"
                            fill="currentColor"
                            focusable="false"
                            height="1em"
                            viewBox="64 64 896 896"
                            width="1em"
                          >
                            <defs>
                              <style />
                            </defs>
                            <path
                              d="M573 421c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40zm-280 0c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40z"
                            />
                            <path
                              d="M894 345a343.92 343.92 0 00-189-130v.1c-17.1-19-36.4-36.5-58-52.1-163.7-119-393.5-82.7-513 81-96.3 133-92.2 311.9 6 439l.8 132.6c0 3.2.5 6.4 1.5 9.4a31.95 31.95 0 0040.1 20.9L309 806c33.5 11.9 68.1 18.7 102.5 20.6l-.5.4c89.1 64.9 205.9 84.4 313 49l127.1 41.4c3.2 1 6.5 1.6 9.9 1.6 17.7 0 32-14.3 32-32V753c88.1-119.6 90.4-284.9 1-408zM323 735l-12-5-99 31-1-104-8-9c-84.6-103.2-90.2-251.9-11-361 96.4-132.2 281.2-161.4 413-66 132.2 96.1 161.5 280.6 66 412-80.1 109.9-223.5 150.5-348 102zm505-17l-8 10 1 104-98-33-12 5c-56 20.8-115.7 22.5-171 7l-.2-.1A367.31 367.31 0 00729 676c76.4-105.3 88.8-237.6 44.4-350.4l.6.4c23 16.5 44.1 37.1 62 62 72.6 99.6 68.5 235.2-8 330z"
                            />
                            <path
                              d="M433 421c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </th>
                <th
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="orders"
                  style="background-color: transparent; border-top: 1px solid rgba(0,0,0,.06); border-right: 1px solid rgba(0,0,0,.06);"
                >
                  <div
                    class="css-i80i99"
                  >
                    <div
                      class="css-p7o96u"
                    >
                      <a
                        data-testid="BLX-000344"
                        href="/order/21748e89-7356-42e6-b7a9-8f5a18b9c9d8"
                        target="_blank"
                      >
                        <span
                          aria-label="number"
                          class="anticon anticon-number"
                          role="img"
                        >
                          <svg
                            aria-hidden="true"
                            class=""
                            data-icon="number"
                            fill="currentColor"
                            focusable="false"
                            height="1em"
                            viewBox="64 64 896 896"
                            width="1em"
                          >
                            <path
                              d="M872 394c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8H708V152c0-4.4-3.6-8-8-8h-64c-4.4 0-8 3.6-8 8v166H400V152c0-4.4-3.6-8-8-8h-64c-4.4 0-8 3.6-8 8v166H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h168v236H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h168v166c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V706h228v166c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V706h164c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8H708V394h164zM628 630H400V394h228v236z"
                            />
                          </svg>
                        </span>
                        BLX-000344: 
                      </a>
                      Halls Supplies
                    </div>
                    <div
                      class="css-p7o96u"
                    >
                      <span
                        class="ant-badge"
                      >
                        <span
                          _css="[object Object]"
                          class="ant-tag ant-tag-gold order_state___StyledTag-m8e6se-0 eHggoA"
                        >
                          <div
                            class="css-p7o96u"
                          >
                            Quote Requested
                          </div>
                        </span>
                      </span>
                      <button
                        class="ant-btn ant-btn-sm ant-btn-icon-only"
                        style="background-color: transparent;"
                        type="button"
                      >
                        <span
                          aria-label="comment"
                          class="anticon anticon-comment"
                          role="img"
                        >
                          <svg
                            aria-hidden="true"
                            class=""
                            data-icon="comment"
                            fill="currentColor"
                            focusable="false"
                            height="1em"
                            viewBox="64 64 896 896"
                            width="1em"
                          >
                            <defs>
                              <style />
                            </defs>
                            <path
                              d="M573 421c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40zm-280 0c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40z"
                            />
                            <path
                              d="M894 345a343.92 343.92 0 00-189-130v.1c-17.1-19-36.4-36.5-58-52.1-163.7-119-393.5-82.7-513 81-96.3 133-92.2 311.9 6 439l.8 132.6c0 3.2.5 6.4 1.5 9.4a31.95 31.95 0 0040.1 20.9L309 806c33.5 11.9 68.1 18.7 102.5 20.6l-.5.4c89.1 64.9 205.9 84.4 313 49l127.1 41.4c3.2 1 6.5 1.6 9.9 1.6 17.7 0 32-14.3 32-32V753c88.1-119.6 90.4-284.9 1-408zM323 735l-12-5-99 31-1-104-8-9c-84.6-103.2-90.2-251.9-11-361 96.4-132.2 281.2-161.4 413-66 132.2 96.1 161.5 280.6 66 412-80.1 109.9-223.5 150.5-348 102zm505-17l-8 10 1 104-98-33-12 5c-56 20.8-115.7 22.5-171 7l-.2-.1A367.31 367.31 0 00729 676c76.4-105.3 88.8-237.6 44.4-350.4l.6.4c23 16.5 44.1 37.1 62 62 72.6 99.6 68.5 235.2-8 330z"
                            />
                            <path
                              d="M433 421c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </th>
                <th
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="orders"
                  style="background-color: transparent; border-top: 1px solid rgba(0,0,0,.06); border-right: 1px solid rgba(0,0,0,.06);"
                >
                  <div
                    class="css-i80i99"
                  >
                    <div
                      class="css-p7o96u"
                    >
                      <a
                        data-testid="BLX-000345"
                        href="/order/acece0c2-a3be-486b-b4ad-d2c2bcc50455"
                        target="_blank"
                      >
                        <span
                          aria-label="number"
                          class="anticon anticon-number"
                          role="img"
                        >
                          <svg
                            aria-hidden="true"
                            class=""
                            data-icon="number"
                            fill="currentColor"
                            focusable="false"
                            height="1em"
                            viewBox="64 64 896 896"
                            width="1em"
                          >
                            <path
                              d="M872 394c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8H708V152c0-4.4-3.6-8-8-8h-64c-4.4 0-8 3.6-8 8v166H400V152c0-4.4-3.6-8-8-8h-64c-4.4 0-8 3.6-8 8v166H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h168v236H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h168v166c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V706h228v166c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V706h164c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8H708V394h164zM628 630H400V394h228v236z"
                            />
                          </svg>
                        </span>
                        BLX-000345: 
                      </a>
                      Lighting Today
                    </div>
                    <div
                      class="css-p7o96u"
                    >
                      <span
                        class="ant-badge"
                      >
                        <span
                          _css="[object Object]"
                          class="ant-tag ant-tag-gold order_state___StyledTag-m8e6se-0 eHggoA"
                        >
                          <div
                            class="css-p7o96u"
                          >
                            Quote Requested
                          </div>
                        </span>
                      </span>
                      <button
                        class="ant-btn ant-btn-sm ant-btn-icon-only"
                        style="background-color: transparent;"
                        type="button"
                      >
                        <span
                          aria-label="comment"
                          class="anticon anticon-comment"
                          role="img"
                        >
                          <svg
                            aria-hidden="true"
                            class=""
                            data-icon="comment"
                            fill="currentColor"
                            focusable="false"
                            height="1em"
                            viewBox="64 64 896 896"
                            width="1em"
                          >
                            <defs>
                              <style />
                            </defs>
                            <path
                              d="M573 421c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40zm-280 0c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40z"
                            />
                            <path
                              d="M894 345a343.92 343.92 0 00-189-130v.1c-17.1-19-36.4-36.5-58-52.1-163.7-119-393.5-82.7-513 81-96.3 133-92.2 311.9 6 439l.8 132.6c0 3.2.5 6.4 1.5 9.4a31.95 31.95 0 0040.1 20.9L309 806c33.5 11.9 68.1 18.7 102.5 20.6l-.5.4c89.1 64.9 205.9 84.4 313 49l127.1 41.4c3.2 1 6.5 1.6 9.9 1.6 17.7 0 32-14.3 32-32V753c88.1-119.6 90.4-284.9 1-408zM323 735l-12-5-99 31-1-104-8-9c-84.6-103.2-90.2-251.9-11-361 96.4-132.2 281.2-161.4 413-66 132.2 96.1 161.5 280.6 66 412-80.1 109.9-223.5 150.5-348 102zm505-17l-8 10 1 104-98-33-12 5c-56 20.8-115.7 22.5-171 7l-.2-.1A367.31 367.31 0 00729 676c76.4-105.3 88.8-237.6 44.4-350.4l.6.4c23 16.5 44.1 37.1 62 62 72.6 99.6 68.5 235.2-8 330z"
                            />
                            <path
                              d="M433 421c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </th>
                <th
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="orders"
                  style="background-color: transparent; border-top: 1px solid rgba(0,0,0,.06); border-right: 1px solid rgba(0,0,0,.06);"
                >
                  <div
                    class="css-i80i99"
                  >
                    <div
                      class="css-p7o96u"
                    >
                      <a
                        data-testid="BLX-000346"
                        href="/order/2e69b769-7b69-4531-88d3-5416c7b7ba68"
                        target="_blank"
                      >
                        <span
                          aria-label="number"
                          class="anticon anticon-number"
                          role="img"
                        >
                          <svg
                            aria-hidden="true"
                            class=""
                            data-icon="number"
                            fill="currentColor"
                            focusable="false"
                            height="1em"
                            viewBox="64 64 896 896"
                            width="1em"
                          >
                            <path
                              d="M872 394c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8H708V152c0-4.4-3.6-8-8-8h-64c-4.4 0-8 3.6-8 8v166H400V152c0-4.4-3.6-8-8-8h-64c-4.4 0-8 3.6-8 8v166H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h168v236H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h168v166c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V706h228v166c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V706h164c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8H708V394h164zM628 630H400V394h228v236z"
                            />
                          </svg>
                        </span>
                        BLX-000346: 
                      </a>
                      Pace Today
                    </div>
                    <div
                      class="css-p7o96u"
                    >
                      <span
                        class="ant-badge"
                      >
                        <span
                          _css="[object Object]"
                          class="ant-tag ant-tag-gold order_state___StyledTag-m8e6se-0 eHggoA"
                        >
                          <div
                            class="css-p7o96u"
                          >
                            Quote Requested
                          </div>
                        </span>
                      </span>
                      <button
                        class="ant-btn ant-btn-sm ant-btn-icon-only"
                        style="background-color: transparent;"
                        type="button"
                      >
                        <span
                          aria-label="comment"
                          class="anticon anticon-comment"
                          role="img"
                        >
                          <svg
                            aria-hidden="true"
                            class=""
                            data-icon="comment"
                            fill="currentColor"
                            focusable="false"
                            height="1em"
                            viewBox="64 64 896 896"
                            width="1em"
                          >
                            <defs>
                              <style />
                            </defs>
                            <path
                              d="M573 421c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40zm-280 0c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40z"
                            />
                            <path
                              d="M894 345a343.92 343.92 0 00-189-130v.1c-17.1-19-36.4-36.5-58-52.1-163.7-119-393.5-82.7-513 81-96.3 133-92.2 311.9 6 439l.8 132.6c0 3.2.5 6.4 1.5 9.4a31.95 31.95 0 0040.1 20.9L309 806c33.5 11.9 68.1 18.7 102.5 20.6l-.5.4c89.1 64.9 205.9 84.4 313 49l127.1 41.4c3.2 1 6.5 1.6 9.9 1.6 17.7 0 32-14.3 32-32V753c88.1-119.6 90.4-284.9 1-408zM323 735l-12-5-99 31-1-104-8-9c-84.6-103.2-90.2-251.9-11-361 96.4-132.2 281.2-161.4 413-66 132.2 96.1 161.5 280.6 66 412-80.1 109.9-223.5 150.5-348 102zm505-17l-8 10 1 104-98-33-12 5c-56 20.8-115.7 22.5-171 7l-.2-.1A367.31 367.31 0 00729 676c76.4-105.3 88.8-237.6 44.4-350.4l.6.4c23 16.5 44.1 37.1 62 62 72.6 99.6 68.5 235.2-8 330z"
                            />
                            <path
                              d="M433 421c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                <th
                  class="ant-table-cell"
                  style="border-top: 1px solid rgba(0,0,0,.06); border-left: 1px solid rgba(0,0,0,.06);"
                >
                  Description
                </th>
                <th
                  class="ant-table-cell"
                  style="border-top: 1px solid rgba(0,0,0,.06);"
                >
                  Qty
                </th>
                <th
                  class="ant-table-cell"
                  style="border-top: 1px solid rgba(0,0,0,.06); border-right: 1px solid rgba(0,0,0,.06);"
                >
                  Unit
                </th>
                <th
                  class="ant-table-cell"
                  data-testid="lead-time"
                  style="min-width: 85px; text-align: right;"
                >
                  Lead Time
                </th>
                <th
                  class="ant-table-cell"
                  data-testid="unit-cost"
                  style="min-width: 85px; text-align: right;"
                >
                  Unit Cost
                </th>
                <th
                  class="ant-table-cell"
                  data-testid="ex-cost"
                  style="min-width: 85px; text-align: right;"
                >
                  Ex. Cost
                </th>
                <th
                  class="ant-table-cell"
                  style="width: 39px; min-width: 39px; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <th
                  class="ant-table-cell"
                  data-testid="lead-time"
                  style="min-width: 85px; text-align: right;"
                >
                  Lead Time
                </th>
                <th
                  class="ant-table-cell"
                  data-testid="unit-cost"
                  style="min-width: 85px; text-align: right;"
                >
                  Unit Cost
                </th>
                <th
                  class="ant-table-cell"
                  data-testid="ex-cost"
                  style="min-width: 85px; text-align: right;"
                >
                  Ex. Cost
                </th>
                <th
                  class="ant-table-cell"
                  style="width: 39px; min-width: 39px; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <th
                  class="ant-table-cell"
                  data-testid="lead-time"
                  style="min-width: 85px; text-align: right;"
                >
                  Lead Time
                </th>
                <th
                  class="ant-table-cell"
                  data-testid="unit-cost"
                  style="min-width: 85px; text-align: right;"
                >
                  Unit Cost
                </th>
                <th
                  class="ant-table-cell"
                  data-testid="ex-cost"
                  style="min-width: 85px; text-align: right;"
                >
                  Ex. Cost
                </th>
                <th
                  class="ant-table-cell"
                  style="width: 39px; min-width: 39px; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <th
                  class="ant-table-cell"
                  data-testid="lead-time"
                  style="min-width: 85px; text-align: right;"
                >
                  Lead Time
                </th>
                <th
                  class="ant-table-cell"
                  data-testid="unit-cost"
                  style="min-width: 85px; text-align: right;"
                >
                  Unit Cost
                </th>
                <th
                  class="ant-table-cell"
                  data-testid="ex-cost"
                  style="min-width: 85px; text-align: right;"
                >
                  Ex. Cost
                </th>
                <th
                  class="ant-table-cell"
                  style="width: 39px; min-width: 39px; border-right: 1px solid rgba(0,0,0,.06);"
                />
              </tr>
            </thead>
            <tbody
              class="ant-table-tbody"
            >
              <tr
                data-testid="row"
              >
                <td
                  class="ant-table-cell"
                  style="border-left: 1px solid rgba(0,0,0,.06);"
                >
                  <span
                    class="ant-typography ant-typography-ellipsis ant-typography-ellipsis-single-line"
                    style="max-width: 250px;"
                  >
                    Arrow Hart High Output Fluorescent Lampholde...
                  </span>
                </td>
                <td
                  class="ant-table-cell"
                >
                  10
                </td>
                <td
                  class="ant-table-cell"
                  style="border-right: 1px solid rgba(0,0,0,.06);"
                >
                   each
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  1
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $100.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $1,000.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                >
                  <span
                    aria-label="flag"
                    class="anticon anticon-flag"
                    data-testid="vendor-note-cell"
                    role="img"
                  >
                    <svg
                      aria-hidden="true"
                      class=""
                      data-icon="flag"
                      fill="currentColor"
                      focusable="false"
                      height="1em"
                      viewBox="64 64 896 896"
                      width="1em"
                    >
                      <path
                        d="M184 232h368v336H184z"
                        fill="#e6f7ff"
                      />
                      <path
                        d="M624 632c0 4.4-3.6 8-8 8H504v73h336V377H624v255z"
                        fill="#e6f7ff"
                      />
                      <path
                        d="M880 305H624V192c0-17.7-14.3-32-32-32H184v-40c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v784c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V640h248v113c0 17.7 14.3 32 32 32h416c17.7 0 32-14.3 32-32V337c0-17.7-14.3-32-32-32zM184 568V232h368v336H184zm656 145H504v-73h112c4.4 0 8-3.6 8-8V377h216v336z"
                        fill="#1890ff"
                      />
                    </svg>
                  </span>
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  N/A
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  N/A
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  data-testid="lowest-price"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  2
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  $10.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  $100.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); border-right: 1px solid rgba(0,0,0,.06);"
                />
              </tr>
              <tr
                data-testid="row"
              >
                <td
                  class="ant-table-cell"
                  style="border-left: 1px solid rgba(0,0,0,.06);"
                >
                  <span
                    class="ant-typography ant-typography-ellipsis ant-typography-ellipsis-single-line"
                    style="max-width: 250px;"
                  >
                    Light Socket To Single Outlet Adapter, Black
                  </span>
                </td>
                <td
                  class="ant-table-cell"
                >
                  20
                </td>
                <td
                  class="ant-table-cell"
                  style="border-right: 1px solid rgba(0,0,0,.06);"
                >
                   each
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  1
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $10.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $200.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  N/A
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  N/A
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  data-testid="lowest-price"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  3
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  $2.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  $40.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); border-right: 1px solid rgba(0,0,0,.06);"
                />
              </tr>
              <tr
                data-testid="row"
              >
                <td
                  class="ant-table-cell"
                  style="border-left: 1px solid rgba(0,0,0,.06);"
                >
                  <span
                    class="ant-typography ant-typography-ellipsis ant-typography-ellipsis-single-line"
                    style="max-width: 250px;"
                  >
                    Brass Elbow, 1/8 In  x 1/8 In
                  </span>
                </td>
                <td
                  class="ant-table-cell"
                >
                  5
                </td>
                <td
                  class="ant-table-cell"
                  style="border-right: 1px solid rgba(0,0,0,.06);"
                >
                   each
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  1
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $199.78
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $998.90
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  N/A
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  N/A
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  data-testid="lowest-price"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  4
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  $50.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  $250.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); border-right: 1px solid rgba(0,0,0,.06);"
                />
              </tr>
              <tr
                data-testid="row"
              >
                <td
                  class="ant-table-cell"
                  style="border-left: 1px solid rgba(0,0,0,.06);"
                >
                  <span
                    class="ant-typography ant-typography-ellipsis ant-typography-ellipsis-single-line"
                    style="max-width: 250px;"
                  >
                    Single Outlet To Medium Base Socket Adapter,...
                  </span>
                </td>
                <td
                  class="ant-table-cell"
                >
                  50
                </td>
                <td
                  class="ant-table-cell"
                  style="border-right: 1px solid rgba(0,0,0,.06);"
                >
                   each
                </td>
                <td
                  class="ant-table-cell"
                  data-testid="lowest-price"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  1
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  $2.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  $100.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); border-right: 1px solid rgba(0,0,0,.06);"
                >
                  <span
                    aria-label="flag"
                    class="anticon anticon-flag"
                    data-testid="vendor-note-cell"
                    role="img"
                  >
                    <svg
                      aria-hidden="true"
                      class=""
                      data-icon="flag"
                      fill="currentColor"
                      focusable="false"
                      height="1em"
                      viewBox="64 64 896 896"
                      width="1em"
                    >
                      <path
                        d="M184 232h368v336H184z"
                        fill="#e6f7ff"
                      />
                      <path
                        d="M624 632c0 4.4-3.6 8-8 8H504v73h336V377H624v255z"
                        fill="#e6f7ff"
                      />
                      <path
                        d="M880 305H624V192c0-17.7-14.3-32-32-32H184v-40c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v784c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V640h248v113c0 17.7 14.3 32 32 32h416c17.7 0 32-14.3 32-32V337c0-17.7-14.3-32-32-32zM184 568V232h368v336H184zm656 145H504v-73h112c4.4 0 8-3.6 8-8V377h216v336z"
                        fill="#1890ff"
                      />
                    </svg>
                  </span>
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-cell"
                  style="background-color: rgb(247, 247, 247);"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  N/A
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  6
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  N/A
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
              </tr>
              <tr
                data-testid="row"
              >
                <td
                  class="ant-table-cell"
                  style="border-left: 1px solid rgba(0,0,0,.06);"
                >
                  <span
                    class="ant-typography ant-typography-ellipsis ant-typography-ellipsis-single-line"
                    style="max-width: 250px;"
                  >
                    T12 Fluorescent Tube Guard, 48 In
                  </span>
                </td>
                <td
                  class="ant-table-cell"
                >
                  1
                </td>
                <td
                  class="ant-table-cell"
                  style="border-right: 1px solid rgba(0,0,0,.06);"
                >
                   each
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  1
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  N/A
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-cell"
                  style="background-color: rgb(247, 247, 247);"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  N/A
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  1
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  N/A
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
              </tr>
              <tr
                data-testid="row"
              >
                <td
                  class="ant-table-cell"
                  style="border-left: 1px solid rgba(0,0,0,.06);"
                >
                  <span
                    class="ant-typography ant-typography-ellipsis ant-typography-ellipsis-single-line"
                    style="max-width: 250px;"
                  >
                    Fluorescent Blacklight Fixture, 18 In
                  </span>
                </td>
                <td
                  class="ant-table-cell"
                >
                  10
                </td>
                <td
                  class="ant-table-cell"
                  style="border-right: 1px solid rgba(0,0,0,.06);"
                >
                   each
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  1
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $150.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $1,500.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  N/A
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  N/A
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  data-testid="lowest-price"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  1
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  $60.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  $600.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); border-right: 1px solid rgba(0,0,0,.06);"
                />
              </tr>
              <tr
                data-testid="row"
              >
                <td
                  class="ant-table-cell"
                  style="border-left: 1px solid rgba(0,0,0,.06);"
                >
                  <span
                    class="ant-typography ant-typography-ellipsis ant-typography-ellipsis-single-line"
                    style="max-width: 250px;"
                  >
                    Double Strip T8 Fluorescent Fixture, 48 In
                  </span>
                </td>
                <td
                  class="ant-table-cell"
                >
                  1
                </td>
                <td
                  class="ant-table-cell"
                  style="border-right: 1px solid rgba(0,0,0,.06);"
                >
                   each
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  1
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $15.235
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $15.24
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-cell"
                  style="background-color: rgb(247, 247, 247);"
                />
                <td
                  class="ant-table-cell"
                  data-testid="lowest-price"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  $1.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  $1.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-cell"
                  style="background-color: rgb(247, 247, 247);"
                />
              </tr>
              <tr
                data-testid="row"
              >
                <td
                  class="ant-table-cell"
                  style="border-left: 1px solid rgba(0,0,0,.06);"
                >
                  <span
                    class="ant-typography ant-typography-ellipsis ant-typography-ellipsis-single-line"
                    style="max-width: 250px;"
                  >
                    Brass Lock Up Cap, 2 Pack
                  </span>
                </td>
                <td
                  class="ant-table-cell"
                >
                  1
                </td>
                <td
                  class="ant-table-cell"
                  style="border-right: 1px solid rgba(0,0,0,.06);"
                >
                   each
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-cell"
                  style="background-color: rgb(247, 247, 247);"
                />
                <td
                  class="ant-table-cell"
                  data-testid="lowest-price"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  $100.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  $100.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-cell"
                  style="background-color: rgb(247, 247, 247);"
                />
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-cell"
                  style="background-color: rgb(247, 247, 247);"
                />
              </tr>
              <tr
                data-testid="row"
              >
                <td
                  class="ant-table-cell"
                  style="border-left: 1px solid rgba(0,0,0,.06);"
                >
                  <span
                    class="ant-typography ant-typography-ellipsis ant-typography-ellipsis-single-line"
                    style="max-width: 250px;"
                  >
                    Single Outlet To Medium Base Socket Adapter,...
                  </span>
                </td>
                <td
                  class="ant-table-cell"
                >
                  51
                </td>
                <td
                  class="ant-table-cell"
                  style="border-right: 1px solid rgba(0,0,0,.06);"
                >
                   each
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-cell"
                  style="background-color: rgb(247, 247, 247);"
                />
                <td
                  class="ant-table-cell"
                  data-testid="lowest-price"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  $1.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); min-width: 85px; text-align: right;"
                >
                  $51.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: rgb(246, 255, 236); border-right: 1px solid rgba(0,0,0,.06);"
                />
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-cell"
                  style="background-color: rgb(247, 247, 247);"
                />
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-cell"
                  style="background-color: rgb(247, 247, 247);"
                />
              </tr>
              <tr
                data-testid="row"
              >
                <td
                  class="ant-table-cell"
                  style="border-left: 1px solid rgba(0,0,0,.06);"
                >
                  <span
                    class="ant-typography ant-typography-ellipsis ant-typography-ellipsis-single-line"
                    style="max-width: 250px;"
                  >
                    Brass Lock Up Cap, 2 Pack
                  </span>
                </td>
                <td
                  class="ant-table-cell"
                >
                  2
                </td>
                <td
                  class="ant-table-cell"
                  style="border-right: 1px solid rgba(0,0,0,.06);"
                >
                   each
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-cell"
                  style="background-color: rgb(247, 247, 247);"
                />
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-cell"
                  style="background-color: rgb(247, 247, 247);"
                />
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-cell"
                  style="background-color: rgb(247, 247, 247);"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                />
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  N/A
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; min-width: 85px; text-align: right;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  style="background-color: transparent; border-right: 1px solid rgba(0,0,0,.06);"
                />
              </tr>
            </tbody>
            <tfoot
              class="css-1viwiur"
            >
              <tr
                data-testid="Discount"
              >
                <td
                  class="ant-table-cell"
                  colspan="3"
                  style="text-align: right; padding: 4px;"
                >
                  Discount
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="Discount-value-0"
                  style="text-align: right; padding-right: 47px; padding-top: 4px; padding-bottom: 4px;"
                >
                  $250.00
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="Discount-value-1"
                  style="text-align: right; padding-right: 47px; padding-top: 4px; padding-bottom: 4px;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="Discount-value-2"
                  style="text-align: right; padding-right: 47px; padding-top: 4px; padding-bottom: 4px;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="Discount-value-3"
                  style="text-align: right; padding-right: 47px; padding-top: 4px; padding-bottom: 4px;"
                >
                  $0.00
                </td>
              </tr>
              <tr
                data-testid="Shipping"
              >
                <td
                  class="ant-table-cell"
                  colspan="3"
                  style="text-align: right; padding: 4px;"
                >
                  Shipping
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="Shipping-value-0"
                  style="text-align: right; padding-right: 47px; padding-top: 4px; padding-bottom: 4px;"
                >
                  $50.00
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="Shipping-value-1"
                  style="text-align: right; padding-right: 47px; padding-top: 4px; padding-bottom: 4px;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="Shipping-value-2"
                  style="text-align: right; padding-right: 47px; padding-top: 4px; padding-bottom: 4px;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="Shipping-value-3"
                  style="text-align: right; padding-right: 47px; padding-top: 4px; padding-bottom: 4px;"
                >
                  $250.00
                </td>
              </tr>
              <tr
                data-testid="Tax"
              >
                <td
                  class="ant-table-cell"
                  colspan="3"
                  style="text-align: right; padding: 4px;"
                >
                  Tax
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="Tax-value-0"
                  style="text-align: right; padding-right: 47px; padding-top: 4px; padding-bottom: 4px;"
                >
                  $320.00
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="Tax-value-1"
                  style="text-align: right; padding-right: 47px; padding-top: 4px; padding-bottom: 4px;"
                >
                  $0.00
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="Tax-value-2"
                  style="text-align: right; padding-right: 47px; padding-top: 4px; padding-bottom: 4px;"
                >
                  $50.00
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="Tax-value-3"
                  style="text-align: right; padding-right: 47px; padding-top: 4px; padding-bottom: 4px;"
                >
                  $0.00
                </td>
              </tr>
              <tr
                style="font-weight: bold;"
              >
                <td
                  class="ant-table-cell"
                  colspan="3"
                  style="text-align: right;"
                >
                  Grand Total
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="grand-total"
                  style="text-align: right; padding-right: 47px;"
                >
                  $3,934.14
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="grand-total"
                  style="text-align: right; padding-right: 47px;"
                >
                  $151.00
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="grand-total"
                  style="text-align: right; padding-right: 47px;"
                >
                  $51.00
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="grand-total"
                  style="text-align: right; padding-right: 47px;"
                >
                  $1,240.00
                </td>
              </tr>
              <tr>
                <td
                  class="divider css-1mzwzrt"
                  colspan="19"
                />
              </tr>
              <tr>
                <td
                  class="ant-table-cell"
                  colspan="3"
                  style="text-align: right; vertical-align: baseline;"
                >
                  <span
                    class="ant-typography"
                  >
                    <strong>
                      Quote Attachments
                    </strong>
                  </span>
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-quote"
                  style="width: 155.5px; max-width: 155.5px;"
                />
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-quote"
                  style="width: 155.5px; max-width: 155.5px;"
                />
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-quote"
                  style="width: 155.5px; max-width: 155.5px;"
                />
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-quote"
                  style="width: 155.5px; max-width: 155.5px;"
                />
              </tr>
              <tr>
                <td
                  class="ant-table-cell"
                  colspan="3"
                  style="text-align: right; vertical-align: baseline;"
                >
                  <span
                    class="ant-typography"
                  >
                    <strong>
                      Vendor Attachments
                    </strong>
                  </span>
                </td>
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-vendor-files"
                  style="width: 155.5px; max-width: 155.5px;"
                />
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-vendor-files"
                  style="width: 155.5px; max-width: 155.5px;"
                />
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-vendor-files"
                  style="width: 155.5px; max-width: 155.5px;"
                />
                <td
                  class="ant-table-cell"
                  colspan="4"
                  data-testid="empty-vendor-files"
                  style="width: 155.5px; max-width: 155.5px;"
                />
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
