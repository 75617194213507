import React from 'react'

import { CellBase, DataEditorProps } from 'react-spreadsheet'

import { toJS } from 'mobx'

import {
  SelectCostCodePhaseAdvanced,
  makeCostCodePhaseOptionAdvanced,
  SelectCostCodePhaseAdvancedProps,
} from 'contractor/components/SelectCostCodePhaseAdvanced'

type PhaseCodeEditorCellProps = SelectCostCodePhaseAdvancedProps &
  DataEditorProps & {
    onChange: (cell: CellBase, shouldPropagateValue?: boolean) => void
  }

export const PhaseCodeEditorCell = ({ cell, onChange, ...props }: PhaseCodeEditorCellProps) => {
  return (
    <SelectCostCodePhaseAdvanced
      {...props}
      value={makeCostCodePhaseOptionAdvanced(cell.value)}
      onChange={(value, shouldPropagateValues) => {
        onChange(
          {
            ...cell,
            value: toJS(value?.originalObject),
          },
          shouldPropagateValues,
        )
      }}
      autoFocus
    />
  )
}
