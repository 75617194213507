import React from 'react'

import { FlexBoxY } from 'common/components/boxes'
import { ChangedItem, TextPrevious, ChangeType } from 'common/components/History'
import { EventChange } from 'common/server/order_history'

const DELIVERY_FIELDS_CONFIG = [
  { key: 'Shipping', type: 'currency' },
  { key: 'Other', type: 'currency' },
  { key: 'Discount', type: 'currency' },
  { key: 'Tax', type: 'currency' },
  { key: 'Requested delivered at', type: 'date' },
  { key: 'Estimated delivered at', type: 'date' },
  {
    key: 'Address',
    type: 'text',
  },
  { key: 'Lead time in days', type: 'text' },
  { key: 'Carrier', type: 'text' },
  { key: 'Instructions', type: 'text' },
  { key: 'Tracking number', type: 'text' },
  { key: 'Delivery issues', type: 'text' },
  { key: 'Delivery issue type', type: 'enum' },
  { key: 'Deleted at', type: 'text' },
  { key: 'Delivery issue resolved by', type: 'text' },
  { key: 'Merged at', type: 'date' },
  { key: 'Merged into', type: 'text' },
  { key: 'Address to', type: 'text' },
]
export const ALLOW_DELIVERY_FIELDS = DELIVERY_FIELDS_CONFIG.map((item) => item.key)

type DeliveryProps = {
  delivery: EventChange
}

export const Delivery = ({ delivery }: DeliveryProps) => {
  const keys = Object.keys(delivery.changes).filter((key) => ALLOW_DELIVERY_FIELDS.includes(key))

  if (!keys.length) {
    return null
  }

  return (
    <FlexBoxY alignItems="flex-start" width="100%">
      {keys.map((key, index) => {
        const changes = delivery.changes[key]

        if (delivery.changes['Deleted at']) {
          return <TextPrevious key={`${key}-${index}`}>Removed Delivery</TextPrevious>
        }

        return (
          <FlexBoxY alignItems="flex-start" width="100%" key={`${key}-${index}`}>
            <ChangedItem
              label={key}
              changes={changes}
              isCreate={delivery.event === 'create'}
              type={DELIVERY_FIELDS_CONFIG.find((item) => item.key === key)?.type as ChangeType}
            />
          </FlexBoxY>
        )
      })}
    </FlexBoxY>
  )
}
