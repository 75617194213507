import React from 'react'

import { Button, Typography, Divider, Space, Checkbox } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { SelectFilter } from 'common/components/table/postgres_filters'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { useStores } from 'contractor/hooks/use-stores'

const makeFilterOption = (option) => ({
  value: option.label,
  label: option.label,
})

export const Filters = observer(() => {
  const { costCodeStore, companySettingStore } = useStores()
  const persistentFilters = usePersistentFilters()

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const { costCodeListStore } = costCodeStore

  return (
    <Box display="flex" flexDirection="column" p={12} gridGap={16}>
      {costCodeSettings?.project_filtering_enabled && (
        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Paragraph type="secondary">Project</Typography.Paragraph>
          <SelectFilter
            items={costCodeListStore.facets['projects']?.map(makeFilterOption) || []}
            selected={costCodeListStore.searchState.filters['projects']}
            onChange={(projects) => persistentFilters.handleChangeFilters({ projects })}
            attribute="projects"
            placeholder="Select Projects"
            isSearchable
            formatter={(value) => value}
          />
        </Box>
      )}

      <Box width="100%" display="flex" flexDirection="column">
        <Typography.Paragraph type="secondary">Tags</Typography.Paragraph>
        <SelectFilter
          items={costCodeListStore.facets['tags']?.map(makeFilterOption) || []}
          selected={costCodeListStore.searchState.filters['tags']}
          onChange={(tags) => persistentFilters.handleChangeFilters({ tags })}
          attribute="tags"
          placeholder="Select Tags"
          isSearchable
          formatter={(value) => value}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: 0 }} />
              <Space
                onClick={() => {
                  persistentFilters.handleChangeFilters({
                    combined_tags: !costCodeListStore.searchState.filters['combined_tags'],
                  })
                }}
                style={{ padding: '8px 12px', cursor: 'pointer', width: '100%' }}
              >
                <Checkbox checked={costCodeListStore.searchState.filters['combined_tags']} />
                <Typography.Text type="secondary">Search exactly the combined tags</Typography.Text>
              </Space>
            </>
          )}
        />
      </Box>

      <Button
        style={{ marginTop: 16, width: '100%' }}
        type="primary"
        data-cy="clear-filters"
        onClick={() => persistentFilters.handleClearFilters(['active'])}
        disabled={persistentFilters.appliedFiltersCount === 0}
      >
        Clear All
      </Button>
    </Box>
  )
})
