import React, { useState, useRef } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { Typography, Button, Modal, List, Divider } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY, FlexBoxX } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { SearchInput } from 'common/components/SearchInput'

import { VendorProfile, VendorProfileProps } from 'contractor/components/VendorProfile'
import { filterTextByFields } from 'contractor/helpers/filter-text-by-fields'
import { useStores } from 'contractor/hooks/use-stores'
import { VendorCard } from 'contractor/pages/CompanyVendorContacts/SubBaseNetwork/vendor_card'
import { Vendor } from 'contractor/server/vendors'

type SubBaseNetworkProps = {
  onClickToCreate: () => void
} & Pick<VendorProfileProps, 'onSubmitAndAdd' | 'orderType'>

export const SubBaseNetwork = observer<SubBaseNetworkProps>(({ onClickToCreate, onSubmitAndAdd, orderType }) => {
  const { vendorStore } = useStores()

  const drawerVendorRef = useRef<DrawerRef>()

  const [searchText, setSearchText] = useState('')
  const [selectedVendorId, setSelectedVendorId] = useState(null)

  const handleShowInfo = () => {
    Modal.info({
      title: 'SubBase Network',
      content: (
        <div>
          <Typography.Paragraph>
            Every week, we are onboarding new Vendors who start receiving orders through SubBase.
          </Typography.Paragraph>
          <Typography.Paragraph style={{ margin: 0 }}>
            Don&apos;t see one of your vendors in our network? Don&apos;t worry, they will immediately receive all your
            orders via email.
          </Typography.Paragraph>
        </div>
      ),
    })
  }

  const handleSearchText = (text = '') => setSearchText(text.trim())

  const handeFilterText = (vendor: Vendor) => {
    return filterTextByFields({ fields: ['name', 'description', 'domain'], obj: vendor, searchText })
  }

  const handleOpenVendorProfile = (vendorId: string) => {
    drawerVendorRef.current?.show()
    setSelectedVendorId(vendorId)
  }

  return (
    <>
      <FlexBoxY width="100%" height="100%" alignItems="flex-start" justifyContent="flex-start">
        <FlexBoxY px={24} pb="24px" width="100%" alignItems="flex-start" justifyContent="flex-start" flexGrow={0}>
          <FlexBoxX alignItems="baseline" mb={12}>
            <Typography.Title style={{ marginBottom: 0 }} level={3}>
              SubBase Network
            </Typography.Title>
            <Button type="link" onClick={() => handleShowInfo()}>
              Learn more
            </Button>
          </FlexBoxX>

          <SearchInput onSearch={handleSearchText} style={{ width: '100%' }} />
        </FlexBoxY>

        <Divider style={{ margin: 0 }} />

        <FlexBoxY
          px={24}
          pt={24}
          overflow="hidden auto"
          width="100%"
          alignItems="initial"
          justifyContent="flex-start"
          flexGrow={1}
        >
          {!!vendorStore?.vendorsWithContacts.length && (
            <List
              grid={{
                gutter: 20,
                column: 1,
              }}
              dataSource={vendorStore.vendorsWithContacts.filter(handeFilterText)}
              renderItem={(vendor) => (
                <List.Item>
                  <VendorCard
                    name={vendor.name}
                    description={vendor.description}
                    logo={vendor?.logo?.url}
                    onClick={() => handleOpenVendorProfile(vendor.id)}
                    contactsCount={vendor?.vendor_users_count}
                  />
                </List.Item>
              )}
            />
          )}

          <FlexBoxY flexGrow={0} mb={16}>
            <SearchOutlined style={{ fontSize: 45 }} />
            <Typography.Text type="secondary" strong style={{ margin: '12px 0', fontSize: 16 }}>
              Don&apos;t see who you are looking for?
            </Typography.Text>
            <Button type="primary" onClick={onClickToCreate}>
              Add New Vendor Contact
            </Button>
          </FlexBoxY>
        </FlexBoxY>
      </FlexBoxY>

      <VendorProfile
        vendorId={selectedVendorId}
        hideCreate
        onSubmitAndAdd={(newCompanyVendorContact) => {
          drawerVendorRef.current.close()
          onSubmitAndAdd(newCompanyVendorContact)
        }}
        orderType={orderType}
        ref={drawerVendorRef}
      />
    </>
  )
})
