import React, { useState } from 'react'

import { UploadOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { RibbonFilter } from 'common/components/RibbonFilter'
import { SearchInput } from 'common/components/SearchInput'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { useStores } from 'contractor/hooks/use-stores'

import { PriceSheetCreate } from '../Create'
import { Options } from './Options'

const ribbonOptions = [
  { label: 'All', filter: '' },
  { label: 'Active', filter: 'active' },
  { label: 'Not started', filter: 'not_started' },
  { label: 'Expired', filter: 'expired' },
  { label: 'Draft', filter: 'draft' },
]

export const PriceSheetHeader = observer(() => {
  const {
    priceSheetStore: { listStore },
  } = useStores()

  const [open, toggleOpenUpload] = useState(false)

  const persistentFilters = usePersistentFilters()

  return (
    <>
      <PriceSheetCreate drawerProps={{ open: open, onClose: () => toggleOpenUpload(false) }} />

      <Box display="flex" gridGap={8} alignItems="center" justifyContent="space-between" width="100%">
        <SearchInput
          value={listStore.searchState.search}
          onSearch={(search: string) => persistentFilters.handleChangeFilters({ search }, false)}
        />

        <Options />

        <Button icon={<UploadOutlined />} type="primary" onClick={() => toggleOpenUpload(true)}>
          Upload
        </Button>
      </Box>

      <RibbonFilter
        showCounts
        value={listStore.searchState.filters['state'] || []}
        counts={listStore.stateCounts || {}}
        onChange={(value) => persistentFilters.handleChangeFilters({ state: value })}
        options={ribbonOptions}
        boxProps={{ overflowX: 'auto' }}
      />
    </>
  )
})
