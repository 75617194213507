import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'

import { getUnit } from 'common/components/OrderMaterialsV2'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { CostCode } from 'common/server/cost_codes/cost_codes'

import { CommonMaterial } from 'contractor/components/MaterialsAutocomplete'
import { useStores } from 'contractor/hooks/use-stores'
import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'
import { CommitmentMaterialsDrawer } from 'contractor/pages/@v2/Commitments/Material/components/MaterialsDrawer/commitment_materials_drawer'
import { List } from 'contractor/pages/@v2/Commitments/Material/components/Table/List'
import { Table } from 'contractor/pages/@v2/Commitments/Material/components/Table/Table'
import { CommitmentMaterial } from 'contractor/server/commitments'
import { ConsolidatedOrdersMaterials } from 'contractor/server/order_materials'

interface CommitmentsMaterialsProps {
  disabled: boolean
}

export const CommitmentsMaterials = observer<CommitmentsMaterialsProps>((props: CommitmentsMaterialsProps) => {
  const { companySettingStore, costCodeStore, commitmentStore, userStore, unitsStore } = useStores()
  const { disabled } = props
  const dataSource = commitmentStore?.selectedCommitment?.commitment_materials || []
  const isMobileScreen = useMediaQuery('md')
  const costCodeSettings = companySettingStore?.otherSettings?.cost_code_settings
  const hideCostCode = companySettingStore?.companyAttributes['cost_code_id']
  const canEditMaterialDatabase = userStore?.canEditMaterialDatabase
  const units = unitsStore?.units
  const [commitmentOm, setCommitmentOm] = useState<ConsolidatedOrdersMaterials.OrderMaterial[]>([])
  const [visible, setVisible] = useState(false)
  const { projectId, commitment, precision } = useCommitment()
  const commitmentHaveOrders = commitment?.order_count > 0

  const [costCodes, setCostCodes] = useState<CostCode[]>([])

  useEffect(() => {
    costCodeStore.costCodeListStore.fetchAllRecords().then(setCostCodes)
  }, [costCodeStore?.costCodeListStore])

  useEffect(() => {
    costCodeStore.costCodePhaseListStore.fetchRecords()
  }, [costCodeStore?.costCodeListStore])

  const setFocusOnInputQuantityLastSelectedMaterial = () => {
    const inputsQuantity = document.querySelectorAll(
      '.input-quantity .ant-input-number-input',
    ) as NodeListOf<HTMLInputElement>

    const inputQuantity = isMobileScreen
      ? inputsQuantity[inputsQuantity.length - 1]
      : inputsQuantity[inputsQuantity.length - 2]
    inputQuantity?.focus()
  }

  const scollToLastSelectedMaterial = () => {
    const mobileRows = document.getElementsByClassName('row-item')
    const desktopRows = document.getElementsByClassName('ant-table-row')
    mobileRows[mobileRows.length - 1]?.scrollIntoView({ behavior: 'smooth' })
    desktopRows[desktopRows.length - 1]?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleLoadOrderMaterials = (materialId: string) => {
    setVisible(true)
    commitmentStore?.loadCommitmentMaterials(materialId).then((materials) => {
      setCommitmentOm(materials)
    })
  }

  const handleSelectMaterial = (commonMaterial: CommonMaterial) => {
    const newMaterial = {
      id: null,
      unit_price: 0,
      unit: getUnit(commonMaterial),
      cost_code_phase: commonMaterial?.['cost_code_phase'],
      cost_code_phase_id: commonMaterial?.['cost_code_phase']?.['id'],
      cost_code: commonMaterial['cost_code'] as CostCode,
      cost_code_id: commonMaterial['cost_code']?.['id'],
      company_material: {
        ...commonMaterial,
      },
      quantity: 0,
    } as CommitmentMaterial

    commitmentStore.addMaterial(newMaterial)

    setTimeout(() => {
      setFocusOnInputQuantityLastSelectedMaterial()
      scollToLastSelectedMaterial()
    }, 100)
  }

  const materialsAutocompleteProps = {
    onSelect: handleSelectMaterial,
    canCreateNewMaterial: canEditMaterialDatabase,
    disabled: disabled,
    units: units,
  }

  if (isMobileScreen) {
    return (
      <>
        <List
          costCodeSettings={costCodeSettings}
          costCodes={costCodes}
          dataSource={dataSource}
          materialsAutocompleteProps={materialsAutocompleteProps}
          units={units}
          projectId={projectId}
          disabled={disabled}
          hideCostCode={hideCostCode}
          onChange={commitmentStore.updateMaterial}
          onRemove={commitmentStore.removeMaterial}
          precision={precision}
        />
      </>
    )
  }

  return (
    <>
      <Table
        costCodeSettings={costCodeSettings}
        costCodes={costCodes}
        dataSource={dataSource}
        materialsAutocompleteProps={materialsAutocompleteProps}
        units={units}
        commitment={commitment}
        disabled={disabled}
        projectId={projectId}
        hideCostCode={hideCostCode}
        commitmentHaveOrders={commitmentHaveOrders}
        onChange={commitmentStore.updateMaterial}
        onRemove={commitmentStore.removeMaterial}
        handleLoadOrderMaterials={handleLoadOrderMaterials}
        precision={precision}
      />
      <CommitmentMaterialsDrawer
        title={`Order Materials from commitment ${commitmentStore?.selectedCommitment?.commitment_name}`}
        visible={visible}
        materials={commitmentOm}
        onClose={() => setVisible(false)}
      />
    </>
  )
})
