/** @jsx jsx */

import React from 'react'

import { jsx } from '@emotion/core'

import PlusOutlined from '@ant-design/icons/PlusOutlined'
import { Button, Divider, Select, Tooltip, Typography } from 'antd'
import { SelectProps } from 'antd/lib/select'

import { observer } from 'mobx-react-lite'

import { BoxProps, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { Address } from 'common/server/addresses'

interface AddressOptions {
  company: Address[]
  project: ({ project_id: string; project_name: string } & Address)[]
  companyVendor?: ({ vendor_name: string } & Address)[]
}

export type AddressSelectorProps = {
  setAddress?: (address: Nullable<Address>) => void
  selectedAddress?: Address
  placeholder?: string
  addressOptions: AddressOptions
  addNewAddressOptionVisible?: boolean
  onShowAddressCreate?: () => void
  disabled?: boolean
  wrapperProps?: BoxProps
  hideCompanyAddress?: boolean
  hideProjectAddress?: boolean
  hideVendorAddress?: boolean
  showAddressTo?: boolean
  requireAddressTo?: boolean
  onChangeAddressTo?: (value: string) => void
  isProjectIdPresent: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & SelectProps<any>

const AddressSelector: React.FC<AddressSelectorProps> = observer(
  ({
    addNewAddressOptionVisible,
    selectedAddress,
    setAddress,
    onShowAddressCreate,
    placeholder,
    addressOptions,
    disabled,
    wrapperProps,
    hideCompanyAddress,
    hideProjectAddress,
    hideVendorAddress,
    isProjectIdPresent,
    ...props
  }) => {
    React.useEffect(() => {
      if (!selectedAddress || !isProjectIdPresent) {
        return
      }

      const allAddresses = [
        ...addressOptions.company,
        ...addressOptions.project,
        ...(addressOptions.companyVendor || []),
      ]

      if (!allAddresses.length) {
        return
      }

      const foundAddress = allAddresses.find((a) => a.id === selectedAddress.id)

      if (!foundAddress) {
        setAddress(null)
      }
    }, [addressOptions, selectedAddress])

    return (
      <FlexBoxX maxWidth="375px" justifyContent="flex-start" {...wrapperProps}>
        <Select
          aria-label="address-select"
          data-cy="address-selector"
          value={selectedAddress?.id}
          style={{ width: '100%' }}
          onChange={(_value, more) => {
            if (more) {
              setAddress(more['address'])
            } else {
              setAddress(null)
            }
          }}
          placeholder={placeholder}
          disabled={disabled}
          allowClear
          aria-autocomplete="none"
          dropdownRender={(menu) => (
            <div>
              {menu}
              {addNewAddressOptionVisible && (
                <React.Fragment>
                  <Divider style={{ margin: '4px 0' }} />
                  <FlexBoxX margin="4px" justifyContent="flex-start">
                    <Button type="link" onClick={onShowAddressCreate}>
                      <PlusOutlined style={{ marginRight: '5px' }} /> Add address
                    </Button>
                  </FlexBoxX>
                </React.Fragment>
              )}
            </div>
          )}
          showSearch
          filterOption={(input, option) => {
            const inputValue = input.toLowerCase()
            return option?.searchable?.toLowerCase().includes(inputValue)
          }}
          {...props}
        >
          {!hideCompanyAddress && (
            <Select.OptGroup label="Company Addresses">
              {addressOptions.company?.map((a) => (
                <Select.Option key={a.id} value={a.id} address={a} searchable={`${a.nickname}:${a.address_line_1}`}>
                  <Tooltip title={`${a.nickname} ${a.address_line_1}`}>
                    <span>
                      {a.nickname} {a.address_line_1}
                    </span>
                  </Tooltip>
                </Select.Option>
              ))}
            </Select.OptGroup>
          )}
          {!hideProjectAddress && (
            <Select.OptGroup label="Project Addresses" style={{ width: '100%' }}>
              {addressOptions.project?.map((a) => (
                <Select.Option
                  key={a.id}
                  value={a.id}
                  address={a}
                  searchable={`${a.nickname}:${a.address_line_1}:${a.project_name}`}
                >
                  <Tooltip title={`${a.nickname} ${a.address_line_1}`}>
                    <span>
                      {a.nickname} {a.address_line_1}
                    </span>
                  </Tooltip>
                </Select.Option>
              ))}
            </Select.OptGroup>
          )}
          {!hideVendorAddress && (
            <Select.OptGroup label="Vendor Addresses" style={{ width: '100%' }}>
              {addressOptions.companyVendor?.map((a) => (
                <Select.Option
                  key={a.id}
                  value={a.id}
                  address={a}
                  searchable={`${a.nickname}:${a.address_line_1}:${a.vendor_name}`}
                >
                  <Tooltip title={`${a.nickname} ${a.address_line_1}`}>
                    <FlexBoxY alignItems="flex-start">
                      <Typography.Text>
                        {a.nickname} {a.address_line_1}
                      </Typography.Text>
                      <Typography.Text type="secondary">{a.vendor_name}</Typography.Text>
                    </FlexBoxY>
                  </Tooltip>
                </Select.Option>
              ))}
            </Select.OptGroup>
          )}
        </Select>
      </FlexBoxX>
    )
  },
)

export default AddressSelector
