import React, { useState } from 'react'

import { InputNumber } from 'antd'

import { SelectType } from 'common/components/InputCurrencyPercentage/InvoiceMaterial/select_type'
import { PopoverInfo } from 'common/components/InputCurrencyPercentage/popover_info'
import { inputCurrencyFormatter, inputCurrencyParser } from 'common/helpers/formatters'

type InputInvoiceMaterialPercentageProps = {
  totalCost: number
  retainageAmount: number
  retainageType?: string
  roundingPrecision: number
  retainagePercentage: number
  onChange: (value: number, type: string, percentage: Nullable<string | number>) => void
}

export const InputInvoiceMaterialPercentage = (props: InputInvoiceMaterialPercentageProps) => {
  const { totalCost, retainageType, retainageAmount, roundingPrecision, onChange, retainagePercentage } = props
  const [selectedOption, setSelectedOption] = useState(retainageType || 'AMOUNT')
  const isPercentage = selectedOption === 'PERCENTAGE'

  const calculatePercentageValue = (percentage) => {
    return (Number(percentage) / 100) * totalCost
  }

  const reversePercentage = () => {
    if ([null, undefined].includes(retainageAmount) || totalCost === 0) return null
    return ((retainageAmount / totalCost) * 100).toFixed(roundingPrecision)
  }

  const handleChangeType = (value) => {
    setSelectedOption(value)
    if (value === 'PERCENTAGE') {
      onChange(retainageAmount, value, reversePercentage())
    } else {
      onChange(retainageAmount, value, retainagePercentage)
    }
  }

  const handleInputChange = (newValue) => {
    if (newValue) {
      const newCalculatedValue = selectedOption === 'PERCENTAGE' ? calculatePercentageValue(newValue) : newValue
      const percentage = selectedOption === 'PERCENTAGE' ? newValue : reversePercentage()

      onChange(newCalculatedValue, selectedOption, percentage)
    } else {
      onChange(0, selectedOption, 0)
    }
  }

  return (
    <InputNumber
      formatter={(value) => inputCurrencyFormatter(value, false)}
      addonBefore={<SelectType selectedOption={selectedOption} handleSelectChange={handleChangeType} />}
      parser={inputCurrencyParser}
      inputMode={isPercentage ? 'numeric' : 'decimal'}
      addonAfter={isPercentage && <PopoverInfo inputValue={retainageAmount} roundingPrecision={roundingPrecision} />}
      aria-autocomplete="none"
      value={isPercentage ? reversePercentage() : retainageAmount}
      onChange={handleInputChange}
    />
  )
}
