/*
  Stylizes Antd input like SearchBox from algolia.
  https://github.com/algolia/react-instantsearch/blob/master/packages/react-instantsearch-dom/src/components/SearchBox.js
  https://www.algolia.com/doc/api-reference/widgets/search-box/react/
  https://ant.design/components/input/
*/

import React, { useEffect, useState } from 'react'

import styled from '@emotion/styled'

import { Input } from 'antd'
import { InputProps } from 'antd/lib/input'

type SearchInputProps = Pick<InputProps, 'style' | 'placeholder' | 'value' | 'onFocus' | 'onChange' | 'disabled'> & {
  onSearch?: (text: InputProps['value']) => void
  placeholder?: string
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    background-color: #fff;
    color: #3a4570;
  }

  .ant-input-affix-wrapper {
    border: 1px solid #c5c1c06e;
    padding: 0.3rem 1.3rem 0.3rem 0.4rem;
    width: 100%;
    max-height: 32px;
    position: relative;
    border-radius: 2px;

    :hover,
    :focus,
    &.ant-input-affix-wrapper-focused {
      border-color: #c5c1c06e;
      box-shadow: none;
    }

    svg {
      fill: #3a4570;
    }

    .ant-input {
      color: #3a4570;
      padding-left: 2px !important;

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #a5aed1;
        opacity: 1; /* Firefox */
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #a5aed1;
      }
      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #a5aed1;
      }
    }
  }

  svg {
    cursor: pointer;
  }

  .clear {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`

const DefaultReset = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="14" height="14" className="clear" {...props}>
    <path d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z" />
  </svg>
)

const DefaultSubmit = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 40 40" {...props}>
    <path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z" />
  </svg>
)

export const SearchInput = ({
  style,
  onSearch,
  value: valueProp,
  onChange,
  placeholder,
  ...props
}: SearchInputProps) => {
  const [value, setValue] = useState<InputProps['value']>()

  const handleChange = (event) => {
    setValue(event.target.value)
    onChange?.(event)
  }

  const handleKeyPress = (event) => {
    const enterCharCode = 13
    if (event.charCode === enterCharCode) {
      onSearch?.(value)
    }
  }

  const handleClear = () => {
    setValue(null)
    onSearch?.('')
    onChange?.(null)
  }

  useEffect(() => {
    setValue(valueProp)
  }, [valueProp])

  return (
    <Wrapper style={style}>
      <Input
        aria-autocomplete="none"
        prefix={<DefaultSubmit onClick={() => onSearch?.(value)} />}
        placeholder={placeholder || 'Search here...'}
        suffix={!!value && <DefaultReset onClick={handleClear} />}
        onChange={handleChange}
        value={value}
        onKeyPress={handleKeyPress}
        {...props}
      />
    </Wrapper>
  )
}
