import React from 'react'

import { Theme } from '@emotion/react'

import { FlexBoxX } from 'common/components/boxes'
import { noticeError } from 'common/helpers/new_relic'
import { useTheme } from 'common/hooks/use-theme'
import { InvoiceInboxFilesStates, InvoiceInboxSubStates } from 'common/server/server_types'

import { InvoiceInboxFileFailMessage } from 'contractor/server/invoices/inbox'
import { ConsolidatedInvoiceInboxes } from 'contractor/server/invoices/inbox/consolidated_invoice_inboxes'

import { FileTag } from './file_tag'

export type FilesTagProps = {
  inbox_id: string
  files: ConsolidatedInvoiceInboxes.InvoiceInbox['files']
  inbox_fail_reason?: string
  inbox_auto_ignored_reason?: string
  upper_state: InvoiceInboxSubStates
}

const tooltipTitleColor = (
  filename: string,
  upper_state: InvoiceInboxSubStates,
  state: InvoiceInboxFilesStates,
  theme: Theme,
  fail_message: InvoiceInboxFileFailMessage[],
  auto_ignored_message?: string,
  inbox_fail_reason?: string,
  inbox_auto_ignored_reason?: string,
) => {
  let title: string
  let color: string
  // Be aware that the validation order is important here!
  if (!filename.toLowerCase().endsWith('.pdf')) {
    title = 'Ignored'
    color = theme.colors['gray-7']
  } else if (upper_state === InvoiceInboxSubStates.IGNORED) {
    title = 'Marked as ignored'
    color = theme.colors['gray-7']
  } else if (upper_state === InvoiceInboxSubStates.AUTO_IGNORED) {
    title = inbox_auto_ignored_reason || 'Auto ignored'
    color = theme.colors['gold-6']
  } else {
    switch (state) {
      case InvoiceInboxFilesStates.RESOLVED:
        title = 'Manually resolved'
        color = theme.colors['green-8']
        break
      case InvoiceInboxFilesStates.SUCCESS:
        title = 'Successfully extracted'
        color = theme.colors['green-6']
        break
      case InvoiceInboxFilesStates.AUTO_IGNORED:
        title = auto_ignored_message || 'Auto ignored'
        color = theme.colors['gold-6']
        break
      case InvoiceInboxFilesStates.FAILED:
        color = theme.colors['red-6']
        if (upper_state === InvoiceInboxSubStates.RESOLVED) {
          title = 'Manually resolved'
          color = theme.colors['green-8']
        } else if (fail_message && fail_message[0]?.reason) {
          title = fail_message[0]?.reason
        } else if (inbox_fail_reason) {
          title = inbox_fail_reason
        } else {
          title = 'Oops, something failed'
        }
        break
      case InvoiceInboxFilesStates.IN_REVIEW: {
        color = theme.colors['gold-7']
        const failMessage = fail_message[0]?.reason ? `${fail_message[0]?.reason}. ` : ''
        title = `${failMessage}An analyst is reviewing it. The average resolution time is 1 hour. Please don't make any changes to the invoice until the review is done`
        break
      }
      case InvoiceInboxFilesStates.EXTRACTING:
        title = 'Extracting'
        color = theme.colors['gray-7']
        break
    }
  }
  return {
    title,
    color,
  }
}

const parseFailMessage = (failMessage: string) => {
  try {
    return JSON.parse(failMessage)
  } catch (error) {
    noticeError('Failed to parse fail message in the invoice inbox list.', { failMessage })
    return []
  }
}

export const FileTags = ({
  inbox_id,
  files,
  inbox_fail_reason,
  inbox_auto_ignored_reason,
  upper_state,
}: FilesTagProps) => {
  const theme = useTheme()

  return (
    <FlexBoxX
      mt={5}
      flexWrap="wrap"
      justifyContent="flex-start"
      style={{ gap: 4 }}
      onClick={(e) => e.stopPropagation()}
    >
      {files.map(({ filename, url, state, fail_message, auto_ignored_message }) => {
        // This is a temporary fix, should be removed when the backend is fixed
        const railsHashJson = fail_message ? fail_message.replace(/=>/g, ':').replace(/nil/g, 'null') : '[]'
        const messages = parseFailMessage(railsHashJson)

        const tooltip = tooltipTitleColor(
          filename,
          upper_state,
          state,
          theme,
          messages,
          auto_ignored_message,
          inbox_fail_reason,
          inbox_auto_ignored_reason,
        )

        return (
          <FileTag
            key={`${inbox_id}-${filename}-key`}
            filename={filename}
            url={url}
            state={state}
            upper_state={upper_state}
            title={tooltip.title}
            color={tooltip.color}
          />
        )
      })}
    </FlexBoxX>
  )
}
