import React, { useEffect } from 'react'

import { Button, notification } from 'antd'

import { useStores } from 'contractor/hooks/use-stores'

interface UseExportListenerProps {
  userId?: string
}

const { success } = notification

export const useExportListener = (params: UseExportListenerProps) => {
  const { userId } = params
  const { companyStore } = useStores()

  const handleWebSocketMessage = (message) => {
    if (message.type === 'download') {
      const fileUrl = message.data['file_url']
      const recordType = message.data['record_type']
      window.open(fileUrl, '_blank')
      return success({
        message: 'File ready!',
        description: <span>Your file is ready to download!</span>,
        duration: 30,
        placement: 'bottomLeft',
        key: recordType,
        btn: (
          <Button
            type="primary"
            style={{ marginLeft: '10px' }}
            size="small"
            onClick={() => window.open(fileUrl, '_blank')}
          >
            Download
          </Button>
        ),
      })
    }
  }

  useEffect(() => {
    if (userId) {
      companyStore.companySubscribe(userId, handleWebSocketMessage)
    }
  }, [userId])
}
