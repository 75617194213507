import React from 'react'

import { render, screen, fireEvent, waitFor } from 'common/test-utils/index'

import { MaterialInfo, MaterialInfoProps } from '../material_info'
import { makeTableData } from './table_body.test'

const mockCompanyMaterial = {}

const mockCompanyMaterialStore = {
  showMaterialDetail: jest.fn(() => Promise.resolve(mockCompanyMaterial)),
  selectedCompanyMaterial: null,
}

jest.mock('contractor/hooks/use-stores', () => ({
  useStores: () => ({
    companyMaterialStore: mockCompanyMaterialStore,
  }),
}))

describe.skip('CompareOrders > material_info', () => {
  it('should render description', () => {
    render(<MaterialInfo item={makeTableData() as MaterialInfoProps['item']} />)

    expect(screen.getByText('Material N')).toBeTruthy()
  })

  it('should call showMaterialDetail', async () => {
    render(<MaterialInfo item={makeTableData() as MaterialInfoProps['item']} />)

    fireEvent.mouseOver(screen.getByText('Material N'))

    await waitFor(() => {
      const buttonInfo = document.querySelector('[data-testid="more-info"]')
      expect(buttonInfo).toBeTruthy()

      fireEvent.click(buttonInfo)

      expect(mockCompanyMaterialStore.showMaterialDetail).toBeCalledWith('company_material_1')
      expect(mockCompanyMaterialStore.selectedCompanyMaterial).toEqual(mockCompanyMaterial)
    })
  })
})
