import React, { useRef, useState } from 'react'

import { Typography, Button, Popover, Descriptions, Divider } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { currencyFormatter } from 'common/helpers/formatters'
import { getDataTestId } from 'common/test-utils'

import { useStores } from 'contractor/hooks/use-stores'
import { UpdateMaterial } from 'contractor/pages/CompanyMaterials/Materials/UpdateMaterial'

import { TableDataSource } from './make_table_data'

export type MaterialInfoProps = {
  item: TableDataSource
}

export const MaterialInfo = observer<MaterialInfoProps>(({ item }) => {
  const { companyMaterialStore } = useStores()

  const drawerUpdateRef = useRef<DrawerRef>()

  const [visible, toggleVisible] = useState(false)

  const getCompanyMaterial = async (companyMaterialId: string) => {
    const companyMaterial = await companyMaterialStore.showMaterialDetail(companyMaterialId)

    companyMaterialStore.selectedCompanyMaterial = companyMaterial
    drawerUpdateRef.current.show()
    toggleVisible(false)
  }

  return (
    <>
      <UpdateMaterial editable={false} onCancel={() => drawerUpdateRef.current.close()} ref={drawerUpdateRef} />

      <Popover
        overlayStyle={{ maxWidth: 370 }}
        placement="rightTop"
        overlayClassName="material"
        open={visible}
        onOpenChange={toggleVisible}
        content={
          <FlexBoxY>
            <Descriptions size="small" column={1}>
              <Descriptions.Item label="Manufacturer">{item?.company_material.manufacturer}</Descriptions.Item>
              <Descriptions.Item label="Product Identifier">
                {item?.company_material.product_identifier}
              </Descriptions.Item>
              {item?.company_material.group && (
                <Descriptions.Item label="Group">{item?.company_material.group}</Descriptions.Item>
              )}
              {item?.company_material.sub_group && (
                <Descriptions.Item label="Sub-Group">{item?.company_material.sub_group}</Descriptions.Item>
              )}
            </Descriptions>
            <Divider style={{ margin: '12px 0' }} />
            <Descriptions size="small" column={1}>
              <Descriptions.Item label="Last Order Price">
                {currencyFormatter(item?.company_material.cached_last_price)}
              </Descriptions.Item>
              <Descriptions.Item label="Average Order Price">
                {currencyFormatter(item?.company_material.cached_average_price)}
              </Descriptions.Item>
              <Descriptions.Item label="Lowest Order Price">
                {currencyFormatter(item?.company_material.cached_lowest_price)}
              </Descriptions.Item>
            </Descriptions>
            <Button
              type="primary"
              style={{ marginTop: 16 }}
              onClick={() => {
                getCompanyMaterial(item?.company_material.id)
              }}
              {...getDataTestId('more-info')}
            >
              More Info
            </Button>
          </FlexBoxY>
        }
        title={item.company_material.description}
      >
        <Typography.Text ellipsis style={{ maxWidth: 250 }}>
          {item.company_material.description}
        </Typography.Text>
      </Popover>
    </>
  )
})
