import React, { createContext, useContext } from 'react'

import { FormInstance, Form } from 'antd'

import { observer } from 'mobx-react-lite'

import { Loading } from 'common/components/Loading'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

const OtherSettingsContext = createContext<OtherSettingsProps>({} as OtherSettingsProps)

export const useOtherSettings = () => useContext(OtherSettingsContext)

type OtherSettingsProps = {
  form: FormInstance
}

export const OtherSettingsProvider = observer(({ children }) => {
  const { companySettingStore } = useStores()

  const { isLoading } = useQuery(companySettingStore.indexOtherSettings)

  const { otherSettings } = companySettingStore

  const [form] = Form.useForm()

  if (isLoading) {
    return <Loading />
  }

  if (!otherSettings) {
    return null
  }

  return <OtherSettingsContext.Provider value={{ form }}>{children}</OtherSettingsContext.Provider>
})

export const withOtherSettingsProvider = (Component) => (props) => {
  return (
    <OtherSettingsProvider>
      <Component {...props} />
    </OtherSettingsProvider>
  )
}
