import React from 'react'

import styled from '@emotion/styled'

import { Timeline, Avatar, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { SubBaseWhiteIcon } from 'common/components/SubBaseIcon'
import { useTheme } from 'common/hooks/use-theme'

import { Filename, FilenameProps } from './filename'

type SubBaseActionProps = {
  children: React.ReactNode
  attachments?: FilenameProps['attachment'][]
  issueMessage?: string
}

const LastTimelineItem = styled(Timeline.Item)`
  .ant-timeline-item-tail {
    display: none;
  }
`

export const SubBaseAction = ({ children, attachments, issueMessage }: SubBaseActionProps) => {
  const theme = useTheme()

  return (
    <LastTimelineItem
      dot={
        <Avatar
          style={{
            backgroundColor: theme.colors.primary,
          }}
          icon={<SubBaseWhiteIcon />}
        />
      }
    >
      <Box display="flex" flexDirection="column" pl="4px">
        <Typography.Text>{children}</Typography.Text>

        {issueMessage && (
          <Typography.Paragraph style={{ margin: 0 }}>
            <pre>{issueMessage}</pre>
          </Typography.Paragraph>
        )}

        {attachments?.map((attachment) => (
          <Filename key={attachment.id} attachment={attachment} />
        ))}
      </Box>
    </LastTimelineItem>
  )
}
