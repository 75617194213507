import React from 'react'

import { uniq } from 'lodash'

import { ChangedItem, ChangeType, SyncStatus } from 'common/components/History'
import { InvoiceHistoryResponse } from 'common/server/invoice_history'

import { ALLOW_INVOICE_FIELDS, INVOICE_FIELDS_CONFIG, INVOICE_FIELDS_CONFIG_FULL } from './helpers'
import { InvoiceState } from './invoice_state'

export type InvoiceProps = {
  invoice: InvoiceHistoryResponse['invoice']
  snapshot: InvoiceHistoryResponse['snapshot']
  isCreate?: boolean
  allowInvoiceFields?: string[]
}

export const Invoice = ({ invoice, snapshot, isCreate, allowInvoiceFields = ALLOW_INVOICE_FIELDS }: InvoiceProps) => {
  const keys = Object.keys(invoice).filter((key) => allowInvoiceFields.includes(key))

  const uniqKeys = uniq(keys)

  if (!uniqKeys.length) {
    return null
  }

  return (
    <>
      {uniqKeys
        .sort((a, b) => {
          if (a < b) return -1
          if (a > b) return 1
          return 0
        })
        .sort((a, b) => {
          const state = 'State'
          if (a === state && b !== state) return -1
          if (a !== state && b === state) return 1
          return 0
        })
        .map((key, index) => {
          const changes = invoice[key]

          if (key === 'State') {
            return <InvoiceState invoice={invoice} snapshot={snapshot} key={`${key}-${index}`} />
          }

          if (key === 'Sync status') {
            return <SyncStatus invoice={invoice} snapshot={snapshot} key={`${key}-${index}`} />
          }

          return (
            <ChangedItem
              key={`${key}-${index}`}
              label={key}
              changes={changes}
              isCreate={isCreate}
              type={
                (INVOICE_FIELDS_CONFIG.find((item) => item.key === key)?.type as ChangeType) ||
                (INVOICE_FIELDS_CONFIG_FULL.find((item) => item.key === key)?.type as ChangeType)
              }
            />
          )
        })}
    </>
  )
}
