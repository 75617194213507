import React from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'

import { useDeleteInvoiceMaterials } from './hooks/use-delete-invoice-materials'

export const DeleteInvoiceItems = () => {
  const { handleDelete, handleConfirmDelete, handleCancel, opened, disabled } = useDeleteInvoiceMaterials()

  return (
    <>
      <Button
        icon={<DeleteOutlined />}
        style={{ minWidth: 32 }}
        danger
        type="ghost"
        onClick={handleDelete}
        disabled={disabled}
      />

      <Modal title="Delete items" open={opened} onCancel={handleCancel} onOk={handleConfirmDelete}>
        Do you want to permanently delete the items?
      </Modal>
    </>
  )
}
