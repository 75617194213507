import React, { useCallback, useRef } from 'react'

import { Form } from 'antd'

import { InputCurrency, InputCurrencyProps } from 'common/components/InputCurrency'

import { useEditableTableContext } from './context'
import { useForm } from './row_context'

type EditableCellInputCurrencyProps = {
  inputName: string
  onSave: (value: string | number) => void
} & InputCurrencyProps

export const EditableCellInputCurrency = ({ inputName, onSave, ...props }: EditableCellInputCurrencyProps) => {
  const tableContext = useEditableTableContext()

  const inputNumberRef = useRef<HTMLInputElement>(null)
  const form = useForm()

  const save = useCallback(async () => {
    try {
      const values = await form.validateFields([inputName])

      // If the value is null, don't save it
      if (values[inputName] === null) {
        onSave(0)
        return
      }

      onSave(values[inputName])
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }, [form, onSave])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      inputNumberRef.current?.focus()

      if (tableContext.navigationByTabs) {
        inputNumberRef.current?.select()
      }
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [inputNumberRef.current])

  return (
    <Form.Item style={{ margin: 0 }} name={inputName}>
      <InputCurrency
        ref={inputNumberRef}
        onPressEnter={save}
        onBlur={save}
        onKeyDown={(e) => {
          if (e.key === 'Tab') {
            save()
          }
        }}
        style={{ width: '100%' }}
        autoFocus
        {...props}
      />
    </Form.Item>
  )
}
