import React from 'react'

import { useHistory, useRouteMatch } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

import { PageHeader } from 'antd'

import { observer } from 'mobx-react-lite'

import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import Settings from 'contractor/pages/ProjectSettings/settings'

const ProjectSettings = observer(() => {
  const { projectStore, companySettingStore, projectGroupsStore } = useStores()
  const { params } = useRouteMatch()
  const history = useHistory()
  const lastLocation = useLastLocation()

  const { isLoading } = useQuery(() => projectStore.showProject(params['id']))
  useQuery(companySettingStore.maybeIndexUsers)
  useQuery(projectGroupsStore.indexProjectGroups)

  const goBack = () => {
    if (lastLocation) {
      history.push(lastLocation)
    } else {
      history.push('/projects')
    }
  }

  if (isLoading) {
    return <Loading />
  }

  // Used to have project level sharing but got rid of it for now, no longer using tabs or Company Projects
  return (
    <Page>
      <Page.Header>
        <PageHeader onBack={() => goBack()} title="Project Settings" style={{ padding: '0', width: '100%' }} />
      </Page.Header>

      <Page.Content>
        <Settings />
      </Page.Content>
    </Page>
  )
})

export default ProjectSettings
