import { InvoiceResponse } from 'common/server/invoice'

import { useStores } from 'contractor/hooks/use-stores'

export type LockInvoiceResponse = {
  canEditInvoice: boolean
  canChangeStatusAndAssignee: boolean
  canPostInvoice: boolean
}

export const useLockInvoice = (invoice: InvoiceResponse) => {
  const { userStore } = useStores()

  if (invoice?.discarded_at) {
    return {
      canEditInvoice: false,
      canChangeStatusAndAssignee: false,
      canPostInvoice: false,
    }
  }

  return {
    canEditInvoice: userStore.canEditInvoices,
    canChangeStatusAndAssignee: userStore.canChangeStatusAndAssignee,
    canPostInvoice: userStore.canPostInvoices,
  }
}
