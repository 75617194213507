import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import qs from 'qs'

import { Card, Statistic, Row, Col } from 'antd'

import { observer } from 'mobx-react-lite'

import { formatEnumValue } from 'common/helpers/formatters'
import { useQuery } from 'common/hooks/use-query'
import { useTheme } from 'common/hooks/use-theme'
import { OrderStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

type StatCountProps = {
  title: string
  count: number
  loading?: boolean
}

const StatCount = observer<StatCountProps>(({ title, count, loading }) => {
  const theme = useTheme()

  const { search } = useLocation()
  const history = useHistory()

  return (
    <Card
      hoverable
      style={{ ...theme.cardStyles.hoverable, width: '100%' }}
      bodyStyle={{ padding: '10px 20px' }}
      onClick={() => {
        const query = qs.parse(search)
        const newQuery = {}
        if (query['projectId']) {
          newQuery['project_id'] = query['projectId']
        }
        query['refinementList'] = { state: title }
        history.replace({ pathname: '/orders', search: qs.stringify(query) })
      }}
    >
      <Statistic title={formatEnumValue(title)} value={count} loading={loading} />
    </Card>
  )
})

const Deliveries = observer(() => {
  const { projectStore, dashboardStore } = useStores()

  const { isLoading } = useQuery(
    () => dashboardStore.getOrderCounts(projectStore.selectedProject?.id),
    [projectStore.selectedProject?.id],
  )

  const orderCounts = dashboardStore.orderCounts

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={12} md={5}>
        <StatCount title={OrderStates.QUOTED} count={orderCounts[OrderStates.QUOTED]} loading={isLoading} />
      </Col>
      <Col xs={24} sm={12} md={5}>
        <StatCount title={OrderStates.ORDERED} count={orderCounts[OrderStates.ORDERED]} loading={isLoading} />
      </Col>
      <Col xs={24} sm={12} md={5}>
        <StatCount title={OrderStates.SHIPPED} count={orderCounts[OrderStates.SHIPPED]} loading={isLoading} />
      </Col>
      <Col xs={24} sm={12} md={5}>
        <StatCount
          title={OrderStates.PARTIALLY_SHIPPED}
          count={orderCounts[OrderStates.PARTIALLY_SHIPPED]}
          loading={isLoading}
        />
      </Col>
      <Col xs={24} sm={12} md={4}>
        <StatCount title={OrderStates.DELIVERED} count={orderCounts[OrderStates.DELIVERED]} loading={isLoading} />
      </Col>
    </Row>
  )
})

export default Deliveries
