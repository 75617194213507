import React from 'react'

import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined'
import { Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX } from 'common/components/boxes'

import { InvoiceStatusTag } from 'contractor/components/Invoices/invoice_status_tag'
import { useStores } from 'contractor/hooks/use-stores'

import { InvoiceProps } from './invoice'

type InvoiceState = Pick<InvoiceProps, 'invoice' | 'snapshot'>

export const InvoiceState = observer<InvoiceState>(({ invoice, snapshot }) => {
  const { invoiceStateStore } = useStores()

  const statePrevId = invoice?.['State'] ? invoice?.['State'][0] : snapshot['State']
  const stateNextId = invoice?.['State'] ? invoice?.['State'][1] : snapshot['State']

  const statePrev = invoiceStateStore.invoiceStates?.find(({ id }) => id === statePrevId)
  const stateNext = invoiceStateStore.invoiceStates?.find(({ id }) => id === stateNextId)

  return (
    <FlexBoxX alignItems="center" justifyContent="flex-start" mb="8px">
      <Typography.Text style={{ marginRight: 8, whiteSpace: 'nowrap' }}>State:</Typography.Text>
      {!!statePrev && (
        <>
          <InvoiceStatusTag state={statePrev} style={{ width: 'auto' }} />
          <ArrowRightOutlined style={{ margin: '0 8px' }} />
        </>
      )}
      <InvoiceStatusTag state={stateNext} style={{ width: 'auto' }} />
    </FlexBoxX>
  )
})
