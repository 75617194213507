import React, { createContext, useContext, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

import { message, Form } from 'antd'
import { FormInstance } from 'antd/es/form/Form'

import { observer } from 'mobx-react-lite'

import { Loading } from 'common/components/Loading'
import { noticeError } from 'common/helpers/new_relic'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { PriceSheet, PriceSheetUpdate } from 'contractor/server/price_sheet'

import { ErrorState } from './components'

const EditPriceSheetContext = createContext<EditPriceSheetContextProps>({} as EditPriceSheetContextProps)

export const useEditPriceSheet = () => useContext(EditPriceSheetContext)

type EditPriceSheetContextProps = {
  selectedMaterials: string[]
  setSelectedMaterials: React.Dispatch<React.SetStateAction<string[]>>
  applyPreferredPrice: boolean
  setApplyPreferredPrice: React.Dispatch<React.SetStateAction<boolean>>
  handleUpdatePriceSheetDraft: (priceSheet: PriceSheetUpdate, applyPreferredPrice?: boolean) => Promise<void>
  form: FormInstance
  goBack: () => void
  priceSheet: PriceSheet
  refetchPriceSheet: () => Promise<void>
}

export const EditPriceSheetProvider = observer(({ children }) => {
  const { priceSheetStore, projectStore, unitsStore } = useStores()

  const [selectedMaterials, setSelectedMaterials] = useState<string[]>([])
  const [applyPreferredPrice, setApplyPreferredPrice] = useState(false)

  const [form] = Form.useForm()

  const lastLocation = useLastLocation()
  const history = useHistory()
  const [previousListLocation] = useState(lastLocation)

  const { id } = useParams<{ id: string }>()

  useQuery(projectStore.maybeIndexProjects)
  useQuery(unitsStore.maybeUnits)
  const {
    isError,
    isLoading,
    data: priceSheet,
    refetch: refetchPriceSheet,
  } = useQuery(() => priceSheetStore.getPriceSheetById(id), [id])

  const handleUpdatePriceSheetDraft = async (priceSheet: PriceSheetUpdate, applyPreferredPrice?: boolean) => {
    try {
      await priceSheetStore.updatePriceSheet(priceSheet, applyPreferredPrice)
      refetchPriceSheet()
      setApplyPreferredPrice(false)
      message.success('Successfully updated price sheet')
    } catch (error) {
      noticeError(error, { entry: 'update-price-sheet' })
      message.error(error?.response?.data?.error || 'Unable to save price sheet')
    }
  }

  const goBack = () => {
    if (previousListLocation) {
      history.push(previousListLocation)
    } else {
      history.push('/company_materials?tab=price-sheets')
    }
  }

  if (isLoading && !priceSheet) {
    return <Loading />
  }

  if (isError) {
    return <ErrorState />
  }

  return (
    <EditPriceSheetContext.Provider
      value={{
        selectedMaterials,
        setSelectedMaterials,
        applyPreferredPrice,
        setApplyPreferredPrice,
        handleUpdatePriceSheetDraft,
        form,
        goBack,
        priceSheet,
        refetchPriceSheet,
      }}
    >
      {children}
    </EditPriceSheetContext.Provider>
  )
})

export const withEditPriceSheetProvider = (Component) => (props) => {
  return (
    <EditPriceSheetProvider>
      <Component {...props} />
    </EditPriceSheetProvider>
  )
}
