import React, { useState, useEffect } from 'react'

import { DeleteOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Checkbox, Modal, Tag } from 'antd'

import { Box } from 'common/components/boxes'

type DeleteHeaderProps = {
  onCancelDelete: () => void
  onConfirmDelete: () => void
  onSelectAll: (checkedAll: boolean) => void
  qtyItemsToDelete: number
  qtyTotalItems: number
  colSpan: number
}

export const DeleteHeader = ({
  onCancelDelete,
  onConfirmDelete,
  qtyItemsToDelete = 0,
  qtyTotalItems = 0,
  colSpan,
  onSelectAll,
}: DeleteHeaderProps) => {
  const [checkedAll, toggleCheckedAll] = useState(false)

  useEffect(() => {
    if (qtyItemsToDelete === qtyTotalItems) {
      toggleCheckedAll(true)
    }
  }, [qtyItemsToDelete, qtyTotalItems])

  return (
    <tr>
      <th className="ant-table-cell">
        <Checkbox
          checked={checkedAll}
          onChange={(e) => {
            onSelectAll(e.target.checked)
            toggleCheckedAll(e.target.checked)
          }}
          indeterminate={!!qtyItemsToDelete && qtyItemsToDelete < qtyTotalItems}
        />
      </th>
      <th className="ant-table-cell" colSpan={colSpan}>
        <Box display="flex" alignItems="center" justifyContent="flex-end" style={{ gap: 8 }}>
          <Tag color="processing" style={{ margin: 0 }}>
            {qtyItemsToDelete} items
          </Tag>
          <Button
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: `Are you sure you want to remove ${qtyItemsToDelete} material(s)?`,
                onOk() {
                  onConfirmDelete()
                },
              })
            }}
            disabled={!qtyItemsToDelete}
          >
            Delete
          </Button>
          <Button type="text" size="small" icon={<CloseOutlined />} onClick={() => onCancelDelete()} />
        </Box>
      </th>
    </tr>
  )
}
