import React, { useCallback, useState } from 'react'

import { useClickOutside } from 'common/hooks/use-click-outside'

import { useEditableTableContext } from './context'
import { useForm } from './row_context'

export type EditableCellProps<Item> = {
  children: React.ReactNode
  record: Item
  rowIndex: number
  editable?: (record: Item, rowIndex: number) => boolean
  renderEditable?: (record: Item, rowIndex: number) => React.ReactNode
  dataIndex: string
  tabIndex?: number
  tableIndex?: number
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EditableCell = <Item extends Record<string, any>>({
  children,
  record,
  rowIndex,
  renderEditable,
  editable = () => false,
  dataIndex,
  tabIndex,
  tableIndex,
  ...restProps
}: EditableCellProps<Item>) => {
  const wrapperInputRef = React.useRef()
  const form = useForm()

  const tableContext = useEditableTableContext()

  const [editing, setEditing] = useState(false)

  React.useEffect(() => {
    if (tableContext.shouldFocusOnCell(tableIndex, rowIndex, tabIndex)) {
      setEditing(true)
      return
    }

    setEditing(false)
  }, [tableContext, tableIndex, rowIndex, tabIndex])

  useClickOutside([wrapperInputRef], () => {
    if (editing) {
      setEditing(false)
    }

    tableContext.resetCurrentEditingCell()
  })

  const toggleEdit = useCallback(
    (e) => {
      e.stopPropagation()
      form.setFieldsValue({ [dataIndex]: record[dataIndex] })

      setEditing((prevEditing) => !prevEditing)
      setEditing(true)

      if (!editing) {
        tableContext.handleChangeCurrentEditingCell(tableIndex, rowIndex, tabIndex)
      }
    },
    [form, record, dataIndex, rowIndex, tabIndex],
  )

  if (renderEditable && editing && editable(record, rowIndex)) {
    return (
      <td
        {...restProps}
        ref={wrapperInputRef}
        onKeyDown={(e) => {
          if (e.key === 'Tab' && e.shiftKey) {
            tableContext.handleChangeCurrentEditingCell(tableIndex, rowIndex, tabIndex - 1, true)
            setEditing(false)

            return
          }

          if (e.key === 'Tab') {
            tableContext.handleChangeCurrentEditingCell(tableIndex, rowIndex, tabIndex + 1, true)
            setEditing(false)

            return
          }

          tableContext.setNavigationByTabs(false)
        }}
      >
        {renderEditable(record, rowIndex)}
      </td>
    )
  }

  if (renderEditable && !editing && editable(record, rowIndex)) {
    return (
      <td {...restProps}>
        <div
          className="editable-cell-value-wrap"
          tabIndex={rowIndex > 0 ? rowIndex * 5 + tabIndex : tabIndex}
          style={{ paddingRight: 24 }}
          onClick={toggleEdit}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              toggleEdit(e)
            }
          }}
          onFocus={() => {
            const newRow = tableContext.currentEditingCell?.split('_')[1] !== rowIndex.toString()

            if (newRow) {
              tableContext.handleChangeCurrentEditingCell(tableIndex, rowIndex, tabIndex)
              setEditing(true)

              return
            }

            if (tableContext.shouldFocusOnCell(tableIndex, rowIndex, tabIndex)) {
              setEditing(true)
            }
          }}
        >
          {children}
        </div>
      </td>
    )
  }

  return <td {...restProps}>{children}</td>
}
