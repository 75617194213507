import React, { useMemo } from 'react'

import { Table } from 'antd'

import { InvoiceHistoryResponse } from 'common/server/invoice_history'

import { FallbackCellItem } from './fallback_cell_item'
import { makeTableRow, filterAllowedChanges } from './helpers'
import { Wrapper } from './wrapper'

type InvoiceMaterialsProps = {
  invoiceMaterials: InvoiceHistoryResponse['invoice_materials']
  optionalColumns?: string[]
}

export const InvoiceMaterials = ({
  invoiceMaterials,
  optionalColumns: optionalColumnsProp = [],
}: InvoiceMaterialsProps) => {
  const dataSource = useMemo(
    () => invoiceMaterials.filter(filterAllowedChanges).map(makeTableRow),
    [invoiceMaterials?.length],
  )

  if (!dataSource.length) {
    return null
  }

  const optionalColumns = [
    {
      title: 'Cost Code',
      dataIndex: 'cost_code_id',
      width: 100,
      render: (_, record) => {
        return (
          <FallbackCellItem
            minWidth={115}
            event={record.event}
            isUnchanged={!record?.costCode?.length}
            prev={record?.costCode?.[0]}
            next={record?.costCode?.[1]}
            unchangedValue={record?.costCodeUnchanged}
          />
        )
      },
    },
    {
      title: 'Phase Code',
      dataIndex: 'cost_code_phase_id',
      width: 100,
      render: (_, record) => {
        return (
          <FallbackCellItem
            minWidth={115}
            event={record.event}
            isUnchanged={!record?.costCodePhase?.length}
            prev={record?.costCodePhase?.[0]}
            next={record?.costCodePhase?.[1]}
            unchangedValue={record?.costCodePhaseUnchanged}
          />
        )
      },
    },
    {
      title: 'Retainage type',
      dataIndex: 'retainage_type',
      width: 100,
      render: (_, record) => {
        return (
          <FallbackCellItem
            minWidth={115}
            event={record.event}
            isUnchanged={!record?.retainageType?.length}
            prev={record?.retainageType?.[0]}
            next={record?.retainageType?.[1]}
            unchangedValue={record?.retainageTypeUnchanged}
          />
        )
      },
    },
    {
      title: 'Retainage',
      dataIndex: 'retainage_value',
      width: 100,
      render: (_, record) => {
        const retainageType = record?.retainageType?.[1] || record?.retainageTypeUnchanged

        if (retainageType === 'AMOUNT') {
          return (
            <FallbackCellItem
              minWidth={115}
              event={record.event}
              isUnchanged={!record?.retainageAmount?.length}
              prev={record?.retainageAmount?.[0]}
              next={record?.retainageAmount?.[1]}
              unchangedValue={record?.retainageAmountUnchanged}
            />
          )
        }

        return (
          <FallbackCellItem
            minWidth={115}
            event={record.event}
            isUnchanged={!record?.retainagePercentage?.length}
            prev={record?.retainagePercentage?.[0]}
            next={record?.retainagePercentage?.[1]}
            unchangedValue={record?.retainagePercentageUnchanged}
          />
        )
      },
    },
  ].filter((column) => {
    return !column?.dataIndex || optionalColumnsProp.includes(column?.dataIndex)
  })

  return (
    <Wrapper>
      <Table
        tableLayout="auto"
        style={{ width: '100%' }}
        rowClassName={(record) => {
          if (record?.discardedAt) {
            return 'delete'
          }
          return record.event
        }}
        columns={[
          {
            title: 'Description',
            dataIndex: 'description',
            render: (_, record) => {
              return (
                <FallbackCellItem
                  minWidth={200}
                  event={record.event}
                  isUnchanged={!record?.description?.length}
                  prev={record?.description?.[0]}
                  next={record?.description?.[1]}
                  unchangedValue={record?.descriptionUnchanged}
                />
              )
            },
          },
          {
            title: 'Order Materials',
            dataIndex: 'order_materials',
            render: (_, record) => {
              return record?.invoiceOrderMaterials?.map((invoiceOrderMaterial, index) => {
                const isDiscarded = !!invoiceOrderMaterial?.changes?.['Discarded at']
                const companyMaterialDescription = invoiceOrderMaterial?.snapshot['Company material']

                return (
                  <FallbackCellItem
                    key={`${invoiceOrderMaterial?.item_id}-${index}`}
                    minWidth={200}
                    event="update"
                    isUnchanged={!!record?.discardedAt || !Object.keys(invoiceOrderMaterial?.changes).length}
                    prev={isDiscarded ? companyMaterialDescription : null}
                    next={isDiscarded ? null : companyMaterialDescription}
                    unchangedValue={companyMaterialDescription}
                  />
                )
              })
            },
          },
          {
            title: 'Qty',
            width: 76,
            render: (_, record) => {
              return (
                <FallbackCellItem
                  minWidth={60}
                  event={record.event}
                  isUnchanged={!record?.quantityShipped?.length}
                  prev={record?.quantityShipped?.[0]}
                  next={record?.quantityShipped?.[1]}
                  unchangedValue={record?.quantityShippedUnchanged}
                />
              )
            },
          },
          {
            title: 'UOM',
            dataIndex: 'unit',
            width: 76,
            render: (_, record) => {
              return (
                <FallbackCellItem
                  event={record.event}
                  minWidth={60}
                  isUnchanged={!record?.unit?.length}
                  prev={record?.unit?.[0]}
                  next={record?.unit?.[1]}
                  unchangedValue={record?.unitUnchanged}
                />
              )
            },
          },
          {
            title: 'Unit Cost',
            width: 86,
            render: (_, record) => {
              return (
                <FallbackCellItem
                  minWidth={70}
                  event={record.event}
                  isUnchanged={!record?.unitCost?.length}
                  prev={record?.unitCost?.[0]}
                  next={record?.unitCost?.[1]}
                  unchangedValue={record?.unitCostUnchanged}
                />
              )
            },
          },
          {
            title: 'Ext. Cost',
            width: 86,
            render: (_, record) => {
              return (
                <FallbackCellItem
                  minWidth={70}
                  event={record.event}
                  isUnchanged={!record?.extendedPrice?.length}
                  prev={record?.extendedPrice?.[0]}
                  next={record?.extendedPrice?.[1]}
                  unchangedValue={record?.extendedPriceUnchanged}
                />
              )
            },
          },
          ...optionalColumns,
        ]}
        dataSource={dataSource}
        pagination={false}
        size="small"
        scroll={{ x: 1200 }}
      />
    </Wrapper>
  )
}
