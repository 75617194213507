import React from 'react'

import { Button, Typography } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'
import { DateRangePicker } from 'common/components/DateRangePicker'
import { SelectFilter } from 'common/components/table/postgres_filters'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { useStores } from 'contractor/hooks/use-stores'

export const CommitmentFilters = () => {
  const {
    commitmentStore: { listStore },
  } = useStores()
  const values = listStore.searchState.filters
  const facets = listStore.facets

  const optionsFactory = React.useCallback(
    (key: string) => {
      return (facets[key] || []).map((x: { [key: string]: string }) => ({
        label: x.label,
        value: x.value,
      }))
    },
    [facets],
  )

  const persistentFilters = usePersistentFilters()
  const source = window.location.pathname

  return (
    <>
      <Box display="flex" flexDirection="column" p="12" gridGap={16}>
        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Vendor</Typography.Text>

          <SelectFilter
            items={optionsFactory('vendor_names')}
            selected={values['vendor_name']}
            onChange={(value) => persistentFilters.handleChangeFilters({ vendor_name: value })}
            attribute="vendor_name"
            placeholder="Select vendor"
            isSearchable
          />
        </Box>

        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Tags</Typography.Text>

          <SelectFilter
            items={optionsFactory('tags')}
            selected={values['tags']}
            onChange={(value) => persistentFilters.handleChangeFilters({ tags: value })}
            attribute="tags"
            placeholder="Select tag"
            isSearchable
          />
        </Box>

        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Type</Typography.Text>

          <SelectFilter
            items={optionsFactory('commitment_types')}
            selected={values['commitment_types']}
            onChange={(value) => persistentFilters.handleChangeFilters({ commitment_types: value })}
            attribute="commitment_types"
            placeholder="Select type"
            isSearchable
          />
        </Box>

        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Drafted by</Typography.Text>

          <SelectFilter
            items={optionsFactory('drafted_by')}
            selected={values['drafted_by']}
            onChange={(value) => persistentFilters.handleChangeFilters({ drafted_by: value })}
            attribute="drafted_by"
            placeholder="Select drafted by"
            isSearchable
          />
        </Box>

        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Submitted by</Typography.Text>

          <SelectFilter
            items={optionsFactory('submitted_by')}
            selected={values['submitted_by']}
            onChange={(value) => persistentFilters.handleChangeFilters({ submitted_by: value })}
            attribute="submitted_by"
            placeholder="Select submitted by"
            isSearchable
          />
        </Box>

        <Box width="100%">
          <Typography.Text type="secondary">Created at</Typography.Text>

          <DateRangePicker
            placeholderStart="Start date"
            placeholderEnd="End date"
            attribute="created_at"
            value={{
              startDate: values['created_at_start'],
              endDate: values['created_at_end'],
            }}
            onChange={({ startDate, endDate }) => {
              persistentFilters.handleChangeFilters({
                created_at_start: startDate,
                created_at_end: endDate,
              })
            }}
          />
        </Box>

        <Box width="100%">
          <Typography.Text type="secondary">Submitted at</Typography.Text>

          <DateRangePicker
            placeholderStart="Start date"
            placeholderEnd="End date"
            attribute="submitted_at_timestamp"
            value={{
              startDate: values['submitted_at_start'],
              endDate: values['submitted_at_end'],
            }}
            onChange={({ startDate, endDate }) => {
              persistentFilters.handleChangeFilters({
                submitted_at_start: startDate,
                submitted_at_end: endDate,
              })
            }}
          />
        </Box>

        <Box width="100%">
          <Typography.Text type="secondary">Expiration Date</Typography.Text>

          <DateRangePicker
            placeholderStart="Start date"
            placeholderEnd="End date"
            attribute="expiration_date_timestamp"
            value={{
              startDate: values['expiration_date_start'],
              endDate: values['expiration_date_end'],
            }}
            onChange={({ startDate, endDate }) => {
              persistentFilters.handleChangeFilters({
                expiration_date_start: startDate,
                expiration_date_end: endDate,
              })
            }}
          />
        </Box>

        <Button
          style={{ marginTop: 16, width: '100%' }}
          type="primary"
          data-cy="clear-filters"
          onClick={() => {
            trackEvent(Events.CLICK_COMMITMENTS_FILTER_CLEAR, source)
            persistentFilters.handleClearFilters()
          }}
          disabled={persistentFilters.appliedFiltersCount === 0}
        >
          Clear All
        </Button>
      </Box>
    </>
  )
}
