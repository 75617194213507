import React from 'react'

import { Typography, Tooltip, Tag } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { formatDateStringShort } from 'common/helpers/formatters'
import { formatEnumValue } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'
import { DeliveryStates } from 'common/server/server_types'

export type DeliveryInfoProps = {
  state: string
  estimated_delivered_at: string
  carrier: string
  actual_delivered_at: string
  marked_delivered_at: string
  delivery_issue_type: string
  delivery_issues: string
  requested_delivered_at?: string
  has_open_issue?: boolean
}

const DeliveryInfo: React.FC<{ delivery: DeliveryInfoProps; boxStyle?: React.CSSProperties }> = observer(
  ({ delivery, boxStyle = {} }) => {
    const theme = useTheme()

    const { state, estimated_delivered_at, carrier, actual_delivered_at, marked_delivered_at, requested_delivered_at } =
      delivery
    const { delivery_issue_type, delivery_issues } = delivery
    const formattedDeliveryDate = estimated_delivered_at && formatDateStringShort(estimated_delivered_at)

    // TODO: ETA is the wrong word here
    let text: string
    const color = theme.colors['gray-7']

    if (state == DeliveryStates.DELIVERED) {
      // TODO: Include date of delivery, right now we don't always capture this
      const delivered_at = formatDateStringShort(actual_delivered_at || marked_delivered_at)
      text = `Delivered on ${delivered_at}`
    } else if (estimated_delivered_at && carrier) {
      text = `${carrier}, ETA: ${formattedDeliveryDate}`
    } else if (estimated_delivered_at && !carrier) {
      text = `ETA: ${formattedDeliveryDate}`
    } else if (!estimated_delivered_at && carrier) {
      text = `${carrier}, ETA: Unknown`
    } else if (requested_delivered_at) {
      text = `Requested on ${formatDateStringShort(requested_delivered_at)}`
    }

    if (!text) return null

    return (
      <Box display="flex" alignItems="center" style={{ gap: 10, ...boxStyle }}>
        <Typography.Text ellipsis style={{ color }}>
          {text}
        </Typography.Text>

        {delivery.has_open_issue && (
          <Tooltip title={delivery_issues}>
            <Tag color="red" style={{ margin: 0 }}>
              {formatEnumValue(delivery_issue_type)}
            </Tag>
          </Tooltip>
        )}
      </Box>
    )
  },
)

export default DeliveryInfo
