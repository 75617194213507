import React from 'react'

import styled from '@emotion/styled'

import { CloseOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

type ActionsProps = {
  notificationId: string
  read: boolean
  onDismiss: (id: string) => void
}

const DismissButton = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: black;
  font-size: 12px;
`

const BlueDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border-radius: 50%;
  position: absolute;
  right: 28px;
  top: 50%;
  transform: translateY(-50%);
`

export const Actions: React.FC<ActionsProps> = ({ notificationId, read, onDismiss }) => {
  if (read) return null

  return (
    <>
      <BlueDot />
      <Tooltip title="Dismiss message" placement="right">
        <DismissButton
          onClick={(e) => {
            e.stopPropagation()
            onDismiss(notificationId)
          }}
        >
          <CloseOutlined />
        </DismissButton>
      </Tooltip>
    </>
  )
}
