import React from 'react'

import moment from 'moment'

import { Typography } from 'antd'
import { TextProps } from 'antd/lib/typography/Text'

import { OrdersList } from 'common/components/OrdersList'
import { PsqlColumn } from 'common/components/PsqlTable/psql_table_provider'
import { currencyFormatter } from 'common/helpers/formatters'

type FormattedTextProps = {
  value: string | number
  column: PsqlColumn
} & TextProps

export function CellFormattedText(props: FormattedTextProps) {
  const { value, column, ...rest } = props

  if (value === undefined || value === null || (typeof value === 'string' && value.length === 0)) {
    return <OrdersList.Cell.NoContent />
  }

  if (column.format === 'date') {
    return <span>{(value && moment(value).format('ddd. MMM Do, YYYY')) ?? '-'}</span>
  } else if (column.format === 'currency') {
    return <span>{currencyFormatter(value, 2)}</span>
  } else {
    return (
      <Typography.Text ellipsis={column.ellipsis} {...rest}>
        {value}
      </Typography.Text>
    )
  }
}
