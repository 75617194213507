import React from 'react'

import { components, ControlProps, OptionProps } from 'react-select'

import styled from '@emotion/styled'

import { Typography, Tooltip } from 'antd'

import { FlexBoxY, FlexBoxX, Box } from 'common/components/boxes'

import { Select, Select2Props } from './select'

interface Option {
  value: string
  label: string
  logo?: string
  description?: string
  showSubBaseLogo?: boolean
  [key: string]: unknown
}

export type SelectWithLogoProps = Select2Props<Option>

const Wrapper = styled(FlexBoxY)`
  & > div {
    width: 100%;
  }
`

const Option = ({ children, ...props }: OptionProps<Option>) => {
  const { data } = props

  return (
    <components.Option {...props}>
      {data?.showSubBaseLogo && <img src="/subbase_logo_short.svg" width="24px" height="24px" />}

      <Box ml="4px" display="grid" flexGrow={1}>
        {children}

        {data?.description && (
          <Tooltip title={data?.description} placement="right">
            <Typography.Text ellipsis style={{ fontSize: 12, color: 'inherit' }}>
              {data?.description}
            </Typography.Text>
          </Tooltip>
        )}
      </Box>

      {data?.logo && <img src={data?.logo} width="24px" height="24px" />}
    </components.Option>
  )
}

const Control = ({ children, ...props }: ControlProps<Option>) => {
  const data = props.selectProps.value as Option

  return (
    <components.Control {...props}>
      <FlexBoxX justifyContent="space-between" mx="4px">
        <FlexBoxX>
          {data?.showSubBaseLogo && <img src="/subbase_logo_short.svg" width="24px" height="24px" />}
          <Wrapper ml="4px" alignItems="baseline">
            {children[0]}
          </Wrapper>
        </FlexBoxX>

        {data?.logo && <img src={data?.logo} width="24px" height="24px" />}
      </FlexBoxX>
      {children[1]}
    </components.Control>
  )
}

export const SelectWithLogo = (props: SelectWithLogoProps) => {
  return (
    <Select
      defaultOptions
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      components={{ Option, Control } as any}
      styles={{
        menuList: (css) => ({ ...css, maxHeight: 500 }),
        option: (css) => ({ ...css, display: 'flex', alignItems: 'center' }),
      }}
      {...props}
    />
  )
}
