import axios from 'axios'

import { StandardIndexRequest, StandardIndexResponse } from 'common/server/server_types'

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ConsolidatedManufacturerMaterials {
  export interface ManufacturerMaterials {
    id: string
    manufacturer_material_id: string
    description: string
    group?: string
    product_identifier?: string
    size?: string
    sub_group?: string
    sub_group1?: string
    sub_group2?: string
    upc?: string
    material?: string
    manufacturer_name?: string
    manufacturer_domain?: string
    source?: string
    source_id?: string
    image_url?: string
    company_id: 'CATALOG'
    is_catalog: boolean // Always true
  }

  export type Response = StandardIndexResponse<Array<ManufacturerMaterials>>
}

export async function index(request: StandardIndexRequest) {
  const { filters, ...restRequest } = request
  const url = `/manufacturer_materials`

  return axios.get<ConsolidatedManufacturerMaterials.Response>(url, {
    params: {
      ...filters,
      ...restRequest,
    },
  })
}
