import React from 'react'

import { Popover } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { OrdersList } from 'common/components/OrdersList'
import Tags from 'common/components/tags'

interface Props {
  orderId: string
  tags: string[]
  value: string[]
  onChange: (tags: string[]) => Promise<void>
}

export function CellTag(props: Props) {
  const { tags, value, onChange } = props

  const content = (
    <FlexBoxX
      flexGrow={0}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
      minWidth="200px"
    >
      <Tags value={value} onChange={onChange} tags={tags} />
    </FlexBoxX>
  )

  return (
    <Popover content={content}>
      <FlexBoxX alignItems="flex-start" justifyContent="flex-start" height="30px">
        <OrdersList.Cell.Tags tags={value} />
      </FlexBoxX>
    </Popover>
  )
}
