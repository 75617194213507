import React from 'react'

import { message, Select } from 'antd'

import { observer } from 'mobx-react-lite'

import { formatEnumValue } from 'common/helpers/formatters'

import { useStores } from 'contractor/hooks/use-stores'
import { ManagedUser } from 'contractor/server/company_settings/manage_users'

type RoleSelectProps = ManagedUser

export const RoleSelect = observer<RoleSelectProps>(
  ({ id, first_name, last_name, role, is_active, is_account_user }) => {
    const { companySettingStore, userStore } = useStores()

    const handleChangeRole = async (selectedRole) => {
      const fullName = `${first_name} ${last_name}` || 'this user'
      try {
        await companySettingStore.updateUser({ user_id: id, role_id: selectedRole.value })
        message.success(`Changed role to: ${formatEnumValue(selectedRole.label)}`)
      } catch (error) {
        const errorMessage = error?.response?.data?.error || `Unable to modify ${fullName}'s role`
        message.error(errorMessage)
      }
    }

    //It does not allow account users to change their role
    if (userStore.canManageCompanySettings && !is_account_user) {
      const options = companySettingStore.roles.map((currentRole) => ({
        value: currentRole.id,
        label: formatEnumValue(currentRole.name),
      }))

      return (
        <Select
          style={{ width: '100%' }}
          filterOption={(input, option) => {
            const inputValue = input.toLowerCase()
            const label = option?.label as string
            return label?.toLowerCase().includes(inputValue)
          }}
          showSearch
          options={options}
          onChange={handleChangeRole}
          value={role?.id}
          disabled={!is_active}
          labelInValue
        />
      )
    }

    return <span>{formatEnumValue(role.name)}</span>
  },
)
