import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { PageHeader } from 'antd'

import { Box, FlexBoxY } from 'common/components/boxes'

import { HeaderProps } from './page'

const filter = css`
  filter: drop-shadow(0px 2px 2px rgb(0 0 0 / 5%));
  z-index: 2;
`

export const HeaderStyled = styled(Box)<HeaderProps>`
  ${({ showShadow }) => showShadow && filter}

  & .ant-page-header {
    padding: 0;
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    & .ant-page-header-heading-extra {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .ant-page-header-heading-title,
    & .ant-page-header-heading-left {
      flex-grow: 1;
    }

    & .ant-page-header-heading-title {
      margin: 0;
    }
  }
`

export const PageStyled = styled(FlexBoxY)`
  & .sb-page-tabs {
    width: 100%;
    height: 100%;
    overflow: auto;

    & > .ant-tabs-content-holder {
      overflow: auto;
    }

    & > .ant-tabs-content-holder,
    & > .ant-tabs-content-holder > .ant-tabs-content,
    & > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
      width: 100%;
      height: 100%;
    }

    & > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane-active {
      display: flex;
      flex-direction: column;
    }

    & > .ant-tabs-nav {
      background-color: ${({ theme }) => theme.colors.white};
      padding: 0px 16px 0px;
      margin: 0;

      &:before {
        right: 16px;
        left: 16px;
      }
    }

    @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
      & > .ant-tabs-nav {
        padding: 0;

        &:before {
          right: 0;
          left: 0;
        }

        & .ant-tabs-tab {
          padding: 12px;
        }
      }
    }
  }

  & .sb-page-header ~ .sb-page-tabs > .ant-tabs-nav {
    ${filter}

    &:before {
      border: none;
    }
  }
`

export const Header = styled(PageHeader)`
  .ant-page-header-heading-left {
    align-items: baseline;
  }
  .ant-page-header-heading-extra {
    display: flex;
    align-items: baseline;
  }
`
