import React from 'react'

import { uniq } from 'lodash'

import { CheckCircleOutlined, ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { Popover, Table } from 'antd'

import { Box } from 'common/components/boxes'
import { OrderMaterial } from 'common/server/orders'

type MappedStatusProps = {
  orderMaterial: OrderMaterial
}

const getOrderMaterialUnit = (orderMaterial: OrderMaterial) => {
  return orderMaterial?.company_material?.unit?.name?.trim() || orderMaterial?.company_material?.unit_name?.trim() || ''
}

const getOrderMaterialQuantity = (orderMaterial: OrderMaterial) =>
  Number(orderMaterial?.quantity_delivered ?? orderMaterial?.quantity ?? 0)

const getUniqueInvoiceMaterialsQuantities = (orderMaterial: OrderMaterial) => {
  const quantities = orderMaterial?.invoice_materials?.map(
    (invoiceMaterial) => Number(invoiceMaterial?.quantity_shipped) || 0,
  )
  return uniq(quantities)
}

const getUniqueInvoiceMaterialsUnits = (orderMaterial: OrderMaterial) => {
  const units = orderMaterial?.invoice_materials?.map(
    (invoiceMaterial) => invoiceMaterial?.unit?.name?.trim() || invoiceMaterial?.uom?.trim() || '',
  )
  return uniq(units)
}

export const MappedStatus = ({ orderMaterial }: MappedStatusProps) => {
  const getAttributes = (orderMaterial) => {
    if (!orderMaterial?.invoice_materials?.length) {
      return {
        color: 'error',
        title: 'Discrepancies',
        icon: <ExclamationCircleOutlined />,
        dataSource: [],
      }
    }

    if (orderMaterial.is_remaining) {
      return {
        color: 'info',
        title: 'Invoice Material',
        icon: <WarningOutlined />,
      }
    }

    const invoiceMaterialsQuantities = getUniqueInvoiceMaterialsQuantities(orderMaterial)
    const invoiceMaterialsUnits = getUniqueInvoiceMaterialsUnits(orderMaterial)

    const orderMaterialUnit = getOrderMaterialUnit(orderMaterial)
    const orderMaterialQuantity = getOrderMaterialQuantity(orderMaterial)

    const isQuantityMatch =
      invoiceMaterialsQuantities?.length <= 1 && orderMaterialQuantity === invoiceMaterialsQuantities[0]
    const isUnitMatch = invoiceMaterialsUnits?.length <= 1 && orderMaterialUnit === invoiceMaterialsUnits[0]

    if (isUnitMatch && isQuantityMatch) {
      return { color: 'success', title: 'Perfect match!', icon: <CheckCircleOutlined />, dataSource: [] }
    }

    const dataSource = []
    if (!isUnitMatch) {
      dataSource.push({ field: 'UOM', invoice: orderMaterialUnit, order: invoiceMaterialsUnits.join(', ') })
    }

    if (!isQuantityMatch) {
      dataSource.push({
        field: 'Qty',
        invoice: orderMaterialQuantity,
        order: invoiceMaterialsQuantities.join(', '),
      })
    }

    return {
      color: 'warning',
      title: 'Discrepancies',
      icon: <WarningOutlined />,
      dataSource,
    }
  }

  const { color, dataSource, icon, title } = getAttributes(orderMaterial)

  return (
    <Box color={color} m="0 auto" display="flex" alignItems="center" justifyContent="center">
      <Popover
        title={title}
        placement="topRight"
        content={
          dataSource?.length && (
            <Table
              pagination={false}
              size="small"
              columns={[
                { dataIndex: 'field' },
                { title: 'Invoice', dataIndex: 'invoice' },
                { title: 'Order', dataIndex: 'order' },
              ]}
              dataSource={dataSource}
              style={{ minWidth: 200 }}
              locale={{
                emptyText: 'No matching line item in order.',
              }}
            />
          )
        }
      >
        {icon}
      </Popover>
    </Box>
  )
}
