import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'

export const ErrorState = () => {
  return (
    <Box justifyContent="center" alignContent="center" height="100%">
      <Typography.Text>Oops! Something went wrong.</Typography.Text>
      <Typography.Text>
        If you are seeing this screen repeatedly, please contact:
        <Typography.Link href="mailto: help@subbase.io">help@subbase.io</Typography.Link>
      </Typography.Text>
    </Box>
  )
}
