import React, { useState, useCallback, useEffect } from 'react'

import { Calendar, momentLocalizer } from 'react-big-calendar'

import moment from 'moment'

import styled from '@emotion/styled'

import { message } from 'antd'

import { observer } from 'mobx-react-lite'

import { Page } from 'common/components/Page'
import { useTheme } from 'common/hooks/use-theme'
import { deliveryRequests } from 'common/server/deliveries'
import { DeliveryStates, OrderStates } from 'common/server/server_types'

import { DeliveryDetail } from 'contractor/components/DeliveryDetail'
import ProjectSelector from 'contractor/components/ProjectSelector'
import { useStores } from 'contractor/hooks/use-stores'

import { withCalendarProvider, useCalendar } from './context'
import { DeliveryEvent } from './event'

// import "react-big-calendar/lib/css/react-big-calendar.css";
// https://github.com/jquense/react-big-calendar/issues/234
// import '!style-loader!css-loader!react-big-calendar/lib/css/react-big-calendar.css'

const localizer = momentLocalizer(moment)

const CalendarStyled = styled(Calendar)`
  width: 100%;

  & .rbc-btn-group,
  & .rbc-btn-group,
  & .rbc-agenda-view,
  & .rbc-month-view {
    background-color: ${({ theme }) => theme.colors.white};
  }

  & .rbc-agenda-view,
  & .rbc-month-view {
    border-radius: ${({ theme }) => theme.radii.md};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    .rbc-toolbar {
      flex-direction: column;

      .rbc-toolbar-label {
        margin: 8px;
      }
    }
  }
`

const CalendarOverview = observer(() => {
  const { deliveryStore, projectStore } = useStores()
  const theme = useTheme()

  const { deliveryDetailRef } = useCalendar()

  const [date, setDate] = useState<Date>(moment().toDate())
  const [currentView, setCurrentView] = useState('month')

  const [deliveries, setDeliveries] = useState([])

  const fetchDeliveries = useCallback(async () => {
    // Choose a window which adds a week to either end of the month so we can show deliveries in the previous or next month
    // That are viewable in our "month" view
    const startDate = moment(date).startOf('month').subtract(7, 'days')
    const endDate = moment(date).endOf('month').add(7, 'days')

    const deliveries = await deliveryRequests.index({
      project_id: projectStore.selectedProject?.id,
      project_active: true,
      order_state: [OrderStates.ORDERED, OrderStates.SHIPPED, OrderStates.PARTIALLY_SHIPPED, OrderStates.DELIVERED],
      best_guess_delivered_at_start: startDate.toDate(),
      best_guess_delivered_at_end: endDate.toDate(),
    })

    setDeliveries(
      deliveries.records.map((delivery) => ({
        title: delivery.order_name,
        start: delivery.best_guess_delivered_at && moment(delivery.best_guess_delivered_at).toDate(),
        end: delivery.best_guess_delivered_at && moment(delivery.best_guess_delivered_at).toDate(),
        allDay: true,
        hit: delivery,
      })),
    )
  }, [projectStore.selectedProject?.id, date])

  useEffect(() => {
    fetchDeliveries()

    // Refetch deliveries each 10 seconds
    const timer = setInterval(() => {
      fetchDeliveries()
    }, 10000)
    return () => clearInterval(timer)
  }, [fetchDeliveries])

  // Based on delivery state colors
  const eventPropGetter = (event) => {
    let color = ''
    let borderStyle = 'solid'
    let borderColor = 'solid'
    const { state } = event.hit
    switch (state) {
      case DeliveryStates.REQUESTED:
        color = theme.colors['gold-1']
        borderColor = theme.colors['gold-3']
        borderStyle = 'dashed'
        break
      case DeliveryStates.SCHEDULED:
        color = theme.colors['gray-2']
        borderColor = theme.colors['gray-5']
        break
      case DeliveryStates.SHIPPED:
        color = theme.colors['yellow-1']
        borderColor = theme.colors['yellow-6']
        break
      case DeliveryStates.DELIVERED:
        color = theme.colors['green-1']
        borderColor = theme.colors['gray-4']
        break
    }
    return {
      style: {
        backgroundColor: color,
        color: theme.colors.primary,
        borderStyle,
        borderColor,
        borderWidth: '2px',
      },
    }
  }

  return (
    <Page>
      <Page.Header>
        <ProjectSelector showSettings showAddProject />
      </Page.Header>

      <Page.Content>
        <CalendarStyled
          localizer={localizer}
          events={deliveries}
          onNavigate={(newDate) => setDate(newDate)}
          // Want agenda view to always show a full week
          date={currentView == 'agenda' ? moment(date).startOf('week').toDate() : date}
          length={7}
          views={['month', 'agenda', 'day']}
          view={currentView}
          onView={setCurrentView}
          titleAccessor={(event) => <DeliveryEvent delivery={event.hit} />}
          eventPropGetter={eventPropGetter}
          tooltipAccessor={null}
          popup
        />
      </Page.Content>

      <DeliveryDetail
        ref={deliveryDetailRef}
        onClose={() => {
          deliveryDetailRef.current?.close()
          deliveryStore.selectDelivery(null)
        }}
        orderDelivery={deliveryStore.selectedDelivery?.delivery}
        onUpdate={async (delivery) => {
          await deliveryStore.updateDelivery(delivery)
          deliveryDetailRef.current?.close()
          deliveryStore.selectDelivery(null)
          message.success('Delivery updated')
          fetchDeliveries()
        }}
        project_ids={[deliveryStore.selectedDelivery?.order.project_id]}
        orderInfo={deliveryStore.selectedDelivery?.order}
      />
    </Page>
  )
})

export default withCalendarProvider(CalendarOverview)
