import React from 'react'

import styled from '@emotion/styled'

import { Space, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX } from 'common/components/boxes'
import { isStaging } from 'common/helpers/check_environment'
import { UserRoles } from 'common/server/server_types'

import { usePublicOrderForm } from './context'

const Link = styled.a`
  color: ${({ theme }) => theme.colors.white};
  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`

const headerStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexGrow: 0,
}

const TopBar = observer(() => {
  const { user, redirectToUserRegistration } = usePublicOrderForm()

  const isMaterialRequester = (user) => user?.role?.name === UserRoles.MATERIAL_REQUESTER

  // noinspection HtmlUnknownTarget
  return (
    <FlexBoxX
      px={{ _: 16, sm: 24 }}
      bg="primary"
      color="white"
      zIndex="sticky"
      {...headerStyle}
      className={isStaging() && 'navbar-staging'}
    >
      <img
        onClick={() => (window.location.href = '/')}
        src="../subbase_logo_short.png"
        style={{ width: '40px', height: '40px', cursor: 'pointer' }}
        alt="subbase-logo"
      />

      <Space size="middle">
        {(!user || isMaterialRequester(user)) && (
          <Tooltip title="Click here if you want to get one email and password to sign in" placement="bottomRight">
            <Link data-cy="register-link" href="#" onClick={redirectToUserRegistration}>
              Register
            </Link>
          </Tooltip>
        )}
        {user && !isMaterialRequester(user) && (
          <Tooltip title="Click here if you already have an email and password to sign in" placement="bottomRight">
            <Link data-cy="login-link" href="/">
              Login
            </Link>
          </Tooltip>
        )}
      </Space>
    </FlexBoxX>
  )
})

export default TopBar
