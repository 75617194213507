import { extend } from 'lodash'

import { OrderMaterial } from 'common/server/orders'

export const formatOrderMaterialUnitCost = (orderMaterial: OrderMaterial) => {
  // Spreadsheet return an empty string when the unit cost is clean
  if (typeof orderMaterial.unit_cost === 'string' && !orderMaterial.unit_cost) {
    return {
      ...orderMaterial,
      unit_cost: null,
    }
  }

  return {
    ...orderMaterial,
    unit_cost: Number(orderMaterial.unit_cost),
  }
}

/*
  On order materials v2 we add the "is_new: true" prop when the user change unit from the material,
  so here we remove the company material id to backend know this material could be new.
*/
export const resetCompanyMaterialId = (orderMaterial) => {
  let currentOrderMaterial = extend(true, {}, orderMaterial)

  if (
    !!orderMaterial?.unit &&
    orderMaterial?.unit?.value === orderMaterial?.unit?.label &&
    orderMaterial?.unit?.label !== orderMaterial?.company_material?.unit_name
  ) {
    currentOrderMaterial = {
      ...currentOrderMaterial,
      company_material: {
        ...currentOrderMaterial.company_material,
        unit_name: orderMaterial?.unit?.label,
        unit_id: null,
        id: null,
      },
    }
  }

  if (
    !!orderMaterial?.unit &&
    orderMaterial?.unit?.value !== orderMaterial?.unit?.label &&
    orderMaterial?.unit?.value !== orderMaterial?.company_material?.unit_id
  ) {
    currentOrderMaterial = {
      ...currentOrderMaterial,
      company_material: {
        ...currentOrderMaterial.company_material,
        unit_name: null,
        unit_id: currentOrderMaterial?.unit?.value,
        id: null,
      },
    }
  }

  if (orderMaterial?.company_material?.is_catalog) {
    return {
      ...currentOrderMaterial,
      company_material: {
        ...currentOrderMaterial.company_material,
        id: null,
      },
    }
  }
  return currentOrderMaterial
}

export const makeCostCode = (orderMaterial: OrderMaterial) => ({
  ...orderMaterial,
  cost_code_id: orderMaterial?.cost_code?.id || null,
})

export const makeCostCodePhase = (orderMaterial: OrderMaterial) => ({
  ...orderMaterial,
  cost_code_phase_id: orderMaterial?.cost_code_phase?.id || null,
})

export const makeOrderMaterial = (orderMaterial: OrderMaterial) => ({
  ...orderMaterial,
  company_material_id: orderMaterial?.company_material?.id,
})

export const canCreateNewCompanyMaterial = (orderMaterial, canCreateNewMaterial) => {
  if (canCreateNewMaterial) {
    return true
  }

  return !!orderMaterial?.company_material?.id
}

export const roundValue = (value: number, precision = 2) => {
  const factor = Math.pow(10, precision)
  return Math.round((value + Number.EPSILON) * factor) / factor
}

export const calcExtCost = ({
  unitCost: unitCostProp = 0,
  quantity: quantityProp = 0,
  multiplier: multiplierProp = 1,
  qtyIncrement: qtyIncrementProp = 1,
  precision = 3,
}) => {
  const unitCost = Number(unitCostProp) || 0
  const quantity = Number(quantityProp) || 0

  const multiplier = Number(multiplierProp) || 1
  const qtyIncrement = Number(qtyIncrementProp) || 1

  const extCost = (unitCost * quantity * qtyIncrement) / multiplier

  return roundValue(extCost, precision)
}

export const calcInvoiceTaxSplitBulk = ({
  taxAmount = 0,
  invoiceMaterials = [],
  taxLineItemsEnabled = false,
  precision = 2,
}) => {
  if (!taxAmount || taxAmount === 0 || !taxLineItemsEnabled) {
    return invoiceMaterials.map((invoiceMaterial) => ({ ...invoiceMaterial, tax_split: 0 }))
  }

  const eligibleTaxSplitTotal = invoiceMaterials.reduce((total, material) => {
    if (material.accepts_tax_split) {
      const extendedCost = Number(material?.extended_price) || 0
      return total + extendedCost
    }

    return total
  }, 0)

  if (eligibleTaxSplitTotal === 0) {
    return invoiceMaterials.map((invoiceMaterial) => ({ ...invoiceMaterial, tax_split: 0 }))
  }

  let totalSplit = 0.0
  const updatedMaterials = invoiceMaterials.map((invoiceMaterial, index, array) => {
    if (!invoiceMaterial.accepts_tax_split) {
      return { ...invoiceMaterial, tax_split: 0 }
    }

    let taxSplitValue
    if (index === array.length - 1) {
      // For the last material, adjust to ensure the total is correct
      taxSplitValue = taxAmount - totalSplit
      taxSplitValue = parseFloat(taxSplitValue.toFixed(precision))
    } else {
      const extendedCost = Number(invoiceMaterial?.extended_price) || 0
      taxSplitValue = taxAmount * (extendedCost / eligibleTaxSplitTotal)
      taxSplitValue = parseFloat(taxSplitValue.toFixed(precision))
    }
    totalSplit += taxSplitValue

    return { ...invoiceMaterial, tax_split: taxSplitValue }
  })

  // Find the last eligible material that accepts tax split
  const lastEligibleMaterialIndex =
    updatedMaterials.length - 1 - [...updatedMaterials].reverse().findIndex((material) => material.accepts_tax_split)

  // Adjust any discrepancies due to rounding
  const adjustment = parseFloat((taxAmount - totalSplit).toFixed(precision))
  if (adjustment !== 0 && lastEligibleMaterialIndex >= 0) {
    const correctedValue = updatedMaterials[lastEligibleMaterialIndex].tax_split + adjustment
    updatedMaterials[lastEligibleMaterialIndex].tax_split = parseFloat(correctedValue.toFixed(precision))
  }

  return updatedMaterials
}

export const calcOrderTaxSplitBulk = ({
  taxAmount = 0,
  orderMaterials = [],
  taxLineItemsEnabled = false,
  precision = 2,
}) => {
  if (!taxAmount || taxAmount === 0 || !taxLineItemsEnabled) {
    return orderMaterials.map((orderMaterial) => ({ ...orderMaterial, tax_value: 0 }))
  }

  const calculteExtCost = (material) => {
    const unit = material['unit']?.['original'] || material?.company_material?.unit
    return calcExtCost({
      unitCost: material?.unit_cost,
      quantity: material?.quantity,
      multiplier: unit?.multiplier,
      qtyIncrement: unit?.qty_increment,
      precision,
    })
  }

  const subtotal = orderMaterials.reduce((total, material) => {
    const extCost = calculteExtCost(material)
    return total + extCost
  }, 0)

  if (subtotal === 0) {
    return orderMaterials.map((orderMaterial) => ({ ...orderMaterial, tax_value: 0 }))
  }

  let totalSplit = 0.0
  const updatedMaterials = orderMaterials.map((orderMaterial, index, array) => {
    let taxSplitValue
    if (index === array.length - 1) {
      // For the last material, adjust to ensure the total is correct
      taxSplitValue = taxAmount - totalSplit
      taxSplitValue = parseFloat(taxSplitValue.toFixed(precision))
    } else {
      const extCost = calculteExtCost(orderMaterial)
      taxSplitValue = taxAmount * (extCost / subtotal)
      taxSplitValue = parseFloat(taxSplitValue.toFixed(precision))
    }
    totalSplit += taxSplitValue

    return { ...orderMaterial, tax_value: taxSplitValue }
  })

  // Adjust any discrepancies due to rounding
  const adjustment = parseFloat((taxAmount - totalSplit).toFixed(precision))
  if (adjustment !== 0) {
    const lastIndex = updatedMaterials.length - 1
    const correctedValue = updatedMaterials[lastIndex].tax_value + adjustment
    updatedMaterials[lastIndex].tax_value = parseFloat(correctedValue.toFixed(precision))
  }

  return updatedMaterials
}
