import React, { useEffect, useMemo, useState } from 'react'

import styled from '@emotion/styled'

import { Alert, Button, Checkbox, Space, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { Loading } from 'common/components/Loading'
import { InvoiceInboxFilesStates } from 'common/server/server_types'

import { CreateFailedInvoiceDrawer } from 'contractor/pages/Invoices/Add/create_failed_invoice_drawer'
import { InvoiceInboxFileHit } from 'contractor/server/invoices/inbox'
import { ConsolidatedInvoiceInboxes } from 'contractor/server/invoices/inbox/consolidated_invoice_inboxes'

type InvoicePdfSelectorProps = {
  inbox_id: string
  files: ConsolidatedInvoiceInboxes.File[]
  onClose: () => void
}

type PdfOptions = {
  label: string
  value: string
  checked: boolean
}

const CheckboxStyled = styled(Checkbox)`
  .ant-checkbox + span {
    width: 330px;
  }
`

const mountOptions = (files: InvoiceInboxFileHit[]): PdfOptions[] => {
  return (
    files
      ?.filter((f) => f.state === InvoiceInboxFilesStates.FAILED)
      .map((file) => {
        return {
          label: file.filename,
          value: file.id,
          checked: false,
        }
      }) || []
  )
}

export type PdfStep = {
  file_id: string
  step: number
}

export type FailedInboxPdfs = {
  inbox_id: string
  steps: PdfStep[]
  total: number
}

function Element(props: InvoicePdfSelectorProps, ref: React.RefObject<DrawerRef>) {
  const { inbox_id, files, onClose } = props

  const createFailedInvoiceDrawerRef = React.useRef<DrawerRef>()
  const [selectedPdfFiles, setSelectedPdfFiles] = useState<FailedInboxPdfs>({
    inbox_id: '',
    steps: [],
    total: 0,
  })

  const options = useMemo((): PdfOptions[] => mountOptions(files), [files])
  const [checkedList, setCheckedList] = useState<PdfOptions[]>(options)

  function onChange(e) {
    setCheckedList((prevState) => {
      return prevState.map((item) => {
        if (item.value === e.target.value) {
          return { ...item, checked: e.target.checked }
        }
        return item
      })
    })
  }

  function next() {
    const selectedFileIds = checkedList.filter((elem) => elem.checked).map((el) => el.value)
    setSelectedPdfFiles({
      inbox_id: inbox_id,
      steps: selectedFileIds.map((file_id, idx) => {
        return {
          file_id,
          step: idx + 1,
        }
      }),
      total: selectedFileIds.length,
    })

    createFailedInvoiceDrawerRef.current?.show()
  }

  function handleClose() {
    ref.current?.close()
    createFailedInvoiceDrawerRef.current?.close()

    onClose()
  }

  useEffect(() => {
    setCheckedList(options)
  }, [options])

  return (
    <Drawer
      ref={ref}
      width={378}
      forceRender
      title={
        <Box display="flex" justifyContent="space-between">
          <Typography.Text style={{ paddingTop: 5 }} strong>
            Select Files
          </Typography.Text>
          <Box display="inline-flex" justifyContent="flex-end">
            <Button disabled={!checkedList?.some((elem) => elem.checked)} type="primary" onClick={next}>
              Next
            </Button>
          </Box>
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" width="100%" m={20}>
        <Space direction="vertical" style={{ width: '100%', marginBottom: 10 }}>
          <Alert message="We are showing you only FAILED pdf files" type="warning" closable />
        </Space>
        <Typography.Text style={{ paddingBottom: 10 }}>Which PDF files do you want to create Invoices?</Typography.Text>
        {options.length === 0 && <Loading />}
        {options.length > 0 &&
          options.map((opt) => (
            <CheckboxStyled style={{ marginLeft: 0 }} key={opt.value} value={opt.value} onChange={onChange}>
              <Typography.Text ellipsis>{opt.label}</Typography.Text>
            </CheckboxStyled>
          ))}
      </Box>

      <CreateFailedInvoiceDrawer
        ref={createFailedInvoiceDrawerRef}
        failedInboxPdfs={selectedPdfFiles}
        onClose={handleClose}
      />
    </Drawer>
  )
}

export const InvoicePdfSelector = React.forwardRef(Element)
