import React from 'react'

import styled from '@emotion/styled/dist/emotion-styled.cjs'

import { Box, BoxProps } from 'common/components/boxes'

const StyledListItem = styled(Box)`
  transition: background 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors['gray-3']};
  }
`

type ListItemProps = BoxProps & {
  onClick?: () => void
  children: React.ReactNode
}

export const ListItem = (props: ListItemProps) => {
  return <StyledListItem {...props}>{props.children}</StyledListItem>
}
