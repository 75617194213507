import React from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Popover, Typography } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'
import { CompanyMaterialVendorPrice } from 'common/server/company_materials'

import { PriceLineItem } from './price_line_item'
import { UnitCostColumnProps } from './unit_cost'

export type PreferredVendorsProps = Pick<
  UnitCostColumnProps,
  'onChange' | 'companyVendorId' | 'defaultVendors' | 'hideDetails'
> & {
  defaultVendorSelected?: CompanyMaterialVendorPrice
  onChangeDefaultVendorSelected?: (defaultVendorSelected: CompanyMaterialVendorPrice) => void
}

export const PreferredVendors = ({
  onChange = null,
  companyVendorId = '',
  defaultVendors = [],
  hideDetails,
  defaultVendorSelected,
  onChangeDefaultVendorSelected,
}: PreferredVendorsProps) => {
  return (
    <FlexBoxX width="100%">
      <Typography.Text type="secondary" style={{ width: '100%', textAlign: 'end', marginRight: hideDetails ? 30 : 16 }}>
        {defaultVendorSelected?.company_vendor?.safe_globalized_vendor_name || 'No vendor'}
      </Typography.Text>
      <Popover
        title="Preferred Vendor"
        content={
          <Box maxWidth={275}>
            <Typography.Paragraph style={{ fontSize: 12 }}>
              This unit cost was autopopulated with the price configured for the last used preferred vendor.
              <br />
              You can select any other preferred vendor price below.
            </Typography.Paragraph>

            <Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" mr={28} mb={4}>
                <Typography.Text strong>Vendor</Typography.Text>
                <Typography.Text strong>Price</Typography.Text>
              </Box>
              {defaultVendors.map((defaultVendor) => (
                <PriceLineItem
                  key={defaultVendor.id}
                  value={Number(defaultVendor.price)}
                  companyVendorId={companyVendorId}
                  onChange={(value) => {
                    onChange(value, { defaultVendor })
                    onChangeDefaultVendorSelected(defaultVendor)
                  }}
                  title={defaultVendor.company_vendor.safe_globalized_vendor_name}
                  option={defaultVendor.company_vendor.safe_globalized_vendor_name}
                />
              ))}
            </Box>
          </Box>
        }
        arrowPointAtCenter={false}
        placement="bottomRight"
      >
        <QuestionCircleOutlined data-cy="preferred-vendors" />
      </Popover>
    </FlexBoxX>
  )
}
