import React, { useState } from 'react'

import pluralize from 'pluralize'

import { Button, message, Typography, Modal } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import Uploader from 'common/components/uploader'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

const UPLOAD_LIST_KEY = 'invoice_upload'

type InvoiceUploadProps = {
  onClose: () => void
}

export const InvoiceUpload = observer<InvoiceUploadProps, DrawerRef>(
  (props, ref) => {
    const { onClose } = props

    const canUseConsolidatedInvoicesInboxes = useFlag('consolidated_invoices_inboxes')

    const { uploaderStore, invoiceInboxStore } = useStores()

    const [isSubmitting, setIsSubmitting] = useState(false)

    const submit = async () => {
      if (!uploaderStore.checkIfAllUploadsCompleted()) {
        Modal.error({ title: 'Upload have not completed yet, wait until finish.' })
        return
      }

      const file_signed_ids = uploaderStore.signedIds(UPLOAD_LIST_KEY)
      const count = file_signed_ids.length
      setIsSubmitting(true)

      try {
        await invoiceInboxStore.uploadInvoices(file_signed_ids)

        if (canUseConsolidatedInvoicesInboxes) {
          invoiceInboxStore.listStore.fetchRecords()
        }

        message.success(`Successfully uploaded ${count} ${pluralize('file', count, false)}`)
        uploaderStore.resetAllUploads()
        onClose()
      } catch {
        message.error('Unable to upload')
      } finally {
        setIsSubmitting(false)
      }
    }

    return (
      <Drawer
        closable={false}
        onClose={onClose}
        ref={ref}
        width={378}
        title={
          <Box display="flex" width="100%" height="100%" alignItems="flex-start" justifyContent="space-between">
            <Box display="inline-flex" alignItems="flex-start">
              <Typography.Text style={{ margin: 0, paddingTop: 5 }}>Manual Upload</Typography.Text>
            </Box>
            <Box display="inline-flex" alignItems="flex-end">
              <Button
                style={{ margin: 0 }}
                type="primary"
                disabled={
                  uploaderStore.fileList(UPLOAD_LIST_KEY).length === 0 ||
                  uploaderStore.signedIds(UPLOAD_LIST_KEY).length === 0
                }
                onClick={submit}
                loading={isSubmitting}
              >
                Upload Invoices
              </Button>
            </Box>
          </Box>
        }
        forceRender
      >
        <Box display="flex" flexDirection="column" overflow="auto" width="100%" height="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center" style={{ padding: 20 }}>
            <Uploader
              fileList={uploaderStore.fileList(UPLOAD_LIST_KEY)}
              uploadKey={UPLOAD_LIST_KEY}
              multiple
              accept="application/pdf"
              data-cy="attach-invoice"
              component="Dragger"
              showPreviewModal
              listType="text"
              showDownload
              onRemoveUpload={uploaderStore.removeUpload}
              onAddNewUpload={uploaderStore.addNewUpload}
              onResetUploads={uploaderStore.resetUploads}
              onSetUploadError={uploaderStore.setUploadError}
              onUpdateUpload={uploaderStore.updateUpload}
            />
          </Box>
        </Box>
      </Drawer>
    )
  },
  { forwardRef: true },
)
