import React, { useRef } from 'react'

import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'

import { InvoiceInboxDetailsDrawer } from 'contractor/components/InvoiceInboxDetailsDrawer'
import { useStores } from 'contractor/hooks/use-stores'

export const SourceFrom = observer(() => {
  const { invoiceStore } = useStores()

  const detailDrawerRef = useRef<DrawerRef>()

  const invoice = invoiceStore.invoice

  return (
    <>
      <InvoiceInboxDetailsDrawer
        invoiceInboxId={invoice?.invoice_inbox_id}
        invoicesInboxSourceId={invoice?.invoice_source_id}
        invoiceInboxType={invoice?.invoice_source_type}
        ref={detailDrawerRef}
      />

      <span onClick={() => invoice?.invoice_inbox_id && detailDrawerRef.current?.show()}>Source From</span>
    </>
  )
})
