import { OrderStates } from 'common/server/server_types'

export const getOrderTitleType = (state: OrderStates) => {
  const emptyStates = [OrderStates.DRAFT, OrderStates.REQUESTED, OrderStates.CANCELLED]
  if (emptyStates.includes(state)) {
    return ''
  }

  if (state === OrderStates.QUOTED) {
    return 'RFQ'
  }

  return 'Order'
}
