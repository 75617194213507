import React from 'react'

import { Typography, Space } from 'antd'

import { FlexBoxY } from 'common/components/boxes'
import { LinkNext, LinkPrevious } from 'common/components/History'
import { EventChange } from 'common/server/invoice_history'

type OrdersProps = {
  orders: EventChange[]
}

export const Orders = ({ orders = [] }: OrdersProps) => {
  return (
    <FlexBoxY alignItems="flex-start" width="100%" mb="8px">
      <Typography.Text>
        Orders:
        <Space style={{ marginLeft: 8 }}>
          {orders.map(({ snapshot, event }) => {
            const quickPOCreated = snapshot?.['Quick created at'] ? ' - (Quick PO created)' : ''

            if (event === 'update') {
              return (
                <LinkPrevious key={snapshot?.['Order Id']} href={`/order/${snapshot?.['Order Id']}`} target="_blank">
                  {snapshot?.['PO Number']}
                  {quickPOCreated}
                </LinkPrevious>
              )
            }

            return (
              <LinkNext key={snapshot?.['Order Id']} href={`/order/${snapshot?.['Order Id']}`} target="_blank">
                {snapshot?.['PO Number']}
                {quickPOCreated}
              </LinkNext>
            )
          })}
        </Space>
      </Typography.Text>
    </FlexBoxY>
  )
}
