import React from 'react'

import { LibraryAddCheck } from '@styled-icons/material-twotone'

import { Empty, Typography } from 'antd'

import { useTheme } from 'common/hooks/use-theme'

type EmptyNoMatchesProps = {
  onClick: React.MouseEventHandler<HTMLElement>
}

export const EmptyNoMatches = ({ onClick }: EmptyNoMatchesProps) => {
  const theme = useTheme()

  return (
    <Empty
      image={<LibraryAddCheck style={{ color: theme.colors['green-2'] }} />}
      description={
        <Typography.Text>Congrats! All items in this invoice have been matched to an item you ordered.</Typography.Text>
      }
    >
      <Typography.Link onClick={onClick} style={{ textDecoration: 'underline' }}>
        Click here to finish reviewing the invoice.
      </Typography.Link>
    </Empty>
  )
}
