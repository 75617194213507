import React from 'react'

import { Col, Form, Row } from 'antd'

import SelectAddress from 'common/components/address/selector'
import { FlexBoxX } from 'common/components/boxes'
import DateTimePicker from 'common/components/date_time_picker'

import { useStores } from 'contractor/hooks/use-stores'
import { usePublicOrderForm } from 'contractor/pages/PublicOrderForm/context'

const colProps = { xs: 24, md: 12, lg: 10, xl: 10, xxl: 10 }
const colLayoutProps = { xs: 24, md: 24, lg: 12, xl: 12, xxl: 12 }

export const MaterialInputs = () => {
  const { publicOrderStore } = useStores()
  const { project } = usePublicOrderForm()

  const { company_addresses } = publicOrderStore.formParams

  const addressOptions = {
    company: company_addresses,
    project: (project?.addresses || []).map((address) => ({
      project_id: project?.id,
      project_name: project?.name,
      ...address,
    })),
  }

  return (
    <FlexBoxX width="100%" mt="5px" justifyContent="flex-start">
      <Row gutter={16} style={{ width: '100%', flexGrow: 1 }}>
        <Col {...colLayoutProps}>
          <Row gutter={16}>
            <Col {...colProps}>
              <Form.Item label="Address" name="address">
                <SelectAddress
                  placeholder={'Select address'}
                  addressOptions={addressOptions}
                  aria-label="address-select"
                  addNewAddressOptionVisible={false}
                  wrapperProps={{
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  disabled={!project}
                />
              </Form.Item>
            </Col>

            <Col {...colProps}>
              <Form.Item label="Deliver On" name="requestedDeliveredAt">
                <DateTimePicker
                  data-cy="delivered-at"
                  wrapperProps={{
                    width: '100%',
                  }}
                  disabled={!project}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </FlexBoxX>
  )
}
