import { InvoiceOrder, InvoiceResponse } from 'common/server/invoice'

import { ShowOrderResponse } from 'contractor/server/orders'

// Calculate the sum of the amount of a field in a list of orders
export const calculateOrderTotalAmount = (items: ShowOrderResponse[], valueKey: string) => {
  return items?.reduce((acc, item) => acc + Number(item[valueKey]) || 0, 0) || 0
}

// Calculate the sum of the amount of a field in multiple deliveries a list of orders
export const calculateOrderDeliveryTotal = (orders: InvoiceOrder[], valueKey: string) =>
  orders?.reduce((acc, { order }) => acc + calculateOrderTotalAmount(order.deliveries, valueKey), 0) || 0

// Calculate the sum of the amount of a field in a list of invoices
export const calculateInvoiceAmounts = (items: InvoiceResponse[], valueKey: string) => {
  return items?.reduce((acc, item) => acc + Number(item[valueKey]) || 0, 0) || 0
}

// Calculate the sum of the amount of a field in multiple invoices to use in the invoice page footer
export const calculateInvoicesAmounts = (invoices: InvoiceResponse[]) => {
  if (!invoices) return [0, 0, 0, 0]

  const invoiceShippingCost = () => calculateInvoiceAmounts(invoices, 'shipping_cost')

  const invoiceTaxValue = () => calculateInvoiceAmounts(invoices, 'tax_amount')

  const invoiceDiscountValue = () => calculateInvoiceAmounts(invoices, 'discount_amount')

  const invoiceOtherCostValue = () => calculateInvoiceAmounts(invoices, 'other_costs')

  return [invoiceDiscountValue(), invoiceTaxValue(), invoiceOtherCostValue(), invoiceShippingCost()]
}

// Calculate the sum of the amount of a field in multiple orders to use in the order page footer
export const calculateOrdersAmounts = (orders: InvoiceOrder[]) => {
  if (!orders?.length) return [0, 0, 0, 0]

  const ordersShippingCost = () => calculateOrderDeliveryTotal(orders, 'shipping_value')

  const ordersTaxValue = () => calculateOrderDeliveryTotal(orders, 'tax_value')

  const ordersDiscountValue = () => calculateOrderDeliveryTotal(orders, 'discount_value')

  const ordersOtherCostValue = () => calculateOrderDeliveryTotal(orders, 'other_value')

  return [ordersDiscountValue(), ordersTaxValue(), ordersOtherCostValue(), ordersShippingCost()]
}

export const extractInvoiceOrderDiscrepancy = (invoices: InvoiceResponse[], orders: InvoiceOrder[]) => {
  const [invoiceDiscountValue, invoiceTaxValue, invoiceOtherCostValue, invoiceShippingCost] =
    calculateInvoicesAmounts(invoices)
  const [orderDiscountValue, orderTaxValue, orderOtherCostValue, orderShippingCost] = calculateOrdersAmounts(orders)

  const issues = []

  if (invoiceDiscountValue > orderDiscountValue) {
    issues.push('Discount')
  }

  if (invoiceTaxValue > orderTaxValue) {
    issues.push('Tax')
  }

  if (invoiceOtherCostValue > orderOtherCostValue) {
    issues.push('Other Cost')
  }

  if (invoiceShippingCost > orderShippingCost) {
    issues.push('Shipping Cost')
  }

  return 'The remaining balance is due to the following discrepancies: ' + issues.join(', ')
}
