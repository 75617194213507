/* Load axios to set headers configuration */
import 'common/data-access/axios'
import React from 'react'

import { Button, Input, message, Space, Typography } from 'antd'

import { FlexBoxY } from 'common/components/boxes'
import { FlexBoxX } from 'common/components/boxes'
import { withErrorBoundary } from 'common/components/ErrorBoundary/error_boundary'
import History from 'common/pages/TestEmail/history'
import { post } from 'common/server/test_email'

const TestEmail: React.FC = () => {
  const [messageId, setMessageId] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [subject, setSubject] = React.useState('Subbase Test Email')
  const [body, setBody] = React.useState('This is a test message from Subbase!')

  const sendTestEmail = async () => {
    try {
      const { message_id } = (await post({ email, subject, message: body })).data
      message.success({ content: 'Email Sent', style: { marginTop: '85vh' } })
      setMessageId(message_id)
    } catch (err) {
      console.error(err.response)
      message.error({ content: 'Unable to send email', style: { marginTop: '85vh' } })
    }
  }

  return (
    <FlexBoxY width="100%" height="100%">
      <img
        src="subbase_logo.png"
        alt="Subbase logo"
        style={{ width: '360px', height: '130px', marginBottom: '50px' }}
      />
      <FlexBoxX flexGrow={0} marginBottom="15px">
        <Typography.Title level={3}>Send a Test Email</Typography.Title>
      </FlexBoxX>
      <Space direction="vertical" size="large" style={{ display: 'flex', width: '500px' }}>
        <Input addonBefore={'Email'} value={email} onChange={(v) => setEmail(v.target.value)} />
        <Input addonBefore={'Subject'} value={subject} onChange={(v) => setSubject(v.target.value)} />
        <Input addonBefore={'Body'} value={body} onChange={(v) => setBody(v.target.value)} />
      </Space>
      <Button onClick={sendTestEmail} type="primary" style={{ marginTop: '15px' }}>
        Send Email
      </Button>
      <History messageId={messageId} />
    </FlexBoxY>
  )
}

export default withErrorBoundary(TestEmail)
