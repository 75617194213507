import React from 'react'

import { CSVLink } from 'react-csv'

import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
import { Button, Tooltip, message } from 'antd'

import { formatForCSVExport } from 'common/helpers/formatters'
import { noticeError } from 'common/helpers/new_relic'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'

interface DownloadProps<Data = never, MapperResult = never> {
  dataMapper: (data: Data) => MapperResult
  title: string
  filename: string
  headers: Array<{ label: string; key: string }>
  onLoadData: () => Promise<Array<Data>>
}

export function DownloadButton<Data = never, MapperResult = never>(props: DownloadProps<Data, MapperResult>) {
  const { dataMapper, headers, onLoadData, title, filename } = props

  const csvInstance = React.useRef(null)

  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  async function handleDownloadData() {
    try {
      setLoading(true)
      const data = await onLoadData()

      if (data.length === 0) return

      const newData = data.map((record) => {
        headers.forEach(({ key }) => {
          const currentValue = record[key]
          const newValue = formatForCSVExport(currentValue)

          record[key] = newValue
        })

        return dataMapper(record)
      })

      setData(newData)
      csvInstance.current.link.click()
      setData([])
    } catch (error) {
      noticeError(error, { entry: 'download-pdf' })
      message.error(`Unable to download file. Please contact ${SUPPORT_EMAIL_ADDRESS} for further assistance.`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Tooltip title={`Download ${title}`} placement="bottomRight">
      <>
        <Button loading={loading} data-cy="download-button" onClick={handleDownloadData}>
          <DownloadOutlined />
        </Button>

        {data.length > 0 && <CSVLink data={data} filename={`${filename}.csv`} headers={headers} ref={csvInstance} />}
      </>
    </Tooltip>
  )
}
