import React from 'react'

import { Table } from 'antd'

import { observer } from 'mobx-react-lite'

import { makeOption, ValueType } from 'common/components/DebounceSelect'
import { MappingDirections } from 'common/server/server_types'

import { SelectAgaveProjects } from 'contractor/components/SelectAgaveProjects'
import {
  hasSuggestions,
  maybeAddApproveRejectColumn,
} from 'contractor/components/SelectExternalMappings/select_external_mappings'
import { SelectIntegrationEntity } from 'contractor/components/SelectIntegrationEntity/select_integration_entity'
import { useStores } from 'contractor/hooks/use-stores'
import RelationshipsTable from 'contractor/pages/Integrations/Mappings/Relationships/relationships_table'
import { SuggestionAlert } from 'contractor/pages/Integrations/Mappings/suggestion_alert'
import { useUpdateMappingsHook } from 'contractor/pages/Integrations/Mappings/use_update_mappings_hook'
import { ListParams, Project } from 'contractor/server/integrations'

interface ProjectsTableProps {
  filter?: ListParams
  setFilter?: React.Dispatch<React.SetStateAction<ListParams>>
  isLoading: boolean
}

const ProjectsTable = observer<ProjectsTableProps>(({ isLoading, filter, setFilter }) => {
  const { integrationStore } = useStores()

  const reloadCallback = (fetchRelationships = true) => {
    if (fetchRelationships) integrationStore.getProjectsRelationships(filter)
    integrationStore.getProjects(filter)
    integrationStore.getProjectsCount({ search: filter?.search })
  }

  const { handleUpdate, handleDirectUpdate, handleSuggestionsApproval, handleSuggestionsRejection, updating } =
    useUpdateMappingsHook({
      update: integrationStore.updateProject,
      reloadCallback,
    })

  if (integrationStore.mappingDirection === MappingDirections.INSIDE_OUT) {
    const columns = [
      {
        title: 'SubBase Name',
        dataIndex: 'name',
        width: '20%',
      },
      {
        title: 'SubBase Project ID',
        dataIndex: 'project_id',
        width: '20%',
      },
      {
        title: `${integrationStore.title()} Projects`,
        width: integrationStore.showProjectsSuggestions() ? '53%' : '60%',
        dataIndex: 'external_relationships',
        render: (_, row) => {
          const selected = []
          const suggestions = hasSuggestions(row)
          if (suggestions) {
            selected.push(...row.mapping_suggestions.map((item) => makeOption(item)))
          } else {
            selected.push(...row.external_relationships.map((item) => makeOption(item)))
          }
          return (
            <SelectAgaveProjects
              selected={selected}
              loading={updating}
              showArrow={true}
              onChange={(value) => {
                handleUpdate({ option: value, entity: row })
              }}
              status={suggestions ? 'warning' : undefined}
            />
          )
        },
      },
    ]
    maybeAddApproveRejectColumn(
      'projects',
      integrationStore.projects?.data,
      integrationStore.showProjectsSuggestions,
      columns,
      handleSuggestionsApproval,
      handleSuggestionsRejection,
    )
    return (
      <>
        <SuggestionAlert showSuggestions={integrationStore.showProjectsSuggestions()} />
        <Table<Project>
          pagination={{
            total: integrationStore.projects?.totalCount,
            current: integrationStore.projects?.currentPage,
            pageSize: integrationStore.projects?.pageItems,
            onChange: (page) => {
              setFilter((prev) => ({ ...prev, page }))
            },
            showSizeChanger: false,
          }}
          loading={isLoading}
          rowKey="id"
          style={{ width: '100%' }}
          columns={columns}
          dataSource={integrationStore.projects?.data}
        />
      </>
    )
  }

  const disabledOption = (item: Project) =>
    integrationStore.enabledProjectsMultiMapping() ? false : item.external_relationships.length > 0

  const makeInternalOption = (item: Project) =>
    ({
      value: item.id,
      label: item.name,
      disabled: disabledOption(item),
    } as ValueType)

  return (
    <RelationshipsTable
      showSuggestions={integrationStore.showProjectsSuggestions()}
      handleSuggestionsApproval={handleSuggestionsApproval}
      handleSuggestionsRejection={handleSuggestionsRejection}
      filter={filter}
      setFilter={setFilter}
      isLoading={isLoading}
      relationships={integrationStore.projectsRelationships}
      renderRowTitle="Projects"
      renderRow={(row) => (
        <SelectIntegrationEntity
          loading={updating}
          row={row}
          optionApi={integrationStore.getProjects}
          optionData={integrationStore.projects}
          makeInternalOption={makeInternalOption}
          handleUpdate={handleDirectUpdate}
          status={row.mapping_suggestions && row.mapping_suggestions.length > 0 ? 'warning' : undefined}
        />
      )}
    />
  )
})

export default ProjectsTable
