import React from 'react'

import { ClockCircleOutlined } from '@ant-design/icons'
import { Input, Modal, Typography, Checkbox, Space, Divider } from 'antd'
import { SYNC_ORDER_WITH_INTEGRATION_LOCAL_KEY } from 'contractor/constants'

import { observer } from 'mobx-react-lite'

import { Tool, trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { FlexBoxY } from 'common/components/boxes'

import { POHistory } from 'contractor/components/POHistory'
import { TermsAndConditions, mapOptions } from 'contractor/components/TermsAndConditions'
import { useStores } from 'contractor/hooks/use-stores'

import { useNewQuote } from './context'

type ModalConfirmPurchaseProps = {
  visible: boolean
  onCancel: () => void
  isLoading?: boolean
  onFinish: () => Promise<void>
}

export const ModalConfirmPurchase = observer<ModalConfirmPurchaseProps>(
  ({ visible, onCancel, onFinish, isLoading }) => {
    const { userStore, integrationStore, orderStore, companySettingStore } = useStores()

    const {
      syncOrderWithIntegration,
      setSyncOrderWithIntegration,
      orderNumber,
      setOrderNumber,
      setTermsAndConditionId,
    } = useNewQuote()

    const onClickConfirm = () => {
      trackEvent(
        Events.CLICK_CONFIRM_PURCHASE_PLACE_ORDER_MODAL,
        window.location.pathname,
        {
          po_number: orderNumber,
        },
        Tool.AMPLITUDE,
      )
      onFinish()
    }

    return (
      <Modal
        title="Confirm Purchase"
        open={visible}
        onOk={onClickConfirm}
        okText="Confirm"
        okButtonProps={{ loading: isLoading }}
        cancelButtonProps={{ disabled: isLoading }}
        onCancel={onCancel}
      >
        <FlexBoxY alignItems="flex-start" justifyContent="flex-start">
          <Typography.Text type="secondary">PO number (optional)</Typography.Text>
          <Space>
            <Input
              data-cy="order-number"
              style={{ width: 280 }}
              placeholder="PO number (optional)"
              onChange={(e) => setOrderNumber(e.target.value)}
              value={orderNumber}
            />
            {orderStore.selectedOrder && (
              <POHistory poHistory={orderStore.selectedOrder?.po_history}>
                <ClockCircleOutlined />
              </POHistory>
            )}
          </Space>
        </FlexBoxY>

        {userStore.canUseIntegrations && integrationStore.connected && integrationStore.purchaseOrderSyncEnabled && (
          <Checkbox
            onChange={(e) => {
              localStorage.setItem(SYNC_ORDER_WITH_INTEGRATION_LOCAL_KEY, `${e.target.checked}`)
              setSyncOrderWithIntegration(e.target.checked)
            }}
            checked={syncOrderWithIntegration}
            style={{ marginTop: 8 }}
          >
            Sync purchase order with {integrationStore.getIntegrationName()}
          </Checkbox>
        )}
        <br />
        <Typography.Text type="secondary" italic style={{ fontSize: 12 }}>
          PDF of PO will be automatically created
        </Typography.Text>

        {!!companySettingStore.activeTermsAndConditions?.length && (
          <>
            <Divider style={{ margin: '12px 0' }} />
            <TermsAndConditions
              style={{ width: 280 }}
              type="PO"
              options={companySettingStore.activeTermsAndConditions?.map(mapOptions)}
              onChange={(termsAndConditionId) => setTermsAndConditionId(termsAndConditionId)}
            />
          </>
        )}
      </Modal>
    )
  },
)
