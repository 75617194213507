import React from 'react'

import { Modal, ModalProps } from 'antd'

import { TermsAndConditions, TermsAndConditionsProps } from './terms_and_conditions'

type TermsAndConditionsModalProps = {
  termsAndConditionsProps: TermsAndConditionsProps
  isLoading?: boolean
  onFinish: () => Promise<void>
} & ModalProps

export const TermsAndConditionsModal = (props: TermsAndConditionsModalProps) => {
  const { termsAndConditionsProps, isLoading, onFinish, ...rest } = props

  return (
    <Modal
      title="Terms and Conditions"
      okText="Confirm"
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
      onOk={onFinish}
      {...rest}
    >
      <TermsAndConditions style={{ width: 280 }} {...termsAndConditionsProps} />
    </Modal>
  )
}
