import React, { useState } from 'react'

import { flatten, sortBy } from 'lodash'

import styled from '@emotion/styled'

import { Empty, Space, Table, TableProps } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { EditableTable } from 'common/components/EditableTable'
import { RibbonFilter } from 'common/components/RibbonFilter'

import { useStores } from 'contractor/hooks/use-stores'

import { ExpandButton } from '../InvoiceMaterials/expand_button'
import { DeliveryButton } from './delivery_button'
import { getExpandablecolumns } from './expandable_table_columns'
import { useOrderMaterialsData, useVisibleColumns } from './hooks'
import { getTableColumns } from './table_columns'
import { TableOptions } from './table_options'

const makeFilterOption = ({ order }) => {
  return { label: order['order_number'], filter: order['id'] }
}
const TableStyled = styled(Table)`
  .ant-table-row-expand-icon-cell {
    text-align: center;
  }

  .ant-table-expanded-row {
    .ant-table.ant-table-small {
      margin: -8px -8px -8px 28px !important;
    }

    .ant-table-row:hover,
    .ant-table-cell {
      background: ${({ theme }) => theme.colors['gray-2']} !important;
      color: #00000082;
    }
  }
` as <T>(props: TableProps<T>) => React.ReactElement

type OrderMaterialsProps = {
  visible: boolean
}

export const OrderMaterials = observer<OrderMaterialsProps>(({ visible }) => {
  const { invoiceStore } = useStores()

  const { dataSource } = useOrderMaterialsData()
  const { filterVisibleColumn, handleChangeVisibleColumns, visibleColumns } = useVisibleColumns()

  const [filter, setFilter] = useState(['all'])
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([])

  const handleToggleExpandRowKeys = () => {
    if (expandedRowKeys.length) {
      setExpandedRowKeys([])
    } else {
      const allOrderMaterialsKeys = invoiceStore?.selectedOrders?.map((order) =>
        order.order.order_materials?.map((om) => om.id),
      )
      const ordersKeys = invoiceStore.selectedOrders.map(({ order }) => [
        `tax-${order.id}`,
        `shipping-${order.id}`,
        `other-${order.id}`,
        `discount-${order.id}`,
        `total-${order.id}`,
      ])
      setExpandedRowKeys([...flatten(ordersKeys), ...flatten(allOrderMaterialsKeys)])
    }
  }

  const handleApplytRibbonFilter = (orderMaterial) => {
    if (filter.includes('all')) {
      return true
    }

    return filter.includes(orderMaterial['order_id'])
  }

  const { fixedFirstColumns, columnsToHide } = getTableColumns()
  const { expandableFixedFirstColumns, expandableColumnsToHide } = getExpandablecolumns()

  const ordersOptions = sortBy(invoiceStore.selectedOrders, ({ order }) => order?.order_number)?.map(makeFilterOption)

  return (
    <Box display={visible ? 'block' : 'none'} width="100%" minWidth={1280}>
      <Box width="100%" display="flex" alignContent="center" justifyContent="space-between">
        <div>
          {invoiceStore.selectedOrders?.length > 1 && (
            <RibbonFilter
              boxProps={{ mb: 12 }}
              value={filter}
              onChange={setFilter}
              options={[{ label: 'All', filter: 'all' }, ...ordersOptions]}
            />
          )}
        </div>

        <Space>
          <TableOptions value={visibleColumns} onChange={handleChangeVisibleColumns} />
          <DeliveryButton />
        </Space>
      </Box>

      <TableStyled
        data-cy="order-materials-table"
        rowKey="id"
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No order materials." />,
        }}
        columns={[...fixedFirstColumns, ...columnsToHide.filter(filterVisibleColumn), Table.EXPAND_COLUMN]}
        dataSource={dataSource.filter(handleApplytRibbonFilter)}
        pagination={false}
        size="small"
        expandable={{
          columnTitle: () => {
            if (dataSource?.some((orderMaterial) => !!orderMaterial?.invoice_materials?.length)) {
              return (
                <ExpandButton
                  onToggleExpandRowKeys={handleToggleExpandRowKeys}
                  hasExpandedRowKeys={!!expandedRowKeys.length}
                />
              )
            }
          },
          onExpandedRowsChange: (expandedRows) => setExpandedRowKeys(expandedRows as React.Key[]),
          expandedRowKeys,
          expandRowByClick: true,
          expandedRowRender: (orderMaterial, index) => (
            <EditableTable
              index={index}
              data-cy="order-materials-table-expanded-row"
              rowKey={(record) => `expandable-${record.id}`}
              showHeader={false}
              pagination={false}
              size="small"
              dataSource={orderMaterial?.invoice_materials}
              columns={[
                ...expandableFixedFirstColumns,
                ...expandableColumnsToHide.filter(filterVisibleColumn),
                { dataIndex: 'expand_column', width: 48 },
              ]}
            />
          ),
          rowExpandable: (orderMaterial) => !!orderMaterial?.invoice_materials?.length,
        }}
      />
    </Box>
  )
})
