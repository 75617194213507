import axios from 'axios'

import { ShowInvoiceMailResponse, ShowInvoiceUploadResponse } from 'common/server/invoice'
import { InvoiceInboxResponse } from 'common/server/invoice_inbox'
import {
  InvoiceInboxStates,
  InvoiceInboxSubStates,
  SearchKeysResponse,
  InvoiceSourceType,
  InvoiceInboxFilesStates,
  InvoiceInboxIssueActionType,
} from 'common/server/server_types'

import { ReportInvoiceInboxIssuePayload, ReportInvoiceInboxTipPayload } from 'contractor/server/invoices/invoice'

export interface InvoiceInboxFileFailMessage {
  page?: number
  reason?: string
}

export interface InvoiceInboxFileHit {
  id: string
  url: string
  filename: string
  state: InvoiceInboxFilesStates
  fail_message?: string
  auto_ignored_message?: string
}

export interface InvoiceInboxHit {
  id: string
  state: InvoiceInboxStates
  sub_state: InvoiceInboxSubStates
  created_at: string
  invoice_source_type: InvoiceSourceType
  invoice_source_id: string
  from?: string
  subject?: string
  files: InvoiceInboxFileHit[]
  uploaded_by?: string
  origin: 'Uploaded' | 'Email Received'
  invoice_count: number
  fail_reason?: string
  auto_ignored_reason?: string
}

export interface ReportIssueHistory {
  id: string
  issue_message: string
  created_at: string
  author: { id: string; name: string }
  invoice_inbox_attachments?: { id: string; filename: string; url: string }[]
  action_type: InvoiceInboxIssueActionType
}

export function search_key() {
  return axios.get<SearchKeysResponse>('/invoice_inboxes/search_key')
}

export function show(id: string) {
  return axios.get<InvoiceInboxResponse>(`/invoice_inboxes/${id}`)
}

export function upload(file_signed_ids: string[]) {
  return axios.post(`/invoice_uploads`, { file_signed_ids })
}

export function ignore(id: string) {
  return axios.patch(`/invoice_inboxes/${id}`, { ignore_failures: true })
}

export function ignore_all() {
  return axios.patch(`/invoice_inboxes/ignore_all`, {})
}

export function showInvoiceMail(id: string) {
  return axios.get<ShowInvoiceMailResponse>(`/invoice_mails/${id}`)
}

export function showInvoiceUpload(id: string) {
  return axios.get<ShowInvoiceUploadResponse>(`/invoice_uploads/${id}`)
}

export function issue_history(id: string, params: { invoice_inbox_attachment_id: string }) {
  return axios.get<{ history: ReportIssueHistory[] }>(`/invoice_inboxes/${id}/report_issue_history`, { params })
}

export function issue(id: string, payload: ReportInvoiceInboxIssuePayload) {
  return axios.post(`/invoice_inboxes/${id}/issue`, { report_issue: payload })
}

export function tip(id: string, payload: ReportInvoiceInboxTipPayload) {
  return axios.post(`/invoice_inboxes/${id}/tip`, payload)
}
