import React from 'react'

import { Popover, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import OrderState from 'common/components/statuses/order_state'
import { useTheme } from 'common/hooks/use-theme'

import { ShowOrderResponse } from 'contractor/server/orders'

type TagWrapperProps = {
  children: React.ReactNode
  order: ShowOrderResponse
}

export const TagWrapper = ({ children, order }: TagWrapperProps) => {
  const theme = useTheme()

  return (
    <Popover
      mouseEnterDelay={0.5}
      content={
        <Box width={280}>
          <Box display="flex" flexDirection="column">
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              Order Name
            </Typography.Text>
            <Typography.Link href={`/order/${order.id}`} target="_blank">
              {order?.order_package_name}
            </Typography.Link>
          </Box>

          <Box display="flex" flexDirection="column" my={8}>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              Project
            </Typography.Text>
            <Typography.Text>{order?.project?.name}</Typography.Text>
          </Box>

          <Box display="flex" flexDirection="column">
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              Vendor
            </Typography.Text>
            <Typography.Text>
              {order?.company_vendor?.vendor?.name || order?.company_vendor?.vendor_name}
            </Typography.Text>
          </Box>

          {!!order?.quick_created_at && (
            <Typography.Text italic style={{ fontSize: 12, color: theme.colors.highlightBlue }}>
              This PO was Quick Created during Invoice Approval
            </Typography.Text>
          )}
        </Box>
      }
      title={
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography.Text>{order?.order_number}</Typography.Text>
          <OrderState state={order?.state} sub_state={order?.sub_state} size="small" style={{ margin: 0 }} />
        </Box>
      }
    >
      {children}
    </Popover>
  )
}
