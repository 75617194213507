import React, { useEffect, useState } from 'react'

import { Button, ButtonProps, message, Modal } from 'antd'

import { UploadResponse } from 'contractor/server/flatfile'

type FlatfileProps = {
  buttonProps?: ButtonProps
  icon?: React.ReactNode
  openUpload: () => Promise<UploadResponse>
  onSuccess?: () => void
}

export const Flatfile = ({ buttonProps, openUpload, onSuccess, icon }: FlatfileProps) => {
  const [guestLink, setGuestLink] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleOpenUpload = async () => {
    try {
      setLoading(true)
      const data = await openUpload()
      setGuestLink(data.guestLink)
    } catch (error) {
      message.error(error?.response?.data?.error || 'Unable to upload')
    } finally {
      setLoading(false)
    }
  }

  // Trick to close the Flatfile when submitted as a success
  useEffect(() => {
    const handlePostMessage = (event) => {
      const flatfileEvent = event?.data?.flatfileEvent
      if (flatfileEvent?.topic === 'job:completed' && flatfileEvent?.payload?.job?.includes('workbook:submit')) {
        setGuestLink(null)
        onSuccess?.()
      }
    }

    window.addEventListener('message', handlePostMessage)
    return () => {
      window.removeEventListener('message', handlePostMessage)
    }
  }, [onSuccess])

  return (
    <>
      <Button type="primary" loading={loading} {...buttonProps} onClick={handleOpenUpload}>
        {icon || 'Upload'}
      </Button>

      <Modal
        style={{ top: 32 }}
        bodyStyle={{ padding: '48px 0 0 0', height: 'calc(100vh - 64px)' }}
        footer={false}
        open={guestLink}
        onCancel={() => setGuestLink(null)}
        width="calc(100vw - 32px)"
      >
        <iframe key={guestLink} style={{ border: 'none' }} src={guestLink} title="Upload" width="100%" height="100%" />
      </Modal>
    </>
  )
}
