import React from 'react'

import { Divider } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import { Visibility } from 'common/components/Visibility'
import { InvoiceResponse } from 'common/server/invoice'

import { AccountingDate } from 'contractor/pages/Invoices/Detail/@v2/Header/accounting_date'

import { InvoiceProject, CompanyVendorSelector } from '../components'
import { useInvoice } from '../context'
import { IntegrationSyncProps } from '../integration_sync'
import { OrdersTags } from '../OrdersTags'
import { DocumentDate } from './document_date'
import { InvoiceNumber } from './invoice_number'
import { LeftActions } from './left_actions'
import { RightActions } from './right_actions'

export type HeaderProps = {
  isCorrelating: boolean
  toggleRejectionReasonModal: React.Dispatch<React.SetStateAction<boolean>>
  isSubmitting: boolean
  saveAndNextDefault: boolean
  onChangeName: (name: string) => void
  onSaveInvoice: () => Promise<void>
  onSaveInvoiceAndNext: () => Promise<void>
  onConfirmSync?: IntegrationSyncProps['onFinish']
  accountingDateEnabled: boolean

  assignToId?: string
  onChangeAssignToId: (assignToId: string) => void

  stateId?: string
  onChangeStateId: (stateId: string) => void

  attachedOrderIds: string[]
  onAttachOrder: (orderId: Nullable<string>) => void
  onUnAttachOrder: (orderId: Nullable<string>) => void
  saveInvoice: (autoMatch?: boolean, orderIds?: string[]) => Promise<InvoiceResponse>
}
export const Header = observer<HeaderProps>(
  ({
    attachedOrderIds,
    onAttachOrder,
    onUnAttachOrder,
    accountingDateEnabled,
    saveInvoice,
    onConfirmSync,
    onChangeName,
    ...props
  }) => {
    const { lockInvoice } = useInvoice()

    return (
      <Page.Header>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <LeftActions
            isSubmitting={props?.isSubmitting}
            onSaveInvoice={props?.onSaveInvoice}
            onConfirmSync={onConfirmSync}
            onChangeName={onChangeName}
          />
          <RightActions attachedOrderIds={attachedOrderIds} {...props} />
        </Box>

        <Visibility.Hidden breakpoint="lg">
          <Box display="flex" alignItems="center" justifyContent="space-between" mt={8}>
            <Box fontSize={14} display="flex" alignItems="center" style={{ gap: 8 }}>
              <CompanyVendorSelector disabled={!lockInvoice.canEditInvoice} />
              <Divider type="vertical" />
              <InvoiceProject disabled={!lockInvoice.canEditInvoice} />
              <Divider type="vertical" />
              <InvoiceNumber disabled={!lockInvoice.canEditInvoice} />
              <Divider type="vertical" />
              <DocumentDate disabled={!lockInvoice.canEditInvoice} />
              {accountingDateEnabled && lockInvoice.canEditInvoice && (
                <>
                  <Divider type="vertical" />
                  <AccountingDate />
                </>
              )}
            </Box>

            <OrdersTags
              noFilter
              disabled={!lockInvoice.canEditInvoice}
              attachedOrderIds={attachedOrderIds}
              onAttachOrder={onAttachOrder}
              onUnAttachOrder={onUnAttachOrder}
              saveInvoice={saveInvoice}
            />
          </Box>
        </Visibility.Hidden>
      </Page.Header>
    )
  },
)
