import React from 'react'

import { Typography } from 'antd'
import { TextProps } from 'antd/lib/typography/Text'

import { currencyFormatter } from 'common/helpers/formatters'

import { UnitCostColumnProps } from './unit_cost'

type ExtendedCostProps = Pick<UnitCostColumnProps, 'extCost' | 'hideDetails' | 'roundingPrecision'> & TextProps

export const ExtendedCost = ({
  extCost = 0,
  hideDetails = false,
  roundingPrecision = 3,
  ...props
}: ExtendedCostProps) => {
  return (
    <Typography.Text
      type="secondary"
      {...props}
      style={{ width: '100%', textAlign: 'end', marginRight: hideDetails ? 30 : 16, ...props?.style }}
    >
      {currencyFormatter(extCost, roundingPrecision)}
    </Typography.Text>
  )
}
