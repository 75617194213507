/** @jsx jsx */

import * as React from 'react'

import { jsx } from '@emotion/core'

import { Tag } from 'antd'

import { observer } from 'mobx-react-lite'

import { formatEnumValue } from 'common/helpers/formatters'
import { DeliveryStates } from 'common/server/server_types'

interface DeliveryStateProps {
  state: string
  showIssue?: boolean
  deliveryIssue?: string
  style?: React.CSSProperties
}

const DeliveryState: React.FC<DeliveryStateProps> = observer(
  ({ state, showIssue, deliveryIssue, style: styleProp }) => {
    let [color, borderStyle] = ['', '']
    switch (state) {
      case DeliveryStates.UNSCHEDULED:
        color = 'default'
        borderStyle = 'solid'
        break
      case DeliveryStates.REQUESTED:
        color = 'gold'
        borderStyle = 'solid'
        break
      case DeliveryStates.SCHEDULED:
        color = 'default'
        borderStyle = 'solid'
        break
      case DeliveryStates.SHIPPED:
        color = 'orange'
        borderStyle = 'solid'
        break
      case DeliveryStates.DELIVERED:
        color = 'green'
        borderStyle = 'solid'
        break
    }

    const style = {
      color: 'black',
      borderStyle,
      marginRight: '5px',
      marginBottom: '5px',
      width: '120px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }

    // TODO: The delivery statuses aren't super clear, worth thinking about some more
    return (
      <div>
        <Tag color={color} css={style} style={styleProp}>
          {formatEnumValue(state || DeliveryStates.UNSCHEDULED)}
        </Tag>
        {showIssue && (
          <Tag color={'red'} css={style}>
            {formatEnumValue(deliveryIssue)}
          </Tag>
        )}
      </div>
    )
  },
)

export default DeliveryState
