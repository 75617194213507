import React from 'react'

import { Col, Input, Row, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'
import DateTimePicker from 'common/components/date_time_picker'
import { OrderDelivery } from 'common/server/deliveries'

import AddressSelector from 'contractor/components/OrderDeliveries/address_selector'

import ItemTitle from './item_title'

interface RequestDetailProps {
  delivery: OrderDelivery
  onChange: (item) => void
  project_ids: string[]
  disableEditing?: boolean
}

export const DeliveryDetail: React.FC<RequestDetailProps> = observer(
  ({ delivery, onChange, project_ids, disableEditing }) => {
    return (
      <>
        <Typography.Text strong style={{ marginBottom: 12 }}>
          Request
        </Typography.Text>

        <Row gutter={[20, 20]}>
          <Col xs={24} sm={12}>
            <FlexBoxY justifyContent="flex-start" alignItems="flex-start" flexGrow={0}>
              <ItemTitle text="Requested Date" />
              <DateTimePicker
                dataCy="requested-delivered-at"
                value={delivery?.requested_delivered_at}
                onChange={(requested_delivered_at) => onChange({ requested_delivered_at })}
                wrapperProps={{ width: '100%' }}
                disabled={disableEditing}
              />
            </FlexBoxY>
          </Col>

          <Col xs={24} sm={12}>
            <FlexBoxY justifyContent="flex-start" alignItems="flex-start">
              <ItemTitle text="Address" />
              <AddressSelector
                delivery={delivery}
                isPickUp={delivery.is_pick_up}
                project_ids={project_ids}
                setAddress={(address) => {
                  onChange({ address })
                }}
                selectedAddress={delivery?.address}
                wrapperProps={{ width: '100%', minWidth: 'auto', maxWidth: 'auto' }}
                disabled={disableEditing}
              />
            </FlexBoxY>
          </Col>

          <Col span={24}>
            <FlexBoxY justifyContent="flex-start" alignItems="flex-start" width="100%">
              <ItemTitle text="Instructions" />
              <Input.TextArea
                data-cy="delivery-instructions"
                value={delivery.instructions}
                onChange={(e) => onChange({ instructions: e.target.value })}
                style={{ resize: 'none' }}
                disabled={disableEditing}
              />
            </FlexBoxY>
          </Col>
        </Row>
      </>
    )
  },
)

export default DeliveryDetail
