import React from 'react'

import { Link } from 'react-router-dom'

import { FileAddOutlined, SnippetsOutlined } from '@ant-design/icons'
import PlusOutlined from '@ant-design/icons/PlusOutlined'
import { Button, Popover } from 'antd'

import { Tool, trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'

interface ActionsProps {
  canSendAndUpdateOrders: boolean
  canSendAndUpdateRFQs: boolean
  publicOrderFormUrl?: string
  projectId?: string
}

export const Actions = (props: ActionsProps) => {
  const { canSendAndUpdateOrders, canSendAndUpdateRFQs, publicOrderFormUrl, projectId } = props

  const trackClick = (event: Events) => {
    trackEvent(event, window.location.pathname, {}, Tool.AMPLITUDE)
  }

  const getUrl = (url: string) => {
    if (projectId) {
      return `${url}?project_id=${projectId}`
    }
    return url
  }

  if (!canSendAndUpdateRFQs && !canSendAndUpdateOrders) {
    return (
      <Popover placement="bottomRight" content="Request Materials">
        <Link to={getUrl(`/new_order/${publicOrderFormUrl}`)}>
          <Button data-cy="request-materials" type="primary" style={{ height: 32 }}>
            <FileAddOutlined style={{ fontSize: 16 }} />
          </Button>
        </Link>
      </Popover>
    )
  }

  return (
    <Box display="flex" gridGap={8} alignItems="center">
      {canSendAndUpdateRFQs && (
        <Popover placement="bottomRight" content="Create RFQ">
          <Link onClick={() => trackClick(Events.CLICK_CREATE_RFQ_ORDER_TABLE)} to={getUrl(`/orders/new_quote`)}>
            <Button data-cy="create-rfq" type="primary" style={{ height: 32 }}>
              <SnippetsOutlined style={{ fontSize: 16 }} />
            </Button>
          </Link>
        </Popover>
      )}

      {canSendAndUpdateOrders && (
        <Popover placement="bottomRight" content="Create Order">
          <Link onClick={() => trackClick(Events.CLICK_CREATE_ORDER_ORDER_TABLE)} to={getUrl(`/orders/new_order`)}>
            <Button data-cy="create-order" type="primary" style={{ height: 32 }}>
              <PlusOutlined style={{ fontSize: 16 }} />
            </Button>
          </Link>
        </Popover>
      )}
    </Box>
  )
}
