import { findIndex } from 'lodash'
import { BindAll } from 'lodash-decorators'

import { action, observable } from 'mobx'

import { Address } from 'common/server/addresses'
import ListBaseStore from 'common/stores/ListBaseStore'

import { create as createAddress, update as updateAddress } from 'contractor/server/addresses'
import {
  create,
  CreateVendorContactRequest,
  index as indexContacts,
  CompanyVendorContact,
  UpdateVendorContactRequest,
  update as updateVendorContact,
  getById,
} from 'contractor/server/company_vendor_contacts'
import {
  UpdateVendorRequest,
  update as updateVendor,
  index as indexCompanyVendors,
  show as getCompanyVendorById,
  IndexCompanyVendor,
} from 'contractor/server/company_vendors'

class CompanyVendorListStore extends ListBaseStore<IndexCompanyVendor> {
  index = indexCompanyVendors
}

class CompanyVendorListSelectorStore extends ListBaseStore<IndexCompanyVendor> {
  index = indexCompanyVendors
}

class CompanyVendorContactListStore extends ListBaseStore<CompanyVendorContact> {
  index = indexContacts
}

@BindAll()
export default class CompanyVendorStore {
  @observable selectedCompanyVendor: Nullable<IndexCompanyVendor> = null
  @observable selectedCompanyVendorContact: Nullable<CompanyVendorContact> = null

  companyVendorListStore: CompanyVendorListStore
  companyVendorListSelectorStore: CompanyVendorListSelectorStore
  companyVendorContactListStore: CompanyVendorContactListStore
  constructor() {
    this.companyVendorListStore = new CompanyVendorListStore()
    this.companyVendorListSelectorStore = new CompanyVendorListSelectorStore()
    this.companyVendorContactListStore = new CompanyVendorContactListStore()
  }

  updateCompanyVendor(companyVendor: UpdateVendorRequest) {
    return updateVendor(companyVendor)
  }

  async getCompanyVendorById(id: string) {
    this.selectedCompanyVendor = null
    const { data } = await getCompanyVendorById(id)

    this.selectedCompanyVendor = data.company_vendor
  }

  async getCompanyVendorContactById(id: string) {
    this.selectedCompanyVendorContact = null
    const { data } = await getById(id)

    this.selectedCompanyVendorContact = data.vendor_contact
  }

  // Will also create a vendor if applicable
  @action
  async createVendorContact(
    vendor: CreateVendorContactRequest['vendor'],
    contact: CreateVendorContactRequest['contact'],
  ) {
    const newContact = (await create({ vendor, contact })).data
    return newContact
  }

  async updateVendorContact({
    vendor,
    vendorContact,
  }: {
    vendor?: UpdateVendorRequest
    vendorContact?: UpdateVendorContactRequest
  }) {
    const promises = []
    if (vendor) {
      promises.push(updateVendor(vendor))
    }
    if (vendorContact) {
      promises.push(updateVendorContact(vendorContact))
    }
    await Promise.all(promises)
  }

  updateCompanyVendorContact(companyVendorContact?: UpdateVendorContactRequest) {
    return updateVendorContact(companyVendorContact)
  }

  async connectToVendor({ vendorId, vendorUserId }) {
    const { data } = await create({ vendor: { vendor_id: vendorId }, contact: { vendor_user_id: vendorUserId } })
    return data
  }

  async createAddress(companyVendorId: string, address: Address) {
    const { data } = await createAddress({ company_vendor_id: companyVendorId, address })

    if (this.selectedCompanyVendorContact) {
      this.selectedCompanyVendorContact?.company_vendor.addresses.push(data)
    }

    if (this.selectedCompanyVendor) {
      this.selectedCompanyVendor?.addresses.push(data)
    }
  }

  async updateAddress(address: Address) {
    const { data } = await updateAddress({ id: address.id, address })

    if (this.selectedCompanyVendorContact) {
      const index = findIndex(this.selectedCompanyVendorContact?.company_vendor.addresses, { id: address.id })
      this.selectedCompanyVendorContact.company_vendor.addresses[index] = data
    }

    if (this.selectedCompanyVendor) {
      const index = findIndex(this.selectedCompanyVendor?.addresses, { id: address.id })
      this.selectedCompanyVendor.addresses[index] = data
    }
  }
}
