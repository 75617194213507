import { createConsumer } from '@rails/actioncable'

const INTEGRATION_STORE_CHANNEL = 'ApplicationCable::IntegrationStoreChannel'

export const TYPE_VENDOR = 'VENDOR'
export const TYPE_CONTRACTOR = 'CONTRACTOR'

const getActionCableUrl = () => document.querySelector('meta[name="action-cable-url"]')['content']

let consumer = null

export const subscribeChannel = (callback, type, entity_id) => {
  if (!consumer) {
    consumer = createConsumer(`${getActionCableUrl()}?type=${type}`)
  }

  return consumer.subscriptions.create(
    {
      channel: INTEGRATION_STORE_CHANNEL,
      entity_id: entity_id,
    },
    {
      received(message) {
        callback(message)
      },
      connected() {
        console.log('connected')
      },
      disconnected() {
        console.log('disconnected')
      },
    },
  )
}
