import React, { useState, useEffect } from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Form, Row, Col, Checkbox, Space, Modal, Typography, Input, Alert, Popover } from 'antd'
import { ModalProps } from 'antd/lib/modal'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { useQuery } from 'common/hooks/use-query'
import { RelationshipStockStatus } from 'common/server/server_types'

import { SelectAgaveCostCodeClass } from 'contractor/components/SelectAgaveCostCodeClass'
import { SelectAgaveCostCodes } from 'contractor/components/SelectAgaveCostCodes'
import { SelectAgaveProjects } from 'contractor/components/SelectAgaveProjects'
import { SelectAgaveVendors } from 'contractor/components/SelectAgaveVendors'
import { useStores } from 'contractor/hooks/use-stores'

const SYNC_INVOICE_WITH_INTEGRATION_LOCAL_KEY = 'checkbox-sync-invoice-with-integration'

type Option = {
  value: string
  label: string
}

type IntegrationSyncProps = {
  isSubmitting?: boolean
  onFinish: (formValues: {
    vendor: Option | string
    customer: Option | string
    ledgerAccount: Option | string
    class: Option | string
  }) => void
  onFinishWithoutSync: () => void
  markAsSynced: () => void
} & ModalProps

const makeOption = (option) =>
  option
    ? {
        value: option?.external_id,
        label: option?.external_name,
      }
    : undefined

export const IntegrationSyncOriginal = observer<IntegrationSyncProps>(
  ({ isSubmitting, onFinish, markAsSynced, onFinishWithoutSync, ...props }) => {
    const { integrationStore, invoiceStore, companySettingStore, userStore } = useStores()

    const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

    const invoice = invoiceStore.invoice
    const integration = invoiceStore.invoice.integration
    const integrationName = integrationStore.getIntegrationName(integration?.source)

    const [form] = Form.useForm()

    const [syncInvoiceWithIntegration, setSyncInvoiceWithIntegration] = useState(
      localStorage.getItem(SYNC_INVOICE_WITH_INTEGRATION_LOCAL_KEY) === 'true',
    )

    const { isLoading } = useQuery(() => {
      if (!integrationStore.isProcore()) {
        return Promise.all([
          integrationStore.getProjectsRelationships(),
          integrationStore.getCompanyVendorsRelationships(),
        ])
      }
    })
    useQuery(() => {
      if (integrationStore.invoiceSyncTypeExpense() && !integrationStore.isProcore()) {
        return integrationStore.getCostCodeNumbersRelationships()
      }
    })
    useQuery(() => {
      if (userStore.canUseCostCode && costCodeSettings?.class_enabled && integrationStore.isQBO()) {
        return integrationStore.getCostCodeClassesRelationships()
      }
    }, [userStore.canUseCostCode, costCodeSettings?.class_enabled])

    const { isLoading: isLoadingSuggestions } = useQuery(() => {
      if (!integrationStore.isProcore()) {
        return integrationStore.getInvoiceSuggestions(invoiceStore.invoice?.id)
      }
    }, [invoiceStore.invoice?.id])

    useEffect(() => {
      if (integrationStore.invoiceSuggestions) {
        const suggestions = integrationStore.invoiceSuggestions
        form.setFieldsValue({
          vendor: makeOption(suggestions?.vendor),
          customer: makeOption(suggestions?.customer),
          ledgerAccount: makeOption(suggestions.ledger_account),
          class: makeOption(suggestions?.class),
        })
      }
    }, [integrationStore.invoiceSuggestions])

    useEffect(() => {
      if (invoice.project) {
        form.setFieldValue('internalProject', invoice?.project?.name)
      } else {
        const invoiceOrder = invoiceStore.getInvoiceOrder([...invoice?.orders][0]?.id)
        form.setFieldValue('internalProject', invoiceOrder?.order?.project?.name)
      }
    }, [invoiceStore.selectedOrders?.length, invoice?.project])

    useEffect(() => {
      form.setFieldValue(
        'internalVendor',
        invoice?.company_vendor?.vendor?.name || invoice?.company_vendor?.vendor_name,
      )
    }, [invoice?.company_vendor])

    if (isLoading || isLoadingSuggestions) {
      return null
    }

    return (
      <Modal
        title={
          <Space>
            <Typography.Text style={{ fontSize: 14 }}>Sync Invoice with {integrationName}</Typography.Text>
            <Checkbox
              onChange={(e) => {
                localStorage.setItem(SYNC_INVOICE_WITH_INTEGRATION_LOCAL_KEY, `${e.target.checked}`)
                setSyncInvoiceWithIntegration(e.target.checked)
              }}
              checked={syncInvoiceWithIntegration}
            />
          </Space>
        }
        footer={false}
        width={580}
        forceRender
        {...props}
      >
        {integration.status === RelationshipStockStatus.FAILED && (
          <Space direction="vertical" style={{ marginBottom: 16, width: '100%' }}>
            {integration?.errors?.map((error, index) => (
              <Alert type="error" message={error.fail_message} key={`error-${index}`} closable />
            ))}
          </Space>
        )}

        <Form form={form} layout="vertical" onFinish={onFinish}>
          {/* We may need to revisit this if suggestions needs multi mapping in the future */}
          {!integrationStore.isProcore() && (
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item label="SubBase Vendor" name="internalVendor">
                  <Input disabled bordered={false} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  label={`${integrationName} Vendor`}
                  name="vendor"
                  rules={[{ required: true, message: 'Vendor is required.' }]}
                >
                  <SelectAgaveVendors disabled={!syncInvoiceWithIntegration} canDisableSelected={false} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item label="SubBase Project" name="internalProject">
                  <Input disabled bordered={false} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  label={`${integrationName} Project`}
                  name="customer"
                  rules={[{ required: true, message: 'Project is required.' }]}
                >
                  <SelectAgaveProjects disabled={!syncInvoiceWithIntegration} canDisableSelected={false} />
                </Form.Item>
              </Col>

              {integrationStore.invoiceSyncTypeExpense() && (
                <>
                  <Col xs={24} sm={12} style={{ marginBottom: 24 }}>
                    <Typography.Text>Category</Typography.Text>
                  </Col>

                  <Col xs={24} sm={12}>
                    <Form.Item
                      label={`${integrationName} Category`}
                      name="ledgerAccount"
                      rules={[{ required: true, message: 'Category is required.' }]}
                    >
                      <SelectAgaveCostCodes disabled={!syncInvoiceWithIntegration} canDisableSelected={false} />
                    </Form.Item>
                  </Col>
                </>
              )}

              {userStore.canUseCostCode && costCodeSettings?.class_enabled && integrationStore.isQBO() && (
                <>
                  <Col xs={24} sm={12} style={{ marginBottom: 24 }}>
                    <Typography.Text>Class</Typography.Text>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item label={`${integrationName} Class`} name="class">
                      <SelectAgaveCostCodeClass disabled={!syncInvoiceWithIntegration} canDisableSelected={false} />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          )}
          <Box
            display="flex"
            justifyContent={integration.status === RelationshipStockStatus.FAILED ? 'space-between' : 'flex-end'}
          >
            {syncInvoiceWithIntegration ? (
              <>
                {integration.status === RelationshipStockStatus.FAILED && (
                  <Box>
                    <Button onClick={markAsSynced} loading={isSubmitting} style={{ marginRight: 5 }}>
                      Mark as synced
                    </Button>
                    <Popover
                      content={
                        <Typography.Text>
                          This action will mark the invoice as synced and clear the failed status. It&lsquo;s useful in
                          situations where the synchronization was completed successfully, but the sync request
                          mistakenly returned an error
                        </Typography.Text>
                      }
                    >
                      <QuestionCircleOutlined />
                    </Popover>
                  </Box>
                )}
                <Button type="primary" htmlType="submit" loading={isSubmitting}>
                  Submit
                </Button>
              </>
            ) : (
              <Button type="primary" onClick={onFinishWithoutSync} loading={isSubmitting}>
                Submit without sync
              </Button>
            )}
          </Box>
        </Form>
      </Modal>
    )
  },
)
