import React, { useEffect } from 'react'

import { Checkbox, Form, Input, Row, Col } from 'antd'
import { FormInstance } from 'antd/lib/form'

import { observer } from 'mobx-react-lite'

import { Address } from 'common/server/addresses'

type AddressProps = {
  form: FormInstance
  address?: Address
  showMainOfficeLabel?: boolean
  showActive?: boolean
}

// Using a Form here from Antd but besides for some nice email validation, don't think it buys us much
const AddressForm = observer<AddressProps>(({ form, address, showMainOfficeLabel, showActive }) => {
  // Update the form values any times the address changes
  useEffect(() => {
    form.setFieldsValue(address)
  }, [address])

  return (
    <Form layout="vertical" form={form} initialValues={address} name="address" style={{ width: '100%' }}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name={['nickname']} label="Address Nickname">
            <Input data-cy="address-nickname" placeholder="Warehouse A, Side Entrance" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name={['address_to']} label="Name">
            <Input data-cy="address-name" placeholder="John Doe" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item
            name={['address_line_1']}
            label="Address Line 1"
            rules={[{ required: true, message: 'Address Line 1 is required!' }]}
          >
            <Input data-cy="address-line-1" placeholder="123 Main St." />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item name={['address_line_2']} label="Address Line 2">
            <Input data-cy="address-line-2" placeholder="Apt 123" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name={['city']} label="City" rules={[{ required: true, message: 'City is required!' }]}>
            <Input data-cy="address-city" placeholder="Miami" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name={['state']} label="State" rules={[{ required: true, message: 'State is required!' }]}>
            <Input data-cy="address-state" placeholder="FL" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name={['zip_code']}
            label="Zip Code"
            rules={[
              { required: true, message: 'Zip Code is required!' },
              { pattern: /(^\d{5}$)|(^\d{5}-\d{4}$)/, message: 'Invalid zip code' },
            ]}
          >
            <Input data-cy="address-zip-code" placeholder="33133" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name={['phone_number']} label="Phone Number">
            <Input data-cy="address-phone-number" placeholder="786-281-8923" />
          </Form.Item>
        </Col>

        {showMainOfficeLabel && (
          <Col xs={24} sm={12}>
            <Form.Item
              label="Main Office"
              help={
                <>
                  When <strong>checked</strong> this is the address that will show up on your{' '}
                  <strong>Purchase Orders</strong>.
                </>
              }
            >
              <Form.Item noStyle name={['main_address']} valuePropName="checked">
                <Checkbox data-cy="address-main-address" />
              </Form.Item>
            </Form.Item>
          </Col>
        )}

        {showActive && (
          <Col xs={24} sm={12}>
            <Form.Item name={['active']} label="Active" valuePropName="checked">
              <Checkbox data-cy="address-active-inactive" />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  )
})

export default AddressForm
