import React from 'react'

import pluralize from 'pluralize'

import { WarningOutlined } from '@ant-design/icons'
import { Button, Space, Typography, Modal } from 'antd'

import { FlexBoxY, FlexBoxX } from 'common/components/boxes'

type ModalUpdateOrCreateMaterialProps = {
  visible: boolean
  onCancel: () => void
  onYes: () => void
  onNo: () => void
  ordersCount: number
}

export const ModalUpdateOrCreateMaterial = ({
  visible,
  onCancel,
  onYes,
  onNo,
  ordersCount,
}: ModalUpdateOrCreateMaterialProps) => {
  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={
        <FlexBoxX justifyContent="space-between">
          <Button onClick={onCancel}>Cancel</Button>
          <Space size="middle">
            <Button onClick={onNo}>Update only for this Order</Button>
            <Button type="primary" onClick={onYes}>
              Update for all Orders
            </Button>
          </Space>
        </FlexBoxX>
      }
    >
      <FlexBoxY>
        <FlexBoxX mb="12px">
          <WarningOutlined style={{ fontSize: 22, color: '#faad14', marginRight: 24 }} />
          <Typography.Text strong style={{ fontSize: 16 }}>
            Would you like to update the <Typography.Text strong>{ordersCount}</Typography.Text> other{' '}
            {pluralize('order', ordersCount)} where this material is used?
          </Typography.Text>
        </FlexBoxX>
        <Typography.Text style={{ marginLeft: 46 }}>
          Editing information for materials that have been previously ordered is dangerous. You are overwriting past
          orders and your vendors will NOT be notified of changes.
        </Typography.Text>
      </FlexBoxY>
    </Modal>
  )
}
