import axios from 'axios'

import { CompanyMaterialVendorPrice } from 'common/server/company_materials'
import { CostCodePhase } from 'common/server/cost_codes/cost_code_phases'
import { CostCode } from 'common/server/cost_codes/cost_codes'
import { OrderStates, OrderSubStates } from 'common/server/server_types'
import { Unit } from 'common/server/units'

export interface OrderMaterial {
  id?: string
  unit_cost: string
  quantity: number
  order: {
    id: string
    state: OrderStates
    sub_state: OrderSubStates
    created_at: string
    quote_provided_at?: string
    quoted_at?: string
    order_package_name: string
    order_number: string
    project: { name: string }
    company_vendor: { id: string; vendor_name: string }
  }
  cost_code?: CostCode
}
export interface CompanyMaterial {
  id?: string
  active?: boolean
  company_id?: string
  description: string
  product_identifier?: string
  manufacturer?: string
  unit_name?: string
  unit_id?: string
  unit?: Unit
  group?: string
  sub_group?: string
  size?: string
  connection_type?: string
  material?: string
  cached_average_price?: string
  cached_last_price?: string
  cached_lowest_price?: string
  cost_code?: CostCode
  created_from?: string
  requested_at?: string
  requested_by_id?: string
  company_material_vendor_prices?: CompanyMaterialVendorPrice[]
}

export interface ShowCompanyMaterialResponse
  extends Omit<
    CompanyMaterial,
    'cached_last_price' | 'cached_average_price' | 'vendors_last_prices' | 'cached_lowest_price'
  > {
  id?: string
  project_id?: string
  project_name?: string
  company_id: string
  cached_at: string
  cached_last_price: string
  cached_average_price: string
  cached_lowest_price: string
  cached_last_quoted_price: string
  cached_average_quoted_price: string
  cached_lowest_quoted_price: string
  vendors_last_prices: string[]
  order_materials?: OrderMaterial[]
  quote_materials?: OrderMaterial[]
  cost_code?: CostCode
  cost_code_phase?: CostCodePhase
  cost_code_phase_id?: string
  manufacturer_material_id?: string
  cost_code_id?: string
  image_url?: string
  tags?: string[]
  unit_price?: string
  remaining_quantity?: string
}

export interface CreateCompanyMaterial {
  id?: string
  description: string
  unit_name?: string
  group?: string
  cost_code_id?: string
  product_identifier?: string
  manufacturer?: string
  sub_group?: string
  size?: string
  connection_type?: string
  material?: string
  created_from?: string
  requested_by_id?: Nullable<string>
  requested_at?: Nullable<string>
  company_material_vendor_prices_attributes?: {
    id?: string
    price?: string | number
    company_vendor_id?: string
  }[]
}

export function show(id: string) {
  return axios.get<ShowCompanyMaterialResponse>(`/company_materials/${id}`)
}

export function create(company_material: CreateCompanyMaterial) {
  return axios.post<ShowCompanyMaterialResponse>('/company_materials', { company_material })
}

export function publicCreate(company_material: CreateCompanyMaterial, publicOrderFormUrlExtension: string) {
  return axios.post<ShowCompanyMaterialResponse>('/company_materials/public_create', {
    company_material,
    public_order_form_url_extension: publicOrderFormUrlExtension,
  })
}

export function update(company_material: CreateCompanyMaterial) {
  return axios.patch<ShowCompanyMaterialResponse>(`/company_materials/${company_material.id}`, { company_material })
}

export function search_key() {
  // @TODO: this is a temporary solution to get the search key for the search bar
  return {
    application_id: '',
    index_name: '',
    search_key: '',
  }
}

export function getCompanyMaterialTags() {
  return axios.get<{ company_material_tags: string[] }>('/company_materials/tags')
}
