import React from 'react'

import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Checkbox, Row, Col, Modal } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { SearchInput } from 'common/components/SearchInput'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { useStores } from 'contractor/hooks/use-stores'
import { PriceSheetMaterial } from 'contractor/server/price_sheet'

import { useEditPriceSheet } from '../context'

type SearchProps = {
  priceSheetMaterials: PriceSheetMaterial[]
  itemsCount: number
}

export const Search = observer<SearchProps>(({ priceSheetMaterials, itemsCount }) => {
  const {
    priceSheetStore: { priceSheetMaterialListStore },
  } = useStores()

  const persistentFilters = usePersistentFilters()

  const { selectedMaterials, setSelectedMaterials, form } = useEditPriceSheet()

  const handleCreateNewMaterialInBulk = () => {
    if (!selectedMaterials?.length) return

    const currentList = form.getFieldValue('priceSheetMaterials') || []

    const updatedList = currentList
      .filter((priceSheetMaterial) => selectedMaterials.includes(priceSheetMaterial.id))
      .map((priceSheetMaterial) => ({
        ...priceSheetMaterial,
        match_confidence: 'match',
        company_material: {
          description: priceSheetMaterial.description,
          size: priceSheetMaterial.size,
          unit: priceSheetMaterial.unit,
        },
      }))

    form.setFieldsValue({ priceSheetMaterials: updatedList })
  }

  return (
    <Box px={16} py={10} width="100%">
      <Row gutter={[10, 10]} align="middle">
        <Col xs={24} sm={8} md={6} lg={5} xl={4} xxl={3}>
          <Box display="flex" alignItems="center">
            <Checkbox
              style={{ minWidth: 16, marginRight: 10 }}
              checked={!!selectedMaterials?.length && selectedMaterials?.length === priceSheetMaterials?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedMaterials(priceSheetMaterials?.map((material) => material.id) || [])
                } else {
                  setSelectedMaterials([])
                }
              }}
              indeterminate={selectedMaterials?.length > 0 && selectedMaterials?.length < priceSheetMaterials?.length}
            />
            <Button
              onClick={() =>
                Modal.confirm({
                  title: `Are you sure to create ${itemsCount} items?`,
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    handleCreateNewMaterialInBulk()
                  },
                })
              }
              // disabled={!selectedMaterials?.length} disabled it for now
              disabled
              icon={<PlusOutlined />}
              style={{ width: '100%' }}
            >
              Create material
            </Button>
          </Box>
        </Col>
        <Col xs={21} sm={14} md={16} lg={18} xl={19} xxl={20}>
          <SearchInput
            value={priceSheetMaterialListStore.searchState['search']}
            onSearch={(search: string) => persistentFilters.handleChangeFilters({ search })}
          />
        </Col>
        <Col xs={3} sm={2} md={2} lg={1} xl={1} xxl={1}>
          <Button
            onClick={() =>
              Modal.confirm({
                title: `Are you sure to delete ${itemsCount} items?`,
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  handleCreateNewMaterialInBulk()
                },
              })
            }
            // disabled={!selectedMaterials?.length} disabled it for now
            disabled
            icon={<DeleteOutlined />}
            danger
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
    </Box>
  )
})
