import React from 'react'

import styled from '@emotion/styled'

import { Tag } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { PsqlTableCells } from 'common/components/PsqlTable'

interface CellCostCodeProps {
  code?: string
}

const StyledTag = styled(Tag)`
  margin: 0 4px;
`

export const CellPhaseCode = (props: CellCostCodeProps) => {
  const { code } = props

  if (!code) return <PsqlTableCells.NoContent />

  return (
    <FlexBoxX alignItems="flex-start" justifyContent="flex-start" height="22px">
      {code && <StyledTag>{code}</StyledTag>}
    </FlexBoxX>
  )
}
