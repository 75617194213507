/** @jsx jsx */

import * as React from 'react'

import { Column, Row } from 'react-table'

import moment from 'moment'

import { jsx } from '@emotion/core'

import { observer } from 'mobx-react-lite'

import DateTimePicker from 'common/components/date_time_picker'

interface DatePickerCellProps {
  value: string
  row: Row
  column: Column & {
    onBlurChange: (index: number, id: string, value: string) => void
    border?: boolean
  }
  rowDisabled: boolean
}

const DatePickerCell: React.FC<DatePickerCellProps> = observer(({ value, row, column, rowDisabled }) => {
  const onChangeValue = (value): void => {
    column.onBlurChange(row.index, column.id, value)
  }

  const inputStyle = {
    width: '150px',
    marginLeft: '3px',
  }
  if (column.border !== true) {
    inputStyle['border'] = 'none'
    inputStyle['boxShadow'] = 'none'
    inputStyle['outline'] = 'none'
  }

  if (rowDisabled) {
    return <span>{value && moment(value).format('MM/DD/YYYY')}</span>
  }

  return (
    // TODO: pass in style
    <DateTimePicker value={value} onChange={onChangeValue} />
  )
})

export default DatePickerCell
