import React from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Popover, Tag, Typography } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'

const MAX_TAGS_SHOW = 3

interface MaterialItemTagsProps {
  label: string
  value?: Array<string>
}

export const MaterialItemTags = ({ label = '', value = [] }: MaterialItemTagsProps) => {
  if (!value.length) {
    return null
  }

  const firstThreeTags = value.slice(0, MAX_TAGS_SHOW)
  const remainingTags = value.slice(MAX_TAGS_SHOW)

  return (
    <FlexBoxX alignItems="flex-start" justifyContent="flex-start" flexGrow={0}>
      {label && (
        <Typography.Text type="secondary" style={{ marginRight: 4, fontSize: 12, wordBreak: 'normal' }}>
          {label}:
        </Typography.Text>
      )}

      <FlexBoxX alignItems="flex-start" justifyContent="flex-start" flexWrap="wrap" gridRowGap={8}>
        {firstThreeTags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}

        {!!remainingTags.length && (
          <>
            <Popover
              content={() => (
                <FlexBoxX
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDirection="column"
                  flexWrap="wrap"
                  gridRowGap={8}
                >
                  {remainingTags.map((tag, index) => (
                    <Tag key={index}>{tag}</Tag>
                  ))}
                </FlexBoxX>
              )}
              trigger="hover"
              zIndex={99999}
            >
              <Tag style={{ cursor: 'pointer' }}>+ {remainingTags.length}</Tag>
            </Popover>

            <Popover
              arrowPointAtCenter
              placement="topLeft"
              content={
                <Box>
                  When the item has more than three tags, <br />
                  the remaining tags will automatically appear <br />
                  in a tooltip when hovering over the indicator <br />
                  next to how many remaining tags there are.
                </Box>
              }
              title="Tags"
              zIndex={99999}
            >
              <Box position="relative" mr={12}>
                <QuestionCircleOutlined style={{ fontSize: 12, position: 'absolute' }} />
              </Box>
            </Popover>
          </>
        )}
      </FlexBoxX>
    </FlexBoxX>
  )
}
