// ItemHeader.tsx
import React from 'react'

import moment from 'moment'

import HistoryOutlined from '@ant-design/icons/HistoryOutlined'
import { Popover, Tag, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { formatEnumValue } from 'common/helpers/formatters'
import { OrderMaterial } from 'common/server/orders'

type ItemHeaderProps = {
  orderMaterial: OrderMaterial
  isLocked: boolean
  form: FormInstance
  isResolved?: boolean
}

export const ItemHeader = (props: ItemHeaderProps) => {
  const { orderMaterial, isLocked, form, isResolved } = props
  const deliveryIssues = form.getFieldValue(`delivery_issues_${orderMaterial.id}`) || []
  const issueCount = deliveryIssues.length
  const resolvedAt = form.getFieldValue(`resolved_${orderMaterial.id}`)
    ? form.getFieldValue(`resolved_at_${orderMaterial.id}`) || moment().toISOString()
    : null
  const showHistory = issueCount > 0 && !!resolvedAt
  const showIssues = !isResolved && isLocked && issueCount > 0

  return (
    <Box flexGrow={1}>
      <FlexBoxY alignItems="start" minHeight={32} width="100%">
        <FlexBoxX>
          <Typography.Text>{orderMaterial?.company_material?.description}</Typography.Text>
          {showIssues && (
            <Box ml={8}>
              {deliveryIssues.map((issue) => (
                <Tag color="red" key={issue}>
                  {formatEnumValue(issue)}
                </Tag>
              ))}
            </Box>
          )}
          {showHistory && (
            <Popover
              trigger={['hover', 'focus', 'click']}
              title="Delivery issue resolved!"
              content={
                <FlexBoxY alignItems="start">
                  <Typography.Text type="secondary">
                    This material had the following {issueCount > 1 ? 'issues' : 'issue'}:{' '}
                    {deliveryIssues.map(formatEnumValue).join(', ')}.
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    and was marked resolved at {moment(resolvedAt).format('YYYY-MM-DD HH:mm')}
                  </Typography.Text>
                </FlexBoxY>
              }
            >
              <HistoryOutlined style={{ marginLeft: 8 }} />
            </Popover>
          )}
        </FlexBoxX>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          Order quantity: {orderMaterial?.quantity}
        </Typography.Text>
        {orderMaterial.company_note && <Typography.Text type="secondary">{orderMaterial.company_note}</Typography.Text>}
      </FlexBoxY>
    </Box>
  )
}
