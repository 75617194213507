import { CellFormattedText } from 'common/components/PsqlTable/cells/cell_formatted_text'
import { CellNoContent } from 'common/components/PsqlTable/cells/cell_no_content'

export * from './table'

// @todo: create this as composite pattern (Table, Provider, Cells...)
export const PsqlTableCells = {
  FormattedText: CellFormattedText,
  NoContent: CellNoContent,
}
