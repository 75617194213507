import React from 'react'

import { useDrag } from 'react-dnd'

type DraggableBodyRowProps<Item> = React.HTMLAttributes<HTMLTableRowElement> & {
  index: number
  item: Item
  disabled?: boolean
}

const autoScroll = (event) => {
  const SCROLL_THRESHOLD = 100
  const SCROLL_SPEED = 10

  const { clientY } = event
  const { innerHeight } = window

  // If the mouse is near the top
  if (clientY < SCROLL_THRESHOLD) {
    window.scrollBy(0, -SCROLL_SPEED)
  }
  // If the mouse is near the bottom
  else if (clientY > innerHeight - SCROLL_THRESHOLD) {
    window.scrollBy(0, SCROLL_SPEED)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DraggableBodyRow = <Item extends Record<string, any>>({
  index,
  item: itemProp,
  style,
  disabled,
  ...restProps
}: DraggableBodyRowProps<Item>) => {
  const [, drag] = useDrag(
    () => ({
      type: 'DraggableBodyRow',
      item: { index, ...itemProp },
      canDrag: !!itemProp?.company_material?.id && !disabled,
    }),
    [itemProp, index],
  )

  return (
    <tr
      ref={drag}
      onDrag={autoScroll}
      style={{ cursor: itemProp?.company_material?.id && !disabled ? 'move' : 'default', ...style }}
      {...restProps}
    />
  )
}
