import React, { useState, useEffect, useImperativeHandle } from 'react'

import { Typography, Skeleton, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { formatEnumValue, formatDateString } from 'common/helpers/formatters'
import { deliveryRequests, Deliveries } from 'common/server/deliveries'

import { useStores } from 'contractor/hooks/use-stores'

import { DeliveriesTable } from './components/DeliveriesTable'
import { useDashboard } from './context'

export type DeliveryIssuesRef = {
  fetchDeliveries: () => void
}

const columns: ColumnsType<Deliveries.Index> = [
  {
    title: 'Issue',
    dataIndex: 'delivery_issue_type',
    key: 'delivery_issue_type',
    render: (issue) => {
      return <Tag color="red">{formatEnumValue(issue)}</Tag>
    },
  },
  {
    title: 'Name',
    dataIndex: 'order_name',
    key: 'name',
  },
  {
    title: 'Delivered On',
    dataIndex: 'best_guess_delivered_at',
    key: 'best_guess_delivered_at',
    render: (date) => <span>{formatDateString(date)}</span>,
  },
]

const DeliveryIssues = observer<{}, DeliveryIssuesRef>(
  (_, ref) => {
    const { deliveryStore, projectStore } = useStores()

    const [deliveries, setDeliveries] = useState<Array<Deliveries.Index>>()
    const { deliveryDetailRef } = useDashboard()

    const fetchDeliveries = async () => {
      const response = await deliveryRequests.index({
        project_active: true,
        has_open_issue: true,
        project_id: projectStore.selectedProject?.id,
      })

      setDeliveries(response.records)
    }

    useImperativeHandle(ref, () => ({ fetchDeliveries }))

    useEffect(() => {
      fetchDeliveries()
    }, [projectStore.selectedProject])

    if (deliveries === undefined) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
          <Skeleton paragraph={{ rows: 5 }} />
        </Box>
      )
    }

    if (deliveries.length === 0) {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          <Typography.Text type="secondary">No open delivery issues</Typography.Text>
        </Box>
      )
    }

    function handleSelectDelivery(id: string) {
      deliveryStore.selectDelivery(id).then(() => deliveryDetailRef.current?.show())
    }

    return (
      <DeliveriesTable
        columns={columns}
        onSelectDelivery={handleSelectDelivery}
        deliveries={deliveries}
        showProjectName={!projectStore.selectedProject}
      />
    )
  },
  { forwardRef: true },
)

export default DeliveryIssues
