import axios, { AxiosResponse } from 'axios'

import { CompanyMaterial } from 'common/server/company_materials'
import { OrderStatesFilter, OrderSubStates, SearchKeysResponse } from 'common/server/server_types'

// TODO: This isn't actually fully implemented, has much much more info
export interface OrderMaterialHit {
  objectID: string
  id: string
  created_at: string
  updated_at: string
  project_name: string
  company_note: string
  vendor_note: string
  order: {
    id: string
    order_number: string
    state: OrderStatesFilter
    sub_state: OrderSubStates
  }
  cost_code?: {
    code: string
    description?: string
  }
  cost_code_phase?: {
    id: string
    code: string
  }
  project_identifier?: string
}

export async function search_key(): Promise<AxiosResponse<SearchKeysResponse>> {
  return await axios.get('/order_materials/search_key')
}

export const emptyOrderMaterial = {
  quantity: null,
  unit_cost: null,
  delivery_id: '',
  // TODO: We have many additional columns in company material
  company_material: {} as CompanyMaterial,
  vendor_note: null,
}
