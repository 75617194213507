import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

export const useActiveView = () => {
  const history = useHistory()
  const initialActiveKey = React.useCallback(() => {
    const localActiveKey = localStorage.getItem('@subbase/orders/active-view')
    if (localActiveKey && localActiveKey !== 'undefined') {
      return localActiveKey
    } else {
      const activeKey = new URLSearchParams(location.search).get('view')
      return activeKey || 'all_orders'
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('activeKey', initialActiveKey())

    const searchParams = new URLSearchParams(history.location.search)
    searchParams.set('view', initialActiveKey())
    history.push({ search: searchParams.toString() })
  }, [initialActiveKey, history])

  return initialActiveKey()
}
