import React from 'react'

import _ from 'lodash'

import { PaperClipOutlined } from '@ant-design/icons'
import { Divider, Space, Tooltip, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { formatDateStringShort } from 'common/helpers/formatters'

import { ConsolidatedInvoiceInboxes } from 'contractor/server/invoices/inbox/consolidated_invoice_inboxes'

const FileLink = ({ file }) => {
  return (
    <Space>
      <PaperClipOutlined />
      <Typography.Link href={file.url} style={{ marginLeft: 4 }} target="_blank">
        {file.filename}
      </Typography.Link>
    </Space>
  )
}

const InvoiceLink = ({ invoice, invoiceCount, index }) => {
  if (invoice.discarded_at) {
    return (
      <span>
        <Tooltip title={`Invoice deleted at ${formatDateStringShort(invoice.discarded_at)}`}>
          <Typography.Text delete>#{invoice.number}</Typography.Text>
        </Tooltip>
        {index < invoiceCount - 1 ? ', ' : ''}
      </span>
    )
  }

  return (
    <span>
      <Typography.Link href={`/invoice/${invoice.id}`} target="_blank">
        #{invoice.number}
      </Typography.Link>
      {index < invoiceCount - 1 ? ', ' : ''}
    </span>
  )
}

export const InboxAttachments = ({
  consolidatedInbox,
}: {
  consolidatedInbox: ConsolidatedInvoiceInboxes.InvoiceInbox
}) => {
  if (!consolidatedInbox || consolidatedInbox.files.length === 0) {
    return null
  }
  const createdInvoiceFiles = consolidatedInbox.files.filter((file) => {
    const invoice_count = file.extras?.invoice_count || 0
    return invoice_count > 0
  })

  if (createdInvoiceFiles.length === 0) {
    return null
  }

  const ignoredFiles = createdInvoiceFiles.filter((file) => {
    return !_.isEmpty(file.extras?.ignored_pages)
  })

  return (
    <>
      <Box width="100%" display="flex" flexDirection="column" alignItems="flex-start" mb={0}>
        <Typography.Text strong>Created Invoices By File</Typography.Text>
        <Box mt={8}>
          {createdInvoiceFiles.map((file) => {
            const invoice_count = file.extras?.invoice_count || 0
            if (invoice_count === 0) {
              return null
            }

            return (
              <div key={`file-${file.id}`}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <FileLink file={file} />
                </div>
                <div style={{ marginBottom: 16, marginLeft: 16 }}>
                  {file.extras.invoices
                    ?.filter((invoice) => invoice.invoice_flow_id === file.invoice_flow_id)
                    .map((invoice, invoiceIndex) => {
                      return (
                        <InvoiceLink
                          key={`invoice-${invoice.id}`}
                          invoice={invoice}
                          invoiceCount={file.extras.invoice_count}
                          index={invoiceIndex}
                        />
                      )
                    })}
                </div>
              </div>
            )
          })}
        </Box>
      </Box>
      {ignoredFiles.length > 0 ? (
        <>
          <Divider style={{ marginBottom: 0, marginTop: 0 }} />
          <Box width="100%" display="flex" flexDirection="column" alignItems="flex-start" mb={0}>
            <Typography.Paragraph>
              <Typography.Text strong>Ignored Pages By File</Typography.Text>
            </Typography.Paragraph>
            <Box mt={8}>
              {ignoredFiles.map((file) => {
                const key = `ignored-file-key-${file.id}`
                return (
                  <div key={key}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                      <FileLink file={file} />
                      <Typography.Text style={{ marginLeft: 10 }}>
                        {`(Pages ${file.extras.ignored_pages})`}
                      </Typography.Text>
                    </div>
                  </div>
                )
              })}
            </Box>
          </Box>
        </>
      ) : null}
      {/* Two line divider */}
      <Divider style={{ marginBottom: 0, marginLeft: '-23px', marginRight: '-23px', width: 'auto', marginTop: 0 }} />
      <Divider
        style={{
          marginBottom: 0,
          marginTop: '-12px',
          marginLeft: '-23px',
          marginRight: '-23px',
          width: 'auto',
        }}
      />
    </>
  )
}
