import React from 'react'

import DateTimePicker from 'common/components/date_time_picker'

export const DateEditorCell = ({ cell, onChange }) => {
  return (
    <DateTimePicker
      value={cell.value}
      onChange={(date) => {
        onChange({ ...cell, value: date })
      }}
      style={{ width: '100%', border: 'none', padding: '5px', boxShadow: 'none' }}
      autoFocus
      format={['MM/DD/YYYY', 'MM/DD/YY']}
    />
  )
}
