import React from 'react'

import { useHistory } from 'react-router-dom'

import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, Space } from 'antd'
import { MenuProps } from 'antd/lib/menu'

import { Box } from 'common/components/boxes'

import { useFlag } from 'contractor/hooks/use-flag'

const insertIf = (condition, ...elements) => (condition ? elements : [])

interface ActionsProps {
  projectId?: string
}

export const Actions = (props: ActionsProps) => {
  const { projectId } = props
  const history = useHistory()
  const newCommitmentTypesEnabled = useFlag('new_commitment_types')

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    const commitmentType = e.key
    history.push(projectId ? `/${commitmentType}/new?project_id=${projectId}` : `/${commitmentType}/new`)
  }

  const items: MenuProps['items'] = [
    {
      label: 'Create Material Commitment',
      key: 'material_commitments',
    },
    ...insertIf(newCommitmentTypesEnabled, {
      label: 'Create Concrete Commitment',
      key: 'concrete_commitments',
    }),
    ...insertIf(newCommitmentTypesEnabled, {
      label: 'Create Rental Commitment',
      key: 'rental_commitments',
      disabled: true,
    }),
    ...insertIf(newCommitmentTypesEnabled, {
      label: 'Create Budget Commitment',
      key: 'budget_commitments',
      disabled: true,
    }),
  ]

  const menuProps: MenuProps = {
    items,
    onClick: handleMenuClick,
  }

  return (
    <Box display="flex" gridGap={8} alignItems="center">
      <Dropdown menu={menuProps}>
        <Button type="primary">
          <Space>
            Create
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </Box>
  )
}
