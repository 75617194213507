const radii = {
  none: '0',
  sm: '2px',
  md: '4px',
  lg: '8px',
  full: '9999px',
}

export type Radii = typeof radii
export default radii
