import React from 'react'

import ArrowLeftIcon from '@ant-design/icons/ArrowLeftOutlined'
import { Button, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import { Header } from 'common/components/Page/styles'

export const InvoiceGone = ({ goBack }: { goBack: () => void }) => {
  return (
    <Page>
      <Page.Header>
        <Header
          backIcon={<ArrowLeftIcon />}
          onBack={goBack}
          title={
            <Box
              display="flex"
              alignItems={{ _: 'flex-start', sm: 'center' }}
              flexDirection={{ _: 'column', sm: 'row' }}
            >
              <Box display="flex" alignItems="center" mb={2}>
                <Typography.Text style={{ marginRight: 20 }}>Invoice</Typography.Text>
              </Box>
            </Box>
          }
        />
      </Page.Header>
      <Page.Content>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
          <Typography.Text type="secondary" style={{ fontSize: 14 }}>
            SubBase is reprocessing this invoice because we found an extraction problem.
          </Typography.Text>
          <Typography.Text type="secondary" style={{ fontSize: 14 }}>
            This page will auto refresh in 5 seconds, please wait!
          </Typography.Text>
          <br />
          <Button type="primary" onClick={() => window.location.reload()} style={{ marginLeft: 20 }}>
            Refresh now
          </Button>
        </Box>
      </Page.Content>
    </Page>
  )
}
