import React from 'react'

import { Empty } from 'antd'

import { Box } from 'common/components/boxes'

const commonBoxProps = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}

// Empty state for invoice approvals
export const StateResults = () => {
  return (
    <Box {...commonBoxProps}>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Congrats, you are all caught up with your invoice approvals!"
      />
    </Box>
  )
}
