/** @jsx jsx */

import * as React from 'react'

import { Column, Row } from 'react-table'

import _ from 'lodash'

import { jsx } from '@emotion/core'

import { Tag } from 'antd'

import { observer } from 'mobx-react-lite'

import { Select } from 'common/components/Select'
import { useTheme } from 'common/hooks/use-theme'

interface EditableTagsProps {
  value: string[]
  row: Row
  column: Column & {
    onChange?: (index: number, id: string, value: string[]) => void
    onBlurChange?: (index: number, id: string, value: string[]) => void
    onFocus?: (index: number, id: string, value: string[]) => void
    border?: boolean
  }
  rowDisabled?: boolean
}

const EditableTags: React.FC<EditableTagsProps> = observer(({ value, row, column, rowDisabled }) => {
  const theme = useTheme()

  if (rowDisabled) {
    return (
      <span css={{ marginLeft: '10px' }}>
        {value?.map((v, i) => (
          <Tag key={i}>{v}</Tag>
        ))}
      </span>
    )
  }

  const onChange = (value): void => {
    column.onBlurChange && column.onBlurChange(row.index, column.id, value)
  }
  const onFocus = () => {
    column.onFocus(row.index, column.id, value)
  }

  return (
    <Select
      creatable={true}
      isClearable={false}
      isMulti
      onFocus={onFocus}
      placeholder={column['placeholder'] || ''}
      styles={{
        dropdownIndicator: () => {
          return { display: 'none' }
        },
        indicatorSeparator: () => {
          return { display: 'none' }
        },
        menu: () => {
          return { display: 'none' }
        },
        control: (style) => {
          return _.omitBy(
            {
              ...style,
              border: column.border !== true ? 'none' : undefined,
              outline: 'none',
              boxShadow: 'none',
              width: '90%',
              marginLeft: '3px',
              '&:hover': {
                boxShadow: `0 0 0 2px ${theme.colors['gray-7']}`,
              },
              '&:focus-within': {
                boxShadow: `0 0 0 2px ${theme.colors['gray-7']}`,
              },
            },
            _.isNil,
          )
        },
      }}
      value={value?.map((v): { label: string; value: string } => {
        return { label: v, value: v }
      })}
      // Weird shenanigans we are doing here
      onChange={(v): void => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange(((v || []) as any[]).map((v) => v.value))
      }}
    />
  )
})

export default EditableTags
