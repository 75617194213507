import React from 'react'

import { CostCodeEditorCell, CostCodeEditorCellProps } from 'common/components/OrderMaterialsSpreadsheet'

import { useStores } from 'contractor/hooks/use-stores'

type CustomCostCodeEditorCellProps = CostCodeEditorCellProps

export const CustomCostCodeEditorCell = (props: CustomCostCodeEditorCellProps) => {
  const { costCodeStore, companySettingStore } = useStores()

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  return (
    <CostCodeEditorCell
      {...props}
      costCodes={costCodeStore.costCodeListStore.records}
      costCodeSettings={costCodeSettings}
    />
  )
}
