import React from 'react'

import { Table } from 'antd'

import { observer } from 'mobx-react-lite'

import { makeOption, ValueType } from 'common/components/DebounceSelect'
import { MappingDirections } from 'common/server/server_types'

import { SelectAgaveCostCodeClass } from 'contractor/components/SelectAgaveCostCodeClass'
import {
  hasSuggestions,
  maybeAddApproveRejectColumn,
} from 'contractor/components/SelectExternalMappings/select_external_mappings'
import { SelectIntegrationEntity } from 'contractor/components/SelectIntegrationEntity/select_integration_entity'
import { useStores } from 'contractor/hooks/use-stores'
import RelationshipsTable from 'contractor/pages/Integrations/Mappings/Relationships/relationships_table'
import { SuggestionAlert } from 'contractor/pages/Integrations/Mappings/suggestion_alert'
import { useUpdateMappingsHook } from 'contractor/pages/Integrations/Mappings/use_update_mappings_hook'
import { ListParams } from 'contractor/server/integrations'
import { CostCodeClass } from 'contractor/server/integrations/cost_code_classes'

interface CostCodeClassesTableProps {
  filter?: ListParams
  setFilter?: React.Dispatch<React.SetStateAction<ListParams>>
  isLoading: boolean
}

const CostCodeClassesTable = observer<CostCodeClassesTableProps>(({ isLoading, filter, setFilter }) => {
  const { integrationStore, userStore } = useStores()

  const reloadCallback = (fetchRelationships = true) => {
    if (fetchRelationships) integrationStore.getCostCodeClassesRelationships(filter)
    integrationStore.getCostCodeClasses(filter)
    integrationStore.getCostCodeClassesCount({ search: filter?.search })
  }

  const { handleUpdate, handleDirectUpdate, handleSuggestionsApproval, handleSuggestionsRejection, updating } =
    useUpdateMappingsHook({
      update: integrationStore.updateCostCodeClass,
      reloadCallback,
    })

  if (integrationStore.mappingDirection === MappingDirections.INSIDE_OUT) {
    const columns = [
      {
        title: 'SubBase Code',
        dataIndex: 'code',
        width: '20%',
      },
      {
        title: 'SubBase Description',
        dataIndex: 'description',
        width: '20%',
      },
      {
        title: `${integrationStore.title()} Cost Classes`,
        width: '60%',
        dataIndex: 'external_relationships',
        render: (_, row) => {
          const selected = []
          const suggestions = hasSuggestions(row)
          if (suggestions) {
            selected.push(...row.mapping_suggestions.map((item) => makeOption(item)))
          } else {
            selected.push(...row.external_relationships.map((item) => makeOption(item)))
          }
          return (
            <SelectAgaveCostCodeClass
              selected={selected}
              loading={updating}
              showArrow={true}
              disabled={!userStore.canSyncWithErp}
              onChange={(value) => handleUpdate({ option: value, entity: row })}
              status={suggestions ? 'warning' : undefined}
            />
          )
        },
      },
    ]
    maybeAddApproveRejectColumn(
      'costCodeClasses',
      integrationStore.costCodeClasses?.data,
      integrationStore.showCostCodeClassesSuggestions,
      columns,
      handleSuggestionsApproval,
      handleSuggestionsRejection,
    )
    return (
      <>
        <SuggestionAlert showSuggestions={integrationStore.showCostCodeClassesSuggestions()} />
        <Table<CostCodeClass>
          pagination={{
            total: integrationStore.costCodeClasses?.totalCount,
            current: integrationStore.costCodeClasses?.currentPage,
            pageSize: integrationStore.costCodeClasses?.pageItems,
            onChange: (page) => {
              setFilter((prev) => ({ ...prev, page }))
            },
            showSizeChanger: false,
          }}
          loading={isLoading}
          rowKey="id"
          style={{ width: '100%' }}
          columns={columns}
          dataSource={integrationStore.costCodeClasses?.data}
        />
      </>
    )
  }

  const disabledOption = (item: CostCodeClass) =>
    integrationStore.enabledCostClassesMultiMapping() ? false : item.external_relationships.length > 0

  const makeInternalOption = (item: CostCodeClass) =>
    ({
      value: item.id,
      label: item.code,
      disabled: disabledOption(item),
    } as ValueType)

  return (
    <RelationshipsTable
      isLoading={isLoading}
      relationships={integrationStore.costCodeClassRelationships}
      renderRowTitle="Cost Classes"
      showSuggestions={integrationStore.showCostCodeClassesSuggestions()}
      handleSuggestionsApproval={handleSuggestionsApproval}
      handleSuggestionsRejection={handleSuggestionsRejection}
      filter={filter}
      setFilter={setFilter}
      renderRow={(row) => (
        <SelectIntegrationEntity
          loading={updating}
          row={row}
          disabled={!userStore.canSyncWithErp}
          optionApi={integrationStore.getCostCodeClasses}
          optionData={integrationStore.costCodeClasses}
          makeInternalOption={makeInternalOption}
          handleUpdate={handleDirectUpdate}
          status={row.mapping_suggestions && row.mapping_suggestions.length > 0 ? 'warning' : undefined}
        />
      )}
    />
  )
})

export default CostCodeClassesTable
