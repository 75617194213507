import React, { useState } from 'react'

import { Card } from 'antd'

import { PdfViewer } from 'common/components/PdfViewer'
import { InvoiceResponse } from 'common/server/invoice'

import { CardTitle } from './card_title'
import { IssueAction } from './issue_action'
import { SourceAction } from './source_action'

export type InvoiceCardProps = {
  invoice: InvoiceResponse
  onOpenInvoiceInbox: (invoiceInboxId: string) => void
}

export const InvoiceCard = ({ invoice, onOpenInvoiceInbox }: InvoiceCardProps) => {
  const [index, setIndex] = useState(0)

  return (
    <Card
      id={invoice.id}
      title={<CardTitle size={invoice.extracted_files.length} onChangePage={setIndex} />}
      aria-label="invoice-card"
      style={{ width: '50%', flexGrow: 1, display: 'flex', flexDirection: 'column' }}
      bodyStyle={{ padding: 0, flexGrow: 1 }}
      actions={[
        <SourceAction invoice={invoice} onOpenInvoiceInbox={onOpenInvoiceInbox} key="source-action" />,
        <IssueAction key="issue-action" />,
      ]}
    >
      <PdfViewer
        url={invoice.extracted_files[index]?.url}
        key={`${invoice.id}-i-key`}
        style={{ height: '100%', borderRadius: 4, minHeight: 'auto' }}
      />
    </Card>
  )
}
