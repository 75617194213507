import React from 'react'

import { v4 as uuidV4 } from 'uuid'

import { Button, Checkbox, Form, Typography } from 'antd'
import { Card } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { InputCurrency } from 'common/components/InputCurrency'
import { SearchInput } from 'common/components/SearchInput'

import { useStores } from 'contractor/hooks/use-stores'

import { CreateGroupItem } from '../components/CreateGroupItem'
import { DeleteInvoiceItems } from '../components/DeleteInvoiceMaterials'
import { useInvoice } from '../context'
import { InvoiceMaterials } from '../InvoiceMaterials'
import { CalculatedLabel } from './calculated_label'
import { TaxInput } from './tax_input'

export const ExtractedData = observer(() => {
  const { invoiceStore } = useStores()

  const {
    form,
    setSelectedInvoiceDirty,
    calculatedGrandTotal,
    calculatedSubtotal,
    currencyFormatter,
    lockInvoice,
    handleSearch,
    toggleAllInvoiceMaterialsChecked,
    allInvoiceMaterialsIsChecked,
    invoiceMaterialsFiltered,
    searchTerm,
  } = useInvoice()

  const invoice = invoiceStore.invoice

  const handleAddItem = () => {
    invoiceStore.updateSelectedInvoice('invoice_materials', [
      ...invoice?.invoice_materials,
      {
        id: `new-item-${uuidV4()}`,
        description: '',
        quantity_shipped: 0,
        uom: '',
        unit_price: 0,
        extended_price: 0,
        key: uuidV4(),
        invoice_id: invoice?.id,
        accepts_tax_split: true,
      },
    ])
    setSelectedInvoiceDirty(true)
  }

  React.useEffect(() => {
    form.setFieldsValue({
      taxAmount: invoice?.tax_amount,
      discountAmount: invoice?.discount_amount,
      shippingCost: invoice?.shipping_cost,
      otherCosts: invoice?.other_costs,
    })
  }, [invoice?.tax_amount, invoice?.discount_amount, invoice?.shipping_cost, invoice?.other_costs])

  const extractedSubtotal = Number(invoice?.subtotal_amount)
  const extractedGrandTotal = Number(invoice?.total_amount)

  const extractedSubtotalFormatted = currencyFormatter(extractedSubtotal)
  const extractedGrandTotalFormatted = currencyFormatter(extractedGrandTotal)

  const calculatedSubtotalFormatted = currencyFormatter(calculatedSubtotal)
  const calculatedGrandTotalFormatted = currencyFormatter(calculatedGrandTotal)

  const groupButtonIsDisabled = invoiceMaterialsFiltered.groups.some((group) => group.checked)

  return (
    <FlexBoxY maxWidth="100%" style={{ gap: 16 }}>
      <Card
        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        bodyStyle={{ padding: 10, flexGrow: 1, display: 'flex', alignItems: 'center' }}
      >
        <Checkbox onChange={() => toggleAllInvoiceMaterialsChecked()} checked={allInvoiceMaterialsIsChecked} />

        <FlexBoxX ml={8} style={{ gap: 8 }}>
          <CreateGroupItem disabled={groupButtonIsDisabled} />

          <Button type="ghost">Accounting details</Button>

          <DeleteInvoiceItems />

          <SearchInput onSearch={handleSearch} onChange={(e) => handleSearch(e.target.value)} value={searchTerm} />
        </FlexBoxX>
      </Card>

      <Form
        key={invoice?.id}
        disabled={!lockInvoice.canEditInvoice}
        form={form}
        onChange={() => setSelectedInvoiceDirty(true)}
        layout="vertical"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: 1,
          width: '100%',
        }}
      >
        <Box flexGrow={1} display="flex" flexDirection="column">
          <Box overflow="auto" height={0} flexGrow={1}>
            <InvoiceMaterials />
            <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end" mt={12}>
              <Button disabled={true /*!lockInvoice.canEditInvoice*/} onClick={() => handleAddItem()} type="primary">
                Add Item
              </Button>
            </Box>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ gap: 16 }} mt={12}>
          <CalculatedLabel
            label="Subtotal"
            value={calculatedSubtotalFormatted}
            errorMessage={
              <Typography.Text style={{ width: 250, display: 'block' }}>
                When we attempted to extract the subtotal directly from the invoice we found{' '}
                <Typography.Text strong underline>
                  {extractedSubtotalFormatted}
                </Typography.Text>{' '}
                which does not match the calculated value. Please double check that everything is correct.
              </Typography.Text>
            }
            showError={calculatedSubtotalFormatted !== extractedSubtotalFormatted}
            placement="topLeft"
          />

          <Form.Item label="Tax" name="taxAmount" style={{ flexGrow: 1, margin: 0 }}>
            <TaxInput tabIndex={51} disabled={!lockInvoice.canEditInvoice} />
          </Form.Item>
          <Form.Item label="Shipping" name="shippingCost" style={{ flexGrow: 1, margin: 0 }}>
            <InputCurrency tabIndex={52} disabled={!lockInvoice.canEditInvoice} />
          </Form.Item>
          <Form.Item label="Other costs" name="otherCosts" style={{ flexGrow: 1, margin: 0 }}>
            <InputCurrency tabIndex={53} disabled={!lockInvoice.canEditInvoice} />
          </Form.Item>
          <Form.Item label="Discount" name="discountAmount" style={{ flexGrow: 1, margin: 0 }}>
            <InputCurrency tabIndex={54} disabled={!lockInvoice.canEditInvoice} />
          </Form.Item>

          <CalculatedLabel
            label="Grand Total"
            value={calculatedGrandTotalFormatted}
            errorMessage={
              <Typography.Text style={{ width: 250, display: 'block' }}>
                When we attempted to extract the grand total directly from the invoice we found{' '}
                <Typography.Text strong underline>
                  {extractedGrandTotalFormatted}
                </Typography.Text>{' '}
                which does not match the calculated value. Please double check that everything is correct.
              </Typography.Text>
            }
            showError={calculatedGrandTotalFormatted !== extractedGrandTotalFormatted}
            placement="topRight"
          />
        </Box>
      </Form>
    </FlexBoxY>
  )
})
