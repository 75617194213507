import React from 'react'

import { Typography, Empty } from 'antd'

import { Box } from 'common/components/boxes'

export const EmptySearch = () => {
  return (
    <Box
      px={{ _: 12, lg: 24 }}
      pb={{ _: 12, lg: 24 }}
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <Typography.Title level={5} style={{ textAlign: 'center' }}>
            Sorry! No Items were found with these search criteria.
          </Typography.Title>
        }
      />
    </Box>
  )
}
