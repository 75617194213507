import { useHistory } from 'react-router-dom'

import { CommitmentTypes } from 'contractor/server/commitments'

type PushParams = {
  url: string
  isNewTab?: boolean
  basePath?: string
}

export const usePushToCommitment = () => {
  const history = useHistory()

  const getUrl = ({ commitmentId, basePath = '' }: Omit<PushParams, 'isNewTab'>) => {
    return `${basePath}/commitment/${commitmentId}`
  }

  const getCommitmentUrl = (commitmentId: string, type: CommitmentTypes) => {
    switch (type) {
      case 'MaterialCommitment':
        return `/material_commitments/${commitmentId}`
      case 'ConcreteCommitment':
        return `/concrete_commitments/${commitmentId}`
      case 'BudgetCommitment':
        return `/budget_commitments/${commitmentId}`
    }
  }

  const push = ({ url, isNewTab }: PushParams) => {
    isNewTab ? window.open(url, '_blank') : history.push(url)
  }

  return { push, getUrl, getCommitmentUrl }
}
