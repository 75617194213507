/** @jsx jsx */

import * as React from 'react'

import { Column, Row } from 'react-table'

import { jsx } from '@emotion/core'

import { Popover, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

interface TextPopoverProps {
  value: string
  row: Row
  column: Column
  rowDisabled: boolean
}

const TextPopover: React.FC<TextPopoverProps> = observer(({ value, row, column }) => {
  // Only show the placeholder if the row is null
  const placeholder = row.original['id'] === null ? column['placeholder'] : undefined

  return (
    <React.Fragment>
      <Popover content={value}>
        <Typography.Text ellipsis={true} style={{ width: '100%' }}>
          {value || placeholder}
        </Typography.Text>
      </Popover>
    </React.Fragment>
  )
})

export default TextPopover
