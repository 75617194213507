import React from 'react'

import styled from '@emotion/styled'

import { SettingOutlined } from '@ant-design/icons'
import { Typography, Tooltip } from 'antd'

import { InvoiceCardProps } from './invoice_card'

const Source = styled(Typography.Paragraph)`
  transition: color 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.highlightBlue};
  }
`

type SourceActionProps = InvoiceCardProps

export const SourceAction = ({ invoice, onOpenInvoiceInbox }: SourceActionProps) => {
  if (invoice.source_from.startsWith('Manually')) {
    return (
      <Typography.Paragraph style={{ margin: 0 }}>
        <b>Source: </b>
        {invoice.source_from}
      </Typography.Paragraph>
    )
  }

  return (
    <Tooltip key="invoice-card-key" title="Click to see details">
      <Source style={{ margin: 0 }} onClick={() => onOpenInvoiceInbox(invoice.invoice_inbox_id)}>
        <SettingOutlined />
        &nbsp;
        <b>Source: </b>
        {invoice.source_from}
      </Source>
    </Tooltip>
  )
}
