/* eslint-disable no-undef */
import { Events } from 'common/analytics/events'
import { ENVIRONMENT } from 'common/helpers/check_environment'

const userInfo = {
  user_id: '',
  company_id: '',
  company_name: '',
}

export enum Tool {
  PENDO = 'pendo',
  AMPLITUDE = 'amplitude',
  HEAP = 'heap',
}

const isPendoReady = () => window?.pendo?.isReady?.()
const isAmplitudeReady = () => window?.amplitude?.getDeviceId

const isReady = (tool: Tool) => {
  switch (tool) {
    case Tool.PENDO:
      return isPendoReady()
    case Tool.AMPLITUDE:
      return isAmplitudeReady()
    default:
      return false
  }
}

const eventTracker = (tool: Tool) => {
  switch (tool) {
    case Tool.PENDO:
      return window?.pendo
    case Tool.AMPLITUDE:
      return window?.amplitude
    default:
      return null
  }
}

export const setEventsUserInfo = (user_id: string, company_id: string, company_name: string) => {
  userInfo.user_id = user_id
  userInfo.company_id = company_id
  userInfo.company_name = company_name
}

export const trackEvent = (name: string, location: string, data = {}, tool = Tool.PENDO) => {
  const properties = {
    ...data,
    ...userInfo,
    location,
    environment: ENVIRONMENT,
  }

  // The event tracking is raising errors while e2e is executing
  if (ENVIRONMENT === 'development') {
    console.log('Debugging event tracking', name, location, data, tool, properties)
    return
  }
  if (isReady(tool)) {
    return eventTracker(tool)?.track(name, properties)
  }
  // Retry tracking after 500ms
  setTimeout(function () {
    trackEvent(name, location, data, tool)
  }, 500)
}

export const trackTableFiltering = (location: string, field: string) => {
  trackEvent(Events.TABLE_FILTERING, location, {
    field,
  })
}
