import React from 'react'

import { observer } from 'mobx-react-lite'

import { Card } from 'common/components/Card'
import Uploader from 'common/components/uploader'

import { useStores } from 'contractor/hooks/use-stores'

import { usePrivateOrderForm } from './context'

export const Attachments = observer(() => {
  const { isSubmitting } = usePrivateOrderForm()

  const { uploaderStore } = useStores()

  return (
    <Card title="Attachments" mt={16} contentProps={{ py: 25 }}>
      <Uploader
        component="Upload"
        hasCamera
        showPreviewModal
        multiple
        uploadKey="purchaser_files"
        listType="picture-card"
        showDownload
        hasFileNameTimeStamp
        fileList={uploaderStore.fileList('purchaser_files')}
        onRemoveUpload={uploaderStore.removeUpload}
        onAddNewUpload={uploaderStore.addNewUpload}
        onResetUploads={uploaderStore.resetUploads}
        onSetUploadError={uploaderStore.setUploadError}
        onUpdateUpload={uploaderStore.updateUpload}
        disabled={isSubmitting}
      />
    </Card>
  )
})
