import React from 'react'

import { Drawer, Form, Space, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'

import { observer } from 'mobx-react-lite'

import { InputCurrency } from 'common/components/InputCurrency'

type AdditionalCostsDrawerProps = {
  visible: boolean
  onClose: () => void
  title: string
  form: FormInstance
}

export const AdditionalCostsDrawer = observer<AdditionalCostsDrawerProps>((props: AdditionalCostsDrawerProps) => {
  const { onClose, visible, title, form } = props

  return (
    <Drawer
      style={{ padding: 0, paddingLeft: 16, paddingRight: 16 }}
      title={title}
      placement="bottom"
      closable={true}
      onClose={onClose}
      open={visible}
      height={150}
    >
      <Form form={form}>
        <Space>
          <Space style={{ marginRight: 16 }}>
            <Form.Item name="fuelCharge" style={{ marginBottom: 0 }}>
              <InputCurrency />
            </Form.Item>
            <Typography.Text type="danger">$</Typography.Text>
            <Typography.Text style={{ whiteSpace: 'nowrap' }}>Fuel charge</Typography.Text>
          </Space>

          <Space>
            <Form.Item name="environmentalCharge" style={{ marginBottom: 0 }}>
              <InputCurrency />
            </Form.Item>
            <Typography.Text type="danger">$</Typography.Text>
            <Typography.Text style={{ whiteSpace: 'nowrap' }}>Environmental charge</Typography.Text>
          </Space>
        </Space>
      </Form>
    </Drawer>
  )
})
