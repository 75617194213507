import React from 'react'

import { Link, LinkProps } from 'react-router-dom'

import { usePushToOrder } from 'common/hooks/use-push-to-order'
import { OrderStates, OrderSubStates } from 'common/server/server_types'

type CellOrderNumberProps = {
  orderNumber: string
  orderName: string
  orderId: string
  orderPackageId: string
  basePath?: string
  state?: OrderStates
  subState?: OrderSubStates
} & Partial<LinkProps>

export const CellOrderNumber = ({
  orderNumber,
  orderName,
  orderId,
  orderPackageId,
  basePath = '',
  state,
  subState,
  ...props
}: CellOrderNumberProps) => {
  const { getUrl } = usePushToOrder()

  return (
    <Link
      onClick={(e) => e.stopPropagation()}
      to={getUrl({ orderId, orderPackageId, state, subState, basePath })}
      style={{ width: '225px' }}
      {...props}
    >
      {orderNumber}: {orderName}
    </Link>
  )
}
