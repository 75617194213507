import React from 'react'

import classNames from 'classnames'

function getOffsetRect(element: HTMLElement) {
  return {
    width: element.offsetWidth,
    height: element.offsetHeight,
    left: element.offsetLeft,
    top: element.offsetTop,
  }
}

/*
  https://github.com/iddan/react-spreadsheet/blob/master/src/Cell.tsx
  We need to custom the base cell to prevent some default behaviors
*/
export const CustomCell = ({
  column,
  row,
  setCellDimensions,
  select,
  activate,
  mode,
  dragging,
  active,
  data,
  DataViewer,
  setCellData,
  selected,
}) => {
  const rootRef = React.createRef<HTMLTableCellElement>()
  const point = React.useMemo(
    () => ({
      row,
      column,
    }),
    [row, column],
  )

  React.useEffect(() => {
    if (rootRef.current && active && mode === 'view') {
      rootRef.current.focus()
    }
  }, [rootRef, active, mode])

  const handleMouseDown = React.useCallback(
    (event) => {
      // If the cell is "approve" don't select it, just leave the user to click on the button
      if (data?.accessor === 'approve') return

      if (mode === 'view') {
        setCellDimensions(point, getOffsetRect(event.currentTarget))

        if (event.shiftKey) {
          select(point)
          return
        }

        activate(point)
      }
    },
    [select, activate, mode, point, setCellDimensions, data?.accessor],
  )

  const handleMouseOver = React.useCallback(
    (event) => {
      if (dragging) {
        setCellDimensions(point, getOffsetRect(event.currentTarget))
        select(point)
      }
    },
    [dragging, select, point, setCellDimensions],
  )

  React.useEffect(() => {
    const root = rootRef.current
    if (selected && root) {
      setCellDimensions(point, getOffsetRect(root))
    }
    if (root && active && mode === 'view') {
      root.focus()
    }
  }, [setCellDimensions, selected, active, mode, point, data])

  if (data?.DataViewer) {
    DataViewer = data.DataViewer
  }

  return (
    <td
      ref={rootRef}
      className={classNames('Spreadsheet__cell', data?.readOnly && 'Spreadsheet__cell--readonly', data?.className)}
      tabIndex={0}
      onMouseOver={handleMouseOver}
      onMouseDown={handleMouseDown}
    >
      <DataViewer row={row} column={column} cell={data} setCellData={setCellData} />
    </td>
  )
}
