import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import styled from '@emotion/styled'

import { SearchOutlined } from '@ant-design/icons'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import { AutoComplete, AutoCompleteProps, Button, Input, Spin } from 'antd'

import { useDebounce } from 'common/hooks/use-debounce'
import { useIsFirstRender } from 'common/hooks/use-is-first-render'
import { useMediaQuery } from 'common/hooks/use-media-query'

import { Mobile } from './mobile_wrapper'
import { NotFound } from './not_found'

const StdInput = styled(Input)`
  color: ${({ theme }) => theme.colors['gray-9']};

  & > input {
    margin-left: 0.25rem;
  }
`

export type AutocompleteProps = {
  footer?: React.ReactNode
  isFetching?: boolean
  openOnClick?: boolean
} & AutoCompleteProps

export type AutocompleteRef = { toggleOpen: (open: boolean) => void }

export const Autocomplete = forwardRef<AutocompleteRef, AutocompleteProps>((props, ref) => {
  const {
    placeholder = 'Search...',
    options,
    isFetching,
    footer,
    onSearch,
    onSelect,
    openOnClick = false,
    ...rest
  } = props

  const isFirstRender = useIsFirstRender()
  const isMdScreen = useMediaQuery('md')

  const [open, toggleOpen] = useState(false)
  const [keyword, setKeyword] = useState('')
  const debouncedValue = useDebounce<string>(keyword.trim(), 500)

  useEffect(() => {
    if (!isFirstRender) {
      onSearch?.(debouncedValue)
    }
  }, [debouncedValue])

  useImperativeHandle(ref, () => ({
    toggleOpen: (value) => toggleOpen(value),
  }))

  const handleSelect = (value: string | number, option: AutocompleteProps['options'][0]) => {
    onSelect?.(value, option)
    setKeyword('')
    toggleOpen(false)
  }

  const autcomplete = (
    <AutoComplete
      onClick={() => {
        if (isMdScreen) {
          toggleOpen(true)
        }
      }}
      data-cy="autocomplete"
      style={{ width: '100%' }}
      open={open}
      onDropdownVisibleChange={toggleOpen}
      allowClear
      dropdownMatchSelectWidth={600}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            {footer}
          </>
        )
      }}
      notFoundContent={debouncedValue && !isFetching ? <NotFound value={debouncedValue} /> : null}
      popupClassName="materials-autocomplete-popup"
      onSearch={setKeyword}
      value={keyword}
      options={debouncedValue || openOnClick ? options : []}
      onSelect={handleSelect}
      defaultActiveFirstOption
      {...rest}
    >
      <StdInput
        aria-autocomplete="none"
        placeholder={placeholder as string}
        prefix={
          isFetching ? <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} /> : <SearchOutlined />
        }
      />
    </AutoComplete>
  )

  if (isMdScreen && open) {
    return (
      <Mobile.Wrapper>
        <Mobile.Header>
          <StdInput
            autoFocus
            aria-autocomplete="none"
            placeholder={placeholder as string}
            onChange={(e) => setKeyword(e.target.value)}
            allowClear
            prefix={
              isFetching ? <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} /> : <SearchOutlined />
            }
          />
          <Button
            type="text"
            onClick={() => {
              toggleOpen(false)
              setKeyword('')
            }}
          >
            Cancel
          </Button>
        </Mobile.Header>
        <Mobile.List>
          {(debouncedValue || openOnClick) && (
            <>
              {options.map((option, index) => (
                <Mobile.ListItem
                  onClick={() => handleSelect(option.value, null)}
                  key={`materials-autocomplete-option-${index}`}
                >
                  {option?.label}
                </Mobile.ListItem>
              ))}
            </>
          )}
        </Mobile.List>
        {footer && <Mobile.Footer>{footer}</Mobile.Footer>}
      </Mobile.Wrapper>
    )
  }

  return autcomplete
})
