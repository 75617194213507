import React, { useState } from 'react'

import { Button, message } from 'antd'

import { useStores } from 'contractor/hooks/use-stores'

import { FooterProps } from './footer'

type QuickCreateMaterialProps = FooterProps

export const QuickCreateMaterial = (props: QuickCreateMaterialProps) => {
  const { materialDescription = '', onSelect, publicOrderFormUrlExtension, setIsOpen } = props

  const { companyMaterialStore } = useStores()

  const [isSubmitting, setSubmitting] = useState(false)

  const createMaterial = (companyMaterial) => {
    if (publicOrderFormUrlExtension) {
      return companyMaterialStore.publicCreate(companyMaterial, publicOrderFormUrlExtension)
    }
    return companyMaterialStore.create(companyMaterial)
  }

  const handleCreateMaterial = async () => {
    setSubmitting(true)
    setIsOpen?.(false)
    try {
      const companyMaterial = await createMaterial({
        description: materialDescription.trim(),
        created_from: 'ORDER_MATERIAL_SEARCH_BAR',
      })
      onSelect(companyMaterial)
    } catch (err) {
      message.error(err?.response?.data?.error || 'Unable to create material')
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Button
      data-cy="quick-create-material-button"
      onClick={handleCreateMaterial}
      loading={isSubmitting}
      type="primary"
      style={{ width: 200 }}
    >
      Quick Create &quot;
      {materialDescription.length > 9 ? materialDescription.slice(0, 9).concat('...') : materialDescription}&quot;
    </Button>
  )
}
