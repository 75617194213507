import { CellFormattedText } from 'common/components/OrdersList/Cells/cell_formatted_text'

import { CellCommitmentNumber } from 'contractor/pages/@v2/Commitments/common/components/ListCells/cell_commitment_number'
import { CellStatus } from 'contractor/pages/@v2/Commitments/common/components/ListCells/cell_status'
import { CellVendorName } from 'contractor/pages/@v2/Commitments/common/components/ListCells/cell_vendor_name'

import { CellExpirationDate } from './cell_expiration_date'
import { CellTag } from './cell_tags'

export const Cell = {
  ExpirationDate: CellExpirationDate,
  CellCommitmentNumber: CellCommitmentNumber,
  Tags: CellTag,
  VendorName: CellVendorName,
  Status: CellStatus,
  FormattedText: CellFormattedText,
}
