import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import moment from 'moment'

import { Skeleton, Table, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import OrderState from 'common/components/statuses/order_state'
import { formatDateString } from 'common/helpers/formatters'

import { useStores } from 'contractor/hooks/use-stores'
import { ConsolidatedOrders, consolidatedOrdersRequests } from 'contractor/server/orders'

const OrdersTable: React.FC<{ orders: Array<ConsolidatedOrders.Order> }> = observer(({ orders }) => {
  const { projectStore, notificationStore } = useStores()
  const history = useHistory()

  const columns = [
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (state, order) => {
        const notificationCount = notificationStore.notificationCountByObject(order.id)
        return <OrderState state={state} sub_state={order['sub_state']} notificationCount={notificationCount} />
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Order by',
      dataIndex: 'lead_time_cutoff_date',
      key: 'lead_time_cutoff_date',
      render: (date) => <span>{formatDateString(date)}</span>,
    },
  ]

  // Maybe add the project name
  if (!projectStore.selectedProject) {
    columns.splice(1, 0, {
      title: 'Project',
      dataIndex: 'project_name',
      key: 'project_name',
    })
  }

  return (
    <Table
      style={{ width: '100%' }}
      rowKey={'id'}
      dataSource={orders}
      columns={columns}
      pagination={false}
      onRow={(record) => ({
        onClick: () => history.push(`order/${record.id}`),
      })}
      size="small"
    />
  )
})

const Orders = observer(() => {
  const { projectStore } = useStores()
  const [orders, setOrders] = useState<Array<ConsolidatedOrders.Order>>()

  const fetchOrders = async () => {
    // Filter for orders that need to be ordered by today
    const weekFromToday = moment().add(7, 'd').format('YYYY-MM-DD')

    const filters = {
      lead_time_cutoff_until_date: weekFromToday,
      lead_time_cutoff_applicable: true,
    }

    if (projectStore.selectedProject?.id) {
      filters['project_id'] = projectStore.selectedProject?.id
    }

    const response = await consolidatedOrdersRequests.index({
      filters,
    })

    setOrders(response.data.records)
  }

  useEffect(() => {
    fetchOrders()
  }, [projectStore.selectedProject?.id])

  if (orders === undefined) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
        <Skeleton paragraph={{ rows: 5 }} />
      </Box>
    )
  }
  if (orders.length === 0) {
    return (
      <Box display="flex" justifyContent="center" width="100%">
        <Typography.Text type="secondary">No reminders</Typography.Text>{' '}
      </Box>
    )
  }
  return <OrdersTable orders={orders} />
})

export default Orders
