import React from 'react'

import { Typography, Divider } from 'antd'

import { Box, BoxProps } from 'common/components/boxes'

type CardProps = {
  title?: React.ReactNode
  extra?: React.ReactNode
  children: React.ReactNode
  contentProps?: BoxProps
} & BoxProps

export const Card = ({ children, title, extra, contentProps, ...props }: CardProps) => (
  <Box width="100%" bg="white" borderRadius="sm" display="flex" flexDirection="column" {...props}>
    {title && (
      <>
        <Box display="flex" justifyContent="space-between" px={16} pt={10} pb={12}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {title}
          </Typography.Title>

          {extra}
        </Box>
        <Divider style={{ margin: 0 }} />
      </>
    )}
    <Box p={16} flexGrow={1} {...contentProps}>
      {children}
    </Box>
  </Box>
)
