export const INVOICE_FIELDS_CONFIG = [
  { key: 'Name', type: 'text' },
  { key: 'State', type: 'state' },
  { key: 'Sync status', type: 'sync' },
  {
    key: 'Total amount',
    type: 'currency',
  },
  { key: 'Tags', type: 'tag' },
  { key: 'Document date', type: 'date' },
  { key: 'Accounting date', type: 'date' },
  { key: 'Due date', type: 'date' },
  { key: 'Assigned to', type: 'text' },
  { key: 'Rejection reason', type: 'text' },
  { key: 'Number', type: 'text' },
  { key: 'Company vendor', type: 'text' },
  { key: 'Watchers', type: 'tag' },
]
export const ALLOW_INVOICE_FIELDS = INVOICE_FIELDS_CONFIG.map((item) => item.key)

// This one has all the fields mapped to show in the invoice v2
export const INVOICE_FIELDS_CONFIG_FULL = [
  ...INVOICE_FIELDS_CONFIG.filter((item) => item.key !== 'Total amount'),
  { key: 'Marked extracted at', type: 'date' },
  { key: 'Marked extracted by', type: 'text' },
  { key: 'Marked reconciled at', type: 'date' },
  { key: 'Marked reconciled by', type: 'text' },
  { key: 'Project', type: 'text' },
  { key: 'Discount amount', type: 'text' },
  { key: 'Other costs', type: 'text' },
  { key: 'Shipping cost', type: 'text' },
  { key: 'Tax amount', type: 'text' },
]
export const ALLOW_INVOICE_FIELDS_FULL = INVOICE_FIELDS_CONFIG_FULL.map((item) => item.key)
