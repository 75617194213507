import React from 'react'

import { EditableTableContextProvider } from 'common/components/EditableTable/context'

import { InvoiceProvider } from './context'
import { InvoiceDetailExtract } from './invoice_detail_extract'

export const InvoiceDetailV3 = () => {
  return (
    <InvoiceProvider>
      <EditableTableContextProvider>
        <InvoiceDetailExtract />
      </EditableTableContextProvider>
    </InvoiceProvider>
  )
}
