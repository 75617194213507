import React, { useRef } from 'react'

import { BrowserRouter } from 'react-router-dom'

import { ThemeProvider } from '@emotion/react'

import { Typography, Button } from 'antd'

import { FlexBoxY } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { OrderStates } from 'common/server/server_types'
import theme from 'common/styles/theme'

import { CreateMaterial } from 'contractor/pages/CompanyMaterials/Materials/create_material'

import { FooterProps } from './footer'

type RequestNewMaterialProps = FooterProps

const blockedStates = [
  OrderStates.APPROVED,
  OrderStates.ORDERED,
  OrderStates.QUOTED,
  OrderStates.SHIPPED,
  OrderStates.PARTIALLY_SHIPPED,
  OrderStates.DELIVERED,
]

export const RequestNewMaterial = ({
  materialDescription = '',
  onSelect,
  publicOrderFormUrlExtension,
  companyAttributes,
  units,
  setIsOpen,
  userId,
  orderState,
}: RequestNewMaterialProps) => {
  const drawerCreateRef = useRef<DrawerRef>()

  // Is not allowed to request material if the order is not a DRAFT or creation
  if (blockedStates.includes(orderState)) {
    return null
  }

  return (
    <FlexBoxY p="16px 8px" backgroundColor="gray-2">
      <Typography.Text type="secondary" strong style={{ fontSize: 16, marginBottom: 4 }}>
        Don&apos;t see what you are looking for?
      </Typography.Text>

      <Button
        type="primary"
        onClick={() => {
          setIsOpen?.(false)
          drawerCreateRef.current.show()
        }}
      >
        Request New Material
      </Button>

      {/* Need to add provider because autocomplete rendering outside of app  */}
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CreateMaterial
            title="Request New Material"
            onCancel={() => drawerCreateRef.current.close()}
            onSubmit={(response) => {
              drawerCreateRef.current.close()
              onSelect(response)
            }}
            initialValues={{ description: materialDescription }}
            hideNotification
            publicOrderFormUrlExtension={publicOrderFormUrlExtension}
            companyAttributes={companyAttributes}
            units={units}
            ref={drawerCreateRef}
            requestedById={userId}
          />
        </ThemeProvider>
      </BrowserRouter>
    </FlexBoxY>
  )
}
