import React from 'react'

import { Button } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'

type PlaceOrderButtonProps = {
  isLoading: boolean
  disabled: boolean
  handleUpdate: () => void
}

export const UpdateCommitmentButton = (props: PlaceOrderButtonProps) => {
  const { isLoading, disabled, handleUpdate } = props
  const source = window.location.pathname

  return (
    <Button
      data-cy="update-commitment"
      type="primary"
      onClick={() => {
        handleUpdate()
        trackEvent(Events.CLICK_UPDATE_COMMITMENT, source)
      }}
      loading={isLoading}
      disabled={disabled}
      style={{ marginLeft: 8 }}
    >
      Update
    </Button>
  )
}
