import React from 'react'

import { Column, PluginHook, TableInstance, TableOptions, useTable, ColumnInstance } from 'react-table'

import { usePersistentFilters } from 'common/contexts/persistent_filters'

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PsqlColumn<T = any> = Column<T> & {
  disabled?: boolean
  disabledForToggleView?: boolean
  ellipsis?: boolean
  sortBy?: string
  format?: 'date' | 'currency'
}

interface Sort {
  onChange?: (params: { field: string; direction: string }) => void
  field: string
  direction: string
  sortFromPersistentFilter?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PsqlTableContextProps = Omit<TableInstance<any>, 'columns'> & {
  sort: Sort
  localStorageKey: string
  tableName: string
  columns: Array<ColumnInstance & PsqlColumn>
}

interface PsqlTableProviderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: TableOptions<any> & { sort?: Sort; columns: Array<PsqlColumn> }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  plugins?: PluginHook<any>
  tableName: string
  children: React.ReactNode
}

export const PsqlTableContext = React.createContext<PsqlTableContextProps | null>(null)

export function PsqlTableProvider(props: PsqlTableProviderProps) {
  const {
    children,
    tableName,
    plugins,
    options: { data = [], sort, columns, ...options },
  } = props

  const localStorageKey = `@subbase/table-${tableName}/hidden-columns`

  const persistentFilters = usePersistentFilters()

  const managedColumns = React.useMemo(() => {
    return columns.filter((column: PsqlColumn) => !column.disabled)
  }, [columns])

  const table = useTable(
    {
      initialState: {
        hiddenColumns: JSON.parse(localStorage[localStorageKey] || '[]'),
      },
      ...options,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      columns: managedColumns as Array<Column<any>>,
      data,
    },
    plugins,
  )

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(table.state.hiddenColumns))
  }, [table.state.hiddenColumns.length])

  return (
    <PsqlTableContext.Provider
      value={{
        ...table,
        sort: {
          ...sort,
          onChange: sort?.sortFromPersistentFilter ? persistentFilters.handleChangeSort : sort?.onChange,
        },
        localStorageKey,
        tableName,
      }}
    >
      {children}
    </PsqlTableContext.Provider>
  )
}

export function usePsqlTable(): PsqlTableContextProps {
  return React.useContext(PsqlTableContext)
}
