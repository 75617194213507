import React from 'react'

import { MessageOutlined } from '@ant-design/icons'
import { Tag, Popover } from 'antd'

import { observer } from 'mobx-react-lite'

import { useTheme } from 'common/hooks/use-theme'

const defaultStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  margin: 0,
}

type InvoiceStatusTagProps = {
  state: {
    id: string
    label: string
    color: string
  }
  rejectionReason?: string
  style?: React.CSSProperties
}

export const InvoiceStatusTag = observer<InvoiceStatusTagProps>(({ state, rejectionReason = '', style }) => {
  const theme = useTheme()

  const colorName = state?.color || 'gray'

  const content = (
    <Tag
      style={{
        ...defaultStyle,
        backgroundColor: theme.colors[`${colorName}-1`],
        borderColor: theme.colors[`${colorName}-3`],
        color: theme.colors[`${colorName}-6`],
        ...style,
      }}
      onClick={(e) => e.stopPropagation()}
      icon={!!rejectionReason && <MessageOutlined />}
    >
      {state?.label}
    </Tag>
  )

  if (rejectionReason) {
    return (
      <Popover title="Rejection reason" content={rejectionReason} trigger={['hover', 'click']} placement="topLeft">
        {content}
      </Popover>
    )
  }

  return content
})
