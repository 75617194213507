export const baseSizes = {
  px: '1px',
  '0': '0',
  '2': '2px',
  '4': '4px',
  '8': '8px',
  '12': '12px',
  '16': '16px',
  '20': '20px',
  '24': '24px',
  '28': '28px',
  '32': '32px',
  '36': '36px',
  '40': '40px',
  '44': '44px',
  '48': '48px',
  '52': '52px',
  '56': '56px',
  '60': '60px',
  '64': '64px',
}

const largeSizes = {
  full: '100%',
  '3xs': '14rem',
  '2xs': '16rem',
  xs: '20rem',
  sm: '24rem',
  md: '28rem',
  lg: '32rem',
  xl: '36rem',
  '2xl': '42rem',
  '3xl': '48rem',
  '4xl': '56rem',
  '5xl': '64rem',
  '6xl': '72rem',
  '7xl': '80rem',
}

const container = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
}

const sizes = {
  ...baseSizes,
  ...largeSizes,
  container,
}

export type BaseSizes = typeof baseSizes
export type Sizes = typeof baseSizes & typeof largeSizes & { container: typeof container }
export default sizes
