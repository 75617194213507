import React from 'react'

import { BorderlessTableOutlined } from '@ant-design/icons'
import { Form, Input, Space, Tooltip, Typography } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'
import { CustomEditableContent } from 'common/components/CustomEditableContent'

import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'

interface InvoiceNumberProps {
  disabled?: boolean
}

export const CommitmentNumber = (props: InvoiceNumberProps) => {
  const { disabled } = props
  const { commitmentNumber } = useCommitment()
  const source = window.location.pathname

  if (disabled) {
    return (
      <Tooltip title="Commitment Number" placement="bottom">
        <Space>
          <Form.Item style={{ marginBottom: 0 }} name="commitmentNumber">
            <BorderlessTableOutlined />
            <Typography.Text type="secondary">{commitmentNumber || 'Commitment number'}</Typography.Text>
          </Form.Item>
        </Space>
      </Tooltip>
    )
  }

  return (
    <CustomEditableContent
      boxProps={{ mt: 0 }}
      input={
        <Form.Item style={{ marginBottom: 0 }} name="commitmentNumber">
          <Input data-cy="commitment_number" style={{ width: 180 }} />
        </Form.Item>
      }
    >
      <Tooltip title="Commitment Number" placement="bottom">
        <Box onClick={() => trackEvent(Events.CLICK_COMMITMENT_NUMBER, source)}>
          <Space>
            <BorderlessTableOutlined />
            <Typography.Text type="secondary">{commitmentNumber || 'Commitment number'}</Typography.Text>
          </Space>
        </Box>
      </Tooltip>
    </CustomEditableContent>
  )
}
