import React from 'react'

import { Global } from '@emotion/react'

export const GlobalStyle = () => (
  <Global
    styles={(theme) => ({
      '.ant-menu-dark .ant-menu-item:hover': {
        backgroundColor: theme.colors.highlightBlue,
      },
      '.ant-menu-light .ant-menu-item:hover': {
        backgroundColor: theme.colors['gray-2'],
      },
      '.ant-select-dropdown .selected-label': {
        display: 'none',
      },
      '.ant-select-dropdown .selection-label': {
        display: 'block',
      },
      '.ant-select .selected-label': {
        display: 'block',
      },
      '.ant-select .selection-label': {
        display: 'none',
      },
      '.materials-autocomplete-popup': {
        padding: 0,
        borderRadius: 4,
      },
      '.materials-autocomplete-popup .ant-select-item-option-content': {
        whiteSpace: 'normal',
      },
      '.materials-autocomplete-popup .ant-select-item-empty': {
        padding: 0,
      },
    })}
  />
)
