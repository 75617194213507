import React from 'react'

import moment from 'moment'

import styled from '@emotion/styled'

import { Comment as AntdComment } from 'antd'

import { formatName } from 'common/helpers/formatters'
import { Comment as IComment } from 'common/server/comment'

import { Avatar } from './avatar'
import { CommentsProps } from './comments'

type CommentProps = { comment: IComment } & Pick<CommentsProps, 'currentView'>

const CommentStyled = styled(AntdComment)`
  .ant-comment-inner {
    padding: 0;
  }
`

export const Comment = ({ comment, currentView }: CommentProps) => {
  const { commentator_type, commentator, created_at } = comment

  const isContractor = commentator_type === 'User'
  const isVendorContact = commentator_type === 'VendorUser' || commentator_type === 'CompanyVendorContact'
  const isStyleAvatar = (currentView === 'VENDOR' && isVendorContact) || (currentView === 'CONTRACTOR' && isContractor)

  return (
    <CommentStyled
      style={{ width: '100%' }}
      author={formatName(commentator?.first_name, commentator?.last_name)}
      avatar={<Avatar isStyled={isStyleAvatar} isContractor={isContractor} />}
      content={<p>{comment.comment}</p>}
      datetime={<span>{moment(created_at).format('MM/DD/YYYY hh:mm a')}</span>}
    />
  )
}
