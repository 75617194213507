import React, { useCallback, useRef } from 'react'

import { Form, Input, InputRef } from 'antd'
import { TextAreaProps } from 'antd/lib/input'

import { useEditableTableContext } from './context'
import { useForm } from './row_context'

type EditableCellInputTextAreaProps = {
  inputName: string
  onSave: (value: string) => void
} & TextAreaProps

export const EditableCellInputTextArea = ({ inputName, onSave, ...props }: EditableCellInputTextAreaProps) => {
  const tableContext = useEditableTableContext()

  const inputTextRef = useRef<InputRef>(null)
  const form = useForm()

  const save = useCallback(async () => {
    try {
      const values = await form.validateFields()

      onSave(values[inputName])
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }, [form, onSave])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      inputTextRef.current?.focus({
        cursor: tableContext.navigationByTabs ? 'all' : 'end',
      })
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [inputTextRef.current])

  return (
    <Form.Item style={{ margin: 0 }} name={inputName}>
      <Input.TextArea
        ref={inputTextRef}
        onPressEnter={save}
        onBlur={save}
        onKeyDown={(e) => {
          if (e.key === 'Tab') {
            save()
          }
        }}
        style={{ width: '100%', resize: 'none' }}
        rows={2}
        autoFocus
        {...props}
      />
    </Form.Item>
  )
}
