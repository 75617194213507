import React from 'react'

import { CellBase, DataEditorProps } from 'react-spreadsheet'

import { toJS } from 'mobx'

import { SelectCostCode, SelectCostCodeProps, makeCostCodeValue } from 'common/components/SelectCostCode'

export type CostCodeEditorCellProps = SelectCostCodeProps &
  DataEditorProps & {
    onChange: (cell: CellBase, shouldPropagateValue?: boolean) => void
  }

export const CostCodeEditorCell = ({ cell, onChange, ...props }: CostCodeEditorCellProps) => {
  return (
    <SelectCostCode
      {...props}
      value={makeCostCodeValue(cell.value, props?.costCodeSettings)}
      onChange={(value, shouldPropagateValue) => {
        onChange(
          {
            ...cell,
            value: toJS(value?.costCode),
          },
          shouldPropagateValue,
        )
      }}
      autoFocus
    />
  )
}
