import axios from 'axios'

import { StandardIndexRequest, StandardIndexResponse } from 'common/server/server_types'

export interface CostCodeClass {
  id: string
  code: string
  description?: string
  discarded_at?: string
}

export interface CreateCostCodeClass {
  code: string
  description?: string
  active: boolean
}

export interface UpdateCostCodeClass {
  id: string
  code?: string
  description?: string
  active?: boolean
}

export function create_cost_code_class(cost_code_class: CreateCostCodeClass) {
  return axios.post<CostCodeClass>('/cost_code_classes', { cost_code_class })
}

export function update_cost_code_class(cost_code_class: UpdateCostCodeClass) {
  return axios.patch<CostCodeClass>(`/cost_code_classes/${cost_code_class.id}`, { cost_code_class })
}

export function get_all_cost_code_classes(params: StandardIndexRequest) {
  return axios.get<StandardIndexResponse<CostCodeClass[]>>('/cost_code_classes', { params })
}

export function get_cost_code_class(id: string) {
  return axios.get<CostCodeClass>(`/cost_code_classes/${id}`)
}
