import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import { Button, Divider, Form, Select, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DrawerRef, Drawer, DrawerProps } from 'common/components/Drawer'
import { TextArea } from 'common/components/TextArea'
import { InvoiceDiscardReasons } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

type InvoiceDiscardDrawerProps = Partial<DrawerProps>

export const InvoiceDiscardDrawer = observer<InvoiceDiscardDrawerProps, DrawerRef>(
  (props, ref) => {
    const { invoiceStore } = useStores()

    const history = useHistory()

    const [form] = Form.useForm()
    const [isSubmitting, setSubmitting] = useState(false)

    const handleDeleteInvoice = async (formValues) => {
      try {
        await invoiceStore.delete(invoiceStore.invoice.id, formValues?.reason, formValues?.explanation)
        message.success('Invoice deleted')
        history.push('/invoices')
      } catch {
        message.error('Unable to delete this Invoice')
      } finally {
        setSubmitting(false)
      }
    }

    return (
      <Drawer title="Are you sure you want to delete?" ref={ref} {...props}>
        <Box width="100%" overflowY="auto" p={16}>
          <Form form={form} style={{ width: '100%' }} onFinish={handleDeleteInvoice} layout="vertical">
            <Form.Item
              label="Reason"
              name="reason"
              rules={[{ required: true, message: 'Reason is required!' }]}
              style={{ width: '100%' }}
            >
              {/*  Using antd Select, create a selector from InvoiceDiscardReasons */}
              <Select
                aria-label="discard-reason-select"
                data-cy="discard-reason-select"
                style={{ width: '100%', textTransform: 'capitalize' }}
                placeholder="Select a reason"
              >
                {Object.keys(InvoiceDiscardReasons).map((key) => (
                  <Select.Option key={key} value={InvoiceDiscardReasons[key]} style={{ textTransform: 'capitalize' }}>
                    {key.replace(/_/g, ' ').toLowerCase()}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Explanation"
              name="explanation"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value &&
                      [InvoiceDiscardReasons.EXTRACTION_PROBLEM, InvoiceDiscardReasons.OTHER].includes(
                        getFieldValue('reason'),
                      )
                    ) {
                      return Promise.reject(
                        new Error('Explanation is required when reason is OTHER or EXTRACTION PROBLEM.'),
                      )
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <TextArea placeholder="Explain why you are deleting this Invoice" />
            </Form.Item>

            <Divider />

            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Button onClick={props?.onClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button htmlType="submit" type="primary" loading={isSubmitting}>
                Delete
              </Button>
            </Box>
          </Form>
        </Box>
      </Drawer>
    )
  },
  {
    forwardRef: true,
  },
)
