import React, { useMemo } from 'react'

import { useLocation } from 'react-router-dom'

import moment from 'moment'

import styled from '@emotion/styled'

import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import { Typography, Space } from 'antd'
import { MenuProps } from 'antd/lib/menu'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { FlexBoxY, Box } from 'common/components/boxes'
import { Visibility } from 'common/components/Visibility'
import { InvoiceInboxFilesStates, InvoiceInboxSubStates } from 'common/server/server_types'

import { FileTags } from 'contractor/pages/Invoices/Inbox/List/file_tags'
import { DropdownStyled } from 'contractor/pages/Invoices/styles'
import { InvoiceInboxHit } from 'contractor/server/invoices/inbox'

import { InboxState } from '../inbox_state'

export enum InboxRowAction {
  CREATE = 'CREATE',
  IGNORE = 'IGNORE',
}

type ListItemInboxProps = {
  hit: InvoiceInboxHit
  onSelectRow: (hit: InvoiceInboxHit) => void
  onSelectAction: (hit: InvoiceInboxHit, action: InboxRowAction) => void
}

const ListItemInboxStyled = styled(Box)`
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors['gray-4']};
  transition: background 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors['gray-2']};
  }
`

export const ListItemInbox = ({ hit, onSelectRow, onSelectAction }: ListItemInboxProps) => {
  const location = useLocation()
  const source = location.pathname.substr(1)

  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackEvent(Events.CLICK_INVOICE_INBOX, source)
    onSelectRow(hit)
  }

  const items: MenuProps['items'] = [
    {
      label: 'Create Invoice',
      key: '1',
      icon: <PlusOutlined />,
      disabled: hit.files.length === 0 || !hit.files.some((file) => file.filename.toLowerCase().endsWith('.pdf')),
      onClick: (e) => {
        e.domEvent.preventDefault()
        e.domEvent.stopPropagation()
        onSelectAction(hit, InboxRowAction.CREATE)
      },
    },
    {
      type: 'divider',
    },
    {
      label: <Typography.Text style={{ color: '#8c8c8c' }}>Mark as ignored</Typography.Text>,
      key: '2',
      onClick: (e) => {
        e.domEvent.preventDefault()
        e.domEvent.stopPropagation()
        onSelectAction(hit, InboxRowAction.IGNORE)
      },
    },
  ]

  const showActionMenu = useMemo(() => {
    return (
      hit.sub_state === InvoiceInboxSubStates.FAILED &&
      (hit.files?.some((file) => file.state === InvoiceInboxFilesStates.FAILED) ||
        hit.files?.every((file) => file.state === InvoiceInboxFilesStates.NONE) ||
        hit.files?.length === 0)
    )
  }, [hit.sub_state, hit.files])

  return (
    <ListItemInboxStyled
      onClick={onClick}
      px={24}
      py={16}
      bg="white"
      width="100%"
      display="flex"
      alignItems={{ _: 'flex-start', md: 'center' }}
      flexDirection={{ _: 'column', md: 'row' }}
    >
      <FlexBoxY flexGrow={0} alignItems="flex-start" width="200px" minWidth="200px">
        <Typography.Text type="secondary" style={{ textTransform: 'uppercase', fontSize: 12 }}>
          {moment(hit.created_at).format('ddd, MMM Do')}
        </Typography.Text>
        <Typography.Text style={{ marginTop: 5 }}>{hit.from || hit.uploaded_by}</Typography.Text>
      </FlexBoxY>

      <Visibility.Hidden>
        <FlexBoxY flexGrow={1} alignItems="flex-start" mx={12}>
          {hit.subject ? (
            <Typography.Text>{hit.subject}</Typography.Text>
          ) : (
            <Typography.Text type="secondary">Manually uploaded files</Typography.Text>
          )}

          <FileTags
            inbox_id={hit.id}
            files={hit.files}
            inbox_fail_reason={hit.fail_reason}
            inbox_auto_ignored_reason={hit.auto_ignored_reason}
            upper_state={hit.sub_state}
          />
        </FlexBoxY>
      </Visibility.Hidden>

      <Box mt={{ _: 12, md: 0 }}>
        <Space
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <InboxState state={hit.state} sub_state={hit.sub_state} count={hit.invoice_count} />
          {showActionMenu ? (
            <DropdownStyled
              menu={{ items }}
              autoFocus
              trigger={['hover', 'click']}
              type="link"
              icon={<MoreOutlined />}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              style={{ padding: 0 }}
            />
          ) : (
            <div style={{ width: 36 }} />
          )}
        </Space>
      </Box>
    </ListItemInboxStyled>
  )
}
