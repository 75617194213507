import React from 'react'

import { LocalShipping } from '@styled-icons/material'

import { FileTextOutlined, ShopFilled } from '@ant-design/icons'

type IconProps = {
  isInvoice: boolean
  isPickUp: boolean
}

export const Icon = ({ isInvoice, isPickUp }: IconProps) => {
  if (isInvoice) return <FileTextOutlined style={{ marginRight: '8px', fontSize: 16 }} />
  if (isPickUp) return <ShopFilled style={{ marginRight: '8px', fontSize: 20 }} />
  return <LocalShipping size="20" style={{ marginRight: '8px' }} />
}
