import React, { useState, useEffect } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Typography, Space, Popover } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { InputCurrency } from 'common/components/InputCurrency'

import { FailureReason } from 'contractor/pages/OrderDetail/QuoteReconciliation/OrderMaterialsTable/failure_reason'
import { Quote, QuoteAdditionalCharge } from 'contractor/server/quote'

type GrandTotalFooterProps = {
  subTotal: number
  quote: Quote
  discount: number
  onChange: (payload: QuoteAdditionalCharge) => void
}

export const GrandTotalFooter = observer<GrandTotalFooterProps>((props: GrandTotalFooterProps) => {
  const { subTotal, onChange, quote } = props
  const [tax, setTax] = useState(quote.tax || 0)
  const [shipping, setShipping] = useState(Number(quote.additional_charges || 0) + Number(quote.shipping_cost || 0))
  const [discount, setDiscount] = useState(props.discount || 0)
  const [grandTotal, setGrandTotal] = useState(0)

  const showGrandTotalAlert = Number(quote.total) > 0 && Number(grandTotal) !== Number(quote.total)

  useEffect(() => {
    const total = Number(subTotal || 0) + Number(tax || 0) + Number(shipping || 0) - Number(discount || 0)
    setGrandTotal(Number(total.toFixed(2)))
    onChange({ tax, shipping, discount })
  }, [tax, shipping, discount, subTotal])

  return (
    <FlexBoxY>
      <FailureReason quote={quote} />
      <Space style={{ width: '100%' }}>
        <Box width="100%">
          <Typography.Text strong>Tax</Typography.Text>
          <InputCurrency style={{ width: '100%', paddingRight: 16 }} value={tax} onChange={(e) => setTax(Number(e))} />
        </Box>
        <Box width="100%">
          <Typography.Text strong>Shipping</Typography.Text>
          <InputCurrency
            style={{ width: '100%', paddingRight: 16 }}
            value={shipping}
            onChange={(e) => setShipping(Number(e))}
          />
        </Box>
        <Box width="100%">
          <Typography.Text strong>Discount</Typography.Text>
          <InputCurrency
            style={{ width: '100%', paddingRight: 16 }}
            value={discount}
            onChange={(e) => setDiscount(Number(e))}
          />
        </Box>
        <Box width="100%">
          <Typography.Text strong>Grand total</Typography.Text>
          <FlexBoxX>
            <InputCurrency value={grandTotal} disabled />
            {showGrandTotalAlert && (
              <Box margin={8} color="error">
                <Popover
                  content={
                    <Typography.Text style={{ width: 250, display: 'block' }}>
                      When we attempted to extract the subtotal directly from the quote we found{' '}
                      <Typography.Text strong underline>
                        ${Number(quote.total || 0).toFixed(2)}
                      </Typography.Text>{' '}
                      which does not match the calculated value. Please double check that everything is correct.
                    </Typography.Text>
                  }
                  placement="topRight"
                  arrowPointAtCenter
                >
                  <ExclamationCircleOutlined />
                </Popover>
              </Box>
            )}
          </FlexBoxX>
        </Box>
      </Space>
    </FlexBoxY>
  )
})
