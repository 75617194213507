import React from 'react'

import { Button, Popconfirm, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

import { OrderStates } from 'common/server/server_types'

import { ModalConfirmPurchase } from 'contractor/components/ModalConfirmPurchase'
import { useStores } from 'contractor/hooks/use-stores'
import { UpdateAndSaveButton } from 'contractor/pages/OrderDetail/Actions/update_button'

import { OrderActionsProps } from './actions'
import { ExtraActions } from './extra_actions'

type QuotedStateActionsProps = Pick<
  OrderActionsProps,
  | 'onFinish'
  | 'onCancelOrder'
  | 'onPlaceOrder'
  | 'actionClicked'
  | 'isPoLocked'
  | 'purchaseOrderModalVisible'
  | 'togglePurchaseOrderModalVisible'
  | 'syncOrderWithIntegration'
  | 'toggleSyncOrderWithIntegration'
  | 'cancelOtherPackageOrders'
  | 'toggleCancelOtherPackageOrders'
  | 'onRequestCancellation'
  | 'isSpreadsheetMode'
  | 'toggleSpreadsheetMode'
>

export const QuotedStateActions = observer<QuotedStateActionsProps>(
  ({
    onFinish,
    onCancelOrder,
    onPlaceOrder,
    actionClicked,
    isPoLocked,
    purchaseOrderModalVisible,
    togglePurchaseOrderModalVisible,
    syncOrderWithIntegration,
    toggleSyncOrderWithIntegration,
    cancelOtherPackageOrders,
    toggleCancelOtherPackageOrders,
    onRequestCancellation,
    isSpreadsheetMode,
    toggleSpreadsheetMode,
  }) => {
    const { orderStore, userStore } = useStores()

    return (
      <>
        {userStore.canSendAndUpdateRfqs && (
          <Popconfirm
            placement="bottom"
            title="Are you sure to cancel this RFQ?"
            onConfirm={onCancelOrder}
            okText="Yes"
            cancelText="No"
          >
            <Button
              data-cy="cancel-rfq"
              danger
              loading={actionClicked === 1}
              disabled={isPoLocked || actionClicked > 0}
            >
              Cancel
            </Button>
          </Popconfirm>
        )}

        {userStore.canSendAndUpdateOrders && (
          <Tooltip title="Notify the vendor you would like to place the order.">
            <Button
              data-cy="place-order"
              type="primary"
              onClick={() => togglePurchaseOrderModalVisible(true)}
              loading={actionClicked === 2}
              disabled={isPoLocked || actionClicked > 0}
            >
              Place Order
            </Button>
          </Tooltip>
        )}

        <UpdateAndSaveButton
          orderState={OrderStates.QUOTED}
          onUpdateWithNotification={() => onFinish({ action: 3 })}
          onUpdateWithoutNotification={() => onFinish({ action: 3, silentUpdate: true })}
          loading={actionClicked === 3}
          disabled={
            isPoLocked || !orderStore.selectedOrderDirty || actionClicked > 0 || !userStore.canSendAndUpdateRfqs
          }
        />

        <ExtraActions
          onRequestCancellation={onRequestCancellation}
          isSubmitting={actionClicked > 0}
          isSpreadsheetMode={isSpreadsheetMode}
          toggleSpreadsheetMode={toggleSpreadsheetMode}
        />

        {userStore.canSendAndUpdateOrders && (
          <ModalConfirmPurchase
            open={purchaseOrderModalVisible}
            onFinish={onPlaceOrder}
            onClose={() => togglePurchaseOrderModalVisible(false)}
            onChangeSyncOrderWithIntegration={toggleSyncOrderWithIntegration}
            cancelOtherPackageOrders={cancelOtherPackageOrders}
            onChangeCancelOtherPackageOrders={toggleCancelOtherPackageOrders}
            syncOrderWithIntegration={syncOrderWithIntegration}
            isLoading={actionClicked > 0}
          />
        )}
      </>
    )
  },
)
