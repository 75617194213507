import React from 'react'

import { observer } from 'mobx-react-lite'

import { ButtonToggleSpreadsheet } from 'common/components/OrderMaterialsV2'
import { OrderMaterialTabs } from 'common/components/OrderMaterialTabs'
import { Visibility } from 'common/components/Visibility'
import { Address } from 'common/server/addresses'
import { emptyOrderDelivery } from 'common/server/deliveries'

import { DeliveryInfo } from 'contractor/components/OrderDeliveries'
import { getDeliveryPickUpTitle } from 'contractor/helpers/get-delivery-pick-up-title'
import { useStores } from 'contractor/hooks/use-stores'
import { addCompanyVendor } from 'contractor/pages/NewQuote/VendorForm'

import { useNewQuote } from '../context'
import { MaterialsSpreadsheet } from './materials_spreadsheet'
import { MaterialsV2 } from './materials_v2'

export type MaterialsAndDeliveriesProps = {
  orderType: OrderType
  disabled?: boolean
  isPoLocked?: boolean
  overBudgetMaterial?: string
}

export const MaterialsAndDeliveries = observer<MaterialsAndDeliveriesProps>(
  ({ orderType, disabled, isPoLocked, overBudgetMaterial }) => {
    const { orderStore, companyVendorStore } = useStores()

    const { setCompanyVendors, companyVendors, projectId, isSpreadsheetMode, toggleSpreadsheetMode, handleOrderDirty } =
      useNewQuote()

    const deliveries = orderStore.newDeliveries

    const handleAddDelivery = () => {
      const newDelivery = emptyOrderDelivery()
      deliveries.push(newDelivery)
      orderStore.addEmptyOrderMaterials(newDelivery.id, 5)
      handleOrderDirty()
    }

    const handleRemoveDelivery = (index: string) => {
      deliveries.splice(parseInt(index, 10), 1)
      handleOrderDirty()
    }

    const handleChangeAddress = async (address: Nullable<Address>) => {
      if (address?.owned_by_type === 'CompanyVendor') {
        companyVendorStore.companyVendorContactListStore.setFilter('company_vendor_id', address.owned_by_id, true, true)
        const companyVendorContacts = await companyVendorStore.companyVendorContactListStore.fetchRecords()

        if (companyVendorContacts?.length) {
          const vendorContact = companyVendorContacts[0]
          const newCompanyVendors = addCompanyVendor(vendorContact, '', [])
          setCompanyVendors(newCompanyVendors)
        }

        handleOrderDirty()
      }
    }

    let deliveryCount = 0
    let pickUpCount = 0

    const companyVendorId = companyVendors[0]?.id

    return (
      <OrderMaterialTabs
        onDuplicate={(deliveryIndex) => orderStore.duplicateDelivery(deliveryIndex, deliveries)}
        onAdd={handleAddDelivery}
        onRemove={handleRemoveDelivery}
        deliveriesCount={deliveries.length}
        hideAdd={orderStore.isSplitting}
        disabled={disabled}
        items={deliveries?.map((delivery, index) => {
          const idx = index + 1
          delivery.is_pick_up ? pickUpCount++ : deliveryCount++

          const title = getDeliveryPickUpTitle({
            requestedDeliveredAt: delivery.requested_delivered_at,
            isPickUp: delivery.is_pick_up,
            pickUpCount,
            deliveryCount,
          })

          return {
            title,
            label: <OrderMaterialTabs.TabPaneTitle title={title} isPickUp={delivery.is_pick_up} />,
            key: `${index}`,
            closable: deliveries.length > 1 && !orderStore.isSplitting,
            children: (
              <>
                <DeliveryInfo
                  project_ids={[projectId]}
                  delivery={delivery}
                  showDetails={false}
                  orderType={orderType}
                  disabled={disabled}
                  companyVendorId={companyVendorId}
                  onChangeAddress={handleChangeAddress}
                  handleOrderDirty={handleOrderDirty}
                />
                {isSpreadsheetMode ? (
                  <MaterialsSpreadsheet
                    index={idx}
                    deliveryId={delivery.id}
                    orderType={orderType}
                    isPoLocked={isPoLocked}
                  />
                ) : (
                  <MaterialsV2
                    index={idx}
                    deliveryId={delivery.id}
                    orderType={orderType}
                    disabled={disabled}
                    overBudgetMaterial={overBudgetMaterial}
                    isPoLocked={isPoLocked}
                  />
                )}
                <Visibility.Hidden>
                  <ButtonToggleSpreadsheet
                    onClick={() => toggleSpreadsheetMode((prev) => !prev)}
                    data-cy={isSpreadsheetMode ? `is-spread-sheet-${index}` : `spread-sheet-${index}`}
                    isSpreadsheetMode={isSpreadsheetMode}
                    disabled={orderStore.isSplitting || disabled}
                  />
                </Visibility.Hidden>
              </>
            ),
          }
        })}
      />
    )
  },
)
