import { formatDateStringShort } from 'common/helpers/formatters'
import { calcExtCost } from 'common/helpers/order'
import { OrderHit } from 'common/server/orders'

import { ShowOrderResponse } from 'contractor/server/orders'

type Params = { order?: ShowOrderResponse; orderHit?: OrderHit }

const getDestination = ({ order, orderHit }: Params) => {
  const inventoryGroupName = 'z. Warehouse'
  if (
    order?.project?.project_group?.name === inventoryGroupName ||
    orderHit?.project_group_name === inventoryGroupName
  ) {
    return '2'
  }

  return '1'
}

const getAccount = ({ orderMaterial, order = {}, orderHit = {} }) => {
  const costCodeClass = orderMaterial?.cost_code?.clazz?.toUpperCase()?.trim()

  const destination = getDestination({ order, orderHit } as Params)

  if (destination === '2') {
    return 275000
  }

  if (costCodeClass === 'M') {
    return 730000
  }

  if (costCodeClass === 'E') {
    return 765000
  }
}

/*
  The TCM needs all these columns in exactly this order even though not all columns had value.
*/
export const makeTCMHeaderFile = ({ order, orderHit }: Params) => {
  if (orderHit) {
    return {
      'Company ID': '25',
      'Purchase Order': orderHit?.order_number,
      Destination: getDestination({ orderHit }),
      Type: '1',
      Vendor: orderHit?.company_vendor_name,
      'Job ID': orderHit?.project_identifier,
      Category: '',
      Description: '',
      'Issue Date': formatDateStringShort(orderHit?.ordered_at),
      'Authorized By': '',
      Confirming: '',
      Requisition: '',
      'User Field Comment 1': orderHit?.quote_number,
      'User Field Comment 2': '',
      Notes: '',
      'Vend Name': '',
      'Vend Address Line 1': '',
      'Vend Address Line 2': '',
      'Vend Address Line 3': '',
      'Vend City': '',
      'Vend State': '',
      'Vend Country': '',
      'Vend Zip Code': '',
      'Vend Telephone': '',
      'Vend Fax': '',
      'Vend Email': '',
      'Vend Contact Salutation': '',
      'Vend Contact First Name': '',
      'Vend Contact Middle Initial': '',
      'Vend Contact Last Name': '',
      'Ship Name': '',
      'Ship Address Line 1': '',
      'Ship Address Line 2': '',
      'Ship Address Line 3': '',
      'Ship City': '',
      'Ship State': '',
      'Ship Country': '',
      'Ship Zip Code': '',
      'Ship Telephone': '',
      'Ship  Fax': '',
      'Ship  Email': '',
      'Ship Contact Salutation': '',
      'Ship Contact First Name': '',
      'Ship Contact Middle Initial': '',
      'Ship Contact Last Name': '',
      'FOB Term': '',
      'Payment Term': '',
      'Freight Term': '',
      'Ship Via': orderHit?.deliveries[0]?.is_pick_up ? 'Pick Up' : 'Delivery',
    }
  }

  return {
    'Company ID': '25',
    'Purchase Order': order?.order_number,
    Destination: getDestination({ order }),
    Type: '1',
    Vendor: order?.company_vendor?.vendor?.name || order?.company_vendor?.vendor_name,
    'Job ID': order?.project?.project_id,
    Category: '',
    Description: '',
    'Issue Date': formatDateStringShort(order?.ordered_at),
    'Authorized By': '',
    Confirming: '',
    Requisition: '',
    'User Field Comment 1': order?.quote_number,
    'User Field Comment 2': '',
    Notes: '',
    'Vend Name': '',
    'Vend Address Line 1': '',
    'Vend Address Line 2': '',
    'Vend Address Line 3': '',
    'Vend City': '',
    'Vend State': '',
    'Vend Country': '',
    'Vend Zip Code': '',
    'Vend Telephone': '',
    'Vend Fax': '',
    'Vend Email': '',
    'Vend Contact Salutation': '',
    'Vend Contact First Name': '',
    'Vend Contact Middle Initial': '',
    'Vend Contact Last Name': '',
    'Ship Name': '',
    'Ship Address Line 1': '',
    'Ship Address Line 2': '',
    'Ship Address Line 3': '',
    'Ship City': '',
    'Ship State': '',
    'Ship Country': '',
    'Ship Zip Code': '',
    'Ship Telephone': '',
    'Ship  Fax': '',
    'Ship  Email': '',
    'Ship Contact Salutation': '',
    'Ship Contact First Name': '',
    'Ship Contact Middle Initial': '',
    'Ship Contact Last Name': '',
    'FOB Term': '',
    'Payment Term': '',
    'Freight Term': '',
    'Ship Via': order?.deliveries[0]?.is_pick_up ? 'Pick Up' : 'Delivery',
  }
}

export const makeTCMDetailFileItems = ({ order, orderHit }: Params) => {
  if (orderHit) {
    return orderHit?.order_materials?.map((orderMaterial) => {
      const extCost = calcExtCost({
        unitCost: orderMaterial?.unit_cost,
        quantity: orderMaterial?.quantity,
        multiplier: orderMaterial?.company_material?.unit?.multiplier,
        qtyIncrement: orderMaterial?.company_material?.unit?.qty_increment,
      })

      return {
        'Company ID': '25',
        'Purchase Order': orderHit?.order_number,
        Destination: getDestination({ orderHit }),
        Type: '1',
        Phase: '',
        'Cost Code': orderMaterial?.cost_code?.code,
        Category: '',
        'Cost Type Abbr': orderMaterial?.cost_code?.clazz,
        'Change Order': '',
        'IV Item': '',
        'IV Location': '',
        'IV Area': '',
        'Unit Cost Item': '',
        'Start Date': formatDateStringShort(orderHit?.quoted_at),
        'Complete Date': formatDateStringShort(orderHit?.ordered_at),
        Quantity: orderMaterial?.quantity,
        'Unit Measure': '',
        'Unit Price': orderMaterial?.unit_cost,
        'Total Amt': extCost,
        'Retention Pct': '',
        'Retention Amt': '',
        'Sales Tax Percent': '',
        'Sales Tax Amt': '',
        'Description 1': orderMaterial?.company_material?.description,
        'Description 2': '',
        'Description 3': '',
        'Description 4': '',
        'Discount Percent': '',
        Account: getAccount({ orderMaterial, orderHit }),
        'Sales Tax Code': '',
        'Quantities Received': '',
      }
    })
  }

  return order?.order_materials?.map((orderMaterial) => {
    const extCost = calcExtCost({
      unitCost: orderMaterial?.unit_cost,
      quantity: orderMaterial?.quantity,
      multiplier: orderMaterial?.company_material?.unit?.multiplier,
      qtyIncrement: orderMaterial?.company_material?.unit?.qty_increment,
    })

    return {
      'Company ID': '25',
      'Purchase Order': order?.order_number,
      Destination: getDestination({ order }),
      Type: '1',
      Phase: '',
      'Cost Code': orderMaterial?.cost_code?.code,
      Category: '',
      'Cost Type Abbr': orderMaterial?.cost_code?.clazz,
      'Change Order': '',
      'IV Item': '',
      'IV Location': '',
      'IV Area': '',
      'Unit Cost Item': '',
      'Start Date': formatDateStringShort(order?.quoted_at),
      'Complete Date': formatDateStringShort(order?.ordered_at),
      Quantity: orderMaterial?.quantity,
      'Unit Measure': '',
      'Unit Price': orderMaterial?.unit_cost,
      'Total Amt': extCost,
      'Retention Pct': '',
      'Retention Amt': '',
      'Sales Tax Percent': '',
      'Sales Tax Amt': '',
      'Description 1': orderMaterial?.company_material?.description,
      'Description 2': '',
      'Description 3': '',
      'Description 4': '',
      'Discount Percent': '',
      Account: getAccount({ orderMaterial, order }),
      'Sales Tax Code': '',
      'Quantities Received': '',
    }
  })
}

export const makeTCMTaxItem = ({ order, orderHit }: Params) => {
  if (orderHit) {
    const totalTaxes = orderHit?.deliveries?.reduce((acc, delivery) => acc + Number(delivery?.tax_value), 0)

    return {
      'Company ID': '25',
      'Purchase Order': orderHit?.order_number,
      Destination: '1',
      Type: '1',
      Phase: '',
      'Cost Code': '004000',
      Category: '',
      'Cost Type Abbr': 'M',
      'Change Order': '',
      'IV Item': '',
      'IV Location': '',
      'IV Area': '',
      'Unit Cost Item': '',
      'Start Date': formatDateStringShort(orderHit?.quoted_at),
      'Complete Date': formatDateStringShort(orderHit?.ordered_at),
      Quantity: 1,
      'Unit Measure': '',
      'Unit Price': totalTaxes,
      'Total Amt': totalTaxes,
      'Retention Pct': '',
      'Retention Amt': '',
      'Sales Tax Percent': '',
      'Sales Tax Amt': '',
      'Description 1': 'Sales Taxes',
      'Description 2': '',
      'Description 3': '',
      'Description 4': '',
      'Discount Percent': '',
      Account: '730000',
      'Sales Tax Code': '',
      'Quantities Received': '',
    }
  }

  const totalTaxes = order?.deliveries?.reduce((acc, delivery) => acc + Number(delivery?.tax_value), 0)

  return {
    'Company ID': '25',
    'Purchase Order': order?.order_number,
    Destination: '1',
    Type: '1',
    Phase: '',
    'Cost Code': '004000',
    Category: '',
    'Cost Type Abbr': 'M',
    'Change Order': '',
    'IV Item': '',
    'IV Location': '',
    'IV Area': '',
    'Unit Cost Item': '',
    'Start Date': formatDateStringShort(order?.quoted_at),
    'Complete Date': formatDateStringShort(order?.ordered_at),
    Quantity: 1,
    'Unit Measure': '',
    'Unit Price': totalTaxes,
    'Total Amt': totalTaxes,
    'Retention Pct': '',
    'Retention Amt': '',
    'Sales Tax Percent': '',
    'Sales Tax Amt': '',
    'Description 1': 'Sales Taxes',
    'Description 2': '',
    'Description 3': '',
    'Description 4': '',
    'Discount Percent': '',
    Account: '730000',
    'Sales Tax Code': '',
    'Quantities Received': '',
  }
}

export const makeFoundationHeaderFile = ({ order, orderHit }: Params) => {
  if (orderHit) {
    return {
      'PO#': orderHit?.order_number,
      Vendor: orderHit?.company_vendor_name,
      Date: formatDateStringShort(orderHit?.ordered_at),
      'Description of PO': orderHit?.name,
      Job: orderHit?.project_name,
      GL: '5420',
      'Amount Pre Tax': orderHit?.subtotal_before_tax,
      'Sales Tax': 'C',
    }
  }

  return {
    'PO#': order?.order_number,
    Vendor: order?.company_vendor?.vendor_name,
    Date: formatDateStringShort(order?.ordered_at),
    'Description of PO': order?.order_package_name,
    Job: order?.project?.name,
    GL: '5420',
    'Amount Pre Tax': orderHit?.subtotal_before_tax,
    'Sales Tax': 'C',
  }
}

export const makeFoundationDetailFileItems = ({ order, orderHit }: Params) => {
  if (orderHit) {
    return orderHit?.order_materials?.map((orderMaterial) => {
      const extCost = calcExtCost({
        unitCost: orderMaterial?.unit_cost,
        quantity: orderMaterial?.quantity,
        multiplier: orderMaterial?.company_material?.unit?.multiplier,
        qtyIncrement: orderMaterial?.company_material?.unit?.qty_increment,
      })

      return {
        'PO#': orderHit?.order_number,
        Job: orderHit?.project_name,
        GL: '5420',
        Division: '1',
        'Cost Code': orderMaterial?.cost_code?.code,
        'Cost Class': orderMaterial?.cost_code?.clazz,
        'Amount Pre Tax': extCost,
      }
    })
  }

  return order?.order_materials?.map((orderMaterial) => {
    const extCost = calcExtCost({
      unitCost: orderMaterial?.unit_cost,
      quantity: orderMaterial?.quantity,
      multiplier: orderMaterial?.company_material?.unit?.multiplier,
      qtyIncrement: orderMaterial?.company_material?.unit?.qty_increment,
    })

    return {
      'PO#': order?.order_number,
      Job: order?.project?.name,
      GL: '5420',
      Division: '1',
      'Cost Code': orderMaterial?.cost_code?.code,
      'Cost Class': orderMaterial?.cost_code?.clazz,
      'Amount Pre Tax': extCost,
    }
  })
}
