/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require('@rails/ujs').start()
require('@rails/activestorage').start()

import 'normalize.css'
import 'antd/dist/antd.less'

// Include the full Algolia theme (actually importing the link directly via application.html.rb)
import 'instantsearch.css/themes/algolia'

/* TODO We are having a problem actually importing stylesheets directly into our projects
 that exist on nodemodules. We've really only been able to get two solutions to work in production
 1) Import using a "link_stylesheet" in application.html.erb which has to make a webrequest outside the blox app
 2) Manually copy and paste all the styles into their own css file

 Both of these suck and as we need to make updates and changes are only going to get more and more annoying
 */
import 'common/styles/staging.css'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
const componentRequireContext = require.context('contractor', true)
const ReactRailsUJS = require('react_ujs') // eslint-disable-line
ReactRailsUJS.useContext(componentRequireContext)
