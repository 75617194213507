import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { OrdersList } from 'common/components/OrdersList'

interface Props {
  invoices: Array<{ id: string; number: string }>
}

export function CellInvoices(props: Props) {
  const { invoices } = props

  return (
    <Box>
      {invoices.slice(0, 3).map((invoice, index) => (
        <Typography.Text key={invoice.id}>{(index && index <= 2 ? ', ' : '') + `#${invoice.number}`}</Typography.Text>
      ))}
      {invoices.length > 3 && ` and ${invoices.length - 3} more.`}
      {invoices.length === 0 && <OrdersList.Cell.NoContent />}
    </Box>
  )
}
