import { ConfigKeys } from '../match_confidence'

export const getMatchConfidenceOnSelect = (priceSheetMaterial, databaseMaterial): ConfigKeys => {
  if (!databaseMaterial) return 'noMatch'

  if (
    !!priceSheetMaterial?.unit_id &&
    !!databaseMaterial?.unit_id &&
    priceSheetMaterial.unit_id === databaseMaterial.unit_id
  ) {
    return 'match'
  }

  if (
    !!priceSheetMaterial?.unit_name &&
    !!databaseMaterial?.unit_name &&
    priceSheetMaterial.unit_name === databaseMaterial.unit_name
  ) {
    return 'match'
  }

  if (
    !priceSheetMaterial?.unit_id &&
    !databaseMaterial?.unit_id &&
    !priceSheetMaterial.unit_name &&
    !databaseMaterial.unit_name
  ) {
    return 'match'
  }

  return 'partialMatch'
}

export const getMatchConfidenceOnUpdate = (priceSheetMaterial, databaseMaterial): ConfigKeys => {
  if (!databaseMaterial) return 'noMatch'

  if (
    !!priceSheetMaterial?.unit?.value &&
    !!databaseMaterial?.unit?.value &&
    priceSheetMaterial?.unit?.value === databaseMaterial?.unit?.value
  ) {
    return 'match'
  }

  if (!priceSheetMaterial?.unit?.value && !databaseMaterial?.unit?.value) {
    return 'match'
  }

  return 'partialMatch'
}
