import React from 'react'

import { flatten, uniqBy } from 'lodash'
import pluralize from 'pluralize'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Typography, Tooltip, Space, Divider } from 'antd'

import { Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import { calcExtCost } from 'common/helpers/order'
import { useTheme } from 'common/hooks/use-theme'

import { ShowOrderResponse } from 'contractor/server/orders'

type FooterProps = {
  order: ShowOrderResponse
}

export const Footer = (props: FooterProps) => {
  const { order } = props

  const theme = useTheme()

  const getTotalRemainingProperties = (remaining) => {
    if (remaining === 0) {
      return {
        color: theme.colors['gray-7'],
        text: '',
        icon: null,
      }
    }

    if (remaining > 0) {
      return {
        color: theme.colors.link,
        text: 'Outstanding balance to be invoiced',
        icon: <ExclamationCircleOutlined style={{ color: theme.colors.link, marginLeft: 4 }} />,
      }
    }

    return {
      color: theme.colors.error,
      text: 'Total invoiced exceeds PO amount',
      icon: <ExclamationCircleOutlined style={{ color: theme.colors.error, marginLeft: 4 }} />,
    }
  }

  const invoiceMaterials = uniqBy(flatten(order?.invoices?.map((invoice) => invoice.invoice_materials)), 'id')

  const invoiceSubtotal = invoiceMaterials?.reduce((acc, invoiceMaterial) => {
    const extendedCost = calcExtCost({
      unitCost: invoiceMaterial?.unit_price,
      quantity: invoiceMaterial?.quantity_shipped,
      multiplier: invoiceMaterial?.unit?.multiplier,
      qtyIncrement: invoiceMaterial?.unit?.qty_increment,
    })
    return acc + extendedCost
  }, 0)

  const invoiceCosts = order?.invoices?.reduce(
    (acc, invoice) => {
      acc.discountValue += Number(invoice.discount_amount || 0)
      acc.shippingValue += Number(invoice.shipping_cost || 0)
      acc.otherValue += Number(invoice.other_costs || 0)
      acc.taxValue += Number(invoice.tax_amount || 0)
      return acc
    },
    {
      discountValue: 0,
      shippingValue: 0,
      otherValue: 0,
      taxValue: 0,
    },
  )

  const totalInvoicedAmount =
    invoiceSubtotal +
    Number(invoiceCosts?.taxValue) +
    Number(invoiceCosts?.shippingValue) +
    Number(invoiceCosts?.otherValue) -
    Number(invoiceCosts?.discountValue)

  const totalRemaining = Number(order?.grand_total) - totalInvoicedAmount
  const totalRemainingProperties = getTotalRemainingProperties(totalRemaining)

  return (
    <Box borderTop="1px solid" borderColor="gray-2" p={16} display="flex" justifyContent="flex-end">
      <Space>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography.Text type="secondary">PO Total</Typography.Text>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {currencyFormatter(order?.grand_total, 2)}
          </Typography.Title>
        </Box>

        <Divider type="vertical" style={{ height: 44 }} />

        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography.Text type="secondary">
            Total Invoiced ({pluralize('Invoice', order?.invoices?.length, true)})
          </Typography.Text>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {currencyFormatter(totalInvoicedAmount, 2)}
          </Typography.Title>
        </Box>

        <Divider type="vertical" style={{ height: 44 }} />

        <Tooltip title={totalRemainingProperties.text}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography.Text type="secondary">Remaining {totalRemainingProperties.icon}</Typography.Text>
            <Typography.Title level={5} style={{ margin: 0, color: totalRemainingProperties.color }}>
              {currencyFormatter(totalRemaining, 2)}
            </Typography.Title>
          </Box>
        </Tooltip>
      </Space>
    </Box>
  )
}
