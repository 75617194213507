import React from 'react'

import moment from 'moment'

import { Typography } from 'antd'

import { OrdersList } from 'common/components/OrdersList'
import { formatDateString } from 'common/helpers/formatters'

type CellExpirationQuoteProps = {
  date?: string
}

export const CellExpirationDate = ({ date }: CellExpirationQuoteProps) => {
  const expDate = moment(date)
  const diffInDays = Math.round(moment().diff(expDate, 'days', true)) * -1

  if (!date) {
    return <OrdersList.Cell.NoContent />
  }

  return <Typography.Text type={diffInDays <= 30 ? 'danger' : 'secondary'}>{formatDateString(date)}</Typography.Text>
}
