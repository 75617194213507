import React from 'react'

import { Link } from 'react-router-dom'

import { ForwardOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import theme from 'common/styles/theme'

import { OrderDetailPopover } from 'contractor/components/OrderDetailPopover'

interface Props {
  id: string
  name: string
  number: string
  href: string
  isQuickCreated?: boolean
}

export function CellOrderNumber(props: Props) {
  const { id, name, number, href, isQuickCreated } = props

  return (
    <FlexBoxX justifyContent="flex-start">
      {isQuickCreated && (
        <Tooltip title="This PO was Quick Created during Invoice Approval">
          <ForwardOutlined style={{ fontSize: 20, marginRight: 4, color: theme.colors.link }} />
        </Tooltip>
      )}

      <OrderDetailPopover orderId={id}>
        <Link to={href} onClick={(e) => e.stopPropagation()}>
          {number}: {name}
        </Link>
      </OrderDetailPopover>
    </FlexBoxX>
  )
}
