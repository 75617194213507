import React from 'react'

import ReactJson from 'react-json-view'

import { Button, Modal, Space, Spin, Typography } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'
import { withErrorBoundary } from 'common/components/ErrorBoundary/error_boundary'
import 'common/data-access/axios'
import Event from 'common/pages/TestEmail/event'
import { get } from 'common/server/test_email'

import 'moment'

const History: React.FC<{ messageId: string }> = ({ messageId }) => {
  const pollLimit = 20

  const [intervalId, setIntervalId] = React.useState(0)
  const [history, setHistory] = React.useState([])
  const [modalDetail, setModalDetail] = React.useState(null)
  const [pollCount, setPollCount] = React.useState(0)
  const [polling, setPolling] = React.useState(false)

  React.useEffect(() => {
    if (messageId != '') {
      // If we have an interval clear it
      clearInterval(intervalId)
      setPollCount(0)
      setPolling(true)
      setHistory([])
      // Poll every 2 seconds
      const id = setInterval(async () => {
        const data = (await get(messageId)).data
        setPollCount((prev) => prev + 1)
        setHistory(() => data)
      }, 2000)
      setIntervalId(id)
    }
  }, [messageId])

  React.useEffect(() => {
    return () => intervalId && clearInterval(intervalId)
  }, [intervalId])

  React.useEffect(() => {
    // Once we exceed our limit or have our data (generally records) clear the interval so we stop polling
    if (pollCount > pollLimit || history.length >= 2) {
      clearInterval(intervalId)
      setPolling(false)
    }
  }, [pollCount, intervalId, history, setPolling])

  return (
    <Box width="100%" height="100%" marginTop="30px" overflow="scroll">
      <FlexBoxX flexGrow={0} marginBottom="15px">
        <Space>
          <Typography.Title level={3}>Events</Typography.Title>
          {polling && <Spin />}
        </Space>
      </FlexBoxX>
      {history.map((event, i) => {
        return (
          <FlexBoxX key={i} height="60px">
            <Box minWidth="800px" maxWidth="800px" marginRight="15px">
              <Event event={event} />
            </Box>
            <Button onClick={() => setModalDetail(event)}>Open Detail</Button>
          </FlexBoxX>
        )
      })}
      <Modal
        title="Request Detail"
        open={!!modalDetail}
        onOk={() => setModalDetail(null)}
        onCancel={() => setModalDetail(null)}
        width={1000}
      >
        <Box display="block" maxWidth="950px" maxHeight="500px" overflow="scroll">
          <ReactJson src={modalDetail} />
        </Box>
      </Modal>
    </Box>
  )
}

export default withErrorBoundary(History)
