import React, { useCallback } from 'react'

import { observer } from 'mobx-react-lite'

import { DebounceSelectProps } from 'common/components/DebounceSelect'

import {
  DEFAULT_PAGE_SIZE,
  SelectExternalMappings,
  ValueType,
} from 'contractor/components/SelectExternalMappings/select_external_mappings'
import { useStores } from 'contractor/hooks/use-stores'
import { ListParams } from 'contractor/server/integrations'

type SelectAgaveCostCodePhaseProps = {
  multiToMono?: boolean
  tiedToId?: Nullable<string>
  canDisableSelected?: boolean
  selected?: ValueType[]
} & Partial<DebounceSelectProps>

export const SelectAgaveCostCodePhase = observer<SelectAgaveCostCodePhaseProps>(
  ({ multiToMono, tiedToId, selected, canDisableSelected, ...props }) => {
    const { integrationStore } = useStores()

    const fetchOptions = useCallback(
      (search?: string, page = 1, per_page = DEFAULT_PAGE_SIZE) => {
        let params: ListParams = { search, page, per_page }
        if (integrationStore.isProcore() && tiedToId) {
          params = { ...params, tied_to_id: tiedToId }
        }
        return integrationStore.getCostCodePhasesRelationships(params, true)
      },
      [canDisableSelected, tiedToId, integrationStore.accountingIntegration?.integrations],
    )

    return (
      <SelectExternalMappings
        selected={selected}
        // @ts-ignore (this one too, cant make callback options match the fetchOptions with unknown type)
        fetchOptions={fetchOptions}
        enabledMultiMapping={!multiToMono && integrationStore.enabledCostPhaseMultiMapping()}
        {...props}
      />
    )
  },
)
