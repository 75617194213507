import React, { useRef } from 'react'

import { Alert } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'
import { Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { ErrorBoundary } from 'common/components/ErrorBoundary'
import { Loading } from 'common/components/Loading'
import { useQuery } from 'common/hooks/use-query'

import { InvoiceTotalValues } from 'contractor/components/Invoices/invoice_total_values'
import { DrawerInvoices } from 'contractor/components/Invoices/invoices_drawer'
import { useStores } from 'contractor/hooks/use-stores'

import { DeliveryList } from './delivery_list'
import { OrderInfo } from './order_info'

type OrderPanelProps = {
  orderId: string
}

export const OrderPanel = observer(({ orderId }: OrderPanelProps) => {
  const { invoiceStore } = useStores()

  const drawerUpdateRef = useRef<DrawerRef>()

  const { isLoading } = useQuery(() => invoiceStore.selectOrder(orderId), [orderId])

  const invoiceOrder = invoiceStore.getInvoiceOrder(orderId)

  if (isLoading) {
    return <Loading />
  }

  if (!invoiceOrder) {
    return null
  }

  const remaining = Number(invoiceOrder.order?.grand_total) - Number(invoiceOrder.order.total_invoiced_amount)

  return (
    <ErrorBoundary isFull={false}>
      <FlexBoxY width="100%" height="100%" alignItems="flex-start">
        <OrderInfo order={invoiceOrder.order} />

        {invoiceOrder.order?.quick_created_at && (
          <Alert
            message="This PO was Quick Created during Invoice Approval"
            type="info"
            style={{ width: '100%', marginBottom: 12 }}
          />
        )}

        <DeliveryList
          deliveries={invoiceOrder.order.deliveries}
          orderMaterials={invoiceOrder.materials}
          projectIds={[invoiceOrder.order.project_id]}
        />

        <Box
          borderBottom="1px solid"
          borderLeft="1px solid"
          borderRight="1px solid"
          borderColor="gray-5"
          width="100%"
          px={16}
          pb={16}
          bg="white"
        >
          <InvoiceTotalValues
            grandTotal={invoiceOrder.order?.grand_total}
            totalInvoicedAmount={invoiceOrder.order.total_invoiced_amount}
            remaining={remaining}
            invoicesCount={invoiceOrder.order.invoices?.length}
            onClickInvoice={() => drawerUpdateRef.current?.show()}
            showRemaining={invoiceStore.invoice?.orders.length === 1}
            showTotalInvoicedAmount={invoiceStore.invoice?.orders.length === 1}
          />
        </Box>
      </FlexBoxY>

      <DrawerInvoices
        orderNumber={invoiceOrder.order.order_number}
        invoices={invoiceOrder.order.invoices}
        ref={drawerUpdateRef}
        grandTotal={invoiceOrder.order?.grand_total}
        totalInvoicedAmount={invoiceOrder.order.total_invoiced_amount}
        remaining={remaining}
        showRemaining={invoiceStore.invoice?.orders.length === 1}
        showTotalInvoicedAmount={invoiceStore.invoice?.orders.length === 1}
      />
    </ErrorBoundary>
  )
})
