import React from 'react'

import { Alert } from 'antd'

export const SuggestionAlert = ({ showSuggestions }: { showSuggestions: boolean }) => {
  if (showSuggestions) {
    return (
      <Alert
        message="SubBase uses AI to suggest mappings for your integration. You can approve individual suggestions line by line or the entire page at once."
        style={{ marginTop: 10 }}
        type="warning"
        closable
      />
    )
  }
  return <></>
}
