import { useState, useCallback } from 'react'

import { message } from 'antd'

import { noticeError } from 'common/helpers/new_relic'

import { PriceSheet, PriceSheetCreateCreate } from 'contractor/server/price_sheet'
import PriceSheetStore from 'contractor/stores/PriceSheetStore'

export const usePriceSheet = (priceSheetStore: PriceSheetStore) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [createdPriceSheet, setCreatedPriceSheet] = useState<PriceSheet>(null)

  const createPriceSheet = useCallback(
    async (priceSheetCreateCreate: PriceSheetCreateCreate, onSuccess?: () => void) => {
      try {
        setSubmitting(true)
        const response = await priceSheetStore.createPriceSheet(priceSheetCreateCreate)
        setCreatedPriceSheet(response)
        onSuccess?.()
      } catch (error) {
        noticeError(error, { entry: 'price-sheet-create' })
        message.error(error?.response?.data?.error || 'Unable to create price sheet')
      } finally {
        setSubmitting(false)
      }
    },
    [],
  )

  const destroyPriceSheet = useCallback((id) => {
    try {
      priceSheetStore.destroyPriceSheet(id)
    } catch (error) {
      noticeError(error, { entry: 'price-sheet-destroy' })
    }
  }, [])

  const clearCreatedPriceSheet = useCallback(() => {
    setCreatedPriceSheet(null)
  }, [])

  return { isSubmitting, createdPriceSheet, createPriceSheet, destroyPriceSheet, clearCreatedPriceSheet }
}
