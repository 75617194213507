import React from 'react'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'

import { MaterialHistoryItem } from './material_history_item'
import { MaterialHistoryList } from './material_history_list'

export const OrderMaterialHistoryTab = observer(() => {
  const { companyMaterialStore } = useStores()
  const { cached_last_price, cached_average_price, cached_lowest_price, order_materials } =
    companyMaterialStore.selectedCompanyMaterial

  return (
    <Box height="100%" display="flex" flexDirection="column" alignItems="flex-end" style={{ gap: 12 }}>
      <MaterialHistoryItem
        type="order"
        lastPrice={cached_last_price}
        averagePrice={cached_average_price}
        lowestPrice={cached_lowest_price}
      />
      <MaterialHistoryList items={order_materials} type="order" />
    </Box>
  )
})
