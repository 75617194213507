import axios, { AxiosResponse } from 'axios'

import { Address } from 'common/server/addresses'
import { CompanyTypes, Role, NotificationFrequency, UserTiers, HomePageOptions } from 'common/server/server_types'
import {
  AcceptInvite,
  ForgotPassword,
  Register,
  RegisterMaterialRequester,
  ResendConfirmation,
  ResetPassword,
  SignIn,
  SignOut,
  Flags,
} from 'common/server/user'

export interface ShowUserResponse {
  id: string
  first_name: string
  last_name: string
  phone_number?: string
  email: string
  company_user_id: string
  company_id: string
  company_name: string
  company_type: CompanyTypes
  role: Role
  addresses: Address[]
  is_subbase: boolean
  feature_flags: Flags
  user_permissions: string[]
  available_permissions: string[]
  terms_and_conditions: {
    terms_and_conditions_approved_on: string
    url: string
  }
  project_group_ids: string[]
  notification_frequency: NotificationFrequency
  daily_reminder_email: boolean
  billing: {
    tier: UserTiers
    stripe_customer_id: string
  }
  impersonating: boolean
  intercom_settings: {
    api_base: string
    app_id: string
    name: string
    email: string
    created_at: number
    user_id: string
    user_hash: string
    company: {
      id: string
      name: string
      created_at: number
      plan: string
      type: string
      domain: string
      invoicing_email: string
    }
  }
  help_mode: boolean
  account_users?: { id: string; account_id: string }[]
}

export interface UserEditOptions {
  first_name?: string
  last_name?: string
  terms_and_conditions_approved: boolean
}

export interface UserPersonalInformationEditOptions {
  first_name: string
  last_name: string
}

export interface UserDefaultHomePageSettings {
  default_home_page: HomePageOptions
}
export interface UserPasswordEditOptions {
  current_password?: string
  password?: string
  password_confirmation?: string
}

export interface UserNotificationFrequency {
  notification_frequency: NotificationFrequency
  daily_reminder_email: boolean
}

// Authenticate
export const sign_in: SignIn = function (payload) {
  return axios.post('/users/sign_in', { user: payload })
}

export const sign_out: SignOut = function () {
  return axios.delete('/users/sign_out')
}

export const register: Register = function (payload) {
  return axios.post('/users', { user: payload })
}

export const register_material_requester: RegisterMaterialRequester = function (payload) {
  const { public_order_form_url_extension, ...user } = payload
  return axios.post('/users', { user, public_order_form_url_extension })
}

export const forgot_password: ForgotPassword = function forgot_password(email) {
  return axios.post('/users/password/', { user: { email } })
}

export const reset_password: ResetPassword = function reset_password(
  password,
  password_confirmation,
  reset_password_token,
) {
  return axios.patch('/users/password/', { user: { password, password_confirmation, reset_password_token } })
}

export const resend_confirmation: ResendConfirmation = function (email) {
  return axios.post('/users/confirmation/', { user: { email } })
}

export const accept_invite: AcceptInvite = function (payload) {
  return axios.put('/users/invitation/', { user: payload })
}

// User
export function update(
  payload: UserEditOptions | UserPersonalInformationEditOptions | UserPasswordEditOptions | UserNotificationFrequency,
): Promise<AxiosResponse<{}>> {
  return axios.put('/users', { user: payload })
}

export function show(): Promise<AxiosResponse<ShowUserResponse>> {
  return axios.get('/user')
}
