import React, { useState } from 'react'

import { ConsolidatedOrders } from 'javascript/contractor/server/orders'
import { flatten } from 'lodash'
import * as XLSX from 'xlsx'

import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
import { Button, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'
import { OrderStates, OrderSubStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

import { makeTCMFileConsolidated } from '../../helpers/tcm'

const allowedOrderStatesToDownload = [
  OrderSubStates.ORDERED_CONFIRMED,
  OrderStates.SHIPPED,
  OrderStates.PARTIALLY_SHIPPED,
  OrderStates.DELIVERED,
]

OrderStates.ORDERED_CONFIRMED_CANCELLATION_REQUESTED

export const DownloadTCM = observer(() => {
  const { orderStore } = useStores()

  const [loading, setLoading] = useState(false)

  async function downloadData() {
    try {
      setLoading(true)

      const orders = await orderStore.listStore.fetchAllRecords()

      if (!orders.length) {
        return
      }

      const ordersToDownload = orders.filter((order) => {
        return (
          (allowedOrderStatesToDownload.includes(order.state) ||
            allowedOrderStatesToDownload.includes(order.sub_state)) &&
          order.state !== OrderStates.ORDERED_CONFIRMED_CANCELLATION_REQUESTED
        )
      })
      const downloadOrders = flatten(
        ordersToDownload.map((consolidatedOrder) => makeTCMFileConsolidated(consolidatedOrder)),
      )

      const wsFile = XLSX.utils.json_to_sheet(downloadOrders)
      const wbFile = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wbFile, wsFile, 'Data')
      XLSX.writeFile(wbFile, 'TCMPOImp.csv')

      await orderStore.manual_export(ordersToDownload.map((order) => ({ id: order.order_id })))

      // HACK: If we update directly the records, the list will not be updated
      // becuase it's an async operation in our API.
      const ordersToUpdate = orderStore.listStore.records.map<ConsolidatedOrders.Order>((order) => ({
        ...order,
        integration_sync_status: 'SYNCED',
      }))

      orderStore.listStore.optimisticUpdateRecords(ordersToUpdate)
    } catch (error) {
      console.log(error)
      message.error(`Unable to download files. Please contact ${SUPPORT_EMAIL_ADDRESS} for further assistance.`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button loading={loading} onClick={downloadData}>
      <DownloadOutlined />
    </Button>
  )
})
