import { BindAll } from 'lodash-decorators'

import { observable } from 'mobx'

import AlgoliaBaseStore from 'common/stores/AlgoliaBaseStore'
import ListBaseStore from 'common/stores/ListBaseStore'

import { OrderMaterialHit, search_key } from 'contractor/server/order_materials'
import {
  consolidatedOrderMaterialsServer,
  ConsolidatedOrdersMaterials,
} from 'contractor/server/order_materials/consolidated_order_materials'

class OrderMaterialListStore extends ListBaseStore<ConsolidatedOrdersMaterials.OrderMaterial> {
  index = consolidatedOrderMaterialsServer.requests.index
  getFacets = consolidatedOrderMaterialsServer.requests.facets
  export = consolidatedOrderMaterialsServer.requests.exportRecords
}

@BindAll()
export default class OrderMaterialStore extends AlgoliaBaseStore<OrderMaterialHit> {
  listStore: OrderMaterialListStore
  getSearchKey = search_key
  @observable selectedRibbonFilter: string = null

  constructor() {
    super()

    this.listStore = new OrderMaterialListStore()
  }

  get orderMaterials() {
    return this.hits
  }
}
