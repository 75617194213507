import React from 'react'

import { Typography } from 'antd'

import { FlexBoxX } from 'common/components/boxes'

export const RequiredMark = ({ title, requiredFields, orderType }) => {
  const isRequired = () => {
    switch (title) {
      case 'Quantity':
        return requiredFields?.quantity
      case 'Unit Cost':
        if (orderType === 'Order') return requiredFields?.unit_cost
        break
      case 'Unit':
        return requiredFields?.unit
      case 'Cost Code':
        return requiredFields?.cost_code
      case 'Tax':
        if (orderType === 'Order') return requiredFields?.tax_value
        break
      default:
        return false
    }
  }

  if (!isRequired()) {
    return <Typography.Text>{title}</Typography.Text>
  }

  return (
    <FlexBoxX>
      <Typography.Text type="danger" style={{ fontSize: 12, paddingRight: 8 }}>
        *
      </Typography.Text>
      <Typography.Text>{title}</Typography.Text>
    </FlexBoxX>
  )
}
