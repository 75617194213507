import axios from 'axios'

export interface OrderSavingRequest {
  order_package_id: string
  original_avg_grand_total: string | number
  original_avg_sub_total: string | number
  ordered_grand_total: string | number
  ordered_sub_total: string | number
  num_quotes_requested: number
  num_orders_placed: number
  sub_total_avg_savings: string | number
  grand_total_avg_savings: string | number
  sub_total_total_min_max_savings: string | number
  grand_total_min_max_savings: string | number
  order_breakdown: {
    order_id: string
    original_sub_total: string | number
    original_grand_total: string | number
  }[]
  avg_material_savings_breakdown: {
    company_material_id: string
    qty: number
    savings: string | number
  }[]
  min_max_material_savings_breakdown: {
    company_material_id: string
    qty: number
    savings: string | number
  }[]
}

export function create_order_saving(order_saving: OrderSavingRequest) {
  return axios.post('/order_savings', { order_saving })
}
