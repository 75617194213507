import React, { useState, useEffect, useImperativeHandle } from 'react'

import moment from 'moment'

import { Typography, Skeleton } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import DeliveryState from 'common/components/statuses/delivery_state'
import { formatDateString } from 'common/helpers/formatters'
import { Deliveries, deliveryRequests } from 'common/server/deliveries'
import { DeliveryStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

import { DeliveriesTable } from './components/DeliveriesTable'
import { useDashboard } from './context'

const columns: ColumnsType<Deliveries.Index> = [
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    render: (state) => {
      return <DeliveryState state={state} />
    },
    width: 160,
  },
  {
    title: 'Name',
    dataIndex: 'order_name',
    key: 'order_name',
    render: (orderName, record) => {
      return `${record?.order_number}: ${orderName}`
    },
  },
  {
    title: 'Vendor',
    dataIndex: 'company_vendor_name',
    key: 'company_vendor_name',
  },
  {
    title: 'Delivery',
    dataIndex: 'best_guess_delivered_at',
    key: 'best_guess_delivered_at',
    render: (date) => <span>{formatDateString(date)}</span>,
  },
]

export type UpcomingDeliveriesRef = {
  fetchDeliveries: () => void
}

const UpcomingDeliveries = observer<{}, UpcomingDeliveriesRef>(
  (_, ref) => {
    const { deliveryStore, projectStore } = useStores()

    const { deliveryDetailRef } = useDashboard()

    const [deliveries, setDeliveries] = useState<Array<Deliveries.Index>>()

    const fetchDeliveries = async () => {
      // Filter for deliveries in next 2 weeks that have yet to be delivered
      const startOfWeek = moment().startOf('week')
      const endOfNextWeek = moment().add(7, 'd').endOf('week')

      const response = await deliveryRequests.index({
        ordered: true,
        project_active: true,
        not_of_state: DeliveryStates.DELIVERED,
        project_id: projectStore.selectedProject?.id,
        best_guess_delivered_at_start: startOfWeek.toDate(),
        best_guess_delivered_at_end: endOfNextWeek.toDate(),
      })

      setDeliveries(response.records)
    }

    useImperativeHandle(ref, () => ({ fetchDeliveries }))

    useEffect(() => {
      fetchDeliveries()
    }, [projectStore.selectedProject?.id])

    if (deliveries === undefined) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
          <Skeleton paragraph={{ rows: 5 }} />
        </Box>
      )
    }
    if (deliveries.length === 0) {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          <Typography.Text type="secondary">No deliveries this week</Typography.Text>
        </Box>
      )
    }

    function handleSelectDelivery(id: string) {
      deliveryStore.selectDelivery(id).then(() => deliveryDetailRef.current?.show())
    }

    return (
      <DeliveriesTable
        columns={columns}
        onSelectDelivery={handleSelectDelivery}
        deliveries={deliveries}
        showProjectName={!projectStore.selectedProject}
      />
    )
  },
  { forwardRef: true },
)

export default UpcomingDeliveries
