import React from 'react'

import { Divider } from 'antd'

import { Box } from 'common/components/boxes'
import { LogDeliveryResponse } from 'common/server/deliveries'

import { DeliveryCard } from 'contractor/components/LogDelivery/SelectOrderStep/delivery_card'

type DeliveryOptionsProps = {
  dataSource: LogDeliveryResponse[]
  onSelect: (deliveryId: string) => void
}

export const DeliveryOptions = (props: DeliveryOptionsProps) => {
  const { dataSource, onSelect } = props

  if (dataSource?.length === 0) {
    return null
  }

  return (
    <Box overflow="auto" width="100%" height="100%">
      {dataSource.map((data) => (
        <React.Fragment key={data.delivery.id}>
          <Divider type="horizontal" style={{ width: '100%', margin: 0, padding: 0, paddingTop: 4 }} />
          <DeliveryCard key={data.delivery.id} orderDelivery={data} onSelect={onSelect} />
        </React.Fragment>
      ))}
    </Box>
  )
}
