import React from 'react'

import ArrowLeftIcon from '@ant-design/icons/ArrowLeftOutlined'
import { Popconfirm } from 'antd'

type UnsavedPopupProps = {
  goBack: () => void
  isDirty?: boolean
}

export const UnsavedPopup = ({ goBack, isDirty }: UnsavedPopupProps) => {
  if (isDirty) {
    return (
      <Popconfirm
        title="You may have unsaved changes, are you sure you want to exit?"
        onConfirm={goBack}
        okText="Yes"
        cancelText="No"
        placement="bottomLeft"
      >
        <ArrowLeftIcon />
      </Popconfirm>
    )
  }

  return <ArrowLeftIcon onClick={goBack} />
}
