import React from 'react'

import { useHistory } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

import { PageHeader } from 'antd'

import { observer } from 'mobx-react-lite'

import { Page } from 'common/components/Page'
import { isProduction } from 'common/helpers/check_environment'

import { useStores } from 'contractor/hooks/use-stores'

const SubscriptionPricingTable = observer(() => {
  const { userStore } = useStores()
  const history = useHistory()
  const lastLocation = useLastLocation()

  const goBack = () => {
    if (lastLocation) {
      history.push(lastLocation)
    } else {
      history.push('/projects')
    }
  }

  let pricingTableId = ''
  let publishKey = ''
  if (isProduction()) {
    pricingTableId = 'prctbl_1MQw1PCY6fdlurcuz9Ete5Wm'
    publishKey =
      'pk_live_51LDXWhCY6fdlurcud3l8V49K4IBazY8ZWSm4IbrkbA9rgzJGxjIhmpjbV2PmqrpoQ21uccJtS9WQHJibZeq2vRbV00OrTTUTBA'
  } else {
    pricingTableId = 'prctbl_1MR3e9CY6fdlurcuQaKT7bm3'
    publishKey =
      'pk_test_51LDXWhCY6fdlurcuHHpSjV6rzGVwfQkCoCE4udsI9CCtmPPwHvNkPXmpDpf2VPz5JM74Hy6H2qCtwAjitbHcOjOS00Yf2OK5HQ'
  }

  return (
    <Page>
      <Page.Header>
        <PageHeader onBack={() => goBack()} title="Subscription Options" style={{ padding: '0', width: '100%' }} />
      </Page.Header>
      <Page.Content>
        {/* Unfortunately stripe doesn't let you pass a redirect URL for the checkout session to send users back, you need to configure that 
          in the Pricing Table configuration page on a per product basis
        */}
        <stripe-pricing-table
          pricing-table-id={pricingTableId}
          publishable-key={publishKey}
          // Pass the company ID so that when the webhook comes, we can identify
          client-reference-id={userStore.currentUser.company_id}
          customer-email={userStore.currentUser.email}
        />
      </Page.Content>
    </Page>
  )
})

export default SubscriptionPricingTable
