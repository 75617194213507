import React, { useState } from 'react'

import _ from 'lodash'

import { Button, message, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import { Visibility } from 'common/components/Visibility'

import { useStores } from 'contractor/hooks/use-stores'
import { LockSettings } from 'contractor/pages/CompanySettings/Configuration/lock_settings'
import { OrderFieldsSettings } from 'contractor/pages/CompanySettings/Configuration/order_fields_settings'

import ColumnsTable from './columns_table'
import { EmailSettings } from './email_settings'
import { RequiredFieldsSettings } from './required_fields_settings'

const Columns = observer(() => {
  const { companySettingStore, userStore } = useStores()

  const [isSubmitting, setSubmitting] = useState(false)
  const [emailSettingMap, setEmailSettingMap] = useState({})
  const [orderFieldsMap, setOrderFieldsMap] = useState({})
  const [requiredFieldsMap, setRequiredFieldsMap] = useState({})
  const [lockOrderSettingMap, setLockOrderSettingMap] = useState({})

  const handleSaveMaterialConfig = () => {
    return companySettingStore.updateCompanyMaterialConfiguration()
  }

  const handleSaveCustomConfig = () => {
    return companySettingStore.updateOtherSettings({
      ...companySettingStore.otherSettings,
      email_settings: {
        include_pdf_attachments: emailSettingMap['include_pdf_attachments'],
      },
      lock_order_settings: {
        lock_order_rule: lockOrderSettingMap['lock_order_rule'],
      },
      order_fields_settings: {
        address_to: orderFieldsMap['address_to'],
      },
      required_order_fields: {
        unit: requiredFieldsMap['unit_order'],
        quantity: requiredFieldsMap['quantity_order'],
        unit_cost: requiredFieldsMap['unit_cost_order'],
        cost_code: requiredFieldsMap['cost_code_order'],
        cost_code_phase: requiredFieldsMap['cost_code_phase_order'],
        tax_value: requiredFieldsMap['tax_value_order'],
      },
      required_quote_fields: {
        unit: requiredFieldsMap['unit_quote'],
        quantity: requiredFieldsMap['quantity_quote'],
        cost_code: requiredFieldsMap['cost_code_quote'],
        cost_code_phase: requiredFieldsMap['cost_code_phase_quote'],
      },
      required_request_fields: {
        unit: requiredFieldsMap['unit_request'],
        quantity: requiredFieldsMap['quantity_request'],
        cost_code: requiredFieldsMap['cost_code_request'],
        cost_code_phase: requiredFieldsMap['cost_code_phase_request'],
      },
    })
  }

  const handleSave = async () => {
    setSubmitting(true)
    try {
      await Promise.all([handleSaveMaterialConfig(), handleSaveCustomConfig()])
      message.success('Successfully changed settings')
    } catch (error) {
      message.error(`Unable to save changes ${_.get(error, 'response.data.error', '')}`, 3)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <Page.Header>
        <FlexBoxY alignItems="flex-start">
          <FlexBoxX justifyContent="space-between" width="100%">
            <Typography.Title level={4} style={{ margin: 0 }}>
              Order Configuration
            </Typography.Title>

            <Button type="primary" onClick={() => handleSave()} loading={isSubmitting}>
              Save Changes
            </Button>
          </FlexBoxX>
        </FlexBoxY>
      </Page.Header>

      <Page.Content>
        <OrderFieldsSettings onChange={setOrderFieldsMap} />
        <Typography.Title level={5}>Line Item Configuration</Typography.Title>
        <Visibility.Hidden>
          <Typography.Paragraph style={{ margin: 0 }}>
            Configure the columns you want in your material database. These will also show up on your order page. If you
            have another column you would like to see please reach out to support.
          </Typography.Paragraph>
        </Visibility.Hidden>
        <ColumnsTable />
        {userStore.canUseRequiredFieldsSettings && <RequiredFieldsSettings onChange={setRequiredFieldsMap} />}
        <LockSettings onChange={setLockOrderSettingMap} />
        <EmailSettings onChange={setEmailSettingMap} />
      </Page.Content>
    </>
  )
})

export default Columns
