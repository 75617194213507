import React, { CSSProperties } from 'react'

import { useTheme } from '@emotion/react'

import { DeleteOutlined, DownloadOutlined, FilePdfOutlined } from '@ant-design/icons'
import { Button, Tooltip, Typography, UploadFile } from 'antd'

import { Box } from 'common/components/boxes'
import { Colors } from 'common/styles/theme/colors'

type UploadItemProps = {
  file: UploadFile
  onRemove?: (file: UploadFile) => void
  onDownload: (file: UploadFile) => void
  color?: keyof Colors
  style?: CSSProperties
  size?: 'small' | 'medium'
}

const formatFileSize = (sizeInBytes: number) => {
  if (sizeInBytes < 1024 * 1024) {
    return `${Math.round(sizeInBytes / 1024)} KB`
  } else {
    return `${(sizeInBytes / 1024 / 1024).toFixed(2)} MB`
  }
}

export const CustomUploadItem = (props: UploadItemProps) => {
  const { color = 'info', file, onRemove, onDownload, size = 'medium', style } = props
  const theme = useTheme()

  return (
    <Box
      className="upload-item"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: size === 'small' ? '0px 0px 0px 8px' : '4px 8px 4px 16px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.colors[color],
        borderRadius: '4px',
        marginTop: '8px',
        backgroundColor: '#fff',
        ...style,
      }}
    >
      <FilePdfOutlined style={{ marginRight: '8px' }} />
      <Box display="flex" alignItems="center" flex={1} overflow="hidden">
        <Tooltip title={file.name}>
          <Typography.Text ellipsis>{file.name}</Typography.Text>
        </Tooltip>
      </Box>
      <Typography.Text style={{ marginRight: '16px', marginLeft: '16px', color: 'rgba(0, 0, 0, 0.45)' }}>
        {formatFileSize(file.size || file.originFileObj?.size)}
      </Typography.Text>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title="Download">
          <Button style={{ fontSize: 12 }} type="link" icon={<DownloadOutlined />} onClick={() => onDownload(file)} />
        </Tooltip>
        {onRemove && (
          <Tooltip title="Remove">
            <Button
              style={{ fontSize: 12 }}
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => onRemove(file)}
            />
          </Tooltip>
        )}
      </Box>
    </Box>
  )
}
