import React from 'react'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { useMediaQuery } from 'common/hooks/use-media-query'

import { InvoicesContent } from 'contractor/components/@v2/Invoices/invoices_content'
import { useStores } from 'contractor/hooks/use-stores'

export const Invoices = observer(() => {
  const { orderStore } = useStores()
  const isMobile = useMediaQuery('md')

  const order = orderStore.selectedOrder
  const { invoices = [] } = orderStore.selectedOrder

  return (
    <Box mx={{ _: 24, md: 0 }} my={{ _: 28, md: 0 }} pb={{ _: 112, md: 0 }}>
      <InvoicesContent
        invoices={invoices}
        grandTotal={order?.grand_total}
        totalInvoicedAmount={order?.total_invoiced_amount}
        remaining={Number(order?.grand_total) - Number(order?.total_invoiced_amount)}
        renderMobile={isMobile}
      />
    </Box>
  )
})
