import { useState } from 'react'

import { SYNC_ORDER_WITH_INTEGRATION_LOCAL_KEY } from 'contractor/constants'

import { useMediaQuery } from 'common/hooks/use-media-query'
import { OrderStates, QuoteExtractionStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

import { UseOrderPermissionsType } from './useOrderPermissions'

export type UseOrderStatesType = {
  cancelOtherPackageOrders: boolean
  countActionClicked: number
  isRFQ: boolean
  isMobileScreen: boolean
  isSpreadsheetMode: boolean
  isSubmitting: boolean
  purchaseOrderModalVisible: boolean
  quoteFullyMatched: boolean
  setCancelOtherPackageOrders: (value: boolean) => void
  setCountActionClicked: (value: number) => void
  setPurchaseOrderModalVisible: (value: boolean) => void
  setShowQuoteDrawer: (value: boolean) => void
  setSpreadsheetMode: (value: boolean) => void
  setSubmitting: (value: boolean) => void
  setSyncOrderWithIntegration: (value: boolean) => void
  showQuoteAlert: boolean
  showQuoteDrawer: boolean
  syncOrderWithIntegration: boolean
}

export const useOrderStates = (permissions: UseOrderPermissionsType): UseOrderStatesType => {
  const { orderStore, quoteStore } = useStores()

  const [countActionClicked, setCountActionClicked] = useState(-1)
  const [isSpreadsheetMode, setSpreadsheetMode] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [purchaseOrderModalVisible, setPurchaseOrderModalVisible] = useState(false)
  const [syncOrderWithIntegration, setSyncOrderWithIntegration] = useState(
    localStorage.getItem(SYNC_ORDER_WITH_INTEGRATION_LOCAL_KEY) === 'true',
  )
  const [showQuoteDrawer, setShowQuoteDrawer] = useState(false)
  const [cancelOtherPackageOrders, setCancelOtherPackageOrders] = useState<boolean>(true)

  const isMobileScreen = useMediaQuery('md')
  const isRFQ = orderStore?.selectedOrder?.state === OrderStates.QUOTED

  const quoteFullyMatched = orderStore?.selectedOrder?.quote_extraction?.quote_fully_matched
  const showQuoteAlert =
    permissions.rfqExtractionEnabled &&
    orderStore?.selectedOrder?.state === OrderStates.QUOTED &&
    orderStore?.selectedOrder?.quote?.url &&
    quoteStore?.quote?.status !== QuoteExtractionStates.EXTRACTING

  return {
    cancelOtherPackageOrders,
    countActionClicked,
    isRFQ,
    isMobileScreen,
    isSpreadsheetMode,
    isSubmitting,
    purchaseOrderModalVisible,
    quoteFullyMatched,
    setCancelOtherPackageOrders,
    setCountActionClicked,
    setPurchaseOrderModalVisible,
    setShowQuoteDrawer,
    setSpreadsheetMode,
    setSubmitting,
    setSyncOrderWithIntegration,
    showQuoteAlert,
    showQuoteDrawer,
    syncOrderWithIntegration,
  }
}
