import { useHistory } from 'react-router-dom'

import { OrderStates, OrderSubStates } from 'common/server/server_types'

type PushParams = {
  state?: OrderStates
  subState?: OrderSubStates
  orderId?: string
  orderPackageId?: string
  isNewTab?: boolean
  basePath?: string
  privateFormUrl?: string
}

export const usePushToOrder = () => {
  const history = useHistory()

  const getUrl = ({
    orderId,
    orderPackageId,
    state,
    subState,
    basePath = '',
    privateFormUrl = '',
  }: Omit<PushParams, 'isNewTab'>) => {
    const isDeleted = [OrderSubStates.DELETED_DRAFT, OrderSubStates.DELETED_REQUEST].includes(subState)

    if ([OrderStates.DRAFT, OrderStates.REQUESTED, OrderStates.APPROVED].includes(state) || isDeleted) {
      const type = [OrderSubStates.DRAFT_QUOTE, OrderSubStates.REQUESTED_QUOTE].includes(subState)
        ? 'new_quote'
        : 'new_order'
      return `${basePath}/orders/${type}?order_package_id=${orderPackageId}`
    }

    if (state === OrderStates.DRAFTED_REQUEST) {
      return `${basePath}/new_order/${privateFormUrl}?order_package_id=${orderPackageId}`
    }

    return `${basePath}/order/${orderId}`
  }

  const push = ({ isNewTab, ...props }: PushParams) => {
    const path = getUrl(props)
    isNewTab ? window.open(path, '_blank') : history.push(path)
  }

  return { push, getUrl }
}
