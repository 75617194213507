import React from 'react'

import { Button } from 'antd'
import { FormInstance } from 'antd/lib/form'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX } from 'common/components/boxes'
import { OrderMaterial } from 'common/server/orders'
import { OrderStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { PlaceOrderButton } from 'contractor/pages/NewQuote/Actions/place_order_button'
import { SaveDraftButton } from 'contractor/pages/NewQuote/Actions/save_draft_button'
import { ShowRFQButton } from 'contractor/pages/NewQuote/Actions/send_quote_button'

import { useNewQuote } from '../context'

export type ActionsProps = {
  orderType: OrderType
  orderState: OrderStates
  isLoading: boolean
  isRequest?: boolean
  isDeleted?: boolean
  disabled?: boolean
  showPurchaseOrderModal: () => void
  showTermsAndConditionsModal: () => void
  showOrderPackageNameModal: () => void
  form: FormInstance
  onSaveDraftAndApprove: () => Promise<void>
  onSaveDraft: (data: {
    orderMaterials?: OrderMaterial[]
    isBack?: boolean
    isShowNotification?: boolean
    throwError?: boolean
    changeSubMitting?: boolean
  }) => Promise<void>
  onSendRfq: () => Promise<void>
  updateDisabled?: boolean
}

export const Actions = observer<ActionsProps>((props) => {
  const {
    orderType,
    orderState,
    showOrderPackageNameModal,
    showPurchaseOrderModal,
    showTermsAndConditionsModal,
    isDeleted,
    isLoading,
    form,
    onSaveDraftAndApprove,
    onSaveDraft,
    onSendRfq,
    isRequest,
    disabled,
    updateDisabled,
  } = props

  const { orderStore, userStore, companySettingStore } = useStores()

  const { companyVendors } = useNewQuote()

  const { otherSettings } = companySettingStore

  const handleCancelSplitting = () => orderStore.endToSplitOrder()

  const handleConfirmSplitting = async () => {
    try {
      await form.validateFields()
      showOrderPackageNameModal()
    } catch (e) {
      console.error(e)
    }
  }

  const handlePlaceOrder = async () => {
    try {
      await form.validateFields()
      showPurchaseOrderModal()
    } catch (e) {
      console.error(e)
    }
  }

  const handleSendRfq = async () => {
    try {
      await form.validateFields()

      if (otherSettings.terms_and_conditions?.length) {
        showTermsAndConditionsModal()
      } else {
        onSendRfq()
      }
    } catch (e) {
      console.error(e)
    }
  }

  const isDraft = orderState == OrderStates.DRAFT || orderState == OrderStates.REQUESTED

  const isApproved = orderState == OrderStates.APPROVED

  const emptyCompanyVendors = !companyVendors.length

  if (isDeleted) return null

  if (orderStore.isSplitting) {
    return (
      <Box display="flex">
        <Button onClick={handleCancelSplitting} loading={isLoading}>
          Cancel
        </Button>
        <Button onClick={handleConfirmSplitting} type="primary" loading={isLoading} style={{ marginLeft: 8 }}>
          Confirm
        </Button>
      </Box>
    )
  }

  if (userStore.cannotSendAndUpdateOrders) {
    return (
      <FlexBoxX alignItems="flex-start">
        <SaveDraftButton
          disabled={disabled}
          isDraft={isDraft}
          onSaveDraftAndApprove={onSaveDraftAndApprove}
          onSaveDraft={onSaveDraft}
          isApproved={isApproved}
          isLoading={isLoading}
          orderState={orderState}
          isRequest={isRequest}
          updateDisabled={updateDisabled}
        />
      </FlexBoxX>
    )
  }

  return (
    <Box display="flex">
      <SaveDraftButton
        disabled={disabled}
        isDraft={isDraft}
        onSaveDraftAndApprove={onSaveDraftAndApprove}
        onSaveDraft={onSaveDraft}
        isApproved={isApproved}
        isLoading={isLoading}
        orderState={orderState}
        isRequest={isRequest}
      />
      <PlaceOrderButton
        handlePlaceOrder={handlePlaceOrder}
        isLoading={isLoading}
        orderType={orderType}
        emptyCompanyVendors={emptyCompanyVendors || disabled}
      />
      <ShowRFQButton
        emptyCompanyVendors={emptyCompanyVendors}
        orderType={orderType}
        isLoading={isLoading}
        onSendRfq={handleSendRfq}
        disabled={disabled}
      />
    </Box>
  )
})
