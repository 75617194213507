import React from 'react'

import ReactAsyncSelect from 'react-select/async'
import AsyncCreatableSelect, { Props as AsyncCreatableProps } from 'react-select/async-creatable'

import { useTheme } from 'common/hooks/use-theme'

import { setTheme } from './set-theme'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AsyncSelectProps<OptionType = any> = AsyncCreatableProps<OptionType>

export const AsyncSelect = (props: AsyncSelectProps) => {
  const theme = useTheme()

  const selectTheme = setTheme(theme)

  if (props.creatable) {
    return <AsyncCreatableSelect {...{ theme: selectTheme, ...props }} />
  }

  return <ReactAsyncSelect {...{ theme: selectTheme, ...props }} />
}
