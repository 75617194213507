import React, { useEffect, useState } from 'react'

import { CloudUploadOutlined } from '@ant-design/icons'
import { Space, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX } from 'common/components/boxes'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { CostCode } from 'common/server/cost_codes/cost_codes'

import { Flatfile } from 'contractor/components/Flatfile'
import { CommonMaterial, MaterialsAutocomplete } from 'contractor/components/MaterialsAutocomplete'
import { useStores } from 'contractor/hooks/use-stores'
import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'
import { ConcreteMaterialRow } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/MixList/concrete_material_row'
import { ConcreteCommitmentMaterial } from 'contractor/server/commitments'

interface CommitmentsMaterialsProps {
  disabled: boolean
}

export const MixList = observer<CommitmentsMaterialsProps>((props: CommitmentsMaterialsProps) => {
  const { companySettingStore, flatfileStore, commitmentStore, userStore, unitsStore, costCodeStore } = useStores()
  const { disabled } = props
  const dataSource = commitmentStore?.selectedCommitment?.commitment_materials || []
  const isMobileScreen = useMediaQuery('md')
  const costCodeSettings = companySettingStore?.otherSettings?.cost_code_settings
  const hideCostCode = companySettingStore?.companyAttributes['cost_code_id']
  const canEditMaterialDatabase = userStore?.canEditMaterialDatabase
  const units = unitsStore?.units
  const { projectId } = useCommitment()

  const [costCodes, setCostCodes] = useState<CostCode[]>([])

  useEffect(() => {
    costCodeStore.costCodeListStore.fetchAllRecords().then(setCostCodes)
  }, [costCodeStore?.costCodeListStore])

  useEffect(() => {
    costCodeStore.costCodePhaseListStore.fetchRecords()
  }, [costCodeStore?.costCodeListStore])

  const setFocusOnInputQuantityLastSelectedMaterial = () => {
    const inputsQuantity = document.querySelectorAll(
      '.input-quantity .ant-input-number-input',
    ) as NodeListOf<HTMLInputElement>

    const inputQuantity = isMobileScreen
      ? inputsQuantity[inputsQuantity.length - 1]
      : inputsQuantity[inputsQuantity.length - 2]
    inputQuantity?.focus()
  }

  const scollToLastSelectedMaterial = () => {
    const mobileRows = document.getElementsByClassName('row-item')
    const desktopRows = document.getElementsByClassName('ant-table-row')
    mobileRows[mobileRows.length - 1]?.scrollIntoView({ behavior: 'smooth' })
    desktopRows[desktopRows.length - 1]?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleSelectMaterial = (commonMaterial: CommonMaterial) => {
    const newMaterial = {
      id: null,
      unit_price: 0,
      cost_code_phase: commonMaterial?.['cost_code_phase'],
      cost_code_phase_id: commonMaterial?.['cost_code_phase']?.['id'],
      cost_code: commonMaterial['cost_code'] as CostCode,
      cost_code_id: commonMaterial['cost_code']?.['id'],
      concrete_company_material: {
        ...commonMaterial,
      },
    } as ConcreteCommitmentMaterial

    commitmentStore.addMaterial(newMaterial)

    setTimeout(() => {
      setFocusOnInputQuantityLastSelectedMaterial()
      scollToLastSelectedMaterial()
    }, 100)
  }

  const materialsAutocompleteProps = {
    onSelect: handleSelectMaterial,
    canCreateNewMaterial: canEditMaterialDatabase,
    disabled: disabled,
    units: units,
  }

  return (
    <Space direction="vertical" style={{ marginTop: 16, width: '100%' }}>
      <FlexBoxX width="100%">
        <Flatfile
          icon={
            <Tooltip title={'Upload materials'}>
              <CloudUploadOutlined />
            </Tooltip>
          }
          openUpload={flatfileStore.openUploadConcreteCommitment}
          buttonProps={{ type: 'default', style: { marginRight: 8 } }}
        />
        <MaterialsAutocomplete {...materialsAutocompleteProps} />
      </FlexBoxX>
      <Box width="100%" pt={8} display="flex" flexDirection="column" gridGap={16}>
        {dataSource?.map((commitmentMaterial) => (
          <ConcreteMaterialRow
            key={commitmentMaterial.id}
            dataSource={commitmentMaterial}
            costCodes={costCodes}
            costCodeSettings={costCodeSettings}
            projectId={projectId}
            onChange={commitmentStore?.updateMaterial}
            units={units}
            disabled={disabled}
            hideCostCode={hideCostCode}
            onRemove={commitmentStore?.removeMaterial}
          />
        ))}
      </Box>
    </Space>
  )
})
