import React from 'react'

import styled from '@emotion/styled'

import { NumberOutlined, WalletOutlined } from '@ant-design/icons'
import { Space, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, BoxProps, FlexBoxX } from 'common/components/boxes'
import Tags from 'common/components/tags'
import { getPONumberTitle } from 'common/helpers/get-po-number-title'
import { useMediaQuery } from 'common/hooks/use-media-query'
import theme from 'common/styles/theme'

import { POHistory } from 'contractor/components/POHistory'
import { WatchersDrawer } from 'contractor/components/WatchersDrawer'
import { makeWatcherOption } from 'contractor/components/WatchersSelect'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

import { Leveling } from '../Leveling'
import { ProjectsDrawer } from './projects_drawer'
import { VendorContactsDrawer } from './vendor_contacts_drawer'

type OrderInfoProps = BoxProps & {
  isPoLocked?: boolean
}

const fontSize = 12
const textColor = theme.colors['gray-8']

const Wrapper = styled(Box)`
  & .ant-typography-edit-content {
    margin-left: 24px !important;
    margin-top: 4px !important;

    @media (max-width: ${({ theme }) => theme.breakpoints[3]}) {
      margin-top: 12px !important;
    }

    textarea {
      font-size: ${fontSize}px !important;
      height: 30px !important;
      min-height: 30px !important;
    }
  }
`

export const OrderInfo = observer<OrderInfoProps>((props) => {
  const { orderStore, userStore, companySettingStore } = useStores()

  const isMobileScreen = useMediaQuery('lg')

  const quoteNumberEnabled = useFlag('quote_number')

  const { watcher_ids, tags, order_number, quote_number, state, commitment_id, commitment } = orderStore.selectedOrder

  const editDisabled = userStore.cannotSendAndUpdateOrders || props.isPoLocked

  const editableOrderNumber = !editDisabled && {
    onChange: (value = '') => orderStore.updateSelectedOrder('order_number', value),
  }

  const editableQuoteNumber = !editDisabled && {
    onChange: (value = '') => orderStore.updateSelectedOrder('quote_number', value),
  }

  const poNumberTitle = getPONumberTitle(state)

  if (isMobileScreen) {
    return (
      <Wrapper bg="white" p={16}>
        <Box mb={24}>
          <Typography.Text type="secondary" style={{ fontSize }}>
            PROJECT
          </Typography.Text>
          <ProjectsDrawer disabled={editDisabled} />
        </Box>

        <Box mb={24}>
          <Typography.Text type="secondary" style={{ fontSize }}>
            VENDOR
          </Typography.Text>
          <VendorContactsDrawer disabled={editDisabled} />
        </Box>

        <Box mb={24} width="fit-content" alignItems="flex-start" display="flex" flexDirection="column">
          <Typography.Text type="secondary" style={{ fontSize }}>
            {poNumberTitle?.toUpperCase()}
          </Typography.Text>
          <FlexBoxX flexGrow={0} py={2} style={{ columnGap: 4 }}>
            <NumberOutlined />
            <Typography.Text
              style={{ marginBottom: '0', whiteSpace: 'break-spaces', fontSize, color: textColor }}
              editable={editableOrderNumber}
            >
              {order_number || poNumberTitle}
            </Typography.Text>
          </FlexBoxX>
        </Box>

        {quoteNumberEnabled && !commitment_id && (
          <Box mb={24} width="fit-content" alignItems="flex-start" display="flex" flexDirection="column">
            <Typography.Text type="secondary" style={{ fontSize }}>
              QUOTE NUMBER
            </Typography.Text>
            <FlexBoxX flexGrow={0} py={2} style={{ columnGap: 4 }}>
              <NumberOutlined />
              <Typography.Text
                style={{ marginBottom: '0', whiteSpace: 'break-spaces', fontSize, color: textColor }}
                editable={editableQuoteNumber}
              >
                {quote_number || 'Quote Number'}
              </Typography.Text>
            </FlexBoxX>
          </Box>
        )}

        {!!commitment_id && (
          <Box mb={24} width="fit-content" alignItems="flex-start" display="flex" flexDirection="column">
            <Typography.Text type="secondary" style={{ fontSize }}>
              COMMITMENT
            </Typography.Text>
            <FlexBoxX flexGrow={0} py={2} style={{ columnGap: 4 }}>
              <WalletOutlined />
              <Typography.Text style={{ marginBottom: '0', whiteSpace: 'break-spaces', fontSize, color: textColor }}>
                {commitment?.name}: {commitment?.number}
              </Typography.Text>
            </FlexBoxX>
          </Box>
        )}

        {userStore.canSendAndUpdateOrders && (
          <Box mb={24}>
            <Typography.Text>Tags</Typography.Text>
            <Tags
              value={tags}
              disabled={editDisabled}
              onChange={(tags) => orderStore.updateSelectedOrder('tags', tags)}
              tags={orderStore.orderTags}
            />
          </Box>
        )}

        <Space>
          {userStore.canSendAndUpdateOrders && (
            <Box width="fit-content">
              <WatchersDrawer
                value={companySettingStore.possibleUsers
                  .filter((user) => watcher_ids?.includes(user.id))
                  .map((user) => makeWatcherOption(user))}
                onChange={(watchers = []) =>
                  orderStore.updateSelectedOrder(
                    'watcher_ids',
                    watchers?.map((watcher) => watcher.value),
                  )
                }
                isDisabled={editDisabled}
                boxProps={{ height: 34, borderColor: 'gray-5' }}
              />
            </Box>
          )}

          <Leveling />
        </Space>
      </Wrapper>
    )
  }

  return (
    <Wrapper
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      style={{ columnGap: 16, rowGap: 2 }}
      px={{ _: 24, xs: 0 }}
      {...props}
    >
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        justifyContent={{ _: 'space-between', xs: 'flex' }}
        style={{ columnGap: 20, rowGap: 2 }}
      >
        <Box>
          <ProjectsDrawer disabled={editDisabled} />
        </Box>

        <Box>
          <VendorContactsDrawer disabled={editDisabled} />
        </Box>

        <POHistory poHistory={orderStore.selectedOrder?.po_history} orderState={orderStore.selectedOrder?.state}>
          <Box>
            <Tooltip title={poNumberTitle} placement="top">
              <FlexBoxX flexGrow={0} py={2} style={{ columnGap: 4 }}>
                <NumberOutlined />
                <Typography.Paragraph
                  style={{ marginBottom: '0', whiteSpace: 'break-spaces', fontSize, color: textColor }}
                  editable={editableOrderNumber}
                >
                  {order_number || poNumberTitle}
                </Typography.Paragraph>
              </FlexBoxX>
            </Tooltip>
          </Box>
        </POHistory>

        {quoteNumberEnabled && !commitment_id && (
          <Box>
            <Tooltip title="Quote number" placement="bottom">
              <FlexBoxX flexGrow={0} py={2} style={{ columnGap: 4 }}>
                <NumberOutlined />
                <Typography.Paragraph
                  style={{ marginBottom: '0', whiteSpace: 'break-spaces', fontSize, color: textColor }}
                  editable={editableQuoteNumber}
                >
                  {quote_number || 'Quote Number'}
                </Typography.Paragraph>
              </FlexBoxX>
            </Tooltip>
          </Box>
        )}

        {!!commitment_id && (
          <Box>
            <Tooltip title="Commitment" placement="bottom">
              <FlexBoxX flexGrow={0} py={2} style={{ columnGap: 4 }}>
                <WalletOutlined />
                <Typography.Paragraph
                  style={{ marginBottom: '0', whiteSpace: 'break-spaces', fontSize, color: textColor }}
                >
                  {commitment?.name}: {commitment?.number}
                </Typography.Paragraph>
              </FlexBoxX>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Wrapper>
  )
})
