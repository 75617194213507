import React from 'react'

import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined'
import { Typography } from 'antd'

import { FlexBoxX, Box } from 'common/components/boxes'

const noAnswer = 'N/A'

const CellItem = ({ prev = '', next = '', minWidth }) => (
  <FlexBoxX justifyContent="flex-start" minWidth={minWidth}>
    <Typography.Text style={{ textDecoration: 'line-through', whiteSpace: 'nowrap' }}>
      {prev ?? noAnswer}
    </Typography.Text>
    <ArrowRightOutlined style={{ margin: '0 8px' }} />
    <Typography.Text style={{ whiteSpace: 'nowrap' }}>{next ?? noAnswer}</Typography.Text>
  </FlexBoxX>
)

export const FallbackCellItem = ({ event, isUnchanged = false, prev, next, unchangedValue, minWidth = 100 }) => {
  if (isUnchanged) {
    return <Box minWidth={minWidth}>{unchangedValue ?? noAnswer}</Box>
  }
  if (event === 'create') {
    return <Box minWidth={minWidth}>{next ?? noAnswer}</Box>
  }

  // Default return event === 'update'
  return <CellItem prev={prev} next={next} minWidth={minWidth} />
}
