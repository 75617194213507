import React from 'react'

import { Button, Space } from 'antd'

import { observer } from 'mobx-react-lite'

import { Visibility } from 'common/components/Visibility'

import { Flatfile } from 'contractor/components/Flatfile'
import { useStores } from 'contractor/hooks/use-stores'

type ActionsProps = {
  onAdd: () => void
  goBackWithRefreshTable: () => void
}

export const Actions = observer<ActionsProps>(({ onAdd, goBackWithRefreshTable }) => {
  const { userStore, flatfileStore } = useStores()

  if (!userStore.canCreateNewMaterial) {
    return null
  }

  return (
    <Space size="middle">
      <Visibility.Hidden breakpoint="md">
        <Flatfile openUpload={flatfileStore.openUploadCompanyMaterials} onSuccess={goBackWithRefreshTable} />
      </Visibility.Hidden>

      <Button type="primary" onClick={onAdd}>
        Add
      </Button>
    </Space>
  )
})
