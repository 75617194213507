import React, { useEffect } from 'react'

import { Divider, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { UploaderList } from 'common/components/@v2/UploaderList'
import { UploaderTitle } from 'common/components/@v2/UploaderTitle'
import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { Card } from 'common/components/Card'
import { OrderStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

type AttachmentsProps = {
  disabled?: boolean
}

export const MATERIAL_REQUEST_FILE_KEY = 'material_request'
export const PURCHASER_FILES_KEY = 'purchaser_files'

const statesToShowMaterialRequestFile = [OrderStates.REQUESTED, OrderStates.APPROVED]

export const Attachments = observer(({ disabled }: AttachmentsProps) => {
  const { orderStore, uploaderStore } = useStores()

  useEffect(() => {
    uploaderStore.resetAllUploads()

    uploaderStore.addExistingFiles(MATERIAL_REQUEST_FILE_KEY, orderStore.selectedOrder?.material_request)
    uploaderStore.addExistingFiles(PURCHASER_FILES_KEY, orderStore.selectedOrder?.purchaser_files)
  }, [orderStore.selectedOrder?.material_request?.url, orderStore.selectedOrder?.purchaser_files])

  if (!uploaderStore.fileList(PURCHASER_FILES_KEY).length) {
    return null
  }

  return (
    <Card>
      <FlexBoxY alignItems="flex-start">
        <FlexBoxX alignItems="flex-start" justifyContent="space-between" width="100%">
          <Typography.Title level={5}>Attachments</Typography.Title>

          <UploaderTitle
            data-cy="uploader"
            disabled={orderStore.isSplitting || disabled}
            multiple={true}
            uploadKey={PURCHASER_FILES_KEY}
            fileList={uploaderStore.fileList(PURCHASER_FILES_KEY)}
            onRemoveUpload={uploaderStore.removeUpload}
            onAddNewUpload={uploaderStore.addNewUpload}
            onResetUploads={uploaderStore.resetUploads}
            onSetUploadError={uploaderStore.setUploadError}
            onUpdateUpload={uploaderStore.updateUpload}
          />
        </FlexBoxX>

        <Divider style={{ marginBottom: 4, marginTop: 4 }} />

        <UploaderList
          multiple={false}
          uploadKey={PURCHASER_FILES_KEY}
          fileList={uploaderStore.fileList(PURCHASER_FILES_KEY)}
        />
      </FlexBoxY>

      {!!orderStore.selectedOrder?.material_request &&
        statesToShowMaterialRequestFile.includes(orderStore.selectedOrder?.state) && (
          <>
            <Divider style={{ marginBottom: 8 }} />

            <UploaderList
              title="Material Request"
              multiple={false}
              disabled
              uploadKey={MATERIAL_REQUEST_FILE_KEY}
              fileList={uploaderStore.fileList(MATERIAL_REQUEST_FILE_KEY)}
            />
          </>
        )}
    </Card>
  )
})
