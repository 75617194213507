import React from 'react'

import { Alert, Button, Form, Input, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'
import { SignInTypes, LandingMethods } from 'common/pages/Landing'
import { passwordConfirmationRules, passwordRules } from 'common/pages/Landing/password_rules'

import { Logo } from './logo'

type ResetPassword = {
  setSignInType: (string) => void
  token: string
  resetPassword: LandingMethods['resetPassword']
}

const ResetPassword = ({ setSignInType, token, resetPassword }: ResetPassword) => {
  const [resetMessage, setResetMessage] = React.useState([])

  const onFinish = async ({ password, password_confirmation }): Promise<void> => {
    try {
      await resetPassword(password, password_confirmation, token)
      window.location.reload()
    } catch (err) {
      setResetMessage([
        'error',
        `Unable to reset password for this email address. Please contact ${SUPPORT_EMAIL_ADDRESS} for further asssistance`,
      ])
    }
  }

  return (
    <Box display="flex" flexDirection="column" width="100%" justifyContent="center" alignItems="flex-start">
      <Logo onClick={() => setSignInType(SignInTypes.SIGN_IN)} />

      <Form style={{ width: '100%' }} onFinish={onFinish}>
        <Form.Item name="password" validateTrigger="onBlur" rules={passwordRules}>
          <Input type="password" placeholder="Password" />
        </Form.Item>

        <Form.Item
          name="password_confirmation"
          validateTrigger="onBlur"
          dependencies={['password']}
          hasFeedback
          rules={passwordConfirmationRules}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item>
          <Box display="flex" flexDirection="column">
            <Button block type="primary" htmlType="submit">
              Reset Password
            </Button>

            <Box display="flex" justifyContent="center" width="100%" mt={8}>
              <Typography.Text>
                Already have an account?{' '}
                <Typography.Link onClick={() => setSignInType(SignInTypes.SIGN_IN)}>Login</Typography.Link>
              </Typography.Text>
            </Box>
          </Box>
        </Form.Item>

        {resetMessage.length > 0 && (
          <Alert style={{ marginBottom: '20px' }} type={resetMessage[0]} message={resetMessage[1]} />
        )}
      </Form>
    </Box>
  )
}

export default ResetPassword
