import React from 'react'

import { Typography, Divider } from 'antd'

import { Box } from 'common/components/boxes'
import { InvoiceMailAttachments } from 'common/components/InvoiceMail/invoice_mail_attachments'
import { formatDateString } from 'common/helpers/formatters'
import { ShowInvoiceMailResponse } from 'common/server/invoice'

const formatEmail = (value) => {
  if (Array.isArray(value)) {
    return value.join(', ')
  }
  return value
}

export type InvoiceMailDetailProps = {
  invoiceMail: ShowInvoiceMailResponse
}

export const InvoiceMailDetail = ({ invoiceMail }: InvoiceMailDetailProps) => (
  <Box display="flex" flexDirection="column">
    <Typography.Title level={5}>{invoiceMail?.subject}</Typography.Title>
    <Typography.Text strong style={{ textTransform: 'uppercase', fontSize: 12 }}>
      {formatEmail(invoiceMail?.from)}
    </Typography.Text>
    <Typography.Text type="secondary" style={{ textTransform: 'uppercase', fontSize: 10 }}>
      {formatDateString(invoiceMail?.created_at)}
    </Typography.Text>

    <Divider style={{ margin: '12px 0' }} />

    <div dangerouslySetInnerHTML={{ __html: invoiceMail?.body }} />

    <Divider style={{ margin: '12px 0' }} />

    <InvoiceMailAttachments attachments={invoiceMail?.mail_attachments} />
  </Box>
)
