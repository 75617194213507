import React from 'react'

import { LocalShipping } from '@styled-icons/material'

import { ShopFilled } from '@ant-design/icons'
import { Radio, Tooltip } from 'antd'
import { RadioGroupProps } from 'antd/lib/radio'

type DeliveryPickUpToggleProps = RadioGroupProps

export const DeliveryPickUpToggle = (props: DeliveryPickUpToggleProps) => {
  return (
    <Radio.Group buttonStyle="solid" style={{ display: 'inline-flex', zIndex: 0 }} {...props}>
      <Radio.Button value={false}>
        <Tooltip title="Delivery">
          <span>
            <LocalShipping size="15" />
          </span>
        </Tooltip>
      </Radio.Button>
      <Radio.Button value={true}>
        <Tooltip title="Pick Up">
          <span>
            <ShopFilled />
          </span>
        </Tooltip>
      </Radio.Button>
    </Radio.Group>
  )
}
