import axios, { AxiosResponse } from 'axios'

export interface ShippingCarrierResponse {
  shipping_carriers: ShippingCarrier[]
}

export interface ShippingCarrier {
  id: string
  name: string
}

export async function index(): Promise<AxiosResponse<ShippingCarrierResponse>> {
  return await axios.get(`/shipping_carriers`)
}
