import React from 'react'

import { ColumnInstance } from 'react-table'

import styled from '@emotion/styled'

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { Switch, Typography, Button } from 'antd'

import { Box } from 'common/components/boxes'

type ColumnSelectionProps = {
  columns: Array<Partial<ColumnInstance>>
  omitColumns?: string[]
}

const ListItem = styled(Box)`
  transition: background 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors['gray-2']};
  }
`

export const ColumnsSelection = ({ columns, omitColumns = [] }: ColumnSelectionProps) => {
  return (
    <Box width="100%">
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" mb={16}>
        <Typography.Title level={5} style={{ margin: 0 }}>
          Columns
        </Typography.Title>
        <Button
          type="link"
          onClick={() => columns.forEach((column) => column.toggleHidden(false))}
          disabled={columns.every((column) => column.isVisible)}
        >
          Show All
        </Button>
      </Box>

      {columns
        .filter((column) => !omitColumns?.includes(column.id))
        .map((column) => {
          return (
            <ListItem
              key={column.id}
              mb={4}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => column.toggleHidden(column.isVisible)}
              px={4}
              borderRadius="sm"
              style={{ cursor: 'pointer' }}
              backgroundColor={column.isVisible ? 'transparent' : 'gray-2'}
            >
              <Typography.Text>{column.Header}</Typography.Text>
              <Switch
                size="small"
                checked={column.isVisible}
                checkedChildren={<EyeOutlined />}
                unCheckedChildren={<EyeInvisibleOutlined />}
              />
            </ListItem>
          )
        })}
    </Box>
  )
}
