import React from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { Divider, Typography, Button } from 'antd'

import { FlexBoxY, Box } from 'common/components/boxes'
import { SearchInput } from 'common/components/SearchInput'

type SalesRepresentativesProps = {
  children: React.ReactNode
  onSearch: (searchTern: string) => void
  hideCreate?: boolean
  onAddNewVendorContact: () => void
}

export const SalesRepresentatives = (props: SalesRepresentativesProps) => {
  const { children, onSearch, hideCreate, onAddNewVendorContact } = props

  return (
    <>
      <Box mx={{ _: 12, xs: 24 }}>
        <SearchInput onSearch={onSearch} placeholder="Search for sales representatives..." style={{ width: '100%' }} />
      </Box>

      <Divider style={{ margin: '16px 0' }} />

      <FlexBoxY width="100%" justifyContent="flex-start" alignItems="stretch" flexGrow={0} px={{ _: 12, xs: 24 }}>
        {children}

        {!hideCreate && (
          <FlexBoxY flexGrow={0} mb="16px">
            <SearchOutlined style={{ fontSize: 45 }} />
            <Typography.Text type="secondary" strong style={{ margin: '12px 0', fontSize: 16 }}>
              Don&apos;t see who you are looking for?
            </Typography.Text>
            <Button type="primary" onClick={onAddNewVendorContact}>
              Add New Vendor Contact
            </Button>
          </FlexBoxY>
        )}
      </FlexBoxY>
    </>
  )
}
