import React from 'react'

import { Alert, Button, Form, Input, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { SignInTypes, LandingMethods } from 'common/pages/Landing'
import { passwordConfirmationRules, passwordRules } from 'common/pages/Landing/password_rules'

import { Logo } from './logo'

type AcceptInviteProps = {
  setSignInType: (string) => void
  token: string
  acceptInvite: LandingMethods['acceptInvite']
}

const AcceptInvite = ({ setSignInType, token, acceptInvite }: AcceptInviteProps) => {
  const [error, setError] = React.useState('')

  const params = new URLSearchParams(location.search)

  const onFinish = async (values): Promise<void> => {
    try {
      await acceptInvite({ invitation_token: token, ...values })
      window.location.reload()
    } catch (err) {
      setError(err?.response?.data?.error || 'Registration error, please contact help@subbase.io')
    }
  }

  return (
    <Box display="flex" flexDirection="column" width="100%" justifyContent="center" alignItems="flex-start">
      <Logo onClick={() => setSignInType(SignInTypes.SIGN_IN)} />

      <Form
        style={{ width: '100%' }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          first_name: params.get('first_name'),
          last_name: params.get('last_name'),
        }}
      >
        <Form.Item
          name="first_name"
          validateTrigger="onBlur"
          rules={[{ required: true, message: 'Please your first name!' }]}
        >
          <Input placeholder="First name" autoFocus />
        </Form.Item>

        <Form.Item
          name="last_name"
          validateTrigger="onBlur"
          rules={[{ required: true, message: 'Please input your last name!' }]}
        >
          <Input placeholder="Last name" />
        </Form.Item>

        <Form.Item name="password" validateTrigger="onBlur" rules={passwordRules}>
          <Input type="password" placeholder="Password" />
        </Form.Item>

        <Form.Item
          name="password_confirmation"
          validateTrigger="onBlur"
          dependencies={['password']}
          hasFeedback
          rules={passwordConfirmationRules}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        {error && <Alert style={{ marginBottom: '20px' }} message={error} type="error" />}

        <Form.Item>
          <Box display="flex" flexDirection="column">
            <Button block type="primary" htmlType="submit">
              Register
            </Button>

            <Box display="flex" justifyContent="center" width="100%" mt={8}>
              <Typography.Text>
                Already have an account?{' '}
                <Typography.Link onClick={() => setSignInType(SignInTypes.SIGN_IN)}>Login</Typography.Link>
              </Typography.Text>
            </Box>
          </Box>
        </Form.Item>
      </Form>
    </Box>
  )
}

export default AcceptInvite
