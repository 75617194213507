import React from 'react'

import moment from 'moment'

import FieldTimeOutlined from '@ant-design/icons/FieldTimeOutlined'
import { Button, DatePicker, Tooltip } from 'antd'
import { DatePickerProps } from 'antd/lib/date-picker'

import { FlexBoxX, BoxProps } from 'common/components/boxes'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { useTheme } from 'common/hooks/use-theme'

type DateTimePickerProps = {
  value?: string
  onChange?: (s: string) => void
  showTimeOption?: boolean
  dataCy?: string
  defaultValue?: moment.Moment
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  wrapperProps?: BoxProps
  pickerRef?: React.RefObject<unknown>
} & Pick<DatePickerProps, 'inputReadOnly' | 'placeholder' | 'disabledDate'>

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  value,
  onChange,
  showTimeOption,
  defaultValue,
  wrapperProps,
  dataCy,
  pickerRef,
  ...props
}) => {
  const [showTime, setShowTime] = React.useState(false)
  const theme = useTheme()

  const isMobileScreen = useMediaQuery('md')

  const onChangeDate = (newValue) => {
    // TODO: Eventually we should use the address's timezone if we want to enable you to request a specific time
    if (!newValue) {
      onChange?.('')
    } else if (showTime) {
      onChange?.(newValue.toISOString())
    } else {
      // Default all times to 12 PM in whatever timezone you are currently in
      onChange?.(newValue.startOf('day').add(12, 'hours').toISOString())
    }
  }

  return (
    <FlexBoxX justifyContent="flex-start" width={showTimeOption ? '180px' : '130px'} {...wrapperProps}>
      <DatePicker
        ref={pickerRef}
        data-cy={dataCy}
        aria-label="date-picker"
        value={value && moment(value)}
        onChange={onChangeDate}
        defaultValue={defaultValue}
        showTime={showTime && { format: 'h', use12Hours: true }}
        format={showTime ? 'YYYY-MM-DD h a' : 'YYYY-MM-DD'}
        style={{ width: '100%' }}
        // Disable show keyboard when is mobile
        inputReadOnly={isMobileScreen}
        aria-autocomplete="none"
        {...props}
      />
      {/* Hard to both have a time option and not, how do we know from last time that you saved a time?
      Need to break it out in the DB */}
      {showTimeOption && (
        <Tooltip title="Show time">
          <Button
            style={{
              background: showTime ? theme.colors.success : 'none',
              marginLeft: '15px',
            }}
            icon={<FieldTimeOutlined />}
            onClick={() => setShowTime(!showTime)}
          />
        </Tooltip>
      )}
    </FlexBoxX>
  )
}

export default DateTimePicker
