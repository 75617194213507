import React, { useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Typography, Button, Modal, Tooltip, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'

import { getErrorMessages } from '../utils'
import { MaterialHistoryItem } from './material_history_item'
import { MaterialHistoryList } from './material_history_list'

const mapPreferredPrice = (vendorPrice) => {
  return {
    id: vendorPrice.id,
    company_vendor_id: vendorPrice.company_vendor.id,
    price: vendorPrice.price,
    order_id: vendorPrice.order.id,
  }
}

type QuoteMaterialHistoryTabProps = {
  editable?: boolean
}

export const QuoteMaterialHistoryTab = observer(({ editable }: QuoteMaterialHistoryTabProps) => {
  const { companyMaterialStore } = useStores()

  const [savePreferredPriceVisible, toggleSavePreferredPriceVisible] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [orderMaterial, setOrderMaterial] = useState(null)

  const companyMaterial = companyMaterialStore.selectedCompanyMaterial
  const { cached_last_quoted_price, cached_average_quoted_price, cached_lowest_quoted_price, quote_materials } =
    companyMaterial

  const handleCancel = () => {
    toggleSavePreferredPriceVisible(false)
    setOrderMaterial(null)
  }

  const maybeUpdatePreferredPrice = (vendorPrice) => {
    const commonData = {
      id: vendorPrice.id,
      company_vendor_id: vendorPrice.company_vendor.id,
    }

    if (vendorPrice.company_vendor?.id === orderMaterial.order.company_vendor.id) {
      return {
        ...commonData,
        order_id: orderMaterial.order.id,
        price: orderMaterial.unit_cost,
      }
    }

    return { ...commonData, price: vendorPrice.price }
  }

  const updateCompanyMaterial = (params) => {
    return companyMaterialStore.update({ ...params, id: companyMaterial?.id })
  }

  const handleUpdatePreferredPrice = async () => {
    if (!orderMaterial || !companyMaterial) return

    setSubmitting(true)

    const { unit_cost, order } = orderMaterial
    const { id: companyVendorId } = order.company_vendor

    const currentCompanyMaterialVendorPrices = companyMaterial?.company_material_vendor_prices || []
    const vendorPriceExists = currentCompanyMaterialVendorPrices.some(
      (vendorPrice) => vendorPrice.company_vendor?.id === companyVendorId,
    )

    try {
      if (vendorPriceExists) {
        await updateCompanyMaterial({
          company_material_vendor_prices_attributes: currentCompanyMaterialVendorPrices.map(maybeUpdatePreferredPrice),
        })
      } else {
        const newCompanyMaterialVendorPrice = {
          price: unit_cost,
          order_id: order.id,
          company_vendor_id: companyVendorId,
        }

        await updateCompanyMaterial({
          company_material_vendor_prices_attributes: [
            ...currentCompanyMaterialVendorPrices.map(mapPreferredPrice),
            newCompanyMaterialVendorPrice,
          ],
        })
      }

      await companyMaterialStore.selectMaterial(companyMaterial.id)

      message.success('Preferred price saved')
      setOrderMaterial(null)
      toggleSavePreferredPriceVisible(false)
    } catch (error) {
      const errorMessage = getErrorMessages(error)
      message.error(errorMessage)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <Modal
        title="Save preferred price"
        okText="Confirm"
        open={savePreferredPriceVisible}
        onCancel={handleCancel}
        onOk={handleUpdatePreferredPrice}
        okButtonProps={{ loading: isSubmitting }}
        cancelButtonProps={{ disabled: isSubmitting }}
      >
        <Typography.Text>
          This price and vendor will be added as preferred for this item in the material database
        </Typography.Text>
      </Modal>
      <Box height="100%" display="flex" flexDirection="column" alignItems="flex-end" style={{ gap: 12 }}>
        <MaterialHistoryItem
          type="order"
          lastPrice={cached_last_quoted_price}
          averagePrice={cached_average_quoted_price}
          lowestPrice={cached_lowest_quoted_price}
        />
        <MaterialHistoryList
          type="quote"
          items={quote_materials}
          extraAction={(record) =>
            editable && (
              <Box>
                <Tooltip title="Add preferred price" placement="topLeft">
                  <Button
                    size="small"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={(event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      setOrderMaterial(record)
                      toggleSavePreferredPriceVisible(true)
                    }}
                  />
                </Tooltip>
              </Box>
            )
          }
        />
      </Box>
    </>
  )
})
