import { BindAll } from 'lodash-decorators'

import { action } from 'mobx'

import { index, InvoiceOtherSettings, update } from 'contractor/server/invoices/invoice_settings'

@BindAll()
export default class InvoiceSettingsStore {
  settings: InvoiceOtherSettings | null = null

  @action
  async indexSettings() {
    const { data } = await index()
    this.settings = data || []
    return this.settings
  }

  async update(invoice_settings: InvoiceOtherSettings) {
    await update(invoice_settings)
    this.settings = invoice_settings
  }
}
