import React, { useEffect } from 'react'

import moment from 'moment'

import styled from '@emotion/styled'

import { CloudUploadOutlined } from '@ant-design/icons'
import { Checkbox, Col, Input, Row, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import DateTimePicker from 'common/components/date_time_picker'
import { SearchInput } from 'common/components/SearchInput'
import Uploader from 'common/components/uploader'
import { CustomUploadItem } from 'common/components/uploader/custom_upload_item'
import { formatDateString, formatName } from 'common/helpers/formatters'
import { OrderDelivery } from 'common/server/deliveries'
import { OrderMaterial } from 'common/server/orders'
import {
  DeliveryFileNameConvention,
  DeliveryIssueTypes,
  DeliveryStatuses,
  OrderStates,
} from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

import { IncompleteItems } from './IncompleteItems/incomplete_items'
import ItemTitle from './item_title'

type ReceivingDetailsProps = {
  delivery: OrderDelivery
  onChange: (item) => void
  updateConfirmDisabled?: (disabled: boolean) => void
  orderState: OrderStates
  disableEditing?: boolean
  orderMaterials: OrderMaterial[]
}

const DELVIERY_FILES_KEY = 'delivery_files'

const UploaderWrapper = styled(Uploader)`
  .ant-upload.ant-upload-select {
    width: 100%;
    margin-bottom: 0;
  }
`

export const ReceivingDetails = observer<ReceivingDetailsProps>(
  ({ delivery, onChange, disableEditing, orderState, orderMaterials, updateConfirmDisabled }) => {
    const {
      delivery_issues,
      delivery_issue_type,
      actual_delivered_at,
      marked_delivered_at,
      marked_delivered_by,
      delivery_issue_resolved_at,
      delivery_issue_resolved_by,
      delivery_files,
    } = delivery

    const { userStore, uploaderStore, companySettingStore } = useStores()
    const [filter, setFilter] = React.useState<string>('')
    const [incompleteItems, setIncompleteItems] = React.useState<OrderMaterial[]>(orderMaterials)
    const dataSource = incompleteItems?.filter(
      (item) => item?.searchable_description?.toLowerCase()?.includes(filter?.toLowerCase()) || item.has_open_issue,
    )
    const [deliveryIssues, setDeliveryIssues] = React.useState(delivery_issues)
    const confirmDisabled =
      delivery_issue_type === DeliveryStatuses.INCOMPLETE_DELIVERY &&
      (incompleteItems.length == 0 || incompleteItems?.filter((item) => !item.resolved).length == 0) &&
      !deliveryIssues &&
      !delivery_issue_resolved_at

    const openIssueMaterials = incompleteItems?.filter((item) => item.has_open_issue)

    useEffect(() => {
      updateConfirmDisabled?.(confirmDisabled)
    }, [updateConfirmDisabled, confirmDisabled])

    React.useEffect(() => {
      uploaderStore.resetUploads(DELVIERY_FILES_KEY)
      uploaderStore.addExistingFiles(DELVIERY_FILES_KEY, delivery_files)
    }, [delivery_files])

    // Assume it's you if it is empty (i.e. you just marked it and we didn't populate the field yet)
    const who_marked_delivered = marked_delivered_by?.first_name
      ? formatName(marked_delivered_by.first_name, marked_delivered_by.last_name)
      : 'you'
    const who_marked_resolved = delivery_issue_resolved_by?.first_name
      ? formatName(delivery_issue_resolved_by.first_name, delivery_issue_resolved_by.last_name)
      : 'you'

    const disableEditFields = [OrderStates.DRAFT, OrderStates.REQUESTED, OrderStates.QUOTED].includes(orderState)

    const handleResolveAllIssues = () => {
      const newIncompleteItems = incompleteItems.map((item) => {
        return {
          ...item,
          resolved: true,
          resolved_at: moment().toISOString(),
        }
      })

      setIncompleteItems(newIncompleteItems)
      onChange({ incomplete_items: newIncompleteItems })
    }

    return (
      <>
        <Typography.Text strong style={{ marginBottom: 12 }}>
          Receiving
        </Typography.Text>

        <Row gutter={[20, 20]}>
          <Col xs={24} sm={12}>
            <FlexBoxX justifyContent="flex-start" alignItems="flex-start">
              <FlexBoxY justifyContent="flex-start" alignItems="flex-start">
                <ItemTitle text="Date" />
                <DateTimePicker
                  data-cy="actual-delivered-at"
                  // For legacy reasons we support both of these, ideally we would make a migration to guarantees
                  // Anything that was marked delivered prior to 12-8-21 was just delivered on that date
                  value={actual_delivered_at || marked_delivered_at}
                  onChange={(actual_delivered_at) => {
                    onChange({
                      actual_delivered_at,
                      marked_delivered_at: actual_delivered_at ? moment().toISOString() : null,
                      marked_delivered_by: actual_delivered_at ? { id: userStore.companyUserId } : null,
                      delivery_issue_type: null,
                    })
                  }}
                  disabled={disableEditFields || disableEditing}
                  wrapperProps={{ width: '100%' }}
                />
                {marked_delivered_at && `Confirmed by ${who_marked_delivered}`}
              </FlexBoxY>
            </FlexBoxX>
          </Col>

          <Col style={{ paddingLeft: 0, paddingRight: 0, display: 'flex', flexDirection: 'column' }} span={24}>
            <SearchInput
              onChange={(search) => setFilter(search?.target?.value)}
              style={{ paddingLeft: 16, paddingRight: 16 }}
            />
            <IncompleteItems
              orderMaterials={dataSource}
              onChange={(incomplete_items) => {
                onChange({ incomplete_items: incomplete_items })
                setIncompleteItems(incomplete_items)
              }}
            />
          </Col>
          <Col style={{ width: '100%' }}>
            <FlexBoxY mb={16} style={{ width: '100%' }} justifyContent="flex-start" alignItems="flex-start">
              <ItemTitle text="Files" />
              <UploaderWrapper
                component="Upload"
                showPreviewModal
                hideUploadButton
                multiple
                itemRender={(_originNode, file, _fileList, actions) => (
                  <CustomUploadItem
                    file={file}
                    onRemove={() => actions.remove(file)}
                    onDownload={() => actions.download(file)}
                  />
                )}
                uploadKey={DELVIERY_FILES_KEY}
                listType="picture"
                showDownload
                noResetUploads
                hasFileNameTimeStamp={
                  companySettingStore.otherSettings?.delivery_file_name_convention ===
                  DeliveryFileNameConvention.CURRENT_DATE_TIME
                }
                fileList={uploaderStore.fileList(DELVIERY_FILES_KEY)}
                onRemoveUpload={uploaderStore.removeUpload}
                onAddNewUpload={uploaderStore.addNewUpload}
                onResetUploads={uploaderStore.resetUploads}
                onSetUploadError={uploaderStore.setUploadError}
                onUpdateUpload={uploaderStore.updateUpload}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{
                    padding: '8px 16px',
                    borderRadius: '4px',
                    borderColor: 'gray-4',
                    cursor: 'pointer',
                    width: '100%',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                  }}
                >
                  Upload file
                  <CloudUploadOutlined />
                </Box>
              </UploaderWrapper>
            </FlexBoxY>
          </Col>
        </Row>

        {openIssueMaterials?.length > 0 && (
          <FlexBoxY justifyContent="flex-start" alignItems="flex-start">
            <ItemTitle
              text={
                delivery_issue_type === DeliveryIssueTypes.MISSING_ITEMS
                  ? 'Additional comments for vendor'
                  : 'Provide details on the problem for your vendor'
              }
            />
            <Input.TextArea
              data-cy="delivery-issues"
              value={delivery_issues}
              placeholder="Describe issues with delivery here"
              onChange={(e) => {
                onChange({ delivery_issues: e.target.value })
                setDeliveryIssues(e.target.value)
              }}
              style={{ resize: 'none' }}
              disabled={disableEditing}
            />

            <FlexBoxX justifyContent="flex-start" alignItems="flex-start" marginTop="15px">
              <Checkbox
                checked={delivery_issue_resolved_at != null}
                disabled={disableEditing}
                onChange={() => {
                  if (!delivery_issue_resolved_at) handleResolveAllIssues()

                  onChange({
                    delivery_issue_resolved_at: delivery_issue_resolved_at ? null : moment().toISOString(),
                    delivery_issue_resolved_by: delivery_issue_resolved_at ? null : { id: userStore.companyUserId },
                  })
                }}
                style={{ marginRight: '10px' }}
              />
              {delivery_issue_resolved_at ? (
                `Issue resolved on ${formatDateString(delivery_issue_resolved_at)} by ${who_marked_resolved}`
              ) : (
                <ItemTitle text="Mark as Resolved" />
              )}
            </FlexBoxX>
          </FlexBoxY>
        )}
      </>
    )
  },
)
export default ReceivingDetails
