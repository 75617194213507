import React from 'react'

import { Typography } from 'antd'

import { Box, FlexBoxY } from 'common/components/boxes'
import { NotificationGroups } from 'common/components/Notifications/notification_group'

export const NotificationsContent = ({ title, notifications, ...props }) => {
  if (notifications.length) {
    return (
      <FlexBoxY mb="8" width="100%" flexGrow={0} alignItems="flex-start">
        <Box
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            width: '100%',
            zIndex: 1,
            padding: '8px 16px',
          }}
        >
          <Typography.Text type="secondary">{title}</Typography.Text>
        </Box>
        <NotificationGroups notificationGroups={notifications} {...props} />
      </FlexBoxY>
    )
  }

  return null
}
