import React from 'react'

import ProjectSelector, { ProjectOption } from 'contractor/components/ProjectSelector/project_selector'
import { ShowProjectResponse, unknownProject } from 'contractor/server/projects'

type InvoiceProjectSelectorProps = {
  onChange?: (project: ShowProjectResponse) => void
  canViewAllInvoices?: boolean
}

const getProjectOptions = (canViewAllInvoices) => {
  const projectOptions = [
    {
      id: 'all',
      name: 'All Projects',
      project_id: null,
      inventory: false,
    },
  ] as ProjectOption[]

  // If the user cannot see all the invoices then he cannot see invoices with no project
  if (canViewAllInvoices) {
    projectOptions.push({
      id: unknownProject.id,
      name: unknownProject.name,
      project_id: null,
      inventory: unknownProject.inventory,
    })
  }

  return projectOptions
}

export const InvoiceProjectSelector = ({ canViewAllInvoices, ...props }: InvoiceProjectSelectorProps) => {
  return <ProjectSelector projectOptions={getProjectOptions(canViewAllInvoices)} {...props} />
}
