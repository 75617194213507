import React from 'react'

import styled from '@emotion/styled'

import { Table, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import { calcExtCost } from 'common/helpers/order'
import { OrderMaterial as IOrderMaterial } from 'common/server/orders'

import { useStores } from 'contractor/hooks/use-stores'

const Wrapper = styled.div`
  .ant-table-summary .ant-table-cell {
    background-color: ${({ theme }) => theme.colors['gray-2']};
  }
`

type OrderMaterialProps = {
  orderMaterials: IOrderMaterial[]
  discountValue?: number
  shippingValue?: number
  otherValue?: number
  taxValue?: number
  deliveryTotalCost?: number
}

export const OrderMaterial = observer<OrderMaterialProps>(
  ({ orderMaterials = [], discountValue, shippingValue, otherValue, taxValue, deliveryTotalCost = 0 }) => {
    const { companySettingStore } = useStores()

    const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

    return (
      <Wrapper>
        <Table<IOrderMaterial>
          rowKey="id"
          columns={[
            { title: 'Description', dataIndex: ['company_material', 'description'] },
            { title: 'Quantity', dataIndex: 'quantity', width: 100 },
            {
              title: 'Unit Price',
              dataIndex: 'unit_cost',
              width: 100,
              render: (value) => <Typography.Text>{currencyFormatter(value)}</Typography.Text>,
            },
            {
              title: 'Ext. Price',
              key: 'ext_price',
              width: 100,
              render: (_, item) => {
                const extendedCost = calcExtCost({
                  unitCost: item?.unit_cost,
                  quantity: item?.quantity,
                  multiplier: item.company_material?.unit?.multiplier,
                  qtyIncrement: item.company_material?.unit?.qty_increment,
                })
                return <Typography.Text>{currencyFormatter(extendedCost, 2)}</Typography.Text>
              },
              align: 'right',
            },
            {
              title: 'Cost Code',
              dataIndex: 'cost_code',
              width: 150,
              render: (costCode) => {
                const canShowPhase =
                  costCodeSettings?.phase_code_enabled &&
                  !costCodeSettings.independent_phase_codes_enabled &&
                  !!costCode
                const canShowClass = costCodeSettings?.class_enabled && !!costCode
                return (
                  <Box>
                    {[
                      costCode?.code,
                      canShowPhase ? costCode?.phase_code || 'N/A' : '',
                      canShowClass ? costCode?.clazz || 'N/A' : '',
                    ]
                      .filter((item) => !!item)
                      .join(' / ')}
                  </Box>
                )
              },
            },
          ]}
          dataSource={orderMaterials}
          pagination={false}
          size="small"
          summary={() => {
            return (
              <>
                {discountValue && (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={3}>
                      Discount
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right">
                      {currencyFormatter(discountValue, 2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} />
                  </Table.Summary.Row>
                )}
                {shippingValue && (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={3}>
                      Shipping
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right">
                      {currencyFormatter(shippingValue, 2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} />
                  </Table.Summary.Row>
                )}
                {otherValue && (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={3}>
                      Other
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right">
                      {currencyFormatter(otherValue, 2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} />
                  </Table.Summary.Row>
                )}
                {taxValue && (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={3}>
                      Tax
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right">
                      {currencyFormatter(taxValue, 2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} />
                  </Table.Summary.Row>
                )}
                <Table.Summary.Row>
                  <Table.Summary.Cell className="strong" index={0} colSpan={3}>
                    <Typography.Text strong>Delivery Total</Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={1} align="right">
                    <Typography.Text>{currencyFormatter(deliveryTotalCost, 2)}</Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} colSpan={1} />
                </Table.Summary.Row>
              </>
            )
          }}
        />
      </Wrapper>
    )
  },
)
