import axios from 'axios'

import {
  InvoiceInboxFilesStates,
  InvoiceInboxStates,
  InvoiceInboxSubStates,
  InvoiceSourceType,
  StandardIndexRequest,
  StandardIndexResponse,
} from 'common/server/server_types'

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ConsolidatedInvoiceInboxes {
  export interface InvoiceInbox {
    id: string
    created_at: string
    from: string
    uploaded_by: string
    subject: string
    files: Array<File>
    fail_reason: string
    auto_ignored_reason: string
    sub_state: InvoiceInboxSubStates
    state: InvoiceInboxStates
    invoice_count: number
    invoice_inbox_id: string
    invoice_inbox_created_at: string
    invoice_source_type: InvoiceSourceType
    invoice_source_id: string
  }

  interface Filters {
    search?: string
    from?: string
    uploaded_by?: string
    origin?: string
    created_at_start?: string
    created_at_end?: string
  }

  export interface File {
    id: string
    url: string
    filename: string
    state: InvoiceInboxFilesStates
    fail_message?: string
    auto_ignored_message?: string
    invoice_flow_id: string
    extras?: {
      ignored_pages?: string
      invoice_count?: number
      invoices?: Array<{
        id: string
        number: string
        invoice_flow_id: string
      }>
    }
  }

  export type IndexParams = StandardIndexRequest & {
    filters: Filters
  }

  export type IndexResponse = StandardIndexResponse<InvoiceInbox> & {
    // @todo: [ConsolidatedInvoiceInboxes] -> type state counts
  }

  export interface FacetsResponse {
    [facetName: string]: string[]
  }
}

async function index(request: ConsolidatedInvoiceInboxes.IndexParams) {
  const { filters = {}, ...restRequest } = request

  const url = `/consolidated_invoice_inboxes`

  return axios.get<ConsolidatedInvoiceInboxes.IndexResponse>(url, {
    params: {
      ...filters,
      ...restRequest,
    },
  })
}

async function show(consolidatedInvoiceId: string) {
  const url = `/consolidated_invoice_inboxes/${consolidatedInvoiceId}`
  return axios.get<ConsolidatedInvoiceInboxes.IndexResponse>(url)
}

function facets() {
  const url = `/consolidated_invoice_inboxes/facets`

  return axios.get<ConsolidatedInvoiceInboxes.FacetsResponse>(url)
}

export const consolidatedInvoiceInboxesRequests = {
  index,
  facets,
  show,
}
