import React, { useCallback } from 'react'

import { observer } from 'mobx-react-lite'

import { DebounceSelectProps } from 'common/components/DebounceSelect'

import {
  DEFAULT_PAGE_SIZE,
  SelectExternalMappings,
  ValueType,
} from 'contractor/components/SelectExternalMappings/select_external_mappings'
import { useStores } from 'contractor/hooks/use-stores'
import { ListParams } from 'contractor/server/integrations'

type SelectAgaveProjectsProps = {
  canDisableSelected?: boolean
  selected?: ValueType[]
} & Partial<DebounceSelectProps>

export const SelectAgaveProjects = observer<SelectAgaveProjectsProps>(({ canDisableSelected, selected, ...props }) => {
  const { integrationStore } = useStores()

  const fetchOptions = useCallback(
    (search?: string, page = 1, per_page = DEFAULT_PAGE_SIZE) => {
      const params: ListParams = { search, page, per_page }
      return integrationStore.getProjectsRelationships(params, true)
    },
    [canDisableSelected],
  )

  return (
    <SelectExternalMappings
      selected={selected}
      // @ts-ignore (this one too, cant make callback options match the fetchOptions with unknown type)
      fetchOptions={fetchOptions}
      enabledMultiMapping={integrationStore.enabledProjectsMultiMapping()}
      {...props}
    />
  )
})
