import React from 'react'

import { Box } from 'common/components/boxes'

import { ShowOrderResponse } from 'contractor/server/orders'

import { InvoiceTableItems } from './invoice_table_items'
import { InvoiceTableItemsMobile } from './invoice_table_items_mobile'
import { InvoiceTotalValues } from './invoice_total_values'
import { InvoiceTotalValuesMobile } from './invoice_total_values_mobile'

export type InvoiceContentProps = {
  invoices: ShowOrderResponse['invoices']
  grandTotal?: string
  totalInvoicedAmount?: number
  remaining?: number
  showRemaining?: boolean
  showTotalInvoicedAmount?: boolean
  renderMobile?: boolean
}

export const InvoicesContent = (props: InvoiceContentProps) => {
  const { invoices, grandTotal, totalInvoicedAmount, remaining, renderMobile } = props

  if (renderMobile) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        overflowY="auto"
        marginRight="0"
        height="100%"
        style={{ gap: 16 }}
        justifyContent="space-between"
      >
        <InvoiceTableItemsMobile invoices={invoices} />

        <InvoiceTotalValuesMobile
          grandTotal={grandTotal}
          totalInvoicedAmount={totalInvoicedAmount}
          remaining={remaining}
          invoicesCount={invoices?.length}
        />
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      overflowY="auto"
      marginRight="0"
      height="100%"
      style={{ gap: 16 }}
      justifyContent="space-between"
    >
      <InvoiceTableItems invoices={invoices} />

      <InvoiceTotalValues
        grandTotal={grandTotal}
        totalInvoicedAmount={totalInvoicedAmount}
        remaining={remaining}
        invoicesCount={invoices?.length}
      />
    </Box>
  )
}
