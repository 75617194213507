import React from 'react'

import { InputNumber } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'

import { SelectType } from 'common/components/InputCurrencyPercentage/Delivery/select_type'
import { PopoverInfo } from 'common/components/InputCurrencyPercentage/popover_info'
import { DeliveryExtraValues } from 'common/components/OrderMaterialsSpreadsheet'
import { inputCurrencyFormatter, inputCurrencyParser } from 'common/helpers/formatters'
import { DeliveryChargesUnit } from 'common/server/deliveries'

type InputCurrencyPercentageProps = {
  inputName: DeliveryExtraValues
  totalCost: number
  roundingPrecision: number
  deliveryChargesUnits?: DeliveryChargesUnit
  onChangeChargesUnit?: (value: DeliveryChargesUnit) => void
} & InputNumberProps

const PERCENTAGE_BASE = 100
const DEFAULT_DELIVERY_UNIT = 'decimal'

export const InputDeliveryPercentage: React.FC<InputCurrencyPercentageProps> = ({
  totalCost,
  roundingPrecision,
  deliveryChargesUnits,
  onChangeChargesUnit,
  inputName,
  value,
  onChange,
  style,
  ...restProps
}) => {
  const selectedOption = deliveryChargesUnits?.[inputName] || DEFAULT_DELIVERY_UNIT
  const isPercentage = selectedOption === 'percentage'
  const showCurrencyAmount = selectedOption !== 'decimal'

  const getPercentageValue = (percentage: number) => {
    return (percentage / PERCENTAGE_BASE) * totalCost
  }

  const getReversePercentage = (absolute?: number) => {
    if (!absolute || totalCost === 0) return 0
    return (absolute / totalCost) * PERCENTAGE_BASE
  }

  const fieldValue = React.useMemo(() => {
    const absoluteValue = Number(value)
    const percentageValue = getReversePercentage(absoluteValue)
    const roundedPercentage = Number(percentageValue.toFixed(roundingPrecision))
    const roundedAbsolute = Number(absoluteValue.toFixed(roundingPrecision))

    return {
      percentage: roundedPercentage,
      absolute: roundedAbsolute,
      display: isPercentage ? roundedPercentage : roundedAbsolute,
    }
  }, [value, totalCost, roundingPrecision, isPercentage])

  const handleInputChange = (newValue: number | null) => {
    if (newValue === null || !onChange) return
    const calculatedValue = isPercentage ? getPercentageValue(newValue) : newValue
    onChange(calculatedValue)
  }

  const handleChangeChargeUnit = (unit: string) => {
    onChangeChargesUnit?.({ ...deliveryChargesUnits, [inputName]: unit })
    if (unit === 'percentage') {
      const percentage = getPercentageValue(fieldValue.percentage)
      const roundedPercentage = Number(percentage.toFixed(roundingPrecision))
      if (fieldValue.percentage !== roundedPercentage) {
        onChange?.(percentage)
      }
    }
  }

  return (
    <InputNumber
      formatter={(val) => inputCurrencyFormatter(val, false)}
      parser={inputCurrencyParser}
      prefix={inputName === DeliveryExtraValues.Discount ? '-' : '+'}
      inputMode={isPercentage ? 'numeric' : 'decimal'}
      addonBefore={
        <SelectType selectedOption={selectedOption} handleSelectChange={handleChangeChargeUnit} inputName={inputName} />
      }
      addonAfter={
        showCurrencyAmount && <PopoverInfo inputValue={fieldValue.absolute} roundingPrecision={roundingPrecision} />
      }
      aria-autocomplete="none"
      {...restProps}
      value={fieldValue.display}
      style={{ width: '100%', ...style }}
      onChange={handleInputChange}
    />
  )
}
