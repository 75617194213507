import React, { useEffect, useState } from 'react'

import { ProjectOutlined } from '@ant-design/icons'
import { Form, Tooltip, Typography } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'
import { CustomEditableContent } from 'common/components/CustomEditableContent'
import { useQuery } from 'common/hooks/use-query'
import { InvoiceProject as InvoiceProjectResponse } from 'common/server/invoice'

import { SelectProject } from 'contractor/components/SelectProject'
import { useStores } from 'contractor/hooks/use-stores'
import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'
import { SimpleProject } from 'contractor/server/orders'
import { IndexProject } from 'contractor/server/projects'

const makeProjectOption = (project: IndexProject | InvoiceProjectResponse | SimpleProject | null) => {
  return project ? { label: project.name, value: project.id } : { label: 'Unknown', value: null }
}

interface CommitmentProjectProps {
  disabled?: boolean
}

export const CommitmentProject = (props: CommitmentProjectProps) => {
  const { projectStore } = useStores()
  const { disabled } = props
  const { isLoading } = useQuery(projectStore.maybeIndexProjects)
  const { projectId, form, setDirty, commitment } = useCommitment()
  const [project, setProject] = useState(projectStore.projects.find((project) => project.id === projectId))
  const source = window.location.pathname

  useEffect(() => {
    const newProject = projectStore.projects.find((project) => project.id === projectId)
    setProject(newProject)
  }, [projectId, projectStore?.projects])

  const onProjectChange = (projectId: string | null) => {
    setProject(projectStore.projects.find((project) => project.id === projectId))
  }

  const onProjectClear = () => {
    setProject(null)
  }

  useEffect(() => {
    if (!project) return

    form.setFieldsValue({ projectId: project?.id })
    setDirty(true)
  }, [project])

  if (isLoading) {
    return null
  }

  if (disabled) {
    return (
      <Tooltip title="Project" placement="bottom">
        <Box style={{ gap: 8 }} display="inline-flex" alignItems="center">
          <ProjectOutlined />
          <Typography.Text type="secondary">{project?.name || 'Select project'}</Typography.Text>
        </Box>
      </Tooltip>
    )
  }

  return (
    <CustomEditableContent
      showRequired={!commitment?.id}
      boxProps={{ mt: 0 }}
      input={
        <Tooltip title="Project" placement="bottom">
          <Box
            onClick={() => trackEvent(Events.CLICK_PROJECT, source)}
            style={{ gap: 8 }}
            display="inline-flex"
            alignItems="center"
          >
            <Form.Item style={{ marginBottom: 0 }} required name="projectId">
              <SelectProject
                aria-label="commitment-project-selector"
                value={makeProjectOption(project)}
                onSelect={onProjectChange}
                onClear={onProjectClear}
                style={{ width: '100%', minWidth: 200 }}
              />
            </Form.Item>
          </Box>
        </Tooltip>
      }
    >
      <Tooltip title="Project" placement="bottom">
        <Box aria-label="commitment-project" style={{ gap: 8 }} display="inline-flex" alignItems="center">
          <ProjectOutlined />
          <Typography.Text type="secondary">{project?.name || 'Select project'}</Typography.Text>
        </Box>
      </Tooltip>
    </CustomEditableContent>
  )
}
