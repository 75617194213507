import React, { useState } from 'react'

import { UploadOutlined } from '@ant-design/icons'
import { Button, Modal, Tooltip, Typography, Upload } from 'antd'
import { UploadProps } from 'antd/lib/upload'

import { Box, FlexBoxX } from 'common/components/boxes'
import Uploader, { FileControl } from 'common/components/uploader'

type UploaderProps = FileControl &
  Pick<UploadProps, 'onPreview'> & {
    accept?: string
    disabled?: boolean
    multiple: boolean
    onChange?: () => void
    title?: string
    extra?: React.ReactNode
    uploadButtonText?: string
    tag?: React.ReactNode
    type?: 'icon' | 'text'
  }

const UploaderModal = ({
  accept,
  disabled,
  multiple,
  onChange,
  title,
  fileList,
  uploadKey,
  onUpdateUpload,
  onRemoveUpload,
  onResetUploads,
  onAddNewUpload,
  onSetUploadError,
  onPreview,
  extra,
  uploadButtonText = '',
  tag,
  type = 'icon',
  ...props
}: UploaderProps) => {
  const typeIsText = type === 'text'

  const [modalVisible, setModalVisibility] = useState(false)

  const handlePreviw = (file) => {
    window.open(file?.url)
    onPreview?.(file)
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" width="100%">
        <FlexBoxX display="flex" alignItems="start" width="100%">
          <Box display="flex" justifyContent="flex-start">
            {title && (
              <Typography.Text style={{ whiteSpace: 'nowrap' }} strong>
                {title}
              </Typography.Text>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end" width="100%">
            {tag}
          </Box>
        </FlexBoxX>
        <Box display="flex">
          {!disabled && (
            <Tooltip title="Add attachment">
              <Button
                data-cy={`upload-${uploadButtonText}-button`}
                size="small"
                onClick={() => setModalVisibility(true)}
                icon={<UploadOutlined />}
                type={typeIsText ? 'default' : 'text'}
              >
                {typeIsText && uploadButtonText}
              </Button>
            </Tooltip>
          )}
          {extra}
        </Box>
      </Box>

      <Upload
        disabled={disabled}
        multiple={multiple}
        fileList={fileList}
        onChange={onChange}
        onRemove={(file) => {
          onRemoveUpload?.(uploadKey, file.uid)
        }}
        onPreview={handlePreviw}
      />

      <Modal
        title={`Attach ${title}`}
        open={!disabled && modalVisible}
        onCancel={() => setModalVisibility(false)}
        onOk={() => setModalVisibility(false)}
        destroyOnClose
      >
        <Uploader
          disabled={disabled}
          multiple={multiple}
          onChange={onChange}
          uploadKey={uploadKey}
          noResetUploads
          accept={accept}
          onUpdateUpload={onUpdateUpload}
          onResetUploads={onResetUploads}
          onAddNewUpload={onAddNewUpload}
          onSetUploadError={onSetUploadError}
          onRemoveUpload={onRemoveUpload}
          fileList={fileList}
          onPreview={handlePreviw}
          {...props}
        />
      </Modal>
    </Box>
  )
}

export default UploaderModal
