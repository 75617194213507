import React from 'react'

import { flatten } from 'lodash'

import { ReFetchDebounceSelect, ReFetchDebounceSelectProps, ValueType } from 'common/components/DebounceSelect'

import { useStores } from 'contractor/hooks/use-stores'
import { ApproveRejectButton } from 'contractor/pages/Integrations/Mappings/approve_reject_button'
import { MappingSuggestion } from 'contractor/server/integrations'

export const DEFAULT_PAGE_SIZE = 50

export const hasSuggestions = (row): boolean =>
  row.external_relationships.length === 0 && row.mapping_suggestions && row.mapping_suggestions.length > 0

export const maybeAddApproveRejectColumn = (
  rowId: string,
  data: Nullable<unknown[]>,
  showSuggestions: () => boolean,
  columns: unknown[],
  handleApprove: (suggestions: MappingSuggestion[]) => void,
  handleReject: (suggestions: MappingSuggestion[]) => void,
) => {
  const pageMappings = flatten(data?.map((item) => item['mapping_suggestions']) || [])
  const { userStore } = useStores()
  if (showSuggestions()) {
    columns.push({
      width: '7%',
      dataIndex: 'mapping_suggestions',
      // typescript is complaining about title being a React node but Antd docs say it's ok
      // @ts-ignore
      title: (
        <ApproveRejectButton
          isTitle
          handleApprove={() => handleApprove(pageMappings)}
          handleReject={() => handleReject(pageMappings)}
          disabled={pageMappings.length === 0 || !userStore.canSyncWithErp}
          rowId={rowId}
        />
      ),
      render: (_, row) =>
        hasSuggestions(row) ? (
          <ApproveRejectButton
            handleApprove={() => handleApprove(row.mapping_suggestions)}
            handleReject={() => handleReject(row.mapping_suggestions)}
            disabled={!userStore.canSyncWithErp}
            rowId={row.id}
          />
        ) : (
          <></>
        ),
    })
  }
}

type SelectExternalMappingsProps = {
  enabledMultiMapping: boolean
  disabled?: boolean
  selected?: ValueType[]
} & Partial<ReFetchDebounceSelectProps>

export const SelectExternalMappings = ({
  disabled,
  enabledMultiMapping,
  selected,
  fetchOptions,
  ...props
}: SelectExternalMappingsProps) => {
  const { userStore } = useStores()
  const defaultDisabled = !userStore.canSyncWithErp || disabled
  if (enabledMultiMapping === true) {
    const defaultSelected = selected || props.value || []
    return (
      <ReFetchDebounceSelect<ValueType>
        autoClearSearchValue={false}
        selected={defaultSelected}
        fetchOptions={fetchOptions}
        mode="multiple"
        {...props}
        disabled={defaultDisabled}
        onChange={(value, option) => {
          props.onChange(option, option)
        }}
      />
    )
  }
  const defaultValue = selected || props.value || undefined
  return (
    <ReFetchDebounceSelect<ValueType>
      fetchOptions={fetchOptions}
      allowClear
      {...props}
      value={defaultValue}
      disabled={defaultDisabled}
      onChange={(_, option) => {
        props.onChange(option, option)
      }}
    />
  )
}
