import axios, { AxiosResponse } from 'axios'

interface IndexUsersResponse {
  users: {
    id: string
    first_name: string
    last_name: string
    email: string
    company_name: string
    masquerade_path: string
  }[]
}

export async function indexUsers(): Promise<AxiosResponse<IndexUsersResponse>> {
  return await axios.get('/users/all_users/')
}
