import React from 'react'

import moment from 'moment'

import styled from '@emotion/styled'

import { Tag } from 'antd'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { withErrorBoundary } from 'common/components/ErrorBoundary/error_boundary'

import 'common/data-access/axios'

const StyledSpan = styled('span')`
  margin-left: 10px;
  margin-right: 10px;
`

const timestamp = (event) => {
  return moment.unix(event['timestamp']).format('MMMM Do YYYY, h:mm:ss a')
}

const Accepted = ({ event }) => {
  return (
    <FlexBoxX justifyContent="flex-start">
      <StyledSpan>{timestamp(event)}</StyledSpan>
      <Tag color="success">Accepted</Tag>
      Message was sent
    </FlexBoxX>
  )
}

const Delivered = ({ event }) => {
  const deliveryStatus = event['delivery-status']
  return (
    <FlexBoxX justifyContent="flex-start">
      <StyledSpan>{timestamp(event)}</StyledSpan>
      <Tag color="success">Delivered</Tag>
      <StyledSpan>Code: {deliveryStatus['code']}</StyledSpan>
      <StyledSpan>Successfully delivered to your inbox!</StyledSpan>
    </FlexBoxX>
  )
}

const Failed = ({ event }) => {
  const deliveryStatus = event['delivery-status']
  return (
    <FlexBoxY alignItems="flex-start">
      <FlexBoxX alignItems="flex-start">
        <StyledSpan>{timestamp(event)}</StyledSpan>
        <Tag color="error">Failed</Tag>
        <StyledSpan>Code: {deliveryStatus['code']}</StyledSpan>
        <StyledSpan>Severity: {event['severity']}</StyledSpan>
      </FlexBoxX>
      <FlexBoxX alignItems="flex-start">
        <StyledSpan>Reason: {event['reason']}</StyledSpan>
        <StyledSpan>{deliveryStatus['description']}</StyledSpan>
      </FlexBoxX>
    </FlexBoxY>
  )
}

const Unknown = ({ event }) => {
  console.error('Unknown Event Type')
  return (
    <FlexBoxX justifyContent="flex-start">
      <StyledSpan>{timestamp(event)}</StyledSpan>
      <Tag color="warning">{event['event']}</Tag>
      <StyledSpan>Open details to learn more</StyledSpan>
    </FlexBoxX>
  )
}

const Event = ({ event }) => {
  switch (event['event']) {
    case 'accepted':
      return <Accepted event={event} />
    case 'delivered':
      return <Delivered event={event} />
    case 'failed':
      return <Failed event={event} />
    default:
      return <Unknown event={event} />
  }
}

export default withErrorBoundary(Event)
