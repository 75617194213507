import React from 'react'

import { DataEditorProps } from 'react-spreadsheet'

import { Select } from 'antd'

import { VendorResponseType } from 'common/server/server_types'

type VendorResponseEditorCellProps = DataEditorProps

const options = [
  { value: VendorResponseType.SUBSTITUTION, label: 'Substitution' },
  { value: VendorResponseType.DO_NOT_CARRY, label: 'Do Not Carry' },
  { value: VendorResponseType.CHANGED_UNITS, label: 'Changed Units' },
  { value: VendorResponseType.BACKORDERED, label: 'Backordered' },
  { value: VendorResponseType.OTHER, label: 'Other' },
]

export const VendorResponseEditorCell = ({ cell, onChange, ...props }: VendorResponseEditorCellProps) => {
  return (
    <Select
      {...props}
      options={options}
      mode="multiple"
      allowClear
      showArrow
      value={cell.value}
      onChange={(value) => {
        onChange({ ...cell, value })
      }}
      autoFocus
    />
  )
}
