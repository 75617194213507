import React from 'react'

import { DataViewerProps } from 'react-spreadsheet'

import { Box } from 'common/components/boxes'
import { formatEnumValue } from 'common/helpers/formatters'

type VendorResponseProps = DataViewerProps

export const VendorResponseViewerCell = ({ cell }: VendorResponseProps) => {
  const value = cell?.value || []
  return <Box ml={4}>{value?.map(formatEnumValue)?.join(', ')}</Box>
}
