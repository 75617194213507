import { CreateVendorContactRequest } from 'contractor/server/company_vendor_contacts'

export const makeCompanyVendorContactPayload = ({ contact, vendorUser }): CreateVendorContactRequest['contact'] => {
  const { first_name, last_name, email, phone_number, nickname } = contact
  if (!vendorUser) {
    return {
      first_name,
      last_name,
      email,
      phone_number,
      nickname,
    }
  }

  return {
    vendor_user_id: vendorUser?.id,
  }
}
