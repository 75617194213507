import React from 'react'

import moment from 'moment'

import styled from '@emotion/styled'

import { Space, Typography } from 'antd'

import { Box, FlexBoxY } from 'common/components/boxes'
import { Visibility } from 'common/components/Visibility'

import { FileTags } from 'contractor/pages/@v2/Invoices/components/file_tags'
import { InboxState } from 'contractor/pages/@v2/Invoices/components/inbox_state'
import { ConsolidatedInvoiceInboxes } from 'contractor/server/invoices/inbox/consolidated_invoice_inboxes'

interface Props {
  onClick: () => void
  inbox: ConsolidatedInvoiceInboxes.InvoiceInbox
}

const Element = styled(Box)`
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors['gray-4']};
  transition: background 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors['gray-2']};
  }
`

export function InvoiceInboxItem(props: Props) {
  const { inbox, onClick } = props

  return (
    <Element
      px={24}
      py={16}
      bg="white"
      width="100%"
      display="flex"
      alignItems={{ _: 'flex-start', md: 'center' }}
      flexDirection={{ _: 'column', md: 'row' }}
      onClick={onClick}
    >
      <Box flexGrow={0} display="flex" flexDirection="column" alignItems="flex-start" width="200px" minWidth="200px">
        <Typography.Text type="secondary" style={{ textTransform: 'uppercase', fontSize: 12 }}>
          {moment(inbox.invoice_inbox_created_at).format('ddd, MMM Do')}
        </Typography.Text>
        <Typography.Text style={{ marginTop: 5 }}>{inbox.from || inbox.uploaded_by}</Typography.Text>
      </Box>
      <Visibility.Hidden>
        <FlexBoxY flexGrow={1} alignItems="flex-start" mx={12}>
          {inbox.subject ? (
            <Typography.Text>{inbox.subject}</Typography.Text>
          ) : (
            <Typography.Text type="secondary">Manually uploaded files</Typography.Text>
          )}

          <FileTags
            inbox_id={inbox.id}
            files={inbox.files}
            inbox_fail_reason={inbox.fail_reason}
            inbox_auto_ignored_reason={inbox.auto_ignored_reason}
            upper_state={inbox.sub_state}
          />
        </FlexBoxY>
      </Visibility.Hidden>
      <Box mt={{ _: 12, md: 0 }}>
        <Space
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <InboxState state={inbox.state} sub_state={inbox.sub_state} count={inbox.invoice_count} />
          {/* @todo: show actions for InvoiceInboxItem */}
          {/*{showActionMenu ? (*/}
          {/*  <DropdownStyled*/}
          {/*    menu={{ items }}*/}
          {/*    autoFocus*/}
          {/*    trigger={['hover', 'click']}*/}
          {/*    type="link"*/}
          {/*    icon={<MoreOutlined />}*/}
          {/*    onClick={(e) => {*/}
          {/*      e.preventDefault()*/}
          {/*      e.stopPropagation()*/}
          {/*    }}*/}
          {/*    style={{ padding: 0 }}*/}
          {/*  />*/}
          {/*) : (*/}
          {/*)}*/}
          <div style={{ width: 36 }} />
        </Space>
      </Box>
    </Element>
  )
}
