import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

export const useQueryCostCodePhases = ({ projectId, enabled = true }) => {
  const { costCodeStore } = useStores()
  const { costCodePhaseListStore } = costCodeStore

  return useQuery(
    () => {
      if (projectId) {
        costCodePhaseListStore.setFilter('project_id', projectId, true, true)
      }
      costCodePhaseListStore.setFilter('active', true, true, true)
      return costCodePhaseListStore.fetchRecords()
    },
    [projectId],
    enabled,
  )
}
