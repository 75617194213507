import React from 'react'

import { Alert, Typography } from 'antd'

import { formatDateStringShort } from 'common/helpers/formatters'
import { DuplicatedInvoices } from 'common/server/invoice'

type WarnNoticesProps = {
  showWarnNotice: boolean
  duplicatedInvoices: DuplicatedInvoices[]
}

export const WarnNotices = (props: WarnNoticesProps) => {
  const { showWarnNotice, duplicatedInvoices } = props

  if (!showWarnNotice || !duplicatedInvoices) return null

  return (
    <Alert
      message={
        <>
          <Typography.Text>
            We&apos;ve detected that this might be a duplicate invoice, check the following invoices:{' '}
          </Typography.Text>
          {duplicatedInvoices.map((invoice, index) => (
            <React.Fragment key={index}>
              <Typography.Link target="_blank" underline href={invoice.url}>
                #{invoice.number} (${Number(invoice.total_amount || 0).toFixed(2)})
              </Typography.Link>
              <Typography.Text>
                {' '}
                which was created on {formatDateStringShort(invoice.created_at)}
                {duplicatedInvoices.length > index + 1 ? ', ' : ' '}
              </Typography.Text>
            </React.Fragment>
          ))}
        </>
      }
      type="warning"
      style={{ width: '100%', marginBottom: 8, marginTop: 16 }}
      showIcon
      closable
    />
  )
}
