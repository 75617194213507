import React from 'react'

import { Typography } from 'antd'

import { InvoiceInboxFilesStates } from 'common/server/server_types'

import { InvoiceInboxFileHit } from 'contractor/server/invoices/inbox'

// @todo: change this type (InvoiceInboxFileHit): FailedPdfDetails
export function IgnoredPdfDetails({ files }: { files: InvoiceInboxFileHit[] }) {
  return files.some((file) => file.state === InvoiceInboxFilesStates.AUTO_IGNORED) ? (
    <Typography.Paragraph style={{ paddingTop: 10 }}>
      {files
        .filter((file) => file.state === InvoiceInboxFilesStates.AUTO_IGNORED)
        .map((file) => (
          <Typography.Paragraph key={`${file.filename}-key`}>
            <a href={file.url} target="_blank" rel="noreferrer">
              {file.filename}
            </a>
            :
            {file.auto_ignored_message && (
              <ul>
                <li key="auto-ignored-message">{file.auto_ignored_message}</li>
              </ul>
            )}
          </Typography.Paragraph>
        ))}
    </Typography.Paragraph>
  ) : (
    <Typography.Paragraph style={{ paddingTop: 10 }}>
      <ul>
        <li key="auto-ignored-message">This email has no attachments</li>
      </ul>
    </Typography.Paragraph>
  )
}
