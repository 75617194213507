import React from 'react'

import { Tag, Popover, Typography } from 'antd'

import { FlexBoxX, Box } from 'common/components/boxes'

const style = { padding: '0 4px' }

const fontSize = 12

type CellCostCodeProps = {
  code?: string
  codeDescription?: string
  clazz?: string
  clazzDescription?: string
  phaseCode?: string
  phaseCodeDescription?: string
  phaseEnabled?: boolean
  classEnabled?: boolean
}

export const CellCostCode = (props: CellCostCodeProps) => {
  const {
    code,
    codeDescription,
    clazz,
    clazzDescription,
    phaseCode,
    phaseCodeDescription,
    classEnabled,
    phaseEnabled,
  } = props

  return (
    <Popover
      content={
        <Box width="fit-content">
          {phaseEnabled && !!phaseCode ? (
            <Box width="100%" display="flex" flexDirection="column" mb={12}>
              <Typography.Text type="secondary" style={{ fontSize }}>
                PHASE: <Typography.Text strong>{phaseCode || 'N/A'}</Typography.Text>
              </Typography.Text>
              {phaseCodeDescription && (
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  {phaseCodeDescription}
                </Typography.Text>
              )}
            </Box>
          ) : (
            ''
          )}
          {code && (
            <Box width="100%" display="flex" flexDirection="column">
              <Typography.Text type="secondary" style={{ fontSize }}>
                COST CODE: <Typography.Text strong>{code || 'N/A'}</Typography.Text>
              </Typography.Text>
              {codeDescription && (
                <Typography.Text type="secondary" style={{ fontSize }}>
                  {codeDescription}
                </Typography.Text>
              )}
            </Box>
          )}
          {clazz ? (
            <Box width="100%" display="flex" flexDirection="column" mt={12}>
              <Typography.Text type="secondary" style={{ fontSize }}>
                CLASS: <Typography.Text strong>{clazz || 'N/A'}</Typography.Text>
              </Typography.Text>
              {clazzDescription && (
                <Typography.Text type="secondary" style={{ fontSize }}>
                  {clazzDescription}
                </Typography.Text>
              )}
            </Box>
          ) : (
            ''
          )}
        </Box>
      }
    >
      <FlexBoxX alignItems="flex-start" justifyContent="flex-start">
        {phaseEnabled && !!phaseCode ? <Tag style={style}>{phaseCode || 'N/A'}</Tag> : ''}
        {code && <Tag style={style}>{code}</Tag>}
        {classEnabled && !!clazz ? <Tag style={style}>{clazz || 'N/A'}</Tag> : ''}
      </FlexBoxX>
    </Popover>
  )
}
