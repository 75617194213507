import React from 'react'

import { FlexBoxY } from 'common/components/boxes'
import { ChangedItem, ChangeType } from 'common/components/History'
import { EventChange } from 'common/server/order_history'

const ORDER_COMMENT_FIELDS_CONFIG = [
  {
    key: 'Comment',
    type: 'text',
  },
]
export const ALLOW_ORDER_COMMENT_FIELDS = ORDER_COMMENT_FIELDS_CONFIG.map((item) => item.key)

type CommentProps = {
  orderComment: EventChange
}

export const OrderComment = ({ orderComment }: CommentProps) => {
  const keys = Object.keys(orderComment.changes).filter((key) => ALLOW_ORDER_COMMENT_FIELDS.includes(key))

  if (!keys.length) {
    return null
  }

  return (
    <FlexBoxY alignItems="flex-start" width="100%" key={orderComment.item_id}>
      {keys.map((key, index) => {
        const changes = orderComment.changes[key]

        return (
          <FlexBoxY alignItems="flex-start" width="100%" key={`${key}-${index}`}>
            <ChangedItem
              label="Messages"
              changes={changes}
              isCreate
              type={ORDER_COMMENT_FIELDS_CONFIG.find((item) => item.key === key)?.type as ChangeType}
            />
          </FlexBoxY>
        )
      })}
    </FlexBoxY>
  )
}
