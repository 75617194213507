import axios from 'axios'

export interface SendEmailRequest {
  email: string
  subject: string
  message: string
}

export interface PostResponse {
  message_id: string
}

export function post(params: SendEmailRequest) {
  return axios.post<PostResponse>(`/test_email`, params)
}

export function get(message_id: string) {
  // This isn't a mistake, we use a post so we can pass message id as a param as it is hard to encode
  return axios.post(`/test_email/history`, { message_id })
}
