import React from 'react'

import { Alert, Button } from 'antd'

import { QuoteExtractionStates } from 'common/server/server_types'

type QuoteAlertProps = {
  quoteFullyMatched: boolean
  setShowQuoteDrawer: (value: boolean) => void
  state: QuoteExtractionStates
}

export const QuoteAlert = (props: QuoteAlertProps) => {
  const { quoteFullyMatched, setShowQuoteDrawer, state } = props

  if (state === QuoteExtractionStates.NOT_EXTRACTED) return null

  let message = ''
  let alertType = 'warning'

  switch (state) {
    case QuoteExtractionStates.SUCCESS:
      message = quoteFullyMatched
        ? 'We successfully extracted and reconciled all prices from the RFQ your vendor provided.'
        : 'We partially reconciled prices from the RFQ your vendor provided, your manual review may be needed.'
      alertType = quoteFullyMatched ? 'success' : 'warning'
      break
    case QuoteExtractionStates.FAILED:
      message =
        'We could not extract any prices from the RFQ your vendor provided, please review the document manually.'
      alertType = 'error'
      break
    default:
      break
  }

  return (
    <Alert
      message={message}
      type={alertType}
      style={{ marginTop: 0, width: '100%', marginBottom: 16 }}
      showIcon
      action={
        <Button onClick={() => setShowQuoteDrawer(true)} type="primary" size="small">
          Open
        </Button>
      }
      closable
    />
  )
}
