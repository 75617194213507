import React, { useState, useEffect } from 'react'

import { CSVLink } from 'react-csv'

import { get, set, flatten } from 'lodash'

import { observer } from 'mobx-react-lite'

import { formatFullAddress } from 'common/helpers/formatters'
import { formatForCSVExport } from 'common/helpers/formatters'
import { calcExtCost } from 'common/helpers/order'

import { useStores } from 'contractor/hooks/use-stores'

const headersOptional = [
  { label: 'Description', key: 'description' },
  { label: 'Product ID', key: 'product_identifier' },
  { label: 'Manufacturer', key: 'manufacturer' },
  { label: 'Unit', key: 'unit' },
  { label: 'Size', key: 'size' },
  { label: 'Connection Type', key: 'connection_type' },
  { label: 'Material', key: 'material' },
  { label: 'Cost Code', key: 'cost_code' },
]

const headersDefault = [
  { label: 'Project', key: 'project' },
  { label: 'Quantity', key: 'quantity' },
  { label: 'Unit Cost', key: 'unit_cost' },
  { label: 'Ext. Cost', key: 'extended_cost' },
  { label: 'Type', key: 'type' },
  { label: 'Address', key: 'full_address' },
]

export const makeDeliveryCosts = (deliveries = []) => {
  const deliveriesCosts = deliveries.map((delivery) => {
    const full_address = delivery?.address ? formatFullAddress(delivery?.address) : ''

    const values = [
      { key: 'discount_value', label: 'Discount' },
      { key: 'shipping_value', label: 'Shipping' },
      { key: 'other_value', label: 'Other' },
      { key: 'tax_value', label: 'Tax' },
    ]
      .map((cost) => {
        const numberValue = Number(delivery?.[cost.key] || 0)
        // Convert discount to negative value
        const value = cost.key === 'discount_value' && numberValue > 0 ? numberValue * -1 : numberValue
        return {
          description: cost.label,
          unit_cost: value,
          unit_price: value,
          extended_cost: value,
          quantity: 1,
          uom: 'EA',
          type: delivery?.is_pick_up ? 'PICK UP' : 'DELIVERY',
          full_address,
          cost_code: 'Empty',
        }
      })
      .filter((delivery) => delivery.unit_cost != 0)

    return values
  })

  return flatten(deliveriesCosts)
}

export const DownloadCsv = observer(() => {
  const { orderStore, companySettingStore } = useStores()

  const { company_attributes = [] } = companySettingStore.companyMaterialConfiguration

  const orderNumber = orderStore.selectedOrder?.order_number

  const [data, setData] = useState([])

  useEffect(() => {
    const { order_materials = [], deliveries = [] } = orderStore.selectedOrder

    const downloadContent =
      order_materials.map((material) => {
        const delivery = deliveries.find((delivery) => delivery.id === material.delivery_id)

        const full_address = delivery?.address ? formatFullAddress(delivery?.address) : ''

        const extCost = calcExtCost({
          unitCost: material?.unit_cost,
          quantity: material?.quantity,
          multiplier: material?.company_material?.unit?.multiplier,
          qtyIncrement: material?.company_material?.unit?.qty_increment,
        })

        return {
          description: material.company_material.description,
          product_identifier: material.company_material.product_identifier,
          manufacturer: material.company_material.manufacturer,
          unit: material.company_material.unit?.unit_name_with_increment_label || material.company_material.unit_name,
          size: material.company_material.size,
          connection_type: material.company_material.connection_type,
          material: material.company_material.material,
          cost_code: material?.cost_code?.code,

          project: orderStore.selectedOrder?.project?.name,
          quantity: material.quantity,
          unit_cost: material.unit_cost,
          extended_cost: extCost,
          type: delivery?.is_pick_up ? 'PICK UP' : 'DELIVERY',
          full_address,
        }
      }) || []

    downloadContent.forEach((hit) => {
      const headers = [...headersDefault, ...headersOptional]
      headers.forEach(({ key }) => {
        const currentValue = get(hit, key)
        const newValue = formatForCSVExport(currentValue)
        set(hit, key, newValue)
      })
    })

    setData([...downloadContent, ...makeDeliveryCosts(deliveries)])
  }, [orderStore.selectedOrder])

  const headersOptionalToShow = headersOptional.filter((header) => company_attributes?.includes(header.key))
  const allHeaders = [...headersOptionalToShow, ...headersDefault]

  const desiredOrderKeys = ['project', 'description', 'quantity', 'unit', 'unit_cost', 'extended_cost']

  const remainingItems = allHeaders.filter((item) => !desiredOrderKeys.includes(item.key))
  const sortedDesiredItems = desiredOrderKeys.map((key) => allHeaders.find((item) => item.key === key))

  const headers = [...sortedDesiredItems, ...remainingItems]

  if (!data.length) {
    return <>Processing CSV...</>
  }

  return (
    <CSVLink data={data} filename={`${orderNumber}.csv`} headers={headers} target="_blank">
      Download CSV
    </CSVLink>
  )
})
