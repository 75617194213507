import React from 'react'

import { InputCurrency } from 'common/components/InputCurrency'

export const CurrencyEditorCell = ({ cell, onChange }) => {
  return (
    <InputCurrency
      autoFocus
      style={{ width: '100%', border: 'none', height: '28px' }}
      value={cell.value}
      onChange={(value) => {
        onChange({ ...cell, value })
      }}
    />
  )
}
