import { useState, useEffect, useRef, useMemo } from 'react'

interface TimerSettings {
  autoStart?: boolean
  format?: string
  initial?: number
  onComplete?: () => void
}

interface UseCountdownResult {
  isRunning: boolean
  pause: () => void
  reset: () => void
  seconds: number
  start: () => void
  time: string
  setTime: (newTime: number) => void
}

const formatTime = (seconds: number, format: string): string => {
  const date = new Date(seconds * 1000)
  const hours = String(date.getUTCHours()).padStart(2, '0')
  const minutes = String(date.getUTCMinutes()).padStart(2, '0')
  const secs = String(date.getUTCSeconds()).padStart(2, '0')
  switch (format) {
    case 'HH:mm:ss':
      return `${hours}:${minutes}:${secs}`
    case 'mm:ss':
      return `${minutes}:${secs}`
    default:
      return `${hours}:${minutes}:${secs}`
  }
}

export function useCountdown(settings?: TimerSettings): UseCountdownResult {
  const { autoStart = true, format = 'mm:ss', initial = 0, onComplete } = settings || {}

  const [isRunning, setRunning] = useState<boolean>(false)
  const [seconds, setSeconds] = useState<number>(initial)

  const time = useMemo(() => formatTime(seconds, format), [format, seconds])

  const intervalRef = useRef<number | undefined>(undefined)

  function pause() {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = undefined
    }
    setRunning(false)
  }

  const updateSeconds = () => {
    setSeconds((prevSeconds) => {
      const newSeconds = prevSeconds - 1
      if (newSeconds <= 0) {
        pause()
        if (onComplete) {
          onComplete()
        }
        return 0
      }
      return newSeconds
    })
  }

  function start() {
    if (!intervalRef.current) {
      intervalRef.current = window.setInterval(updateSeconds, 1000)
    }
    setRunning(true)
  }

  function setTime(newTime: number) {
    setSeconds(newTime)
  }

  function reset() {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = undefined
    }
    setRunning(false)
    setSeconds(initial)
  }

  useEffect(() => {
    if (autoStart) {
      start()
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [autoStart])

  return { isRunning, pause, reset, seconds, start, time, setTime }
}
