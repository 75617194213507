import React, { useRef } from 'react'

import FilterOutlined from '@ant-design/icons/FilterOutlined'
import { Badge, Button, Tooltip } from 'antd'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef, DrawerProps } from 'common/components/Drawer'
import theme from 'common/styles/theme/theme'

import { FilterButton } from './button'

export type DrawerTableFiltersProps = {
  children: React.ReactNode
  title: string
  useAlgolia?: boolean
  setIsSorted?: () => void
  appliedFiltersCounter?: number
  padding?: number
  drawerProps?: Partial<DrawerProps>
}

export const DrawerTableFilters = ({
  children,
  title,
  useAlgolia = true,
  setIsSorted,
  appliedFiltersCounter = 0,
  padding = 12,
  drawerProps,
}: DrawerTableFiltersProps) => {
  const drawerUpdateRef = useRef<DrawerRef>()

  return (
    <>
      {useAlgolia ? (
        <FilterButton onClick={() => drawerUpdateRef.current.show()} />
      ) : (
        <Tooltip title="Options" placement="bottomRight">
          <Button
            data-cy="filters"
            onClick={() => drawerUpdateRef.current.show()}
            style={{ display: 'flex', alignItems: 'center', minHeight: 32 }}
          >
            <FilterOutlined style={{ fontSize: 16 }} />

            {appliedFiltersCounter > 0 && (
              <Badge
                count={appliedFiltersCounter}
                style={{ marginLeft: '5px', background: theme.colors['gray-7'], color: theme.colors.white }}
              />
            )}
          </Button>
        </Tooltip>
      )}

      <Drawer ref={drawerUpdateRef} width={378} title={title} forceRender closable={false} {...drawerProps}>
        <Box
          display="flex"
          flexDirection="column"
          overflow="auto"
          width="100%"
          height="100%"
          p={{ _: padding, sm: padding + 4 }}
          onClick={() => {
            setIsSorted?.()
          }}
        >
          {children}
        </Box>
      </Drawer>
    </>
  )
}
