import React from 'react'

import styled from '@emotion/styled'

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Dropdown, Space, Typography } from 'antd'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { InputCurrencyProps } from 'common/components/InputCurrency'
import { InputDeliveryPercentage } from 'common/components/InputCurrencyPercentage/Delivery/input_delivery_percentage'
import { currencyFormatter } from 'common/helpers/formatters'
import { DeliveryChargesUnit } from 'common/server/deliveries'

type Value = {
  discountValue: number
  shippingValue: number
  otherValue: number
  taxValue: number
}

export type TotalProps = {
  onChange?: (value: Value) => void
  deliveryTotalCost?: number
  hideAddTotal?: boolean
  index: number
  value: Value
  disabled?: boolean
  costsDisabled?: boolean
  isPoLocked?: boolean
  roundingPrecision?: number
  orderMaterialsTotalCost?: number
  inputsProps?: Partial<Record<DeliveryExtraValues, InputCurrencyProps>>
  deliveryChargesUnits?: DeliveryChargesUnit
  onChangeChargesUnit?: (value: DeliveryChargesUnit) => void
}

export enum DeliveryExtraValues {
  Discount = 'discount_value',
  Shipping = 'shipping_value',
  Other = 'other_value',
  Tax = 'tax_value',
}

const ItemDeliveryExtraValue = styled(FlexBoxX)`
  margin-bottom: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    .ant-btn {
      visibility: hidden;
    }

    :hover .ant-btn {
      visibility: visible;
    }
  }
`

export const Total = (props: TotalProps) => {
  const {
    onChange,
    deliveryTotalCost,
    hideAddTotal,
    index,
    value,
    disabled,
    roundingPrecision,
    isPoLocked,
    costsDisabled,
    inputsProps,
    orderMaterialsTotalCost,
    deliveryChargesUnits,
    onChangeChargesUnit,
  } = props

  const currentValues = {
    [DeliveryExtraValues.Discount]: value?.discountValue,
    [DeliveryExtraValues.Shipping]: value?.shippingValue,
    [DeliveryExtraValues.Other]: value?.otherValue,
    [DeliveryExtraValues.Tax]: value?.taxValue,
  }

  const handleChangeAfter = () => {
    onChange?.({
      discountValue: currentValues[DeliveryExtraValues.Discount],
      shippingValue: currentValues[DeliveryExtraValues.Shipping],
      otherValue: currentValues[DeliveryExtraValues.Other],
      taxValue: currentValues[DeliveryExtraValues.Tax],
    })
  }

  const handleAddExtra = (key: DeliveryExtraValues) => {
    currentValues[key] = 0
    handleChangeAfter()
  }

  const handleRemoveExtra = (key: string) => {
    currentValues[key] = null
    handleChangeAfter()
  }

  const humanizeKey = (key: string) => {
    return key
      .split('_')[0]
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }

  // Show items not yet selected
  const menuItems = Object.values(DeliveryExtraValues).filter((value) => currentValues[value] === null)

  const handleChange = (value, key) => {
    currentValues[key] = value
    handleChangeAfter()
  }

  return (
    <FlexBoxX justifyContent="flex-end" flexGrow={0}>
      <Space direction="horizontal" align="start" size="middle">
        {!hideAddTotal && (
          <Dropdown
            menu={{
              items: menuItems.map((value) => ({
                label: <div onClick={() => handleAddExtra(value)}>{humanizeKey(value)}</div>,
                key: value,
                disabled: inputsProps?.[value]?.disabled,
              })),
            }}
            disabled={!menuItems.length || isPoLocked || costsDisabled}
            trigger={['hover', 'click']}
          >
            <Button
              disabled={disabled || isPoLocked || costsDisabled}
              data-cy={`extras-total-${index}`}
              icon={<PlusOutlined />}
              size="small"
              type="link"
            >
              Add Cost
            </Button>
          </Dropdown>
        )}

        <FlexBoxY alignItems="flex-end" py="1px">
          {Object.keys(currentValues).map((key, idx) => {
            const currentValue = currentValues[key]

            if (currentValue === null) {
              return
            }

            const inputProps = inputsProps?.[key]

            return (
              <ItemDeliveryExtraValue key={idx} justifyContent="space-between" width="100%">
                {!isPoLocked && !costsDisabled && !inputProps?.disabled && (
                  <Button
                    data-cy={`extras-item-${key}-${index}`}
                    icon={<DeleteOutlined />}
                    size="small"
                    danger
                    type="link"
                    onClick={() => handleRemoveExtra(key)}
                  />
                )}

                <FlexBoxX ml="8px" justifyContent="space-between">
                  <Typography.Text>{humanizeKey(key)}:</Typography.Text>
                  <FlexBoxX ml="8px" flexGrow={0}>
                    <InputDeliveryPercentage
                      data-cy={`extras-input-${key}-${index}`}
                      style={{ width: '180px' }}
                      size="small"
                      totalCost={orderMaterialsTotalCost}
                      roundingPrecision={roundingPrecision}
                      inputName={key}
                      onChangeChargesUnit={onChangeChargesUnit}
                      deliveryChargesUnits={deliveryChargesUnits}
                      value={currentValue}
                      onChange={(value) => {
                        const valueNumber = Number(value)
                        if (!isNaN(valueNumber)) {
                          handleChange(valueNumber, key)
                        }
                      }}
                      {...inputProps}
                      disabled={isPoLocked || costsDisabled || inputProps?.disabled}
                    />
                  </FlexBoxX>
                </FlexBoxX>
              </ItemDeliveryExtraValue>
            )
          })}

          <Typography.Text data-cy="grand-total-material-text" strong>
            Grand Total: {currencyFormatter(deliveryTotalCost || 0, roundingPrecision || 3)}
          </Typography.Text>
        </FlexBoxY>
      </Space>
    </FlexBoxX>
  )
}
