import React, { useState } from 'react'

import styled from '@emotion/styled'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Switch, Table, Tooltip, Typography, Badge } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { UserRoles } from 'common/server/server_types'

import { InvoicePermissions } from 'contractor/server/company_settings/permissions'

const TableStyled = styled(Table)`
  width: 100%;

  tr.disabled-table-row:hover > td,
  .locked-table-row {
    background-color: ${({ theme }) => theme.colors['gray-3']};
    cursor: not-allowed;
  }
`

type invoiceTableProps = {
  setInvoicePermissions: (data: InvoicePermissions) => void
  invoicesPermissions: InvoicePermissions
}

const InvoicesTable = ({ setInvoicePermissions, invoicesPermissions }: invoiceTableProps) => {
  const [data, setData] = useState([
    {
      id: 'i1', // ID used for antd table to set the row key
      key: 'can_view_all_invoices',
      permission: 'Can view all invoices',
      tooltip: 'Can view all invoices in the company regardless of status or assignment.',
      admin: invoicesPermissions?.ADMIN?.can_view_all_invoices,
      purchaser: invoicesPermissions?.PURCHASER?.can_view_all_invoices,
      user: invoicesPermissions?.USER?.can_view_all_invoices,
      material_requester: invoicesPermissions?.MATERIAL_REQUESTER?.can_view_all_invoices,
    },
    {
      id: 'i2',
      key: 'can_view_invoice_inbox',
      permission: 'Can view invoice inbox',
      tooltip: 'Can see inbox with emailed and uploaded invoices',
      admin: invoicesPermissions?.ADMIN?.can_view_invoice_inbox,
      purchaser: invoicesPermissions?.PURCHASER?.can_view_invoice_inbox,
      user: invoicesPermissions?.USER?.can_view_invoice_inbox,
      material_requester: invoicesPermissions?.MATERIAL_REQUESTER?.can_view_invoice_inbox,
    },
    {
      id: 'i3',
      key: 'can_create_quick_po',
      permission: 'Can create Quick PO',
      tooltip:
        'Can create a PO during the invoice reconciliation process intended for record keeping purposes. ' +
        'This type of PO will NOT notify the vendor of the order and is assumed to be already delivered',
      admin: invoicesPermissions?.ADMIN?.can_create_quick_po,
      purchaser: invoicesPermissions?.PURCHASER?.can_create_quick_po,
      user: invoicesPermissions?.USER?.can_create_quick_po,
      material_requester: invoicesPermissions?.MATERIAL_REQUESTER?.can_create_quick_po,
    },
    {
      id: 'i4',
      key: 'can_delete_invoices',
      permission: 'Can delete invoices',
      tooltip: 'Can delete all invoices, once deleted, there is no way to undo this action.',
      admin: invoicesPermissions?.ADMIN?.can_delete_invoices,
      purchaser: invoicesPermissions?.PURCHASER?.can_delete_invoices,
      user: invoicesPermissions?.USER?.can_delete_invoices,
      material_requester: invoicesPermissions?.MATERIAL_REQUESTER?.can_delete_invoices,
    },
    {
      id: 'i5',
      key: 'can_edit_invoices',
      permission: 'Can edit invoices',
      tooltip:
        'Can view and edit invoices assigned to the user. If the user is an Admin, can edit any invoice regardless of status or assignment.',
      admin: invoicesPermissions?.ADMIN?.can_edit_invoices,
      purchaser: invoicesPermissions?.PURCHASER?.can_edit_invoices,
      user: invoicesPermissions?.USER?.can_edit_invoices,
      material_requester: invoicesPermissions?.MATERIAL_REQUESTER?.can_edit_invoices,
    },
    {
      id: 'i6',
      key: 'can_view_all_my_project_invoices',
      permission: 'Can view all my project invoices',
      tooltip: 'Can view invoices that are assigned to projects from groups they have access to.',
      admin: invoicesPermissions?.ADMIN?.can_view_all_my_project_invoices,
      purchaser: invoicesPermissions?.PURCHASER?.can_view_all_my_project_invoices,
      user: invoicesPermissions?.USER?.can_view_all_my_project_invoices,
      material_requester: invoicesPermissions?.MATERIAL_REQUESTER?.can_view_all_my_project_invoices,
    },
    {
      id: 'i7',
      key: 'can_post_invoices',
      permission: 'Can post and sync invoices',
      tooltip: 'Can change any invoice status to posted and sync with the ERP, regardless or assignment.',
      admin: invoicesPermissions?.ADMIN?.can_post_invoices,
      purchaser: invoicesPermissions?.PURCHASER?.can_post_invoices,
      user: invoicesPermissions?.USER?.can_post_invoices,
      material_requester: invoicesPermissions?.MATERIAL_REQUESTER?.can_post_invoices,
    },
    {
      id: 'i8',
      key: 'can_change_status_and_assignee',
      permission: 'Can change status and assignee',
      tooltip: 'Can change status and assignee of all invoices in the company regardless of status or assignment.',
      admin: invoicesPermissions?.ADMIN?.can_change_status_and_assignee,
      purchaser: invoicesPermissions?.PURCHASER?.can_change_status_and_assignee,
      user: invoicesPermissions?.USER?.can_change_status_and_assignee,
      material_requester: invoicesPermissions?.MATERIAL_REQUESTER?.can_change_status_and_assignee,
    },
  ])

  const handleSwitchChange = (permissionKey: string, role: string) => {
    const newData = [...data]
    newData.map((item) => {
      if (item.key === permissionKey) {
        item[role.toLowerCase()] = !item[role.toLowerCase()]
      }
      return item
    })

    const updatedInvoicePermissions = {
      ADMIN: {},
      PURCHASER: {},
      USER: {},
      MATERIAL_REQUESTER: {},
    } as InvoicePermissions

    for (const key in updatedInvoicePermissions) {
      const rolePermissions = updatedInvoicePermissions[key]
      for (const dataItem of newData) {
        rolePermissions[dataItem.key] = dataItem[key.toLowerCase()] || false
      }
    }

    setData(newData)
    setInvoicePermissions(updatedInvoicePermissions)
  }

  const columns: ColumnsType<{
    key: string
    permission: string
    tooltip: string
    admin: boolean
    purchaser: boolean
    user: boolean
    material_requester: boolean
  }> = [
    {
      title: 'Invoices Permissions',
      dataIndex: 'permission',
      width: 200,
      render: (permission, record) => (
        <>
          <Typography.Text>{permission}</Typography.Text>{' '}
          {record?.tooltip && (
            <Badge
              count={
                <Tooltip placement="top" title={record?.tooltip}>
                  <QuestionCircleOutlined style={{ top: '-5px', right: '-5px', fontSize: 14 }} />
                </Tooltip>
              }
            />
          )}
        </>
      ),
    },
    {
      title: 'Admin',
      dataIndex: 'admin',
      width: 150,
      render: (_, record) => (
        <Switch checked={record.admin} onChange={() => handleSwitchChange(record.key, UserRoles.ADMIN)} />
      ),
    },
    {
      title: 'Purchaser',
      dataIndex: 'purchaser',
      width: 150,
      render: (_, record) => (
        <Switch checked={record.purchaser} onChange={() => handleSwitchChange(record.key, UserRoles.PURCHASER)} />
      ),
    },
    {
      title: 'User',
      dataIndex: 'user',
      width: 150,
      render: (_, record) => (
        <Switch checked={record.user} onChange={() => handleSwitchChange(record.key, UserRoles.USER)} />
      ),
    },
    {
      title: 'Material Requester',
      dataIndex: 'material_requester',
      width: 150,
      render: (_, record) => (
        <Switch
          checked={record.material_requester}
          onChange={() => handleSwitchChange(record.key, UserRoles.MATERIAL_REQUESTER)}
        />
      ),
    },
  ]

  if (!invoicesPermissions) {
    return null
  }

  return <TableStyled rowKey="id" dataSource={data} columns={columns} pagination={false} />
}

export default InvoicesTable
