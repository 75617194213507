import React from 'react'

import { Button } from 'antd'

import { Tool, trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'

import { useStores } from 'contractor/hooks/use-stores'

type PlaceOrderButtonProps = {
  isLoading: boolean
  emptyCompanyVendors: boolean
  orderType: OrderType
  handlePlaceOrder: () => void
}

export const PlaceOrderButton = (props: PlaceOrderButtonProps) => {
  const { isLoading, emptyCompanyVendors, orderType, handlePlaceOrder } = props
  const { userStore } = useStores()

  const onClickPlaceOrder = () => {
    trackEvent(Events.CLICK_PLACE_ORDER, window.location.pathname, {}, Tool.AMPLITUDE)
    handlePlaceOrder()
  }

  if (orderType == 'Order' && userStore.canSendAndUpdateOrders) {
    return (
      <Button
        data-cy="place-order"
        type="primary"
        onClick={onClickPlaceOrder}
        loading={isLoading}
        disabled={emptyCompanyVendors}
        style={{ marginLeft: 8 }}
      >
        Place Order
      </Button>
    )
  }

  return null
}
