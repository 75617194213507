import React from 'react'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { PersistentFiltersProvider } from 'common/contexts/persistent_filters'

import { useStores } from 'contractor/hooks/use-stores'
import { InvoiceInboxesContent } from 'contractor/pages/@v2/Invoices/tabs/Inboxes/invoice_inboxes_content'

function Content() {
  const {
    invoiceInboxStore: { listStore },
  } = useStores()

  return (
    <PersistentFiltersProvider
      ignoreQueryParams={['tab']}
      ignoreOnFilterCounter={['state', 'tab', 'invoices_inbox_source_id', 'invoices_inbox_source_type']}
      listStore={listStore}
      filters={toJS(listStore.searchState.filters)}
    >
      <InvoiceInboxesContent />
    </PersistentFiltersProvider>
  )
}

export const InvoiceInboxes = observer(Content)
