import React from 'react'

import moment from 'moment'

import { Typography } from 'antd'

import { formatDateString } from 'common/helpers/formatters'
import { OrderStates } from 'common/server/server_types'

type CellExpirationQuoteProps = {
  date?: string
  state: OrderStates
}

export const CellExpirationQuote = ({ date, state }: CellExpirationQuoteProps) => {
  if (state != OrderStates.QUOTED || !date) {
    return null
  }

  const expDate = moment(date)
  const diffInDays = Math.round(moment().diff(expDate, 'days', true)) * -1

  return (
    <Typography.Text type={diffInDays <= 3 ? 'danger' : 'secondary'}>
      {date ? formatDateString(date) : '-'}
    </Typography.Text>
  )
}
