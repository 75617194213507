import axios from 'axios'

export interface ProjectGroupsList {
  id: string
  name: string
  projects_count: number
  project_group_members_count: number
  created_at: string
  updated_at: string
}

export interface ProjectGroupsShow {
  id: string
  name: string
  projects_count: number
  project_group_members_count: number
  company_user_ids: string[]
  project_ids: string[]
  created_at: string
  updated_at: string
}

export interface ProjectGroupsUpdate {
  name: string
  company_user_ids: string[]
  project_ids: string[]
}

export interface ProjectGroupsCreate {
  name: string
  company_user_ids: string[]
  project_ids: string[]
}

export function index() {
  return axios.get<ProjectGroupsList[]>('/project_groups')
}

export function show(id: string) {
  return axios.get<ProjectGroupsShow>(`/project_groups/${id}`)
}

export function create(project_group: ProjectGroupsCreate) {
  return axios.post<ProjectGroupsShow>('/project_groups', { project_group })
}

export function update(id: string, project_group: ProjectGroupsUpdate) {
  return axios.patch<ProjectGroupsShow>(`/project_groups/${id}`, { project_group })
}

export function remove(id: string) {
  return axios.delete(`/project_groups/${id}`)
}
