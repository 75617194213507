import { toJS } from 'mobx'

import { OrderMaterial } from 'common/server/orders'

/*
  Transform a mobx observable array on a plane array.
  Used to pass order material summary to the spreadsheet,
  can't edit the mobX observable directly, incompatible with being "frozen",
  so convert to the plain array.
*/
export const undoObservableArray = (items: OrderMaterial[] = []) => {
  return items.map((item) => {
    let plainItem = {}
    for (const field in item) {
      plainItem = {
        ...plainItem,
        [field]: toJS(item[field]),
      }
    }
    return plainItem
  }) as OrderMaterial[]
}
