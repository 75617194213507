import React from 'react'

import { Typography, Tooltip, Tag } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { formatDateString } from 'common/helpers/formatters'
import { formatEnumValue } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'
import { DeliveryStates } from 'common/server/server_types'

export type DeliveryInfoProps = {
  state: string
  estimated_delivered_at: string
  carrier: string
  actual_delivered_at: string
  marked_delivered_at: string
  delivery_issue_type: string
  delivery_issues: string
}

const DeliveryInfo: React.FC<{ delivery: DeliveryInfoProps }> = observer(({ delivery }) => {
  const theme = useTheme()

  const { state, estimated_delivered_at, carrier, actual_delivered_at, marked_delivered_at } = delivery
  const { delivery_issue_type, delivery_issues } = delivery
  const formattedDeliveryDate = estimated_delivered_at && formatDateString(estimated_delivered_at)

  // TODO: ETA is the wrong word here
  let text = 'ETA: Unknown'
  const color = theme.colors['gray-7']

  if (state == DeliveryStates.DELIVERED) {
    // TODO: Include date of delivery, right now we don't always capture this
    const delivered_at = formatDateString(actual_delivered_at || marked_delivered_at)
    text = `Delivered on ${delivered_at}`
  } else if (estimated_delivered_at && carrier) {
    text = `${carrier}, ETA: ${formattedDeliveryDate}`
  } else if (estimated_delivered_at && !carrier) {
    text = `ETA: ${formattedDeliveryDate}`
  } else if (!estimated_delivered_at && carrier) {
    text = `${carrier}, ETA: Unknown`
  }

  return (
    <Box display="flex" alignItems="center" pt={5} style={{ gap: 10 }}>
      <Typography.Text ellipsis style={{ color }}>
        {text}
      </Typography.Text>

      {delivery.has_open_issue && (
        <Tooltip title={delivery_issues}>
          <Tag color="red" style={{ margin: 0 }}>
            {formatEnumValue(delivery_issue_type)}
          </Tag>
        </Tooltip>
      )}
    </Box>
  )
})

export default DeliveryInfo
