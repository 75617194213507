import React from 'react'

import { OrdersList } from 'common/components/OrdersList'

interface Props {
  vendorName: string
}

export function CellVendorName(props: Props) {
  const { vendorName } = props

  if (!vendorName || vendorName.length === 0) return <OrdersList.Cell.NoContent />

  return <div>{vendorName}</div>
}
