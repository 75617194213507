import React from 'react'

import _ from 'lodash'

import { Button } from 'antd'

import { OrderStates } from 'common/server/server_types'

import { UpdatesMappings } from 'contractor/components/OrderQuickCommentModal/order_quick_comment_modal'

interface Props {
  onQuickComment: () => void
  orderState: OrderStates
}

export function CellAction(props: Props) {
  const { onQuickComment, orderState } = props

  return (
    <Button
      onClick={(event) => {
        event.stopPropagation()
        onQuickComment()
      }}
    >
      {_.get(UpdatesMappings, `${orderState}.actionText`)}
    </Button>
  )
}
