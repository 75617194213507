import React, { useEffect } from 'react'

import { DirectUpload } from 'activestorage'

import { InboxOutlined } from '@ant-design/icons'
import { message, Upload, Typography } from 'antd'
import { UploadProps } from 'antd/lib/upload'

import { noticeError } from 'common/helpers/new_relic'
import { useTheme } from 'common/hooks/use-theme'

import { Wrapper } from './styles'

type AttachmentsProps = {
  noResetUploads?: boolean
  onUpdateUpload?: (uploadKey: string, uid: string, precent: number, signedId: string) => void
  onResetUploads?: (uploadKey: string) => void
  onAddNewUpload?: (uploadKey: string, directUpload: DirectUpload, file: File) => void
  onSetUploadError?: (uploadKey: string, uid: string) => void
  onRemoveUpload?: (uploadKey: string, uid: string) => void
  fileList: UploadProps['fileList']
  uploadKey: string
  isResponsive?: boolean
} & UploadProps

export const Attachments = ({
  uploadKey,
  noResetUploads,
  onUpdateUpload,
  onResetUploads,
  onAddNewUpload,
  onSetUploadError,
  onRemoveUpload,
  multiple,
  isResponsive = true,
  ...props
}: AttachmentsProps) => {
  const theme = useTheme()

  const handleProgress = (event, uid) => {
    const percent = (event.loaded / event.total) * 100
    onUpdateUpload?.(uploadKey, uid, percent, '')
  }

  const handleUpload = (request) => {
    const uid = request.file['uid']

    const file = request.file

    const directUpload = new DirectUpload(file, '/rails/active_storage/direct_uploads', {
      directUploadWillStoreFileWithXHR: (request) => {
        request?.upload.addEventListener('progress', (event) => handleProgress(event, uid))
      },
    })

    // If we aren't allowing multiple uploads we must remove the existing upload (no need to handle)
    // deletes, we will do the purging server side
    if (!multiple) {
      onResetUploads?.(uploadKey)
    }

    onAddNewUpload?.(uploadKey, directUpload, file)

    directUpload.create((error, blob) => {
      if (error) {
        // TODO: Handle error a little better than this...
        console.error('Uploader error', error)
        message.error('Upload error ')
        onSetUploadError?.(uploadKey, uid)
        noticeError(error, { entry: 'direct-upload' })
      } else {
        onUpdateUpload?.(uploadKey, uid, 100, blob.signed_id)
      }
    })
  }

  useEffect(() => {
    !noResetUploads && onResetUploads?.(uploadKey)
  }, [noResetUploads, uploadKey, onResetUploads])

  return (
    <Wrapper isResponsive={isResponsive}>
      <Upload.Dragger
        onRemove={(file) => {
          onRemoveUpload?.(uploadKey, file.uid)
        }}
        customRequest={handleUpload}
        multiple={multiple}
        {...props}
      >
        <InboxOutlined style={{ fontSize: 24, color: theme.colors.highlightBlue }} />
        <Typography.Paragraph style={{ margin: 0 }}>Drag to upload</Typography.Paragraph>
      </Upload.Dragger>
    </Wrapper>
  )
}
