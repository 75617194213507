import { CellQuantity } from 'contractor/pages/@v2/OrderMaterials/components/cells/cell_quantity'

import { CellCostCode } from 'contractor/pages/@v2/OrderMaterials/components/cells/cell_cost_code'
import { CellPhaseCode } from 'contractor/pages/@v2/OrderMaterials/components/cells/cell_phase_code'
import { CellPreferredVendors } from 'contractor/pages/@v2/OrderMaterials/components/cells/cell_preferred_vendors'
import { CellVendorNote } from 'contractor/pages/@v2/OrderMaterials/components/cells/cell_vendor_note'

export const OrderMaterialCells = {
  Quantity: CellQuantity,
  VendorNote: CellVendorNote,
  CostCode: CellCostCode,
  PhaseCode: CellPhaseCode,
  PreferredVendors: CellPreferredVendors,
}
