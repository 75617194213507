import { SearchOptions, MultipleQueriesQuery } from '@algolia/client-search'
import algoliasearch, { SearchClient, SearchIndex } from 'algoliasearch'
import _ from 'lodash'
import { BindAll } from 'lodash-decorators'
import insightsClient from 'search-insights'

import { observable, action } from 'mobx'

import { SearchKeysResponse } from 'common/server/server_types'

@BindAll()
export default abstract class AlgoliaBaseStore<Hit = null> {
  abstract getSearchKey

  hits = observable.array<Hit>([], { deep: true })
  replicas = observable.array<string>([])
  @observable searchKey: SearchKeysResponse = {
    search_key: '',
    application_id: '',
    index_name: '',
    replica_index_name: '',
    replica_index_search_key: '',
  }
  @observable isSorted = false
  @observable refreshHits = false
  @observable needsRefreshHits = false
  searchClient: SearchClient
  searchReplicaClient: SearchClient
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchIndex: SearchIndex

  @observable searchState: {} = { query: '', page: 1 }

  forceRefreshHits(clearState = false) {
    if (this.searchClient) {
      this.enableRefreshHits()
      this.searchClient.clearCache().then(() => {
        if (clearState) {
          this.onSearchStateChange({ page: 1 })
        }
        this.disableRefreshHits()
      })
    }
  }

  @action
  enableRefreshHits() {
    this.refreshHits = true
  }
  @action
  disableRefreshHits() {
    this.refreshHits = false
  }

  maybeLoadSearchKey() {
    if (!this.searchKey.application_id) {
      return this.loadSearchKey()
    }
  }

  // SEARCH
  async loadSearchKey() {
    const { data } = await this.getSearchKey()
    this.initializeSearchKey(data)
  }

  initializeSearchKey(searchKey: SearchKeysResponse) {
    const {
      search_key,
      application_id,
      index_name,
      index_names,
      replicas,
      replica_index_name,
      replica_index_search_key,
    } = searchKey
    this.searchClient = algoliasearch(application_id, search_key)
    this.searchReplicaClient = algoliasearch(application_id, replica_index_search_key)
    this.searchIndex = this.searchClient.initIndex(index_name)
    // Need to do this last, otherwise will start search too early
    this.searchKey = {
      search_key,
      application_id,
      index_name,
      index_names,
      replica_index_name,
      replica_index_search_key,
    }
    this.replicas.replace(replicas || [])
  }

  initializeInsightsClient(searchKey: SearchKeysResponse) {
    const { search_key, application_id } = searchKey
    insightsClient('init', { appId: application_id, apiKey: search_key, useCookie: true })
  }

  setInsightsUserToken(userToken: string) {
    insightsClient('setUserToken', userToken)
  }

  getInsightsClient() {
    return insightsClient
  }

  getInsightsUserToken() {
    let token = null
    insightsClient('getUserToken', null, (_, userToken) => {
      token = userToken
    })

    return token
  }

  async refresh(delay = 1000) {
    // Ideally we would actually just use the refresh functionality but currently a bug with that so just
    // Re-initializing the searchkey is ugly but remounts the component
    // https://github.com/algolia/react-instantsearch/issues/2995
    window.setTimeout(async () => {
      this.searchKey = { search_key: '', application_id: '', index_name: '' }
      this.loadSearchKey()
    }, delay)

    // Ideal...
    // this.refreshHits = true
    // window.setTimeout(async () => {
    //   this.refreshHits = false
    // }, 1000)
  }

  @action
  onSearchStateChange(newSearchState) {
    this.searchState = newSearchState
  }

  async setHits(hits: Hit[]): Promise<void> {
    this.hits.replace(hits)
  }

  // https://www.algolia.com/doc/api-reference/api-methods/multiple-queries/?client=javascript
  async multipleQueries(queries: MultipleQueriesQuery[]) {
    const values = await this.searchClient.multipleQueries(queries)
    return values.results
  }

  // If you don't want to use InstantSearch can use this, don't save it as part of the hits because things can get confusing
  // https://www.algolia.com/doc/api-reference/api-methods/search/?language=javascript
  async query(query_string: string, request_options = {}) {
    const values = await this.searchIndex.search<Hit>(query_string, request_options)
    return values.hits
  }

  async queryNbHits(query_string: string, request_options = {}) {
    const values = await this.searchIndex.search<Hit>(query_string, request_options)
    return values.nbHits
  }

  /*
    Return all items from the index
    https://www.algolia.com/doc/api-reference/api-methods/browse/
  */
  async getAllIndexData(indexName: string, requestOptions: SearchOptions) {
    const client = this.searchClient.initIndex(indexName)

    let hits: Hit[] = []

    await client.browseObjects<Hit>({
      ...requestOptions,
      batch: (batch) => {
        hits = [...hits, ...batch]
      },
    })

    return hits
  }

  convertRefinementList(searchState) {
    const options = []
    _.forOwn(searchState['refinementList'], (value, key) => {
      const facetFilter = []

      if (Array.isArray(value)) {
        _.each(value, (v) => facetFilter.push(`${key}:${v}`))
      } else {
        facetFilter.push(`${key}:${value}`)
      }

      options.push(facetFilter)
    })

    _.forOwn(searchState['toggle'], (value, key) => {
      const facetFilter = []

      if (Array.isArray(value)) {
        _.each(value, (v) => {
          console.log(key, v)
          if (v == 'false') return
          facetFilter.push(`${key}:${v}`)
        })
      } else {
        if (value == 'false') return
        facetFilter.push(`${key}:${value}`)
      }

      options.push(facetFilter)
    })
    return options
  }

  convertRange(range) {
    const numericFilter = []
    _.forOwn(range, (value, key) => {
      console.log({ ...value }, key)
      value['min'] && numericFilter.push(`${key}>=${value['min']}`)
      value['max'] && numericFilter.push(`${key}<=${value['max']}`)
    })
    return numericFilter
  }
}
