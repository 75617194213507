import axios from 'axios'

import { Comment } from 'common/server/comment'
import { OrderDelivery } from 'common/server/deliveries'
import { InvoiceMaterial } from 'common/server/invoice'
import { OrderHistoryResponse } from 'common/server/order_history'
import { OrderHit, OrderMaterial } from 'common/server/orders'
import {
  File,
  IntegrationsSourceSystem,
  InvoicesStates,
  InvoicesStatuses,
  OrderStates,
  OrderSubStates,
  QuoteExtractionStates,
  RelationshipStockStatus,
  SearchKeysResponse,
} from 'common/server/server_types'

import { BasicRelationship } from 'contractor/server/integrations'
import { ProjectGroupsList } from 'contractor/server/project_groups'

export interface OrderIntegration {
  last_sync_at: Nullable<string>
  status: RelationshipStockStatus
  source: IntegrationsSourceSystem
  errors: {
    fail_message: string
    fail_body: string
  }[]
  web_url: Nullable<string>
}

export interface SimpleProject {
  id: string
  name: string
  project_id?: string
  external_relationships: Nullable<BasicRelationship[]>
  project_group?: ProjectGroupsList
  tax_line_items: { enabled: boolean }
}

export interface ShowOrderResponse {
  id: string
  commitment_id?: string
  commitment?: {
    id: string
    name: string
    number: string
  }
  po_history?: { order_number?: string; company_vendor_name?: string }[]
  order_number: string
  quote_number?: string
  quote_extraction: {
    id: string
    quote_state?: QuoteExtractionStates
    quote_extracted?: boolean
    quote_fully_matched?: boolean
  }
  state: OrderStates
  sub_state: OrderSubStates
  confirmed_at: string
  total_invoiced_amount: number
  grand_total: string
  sub_total: string
  quote_scheduled_to_expire_at?: string
  order_package_name: string
  order_package_id: string
  requested_at: string
  requested_by_name?: string
  requested_by_id?: string
  approved_at?: string
  approved_by_name?: string
  project_id: string
  project: SimpleProject
  ordered_at: string
  quoted_at?: string
  tags: string[]
  company_user: {
    id: string
    full_name: string
  }
  requested_by_company_user_id: string
  ordered_by_company_user: {
    id: string
    full_name: string
  }
  watcher_ids: string[]
  company_vendor: {
    id: string
    vendor_name: string
    external_vendor_id?: string
    external_relationships: Nullable<BasicRelationship[]>
    vendor?: {
      id: string
      name: string
    }
  }
  vendor_contacts: {
    id: string
    first_name: string
    last_name: string
    email: string
    nickname?: string
    vendor_user?: {
      id: string
      first_name: string
      last_name: string
      email: string
    }
  }[]
  company: {
    id: string
    name: string
    is_owner: boolean
  }
  order_materials: OrderMaterial[]
  deliveries: OrderDelivery[]
  purchaser_files: File[]
  vendor_files: File[]
  purchase_order?: File
  request_for_quote?: File
  material_request?: File
  quote?: File
  comments: Comment[]
  internal_comments: Comment[]
  vendor_contact_ids?: string[]
  updated_at: string
  quick_created_at?: string
  drafted_request_at?: string
  drafted_request_by_id?: string
  cancellation_rejected_at?: string
  cancellation_accepted_at?: string
  cancellation_requested_at?: string
  cancellation_requested_reason?: string
  count_order_package: number
  invoices: {
    id: string
    number: string
    status: InvoicesStatuses
    total_amount?: string
    invoice_grand_total?: string
    rejection_reason?: string
    document_date?: string
    created_at: string
    assigned_to?: {
      name: string
      email: string
    }
    state: {
      id: string
      label: string
      color: string
      state: InvoicesStates
    }
    discount_amount: string
    shipping_cost: string
    other_costs: string
    tax_amount: string
    invoice_materials: Omit<InvoiceMaterial, 'order_materials'>[]
  }[]
  is_fully_invoiced?: boolean
  integration: OrderIntegration
  manual_exported_at?: string
  terms_and_condition_id?: string
}

export interface OrderSettingResponse {
  order_tags: string[]
}

export interface OrderStateChanges {
  quoted_at?: string
  ordered_at?: string
  cancelled_at?: string
  requested_at?: string
  drafted_request_at?: string
  cancellation_requested_at?: string
  cancellation_requested_reason?: string
}

export interface CreateOrderRequest {
  order_package_id?: string
  order_package_name: string
  order_number?: string
  quote_number?: string
  vendors?: {
    id: string
    company_vendor_contact_ids: string[]
    comment?: string
  }[]
  state_changes: OrderStateChanges
  project_id: string
  order_materials: OrderMaterial[]
  deliveries: OrderDelivery[]
  purchaser_files_signed_ids: string[]
  purchaser_files_delete_ids: string[]
  watcher_ids: string[]
  tags?: string[]
  internal_comment?: string
  requested_by_company_user_id?: string
  terms_and_condition_id?: string
  commitment_id?: string
  splitted_from_id?: string
  splitted_to_id?: string
}

export interface CreateQuickPORequest {
  project_id: string
  company_vendor_id: string
  order_package_name: string
  order_number: string
  grand_total?: string // The order doesn't have grand_total attr, we put the value inside delivery > other_value
  order_date?: string // Used to fill marked_delivered_at
  comment?: string

  // Used only when the user marks 'Create Materials using Invoice'
  order_materials?: OrderMaterial[]
  other_value?: number
  shipping_value?: number
  tax_value?: number
  discount_value?: number
}

export interface UpdateOrderRequest {
  id: string
  project_id: string
  state_changes: OrderStateChanges
  order_package_name: string
  order_number: string
  order_materials: OrderMaterial[]
  purchaser_files_signed_ids: string[]
  purchaser_files_delete_ids: string[]
  quote_signed_id?: string
  comment?: string
  watcher_ids: string[]
  tags: string[]
  silent_update?: boolean
  vendor_contact_ids?: string[]
  order_saving_costs?: OrderSavingCostsRequest
}

export interface ConfirmOrderRequest {
  id: string
}

export type DeliveryPreference = 'existing' | 'new'

export interface OrderCreateResponse {
  id: string
  order_number: string
  order_package_id: string
  state: OrderStates
  sub_state: OrderSubStates
  purchase_order: File
}

export interface OrderSavingCostsRequest {
  order_package_id: string
  original_avg_grand_total: string
  original_avg_sub_total: string
  ordered_grand_total: string
  ordered_sub_total: string
  num_quotes_requested: number
  num_orders_placed: number
  order_breakdown: {
    order_id: string
    original_sub_total: string
    original_grand_total: string
  }[]
}

export interface CreateMergeOrderRequest {
  draft_id: string
  order_id: string
  preference: DeliveryPreference
  send_notification?: boolean
}

export interface CreatePlaceLevelingQuotesRequest {
  id: string
  order_number?: string
  state_changes: OrderStateChanges
  terms_and_condition_id?: string
  order_materials?: Pick<OrderMaterial, 'id' | 'quantity' | 'company_material_id' | 'delivery_id'>[]
}

export function manual_export(orders: { id: string }[]) {
  return axios.post<{ orders: { id: string; manual_exported_at: string }[] }>('/order/manual_export', { orders })
}

export function search_key() {
  return axios.get<SearchKeysResponse>('/orders/search_key')
}

export function show(id: string) {
  return axios.get<ShowOrderResponse>(`/order/${id}`)
}

export function pdf(id: string) {
  return axios.get(`/order/${id}.pdf`, { responseType: 'arraybuffer' })
}

export function getOrderSettings() {
  return axios.get<OrderSettingResponse>(`/order_settings`)
}

export function getOrdersLastUpdated(project_id?: string, state?: string, allowed_project_ids?: string[]) {
  let url = '/orders_last_updated'

  if (project_id) {
    url += `?project_id=${project_id}`
  } else {
    url += `?allowed_project_ids=${allowed_project_ids}`
  }

  if (state) {
    url += `&state=${state}`
  }

  return axios.get<OrderHit[]>(url)
}

export function create(payload: CreateOrderRequest) {
  return axios.post<{ orders: OrderCreateResponse[] }>('/order', payload)
}

export function create_quick_po(payload: CreateQuickPORequest) {
  return axios.post<{ orders: OrderCreateResponse[] }>('/order/quick_po', payload)
}

export function update(payload: UpdateOrderRequest) {
  return axios.patch<ShowOrderResponse>(`/order/${payload['id']}`, payload)
}

export function confirmOrder(payload: { id: string }) {
  return axios.post<ShowOrderResponse>(`/order/confirm_order`, payload)
}
export function approveOrder(payload: { id: string }) {
  return axios.post<ShowOrderResponse>(`/order/approve_order`, payload)
}
export function mergeOrder(payload: CreateMergeOrderRequest) {
  return axios.post<ShowOrderResponse>(`/order/merge_order`, payload)
}
export function undiscardOrder(payload: { id: string; restore_as_order: boolean }) {
  return axios.post<ShowOrderResponse>(`/order/undiscard_order`, payload)
}
export function request_update(payload: { id: string; comment: string }) {
  return axios.patch<ShowOrderResponse>(`/order/request_update/${payload['id']}`, payload)
}

export function history(id: string) {
  return axios.get<{ history: OrderHistoryResponse[] }>(`/order/${id}/history`)
}

export function create_place_leveling_quotes(orders: CreatePlaceLevelingQuotesRequest[]) {
  return axios.post<{ orders: OrderCreateResponse[] }>('/order/place_leveling_quotes', { orders })
}

export function internal_comment(payload: { id: string; comment: string; company_user_ids: string[] }) {
  return axios.post<ShowOrderResponse>(`/order/${payload['id']}/internal_comment`, payload)
}

export function export_orders(params?: { [key: string]: string }) {
  return axios.post('/consolidated_orders/export', params)
}
