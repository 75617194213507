import React from 'react'

import { Box } from 'common/components/boxes'

type NotFoundProps = {
  value?: string
}

export const NotFound = (props: NotFoundProps) => {
  const { value } = props

  return (
    <Box textAlign="center" py={12}>
      {`No items found for "${value}"`}
    </Box>
  )
}
