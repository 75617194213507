import React from 'react'

import { useParams } from 'react-router-dom'

import { Button, Space, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { noticeError } from 'common/helpers/new_relic'

import { useStores } from 'contractor/hooks/use-stores'

import { useEditPriceSheet } from '../context'

type ActionsProps = {
  priceSheetState: string
  withSpace?: boolean
}

export const Actions = observer<ActionsProps>(({ priceSheetState, withSpace }) => {
  const { priceSheetStore } = useStores()
  const { form, setApplyPreferredPrice, goBack } = useEditPriceSheet()

  const { id } = useParams<{ id: string }>()

  const WrapperComponent = withSpace ? Space : React.Fragment

  const handleExpirePriceSheet = async () => {
    try {
      await priceSheetStore.expirePriceSheet(id)
      message.success('Successfully expired price sheet')
      goBack()
    } catch (error) {
      noticeError(error, { entry: 'expire-price-sheet' })
      message.error(error?.response?.data?.error || 'Unable to expire price sheet')
    }
  }

  if (priceSheetState === 'draft') {
    return (
      <WrapperComponent>
        <Button onClick={() => form.submit()}>Update draft</Button>
        <Button
          onClick={() => {
            setApplyPreferredPrice(true)
            form.submit()
          }}
          type="primary"
        >
          Apply preferred price
        </Button>
      </WrapperComponent>
    )
  }

  if (['active', 'not_started'].includes(priceSheetState)) {
    return (
      <WrapperComponent>
        <Button danger onClick={handleExpirePriceSheet}>
          Expire
        </Button>
        <Button onClick={() => form.submit()} type="primary">
          Update
        </Button>
      </WrapperComponent>
    )
  }

  return null
})
