import React from 'react'

import { AuditOutlined, EditOutlined, FileDoneOutlined, FileSyncOutlined, LeftCircleFilled } from '@ant-design/icons'
import { Avatar, Popover, Space, Typography } from 'antd'

import OrderState from 'common/components/statuses/order_state'
import { useTheme } from 'common/hooks/use-theme'
import { CommitmentStatuses, OrderStates, OrderSubStates } from 'common/server/server_types'

import { InvoiceStates } from 'contractor/server/company_settings/invoice_states'

export type MarkerProps = {
  orderState?: OrderStates
  orderSubState?: OrderSubStates
  invoiceState?: InvoiceStates
  commitmentState?: CommitmentStatuses
}

export const Marker = ({ orderState, orderSubState, invoiceState, commitmentState }: MarkerProps) => {
  const theme = useTheme()

  if (commitmentState) {
    return (
      <Avatar
        style={{
          backgroundColor: theme.colors['gold-1'],
          borderColor: theme.colors['gold-3'],
          color: theme.colors['gray-13'],
        }}
      >
        <AuditOutlined />
      </Avatar>
    )
  }

  if (invoiceState) {
    const [first, second] = invoiceState?.label?.split(' ')

    const initials = second ? `${first?.substring(0, 1)}${second?.substring(0, 1)}` : first?.substring(0, 2)
    const color = theme.colors[`${invoiceState?.color || 'gray'}-6`]

    return (
      <Popover
        placement="right"
        content={
          <Space>
            <Typography.Text>{invoiceState?.label}</Typography.Text>
            <LeftCircleFilled style={{ color }} />
          </Space>
        }
      >
        <Avatar style={{ backgroundColor: color }}>{initials?.toUpperCase()}</Avatar>
      </Popover>
    )
  }

  if (orderState || orderSubState) {
    const orderStateMapping = {
      [OrderStates.REQUESTED]: {
        backgroundColor: theme.colors['gold-1'],
        borderColor: theme.colors['gold-3'],
        icon: <AuditOutlined />,
      },
      [OrderStates.DRAFT]: {
        backgroundColor: theme.colors['gold-1'],
        borderColor: theme.colors['gold-3'],
        icon: <EditOutlined />,
      },
      [OrderStates.APPROVED]: {
        backgroundColor: theme.colors['gold-1'],
        borderColor: theme.colors['gold-3'],
      },
      [OrderStates.QUOTED]: {
        backgroundColor:
          orderSubState == OrderSubStates.QUOTED_RECEIVED ? theme.colors['blue-1'] : theme.colors['gold-1'],
        borderColor: orderSubState == OrderSubStates.QUOTED_RECEIVED ? theme.colors['blue-3'] : theme.colors['gold-3'],
        icon: orderSubState == OrderSubStates.QUOTED_RECEIVED ? <FileSyncOutlined /> : undefined,
      },
      [OrderStates.ORDERED]: {
        backgroundColor:
          orderSubState == OrderSubStates.ORDERED_CONFIRMED ? theme.colors['blue-1'] : theme.colors['gold-1'],
        borderColor:
          orderSubState == OrderSubStates.ORDERED_CONFIRMED ? theme.colors['blue-3'] : theme.colors['gold-3'],
        icon: orderSubState == OrderSubStates.ORDERED_CONFIRMED ? <FileDoneOutlined /> : undefined,
      },
      [OrderStates.SHIPPED]: { backgroundColor: theme.colors['green-1'], borderColor: theme.colors['green-3'] },
      [OrderStates.PARTIALLY_SHIPPED]: {
        backgroundColor: theme.colors['green-1'],
        borderColor: theme.colors['green-3'],
      },
      [OrderStates.DELIVERED]: { backgroundColor: theme.colors['green-1'], borderColor: theme.colors['green-3'] },
      [OrderStates.CANCELLED]: { backgroundColor: theme.colors['gray-2'], borderColor: theme.colors['gray-3'] },
    }

    const params = orderStateMapping[orderState]

    return (
      <Popover placement="right" content={<OrderState state={orderState} sub_state={orderSubState} size="medium" />}>
        <Avatar
          style={{
            backgroundColor: params?.backgroundColor,
            border: `1px solid ${params?.borderColor}`,
            color: theme.colors['gray-13'],
          }}
        >
          {params?.['icon']}
        </Avatar>
      </Popover>
    )
  }

  return <Avatar />
}
