import React from 'react'

import moment from 'moment'

import FlagTwoTone from '@ant-design/icons/FlagTwoTone'
import WarningTwoTone from '@ant-design/icons/WarningTwoTone'
import { Tooltip } from 'antd'

import { FlexBoxY, FlexBoxX } from 'common/components/boxes'
import OrderState, { OrderStateTagProps } from 'common/components/statuses/order_state'
import { OrderHit } from 'common/server/orders'

export type CellOrderStateProps = {
  notificationsCount?: number
  showWarnings?: boolean
  order: OrderHit
  showDeliveryIssue?: boolean
} & Pick<OrderStateTagProps, 'style'>

type OrderWarningsProps = { order: OrderHit }

const OrderWarnings = ({ order }: OrderWarningsProps) => {
  const today = moment().unix()
  const weekFromToday = moment().add(7, 'd').unix()

  const { lead_time_cutoff_date_timestamp } = order

  const warnings: string[] = []
  const errors: string[] = []

  // Order By Date
  if (lead_time_cutoff_date_timestamp && lead_time_cutoff_date_timestamp < today) {
    errors.push('Based on your lead times and requested delivery date this order will not arrive in time')
  } else if (lead_time_cutoff_date_timestamp && lead_time_cutoff_date_timestamp < weekFromToday) {
    warnings.push(
      'Based on your lead times and requested delivery date you must place this order now for it to arrive on time',
    )
  }

  const messages = [...errors, ...warnings].join(', ')
  if (messages.length == 0) {
    return null
  }

  return (
    <Tooltip title={messages}>
      {errors.length > 0 ? (
        <FlagTwoTone twoToneColor="#eb2f96" style={{ margin: '0 10px' }} />
      ) : (
        <WarningTwoTone twoToneColor="#F7CE3E" style={{ margin: '0 10px' }} />
      )}
    </Tooltip>
  )
}

export const CellOrderState = ({
  order,
  notificationsCount,
  showWarnings = true,
  style,
  showDeliveryIssue = true,
}: CellOrderStateProps) => {
  const { state, sub_state, has_open_delivery_issue = false, deliveries = [] } = order
  return (
    <FlexBoxX justifyContent="center">
      <FlexBoxY justifyContent="center">
        <OrderState
          state={state}
          sub_state={sub_state}
          notificationCount={notificationsCount}
          showIssue={showDeliveryIssue && has_open_delivery_issue}
          deliveryIssues={deliveries.map((d) => d.delivery_issue_type)}
          style={style}
        />
      </FlexBoxY>
      {showWarnings && <OrderWarnings order={order} />}
    </FlexBoxX>
  )
}
