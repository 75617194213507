import { BindAll } from 'lodash-decorators'

import { action, observable } from 'mobx'

import { AutoMatchOptions, getQuote, Quote, reportQuoteIssue, UnmatchedMaterial } from 'contractor/server/quote'

@BindAll()
export default class QuoteStore {
  @observable quote: Quote | null = null

  @observable isSaving = false

  @action
  async getQuote(orderId: string, quoteId: string) {
    if (quoteId) {
      this.quote = (await getQuote(orderId, quoteId)).data
    }
    return this.quote
  }

  @action
  updateQuoteLineItems(autoMatchOptions: AutoMatchOptions[]) {
    this.quote.automatch_options = autoMatchOptions
  }

  @action
  updateUnquotedMaterials(newUnquotedMaterials: string[]) {
    this.quote.unquoted_materials = newUnquotedMaterials
  }

  @action
  updateUnmatchedMaterials(newUnmatchedMaterials: UnmatchedMaterial[]) {
    this.quote.unmatched_materials = newUnmatchedMaterials
  }

  reportQuoteIssue(quoteId: string, issue: string, orderId: string) {
    return reportQuoteIssue(quoteId, issue, orderId)
  }
}
