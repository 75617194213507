import { BindAll } from 'lodash-decorators'

import { action, observable } from 'mobx'

import {
  index,
  ProjectInvoiceAssignee,
  upsert,
  UpsertProjectInvoiceAssignee,
} from 'contractor/server/project_invoice_assignees'

@BindAll()
export default class ProjectInvoiceAssigneeStore {
  projectInvoiceAssignees = observable.array<ProjectInvoiceAssignee>([])

  @action
  async getAllProjectInvoiceAssignees() {
    const { data } = await index()
    this.projectInvoiceAssignees.replace(data?.project_invoice_assignees || [])
  }

  async upsert(payload: UpsertProjectInvoiceAssignee[]) {
    await upsert(payload)
    this.getAllProjectInvoiceAssignees()
  }
}
