import React, { useMemo, useState } from 'react'

import { sortBy } from 'lodash'

import { DeleteOutlined, ForwardOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Popconfirm, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer } from 'common/components/Drawer'
import { RibbonFilter } from 'common/components/RibbonFilter'
import { InvoiceResponse } from 'common/server/invoice'

import { useStores } from 'contractor/hooks/use-stores'

import { OrderSearch } from '../OrderDetailNew'
import { TagWrapper } from './tag_wrapper'

type OrdersTagsProps = {
  noFilter?: boolean
  disabled?: boolean
  attachedOrderIds: string[]
  onAttachOrder: (orderId: Nullable<string>) => void
  onUnAttachOrder: (orderId: Nullable<string>) => void
  value?: string[]
  onChange?: (value: string[]) => void
  saveInvoice: (autoMatch?: boolean, orderIds?: string[]) => Promise<InvoiceResponse>
}

export const OrdersTags = observer<OrdersTagsProps>(
  ({ attachedOrderIds = [], onAttachOrder, onUnAttachOrder, value, onChange, noFilter, saveInvoice, disabled }) => {
    const { invoiceStore } = useStores()

    const [showLinkOrderModal, toggleLinkOrderModal] = useState(false)

    const invoice = invoiceStore.invoice

    const makeOrderFilterOption = ({ order }) => ({
      wrapper: (children) => <TagWrapper order={order}>{children}</TagWrapper>,
      leftIcon: order?.quick_created_at ? <ForwardOutlined /> : undefined,
      label: order?.order_number,
      filter: order?.id,
      rightIcon: !disabled && (
        <Popconfirm
          title="Are you sure to remove this order?"
          okText="Yes"
          cancelText="No"
          onConfirm={(e) => {
            e.stopPropagation()
            onUnAttachOrder(order.id)
            if (value?.includes(order.id) || !invoiceStore.selectedOrders?.length) {
              onChange?.(['all'])
            }
          }}
        >
          <DeleteOutlined onClick={(e) => e.stopPropagation()} />
        </Popconfirm>
      ),
    })

    const ordersOptions = sortBy(invoiceStore.selectedOrders, ({ order }) => order?.order_number)?.map(
      makeOrderFilterOption,
    )

    const allOrdersOptions = [
      ...(invoiceStore.selectedOrders.length ? [{ label: 'All', filter: 'all' }] : []),
      ...ordersOptions,
    ]

    const linkOrderButton = useMemo(
      () => (
        <Tooltip title="Link Order" placement="topRight">
          <Button
            disabled={disabled}
            icon={<PlusOutlined />}
            shape="circle"
            size="small"
            onClick={() => toggleLinkOrderModal(true)}
          />
        </Tooltip>
      ),
      [],
    )

    const drawer = useMemo(
      () => (
        <Drawer
          title="Link Order"
          bgGray
          width={720}
          open={showLinkOrderModal}
          onClose={() => toggleLinkOrderModal(false)}
          placement="left"
          closable={false}
        >
          <Box width="100%" overflowY="auto" p={16}>
            {!!attachedOrderIds?.length && (
              <Box mb={20}>
                <Typography.Text>Linked Orders</Typography.Text>
                <RibbonFilter options={ordersOptions} />
              </Box>
            )}

            <OrderSearch
              onSelect={(order) => {
                onAttachOrder(order.id)
                toggleLinkOrderModal(false)
              }}
              onCreated={(order) => {
                onAttachOrder(order.id)
                toggleLinkOrderModal(false)
              }}
              initialValues={{
                grandTotal: invoice?.total_amount,
                orderNumber: invoice?.extracted_order_number,
                companyVendorId: invoice?.company_vendor_id,
              }}
              onClickToCreateQuickPO={() => toggleLinkOrderModal(false)}
              saveInvoice={saveInvoice}
            />
          </Box>
        </Drawer>
      ),
      [
        onAttachOrder,
        showLinkOrderModal,
        attachedOrderIds.length,
        invoice?.total_amount,
        invoice?.extracted_order_number,
        invoice?.company_vendor_id,
      ],
    )

    if (noFilter) {
      return (
        <>
          {drawer}
          <Box display="inline-flex" alignItems="center">
            <RibbonFilter options={ordersOptions} />
            {!attachedOrderIds.length && linkOrderButton}
          </Box>
        </>
      )
    }

    return (
      <>
        {drawer}
        <Box
          width="100%"
          display="flex"
          alignContent="center"
          justifyContent="space-between"
          position="sticky"
          top={-15}
          zIndex={1}
          py={8}
          bg="gray-2"
          height={48}
        >
          {!!invoiceStore.selectedOrders.length && (
            <Box display="inline-flex" alignItems="center">
              <RibbonFilter value={value} onChange={onChange} options={allOrdersOptions} />
              <Divider type="vertical" />
              {linkOrderButton}
            </Box>
          )}
        </Box>
      </>
    )
  },
)
