import React, { useState } from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Typography, List, message, Tabs, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'
import { useQuery } from 'common/hooks/use-query'

import { filterTextByFields } from 'contractor/helpers/filter-text-by-fields'
import { useStores } from 'contractor/hooks/use-stores'
import { VendorUser } from 'contractor/server/vendors'

import { Loading } from './loading'
import { MyContacts } from './my_contacts'
import { SalesRepresentatives } from './sales_representatives'
import { VendorCard } from './vendor_card'
import { VendorProfileProps } from './vendor_profile'
import { VendorUserCard } from './vendor_user_card'
import { Wrapper } from './wrapper'

export type VendorProfileContentProps = {
  setActiveKey: (key: 'sales-representatives' | 'my-contacts') => void
  activeKey: 'sales-representatives' | 'my-contacts'
  onAddNewVendorContact: () => void
} & VendorProfileProps

export const VendorProfileContent = observer<VendorProfileContentProps>((props) => {
  const {
    vendorId,
    hideCreate,
    onSubmitAndAdd,
    companyVendor: companyVendorProp,
    orderType,
    onAddNewVendorContact,
    activeKey,
    setActiveKey,
  } = props
  const { vendorStore, companyVendorStore } = useStores()

  const vendor = vendorStore.selectedVendor
  const companyVendor = companyVendorProp || vendorStore.selectedVendor?.company_vendor

  const { isLoading } = useQuery(() => {
    if (!vendorId) return
    return vendorStore.getVendorById(vendorId)
  }, [vendorId])

  const [isSubmitting, setSubmitting] = useState(false)
  const [searchText, setSearchText] = useState('')

  const handleConnect = async (vendorId: string, vendorUserId: string, isConnectAndAdd = false) => {
    setSubmitting(true)

    try {
      const newVendorContact = await companyVendorStore.connectToVendor({ vendorId, vendorUserId })
      vendorStore.getVendorById(vendorId)
      message.success('Successfully to connect')

      if (isConnectAndAdd && !!onSubmitAndAdd) {
        onSubmitAndAdd(newVendorContact)
      }
    } catch (err) {
      message.error(err?.response?.data?.error || 'Unable to connect')
    } finally {
      setSubmitting(false)
    }
  }

  const handleSearchText = (text = '') => setSearchText(text.trim())

  const handeFilterTextVendorUser = (vendorUser: VendorUser) => {
    return filterTextByFields({ fields: ['full_name', 'email', 'headline'], obj: vendorUser, searchText })
  }

  const getCompanyVendorContact = (companyVendorId: string) => {
    setSubmitting(true)

    try {
      companyVendorStore.companyVendorContactListStore.setFilter('company_vendor_id', companyVendorId, true, true)
      return companyVendorStore.companyVendorContactListStore.fetchRecords()
    } catch (err) {
      setSubmitting(false)
      message.error(err?.response?.data?.error || 'Unable to add')
    } finally {
      setSubmitting(false)
    }
  }

  const handleConnectOrAddByVendor = async (vendorId: string, vendorUserId: string, isConnected = false) => {
    if (isConnected) {
      const vendorContacts = await getCompanyVendorContact(companyVendor?.id)
      onSubmitAndAdd(vendorContacts.find(({ contact }) => contact?.vendor_user?.id === vendorUserId))
    } else {
      handleConnect(vendorId, vendorUserId, true)
    }
  }

  const vendorUsers =
    vendor?.vendor_users.filter((vendorUser) => {
      return vendorUser?.is_public || vendor?.connected_vendor_user_ids.includes(vendorUser.id)
    }) || []

  if (isLoading) {
    return <Loading />
  }

  return (
    <Wrapper justifyContent="flex-start" width="100%" height="100%" overflowY="auto">
      <FlexBoxY alignItems="flex-start" width="100%" flexGrow={0} p={{ _: 12, xs: 24 }}>
        <VendorCard
          name={vendor?.name}
          domain={vendor?.domain}
          logoUrl={vendor?.logo?.url}
          description={vendor?.description}
        />
      </FlexBoxY>

      <Tabs
        activeKey={activeKey}
        onChange={setActiveKey}
        destroyInactiveTabPane
        items={[
          {
            label: (
              <>
                <Typography.Text>Sales Representatives</Typography.Text>
                <Tooltip placement="bottomLeft" title="All contacts for this vendor on the SubBase Network">
                  <QuestionCircleOutlined style={{ marginLeft: 4 }} />
                </Tooltip>
              </>
            ),
            key: 'sales-representatives',
            children: (
              <SalesRepresentatives
                onSearch={handleSearchText}
                hideCreate={hideCreate}
                onAddNewVendorContact={onAddNewVendorContact}
              >
                {!!vendorUsers?.length && (
                  <List
                    grid={{
                      gutter: 10,
                      column: 1,
                    }}
                    style={{ width: '100%' }}
                    dataSource={vendorUsers.filter(handeFilterTextVendorUser)}
                    renderItem={(vendorUser) => {
                      const isConnected = vendor?.connected_vendor_user_ids.includes(vendorUser.id)
                      return (
                        <List.Item>
                          <VendorUserCard
                            name={vendorUser.full_name}
                            email={vendorUser.email}
                            headline={vendorUser?.headline}
                            onConnect={() => handleConnect(vendor?.id, vendorUser.id)}
                            onConnectAndAdd={
                              !!onSubmitAndAdd &&
                              (() => handleConnectOrAddByVendor(vendor?.id, vendorUser.id, isConnected))
                            }
                            isConnected={isConnected}
                            orderType={orderType}
                            isLoading={isSubmitting}
                          />
                        </List.Item>
                      )
                    }}
                  />
                )}
              </SalesRepresentatives>
            ),
          },
          {
            label: (
              <>
                <Typography.Text>My Contacts</Typography.Text>
                <Tooltip placement="bottomLeft" title="Your contacts for this vendor">
                  <QuestionCircleOutlined style={{ marginLeft: 4 }} />
                </Tooltip>
              </>
            ),
            key: 'my-contacts',
            children: <MyContacts companyVendorId={companyVendor?.id} orderType={orderType} onAdd={onSubmitAndAdd} />,
          },
        ]}
      />
    </Wrapper>
  )
})
