import React from 'react'

import { useHistory } from 'react-router-dom'

import { Typography, Row, Col } from 'antd'

import { Box } from 'common/components/boxes'
import { formatDateStringShort } from 'common/helpers/formatters'

import { PriceSheet } from 'contractor/server/price_sheet'

import { PriceSheetStateTag } from '../components'

const Label = (props) => <Typography.Text type="secondary" style={{ fontSize: 10 }} {...props} />

type PriceSheetListRowProps = { priceSheet: PriceSheet }

export const PriceSheetListRow = ({ priceSheet }: PriceSheetListRowProps) => {
  const history = useHistory()

  const priceSheetNumber = priceSheet.number ? `${priceSheet.number}: ` : ''
  const projectId = priceSheet.project?.project_id ? `(${priceSheet.project?.project_id})` : ''

  return (
    <Box
      style={{ cursor: 'pointer' }}
      bg="white"
      borderRadius={4}
      px={16}
      py={12}
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      onClick={() => history.push(`/price_sheets/${priceSheet.id}`)}
    >
      <Row gutter={[16, 16]} align="middle" style={{ width: '100%' }}>
        <Col xs={8} sm={4} md={4} lg={3} xl={2} xxl={2}>
          <PriceSheetStateTag stateName={priceSheet.workflow_state} />
        </Col>

        <Col xs={16} sm={20} md={12} lg={9} xl={6} xxl={6}>
          <Box display="flex" flexDirection="column">
            <Label>Price sheet</Label>
            <Typography.Text
              strong
              ellipsis={{ tooltip: true }}
            >{`${priceSheetNumber}${priceSheet.name}`}</Typography.Text>
          </Box>
        </Col>

        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
          <Box display="flex" flexDirection="column">
            <Label>Vendor</Label>
            <Typography.Text ellipsis={{ tooltip: true }}>
              {priceSheet.company_vendor?.vendor?.name || priceSheet.company_vendor?.vendor_name}
            </Typography.Text>
          </Box>
        </Col>

        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
          <Box display="flex" flexDirection="column">
            <Label>Project</Label>
            <Typography.Text ellipsis={{ tooltip: true }}>
              {priceSheet.project ? `${priceSheet.project?.name} ${projectId}` : '-'}
            </Typography.Text>
          </Box>
        </Col>

        <Col xs={12} sm={6} md={4} lg={6} xl={2} xxl={2}>
          <Box display="flex" flexDirection="column">
            <Label>Start date</Label>
            <Typography.Text>{formatDateStringShort(priceSheet.start_at, '-')}</Typography.Text>
          </Box>
        </Col>

        <Col xs={12} sm={6} md={4} lg={6} xl={2} xxl={2}>
          <Box display="flex" flexDirection="column">
            <Label>Expiration data</Label>
            <Typography.Text>{formatDateStringShort(priceSheet.end_at, '-')}</Typography.Text>
          </Box>
        </Col>

        <Col xs={12} sm={6} md={4} lg={6} xl={2} xxl={2}>
          <Box display="flex" flexDirection="column">
            <Label>Created at</Label>
            <Typography.Text>{formatDateStringShort(priceSheet.created_at, '-')}</Typography.Text>
          </Box>
        </Col>

        <Col xs={12} sm={6} md={4} lg={6} xl={2} xxl={2}>
          <Box display="flex" flexDirection="column">
            <Label>Qty materials</Label>
            <Typography.Text>{priceSheet.price_sheet_materials_count}</Typography.Text>
          </Box>
        </Col>
      </Row>
    </Box>
  )
}
