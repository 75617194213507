import React from 'react'

import { Select, SelectProps } from 'antd'

import { observer } from 'mobx-react-lite'

import { OptionDescriptor } from 'common/components/Select/Option'

import { useStores } from 'contractor/hooks/use-stores'

export type SelectProjectProps = SelectProps

export const SelectProject = observer<SelectProjectProps>((props) => {
  const { projectStore } = useStores()

  if (props.options) {
    return (
      <Select
        aria-label="project-select"
        data-cy="project-select"
        style={{ width: '100%' }}
        placeholder={props.mode ? 'Select Projects' : 'Select project'}
        filterOption={(input, option) => {
          const inputValue = input?.toLowerCase()
          const searchable = option?.label as Nullable<string>
          return searchable?.toLowerCase()?.includes(inputValue)
        }}
        showSearch
        aria-autocomplete="none"
        {...props}
      >
        {props.options.map(({ label, value }) => (
          <Select.Option key={value} name={label} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>
    )
  }

  return (
    <Select
      aria-label="project-select"
      style={{ width: '100%' }}
      placeholder={props.mode ? 'Select Projects' : 'Select project'}
      filterOption={(input, option) => {
        const inputValue = input?.toLowerCase()
        return option?.searchable?.toLowerCase()?.includes(inputValue)
      }}
      showSearch
      {...props}
    >
      {projectStore?.projects?.map((project) => (
        <Select.Option
          key={project.id}
          value={project.id}
          label={project.name}
          searchable={`${project.name}:${project.project_id ? project.project_id : ''}`}
        >
          <OptionDescriptor value={project.id} label={project.name} descriptor={project.project_id} />
        </Select.Option>
      ))}
    </Select>
  )
})
