import React from 'react'

import ReactSelect, { Props } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { capitalize } from 'lodash'

import { useTheme } from 'common/hooks/use-theme'

import { setTheme } from './set-theme'

export interface DefaultOption {
  value: string
  label: string
  removable?: boolean
}

export function makeOption(option: string): { label: string; value: string } {
  return option && { label: option.replace(/\w+/g, capitalize), value: option }
}

export type Select2Props<T, IsMulti extends boolean = false> = Props<T, IsMulti>

export const Select = <T extends object, IsMulti extends boolean = false>(props: Select2Props<T, IsMulti>) => {
  const theme = useTheme()

  const selectTheme = setTheme(theme)

  if (props.creatable) {
    return <CreatableSelect {...{ theme: selectTheme, ...props }} />
  }

  return <ReactSelect {...{ theme: selectTheme, ...props }} />
}
