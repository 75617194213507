import React, { useState } from 'react'

import styled from '@emotion/styled'

import { InfoCircleOutlined } from '@ant-design/icons'
import { Typography, Tooltip, message } from 'antd'

import {
  ReportIssueHistory as ReportInvoiceInboxIssueHistory,
  ReportIssue as ReportInvoiceInboxIssue,
} from 'contractor/components/InvoiceInboxDetailsDrawer'
import { useStores } from 'contractor/hooks/use-stores'

const Source = styled(Typography.Paragraph)`
  transition: color 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.highlightBlue};
  }
`

export const IssueAction = () => {
  const { invoiceStore } = useStores()

  const [isIssueModalVisible, toggleIssueModalVisible] = useState(false)
  const [isIssueHistoryModal, togglepenIssueHistoryVisible] = useState(false)

  const handleSendIssue = async (issueMessage: string) => {
    try {
      await invoiceStore.reportIssue(invoiceStore.invoice.id, {
        issue_message: issueMessage,
        invoice_inbox_attachment_id: invoiceStore.invoice?.invoice_inbox_attachment?.id,
      })
      message.success('Thanks for your issue report! Our team will take care of it ASAP.')
    } catch (error) {
      message.error(error?.response?.data?.error || 'Error while sending the issue, try again later.')
    } finally {
      toggleIssueModalVisible(false)
    }
  }

  if (invoiceStore.invoice?.invoice_inbox?.has_reported_issue) {
    return (
      <>
        <Source style={{ margin: 0 }} onClick={() => togglepenIssueHistoryVisible(true)}>
          Issue History&nbsp;
          <InfoCircleOutlined />
        </Source>

        <ReportInvoiceInboxIssueHistory
          invoiceInboxId={invoiceStore.invoice?.invoice_inbox_id}
          filters={{ invoice_inbox_attachment_id: invoiceStore.invoice?.invoice_inbox_attachment?.id }}
          open={isIssueHistoryModal}
          onClose={() => togglepenIssueHistoryVisible(false)}
        />
      </>
    )
  }

  return (
    <>
      <Tooltip title="Click here and let us know!">
        <Source style={{ margin: 0 }} onClick={() => toggleIssueModalVisible(true)}>
          Something extracted incorrectly?&nbsp;
          <InfoCircleOutlined />
        </Source>
      </Tooltip>

      <ReportInvoiceInboxIssue
        open={isIssueModalVisible}
        onClose={() => toggleIssueModalVisible(false)}
        onSendIssue={(_, issueMessage) => handleSendIssue(issueMessage)}
      />
    </>
  )
}
