import React, { forwardRef } from 'react'

import { ExternalLink } from '@styled-icons/evaicons-solid'

import { CalendarOutlined, DollarOutlined } from '@ant-design/icons'
import { Typography, Space } from 'antd'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { formatDateString } from 'common/helpers/formatters'
import { InvoiceResponse } from 'common/server/invoice'

import { InvoiceStatusTag } from 'contractor/components/Invoices/invoice_status_tag'

import { useInvoice } from '../../context'
import { ItemStyled } from './styles'
import { TotalValuesDrawer } from './total_values_drawer'

type DrawerInvoiceProps = {
  invoices: InvoiceResponse[]
  ordersGrandTotal?: number
  totalInvoicedAmount?: number
  ordersCount?: number
}

export const DrawerInvoices = forwardRef<DrawerRef, DrawerInvoiceProps>((props, ref) => {
  const { invoices, ordersGrandTotal, totalInvoicedAmount, ordersCount } = props

  const { currencyFormatter } = useInvoice()

  return (
    <Drawer title="Invoices" ref={ref} closable={false} width={378}>
      <Box width="100%" overflowY="auto" marginRight="0">
        {invoices?.map((invoice) => (
          <ItemStyled
            key={invoice.id}
            onClick={() => window.open(`/invoice/${invoice.id}`, '_blank')}
            borderRadius="4px"
          >
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" mb={8}>
              <Box>
                <InvoiceStatusTag state={invoice.state} rejectionReason={invoice?.rejection_reason} />
              </Box>
              <Typography.Link>
                <Box display="inline-flex">
                  {invoice.number}
                  <ExternalLink size="13" />
                </Box>
              </Typography.Link>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Space>
                <CalendarOutlined />
                <Typography.Text>
                  {invoice.document_date ? formatDateString(invoice.document_date) : 'Unknown'}
                </Typography.Text>
              </Space>
              <Space>
                <DollarOutlined />
                <Typography.Text>
                  {invoice.invoice_grand_total ? currencyFormatter(invoice.invoice_grand_total) : 'Unknown'}
                </Typography.Text>
              </Space>
            </Box>
          </ItemStyled>
        ))}

        <TotalValuesDrawer
          ordersGrandTotal={ordersGrandTotal}
          totalInvoicedAmount={totalInvoicedAmount}
          invoicesCount={invoices?.length}
          ordersCount={ordersCount}
        />
      </Box>
    </Drawer>
  )
})
