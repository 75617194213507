import React, { useState } from 'react'

import { connectSortBy } from 'react-instantsearch-dom'
import { useLocation } from 'react-router-dom'

import { SortAscendingOutlined, SortDescendingOutlined, CloseCircleFilled } from '@ant-design/icons'
import { Typography, Select, Radio, Tooltip } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'

const SortRefinement = (props) => {
  const { defaultRefinement, refine, sortReplicas = [], currentRefinement = '' } = props
  const location = useLocation()
  const source = location.pathname.substr(1)

  const [direction, setDirection] = useState('asc')
  const [selectedValue, setSelectValue] = useState(
    sortReplicas.find((sortReplica) => currentRefinement.includes(sortReplica?.value))?.value,
  )

  const handleSort = (replica) => {
    refine(replica)
    trackEvent(Events.TABLE_SORTING, source, {
      field: replica,
      direction: direction,
    })
  }

  return (
    <Box display="flex" flexDirection="column">
      <Typography.Title level={5}>Sort By</Typography.Title>
      <Box mb={16} width="100%" display="flex" alignItems="center">
        <Select
          allowClear
          onSelect={(value) => {
            setSelectValue(value)
            const replica = `${defaultRefinement}__${value}__${direction}`
            handleSort(replica)
          }}
          clearIcon={
            <CloseCircleFilled
              onClick={() => {
                setSelectValue(undefined)
                handleSort(defaultRefinement)
              }}
            />
          }
          value={selectedValue}
          style={{ marginRight: '12px', flexGrow: 1 }}
        >
          {sortReplicas.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>

        <Radio.Group
          onChange={({ target }) => {
            setDirection(target.value)
            const replica = `${defaultRefinement}__${selectedValue}__${target.value}`
            handleSort(replica)
          }}
          value={direction}
          options={[
            {
              label: (
                <Tooltip title="Asc">
                  <SortAscendingOutlined />
                </Tooltip>
              ),
              value: 'asc',
            },
            {
              label: (
                <Tooltip title="Desc">
                  <SortDescendingOutlined />
                </Tooltip>
              ),
              value: 'desc',
            },
          ]}
          optionType="button"
        />
      </Box>
    </Box>
  )
}

export const SortSelector = connectSortBy(SortRefinement)
