import styled from '@emotion/styled'

import { FlexBoxY } from 'common/components/boxes'
export const Wrapper = styled(FlexBoxY)`
  & .ant-tabs {
    width: 100%;
    height: 100%;
  }
  & .ant-tabs-content {
    height: 100%;
  }
  & .ant-tabs-nav {
    padding: 10px 24px 0 24px;
  }
  & .ant-tabs-tabpane {
    display: flex;
    flex-direction: column;
  }

  & .ant-list-loading .ant-spin-nested-loading {
    height: 100%;
  }
  & .ant-spin-spinning {
    max-height: 100% !important;
  }
  & .ant-spin-blur {
    height: 100%;
  }

  & .ant-spin-nested-loading {
    width: 100%;
    height: 100%;

    & .ant-spin-container {
      width: inherit;
      height: inherit;
    }
  }
`
