import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { Table, Typography, Skeleton } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import OrderState from 'common/components/statuses/order_state'
import { formatDateDiff } from 'common/helpers/formatters'
import { OrderSubStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { ConsolidatedOrders, consolidatedOrdersRequests } from 'contractor/server/orders'

const OrdersTable: React.FC<{ orders: Array<ConsolidatedOrders.Order> }> = observer(({ orders }) => {
  const { projectStore } = useStores()

  const columns: ColumnsType<ConsolidatedOrders.Order> = [
    {
      title: 'State',
      dataIndex: 'order_state',
      key: 'order_state',
      render: (_, order) => {
        return <OrderState state={order.state} sub_state={order.sub_state} />
      },
      width: 160,
    },
    {
      title: 'Name',
      dataIndex: 'order_name',
      key: 'order_name',
      render: (_, record) => {
        return `${record?.order_number}: ${record?.name}`
      },
    },
    {
      title: 'Vendor',
      dataIndex: ['company_vendor', 'vendor_name'],
      key: 'company_vendor.vendor_name',
      render: (_, record) => {
        return record.company_vendor_name
      },
    },
    {
      title: 'Ordered',
      dataIndex: 'ordered_at',
      key: 'ordered_at',
      render: (diff) => <span>{formatDateDiff(diff)}</span>,
    },
  ]

  // Maybe add the project name
  if (!projectStore.selectedProject) {
    columns.splice(1, 0, {
      title: 'Project',
      dataIndex: 'project_name',
      key: 'project_name',
    })
  }

  const history = useHistory()

  return (
    <Table
      style={{ width: '100%' }}
      rowKey={'id'}
      dataSource={orders}
      columns={columns}
      pagination={false}
      onRow={(record) => ({
        onClick: () => history.push(`order/${record.id}`),
      })}
      size="small"
    />
  )
})

const Orders = observer(() => {
  const { projectStore } = useStores()
  const [orders, setOrders] = useState<Array<ConsolidatedOrders.Order>>()

  async function fetchOrders() {
    const filters = {
      state: [OrderSubStates.ORDERED_REQUESTED],
    }

    if (projectStore.selectedProject?.id) {
      filters['project_id'] = projectStore.selectedProject.id
    }

    const response = await consolidatedOrdersRequests.index({
      filters,
    })

    setOrders(response.data.records)
  }

  useEffect(() => {
    fetchOrders()
  }, [projectStore.selectedProject?.id])

  if (orders === undefined) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
        <Skeleton paragraph={{ rows: 5 }} />
      </Box>
    )
  }

  if (orders.length === 0) {
    return (
      <Box display="flex" justifyContent="center" width="100%">
        <Typography.Text type="secondary">No unconfirmed orders</Typography.Text>
      </Box>
    )
  }

  return <OrdersTable orders={orders} />
})

export default Orders
