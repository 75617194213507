import React from 'react'

import { Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'

import { OtherSettingsTable } from 'contractor/pages/CompanySettings/Invoices/OtherSettings/other_settings_table'
import { InvoiceOtherSettings } from 'contractor/server/invoices/invoice_settings'

type OtherSettingsProps = {
  onChange: (value: InvoiceOtherSettings) => void
  otherSettings: InvoiceOtherSettings
}

export const OtherSettings = observer<OtherSettingsProps>(({ onChange, otherSettings }) => {
  return (
    <Box mt={32}>
      <Typography.Title level={5}>Other Settings</Typography.Title>
      <OtherSettingsTable onChange={onChange} otherSettings={otherSettings} />
    </Box>
  )
})
