import React from 'react'

import { Tag, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { PsqlTableCells } from 'common/components/PsqlTable'
import { VendorResponseType } from 'common/server/server_types'

interface CellVendorNoteProps {
  vendorNote: string
  vendorResponse: string[]
}

const options = [
  { value: VendorResponseType.SUBSTITUTION, label: 'Substitution' },
  { value: VendorResponseType.DO_NOT_CARRY, label: 'Do Not Carry' },
  { value: VendorResponseType.CHANGED_UNITS, label: 'Changed Units' },
  { value: VendorResponseType.BACKORDERED, label: 'Backordered' },
  { value: VendorResponseType.OTHER, label: 'Other' },
] as const

export const CellVendorNote = ({ vendorNote, vendorResponse }: CellVendorNoteProps) => {
  if (!vendorNote && !vendorResponse?.length) {
    return <PsqlTableCells.NoContent />
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="start">
      <Typography.Text>{vendorNote}</Typography.Text>
      <Box width="100%" display="flex" flexWrap="wrap" style={{ gap: 4, paddingTop: 8 }}>
        {vendorResponse?.map((item) => (
          <Tag key={item} color={item === VendorResponseType.DO_NOT_CARRY ? 'red' : 'default'} style={{ margin: 0 }}>
            {options.find((option) => option.value === item)?.label}
          </Tag>
        ))}
      </Box>
    </Box>
  )
}
