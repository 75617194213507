import React from 'react'

import { useDrop } from 'react-dnd'

import { OrderMaterial } from 'common/server/orders'

export const DroppableUnmatch = ({ onUnmatch, ...restProps }) => {
  const [, drop] = useDrop({
    accept: 'DraggableBodyRow',
    drop: (item: OrderMaterial) => {
      onUnmatch(item)
    },
  })

  return <div ref={drop} style={{ height: '100%', ...restProps?.style }} {...restProps} />
}
