import { useEffect, useMemo, useState } from 'react'

import { notification } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { useQuery } from 'common/hooks/use-query'
import { LockOrderOptionType, OrderStates, OrderSubStates } from 'common/server/server_types'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import { OrderSessionOwner, UpdateOrderSessionResponse } from 'contractor/server/order_sessions'

export const USE_LOCK_PO_SESSION_ACTIONS_DEBOUNCE_TIME = 10000 //10s

export type UseLockPoType = {
  orderLocked: boolean
  orderSession: {
    externalUser: OrderSessionOwner | null
    isBlocked: boolean
    isReleased: boolean
    modalOrderExpiredOpened: boolean
    modalOrderLockedOpened: boolean
    modalOrderReleasedOpened: boolean
    modalOrderUnlockedOpened: boolean
    onCloseModalOrderLocked: () => void
    onCloseModalOrderUnlocked: () => void
    onOpenModalOrderUnlocked: () => void
    onRelease: () => void
    onRenew: () => void
    onUnlock: () => void
    onUpdate: () => void
    unlockEnabled: boolean
  }
}

export const useLockPo = () => {
  const { companySettingStore, orderStore, userStore } = useStores()

  const order = orderStore?.selectedOrder

  const featureFlagOrderBlockingEnabled = useFlag('order_blocking')
  const userIsImpersonating = userStore.currentUser?.impersonating
  const userHasUnlockEnabled = userStore.canUnlockOrder

  const orderBlockingEnabled = featureFlagOrderBlockingEnabled && !!order?.id

  const [isOrderLocked, setIsOrderLocked] = useState(false)
  const rule = companySettingStore?.otherSettings?.lock_order_settings?.lock_order_rule
  const hasOpenDeliveryIssue = order?.deliveries?.some((delivery) => delivery?.has_open_issue)

  const { data, isLoading } = useQuery<UpdateOrderSessionResponse>(
    async () => {
      const name = `${userStore.currentUser.first_name} ${userStore.currentUser.last_name}`
      const user = { id: userStore.currentUser.id, name }
      return orderStore.updateOrderSession({ order_ids: [order.id] }, user)
    },
    [order?.id],
    !!order?.id,
  )

  const [showOrderLockedState, setShowOrderLocked] = useState(false)
  const [showOrderUnlockedState, setShowOrderUnlockedState] = useState(false)

  useEffect(() => {
    if (orderBlockingEnabled) {
      const isExternalUser = data?.ownerUser?.id !== userStore.currentUser.id
      if (isExternalUser) {
        setShowOrderLocked(true)
      }
    }
  }, [data, orderBlockingEnabled])

  useEffect(() => {
    if (orderBlockingEnabled && orderStore.orderSessionOwner?.manually_blocked) {
      setShowOrderLocked(true)
    }
  }, [orderStore.orderSessionOwner?.manually_blocked])

  useEffect(() => {
    if (order) {
      let backgroundPingInterval: NodeJS.Timeout | null = null
      const orderSessionSubscribe = orderStore.orderSessionSubscribe(order.id, userStore.currentUser?.id)

      const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          backgroundPingInterval = setInterval(() => {
            if (orderSessionSubscribe.isConnected()) {
              orderSessionSubscribe.keepalive()
            }
          }, 120000) // 2m

          return () => clearInterval(backgroundPingInterval)
        }

        if (document.visibilityState === 'visible') {
          if (backgroundPingInterval) {
            clearInterval(backgroundPingInterval)
            backgroundPingInterval = null
          }
        }
      }

      // keeps the connection open if the tab is in the background
      document.addEventListener('visibilitychange', handleVisibilityChange)

      return () => {
        orderStore.orderSessionUnsubscribe(order.id)
        document.removeEventListener('visibilitychange', handleVisibilityChange)
        if (backgroundPingInterval) clearInterval(backgroundPingInterval)
      }
    }
  }, [order?.id])

  const orderSession = useMemo(() => {
    if (!order?.id) {
      return {
        isBlocked: false,
        isReleased: false,
        externalUser: null as OrderSessionOwner | null,
        modalOrderLockedOpened: false,
        modalOrderUnlockedOpened: false,
        modalOrderExpiredOpened: false,
        modalOrderReleasedOpened: false,
        onCloseModalOrderLocked: () => null,
        onCloseModalOrderUnlocked: () => null,
        onOpenModalOrderUnlocked: () => null,
        onRenew: () => null,
        onRelease: () => null,
        onUnlock: () => null,
        onUpdate: () => null,
        unlockEnabled: false,
      }
    }

    const userInOrder = orderStore.orderSessionOwner
    const externalUser = userInOrder?.id !== userStore.currentUser.id && userInOrder
    const isBlocked = orderBlockingEnabled && externalUser && !isLoading
    const isReleased = !isLoading && !userInOrder
    const modalOrderLockedOpened = orderBlockingEnabled && showOrderLockedState && !!externalUser
    const modalOrderExpiredOpened = orderBlockingEnabled && userInOrder?.expired
    const modalOrderUnlockedOpened = orderBlockingEnabled && showOrderUnlockedState && userHasUnlockEnabled
    const modalOrderReleasedOpened = orderBlockingEnabled && isReleased && !userIsImpersonating
    const unlockEnabled = orderBlockingEnabled && userHasUnlockEnabled

    const onCloseModalOrderLocked = () => setShowOrderLocked(false)

    const onOpenModalOrderUnlocked = () => {
      onCloseModalOrderLocked()
      setShowOrderUnlockedState(true)
    }
    const onCloseModalOrderUnlocked = () => setShowOrderUnlockedState(false)

    const onRenew = () => {
      if (orderBlockingEnabled) {
        orderStore.updateOrderSession(
          { order_ids: [order.id], order_changed: orderStore?.selectedOrderDirty },
          {
            id: userStore.currentUser.id,
            name: userStore.currentUser.first_name,
          },
        )
      }
    }

    const onRelease = () => orderStore.releaseOrderSession({ order_ids: [order.id] })

    const onUnlock = async () => {
      onCloseModalOrderUnlocked()
      try {
        const user_kicked_id = externalUser?.id
        await orderStore.unlockOrderSession(
          { order_ids: [order.id] },
          {
            id: userStore.currentUser.id,
            name: userStore.currentUser.first_name,
          },
        )
        trackEvent(Events.CLICK_UNLOCK_ORDER_SESSION, window.location.pathname, {
          order_id: order.id,
          user_id: userStore.currentUser.id,
          user_kicked_id,
          origin: 'ORDER',
        })
      } catch (e) {
        notification.error({
          message: 'Error while unlock order, try again later',
          placement: 'bottomLeft',
        })
      }
    }

    const onUpdate = () => {
      if (orderBlockingEnabled) orderStore.selectOrder(order.id)
      orderStore.updateOrderSession(
        { order_ids: [order.id] },
        {
          id: userStore.currentUser.id,
          name: userStore.currentUser.first_name,
        },
      )
    }

    return {
      externalUser,
      isBlocked,
      isReleased,
      modalOrderExpiredOpened,
      modalOrderLockedOpened,
      modalOrderReleasedOpened,
      modalOrderUnlockedOpened,
      onCloseModalOrderLocked,
      onCloseModalOrderUnlocked,
      onOpenModalOrderUnlocked,
      onRelease,
      onRenew,
      onUnlock,
      onUpdate,
      unlockEnabled,
    }
  }, [
    isLoading,
    order?.id,
    orderBlockingEnabled,
    orderStore.orderSessionOwner,
    userStore.currentUser.id,
    userStore.currentUser.first_name,
    showOrderLockedState,
    showOrderUnlockedState,
    userHasUnlockEnabled,
    userIsImpersonating,
  ])

  useEffect(() => {
    /*
      If the feature is disabled, the process of entering the session becomes automatic,
      so whoever calls the request first is saved in it. If it is enabled, the process
      involves clicking on the modal to access the session.
    */
    if (order?.id && !featureFlagOrderBlockingEnabled && orderSession?.isReleased) {
      orderSession?.onUpdate()
    }
  }, [orderSession?.isReleased])

  useEffect(() => {
    return () => {
      orderStore.orderSessionOwner = null
    }
  }, [])

  useEffect(() => {
    const calculateOrderLockStatus = () => {
      switch (rule) {
        case LockOrderOptionType.NEVER:
          setIsOrderLocked(false)
          break
        case LockOrderOptionType.ORDER_CONFIRMATION:
          setIsOrderLocked(
            [OrderStates.DELIVERED, OrderStates.PARTIALLY_SHIPPED, OrderStates.SHIPPED].includes(order?.state) ||
              order?.sub_state == OrderSubStates.ORDERED_CONFIRMED,
          )
          break
        case LockOrderOptionType.DELIVERY_COMPLETION:
          setIsOrderLocked(order?.state == OrderStates.DELIVERED && !hasOpenDeliveryIssue)
          break
        case LockOrderOptionType.ORDER_INVOICED:
          setIsOrderLocked(order?.is_fully_invoiced)
          break
        default:
          setIsOrderLocked(false)
      }
    }

    calculateOrderLockStatus()
  }, [order, rule])

  return {
    orderLocked: isOrderLocked || orderSession.isBlocked,
    orderSession,
  }
}
