import React, { useState } from 'react'

import { useParams } from 'react-router-dom'

import { flatten } from 'lodash'
import moment from 'moment'

import { Space, Button, Modal, Input, Typography, Checkbox, Divider, message, notification } from 'antd'
import { SYNC_ORDER_WITH_INTEGRATION_LOCAL_KEY } from 'contractor/constants'

import { observer } from 'mobx-react-lite'

import { Tool, trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { FlexBoxY, Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import { calcExtCost } from 'common/helpers/order'
import { OrderStates } from 'common/server/server_types'

import { TermsAndConditions, mapOptions } from 'contractor/components/TermsAndConditions'
import { useStores } from 'contractor/hooks/use-stores'
import { CreatePlaceLevelingQuotesRequest } from 'contractor/server/orders'
import { ShowOrderResponse } from 'contractor/server/orders'

import { useLeveling } from '../context'
import { TableDataSource } from '../make_table_data'
import { HeaderProps } from './header'

type ExtraSplittingProps = HeaderProps

const makeOrdersToConfirm = (order: ShowOrderResponse, tableData: TableDataSource[]) => ({
  ...order,
  order_materials: order.order_materials.map((orderMaterial) => {
    const vendorItems = flatten(tableData.map((tableRow) => tableRow.vendor_items))
    const vendorItem = vendorItems.find(
      (vendorItem) => vendorItem.orderId === order.id && vendorItem.orderMaterialId === orderMaterial.id,
    )

    if (vendorItem) {
      return {
        ...orderMaterial,
        quantity: vendorItem.quantity,
      }
    }

    return orderMaterial
  }),
})

const makeOrdersToSend = (
  order: ShowOrderResponse,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ordersParams: Record<string, any>,
  onlyUpdateQuotes = false,
): CreatePlaceLevelingQuotesRequest => {
  const hasQuantity = order.order_materials.some((orderMaterial) => !!orderMaterial.quantity)

  const currentOrderState = order.state === OrderStates.CANCELLED

  // If the order is already canceled for some reason (like the vendor rejected the quote)
  // We don't need to do anything, the user can't change the quantities via the leveling page and we keep the state as the previous
  if (currentOrderState) {
    return null
  }

  const getStateChanges = () => {
    if (!hasQuantity) {
      return { cancelled_at: moment().toISOString() }
    }
    if (onlyUpdateQuotes) {
      return { cancelled_at: null }
    }
    return { cancelled_at: null, ordered_at: moment().toISOString() }
  }

  return {
    id: order.id,
    order_number: ordersParams[order.id]?.orderNumber as string,
    terms_and_condition_id: ordersParams[order.id]?.termsAndConditionId as string,
    ...(hasQuantity
      ? {
          order_materials: order.order_materials
            .filter((orderMaterial) => !!orderMaterial.quantity)
            .map((orderMaterial) => ({
              id: orderMaterial.id,
              quantity: Number(orderMaterial.quantity),
              company_material_id: orderMaterial?.company_material?.id,
              delivery_id: orderMaterial?.delivery_id,
              unit_cost: orderMaterial?.unit_cost,
              cost_code_id: orderMaterial?.cost_code_id,
              vendor_note: orderMaterial?.vendor_note,
              company_note: orderMaterial?.company_note,
            })),
        }
      : {}),
    // If the order does not have quantity in all materials, the order will be CANCELLED
    state_changes: getStateChanges(),
  }
}

export const ExtraSplitting = observer<ExtraSplittingProps>(({ goBack }) => {
  const { userStore, integrationStore, orderStore, companySettingStore } = useStores()

  const {
    orders,
    tableData,
    handleCancelSplitting,
    getOriginalAvgSubTotal,
    getOriginalAvgGrandTotal,
    getSubtotalAvgAndMinMaxSavedAmount,
    getGrandTotalSavedAmount,
    getGrandTotalMinMaxSavedAmount,
    ordersSession,
  } = useLeveling()

  const params = useParams()

  const [purchaseOrdersModalVisible, setPurchaseOrdersModalVisible] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)

  const [ordersParams, setOrdersParams] = useState(
    orders.reduce((acc, order) => {
      acc[order.id] = { orderNumber: order?.order_number, termsAndConditionId: order?.terms_and_condition_id }
      return acc
    }, {}),
  )
  const [syncOrderWithIntegration, setSyncOrderWithIntegration] = useState(
    localStorage.getItem(SYNC_ORDER_WITH_INTEGRATION_LOCAL_KEY) === 'true',
  )

  // Get the orders with the quantities from the table
  const ordersToConfirm = orders.map((order) => makeOrdersToConfirm(order, tableData))

  const originalAvgSubTotal = getOriginalAvgSubTotal()
  const originalAvgGrandTotal = getOriginalAvgGrandTotal()
  const subtotalAvgAndMinMaxSavedAmount = getSubtotalAvgAndMinMaxSavedAmount()
  const gradTotalSavedAmount = getGrandTotalSavedAmount()
  const gradTotalMinMaxSavedAmount = getGrandTotalMinMaxSavedAmount()
  const ordersLocked = ordersSession.isBlocked

  const orderedGrandTotal = ordersToConfirm.reduce((acc, order) => {
    const hasQuantity = order.order_materials.some((orderMaterial) => !!orderMaterial.quantity)

    if (hasQuantity) {
      const deliveriesTotalCost = order.deliveries.reduce((acc, delivery) => {
        const deliveryTotalCost = orderStore.deliveryTotalCost({
          deliveryId: delivery.id,
          deliveries: order.deliveries,
          materials: order.order_materials,
        })
        return acc + deliveryTotalCost
      }, 0)

      return acc + deliveriesTotalCost
    }

    return acc
  }, 0)

  const orderedSubTotal = ordersToConfirm.reduce((acc, order) => {
    const hasQuantity = order.order_materials.some((orderMaterial) => !!orderMaterial.quantity)

    if (hasQuantity) {
      const orderMaterialsExtTotal = order.order_materials.reduce((acc, orderMaterial) => {
        const subTotal = calcExtCost({
          unitCost: orderMaterial?.unit_cost as number,
          quantity: orderMaterial?.quantity,
          multiplier: orderMaterial?.company_material?.unit?.multiplier as unknown as number,
          qtyIncrement: orderMaterial?.company_material?.unit?.qty_increment as unknown as number,
        })
        return acc + subTotal
      }, 0)

      return acc + orderMaterialsExtTotal
    }

    return acc
  }, 0)

  const handleUpdateQuotes = async () => {
    try {
      setSubmitting(true)

      const ordersToSend = ordersToConfirm.map((order) => makeOrdersToSend(order, ordersParams, true)).filter(Boolean)

      await orderStore.placeLevelingQuotes(ordersToSend)

      notification.success({
        message: 'Update Quotes',
        description: <>Your quotes have been updated successfully</>,
        placement: 'bottomLeft',
        duration: 10,
      })

      goBack(true)
    } catch (error) {
      message.error(error?.response?.data?.error || 'Unable to update the quotes')
    } finally {
      setSubmitting(false)
    }
  }

  const handlePlaceOrders = async () => {
    try {
      setSubmitting(true)

      const ordersToSend = ordersToConfirm.map((order) => makeOrdersToSend(order, ordersParams)).filter(Boolean)

      await orderStore.placeLevelingQuotes(ordersToSend)
      orderStore.createOrderSaving({
        order_package_id: params['id'],
        original_avg_grand_total: originalAvgGrandTotal.toFixed(3),
        original_avg_sub_total: originalAvgSubTotal.toFixed(3),
        ordered_grand_total: orderedGrandTotal.toFixed(3),
        ordered_sub_total: orderedSubTotal.toFixed(3),
        num_quotes_requested: ordersToConfirm.length,
        num_orders_placed: ordersToSend.filter((order) => !!order?.state_changes?.ordered_at).length,
        order_breakdown: orders.map((order) => ({
          order_id: order.id,
          original_grand_total: order.grand_total,
          original_sub_total: order.sub_total,
        })),

        sub_total_avg_savings: subtotalAvgAndMinMaxSavedAmount.subtotalSavedAmount.toFixed(3),
        grand_total_avg_savings: gradTotalSavedAmount.toFixed(3),
        sub_total_total_min_max_savings: subtotalAvgAndMinMaxSavedAmount.subtotalMinMaxSavedAmount.toFixed(3),
        grand_total_min_max_savings: gradTotalMinMaxSavedAmount.toFixed(3),
        avg_material_savings_breakdown: subtotalAvgAndMinMaxSavedAmount.avgMaterialSavingsBreakdown.map(
          (avgMaterialSavingBreakdown) => ({
            company_material_id: avgMaterialSavingBreakdown.companyMaterialId,
            qty: avgMaterialSavingBreakdown.quantity,
            savings: avgMaterialSavingBreakdown.savings.toFixed(3),
          }),
        ),
        min_max_material_savings_breakdown: subtotalAvgAndMinMaxSavedAmount.minMaxMaterialSavingsBreakdown.map(
          (minMaxMaterialSavingBreakdown) => ({
            company_material_id: minMaxMaterialSavingBreakdown.companyMaterialId,
            qty: minMaxMaterialSavingBreakdown.quantity,
            savings: minMaxMaterialSavingBreakdown.savings.toFixed(3),
          }),
        ),
      })

      notification.success({
        message: 'Placed Orders',
        description: (
          <>
            Your orders have been placed successfully. <br />
            Your PO PDFs will be generated in a few seconds.
          </>
        ),
        placement: 'bottomLeft',
        duration: 10,
      })

      ordersToConfirm.forEach((order) => {
        if (syncOrderWithIntegration) {
          integrationStore.createOrder(order.id)
        }
      })

      goBack(true)
    } catch (error) {
      console.log(error)
      message.error(error?.response?.data?.error || 'Unable to place the orders')
    } finally {
      setSubmitting(false)
    }
  }

  const canShowIntegratonCheckbox =
    userStore.canUseIntegrations && integrationStore.connected && integrationStore.purchaseOrderSyncEnabled

  const onClickConfirm = () => {
    trackEvent(Events.CLICK_CONFIRM_PURCHASE_PLACE_ORDER_MODAL, window.location.pathname, {}, Tool.AMPLITUDE)
    handlePlaceOrders()
  }

  return (
    <>
      <Modal
        title="Confirm Purchase"
        open={purchaseOrdersModalVisible}
        onOk={onClickConfirm}
        onCancel={() => setPurchaseOrdersModalVisible(false)}
        okText="Confirm"
        okButtonProps={{ loading: isSubmitting }}
        cancelButtonProps={{ disabled: isSubmitting }}
      >
        <Box width="100%" display="flex" flexDirection="column">
          {ordersToConfirm.map((order, index) => (
            <React.Fragment key={order.id}>
              <Typography.Text strong>
                {order.company_vendor?.vendor?.name || order.company_vendor?.vendor_name}
              </Typography.Text>

              {/* If the order does not have quantity in all materials, the order will be CANCELLED */}
              {order.order_materials.some((orderMaterial) => !!orderMaterial.quantity) ? (
                <>
                  <FlexBoxY alignItems="flex-start" justifyContent="flex-start">
                    <Typography.Text type="secondary">Order number (optional)</Typography.Text>
                    <Input
                      style={{ width: 280 }}
                      placeholder="Order number (optional)"
                      value={ordersParams[order.id]?.orderNumber}
                      onChange={(e) =>
                        setOrdersParams((prev) => ({
                          ...prev,
                          [order.id]: { ...prev[order.id], orderNumber: e.target.value },
                        }))
                      }
                    />
                  </FlexBoxY>
                  {!!companySettingStore.activeTermsAndConditions?.length && (
                    <TermsAndConditions
                      boxProps={{ mt: 12 }}
                      style={{ width: 280 }}
                      type="PO"
                      options={companySettingStore.activeTermsAndConditions?.map(mapOptions)}
                      onChange={(termsAndConditionId) =>
                        setOrdersParams((prev) => ({
                          ...prev,
                          [order.id]: { ...prev[order.id], termsAndConditionId },
                        }))
                      }
                      value={ordersParams[order.id]?.termsAndConditionId}
                    />
                  )}
                </>
              ) : (
                <Typography.Text type="secondary">No materials ordered. Cancelling RFQ.</Typography.Text>
              )}
              {index + 1 < ordersToConfirm.length && <Divider />}
            </React.Fragment>
          ))}

          {canShowIntegratonCheckbox && (
            <Checkbox
              onChange={(e) => {
                localStorage.setItem(SYNC_ORDER_WITH_INTEGRATION_LOCAL_KEY, `${e.target.checked}`)
                setSyncOrderWithIntegration(e.target.checked)
              }}
              checked={syncOrderWithIntegration}
            >
              Sync purchase order with {integrationStore.getIntegrationName()}
            </Checkbox>
          )}

          {/* Only show cost saving message if the savings is greater than 0 */}
          {subtotalAvgAndMinMaxSavedAmount.subtotalSavedAmount > 0 && (
            <>
              <Divider />

              <Typography.Text strong style={{ textAlign: 'center' }}>
                Congrats on your
                <Typography.Text type="success" underline style={{ fontSize: 16, marginLeft: 4 }}>
                  {currencyFormatter(subtotalAvgAndMinMaxSavedAmount.subtotalSavedAmount, 3)} in savings!
                </Typography.Text>
              </Typography.Text>
            </>
          )}
        </Box>
      </Modal>

      <Space size="middle">
        <Button danger onClick={handleCancelSplitting} disabled={ordersLocked}>
          Cancel Split
        </Button>

        <Button type="primary" onClick={handleUpdateQuotes} disabled={ordersLocked}>
          Update Quotes
        </Button>

        <Button type="primary" onClick={() => setPurchaseOrdersModalVisible(true)} disabled={ordersLocked}>
          Place Orders
        </Button>
      </Space>
    </>
  )
})
