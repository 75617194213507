import { BindAll } from 'lodash-decorators'

import { action, observable } from 'mobx'

import { index, upsert, InvoiceStates } from 'contractor/server/company_settings/invoice_states'

@BindAll()
export default class InvoiceStateStore {
  invoiceStates = observable.array<InvoiceStates>([])

  @action
  async getAllInvoiceStates() {
    const { data } = await index()
    this.invoiceStates.replace(data?.invoice_states || [])
  }

  maybeGetAllInvoiceStates() {
    if (!this.invoiceStates.length) {
      return this.getAllInvoiceStates()
    }
  }

  async upsert(payload: InvoiceStates[]) {
    await upsert(payload)
    this.getAllInvoiceStates()
  }
}
