import React, { useRef } from 'react'

import { Card, Typography, Tag, Row, Col } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY, Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { useTheme } from 'common/hooks/use-theme'
import { Address } from 'common/server/addresses'

import AddressDrawer, { AddressProps } from './address_drawer'

type AddressTableProps = {
  addresses: Address[]
  isDrawer?: boolean
} & Pick<AddressProps, 'onSubmit' | 'onSaveAsNew' | 'showMainOfficeLabel' | 'hideSelect'>

const AddressTable: React.FC<AddressTableProps> = observer(
  ({ addresses, onSubmit, hideSelect, showMainOfficeLabel, isDrawer, onSaveAsNew }) => {
    const [selectedAddress, setSelectedAddress] = React.useState({} as Address)
    const theme = useTheme()

    const drawerCreateAddressRef = useRef<DrawerRef>()

    if (!addresses || addresses.length === 0) {
      return (
        <FlexBoxY width="100%" mb={20} alignItems="center" justifyContent="center">
          <Typography.Text strong>No addresses found</Typography.Text>
        </FlexBoxY>
      )
    }

    return (
      <Box mt={12}>
        <Row gutter={[20, 20]}>
          {addresses.map((address) => {
            const cardStyle = {
              width: '100%',
              background: theme.colors['gray-3'],
              color: address.discarded_at ? '#989898' : '#131b4d',
              opacity: address.discarded_at ? 0.5 : 1.0,
              position: 'relative',
              cursor: 'pointer',
            } as React.CSSProperties

            return (
              <Col
                key={address.id}
                xs={24}
                sm={12}
                md={8}
                lg={isDrawer ? 8 : 6}
                xl={isDrawer ? 8 : 5}
                xxl={isDrawer ? 8 : 4}
              >
                <Card data-cy="card-address" hoverable bordered={false} style={cardStyle}>
                  {showMainOfficeLabel && address.main_address && (
                    <Tag
                      style={{ position: 'absolute', right: 0, top: 0, margin: 0 }}
                      color={theme.colors.highlightBlue}
                    >
                      Main Office
                    </Tag>
                  )}

                  <FlexBoxY
                    data-cy={`address-card-${address.id}`}
                    onClick={() => {
                      drawerCreateAddressRef.current?.show()
                      setSelectedAddress(address)
                    }}
                    height="100px"
                  >
                    <Typography.Text strong>{address.nickname}</Typography.Text>
                    <div
                      style={{ textDecoration: address.discarded_at ? 'line-through' : 'none', textAlign: 'center' }}
                    >
                      <span>{address.address_line_1}</span>
                      {address.address_line_2 && <br />}
                      <span>{address.address_line_2 && <span>{address.address_line_2}</span>}</span>
                      <br />
                      <span>
                        {address.city}, {address.state} {address.zip_code}
                      </span>
                    </div>
                  </FlexBoxY>
                </Card>
              </Col>
            )
          })}
        </Row>

        <AddressDrawer
          ref={drawerCreateAddressRef}
          onClose={() => drawerCreateAddressRef.current?.close()}
          onSubmit={onSubmit}
          onSaveAsNew={onSaveAsNew}
          address={selectedAddress}
          hideSelect={hideSelect}
          showMainOfficeLabel={showMainOfficeLabel}
        />
      </Box>
    )
  },
)

export default AddressTable
