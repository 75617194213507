import React from 'react'

import { FlexBoxX } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'

export const CurrencyViewerCell = ({ cell, maximumFractionDigits }) => {
  const value = cell.value

  return (
    <FlexBoxX alignItems="flex-start" justifyContent="space-between">
      {value && currencyFormatter(value, maximumFractionDigits)}
    </FlexBoxX>
  )
}
