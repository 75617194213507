import React, { useRef, useState } from 'react'

import { ExclamationCircleFilled } from '@ant-design/icons'
import SettingOutlined from '@ant-design/icons/SettingOutlined'
import { Button, message, Modal, Switch, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { useQuery } from 'common/hooks/use-query'
import { useTheme } from 'common/hooks/use-theme'

import { IntegrationIcons } from 'contractor/components/IntegrationIcons'
import { useStores } from 'contractor/hooks/use-stores'
import { SettingsDrawer } from 'contractor/pages/Integrations/Settings/settings_drawer'

import { Authenticated } from './authenticated'
import { Unauthenticated } from './unauthenticated'

const { confirm } = Modal

export const Settings = observer(() => {
  const { integrationStore, userStore } = useStores()
  const theme = useTheme()

  const drawerSettingsRef = useRef<DrawerRef>()

  const [loadingIntegration, setLoadingIntegration] = useState(false)
  const [logOut, setLogout] = useState(false)

  const { isLoading } = useQuery(integrationStore.accounting)

  if (isLoading) {
    return <Loading />
  }

  const integrations = integrationStore.accountingIntegration?.integrations
  const integrationName = integrationStore.getIntegrationName()

  const logout = async () => {
    try {
      setLogout(true)
      await integrationStore.logout()
      message.success('Log out process started')
    } catch (error) {
      message.error('Unable to logout')
      setLogout(false)
    }
  }

  const confirmLogout = () => {
    confirm({
      title: `Are you sure you want to logout?`,
      icon: <ExclamationCircleFilled />,
      width: 700,
      content: (
        <Box>
          <Typography.Paragraph style={{ marginTop: 30 }}>
            This will disconnect SubBase from {integrationName} and you wont be able to sync data anymore.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <b>All the data mappings between SubBase and {integrationName} will be lost!</b>
          </Typography.Paragraph>
        </Box>
      ),
      okText: 'Yes',
      cancelText: 'No',
      onOk: logout,
    })
  }

  const online = integrationStore.online()

  return (
    <>
      <SettingsDrawer onClose={() => drawerSettingsRef.current?.close()} ref={drawerSettingsRef} />
      <Page.Header>
        {integrations ? (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box justifyContent="flex-start">
              <Typography.Title level={4} style={{ margin: 0 }}>
                <IntegrationIcons type={integrations} style={{ marginRight: 10 }} />
                Connected with{' '}
                <Typography.Text style={{ marginRight: 10 }} strong>
                  {integrationName}
                </Typography.Text>
                {/* Right now, we will show this only for QBD (but we can extend for others later) */}
                {integrationStore.isQBD() && (
                  <Switch
                    checkedChildren="online"
                    unCheckedChildren="offline"
                    defaultChecked={online}
                    disabled={online}
                    style={
                      online ? { backgroundColor: theme.colors['green-7'] } : { backgroundColor: theme.colors['red-7'] }
                    }
                    onChange={integrationStore.changeStatus}
                  />
                )}
              </Typography.Title>
            </Box>
            <Box justifyContent="flex-end">
              <Tooltip title="Edit integration settings">
                <Button
                  style={{ marginRight: 4 }}
                  icon={<SettingOutlined />}
                  disabled={!userStore.canSyncWithErp || integrationStore.syncingProgress?.syncing_init_data || logOut}
                  onClick={(event) => {
                    event.stopPropagation()
                    drawerSettingsRef.current?.show()
                  }}
                />
              </Tooltip>
              <Button
                disabled={!userStore.canSyncWithErp || integrationStore.syncingProgress?.syncing_init_data || logOut}
                type="primary"
                onClick={confirmLogout}
              >
                Logout
              </Button>
            </Box>
          </Box>
        ) : (
          <Typography.Paragraph style={{ margin: 0 }}>
            Connect the SubBase with others systems to sync data between them.
          </Typography.Paragraph>
        )}
      </Page.Header>

      <Page.Content>
        {loadingIntegration ? (
          <Loading />
        ) : integrations ? (
          <Authenticated />
        ) : (
          <Unauthenticated setLoading={setLoadingIntegration} />
        )}
      </Page.Content>
    </>
  )
})
