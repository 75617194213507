import React from 'react'

import styled from '@emotion/styled'

import { DownOutlined } from '@ant-design/icons'
import { Button, Tooltip, Dropdown } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'
import { OrderStates } from 'common/server/server_types'

type UpdateAndSabeButtonProps = {
  disabled?: boolean
  reopenButtonDisabled?: boolean
  onReopen?: () => void
  onUpdateWithNotification: () => void
  onUpdateWithoutNotification: () => void
  loading?: boolean
  orderState: OrderStates
  showReopenButton?: boolean
}

const Title = () => (
  <span>
    Save your changes <u>without</u> notifying your vendor
  </span>
)

const SaveButtonTooltip = ({ onUpdateWithoutNotification }) => (
  <Tooltip placement="bottomLeft" title={<Title />}>
    <div onClick={onUpdateWithoutNotification}>Save</div>
  </Tooltip>
)

const DropdownStyled = styled(Dropdown.Button)`
  & .ant-btn-compact-first-item {
    width: 100%;
  }
`

export const UpdateAndSaveButton = ({
  disabled,
  onReopen,
  onUpdateWithNotification,
  onUpdateWithoutNotification,
  loading,
  orderState,
  reopenButtonDisabled,
  showReopenButton,
}: UpdateAndSabeButtonProps) => {
  const showSaveButton = [
    OrderStates.QUOTED,
    OrderStates.PARTIALLY_SHIPPED,
    OrderStates.SHIPPED,
    OrderStates.ORDERED,
    OrderStates.DELIVERED,
    OrderStates.CANCELLED,
  ].includes(orderState)

  const orderPlaced = [
    OrderStates.PARTIALLY_SHIPPED,
    OrderStates.SHIPPED,
    OrderStates.ORDERED,
    OrderStates.DELIVERED,
    OrderStates.CANCELLED,
  ].includes(orderState)

  if (!orderPlaced) {
    return (
      <DropdownStyled
        menu={{
          items: [{ label: <SaveButtonTooltip onUpdateWithoutNotification={onUpdateWithoutNotification} />, key: '1' }],
        }}
        placement="bottomRight"
        disabled={disabled}
        data-cy="update-order"
        type="primary"
        onClick={onUpdateWithNotification}
        loading={loading}
        icon={<DownOutlined />}
        trigger={['click', 'hover']}
      >
        <Tooltip title="Save your changes and notify your vendor" placement="bottom">
          Update Vendor
        </Tooltip>
      </DropdownStyled>
    )
  }

  return (
    <FlexBoxX>
      {showReopenButton && onReopen && (
        <Box mr={{ _: 0, sm: 8 }}>
          <Tooltip title="Notify the vendor you are reopening the RFQ">
            <Button type="primary" onClick={onReopen} loading={loading} disabled={reopenButtonDisabled}>
              Reopen RFQ
            </Button>
          </Tooltip>
        </Box>
      )}

      <Tooltip title="Save your changes and notify your vendor" placement="bottom">
        <Button
          disabled={disabled}
          data-cy="update-order"
          type="primary"
          onClick={onUpdateWithNotification}
          loading={loading}
        >
          Update Vendor
        </Button>
      </Tooltip>

      {showSaveButton && (
        <Box ml={{ _: 0, sm: 8 }}>
          <Tooltip title={<Title />} placement="bottomLeft">
            <Button
              disabled={disabled}
              data-cy="save-order-button"
              type="primary"
              onClick={onUpdateWithoutNotification}
              loading={loading}
            >
              Save
            </Button>
          </Tooltip>
        </Box>
      )}
    </FlexBoxX>
  )
}
