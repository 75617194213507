import { minBy, groupBy, flatten } from 'lodash'

import { calcExtCost } from 'common/helpers/order'
import { CompanyMaterial } from 'common/server/company_materials'
import { OrderDelivery } from 'common/server/deliveries'
import { OrderMaterial } from 'common/server/orders'
import { OrderStates } from 'common/server/server_types'

import { ShowOrderResponse } from 'contractor/server/orders'
export interface TableDataSource {
  company_material: CompanyMaterial
  quantity: number
  vendor_items?: {
    vendor_note?: string
    unit_cost?: number
    originalUnitCost?: number
    delivery_id?: string
    delivery?: OrderDelivery
    isLowestPrice?: boolean
    taxValue?: number
    shippingValue?: number
    otherValue?: number
    discountValue?: number
    extended_cost?: number
    originalExtendedCost?: number
    orderId?: string
    orderMaterialId?: string
    discarded_at?: string
    quantity?: number
    originalQuantity?: number
    state?: OrderStates
  }[]
}

const generateKey = (id = '', quantity = '', extendedKey = '') => `${id}::${quantity}::${extendedKey}`

/*
  Sometimes the users put the same material and quantity twice in the same order,
  when this happens we show each material in an individual row to avoid problems
*/
const checkDuplicatedMaterial = (orderMaterial: OrderMaterial, index: number, order: ShowOrderResponse) => {
  const currentKey = generateKey(
    orderMaterial.company_material.id,
    orderMaterial.quantity?.toString(),
    orderMaterial.company_note,
  )

  const foundOrderMaterials = order.order_materials.filter((orderMaterial) => {
    const internalKey = generateKey(
      orderMaterial.company_material.id,
      orderMaterial.quantity?.toString(),
      orderMaterial.company_note,
    )
    return internalKey === currentKey
  })

  if (foundOrderMaterials?.length > 1) {
    return {
      ...orderMaterial,
      company_material: {
        ...orderMaterial?.company_material,
        id: `${orderMaterial?.company_material?.id}:${order.id}_${index}`,
      },
    }
  }

  return orderMaterial
}

const processDeliveries = (deliveries = []) => {
  return deliveries.reduce(
    (acc, delivery) => {
      acc.discountValue += Number(delivery.discount_value)
      acc.shippingValue += Number(delivery.shipping_value)
      acc.otherValue += Number(delivery.other_value)
      acc.taxValue += Number(delivery.tax_value)

      return acc
    },
    {
      discountValue: 0,
      shippingValue: 0,
      otherValue: 0,
      taxValue: 0,
    },
  )
}

export const makeTableData = (orders: ShowOrderResponse[] = []) => {
  const tableDataSource = {} as { [key: string]: TableDataSource }

  const orderMaterialsWithVendor = orders.map((order) => {
    /*
      Should not exist order materials without delivery, but for some reason exists,
      that is why we need to filter only order materials that have a delivery_id.
    */
    return order.order_materials
      .filter((om) => !!om.delivery_id)
      .map((om, index) => checkDuplicatedMaterial(om, index, order))
      .map((om) => {
        const delivery = order.deliveries.find((delivery) => delivery.id === om.delivery_id)
        return {
          ...om,
          company_vendor: order.company_vendor,
          delivery,
          deliveries: order.deliveries,
          orderId: order.id,
          state: order.state,
        }
      })
  })

  const orderMaterialsWithVendorFlat = flatten(orderMaterialsWithVendor)

  const materialsDictionary = groupBy(orderMaterialsWithVendorFlat, (item) => {
    return generateKey(item['company_material']['id'], item['quantity']?.toString(), item['company_note'])
  })

  Object.keys(materialsDictionary).forEach((key) => {
    orderMaterialsWithVendor.forEach((orderMaterials) => {
      const matchedMaterial = orderMaterials.find((om) => {
        const currentKey = generateKey(om.company_material.id, om.quantity?.toString(), om.company_note)
        return currentKey === key
      })

      if (matchedMaterial) {
        const unitCost = Number(matchedMaterial.unit_cost || 0)
        const quantity = Number(matchedMaterial.quantity || 0)

        const extendedCost = calcExtCost({
          quantity: matchedMaterial.quantity,
          unitCost: matchedMaterial.unit_cost,
          multiplier: matchedMaterial.company_material?.unit?.multiplier,
          qtyIncrement: matchedMaterial.company_material?.unit?.qty_increment,
        })

        const { discountValue, shippingValue, otherValue, taxValue } = processDeliveries(matchedMaterial.deliveries)

        tableDataSource[key] = {
          ...tableDataSource[key],
          company_material: matchedMaterial.company_material,
          quantity,
          vendor_items: [
            ...(tableDataSource[key]?.vendor_items || []),
            {
              vendor_note: matchedMaterial.vendor_note,
              unit_cost: unitCost,
              originalUnitCost: unitCost,
              delivery_id: matchedMaterial.delivery_id,
              delivery: matchedMaterial.delivery,
              taxValue: taxValue ? taxValue : null,
              shippingValue: shippingValue ? shippingValue : null,
              otherValue: otherValue ? otherValue : null,
              discountValue: discountValue ? discountValue : null,
              extended_cost: extendedCost,
              originalExtendedCost: extendedCost,
              orderId: matchedMaterial?.orderId,
              orderMaterialId: matchedMaterial?.id,
              discarded_at: matchedMaterial?.discarded_at,
              quantity,
              originalQuantity: quantity,
              state: matchedMaterial?.state,
            },
          ],
        }
      } else {
        // If no order material is found compatible with the current material, add an empty cell
        tableDataSource[key] = {
          ...tableDataSource[key],
          vendor_items: [...(tableDataSource[key]?.vendor_items || []), {}],
        }
      }
    })
  })

  // Checks who has the lowest and defined price on the vendor_items
  const tableDataSourceWithBadge = Object.values(tableDataSource).map((data) => {
    const min = minBy(data.vendor_items, (item) => {
      if (item.unit_cost > 0) {
        return item.unit_cost
      }
    })

    return {
      ...data,
      vendor_items: data.vendor_items.map((item) => {
        if (min && item.delivery_id === min.delivery_id && item.state !== OrderStates.CANCELLED) {
          return {
            ...item,
            isLowestPrice: true,
          }
        }

        return {
          ...item,
          quantity: null,
          isLowestPrice: false,
        }
      }),
    }
  }) as TableDataSource[]

  // Only need to show “deleted” materials when at least one vendor has a non-deleted version of the order material
  const dataSource = tableDataSourceWithBadge.filter((data) => data.vendor_items.some((item) => !item?.discarded_at))

  /*
    All the code below is to group the matched material descriptions and insert them right down.
    Ex: If two materials have the same descriptions we should show them in sequence, otherwise keep the sort from the backend
  */
  const groupedItemsMap = new Map()

  for (const item of dataSource) {
    const description = item.company_material.description
    if (!groupedItemsMap.has(description)) {
      groupedItemsMap.set(description, [])
    }
    groupedItemsMap.get(description).push(item)
  }

  const groupedItemsArray = []

  groupedItemsMap.forEach((value) => {
    groupedItemsArray.push(...value)
  })

  return groupedItemsArray
}
