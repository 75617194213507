import React from 'react'

import { Button, Form, Input, message, Row, Col, Space } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Page } from 'common/components/Page'

import { useStores } from 'contractor/hooks/use-stores'

interface UserPersonalInformationPayload {
  first_name: string
  last_name: string
  phone_number: string
}

export const PersonalInformation = observer(() => {
  const { userStore } = useStores()

  const [form] = Form.useForm()

  const user = userStore.currentUser

  const onSubmit = async (values: UserPersonalInformationPayload) => {
    try {
      await userStore.updatePersonalInformation(values)
      message.success('Personal information updated successfully')
    } catch (err) {
      console.error('Unable to update', err)
      message.error('Unable to update your personal information')
    }
  }

  const colProps = { xs: 24, md: 12 }

  if (!user) {
    return null
  }

  return (
    <>
      <Page.Header p={8} />

      <Page.Content>
        <Form<UserPersonalInformationPayload>
          initialValues={{
            first_name: user?.first_name,
            last_name: user?.last_name,
            phone_number: user?.phone_number,
          }}
          layout="vertical"
          form={form}
          style={{ width: '100%' }}
          onFinish={onSubmit}
        >
          <Row gutter={16}>
            <Col {...colProps}>
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[{ required: true, message: 'First name is required' }]}
              >
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col {...colProps}>
              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[{ required: true, message: 'Last name is required' }]}
              >
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col {...colProps}>
              <Form.Item name="phone_number" label="Phone Number">
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Box display="flex" justifyContent="flex-end" width="100%">
                <Space>
                  <Button onClick={() => form.resetFields()}>Cancel</Button>

                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Space>
              </Box>
            </Col>
          </Row>
        </Form>
      </Page.Content>
    </>
  )
})
