import moment from 'moment'

type GetDeliveryPickUpTitle = {
  requestedDeliveredAt?: string
  isPickUp?: boolean
  pickUpCount?: number
  deliveryCount?: number
}

export const getDeliveryPickUpTitle = ({
  requestedDeliveredAt,
  isPickUp = false,
  pickUpCount,
  deliveryCount,
}: GetDeliveryPickUpTitle) => {
  if (requestedDeliveredAt) {
    return moment(requestedDeliveredAt).format('MM/DD/YYYY')
  }

  if (isPickUp) {
    return `Pick Up ${pickUpCount}`
  }

  return `Delivery ${deliveryCount}`
}
