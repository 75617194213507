import React from 'react'

import { Select } from 'antd'

import { DeliveryExtraValues } from 'common/components/OrderMaterialsSpreadsheet'

type SelectTypeProps = {
  handleSelectChange: (value: string) => void
  selectedOption: string
  inputName?: DeliveryExtraValues
}

export const SelectType = (props: SelectTypeProps) => {
  const { selectedOption, handleSelectChange } = props
  const disabledFields = [DeliveryExtraValues.Shipping, DeliveryExtraValues.Other]
  return (
    <Select
      defaultValue="add"
      value={selectedOption}
      onChange={handleSelectChange}
      showArrow={!disabledFields.includes(props.inputName)}
      disabled={disabledFields.includes(props.inputName)}
      style={{
        width: 60,
      }}
    >
      <Select.Option value="AMOUNT">$</Select.Option>
      <Select.Option value="PERCENTAGE">%</Select.Option>
    </Select>
  )
}
