import styled from '@emotion/styled'

import { Box } from 'common/components/boxes'

export const ItemStyled = styled(Box)`
  height: fit-content;
  width: 100%;
  cursor: pointer;
  padding: 16px;
  transition: background 0.3s;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors['gray-4']};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors['gray-2']};
  }
`
