import React from 'react'

import { ShopOutlined, UserOutlined } from '@ant-design/icons'
import { Typography } from 'antd'

import { Box } from 'common/components/boxes'

type ActorProps = {
  vendor: string
  userName: string
  entity?: string
}

export const Actor = (props: ActorProps) => {
  const { vendor, userName, entity } = props

  if (!vendor && !userName) {
    return null
  }

  if (entity == 'System') {
    return (
      <Box marginLeft="16" display="flex" alignItems="center">
        <UserOutlined style={{ fontSize: 10 }} />
        <Typography.Text style={{ marginLeft: 4, fontSize: 10 }}>SubBase</Typography.Text>
      </Box>
    )
  }

  if (vendor) {
    return (
      <Box marginLeft="16" display="flex" alignItems="center">
        <ShopOutlined style={{ fontSize: 10, color: 'green' }} />
        <Typography.Text style={{ marginLeft: 4, fontSize: 10 }}>
          {vendor} - {userName}
        </Typography.Text>
      </Box>
    )
  }

  return (
    <Box marginLeft="16" display="flex" alignItems="center">
      <UserOutlined style={{ fontSize: 10 }} />
      <Typography.Text style={{ marginLeft: 4, fontSize: 10 }}>{userName}</Typography.Text>
    </Box>
  )
}
