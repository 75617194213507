import React from 'react'

import { useLocation } from 'react-router-dom'

import { ShowProjectResponse } from 'javascript/contractor/server/projects'

import sortOptions from './constants/sort_options'
import { Empty, Typography } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { DrawerTableSort } from 'common/components/@v2/DrawerTableSort'
import { Box, FlexBoxY } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { OrdersList } from 'common/components/OrdersList'
import { Page } from 'common/components/Page'
import { PsqlTable } from 'common/components/PsqlTable'
import { ResponsiveTable } from 'common/components/ResponsiveTable'
import { RibbonFilter } from 'common/components/RibbonFilter'
import { SearchInput } from 'common/components/SearchInput'
import { Visibility } from 'common/components/Visibility'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import { useMaybeOpenIntoNewTab } from 'common/hooks/use-maybe-open-into-new-tab'
import { usePushToOrder } from 'common/hooks/use-push-to-order'
import { useQuery } from 'common/hooks/use-query'
import { OrderStatesFilter, OrderSubStates } from 'common/server/server_types'

import ProjectSelector from 'contractor/components/ProjectSelector'
import { useActiveView } from 'contractor/hooks/use-active-view'
import { useStores } from 'contractor/hooks/use-stores'
import { Actions } from 'contractor/pages/@v2/Orders/components/Actions/actions'
import { OrdersFilters } from 'contractor/pages/@v2/Orders/components/Filters/filters'
import { ConsolidatedOrders } from 'contractor/server/orders'

interface Props {
  handleSelectDelivery?: (id?: string) => void
  canSelectDelivery?: boolean
}

function Content(props: Props) {
  const {
    userStore,
    companySettingStore,
    notificationStore,
    orderStore: { listStore },
    projectStore,
  } = useStores()

  const location = useLocation()
  const { getUrl } = usePushToOrder()
  const persistentFilters = usePersistentFilters()
  const { maybeOpenIntoNewTab } = useMaybeOpenIntoNewTab()
  function handleChangeProject(project: ShowProjectResponse) {
    persistentFilters.handleChangeFilters({ project_id: project?.id })
  }

  const activeView = useActiveView()

  async function init() {
    listStore.setFilter('view', activeView, false, true)

    const initialProjectId =
      new URLSearchParams(location.search).get('project_id') || sessionStorage['selectedProjectId']

    if (initialProjectId && initialProjectId.length > 0) {
      listStore.setFilter('project_id', initialProjectId, true, true)
    }

    return persistentFilters.init(new URLSearchParams({ view: activeView }))
  }

  useQuery(listStore.fetchFacets)
  const { isLoading = true } = useQuery(() => init(), [])

  const renderMobileCard = React.useCallback((item: ConsolidatedOrders.Order) => {
    const notificationCount = notificationStore.notificationCountByObject(item.id)

    return (
      <OrdersList.MobileCard
        notificationCount={notificationCount}
        item={item}
        onSelectDelivery={props.handleSelectDelivery}
        canSelectDelivery={props.canSelectDelivery}
        showVendor
      />
    )
  }, [])

  const ribbonOptions = React.useMemo(
    () => [
      { label: 'All', filter: '' },
      { label: 'Requested', filter: OrderStatesFilter.REQUESTED },
      ...(userStore.cannotSendAndUpdateOrders
        ? [{ label: 'Drafted Requests', filter: OrderSubStates.DRAFTED_REQUEST }]
        : [{ label: 'Drafted', filter: OrderStatesFilter.DRAFT }]),
      ...(companySettingStore?.otherSettings?.approval_state_enabled
        ? [{ label: 'Approved', filter: OrderStatesFilter.APPROVED }]
        : []),
      ...(userStore.canUseCreateRFQ ? [{ label: 'Quote Requested', filter: OrderSubStates.QUOTED_REQUESTED }] : []),
      ...(userStore.canUseCreateRFQ ? [{ label: 'Quote Provided', filter: OrderSubStates.QUOTED_RECEIVED }] : []),
      { label: 'Order Placed', filter: OrderSubStates.ORDERED_REQUESTED },
      { label: 'Order Confirmed', filter: OrderSubStates.ORDERED_CONFIRMED },
      { label: 'Partially Shipped', filter: OrderStatesFilter.PARTIALLY_SHIPPED },
      {
        label: 'Shipped',
        filter: OrderStatesFilter.SHIPPED,
      },
      { label: 'Delivered', filter: OrderStatesFilter.DELIVERED },
      { label: 'Cancelled', filter: OrderStatesFilter.CANCELLED, ignoreWhenCountingAll: true },
    ],
    [companySettingStore, userStore],
  )

  function handleSearch(value: string) {
    persistentFilters.handleChangeFilters({ search: value }, false)
  }

  function pushToOrder({ row }) {
    const url = getUrl({
      orderId: row.order_id,
      orderPackageId: row.order_package_id,
      state: row.state,
      subState: row.sub_state,
    })

    maybeOpenIntoNewTab(url)
  }

  if (isLoading) return <Loading />

  return (
    <Page>
      <Page.Header display="grid" gridGap={16}>
        <Visibility.Show breakpoint="md">
          <FlexBoxY width="100%">
            <Box display="flex" gridGap={8} alignItems="center" justifyContent="space-between" width="100%">
              <ProjectSelector showSettings showAddProject onChange={handleChangeProject} />
              <Actions
                canSendAndUpdateOrders={userStore?.canSendAndUpdateOrders}
                canSendAndUpdateRFQs={userStore?.canSendAndUpdateRfqs}
                publicOrderFormUrl={companySettingStore?.otherSettings?.public_order_form_url_extension}
                projectId={projectStore?.selectedProject?.id}
              />
            </Box>
            <Box mt="8" display="flex" gridGap={8} alignItems="center" justifyContent="space-between" width="100%">
              <SearchInput value={listStore.searchState.search} onSearch={handleSearch} />
              <DrawerTableSort options={sortOptions} />
              <OrdersFilters />
            </Box>
          </FlexBoxY>
        </Visibility.Show>

        <Visibility.Hidden breakpoint="md">
          <Box display="flex" gridGap={8} alignItems="center" justifyContent="space-between">
            <ProjectSelector showSettings showAddProject onChange={handleChangeProject} />
            <SearchInput value={listStore.searchState.search} onSearch={handleSearch} />
            <OrdersFilters />
            <Actions
              canSendAndUpdateOrders={userStore?.canSendAndUpdateOrders}
              canSendAndUpdateRFQs={userStore?.canSendAndUpdateRfqs}
              publicOrderFormUrl={companySettingStore?.otherSettings?.public_order_form_url_extension}
              projectId={projectStore?.selectedProject?.id}
            />
          </Box>
        </Visibility.Hidden>

        <Box width="100%" style={{ overflow: 'auto' }}>
          <RibbonFilter
            showCounts
            value={listStore.searchState.filters['state'] || []}
            counts={listStore.stateCounts || {}}
            onChange={(value) => persistentFilters.handleChangeFilters({ state: value })}
            options={ribbonOptions}
            boxProps={{ overflowX: 'auto' }}
          />
        </Box>
      </Page.Header>
      <Page.Content p={0} px={{ _: 0, md: 12, lg: 16 }} pb={24} pt={{ _: 0, md: 16 }} height="100%">
        {listStore.isFetching && listStore.records.length === 0 && listStore.searchState.page === 1 ? (
          <Loading />
        ) : listStore.records.length === 0 ? (
          <Box
            p={{ _: 12, lg: 24 }}
            height="100%"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <Typography.Title level={5} style={{ textAlign: 'center' }}>
                  Sorry! No Items were found with these search criteria.
                </Typography.Title>
              }
            />
          </Box>
        ) : (
          <ResponsiveTable
            Table={PsqlTable}
            data={toJS(listStore.records) ?? []}
            tableName="AllOrders"
            hasMore={listStore.hasMore}
            loadMore={listStore.fetchNextPage}
            onLoadMore={listStore.fetchNextPage}
            renderMobileCard={renderMobileCard}
            onClickRow={pushToOrder}
          />
        )}
      </Page.Content>
    </Page>
  )
}

export const OrdersContent = observer(Content)
