import React, { useEffect, useState } from 'react'

import { message } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { SearchInput } from 'common/components/SearchInput'
import { MappingDirections } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { Content } from 'contractor/pages/Integrations/Mappings/content'
import CostCodePhasesTable from 'contractor/pages/Integrations/Mappings/CostCodePhases/cost_code_phases_table'
import { Header } from 'contractor/pages/Integrations/Mappings/header'
import { RibbonMappings } from 'contractor/pages/Integrations/Mappings/ribbon_mappings'
import { ListParams } from 'contractor/server/integrations'

export const CostCodePhases = observer(() => {
  const { integrationStore } = useStores()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [filter, setFilter] = useState<ListParams>({ page: 1, per_page: 50 })

  const handleSearch = (value) => {
    setSearch(value)
  }

  useEffect(() => {
    if ((!filter.search || filter.search === '') && search === '') return
    const searchValue = search === '' ? undefined : search
    setFilter((prev) => ({ ...prev, search: searchValue }))
  }, [search])

  useEffect(() => {
    const loadCostCodePhases = async () => {
      setIsLoading(true)
      try {
        await integrationStore.getCostCodePhasesCount({ search: filter?.search })
        await integrationStore.getCostCodePhases(filter)
        await integrationStore.getCostCodePhasesRelationships(filter)
      } finally {
        setIsLoading(false)
      }
    }
    loadCostCodePhases()
  }, [filter, integrationStore.mappingDirection])

  const handleResync = async () => {
    try {
      await integrationStore.resyncCostCodePhases()
      message.success('Resync has started successfully')
    } catch (e) {
      message.error(e?.response?.data?.message || 'Unable to resync cost phases')
    }
  }

  const integrations = integrationStore.accountingIntegration?.integrations

  const searchPlaceholder =
    integrationStore.mappingDirection === MappingDirections.INSIDE_OUT
      ? 'Search for SubBase cost phases here...'
      : `Search for ${integrationStore.getIntegrationName()} cost phases here...`

  return (
    <>
      <Header title="Cost Phases" integrations={integrations} handleResync={handleResync} />
      <Content>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" justifyContent="flex-start" ml={10} width="26%">
            <RibbonMappings
              counts={integrationStore.costCodePhasesCount}
              setFilter={setFilter}
              isLoading={isLoading}
              overflowCount={99}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end" ml={10} width="100%">
            <SearchInput
              placeholder={searchPlaceholder}
              onSearch={(value) => handleSearch(value)}
              style={{ width: '100%' }}
            />
          </Box>
        </Box>
        <CostCodePhasesTable isLoading={isLoading} filter={filter} setFilter={setFilter} />
      </Content>
    </>
  )
})
