import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import CommentOutlined from '@ant-design/icons/CommentOutlined'
import NumberIcon from '@ant-design/icons/NumberOutlined'
import { Tooltip, Button, Typography } from 'antd'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import OrderState from 'common/components/statuses/order_state'
import { useTheme } from 'common/hooks/use-theme'

import { OrderQuickCommentModal } from 'contractor/components/OrderQuickCommentModal/order_quick_comment_modal'
import { request_update } from 'contractor/server/orders'

import { useLeveling } from './context'

const style = { minWidth: 85, textAlign: 'right' }

const Th = (props) => <th className="ant-table-cell" {...props} />

const initialState = {
  visible: false,
  order: null,
}

const Label = ({ text = '', color = '', borderColor = '' }) => {
  const theme = useTheme()
  return (
    <FlexBoxX>
      <span
        style={{
          backgroundColor: theme.colors[color],
          border: `1px solid ${theme.colors[borderColor]}`,
          marginRight: 8,
          width: 12,
          height: 12,
          borderRadius: 6,
        }}
      />
      <Typography.Text style={{ fontWeight: 'normal', fontSize: 13 }} ellipsis>
        {text}
      </Typography.Text>
    </FlexBoxX>
  )
}

const LastTh = styled(Th)`
  &::before {
    content: none !important;
  }
`

export const TableHeader = () => {
  const { isSplitting, orders } = useLeveling()

  const [messageModal, toggleMessageModal] = useState(initialState)

  return (
    <>
      <OrderQuickCommentModal
        visible={messageModal.visible}
        onCancel={() => toggleMessageModal(initialState)}
        orderId={messageModal.order?.id}
        orderState={messageModal.order?.state}
        vendorName={messageModal.order?.company_vendor?.vendor?.name || messageModal.order?.company_vendor?.vendor_name}
        onSubmit={async (params) => {
          await request_update(params)
        }}
      />
      <thead className="ant-table-thead">
        <tr>
          <Th colSpan={3} style={{ backgroundColor: 'transparent' }} className="border-right border-left border-top">
            <FlexBoxY alignItems="flex-start">
              <Label text="Lowest price" color="green-1" borderColor="green-2" />
              <Label text="Material ordered" color="blue-1" borderColor="blue-2" />
              <Label text="Material not added to order" color="gray-2" borderColor="gray-4" />
            </FlexBoxY>
          </Th>

          {orders.map((order) => {
            return (
              <Th
                colSpan={isSplitting ? 4 : 3}
                key={`group-${order.id}`}
                style={{ backgroundColor: 'transparent' }}
                className="border-right border-top"
              >
                <FlexBoxY>
                  <FlexBoxX>
                    <Link to={`/order/${order.id}`} target="_blank">
                      <NumberIcon />
                      {`${order.order_number}:`}&nbsp;
                    </Link>
                    {order.company_vendor?.vendor?.name || order.company_vendor?.vendor_name}
                  </FlexBoxX>

                  <FlexBoxX>
                    <OrderState
                      state={order.state}
                      sub_state={order.sub_state}
                      showIssue
                      deliveryIssues={order.deliveries.map((d) => d.delivery_issue_type)}
                    />
                    <Tooltip title="Send message to vendor">
                      <Button
                        icon={<CommentOutlined />}
                        size="small"
                        style={{ backgroundColor: 'transparent' }}
                        onClick={() =>
                          toggleMessageModal({
                            visible: true,
                            order,
                          })
                        }
                      />
                    </Tooltip>
                  </FlexBoxX>
                </FlexBoxY>
              </Th>
            )
          })}
        </tr>

        <tr>
          <Th className="border-left">Description</Th>
          <Th>Qty</Th>
          <LastTh className="border-right">Unit</LastTh>
          {orders.map((order) => {
            return (
              <React.Fragment key={order.id}>
                {isSplitting && <Th style={{}}>Qty</Th>}
                <Th style={style}>Unit Cost</Th>
                <Th style={style}>Ex. Cost</Th>
                <LastTh style={{ width: 39, minWidth: 39 }} className="border-right" />
              </React.Fragment>
            )
          })}
        </tr>
      </thead>
    </>
  )
}
