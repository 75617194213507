import React from 'react'

import { uniq } from 'lodash'

import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined'
import { Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX } from 'common/components/boxes'
import { ChangedItem, ChangeType, SyncStatus } from 'common/components/History'
import { InvoiceHistoryResponse } from 'common/server/invoice_history'

import { InvoiceStatusTag } from 'contractor/components/Invoices/invoice_status_tag'
import { useStores } from 'contractor/hooks/use-stores'

const INVOICE_FIELDS_CONFIG = [
  { key: 'State', type: 'state' },
  { key: 'Sync status', type: 'sync' },
  {
    key: 'Total amount',
    type: 'currency',
  },
  { key: 'Tags', type: 'tag' },
  { key: 'Document date', type: 'date' },
  { key: 'Due date', type: 'date' },
  { key: 'Marked extracted at', type: 'date' },
  { key: 'Marked extracted by', type: 'text' },
  { key: 'Marked reconciled at', type: 'date' },
  { key: 'Marked reconciled by', type: 'text' },
  { key: 'Assigned to', type: 'text' },
]
export const ALLOW_INVOICE_FIELDS = INVOICE_FIELDS_CONFIG.map((item) => item.key)

type InvoiceProps = {
  invoice: InvoiceHistoryResponse['invoice']
  snapshot: InvoiceHistoryResponse['snapshot']
  isCreate?: boolean
}

const InvoiceState = observer<InvoiceProps>(({ invoice, snapshot }) => {
  const { invoiceStateStore } = useStores()

  const statePrevId = invoice?.['State'] ? invoice?.['State'][0] : snapshot['State']
  const stateNextId = invoice?.['State'] ? invoice?.['State'][1] : snapshot['State']

  const statePrev = invoiceStateStore.invoiceStates?.find(({ id }) => id === statePrevId)
  const stateNext = invoiceStateStore.invoiceStates?.find(({ id }) => id === stateNextId)

  return (
    <FlexBoxX alignItems="center" justifyContent="flex-start" mb="8px">
      <Typography.Text style={{ marginRight: 8, whiteSpace: 'nowrap' }}>State:</Typography.Text>
      {!!statePrev && (
        <>
          <InvoiceStatusTag state={statePrev} style={{ width: 'auto' }} />
          <ArrowRightOutlined style={{ margin: '0 8px' }} />
        </>
      )}
      <InvoiceStatusTag state={stateNext} style={{ width: 'auto' }} />
    </FlexBoxX>
  )
})

export const Invoice = ({ invoice, snapshot, isCreate }: InvoiceProps) => {
  const keys = Object.keys(invoice).filter((key) => ALLOW_INVOICE_FIELDS.includes(key))

  const uniqKeys = uniq(keys)

  if (!uniqKeys.length) {
    return null
  }

  return (
    <>
      {uniqKeys
        .sort((a, b) => {
          if (a < b) return -1
          if (a > b) return 1
          return 0
        })
        .sort((a, b) => {
          const state = 'State'
          if (a === state && b !== state) return -1
          if (a !== state && b === state) return 1
          return 0
        })
        .map((key, index) => {
          const changes = invoice[key]

          if (key === 'State') {
            return <InvoiceState invoice={invoice} snapshot={snapshot} key={`${key}-${index}`} />
          }

          if (key === 'Sync status') {
            return <SyncStatus invoice={invoice} snapshot={snapshot} key={`${key}-${index}`} />
          }

          return (
            <ChangedItem
              key={`${key}-${index}`}
              label={key}
              changes={changes}
              isCreate={isCreate}
              type={INVOICE_FIELDS_CONFIG.find((item) => item.key === key)?.type as ChangeType}
            />
          )
        })}
    </>
  )
}
