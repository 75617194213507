import React, { useEffect } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

import _ from 'lodash'
import moment from 'moment'
import qs from 'qs'

import { Alert, Col, Form, message, Modal, Row, Skeleton, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { UploaderTitle } from 'common/components/@v2/UploaderTitle'
import { Box, FlexBoxX } from 'common/components/boxes'
import { OrderBlockedHeader } from 'common/components/OrderBlockedHeader'
import { OrderBlockedModals } from 'common/components/OrderBlockedModals'
import { Page } from 'common/components/Page'
import { Header } from 'common/components/Page/styles'
import { UnsavedPopup } from 'common/components/Page/unsaved_popup'
import UnloadWarning from 'common/components/unload_warning'
import { Visibility } from 'common/components/Visibility'
import { useNotifyOrder } from 'common/hooks/use-notify-order'
import { OrderStates, OrderSubStates } from 'common/server/server_types'

import { InternalComments } from 'contractor/components/InternalComments'
import { mapOptions, TermsAndConditionsModal } from 'contractor/components/TermsAndConditions'
import { WatchersDrawer } from 'contractor/components/WatchersDrawer'
import { makeCompanyUserWatcherOption } from 'contractor/components/WatchersSelect'
import { useStores } from 'contractor/hooks/use-stores'
import { useNewQuote, withNewQuoteProvider } from 'contractor/pages/NewQuote/context'

import { Actions, DropdownOptions } from '../../NewQuote/Actions'
import { ModalConfirmPurchase } from '../../NewQuote/modal_confirm_purchase'
import { ModalSplittingOrderPackageName } from '../../NewQuote/modal_splitting'
import { HeaderTitle } from '../../NewQuote/title'
import { VendorForm } from '../../NewQuote/VendorForm'
import { Attachments, PURCHASER_FILES_KEY } from './components/Attachments'
import { MaterialsAndDeliveries } from './components/Materials'
import { OrderFields } from './components/OrderFields'
import { Total } from './components/Total'

type NewQuoteProps = {
  orderType: OrderType
}

// Tried to use Antd Form for this but felt like things got too complicated when I wanted a bunch of
// Custom functionality managing state myself is easier...
export const NewQuotePage = withNewQuoteProvider(
  observer<NewQuoteProps>(({ orderType }) => {
    const { orderStore, projectStore, uploaderStore, userStore, companySettingStore, integrationStore } = useStores()

    const lastLocation = useLastLocation()
    const location = useLocation()
    const history = useHistory()

    const { notifyOrder } = useNotifyOrder()
    const {
      companyVendors,
      setOrderNumber,
      orderNumber,
      setCompanyVendors,
      syncOrderWithIntegration,
      setTermsAndConditionId,
      termsAndConditionId,
      loadCommitment,
      form,
      commitment,
      orderLocked: isPoLocked,
      orderSession,
    } = useNewQuote()

    const orderPackageIdParam = new URLSearchParams(location.search).get('order_package_id')

    const [isOrderPackageNameModal, setOrderPackageNameModal] = React.useState(false)
    const [purchaseOrderModalVisible, setPurchaseOrderModalVisible] = React.useState(false)
    const [termsAndConditionsModalVisible, setTermsAndConditionsModalVisible] = React.useState(false)
    const [isSubmitting, setSubmitting] = React.useState(false)
    const [overBudgetMaterial, setOverBudgetMaterial] = React.useState(null)

    const isRFQ = orderType === 'RFQ'
    const isRequest = orderStore?.selectedOrder?.state === OrderStates.REQUESTED
    const isApproved = orderStore?.selectedOrder?.state === OrderStates.APPROVED
    const isDraft = orderStore?.selectedOrder?.state === OrderStates.DRAFT

    const hasOnlyMaterialRequesterPermissions = !userStore.canSendAndUpdateOrders && !userStore.canSendAndUpdateRfqs

    const disableUpdateApproved = userStore.cannotSendAndUpdateOrders && isApproved

    const draftDoesntBelongToUser =
      userStore.companyUserId != orderStore?.selectedOrder?.company_user?.id &&
      isDraft &&
      userStore.cannotSendAndUpdateOrders

    const requestDoesntBelongToUser =
      userStore.companyUserId != orderStore?.selectedOrder?.requested_by_company_user_id &&
      isRequest &&
      userStore.cannotSendAndUpdateOrders

    const disabledForm =
      orderSession.isBlocked || disableUpdateApproved || draftDoesntBelongToUser || requestDoesntBelongToUser

    const disabledActions = orderSession.isBlocked || userStore.cannotSendAndUpdateOrders
    const isDeleted = [OrderSubStates.DELETED_DRAFT, OrderSubStates.DELETED_REQUEST].includes(
      orderStore?.selectedOrder?.sub_state,
    )

    const requiredOrderFields = [
      ...(companySettingStore.otherSettings?.required_order_fields?.quantity
        ? [
            {
              key: 'quantity',
              label: 'Quantity',
            },
          ]
        : []),
      ...(companySettingStore.otherSettings?.required_order_fields?.unit
        ? [
            {
              key: 'unit',
              label: 'Unit',
            },
          ]
        : []),
      ...(companySettingStore.otherSettings?.required_order_fields?.unit_cost
        ? [
            {
              key: 'unit_cost',
              label: 'Unit Cost',
            },
          ]
        : []),
      ...(companySettingStore.otherSettings?.required_order_fields?.cost_code
        ? [
            {
              key: 'cost_code_id',
              label: 'Cost Code',
            },
          ]
        : []),
    ]
    const requiredQuoteFields = [
      ...(companySettingStore.otherSettings?.required_quote_fields?.quantity
        ? [
            {
              key: 'quantity',
              label: 'Quantity',
            },
          ]
        : []),
      ...(companySettingStore.otherSettings?.required_quote_fields?.unit
        ? [
            {
              key: 'unit',
              label: 'Unit',
            },
          ]
        : []),
      ...(companySettingStore.otherSettings?.required_quote_fields?.cost_code
        ? [
            {
              key: 'cost_code_id',
              label: 'Cost Code',
            },
          ]
        : []),
    ]

    const injectDraft = async (orderPackageId) => {
      try {
        const orderPackage = await orderStore.loadDraft(orderPackageId)

        const orders = orderPackage.orders

        const newCompanyVendors = new Map()
        if (orderPackage.temp_company_vendors) {
          orderPackage.temp_company_vendors.forEach((companyVendor) => {
            newCompanyVendors.set(companyVendor.id, {
              ...companyVendor,
              order_id: orders[0].id,
              contacts: companyVendor.vendor_contacts,
              comment: companyVendor.comment,
            })
          })
        } else {
          orders.forEach((order) => {
            if (!order.company_vendor) {
              return
            }

            newCompanyVendors.set(order.company_vendor.id, {
              ...order.company_vendor,
              order_id: order.id,
              contacts: order.vendor_contacts,
              comment: _.get(order, 'comments[0].comment', ''),
            })
          })
        }

        form.setFieldsValue({
          commitmentId: orders[0].commitment_id,
          orderPackageId: orderPackage.id,
          orderPackageName: orders[0].order_package_name,
          projectId: orders[0].project_id,
          orderNumber: orders[0].order_number,
          quoteNumber: orders[0].quote_number,
          watchers: companySettingStore.possibleUsers
            .filter((user) => orders[0].watcher_ids.includes(user.company_user_id))
            .map((user) =>
              makeCompanyUserWatcherOption(
                user,
                !orderStore.selectedOrder?.watcher_ids.includes(user.company_user_id) ||
                  !hasOnlyMaterialRequesterPermissions,
              ),
            ),
          tags: orders[0].tags,
        })
        setCompanyVendors(Array.from(newCompanyVendors, ([_, value]) => value))
        setOrderNumber(orders[0].order_number)
      } catch {
        message.error(`Unable to load the draft ${orderType}`)
      }
    }

    const [loadingWatchers, setLoadingWatchers] = React.useState(false)
    React.useEffect(() => {
      const hasUsersLength = companySettingStore.possibleUsers.length
      const hasSelectedOrder = orderPackageIdParam && !orderStore.selectedOrder

      if (!hasUsersLength || hasSelectedOrder) {
        setLoadingWatchers(true)
        return
      }

      const watcherIds = orderStore.selectedOrder?.watcher_ids

      form.setFieldsValue({
        watchers: companySettingStore.possibleUsers
          .filter((user) => orderStore.selectedOrder?.watcher_ids.includes(user.company_user_id))
          .map((user) =>
            makeCompanyUserWatcherOption(
              user,
              !watcherIds.includes(user.company_user_id) || !hasOnlyMaterialRequesterPermissions,
            ),
          ),
      })

      setLoadingWatchers(false)
    }, [orderStore.selectedOrder?.watcher_ids, companySettingStore.possibleUsers])

    const injectDuplicateData = (order) => {
      const orderPackageName = order.order_package_name
      const newCompanyVendors = new Map()
      if (order.company_vendor) {
        newCompanyVendors.set(order.company_vendor.id, {
          ...order.company_vendor,
          contacts: order.vendor_contacts,
          comment: '',
        })
        setCompanyVendors(Array.from(newCompanyVendors, ([_, value]) => value))
      }
      form.setFieldsValue({ orderPackageName, projectId: null, watcherIds: [], orderNumber: null })
    }

    useEffect(() => {
      if (commitment) {
        setOverBudgetMaterial(orderStore.isOverBudget(commitment.id))
      }
    }, [orderStore.getPlainOrderMaterials()])

    const loadPageData = () => {
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
      // Initialize empty order unless duplicating
      if ('duplicate' in queryParams) {
        // If there is no selected order then probably refreshing, we want to redirect to NOT duplicate
        if (!orderStore.orderDuplicating) {
          history.push('/orders/new_order')
        } else {
          orderStore.duplicateOrder()
          injectDuplicateData(orderStore.orderDuplicating)
        }
      } else if (queryParams['order_package_id']) {
        injectDraft(queryParams['order_package_id'])
      } else if (queryParams['commitment_id']) {
        orderStore.clearOrder()
        loadCommitment(queryParams['commitment_id'])
      } else if (!queryParams['switchTo']) {
        orderStore.clearOrder()
      }
    }

    useEffect(() => {
      loadPageData()
    }, [location?.search])

    useEffect(() => {
      if (orderSession.isReleased) {
        loadPageData()
      }
    }, [orderSession.isReleased])

    useEffect(() => {
      return () => {
        orderStore.clearOrder()
        orderStore.cleanDuplicateOrder()
      }
    }, [])

    const goBack = () => {
      orderStore.selectOrder(null)

      // Do not send the user the creation order page if it was the previous route
      if (
        lastLocation &&
        !lastLocation.pathname.includes('new_order') &&
        !lastLocation.pathname.includes('new_quote')
      ) {
        history.goBack()
      } else {
        history.push('/orders')
      }
    }

    const validateOrderMaterial = () => {
      return {
        message: 'Must specify materials or add an attachment',
        invalid: uploaderStore.signedIds('purchaser_files').length === 0 && orderStore.totalMaterialsCount === 0,
      }
    }

    const validateUploads = () => {
      return {
        message: 'Uploads have not completed yet, please try again',
        invalid: !uploaderStore.checkIfAllUploadsCompleted(),
      }
    }

    const validateOrderVendorAddress = () => {
      const vendorsIds = companyVendors.map((companyVendor) => companyVendor.id)
      const deliveryWithError = orderStore.newDeliveries.find((delivery) => {
        return (
          delivery.is_pick_up &&
          !!vendorsIds.length &&
          !!delivery.address &&
          !vendorsIds.includes(delivery.address?.owned_by_id)
        )
      })

      return {
        message: "The address doesn't match with the vendor. Please make sure that the address belongs to the vendor!",
        invalid: !!deliveryWithError,
      }
    }

    const makeVendorsPayload = () => {
      return companyVendors.map((companyVendor) => {
        return {
          id: companyVendor.id,
          company_vendor_contact_ids: companyVendor.contacts.map((contact) => contact.id),
          comment: companyVendor.comment,
        }
      })
    }

    const handleCreateOrder = async ({
      orderPackageId,
      orderPackageName,
      orderNumber,
      quoteNumber,
      vendors,
      stateChanges,
      projectId,
      orderMaterials = [],
      deliveries = [],
      purchaserFilesSignedIds = [],
      purchaserFilesDeleteIds = [],
      watcherIds = [],
      tags,
      requestedByCompanyUserId = null,
      termsAndConditionId = null,
      commitmentId = null,
      splittedFromId = null,
      splittedToId = null,
    }) => {
      return orderStore.createOrder(
        {
          order_package_id: orderPackageId,
          order_package_name: orderPackageName,
          order_number: orderNumber,
          quote_number: quoteNumber,
          vendors,
          purchaser_files_signed_ids: purchaserFilesSignedIds,
          purchaser_files_delete_ids: purchaserFilesDeleteIds,
          state_changes: stateChanges,
          project_id: projectId,
          watcher_ids: _.uniq([...watcherIds, userStore.companyUserId]),
          tags: tags,
          order_materials: orderMaterials,
          deliveries,
          requested_by_company_user_id: requestedByCompanyUserId,
          terms_and_condition_id: termsAndConditionId,
          commitment_id: commitmentId,
          splitted_from_id: splittedFromId,
          splitted_to_id: splittedToId,
        },
        userStore.canCreateNewMaterial,
      )
    }

    const handleLogError = (error, defaultMessage = '') => {
      if (error?.response?.data?.error) {
        message.error({
          content: error.response.data.error,
          duration: 8,
        })
      } else {
        message.error(defaultMessage || `Unable to save the ${orderType}`)
      }
    }

    const handleSaveDraft = async ({
      orderMaterials = [],
      isBack = true,
      isShowNotification = true,
      throwError = false,
      changeSubMitting = true,
      splittedToId = null,
    }) => {
      const validations = [validateOrderMaterial(), validateOrderVendorAddress(), validateUploads()]
      if (validations.some((validation) => validation.invalid)) {
        const validation = validations.find((validation) => validation.invalid)
        const title = validation?.message
        Modal.error({ title })
        return
      }

      try {
        setSubmitting(true)
        const formValues = await form.validateFields()

        const isOrder = orderType === 'Order'
        const subState = () => {
          if (isRequest) {
            return isOrder ? OrderSubStates.REQUESTED_ORDER : OrderSubStates.REQUESTED_QUOTE
          }
          return isOrder ? OrderSubStates.DRAFT_ORDER : OrderSubStates.DRAFT_QUOTE
        }

        const [order] = await handleCreateOrder({
          orderPackageId: orderPackageIdParam,
          orderPackageName: formValues.orderPackageName,
          orderNumber: formValues.orderNumber,
          quoteNumber: formValues?.quoteNumber,
          vendors: makeVendorsPayload(),
          requestedByCompanyUserId: orderStore.selectedOrder?.requested_by_company_user_id,
          stateChanges: {
            sub_state: subState(),
            requested_at: isRequest ? moment().toISOString() : null,
          },
          projectId: formValues.projectId,
          purchaserFilesSignedIds: uploaderStore.signedIds('purchaser_files'),
          purchaserFilesDeleteIds: uploaderStore.deleteAttachmentIds['purchaser_files'],
          watcherIds: formValues?.watchers?.map((watcher) => watcher.value),
          tags: formValues.tags,
          orderMaterials,
          commitmentId: commitment?.id,
          splittedToId,
        })

        if (isShowNotification) {
          notifyOrder({
            message:
              isRequest || orderStore?.selectedOrder?.state === OrderStates.APPROVED
                ? 'Request Updated'
                : 'Saved Draft',
            orderType,
            state: order?.state,
            subState: order?.sub_state,
            orderId: order?.id,
            orderPackageId: order?.order_package_id,
            fileUrl: order?.purchase_order?.url,
          })
        }

        if (isBack) {
          goBack()
        }
      } catch (error) {
        if (throwError) {
          throw error
        } else {
          handleLogError(error)
        }
      } finally {
        if (changeSubMitting) setSubmitting(false)
      }
    }

    const handleSaveDraftAndApprove = async () => {
      const validations = [validateOrderMaterial(), validateOrderVendorAddress(), validateUploads()]

      if (validations.some((validation) => validation.invalid)) {
        const validation = validations.find((validation) => validation.invalid)
        const title = validation?.message
        Modal.error({ title })
        return
      }

      try {
        setSubmitting(true)
        const formValues = await form.validateFields()

        const isOrder = orderType === 'Order'

        const [order] = await handleCreateOrder({
          orderPackageId: orderPackageIdParam,
          orderPackageName: formValues.orderPackageName,
          orderNumber: formValues.orderNumber,
          quoteNumber: formValues?.quoteNumber,
          vendors: makeVendorsPayload(),
          requestedByCompanyUserId: orderStore.selectedOrder?.requested_by_company_user_id,
          stateChanges: {
            sub_state: isOrder ? OrderSubStates.DRAFT_ORDER : OrderSubStates.DRAFT_QUOTE,
          },
          projectId: formValues.projectId,
          purchaserFilesSignedIds: uploaderStore.signedIds('purchaser_files'),
          purchaserFilesDeleteIds: uploaderStore.deleteAttachmentIds['purchaser_files'],
          watcherIds: formValues?.watchers?.map((watcher) => watcher.value),
          tags: formValues.tags,
          commitmentId: commitment?.id,
        })
        await orderStore.approveOrder()

        notifyOrder({
          message: 'Request Approved',
          orderType,
          state: order?.state,
          subState: order?.sub_state,
          orderId: order?.id,
          orderPackageId: order?.order_package_id,
          fileUrl: order?.purchase_order?.url,
        })

        goBack()
      } catch (error) {
        handleLogError(error, 'Unable to approve')
      } finally {
        setSubmitting(false)
      }
    }

    const handleSendRfq = async () => {
      try {
        const formValues = await form.validateFields()
        const newOrderMaterials = orderStore.filteredOrderedMaterials()

        const validations = [validateOrderMaterial(), validateOrderVendorAddress(), validateUploads()]
        if (validations.some((validation) => validation.invalid)) {
          const validation = validations.find((validation) => validation.invalid)
          const title = validation?.message
          Modal.error({ title, onOk: () => setTermsAndConditionsModalVisible(false) })
          return
        }

        const errors = orderStore.validateRequiredFields(newOrderMaterials, requiredQuoteFields)
        if (errors.length) {
          Modal.error({
            title: `Please fill in the following required fields: ${errors.join(', ')}`,
            onOk: () => setTermsAndConditionsModalVisible(false),
          })
          return
        }

        setSubmitting(true)

        const [order] = await handleCreateOrder({
          orderPackageId: orderPackageIdParam,
          orderPackageName: formValues.orderPackageName,
          orderNumber: formValues.orderNumber,
          quoteNumber: formValues?.quoteNumber,
          vendors: makeVendorsPayload(),
          requestedByCompanyUserId: orderStore.selectedOrder?.requested_by_company_user_id,
          stateChanges: {
            quoted_at: moment().toISOString(),
          },
          projectId: formValues.projectId,
          purchaserFilesSignedIds: uploaderStore.signedIds('purchaser_files'),
          purchaserFilesDeleteIds: uploaderStore.deleteAttachmentIds['purchaser_files'],
          watcherIds: formValues?.watchers?.map((watcher) => watcher.value),
          tags: formValues.tags,
          termsAndConditionId,
          commitmentId: commitment?.id,
        })

        notifyOrder({
          message: 'Sent RFQ',
          orderType,
          state: order?.state,
          subState: order?.sub_state,
          orderId: order?.id,
          orderPackageId: order?.order_package_id,
          fileUrl: order?.purchase_order?.url,
        })

        goBack()
      } catch (error) {
        handleLogError(error)
      } finally {
        setSubmitting(false)
      }
    }

    const handlePlaceOrder = async () => {
      try {
        const formValues = await form.validateFields()
        const newOrderMaterials = orderStore.filteredOrderedMaterials()

        const validations = [validateOrderMaterial(), validateOrderVendorAddress(), validateUploads()]
        if (validations.some((validation) => validation.invalid)) {
          const validation = validations.find((validation) => validation.invalid)
          const title = validation?.message
          Modal.error({ title, onOk: () => setPurchaseOrderModalVisible(false) })
          return
        }

        const errors = orderStore.validateRequiredFields(newOrderMaterials, requiredOrderFields)
        if (errors.length) {
          const title = `Please fill in the following required fields: ${errors.join(', ')}`
          Modal.error({ title, onOk: () => setPurchaseOrderModalVisible(false) })
          return
        }

        setSubmitting(true)

        const [order] = await handleCreateOrder({
          orderPackageId: orderPackageIdParam,
          orderPackageName: formValues.orderPackageName,
          orderNumber: orderNumber || formValues.orderNumber,
          quoteNumber: formValues?.quoteNumber,
          vendors: makeVendorsPayload(),
          requestedByCompanyUserId: orderStore.selectedOrder?.requested_by_company_user_id,
          stateChanges: {
            ordered_at: moment().toISOString(),
          },
          projectId: formValues.projectId,
          purchaserFilesSignedIds: uploaderStore.signedIds('purchaser_files'),
          purchaserFilesDeleteIds: uploaderStore.deleteAttachmentIds['purchaser_files'],
          watcherIds: formValues?.watchers?.map((watcher) => watcher.value),
          tags: formValues.tags,
          termsAndConditionId,
          commitmentId: commitment?.id,
        })
        if (syncOrderWithIntegration) {
          integrationStore.createOrder(order.id)
        }

        notifyOrder({
          message: 'Placed Order',
          orderType,
          state: order?.state,
          subState: order?.sub_state,
          orderId: order?.id,
          orderPackageId: order?.order_package_id,
          fileUrl: order?.purchase_order?.url,
        })

        goBack()
      } catch (error) {
        handleLogError(error)
      } finally {
        setSubmitting(false)
      }
    }

    const handleConfirmSpliting = async (orderPackageName = '') => {
      try {
        const formValues = await form.validateFields()

        const hasOrderMaterialSelect = orderStore.getPlainOrderMaterials().filter((om) => om.select).length
        if (!hasOrderMaterialSelect) {
          Modal.error({ title: `Must select materials to split ${orderType}` })
          return
        }

        setSubmitting(true)

        // Order materials unselected in splitting should be stay in order
        const orderMaterialsToKeep = orderStore.getPlainOrderMaterials().filter((om) => !om.select)
        const { deliveries, order_materials } = orderStore.splitOrder()

        // Create splitted order
        const [order] = await handleCreateOrder({
          orderPackageId: null,
          orderPackageName,
          orderNumber: null,
          quoteNumber: null,
          stateChanges: {
            sub_state: orderType === 'Order' ? OrderSubStates.DRAFT_ORDER : OrderSubStates.DRAFT_QUOTE,
            requested_at: orderStore.selectedOrder.requested_at,
          },
          projectId: formValues.projectId,
          purchaserFilesSignedIds: [],
          watcherIds: formValues?.watchers?.map((watcher) => watcher.value),
          tags: formValues.tags,
          orderMaterials: order_materials,
          deliveries,
          vendors: makeVendorsPayload(),
          splittedFromId: orderStore.selectedOrder.id,
          requestedByCompanyUserId: orderStore.selectedOrder.requested_by_company_user_id,
          commitmentId: commitment?.id,
        })

        // Update current order
        await handleSaveDraft({
          orderMaterials: orderMaterialsToKeep,
          isBack: false,
          isShowNotification: false,
          throwError: true,
          changeSubMitting: false,
          splittedToId: order.id,
        })

        notifyOrder({
          message: `Successfully Split ${orderType}`,
          description: 'We moved your materials to a new draft.',
          orderType,
          state: order?.state,
          subState: order?.sub_state,
          orderId: order?.id,
          orderPackageId: order?.order_package_id,
          isNewTab: true,
          fileUrl: order?.purchase_order?.url,
        })
      } catch (error) {
        handleLogError(error, `Unable to split the ${orderType}`)
      } finally {
        setSubmitting(false)
        orderStore.endToSplitOrder()
      }
    }

    const handleSwitchTo = async (switchOrderType: OrderType) => {
      const isOrder = switchOrderType === 'Order'

      if (switchOrderType === 'Order' && companyVendors.length > 1) {
        Modal.error({ title: 'You cannot change RFQ into an Order with more than 1 vendor selected.' })
        return
      }

      // Clear unit cost when switching
      const orderMaterials = orderStore.getPlainOrderMaterials().map((orderMaterial) => ({
        ...orderMaterial,
        unit_cost: null,
        preferred_vendor_price: null,
        tax_value: null,
      }))
      orderStore.updateAllOrderMaterials(_.groupBy(orderMaterials, 'delivery_id'))

      // If the order has already been created we update it else just redirect between /new_quote or /new_order
      if (orderPackageIdParam) {
        try {
          const formValues = await form.validateFields()

          await handleCreateOrder({
            orderPackageId: orderPackageIdParam,
            orderPackageName: formValues.orderPackageName,
            orderNumber: formValues.orderNumber,
            quoteNumber: formValues?.quoteNumber,
            vendors: makeVendorsPayload(),
            requestedByCompanyUserId: orderStore.selectedOrder?.requested_by_company_user_id,
            stateChanges: {
              sub_state: isOrder ? OrderSubStates.DRAFT_ORDER : OrderSubStates.DRAFT_QUOTE,
            },
            projectId: formValues.projectId,
            purchaserFilesSignedIds: uploaderStore.signedIds('purchaser_files'),
            purchaserFilesDeleteIds: uploaderStore.deleteAttachmentIds['purchaser_files'],
            watcherIds: formValues?.watchers?.map((watcher) => watcher.value),
            tags: formValues.tags,
            commitmentId: commitment?.id,
          })
          history.push({
            pathname: isOrder ? '/orders/new_order' : '/orders/new_quote',
            search: history.location.search,
          })
        } catch (error) {
          handleLogError(error, `Unable to switch the ${orderType}`)
        } finally {
          setSubmitting(false)
        }
      } else {
        history.push(isOrder ? './new_order?switchTo=order' : './new_quote?switchTo=quote')
      }
    }

    const handleSendInternalComment = async ({ text, companyUserIds }) => {
      setSubmitting(true)
      try {
        await orderStore.sendInternalComment({ text, companyUserIds: [...companyUserIds, userStore.companyUserId] })
      } catch (error) {
        message.error('Unable to send message')
      } finally {
        setSubmitting(false)
      }
    }

    return (
      <>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            projectId: projectStore.selectedProject?.id,
            orderPackageName: '',
            orderNumber: null,
            quoteNumber: null,
            tags: [],
            watchers: companySettingStore.possibleUsers
              .filter((user) => projectStore.selectedProject?.default_watcher_user_ids?.includes(user.company_user_id))
              .map((user) =>
                makeCompanyUserWatcherOption(
                  user,
                  !orderStore.selectedOrder?.watcher_ids.includes(user.company_user_id) ||
                    !hasOnlyMaterialRequesterPermissions,
                ),
              ),
            companyVendorsContacts: [],
          }}
          onValuesChange={(changedValues) => {
            // Set projects default watchers when user change the project selector
            if (Object.prototype.hasOwnProperty.call(changedValues || {}, 'projectId')) {
              const selectedProject = projectStore.projects.find((project) => project.id === changedValues?.projectId)
              const defaultWatchers = selectedProject?.default_watcher_user_ids || []
              const watcherIds = _.compact(_.uniq([userStore.companyUserId, ...defaultWatchers]))
              const watchers = companySettingStore.possibleUsers
                .filter((user) => watcherIds.includes(user.company_user_id))
                .map((user) =>
                  makeCompanyUserWatcherOption(
                    user,
                    !orderStore.selectedOrder?.watcher_ids.includes(user.company_user_id) ||
                      !hasOnlyMaterialRequesterPermissions,
                  ),
                )
              form.setFieldsValue({ watchers })
            }

            if (Object.prototype.hasOwnProperty.call(changedValues || {}, 'orderNumber')) {
              setOrderNumber(changedValues?.orderNumber)
            }
            if (Object.prototype.hasOwnProperty.call(changedValues || {}, 'commitmentId')) {
              form.setFieldsValue({ quoteNumber: '' })
            }
          }}
          style={{ height: '100%' }}
          key={orderType}
        >
          <Page>
            <Page.Header>
              <Header
                backIcon={<UnsavedPopup goBack={goBack} isDirty={!disabledForm} />}
                onBack={() => null}
                title={
                  <HeaderTitle
                    orderType={orderType}
                    requestedAt={orderStore?.selectedOrder?.requested_at}
                    requestedBy={orderStore?.selectedOrder?.requested_by_name}
                    isRequest={isRequest}
                    isApproved={isApproved}
                    approvedAt={orderStore?.selectedOrder?.approved_at}
                    approvedBy={orderStore?.selectedOrder?.approved_by_name}
                    isDeleted={isDeleted}
                    deletedAt={orderStore?.selectedOrder?.updated_at}
                  />
                }
                extra={[
                  <Visibility.Hidden key="watchers">
                    <Form.Item name="watchers" style={{ margin: 0 }}>
                      {loadingWatchers ? (
                        <Box
                          display="inline-flex"
                          alignItems="center"
                          border="1px solid"
                          borderColor="gray-4"
                          borderRadius="md"
                          px={8}
                          py={2}
                          width="110px"
                          height="30px"
                          justifyContent="center"
                        >
                          <Skeleton.Button
                            shape="circle"
                            size="small"
                            active={loadingWatchers}
                            style={{ marginRight: 10 }}
                          />
                          <Typography.Text>Watchers</Typography.Text>
                        </Box>
                      ) : (
                        <WatchersDrawer
                          isDisabled={orderStore.isSplitting || disabledForm}
                          makeOption={makeCompanyUserWatcherOption}
                        />
                      )}
                    </Form.Item>
                  </Visibility.Hidden>,
                  <UploaderTitle
                    key="uploader"
                    data-cy="uploader"
                    type="text"
                    size="middle"
                    disabled={orderStore.isSplitting}
                    multiple={true}
                    uploadKey={PURCHASER_FILES_KEY}
                    fileList={uploaderStore.fileList(PURCHASER_FILES_KEY)}
                    onRemoveUpload={uploaderStore.removeUpload}
                    onAddNewUpload={uploaderStore.addNewUpload}
                    onResetUploads={uploaderStore.resetUploads}
                    onSetUploadError={uploaderStore.setUploadError}
                    onUpdateUpload={uploaderStore.updateUpload}
                  />,
                  <InternalComments
                    key="internal-comments"
                    onSubmit={orderStore.selectedOrder && handleSendInternalComment}
                    loading={isSubmitting}
                    value={!orderStore.selectedOrder && orderStore.newInternalComment.text}
                    onChange={!orderStore.selectedOrder && orderStore.updateInternalComment}
                    comments={orderStore.selectedOrder?.internal_comments}
                    isDraftOrder={!orderStore.selectedOrder}
                    orderState={orderStore?.selectedOrder?.state || OrderStates.DRAFT}
                  />,
                  <Actions
                    key="extras"
                    orderState={orderStore?.selectedOrder?.state || OrderStates.DRAFT}
                    orderType={orderType}
                    isLoading={isSubmitting}
                    isDeleted={isDeleted}
                    showPurchaseOrderModal={() => setPurchaseOrderModalVisible(true)}
                    showTermsAndConditionsModal={() => setTermsAndConditionsModalVisible(true)}
                    showOrderPackageNameModal={() => setOrderPackageNameModal(true)}
                    form={form}
                    onSaveDraftAndApprove={handleSaveDraftAndApprove}
                    onSaveDraft={handleSaveDraft}
                    onSendRfq={handleSendRfq}
                    isRequest={isRequest}
                    disabled={disabledActions || !!overBudgetMaterial}
                    updateDisabled={disabledForm && userStore.cannotEditCostCode}
                  />,
                  <DropdownOptions
                    key="options"
                    commitmentId={form.getFieldValue('commitmentId')}
                    orderPackageId={form.getFieldValue('orderPackageId')}
                    orderType={orderType}
                    onSwitchToOrder={() => handleSwitchTo('Order')}
                    onSwitchToRfq={() => handleSwitchTo('RFQ')}
                    onSplit={() => orderStore.startToSplitOrder()}
                    disabled={disabledActions}
                  />,
                ]}
                style={{ padding: '0', width: '100%' }}
              />

              <OrderBlockedHeader {...orderSession} isRFQ={isRFQ} />
            </Page.Header>

            <Page.Content p={0} px={{ _: 0, sm: 16 }} py={16} mb={64}>
              {orderStore.anyRequestedMaterial && (
                <Alert
                  type="warning"
                  message="To proceed, all new material requests must be approved or rejected. "
                  style={{ marginBottom: 16 }}
                />
              )}

              <FlexBoxX mb={16}>
                <OrderFields
                  disabled={disabledForm}
                  orderType={orderType}
                  form={form}
                  deliveryId={orderStore.newDeliveries[0]?.id}
                />
              </FlexBoxX>

              <Row gutter={[16, 16]} style={{ maxHeight: '100%' }}>
                <Col xs={24} sm={24} lg={16} xl={17} xxl={19}>
                  <Row gutter={[16, 16]}>
                    <Visibility.Show breakpoint="lg">
                      <Col span={24}>
                        <VendorForm orderType={orderType} form={form} disabled={disabledActions} />
                      </Col>

                      <Col span={24}>
                        <Attachments disabled={disabledForm} />
                      </Col>
                    </Visibility.Show>

                    <Col span={24}>
                      <MaterialsAndDeliveries
                        overBudgetMaterial={overBudgetMaterial}
                        disabled={disabledForm}
                        orderType={orderType}
                        isPoLocked={isPoLocked}
                      />
                    </Col>
                  </Row>
                </Col>

                <Visibility.Hidden breakpoint="lg">
                  <Col xs={24} sm={24} lg={8} xl={7} xxl={5}>
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <VendorForm orderType={orderType} form={form} disabled={disabledActions} />
                      </Col>

                      <Col span={24}>
                        <Attachments disabled={disabledForm} />
                      </Col>
                    </Row>
                  </Col>
                </Visibility.Hidden>

                <Visibility.Show>
                  <Col span={24}>
                    <Box display="flex" justifyContent="flex-end" width="100%" pr={16}>
                      <Actions
                        orderType={orderType}
                        orderState={orderStore?.selectedOrder?.state || OrderStates.DRAFT}
                        isLoading={isSubmitting}
                        isDeleted={isDeleted}
                        showPurchaseOrderModal={() => setPurchaseOrderModalVisible(true)}
                        showTermsAndConditionsModal={() => setTermsAndConditionsModalVisible(true)}
                        showOrderPackageNameModal={() => setOrderPackageNameModal(true)}
                        form={form}
                        onSaveDraftAndApprove={handleSaveDraftAndApprove}
                        onSaveDraft={handleSaveDraft}
                        onSendRfq={handleSendRfq}
                        disabled={disabledActions}
                      />
                    </Box>
                  </Col>
                </Visibility.Show>
              </Row>

              {/* TODO: Just defaulting to true because we right now don't capture a dirty flag for new orders */}
              <UnloadWarning
                showWarning={() => true}
                onSaveAndLeave={disabledForm ? undefined : () => handleSaveDraft({ isBack: false, throwError: true })}
                isSubmitting={isSubmitting}
              />
            </Page.Content>

            <ModalConfirmPurchase
              visible={purchaseOrderModalVisible}
              onCancel={() => setPurchaseOrderModalVisible(false)}
              isLoading={isSubmitting}
              onFinish={handlePlaceOrder}
            />

            <TermsAndConditionsModal
              open={termsAndConditionsModalVisible}
              onCancel={() => setTermsAndConditionsModalVisible(false)}
              isLoading={isSubmitting}
              onFinish={handleSendRfq}
              termsAndConditionsProps={{
                type: 'RFQ',
                options: companySettingStore.activeTermsAndConditions?.map(mapOptions),
                onChange: (termsAndConditionId) => setTermsAndConditionId(termsAndConditionId),
              }}
            />

            <ModalSplittingOrderPackageName
              initialValue={`${form.getFieldValue('orderPackageName')} - Split`}
              visible={isOrderPackageNameModal}
              onConfirm={handleConfirmSpliting}
              onCancel={() => setOrderPackageNameModal(false)}
            />
          </Page>
        </Form>

        <OrderBlockedModals
          {...orderSession}
          onCancelModalOrderExpired={() => goBack()}
          onCancelModalOrderReleased={() => goBack()}
          isRFQ={isRFQ}
        />

        <Total value={orderStore.totalCost} />
      </>
    )
  }),
)
