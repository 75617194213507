import React from 'react'

import { Theme } from '@emotion/react'

import {
  EditOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  AuditOutlined,
  CheckOutlined,
} from '@ant-design/icons'
import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import { Badge, Tag } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { formatEnumValue } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'
import { CancelledQuoteExpired, OrderStates, OrderStatesFilter, OrderSubStates } from 'common/server/server_types'

export type OrderStateTagProps = {
  state: OrderStatesFilter | OrderStates
  sub_state?: OrderSubStates | null
  notificationCount?: number
  deliveryIssues?: string[]
  showIssue?: boolean
  theme?: Theme
  size?: 'small' | 'medium' | 'large'
  fontSize?: number
  texts?: {
    [OrderStatesFilter.DELIVERED]?: string
    [OrderStatesFilter.DRAFT]?: string
    [OrderStatesFilter.DRAFTED_REQUEST]?: string
    [OrderStatesFilter.APPROVED]?: string
    [OrderStatesFilter.PARTIALLY_SHIPPED]?: string
    [OrderStatesFilter.REQUESTED]?: string
    [OrderStatesFilter.SHIPPED]?: string
    [OrderStatesFilter.QUOTED]?: {
      [OrderSubStates.QUOTED_RECEIVED]?: string
      [OrderSubStates.QUOTED_REQUESTED]?: string
    }
    [OrderStatesFilter.ORDERED]?: {
      [OrderSubStates.ORDERED_REQUESTED]?: string
      [OrderSubStates.ORDERED_CONFIRMED]?: string
    }
    [OrderStatesFilter.CANCELLED]?: {
      [OrderSubStates.CANCELLED_ORDER_BY_BUYER]?: string
      [OrderSubStates.CANCELLED_QUOTE_BY_BUYER]?: string
      [OrderSubStates.CANCELLED_ORDER_BY_VENDOR]?: string
      [OrderSubStates.CANCELLED_QUOTE_BY_VENDOR]?: string
      [OrderSubStates.CANCELLED_MERGED]?: string
      [OrderSubStates.DELETED_DRAFT]?: string
      [OrderSubStates.DELETED_REQUEST]?: string
    }
    [CancelledQuoteExpired.CANCELLED_QUOTED_EXPIRED]?: string
  }
  style?: React.CSSProperties
}

const OrderStateTag = ({
  state,
  notificationCount,
  sub_state,
  showIssue,
  deliveryIssues,
  size,
  fontSize: fontSizeProp,
  theme: themeProp, //HACK: We had to pass the theme here because of algolia autocomplete
  texts,
  style,
}: OrderStateTagProps) => {
  const theme = themeProp || useTheme()

  const isDeleted = [OrderSubStates.DELETED_DRAFT, OrderSubStates.DELETED_REQUEST].includes(sub_state)

  const getCancelledTexts = () => {
    switch (sub_state) {
      case OrderSubStates.CANCELLED_ORDER_BY_VENDOR:
        return texts?.CANCELLED?.CANCELLED_ORDER_BY_VENDOR || 'Cancelled by Vendor'
      case OrderSubStates.CANCELLED_QUOTE_BY_VENDOR:
        return texts?.CANCELLED?.CANCELLED_QUOTE_BY_VENDOR || 'Cancelled by Vendor'
      case OrderSubStates.CANCELLED_ORDER_BY_BUYER:
        return texts?.CANCELLED?.CANCELLED_ORDER_BY_BUYER || 'Cancelled by Buyer'
      case OrderSubStates.CANCELLED_QUOTE_BY_BUYER:
        return texts?.CANCELLED?.CANCELLED_QUOTE_BY_BUYER || 'Cancelled by Buyer'
      case OrderSubStates.CANCELLED_MERGED:
        return texts?.CANCELLED?.CANCELLED_MERGED || 'Cancelled: Merged'
      case OrderSubStates.DELETED_DRAFT:
        return texts?.CANCELLED?.DELETED_DRAFT || 'Deleted Draft'
      case OrderSubStates.DELETED_REQUEST:
        return texts?.CANCELLED?.DELETED_REQUEST || 'Deleted Request'
      default:
        return 'Quote Expired'
    }
  }

  // Default to the earlier stage (i.e. assume quote was requested but not received if no substate provided)
  // This is useful for the OrderMaterials in quoting stage
  const StateMapping = {
    [OrderStatesFilter.REQUESTED]: {
      color: theme.colors['gold-1'],
      text: texts?.REQUESTED || 'Requested',
      icon: <AuditOutlined style={{ paddingTop: 4 }} />,
    },
    [OrderStatesFilter.DRAFT]: {
      color: theme.colors['gold-1'],
      text: texts?.DRAFT || 'Drafted',
      icon: <EditOutlined style={{ paddingTop: 4 }} />,
    },
    [OrderStatesFilter.DRAFTED_REQUEST]: {
      color: theme.colors['gold-1'],
      text: texts?.DRAFTED_REQUEST || 'Draft Request',
      icon: <EditOutlined style={{ paddingTop: 4 }} />,
    },
    [OrderStatesFilter.APPROVED]: {
      color: theme.colors['gold-1'],
      text: texts?.APPROVED || 'Approved',
      icon: <CheckOutlined style={{ paddingTop: 4 }} />,
    },
    [OrderStatesFilter.QUOTED]: {
      color: sub_state == OrderSubStates.QUOTED_RECEIVED ? 'blue' : 'gold',
      text:
        sub_state == OrderSubStates.QUOTED_RECEIVED ? (
          <span style={{ marginLeft: -3 }}>{texts?.QUOTED?.QUOTED_RECEIVED || 'Quote Provided'}</span>
        ) : (
          texts?.QUOTED?.QUOTED_REQUESTED || 'Quote Requested'
        ),
      icon: sub_state == OrderSubStates.QUOTED_RECEIVED ? <FileSyncOutlined style={{ paddingTop: 4 }} /> : undefined,
    },
    [OrderStatesFilter.ORDERED]: {
      color: sub_state == OrderSubStates.ORDERED_CONFIRMED ? 'blue' : 'gold',
      text:
        sub_state == OrderSubStates.ORDERED_CONFIRMED ? (
          <span style={{ marginLeft: -3 }}>{texts?.ORDERED?.ORDERED_CONFIRMED || 'Order Confirmed'}</span>
        ) : (
          texts?.ORDERED?.ORDERED_REQUESTED || 'Order Placed'
        ),
      icon: sub_state == OrderSubStates.ORDERED_CONFIRMED ? <FileDoneOutlined style={{ paddingTop: 4 }} /> : undefined,
    },
    [OrderStatesFilter.SHIPPED]: { color: 'green', text: texts?.SHIPPED || 'Shipped' },
    [OrderStatesFilter.PARTIALLY_SHIPPED]: { color: 'green', text: texts?.PARTIALLY_SHIPPED || 'Partially Shipped' },
    [OrderStatesFilter.DELIVERED]: { color: 'green', text: texts?.DELIVERED || 'Delivered' },
    [OrderStatesFilter.CANCELLED]: {
      color: theme.colors['gray-2'],
      text: getCancelledTexts(),
      icon: isDeleted ? <DeleteOutlined style={{ paddingTop: 4 }} /> : null,
    },
    [OrderStates.ORDERED_CONFIRMED_CANCELLATION_REQUESTED]: {
      color: theme.colors['gray-2'],
      text: 'Requested Cancellation',
    },
  }

  const defaultStyle = {
    color: 'black',
    width: '140px',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'normal',
    ...style,
  }

  const getStyle = () => {
    switch (size) {
      case 'medium':
        return {
          ...defaultStyle,
          height: 25,
        }
      case 'large':
        return {
          ...defaultStyle,
          height: 32,
        }
      case 'small':
      default:
        return defaultStyle
    }
  }

  const fontSize = fontSizeProp || 12

  return (
    <>
      {/* TODO: Maybe make this yellow? Confusing that it is red, feels like a problem */}
      <Badge style={{ right: 12 }} count={notificationCount}>
        <Tag icon={StateMapping[state]?.['icon']} color={StateMapping[state]?.['color']} style={getStyle()}>
          <FlexBoxX aria-label="status-badge" fontSize={fontSize}>
            {StateMapping[state]?.['text']}
          </FlexBoxX>
        </Tag>
      </Badge>
      {showIssue &&
        deliveryIssues &&
        deliveryIssues.map(
          (issue, index) =>
            issue && (
              <Tag
                style={{ ...getStyle(), marginTop: 2 }}
                color="red"
                key={index}
                icon={<ExclamationCircleOutlined style={{ paddingTop: 4 }} />}
              >
                <span style={{ marginLeft: -3 }}>{formatEnumValue(issue)}</span>
              </Tag>
            ),
        )}
    </>
  )
}

export default OrderStateTag
