import React from 'react'

import { Input } from 'antd'
import { TextAreaProps as AntdTextAreaProps } from 'antd/lib/input'
import { TextAreaRef } from 'antd/lib/input/TextArea'

type TextAreaProps = AntdTextAreaProps

export const TextArea = React.forwardRef<TextAreaRef, TextAreaProps>((props, ref) => {
  return <Input.TextArea ref={ref} {...props} />
})
