import React from 'react'

import { render, screen } from 'common/test-utils/index'

import { Attachments, AttachmentsProps } from '../attachments'

describe.skip('CompareOrders > attachments', () => {
  it('should render correct attachments', () => {
    render(
      <table>
        <tfoot>
          <Attachments
            orders={
              [
                {
                  id: '1',
                  quote: {
                    filename: 'quote.txt',
                    url: '/url/quote.txt',
                    key: '123',
                  },
                  vendor_files: [
                    {
                      filename: 'vendor-file-1.txt',
                      url: '/url/vendor-file-1.txt',
                      key: '321',
                    },
                    {
                      filename: 'vendor-file-2.txt',
                      url: '/url/vendor-file-2.txt',
                      key: '231',
                    },
                  ],
                },
              ] as AttachmentsProps['orders']
            }
          />
        </tfoot>
      </table>,
    )

    expect(screen.getByText('Quote Attachments')).toBeTruthy()
    expect(screen.getByText('quote.txt')).toBeTruthy()

    expect(screen.getByText('Vendor Attachments')).toBeTruthy()
    expect(screen.getByText('vendor-file-1.txt')).toBeTruthy()
    expect(screen.getByText('vendor-file-2.txt')).toBeTruthy()
  })

  it('should render empty cell if no have attachment', () => {
    render(
      <table>
        <tfoot>
          <Attachments
            orders={
              [
                {
                  id: '1',
                  quote: null,
                  vendor_files: [],
                },
              ] as AttachmentsProps['orders']
            }
          />
        </tfoot>
      </table>,
    )

    expect(screen.getAllByTestId('empty-quote').length).toBe(1)
    expect(screen.getAllByTestId('empty-vendor-files').length).toBe(1)
  })

  it('should render download item link', () => {
    render(
      <table>
        <tfoot>
          <Attachments
            orders={
              [
                {
                  id: '1',
                  quote: {
                    filename: 'quote.txt',
                    url: '/url/quote.txt',
                    key: '123',
                  },
                  vendor_files: [
                    {
                      filename: 'vendor-file-1.txt',
                      url: '/url/vendor-file-1.txt',
                      key: '321',
                    },
                  ],
                },
              ] as AttachmentsProps['orders']
            }
          />
        </tfoot>
      </table>,
    )

    expect(screen.getByText('quote.txt').closest('a').href).toContain('/url/quote.txt')
    expect(screen.getByText('vendor-file-1.txt').closest('a').href).toContain('/url/vendor-file-1.txt')
  })
})
