import React, { useState } from 'react'

import { AutoComplete } from 'antd'
import { AutoCompleteProps } from 'antd/lib/auto-complete'

import { observer } from 'mobx-react-lite'

import { useStores } from 'contractor/hooks/use-stores'

type CompanyVendorContactAutoCompletePRops = { domain?: string } & AutoCompleteProps

const makeOption = (vendorUser) => ({ label: vendorUser.email, value: vendorUser.id, vendorUser })

export const CompanyVendorContactAutoComplete = observer<CompanyVendorContactAutoCompletePRops>(
  ({ domain, ...props }) => {
    const { vendorStore } = useStores()

    const [options, setOptions] = useState([])

    const handleSearch = (searchText: string) => {
      const searchTextFormatted = searchText.trim().toLowerCase()

      const filteredUsers = vendorStore.vendorUsers
        .filter(({ is_public }) => is_public)
        .filter(({ email }) => (searchTextFormatted ? email.toLowerCase().includes(searchTextFormatted) : true))
        .filter(({ email }) => (domain ? domain === email.split('@')[1] : true))
        .map(makeOption)

      setOptions(filteredUsers)
    }

    const handleChange = (value = '', option) => {
      if (option && Object.keys(option).length) {
        props?.onChange(option.label, option)
      } else {
        props?.onChange(value, option)

        if (value.trim()) {
          const foundVendorUser = vendorStore.vendorUsers.find(
            ({ email }) => email.toLowerCase() === value.toLowerCase(),
          )

          if (foundVendorUser) {
            const vendorUser = makeOption(foundVendorUser)
            props?.onSelect?.(vendorUser.label, vendorUser)
          }
        }
      }
    }

    return <AutoComplete {...props} allowClear options={options} onSearch={handleSearch} onChange={handleChange} />
  },
)
