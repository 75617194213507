import { CreateVendorContactRequest } from 'contractor/server/company_vendor_contacts'

/*
	We have 3 scenarios on the UI, 2 and 3 has the same sub paths.
	1. User creates a new vendor.
	2. User selectes a existing vendor.
	2.1. User selects their own company vendor.
	2.2. User selects a global vendor.
	3. User selects a vendor user in contact email.
	3.1. User already has the global vendor added.
	3.1. User does not have the global vendor added.
*/
export const makeCompanyVendorPaylod = ({
  companyVendorOrVendor,
  vendorName,
  externalVendorId,
  vendorUser,
}): CreateVendorContactRequest['vendor'] => {
  // If user selected a global vendor user in the contact email field
  if (vendorUser) {
    return {
      vendor_id: vendorUser?.vendor?.id,
    }
  }

  // If user selected vendor in the vendor field
  if (companyVendorOrVendor) {
    return companyVendorOrVendor?.ownedByContractor
      ? {
          id: companyVendorOrVendor?.value,
        }
      : {
          vendor_id: companyVendorOrVendor?.value,
        }
  }

  // If are a new vendor
  return {
    vendor_name: vendorName,
    external_vendor_id: externalVendorId,
  }
}
