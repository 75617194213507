import React from 'react'

import styled from '@emotion/styled'

import { Collapse, Empty } from 'antd'

import { NoficationExtraActions } from 'common/components/Notifications/notification_extra_actions'
import { NotificationHeader } from 'common/components/Notifications/notification_header'
import { Notifications } from 'common/components/Notifications/NotificationMessages/notifications'
import { NotificationData, ServerNotificationGroup } from 'common/server/user'

import { NotificationsProps } from './notifications'

type NotificationRowProps = {
  notificationGroups: ServerNotificationGroup[]
  dismissMessage: (id: string) => Promise<void>
  dismissGroup: (id: string) => Promise<void>
  closeDrawer: () => void
  loadMessages: (groupId: string) => Promise<NotificationData[]>
} & Pick<NotificationsProps, 'onDismissNotificationGroup'>

const StyledCollapseItem = styled(Collapse.Panel)`
  border: none;
  width: 100% !important;
  border-bottom: 1px solid #f4f4f4 !important;
  & > .ant-collapse-header {
    padding: 6px 16px !important;
  }
`

export const NotificationGroups = ({
  notificationGroups,
  onDismissNotificationGroup,
  closeDrawer,
  loadMessages,
  dismissMessage,
  dismissGroup,
}: NotificationRowProps) => {
  const [activeKeys, setActiveKeys] = React.useState<string | string[]>([])
  return (
    <>
      <Collapse
        bordered={false}
        onChange={(values) => setActiveKeys(values)}
        ghost
        style={{ width: `100%`, paddingBottom: 2, paddingTop: 2 }}
      >
        {notificationGroups.map((group) => (
          <StyledCollapseItem
            extra={
              <NoficationExtraActions
                activeKeys={activeKeys}
                notification={group}
                onDismissNotificationGroup={onDismissNotificationGroup}
              />
            }
            key={group.id}
            showArrow={false}
            header={<NotificationHeader group={group} />}
          >
            <Notifications
              dismissGroup={dismissGroup}
              closeDrawer={closeDrawer}
              dismissMessage={dismissMessage}
              loadMessages={loadMessages}
              link={group.link}
              groupId={group.id}
              groupUnread={group.unread}
              notificationIds={group.notification_ids}
            />
          </StyledCollapseItem>
        ))}
      </Collapse>

      {!notificationGroups?.length && <Empty />}
    </>
  )
}
