import React from 'react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

type DraggableListProps = {
  children: React.ReactNode
}

// https://react-dnd.github.io/react-dnd/examples/sortable/simple
export const DraggableList = ({ children }: DraggableListProps) => {
  return <DndProvider backend={HTML5Backend}>{children}</DndProvider>
}
