import React from 'react'

import Icon from '@ant-design/icons'

const ContractorSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 18 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6027 16.993C16.1888 16.0126 15.5881 15.122 14.8342 14.371C14.0826 13.6177 13.1922 13.0172 12.2122 12.6025C12.2034 12.5981 12.1947 12.5959 12.1859 12.5915C13.5528 11.6042 14.4415 9.99585 14.4415 8.18131C14.4415 8.11722 14.4403 8.05339 14.4381 7.98984H12.7692C12.7723 8.05339 12.7739 8.11723 12.7739 8.18131C12.7739 9.18841 12.3812 10.1363 11.6681 10.8494C10.955 11.5625 10.0071 11.9552 9 11.9552C7.99289 11.9552 7.04503 11.5625 6.33193 10.8494C5.61884 10.1363 5.22609 9.18841 5.22609 8.18131C5.22609 8.11723 5.22768 8.05339 5.23084 7.98984H3.56185C3.55965 8.05339 3.55855 8.11722 3.55855 8.18131C3.55855 9.99585 4.44717 11.6042 5.81412 12.5937C5.80534 12.5981 5.79656 12.6003 5.78779 12.6047C4.80482 13.0194 3.92277 13.614 3.1658 14.3732C2.41257 15.1248 1.81203 16.0152 1.39732 16.9951C0.989922 17.9545 0.770201 18.9831 0.750055 20.0252C0.749469 20.0487 0.753577 20.072 0.762135 20.0938C0.770694 20.1156 0.783531 20.1354 0.799889 20.1522C0.816247 20.169 0.835796 20.1823 0.857384 20.1914C0.878971 20.2005 0.902161 20.2052 0.925586 20.2052H2.24207C2.33861 20.2052 2.4154 20.1284 2.4176 20.034C2.46148 18.3402 3.14166 16.7538 4.34405 15.5514C5.58812 14.3073 7.2403 13.6228 9 13.6228C10.7597 13.6228 12.4119 14.3073 13.656 15.5514C14.8583 16.7538 15.5385 18.3402 15.5824 20.034C15.5846 20.1306 15.6614 20.2052 15.7579 20.2052H17.0744C17.0978 20.2052 17.121 20.2005 17.1426 20.1914C17.1642 20.1823 17.1838 20.169 17.2001 20.1522C17.2165 20.1354 17.2293 20.1156 17.2379 20.0938C17.2464 20.072 17.2505 20.0487 17.2499 20.0252C17.228 18.9764 17.0108 17.9562 16.6027 16.993Z" />
    <path d="M7.66667 3.1748L7.03471 2.1848C6.74262 1.60061 6.47299 1.09727 6.46176 1.0748C6.39435 1.00739 5.79893 1.3566 5.30462 1.7498C4.39464 2.48003 3.66667 3.73182 3.66667 4.74291V5.07624L3.38889 5.07624C3.22222 5.07624 3 5.18736 3 5.35402V6.74291C3 6.90958 3.16667 7.07624 3.33333 7.07624H8.96178H14.5902C14.7569 7.07624 14.9236 6.90958 14.9236 6.74291V5.35402C14.9236 5.18736 14.7013 5.07624 14.5347 5.07624L14.2569 5.07624V4.74291C14.2569 3.73182 13.5289 2.48003 12.6189 1.7498C12.1246 1.3566 11.5292 1.00739 11.4618 1.0748C11.4506 1.09727 11.1809 1.60061 10.8888 2.1848L10.2569 3.1748V0.6398C10.2569 0.371976 9.92356 0.0398203 9.64534 0.0398203C9.48378 0.0327362 9.26774 0.0401503 8.96178 0.0397996C8.65582 0.0401503 8.43978 0.0327362 8.27821 0.0398203C8 0.0398203 7.66667 0.371976 7.66667 0.6398V3.1748Z" />
  </svg>
)

export const ContractorIcon = (props) => <Icon component={ContractorSvg} {...props} />
