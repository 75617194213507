import React from 'react'

import pluralize from 'pluralize'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { useTheme } from 'common/hooks/use-theme'

import { useInvoice } from '../../context'

type TotalValuesDrawerProps = {
  ordersGrandTotal?: number
  totalInvoicedAmount?: number
  invoicesCount?: number
  ordersCount?: number
}

export const TotalValuesDrawer = ({
  ordersGrandTotal = 0,
  totalInvoicedAmount = 0,
  invoicesCount = 0,
  ordersCount = 0,
}: TotalValuesDrawerProps) => {
  const theme = useTheme()
  const { currencyFormatter } = useInvoice()

  const remaining = ordersGrandTotal - totalInvoicedAmount

  const getRemainingPriceColor = () => {
    if (remaining === 0) {
      return theme.colors['gray-7']
    }

    if (remaining > 0) {
      return theme.colors.link
    }

    return theme.colors.error
  }

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" justifyContent="space-between" p={16}>
        <Typography.Text strong>PO Grand Total ({pluralize('Order', ordersCount, true)})</Typography.Text>
        <Typography.Text style={{ textAlign: 'left' }}>{currencyFormatter(ordersGrandTotal)}</Typography.Text>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={16}
        borderBottomStyle="solid"
        borderBottomWidth="1px"
        borderBottomColor="gray-4"
        borderTopStyle="solid"
        borderTopWidth="1px"
        borderTopColor="gray-4"
      >
        <Typography.Text strong>Total Invoiced ({pluralize('Invoice', invoicesCount, true)})</Typography.Text>
        <Typography.Text style={{ textAlign: 'left' }}>{currencyFormatter(totalInvoicedAmount)}</Typography.Text>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between" py={8} px={16}>
        <Box display="flex" flexDirection="column">
          <Typography.Text strong style={{ whiteSpace: 'pre' }}>
            Remaining
          </Typography.Text>
          {remaining > 0 && (
            <Typography.Text strong style={{ fontSize: 12, color: theme.colors.link, whiteSpace: 'pre' }}>
              *Outstanding balance to be invoiced
            </Typography.Text>
          )}
          {remaining < 0 && (
            <Typography.Text strong style={{ fontSize: 12, color: theme.colors.error, whiteSpace: 'pre' }}>
              *Total invoiced exceeds PO amount
            </Typography.Text>
          )}
        </Box>
        <Typography.Text style={{ color: getRemainingPriceColor(), textAlign: 'left' }}>
          {currencyFormatter(remaining)}
        </Typography.Text>
      </Box>
    </Box>
  )
}
