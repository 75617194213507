import React from 'react'

import _ from 'lodash'

import styled from '@emotion/styled'

import { CloseOutlined } from '@ant-design/icons'
import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import { Button, InputNumber, Table, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX } from 'common/components/boxes'
import { InputCurrency } from 'common/components/InputCurrency'
import { makeOption, SelectUnit } from 'common/components/SelectUnit'
import { currencyFormatter } from 'common/helpers/formatters'

import { useStores } from 'contractor/hooks/use-stores'
import { LineItemStatus } from 'contractor/pages/OrderDetail/QuoteReconciliation/OrderMaterialsTable/line_item_status'
import { AutoMatchOptions } from 'contractor/server/quote'

type LineItemsTableProps = {
  autoMatchOptions: AutoMatchOptions[]
  onChange: (value: AutoMatchOptions[]) => void
  removeUnquotedSuggestion: (value: string) => void
}

const TableStyled = styled(Table)`
  width: 100%;
  overflow: auto;

  tr.disabled-table-row:hover > td,
  .locked-table-row {
    background-color: ${({ theme }) => theme.colors['gray-3']};
    cursor: not-allowed;
    padding: 0;
    margin: 0;
    magin-left: 16px;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    position: relative;
    padding: 12px 8px;
    overflow-wrap: break-word;
  }
`

export const LineItemsTable = observer<LineItemsTableProps>((props: LineItemsTableProps) => {
  const { autoMatchOptions, onChange, removeUnquotedSuggestion } = props
  const { unitsStore, userStore } = useStores()

  if (!autoMatchOptions) return null
  const handleRemove = (id) => {
    const newData = _.filter(autoMatchOptions, (item) => item.order_material.id !== id)
    onChange(newData)
  }

  const handleChangeUnitCost = (id, value) => {
    const newData = autoMatchOptions.map((item) =>
      item.order_material.id === id
        ? {
            ...item,
            order_material: {
              ...item.order_material,
              company_material_id: item.order_material.company_material.id,
              unit_cost: value,
            },
          }
        : item,
    )
    onChange(newData)
  }

  const handleChangeQuantity = (id, value) => {
    const newData = autoMatchOptions.map((item) =>
      item.order_material.id === id
        ? {
            ...item,
            order_material: {
              ...item.order_material,
              company_material_id: item.order_material.company_material.id,
              quantity: value,
            },
          }
        : item,
    )
    onChange(newData)
  }

  const handleChangeUnit = (id, value) => {
    const isUnitId = value?.value != value?.label
    let newData = []

    if (isUnitId) {
      const newUnit = unitsStore?.units?.find((item) => item?.id === value?.value)
      newData = autoMatchOptions.map((item) =>
        item.order_material.id === id
          ? {
              ...item,
              order_material: {
                ...item.order_material,
                company_material_id: null,
                company_material: {
                  ...item.order_material.company_material,
                  id: null,
                  unit_id: value?.value,
                  unit: newUnit,
                  unit_name: null,
                },
              },
            }
          : item,
      )
    } else {
      newData = autoMatchOptions.map((item) =>
        item.order_material.id === id
          ? {
              ...item,
              order_material: {
                ...item.order_material,
                company_material_id: null,
                company_material: {
                  ...item.order_material.company_material,
                  id: null,
                  unit_name: value?.value,
                  unit: null,
                  unit_id: null,
                },
              },
            }
          : item,
      )
    }

    onChange(newData)
  }

  const columns = [
    {
      title: 'Description',
      key: 'description',
      minWidth: 250,
      render: (record) => {
        return (
          <FlexBoxX marginTop="0" height="100%" justifyContent="flex-start" padding="0">
            <LineItemStatus
              status={record.reconcile_status}
              orderQuantity={record.order_material.quantity}
              quoteQuantity={record.quantity}
              unquoted={record.unquoted}
            />
            <Typography.Text type={record.unquoted ? 'danger' : null}>
              {record.order_material?.company_material?.description}
            </Typography.Text>
          </FlexBoxX>
        )
      },
    },
    {
      title: 'Quantity',
      key: 'quantity',
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      render: (record) => {
        return (
          <InputNumber
            value={record.order_material.quantity || 0}
            onChange={(e) => {
              handleChangeQuantity(record.order_material.id, e)
            }}
          />
        )
      },
    },
    {
      title: 'Unit',
      key: 'unit',
      width: 125,
      minWidth: 125,
      render: (record) => {
        const unit = record.order_material?.company_material?.unit
        const unitName = unitsStore?.units?.find(
          (item) => item?.name?.toLowerCase() === record.order_material?.company_material?.unit_name?.toLowerCase(),
        )
        const option = makeOption(unit || unitName)
        return (
          <SelectUnit
            value={option}
            units={unitsStore.units}
            onChange={(_, option) => {
              handleChangeUnit(record.order_material.id, option)
            }}
          />
        )
      },
    },
    {
      title: 'Unit cost',
      key: 'unit_cost',
      width: 110,
      minWidth: 110,
      render: (record) => {
        if (record.unquoted) return 'Did not quote.'

        return (
          <InputCurrency
            value={record.order_material.unit_cost || 0.0}
            onChange={(e) => {
              handleChangeUnitCost(record.order_material.id, e)
            }}
          />
        )
      },
    },
    {
      title: 'Ext. cost',
      key: 'ext_cost',
      width: 120,
      minWidth: 120,
      maxWidth: 140,
      render: (record) => {
        if (record.unquoted) return null

        return (
          <Typography.Text type="secondary" style={{ width: '100%', alignItems: 'end' }}>
            {currencyFormatter(record.ext_cost || 0, 2)}
          </Typography.Text>
        )
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 75,
      minWidth: 75,
      render: (record) => {
        if (record.unquoted) {
          return (
            <Box width="100%" display="flex" alignItems="center">
              <Tooltip title="Remove from Order">
                <Button shape="circle" onClick={() => handleRemove(record.order_material.id)}>
                  <DeleteOutlined style={{ paddingTop: 4 }} />
                </Button>
              </Tooltip>
              <Tooltip title="Reject Suggestion">
                <Button
                  style={{ marginLeft: 8 }}
                  disabled={userStore.canCreateNewMaterial}
                  shape="circle"
                  onClick={() => removeUnquotedSuggestion(record.order_material.id)}
                >
                  <CloseOutlined style={{ paddingTop: 4 }} />
                </Button>
              </Tooltip>
            </Box>
          )
        }
      },
    },
  ]

  return <TableStyled rowKey="id" dataSource={autoMatchOptions} columns={columns} pagination={false} />
})
