import React from 'react'

import { OrdersList } from 'common/components/OrdersList'

interface Props {
  isDraft: boolean
  draftVendorNames: string[]
  vendorName: string
}

export function CellVendorName(props: Props) {
  const { isDraft, draftVendorNames, vendorName } = props

  if (isDraft) {
    return draftVendorNames.length > 0 ? (
      <div>
        {draftVendorNames.map((vendorName) => (
          <div key={vendorName} style={{ width: '100%' }}>
            {vendorName}
          </div>
        ))}
      </div>
    ) : (
      <OrdersList.Cell.NoContent />
    )
  }

  if (!vendorName || vendorName.length === 0) return <OrdersList.Cell.NoContent />

  return <div>{vendorName}</div>
}
