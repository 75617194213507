import axios from 'axios'

interface Company {
  id: string
  name: string
  domain: string
}

export function switch_company(company_id: string) {
  return axios.post(`/companies/${company_id}/switch`)
}

export function get_companies() {
  return axios.get<{ companies: Company[] }>('/companies')
}
