import { BindAll } from 'lodash-decorators'

import { observable } from 'mobx'

import { OrderStates } from 'common/server/server_types'

import { order_stats, order_stats_by_project } from 'contractor/server/dashboard'

@BindAll()
export default class DashboardStore {
  @observable orderCounts = {}
  @observable orderCountsByProject = {}

  async getOrderCounts(project_id?: string): Promise<void> {
    const stats = (await order_stats(project_id)).data['state_counts']
    this.orderCounts = {
      [OrderStates.QUOTED]: stats[OrderStates.QUOTED] || 0,
      [OrderStates.ORDERED]: stats[OrderStates.ORDERED] || 0,
      [OrderStates.SHIPPED]: stats[OrderStates.SHIPPED] || 0,
      [OrderStates.PARTIALLY_SHIPPED]: stats[OrderStates.PARTIALLY_SHIPPED] || 0,
      [OrderStates.DELIVERED]: stats[OrderStates.DELIVERED] || 0,
      [OrderStates.CANCELLED]: stats[OrderStates.CANCELLED] || 0,
    }
  }

  async getOrderCountsByProject(): Promise<void> {
    this.orderCountsByProject = (await order_stats_by_project()).data['project_state_counts']
  }
}
