import React from 'react'

import { Button, Popconfirm, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

import { Visibility } from 'common/components/Visibility'
import { OrderStates, OrderSubStates } from 'common/server/server_types'

import { WatchersDrawer } from 'contractor/components/WatchersDrawer'
import { makeCompanyUserWatcherOption } from 'contractor/components/WatchersSelect'
import { useStores } from 'contractor/hooks/use-stores'
import { UpdateAndSaveButton } from 'contractor/pages/OrderDetail/Actions/update_button'

import { OrderActionsProps } from './actions'
import { ExtraActions } from './extra_actions'

type OrderStateActionsProps = Pick<
  OrderActionsProps,
  | 'onFinish'
  | 'onCancelOrder'
  | 'actionClicked'
  | 'isPoLocked'
  | 'onRequestCancellation'
  | 'isSpreadsheetMode'
  | 'toggleSpreadsheetMode'
>

export const OrderStateActions = observer<OrderStateActionsProps>(
  ({
    onFinish,
    onCancelOrder,
    actionClicked,
    isPoLocked,
    onRequestCancellation,
    isSpreadsheetMode,
    toggleSpreadsheetMode,
  }) => {
    const { orderStore, userStore, companySettingStore } = useStores()
    const { state, sub_state, watcher_ids } = orderStore.selectedOrder

    return (
      <>
        <Visibility.Hidden breakpoint="lg">
          {userStore.canSendAndUpdateOrders && (
            <WatchersDrawer
              value={companySettingStore.possibleUsers
                .filter((user) => watcher_ids?.includes(user.company_user_id))
                .map((user) => makeCompanyUserWatcherOption(user))}
              onChange={(watchers = []) =>
                orderStore.updateSelectedOrder(
                  'watcher_ids',
                  watchers?.map((watcher) => watcher.value),
                )
              }
              isDisabled={isPoLocked}
              boxProps={{ height: 34 }}
              makeOption={makeCompanyUserWatcherOption}
            />
          )}
        </Visibility.Hidden>

        {userStore.canCancelOrder(orderStore.selectedOrder) && (
          <Tooltip placement="left" title="Notify the vendor you would like to cancel the order.">
            <Popconfirm
              placement="bottom"
              title="Are you sure to cancel this order?"
              onConfirm={onCancelOrder}
              okText="Yes"
              cancelText="No"
              disabled={isPoLocked || actionClicked > 0}
            >
              <Button
                data-cy="cancel-order"
                danger
                loading={actionClicked === 1}
                disabled={isPoLocked || actionClicked > 0}
              >
                Cancel
              </Button>
            </Popconfirm>
          </Tooltip>
        )}

        <UpdateAndSaveButton
          orderState={state}
          onUpdateWithNotification={() => onFinish({ action: 2 })}
          onUpdateWithoutNotification={() => onFinish({ action: 2, silentUpdate: true })}
          onReopen={() => onFinish({ stateChanges: { cancelled_at: null }, action: 1 })}
          loading={actionClicked === 2}
          showReopenButton={
            state === OrderStates.CANCELLED &&
            sub_state === OrderSubStates.CANCELLED_QUOTE_BY_BUYER &&
            userStore.canSendAndUpdateRfqs
          }
          reopenButtonDisabled={actionClicked > 0}
          disabled={
            isPoLocked || !orderStore.selectedOrderDirty || actionClicked > 0 || !userStore.canSendAndUpdateOrders
          }
        />

        <ExtraActions
          onRequestCancellation={onRequestCancellation}
          isSubmitting={actionClicked > 0}
          isSpreadsheetMode={isSpreadsheetMode}
          toggleSpreadsheetMode={toggleSpreadsheetMode}
        />
      </>
    )
  },
)
