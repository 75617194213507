import React, { useState } from 'react'

import styled from '@emotion/styled'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Badge, Switch, Table, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { UserRoles } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { OrderPermissions } from 'contractor/server/company_settings/permissions'

const TableStyled = styled(Table)`
  width: 100%;

  tr.disabled-table-row:hover > td,
  .locked-table-row {
    background-color: ${({ theme }) => theme.colors['gray-3']};
    cursor: not-allowed;
  }
`

type OrderTableProps = {
  setOrderPermissions: (data: OrderPermissions) => void
  ordersPermissions: OrderPermissions
}

const insertIf = (condition, ...elements) => (condition ? elements : [])

const OrdersTable = ({ setOrderPermissions, ordersPermissions }: OrderTableProps) => {
  const { userStore, companySettingStore } = useStores()

  const [data, setData] = useState([
    {
      id: 'o1', // ID used for antd table to set the row key
      permission: 'Can see orders from all groups',
      admin: ordersPermissions?.ADMIN?.can_see_orders_from_all_groups,
      purchaser: ordersPermissions?.PURCHASER?.can_see_orders_from_all_groups,
      user: ordersPermissions?.USER?.can_see_orders_from_all_groups,
      material_requester: ordersPermissions?.MATERIAL_REQUESTER?.can_see_orders_from_all_groups,
    },
    {
      id: 'o2',
      permission: 'Can input vendor data',
      admin: ordersPermissions?.ADMIN?.can_input_vendor_data,
      purchaser: ordersPermissions?.PURCHASER?.can_input_vendor_data,
      user: ordersPermissions?.USER?.can_input_vendor_data,
      material_requester: ordersPermissions?.MATERIAL_REQUESTER?.can_input_vendor_data,
      tooltip:
        'Certain data like quotes, order confirmations, etc. we expect vendors to input themselves. This permission will allow you to do it for them.',
    },
    {
      id: 'o3',
      permission: 'Can view historical pricing',
      admin: ordersPermissions?.ADMIN?.can_view_historical_pricing,
      purchaser: ordersPermissions?.PURCHASER?.can_view_historical_pricing,
      user: ordersPermissions?.USER?.can_view_historical_pricing,
      material_requester: ordersPermissions?.MATERIAL_REQUESTER?.can_view_historical_pricing,
    },
    {
      id: 'o4',
      permission: 'Can edit vendor database',
      admin: ordersPermissions?.ADMIN?.can_edit_vendor_database,
      purchaser: ordersPermissions?.PURCHASER?.can_edit_vendor_database,
      user: ordersPermissions?.USER?.can_edit_vendor_database,
      material_requester: ordersPermissions?.MATERIAL_REQUESTER?.can_edit_vendor_database,
    },
    {
      id: 'o5',
      permission: 'Can create new material',
      admin: ordersPermissions?.ADMIN?.can_create_new_material,
      purchaser: ordersPermissions?.PURCHASER?.can_create_new_material,
      user: ordersPermissions?.USER?.can_create_new_material,
      material_requester: ordersPermissions?.MATERIAL_REQUESTER?.can_create_new_material,
      tooltip:
        'This will prevent users from creating new materials in the order request process. They will only be able to select materials from your database. This will also prevent users from changing the units on any of the materials in your database.',
    },
    {
      id: 'o6',
      permission: 'Can edit material database',
      admin: ordersPermissions?.ADMIN?.can_edit_material_database,
      purchaser: ordersPermissions?.PURCHASER?.can_edit_material_database,
      user: ordersPermissions?.USER?.can_edit_material_database,
      material_requester: ordersPermissions?.MATERIAL_REQUESTER?.can_edit_material_database,
    },
    ...insertIf(userStore?.canUseIntegrations, {
      id: 'o7',
      permission: 'Can sync with ERP',
      admin: ordersPermissions?.ADMIN?.can_sync_with_erp,
      purchaser: ordersPermissions?.PURCHASER?.can_sync_with_erp,
      user: ordersPermissions?.USER?.can_sync_with_erp,
      material_requester: ordersPermissions?.MATERIAL_REQUESTER?.can_sync_with_erp,
    }),
    {
      id: 'o8',
      permission: 'Can send and update orders',
      admin: ordersPermissions?.ADMIN?.can_send_and_update_orders,
      purchaser: ordersPermissions?.PURCHASER?.can_send_and_update_orders,
      user: ordersPermissions?.USER?.can_send_and_update_orders,
      material_requester: ordersPermissions?.MATERIAL_REQUESTER?.can_send_and_update_orders,
    },
    {
      id: 'o9',
      permission: 'Can edit delivery information',
      admin: ordersPermissions?.ADMIN?.can_edit_delivery_information,
      purchaser: ordersPermissions?.PURCHASER?.can_edit_delivery_information,
      user: ordersPermissions?.USER?.can_edit_delivery_information,
      material_requester: ordersPermissions?.MATERIAL_REQUESTER?.can_edit_delivery_information,
    },
    ...insertIf(userStore?.canUseCreateRFQ, {
      id: 'o10',
      permission: 'Can send and update RFQs',
      admin: ordersPermissions?.ADMIN?.can_send_and_update_rfqs,
      purchaser: ordersPermissions?.PURCHASER?.can_send_and_update_rfqs,
      user: ordersPermissions?.USER?.can_send_and_update_rfqs,
      material_requester: ordersPermissions?.MATERIAL_REQUESTER?.can_send_and_update_rfqs,
    }),
    {
      id: 'o11',
      permission: 'Can approve order request',
      admin: ordersPermissions?.ADMIN?.can_approve_order_request,
      purchaser: ordersPermissions?.PURCHASER?.can_approve_order_request,
      user: ordersPermissions?.USER?.can_approve_order_request,
      material_requester: ordersPermissions?.MATERIAL_REQUESTER?.can_approve_order_request,
      disabled: !companySettingStore?.otherSettings?.approval_state_enabled,
      tooltip: !companySettingStore?.otherSettings?.approval_state_enabled
        ? 'This is only applicable if you have enabled an Approval step in your order workflow (see Other Settings)'
        : '',
    },
    {
      id: 'o12',
      permission: 'Can create drafts',
      admin: ordersPermissions?.ADMIN?.can_create_drafts,
      purchaser: ordersPermissions?.PURCHASER?.can_create_drafts,
      user: ordersPermissions?.USER?.can_create_drafts,
      material_requester: ordersPermissions?.MATERIAL_REQUESTER?.can_create_drafts,
    },
    {
      id: 'o13',
      permission: 'Can edit cost code',
      admin: ordersPermissions?.ADMIN?.can_edit_cost_code,
      purchaser: ordersPermissions?.PURCHASER?.can_edit_cost_code,
      user: ordersPermissions?.USER?.can_edit_cost_code,
      material_requester: ordersPermissions?.MATERIAL_REQUESTER?.can_edit_cost_code,
    },
    {
      id: 'o14',
      permission: 'Can unlock order',
      admin: ordersPermissions?.ADMIN?.can_unlock_order,
      purchaser: ordersPermissions?.PURCHASER?.can_unlock_order,
      user: ordersPermissions?.USER?.can_unlock_order,
      material_requester: ordersPermissions?.MATERIAL_REQUESTER?.can_unlock_order,
      tooltip:
        'Can unlock any locked order. This will make the person currently editing lose all unsaved changes and get locked from the order instead',
    },
  ])

  const handleSwitchChange = (permission: string, role: UserRoles) => {
    const newData = [...data]
    newData.map((item) => {
      if (item.permission === permission) {
        item[role.toLowerCase()] = !item[role.toLowerCase()]
      }
      return item
    })

    const updatedOrderPermissions = {
      ADMIN: {},
      PURCHASER: {},
      USER: {},
      MATERIAL_REQUESTER: {},
    } as OrderPermissions

    for (const key in updatedOrderPermissions) {
      if (Object.prototype.hasOwnProperty.call(updatedOrderPermissions, key)) {
        const rolePermissions = updatedOrderPermissions[key]
        for (const dataItem of newData) {
          const permission = dataItem.permission.toLowerCase().replace(/\s+/g, '_')
          rolePermissions[permission] = dataItem[key.toLowerCase()] || false
        }
      }
    }
    setData(newData)
    setOrderPermissions(updatedOrderPermissions)
  }

  const columns: ColumnsType<{
    disabled: boolean
    permission: string
    admin: boolean
    purchaser: boolean
    user: boolean
    material_requester: boolean
    tooltip?: string
  }> = [
    {
      title: 'Order Permissions',
      dataIndex: 'permission',
      width: 200,
      render: (permission, record) => {
        return (
          <>
            <Typography.Text>{permission}</Typography.Text>{' '}
            {record?.tooltip && (
              <Badge
                count={
                  <Tooltip placement="top" title={record?.tooltip}>
                    <QuestionCircleOutlined style={{ top: '-5px', right: '-5px', fontSize: 14 }} />
                  </Tooltip>
                }
              />
            )}
          </>
        )
      },
    },
    {
      title: 'Admin',
      dataIndex: 'admin',
      width: 150,
      render: (_, record) => (
        <Switch
          checked={record.admin}
          disabled={record.disabled}
          onChange={() => handleSwitchChange(record.permission, UserRoles.ADMIN)}
        />
      ),
    },
    {
      title: 'Purchaser',
      dataIndex: 'purchaser',
      width: 150,
      render: (_, record) => (
        <Switch
          checked={record.purchaser}
          disabled={record.disabled}
          onChange={() => handleSwitchChange(record.permission, UserRoles.PURCHASER)}
        />
      ),
    },
    {
      title: 'User',
      dataIndex: 'user',
      width: 150,
      render: (_, record) => (
        <Switch
          checked={record.user}
          disabled={record.disabled}
          onChange={() => handleSwitchChange(record.permission, UserRoles.USER)}
        />
      ),
    },
    {
      title: 'Material Requester',
      dataIndex: 'material_requester',
      width: 150,
      render: (_, record) => (
        <Switch
          checked={record.material_requester}
          disabled={record.disabled}
          onChange={() => handleSwitchChange(record.permission, UserRoles.MATERIAL_REQUESTER)}
        />
      ),
    },
  ]

  if (!ordersPermissions) {
    return null
  }

  return <TableStyled rowKey="id" dataSource={data} columns={columns} pagination={false} />
}

export default OrdersTable
