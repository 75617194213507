import React, { useState, useRef, useEffect } from 'react'

import { CirclePicker } from 'react-color'

import { Box } from 'common/components/boxes'
import { useClickOutside } from 'common/hooks/use-click-outside'
import { useTheme } from 'common/hooks/use-theme'

type ColorInputProps = {
  value?: string
  onChange?: (color: string) => void
  onChangeState?: (isOpen: boolean) => void
}

export const ColorInput = ({ value, onChange, onChangeState }: ColorInputProps) => {
  const theme = useTheme()

  const [isOpen, setOpen] = useState(false)

  const wrapperInputRef = useRef()

  useEffect(() => {
    onChangeState?.(isOpen)
  }, [isOpen])

  useClickOutside([wrapperInputRef], () => {
    if (isOpen) {
      setOpen(false)
    }
  })

  const colors = [
    theme.colors['gray-6'],
    theme.colors['red-6'],
    theme.colors['orange-6'],
    theme.colors['gold-6'],
    theme.colors['yellow-6'],
    theme.colors['lime-6'],
    theme.colors['green-6'],
    theme.colors['cyan-6'],
    theme.colors['blue-6'],
    theme.colors['geekblue-6'],
    theme.colors['purple-6'],
    theme.colors['magenta-6'],
  ]

  return (
    <Box position="relative">
      <Box
        onClick={() => setOpen((prev) => !prev)}
        p={4}
        border="1px solid"
        borderColor="gray-6"
        style={{ cursor: 'pointer' }}
      >
        <Box backgroundColor={theme.colors[`${value}-6`]} minWidth={22} height={22} />
      </Box>

      {isOpen && (
        <Box
          border="1px solid"
          borderColor="gray-6"
          backgroundColor="white"
          p={8}
          borderRadius={8}
          position="absolute"
          top={34}
          zIndex={1}
          ref={wrapperInputRef}
          style={{ cursor: 'default' }}
        >
          <CirclePicker
            colors={colors}
            onChange={(resp) => {
              const [colorName] = Object.entries(theme.colors).find(([_, color]) => color === resp.hex)
              onChange?.(colorName.replace(/[0-9]|[-]/g, ''))
            }}
          />
        </Box>
      )}
    </Box>
  )
}
