import React from 'react'

import { Pagination, Space } from 'antd'

import { Box } from 'common/components/boxes'

type CardTitleProps = {
  size: number
  onChangePage: (page: number, pageSize: number) => void
}

export const CardTitle = ({ size, onChangePage }: CardTitleProps) => {
  return (
    <Space>
      <Box>Invoice</Box>
      {size > 1 && (
        <Pagination defaultCurrent={1} total={size} defaultPageSize={1} onChange={onChangePage} size="small" />
      )}
    </Space>
  )
}
