/** @jsx jsx */

import * as React from 'react'

import { Column, Row } from 'react-table'

import { jsx } from '@emotion/core'

import { Input } from 'antd'

import { observer } from 'mobx-react-lite'

import { useTheme } from 'common/hooks/use-theme'

interface EditableCellProps {
  value: string
  row: Row
  column: Column & {
    onChange?: (index: number, id: string, value: string) => void
    onBlurChange?: (index: number, id: string, value: string) => void
    border?: boolean
  }
  rowDisabled: boolean
}

const EditableCell: React.FC<EditableCellProps> = observer(({ value, row, column, rowDisabled }) => {
  const theme = useTheme()
  const [newValue, setNewValue] = React.useState<string>(value)
  // Update our new value if the value prop gets updated
  React.useEffect(() => {
    setNewValue(value)
  }, [value])

  if (rowDisabled) {
    return <span css={{ marginLeft: '15px', marginTop: '10px' }}>{value}</span>
  }

  const onChangeValue = (e): void => {
    column.onChange && column.onChange(row.index, column.id, e.target.value)
    setNewValue(e.target.value)
  }
  const onBlur = () => {
    if (newValue !== value) {
      column.onBlurChange && column.onBlurChange(row.index, column.id, newValue)
    }
  }

  // Only show the placeholder if the row is null
  const placeholder = row.original['id'] === null ? column['placeholder'] : undefined

  const inputStyle = {
    width: '90%',
    marginLeft: '3px',
  }
  if (column.border !== true) {
    inputStyle['border'] = 'none'
    inputStyle['boxShadow'] = 'none'
    inputStyle['outline'] = 'none'
    inputStyle['&:hover'] = {
      outline: 'none',
      borderRadius: theme.radii.md,
      boxShadow: `0 0 0 2px ${theme.colors['gray-7']}`,
    }
  }

  return (
    <Input css={inputStyle} value={newValue || ''} placeholder={placeholder} onChange={onChangeValue} onBlur={onBlur} />
  )
})

export default EditableCell
