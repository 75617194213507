/** @jsx jsx */

import React from 'react'

import { jsx } from '@emotion/core'

import { Typography } from 'antd'

import { useTheme } from 'common/hooks/use-theme'

export const ItemTitle: React.FC<{ text: string }> = ({ text }) => {
  const theme = useTheme()
  return <Typography.Paragraph style={{ color: theme.colors['gray-7'], marginBottom: 0 }}>{text}</Typography.Paragraph>
}
