/** @jsx jsx */

import React from 'react'

import { jsx } from '@emotion/core'

import { Typography } from 'antd'

import { useTheme } from 'common/hooks/use-theme'

const ItemTitle: React.FC<{ text: string }> = ({ text }) => {
  const theme = useTheme()
  return <Typography.Text style={{ color: theme.colors['gray-7'] }}>{text}</Typography.Text>
}
export default ItemTitle
