import { CellAction } from 'contractor/pages/@v2/Orders/components/cells/cell_action'
import { CellInvoices } from 'contractor/pages/@v2/Orders/components/cells/cell_invoices'
import { CellOrderNumber } from 'contractor/pages/@v2/Orders/components/cells/cell_order_number'
import { CellVendorName } from 'contractor/pages/@v2/Orders/components/cells/cell_vendor_name'

import { CellExpirationQuote } from './cell_expiration_quote'
import { CellInvoiceAmountRemaining } from './cell_invoice_amount_remaining'
import { CellLeveling } from './cell_leveling'
import { CellTag } from './cell_tags'

export const Cell = {
  Actions: CellAction,
  ExpirationQuote: CellExpirationQuote,
  Invoices: CellInvoices,
  Leveling: CellLeveling,
  OrderNumber: CellOrderNumber,
  Tags: CellTag,
  VendorName: CellVendorName,
  InvoiceAmountRemaining: CellInvoiceAmountRemaining,
}
