import React from 'react'

import { SelectAccessType, AccessType } from './select_access_type'
import { SignInForm, SignInFormProps } from './sign_in_form'

type SignInPageProps = Omit<SignInFormProps, 'accessType'>

export const SignInPage = (props: SignInPageProps) => {
  const accessType = new URLSearchParams(location.search).get('access-type') as AccessType

  if (!accessType) {
    return <SelectAccessType />
  }

  return <SignInForm {...props} accessType={accessType} />
}
