import React from 'react'

import styled from '@emotion/styled'

import { Typography, Divider, Tag, Button, Badge, Tooltip, Card } from 'antd'

import { FlexBoxY, FlexBoxX } from 'common/components/boxes'
import { useTheme } from 'common/hooks/use-theme'

import { Logo } from 'contractor/components/VendorProfile/vendor_card'

const CardStyled = styled(Card)`
  width: 100%;
  height: 210px;

  & .ant-card-body {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

export const VendorCard = ({ logo, name, tags = [], description, contactsCount = 0, onClick }) => {
  const theme = useTheme()

  return (
    <CardStyled hoverable onClick={onClick}>
      <FlexBoxY p="16px" width="100%" alignItems="flex-start">
        <FlexBoxX justifyContent="space-between" width="100%" mb="12px" flexGrow={0}>
          <FlexBoxY alignItems="flex-start">
            <Typography.Title level={4} ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
              {name}
            </Typography.Title>
            <FlexBoxX>
              {tags.map((tag) => (
                <Tag key={tag} color="green">
                  {tag}
                </Tag>
              ))}
            </FlexBoxX>
          </FlexBoxY>

          <Logo logo={logo} />
        </FlexBoxX>

        <Tooltip title={description}>
          <Typography.Paragraph type="secondary" ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
            {description}
          </Typography.Paragraph>
        </Tooltip>
      </FlexBoxY>

      <Divider style={{ margin: 0 }} />

      <FlexBoxX px={16} py={10} justifyContent="space-between" width="100%" flexGrow={0}>
        <FlexBoxX flexGrow={0}>
          <Badge count={contactsCount} showZero style={{ backgroundColor: theme.colors.primary, marginRight: 8 }} />
          <Typography.Text>Contacts to connect</Typography.Text>
        </FlexBoxX>

        <Button>View Contacts</Button>
      </FlexBoxX>
    </CardStyled>
  )
}
