import React, { useState } from 'react'

import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { Button, Tooltip, Typography } from 'antd'

import { Box } from '../boxes'

interface OrderBlockedHeaderProps {
  externalUser?: { name: string }
  isBlocked?: boolean
  isRFQ?: boolean
  onOpenModalOrderUnlocked?: () => void
  unlockEnabled?: boolean
}

export const OrderBlockedHeader = ({
  externalUser,
  isBlocked,
  isRFQ,
  onOpenModalOrderUnlocked,
  unlockEnabled,
}: OrderBlockedHeaderProps) => {
  if (!externalUser) return null

  const label = isRFQ ? 'RFQ' : 'Order'

  const lockContent = () => {
    if (!isBlocked) return null

    if (unlockEnabled) {
      return <OrderBlockedHeaderUnlockButton onOpenModalOrderUnlocked={onOpenModalOrderUnlocked} />
    }

    return <LockOutlined />
  }

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop="0.5rem" style={{ gap: '0.5rem' }}>
      {lockContent()}

      <Typography.Paragraph style={{ textAlign: 'right', marginBottom: 0 }}>
        This {label} is being edited by <Typography.Text strong>{externalUser.name}</Typography.Text>.
      </Typography.Paragraph>
    </Box>
  )
}

const OrderBlockedHeaderUnlockButton = (props: OrderBlockedHeaderProps) => {
  const [hover, setHover] = useState(false)

  return (
    <Tooltip title="Unlock order">
      <Box onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <Button
          icon={hover ? <UnlockOutlined /> : <LockOutlined />}
          type="ghost"
          shape="circle"
          onClick={props.onOpenModalOrderUnlocked}
          size="small"
        />
      </Box>
    </Tooltip>
  )
}
