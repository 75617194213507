import { initializeAmplitude, AmplitudeUser } from 'common/analytics/amplitude/initialize_amplitude'
import { HeapUser, initializeHeap } from 'common/analytics/heap/initialize_heap'
import { initializePendo, PendoUser } from 'common/analytics/pendo/initialize_pendo'

export default abstract class UserBaseStore {
  maybeInitializePendo(user: PendoUser, featureFlagEnabled: boolean) {
    initializePendo(user, featureFlagEnabled)
  }

  maybeInitializeAmplitude(user: AmplitudeUser, featureFlagEnabled: boolean) {
    initializeAmplitude(user, featureFlagEnabled)
  }

  maybeInitializeHeap(user: HeapUser, featureFlagEnabled: boolean) {
    initializeHeap(user, featureFlagEnabled)
  }
}
