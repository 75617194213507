import React from 'react'

import pluralize from 'pluralize'

import { Typography, Card } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'

type InvoiceTotalValuesMobileProps = {
  grandTotal?: string
  totalInvoicedAmount?: number
  remaining?: number
  invoicesCount?: number
}

export const InvoiceTotalValuesMobile = ({
  grandTotal,
  totalInvoicedAmount,
  remaining,
  invoicesCount,
}: InvoiceTotalValuesMobileProps) => {
  const theme = useTheme()

  const getRemainingPriceColor = () => {
    if (remaining === 0) {
      return theme.colors['gray-7']
    }

    if (remaining > 0) {
      return theme.colors.link
    }

    return theme.colors.error
  }

  return (
    <Card>
      <Box display="flex" flexDirection="column" style={{ gap: 12 }}>
        <FlexBoxX justifyContent="flex-end" style={{ gap: 4 }}>
          <Typography.Text style={{ color: theme.colors['gray-8'] }}>PO total</Typography.Text>

          <Typography.Text style={{ color: theme.colors['gray-12'] }} strong>
            {currencyFormatter(grandTotal, 2)}
          </Typography.Text>
        </FlexBoxX>

        <FlexBoxX justifyContent="flex-end" style={{ gap: 4 }}>
          <Typography.Text style={{ color: theme.colors['gray-8'] }}>
            Total Invoiced ({pluralize('Invoice', invoicesCount, true)})
          </Typography.Text>

          <Typography.Text style={{ color: theme.colors['gray-12'] }} strong>
            {currencyFormatter(totalInvoicedAmount, 2)}
          </Typography.Text>
        </FlexBoxX>

        <FlexBoxX justifyContent="flex-end" style={{ gap: 4 }}>
          <Typography.Text style={{ color: theme.colors['gray-8'] }}>Remaining</Typography.Text>

          <Typography.Text style={{ color: getRemainingPriceColor() }} strong>
            {currencyFormatter(remaining, 2)}
          </Typography.Text>
        </FlexBoxX>
      </Box>
    </Card>
  )
}
