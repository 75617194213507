/** @jsx jsx */

import * as React from 'react'

import { jsx } from '@emotion/core'

import { Modal } from 'antd'

import { observer } from 'mobx-react-lite'

import { Select } from 'common/components/Select'

import { indexUsers } from 'contractor/server/admin/masquerade'

interface MasqueradeProps {
  masqueradeModalOpen: boolean
  setMasqueradeModal: (b: boolean) => void
}

const Masquerade: React.FC<MasqueradeProps> = observer(({ masqueradeModalOpen, setMasqueradeModal }) => {
  const [users, setUsers] = React.useState([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedUser, setSelectedUser] = React.useState<any>()

  React.useEffect(() => {
    const getUsers = async () => {
      setUsers((await indexUsers()).data['users'])
    }
    if (masqueradeModalOpen && users.length == 0) {
      getUsers()
    }
  }, [masqueradeModalOpen])

  const mapOption = (u) => ({
    value: u.id,
    label: `${u.first_name} ${u.last_name} - ${u.company_name}`,
    masquerade_path: u.masquerade_path,
  })

  const loginAsUser = () => {
    if (selectedUser) {
      window.location.href = selectedUser['masquerade_path']
    }
  }

  return (
    <Modal
      title="Login As User"
      open={masqueradeModalOpen}
      onOk={loginAsUser}
      okButtonProps={{ disabled: !selectedUser }}
      onCancel={() => setMasqueradeModal(false)}
    >
      Choose a user to login as
      <Select
        isLoading={users.length == 0}
        options={users.map(mapOption)}
        value={selectedUser}
        onChange={(option) => setSelectedUser(option)}
      />
    </Modal>
  )
})

export default Masquerade
