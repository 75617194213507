import React, { useEffect } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { message, Modal, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { useIntercom } from 'common/hooks/use-intercom'
import theme from 'common/styles/theme'

import { useStores } from 'contractor/hooks/use-stores'

export const Help = observer(() => {
  const { userStore, userSettingStore } = useStores()

  const intercom = useIntercom()

  const handleToggleHelp = async () => {
    try {
      await userSettingStore.updateSettings({ help_mode: !userStore.currentUser?.help_mode })
      userStore.showUser()
    } catch (e) {
      const errorMessage = 'Error updating user settings'
      message.error(e?.response?.data?.message || errorMessage)
    }
  }

  useEffect(() => {
    if (userStore.currentUser?.intercom_settings && userStore.currentUser?.help_mode) {
      intercom.boot(userStore.currentUser?.intercom_settings)
    }
  }, [userStore.currentUser?.intercom_settings, userStore.currentUser?.help_mode])

  useEffect(() => {
    let modal = null
    if (userStore.currentUser?.help_mode) {
      modal = Modal.info({
        title: 'Help mode is enabled',
        icon: <ExclamationCircleOutlined />,
        onOk: () => intercom.show(),
        content: (
          <Box>
            <Typography.Paragraph style={{ marginTop: 30 }}>
              You enabled the SubBase help mode. You can now ask questions to our team through the chat bubble{' '}
              <img src="/intercom_bubble.png" alt="Intercom" width={24} height={24} /> on the bottom right corner.
            </Typography.Paragraph>
            <Typography.Paragraph style={{ marginTop: 30 }} italic>
              You can disable the help mode at any time by clicking again on the help option on the menu.
            </Typography.Paragraph>
          </Box>
        ),
      })
    } else {
      intercom.shutdown()
    }

    return () => {
      modal?.destroy()
    }
  }, [userStore.currentUser?.help_mode, intercom.shutdown])

  return (
    <div onClick={handleToggleHelp} style={userStore.currentUser?.help_mode ? { color: theme.colors['blue-8'] } : {}}>
      Help
    </div>
  )
})
