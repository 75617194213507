/** @jsx jsx */

import * as React from 'react'

import { jsx } from '@emotion/core'

import { Tag } from 'antd'

const TagCell: React.FC<{ value: string | string[] }> = ({ value }) => {
  if (!value) {
    return null
  }

  if (Array.isArray(value)) {
    return (
      <span>
        {value?.map((v, i) => (
          <Tag key={i}>{v}</Tag>
        ))}
      </span>
    )
  }

  return <Tag>{value}</Tag>
}

export default TagCell
