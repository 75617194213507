import React from 'react'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

import { PriceSheetListRow } from './price_sheet_row'

export const PriceSheetList = observer(() => {
  const {
    priceSheetStore: { listStore },
  } = useStores()

  const persistentFilters = usePersistentFilters()

  const { isLoading } = useQuery(persistentFilters.init)

  if (isLoading) return <Loading />

  const priceSheets = toJS(listStore.records)

  return (
    <Box width="100%" display="flex" flexDirection="column" gridGap={16}>
      {priceSheets?.map((priceSheet) => (
        <PriceSheetListRow key={priceSheet.id} priceSheet={priceSheet} />
      ))}
    </Box>
  )
})
