import React from 'react'

import { useHistory } from 'react-router-dom'

import { Typography, Button, Space, Switch } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY, FlexBoxX, Box } from 'common/components/boxes'
import { SearchInput } from 'common/components/SearchInput'
import { Visibility } from 'common/components/Visibility'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import { useLocalStorage } from 'common/hooks/use-local-storage'

import { Flatfile } from 'contractor/components/Flatfile'
import { useStores } from 'contractor/hooks/use-stores'

type HeaderProps = {
  onCreate: () => void
}

export const Header = observer<HeaderProps>(({ onCreate }) => {
  const { companyVendorStore, userStore, flatfileStore } = useStores()
  const persistentFilters = usePersistentFilters()

  const [, setLocalStorage] = useLocalStorage<boolean>('@subbase/my-vendors/only-actives', true)

  const history = useHistory()

  const { companyVendorListStore } = companyVendorStore

  return (
    <FlexBoxY width="100%" alignItems="flex-start" justifyContent="flex-start" flexGrow={0}>
      <FlexBoxX justifyContent="space-between" width="100%" mb={{ _: 12, xs: 24 }} alignItems="flex-start">
        <Typography.Title level={3}>Vendors</Typography.Title>

        {userStore.canManageVendors && (
          <Space size="middle">
            <Flatfile
              onSuccess={() => companyVendorListStore.fetchRecords()}
              buttonProps={{ type: 'default' }}
              openUpload={flatfileStore.openUploadCompanyVendors}
            />

            <Button onClick={onCreate}>Add</Button>

            <Visibility.Hidden>
              <Button type="primary" onClick={() => history.push('/company_vendors?tab=subbase-network')}>
                Discover Vendors
              </Button>
            </Visibility.Hidden>
          </Space>
        )}
      </FlexBoxX>

      <FlexBoxX width="100%" flexDirection={{ _: 'column', xs: 'row' }}>
        <Box mr={{ _: 0, xs: 16 }} mb={{ _: 12, xs: 0 }} width="100%">
          <SearchInput
            placeholder="Search your contact list"
            value={companyVendorListStore.searchState['search']}
            onSearch={(search: string) => persistentFilters.handleChangeFilters({ search })}
            style={{ width: '100%' }}
          />
        </Box>

        <FlexBoxX
          width={{ _: '100%', xs: 'auto' }}
          border="1px solid"
          borderColor="gray-2"
          borderRadius="md"
          px={12}
          py="6px"
          flexGrow={0}
        >
          <Typography.Text style={{ whiteSpace: 'nowrap', marginRight: 12 }} strong>
            Show actives
          </Typography.Text>
          <Switch
            checked={companyVendorListStore.searchState.filters['active']}
            onChange={(active) => {
              setLocalStorage(active)
              persistentFilters.handleChangeFilters({ active })
            }}
          />
        </FlexBoxX>
      </FlexBoxX>
    </FlexBoxY>
  )
})
