import axios from 'axios'

import { HomePageOptions } from 'common/server/server_types'

export interface OtherSettings {
  default_home_page?: HomePageOptions
  help_mode?: boolean
}

export function index() {
  return axios.get<OtherSettings>('/user_settings')
}

export function update(payload: OtherSettings) {
  return axios.patch<OtherSettings>('/user_settings', payload)
}
