import React from 'react'

import styled from '@emotion/styled'

import { PlusOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'

import { getFastActionMenuItem } from './fast_actions_menu'

const AddButton = styled(PlusOutlined)`
  cursor: pointer;
  font-size: 20px;
`

type FastActionProps = {
  onOpen: () => void
}

export const FastAction = ({ onOpen }: FastActionProps) => {
  return (
    <Dropdown
      trigger={['click', 'hover']}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      menu={{
        items: getFastActionMenuItem({ onOpen }).children,
      }}
    >
      <AddButton />
    </Dropdown>
  )
}
