import axios from 'axios'

import {
  DeliveryFileNameConvention,
  File,
  LockOrderOptionType,
  MaterialRequestTypes,
  PONumberGenerationMethods,
} from 'common/server/server_types'

export interface PONumberSettings {
  generation_method: PONumberGenerationMethods
  initial_sequential_number?: number
  lead_zeros: number
  prefix: string
}

export interface CostCodeSettings {
  required: boolean
  project_filtering_enabled: boolean
  class_enabled: boolean
  phase_code_enabled: boolean
  independent_phase_codes_enabled: boolean
  project_specific_phase_codes_enabled: boolean
}

export interface RequiredOrderFields {
  unit: boolean
  quantity: boolean
  unit_cost: boolean
  cost_code: boolean
  cost_code_phase: boolean
  tax_value: boolean
}

export interface RequiredQuoteFields {
  unit: boolean
  quantity: boolean
  cost_code: boolean
  cost_code_phase: boolean
}

export interface RequiredRequestFields {
  unit: boolean
  quantity: boolean
  cost_code: boolean
  cost_code_phase: boolean
}

export interface EmailSettings {
  include_pdf_attachments: boolean
}

export type LockOrderOptions =
  | LockOrderOptionType.NEVER
  | LockOrderOptionType.DELIVERY_COMPLETION
  | LockOrderOptionType.ORDER_CONFIRMATION
  | LockOrderOptionType.ORDER_INVOICED

export interface LockOrderSettings {
  lock_order_rule: LockOrderOptions
}

export interface RoundingPrecisionSettings {
  order_precision: number
}

export interface OrderFieldsSettings {
  address_to: {
    enabled: boolean
    required: boolean
  }
}

interface TermsAndCondition {
  id: string
  name: string
  file: File
  discarded_at?: string
}

export interface OtherSettings {
  approval_state_enabled?: boolean
  order_session_vendor_enabled?: boolean
  public_order_form_enabled?: boolean
  public_order_form_url_extension?: string
  invoicing_email?: string
  logo_signed_id?: string
  logo_signed_delete_id?: string
  logo?: File
  cost_code_settings: CostCodeSettings
  email_settings: EmailSettings
  order_fields_settings: OrderFieldsSettings
  required_order_fields: RequiredOrderFields
  required_quote_fields: RequiredQuoteFields
  required_request_fields: RequiredRequestFields
  lock_order_settings: LockOrderSettings
  rounding_precision_settings: RoundingPrecisionSettings
  material_requisitions_default_order_type: MaterialRequestTypes
  delivery_file_name_convention: DeliveryFileNameConvention
  expired_at_enabled_when_order_session_is_dirty: boolean
  po_number: PONumberSettings
  terms_and_conditions: TermsAndCondition[]
  catalog_sources: string[]
  tax_line_items: { enabled: boolean }
  vendor_price_lock: { enabled: boolean }
}

export function index() {
  return axios.get<OtherSettings>('/company_settings/other_settings')
}

export function update(payload: OtherSettings) {
  return axios.patch<OtherSettings>('/company_settings/other_settings', payload)
}
