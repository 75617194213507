import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import axios from 'axios'
import { uniqBy } from 'lodash'

import { observer } from 'mobx-react-lite'

import { Autocomplete as CommonAutocomplete, AutocompleteRef } from 'common/components/Autocomplete'
import { OrderStates } from 'common/server/server_types'
import { Unit } from 'common/server/units'

import { useStores } from 'contractor/hooks/use-stores'
import { ConsolidatedCompanyMaterials, ShowCompanyMaterialResponse } from 'contractor/server/company_materials'
import { ConsolidatedManufacturerMaterials } from 'contractor/server/manufacturer_materials'

import { Footer } from './Footer'
import { MaterialItem } from './material_item'

export type CommonMaterial =
  | ConsolidatedCompanyMaterials.CompanyMaterial
  | ConsolidatedManufacturerMaterials.ManufacturerMaterials

export interface MaterialsAutocompleteProps {
  onSelect: (material: CommonMaterial) => void
  disabled?: boolean
  publicOrderFormUrlExtension?: string
  companyAttributes?: string[]
  units?: Unit[]
  projectId?: string
  canCreateNewMaterial?: boolean
  userId?: string
  orderState?: OrderStates
  placeholder?: string
  commitmentId?: string
}

const mapCreateCompanyMaterialToConsolidatedCompanyMaterial = (
  companyMaterialResponse: ShowCompanyMaterialResponse,
): ConsolidatedCompanyMaterials.CompanyMaterial => ({
  id: companyMaterialResponse?.id,
  company_id: companyMaterialResponse?.company_id,
  company_material_id: companyMaterialResponse?.id,
  description: companyMaterialResponse?.description,
  product_identifier: companyMaterialResponse?.product_identifier,
  manufacturer: companyMaterialResponse?.manufacturer,
  unit_id: companyMaterialResponse?.unit?.id,
  unit_name: companyMaterialResponse?.unit_name,
  unit: companyMaterialResponse?.unit,
  group: companyMaterialResponse?.group,
  sub_group: companyMaterialResponse?.sub_group,
  size: companyMaterialResponse?.size,
  connection_type: companyMaterialResponse?.connection_type,
  material: companyMaterialResponse?.material,
  cost_code: {
    id: companyMaterialResponse?.cost_code?.id,
    code: companyMaterialResponse?.cost_code?.code,
    phase_code: companyMaterialResponse?.cost_code?.phase_code,
    clazz: companyMaterialResponse?.cost_code?.clazz,
    clazz_description: companyMaterialResponse?.cost_code?.clazz_description,
    description: companyMaterialResponse?.cost_code?.description,
    phase_code_description: companyMaterialResponse?.cost_code?.phase_code_description,
    project_ids: companyMaterialResponse?.cost_code?.project_ids,
  },
  cost_code_code: companyMaterialResponse?.cost_code?.code,
  cost_code_phase_code: companyMaterialResponse?.cost_code?.phase_code,
  cost_code_clazz: companyMaterialResponse?.cost_code?.clazz,
  preferred_vendor_prices: companyMaterialResponse?.company_material_vendor_prices?.map((vendorPrice) => ({
    id: vendorPrice.id,
    price: vendorPrice.price,
    company_vendor: {
      id: vendorPrice.company_vendor.id,
      safe_globalized_vendor_name: vendorPrice.company_vendor.safe_globalized_vendor_name,
      external_vendor_id: vendorPrice.company_vendor.external_vendor_id,
    },
  })),
  cached_average_price: companyMaterialResponse?.cached_average_price,
  cached_lowest_price: companyMaterialResponse?.cached_lowest_price,
  cached_last_price: companyMaterialResponse?.cached_last_price,
  active: companyMaterialResponse?.active,
  tags: companyMaterialResponse?.tags,
  manufacturer_material_id: companyMaterialResponse?.manufacturer_material_id,
  image_url: companyMaterialResponse?.image_url,
})

function Autocomplete(props: MaterialsAutocompleteProps) {
  const {
    onSelect,
    disabled,
    publicOrderFormUrlExtension,
    companyAttributes,
    units,
    projectId,
    canCreateNewMaterial,
    userId,
    orderState,
    placeholder = 'Search Material...',
    commitmentId,
  } = props

  const {
    companyMaterialStore: { listStore: companyMateriaListStore },
    manufacturerMaterialStore: { listStore: manufacturerMateriallistStore },
    companySettingStore,
    publicOrderStore,
  } = useStores()

  const ref = useRef<AutocompleteRef>()

  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    if (companySettingStore.companyMaterialConfiguration?.company_attributes.includes('project_ids')) {
      companyMateriaListStore.setFilter('projects_with_empty', projectId ? [projectId] : [], false, true)
    }
    companyMateriaListStore.setFilter('active', true, true, true)
  }, [companySettingStore.companyMaterialConfiguration?.company_attributes, projectId])

  useEffect(() => {
    if (publicOrderStore.formParams?.public_token) {
      axios.defaults.headers.common['Public-Token'] = publicOrderStore.formParams?.public_token
    }
  }, [publicOrderStore.formParams?.public_token])

  // If the commitment is set we set in the filter and load the materials before the user search in the autocomplete
  useEffect(() => {
    if (commitmentId) {
      companyMateriaListStore.clearRecords()
      manufacturerMateriallistStore.clearRecords()
      companyMateriaListStore.setFilter('commitment_id', commitmentId, true, true)
      setSearchTerm('')
      companyMateriaListStore.setSearch('')
    }
  }, [commitmentId])

  const renderItem = useCallback((item) => {
    return {
      value: item.id,
      label: <MaterialItem item={item} />,
    }
  }, [])

  const mergedMaterials = useMemo(() => {
    return uniqBy([...companyMateriaListStore.records, ...manufacturerMateriallistStore.records], (material) => {
      if (material['manufacturer_material_id']) {
        return material['manufacturer_material_id']
      } else {
        return material['id']
      }
    })
  }, [companyMateriaListStore.records.length, manufacturerMateriallistStore.records.length])

  const handleSelect = useCallback(
    (value: string) => {
      if (!value) return

      const item = mergedMaterials.find((item) => item.id === value)

      onSelect(item)
    },
    [mergedMaterials, onSelect],
  )

  const handleSearch = useCallback(
    (value: string) => {
      setSearchTerm(value)
      if (value.length) {
        companyMateriaListStore.clearRecords()
        manufacturerMateriallistStore.clearRecords()
        companyMateriaListStore.setSearch(value)
        // Do not search the manufacturer materials if the company doesn't have the feature enabled OR the commitment is set
        const hasCatalogEnabled =
          companySettingStore.otherSettings?.catalog_sources?.length ||
          publicOrderStore.formParams?.catalog_sources?.length
        if (hasCatalogEnabled && !commitmentId) {
          manufacturerMateriallistStore.setSearch(value)
        }
      }
    },
    [companySettingStore.otherSettings?.catalog_sources, publicOrderStore.formParams?.catalog_sources, commitmentId],
  )

  const footer = useMemo(
    () => (
      <Footer
        materialDescription={searchTerm}
        isCommitment={!!commitmentId}
        onSelect={(companyMaterialResponse) => {
          const consolidatedCompanyMaterial =
            mapCreateCompanyMaterialToConsolidatedCompanyMaterial(companyMaterialResponse)
          onSelect(consolidatedCompanyMaterial)
        }}
        publicOrderFormUrlExtension={publicOrderFormUrlExtension}
        companyAttributes={companyAttributes}
        units={units}
        canCreateNewMaterial={canCreateNewMaterial}
        userId={userId}
        setIsOpen={ref.current?.toggleOpen}
        orderState={orderState}
      />
    ),
    [
      onSelect,
      searchTerm,
      publicOrderFormUrlExtension,
      companyAttributes,
      units,
      canCreateNewMaterial,
      userId,
      orderState,
    ],
  )

  const options = useMemo(() => {
    return mergedMaterials.map(renderItem)
  }, [mergedMaterials, renderItem])

  const isFetching = companyMateriaListStore.isFetching || manufacturerMateriallistStore.isFetching

  return (
    <CommonAutocomplete
      ref={ref}
      footer={footer}
      onSearch={handleSearch}
      onSelect={handleSelect}
      isFetching={isFetching}
      disabled={disabled}
      options={options}
      data-cy="materials-autocomplete"
      placeholder={placeholder}
      openOnClick={!!commitmentId}
    />
  )
}

export const MaterialsAutocomplete = observer(Autocomplete)
