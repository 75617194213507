import React from 'react'

import styled from '@emotion/styled'

import { ShopOutlined } from '@ant-design/icons'
import { Typography } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'
import { ContractorIcon } from 'common/components/ContractorIcon'

import { Logo } from '../logo'

const Item = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.sizes[24]} ${({ theme }) => theme.sizes[16]};
  border-radius: ${({ theme }) => theme.radii.md};
  border: 1px solid rgba(0, 0, 0, 0.25);
  color: ${({ theme }) => theme.colors['gray-8']};
  cursor: pointer;
  transition: all 0.3s;

  .anticon {
    font-size: 24px;
  }

  &:hover {
    background-color: rgba(91, 194, 231, 0.15);
    border-color: ${({ theme }) => theme.colors.highlightBlue};
    color: ${({ theme }) => theme.colors.highlightBlue};

    .ant-typography {
      color: ${({ theme }) => theme.colors.highlightBlue};
    }
  }
`

export type AccessType = 'VENDOR' | 'CONTRACTOR'

export const SelectAccessType = () => {
  const handleTrackEvent = (accessType: AccessType) => {
    trackEvent(Events.SELECT_ACCESS_TYPE, location.pathname, {
      access_type: accessType,
    })
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      justifyContent={{ _: 'space-between', xs: 'center' }}
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start" width="inherit">
        <Logo />

        <Typography.Title level={4}>Welcome.</Typography.Title>
        <Typography.Text type="secondary">Please select your access type</Typography.Text>

        <a href="/?access-type=CONTRACTOR" onClick={() => handleTrackEvent('CONTRACTOR')} style={{ width: '100%' }}>
          <Item mb={12} mt={20}>
            <ContractorIcon />
            <Box ml={16}>
              <Typography.Text type="secondary">I AM A</Typography.Text>
              <Typography.Title level={5} style={{ margin: 0 }}>
                Contractor
              </Typography.Title>
            </Box>
          </Item>
        </a>

        <a href="/vendor?access-type=VENDOR" onClick={() => handleTrackEvent('VENDOR')} style={{ width: '100%' }}>
          <Item>
            <ShopOutlined />
            <Box ml={16}>
              <Typography.Text type="secondary">I AM A</Typography.Text>
              <Typography.Title level={5} style={{ margin: 0 }}>
                Vendor
              </Typography.Title>
            </Box>
          </Item>
        </a>
      </Box>

      <Box display="flex" justifyContent="center" width="100%" mt={24}>
        <Typography.Text>
          Questions?{' '}
          <Typography.Link
            href="https://subbase.io/contact/"
            target="_blank"
            onClick={() => {
              trackEvent(Events.CLICK_CONTACT_US, location.pathname)
            }}
          >
            Contact Us
          </Typography.Link>
        </Typography.Text>
      </Box>
    </Box>
  )
}
