import React from 'react'

import { CostCodeViewerCell } from 'common/components/OrderMaterialsSpreadsheet'

import { useStores } from 'contractor/hooks/use-stores'

export const CustomCostCodeViewerCell = (props) => {
  const { companySettingStore } = useStores()
  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  return <CostCodeViewerCell {...props} costCodeSettings={costCodeSettings} />
}
