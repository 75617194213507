import React, { useState, useEffect, useCallback } from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Typography, Switch, Table, Badge, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'

interface EmailSettingsItem {
  label: string
  description?: string
  active: boolean
  key: string
}

type EmailSettingsProps = {
  onChange: (configs: {}) => void
}

export const EmailSettings = observer<EmailSettingsProps>(({ onChange }) => {
  const { companySettingStore } = useStores()

  const emailSettingsValue = companySettingStore.otherSettings?.email_settings

  const makeData = useCallback(() => {
    return [
      {
        label: 'Include PDF in Email',
        active: emailSettingsValue?.include_pdf_attachments,
        key: 'include_pdf_attachments',
        description:
          'Vendors are automatically provided a link where they can reply to your order without logging in. Enabling this setting will also include a PDF copy of the RFQ or PO.',
      },
    ]
  }, [emailSettingsValue])

  const [emailSettings, setEmailSettings] = useState<EmailSettingsItem[]>(makeData())

  useEffect(() => {
    const data = makeData()
    setEmailSettings(data)
  }, [makeData])

  const handleToggle = (item: EmailSettingsItem) => {
    const newState = emailSettings.map((state) => {
      if (state.key === item.key) {
        return { ...state, active: !item.active }
      }
      return state
    })

    const emailSettingMap = {}
    newState.forEach((setting) => {
      emailSettingMap[setting.key] = setting.active
    })

    setEmailSettings(newState)
    onChange(emailSettingMap)
  }

  const columns: ColumnsType<EmailSettingsItem> = [
    {
      title: 'Feature',
      dataIndex: 'label',
      width: 100,
      render: (label, record) => (
        <>
          <Typography.Text>{label}</Typography.Text>
          {record?.description && (
            <Badge
              count={
                <Tooltip placement="top" title={record?.description}>
                  <QuestionCircleOutlined style={{ top: '-5px', right: '-5px', fontSize: 12 }} />
                </Tooltip>
              }
            />
          )}
        </>
      ),
    },
    {
      title: 'Enabled',
      dataIndex: 'active',
      width: 125,
      render: (active, record) => <Switch checked={active} onChange={() => handleToggle(record)} />,
    },
  ]

  return (
    <Box mt={32}>
      <Typography.Title level={5}>Email Configuration</Typography.Title>
      <Table dataSource={emailSettings} columns={columns} pagination={false} />
    </Box>
  )
})
