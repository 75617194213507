import React from 'react'

import pluralize from 'pluralize'

import { Typography, Space } from 'antd'

import { Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'

type InvoiceTotalValuesProps = {
  grandTotal?: string
  totalInvoicedAmount?: number
  remaining?: number
  invoicesCount?: number
}

export const InvoiceTotalValues = ({
  grandTotal,
  totalInvoicedAmount,
  remaining,
  invoicesCount,
}: InvoiceTotalValuesProps) => {
  const theme = useTheme()

  const getRemainingPriceColor = () => {
    if (remaining === 0) {
      return theme.colors['gray-7']
    }

    if (remaining > 0) {
      return theme.colors.link
    }

    return theme.colors.error
  }

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      position="sticky"
      bottom={0}
      left={0}
      backgroundColor={theme.colors['white']}
      pr={16}
      minHeight={36}
      boxShadow="0px 2px 8px 0px #00000026"
    >
      <Space>
        <Box display="flex" style={{ gap: 8 }} alignItems="center">
          <Typography.Text type="secondary">PO Total</Typography.Text>
          <Typography.Text strong style={{ margin: 0 }}>
            {currencyFormatter(grandTotal, 2)}
          </Typography.Text>
        </Box>

        <Typography.Text type="secondary">{' / '}</Typography.Text>

        <Box display="flex" style={{ gap: 8 }} alignItems="center">
          <Typography.Text type="secondary">
            Total Invoiced ({pluralize('Invoice', invoicesCount, true)})
          </Typography.Text>
          <Typography.Text strong style={{ margin: 0 }}>
            {currencyFormatter(totalInvoicedAmount, 2)}
          </Typography.Text>
        </Box>

        <Typography.Text type="secondary">{' / '}</Typography.Text>

        <Box display="flex" style={{ gap: 8 }} alignItems="center">
          <Typography.Text type="secondary">Remaining</Typography.Text>
          <Typography.Text strong style={{ margin: 0, color: getRemainingPriceColor() }}>
            {currencyFormatter(remaining, 2)}
          </Typography.Text>
        </Box>
      </Space>
    </Box>
  )
}
