import React from 'react'

import { useTheme } from '@emotion/react'

import { AuditOutlined, CheckOutlined, EditOutlined, FileDoneOutlined, FileSyncOutlined } from '@ant-design/icons'
import { Tag } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { OrderStates, OrderStatesFilter, OrderSubStates } from 'common/server/server_types'

export type OrderStateTagProps = {
  state?: OrderStatesFilter | OrderStates
  sub_state?: OrderSubStates | null
  deliveryTag?: string
  style?: React.CSSProperties
}

export const DeliveryTag = (props: OrderStateTagProps) => {
  const { state, sub_state, style, deliveryTag } = props
  const theme = useTheme()

  const StateMapping = {
    [OrderStatesFilter.REQUESTED]: {
      color: theme.colors['gold-1'],
      text: 'Requested',
      icon: <AuditOutlined style={{ paddingTop: 4 }} />,
    },
    [OrderStatesFilter.DRAFT]: {
      color: theme.colors['gold-1'],
      text: 'Drafted',
      icon: <EditOutlined style={{ paddingTop: 4 }} />,
    },
    [OrderStatesFilter.DRAFTED_REQUEST]: {
      color: theme.colors['gold-1'],
      text: 'Draft Request',
      icon: <EditOutlined style={{ paddingTop: 4 }} />,
    },
    [OrderStatesFilter.APPROVED]: {
      color: theme.colors['gold-1'],
      text: 'Approved',
      icon: <CheckOutlined style={{ paddingTop: 4 }} />,
    },
    [OrderStatesFilter.QUOTED]: {
      color: sub_state == OrderSubStates.QUOTED_RECEIVED ? 'blue' : 'gold',
      text:
        sub_state == OrderSubStates.QUOTED_RECEIVED ? (
          <span style={{ marginLeft: -3 }}>Quote Provided</span>
        ) : (
          'Quote Requested'
        ),
      icon: sub_state == OrderSubStates.QUOTED_RECEIVED ? <FileSyncOutlined style={{ paddingTop: 4 }} /> : undefined,
    },
    [OrderStatesFilter.ORDERED]: {
      color: sub_state == OrderSubStates.ORDERED_CONFIRMED ? 'blue' : 'gold',
      text:
        sub_state == OrderSubStates.ORDERED_CONFIRMED ? (
          <span style={{ marginLeft: -3 }}> Order Confirmed</span>
        ) : (
          'Order Placed'
        ),
      icon: sub_state == OrderSubStates.ORDERED_CONFIRMED ? <FileDoneOutlined style={{ paddingTop: 4 }} /> : undefined,
    },
    [OrderStatesFilter.SHIPPED]: { color: 'green', text: 'Shipped' },
    [OrderStatesFilter.PARTIALLY_SHIPPED]: { color: 'green', text: 'Partially Shipped' },
    [OrderStatesFilter.DELIVERED]: { color: 'green', text: 'Delivered' },
    [OrderStates.ORDERED_CONFIRMED_CANCELLATION_REQUESTED]: {
      color: theme.colors['gray-2'],
      text: 'Requested Cancellation',
    },
  }

  const defaultStyle = {
    color: 'black',
    width: deliveryTag ? '80px' : '120px',
    display: 'flex',
    marginRight: '8px',
    marginLeft: deliveryTag ? '0px' : '8px',
    justifyContent: 'center',
    fontWeight: 'normal',
    height: 25,
    ...style,
  }

  if (deliveryTag) {
    return (
      <Tag style={defaultStyle}>
        <FlexBoxX aria-label="status-badge" fontSize={12}>
          {deliveryTag}
        </FlexBoxX>
      </Tag>
    )
  }

  return (
    <>
      <Tag icon={StateMapping[state]?.['icon']} color={StateMapping[state]?.['color']} style={defaultStyle}>
        <FlexBoxX aria-label="status-badge" fontSize={12}>
          {StateMapping[state]?.['text']}
        </FlexBoxX>
      </Tag>
    </>
  )
}
