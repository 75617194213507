import React from 'react'

import { uniq } from 'lodash'

import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined'
import { Typography } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { ChangedItem, ChangeType, SyncStatus } from 'common/components/History'
import OrderStateTag from 'common/components/statuses/order_state'
import { OrderHistoryResponse } from 'common/server/order_history'
import { OrderStates } from 'common/server/server_types'

const ORDER_FIELDS_CONFIG = [
  { key: 'Order name', type: 'text' },
  { key: 'Sync status', type: 'sync' },
  { key: 'PO Number', type: 'text' },
  { key: 'Ref Number', type: 'text' },
  { key: 'State', type: 'state' },
  { key: 'Sub state', type: 'state' },
  { key: 'Tags', type: 'tag' },
  { key: 'Vendor', type: 'text' },
  { key: 'Vendor contacts', type: 'tag' },
  { key: 'Watchers', type: 'tag' },
  { key: 'Expired quote at', type: 'date' },
  { key: 'Quote scheduled to expire at', type: 'date' },
  { key: 'Splitted from', type: 'link' },
  { key: 'Splitted to', type: 'link' },
  { key: 'Merged from', type: 'link' },
  { key: 'Merged to', type: 'link' },
  { key: 'Confirmed by', type: 'text' },
  { key: 'Approved by', type: 'text' },
  { key: 'Cancellation accepted at', type: 'date' },
  { key: 'Cancellation rejected at', type: 'date' },
  { key: 'Cancellation requested at', type: 'date' },
  { key: 'Cancellation requested by', type: 'text' },
  { key: 'Cancellation requested reason', type: 'text' },
]
export const ALLOW_ORDER_FIELDS = ORDER_FIELDS_CONFIG.map((item) => item.key)

type OrderProps = {
  order: OrderHistoryResponse['order']
  snapshot: OrderHistoryResponse['snapshot']
  isOrderCreate?: boolean
}

const OrderState = ({ order, snapshot }: OrderProps) => {
  // We need state and sub-state to mount the component, so we get the change if are exists else we get from the snapshot
  const statePrev = order?.['State'] ? order?.['State'][0] : snapshot['State']
  const subStatePrev = order?.['Sub state'] ? order?.['Sub state'][0] : snapshot['Sub state']

  const stateNext = order?.['State'] ? order?.['State'][1] : snapshot['State']
  const subStateNext = order?.['Sub state'] ? order?.['Sub state'][1] : snapshot['Sub state']

  return (
    <FlexBoxX alignItems="center" justifyContent="flex-start" mb="8px">
      <Typography.Text style={{ marginRight: 8, whiteSpace: 'nowrap' }}>State:</Typography.Text>
      {!!statePrev && (
        <>
          <OrderStateTag state={statePrev} sub_state={subStatePrev} />
          <ArrowRightOutlined style={{ margin: '0 8px' }} />
        </>
      )}
      <OrderStateTag state={stateNext} sub_state={subStateNext} />
    </FlexBoxX>
  )
}

/*
  Each key represents one change that the user will see.
  Sometimes the order could change state, sub-state, or both.
  When we receive both we need to merge them in a single change.
  So when we receive a change in State or Sub state we merge in OrderState.
*/
const mergeStateAndSubState = (key) => {
  if (key === 'State' || key === 'Sub state') {
    return 'OrderState'
  }
  return key
}

export const Order = ({ order, snapshot, isOrderCreate }: OrderProps) => {
  const keys = Object.keys(order)
    .filter((key) => ALLOW_ORDER_FIELDS.includes(key))
    .map(mergeStateAndSubState)

  /* Guarantee that we will not have two OrderState in the list */
  const uniqKeys = uniq(keys)

  if (!uniqKeys.length) {
    return null
  }

  return (
    <>
      {uniqKeys
        .sort((a, b) => {
          if (a < b) return -1
          if (a > b) return 1
          return 0
        })
        .sort((a, b) => {
          const state = 'OrderState'
          if (a === state && b !== state) return -1
          if (a !== state && b === state) return 1
          return 0
        })
        .map((key, index) => {
          const changes = order[key]

          // Return special component for order state change
          if (key === 'OrderState') {
            return <OrderState order={order} snapshot={snapshot} key={`${key}-${index}`} />
          }

          if (key === 'Sync status') {
            return <SyncStatus invoice={order} snapshot={snapshot} key={`${key}-${index}`} />
          }

          if (['Splitted from', 'Splitted to', 'Merged from', 'Merged to'].includes(key)) {
            const typePath = [OrderStates.QUOTED].includes(order['State']?.[1]) ? 'new_quote' : 'new_order'
            const link = ['Merged from'].includes(key)
              ? `/order/${changes[1]?.id}`
              : `/orders/${typePath}?order_package_id=${changes[1]?.id}`
            return (
              <ChangedItem
                key={`${key}-${index}`}
                label={key}
                changes={[null, changes[1]?.name] as [string, string]}
                type="link"
                href={link}
              />
            )
          }

          return (
            <ChangedItem
              key={`${key}-${index}`}
              label={key}
              changes={changes}
              isCreate={isOrderCreate}
              type={ORDER_FIELDS_CONFIG.find((item) => item.key === key)?.type as ChangeType}
            />
          )
        })}
    </>
  )
}
