import React, { useRef, useState } from 'react'

import { HistoryOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { ErrorBoundary } from 'common/components/ErrorBoundary/error_boundary'
import { OrderHistory } from 'common/components/OrderHistory'
import { noticeError } from 'common/helpers/new_relic'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'

import { useStores } from 'contractor/hooks/use-stores'

export const History = observer(() => {
  const drawerHistoryRef = useRef<DrawerRef>()

  const { orderStore, companySettingStore, userStore } = useStores()
  const source = location.pathname.substr(1)

  const [loading, setLoading] = useState(false)

  const handleBtnClick = async () => {
    drawerHistoryRef.current?.show()
    trackEvent(Events.ORDER_HISTORY, source, { clickView: true })
    try {
      setLoading(true)
      await orderStore.getOrderHistory(orderStore.selectedOrder?.id)
    } catch (error) {
      noticeError(error, { entry: 'contractor-order-history' })
      message.error(`Unable to load history. Please contact ${SUPPORT_EMAIL_ADDRESS} for further assistance.`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Button data-cy="history-button" icon={<HistoryOutlined />} onClick={handleBtnClick} />

      <Drawer ref={drawerHistoryRef} forceRender width={800} title="History">
        <Box display="flex" flexDirection="column" alignItems="flex-end" width="100%" height="100%">
          <Box width="100%" overflowY="auto" px={24} pt={24} id="comments-list">
            <ErrorBoundary isFull={false}>
              <OrderHistory
                isLoading={loading}
                data={orderStore.orderHistory}
                companyAttributes={companySettingStore.companyMaterialConfiguration.company_attributes}
                canUseIntegrations={userStore.canUseIntegrations}
              />
            </ErrorBoundary>
          </Box>
        </Box>
      </Drawer>
    </>
  )
})
