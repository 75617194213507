import React, { useEffect, useMemo, useState } from 'react'

import { Alert, Button, Form, message, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { PdfViewer } from 'common/components/PdfViewer'
import Uploader from 'common/components/uploader'
import { useQuery } from 'common/hooks/use-query'
import { OrderHit } from 'common/server/orders'

import { useStores } from 'contractor/hooks/use-stores'
import { CommonInvoiceForm } from 'contractor/pages/Invoices/Add/common_invoice_form'
import { CreateInvoicePayload } from 'contractor/server/invoices/invoice'

const CREATE_INVOICE_KEY = 'create_invoice'

type CreateInvoiceDrawerProps = {
  setSubmitting?: React.Dispatch<React.SetStateAction<boolean>>
  onClose: () => void
}

export const CreateInvoiceDrawer = observer<CreateInvoiceDrawerProps, DrawerRef>(
  ({ setSubmitting, onClose }, ref) => {
    const { uploaderStore, invoiceStore, companySettingStore } = useStores()

    useQuery(companySettingStore.maybeIndexUsers)

    const [alertMessage, setAlertMessage] = useState('')
    const [selectedOrders, setSelectedOrders] = useState<OrderHit[]>([])
    const [submittingForm, setSubmittingForm] = useState(false)
    const [uploadUrl, setUploadUrl] = useState('')

    const [form] = Form.useForm()
    const getFile = (e) => {
      if (Array.isArray(e)) {
        return e
      }
      return e && e.fileList
    }

    const clearForm = () => {
      form.resetFields()
      setSelectedOrders([])
      setAlertMessage('')
      uploaderStore.resetAllUploads()
      setUploadUrl(null)
    }

    const createInvoice = async (formValues) => {
      setSubmitting?.(true)
      setSubmittingForm(true)

      const {
        name,
        number,
        assigned_to_company_user_id,
        company_vendor,
        due_date,
        document_date,
        total_amount,
        order_ids,
      } = formValues

      try {
        const payload: CreateInvoicePayload = {
          order_ids,
          name,
          number,
          assigned_to_company_user_id,
          company_vendor_id: company_vendor?.value || null,
          due_date,
          document_date,
          total_amount,
          file_signed_id: uploaderStore.signedIds(CREATE_INVOICE_KEY)[0],
        }
        await invoiceStore.create(payload)
        message.success('Invoice added')
        clearForm()
      } catch (err) {
        console.error(`Unable create invoice: `, err?.response?.data)
        setAlertMessage(`Unable to create invoice: ${number}`)
      } finally {
        setSubmitting?.(false)
        setSubmittingForm(false)

        await invoiceStore.listStore.fetchRecords()
      }
    }

    const drawerWidth = useMemo(() => {
      return uploadUrl ? 1024 : 378
    }, [uploadUrl])

    const uploadList = uploaderStore.pdfUploadFileList(CREATE_INVOICE_KEY)

    useEffect(() => {
      if (uploadList[0]?.signedId && !uploadUrl) {
        uploaderStore.getFinalUrlBySignedId(uploadList[0].signedId).then((response) => {
          setUploadUrl(response.url)
        })
      }
    }, [uploadList])

    const handleRefreshUpload = () => {
      setUploadUrl('')
    }

    return (
      <Drawer
        closable
        onClose={onClose}
        ref={ref}
        width={drawerWidth}
        title={
          <Box display="flex" width="100%" height="100%" alignItems="flex-start" justifyContent="space-between">
            <Box display="inline-flex" alignItems="flex-start">
              <Typography.Text style={{ marginTop: 5 }}>Manually create Invoices</Typography.Text>
            </Box>
            <Box display="inline-flex" alignItems="flex-end">
              <Button style={{ width: '100%' }} type="default" onClick={clearForm}>
                Reset
              </Button>
            </Box>
          </Box>
        }
        forceRender
      >
        <Box display="flex" width="100%">
          <Box
            display={uploadUrl ? 'inline-flex' : 'none'}
            justifyContent="flex-start"
            width={646}
            style={{
              borderRadius: 3,
              overflowY: 'auto',
              overflowX: 'hidden',
              height: '100%',
            }}
            p="16px 0 16px 16px"
          >
            <PdfViewer url={uploadUrl} key={uploadUrl} />
          </Box>
          <Box width={378} overflowY="auto" p={16}>
            <Form
              id="create-invoice-form"
              style={{ width: '100%' }}
              form={form}
              onFinish={createInvoice}
              layout="vertical"
            >
              {alertMessage && <Alert type="error" message={alertMessage} style={{ marginBottom: '20px' }} />}

              <Form.Item
                name="signed_file_id"
                label="Invoice PDF"
                getValueFromEvent={getFile}
                style={{ marginBottom: 12 }}
                rules={[{ required: true, message: 'Invoice PDF is required!' }]}
              >
                <Uploader
                  fileList={uploaderStore.fileList(CREATE_INVOICE_KEY)}
                  uploadKey={CREATE_INVOICE_KEY}
                  multiple={false}
                  accept="application/pdf"
                  data-cy="attach-invoice"
                  showPreviewModal
                  listType="text"
                  showDownload
                  onRemoveUpload={(uploadKey, uid) => {
                    handleRefreshUpload()
                    uploaderStore.removeUpload(uploadKey, uid)
                  }}
                  onAddNewUpload={(uploadKey, directUpload, file) => {
                    uploaderStore.addNewUpload(uploadKey, directUpload, file)
                  }}
                  onResetUploads={uploaderStore.resetUploads}
                  onSetUploadError={uploaderStore.setUploadError}
                  onUpdateUpload={uploaderStore.updateUpload}
                />
              </Form.Item>
              <CommonInvoiceForm form={form} selectedOrders={selectedOrders} setSelectedOrders={setSelectedOrders} />
              <Button
                style={{ width: '100%' }}
                type="primary"
                loading={submittingForm}
                form="create-invoice-form"
                key="submit"
                htmlType="submit"
              >
                Create Invoice
              </Button>
            </Form>
          </Box>
        </Box>
      </Drawer>
    )
  },
  { forwardRef: true },
)
