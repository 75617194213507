import axios from 'axios'

import {
  BasicRelationship,
  ListParams,
  MappingSuggestion,
  Relationship,
  SearchParams,
  UpdateMapping,
} from './integration'

export type UpdateCostCodeNumber = UpdateMapping

export interface CostCodeNumber {
  id: string
  code: string
  description?: string
  external_relationships: BasicRelationship[]
  mapping_suggestions?: MappingSuggestion[]
}

export function cost_code_numbers_relationships(params?: ListParams) {
  return axios.get<Relationship[]>(`/integrations/accounting/cost_code_numbers/relationships`, { params })
}
export function cost_code_numbers_count(mappingDirection: string, params?: SearchParams) {
  const queryParams = params
    ? { ...params, mapping_direction: mappingDirection }
    : { mapping_direction: mappingDirection }
  return axios.get<{ [key: string]: number }>(`/integrations/accounting/cost_code_numbers/count`, {
    params: queryParams,
  })
}
export function cost_code_numbers(params: ListParams) {
  return axios.get<CostCodeNumber[]>(`/integrations/accounting/cost_code_numbers`, { params })
}
export function update_cost_code_number(cost_code_number: UpdateCostCodeNumber) {
  return axios.patch(`/integrations/accounting/cost_code_numbers/${cost_code_number?.id}`, cost_code_number)
}
export function resync_cost_code_numbers() {
  return axios.patch('/integrations/accounting/cost_code_numbers/resync')
}
