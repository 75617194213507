import React from 'react'

import { Card } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { InvoiceMaterial, InvoiceMaterialGroup } from 'common/server/invoice'

import { CreateGroupItemDrawer } from '../components/CreateGroupItem'
import { InvoiceGroupItems } from '../components/InvoiceGroupItems'
import { InvoiceItem } from '../components/InvoiceItem'
import { useInvoice } from '../context'

export enum DraggableType {
  ORDER_MATERIAL,
  INVOICE_MATERIAL,
}

type InvoiceMaterialsProps = {
  onFilterTable?: (dataSource: InvoiceMaterial) => void
}

export const InvoiceMaterials = observer<InvoiceMaterialsProps>(({ onFilterTable }) => {
  const {
    taxAmountField,
    calcTaxSplitBulk,
    taxLineItemsEnabled,
    invoiceMaterialsFiltered,
    calcExtCost,
    handleSelectInvoiceMaterials,
  } = useInvoice()

  const [groupInEdit, setGroupInEdit] = React.useState<InvoiceMaterialGroup>()
  const [groupDrawerOpened, setGroupDrawerOpened] = React.useState(false)

  const dataSource = calcTaxSplitBulk({
    invoiceMaterials: invoiceMaterialsFiltered.materialsFilteredBySearch,
    taxAmount: taxAmountField,
    taxLineItemsEnabled,
  })

  const dataFiltered = onFilterTable ? dataSource.filter(onFilterTable) : dataSource
  const groupsFiltered = invoiceMaterialsFiltered.groups

  return (
    <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
      {groupsFiltered.map((group) => (
        <Card key={group.id} bodyStyle={{ padding: 12 }}>
          <InvoiceGroupItems
            group={{
              id: group.id,
              group_name: group.name,
              group_total: group.invoice_materials?.length,
              quantity_shipped: group.quantity_shipped,
              unit_price: group.unit_price,
              uom: group.unit_name,
              extended_price: group.extended_price,
              calcExtCost,
              checked: group?.checked,
            }}
            onCheck={() => handleSelectInvoiceMaterials(group.invoice_materials.map((material) => material.id))}
            onEdit={() => {
              setGroupInEdit({
                ...group,
                invoice_materials: group.invoice_materials.map((material) => ({
                  ...material,
                  checked: true,
                })),
              })
              setGroupDrawerOpened(true)
            }}
          />
        </Card>
      ))}
      {dataFiltered.map((invoiceMaterial) => (
        <Card key={invoiceMaterial.id} bodyStyle={{ padding: 12 }}>
          <InvoiceItem
            item={{
              checked: invoiceMaterial?.checked,
              id: invoiceMaterial.id,
              description: invoiceMaterial.description,
              quantity_shipped: invoiceMaterial.quantity_shipped,
              uom: invoiceMaterial.uom,
              unit_price: invoiceMaterial.unit_price,
              calcExtCost,
            }}
            onCheck={(itemId) => handleSelectInvoiceMaterials([itemId])}
          />
        </Card>
      ))}

      <CreateGroupItemDrawer isOpened={groupDrawerOpened} setIsOpened={setGroupDrawerOpened} group={groupInEdit} />
    </Box>
  )
})
