import axios from 'axios'

import { StandardIndexRequest, StandardIndexResponse } from 'common/server/server_types'
export interface CostCodeNumber {
  id: string
  code: string
  description?: string
  discarded_at?: string
}

export interface CreateCostCodeNumber {
  code: string
  description?: string
  active: boolean
}

export interface UpdateCostCodeNumber {
  id: string
  code?: string
  description?: string
  active?: boolean
}

export function create_cost_code_number(cost_code_number: CreateCostCodeNumber) {
  return axios.post<CostCodeNumber>('/cost_code_numbers', { cost_code_number })
}

export function update_cost_code_number(cost_code_number: UpdateCostCodeNumber) {
  return axios.patch<CostCodeNumber>(`/cost_code_numbers/${cost_code_number.id}`, { cost_code_number })
}

export function get_all_cost_code_numbers(params: StandardIndexRequest) {
  return axios.get<StandardIndexResponse<CostCodeNumber[]>>('/cost_code_numbers', { params })
}

export function get_cost_code_number(id: string) {
  return axios.get<CostCodeNumber>(`/cost_code_numbers/${id}`)
}
