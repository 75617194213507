import React, { useEffect, useMemo, useCallback } from 'react'

import { sortBy, uniqBy } from 'lodash'

import { Skeleton } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { DebounceSelect, DebounceSelectProps } from 'common/components/DebounceSelect'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { IndexCompanyVendor } from 'contractor/server/company_vendors'
import { Vendor } from 'contractor/server/vendors'

export type Option = {
  value: string
  label: string
  vendorId: string
  isGlobal: boolean
  domain?: string
  ownedByContractor: boolean
}

export type CompanyVendorSelectorProps = {
  domain?: string
  onMatchDomain?: (vendor: Option) => void
  defaultCompanyVendor?: IndexCompanyVendor
  defaultVendor?: Vendor
  onChange?: (option: Nullable<Option>) => void
} & Partial<Omit<DebounceSelectProps, 'onChange'>>

export const makeCompanyVendorOption = (companyVendor) => {
  const vendor = companyVendor?.vendor

  return {
    value: companyVendor.id,
    label: vendor ? companyVendor.vendor.name : companyVendor.vendor_name,
    vendorId: vendor?.id,
    isGlobal: !!vendor,
    domain: vendor?.domain,
    ownedByContractor: true,
  }
}

export const makeVendorOption = (vendor) => {
  return {
    value: vendor.id,
    label: vendor.name,
    vendorId: vendor?.id,
    isGlobal: true,
    domain: vendor?.domain,
    ownedByContractor: false,
  }
}

const getFilteredCompanyVendors = (companyVendors = []) => {
  const companyVendorsWithVendor = companyVendors?.filter((companyVendor) => !!companyVendor?.vendor)
  const companyVendorsWithoutVendor = companyVendors?.filter((companyVendor) => !companyVendor?.vendor)
  const uniqCompanyVendorsWithVendor = uniqBy(companyVendorsWithVendor, (companyVendor) => companyVendor?.vendor?.id)

  return [...companyVendorsWithoutVendor, ...uniqCompanyVendorsWithVendor]
}

export const CompanyVendorSelector = observer<CompanyVendorSelectorProps>(
  ({ domain, onMatchDomain, onChange, ...props }) => {
    const { vendorStore, companyVendorStore } = useStores()

    const { companyVendorListSelectorStore } = companyVendorStore

    const { isLoading } = useQuery(
      () => {
        return companyVendorListSelectorStore.fetchRecords()
      },
      [],
      !companyVendorListSelectorStore.records.length,
    )

    const subbaseVendorsOptions = useMemo(() => vendorStore.vendors.map(makeVendorOption), [vendorStore.vendors.length])

    const fetchOptions = useCallback(
      (search: string) => {
        return companyVendorListSelectorStore.setSearch(search).then((data) => {
          const companyVendorsSelect = toJS(data)
          const filteredCompanyVendors = getFilteredCompanyVendors(companyVendorsSelect)

          const companyVendorsOptions = filteredCompanyVendors.map(makeCompanyVendorOption)
          const companyVendorsVendorsIds = companyVendorsSelect?.map((companyVendor) => companyVendor?.vendor?.id)

          // Remove the vendor that the current contract already added to your list
          const vendorsOptions = subbaseVendorsOptions.filter(
            (option) => !companyVendorsVendorsIds.includes(option.value),
          )

          return [
            {
              label: 'Your Existing Vendors',
              options: sortBy(companyVendorsOptions, 'label'),
            },
            { label: 'Vendors from SubBase Network', options: sortBy(vendorsOptions, 'label') },
          ]
        })
      },
      [subbaseVendorsOptions.length],
    )

    const initialOptions = useMemo(() => {
      const companyVendorsSelect = toJS(companyVendorListSelectorStore.records)
      const filteredCompanyVendors = getFilteredCompanyVendors(companyVendorsSelect)

      const companyVendorsOptions = filteredCompanyVendors.map(makeCompanyVendorOption)
      const companyVendorsVendorsIds = companyVendorsSelect?.map((companyVendor) => companyVendor?.vendor?.id)

      // Remove the vendor that the current contract already added to your list
      const vendorsOptions = subbaseVendorsOptions.filter((option) => !companyVendorsVendorsIds.includes(option.value))

      return [
        {
          label: 'Your Existing Vendors',
          options: sortBy(companyVendorsOptions, 'label'),
        },
        { label: 'Vendors from SubBase Network', options: sortBy(vendorsOptions, 'label') },
      ]
    }, [companyVendorListSelectorStore.records.length, subbaseVendorsOptions.length])

    useEffect(() => {
      if (domain) {
        const vendor = [...initialOptions[0].options, ...initialOptions[1].options].find(
          (vendor) => vendor.domain === domain,
        )

        if (vendor) {
          onChange(vendor)
          onMatchDomain(vendor)
        }
      }
    }, [initialOptions.length, onMatchDomain, domain])

    if (isLoading) {
      return <Skeleton.Input block active />
    }

    return (
      <DebounceSelect
        allowClear
        labelInValue
        placeholder=""
        loading={isLoading}
        initialOptions={initialOptions}
        fetchOptions={fetchOptions}
        {...props}
        onSelect={(_, option: Option) => {
          onChange(option)
          companyVendorListSelectorStore.searchState.search = ''
        }}
        onClear={() => {
          onChange(null)
          companyVendorListSelectorStore.searchState.search = ''
        }}
        style={{ width: '100%', ...props?.style }}
      />
    )
  },
)
