import React from 'react'

import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import { Col, Row, Typography } from 'antd'

import { Box } from 'common/components/boxes'

export const ProcoreMultiSelect = ({ children, hasSuggestions, values, row, selected, handleUpdate }) => {
  const boxStyle = hasSuggestions ? { border: '1px solid #ffc53d' } : {}
  return (
    <>
      {children}
      <Box width="100%" p={10} mt={5} style={boxStyle}>
        {values
          .sort((a, b) => (a.external_name > b.external_name ? 1 : -1))
          .map((item, idx) => (
            <span key={`span-${item.external_id}`}>
              {idx === 0 && (
                <Row key={`header-${item.external_id}`}>
                  <Col span={7}>
                    <Typography.Text strong>Project</Typography.Text>
                  </Col>
                  <Col span={14}>
                    <Typography.Text strong>Name</Typography.Text>
                  </Col>
                  <Col span={3} />
                </Row>
              )}
              <Row key={`row-${item.external_id}`}>
                <Col span={7}>
                  <Typography.Text>{item.external_name.split(':')[0]}</Typography.Text>
                </Col>
                <Col span={14}>
                  <Typography.Text>{item.external_name.split(':').slice(1).join(':')}</Typography.Text>
                </Col>
                {/* @ts-ignore (complaining about align) */}
                <Col span={3} align="right">
                  {!hasSuggestions && (
                    <DeleteOutlined
                      onClick={() => {
                        const filteredOptions = selected.filter((option) => option.value !== item.external_id)
                        handleUpdate({ option: filteredOptions, entity: row })
                      }}
                    />
                  )}
                </Col>
              </Row>
            </span>
          ))}
      </Box>
    </>
  )
}
