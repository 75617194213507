import { BindAll } from 'lodash-decorators'

import { action, observable } from 'mobx'

import { CompanyMaterialHit } from 'common/server/company_materials'
import AlgoliaBaseStore from 'common/stores/AlgoliaBaseStore'
import ListBaseStore from 'common/stores/ListBaseStore'

import {
  CompanyMaterial,
  ConsolidatedCompanyMaterials,
  create,
  CreateCompanyMaterial,
  exportRecords,
  facets,
  getCompanyMaterialTags,
  index,
  publicCreate,
  search_key,
  show,
  ShowCompanyMaterialResponse,
  update,
} from 'contractor/server/company_materials'

class CompanyMaterialListStore extends ListBaseStore<ConsolidatedCompanyMaterials.CompanyMaterial> {
  index = index
  getFacets = facets
  export = exportRecords
}

@BindAll()
export default class CompanyMaterialStore extends AlgoliaBaseStore<CompanyMaterialHit> {
  listStore: CompanyMaterialListStore

  @observable selectedCompanyMaterial: Nullable<ShowCompanyMaterialResponse> = null
  allCompanyMaterials = observable.array<CompanyMaterial>([], { deep: true })

  companyMaterialTags = observable.array<string>([])

  getSearchKey = search_key

  constructor() {
    super()

    this.listStore = new CompanyMaterialListStore()
  }

  get company_materials() {
    return this.hits
  }

  @action
  async getCompanyMaterialTags() {
    const { data } = await getCompanyMaterialTags()
    this.companyMaterialTags.replace(data?.company_material_tags || [])
  }

  async publicCreate(companyMaterial: CreateCompanyMaterial, publicOrderFormUrlExtension: string) {
    const { data } = await publicCreate(companyMaterial, publicOrderFormUrlExtension)
    return data
  }

  async create(companyMaterial: CreateCompanyMaterial) {
    const { data } = await create(companyMaterial)
    return data
  }

  async update(companyMaterial: CreateCompanyMaterial) {
    const { data } = await update(companyMaterial)
    return data
  }

  @action
  async selectMaterial(id: Nullable<string>) {
    if (!id) {
      this.selectedCompanyMaterial = null
    } else {
      this.selectedCompanyMaterial = (await show(id)).data
    }
    return this.selectedCompanyMaterial
  }

  @action
  async replaceSelectedMaterial(companyMaterial: ShowCompanyMaterialResponse) {
    this.selectedCompanyMaterial = companyMaterial
  }

  async showMaterialDetail(id: string) {
    return (await show(id)).data
  }
}
