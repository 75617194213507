export const isLocal = () => window.location.href.includes('localhost') || window.location.href.includes('ngrok')

export const isStaging = () => window.location.href.includes('staging')

const environment = (): string => {
  if (isLocal()) return 'development'
  if (isStaging()) return 'staging'
  return 'production'
}

export const isProduction = () => environment() == 'production'

export const ENVIRONMENT = environment()
