import React from 'react'

import { CalendarOutlined } from '@ant-design/icons'
import { Space, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { CustomEditableContent } from 'common/components/CustomEditableContent'
import DateTimePicker from 'common/components/date_time_picker'
import { formatDateStringShort } from 'common/helpers/formatters'

import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'

interface AccountingDateProps {
  disabled?: boolean
}

export const AccountingDate = observer<AccountingDateProps>(({ disabled }) => {
  const { invoiceStore } = useStores()

  const { setSelectedInvoiceDirty } = useInvoice()
  const ref = React.useRef()

  const { invoice } = invoiceStore

  return (
    <CustomEditableContent
      boxProps={{ mt: 0 }}
      disabled={disabled}
      refs={[ref]}
      input={
        <DateTimePicker
          pickerRef={ref}
          disabled={disabled}
          value={invoice?.accounting_date}
          onChange={(date) => {
            setSelectedInvoiceDirty(true)
            invoiceStore.updateSelectedInvoice('accounting_date', date)
          }}
          wrapperProps={{ width: 150 }}
        />
      }
    >
      <Tooltip title="Accounting Date" placement="bottom">
        <Space>
          <CalendarOutlined />
          <Typography.Text type="secondary">
            {formatDateStringShort(invoice.accounting_date) || 'Accounting Date'}
          </Typography.Text>
        </Space>
      </Tooltip>
    </CustomEditableContent>
  )
})
