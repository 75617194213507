import React from 'react'

import { Tag, Empty, Typography } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { PsqlTable } from 'common/components/PsqlTable'
import { PsqlTableProvider } from 'common/components/PsqlTable/psql_table_provider'
import { ResponsiveTable } from 'common/components/ResponsiveTable'
import CheckBoxCell from 'common/components/table/cells/checkbox'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

type TableCostCodesProps = {
  onClickRow: (costCodeId: string) => void
}

const ItemListWithMore = ({ items = [], renderItem, label, qtyItemsToShow = 5 }) => {
  const displayedItems = items?.slice(0, qtyItemsToShow)
  const remainingItemsCount = items?.length - displayedItems?.length

  const renderList =
    remainingItemsCount > 0
      ? [...displayedItems, { id: 'more', name: `...and ${remainingItemsCount} more ${label}` }]
      : displayedItems

  if (!renderList?.length) {
    return null
  }

  return (
    <Box style={{ gap: 4 }} display="flex" flexWrap="wrap">
      {renderList.map((item) =>
        item.id === 'more' ? (
          <Tag key={item.id} style={{ margin: 0 }}>
            {item.name}
          </Tag>
        ) : (
          renderItem(item)
        ),
      )}
    </Box>
  )
}

const Content = observer<TableCostCodesProps>(({ onClickRow }) => {
  const { costCodeStore } = useStores()

  const { costCodeListStore } = costCodeStore

  if (costCodeListStore.isFetching && costCodeListStore.searchState.page === 1) {
    return <Loading />
  }

  if (costCodeListStore.records.length === 0) {
    return (
      <Box
        p={16}
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              Sorry! No Items were found with these search criteria.
            </Typography.Title>
          }
        />
      </Box>
    )
  }

  return (
    <ResponsiveTable
      Table={PsqlTable}
      data={toJS(costCodeListStore.records) ?? []}
      hasMore={costCodeListStore.hasMore}
      loadMore={costCodeListStore.fetchNextPage}
      onLoadMore={costCodeListStore.fetchNextPage}
      onClickRow={({ row }) => onClickRow(row.id)}
    />
  )
})

export const TableCostCodes = observer<TableCostCodesProps>(({ onClickRow }) => {
  const { costCodeStore, companySettingStore } = useStores()
  const persistentFilters = usePersistentFilters()

  const { costCodeListStore } = costCodeStore

  useQuery(costCodeListStore.fetchFacets)
  useQuery(() => {
    persistentFilters.handleChangeFilters({ active: true }, false)
    return persistentFilters.init()
  }, [])

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  return (
    <PsqlTableProvider
      options={{
        data: toJS(costCodeListStore.records),
        sort: {
          sortFromPersistentFilter: true,
          field: costCodeListStore.searchState.sort,
          direction: costCodeListStore.searchState.sort_direction,
        },
        columns: [
          ...(costCodeSettings?.phase_code_enabled && !costCodeSettings?.independent_phase_codes_enabled
            ? [
                {
                  Header: 'Phase Code',
                  accessor: 'phase_code',
                  width: 'auto',
                },
                {
                  Header: 'Phase Code Description',
                  accessor: 'phase_code_description',
                  width: 'auto',
                },
              ]
            : []),
          {
            Header: 'Code',
            accessor: 'code',
            width: 'auto',
          },
          {
            Header: 'Description',
            accessor: 'description',
            width: 'auto',
          },
          ...(costCodeSettings?.class_enabled
            ? [
                {
                  Header: 'Class',
                  accessor: 'clazz',
                  width: 'auto',
                },
                {
                  Header: 'Class Description',
                  accessor: 'clazz_description',
                  width: 'auto',
                },
              ]
            : []),
          ...(costCodeSettings?.project_filtering_enabled
            ? [
                {
                  Header: 'Projects',
                  accessor: 'projects',
                  width: 'auto',
                  Cell: ({ value = [] }) => (
                    <ItemListWithMore
                      items={value}
                      renderItem={(project) => (
                        <Tag key={project?.id} style={{ margin: 0 }}>
                          {project?.name}
                        </Tag>
                      )}
                      label="projects"
                    />
                  ),
                },
              ]
            : []),
          {
            Header: 'Tags',
            accessor: 'tags',
            width: 200,
            Cell: ({ value: tags }) => (
              <ItemListWithMore
                items={tags}
                renderItem={(tags) => (
                  <Tag key={tags} style={{ margin: 0 }}>
                    {tags}
                  </Tag>
                )}
                label="tags"
              />
            ),
          },
          {
            Header: 'Active',
            accessor: 'discarded_at',
            width: 50,
            Cell: ({ value }) => <CheckBoxCell value={!value} />,
          },
        ],
      }}
      tableName="AllPhaseCodes"
    >
      <Content onClickRow={onClickRow} />
    </PsqlTableProvider>
  )
})
