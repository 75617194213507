import React from 'react'

import { Alert, Radio, Form, Input, Typography } from 'antd'

import { Box } from 'common/components/boxes'

import { CompanyVendorSelector } from './company_vendor_selector'

export const CompanyVendorForm = ({
  isExistingVendor,
  matchVendorBydomain,
  disabledRadio,
  onRadioChange,
  contactDomain,
  onMatchDomain,
  disabledCompanyVendorSelector,
}) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        flexGrow={0}
        mb="24px"
      >
        <Typography.Title level={4}>Vendor</Typography.Title>

        <Radio.Group buttonStyle="solid" value={isExistingVendor} onChange={onRadioChange} disabled={disabledRadio}>
          <Radio.Button value={true}>Existing Vendor</Radio.Button>
          <Radio.Button value={false}>New vendor</Radio.Button>
        </Radio.Group>
      </Box>

      {isExistingVendor ? (
        <Form.Item
          name="companyVendorOrVendor"
          label="Vendor"
          rules={[{ required: true, message: 'Vendor is required!' }]}
          extra={
            matchVendorBydomain && (
              <Alert
                type="success"
                message={`Awesome! ${matchVendorBydomain?.label} is already on Subbase!`}
                style={{ marginTop: 8 }}
              />
            )
          }
        >
          <CompanyVendorSelector
            domain={contactDomain}
            onMatchDomain={onMatchDomain}
            disabled={disabledCompanyVendorSelector}
          />
        </Form.Item>
      ) : (
        <Form.Item name={'vendorName'} label="Vendor" rules={[{ required: true, message: 'Vendor is required!' }]}>
          <Input />
        </Form.Item>
      )}

      {!isExistingVendor && (
        <Form.Item
          name="externalVendorId"
          label="Vendor ID"
          tooltip="Enter the name as it appears in your accounting system."
        >
          <Input />
        </Form.Item>
      )}
    </>
  )
}
