import React from 'react'

import { Button } from 'antd'

import { useStores } from 'contractor/hooks/use-stores'

type PlaceOrderButtonProps = {
  isLoading: boolean
  emptyCompanyVendors: boolean
  orderType: OrderType
  onSendRfq: () => void
  disabled?: boolean
}

export const ShowRFQButton = (props: PlaceOrderButtonProps) => {
  const { isLoading, emptyCompanyVendors, orderType, onSendRfq, disabled } = props
  const { userStore } = useStores()

  if (orderType == 'RFQ' && userStore.canSendAndUpdateRfqs) {
    return (
      <Button
        data-cy="send-rfq"
        type="primary"
        loading={isLoading}
        disabled={emptyCompanyVendors || disabled}
        onClick={onSendRfq}
        style={{ marginLeft: 8 }}
      >
        Send RFQ
      </Button>
    )
  }

  return null
}
