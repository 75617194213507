import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { OrderMaterial as IOrderMaterial } from 'common/server/orders'

type QuantityColumnProps = {
  item: IOrderMaterial
}

export const QuantityColumn = ({ item }: QuantityColumnProps) => {
  return (
    <Box>
      <Typography.Text>{item?.quantity}</Typography.Text>
    </Box>
  )
}
