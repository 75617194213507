import React, { useState } from 'react'

import { Avatar, Button, Card, Timeline, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { ContractorIcon } from 'common/components/ContractorIcon'
import { CollapsibleContent, Loading, makeTitle } from 'common/components/History'
import { SubBaseWhiteIcon } from 'common/components/SubBaseIcon'
import { VendorIcon } from 'common/components/VendorIcon'
import { useTheme } from 'common/hooks/use-theme'
import { OrderHistoryResponse } from 'common/server/order_history'

import { ALLOW_DELIVERY_FIELDS, Delivery } from './delivery'
import { ALLOW_ORDER_FIELDS, Order } from './order'
import { ALLOW_ORDER_COMMENT_FIELDS, OrderComment } from './order_comment'
import { ALLOW_ORDER_MATERIALS_FIELDS, OrderMaterials } from './OrderMaterials'
import { ALLOW_FILE_FIELDS, PurchaserFile } from './purchaser_file'
import { PurchaserOrder } from './purchaser_order'
import { Quote } from './quote'
import { VendorFile } from './vendor_file'

const filterAllowDeliveryOrOrderMaterialHistoryEvents = (deliveries = []) => {
  return !!deliveries?.some((delivery) => {
    const hasDeliveries = Object.keys(delivery.changes).some((key) => ALLOW_DELIVERY_FIELDS.includes(key))

    const hasMaterials = delivery?.order_materials?.some((orderMaterial) => {
      return Object.keys(orderMaterial?.changes).some((key) => ALLOW_ORDER_MATERIALS_FIELDS.includes(key))
    })

    return hasDeliveries || hasMaterials
  })
}

const filterAllowOrderHistoryEvents = (history: OrderHistoryResponse, canUseIntegrations = false) => {
  const hasOrder = Object.keys(history.order).some((key) => ALLOW_ORDER_FIELDS.includes(key))
  const hasComments = !!history?.comments?.some((comment) => {
    return Object.keys(comment.changes).some((key) => ALLOW_ORDER_COMMENT_FIELDS.includes(key))
  })
  const hasPurchaserFile = !!history?.purchaser_files?.some((purchaserFile) => {
    return Object.keys(purchaserFile.changes).some((key) => ALLOW_FILE_FIELDS.includes(key))
  })
  const hasPurchaserOrder = !!history?.purchase_order?.some((purchaserOrder) => {
    return Object.keys(purchaserOrder.changes).some((key) => ALLOW_FILE_FIELDS.includes(key))
  })
  const hasVendorFile = !!history?.vendor_files?.some((vendorFile) => {
    return Object.keys(vendorFile.changes).some((key) => ALLOW_FILE_FIELDS.includes(key))
  })
  const hasQuote = !!history?.quote?.some((quote) => {
    return Object.keys(quote.changes).some((key) => ALLOW_FILE_FIELDS.includes(key))
  })

  const hasDeliveriesOrMaterials = filterAllowDeliveryOrOrderMaterialHistoryEvents(history?.deliveries)

  // Do not show if the only change is sync status and the user don't have permission to see
  const orderFields = ALLOW_ORDER_FIELDS.filter((key) => key !== 'Sync status')
  if (
    canUseIntegrations &&
    !Object.keys(history.order).some((key) => orderFields.includes(key)) &&
    !(hasPurchaserFile || hasPurchaserOrder || hasVendorFile || hasQuote || hasDeliveriesOrMaterials || hasComments)
  ) {
    return false
  }

  return (
    hasOrder ||
    hasPurchaserFile ||
    hasPurchaserOrder ||
    hasVendorFile ||
    hasQuote ||
    hasDeliveriesOrMaterials ||
    hasComments
  )
}

type OrderHistoryProps = {
  isLoading?: boolean
  data: OrderHistoryResponse[]
  companyAttributes?: string[]
  currentView?: 'CONTRACTOR' | 'VENDOR' | 'SUBBASE'
  canUseIntegrations?: boolean
}

const Icon = ({ author }) => {
  if ((author?.company_id && author?.email.includes('subbase.io')) || !author) {
    return <SubBaseWhiteIcon />
  }

  if (author?.company_id) {
    return <ContractorIcon />
  }

  return <VendorIcon />
}

export const OrderHistory = observer((props: OrderHistoryProps) => {
  const { isLoading, data, companyAttributes = [], currentView = 'CONTRACTOR', canUseIntegrations } = props
  const [expanded, setExpand] = useState<Record<string, boolean>>({ 0: true })

  const theme = useTheme()

  const hasExpanded = Object.values(expanded).length > 1

  const handleToggleItem = (index: number) => {
    const value = Object.prototype.hasOwnProperty.call(expanded, index) ? !expanded[index] : true
    setExpand((prev) => ({
      ...prev,
      [index]: value,
    }))
  }

  const handleToggleAll = () => {
    if (hasExpanded) {
      setExpand({})
    } else {
      const expandeds = {}
      data.forEach((_, index) => {
        expandeds[index] = true
      })
      setExpand(expandeds)
    }
  }

  const getStyle = (author) => {
    const isVendorMessage = author?.vendor_id || author?.vendor_user_id

    if (
      (currentView === 'VENDOR' && isVendorMessage) ||
      (currentView === 'CONTRACTOR' && (author?.company_id || !author))
    ) {
      return {
        backgroundColor: theme.colors.primary,
      }
    }

    return {}
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end">
      {data.length > 0 && (
        <Button type="primary" size="small" onClick={handleToggleAll}>
          {hasExpanded ? 'Collapse All' : 'Expand All'}
        </Button>
      )}
      <Timeline style={{ paddingLeft: 11, paddingTop: 2, width: '100%', marginTop: 16 }}>
        {data
          .filter((history) => filterAllowOrderHistoryEvents(history, canUseIntegrations))
          .map((history, index) => {
            const isOrderCreate = history.event === 'create'

            const hasDeliveriesOrOrderMaterialChanges = filterAllowDeliveryOrOrderMaterialHistoryEvents(
              history?.deliveries,
            )

            return (
              <Timeline.Item
                key={history.created_at}
                dot={
                  <Tooltip title={expanded[index] ? 'Collapse' : 'Expand'}>
                    <span onClick={() => handleToggleItem(index)} style={{ cursor: 'pointer' }}>
                      <Avatar style={getStyle(history?.author)} icon={<Icon author={history?.author} />} />
                    </span>
                  </Tooltip>
                }
              >
                <Box pl="4px">
                  <Typography.Text
                    onClick={() => handleToggleItem(index)}
                    style={{ marginBottom: 12, cursor: 'pointer' }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: makeTitle(history?.title, history?.author?.name) }} />
                  </Typography.Text>

                  <CollapsibleContent isOpen={expanded[index]}>
                    {/* Order */}
                    <Order order={history.order} snapshot={history.snapshot} isOrderCreate={isOrderCreate} />

                    {/* Order Comment */}
                    {history?.comments?.map((orderComment) => (
                      <OrderComment key={orderComment.item_id} orderComment={orderComment} />
                    ))}

                    {/* Purchaser Files */}
                    {history?.purchaser_files?.map((purchaserFile) => (
                      <PurchaserFile key={purchaserFile.item_id} purchaserFile={purchaserFile} />
                    ))}

                    {/* Purchase Order */}
                    {history?.purchase_order?.map((purchaserOrder) => (
                      <PurchaserOrder key={purchaserOrder.item_id} purchaserOrder={purchaserOrder} />
                    ))}

                    {/* Vendor Files */}
                    {history?.vendor_files?.map((vendorFile) => (
                      <VendorFile key={vendorFile.item_id} vendorFile={vendorFile} />
                    ))}

                    {/* Quote */}
                    {history?.quote?.map((quote) => (
                      <Quote key={quote.item_id} quote={quote} />
                    ))}

                    {/* Deliveries */}
                    {hasDeliveriesOrOrderMaterialChanges &&
                      history?.deliveries?.map((delivery, index) => (
                        <Card
                          key={delivery.item_id}
                          style={{ width: '100%', marginBottom: 12 }}
                          size="small"
                          title={`Delivery #${index + 1}`}
                        >
                          <Delivery delivery={delivery} />

                          {/* Materials */}
                          {delivery?.order_materials && (
                            <OrderMaterials
                              orderMaterials={delivery.order_materials}
                              isOrderCreate={isOrderCreate}
                              companyAttributes={companyAttributes}
                            />
                          )}
                        </Card>
                      ))}
                  </CollapsibleContent>
                </Box>
              </Timeline.Item>
            )
          })}
      </Timeline>
    </Box>
  )
})
