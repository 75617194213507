import React from 'react'

import { Button, Modal, Tooltip, Typography } from 'antd'

import { RfqsBlockedModalExpired } from './rfqs_blocked_modal_expired'
import { RfqsBlockedModalUnlocked } from './rfqs_blocked_modal_unlocked'

interface RfqsBlockedModalsProps {
  externalUsers?: Array<{ name: string }>
  modalOrderExpiredOpened?: boolean
  modalOrderLockedOpened?: boolean
  modalOrderReleasedOpened?: boolean
  modalOrderUnlockedOpened?: boolean
  onCancelModalOrderExpired?: () => void
  onCancelModalOrderReleased?: () => void
  onCloseModalOrderLocked?: () => void
  onCloseModalOrderUnlocked?: () => void
  onRenew?: () => void
  onUnlock?: () => void
  onUpdate?: () => void
  unlockEnabled?: boolean
}

const formatArrayNames = (externalUsers: RfqsBlockedModalsProps['externalUsers']) => {
  const users = externalUsers.filter((user, index, self) => index === self.findIndex((u) => u.name === user.name))
  if (users.length === 1) return users[0].name
  if (users.length === 2) return users.map((user) => user.name).join(' and ')

  const allButLast = users
    .slice(0, -1)
    .map((user) => user.name)
    .join(', ')
  const last = users[users.length - 1]?.name
  return `${allButLast} and ${last}`
}

export const RfqsBlockedModals = ({
  externalUsers = [{ name: 'a user' }],
  modalOrderExpiredOpened,
  modalOrderLockedOpened,
  modalOrderReleasedOpened,
  modalOrderUnlockedOpened,
  onCancelModalOrderExpired,
  onCancelModalOrderReleased,
  onCloseModalOrderLocked,
  onCloseModalOrderUnlocked,
  onRenew,
  onUnlock,
  onUpdate,
  unlockEnabled,
}: RfqsBlockedModalsProps) => {
  return (
    <>
      <Modal
        title="RFQs Blocked"
        open={modalOrderLockedOpened}
        onOk={onCloseModalOrderLocked}
        onCancel={onCloseModalOrderLocked}
        footer={[
          unlockEnabled && (
            <Tooltip title="This will make the person currently editing lose all unsaved changes and get locked from the all RFQs instead">
              <Button key="unlock" onClick={onUnlock}>
                Unlock
              </Button>
            </Tooltip>
          ),
          <Button key="ok" type="primary" onClick={onCloseModalOrderLocked}>
            OK
          </Button>,
        ]}
        width={500}
      >
        <Typography.Paragraph>
          This RFQs are being edited by <Typography.Text strong>{formatArrayNames(externalUsers)}</Typography.Text>. For
          urgent access, please contact the user directly.
        </Typography.Paragraph>
      </Modal>

      <Modal
        title="RFQs Released"
        open={modalOrderReleasedOpened}
        onOk={onUpdate}
        onCancel={onCancelModalOrderReleased}
        cancelText="Leave"
        width={500}
      >
        <Typography.Paragraph>The RFQs are now released for editing, do you want to access it?</Typography.Paragraph>
      </Modal>

      <RfqsBlockedModalExpired
        onCancelModalOrderExpired={onCancelModalOrderExpired}
        modalOrderExpiredOpened={modalOrderExpiredOpened}
        onRenew={onRenew}
      />

      <RfqsBlockedModalUnlocked
        modalOrderUnlockedOpened={modalOrderUnlockedOpened}
        onCloseModalOrderUnlocked={onCloseModalOrderUnlocked}
        onUnlock={onUnlock}
      />
    </>
  )
}
