import { Theme } from 'common/styles/theme/theme'

// to custom theme https://github.com/JedWatson/react-select/issues/3692
export const setTheme = (theme: Theme) => (defaultTheme) => ({
  ...defaultTheme,
  borderRadius: 2,
  colors: {
    ...defaultTheme.colors,
    primary25: theme.colors['gray-4'],
    primary50: theme.colors['gray-4'],
    primary75: theme.colors['gray-4'],
    primary: theme.colors['gray-6'],
    dangerLight: theme.colors['gray-5'],
    danger: theme.colors['gray-7'],
  },
  spacing: {
    controlHeight: 30,
    menuGutter: 2,
    baseUnit: 3,
  },
})
