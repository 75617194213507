import moment from 'moment'

import { Modal } from 'antd'

import { useNotifyOrder } from 'common/hooks/use-notify-order'

import { useStores } from 'contractor/hooks/use-stores'

import { UseOrderActionsType, UseOrderInternalActions } from './useOrderActions'
import { UseOrderStatesType } from './useOrderStates'

type UsePlaceOrderActionProps = Pick<
  UseOrderInternalActions,
  'getRequiredOrderFields' | 'handleMaybeAddWatcher' | 'handleValidateProject' | 'handleLogError' | 'handleSaveOrder'
> &
  Pick<UseOrderActionsType, 'handleGoBack'>

export type UsePlaceOrderActionType = {
  handlePlaceOrder: () => Promise<void>
}

export const usePlaceOrderAction = (
  states: UseOrderStatesType,
  actions: UsePlaceOrderActionProps,
): UsePlaceOrderActionType => {
  const { orderStore, uploaderStore, integrationStore } = useStores()
  const { notifyOrder } = useNotifyOrder({ orderId: orderStore.selectedOrder?.id, operation: 'update' })

  const handlePlaceOrder = async () => {
    if (!uploaderStore.checkIfAllUploadsCompleted()) {
      Modal.error({ title: 'Uploads have not completed yet, please try again.' })
      return
    }
    const orderMaterials = orderStore.filteredOrderedMaterials()
    const errors = orderStore.validateRequiredFields(orderMaterials, actions.getRequiredOrderFields())
    if (errors.length) {
      Modal.error({ title: `Please fill in the following required fields: ${errors.join(', ')}.` })
      return
    }

    const projectErrors = actions.handleValidateProject()
    if (projectErrors.length) {
      Modal.error({ title: projectErrors[0] })
      return
    }

    states.setCountActionClicked(2)
    actions.handleMaybeAddWatcher()

    try {
      await actions.handleSaveOrder({ cancelled_at: null, ordered_at: moment().toISOString() }, false)

      if (states.syncOrderWithIntegration) {
        integrationStore.createOrder(orderStore.selectedOrder?.id)
      }

      states.setPurchaseOrderModalVisible(false)
      actions.handleGoBack()

      notifyOrder({ message: 'Placed order' })
    } catch (error) {
      actions.handleLogError(error, 'Unable to place the order.')
    } finally {
      states.setCountActionClicked(-1)
    }
  }

  return { handlePlaceOrder }
}
