import React from 'react'

import {
  ArrowLeftOutlined,
  ShopOutlined,
  ProjectOutlined,
  BorderlessTableOutlined,
  CalendarOutlined,
} from '@ant-design/icons'
import { Button, Space, Input, Typography, Tooltip, Divider, Form } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { CustomEditableContent } from 'common/components/CustomEditableContent'
import DateTimePicker from 'common/components/date_time_picker'
import { Visibility } from 'common/components/Visibility'
import { formatDateStringShort } from 'common/helpers/formatters'

import { SelectProject } from 'contractor/components/SelectProject'
import { useStores } from 'contractor/hooks/use-stores'
import { PriceSheet } from 'contractor/server/price_sheet'

import { PriceSheetStateTag, Actions } from '../components'
import { useEditPriceSheet } from '../context'

type PriceSheetEditProps = {
  priceSheet: PriceSheet
}

export const Header = observer<PriceSheetEditProps>(({ priceSheet }) => {
  const { projectStore } = useStores()

  const { form, goBack } = useEditPriceSheet()

  const nameFormValue = Form.useWatch('name', form)
  const projectIdFormValue = Form.useWatch('projectId', form)
  const numberFormValue = Form.useWatch('number', form)
  const startAtFormValue = Form.useWatch('startAt', form)
  const endAtFormValue = Form.useWatch('endAt', form)

  const project = projectStore.projects.find((project) => project.id === projectIdFormValue)
  const projectId = project?.project_id ? `(${project?.project_id})` : ''

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gridGap={10} mb={4}>
          <Button onClick={goBack} icon={<ArrowLeftOutlined />} shape="circle" type="text" size="small" />

          <CustomEditableContent
            boxProps={{ mt: 0, width: 'max-content' }}
            input={
              <Form.Item noStyle name="name">
                <Input style={{ width: 180 }} />
              </Form.Item>
            }
          >
            <Tooltip title="Price sheet Name" placement="bottom">
              <Space>
                <Typography.Title level={4} style={{ margin: 0 }}>
                  {nameFormValue || 'Price sheet name'}
                </Typography.Title>
              </Space>
            </Tooltip>
          </CustomEditableContent>

          <PriceSheetStateTag stateName={priceSheet?.workflow_state} />
        </Box>

        <Visibility.Hidden breakpoint="md">
          <Actions priceSheetState={priceSheet?.workflow_state} withSpace />
        </Visibility.Hidden>
      </Box>

      <Space style={{ fontSize: 12, flexFlow: 'wrap' }}>
        <Tooltip title="Vendor" placement="bottom">
          <Space>
            <ShopOutlined />
            <Typography.Text type="secondary">
              {priceSheet?.company_vendor?.vendor?.name || priceSheet?.company_vendor?.vendor_name}
            </Typography.Text>
          </Space>
        </Tooltip>

        <Divider type="vertical" />

        <CustomEditableContent
          boxProps={{ mt: 0 }}
          input={
            <Form.Item noStyle name="projectId">
              <SelectProject allowClear style={{ minWidth: 180 }} />
            </Form.Item>
          }
        >
          <Tooltip title="Project" placement="bottom">
            <Space>
              <ProjectOutlined />
              <Typography.Text type="secondary">
                {project ? `${project?.name} ${projectId}` : 'Select project'}
              </Typography.Text>
            </Space>
          </Tooltip>
        </CustomEditableContent>

        <Divider type="vertical" />

        <CustomEditableContent
          boxProps={{ mt: 0 }}
          input={
            <Form.Item noStyle name="number">
              <Input style={{ minWidth: 120 }} />
            </Form.Item>
          }
        >
          <Tooltip title="Number" placement="bottom">
            <Space>
              <BorderlessTableOutlined />
              <Typography.Text type="secondary">{numberFormValue || 'N/A'}</Typography.Text>
            </Space>
          </Tooltip>
        </CustomEditableContent>

        <Divider type="vertical" />

        <CustomEditableContent
          boxProps={{ mt: 0 }}
          input={
            <Form.Item noStyle name="startAt">
              <DateTimePicker wrapperProps={{ minWidth: 100 }} />
            </Form.Item>
          }
        >
          <Tooltip title="Start at" placement="bottom">
            <Space>
              <CalendarOutlined />
              <Typography.Text type="secondary">{formatDateStringShort(startAtFormValue, 'N/A')}</Typography.Text>
            </Space>
          </Tooltip>
        </CustomEditableContent>

        <Divider type="vertical" />

        <CustomEditableContent
          boxProps={{ mt: 0 }}
          input={
            <Form.Item noStyle name="endAt">
              <DateTimePicker wrapperProps={{ minWidth: 100 }} />
            </Form.Item>
          }
        >
          <Tooltip title="End at" placement="bottom">
            <Space>
              <CalendarOutlined />
              <Typography.Text type="secondary">{formatDateStringShort(endAtFormValue, 'N/A')}</Typography.Text>
            </Space>
          </Tooltip>
        </CustomEditableContent>
      </Space>
    </>
  )
})
