import React from 'react'

import { Button, Modal, Typography } from 'antd'

interface RfqsBlockedModalUnlockedProps {
  onCloseModalOrderUnlocked?: () => void
  modalOrderUnlockedOpened?: boolean
  onUnlock?: () => void
}

export const RfqsBlockedModalUnlocked = ({
  onCloseModalOrderUnlocked,
  modalOrderUnlockedOpened,
  onUnlock,
}: RfqsBlockedModalUnlockedProps) => {
  return (
    <Modal
      title="Confirm Unlock"
      open={modalOrderUnlockedOpened}
      footer={[
        <Button key="cancel" onClick={onCloseModalOrderUnlocked}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={onUnlock}>
          OK
        </Button>,
      ]}
      onCancel={onCloseModalOrderUnlocked}
      width={500}
    >
      <Typography.Paragraph>
        This will make the people currently editing <strong>lose all unsaved changes</strong> and get locked from the{' '}
        all RFQs instead.
      </Typography.Paragraph>
    </Modal>
  )
}
