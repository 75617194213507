import React, { useState } from 'react'

import styled from '@emotion/styled'

import { Typography, Pagination, Tag } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX } from 'common/components/boxes'
import OrderState from 'common/components/statuses/order_state'
import { currencyFormatter, formatDateString } from 'common/helpers/formatters'

import { useStores } from 'contractor/hooks/use-stores'
import { OrderMaterial } from 'contractor/server/company_materials'

const ListStyled = styled.a`
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors['gray-4']};
  transition: background 0.3s;
  cursor: pointer;
  padding: 10px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors['gray-2']};
  }
`
const MaterialHistoryBox = styled(Box)`
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
  width: 100%;
`
const style = { padding: '0 4px' }

type MaterialHistoryListProps = {
  extraAction?: (item: OrderMaterial) => React.ReactNode
  items: OrderMaterial[]
  type: 'quote' | 'order'
}

const getDate = (orderMaterial: OrderMaterial, type: 'quote' | 'order') => {
  if (type === 'quote') {
    return orderMaterial.order.quote_provided_at || orderMaterial.order.quoted_at
  }

  return orderMaterial.order.created_at
}

const ListItem = ({ orderMaterial, extraAction, phaseEnabled, classEnabled, type }) => {
  const date = getDate(orderMaterial, type)

  return (
    <ListStyled href={`/order/${orderMaterial.order.id}`} target="_blank" key={`${type}-item-${orderMaterial.id}`}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" py="8" width={{ _: '100%', md: '200px' }}>
        <Typography.Text>{formatDateString(date)}</Typography.Text>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          {orderMaterial.order?.company_vendor?.safe_globalized_vendor_name}
        </Typography.Text>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="flex-start" width={{ _: '100%', md: '220px' }}>
        <Typography.Text>{orderMaterial.order.project.name}</Typography.Text>
        <OrderState size="small" state={orderMaterial.order.state} />
      </Box>

      <Box display="flex" flexDirection="column" alignItems="flex-start" py="8" width={{ _: '100%', md: '180px' }}>
        <Typography.Text>{currencyFormatter(orderMaterial.unit_cost)}</Typography.Text>
        <FlexBoxX alignItems="flex-start" justifyContent="flex-start">
          {phaseEnabled && !!orderMaterial.cost_code ? (
            <Tag style={style}>{orderMaterial.cost_code?.phase_code || 'N/A'}</Tag>
          ) : (
            ''
          )}
          {orderMaterial.cost_code?.code && <Tag style={style}>{orderMaterial.cost_code?.code}</Tag>}
          {classEnabled && !!orderMaterial.cost_code ? (
            <Tag style={style}>{orderMaterial.cost_code?.clazz || 'N/A'}</Tag>
          ) : (
            ''
          )}
        </FlexBoxX>
      </Box>

      {extraAction?.(orderMaterial)}
    </ListStyled>
  )
}

export const MaterialHistoryList = observer<MaterialHistoryListProps>((props) => {
  const { extraAction, items = [], type } = props

  const { companySettingStore } = useStores()

  const [currentPage, setCurrentPage] = useState(1)

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const itemsPerPage = 10
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentQuoteMaterials = items.slice(indexOfFirstItem, indexOfLastItem)

  const handlePageChange = (page) => setCurrentPage(page)

  return (
    <>
      <MaterialHistoryBox>
        {currentQuoteMaterials.map((orderMaterial) => (
          <ListItem
            key={`${type}-item-${orderMaterial.id}`}
            extraAction={extraAction}
            orderMaterial={orderMaterial}
            phaseEnabled={costCodeSettings?.phase_code_enabled && !costCodeSettings?.phase_code_enabled}
            classEnabled={costCodeSettings?.class_enabled}
            type={type}
          />
        ))}
      </MaterialHistoryBox>

      <Pagination
        current={currentPage}
        pageSize={itemsPerPage}
        total={items.length}
        onChange={handlePageChange}
        showSizeChanger={false}
      />
    </>
  )
})
