import React from 'react'

import _ from 'lodash'

import { Checkbox } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'

import { CostCodeEditorCell, CostCodeViewerCell } from './CostCodeCell'
import { CurrencyEditorCell, CurrencyViewerCell } from './CurrencyCell'
import { DescriptionEditorCell, DescriptionViewerCell } from './DescriptionCell'
import { UnitsEditorCell } from './units_editor_cell'
import { VendorResponseEditorCell, VendorResponseViewerCell } from './VendorResponseCell'

export interface Column {
  id: string
  header?: string
  accessor?: string
  DataEditor?: React.ReactNode
  DataViewer?: React.ReactNode
  readOnly?: boolean
  description?: string
  width?: string | number
  minWidth?: string | number
  maxWidth?: string | number
}

// ALL possible columns are represented here, component making use of table responsible for filtering
// Based on company materials + order material columns

// All possible columns are divided in 3 parts (first, middle and last columns) because it makes it easier to sort and filter only the allowed middle columns
// First columns -> Collection with the first static columns
const firstColumns: Column[] = [
  {
    id: 'approve',
    accessor: 'approve',
    width: 70,
    minWidth: 70,
    maxWidth: 70,
  },
  {
    id: 'description',
    header: 'Description',
    width: '300%', // Make this as large as possible
    accessor: 'company_material.description',
    DataEditor: DescriptionEditorCell,
    DataViewer: DescriptionViewerCell,
    minWidth: '200px',
    maxWidth: '300px',
  },
  {
    id: 'quantity',
    header: 'Quantity',
    accessor: 'quantity',
    minWidth: '85px',
  },
]

// Middle Columns -> Collection with the filterable/orderable columns
const orderableColumns: Column[] = [
  {
    id: 'unit',
    header: 'Unit',
    accessor: 'unit',
    description: 'Unit of measure',
    DataEditor: UnitsEditorCell,
    DataViewer: ({ cell }) => <Box p={4}>{cell.value?.label}</Box>,
  },
  {
    id: 'cost_code_id',
    header: 'Cost Code',
    accessor: 'cost_code',
    DataEditor: CostCodeEditorCell,
    DataViewer: CostCodeViewerCell,
    minWidth: '200px',
  },
  {
    id: 'cost_code_phase_id',
    header: 'Phase Code',
    accessor: 'cost_code_phase',
    DataEditor: CostCodeEditorCell,
    DataViewer: CostCodeViewerCell,
    minWidth: '200px',
  },
  {
    id: 'product_identifier',
    header: 'Product ID',
    accessor: 'company_material.product_identifier',
    description: 'Specific product id e.g. XYZ123',
  },
  {
    id: 'manufacturer',
    header: 'Manufacturer',
    accessor: 'company_material.manufacturer',
    description: 'Brand or manufacturer of the product',
  },
  {
    id: 'group',
    header: 'Group',
    accessor: 'company_material.group',
    description: 'Group of material',
  },
  {
    id: 'sub_group',
    header: 'Sub-Group',
    accessor: 'company_material.sub_group',
    description: 'Sub-group of material',
  },
  {
    id: 'size',
    header: 'Size',
    accessor: 'company_material.size',
  },
  {
    id: 'connection_type',
    header: 'Connection Type',
    accessor: 'company_material.connection_type',
  },
  {
    id: 'material',
    header: 'Material',
    accessor: 'company_material.material',
  },
]

// Last Columns -> Collection with the last static columns
const lastColumns: Column[] = [
  {
    id: 'unit_cost',
    header: 'Unit Cost',
    accessor: 'unit_cost',
    DataEditor: CurrencyEditorCell,
    DataViewer: CurrencyViewerCell,
    description: 'Unit cost of product',
    minWidth: '100px',
  },
  {
    id: 'extended_cost',
    header: 'Ext. Cost',
    accessor: 'extended_cost',
    DataViewer: (props) => <CurrencyViewerCell {...props} maximumFractionDigits={2} />,
    description: 'Ext. cost of row',
    minWidth: '100px',
  },
  {
    id: 'tax_value',
    header: 'Tax',
    accessor: 'tax_value',
    DataEditor: CurrencyEditorCell,
    DataViewer: CurrencyViewerCell,
    minWidth: '100px',
  },
  {
    id: 'ext_cost_with_tax',
    header: 'Ext. Cost w/ Tax',
    accessor: 'ext_cost_with_tax',
    DataEditor: CurrencyEditorCell,
    DataViewer: CurrencyViewerCell,
    minWidth: '100px',
  },
  {
    id: 'vendor_response',
    header: 'Vendor Response',
    accessor: 'vendor_response',
    DataEditor: VendorResponseEditorCell,
    DataViewer: VendorResponseViewerCell,
  },
  {
    id: 'vendor_notes',
    header: 'Vendor Notes',
    accessor: 'vendor_note',
    description: 'Notes from supplier',
  },
]

const selectColumn = {
  id: 'select',
  accessor: 'select',
  DataEditor: ({ cell, onChange }) => {
    return <Checkbox checked={cell.value} onChange={(e) => onChange({ ...cell, value: e.target.checked })} />
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  DataViewer: observer<any>(({ cell, setCellData }) => {
    return (
      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        <Checkbox
          checked={cell?.materialRow?.select}
          onChange={(e) => {
            setCellData({ ...cell, value: e.target.checked })
          }}
        />
      </Box>
    )
  }),
  description: 'Select row',
  width: '25px',
  minWidth: '25px',
  maxWidth: '25px',
}

// Get only the columns filtered and ordered by the company attributes
export const getFilteredColumns = (companyColumns: string[]) => {
  const filteredColumns = _.filter(orderableColumns, (x) => companyColumns.includes(x.id))
  const orderedColumns = _.sortBy(filteredColumns, (x) => companyColumns.indexOf(x.id))

  return [...firstColumns, ...orderedColumns, ...lastColumns]
}

// Get all the possible columns
export const allColumns = [selectColumn, ...firstColumns, ...orderableColumns, ...lastColumns]

// TODO: Use descriptions from columns for helper text
// HACK: Making all the columns 100% except description which is 300%
export const ColumnHeader: React.FC<{
  columnIndex: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  label: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filteredColumns: any
}> = ({ columnIndex, label, filteredColumns }) => {
  return (
    <th
      className="Spreadsheet__header"
      style={{
        width: _.get(filteredColumns, `[${columnIndex}].width`, '100%'),
        maxWidth: _.get(filteredColumns, `[${columnIndex}].maxWidth`),
        minWidth: _.get(filteredColumns, `[${columnIndex}].minWidth`, '120px'),
      }}
    >
      {label}
    </th>
  )
}
