import axios from 'axios'

import {
  BasicRelationship,
  ListParams,
  MappingSuggestion,
  Relationship,
  SearchParams,
  UpdateMapping,
} from './integration'

export type UpdateCompanyMaterial = UpdateMapping

export interface CompanyMaterial {
  id: string
  description: string
  material?: string
  manufacturer?: string
  product_identifier?: string
  group?: string
  external_relationships: BasicRelationship[]
  mapping_suggestions?: MappingSuggestion[]
}

export function company_materials_relationships(params?: ListParams) {
  return axios.get<Relationship[]>(`/integrations/accounting/company_materials/relationships`, { params })
}
export function company_materials(params: ListParams) {
  return axios.get<CompanyMaterial[]>(`/integrations/accounting/company_materials`, { params })
}
export function company_materials_count(mappingDirection: string, params?: SearchParams) {
  const queryParams = params
    ? { ...params, mapping_direction: mappingDirection }
    : { mapping_direction: mappingDirection }
  return axios.get<{ [key: string]: number }>(`/integrations/accounting/company_materials/count`, {
    params: queryParams,
  })
}
export function update_company_material(company_material: UpdateCompanyMaterial) {
  return axios.patch(`/integrations/accounting/company_materials/${company_material?.id}`, company_material)
}
export function resync_company_materials() {
  return axios.patch('/integrations/accounting/company_materials/resync')
}
