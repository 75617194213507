import React from 'react'

import styled from '@emotion/styled'

import { Typography, Table, Tooltip } from 'antd'

import { currencyFormatter } from 'common/helpers/formatters'
import { calcExtCost } from 'common/helpers/order'
import { OrderMaterial } from 'common/server/orders'

import { ExpandButton } from 'contractor/pages/Invoices/Detail/@v2/InvoiceMaterials/expand_button'
import { ShowOrderResponse } from 'contractor/server/orders'

type TableItemsProps = {
  expandedRowKeys: React.Key[]
  onToggleExpandRowKeys: () => void
  setExpandedRowKeys: (keys: React.Key[]) => void
  order: ShowOrderResponse
}

const TableStyled = styled(Table)`
  .ant-table-expanded-row {
    .ant-table.ant-table-small {
      margin: -8px -8px -8px 28px !important;
    }

    .ant-table-row:hover,
    .ant-table-cell {
      background: ${({ theme }) => theme.colors['gray-2']} !important;
      color: #00000082;
    }
  }

  .ant-table-row-expand-icon-cell {
    text-align: center;
  }
`

export const TableItems = (props: TableItemsProps) => {
  const { expandedRowKeys, onToggleExpandRowKeys, setExpandedRowKeys, order } = props

  const dataSource = order?.order_materials

  return (
    <TableStyled
      rowKey="id"
      columns={[
        {
          title: 'Description',
          dataIndex: ['company_material', 'description'],
        },
        {
          title: 'Qty',
          dataIndex: 'quantity',
          width: 75,
          align: 'right',
        },
        {
          title: 'UOM',
          dataIndex: 'unit',
          width: 70,
          render: (_, row: OrderMaterial) => {
            const unit = row?.company_material?.unit?.name || row?.company_material?.unit_name
            return unit
          },
        },
        {
          title: 'Unit Cost',
          dataIndex: 'unit_cost',
          width: 100,
          align: 'right',
          render: (value) => currencyFormatter(value, 2),
        },
        {
          title: 'Ext. Cost',
          dataIndex: 'extended_price',
          width: 100,
          align: 'right',
          render: (_, row: OrderMaterial) => {
            const extendedCost = calcExtCost({
              unitCost: Number(row?.unit_cost),
              quantity: row?.quantity,
              multiplier: row.company_material?.unit?.multiplier,
              qtyIncrement: row.company_material?.unit?.qty_increment,
            })
            return currencyFormatter(extendedCost, 2)
          },
        },
        {
          title: 'Invoiced',
          dataIndex: 'invoiced',
          width: 150,
          align: 'right',
          render: (_, row: OrderMaterial) => {
            const allExtendedCosts = row?.invoice_materials?.reduce((acc, invoiceMaterial) => {
              return acc + Number(invoiceMaterial?.extended_price)
            }, 0)

            return currencyFormatter(allExtendedCosts, 2)
          },
        },
        {
          title: 'Remaining',
          dataIndex: 'remaining',
          width: 100,
          align: 'right',
          render: (_, row: OrderMaterial) => {
            const allExtendedCosts = row?.invoice_materials?.reduce((acc, invoiceMaterial) => {
              return acc + Number(invoiceMaterial?.extended_price)
            }, 0)

            const extendedCost = calcExtCost({
              unitCost: Number(row?.unit_cost),
              quantity: row?.quantity,
              multiplier: row.company_material?.unit?.multiplier,
              qtyIncrement: row.company_material?.unit?.qty_increment,
            })

            const remaining = extendedCost - allExtendedCosts

            return <Typography.Text>{currencyFormatter(remaining, 2)}</Typography.Text>
          },
        },
        Table.EXPAND_COLUMN,
      ]}
      dataSource={dataSource}
      pagination={false}
      size="small"
      expandable={{
        columnTitle: () => {
          if (dataSource?.some((data) => data?.invoice_materials?.length)) {
            return (
              <ExpandButton
                onToggleExpandRowKeys={onToggleExpandRowKeys}
                hasExpandedRowKeys={!!expandedRowKeys?.length}
              />
            )
          }
        },
        onExpandedRowsChange: (expandedRows) => setExpandedRowKeys(expandedRows as React.Key[]),
        expandedRowKeys,
        expandRowByClick: true,
        expandedRowRender: (row: OrderMaterial) => (
          <Table
            showHeader={false}
            pagination={false}
            size="small"
            rowKey={(row) => row?.id}
            dataSource={row?.invoice_materials}
            columns={[
              {
                dataIndex: 'description',
              },
              {
                dataIndex: 'quantity_shipped',
                width: 75,
                align: 'right',
              },
              {
                dataIndex: 'uom',
                width: 70,
              },
              {
                title: 'Unit Cost',
                dataIndex: 'unit_price',
                width: 100,
                align: 'right',
                render: (value) => currencyFormatter(value, 2),
              },
              {
                title: 'Ext. Cost',
                dataIndex: 'extended_price',
                width: 100,
                align: 'right',
                render: (value) => currencyFormatter(value, 2),
              },
              {
                title: 'Invoiced',
                dataIndex: 'invoiced',
                width: 150,
                align: 'right',
                render: (_, row) => {
                  return (
                    <Tooltip title="Invoice number">
                      <Typography.Link
                        href={`/invoice/${row?.invoice_id}`}
                        target="blank"
                        style={{ textDecoration: 'underline' }}
                      >
                        #{row?.invoice_number}
                      </Typography.Link>
                    </Tooltip>
                  )
                },
              },
              {
                dataIndex: 'space',
                key: 'space',
                width: 148,
              },
            ]}
          />
        ),
        rowExpandable: (row: OrderMaterial) => !!row?.invoice_materials?.length,
      }}
      style={{ minWidth: 850 }}
    />
  )
}
