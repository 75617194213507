import React, { useCallback, useState } from 'react'

import scrollIntoView from 'scroll-into-view-if-needed'

import { Col, Form, Input, Row, Select } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Card } from 'common/components/Card'

import { useStores } from 'contractor/hooks/use-stores'
import { UserMessages } from 'contractor/pages/PublicOrderForm/user_messages'

import { usePublicOrderForm } from './context'

const colProps = { xs: 24, md: 12 }
const colLayoutProps = { xs: 24, md: 12 }

export const UserInfo = observer(() => {
  const { publicOrderStore } = useStores()
  const { user, form, setUser, setProject } = usePublicOrderForm()
  const { users: allUsersFromCompany = [] } = publicOrderStore.formParams

  const [userOptions, setUserOptions] = useState(allUsersFromCompany.map((user) => user.email))

  const handleUserSearch = (searchValue) => {
    if (searchValue) {
      const foundUsers = allUsersFromCompany.filter((user) => user?.email?.includes(searchValue))
      if (foundUsers.some((user) => user.email == searchValue)) {
        setUserOptions(foundUsers.map((user) => user.email))
      } else {
        setUserOptions([...foundUsers.map((user) => user.email), searchValue])
      }
    } else {
      setUserOptions(allUsersFromCompany.map((user) => user.email))
    }
  }

  const handleUserChange = (selected) => {
    if (!selected) {
      form.setFieldsValue({
        email: undefined,
        first_name: '',
        last_name: '',
        projectId: undefined,
        orderPackageName: '',
      })
      setUser(undefined)
      setProject(undefined)
      setUserOptions(allUsersFromCompany.map((user) => user.email))
    }
  }

  const disableNameInputs = useCallback(() => user !== undefined, [user])

  return (
    <Card>
      <Row gutter={16}>
        <Col {...colLayoutProps}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Email is required' },
                  { type: 'email', message: 'The input is not a valid e-mail' },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="example@email.com"
                  showArrow={false}
                  onSearch={handleUserSearch}
                  onChange={handleUserChange}
                  aria-label="user-select"
                  autoFocus
                  onSelect={() => {
                    scrollIntoView(document.getElementById('order-details'), { behavior: 'smooth' })
                  }}
                >
                  {userOptions.map((email) => (
                    <Select.Option key={email} value={email}>
                      {email}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col {...colProps}>
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[{ required: true, message: 'First Name is required' }]}
              >
                <Input style={{ width: '100%' }} data-cy="first-name" type="text" disabled={disableNameInputs()} />
              </Form.Item>
            </Col>
            <Col {...colProps}>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[{ required: true, message: 'Last Name is required' }]}
              >
                <Input style={{ width: '100%' }} data-cy="last-name" type="text" disabled={disableNameInputs()} />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col {...colLayoutProps} style={{ textAlign: 'center' }}>
          <Box display={{ _: 'none', md: 'flex' }} flexDirection="column" height="100%" justifyContent="center">
            <UserMessages />
          </Box>
        </Col>
      </Row>
    </Card>
  )
})
