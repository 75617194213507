import React from 'react'

import { Tag } from 'antd'

import { Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'

import { ConsolidatedOrderMaterials } from 'contractor/server/order_materials'

type CellPreferredVendorsProps = {
  prices: Array<ConsolidatedOrderMaterials.PreferredVendorPrice>
}

export const CellPreferredVendors = (props: CellPreferredVendorsProps) => {
  const { prices = [] } = props

  if (!prices.length) {
    return null
  }

  return (
    <Box style={{ gap: 4 }} display="flex" flexWrap="wrap">
      {prices?.map(({ vendor_name, price }, index) => (
        <Tag key={index} style={{ margin: 0 }}>{`${vendor_name}: ${currencyFormatter(price)}`}</Tag>
      ))}
    </Box>
  )
}
