import axios from 'axios'

import { Comment } from 'common/server/comment'

import { ShowOrderResponse } from 'contractor/server/orders'

import { Vendor, VendorUser } from './vendors'

export interface ShowOrderPackageResponse {
  id: string
  name: string
  orders: ShowOrderResponse[]
  comments: Comment[]
  updated_at: string
  temp_company_vendors?: {
    id: string
    vendor_name: string
    vendor_contacts: {
      id: string
      first_name: string
      last_name: string
      email: string
      vendor_user?: VendorUser
    }[]
    comment?: string
    vendor?: Vendor
  }[]
}

export interface UpdateOrderPackageParams {
  id: string
  name?: string
  comment?: string
  order_materials?: {
    quantity: number
    unit_cost: string | number
    company_note?: string
    cost_code_id: string
    cost_code_phase_id?: string
    company_material_id?: string
  }[]
}

export interface GetOrderPackageParams {
  with_discarded?: boolean
}

export function getOrderPackage(id: string, params?: GetOrderPackageParams) {
  return axios.get<ShowOrderPackageResponse>(`/order_package/${id}`, { params })
}

export function updateOrderPackage(payload: UpdateOrderPackageParams) {
  return axios.patch<ShowOrderPackageResponse>(`/order_package/${payload['id']}`, payload)
}

export function deletePackage(id: string) {
  return axios.delete<void>(`/order_package/${id}`)
}

export function internal_comment(payload: { id: string; comment: string; user_ids: string[] }) {
  return axios.post<ShowOrderPackageResponse>(`/order_package/${payload['id']}/internal_comment`, payload)
}
