import React, { useEffect } from 'react'

import { Button, Modal, Typography } from 'antd'

import { useCountdown } from 'contractor/hooks/use-countdown'

interface OrderBlockedModalExpiredProps {
  label: string
  onCancelModalOrderExpired?: () => void
  modalOrderExpiredOpened?: boolean
  onRenew?: () => void
}

export const OrderBlockedModalExpired = ({
  label,
  onCancelModalOrderExpired,
  modalOrderExpiredOpened,
  onRenew,
}: OrderBlockedModalExpiredProps) => {
  const { time, start, reset } = useCountdown({ autoStart: true, initial: 120, onComplete: onCancelModalOrderExpired })

  useEffect(() => {
    modalOrderExpiredOpened ? start() : reset()
  }, [modalOrderExpiredOpened])

  return (
    <Modal
      title={`${label} Expired`}
      open={modalOrderExpiredOpened}
      footer={[
        <Button key="leave" onClick={onCancelModalOrderExpired}>
          {`Leave (${time})`}
        </Button>,
        <Button key="ok" type="primary" onClick={onRenew}>
          OK
        </Button>,
      ]}
      cancelText="Leave"
      width={500}
    >
      <Typography.Paragraph>
        The {label} will expire, and <strong>all unsaved changes will be lost</strong>, do you want to continue editing?
      </Typography.Paragraph>
    </Modal>
  )
}
