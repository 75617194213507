import React from 'react'

import { useReactToPrint } from 'react-to-print'

import { PrinterOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { useLeveling } from '../context'

export type HeaderProps = {
  goBack: (isRefresh?: boolean) => void
}

export const Print = () => {
  const { printAreaRef } = useLeveling()

  const handlePrint = useReactToPrint({
    content: () => printAreaRef.current,
  })

  return <Button icon={<PrinterOutlined />} onClick={handlePrint} />
}
