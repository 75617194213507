import * as React from 'react'

import moment from 'moment'

// TODO: Eventually change email address
export const SUPPORT_EMAIL_ADDRESS = 'help@subbase.io'

export const useComponentWillMount = (func) => {
  React.useMemo(func, [])
}

export const useForceUpdate = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_val, setValue] = React.useState(0) // integer state
  return () => setValue((value) => value + 1) // update the state to force render
}

export const nextWeekday = () => {
  const nextDay = moment().add(1, 'days')
  if (nextDay.day() === 0) {
    nextDay.add(1, 'days')
  } else if (nextDay.day() === 6) {
    nextDay.add(2, 'days')
  }
  return nextDay
}
