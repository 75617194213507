import React, { useState } from 'react'

import { Dropdown, MenuProps, message } from 'antd'

import { observer } from 'mobx-react-lite'

import {
  ReportIssue as ReportInvoiceInboxIssue,
  ReportIssueHistory as ReportInvoiceInboxIssueHistory,
} from 'contractor/components/InvoiceInboxDetailsDrawer'
import { WatchersDrawer } from 'contractor/components/WatchersDrawer'
import { makeCompanyUserWatcherOption } from 'contractor/components/WatchersSelect'
import { useStores } from 'contractor/hooks/use-stores'
import { InvoiceDiscardDrawer } from 'contractor/pages/Invoices/Detail/components'

import { InvoiceHistory } from '../components/invoice_history'
import { useInvoice } from '../context'
import { SourceFrom } from './source_from'

export const DropdownActions = observer(() => {
  const { invoiceStore, userStore, companySettingStore } = useStores()

  const { setSelectedInvoiceDirty, lockInvoice } = useInvoice()

  const [isHistoryDrawerVisible, toggleHistoryDrawerVisible] = useState(false)
  const [isInvoiceDiscardDrawerVisible, toggleInvoiceDiscardDrawerVisible] = useState(false)
  const [isIssueModalVisible, toggleIssueModalVisible] = useState(false)
  const [isIssueHistoryModal, togglepenIssueHistoryVisible] = useState(false)

  const { invoice } = invoiceStore

  const handleSendIssue = async (issueMessage: string) => {
    try {
      await invoiceStore.reportIssue(invoice.id, {
        issue_message: issueMessage,
        invoice_inbox_attachment_id: invoiceStore.invoice?.invoice_inbox_attachment?.id,
      })
      message.success('Thanks for your issue report! Our team will take care of it ASAP.')
    } catch (error) {
      message.error(error?.response?.data?.error || 'Error while sending the issue, try again later.')
    } finally {
      toggleIssueModalVisible(false)
    }
  }

  const getDropdownActions = () => {
    const canViewSourceFrom =
      userStore.canViewAllInvoices ||
      userStore.canViewInvoiceInbox ||
      userStore.canViewAllMyProjectInvoices ||
      userStore.canEditInvoices

    const actions: MenuProps['items'] = [
      {
        label: 'History',
        key: 'history',
        onClick: () => toggleHistoryDrawerVisible(true),
      },
    ]

    if (invoiceStore.invoice?.invoice_inbox?.has_reported_issue) {
      actions.push({
        label: 'Issue History',
        key: 'issue-history',
        onClick: () => togglepenIssueHistoryVisible(true),
      })
    } else {
      actions.push({
        label: 'Report Issue',
        key: 'report-issue',
        onClick: () => toggleIssueModalVisible(true),
      })
    }

    actions.push({
      label: (
        <WatchersDrawer
          boxProps={{
            width: 'auto',
            p: 0,
            height: 'auto',
            border: 'none',
            display: 'block',
          }}
          value={companySettingStore.possibleUsers
            .filter((user) => invoice?.watcher_ids?.includes(user.company_user_id))
            .map((user) => makeCompanyUserWatcherOption(user))}
          onChange={(watchers = []) => {
            invoice.watcher_ids = watchers?.map((watcher) => watcher.value)
            setSelectedInvoiceDirty(true)
          }}
          isDisabled={!lockInvoice.canEditInvoice}
          makeOption={makeCompanyUserWatcherOption}
        >
          Watchers
        </WatchersDrawer>
      ),
      key: 'watchers',
    })

    if (canViewSourceFrom) {
      actions.push({
        label: <SourceFrom />,
        key: 'source-from',
      })
    }

    if (userStore.canDeleteInvoices) {
      actions.push({
        label: 'Delete Invoice',
        danger: true,
        onClick: () => toggleInvoiceDiscardDrawerVisible(true),
        key: 'delete',
        disabled: !lockInvoice.canEditInvoice,
      })
    }

    return actions
  }

  return (
    <>
      <InvoiceHistory onClose={() => toggleHistoryDrawerVisible(false)} open={isHistoryDrawerVisible} />

      <InvoiceDiscardDrawer
        onClose={() => toggleInvoiceDiscardDrawerVisible(false)}
        open={isInvoiceDiscardDrawerVisible}
      />

      <ReportInvoiceInboxIssue
        open={isIssueModalVisible}
        onClose={() => toggleIssueModalVisible(false)}
        onSendIssue={(_, issueMessage) => handleSendIssue(issueMessage)}
      />

      <ReportInvoiceInboxIssueHistory
        invoiceInboxId={invoice?.invoice_inbox_id}
        filters={{ invoice_inbox_attachment_id: invoice?.invoice_inbox_attachment?.id }}
        open={isIssueHistoryModal}
        onClose={() => togglepenIssueHistoryVisible(false)}
      />

      <Dropdown.Button
        menu={{ items: getDropdownActions() }}
        trigger={['click']}
        buttonsRender={([_, rightButton]) => [null, React.cloneElement(rightButton as React.ReactElement)]}
        style={{ width: 'auto' }}
      />
    </>
  )
})
