import React from 'react'

import { observer } from 'mobx-react-lite'

import { Drawer, DrawerProps, DrawerBody } from 'common/components/Drawer'
import { ErrorBoundary } from 'common/components/ErrorBoundary/error_boundary'
import { useQuery } from 'common/hooks/use-query'

import { InvoiceHistory as InvoiceHistoryComponent } from 'contractor/components/@v2/InvoiceHistory/history'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

type InvoiceHistoryProps = Partial<DrawerProps>

const Content = () => {
  const { invoiceStore, companySettingStore, userStore } = useStores()
  const canUseRetainage = useFlag('retainage_v1')

  const { isLoading } = useQuery(
    () => invoiceStore.getInvoiceHistory(invoiceStore.invoice?.id),
    [invoiceStore.invoice?.id],
  )

  const showCostCode =
    userStore.canUseCostCode &&
    companySettingStore.companyMaterialConfiguration.company_attributes.includes('cost_code_id')
  const showPhaseCode = companySettingStore.otherSettings?.cost_code_settings?.independent_phase_codes_enabled

  const optionalColumns = [
    ...(canUseRetainage ? ['retainage_type', 'retainage_value'] : []),
    ...(showCostCode ? ['cost_code_id'] : []),
    ...(showPhaseCode ? ['cost_code_phase_id'] : []),
  ]

  return (
    <InvoiceHistoryComponent
      isLoading={isLoading}
      data={invoiceStore.invoiceHistory}
      optionalColumns={optionalColumns}
    />
  )
}

export const InvoiceHistory = observer<InvoiceHistoryProps>((props) => {
  const { invoiceStore } = useStores()

  return (
    <Drawer closable width={580} title={`Invoice #${invoiceStore.invoice?.number} History`} {...props}>
      <DrawerBody>
        <ErrorBoundary isFull={false}>
          <Content />
        </ErrorBoundary>
      </DrawerBody>
    </Drawer>
  )
})
