import React from 'react'

import { Copy } from '@styled-icons/zondicons/Copy'
import { InvoiceMaterial, InvoiceMaterialGroup } from 'javascript/common/server/invoice'

import styled from '@emotion/styled'

import { Button, Checkbox, Input, Select, Tooltip } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'
import { Drawer } from 'common/components/Drawer'
import theme from 'common/styles/theme'

import { useInvoice } from '../../context'
import { InvoiceItem } from '../InvoiceItem'

const RenderItemsContainer = styled(Box)`
  min-height: calc(100% - 64px - 55px);

  & > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors['gray-4']};
  }
`

const CreateGroupItemContent = ({ group, onClose }: { group?: InvoiceMaterialGroup; onClose: () => void }) => {
  const { calcExtCost, invoiceMaterialsFiltered, handleSaveInvoiceMaterialsGroup } = useInvoice()

  const [groupName, setGroupName] = React.useState(group?.name || '')

  const getInitialOptions = () => {
    if (group?.invoice_materials) return group.invoice_materials
    const invoicesChecked = invoiceMaterialsFiltered.materialsFilteredBySearch.filter((material) => material.checked)
    if (invoicesChecked.length === 0) return invoiceMaterialsFiltered.materialsFilteredBySearch
    return invoicesChecked
  }

  const [invoiceMaterials, setInvoiceMaterials] = React.useState(getInitialOptions())

  const invoicesItems = React.useMemo(() => {
    return invoiceMaterialsFiltered.materials
      .filter((item) => !invoiceMaterials.find((invoiceMaterial) => invoiceMaterial.id === item.id))
      .map((invoiceMaterial) => ({
        label: invoiceMaterial.description,
        value: invoiceMaterial.id,
      }))
  }, [invoiceMaterials, invoiceMaterialsFiltered])

  const allInvoiceMaterialsIsChecked = React.useMemo(() => {
    return !!invoiceMaterials?.every((item) => item.checked === true)
  }, [invoiceMaterials])

  const toggleAllInvoiceMaterialsChecked = () => {
    setInvoiceMaterials(invoiceMaterials.map((item) => ({ ...item, checked: !allInvoiceMaterialsIsChecked })))
  }

  const handleCheckInvoiceMaterials = (invoiceMaterial: InvoiceMaterial) => {
    const newInvoiceMaterials = invoiceMaterials.map((item) => {
      if (item.id === invoiceMaterial.id) return { ...item, checked: !item.checked }
      return item
    })

    setInvoiceMaterials(newInvoiceMaterials)
  }

  const renderItems = React.useMemo(() => {
    return (
      <RenderItemsContainer>
        {invoiceMaterials.map((item, idx) => (
          <Box key={idx} padding={12}>
            <InvoiceItem
              item={{
                id: item.id,
                description: item.description,
                quantity_shipped: item.quantity_shipped,
                uom: item.uom,
                unit_price: item.unit_price,
                calcExtCost,
                checked: item.checked,
              }}
              onCheck={() => handleCheckInvoiceMaterials(item)}
            />
          </Box>
        ))}
      </RenderItemsContainer>
    )
  }, [calcExtCost, invoiceMaterials, handleCheckInvoiceMaterials])

  const renderSearch = React.useMemo(() => {
    return (
      <FlexBoxX
        alignItems="center"
        pt={12}
        px={12}
        position="sticky"
        top={0}
        zIndex={1}
        paddingY={12}
        backgroundColor={theme.colors.white}
        style={{ gap: 16 }}
      >
        <Checkbox onChange={toggleAllInvoiceMaterialsChecked} checked={allInvoiceMaterialsIsChecked} />
        <Input
          placeholder="Group name"
          style={{ width: '50%' }}
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />

        <Select
          loading={false}
          showSearch
          onSelect={(invoiceMaterialId) => {
            const selectedMaterial = invoiceMaterialsFiltered.materials.find((item) => item.id === invoiceMaterialId)
            if (selectedMaterial) setInvoiceMaterials([...invoiceMaterials, { ...selectedMaterial, checked: true }])
          }}
          value={null}
          notFoundContent="Not found"
          options={invoicesItems}
          placeholder="Extracted items..."
          style={{ width: '50%' }}
          filterOption={(input, option) => {
            const inputValue = input.toLowerCase()
            return option?.label?.toLowerCase().includes(inputValue)
          }}
        />
      </FlexBoxX>
    )
  }, [toggleAllInvoiceMaterialsChecked, allInvoiceMaterialsIsChecked])

  const renderFooter = React.useMemo(() => {
    return (
      <FlexBoxX
        position="sticky"
        justifyContent="flex-end"
        paddingX={16}
        paddingY={10}
        bottom={0}
        style={{ gap: 16 }}
        backgroundColor={theme.colors.white}
        borderTop={`1px solid ${theme.colors['gray-4']}`}
      >
        <Button type="ghost" size="middle" style={{ width: 100 }} onClick={onClose}>
          Back
        </Button>
        <Button
          type="primary"
          size="middle"
          style={{ width: 100 }}
          onClick={() => {
            handleSaveInvoiceMaterialsGroup(
              groupName,
              invoiceMaterials.filter((invoiceMaterial) => invoiceMaterial?.checked),
              group?.id,
            )
            onClose()
          }}
        >
          Save
        </Button>
      </FlexBoxX>
    )
  }, [groupName, invoiceMaterials, onClose, group?.id])

  return (
    <Box width="100%" overflow="auto">
      {renderSearch}
      {renderItems}
      {renderFooter}
    </Box>
  )
}

export const CreateGroupItemDrawer = ({ isOpened, setIsOpened, group = null }) => {
  return (
    <Drawer
      onClose={() => setIsOpened(false)}
      open={isOpened}
      width={700}
      title="Group items"
      forceRender
      closable={false}
    >
      <Box width="100%" overflow="auto">
        {isOpened && <CreateGroupItemContent onClose={() => setIsOpened(false)} group={group} />}
      </Box>
    </Drawer>
  )
}

export const CreateGroupItem = ({ disabled }: { disabled?: boolean }) => {
  const [isOpened, setIsOpened] = React.useState(false)

  const TooltipRender = ({ children }: { children: React.ReactNode }) => {
    if (!disabled) return <>{children}</>
    return <Tooltip title="You cannot create new groups when a group is selected!">{children}</Tooltip>
  }

  return (
    <>
      <TooltipRender>
        <Button
          type="ghost"
          onClick={() => setIsOpened(true)}
          icon={<Copy style={{ width: 12, marginRight: 8 }} />}
          disabled={disabled}
        >
          Group items
        </Button>
      </TooltipRender>

      <CreateGroupItemDrawer isOpened={isOpened} setIsOpened={setIsOpened} />
    </>
  )
}
