import React from 'react'

import { message, Select, Tag } from 'antd'

import { observer } from 'mobx-react-lite'

import { useStores } from 'contractor/hooks/use-stores'
import { ManagedUser } from 'contractor/server/company_settings/manage_users'

export const GroupSelect = observer<ManagedUser>(({ id, project_group_ids, is_active, role }) => {
  const { userStore, projectGroupsStore, companySettingStore } = useStores()

  const handleSelect = async (selectedsGroupsIds) => {
    try {
      await companySettingStore.updateUser({ user_id: id, project_group_ids: selectedsGroupsIds, role_id: role.id })

      if (selectedsGroupsIds.length > project_group_ids.length) {
        message.success('Successfully added group')
      } else {
        message.success('Successfully removed group')
      }
    } catch {
      message.error('Unable to change group')
    }

    if (userStore.currentUser?.id === id) {
      userStore.showUser()
    }
  }

  if (userStore.canManageCompanySettings) {
    return (
      <Select
        disabled={!is_active}
        onChange={handleSelect}
        value={project_group_ids}
        mode="multiple"
        showArrow
        placeholder="Select a group"
        style={{ width: '100%' }}
      >
        {projectGroupsStore.projectGroups.map((group) => (
          <Select.Option key={group.id} value={group.id}>
            {group.name}
          </Select.Option>
        ))}
      </Select>
    )
  }

  return (
    <span>
      {projectGroupsStore.projectGroups
        .filter((grouo) => project_group_ids.includes(grouo?.id))
        .map((group) => (
          <Tag key={group.id}>{group.name}</Tag>
        ))}
    </span>
  )
})
