import React from 'react'

import { connectCurrentRefinements } from 'react-instantsearch-dom'

import FilterOutlined from '@ant-design/icons/FilterOutlined'
import { Badge, Button, Popover } from 'antd'
import { ButtonProps } from 'antd/lib/button'

import { useTheme } from 'common/hooks/use-theme'

type ShowFiltersButtonProps = {
  items: object[]
} & Pick<ButtonProps, 'onClick'>

const ShowFiltersButton = ({ items, onClick }: ShowFiltersButtonProps) => {
  const theme = useTheme()

  return (
    <Popover content="Options" placement="bottom">
      <Button data-cy="filters" onClick={onClick} style={{ display: 'flex', alignItems: 'center' }}>
        <FilterOutlined />
        {items.length > 0 && (
          <Badge
            count={items.length}
            style={{ marginLeft: '5px', background: theme.colors['gray-7'], color: theme.colors.white }}
          />
        )}
      </Button>
    </Popover>
  )
}

export const FilterButton = connectCurrentRefinements(ShowFiltersButton)
