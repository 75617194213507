import React from 'react'

import { Alert, Typography } from 'antd'

import { FlexBoxY } from 'common/components/boxes'

type ModalBodyProps = {
  isCorrelating: boolean
}

export const ModalBody = (props: ModalBodyProps) => {
  const { isCorrelating } = props

  if (isCorrelating) {
    return <Typography.Text>Auto-Reconciling, please wait.</Typography.Text>
  }

  return (
    <FlexBoxY alignItems="start">
      <Typography.Text>
        Subbase uses AI to auto match line items between the invoice and the order. Using this functionality will take
        some time, and, you won&apos;t be able to update the invoice meanwhile.
      </Typography.Text>
      <Alert
        style={{ marginTop: 10, width: '100%' }}
        message="All manual matches will be overridden."
        type="warning"
        showIcon
      />
    </FlexBoxY>
  )
}
