import axios from 'axios'

export interface OrderSessionOwner {
  id: string
  name: string
  orderId?: string
  expired?: boolean
  manually_blocked?: boolean
}

export interface UpdateOrderSessionBody {
  order_ids: string[]
  order_changed?: boolean
}

export interface UpdateOrderSessionResponse {
  ownerUser: OrderSessionOwner
}

export interface UnlockOrderSessionBody {
  order_ids: string[]
}

export interface UnlockSessionResponse {
  ownerUser: OrderSessionOwner
}

export interface ReleaseOrderSessionBody {
  order_ids: string[]
}

export function update_session(payload?: UpdateOrderSessionBody) {
  return axios.patch<UpdateOrderSessionResponse>('/order_session/update', payload)
}

export function unlock_session(payload?: UnlockOrderSessionBody) {
  return axios.patch<UnlockSessionResponse>('/order_session/unlock', payload)
}

export function release_session(payload?: ReleaseOrderSessionBody) {
  return axios.patch<void>('/order_session/release', payload)
}
