import React from 'react'

import { Button, notification } from 'antd'

import { useStores } from 'contractor/hooks/use-stores'

type Operation = 'create' | 'update'

type UseNotifyCompanyMaterial = {
  operation?: Operation
}

type NotifyParams = {
  companyMaterialId: string
}

export const useNotifyCompanyMaterial = ({ operation = 'create' }: UseNotifyCompanyMaterial = {}) => {
  const { companyMaterialStore } = useStores()

  const handleClick = ({ companyMaterialId }) => {
    notification.destroy()
    companyMaterialStore.selectMaterial(companyMaterialId)
  }

  const notifyCompanyVendor = ({ companyMaterialId }: NotifyParams) => {
    const message = operation === 'create' ? 'Created new material' : 'Updated material'
    const description = `Successfully ${
      operation === 'create' ? 'created' : 'updated'
    } material. Your changes will be shown in the table shortly.`

    notification.success({
      message,
      description,
      duration: 10,
      placement: 'bottomLeft',
      btn: (
        <Button type="primary" size="small" onClick={() => handleClick({ companyMaterialId })}>
          View material
        </Button>
      ),
    })
  }

  return { notifyCompanyVendor }
}
