import React, { useRef, useState } from 'react'

import { DollarOutlined } from '@ant-design/icons'
import { Card, message, Tabs, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import { InvoiceResponse } from 'common/server/invoice'

import { makeCompanyVendorOption } from 'contractor/components/SelectCompanyVendor'
import { useStores } from 'contractor/hooks/use-stores'
import { IndexCompanyVendor } from 'contractor/server/company_vendors'

import { OrderItem } from '../invoice_detail_original'
import { OrderPanel, OrderSearch } from '../OrderDetail'

type OrdersCardProps = {
  grandTotal?: number
  orderNumber?: string
  companyVendor?: InvoiceResponse['company_vendor']
  orderItems: OrderItem[]
  onChangeOrderItems: (orderItems: OrderItem[]) => void
  disabled?: boolean
}

export const OrdersCard = observer<OrdersCardProps>(
  ({ grandTotal, orderNumber, orderItems, companyVendor, onChangeOrderItems, disabled }) => {
    const { invoiceStore } = useStores()

    const [activeKey, setActiveKey] = useState('')

    const newTabIndex = useRef(1)

    const handleAddTab = () => {
      const newActiveKey = `tab-${newTabIndex.current++}`
      onChangeOrderItems([...orderItems, { label: 'Attach Order', key: newActiveKey, order_id: null }])
      setActiveKey(newActiveKey)
    }

    const handleRemoveTab = (targetKey) => {
      const newOrderItems = orderItems.filter((order) => order.key !== targetKey)
      onChangeOrderItems(newOrderItems)
      setActiveKey(newOrderItems[newOrderItems.length - 1]?.key)
    }

    const handleSelect = (orderSelected: { id: string; order_number: string }) => {
      if (!orderItems?.some((item) => item.order_id === orderSelected.id)) {
        onChangeOrderItems([
          ...orderItems.filter((item) => !!item.order_id),
          {
            label: orderSelected.order_number,
            key: orderSelected.id,
            order_id: orderSelected.id,
          },
        ])
      }
      setActiveKey(orderSelected.id)
    }

    const handleAfterCreateQuickPO = async (order: { id: string; order_number: string }) => {
      try {
        await invoiceStore.save({
          order_ids: [...orderItems.filter((item) => item.order_id).map((item) => item.order_id), order?.id],
        })
        handleSelect(order)
      } catch (error) {
        if (error?.response?.data?.error) {
          message.error(error.response.data.error)
        } else {
          message.error('Unable to save the invoice')
        }
      }
    }

    const tabOrderItems = orderItems.map((order) => ({
      label: order.label,
      key: order.key,
      children: order.order_id ? (
        <OrderPanel orderId={order.order_id} />
      ) : (
        <OrderSearch
          onSelect={handleSelect}
          onCreated={handleAfterCreateQuickPO}
          initialValues={{
            grandTotal,
            orderNumber,
            companyVendor: makeCompanyVendorOption(companyVendor as IndexCompanyVendor),
          }}
          disabled={disabled}
        />
      ),
    }))

    return (
      <Card
        id="orders-card"
        title={
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>Orders</Box>

            <Tooltip title="Grand Total" placement="bottom">
              <Typography.Text style={{ fontSize: 14, display: 'inline-flex', alignItems: 'center' }}>
                <DollarOutlined style={{ marginRight: 4 }} />
                {grandTotal ? currencyFormatter(grandTotal) : 'Unknown'}
              </Typography.Text>
            </Tooltip>
          </Box>
        }
        aria-label="orders-card"
        style={{ width: '50%', flexGrow: 1 }}
        bodyStyle={{ padding: '0', backgroundColor: '#fafafa', maxHeight: 'calc(100% - 57px)', overflow: 'auto' }}
      >
        <Tabs
          type={disabled ? 'card' : 'editable-card'}
          onChange={setActiveKey}
          activeKey={activeKey || orderItems[0]?.key}
          onEdit={(targetKey, action) => {
            if (action === 'add') {
              handleAddTab()
            } else {
              handleRemoveTab(targetKey)
            }
          }}
          items={tabOrderItems}
          style={{ width: '100%', padding: 10 }}
        />
      </Card>
    )
  },
)
