import React from 'react'

import { Form } from 'antd'

import { SelectUnit } from 'common/components/SelectUnit'

export const FieldUnit = (props) => (
  <Form.Item name="unit" label="Unit">
    <SelectUnit allowClear {...props} labelInValue />
  </Form.Item>
)
