import React from 'react'

import { Skeleton } from 'antd'

import { FlexBoxY } from 'common/components/boxes'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

import { PriceLineItem } from './price_line_item'

type MaterialPriceDetailsProps = {
  companyMaterialId: string
  companyVendorId: string
  onChange: (value) => void
}

export const MaterialPriceDetails = ({ companyMaterialId, companyVendorId, onChange }: MaterialPriceDetailsProps) => {
  const { companyMaterialStore } = useStores()

  const { isLoading, data = {} } = useQuery(() => {
    if (companyMaterialId) {
      return companyMaterialStore.showMaterialDetail(companyMaterialId)
    }
  }, [companyMaterialId])

  if (isLoading) {
    return <Skeleton title={false} paragraph={{ rows: 3, width: '100%' }} style={{ width: 170 }} />
  }

  const cachedLastPrice = data?.['cached_last_price'] || 0
  const cachedAveragePrice = data?.['cached_average_price'] || 0
  const cachedLowestPrice = data?.['cached_lowest_price'] || 0
  const vendorLastPrice = data?.['vendors_last_prices']?.find((vendor) => vendor.id === companyVendorId) || []

  return (
    <FlexBoxY width={'100%'}>
      <PriceLineItem
        title="Last Order Price"
        onChange={onChange}
        value={cachedLastPrice}
        option="last price"
        companyVendorId={companyVendorId}
      />
      <PriceLineItem
        title="Average Order Price"
        onChange={onChange}
        value={cachedAveragePrice}
        option="average price"
        companyVendorId={companyVendorId}
      />
      <PriceLineItem
        title="Lowest Order Price"
        onChange={onChange}
        value={cachedLowestPrice}
        option="lowest price"
        companyVendorId={companyVendorId}
      />
      {vendorLastPrice?.price && (
        <PriceLineItem
          title={`Last Price for ${vendorLastPrice?.name}`}
          onChange={onChange}
          value={vendorLastPrice?.price}
          option="last price for vendor"
          companyVendorId={companyVendorId}
        />
      )}
    </FlexBoxY>
  )
}
