import React from 'react'

import styled from '@emotion/styled'

import { Popover, PopoverProps, Table, Typography } from 'antd'

import { isRefNumber } from 'common/helpers/get-po-number-title'
import { OrderStates } from 'common/server/server_types'

type POHistoryProps = {
  poHistory: { order_number?: string; company_vendor_name?: string }[]
  orderState?: OrderStates
} & PopoverProps

const TableStyled = styled(Table)`
  .ant-table-row:hover td.ant-table-cell {
    background: none !important;
  }
  td:first-of-type {
    padding: 4px 24px 4px 0 !important;
  }
  td:not(:first-of-type) {
    padding: 4px 0 4px 0 !important;
  }
`

export const POHistory = ({ poHistory = [], orderState, ...props }: POHistoryProps) => {
  if (!poHistory?.length) {
    return <>{props.children}</>
  }

  return (
    <Popover
      title={isRefNumber(orderState) ? 'Previous Project Refs' : 'Previous Project POs'}
      content={
        <TableStyled
          size="small"
          dataSource={poHistory}
          columns={[
            { dataIndex: 'order_number', render: (value) => <Typography.Text>{value}</Typography.Text> },
            {
              dataIndex: 'company_vendor_name',
              render: (value) => <Typography.Text type="secondary">{value}</Typography.Text>,
            },
          ]}
          pagination={false}
          showHeader={false}
        />
      }
      trigger={['hover', 'click']}
      placement="bottomLeft"
      {...props}
    />
  )
}
