import axios from 'axios'

import {
  BasicRelationship,
  ListParams,
  MappingSuggestion,
  Relationship,
  SearchParams,
  UpdateMapping,
} from './integration'

export type UpdateProject = UpdateMapping

export interface Project {
  id: string
  name: string
  project_id?: string
  active: boolean
  external_relationships: BasicRelationship[]
  mapping_suggestions?: MappingSuggestion[]
}

export function projects_count(mappingDirection: string, params?: SearchParams) {
  const queryParams = params
    ? { ...params, mapping_direction: mappingDirection }
    : { mapping_direction: mappingDirection }
  return axios.get<{ [key: string]: number }>(`/integrations/accounting/projects/count`, { params: queryParams })
}
export function projects_relationships(params?: ListParams) {
  return axios.get<Relationship[]>(`/integrations/accounting/projects/relationships`, { params })
}
export function projects(params?: ListParams) {
  return axios.get<Project[]>(`/integrations/accounting/projects`, { params })
}
export function update_project(project: UpdateProject) {
  return axios.patch(`/integrations/accounting/projects/${project?.id}`, project)
}
export function resync_projects() {
  return axios.patch('/integrations/accounting/projects/resync')
}
