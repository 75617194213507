import React from 'react'

import { render, screen } from 'common/test-utils/index'

import { TableFooter, TableFooterProps } from '../table_footer'

describe.skip('CompareOrders > table_footer', () => {
  it('should render correct grand total ', () => {
    render(
      <table>
        <TableFooter
          deliveriesValues={[]}
          orders={
            [
              {
                id: '1',
                quote: {
                  filename: 'file.txt',
                  url: '/url/file.txt',
                  key: '123',
                },
                vendor_files: [
                  {
                    filename: 'file.txt',
                    url: '/url/file.txt',
                    key: '321',
                  },
                ],
                deliveries: [
                  {
                    tax_value: 10,
                    shipping_value: 10,
                    other_value: 10,
                    discount_value: 10,
                  },
                  {
                    tax_value: 10,
                    shipping_value: 10,
                    other_value: 10,
                    discount_value: 10,
                  },
                ],
                order_materials: [
                  {
                    unit_cost: 10,
                    quantity: 1,
                  },
                ],
              },
            ] as TableFooterProps['orders']
          }
        />
      </table>,
    )

    expect(screen.getByTestId('grand-total').innerHTML).toBe('$50.00')
  })

  it('should render all row costs', () => {
    render(
      <table>
        <TableFooter
          deliveriesValues={[
            {
              orderId: '1',
              taxValue: 100,
              shippingValue: 200,
              otherValue: 500,
              discountValue: 300,
            },
            {
              orderId: '2',
              taxValue: 10,
              shippingValue: 20,
              otherValue: 50,
              discountValue: 30,
            },
          ]}
          orders={[]}
        />
      </table>,
    )

    expect(screen.getByTestId('Tax-value-0').innerHTML).toBe('$100.00')
    expect(screen.getByTestId('Tax-value-1').innerHTML).toBe('$10.00')
    expect(screen.getByTestId('Discount-value-0').innerHTML).toBe('$300.00')
    expect(screen.getByTestId('Discount-value-1').innerHTML).toBe('$30.00')
    expect(screen.getByTestId('Shipping-value-0').innerHTML).toBe('$200.00')
    expect(screen.getByTestId('Shipping-value-1').innerHTML).toBe('$20.00')
    expect(screen.getByTestId('Other-value-0').innerHTML).toBe('$500.00')
    expect(screen.getByTestId('Other-value-1').innerHTML).toBe('$50.00')
  })

  it('should not render cost row if not have value', () => {
    render(
      <table>
        <TableFooter
          deliveriesValues={[
            {
              orderId: '1',
              taxValue: null,
              shippingValue: 10,
              otherValue: 10,
              discountValue: 10,
            },
            {
              orderId: '2',
              taxValue: null,
              shippingValue: 10,
              otherValue: 10,
              discountValue: 10,
            },
          ]}
          orders={
            [
              {
                id: '1',
                quote: {
                  filename: 'file.txt',
                  url: '/url/file.txt',
                  key: '123',
                },
                vendor_files: [
                  {
                    filename: 'file.txt',
                    url: '/url/file.txt',
                    key: '321',
                  },
                ],
                deliveries: [
                  {
                    tax_value: 10,
                    shipping_value: 10,
                    other_value: 10,
                    discount_value: 10,
                  },
                ],
                order_materials: [
                  {
                    unit_cost: 10,
                    quantity: 1,
                  },
                ],
              },
            ] as TableFooterProps['orders']
          }
        />
      </table>,
    )

    expect(screen.queryByTestId('Tax')).toBe(null)
  })
})
