import React from 'react'

import { Typography, Space, Switch } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { SearchInput } from 'common/components/SearchInput'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { useStores } from 'contractor/hooks/use-stores'

import { Actions } from './actions'
import { Filters } from './filters'

export type HeaderProps = {
  onAdd: () => void
}

export const Header = observer<HeaderProps>(({ onAdd }) => {
  const { costCodeStore, companySettingStore } = useStores()
  const persistentFilters = usePersistentFilters()

  const { costCodePhaseListStore } = costCodeStore

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" width="100%" mb={{ _: 12, xs: 24 }} alignItems="flex-start">
        <Typography.Title level={3}>Phase Codes</Typography.Title>

        <Actions onAdd={onAdd} />
      </Box>

      <Box display="flex" width="100%" alignItems="flex-start">
        <Box mr={{ _: 0, xs: 16 }} mb={{ _: 12, xs: 0 }} width="100%">
          <SearchInput
            value={costCodePhaseListStore.searchState.search}
            onSearch={(search: string) => persistentFilters.handleChangeFilters({ search })}
            style={{ width: '100%' }}
          />
        </Box>

        <Space>
          <Box
            display="flex"
            alignItems="center"
            width={{ _: '100%', xs: 'auto' }}
            border="1px solid"
            borderColor="gray-2"
            borderRadius="md"
            py="6px"
            px={12}
            flexGrow={0}
          >
            <Typography.Text style={{ whiteSpace: 'nowrap', marginRight: 12 }} strong>
              Actives only
            </Typography.Text>
            <Switch
              checked={costCodePhaseListStore.searchState.filters['active']}
              onChange={(active) => persistentFilters.handleChangeFilters({ active })}
            />
          </Box>

          {costCodeSettings?.project_specific_phase_codes_enabled && <Filters />}
        </Space>
      </Box>
    </Box>
  )
})
