import { InvoiceHistoryResponse } from 'common/server/invoice_history'
import theme from 'common/styles/theme'

import { ALLOW_INVOICE_FIELDS_FULL } from './Invoice'
import { filterAllowedChanges } from './InvoiceMaterials'

export const filterAllowHistoryEvents = (history: InvoiceHistoryResponse) => {
  const hasInvoiceChanges = Object.keys(history.invoice).some((key) => ALLOW_INVOICE_FIELDS_FULL.includes(key))

  const hasInvoiceMaterialChanges = history?.invoice_materials?.some(filterAllowedChanges)

  return hasInvoiceChanges || hasInvoiceMaterialChanges || history?.orders?.length
}

export const getAvatarStyle = (author) => {
  if (author?.email?.includes('subbase.io') || !author) {
    return {
      backgroundColor: theme.colors.primary,
    }
  }

  return {}
}
