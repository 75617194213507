import React, { useState } from 'react'

import { Tabs, Tooltip, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Card } from 'common/components/Card'
import { Comments } from 'common/components/comments'
import { ErrorBoundary } from 'common/components/ErrorBoundary/error_boundary'
import { OrderHistory } from 'common/components/OrderHistory'
import { noticeError } from 'common/helpers/new_relic'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'

import { useStores } from 'contractor/hooks/use-stores'

export const MessagesAndHistory = observer(() => {
  const { orderStore, companySettingStore, userStore } = useStores()
  const source = location.pathname.substr(1)

  const [loading, setLoading] = useState(false)

  const [activeKey, setActiveKey] = useState('vendor_chat')

  const unableToUpdate = !userStore.canSendAndUpdateOrders || !userStore.canSendAndUpdateRfqs

  const handleSubmitComment = (comment) => {
    const newComment = {
      id: orderStore.selectedOrder?.id,
      comment: comment,
    }

    try {
      orderStore.sendNewComment(newComment)
    } catch (error) {
      message.error('Unable to sent comment')
    }
  }

  const { comments } = orderStore.selectedOrder

  return (
    <Card contentProps={{ p: '0 16px 16px 16px' }}>
      <Tabs
        activeKey={activeKey}
        onChange={async (key) => {
          setActiveKey(key)
          if (key === 'history') {
            trackEvent(Events.ORDER_HISTORY, source, { clickView: true })
            try {
              setLoading(true)
              await orderStore.getOrderHistory(orderStore.selectedOrder?.id)
            } catch (error) {
              noticeError(error, { entry: 'contractor-order-history' })
              message.error(`Unable to load history. Please contact ${SUPPORT_EMAIL_ADDRESS} for further assistance.`)
            } finally {
              setLoading(false)
            }
          }
        }}
        items={[
          {
            label: (
              <Tooltip title="All comments on this tab are visible to the selected vendor" placement="topLeft">
                <span>Vendor chat</span>
              </Tooltip>
            ),
            key: 'vendor_chat',
            children: (
              <Comments
                comments={comments}
                onChangeNewComment={orderStore.updateNewComment.bind(orderStore)}
                newComment={orderStore.newComment}
                unableToUpdate={unableToUpdate}
                onSubmit={unableToUpdate ? (comment) => handleSubmitComment(comment) : undefined}
                placeholder="Add a message for the vendor"
              />
            ),
          },
          {
            label: 'History',
            key: 'history',
            children: (
              <ErrorBoundary isFull={false}>
                <OrderHistory
                  isLoading={loading}
                  data={orderStore.orderHistory}
                  companyAttributes={companySettingStore.companyMaterialConfiguration.company_attributes}
                  canUseIntegrations={userStore.canUseIntegrations}
                />
              </ErrorBoundary>
            ),
          },
        ]}
      />
    </Card>
  )
})
