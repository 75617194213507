import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { Tabs, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { Attachments as AttachmentsComponent } from 'common/components/Attachments'
import { Box } from 'common/components/boxes'
import { Card } from 'common/components/Card'
import { ErrorBoundary } from 'common/components/ErrorBoundary/error_boundary'
import { OrderHistory } from 'common/components/OrderHistory'
import UploaderModal from 'common/components/uploaderModal'
import { noticeError } from 'common/helpers/new_relic'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'
import { OrderStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

type AttachmentsProps = {
  disabled?: boolean
}

const MATERIAL_REQUEST_FILE_KEY = 'material_request'
const PURCHASER_FILES_KEY = 'purchaser_files'

const statesToShowMaterialRequestFile = [OrderStates.REQUESTED, OrderStates.APPROVED]

export const Attachments = observer(({ disabled }: AttachmentsProps) => {
  const { orderStore, uploaderStore, companySettingStore, userStore } = useStores()

  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const [activeKey, setActiveKey] = useState('attachments')

  const orderPackageIdParam = new URLSearchParams(location.search).get('order_package_id')

  useEffect(() => {
    uploaderStore.resetAllUploads()

    uploaderStore.addExistingFiles(MATERIAL_REQUEST_FILE_KEY, orderStore.selectedOrder?.material_request)
    uploaderStore.addExistingFiles(PURCHASER_FILES_KEY, orderStore.selectedOrder?.purchaser_files)
  }, [orderStore.selectedOrder?.material_request?.url, orderStore.selectedOrder?.purchaser_files])

  const handleLoadHistory = async () => {
    try {
      setLoading(true)
      await orderStore.getOrderHistory(orderStore.selectedOrder?.id)
    } catch (error) {
      noticeError(error, { entry: 'contractor-draft-history' })
      message.error(`Unable to load history. Please contact ${SUPPORT_EMAIL_ADDRESS} for further assistance.`)
    } finally {
      setLoading(false)
    }
  }

  const attachmentComponent = (
    <Box display="flex" flexDirection="column" style={{ rowGap: 10 }}>
      <AttachmentsComponent
        data-cy="uploader"
        disabled={orderStore.isSplitting || disabled}
        multiple={true}
        uploadKey={PURCHASER_FILES_KEY}
        noResetUploads
        fileList={uploaderStore.fileList(PURCHASER_FILES_KEY)}
        onRemoveUpload={uploaderStore.removeUpload}
        onAddNewUpload={uploaderStore.addNewUpload}
        onResetUploads={uploaderStore.resetUploads}
        onSetUploadError={uploaderStore.setUploadError}
        onUpdateUpload={uploaderStore.updateUpload}
      />

      {!!orderStore.selectedOrder?.material_request &&
        statesToShowMaterialRequestFile.includes(orderStore.selectedOrder?.state) && (
          <UploaderModal
            title="Material Request"
            multiple={false}
            disabled
            uploadKey={MATERIAL_REQUEST_FILE_KEY}
            fileList={uploaderStore.fileList(MATERIAL_REQUEST_FILE_KEY)}
          />
        )}
    </Box>
  )

  if (!orderPackageIdParam) {
    return <Card title="Attachments">{attachmentComponent}</Card>
  }

  return (
    <Card contentProps={{ p: '0 16px 16px 16px' }}>
      <Tabs
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key)
          if (key === 'history') {
            handleLoadHistory()
          }
        }}
        items={[
          {
            label: 'Attachments',
            key: 'attachments',
            children: attachmentComponent,
          },
          {
            label: 'History',
            key: 'history',
            children: (
              <ErrorBoundary isFull={false}>
                <OrderHistory
                  isLoading={loading}
                  data={orderStore.orderHistory}
                  companyAttributes={companySettingStore.companyMaterialConfiguration.company_attributes}
                  canUseIntegrations={userStore.canUseIntegrations}
                />
              </ErrorBoundary>
            ),
          },
        ]}
      />
    </Card>
  )
})
