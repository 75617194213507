import React, { useMemo } from 'react'

import { flatten } from 'lodash'

import { Button } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX } from 'common/components/boxes'
import { Card } from 'common/components/Card'
import { OrderMaterialSpreadsheet, getFilteredColumns } from 'common/components/OrderMaterialsSpreadsheet'
import { ButtonToggleSpreadsheet } from 'common/components/OrderMaterialsV2'
import { MaterialRequestTypes } from 'common/server/server_types'

import {
  CustomUnitsEditorCell,
  CustomDescriptionEditorCell,
  CustomDescriptionViewerCell,
  CustomCostCodeEditorCell,
  CustomCostCodeViewerCell,
  CustomPhaseCodeEditorCell,
  CustomPhaseCodeViewerCell,
} from 'contractor/components/OrderMaterials'
import { useStores } from 'contractor/hooks/use-stores'

import { usePrivateOrderForm } from './context'

type MaterialProps = {
  isSpreadsheetMode: boolean
  toggleSpreadsheetMode: React.Dispatch<React.SetStateAction<boolean>>
}

const Material = observer<MaterialProps>(({ toggleSpreadsheetMode, isSpreadsheetMode }) => {
  const { orderStore, companySettingStore, userStore } = useStores()

  const { projectId } = usePrivateOrderForm()

  const requiredQuoteFields = companySettingStore.otherSettings?.required_quote_fields
  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const columns = getFilteredColumns(companySettingStore.companyAttributes).map((column) => {
    switch (column.id) {
      case 'unit':
        return {
          ...column,
          readOnly: !userStore.canCreateNewMaterial,
          DataEditor: CustomUnitsEditorCell,
        }
      case 'description':
        return {
          ...column,
          DataEditor: (props) => (
            <CustomDescriptionEditorCell
              {...props}
              canCreateNewMaterial={userStore.canCreateNewMaterial}
              projectId={projectId}
            />
          ),
          DataViewer: CustomDescriptionViewerCell,
        }
      case 'cost_code_id':
        return {
          ...column,
          DataEditor: (props) => <CustomCostCodeEditorCell {...props} projectId={projectId} />,
          DataViewer: CustomCostCodeViewerCell,
        }
      case 'cost_code_phase_id':
        return {
          ...column,
          DataEditor: (props) => <CustomPhaseCodeEditorCell {...props} projectId={projectId} />,
          DataViewer: CustomPhaseCodeViewerCell,
        }
      default: {
        // Disable the company materials column to edit when user doesn't have permission
        if (companySettingStore.companyAttributes.includes(column.id)) {
          return {
            ...column,
            readOnly: !userStore.canCreateNewMaterial,
          }
        }
        return column
      }
    }
  })

  const hiddenColumns = useMemo(() => {
    const arr = [
      'approve',
      'vendor_notes',
      'unit_cost',
      'extended_cost',
      'vendor_response',
      'tax_value',
      'ext_cost_with_tax',
    ]

    if (!costCodeSettings?.independent_phase_codes_enabled) {
      arr.push('cost_code_phase_id')
    }
    return arr
  }, [costCodeSettings?.independent_phase_codes_enabled])

  const firstDeliveryId = orderStore.newDeliveries[0].id

  const orderType =
    companySettingStore.otherSettings?.material_requisitions_default_order_type === MaterialRequestTypes.RFQ
      ? 'RFQ'
      : 'Order'

  return (
    <Card title="Materials" mt={16}>
      <OrderMaterialSpreadsheet
        canCreateNewMaterial={userStore.canCreateNewMaterial}
        costCodeSettings={costCodeSettings}
        orderType={orderType}
        columns={flatten(columns)}
        requiredFields={requiredQuoteFields}
        hiddenColumns={hiddenColumns}
        orderMaterials={orderStore.getOrderMaterialsByDeliveryId(firstDeliveryId)}
        onChange={(newOrderMaterials) => {
          orderStore.updateOrderMaterialsByDeliveryId(firstDeliveryId, newOrderMaterials)
        }}
      />

      <FlexBoxX justifyContent="flex-start" width="100%" alignItems="flex-start">
        <Button
          data-cy="add-more-materials"
          onClick={() => orderStore.addEmptyOrderMaterials(firstDeliveryId, 5)}
          type="link"
        >
          Add More Materials
        </Button>
      </FlexBoxX>

      <ButtonToggleSpreadsheet
        onClick={() => toggleSpreadsheetMode((prev) => !prev)}
        data-cy={isSpreadsheetMode ? `is-spread-sheet-0` : `spread-sheet-0`}
        isSpreadsheetMode={isSpreadsheetMode}
      />
    </Card>
  )
})

export default Material
