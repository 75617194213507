import React from 'react'

import { Tag } from 'antd'

import { OrderStates } from 'common/server/server_types'

type NotificationIconProps = {
  type: string
  state?: string
}

export const NotificationTag = (props: NotificationIconProps) => {
  const { type, state } = props

  if ([OrderStates.REQUESTED, OrderStates.APPROVED].includes(state)) {
    return <Tag style={{ marginBottom: 8 }}>Request</Tag>
  }

  if (OrderStates.QUOTED == state) {
    return <Tag style={{ marginBottom: 8 }}>RFQ</Tag>
  }

  if (type == 'IntegrationStore') {
    return <Tag style={{ marginBottom: 8 }}>Integrations</Tag>
  }

  return <Tag style={{ marginBottom: 8 }}>{type}</Tag>
}
