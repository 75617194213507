import React from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm, Button, Typography, Tooltip } from 'antd'

import { Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'

import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'

export const getInvoiceMaterialsExpandableColumns = () => {
  const { userStore, costCodeStore, companySettingStore } = useStores()

  const { handleUnmatch, handleToggleInvoiceOrderMaterialDrawer, calcExtCost, lockInvoice } = useInvoice()

  const { company_attributes = [] } = companySettingStore.companyMaterialConfiguration
  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  return [
    {
      title: 'Description',
      dataIndex: ['company_material', 'description'],
      render: (_, row) => {
        return (
          <Box display="flex" flexDirection="column">
            <Typography.Text>{row.company_material?.description}</Typography.Text>
            {row.company_note && (
              <Typography.Text style={{ fontSize: 10 }} type="secondary">
                {row.company_note}
              </Typography.Text>
            )}
          </Box>
        )
      },
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      width: 75,
      align: 'right',
      render: (_, row) => {
        return row?.quantity_delivered ?? row?.quantity
      },
    },
    {
      title: 'UOM',
      dataIndex: 'unit',
      width: 100,
      render: (_, row) => {
        const unit = row?.company_material?.unit?.name || row?.company_material?.unit_name
        return unit
      },
    },
    {
      title: 'Unit Cost',
      dataIndex: 'unit_cost',
      width: 100,
      align: 'right',
      render: (value) => currencyFormatter(value, 2),
    },
    {
      title: 'Ext. Cost',
      dataIndex: 'extended_price',
      width: 100,
      align: 'right',
      render: (_, row) => {
        const extendedCost = calcExtCost({
          unitCost: Number(row?.unit_cost),
          quantity: row?.quantity_delivered ?? row?.quantity,
          multiplier: row.company_material?.unit?.multiplier,
          qtyIncrement: row.company_material?.unit?.qty_increment,
        })
        return currencyFormatter(extendedCost, 2)
      },
    },
    {
      title: 'Order',
      dataIndex: 'order',
      align: 'right',
      render: (_, row) => {
        return (
          <Tooltip title="Order number">
            <Typography.Link
              onClick={() => handleToggleInvoiceOrderMaterialDrawer(row?.order_id)}
              style={{ textDecoration: 'underline' }}
            >
              #{row?.order_number}
            </Typography.Link>
          </Tooltip>
        )
      },
      width: 150,
    },
    ...(userStore.canUseCostCode && company_attributes.includes('cost_code_id')
      ? [
          {
            title: 'Cost Code',
            dataIndex: 'cost_code',
            width: 200,
            render: (costCode, record) => {
              if (!costCode) return null

              const canShowPhase =
                costCodeSettings?.phase_code_enabled && !costCodeSettings.independent_phase_codes_enabled && !!costCode
              const canShowClass = costCodeSettings?.class_enabled && !!costCode

              const costCodePhase = costCodeStore?.costCodePhaseListStore.records?.find(
                (costCodePhase) => costCodePhase.id === record?.cost_code_phase_id,
              )

              return (
                <Box style={{ whiteSpace: 'nowrap' }} py="5px" px="12px" display="flex" flexDirection="column">
                  <Typography.Text>
                    {[
                      canShowPhase ? costCode?.phase_code || 'N/A' : '',
                      costCode?.code,
                      canShowClass ? costCode?.clazz || 'N/A' : '',
                    ]
                      .filter((item) => !!item)
                      .join(' / ')}
                  </Typography.Text>
                  {costCodeSettings?.independent_phase_codes_enabled && (
                    <Typography.Text>{costCodePhase?.code}</Typography.Text>
                  )}
                </Box>
              )
            },
          },
        ]
      : []),
    {
      title: 'action',
      dataIndex: 'action',
      render: (_, row) => (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Popconfirm
            disabled={!lockInvoice.canEditInvoice}
            placement="topRight"
            title="Are you sure to unmatch this order line item?"
            onConfirm={() => handleUnmatch(row?.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} danger size="small" type="text" disabled={!lockInvoice.canEditInvoice} />
          </Popconfirm>
        </Box>
      ),
      width: 48,
    },
  ]
}
