import React from 'react'

import { ShopOutlined } from '@ant-design/icons'
import { Avatar as AntdAvatar } from 'antd'

import { ContractorIcon } from 'common/components/ContractorIcon'
import { useTheme } from 'common/hooks/use-theme'

export const Avatar = ({ isStyled = false, isContractor = false }) => {
  const theme = useTheme()

  return (
    <AntdAvatar
      style={
        isStyled
          ? {
              backgroundColor: theme.colors.primary,
            }
          : {}
      }
      icon={isContractor ? <ContractorIcon /> : <ShopOutlined />}
    />
  )
}
