import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { DrawerTableFilters } from 'common/components/DrawerTableFilters'
import { Page } from 'common/components/Page'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

import { InvoiceColumns } from './invoice_columns'
import { InvoiceDownload } from './invoice_download'
import { InvoiceFilters } from './invoice_filters'

type ListFiltersProps = {}

export const ListFilters = observer<ListFiltersProps>(() => {
  const { invoiceStore } = useStores()
  const { listStore } = invoiceStore
  const history = useHistory()
  const location = useLocation()

  const activeKey = new URLSearchParams(location.search).get('filter_tab')

  const { isLoading } = useQuery(listStore.fetchFacets)

  if (isLoading) {
    return null
  }

  const tabItems = [
    { label: 'Filters', key: 'filters', children: <InvoiceFilters /> },
    { label: 'Columns', key: 'columns', children: <InvoiceColumns /> },
    { label: 'Download', key: 'download', children: <InvoiceDownload /> },
  ]

  return (
    <DrawerTableFilters title="Invoice Options" useAlgolia={false} padding={0}>
      <Page>
        <Page.Tabs
          activeKey={activeKey || 'filters'}
          onChange={(key) => {
            const searchParams = new URLSearchParams(history.location.search)
            searchParams.set('filter_tab', key)
            history.push({ search: searchParams.toString() })
          }}
          items={tabItems}
        />
      </Page>
    </DrawerTableFilters>
  )
})
