import { useEffect, useRef } from 'react'

export const useIntercom = () => {
  const isInitialized = useRef(false)
  const isBooted = useRef(false)

  useEffect(() => {
    if (!isInitialized.current) {
      const script = document.createElement('script')
      script.src = '/intercom.js'
      script.async = true
      script.id = 'intercom-script'
      document.body.appendChild(script)

      isInitialized.current = true
    }
  }, [])

  return {
    show: () => window?.Intercom?.('show'),
    boot: (settings) => {
      if (!isBooted.current) {
        window.intercomSettings = settings
        window?.Intercom?.('boot', settings)

        isBooted.current = true
      }
    },
    shutdown: () => {
      window?.Intercom?.('shutdown')

      isBooted.current = false
    },
  }
}
