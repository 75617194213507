import React, { forwardRef } from 'react'

import { ExternalLink } from '@styled-icons/evaicons-solid'

import { DollarOutlined } from '@ant-design/icons'
import { Typography, Space } from 'antd'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import OrderState from 'common/components/statuses/order_state'

import { ShowOrderResponse } from 'contractor/server/orders'

import { useInvoice } from '../../context'
import { ItemStyled } from './styles'
import { TotalValuesDrawer } from './total_values_drawer'

type DrawerOrdersProps = {
  orders: ShowOrderResponse[]
  ordersGrandTotal?: number
  totalInvoicedAmount?: number
  invoicesCount?: number
  remaining?: number
}

export const DrawerOrders = forwardRef<DrawerRef, DrawerOrdersProps>((props, ref) => {
  const { orders, ordersGrandTotal, totalInvoicedAmount, invoicesCount = 0, remaining } = props

  const { currencyFormatter } = useInvoice()

  return (
    <Drawer title="Orders" ref={ref} closable={false} width={378}>
      <Box width="100%" overflowY="auto" marginRight="0">
        {orders?.map((order) => (
          <ItemStyled key={order.id} onClick={() => window.open(`/order/${order.id}`, '_blank')} borderRadius="4px">
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" mb={8}>
              <Box>
                <OrderState state={order.state} sub_state={order.sub_state} size="small" />
              </Box>
              <Typography.Link>
                <Box display="inline-flex">
                  {order.order_number}
                  <ExternalLink size="13" />
                </Box>
              </Typography.Link>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Space>
                <DollarOutlined />
                <Typography.Text>
                  {order.grand_total ? currencyFormatter(Number(order.grand_total)) : 'Unknown'}
                </Typography.Text>
              </Space>
            </Box>
          </ItemStyled>
        ))}

        <TotalValuesDrawer
          ordersGrandTotal={ordersGrandTotal}
          totalInvoicedAmount={totalInvoicedAmount}
          invoicesCount={invoicesCount}
          ordersCount={orders?.length}
          remaining={remaining}
        />
      </Box>
    </Drawer>
  )
})
