import React from 'react'

import styled from '@emotion/styled'

import { Tag, Badge } from 'antd'

import { useTheme } from 'common/hooks/use-theme'
import { InvoiceInboxStates, InvoiceInboxSubStates } from 'common/server/server_types'

const BadgeStyled = styled(Badge)`
  margin-right: 4px;
  display: flex;
  align-items: center;

  & .ant-badge-count {
    line-height: 16px;
    min-width: 16px;
    height: 16px;
    padding: 0 4px;
    border-radius: 8px;
  }
`

export const InboxState = ({ state, sub_state, count = 0, style = null }) => {
  const theme = useTheme()

  const extracting = {
    text: 'Extracting',
    style: {
      color: theme.colors['blue-6'],
      background: theme.colors['blue-1'],
      borderColor: theme.colors['blue-6'],
    },
  }
  const failed = {
    text: 'Failed',
    style: {
      color: theme.colors['red-6'],
      background: theme.colors['red-1'],
      borderColor: theme.colors['red-6'],
    },
  }
  const inReview = {
    text: 'In Review',
    style: {
      color: theme.colors['gold-7'],
      background: theme.colors['gold-1'],
      borderColor: theme.colors['gold-3'],
    },
  }
  const ignored = {
    text: 'Manually Ignored',
    style: {
      color: theme.colors['gray-7'],
      background: theme.colors['gray-1'],
      borderColor: theme.colors['gray-6'],
    },
  }
  const autoIgnored = {
    text: 'Auto Ignored',
    style: {
      color: theme.colors['gold-6'],
      background: theme.colors['gold-1'],
      borderColor: theme.colors['gold-6'],
    },
  }
  const created = {
    text: count > 1 ? 'Invoices Created' : 'Invoice Created',
  }
  const stateMapping = (state, sub_state) => {
    switch (sub_state) {
      case InvoiceInboxSubStates.FAILED:
        return failed
      case InvoiceInboxSubStates.IN_REVIEW:
        return inReview
      case InvoiceInboxSubStates.IGNORED:
        return ignored
      case InvoiceInboxSubStates.AUTO_IGNORED:
        return autoIgnored
      case InvoiceInboxSubStates.RESOLVED:
        return created
    }
    switch (state) {
      case InvoiceInboxStates.RECEIVED:
      case InvoiceInboxStates.EXTRACTING:
      case InvoiceInboxStates.TRANSFORMING:
        return extracting
      case InvoiceInboxStates.TRANSFORMATION_COMPLETED:
        return created
    }
  }

  const noBadgeStates = [
    InvoiceInboxSubStates.IN_REVIEW,
    InvoiceInboxSubStates.FAILED,
    InvoiceInboxSubStates.IGNORED,
    InvoiceInboxSubStates.AUTO_IGNORED,
    InvoiceInboxSubStates.RESOLVED,
  ]

  const invoicesManuallyCreated = sub_state === InvoiceInboxSubStates.RESOLVED && count > 0

  const showBadge =
    (!noBadgeStates.includes(sub_state) && state === InvoiceInboxStates.TRANSFORMATION_COMPLETED) ||
    invoicesManuallyCreated

  return (
    <Tag
      style={{
        margin: 0,
        minWidth: 146,
        display: 'flex',
        fontWeight: 'normal',
        justifyContent: 'center',
        ...style,
        ...stateMapping(state, sub_state)?.['style'],
      }}
    >
      {showBadge && (
        <BadgeStyled
          count={count}
          showZero
          style={{
            background: count > 0 ? theme.colors.success : theme.colors.error,
          }}
        />
      )}
      <span style={{ paddingTop: 1 }}>{stateMapping(state, sub_state)?.['text']}</span>
    </Tag>
  )
}
