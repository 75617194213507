import React from 'react'

import classNames from 'classnames'

import FlagTwoTone from '@ant-design/icons/FlagTwoTone'
import { Popover, InputNumber, Tooltip } from 'antd'

import { currencyFormatter } from 'common/helpers/formatters'
import { useTheme } from 'common/hooks/use-theme'
import { OrderStates } from 'common/server/server_types'

import { useLeveling } from './context'
import { MaterialInfo } from './material_info'

const style = { minWidth: 85, textAlign: 'right' }

const Td = (props) => <td {...props} className={classNames('ant-table-cell', props?.className)} />

export const TableBody = () => {
  const { isSplitting, handleChangeQuantity, tableData } = useLeveling()

  const theme = useTheme()

  const getMessageWhenQuantityExceedsOriginal = (
    currentQuantityGreaterOriginal,
    sumRowQuantityGreaterOriginal,
    originalQuantity,
  ) => {
    if (currentQuantityGreaterOriginal) {
      return `The current quantity exceeds the original amount: ${originalQuantity}`
    }
    if (sumRowQuantityGreaterOriginal) {
      return `The row quantity sum exceeds the original amount: ${originalQuantity}`
    }
  }

  const getUnitLabel = (companyMaterial) => {
    if (companyMaterial?.unit_id) {
      return companyMaterial?.unit?.unit_name_with_increment_label
    }

    if (companyMaterial?.unit_name) {
      return companyMaterial?.unit_name
    }

    return null
  }

  return (
    <tbody className="ant-table-tbody">
      {tableData.map((item, rowIndex) => {
        const totalRowQuantities = item.vendor_items.reduce((acc, vendorItem) => acc + vendorItem.quantity, 0)

        return (
          <tr key={`row-${rowIndex}`}>
            <Td className="border-left">
              <MaterialInfo item={item} />
            </Td>

            <Td>{item.quantity}</Td>
            <Td className="border-right">{getUnitLabel(item.company_material)}</Td>

            {item.vendor_items.map((vendor, vendorIndex) => {
              const key = `${vendor?.delivery_id}-${vendorIndex}`

              if (!vendor?.delivery_id) {
                return (
                  <React.Fragment key={key}>
                    <Td
                      style={{ backgroundColor: theme.colors['gray-2'] }}
                      className="border-right"
                      colSpan={isSplitting ? 4 : 3}
                    />
                  </React.Fragment>
                )
              }

              const className = classNames({
                'order-cancelled': vendor?.state === OrderStates.CANCELLED,
                'material-deleted': vendor?.discarded_at,
                'zero-quantity': vendor?.quantity === 0,
                'lowest-price': !vendor?.discarded_at && vendor?.state === OrderStates.QUOTED && vendor?.isLowestPrice,
                'ordered-material':
                  !vendor?.discarded_at &&
                  [
                    OrderStates.ORDERED,
                    OrderStates.PARTIALLY_SHIPPED,
                    OrderStates.SHIPPED,
                    OrderStates.DELIVERED,
                  ].includes(vendor?.state),
              })

              const currentQuantityGreaterOriginal = vendor.quantity > item.quantity
              const sumRowQuantityGreaterOriginal = totalRowQuantities > item.quantity

              const disabledQuantityInput = !!vendor.discarded_at || vendor?.state === OrderStates.CANCELLED

              return (
                <React.Fragment key={key}>
                  {isSplitting && (
                    <Td style={{ width: 85, minWidth: 85 }} className={className}>
                      {!vendor?.discarded_at && (
                        <Tooltip
                          title={
                            currentQuantityGreaterOriginal || sumRowQuantityGreaterOriginal
                              ? getMessageWhenQuantityExceedsOriginal(
                                  currentQuantityGreaterOriginal,
                                  sumRowQuantityGreaterOriginal,
                                  item.quantity,
                                )
                              : ''
                          }
                        >
                          <InputNumber
                            style={{ width: '100%' }}
                            inputMode="numeric"
                            value={vendor.quantity}
                            onChange={(value) => handleChangeQuantity({ quantity: value, rowIndex, vendorIndex })}
                            status={
                              (currentQuantityGreaterOriginal || sumRowQuantityGreaterOriginal) && !!vendor.quantity
                                ? 'warning'
                                : null
                            }
                            disabled={disabledQuantityInput}
                            min={0}
                          />
                        </Tooltip>
                      )}
                    </Td>
                  )}
                  <Td style={style} className={className}>
                    {vendor.unit_cost ? currencyFormatter(vendor.unit_cost, 3) : 'N/A'}
                  </Td>
                  <Td style={style} className={className}>
                    {currencyFormatter(vendor.extended_cost, 3)}
                  </Td>
                  <Td className={`${className} border-right`}>
                    {vendor.vendor_note && (
                      <Popover
                        overlayStyle={{ maxWidth: 370 }}
                        placement="bottom"
                        title="Vendor Notes"
                        content={vendor.vendor_note}
                      >
                        <FlagTwoTone />
                      </Popover>
                    )}
                  </Td>
                </React.Fragment>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  )
}
