import React from 'react'

import { Link, useHistory, useLocation } from 'react-router-dom'

import { DeliveredProcedureOutlined, PlusOutlined, SnippetsOutlined } from '@ant-design/icons'

import { Tool, trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'

import { useStores } from 'contractor/hooks/use-stores'

export const getFastActionMenuItem = ({ onOpen }) => {
  const { userStore, companySettingStore } = useStores()
  const location = useLocation()
  const history = useHistory()

  const showCreateRFQ = userStore.canUseCreateRFQ && userStore.canSendAndUpdateRfqs

  const getUrl = (url: string) => {
    return `${url}${location.search}`
  }

  const openLogDelivery = () => {
    trackEvent(Events.CLICK_LOG_DELIVERY_HEADER, window.location.pathname, {}, Tool.AMPLITUDE)
    onOpen()
  }

  const navigateToNewQuote = () => {
    trackEvent(Events.CLICK_CREATE_RFQ_HEADER, window.location.pathname, {}, Tool.AMPLITUDE)
    history.push('/orders/new_quote')
  }

  const navigateToNewOrder = () => {
    trackEvent(Events.CLICK_CREATE_ORDER_HEADER, window.location.pathname, {}, Tool.AMPLITUDE)
    history.push('/orders/new_order')
  }

  return {
    key: 'fast-actions',
    icon: <PlusOutlined data-cy="extra-actions" style={{ fontSize: 20, minWidth: 24, verticalAlign: 'middle' }} />,
    theme: 'light',
    children: [
      userStore?.cannotSendAndUpdateOrders && {
        key: 'Request Material',
        label: (
          <Link to={getUrl(`/new_order/${companySettingStore?.otherSettings?.public_order_form_url_extension}`)}>
            Request Material
          </Link>
        ),
      },
      userStore.canSendAndUpdateOrders && {
        key: 'create-order',
        label: (
          <>
            <PlusOutlined style={{ fontSize: 16, marginRight: 16 }} />
            Create Order
          </>
        ),
        onClick: navigateToNewOrder,
      },
      showCreateRFQ && {
        key: 'create-rfq',
        label: (
          <>
            <SnippetsOutlined style={{ fontSize: 16, marginRight: 16 }} />
            Create RFQ
          </>
        ),
        onClick: navigateToNewQuote,
      },
      userStore.canEditDeliveryInformation && {
        key: 'log-delivery',
        label: (
          <>
            <DeliveredProcedureOutlined style={{ fontSize: 16, marginRight: 16 }} />
            Log Delivery
          </>
        ),
        onClick: openLogDelivery,
      },
    ],
    className: 'only-icon',
  }
}
