import React, { useState, useRef } from 'react'

import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'
import { Page } from 'common/components/Page'
import { PersistentFiltersProvider } from 'common/contexts/persistent_filters'

import { VendorProfile } from 'contractor/components/VendorProfile'
import { useStores } from 'contractor/hooks/use-stores'
import { IndexCompanyVendor } from 'contractor/server/company_vendors'
import { Vendor } from 'contractor/server/vendors'

import { CreateCompanyVendorContact } from './Create'
import { Header } from './Header'
import { List } from './List'
import { UpdateCompanyVendorContact, UpdateCompanyVendor } from './Update'

type MyVendorsProps = {
  goToNetWork: () => void
}

export const MyVendors = observer<MyVendorsProps>(({ goToNetWork }) => {
  const { companyVendorStore } = useStores()

  const drawerVendorRef = useRef<DrawerRef>()
  const drawerUpdateRef = useRef<DrawerRef>()
  const drawerCreateRef = useRef<DrawerRef>()
  const drawerUpdateCompanyVendorRef = useRef<DrawerRef>()

  const [selectedVendor, setSelectedVendor] = useState<Vendor>(null)
  const [selectedCompanyVendor, setSelectedCompanyVendor] = useState<IndexCompanyVendor>(null)
  const [selectedCompanyVendorContactId, setSelectedCompanyVendorContactId] = useState(null)

  const { companyVendorListStore } = companyVendorStore

  const handleOpenVendorProfile = (vendor: Vendor, companyVendor: IndexCompanyVendor) => {
    setSelectedVendor(vendor)
    setSelectedCompanyVendor(companyVendor)
    drawerVendorRef.current?.show()
  }

  const handleOpenCompanyVendorContactUpdate = (companyVendorContactId: string) => {
    setSelectedCompanyVendorContactId(companyVendorContactId)
    drawerUpdateRef.current?.show()
  }

  const handleOpenCompanyVendorUpdate = (companyVendor: IndexCompanyVendor) => {
    setSelectedCompanyVendor(companyVendor)
    drawerUpdateCompanyVendorRef.current?.show()
  }

  const handleCreate = () => drawerCreateRef.current?.show()

  const handleCancelCreateCompanyVendorContact = () => drawerCreateRef.current?.close()

  const handleCancelUpdateCompanyVendorContact = () => {
    setSelectedCompanyVendorContactId(null)
    drawerUpdateRef.current?.close()
  }

  const handleCancelUpdateCompanyVendor = () => {
    setSelectedCompanyVendor(null)
    drawerUpdateCompanyVendorRef.current?.close()
  }

  return (
    <PersistentFiltersProvider ignoreQueryParams={['tab']} listStore={companyVendorListStore}>
      <Page.Header>
        <Header onCreate={handleCreate} />
      </Page.Header>

      <Page.Content>
        <List
          onCreate={handleCreate}
          goToNetWork={goToNetWork}
          onOpenVendorProfile={handleOpenVendorProfile}
          onOpenCompanyVendorContactUpdate={handleOpenCompanyVendorContactUpdate}
          onOpenCompanyVendorUpdate={handleOpenCompanyVendorUpdate}
        />
      </Page.Content>

      <UpdateCompanyVendor
        companyVendorId={selectedCompanyVendor?.id}
        onCancel={handleCancelUpdateCompanyVendor}
        ref={drawerUpdateCompanyVendorRef}
      />

      <VendorProfile vendorId={selectedVendor?.id} companyVendor={selectedCompanyVendor} ref={drawerVendorRef} />

      <UpdateCompanyVendorContact
        companyVendorContactId={selectedCompanyVendorContactId}
        onCancel={handleCancelUpdateCompanyVendorContact}
        ref={drawerUpdateRef}
      />

      <CreateCompanyVendorContact onCancel={handleCancelCreateCompanyVendorContact} ref={drawerCreateRef} />
    </PersistentFiltersProvider>
  )
})
