import { CompanyVendorContact } from 'contractor/server/company_vendor_contacts'

export const getGlobalSafeVendorContact = (copanyVendorContact: CompanyVendorContact): CompanyVendorContact => {
  const isGlobalizedVendor = !!copanyVendorContact.company_vendor?.vendor
  const isGlobalizedVendorContact = !!copanyVendorContact.contact?.vendor_user

  const companyVendor = copanyVendorContact.company_vendor
  const companyVendorContact = copanyVendorContact.contact

  return {
    company_vendor: {
      ...copanyVendorContact.company_vendor,
      vendor_name: isGlobalizedVendor ? companyVendor.vendor.name : companyVendor.vendor_name,
    },
    contact: {
      ...copanyVendorContact.contact,
      full_name: isGlobalizedVendorContact
        ? companyVendorContact.vendor_user.full_name
        : companyVendorContact.full_name,
      first_name: isGlobalizedVendorContact
        ? companyVendorContact.vendor_user.first_name
        : companyVendorContact.first_name,
      last_name: isGlobalizedVendorContact
        ? companyVendorContact.vendor_user.last_name
        : companyVendorContact.last_name,
      email: isGlobalizedVendorContact ? companyVendorContact.vendor_user.email : companyVendorContact.email,
    },
  }
}
