import React from 'react'

import { Card } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { PdfViewer } from 'common/components/PdfViewer'

import { useStores } from 'contractor/hooks/use-stores'

import { ExtractedData } from './ExtractedData'

const pdfFileIndex = 0

type ExtractedStepProps = {
  visible: boolean
}

export const ExtractedStep = observer<ExtractedStepProps>(({ visible }) => {
  const { invoiceStore } = useStores()

  const invoice = invoiceStore.invoice

  return (
    <Box
      flexGrow={1}
      display={visible ? 'grid' : 'none'}
      gridTemplateColumns="repeat(2, minmax(0, 1fr))"
      style={{ gap: 8 }}
      minWidth={1200}
    >
      <Card style={{ display: 'flex', flexDirection: 'column' }} bodyStyle={{ padding: 0, flexGrow: 1 }}>
        <PdfViewer
          url={invoice.extracted_files[pdfFileIndex]?.url}
          key={`${invoice.id}-expand`}
          style={{ height: '100%', minHeight: '100%', borderRadius: 4 }}
          loading={!invoiceStore.invoice}
        />
      </Card>

      <ExtractedData />
    </Box>
  )
})
