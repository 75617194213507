import borders from './borders'
import breakpoints from './breakpoints'
import colors from './colors'
import radii from './radius'
import sizes, { baseSizes } from './sizes'
import typography from './typography'
import zIndices from './z-index'

const theme = {
  breakpoints,
  colors,
  radii,
  sizes,
  space: baseSizes,
  borders,
  typography,
  zIndices,

  cardStyles: {
    normal: {
      boxShadow: `3px 3px ${colors['gray-2']}`,
      borderWeight: '2px',
      borderColor: colors['gray-4'],
    },
    hoverable: {
      borderWeight: '2px',
      borderColor: colors['gray-4'],
    },
  },
}

export type Theme = typeof theme
export default theme
