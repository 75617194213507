import React from 'react'

import styled from 'styled-components'

import { Tabs, Tag, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY, Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { useTheme } from 'common/hooks/use-theme'

import { useStores } from 'contractor/hooks/use-stores'
import { ShowCompanyMaterialResponse } from 'contractor/server/company_materials'

import { OrderMaterialHistoryTab } from './order_material_history_tab'
import { QuoteMaterialHistoryTab } from './quote_material_history_tab'

export type PriceHistoryProps = {
  hideNotification?: boolean
  editable?: boolean
  onCancel: () => void
  onDuplicate?: (material: ShowCompanyMaterialResponse) => void
}

const StyledTabs = styled(Tabs)`
  .ant-tabs,
  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-tabpane {
    height: 100%;
  }
`

const Title = ({ title, isManagedBySubBase }) => {
  const theme = useTheme()
  return (
    <Box display="flex" alignItems="baseline" flexDirection={{ _: 'column', xs: 'row' }} style={{ columnGap: 20 }}>
      <Typography.Title level={5} style={{ margin: 0 }}>
        {title}
      </Typography.Title>
      {isManagedBySubBase && <Tag color={theme.colors.primary}>Managed By SubBase</Tag>}
    </Box>
  )
}

export const PriceHistory = observer<PriceHistoryProps, DrawerRef>(
  (props, ref) => {
    const { companyMaterialStore } = useStores()

    const companyMaterial = companyMaterialStore.selectedCompanyMaterial

    const previouslyOrdered = false

    const isManagedBySubBase = !!companyMaterial?.manufacturer_material_id || companyMaterial?.company_id === 'CATALOG'

    const tabItems = [
      {
        label: 'Order History',
        key: 'orders',
        children: <OrderMaterialHistoryTab />,
      },
      {
        label: 'Quote History',
        key: 'quotes',
        children: <QuoteMaterialHistoryTab editable={props.editable} />,
      },
    ]

    return (
      <Drawer
        ref={ref}
        bgGray
        destroyOnClose
        title={<Title title="Price History" isManagedBySubBase={isManagedBySubBase} />}
        closable={false}
        onClose={props?.onCancel}
      >
        <FlexBoxY
          px={16}
          width="100%"
          minHeight="100%"
          alignItems="flex-start"
          justifyContent="flex-start"
          overflow="auto"
          pb={16}
          pt={previouslyOrdered ? 0 : 16}
        >
          <StyledTabs defaultActiveKey="orders" style={{ flexGrow: 1, width: '100%' }} items={tabItems} />
        </FlexBoxY>
      </Drawer>
    )
  },
  { forwardRef: true },
)
