import React from 'react'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import Tags from 'common/components/tags'
import { Visibility } from 'common/components/Visibility'

import { SelectUser, makeCompanyUserOption } from 'contractor/components/SelectUser'
import { useStores } from 'contractor/hooks/use-stores'
import { ActionStatus, InternalComments } from 'contractor/pages/Invoices/Detail/components'

import { useInvoice } from '../context'
import { DropdownActions } from './dropdown_actions'
import { HeaderProps } from './header'
import { UpdateButton } from './update_button'

type RightActionsProps = Pick<
  HeaderProps,
  | 'isCorrelating'
  | 'toggleRejectionReasonModal'
  | 'isSubmitting'
  | 'saveAndNextDefault'
  | 'onSaveInvoice'
  | 'onSaveInvoiceAndNext'
  | 'assignToId'
  | 'assignToId'
  | 'onChangeAssignToId'
  | 'stateId'
  | 'onChangeStateId'
  | 'attachedOrderIds'
>

export const RightActions = observer<RightActionsProps>((props) => {
  const {
    isCorrelating,
    toggleRejectionReasonModal,
    isSubmitting,
    saveAndNextDefault,
    onSaveInvoice,
    onSaveInvoiceAndNext,

    assignToId,
    onChangeAssignToId,

    stateId,
    onChangeStateId,
  } = props

  const { invoiceStore } = useStores()
  const { setSelectedInvoiceDirty, lockInvoice } = useInvoice()

  const { invoice } = invoiceStore

  return (
    <>
      <Box display="flex" alignItems="flex-start" style={{ gap: 8 }}>
        {!isCorrelating && (
          <>
            <Visibility.Hidden breakpoint="lg">
              <Box minWidth={150}>
                <Tags
                  disabled={!lockInvoice.canEditInvoice}
                  value={invoice.tags}
                  onChange={(tags) => {
                    invoice.tags = tags
                    setSelectedInvoiceDirty(true)
                  }}
                  tags={invoiceStore.invoiceTags}
                />
              </Box>

              <SelectUser
                onlyActives
                disabled={!lockInvoice.canChangeStatusAndAssignee && !lockInvoice.canEditInvoice}
                onChange={(value) => {
                  onChangeAssignToId(value)
                  setSelectedInvoiceDirty(true)
                }}
                value={assignToId}
                makeOption={makeCompanyUserOption}
                placeholder="Assignee"
                boxProps={{ width: 150 }}
                showAssignToMe={false}
                showAssignToMeOnDropdown
              />
            </Visibility.Hidden>

            <ActionStatus
              disabled={!lockInvoice.canChangeStatusAndAssignee && !lockInvoice.canEditInvoice}
              stateId={stateId}
              changeStatus={(value) => {
                onChangeStateId(value)
                setSelectedInvoiceDirty(true)
              }}
              toggleRejectionReasonModal={toggleRejectionReasonModal}
              canPostInvoices={lockInvoice.canPostInvoice}
            />
          </>
        )}

        <Visibility.Hidden breakpoint="md">
          <InternalComments />

          <UpdateButton
            saveAndNextDefault={saveAndNextDefault}
            isCorrelating={isCorrelating}
            isSubmitting={isSubmitting}
            onSaveInvoice={onSaveInvoice}
            onSaveInvoiceAndNext={onSaveInvoiceAndNext}
          />

          <DropdownActions />
        </Visibility.Hidden>
      </Box>
    </>
  )
})
