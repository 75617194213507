import React from 'react'

import { Skeleton } from 'antd'

import { Box } from 'common/components/boxes'
import { DrawerBody } from 'common/components/Drawer'

const skeletonRows = new Array(3).fill(0)

export const Loading = () => {
  return (
    <DrawerBody p={24}>
      <Skeleton active />

      <Skeleton.Input active block style={{ margin: '24px 0' }} />

      <Box width="100%">
        {skeletonRows.map((_, index) => (
          <Skeleton key={index} active avatar={{ size: 'default' }} paragraph={{ rows: 2 }} />
        ))}
      </Box>
    </DrawerBody>
  )
}
