import React from 'react'

import { Alert, Button, InputRef, Modal } from 'antd'

import { Box } from 'common/components/boxes'
import { TextArea } from 'common/components/TextArea'
import { InvoiceInboxFilesStates, InvoiceInboxSubStates } from 'common/server/server_types'

import { ConsolidatedInvoiceInboxes } from 'contractor/server/invoices/inbox/consolidated_invoice_inboxes'

import { IgnoredPdfDetails } from './ignored_pdf_details'
import { ReportIssue, ReportIssueProps } from './report_issue'

type Props = {
  invoiceInbox: ConsolidatedInvoiceInboxes.InvoiceInbox
  onSendTip: (text: string) => Promise<void>
} & Pick<ReportIssueProps, 'pdfFiles' | 'onSendIssue'>

const getAlertMessage = (files) =>
  files.some((file) => file.state === InvoiceInboxFilesStates.SUCCESS)
    ? 'Some files has been auto marked as Ignored'
    : 'This inbox has been auto marked as Ignored'

export function InvoiceInboxDetailsDrawerWarnAlert(props: Props) {
  const { invoiceInbox, onSendIssue, onSendTip, pdfFiles } = props

  const inputRef = React.useRef<InputRef>(null)

  const [openSuggestionModal, setOpenSuggestionModal] = React.useState(false)
  const [openIssueModal, setOpenIssueModal] = React.useState(false)
  const [text, setText] = React.useState('')

  const someFilesAreIgnored =
    invoiceInbox.files.some((file) => file.state === InvoiceInboxFilesStates.AUTO_IGNORED) ||
    invoiceInbox.files.length === 0

  if (invoiceInbox?.sub_state !== InvoiceInboxSubStates.AUTO_IGNORED && !someFilesAreIgnored) {
    return null
  }

  const message = getAlertMessage(invoiceInbox.files)

  const handleOpenSuggestionModal = () => {
    setOpenSuggestionModal(true)

    setTimeout(() => {
      inputRef.current?.focus()
    }, 100)
  }

  const handleCloseSuggestionModal = () => {
    setOpenSuggestionModal(false)
    setText('')
  }

  const handleSendImprove = () => onSendTip(text).then(() => handleCloseSuggestionModal())

  const showReportButton = [(InvoiceInboxSubStates.FINISHED, InvoiceInboxSubStates.FAILED)].includes(
    invoiceInbox.sub_state,
  )

  return (
    <>
      <Modal
        title="How can we improve? Describe it here and hit OK"
        open={openSuggestionModal}
        onCancel={handleCloseSuggestionModal}
        okButtonProps={{
          onClick: handleSendImprove,
          disabled: text.length === 0,
        }}
      >
        <TextArea
          ref={inputRef}
          value={text}
          onChange={(e) => setText(e.target.value)}
          showCount
          maxLength={255}
          style={{ height: 120, resize: 'none' }}
        />
      </Modal>

      <Alert
        showIcon
        message={message}
        type="warning"
        description={
          <Box>
            <Box mb={24}>
              <IgnoredPdfDetails files={invoiceInbox?.files || []} />
            </Box>

            <Box display="flex" justifyContent="flex-end" gridGap={8}>
              {showReportButton && (
                <>
                  <Button onClick={() => setOpenIssueModal(true)}>Something is wrong?</Button>{' '}
                  <ReportIssue
                    invoiceInboxId={invoiceInbox?.id}
                    open={openIssueModal}
                    onSendIssue={(...params) => onSendIssue(...params).then(() => setOpenIssueModal(false))}
                    onClose={() => setOpenIssueModal(false)}
                    pdfFiles={pdfFiles}
                  />
                </>
              )}
              <Button onClick={handleOpenSuggestionModal}>Help us improve</Button>
            </Box>
          </Box>
        }
      />
    </>
  )
}
