import axios from 'axios'

export interface GetUrl {
  url: string
}

export function getFile(url: string) {
  return axios.get(url)
}

export function getUrlBySignedId(signedId: string) {
  return axios.get<GetUrl>(`/files/find_signed?signed_id=${signedId}`)
}
