import { BindAll } from 'lodash-decorators'

import { observable, action } from 'mobx'

import {
  internal_comment,
  deletePackage,
  updateOrderPackage,
  getOrderPackage,
  UpdateOrderPackageParams,
  ShowOrderPackageResponse,
  GetOrderPackageParams,
} from 'contractor/server/order_package'

@BindAll()
export default class OrderPackageStore {
  @observable orderPackage: Nullable<ShowOrderPackageResponse> = null

  async getOrderPackage(id: string, params?: GetOrderPackageParams): Promise<ShowOrderPackageResponse> {
    const { data } = await getOrderPackage(id, params)

    this.orderPackage = data

    return this.orderPackage
  }

  async updateOrderPackage(payload: UpdateOrderPackageParams): Promise<ShowOrderPackageResponse> {
    const { data } = await updateOrderPackage(payload)

    this.orderPackage = data

    return this.orderPackage
  }

  deleteOrderPackage(id: string) {
    return deletePackage(id)
  }

  @action
  async sendInternalComment(params: { text: string; userIds: string[] }) {
    const { data } = await internal_comment({
      id: this.orderPackage?.id,
      comment: params.text,
      user_ids: params.userIds,
    })

    this.orderPackage = data
  }
}
