import React from 'react'

import { Typography, Button, Checkbox, Tag, Form } from 'antd'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerBody, DrawerFooter, DrawerProps } from 'common/components/Drawer'
import { TextArea } from 'common/components/TextArea'
import { useTheme } from 'common/hooks/use-theme'
import { PdfFile } from 'common/server/invoice'
import { InvoiceInboxFilesStates } from 'common/server/server_types'

export type ReportIssueProps = {
  invoiceInboxId?: string
  onSendIssue: (invoiceInboxId: string, issueMessage: string, invoiceInboxAttachmentIds: string[]) => Promise<void>
  pdfFiles?: PdfFile[]
} & Pick<DrawerProps, 'onClose' | 'open'>

const FailedTag = () => {
  const theme = useTheme()

  return (
    <Tag
      style={{
        margin: 0,
        color: theme.colors['red-6'],
        background: theme.colors['red-1'],
        borderColor: theme.colors['red-6'],
      }}
    >
      Failed
    </Tag>
  )
}

const FileItem = ({ pdfFile }) => {
  const theme = useTheme()
  const isFailed = pdfFile.state === InvoiceInboxFilesStates.FAILED

  return (
    <Box key={pdfFile.invoice_inbox_attachment_id} display="flex" alignItems="baseline" mb={4} style={{ gap: 8 }}>
      <Checkbox
        value={pdfFile.invoice_inbox_attachment_id}
        style={{ color: isFailed ? theme.colors.error : 'inherit', margin: 0 }}
      >
        {pdfFile.filename}
      </Checkbox>

      {isFailed && <FailedTag />}
    </Box>
  )
}

export function ReportIssue(props: ReportIssueProps) {
  const { invoiceInboxId, onSendIssue, pdfFiles, ...rest } = props

  const [form] = Form.useForm()

  const handleFormFinish = (formValues) => {
    onSendIssue(invoiceInboxId, formValues?.issueMessage, formValues?.invoiceInboxAttachmentIds || [])
    form.resetFields()
  }

  return (
    <Drawer title="Report issue" width={378} bodyStyle={{ flexDirection: 'column' }} {...rest}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormFinish}
        style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <DrawerBody>
          {!!pdfFiles?.length && (
            <Box>
              <Typography.Title level={5}>For wich PDF you want to report an issue?</Typography.Title>

              <Form.Item
                name="invoiceInboxAttachmentIds"
                rules={[{ required: true, message: 'Please specify the file!' }]}
                initialValue={[]}
              >
                <Checkbox.Group>
                  {pdfFiles.map((pdfFile) => (
                    <FileItem key={pdfFile.invoice_inbox_attachment_id} pdfFile={pdfFile} />
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </Box>
          )}

          <Form.Item name="issueMessage" rules={[{ required: true, message: 'Please describe the issue!' }]}>
            <TextArea
              placeholder="Please describe the issue..."
              showCount
              maxLength={255}
              style={{ height: 120, resize: 'none' }}
            />
          </Form.Item>
        </DrawerBody>

        <DrawerFooter>
          <Button style={{ minWidth: 100 }} onClick={rest?.onClose}>
            Cancel
          </Button>
          <Button style={{ minWidth: 100 }} type="primary" htmlType="submit">
            Send
          </Button>
        </DrawerFooter>
      </Form>
    </Drawer>
  )
}
