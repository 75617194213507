import React from 'react'

import { Col, Row } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import DateTimePicker from 'common/components/date_time_picker'
import { ItemTitle } from 'common/components/text'
import { useQuery } from 'common/hooks/use-query'
import { Address } from 'common/server/addresses'
import { OrderDelivery } from 'common/server/deliveries'

import { DeliveryInstructions } from 'contractor/components/DeliveryInstructions'
import { DeliveryPickUpToggle } from 'contractor/components/DeliveryPickUpToggle'
import { SelectAddressTo } from 'contractor/components/OrderDeliveries/address_to_selector'
import { useStores } from 'contractor/hooks/use-stores'

import AddressSelector from './address_selector'
import { DeliveryDetails } from './delivery_details'

interface DeliveryInfoProps {
  delivery: OrderDelivery
  disabled?: boolean
  project_ids: string[]
  showDetails: boolean
  orderType?: OrderType
  companyVendorId?: string
  onChangeAddress?: (address: Address) => void
  handleOrderDirty: () => void
}

// TODO: Need to decide on what's mandatory vs optional
export const DeliveryInfo = observer<DeliveryInfoProps>(
  ({ delivery, project_ids, showDetails, orderType, companyVendorId, onChangeAddress, disabled, handleOrderDirty }) => {
    const { orderStore, userStore, companySettingStore, addressToStore } = useStores()

    const showAddressTo = companySettingStore.otherSettings?.order_fields_settings?.address_to?.enabled

    useQuery(() => {
      if (showAddressTo) {
        return addressToStore.indexAddressesTo()
      }
    }, [showAddressTo])

    const handleChangeDelivery = (propName = '', param) => {
      delivery[propName] = param
      handleOrderDirty()
    }

    const handleChangeAddress = (address) => {
      delivery['address'] = address
      handleOrderDirty()
      onChangeAddress?.(address)
    }

    const handleAddressToChange = (addressToId) => {
      delivery['address_to'] = addressToStore.addressesTo.find((addressTo) => addressTo.id === addressToId)
      handleOrderDirty()
    }

    const handleChangePickUp = (isPickUp) => {
      // Remove address each time user toggle between delivery and pick up
      delivery['address'] = null

      delivery['is_pick_up'] = isPickUp
      handleOrderDirty()
    }

    const showDetailButton = showDetails && userStore.canEditDeliveryInformation

    const colProps = React.useMemo(() => {
      const deliveryDetails = { xs: 10, md: 5, lg: 4, xl: 3, xxl: 3 }
      const instructions = { xs: 4, md: 2, lg: 2, xl: 2, xxl: 1 }

      if (showAddressTo && showDetailButton) {
        return {
          address: { xs: 24, lg: 24, xl: 11, xxl: 8 },
          attnTo: { xs: 24, md: 10, lg: 10, xl: 5, xxl: 4 },
          requestedDate: { xs: 14, md: 9, lg: 10, xl: 5, xxl: 4 },
          deliveryDetails,
          instructions,
        }
      }

      if (showAddressTo && !showDetailButton) {
        return {
          address: { xs: 24, lg: 24, xl: 12, xxl: 8 },
          attnTo: { xs: 24, md: 11, lg: 11, xl: 5, xxl: 4 },
          requestedDate: { xs: 20, md: 11, lg: 11, xl: 5, xxl: 4 },
          deliveryDetails,
          instructions,
        }
      }

      if (!showAddressTo && showDetailButton) {
        return {
          address: { xs: 24, lg: 15, xl: 9, xxl: 8 },
          attnTo: {},
          requestedDate: { xs: 14, md: 19, lg: 5, xl: 5, xxl: 4 },
          deliveryDetails,
          instructions,
        }
      }

      return {
        address: { xs: 24, lg: 16, xl: 16, xxl: 9 },
        attnTo: {},
        requestedDate: { xs: 20, md: 22, lg: 6, xl: 6, xxl: 4 },
        deliveryDetails,
        instructions,
      }
    }, [showAddressTo, showDetailButton])

    return (
      <Row gutter={[12, 8]} align="bottom" style={{ marginBottom: 16 }}>
        <Col {...colProps.address}>
          <Box display="flex" width="100%" alignItems="flex-end" style={{ gap: 12 }}>
            <DeliveryPickUpToggle
              value={delivery.is_pick_up}
              onChange={({ target }) => handleChangePickUp(target.value)}
              disabled={orderStore.isSplitting || disabled}
            />

            <FlexBoxY justifyContent="flex-start" alignItems="flex-start" width="100%">
              <ItemTitle text="Address" />
              <AddressSelector
                companyVendorId={companyVendorId}
                isPickUp={delivery.is_pick_up}
                project_ids={project_ids}
                setAddress={handleChangeAddress}
                selectedAddress={delivery.address}
                placeholder="Optional"
                wrapperProps={{
                  maxWidth: '100%',
                  minWidth: '100%',
                  width: '100%',
                }}
                disabled={(orderType === 'RFQ' && delivery.is_pick_up) || orderStore.isSplitting || disabled}
              />
            </FlexBoxY>
          </Box>
        </Col>

        {showAddressTo && (
          <Col {...colProps.attnTo}>
            <FlexBoxX width="100%" alignItems="flex-start">
              <FlexBoxY justifyContent="flex-start" alignItems="flex-start" width="0">
                <ItemTitle text="Attention To" />
                <SelectAddressTo
                  onChange={handleAddressToChange}
                  addressToOptions={addressToStore.addressesTo}
                  value={delivery.address_to?.id}
                  placeholder="Optional"
                  wrapperProps={{
                    maxWidth: '100%',
                    minWidth: '100%',
                    width: '100%',
                  }}
                  disabled={
                    (orderType === 'RFQ' && delivery.is_pick_up) ||
                    orderStore.isSplitting ||
                    disabled ||
                    !delivery.address
                  }
                />
              </FlexBoxY>
            </FlexBoxX>
          </Col>
        )}

        <Col {...colProps.requestedDate}>
          <FlexBoxY justifyContent="flex-start" alignItems="flex-start">
            <ItemTitle text="Requested Date" />
            <DateTimePicker
              value={delivery.requested_delivered_at}
              onChange={(requested_delivered_at) =>
                handleChangeDelivery('requested_delivered_at', requested_delivered_at)
              }
              placeholder="Optional"
              disabled={orderStore.isSplitting || disabled}
              wrapperProps={{ width: '100%' }}
            />
          </FlexBoxY>
        </Col>

        {/* Converted the delivery observable to js to make sure the object changes are propagated to the useEffect */}
        {showDetailButton && (
          <Col {...colProps.deliveryDetails}>
            <DeliveryDetails
              data-cy="show-delivery-details"
              delivery={{ ...delivery }}
              projectIds={project_ids}
              disabled={disabled}
              onOrderDirty={handleOrderDirty}
            />
          </Col>
        )}

        {!showDetails && (
          <Col {...colProps.instructions}>
            <DeliveryInstructions
              value={delivery.instructions}
              onChange={(value) => handleChangeDelivery('instructions', value)}
              disabled={disabled}
              isFullWidth
            />
          </Col>
        )}
      </Row>
    )
  },
)
