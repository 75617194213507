import React from 'react'

import { Checkbox, Divider } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { Box } from 'common/components/boxes'

export const ApplyToAllEmptyPhaseCodes = ({ disabled, onChangeApplyForAllEmptyPhaseCodes, applyToAllChecked }) => {
  return (
    <div
      onMouseDown={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()

        if (disabled) {
          return
        }

        onChangeApplyForAllEmptyPhaseCodes({
          target: {
            checked: !applyToAllChecked,
          },
        } as CheckboxChangeEvent)
      }}
    >
      <Divider style={{ margin: '8px 0' }} />

      <Box display="flex" px={8}>
        <Checkbox disabled={disabled} checked={applyToAllChecked}>
          Apply to all empty phase codes
        </Checkbox>
      </Box>
    </div>
  )
}
