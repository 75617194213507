import React from 'react'

import { Typography } from 'antd'

import { ReportIssueHistory } from 'contractor/server/invoices/inbox'

export type FilenameProps = { attachment: ReportIssueHistory['invoice_inbox_attachments'][0] }

export const Filename = ({ attachment }: FilenameProps) => {
  return (
    <Typography.Link target="_blank" href={attachment.url}>
      {attachment.filename}
    </Typography.Link>
  )
}
