import React from 'react'

import { Input, Modal, Select, Space, Tag, Typography } from 'antd'
import { InputProps } from 'antd/lib/input'
import { SelectProps } from 'antd/lib/select'

import { Box } from 'common/components/boxes'
import { InputCurrency, InputCurrencyProps } from 'common/components/InputCurrency'
import { currencyFormatter } from 'common/helpers/formatters'
import { VendorResponseType } from 'common/server/server_types'

export type VendorResponseColumnProps = {
  edit?: boolean
  inputNoteProps?: InputProps
  inputUnitCostProps?: InputCurrencyProps
  selectProps?: SelectProps
  allowedEditUnitCost?: boolean
}

const options = [
  { value: VendorResponseType.SUBSTITUTION, label: 'Substitution' },
  { value: VendorResponseType.DO_NOT_CARRY, label: 'Do Not Carry' },
  { value: VendorResponseType.CHANGED_UNITS, label: 'Changed Units' },
  { value: VendorResponseType.BACKORDERED, label: 'Backordered' },
  { value: VendorResponseType.OTHER, label: 'Other' },
  { value: VendorResponseType.DID_NOT_QUOTE, label: 'Did not quote' },
] as const

const changePriceOption = { value: VendorResponseType.CHANGED_PRICE, label: 'Changed Price' }

const allOptions = [...options, changePriceOption]

export const VendorResponseColumn = ({
  edit = true,
  inputNoteProps,
  inputUnitCostProps,
  selectProps,
  allowedEditUnitCost,
}: VendorResponseColumnProps) => {
  const showInputNote = !!selectProps?.value?.length || !!inputNoteProps?.value
  const showInputUnitCost = selectProps?.value?.includes(VendorResponseType.CHANGED_PRICE)

  // Keep unchanged the original value to compare if the user changed something
  const [defaultValue] = React.useState([...(selectProps?.value || [])])

  const handleChange = (value = [], option) => {
    const hasPrevChangedUnits = defaultValue?.some((item) => item === VendorResponseType.CHANGED_UNITS)
    const hasChangedUnits = value?.some((item) => item == VendorResponseType.CHANGED_UNITS)

    // We block the user from removing changed units if he has already saved
    if (hasPrevChangedUnits && !hasChangedUnits) {
      Modal.warning({ title: "Once you've saved unit changes on an item you cannot remove this tag." })
    } else {
      selectProps?.onChange(value, option)
    }
  }

  if (edit) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Select
          mode="multiple"
          options={allowedEditUnitCost ? [...options] : allOptions}
          showArrow
          style={{ width: '100%' }}
          {...selectProps}
          onChange={handleChange}
        />

        <Box display="flex" alignItems="center" style={{ gap: 8 }}>
          {showInputUnitCost && (
            <InputCurrency placeholder="New price" style={{ width: '65%' }} {...inputUnitCostProps} />
          )}
          {showInputNote && <Input placeholder="Add notes" style={{ width: '100%' }} {...inputNoteProps} />}
        </Box>
      </Space>
    )
  }

  return (
    <Box width="100%">
      <Box width="100%" display="flex" flexWrap="wrap" style={{ gap: 4 }}>
        {selectProps?.value?.map((item) => (
          <Tag
            key={item}
            color={
              [
                VendorResponseType.DO_NOT_CARRY,
                VendorResponseType.DID_NOT_QUOTE,
                VendorResponseType.CHANGED_PRICE,
              ].includes(item)
                ? 'red'
                : 'default'
            }
            style={{ margin: 0 }}
          >
            {allOptions.find((option) => option.value === item)?.label}
          </Tag>
        ))}
      </Box>

      <Box display="flex" alignItems="center" mt={4} style={{ gap: 8 }}>
        {showInputUnitCost && <Typography.Text>{currencyFormatter(inputUnitCostProps?.value)}</Typography.Text>}
        {showInputNote && <Typography.Text type="secondary">{inputNoteProps?.value}</Typography.Text>}
      </Box>
    </Box>
  )
}
