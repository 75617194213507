import React from 'react'

import { SwapOutlined } from '@ant-design/icons'
import { Button, Tag, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import { IntegrationsSourceSystem, MappingDirections } from 'common/server/server_types'

import { IntegrationIcons } from 'contractor/components/IntegrationIcons'
import { useStores } from 'contractor/hooks/use-stores'

type HeaderProps = {
  title: string
  integrations: IntegrationsSourceSystem
  handleResync: () => void
}

export const Header = observer<HeaderProps>(({ title, integrations, handleResync }) => {
  const { integrationStore } = useStores()

  const integrationName = integrationStore.getIntegrationName()

  const tagDescription =
    integrationStore.mappingDirection === MappingDirections.INSIDE_OUT
      ? `SubBase to ${integrationName}`
      : `${integrationName} to SubBase`

  const tagColor = integrationStore.mappingDirection === MappingDirections.INSIDE_OUT ? 'blue' : 'gray'

  const buttonType = integrationStore.mappingDirection === MappingDirections.INSIDE_OUT ? 'primary' : 'default'

  return (
    <Page.Header>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box justifyContent="flex-start">
          <Typography.Title level={4} style={{ margin: 0 }}>
            <IntegrationIcons type={integrations} />
            {title} mapping
            <Tag color={tagColor} style={{ marginLeft: 20 }}>
              {tagDescription}
            </Tag>
          </Typography.Title>
        </Box>
        <Box justifyContent="flex-end">
          <Button
            type={buttonType}
            style={{ marginRight: 10 }}
            icon={<SwapOutlined />}
            onClick={integrationStore.switchMappingDirection}
          >
            Switch Mapping Direction
          </Button>
          <Button type="primary" onClick={handleResync}>
            Resync
          </Button>
        </Box>
      </Box>
    </Page.Header>
  )
})
