import React, { useState } from 'react'

import { Modal, Input, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'

export type RequestCancellationProps = {
  onRequestCancellation: (cancellationReason: string) => Promise<void>
  isSubmitting?: boolean
}

export const RequestCancellation = observer<RequestCancellationProps>(({ onRequestCancellation, isSubmitting }) => {
  const [open, toggleOpen] = useState(false)
  const [cancellationReason, setCancellationReason] = useState('')

  return (
    <>
      <Modal
        title="Request cancellation"
        open={open}
        onCancel={() => toggleOpen(false)}
        okButtonProps={{
          onClick: () => onRequestCancellation(cancellationReason),
          disabled: !cancellationReason?.trim(),
          loading: isSubmitting,
        }}
        cancelButtonProps={{ loading: isSubmitting }}
      >
        <Typography.Paragraph>
          Your vendor has already confirmed this order. You can request a cancellation and await their response.
        </Typography.Paragraph>
        <Input.TextArea
          value={cancellationReason}
          onChange={(e) => setCancellationReason(e.target.value)}
          showCount
          maxLength={255}
          placeholder="Cancellation reason..."
          style={{ height: 120, resize: 'none' }}
        />
      </Modal>
      <Box color="red-6" onClick={() => toggleOpen(true)}>
        Cancel Order
      </Box>
    </>
  )
})
