import React from 'react'

import classNames from 'classnames'

import { Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { DeliveryIssues } from 'common/components/OrderMaterialsV2/Table/delivery_issues'
import { makeCostCodeValue, SelectCostCode } from 'common/components/SelectCostCode'
import { calcExtCost } from 'common/helpers/order'
import { VendorResponseType } from 'common/server/server_types'

import { MaterialsAutocomplete, MaterialsAutocompleteProps } from 'contractor/components/MaterialsAutocomplete'
import {
  SelectCostCodePhaseAdvanced,
  makeCostCodePhaseOptionAdvanced,
} from 'contractor/components/SelectCostCodePhaseAdvanced'

import {
  ActionsColumn,
  MaterialColumn,
  QuantityColumn,
  UnitColumn,
  UnitCostColumn,
  VendorResponseColumn,
  TaxColumn,
  TaxTitle,
} from '../Columns'
import { DataSource, OrderMaterialsProps } from '../order_materials'
import { RequiredMark } from '../Table/required_mark'
import { Wrapper } from './wrapper'

type ListProps = Pick<
  OrderMaterialsProps,
  | 'dataSource'
  | 'hideAddNewItem'
  | 'disabled'
  | 'canEditMaterialDatabase'
  | 'canViewHistoricalPricing'
  | 'editCompanyNote'
  | 'orderType'
  | 'requiredFields'
  | 'quantityInput'
  | 'unitInput'
  | 'taxInput'
  | 'hideUnitCost'
  | 'hideTax'
  | 'showDefaultVendor'
  | 'showDefaultVendorAlert'
  | 'companyVendorId'
  | 'showMaterialPriceDetails'
  | 'unitCostInput'
  | 'costCodeInput'
  | 'hideCostCode'
  | 'phaseCodeInput'
  | 'costCodeSettings'
  | 'hideVendorResponse'
  | 'vendorResponseInput'
  | 'orderState'
  | 'showDeliveryIssues'
  | 'commitmentId'
  | 'isRequest'
> & {
  isReplacingMaterial?: { [key: number]: boolean }
  materialsAutocompleteProps: MaterialsAutocompleteProps
  onApproveCompanyMaterial: (companyMaterial: DataSource['company_material']) => void
  onEditCompanyMaterial: (companyMaterial: DataSource['company_material']) => void
  onReplaceCompanyMaterial: (
    newCompanyMaterial: DataSource['company_material'],
    prevCompanyMaterialIndex: number,
  ) => void
  onToggleReplaceCompanyMaterial: (companyMaterialIndex: number) => void
  onTogglePriceHistoryCompanyMaterial: (companyMaterial: DataSource['company_material']) => void
  onRemoveMaterial: (index: number) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeDataWithIndex: (index: number, field: string, value: any, shouldApplyToAll?: boolean) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeDataUnitWithIndex: (index: number, value: any) => void
  onChangeVendorResponse: (data: { index: number; value: string; row: DataSource }) => void
  showDeleteItem?: boolean
}

export const List = observer<ListProps>((props) => {
  const {
    dataSource,
    disabled,
    canEditMaterialDatabase,
    canViewHistoricalPricing,
    editCompanyNote,
    orderType,
    requiredFields,
    companyVendorId,
    costCodeSettings,
    materialsAutocompleteProps,
    orderState,
    isReplacingMaterial,
    commitmentId,
    isRequest,
  } = props

  const { showDefaultVendor, showDefaultVendorAlert, showMaterialPriceDetails, showDeleteItem } = props

  const { quantityInput, unitInput, unitCostInput, costCodeInput, phaseCodeInput, taxInput, vendorResponseInput } =
    props

  const { hideAddNewItem, hideUnitCost, hideCostCode, hideVendorResponse, hideTax, showDeliveryIssues } = props

  const showCommitmentQuantity = !!commitmentId

  const {
    onReplaceCompanyMaterial,
    onToggleReplaceCompanyMaterial,
    onTogglePriceHistoryCompanyMaterial,
    onEditCompanyMaterial,
    onRemoveMaterial,
    onChangeDataWithIndex,
    onChangeDataUnitWithIndex,
    onChangeVendorResponse,
    onApproveCompanyMaterial,
  } = props

  const isRequiredField = (field: string) => {
    if (isRequest) {
      return requiredFields?.request?.[field]
    }

    if (orderType === 'Order') {
      return requiredFields?.order?.[field]
    }

    return requiredFields?.quote?.[field]
  }

  return (
    <>
      <Wrapper width="100%" alignItems="flex-start">
        {dataSource.map((data, index) => {
          const companyMaterial = data.company_material
          const extCost = calcExtCost({
            unitCost: Number(data?.unit_cost),
            quantity: data?.quantity,
            multiplier: Number(data?.unit?.original?.multiplier),
            qtyIncrement: Number(data?.unit?.original?.qty_increment),
          })

          const preferredVendorPrices =
            data?.company_material['company_material_vendor_prices'] ||
            data?.company_material['preferred_vendor_prices']

          const remainingQuantity = Number(data.remaining_quantity)

          return (
            <FlexBoxY
              width="100%"
              key={`row-${index}`}
              p={8}
              borderRadius={4}
              className={classNames(
                { requested_material: data?.company_material?.['requested_by_id'] && !!orderState },
                'row-item',
              )}
            >
              <FlexBoxX width="100%" alignItems="flex-start" mb={12}>
                {!hideAddNewItem && (
                  <Box mr={12}>
                    <ActionsColumn
                      disabled={disabled}
                      onApprove={() => onApproveCompanyMaterial(data?.company_material)}
                      onEdit={() => onEditCompanyMaterial(data?.company_material)}
                      onTogglePriceHistory={() => onTogglePriceHistoryCompanyMaterial(data?.company_material)}
                      onToggleReplace={() => onToggleReplaceCompanyMaterial(index)}
                      onRemove={() => onRemoveMaterial(index)}
                      canEditMaterialDatabase={canEditMaterialDatabase}
                      canViewHistoricalPricing={canViewHistoricalPricing}
                      showApprove={data?.company_material?.['requested_at']}
                      showDelete={showDeleteItem}
                      isReplacingMaterial={isReplacingMaterial[index]}
                    />
                  </Box>
                )}
                {isReplacingMaterial[index] ? (
                  <MaterialsAutocomplete
                    {...materialsAutocompleteProps}
                    onSelect={(newCompanyMaterial) => onReplaceCompanyMaterial(newCompanyMaterial, index)}
                    placeholder={`Replacing "${companyMaterial.description}"`}
                  />
                ) : (
                  <MaterialColumn
                    description={companyMaterial.description}
                    disabled={disabled}
                    item={companyMaterial}
                    deliveryIssue={data?.has_open_issue}
                    companyNote={data?.company_note}
                    onChangCompanyNote={(value) => {
                      if (!editCompanyNote) return

                      onChangeDataWithIndex(index, 'company_note', value)
                    }}
                  />
                )}
              </FlexBoxX>

              <FlexBoxX width="100%" mb={12}>
                <Box width="30%" mr={8} textAlign="end">
                  <RequiredMark title="Qty" isRequired={isRequiredField('quantity')} isMobile={true} />
                </Box>
                <FlexBoxX width="70%">
                  {data?.has_open_issue && showDeliveryIssues && <DeliveryIssues orderMaterial={data} />}
                  <QuantityColumn
                    value={data?.quantity}
                    remainingQuantity={showCommitmentQuantity && data?.remaining_quantity}
                    onChange={(value) => onChangeDataWithIndex(index, 'quantity', value)}
                    disabled={disabled}
                    prevValue={data?.prev_quantity}
                    {...quantityInput}
                    edit={data?.vendor_response?.includes(VendorResponseType.CHANGED_UNITS) || quantityInput?.edit}
                  />
                  {showCommitmentQuantity && <FlexBoxX width="100%">Remaining: {remainingQuantity}</FlexBoxX>}
                </FlexBoxX>
              </FlexBoxX>

              <FlexBoxX width="100%" mb={12}>
                <Box width="30%" mr={8} textAlign="end">
                  <RequiredMark title="Unit" isRequired={isRequiredField('unit')} isMobile={true} />
                </Box>
                <Box width="70%">
                  <UnitColumn
                    value={data?.unit}
                    onChange={(_, value) => onChangeDataUnitWithIndex(index, value)}
                    prevValue={data?.prev_unit?.label}
                    {...unitInput}
                    disabled={disabled || unitInput?.disabled}
                    edit={data?.vendor_response?.includes(VendorResponseType.CHANGED_UNITS) || unitInput?.edit}
                  />
                </Box>
              </FlexBoxX>

              {!hideUnitCost && (
                <FlexBoxX width="100%" mb={12} alignItems="baseline">
                  <Box width="30%" mr={8} textAlign="end">
                    <Typography.Text>Unit Cost</Typography.Text>
                  </Box>

                  <Box width="70%">
                    <UnitCostColumn
                      extCost={extCost}
                      disabled={disabled}
                      unitCost={Number(data?.unit_cost || 0)}
                      defaultVendors={showDefaultVendor ? preferredVendorPrices : []}
                      showDefaultVendorAlert={showDefaultVendorAlert}
                      companyMaterialId={companyMaterial['is_catalog'] ? null : companyMaterial?.id}
                      companyVendorId={companyVendorId}
                      companyMaterialDescription={companyMaterial.description}
                      showMaterialPriceDetails={showMaterialPriceDetails}
                      {...unitCostInput}
                      onChange={(value, params) => {
                        const { defaultVendor } = params || {}
                        onChangeDataWithIndex(index, 'unit_cost', value)
                        onChangeDataWithIndex(index, 'preferred_vendor_price', defaultVendor)
                        unitCostInput?.onChange?.(value, { defaultVendor, index })
                      }}
                      onChangeDefaultVendorSelected={(defaultVendor) =>
                        onChangeDataWithIndex(index, 'preferred_vendor_price', defaultVendor)
                      }
                      defaultVendorSelected={data['preferred_vendor_price']}
                    />
                  </Box>
                </FlexBoxX>
              )}

              {!hideTax && (
                <FlexBoxX width="100%" mb={12} alignItems="baseline">
                  <Box width="30%" mr={8} textAlign="end">
                    <TaxTitle isRequired={isRequiredField('tax_value')} />
                  </Box>

                  <Box width="70%">
                    <TaxColumn
                      value={data?.tax_value}
                      onChange={(value) => onChangeDataWithIndex(index, 'tax_value', value)}
                      {...taxInput}
                      extCost={extCost}
                    />
                  </Box>
                </FlexBoxX>
              )}

              {!hideCostCode && (
                <FlexBoxX width="100%" mb={12}>
                  <Box width="30%" mr={8} textAlign="end">
                    <RequiredMark title="Cost Code" isRequired={isRequiredField('cost_code')} isMobile={true} />
                  </Box>
                  <Box width="70%" display="grid" style={{ gap: 8 }}>
                    <SelectCostCode
                      value={makeCostCodeValue(data['cost_code'], costCodeInput?.costCodeSettings)}
                      onChange={(option, shouldPropagateValue = false) =>
                        onChangeDataWithIndex(index, 'cost_code', option?.costCode, shouldPropagateValue)
                      }
                      disabled={disabled}
                      {...costCodeInput}
                      defaultApplyToAllChecked={data.default_cost_code_to_be_applied}
                      onChangeApplyToAll={(props) =>
                        costCodeInput.onChangeApplyToAll({
                          ...props,
                          index,
                        })
                      }
                    />
                    {costCodeSettings?.independent_phase_codes_enabled && (
                      <SelectCostCodePhaseAdvanced
                        value={makeCostCodePhaseOptionAdvanced(data['cost_code_phase'])}
                        onChange={(option = null, shouldPropagateValue = false) =>
                          onChangeDataWithIndex(index, 'cost_code_phase', option?.originalObject, shouldPropagateValue)
                        }
                        disabled={disabled || !!commitmentId}
                        {...phaseCodeInput}
                        defaultApplyToAllChecked={data.default_cost_code_to_be_applied}
                        onChangeApplyToAll={(props) => {
                          phaseCodeInput.onChangeApplyToAll({
                            ...props,
                            index,
                          })
                        }}
                      />
                    )}
                  </Box>
                </FlexBoxX>
              )}

              {!hideVendorResponse && (
                <FlexBoxX width="100%" mb={12}>
                  <Box width="30%" mr={8} textAlign="end">
                    <Typography.Text>Vendor Response</Typography.Text>
                  </Box>
                  <Box width="70%">
                    <VendorResponseColumn
                      inputNoteProps={{
                        value: data?.vendor_note,
                        onChange: ({ target }) => onChangeDataWithIndex(index, 'vendor_note', target.value),
                        disabled,
                        ...vendorResponseInput?.inputNoteProps,
                      }}
                      selectProps={{
                        value: data?.vendor_response || [],
                        onChange: (value = []) => onChangeVendorResponse({ index, value, row: data }),
                        disabled,
                        ...vendorResponseInput?.selectProps,
                      }}
                      {...vendorResponseInput}
                    />
                  </Box>
                </FlexBoxX>
              )}
            </FlexBoxY>
          )
        })}

        {!hideAddNewItem && <MaterialsAutocomplete {...materialsAutocompleteProps} />}
      </Wrapper>
    </>
  )
})
