import React, { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import UploaderModal from 'common/components/uploaderModal'
import { File } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

type InvoiceMailAttachmentsProps = {
  attachments: File[]
}

export const InvoiceMailAttachments = observer<InvoiceMailAttachmentsProps>(({ attachments }) => {
  const { uploaderStore } = useStores()

  useEffect(() => {
    uploaderStore.resetAllUploads()
    uploaderStore.addExistingFiles('mail_attachments', attachments)
  }, [attachments, attachments?.length])

  return (
    <UploaderModal
      multiple
      uploadKey="mail_attachments"
      title="Attachments"
      disabled
      fileList={uploaderStore.fileList('mail_attachments')}
    />
  )
})
