import React from 'react'

import { Typography, Button } from 'antd'

import { Box } from 'common/components/boxes'
import { DateRangePicker } from 'common/components/DateRangePicker'
import { SelectFilter } from 'common/components/table/postgres_filters'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

interface Props {
  phaseCodeEnabled: boolean
  hasShowCostCodeFilter: boolean
  facets: { [key: string]: Array<string> }
  values?: { [key: string]: string }
}

export function OrderMaterialsFilters(props: Props) {
  const { facets, values = {}, phaseCodeEnabled, hasShowCostCodeFilter } = props

  const persistentFilters = usePersistentFilters()

  const optionsFactory = React.useCallback(
    (key: string, nameAttributeKey: string) => {
      return (facets[key] || []).map((x) => ({
        label: x[nameAttributeKey],
        value: x[nameAttributeKey],
      }))
    },
    [facets],
  )

  return (
    <Box display="flex" flexDirection="column" p="12" gridGap={16}>
      <Box width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Vendor</Typography.Text>

        <SelectFilter
          items={optionsFactory('vendor_names', 'vendor_name')}
          selected={values.company_vendor_names}
          onChange={(value) => persistentFilters.handleChangeFilters({ company_vendor_names: value })}
          attribute="company_vendor_names"
          placeholder="Select vendor"
          isSearchable
        />
      </Box>

      <Box width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Ordered by</Typography.Text>

        <SelectFilter
          items={optionsFactory('ordered_by', 'ordered_by')}
          selected={values.order_ordered_by}
          onChange={(value) => persistentFilters.handleChangeFilters({ order_ordered_by: value })}
          attribute="order_ordered_by"
          placeholder="Select ordered by"
          isSearchable
        />
      </Box>

      {phaseCodeEnabled && hasShowCostCodeFilter && (
        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Phase Code</Typography.Text>

          <SelectFilter
            items={optionsFactory('phase_codes', 'phase_code')}
            selected={values.cost_code_phase_codes}
            onChange={(value) => persistentFilters.handleChangeFilters({ cost_code_phase_codes: value })}
            attribute="cost_code_phase_codes"
            placeholder="Select phase code"
            isSearchable
          />
        </Box>
      )}

      {hasShowCostCodeFilter && (
        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Cost code filter</Typography.Text>

          <SelectFilter
            items={optionsFactory('cost_codes', 'cost_code')}
            selected={values.cost_code_codes}
            onChange={(value) => persistentFilters.handleChangeFilters({ cost_code_codes: value })}
            attribute="cost_code_codes"
            placeholder="Select cost code filter"
            isSearchable
          />
        </Box>
      )}

      {phaseCodeEnabled && hasShowCostCodeFilter && (
        <Box width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Class</Typography.Text>

          <SelectFilter
            items={optionsFactory('class_codes', 'class_code')}
            selected={values.cost_code_classes}
            onChange={(value) => persistentFilters.handleChangeFilters({ cost_code_classes: value })}
            attribute="cost_code_classes"
            placeholder="Select class"
            isSearchable
          />
        </Box>
      )}

      <Box width="100%">
        <Typography.Text type="secondary">Ordered at</Typography.Text>

        <DateRangePicker
          placeholderStart="Start date"
          placeholderEnd="End date"
          attribute="order_ordered_at_timestamp"
          value={{
            startDate: values['order_ordered_at_start'],
            endDate: values['order_ordered_at_end'],
          }}
          onChange={({ startDate, endDate }) => {
            persistentFilters.handleChangeFilters({
              order_ordered_at_start: startDate,
              order_ordered_at_end: endDate,
            })
          }}
        />
      </Box>

      <Button
        style={{ marginTop: 16, width: '100%' }}
        type="primary"
        data-cy="clear-filters"
        onClick={() => persistentFilters.handleClearFilters()}
        disabled={persistentFilters.appliedFiltersCount === 0}
      >
        Clear All
      </Button>
    </Box>
  )
}
