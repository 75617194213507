import React from 'react'

import { Skeleton } from 'antd'

import { Box } from 'common/components/boxes'

const skeletonRows = new Array(3).fill(0)

export const LoadingState = () => {
  return (
    <Box width="100%">
      {skeletonRows.map((_, index) => (
        <Skeleton key={index} avatar={{ size: 'default' }} paragraph={{ rows: 2 }} />
      ))}
    </Box>
  )
}
