import { useState, useEffect, useCallback } from 'react'

import { useTheme } from 'common/hooks/use-theme'

export type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

export const useMediaQuery = (breakpoint: Breakpoints) => {
  const { breakpoints } = useTheme()

  const query = `(max-width: ${breakpoints[breakpoint]})`

  const getMatches = useCallback((query: string) => {
    return window.matchMedia(query).matches
  }, [])

  const [matches, setMatches] = useState(() => getMatches(query))

  useEffect(() => {
    const matchMedia = window.matchMedia(query)

    const handleChange = () => {
      setMatches(getMatches(query))
    }

    handleChange()

    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange)
    } else {
      matchMedia?.addEventListener('change', handleChange)
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange)
      } else {
        matchMedia?.removeEventListener('change', handleChange)
      }
    }
  }, [query, getMatches])

  return matches
}
