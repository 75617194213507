import React from 'react'

import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'

import { Box } from 'common/components/boxes'
import { useTheme } from 'common/hooks/use-theme'

type ApproveRejectButtonProps = {
  isTitle?: boolean
  handleApprove: () => void
  handleReject: () => void
  rowId: string
} & ButtonProps

export const ApproveRejectButton = ({
  isTitle,
  handleApprove,
  rowId,
  handleReject,
  ...props
}: ApproveRejectButtonProps) => {
  const theme = useTheme()

  const rejectTooltip = isTitle ? 'Reject Page' : 'Reject'
  const approveTooltip = isTitle ? 'Approve Page' : 'Approve'

  const rejectKey = isTitle ? `reject-page-${rowId}` : `reject-${rowId}`
  const approveKey = isTitle ? `approve-page-${rowId}` : `approve-${rowId}`

  const rejectProps = isTitle ? { type: 'ghost' } : { style: { color: theme.colors.error } }
  const approveProps = isTitle
    ? { type: 'primary', style: { marginLeft: 4 } }
    : { style: { color: theme.colors.success, marginLeft: 4 } }

  return (
    <Box width="100%" display="flex" alignItems="center">
      <Tooltip title={rejectTooltip}>
        {/* @ts-ignore */}
        <Button key={rejectKey} shape="circle" onClick={handleReject} {...rejectProps} {...props}>
          <CloseOutlined style={{ paddingTop: 4 }} />
        </Button>
      </Tooltip>
      <Tooltip title={approveTooltip}>
        {/* @ts-ignore */}
        <Button key={approveKey} shape="circle" onClick={handleApprove} {...approveProps} {...props}>
          <CheckOutlined style={{ paddingTop: 4 }} />
        </Button>
      </Tooltip>
    </Box>
  )
}
