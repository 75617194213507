import React, { useMemo } from 'react'

import styled from '@emotion/styled'

import { EyeOutlined } from '@ant-design/icons'
import { Button, Checkbox, Typography } from 'antd'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import theme from 'common/styles/theme'

type Option = {
  id?: string
  group_name: string
  group_total: number
  quantity_shipped: number
  uom?: string
  unit_price: number
  unit_qty_increment?: number
  extended_price?: number
  checked?: boolean
  calcExtCost?: (...rest) => number
}

type OnAction = (itemId: string) => void

type Column = {
  id: string
  label?: string | React.ReactNode
  render: (value, option: Option) => React.ReactNode
  width?: number | string
}

const BadgeGroupName = styled(Box)`
  background-color: ${({ theme }) => theme.colors['gray-4']};
  border-radius: ${({ theme }) => theme.radii.lg};
  padding: 0 4px;
`

type InvoiceGroupItemsProps = {
  group: Option
  onCheck: OnAction
  onEdit: OnAction
  isDisabled?: boolean
}

const InvoiceGroupItems = ({ isDisabled, group, onEdit, onCheck }: InvoiceGroupItemsProps) => {
  const COLUMNS: Column[] = useMemo(
    () => [
      {
        id: 'group_name',
        label: (
          <FlexBoxX style={{ gap: 4 }}>
            <Typography.Text>Group name</Typography.Text>
            <BadgeGroupName>{group.group_total} Grouped Items</BadgeGroupName>
            <Button type="text" icon={<EyeOutlined />} onClick={() => onEdit(group.id)} />
          </FlexBoxX>
        ),
        render: (value) => <Typography.Text>{value}</Typography.Text>,
        width: '100%',
      },
      {
        id: 'quantity_shipped',
        label: 'Qty',
        render: (value) => <Typography.Text>{value || '-'}</Typography.Text>,
        width: 100,
      },
      {
        id: 'uom',
        label: 'UOM',
        render: (value) => <Typography.Text>{value || '-'}</Typography.Text>,
        width: 150,
      },
      {
        id: 'unit_price',
        label: 'Unit cost',
        render: (value) => <Typography.Text>{value ? currencyFormatter(value) : '-'}</Typography.Text>,
        width: 150,
      },
      {
        id: 'extended_price',
        label: 'Ext. cost',
        render: (value) => <Typography.Text>{currencyFormatter(value)}</Typography.Text>,
        width: 200,
      },
    ],
    [group.group_total],
  )

  const renderColumn = React.useCallback(
    (column: Column, option: Option) => (
      <FlexBoxY key={`${option.group_name}-${column.id}`} alignItems="baseline" width={column.width}>
        <Typography.Text color={theme.colors['gray-8']} style={{ fontSize: 10 }}>
          {column.label}
        </Typography.Text>
        <Typography.Text color={theme.colors['gray-12']}>{column.render(option[column.id], option)}</Typography.Text>
      </FlexBoxY>
    ),
    [],
  )

  return (
    <FlexBoxX alignItems="center" style={{ gap: 16 }}>
      <Checkbox onChange={() => onCheck(group.id)} checked={group.checked} disabled={isDisabled} />
      {COLUMNS.map((column) => renderColumn(column, group))}
    </FlexBoxX>
  )
}

export { InvoiceGroupItems }
