import React, { useEffect, useMemo, useState } from 'react'

import { flatten, sortBy } from 'lodash'

import styled from '@emotion/styled'

import { Empty, Space, Table, TableProps } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { EditableTable } from 'common/components/EditableTable'
import { RibbonFilter } from 'common/components/RibbonFilter'
import { roundValue } from 'common/helpers/order'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'
import { ExpandButton } from '../InvoiceMaterials/expand_button'
import { DeliveryButton } from './delivery_button'
import { getExpandablecolumns } from './expandable_table_columns'
import { getTableColumns } from './table_columns'
import { TableOptions } from './table_options'
import {
  calculateInvoiceSubtotal,
  calculateOrderedDiscount,
  calculateOrderedOther,
  calculateOrderedShipping,
  calculateOrderedTax,
  calculateTotalInvoiced,
  getInvoicesRelatedToOrder,
  makeInvoiceDiscountRows,
  makeInvoiceOtherRows,
  makeInvoiceShippingRows,
  makeInvoiceTaxRows,
  makeInvoiceTotalRows,
  makeInvoiceUnreconciledItemsRows,
  makeOrderDiscountRow,
  makeOrderOtherRow,
  makeOrderShippingRow,
  makeOrderTaxRow,
  makeOrderTotalRow,
} from './utils'

const makeFilterOption = ({ order }) => {
  return { label: order['order_number'], filter: order['id'] }
}
const TableStyled = styled(Table)`
  .ant-table-row-expand-icon-cell {
    text-align: center;
  }

  .ant-table-expanded-row {
    .ant-table.ant-table-small {
      margin: -8px -8px -8px 28px !important;
    }

    .ant-table-row:hover,
    .ant-table-cell {
      background: ${({ theme }) => theme.colors['gray-2']} !important;
      color: #00000082;
    }
  }
` as <T>(props: TableProps<T>) => React.ReactElement

type OrderMaterialsProps = {
  visible: boolean
}

export const reviewStepTableColumnLocalStorageKey = '@subbase/review-step/table-columns'

const setLocalStorage = (value) => localStorage.setItem(reviewStepTableColumnLocalStorageKey, JSON.stringify(value))

const getInitialVisibleColumns = ({
  taxLineItemsEnabled,
  canUseCostCode,
  companyAttributes,
  independentPhaseCodesEnabled,
  canUseRetainage,
}) => {
  const columns = [
    { id: 1, Header: 'Qty', dataIndex: 'quantity', isVisible: true },
    { id: 2, Header: 'UOM', dataIndex: 'unit', isVisible: true },
    { id: 3, Header: 'Unit Cost', dataIndex: 'unit_cost', isVisible: true },
    ...(taxLineItemsEnabled
      ? [
          { id: 4, Header: 'Tax', dataIndex: 'tax_split', isVisible: true },
          { id: 5, Header: 'Ext. Cost + Tax', dataIndex: 'ext_cost_with_tax', isVisible: true },
        ]
      : []),
    { id: 6, Header: 'Ordered', dataIndex: 'ordered', isVisible: true },
    { id: 7, Header: 'Invoiced', dataIndex: 'invoiced', isVisible: true },
    { id: 8, Header: 'Remaining', dataIndex: 'remaining', isVisible: true },
  ]

  if (canUseCostCode && companyAttributes.includes('cost_code_id')) {
    columns.push({ id: 9, Header: 'Cost Code', dataIndex: 'cost_code', isVisible: true })

    if (independentPhaseCodesEnabled) {
      columns.push({ id: 10, Header: 'Phase Code', dataIndex: 'cost_code_phase', isVisible: true })
    }
  }

  if (canUseRetainage) {
    columns.push({ id: 11, Header: 'Retainage amount', dataIndex: 'retainage', isVisible: true })
  }

  return columns
}

export const OrderMaterials = observer<OrderMaterialsProps>(({ visible }) => {
  const { invoiceStore, userStore, companySettingStore } = useStores()

  const {
    taxAmountField,
    shippingCostField,
    discountAmountField,
    otherCostsField,
    calculatedGrandTotal,
    calculatedEligibleTaxSplitTotal,
    allInvoicesExceptTheCurrent,
    calcExtCost,
    calcTaxSplitBulk,
    taxLineItemsEnabled,
  } = useInvoice()

  const canUseRetainage = useFlag('retainage_v1')

  const { invoice } = invoiceStore

  const [filter, setFilter] = useState(['all'])
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([])
  const [visibleColumns, setVisibleColumns] = useState(() =>
    getInitialVisibleColumns({
      taxLineItemsEnabled,
      canUseCostCode: userStore.canUseCostCode,
      companyAttributes: companySettingStore.companyMaterialConfiguration?.company_attributes,
      independentPhaseCodesEnabled:
        companySettingStore.otherSettings?.cost_code_settings?.independent_phase_codes_enabled,
      canUseRetainage,
    }),
  )

  useEffect(() => {
    const savedColumns = JSON.parse(window.localStorage.getItem(reviewStepTableColumnLocalStorageKey))

    const defaultColumns = getInitialVisibleColumns({
      taxLineItemsEnabled,
      canUseCostCode: userStore.canUseCostCode,
      companyAttributes: companySettingStore.companyMaterialConfiguration?.company_attributes,
      independentPhaseCodesEnabled:
        companySettingStore.otherSettings?.cost_code_settings?.independent_phase_codes_enabled,
      canUseRetainage,
    })

    if (savedColumns) {
      const mergedColumns = defaultColumns.map((defaultCol) => {
        const savedCol = savedColumns.find((savedCol) => savedCol.id === defaultCol.id)
        return savedCol ? { ...defaultCol, isVisible: savedCol.isVisible } : defaultCol
      })
      setLocalStorage(mergedColumns)
      setVisibleColumns(mergedColumns)
    } else {
      setLocalStorage(defaultColumns)
      setVisibleColumns(defaultColumns)
    }
  }, [
    taxLineItemsEnabled,
    userStore.canUseCostCode,
    companySettingStore.companyMaterialConfiguration?.company_attributes,
    companySettingStore.otherSettings?.cost_code_settings?.independent_phase_codes_enabled,
  ])

  const handleToggleExpandRowKeys = () => {
    if (expandedRowKeys.length) {
      setExpandedRowKeys([])
    } else {
      const allOrderMaterialsKeys = invoiceStore?.selectedOrders?.map((order) =>
        order.order.order_materials?.map((om) => om.id),
      )
      const ordersKeys = invoiceStore.selectedOrders.map(({ order }) => [
        `tax-${order.id}`,
        `shipping-${order.id}`,
        `other-${order.id}`,
        `discount-${order.id}`,
        `total-${order.id}`,
      ])
      setExpandedRowKeys([...flatten(ordersKeys), ...flatten(allOrderMaterialsKeys)])
    }
  }

  const filterVisibleColumn = (column) => {
    return visibleColumns.some(
      (visibleColumn) => visibleColumn.dataIndex === column.dataIndex && visibleColumn.isVisible,
    )
  }

  const handleApplytRibbonFilter = (orderMaterial) => {
    if (filter.includes('all')) {
      return true
    }

    return filter.includes(orderMaterial['order_id'])
  }

  const handleChangeVisibleColumns = (changedVisibleColumns) => {
    setLocalStorage(changedVisibleColumns)
    setVisibleColumns(changedVisibleColumns)
  }

  /*
    Get all invoice materials from all invoices present in the orders related to the current invoice.
    Except for the current invoice which is loaded in invoiceStore.invoice.
  */
  const allInvoicesMaterialsExceptTheCurrent = useMemo(() => {
    const allInvoicesExceptTheCurrentMatrix = allInvoicesExceptTheCurrent.map((currentInvoice) =>
      currentInvoice.invoice_materials.map((invoiceMaterial) => ({
        ...invoiceMaterial,
        number: currentInvoice.number,
      })),
    )

    return flatten(allInvoicesExceptTheCurrentMatrix)
  }, [allInvoicesExceptTheCurrent.length])

  const currentInvoiceMaterials = calcTaxSplitBulk({
    taxAmount: taxAmountField,
    taxLineItemsEnabled,
    invoiceMaterials: invoice?.invoice_materials?.map((invoiceMaterial) => ({
      ...invoiceMaterial,
      number: invoice.number,
    })),
  })

  // Merge the current invoice materials with all invoices materials from the all orders related to the current invoice
  const allInvoicesMaterials = [...allInvoicesMaterialsExceptTheCurrent, ...currentInvoiceMaterials]

  /*
    Combine all invoices except the current with the current invoice,
    to keep the current invoice values updated based on the user changes
  */
  const allInvoices = [
    {
      ...invoice,
      invoice_grand_total: calculatedGrandTotal,
      eligible_tax_split_total: calculatedEligibleTaxSplitTotal,
      tax_amount: taxAmountField,
      shipping_cost: shippingCostField,
      other_costs: otherCostsField,
      discount_amount: discountAmountField,
    },
    ...allInvoicesExceptTheCurrent,
  ]

  const mapCurrentInvoiceMaterial = (invoiceMaterial) => {
    const invoice = allInvoices.find((inv) => inv.id === invoiceMaterial.invoice_id)

    const taxAmount = invoice?.tax_amount || 0
    const eligibleTaxSplitTotal = invoice?.eligible_tax_split_total || 0

    const common = {
      ...invoiceMaterial,
      tax_amount: taxAmount,
      eligible_tax_split_total: eligibleTaxSplitTotal,
    }

    if (!invoiceMaterial.accepts_tax_split) {
      return common
    }

    const extCost = calcExtCost({
      unitCost: invoiceMaterial?.unit_price,
      quantity: invoiceMaterial?.quantity_shipped,
      multiplier: invoiceMaterial?.unit?.multiplier,
      qtyIncrement: invoiceMaterial?.unit?.qty_increment,
    })

    const extCostWithTax = extCost + invoiceMaterial.tax_split

    return { ...common, ext_cost_with_tax: extCostWithTax }
  }

  const dataSource = flatten(
    invoiceStore.selectedOrders?.map(({ order }) => {
      // Add to the current order materials all invoice materials related
      const orderMaterials = order.order_materials.map((orderMaterial) => {
        // Get the invoice materials related to the current order material
        const invoiceMaterials = allInvoicesMaterials
          ?.filter((invoiceMaterial) => invoiceMaterial?.order_materials?.some((om) => om.id === orderMaterial.id))
          ?.map(mapCurrentInvoiceMaterial)

        const common = {
          ...orderMaterial,
          order_id: order.id,
          order_number: order.order_number,
          sub_total: order.sub_total,
        }

        return invoiceMaterials.length ? { ...common, invoice_materials: invoiceMaterials } : common
      })

      const invoiceNotReconciledItems = currentInvoiceMaterials
        .filter((invoiceMaterial) => toJS(invoiceMaterial.order_materials || [])?.length == 0)
        .map(mapCurrentInvoiceMaterial)
      const transformedInvoicedMaterials = invoice?.marked_reconciled_at
        ? makeInvoiceUnreconciledItemsRows(invoiceNotReconciledItems, invoice)
        : []

      const orderedTax = calculateOrderedTax(order?.deliveries)
      const orderedShipping = calculateOrderedShipping(order?.deliveries)
      const orderedOther = calculateOrderedOther(order?.deliveries)
      const orderedDiscount = calculateOrderedDiscount(order?.deliveries)

      const invoicesRelatedToCurrentOrder = getInvoicesRelatedToOrder(order, allInvoices)

      // Create the expandable row for the invoice taxes.
      const expandableInvoicesTaxRows = makeInvoiceTaxRows(invoicesRelatedToCurrentOrder)
      const expandableInvoicesShippingRows = makeInvoiceShippingRows(invoicesRelatedToCurrentOrder)
      const expandableInvoicesOtherRows = makeInvoiceOtherRows(invoicesRelatedToCurrentOrder)
      const expandableInvoicesDiscountRows = makeInvoiceDiscountRows(invoicesRelatedToCurrentOrder)

      const invoicedTax = calculateTotalInvoiced(expandableInvoicesTaxRows)
      const invoicedShipping = calculateTotalInvoiced(expandableInvoicesShippingRows)
      const invoicedOther = calculateTotalInvoiced(expandableInvoicesOtherRows)
      const invoicedDiscount = calculateTotalInvoiced(expandableInvoicesDiscountRows)

      const taxRow = makeOrderTaxRow({ order, orderedTax, invoicedTax, expandableInvoicesTaxRows })
      const shippingRow = makeOrderShippingRow({
        order,
        orderedShipping,
        invoicedShipping,
        expandableInvoicesShippingRows,
      })
      const otherRow = makeOrderOtherRow({ order, orderedOther, invoicedOther, expandableInvoicesOtherRows })
      const discountRow = makeOrderDiscountRow({
        order,
        orderedDiscount,
        invoicedDiscount,
        expandableInvoicesDiscountRows,
      })

      const invoicedSubtotal = calculateInvoiceSubtotal(invoicesRelatedToCurrentOrder, calcExtCost)
      const invoicedTotal = invoicedSubtotal + invoicedTax + invoicedShipping + invoicedOther - invoicedDiscount

      // Create the expandable row for the invoice total.
      const invoicesTotalRows = makeInvoiceTotalRows(invoicesRelatedToCurrentOrder, [
        ...orderMaterials,
        ...transformedInvoicedMaterials,
      ])

      // Create the row row for the order total.
      const totalRow = makeOrderTotalRow({
        order,
        tax: orderedTax,
        invoicedTotal,
        invoicesTotalRows,
        remaining:
          roundValue(Number(order.grand_total), invoice?.decimal_precision) -
          roundValue(invoicedTotal, invoice?.decimal_precision),
        precision: invoice.decimal_precision,
      })

      const taxesRows = [
        { ordered: orderedTax, invoiced: invoicedTax, row: taxRow },
        { ordered: orderedShipping, invoiced: invoicedShipping, row: shippingRow },
        { ordered: orderedOther, invoiced: invoicedOther, row: otherRow },
        { ordered: orderedDiscount, invoiced: invoicedDiscount, row: discountRow },
      ]

      // Only add the taxes rows if there is any value in invoices or order
      const taxesRowsToShow = taxesRows
        .filter(({ ordered, invoiced }) => ordered !== 0 || invoiced !== 0)
        .map(({ row }) => row)

      // Only show the tax row if the tax to line items is disabled
      return [
        ...orderMaterials,
        ...transformedInvoicedMaterials,
        ...(taxLineItemsEnabled ? [] : taxesRowsToShow),
        totalRow,
      ]
    }),
  )

  const { fixedFirstColumns, columnsToHide } = getTableColumns()
  const { expandableFixedFirstColumns, expandableColumnsToHide } = getExpandablecolumns()

  const ordersOptions = sortBy(invoiceStore.selectedOrders, ({ order }) => order?.order_number)?.map(makeFilterOption)

  return (
    <Box display={visible ? 'block' : 'none'} width="100%" minWidth={1280}>
      <Box width="100%" display="flex" alignContent="center" justifyContent="space-between">
        <div>
          {invoiceStore.selectedOrders?.length > 1 && (
            <RibbonFilter
              boxProps={{ mb: 12 }}
              value={filter}
              onChange={setFilter}
              options={[{ label: 'All', filter: 'all' }, ...ordersOptions]}
            />
          )}
        </div>

        <Space>
          <TableOptions value={visibleColumns} onChange={handleChangeVisibleColumns} />
          <DeliveryButton />
        </Space>
      </Box>

      <TableStyled
        data-cy="order-materials-table"
        rowKey="id"
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No order materials." />,
        }}
        columns={[...fixedFirstColumns, ...columnsToHide.filter(filterVisibleColumn), Table.EXPAND_COLUMN]}
        dataSource={dataSource.filter(handleApplytRibbonFilter)}
        pagination={false}
        size="small"
        expandable={{
          columnTitle: () => {
            if (dataSource?.some((orderMaterial) => !!orderMaterial?.invoice_materials?.length)) {
              return (
                <ExpandButton
                  onToggleExpandRowKeys={handleToggleExpandRowKeys}
                  hasExpandedRowKeys={!!expandedRowKeys.length}
                />
              )
            }
          },
          onExpandedRowsChange: (expandedRows) => setExpandedRowKeys(expandedRows as React.Key[]),
          expandedRowKeys,
          expandRowByClick: true,
          expandedRowRender: (orderMaterial, index) => (
            <EditableTable
              index={index}
              data-cy="order-materials-table-expanded-row"
              rowKey={(record) => `expandable-${record.id}`}
              showHeader={false}
              pagination={false}
              size="small"
              dataSource={orderMaterial?.invoice_materials}
              columns={[
                ...expandableFixedFirstColumns,
                ...expandableColumnsToHide.filter(filterVisibleColumn),
                { dataIndex: 'expand_column', width: 48 },
              ]}
            />
          ),
          rowExpandable: (orderMaterial) => !!orderMaterial?.invoice_materials?.length,
        }}
      />
    </Box>
  )
})
