import { getSpreadValueByDot } from 'common/helpers/formatters'
import { roundValue } from 'common/helpers/order'
import { InvoiceMaterial, InvoiceResponse } from 'common/server/invoice'

export const calculateOrderedTax = (deliveries) => {
  return deliveries?.reduce((acc, delivery) => {
    return acc + Number(delivery.tax_value)
  }, 0)
}

export const calculateOrderedShipping = (deliveries) => {
  return deliveries?.reduce((acc, delivery) => {
    return acc + Number(delivery.shipping_value)
  }, 0)
}

export const calculateOrderedOther = (deliveries) => {
  return deliveries?.reduce((acc, delivery) => {
    return acc + Number(delivery.other_value)
  }, 0)
}

export const calculateOrderedDiscount = (deliveries) => {
  return deliveries?.reduce((acc, delivery) => {
    return acc + Number(delivery.discount_value)
  }, 0)
}

export const calculateInvoiceSubtotal = (invoicesRelatedToCurrentOrder) => {
  const maxFractionalLength = invoicesRelatedToCurrentOrder.reduce((maxLength, currentInvoice) => {
    if (!currentInvoice?.invoice_materials) return maxLength

    const currentMaxLength = currentInvoice.invoice_materials?.reduce((innerMax, invoiceMaterial) => {
      const { fractional } = getSpreadValueByDot(invoiceMaterial?.extended_price?.toString() || '')
      return Math.max(innerMax, fractional?.length || 0)
    }, 0)

    return Math.max(maxLength, currentMaxLength)
  }, 0)

  const invoicesSubtotal = invoicesRelatedToCurrentOrder.reduce((total, currentInvoice) => {
    const invoiceSubtotal = currentInvoice?.invoice_materials.reduce((subtotal, invoiceMaterial) => {
      const extendedCost = Number(invoiceMaterial?.extended_price) || 0
      return subtotal + extendedCost
    }, 0)

    return total + invoiceSubtotal
  }, 0)

  return roundValue(invoicesSubtotal, maxFractionalLength)
}

export const calculateTotalInvoiced = (expandableInvoices) => {
  const maxFractionalLength = expandableInvoices.reduce((maxLength, currentInvoice) => {
    const { fractional } = getSpreadValueByDot(currentInvoice?.invoiced?.toString() || '')
    return Math.max(maxLength, fractional?.length || 0)
  }, 0)

  const totalInvoiced = expandableInvoices.reduce((acc, currentInvoice) => acc + currentInvoice.invoiced, 0)

  return roundValue(totalInvoiced, maxFractionalLength)
}

export const getInvoicesRelatedToOrder = (order, allInvoices) => {
  return allInvoices.filter((currentInvoice) => order.invoices.some(({ id }) => id === currentInvoice.id))
}

export const makeInvoiceTaxRows = (invoicesRelatedToCurrentOrder) => {
  return invoicesRelatedToCurrentOrder
    .filter((currentInvoice) => Number(currentInvoice.tax_amount) !== 0)
    .map((currentInvoice) => ({
      id: currentInvoice.id,
      description: 'Tax',
      invoice_id: currentInvoice.id,
      number: currentInvoice.number,
      invoiced: Number(currentInvoice.tax_amount) || 0,
    }))
}

export const makeInvoiceUnreconciledItemsRows = (invoiceMaterials: InvoiceMaterial[], invoice: InvoiceResponse) => {
  return invoiceMaterials
    ?.filter((invoiceMaterial) => invoiceMaterial?.description)
    ?.map((invoiceMaterial) => ({
      id: invoiceMaterial.id,
      company_material: {
        description: invoiceMaterial.description,
        unit_id: invoiceMaterial.unit?.id,
        unit_name: invoiceMaterial.uom,
      },
      invoice_materials: [{ ...invoiceMaterial, number: invoice.number }],
      invoice_id: invoiceMaterial.invoice_id,
      quantity: invoiceMaterial.quantity_shipped,
      unit_cost: invoiceMaterial.unit_price,
      is_remaining: true,
    }))
}

export const makeInvoiceShippingRows = (invoicesRelatedToCurrentOrder) => {
  return invoicesRelatedToCurrentOrder
    .filter((currentInvoice) => Number(currentInvoice.shipping_cost) !== 0)
    .map((currentInvoice) => ({
      id: currentInvoice.id,
      description: 'Shipping',
      invoice_id: currentInvoice.id,
      number: currentInvoice.number,
      invoiced: Number(currentInvoice.shipping_cost) || 0,
    }))
}

export const makeInvoiceOtherRows = (invoicesRelatedToCurrentOrder) => {
  return invoicesRelatedToCurrentOrder
    .filter((currentInvoice) => Number(currentInvoice.other_costs) !== 0)
    .map((currentInvoice) => ({
      id: currentInvoice.id,
      description: 'Other',
      invoice_id: currentInvoice.id,
      number: currentInvoice.number,
      invoiced: Number(currentInvoice.other_costs) || 0,
    }))
}

export const makeInvoiceDiscountRows = (invoicesRelatedToCurrentOrder) => {
  return invoicesRelatedToCurrentOrder
    .filter((currentInvoice) => Number(currentInvoice.discount_amount) !== 0)
    .map((currentInvoice) => ({
      id: currentInvoice.id,
      description: 'Discount',
      invoice_id: currentInvoice.id,
      number: currentInvoice.number,
      invoiced: Number(currentInvoice.discount_amount) || 0,
    }))
}

export const makeInvoiceTotalRows = (invoicesRelatedToCurrentOrder, allOrderMaterials) => {
  return invoicesRelatedToCurrentOrder.map((currentInvoice) => {
    const orderMaterialRelatedToCurrentInvoice = allOrderMaterials.filter((orderMaterial) =>
      orderMaterial.invoice_materials.some((invoiceMaterial) => invoiceMaterial.invoice_id === currentInvoice.id),
    )

    const maxFractionalLength = orderMaterialRelatedToCurrentInvoice.reduce((maxLength, currentOrderMaterial) => {
      const currentInvoiceMaterials = currentOrderMaterial?.invoice_materials?.filter(
        (invoiceMaterial) => invoiceMaterial.invoice_id === currentInvoice.id,
      )
      const maxInnerFractionalLength = currentInvoiceMaterials.reduce((innerLength, invoiceMaterial) => {
        const { fractional } = getSpreadValueByDot(invoiceMaterial?.ext_cost_with_tax?.toString() || '')
        return Math.max(innerLength, fractional?.length || 0)
      }, 0)

      return Math.max(maxLength, maxInnerFractionalLength?.length || 0)
    }, 0)

    const totalExtCostWithTax = orderMaterialRelatedToCurrentInvoice.reduce((acc, currentOrderMaterial) => {
      const currentInvoiceMaterials = currentOrderMaterial?.invoice_materials?.filter(
        (invoiceMaterial) => invoiceMaterial.invoice_id === currentInvoice.id,
      )
      const totalExtCostWithTax = currentInvoiceMaterials.reduce(
        (acc, invoiceMaterial) => acc + Number(invoiceMaterial.ext_cost_with_tax) || 0,
        0,
      )

      return acc + totalExtCostWithTax
    }, 0)

    return {
      id: currentInvoice.id,
      description: 'Total',
      invoice_id: currentInvoice.id,
      number: currentInvoice.number,
      tax: Number(currentInvoice.tax_amount) || 0,
      invoiced: Number(currentInvoice.invoice_grand_total) || 0,
      ext_cost_with_tax: roundValue(totalExtCostWithTax, maxFractionalLength),
    }
  })
}

export const makeOrderTaxRow = ({ order, orderedTax, invoicedTax, expandableInvoicesTaxRows }) => {
  const { fractional: fractionalOrderedTax } = getSpreadValueByDot(orderedTax?.toString() || '')
  const { fractional: fractionalInvoicedTax } = getSpreadValueByDot(invoicedTax?.toString() || '')

  const maxFractionalLength = Math.max(fractionalOrderedTax?.length || 0, fractionalInvoicedTax?.length || 0)

  return {
    id: `tax-${order.id}`,
    company_material: { description: 'Tax' },
    order_number: order.order_number,
    ordered: orderedTax,
    invoiced: invoicedTax,
    remaining: roundValue(orderedTax - invoicedTax, maxFractionalLength),
    invoice_materials: expandableInvoicesTaxRows,
  }
}

export const makeOrderShippingRow = ({ order, orderedShipping, invoicedShipping, expandableInvoicesShippingRows }) => {
  const { fractional: fractionalOrderedShipping } = getSpreadValueByDot(orderedShipping?.toString() || '')
  const { fractional: fractionalInvoicedShipping } = getSpreadValueByDot(invoicedShipping?.toString() || '')

  const maxFractionalLength = Math.max(fractionalOrderedShipping?.length || 0, fractionalInvoicedShipping?.length || 0)

  return {
    id: `shipping-${order.id}`,
    company_material: { description: 'Shipping' },
    order_number: order.order_number,
    ordered: orderedShipping,
    invoiced: invoicedShipping,
    remaining: roundValue(orderedShipping - invoicedShipping, maxFractionalLength),
    invoice_materials: expandableInvoicesShippingRows,
  }
}

export const makeOrderOtherRow = ({ order, orderedOther, invoicedOther, expandableInvoicesOtherRows }) => {
  const { fractional: fractionalOrderedOther } = getSpreadValueByDot(orderedOther?.toString() || '')
  const { fractional: fractionalInvoicedOther } = getSpreadValueByDot(invoicedOther?.toString() || '')

  const maxFractionalLength = Math.max(fractionalOrderedOther?.length || 0, fractionalInvoicedOther?.length || 0)

  return {
    id: `other-${order.id}`,
    company_material: { description: 'Other' },
    order_number: order.order_number,
    ordered: orderedOther,
    invoiced: invoicedOther,
    remaining: roundValue(orderedOther - invoicedOther, maxFractionalLength),
    invoice_materials: expandableInvoicesOtherRows,
  }
}

export const makeOrderDiscountRow = ({ order, orderedDiscount, invoicedDiscount, expandableInvoicesDiscountRows }) => {
  const { fractional: fractionalOrderedDiscount } = getSpreadValueByDot(orderedDiscount?.toString() || '')
  const { fractional: fractionalInvoicedDiscount } = getSpreadValueByDot(invoicedDiscount?.toString() || '')

  const maxFractionalLength = Math.max(fractionalOrderedDiscount?.length || 0, fractionalInvoicedDiscount?.length || 0)

  return {
    id: `discount-${order.id}`,
    company_material: { description: 'Discount' },
    order_number: order.order_number,
    ordered: orderedDiscount,
    invoiced: invoicedDiscount,
    remaining: roundValue(orderedDiscount - invoicedDiscount, maxFractionalLength),
    invoice_materials: expandableInvoicesDiscountRows,
  }
}

export const makeOrderTotalRow = ({ order, tax, invoicedTotal, invoicesTotalRows, remaining }) => {
  const maxFractionalLength = order?.order_materials?.reduce((maxLength, currentMaterial) => {
    const { fractional: fractionalExtendedPrice } = getSpreadValueByDot(
      currentMaterial?.extended_price?.toString() || '',
    )
    const { fractional: fractionalTaxValue } = getSpreadValueByDot(currentMaterial?.tax_value?.toString() || '')

    return Math.max(maxLength, fractionalExtendedPrice?.length || 0, fractionalTaxValue?.length || 0)
  }, 0)

  const totalExtCostWithTax = order?.order_materials?.reduce((acc, currentMaterial) => {
    const extendedCost = Number(currentMaterial?.extended_price) || 0

    return acc + currentMaterial?.tax_value + extendedCost
  }, 0)

  return {
    id: `total-${order.id}`,
    order_id: order.id,
    company_material: { description: 'Total' },
    order_number: order.order_number,
    tax_split: tax,
    ext_cost_with_tax: roundValue(totalExtCostWithTax, maxFractionalLength),
    ordered: Number(order.grand_total),
    invoiced: invoicedTotal,
    remaining,
    invoice_materials: invoicesTotalRows,
  }
}
