import React from 'react'

import { Button, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { DateRangePicker } from 'common/components/DateRangePicker'
import { DrawerTableFilters } from 'common/components/DrawerTableFilters'
import { SelectFilter } from 'common/components/table/postgres_filters'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

export interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: { [key: string]: any }
  facets?: { [key: string]: string | Date }
}

export function InvoiceInboxesFilter(props: Props) {
  const { values = {}, facets = [] } = props

  const persistentFilters = usePersistentFilters()

  const optionsFactory = React.useCallback(
    (key: string, nameAttributeKey: string) => {
      return (facets[key] || []).map((x) => ({
        label: x[nameAttributeKey],
        value: x[nameAttributeKey] === 'Unknown' ? '' : x[nameAttributeKey],
      }))
    },
    [facets],
  )

  return (
    <DrawerTableFilters
      title="Order Options"
      useAlgolia={false}
      appliedFiltersCounter={persistentFilters.appliedFiltersCount}
    >
      <Box mb={16} display="flex" justifyContent="space-between" alignItems="center">
        <Typography.Title level={5} style={{ margin: 0 }}>
          Filters
        </Typography.Title>

        <Button
          type="link"
          data-cy="clear-filters"
          onClick={() => persistentFilters.handleClearFilters()}
          disabled={persistentFilters.appliedFiltersCount === 0}
        >
          Clear All
        </Button>
      </Box>

      <Box mb={16} width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">From</Typography.Text>

        <SelectFilter
          items={optionsFactory('from', 'from')}
          selected={values['from']}
          onChange={(value) => persistentFilters.handleChangeFilters({ from: value })}
          attribute="from"
          placeholder="Email from"
          isSearchable
        />
      </Box>

      <Box mb={16} width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Uploaded by</Typography.Text>

        <SelectFilter
          items={optionsFactory('uploaded_by', 'uploaded_by')}
          selected={values['uploaded_by']}
          onChange={(value) => persistentFilters.handleChangeFilters({ uploaded_by: value })}
          attribute="uploaded_by"
          placeholder="Manually uploaded by"
          isSearchable
        />
      </Box>

      <Box mb={16} width="100%" display="flex" flexDirection="column">
        <Typography.Text type="secondary">Origin</Typography.Text>

        <SelectFilter
          items={optionsFactory('origin', 'origin')}
          selected={values['origin']}
          onChange={(value) => persistentFilters.handleChangeFilters({ origin: value })}
          attribute="origin"
          placeholder="Select by origin"
          isSearchable
        />
      </Box>

      <Box width="100%" mb={24}>
        <Typography.Text type="secondary">Created at</Typography.Text>

        <DateRangePicker
          placeholderStart="Start date"
          placeholderEnd="End date"
          attribute="invoice_inbox_created_at"
          value={{
            startDate: values['created_at_start'],
            endDate: values['created_at_end'],
          }}
          onChange={({ startDate, endDate }) => {
            persistentFilters.handleChangeFilters({
              created_at_start: startDate,
              created_at_end: endDate,
            })
          }}
        />
      </Box>
    </DrawerTableFilters>
  )
}
