import * as React from 'react'

import { CellProps } from 'react-table'

import { get } from 'lodash'

import { Tag } from 'antd'

import { FlexBoxX } from 'common/components/boxes'

type Watcher = {
  id: string
  full_name: string
}

export type CellWatchersProps = CellProps<{ watchers: Watcher[] }>

export const CellWatchers = ({ row }: CellWatchersProps) => {
  const { original } = row
  const watchers = get(original, 'watchers') || []

  return (
    <FlexBoxX alignItems="flex-start" justifyContent="flex-start">
      {watchers?.map((watcher, index) => (
        <Tag key={index}>{watcher?.full_name}</Tag>
      ))}
    </FlexBoxX>
  )
}
